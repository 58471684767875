import {
    BasicButton,
    ClientId,
    getMatch,
    getMatchStartsWith,
    H2,
    ShadowCard,
    Tooltip,
} from "@fm-frontend/uikit";
import { BITMASK_UTILS, getEnv } from "@fm-frontend/utils";
import { CpStatusBadge } from "components/CpStatusBadge";
import { CLIENT_TYPE_TITLES } from "const";
import React, { useEffect, useMemo, useState } from "react";
import { CpStatus } from "store/counterpartiesSlice";
import {
    useIsMakerUser,
    useIsPrimeBrokerUser,
    useIsSubaccountUser,
    usePrimeBrokerViewType,
    useUsdPrices,
} from "store/hooks";
import { clearPreopenedCpIdLimit, getPreopenedCpIdLimit } from "store/preopenedCpIdLimit";
import styled from "styled-components";
import { TRADING_LIMITS_TAB } from "../counterpartyLimitModal/utils";
import { RiskManagementModal } from "../modal";
import { LimitProps, LimitType } from "../types";
import { CounterpartyStatusType, parseLimit, shouldUseUserGrossLimit } from "../utils";
import { CounterpartyIndicator } from "./counterpartyIndicator";
import { IndicatorHint } from "./indicatorHint/IndicatorHint";
import { Menu } from "./Menu";
import { MenuItem } from "./Menu/Item";
import * as Styled from "./styled";
import {
    getAppliedLimit,
    getCpLimitIndicatorData,
    getEquityIndicatorData,
    getGrossFreeIndicatorData,
    getGrossLimitIndicatorData,
    getInitialMarginLimitIndicatorData,
    getMaintenanceMarginLimitIndicatorData,
    getRestrictedTradingLimitIndicatorData,
} from "./utils";

const Header = styled(Styled.Header)`
    padding: 12px 12px 10px 12px;
`;

const HeaderMenu = styled(Menu)`
    padding: 0 12px 8px 8px;
`;

const Body = styled(Styled.Body)`
    gap: 0;
    padding: 0;

    > * {
        border-top: 1px solid ${(p) => p.theme.colors.ui12};
    }

    > :not(:last-child) {
        border-right: 1px solid ${(p) => p.theme.colors.ui12};

        @media (max-width: 1358px) {
            border-right: none;
        }
    }
`;

const EditButton = styled(BasicButton).attrs({ size: "small" })``;

const ShadowContainer = styled(ShadowCard)<{ isDefault?: boolean }>`
    &:hover {
        background-color: ${(p) => p.theme.colors.uiWhite100};
        box-shadow: 0 0 0 1px ${(p) => p.theme.colors.ui12} inset,
            0 4px 16px 0 ${(p) => p.theme.colors.ui8};
    }

    &:hover:not(:has(${MenuItem}:hover)) {
        ${EditButton} {
            background-color: ${(p) => p.theme.colors.ui12};
        }
    }
`;

const defaultStatusObj: CpStatus = {
    status: CounterpartyStatusType.NORMAL,
    grossStatus: CounterpartyStatusType.NORMAL,
    equityStatus: CounterpartyStatusType.NORMAL,
} as const;

const marginRequirementOffIndicator = {
    title: "Margin requirement",
    value: "Off",
};

const { HRP_MASTER_ID, HRP_CLEARING_TIME } = getEnv();
const ClearingTime = styled.div`
    width: 100%;
    text-align: right;
    color: ${(p) => p.theme.colors.ui72};
`;
const hrpClearingTime = <ClearingTime>🗓️ Clearing at {HRP_CLEARING_TIME}</ClearingTime>;

const { isBitKeyApplied } = BITMASK_UTILS;

export const Counterparty: React.FC<LimitProps> = (props) => {
    const { cpTitle, extendedLimit, cpId, cpStatus, searchQuery = "" } = props;
    const [isOpened, setIsOpened] = useState(getPreopenedCpIdLimit() === cpId);
    const [defaultTab, setDefaultTab] = useState(TRADING_LIMITS_TAB);
    const isSubaccountUser = useIsSubaccountUser();
    const isMakerUser = useIsMakerUser();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();

    useEffect(() => {
        clearPreopenedCpIdLimit();
    }, []);

    const { priceObj } = useUsdPrices();

    const { limit, cpType: limitCpType } = extendedLimit;
    const shouldTakeUserGrossLimit = shouldUseUserGrossLimit(limitCpType, isMakerUser);

    const { counterpartyId, maintenanceMargin, restrictedTrading, initialMargin } =
        parseLimit(limit);

    const { status, grossStatus, equityStatus } = cpStatus ?? defaultStatusObj;

    const { appliedLimitType, appliedLimit } = useMemo(
        () => getAppliedLimit(limit, priceObj),
        [limit, priceObj],
    );

    const grossFreeIndicator = useMemo(
        () => getGrossFreeIndicatorData(limit, appliedLimit, isSubaccountUser),
        [limit, appliedLimit, isSubaccountUser],
    );

    const grossLimitIndicator = useMemo(
        () => getGrossLimitIndicatorData(limit, appliedLimitType),
        [limit, appliedLimitType],
    );

    const cpLimitIndicator = useMemo(
        () => getCpLimitIndicatorData(limit, appliedLimitType, isSubaccountUser, cpTitle),
        [limit, appliedLimitType, isSubaccountUser, cpTitle],
    );

    const equityIndicator = useMemo(
        () => getEquityIndicatorData(limit, shouldTakeUserGrossLimit, limitCpType, cpTitle),
        [limit, shouldTakeUserGrossLimit, limitCpType, cpTitle],
    );

    const maintenanceMarginLimitIndicator = useMemo(
        () => getMaintenanceMarginLimitIndicatorData(limit, shouldTakeUserGrossLimit),
        [limit, shouldTakeUserGrossLimit],
    );

    const restrictedTradingLimitIndicator = useMemo(
        () => getRestrictedTradingLimitIndicatorData(limit, shouldTakeUserGrossLimit),
        [limit, shouldTakeUserGrossLimit],
    );

    const initialMarginLimitIndicator = useMemo(
        () => getInitialMarginLimitIndicatorData(limit, shouldTakeUserGrossLimit),
        [limit, shouldTakeUserGrossLimit],
    );

    const isMarginRequirementOn = Boolean(maintenanceMargin && restrictedTrading && initialMargin);

    const cpLimitHidden = isPrimeBrokerUser && primeBrokerViewType === "subaccounts";

    const handleConfigureClick = () => {
        if (!isSubaccountUser) {
            setDefaultTab(TRADING_LIMITS_TAB);
            setIsOpened(true);
        }
    };

    const type =
        Number(cpId) === HRP_MASTER_ID ? "Clearing broker" : CLIENT_TYPE_TITLES[limitCpType];

    return (
        <ShadowContainer isDefault>
            <Styled.Container onClick={handleConfigureClick}>
                <Header>
                    <Styled.Title>
                        <Styled.TitleBody>
                            <Styled.TitleHeader>
                                <H2>{getMatch(cpTitle, searchQuery) ?? cpTitle}</H2>
                                <ClientId
                                    id={
                                        <>
                                            {type}{" "}
                                            {getMatchStartsWith(
                                                String(counterpartyId),
                                                searchQuery,
                                            ) ?? counterpartyId}
                                        </>
                                    }
                                />
                            </Styled.TitleHeader>
                        </Styled.TitleBody>
                    </Styled.Title>
                    <Styled.Actions>
                        {isBitKeyApplied(CounterpartyStatusType.LOW_GROSS_FREE, status) ||
                        isBitKeyApplied(CounterpartyStatusType.LIQUIDATION, status) ? (
                            <Tooltip
                                positions={["left"]}
                                align={"center"}
                                isHoveredContent
                                content={
                                    <>
                                        <IndicatorHint
                                            status={status}
                                            statusMask={CounterpartyStatusType.LOW_GROSS_FREE}
                                        />
                                        <IndicatorHint
                                            status={status}
                                            statusMask={CounterpartyStatusType.LIQUIDATION}
                                        />
                                    </>
                                }
                            >
                                <CpStatusBadge cpStatus={status} />
                            </Tooltip>
                        ) : (
                            <CpStatusBadge cpStatus={status} />
                        )}

                        {!isSubaccountUser && (
                            <EditButton onClick={handleConfigureClick}>Edit</EditButton>
                        )}
                    </Styled.Actions>
                </Header>
                <HeaderMenu limit={limit} limitCpType={limitCpType} />
                <Styled.ScrollContainer>
                    <Body>
                        <Styled.HintWrapper>
                            <Styled.IndicatorContainer status={status} subStatus={grossStatus}>
                                <Styled.IndicatorWrapper>
                                    <CounterpartyIndicator
                                        status={status}
                                        subStatus={grossStatus}
                                        indicatorData={grossFreeIndicator}
                                        mainIndicator
                                        highlighted
                                    />
                                </Styled.IndicatorWrapper>
                                <Styled.IndicatorWrapper>
                                    {!isSubaccountUser && (
                                        <CounterpartyIndicator
                                            indicatorData={grossLimitIndicator}
                                            highlighted={appliedLimitType === LimitType.UserLimit}
                                        />
                                    )}
                                    {!cpLimitHidden && (
                                        <CounterpartyIndicator
                                            indicatorData={cpLimitIndicator}
                                            highlighted={appliedLimitType === LimitType.MutualLimit}
                                        />
                                    )}
                                </Styled.IndicatorWrapper>
                            </Styled.IndicatorContainer>
                        </Styled.HintWrapper>
                        <Styled.HintWrapper>
                            <Styled.IndicatorContainer status={status} subStatus={equityStatus}>
                                <Styled.IndicatorWrapper>
                                    <CounterpartyIndicator
                                        status={status}
                                        subStatus={equityStatus}
                                        indicatorData={equityIndicator}
                                        mainIndicator={true}
                                        highlighted={true}
                                    />
                                    {HRP_MASTER_ID === Number(cpId) && hrpClearingTime}
                                </Styled.IndicatorWrapper>
                                <Styled.IndicatorWrapper>
                                    {isMarginRequirementOn ? (
                                        <>
                                            <CounterpartyIndicator
                                                indicatorData={
                                                    isMakerUser || isPrimeBrokerUser
                                                        ? maintenanceMarginLimitIndicator
                                                        : initialMarginLimitIndicator
                                                }
                                            />
                                            <CounterpartyIndicator
                                                indicatorData={restrictedTradingLimitIndicator}
                                            />
                                        </>
                                    ) : (
                                        <CounterpartyIndicator
                                            indicatorData={marginRequirementOffIndicator}
                                            highlighted={false}
                                        />
                                    )}
                                </Styled.IndicatorWrapper>
                            </Styled.IndicatorContainer>
                        </Styled.HintWrapper>
                    </Body>
                </Styled.ScrollContainer>
            </Styled.Container>
            <RiskManagementModal
                extendedLimit={extendedLimit}
                type={type}
                counterpartyId={counterpartyId}
                status={status}
                isOpened={isOpened}
                onClose={() => setIsOpened(false)}
                cpTitle={cpTitle}
                defaultTab={defaultTab}
            />
        </ShadowContainer>
    );
};
