import { useCallback, useEffect, useState } from "react";
import { LIGHTBOX_SCROLL_CONTAINER } from "../utils";

export const useScrollToBottom = () => {
    const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        const element = document.getElementById(LIGHTBOX_SCROLL_CONTAINER);
        setScrollContainer(element as HTMLDivElement);
    }, []);

    return useCallback(() => {
        if (scrollContainer) {
            const scrollHeight = scrollContainer.scrollHeight - scrollContainer.clientHeight;
            scrollContainer.scrollTo({ top: scrollHeight, behavior: "smooth" });
        }
    }, [scrollContainer]);
};
