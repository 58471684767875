import { useCallback } from "react";
import useSWR from "swr";
import { post } from "../api";
import { MakerInfo } from "../types";
import { displayError } from "../utils";

export const useMakersInfo = () => {
    const fetcher = useCallback(() => post("onboarding/makerInfo", {}), []);
    const { data: makersInfo, isValidating } = useSWR<MakerInfo[]>("api/onboarding/makerInfo", fetcher, {
        onError: displayError,
        fallbackData: [],
        revalidateOnMount: true,
        revalidateOnFocus: false,
    });

    return {
        makersInfo,
        isLoading: isValidating,
    };
};
