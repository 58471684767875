import { TUserTextAdoptionDictionary } from "hooks/useTextAdaptor";

export const addressesTextAdoptionDictionary: TUserTextAdoptionDictionary = {
    addAndAddressForAll: {
        default: "Address for all CPs",
        primeBroker: {
            subaccounts: "Add for all sub-accounts",
        },
    },
    allCpOrAllSubaccount: {
        default: "All CP",
        primeBroker: {
            subaccounts: "All Sub-accounts",
        },
    },
    cryptoAddressBoxDescription: {
        default:
            "Add addresses to receive assets by clicking on the '+ Address'. Once confirmed by a counterparty, addresses will be used for settlements.",
        primeBroker: {
            subaccounts:
                "Add addresses to receive assets by clicking on the '+ Address'. Once confirmed by a sub-account, addresses will be used for settlements.",
        },
    },
    bankAddressBoxDescription: {
        default:
            "Add accounts to receive assets by clicking on the '+ Account. Once confirmed by a counterparty, accounts will be used for settlements.",
        primeBroker: {
            subaccounts:
                "Add accounts to receive assets by clicking on the '+ Account. Once confirmed by a sub-account, accounts will be used for settlements.",
        },
    },
    yourAccountHeaderTitle: {
        default: "Your addresses",
    },
    sharedAccountHeaderTitle: {
        default: "CP addresses",
        primeBroker: {
            subaccounts: "Sub-accounts addresses",
        },
        subAccount: "Master’s addresses",
    },
    cryptoLightboxNameColumn: {
        default: "Counterparty",
        primeBroker: {
            subaccounts: "Sub-account",
        },
    },
};
