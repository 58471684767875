import { HStack, P } from "@fm-frontend/uikit";
import { CurrencyIcon } from "components/CurrencyIcon";
import React from "react";

export const CurrencyCell: React.FC<{ currency: string | undefined }> = ({ currency }) => {
    if (!currency) {
        return null;
    }

    return (
        <HStack alignItems="center" spacing={4}>
            <CurrencyIcon size={16} name={currency} />
            <P>{currency}</P>
        </HStack>
    );
};
