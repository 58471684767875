import { forwardRef, ReactNode, TextareaHTMLAttributes, useEffect, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import { HintVariant, HintVariantComponents } from "../../common/HintVariantComponents";
import { VStack } from "../../layout";
import { PSmall, TextSmall } from "../../typography";

const Container = styled(VStack)<{ isDisabled?: boolean }>`
    width: 100%;
    gap: 4px;

    ${(p) =>
        p.isDisabled &&
        css`
            ${PSmall}, textarea, textarea::placeholder {
                color: ${p.theme.colors.ui20} !important;
            }
        `}
`;

export const TextareaElement = styled.textarea`
    align-self: stretch;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.07px;
    background-color: transparent;
    height: 100%;
`;

export const TextareaContainer = styled(VStack)`
    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.ui8};
`;

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string | ReactNode;
    hint?: string;
    error?: string;
    height?: string;
}
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
    const { colors } = useTheme();
    const { height, hint, error, label, disabled, ...rest } = props;

    const [hintVariant, setHintVariant] = useState<{ variant: HintVariant; text?: string }>({
        variant: "hint",
    });

    useEffect(() => {
        if (error) {
            setHintVariant({ variant: "error", text: error });
        } else {
            setHintVariant({ variant: "hint", text: hint });
        }
    }, [error, hint]);

    const HintComponent = HintVariantComponents[hintVariant.variant];

    const labelElement =
        label === undefined ? undefined : typeof label === "string" ? (
            <PSmall color={colors.ui72}>{label}</PSmall>
        ) : (
            label
        );

    return (
        <Container>
            {labelElement}
            <TextareaContainer padding={12} height={height}>
                <TextareaElement disabled={disabled} {...rest} ref={ref} />
            </TextareaContainer>
            {hintVariant.text && (
                <HintComponent>
                    <TextSmall>{hintVariant.text}</TextSmall>
                </HintComponent>
            )}
        </Container>
    );
});
