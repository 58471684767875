import { Tooltip } from "@fm-frontend/uikit";
import { CopyableValue } from "./CopyableValue";

export const TransactionLabel = ({ txId }: { txId?: string }) => {
    if (!txId) {
        return null;
    }

    const title = txId.length > 8 ? `${txId.slice(0, 4)}...${txId.slice(-4)}` : txId;

    return (
        <Tooltip content={txId}>
            <CopyableValue value={txId} title={title} stopPropagation />
        </Tooltip>
    );
};
