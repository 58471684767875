import { EmptySearch } from "@fm-frontend/uikit/src/components/common/EmptySearch";
import { VFC } from "react";
import { useParams } from "react-router";
import { HistoryTab } from "./hooks";
import { RfqHistory } from "./RfqHistory";
import { TradesHistory } from "./TradesHistory";

export const TradesHistoryPageContent: VFC = () => {
    const { tab: historyTab = HistoryTab.FirmBook } = useParams<{ tab: HistoryTab }>();

    if (historyTab === HistoryTab.FirmBook) {
        return <TradesHistory />;
    }

    if (historyTab === HistoryTab.RFQ) {
        return <RfqHistory />;
    }

    return <EmptySearch />;
};
