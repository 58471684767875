import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { useMemo } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { AccountStatusType, AddressSide } from "../../types";

const yourStatusOptions: DropdownOption<AccountStatusType>[] = [
    {
        text: "Approved",
        value: AccountStatusType.Approved,
    },
    {
        text: "Verification",
        value: AccountStatusType.Verification,
    },
];
const sharedStatusOptions: DropdownOption<AccountStatusType>[] = [
    {
        text: "Approved",
        value: AccountStatusType.Approved,
    },
    {
        text: "Need to confirm",
        value: AccountStatusType.NeedToConfirm,
    },
];

type StatusFilterDropdownProps<T extends FieldValues> = {
    control: Control<T>;
    name: Path<T>;
    side: AddressSide;
};

export const StatusFilterDropdown = <T extends FieldValues>({ control, name, side }: StatusFilterDropdownProps<T>) => {
    const statusOptions = useMemo(() => (side === "your" ? yourStatusOptions : sharedStatusOptions), [side]);

    return (
        <Controller
            control={control}
            render={({ field }) => (
                <MultipleDropdown
                    values={field.value}
                    onChange={field.onChange}
                    renderTrigger={(trigger) => (
                        <MultipleDropdown.Trigger {...trigger} size="small" variant="simple">
                            <MultipleDropdown.TriggerEssence
                                {...trigger}
                                size="small"
                                option={trigger.selectedOptions}
                            />
                        </MultipleDropdown.Trigger>
                    )}
                    options={statusOptions}
                    caption="Status"
                    asFilter
                >
                    <MultipleDropdown.Sheet size="small">
                        {statusOptions.map((option) => (
                            <MultipleDropdown.Option key={option.value} value={option.value}>
                                <MultipleDropdown.OptionEssence option={option} />
                            </MultipleDropdown.Option>
                        ))}
                    </MultipleDropdown.Sheet>
                </MultipleDropdown>
            )}
            name={name}
        />
    );
};
