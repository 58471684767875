import { PSmallBold } from "@fm-frontend/uikit";
import { EmDash } from "const";
import { TradeHistory } from "feature/history/types";
import React from "react";
import styled from "styled-components";

const Element = styled(PSmallBold)`
    text-wrap: nowrap;
`;

type InstrumentCellProps = Pick<TradeHistory, "instrument" | "isSubrow">;
export const InstrumentCell: React.FC<InstrumentCellProps> = ({ instrument, isSubrow }) => {
    if (instrument === undefined) {
        return <>{EmDash}</>;
    }
    if (instrument === null || isSubrow) {
        return <></>;
    }

    return <Element>{instrument}</Element>;
};
