import { post } from "api";
import { ListItem } from "components/ListItem";
import { CLIENT_TYPE_TITLES } from "const";
import { NEW_TARIFF_POLICY_TIME } from "const/featureFlags";
import { createNotification } from "feature/app";
import { ColoredButton } from "feature/form/Buttons";
import FormInput from "feature/form/FormInput";
import { FormLabel } from "feature/form/style";
import { FormConfig, useForm } from "feature/form/useForm";
import { useOwnerUser } from "hooks/useOwnerUser";
import { useSelector } from "hooks/useSelector";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useIsMakerUser, useIsTakerUser, useUserType } from "store/hooks";
import styled from "styled-components";
import { fmt, formatShiftFixed } from "utils/format";
import { Card, CenteredContent, Row, Title } from "./styled";
import { withSettingsLayout } from "./withSettingsLayout";

const FormGroup = styled.div`
    padding-bottom: 20px;
`;

const FormRow = styled.div`
    margin-top: 16px;
    flex: 1;
    & > label {
        padding-bottom: 8px;
    }
`;

const FlexRow = styled.div`
    display: flex;
    gap: 16px;
`;

const Bottom = styled.div`
    padding: 12px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${(p) => p.theme.colors.ui8};
`;

const SettingsProfileView = () => {
    const { userId, clientData } = useSelector((state) => state.authentication);
    const { signTnc } = clientData ?? {};
    const isMakerUser = useIsMakerUser();
    const isTakerUser = useIsTakerUser();
    const { ownerUser } = useOwnerUser();
    const isOldTariffPolicy =
        ownerUser?.createdAt !== undefined && ownerUser.createdAt < NEW_TARIFF_POLICY_TIME;
    const userType = useUserType();
    const userTypeTitle: string = userType ? CLIENT_TYPE_TITLES[userType] : "Unknown";
    const history = useHistory();
    const dispatch = useDispatch();
    const [
        { registrationNumber, country, companyName, address, city, zipCode, fineryShare, makerFee },
        setCurrentData,
    ] = useState({
        registrationNumber: "",
        country: "",
        companyName: "",
        address: "",
        city: "",
        zipCode: "",
        fineryShare: 0,
        makerFee: 0,
    });
    const turnoverFee = useMemo(() => {
        return `${formatShiftFixed(makerFee, 6)}%`;
    }, [makerFee]);
    const markupShare = useMemo(() => {
        return fmt(fineryShare, {
            type: "u32",
            showZero: true,
            unit: "%",
            unitPosition: "post",
        }).formattedValue;
    }, [fineryShare]);
    const form = useMemo(() => {
        const config: FormConfig = {
            fields: {
                address: { placeholder: "Erik-Mann Street 9" },
                city: { placeholder: "City" },
                zipCode: { placeholder: "123456" },
            },
            resetValues: false,
            shouldShowError: true,
            url: "updateClientData",
        };
        return config;
    }, []);
    const { setValues, inputProps, values, onSubmit, areActionsDisabled } = useForm(form);
    useEffect(() => {
        post("getClientData", {}).then((data) => {
            setCurrentData(data);
            setValues({
                address: data.address,
                city: data.city,
                zipCode: data.zipCode,
            });
        });
    }, []);
    const isDataChanged = useMemo(() => {
        return address !== values.address || city !== values.city || zipCode !== values.zipCode;
    }, [address, city, zipCode, values]);

    const handleUpdateChangesClick = (ev: React.MouseEvent) => {
        ev.preventDefault();
        const newValues = { ...values };
        onSubmit().then((success) => {
            if (success) {
                dispatch(
                    createNotification({
                        type: "success",
                        content: "Legal data has been saved",
                    }),
                );
                setCurrentData((values) => ({
                    ...values,
                    ...newValues,
                }));
            }
        });
    };

    return (
        <>
            <CenteredContent>
                <Card>
                    <Title>Account data</Title>
                    <Row>
                        <div>Display name</div>
                        <span>{clientData?.username}</span>
                    </Row>
                    <Row>
                        <div>User ID</div>
                        <span>{userId}</span>
                    </Row>
                    <Row>
                        <div>User type</div>
                        <span>{userTypeTitle}</span>
                    </Row>
                </Card>
                {isMakerUser && isOldTariffPolicy && (
                    <Card>
                        <Title>Profile data</Title>
                        <Row>
                            <div>Turnover fee</div>
                            <span>{turnoverFee}</span>
                        </Row>
                        <Row>
                            <div>FM Markup share</div>
                            <span>{markupShare}</span>
                        </Row>
                    </Card>
                )}
                {isTakerUser && isOldTariffPolicy && (
                    <Card>
                        <Title>Profile data</Title>
                        <Row>
                            <div>Turnover fee</div>
                            <span>0.000000%</span>
                        </Row>
                    </Card>
                )}
                {signTnc && (
                    <Card>
                        <Title>Company data</Title>
                        <FormGroup>
                            <FormRow>
                                <FormLabel>Company name</FormLabel>
                                <FormInput
                                    disabled
                                    value={companyName}
                                    placeholder="Company name"
                                />
                            </FormRow>
                            <FormRow>
                                <FormLabel>Registration number</FormLabel>
                                <FormInput
                                    disabled
                                    value={registrationNumber}
                                    placeholder="999481789"
                                />
                            </FormRow>
                            <FormRow>
                                <FormLabel>Legal address</FormLabel>
                                <FormInput {...inputProps.address} />
                            </FormRow>
                            <FlexRow>
                                <FormRow>
                                    <FormLabel>City</FormLabel>
                                    <FormInput {...inputProps.city} />
                                </FormRow>
                                <FormRow className="half">
                                    <FormLabel>ZIP Code</FormLabel>
                                    <FormInput {...inputProps.zipCode} />
                                </FormRow>
                            </FlexRow>
                            <FormRow>
                                <FormLabel>Country</FormLabel>
                                <FormInput disabled value={country} placeholder="Germany" />
                            </FormRow>
                        </FormGroup>
                    </Card>
                )}
                {signTnc && (
                    <Card>
                        <Title>Legal</Title>
                        <ListItem
                            title="Terms & Conditions"
                            onClick={() => history.push("/agreement")}
                            isFolder
                        />
                        <ListItem
                            title="Operating rules"
                            onClick={() =>
                                window.open("https://finerymarkets.com/legal.html#rules", "_blank")
                            }
                            isFolder
                        />
                        <ListItem
                            title="Privacy Policy"
                            onClick={() =>
                                window.open(
                                    "https://finerymarkets.com/legal.html#privacy",
                                    "_blank",
                                )
                            }
                            isFolder
                        />
                        <ListItem
                            title="Risk Disclosure"
                            onClick={() =>
                                window.open("https://finerymarkets.com/legal.html#risk", "_blank")
                            }
                            isFolder
                        />
                        <ListItem
                            title="Tariff Policy"
                            onClick={() =>
                                window.open("https://finerymarkets.com/tariffpolicy.html", "_blank")
                            }
                            isFolder
                        />
                    </Card>
                )}
            </CenteredContent>
            {signTnc && (
                <Bottom>
                    <ColoredButton
                        type="submit"
                        disabled={!isDataChanged || areActionsDisabled}
                        onClick={handleUpdateChangesClick}
                    >
                        Update changes
                    </ColoredButton>
                </Bottom>
            )}
        </>
    );
};

export const SettingsProfile = withSettingsLayout(SettingsProfileView);
