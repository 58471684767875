import { Listbox } from "@headlessui/react";
import React, { useState } from "react";
import styled, { DefaultTheme, StyledComponent } from "styled-components";
import { H3 } from "../../../typography";
import { SheetSize } from "../types";

const StyledSheet = styled.div`
    display: flex;
    flex-direction: column;
    background: ${(p) => p.theme.colors.uiWhite100};
    box-shadow: 0 4px 16px 0 ${(p) => p.theme.colors.ui8}, 0 0 0 1px ${(p) => p.theme.colors.ui12};
    outline: none;
    border-radius: 8px;
    max-height: 336px;

    overflow-x: hidden;
    overflow-y: auto;

    width: 336px;
`;
export const LargeSheet = styled(StyledSheet)`
    width: 336px;
`;

export const MediumSheet = styled(StyledSheet)`
    width: 264px;
`;

export const SmallSheet = styled(StyledSheet)`
    width: 192px;
`;

const SHEET_SIZE_MAP: Record<SheetSize, StyledComponent<"div", DefaultTheme>> = {
    large: LargeSheet,
    medium: MediumSheet,
    small: SmallSheet,
};
type SheetFnProps = {
    size: SheetSize;
    className?: string;

    children:
        | React.ReactNode
        | ((props: { query: string; normalizedQuery: string; onSearch: (query: string) => void }) => void);
};

export const SheetFn: React.FC<SheetFnProps> = ({ size, className, children }) => {
    const [query, setQuery] = useState("");

    return (
        <Listbox.Options data-dropdown-sheet as={SHEET_SIZE_MAP[size]} static className={className}>
            {typeof children === "function"
                ? children?.({ query, normalizedQuery: query.toLowerCase(), onSearch: setQuery })
                : children}
        </Listbox.Options>
    );
};

const SheetGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const SheetGroupCaption = styled.div`
    padding: 4px 8px;

    ${H3} {
        color: ${(p) => p.theme.colors.ui52};
    }
`;
const GroupsWrapper = styled.div`
    display: flex;
    width: 100%;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

type SheetGroupFnProps = {
    className?: string;
    caption?: (() => React.ReactNode) | string;
    columns?: number;
};
export const SheetGroupFn: React.FC<SheetGroupFnProps> = ({ className, caption, columns = 1, children }) => {
    const captionContent = typeof caption === "function" ? caption?.() : <H3>{caption}</H3>;

    const childrenElements = React.Children.toArray(children);
    const elementsInColumn = Math.round(childrenElements.length / columns);

    return (
        <SheetGroupContainer className={className}>
            {caption && <SheetGroupCaption>{captionContent}</SheetGroupCaption>}
            <GroupsWrapper>
                {Array.from({ length: columns }).map((_, index) => {
                    const start = index * elementsInColumn;
                    const end = elementsInColumn * (index + 1);

                    return <Wrapper key={`group_${index}`}>{childrenElements.slice(start, end)}</Wrapper>;
                })}
            </GroupsWrapper>
        </SheetGroupContainer>
    );
};
