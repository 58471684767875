import {
    FieldGroup,
    FIXED_MODAL_STYLE,
    Form,
    FormInfo,
    H2,
    Mark,
    Modal,
    PlainButton,
    PrimaryButton,
    SimpleInput,
    SwitchField,
    VStack,
} from "@fm-frontend/uikit";
import { DropdownOption, SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import {
    isCurrencyType,
    isNullOrUndefined,
    useFormCloseConfirmer,
    useModalControl,
    ValueFormat,
    ValueParse,
} from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { post } from "api";
import { CounterpartiesSheet } from "components/CounterpartiesSheet";
import { CounterpartyTriggerEssence } from "components/CounterpartiesTriggerEssence";
import { CurrencyDropdownSheet } from "components/CurrencySheet";
import { CurrencyTriggerEssence } from "components/CurrencyTriggerEssence";
import { DEFAULT_CP_NAME } from "const";
import { createNotification } from "feature/app";
import {
    AdjustType,
    AssetsGroupType,
    CpGroupType,
    CpGroupTypeEnum,
    DEFAULT_ASSETS_OPTION,
    isCpGroupType,
    ModalConfig,
} from "feature/assetsControl/types";
import { ASSET_CONTROL_MODAL_KEY } from "feature/assetsControl/utils";
import { useInstruments } from "hooks";
import { getActionsPermissions, useActionPermissions } from "hooks/useActionPermissions";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useLimitByAssetSWRKey } from "hooks/useExtentedLimitsByAsset";
import { useOvernightFees, useOvernightFeesSWRKey } from "hooks/useOvernightFees";
import { useShortSalesBan, useShortSalesBanSWRKey } from "hooks/useShortSalesBan";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType, useUserType } from "store/hooks";
import { useExtendedCurrencies } from "store/useExtentedCurrencies";
import styled from "styled-components";
import { useSWRConfig } from "swr";
import { Overnight } from "types";
import { formatOvernightFee, getFmtPrecisionConfig } from "utils";
import { fmt } from "utils/format";
import { useLimitsMap } from "../hooks";
import { LimitsSetupResultsModal } from "../LimitsSetupResultsModal";
import { schema } from "./schema";
import { convertToRequestBodies, UpdateLimitResponse, updateLimits } from "./utils";

const Error = styled.div`
    margin-top: auto;
    text-align: center;
    width: 100%;
    font-size: 14px;
    padding-bottom: 8px;
    color: ${(p) => p.theme.colors.negative100};
`;

const ALL_ASSETS_OPTIONS: DropdownOption<AssetsGroupType>[] = [
    { text: "All Assets", value: AssetsGroupType.All },
    { text: "All Crypto", value: AssetsGroupType.Crypto },
    { text: "All Stablecoins", value: AssetsGroupType.Stablecoin },
    { text: "All Fiat", value: AssetsGroupType.Fiat },
];

const ALL_BROKER_CP_OPTIONS: DropdownOption<CpGroupType>[] = [
    { text: "All counterparties", value: "externals" },
    { text: "All makers", value: "externalMakers" },
    { text: "All takers", value: "externalTakers" },
];
const ALL_BROKER_SUB_ACCOUNT_OPTIONS: DropdownOption<CpGroupType>[] = [
    { text: "All sub-accounts", value: "subaccounts" },
    { text: "All sub-makers", value: "subaccountMakers" },
    { text: "All sub-takers", value: "subaccountTakers" },
];

const ALL_GENERAL_OPTIONS: DropdownOption<CpGroupType>[] = [
    { text: "All counterparties", value: "all" },
];

const ALL_ADJUST_OPTION: DropdownOption<AdjustType>[] = [
    { text: "Trading limits", value: "tradingLimits" },
    { text: "Short sales", value: "shortSalesBan" },
    { text: "Overnight fees", value: "overnightFees" },
];

export interface AssetsModalInputs {
    assets: string | AssetsGroupType;
    cp: string | CpGroupType;
    adjust: AdjustType | null;
    shortSalesBan: boolean;
    limitPerAsset: boolean;
    limitCurrency: string | null;
    grossLimit: bigint | string;
    overnightFees: boolean;
    positiveRate: number | string;
    negativeRate: number | string;
}

type AssetsModalViewProps = {
    modalConfig: ModalConfig;
    counterparties: DropdownOption[];
    onSubmit: (adjust?: AdjustType) => void;
    onClose: () => void;
    onUpdate: (result: { response: UpdateLimitResponse[]; shouldAdd: boolean }) => Promise<void>;
};

const isCurrenciesGroupingType = (assets: string) => {
    return isCurrencyType(assets) || assets === AssetsGroupType.All;
};

const getBasicParams = ({ cp, assets }: AssetsModalInputs) => {
    const cpParam = isCpGroupType(cp)
        ? { counterpartyGroup: cp }
        : {
              counterpartyId: Number(cp),
          };
    const assetParam =
        isCurrencyType(assets) || assets === DEFAULT_ASSETS_OPTION
            ? {
                  currencyGroup: assets,
              }
            : {
                  currency: assets,
              };

    return {
        ...cpParam,
        ...assetParam,
    };
};

const useIsAdjustTypeAvailable = () => {
    const userType = useUserType();
    const { getUserActionPermissions } = useActionPermissions();

    const isAdjustTypeAvailable = useCallback(
        (
            adjust: AdjustType | undefined,
            {
                cp,
            }: {
                cp: CpGroupType | string;
                assets: AssetsGroupType | string;
            },
        ) => {
            if (!adjust) {
                return false;
            }

            const permissions = isCpGroupType(cp)
                ? getActionsPermissions(userType, cp as CpGroupTypeEnum) ?? {
                      isShortSalesBanAllowed: true,
                      isLimitPerAssetAllowed: true,
                      isOvernightsAllowed: true,
                  }
                : getUserActionPermissions(Number(cp));

            if (adjust === "tradingLimits") {
                return permissions.isLimitPerAssetAllowed;
            }
            if (adjust === "shortSalesBan") {
                return permissions.isShortSalesBanAllowed;
            }
            if (adjust === "overnightFees") {
                return permissions.isOvernightsAllowed;
            }

            return false;
        },
        [userType, getUserActionPermissions],
    );

    return {
        isAdjustTypeAvailable,
    };
};

function getLimitByAssetDefaultValue(
    grossLimit: bigint | null | undefined,
    assets: string,
    limitCurrency?: string | null,
) {
    const isLimitSet = limitCurrency !== null && limitCurrency !== undefined;

    return {
        limitPerAsset: isLimitSet,
        limitCurrency: isCurrenciesGroupingType(assets) ? null : assets,
        ...(isLimitSet && {
            grossLimit: fmt(grossLimit ?? 0n, {
                ...getFmtPrecisionConfig(limitCurrency ?? assets),
                type: "limit",
                showZero: true,
            }).copyableValue,
            limitCurrency: limitCurrency ?? (isCurrenciesGroupingType(assets) ? null : assets),
        }),
    };
}

const areAllOvernightsSame = (overnights: Overnight[]): boolean => {
    if (overnights.length === 0) {
        return true;
    }

    const { positiveRate: firstPositiveRate, negativeRate: firstNegativeRate } = overnights[0];

    return overnights.every(
        ({ positiveRate, negativeRate }) =>
            positiveRate === firstPositiveRate && negativeRate === firstNegativeRate,
    );
};

const useAssetsGroups = (cpId: number) => {
    const {
        groupedOvernightFees,
        isLoading: isOvernightFeesLoading,
        isValidating: isOvernightFeesValidating,
    } = useOvernightFees();
    const {
        shortSalesBans,
        isLoading: isShortSalesBanLoading,
        isValidating: isShortSalesBanValidating,
    } = useShortSalesBan();
    const {
        currencies,
        isLoading: isInstrumentsLoading,
        isValidating: isInstrumentsValidating,
    } = useInstruments();
    const {
        limitsMap,
        isLoading: isLimitsMapLoading,
        isValidating: isLimitsMapValidating,
    } = useLimitsMap();

    const isLoading =
        isOvernightFeesLoading ||
        isShortSalesBanLoading ||
        isInstrumentsLoading ||
        isLimitsMapLoading;
    const isValidating =
        isOvernightFeesValidating ||
        isShortSalesBanValidating ||
        isInstrumentsValidating ||
        isLimitsMapValidating;

    const cpOvernightFeesMap = useMemo(() => {
        const cpOvernightFees = groupedOvernightFees[cpId] ?? [];

        return new Map(cpOvernightFees.map((fee) => [fee.currency, fee]));
    }, [cpId, groupedOvernightFees]);
    const cpShortSalesBansSet = useMemo(
        () =>
            new Set(
                (shortSalesBans[cpId] ?? [])
                    .filter(({ isUserSetBan }) => isUserSetBan)
                    .map(({ asset }) => asset),
            ),
        [cpId, shortSalesBans],
    );

    const assetsGroups = useMemo(() => {
        const allAssetsGroupings = Object.values(AssetsGroupType);
        const allCurrencies = Object.values(currencies);
        const assets = [...allAssetsGroupings, ...allCurrencies.map(({ name }) => name)];

        return assets.reduce((acc, currency) => {
            const isAssetGroup = allAssetsGroupings.includes(currency as AssetsGroupType);
            const currenciesNames = isAssetGroup
                ? allCurrencies.reduce((acc, { currencyType, name }) => {
                      if (currency === AssetsGroupType.All || currencyType === currency) {
                          acc.push(name);
                      }

                      return acc;
                  }, [] as string[])
                : [currency];
            const shortSalesBan = currenciesNames.every((n) => cpShortSalesBansSet.has(n));
            const overnightFees = currenciesNames.reduce((fees, curr) => {
                const overnightFee = cpOvernightFeesMap.get(curr);
                if (overnightFee) {
                    fees.push(overnightFee);
                }
                return fees;
            }, [] as Overnight[]);

            const [negativeRate, positiveRate] =
                currenciesNames.length === overnightFees.length &&
                areAllOvernightsSame(overnightFees)
                    ? [overnightFees[0]?.negativeRate, overnightFees[0]?.positiveRate]
                    : [undefined, undefined];

            const assetLimit = limitsMap[cpId]?.[currency];
            acc[currency] = {
                negativeRate: negativeRate,
                positiveRate: positiveRate,
                shortSalesBan: shortSalesBan,
                assetLimit:
                    assetLimit?.grossLimit === undefined || assetLimit?.grossLimit === null
                        ? undefined
                        : {
                              grossLimit: assetLimit.grossLimit,
                              limitCurrency: assetLimit.limitCurrency,
                          },
            };

            return acc;
        }, {} as Record<string, { negativeRate: number | undefined; positiveRate: number | undefined; shortSalesBan: boolean; assetLimit?: { grossLimit: bigint | null; limitCurrency: string | null } }>);
    }, [cpOvernightFeesMap, cpShortSalesBansSet, currencies, limitsMap, cpId]);

    return {
        assetsGroups,
        isLoading,
        isValidating,
    };
};

const AssetsModalView: React.FC<AssetsModalViewProps> = ({
    counterparties,
    modalConfig,
    onSubmit,
    onClose,
    onUpdate,
}) => {
    const dispatch = useDispatch();
    const { limitsMap } = useLimitsMap();
    const { getCpType } = useCpInfoHelpers();
    const { currencyGroups } = useExtendedCurrencies();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const limitByAssetSWRKey = useLimitByAssetSWRKey();
    const overnightFeesSWRKey = useOvernightFeesSWRKey();
    const shortSalesBanSWRKey = useShortSalesBanSWRKey();
    const { mutate } = useSWRConfig();
    const { isAdjustTypeAvailable } = useIsAdjustTypeAvailable();

    const [apiError, setApiError] = useState<string | null>(null);

    const {
        cp: cpConfig,
        cpName,
        showCpSelector,
        assets,
        limitCurrency,
        adjust,
        grossLimit,
    } = modalConfig;

    const { currencies } = useInstruments();
    const currenciesOptions = useMemo(
        () =>
            Object.keys(currencies).map(
                (currencyId) =>
                    ({
                        text: currencyId,
                        value: currencyId,
                    } as DropdownOption),
            ),
        [currencies],
    );

    const cp: CpGroupType | string = useMemo(() => {
        if (cpConfig) {
            return cpConfig;
        }

        if (isPrimeBrokerUser && primeBrokerViewType === "counterparties") {
            return "externals" as CpGroupType;
        }
        if (isPrimeBrokerUser && primeBrokerViewType === "subaccounts") {
            return "subaccounts" as CpGroupType;
        }

        return "all";
    }, [cpConfig, isPrimeBrokerUser, primeBrokerViewType]);

    const cpOptionsGroups = useMemo(() => {
        if (isPrimeBrokerUser && primeBrokerViewType === "counterparties") {
            return {
                grouping: ALL_BROKER_CP_OPTIONS,
                other: counterparties,
            };
        }
        if (isPrimeBrokerUser && primeBrokerViewType === "subaccounts") {
            return {
                grouping: ALL_BROKER_SUB_ACCOUNT_OPTIONS,
                other: counterparties,
            };
        }

        return {
            grouping: ALL_GENERAL_OPTIONS,
            other: counterparties,
        };
    }, [counterparties]);

    const {
        control,
        formState: { isSubmitting, isDirty, errors },
        watch,
        handleSubmit,
        register,
        setValue,
        setError,
    } = useForm<AssetsModalInputs>({
        mode: "onSubmit",
        defaultValues: {
            assets,
            adjust: adjust ?? null,
            cp: cp,
            ...getLimitByAssetDefaultValue(grossLimit, assets, limitCurrency),
        },
        resolver: yupResolver(schema),
    });
    const [adjustWatch, cpWatch, assetsWatch, limitPerAssetWatch, overnightFeesWatch] = watch([
        "adjust",
        "cp",
        "assets",
        "limitPerAsset",
        "overnightFees",
    ]);

    const adjustOptions = useMemo(() => {
        const adjustTypeAvailabilityConfig = {
            cp: cpWatch,
            assets: assetsWatch,
        };
        const availableOptions: DropdownOption<AdjustType>[] = [];

        ALL_ADJUST_OPTION.forEach((option) => {
            if (isAdjustTypeAvailable(option.value, adjustTypeAvailabilityConfig)) {
                availableOptions.push(option);
            }
        });

        return availableOptions;
    }, [cpWatch, assetsWatch, isAdjustTypeAvailable]);

    const hasAvailableAdjustOptions = Boolean(adjustOptions.length > 0);

    useEffect(() => {
        setValue("adjust", adjustOptions[0]?.value ?? null, {
            shouldValidate: true,
        });
    }, [adjustOptions]);

    const { assetsGroups, isLoading, isValidating } = useAssetsGroups(Number(cpWatch));
    const isPreparing = isLoading || isValidating;

    useFormCloseConfirmer(ASSET_CONTROL_MODAL_KEY, isDirty);

    useEffect(() => {
        if (adjustWatch === "overnightFees") {
            const positiveRateValue = formatOvernightFee(assetsGroups[assetsWatch]?.positiveRate);
            const negativeRateValue = formatOvernightFee(assetsGroups[assetsWatch]?.negativeRate);

            if (positiveRateValue) {
                setValue("positiveRate", positiveRateValue.copyableValue);
            }
            if (negativeRateValue) {
                setValue("negativeRate", negativeRateValue.copyableValue);
            }

            setValue("overnightFees", Boolean(positiveRateValue || negativeRateValue));
        }
        if (adjustWatch === "shortSalesBan") {
            const isShortSalesBan = assetsGroups[assetsWatch]?.shortSalesBan;

            setValue("shortSalesBan", isShortSalesBan);
        }
        if (adjustWatch === "tradingLimits") {
            const assetLimit = assetsGroups[assetsWatch]?.assetLimit;

            if (assetLimit) {
                setValue(
                    "grossLimit",
                    fmt(assetLimit!.grossLimit! ?? 0n, {
                        ...getFmtPrecisionConfig(limitCurrency ?? assets),
                        type: "limit",
                        showZero: true,
                    }).copyableValue,
                );
                setValue("limitCurrency", assetLimit!.limitCurrency);
                setValue("limitPerAsset", true);
            }
        }
    }, [adjustWatch, assetsWatch, assetsGroups]);

    const modalTitle = useMemo(() => {
        const isNotCurrencyType = !isCurrencyType(assets) && assets !== DEFAULT_ASSETS_OPTION;

        if (isNotCurrencyType && !isCpGroupType(cp) && cpName) {
            let name = cpName;

            if (cpName === DEFAULT_CP_NAME) {
                name = `${cpName} ${cp}`;
            }

            return (
                <>
                    Configure <Mark weight={500}>{assets}</Mark> for {name}
                </>
            );
        }
        if (isNotCurrencyType && isCpGroupType(cp)) {
            return (
                <>
                    Configure <Mark weight={500}>{assets}</Mark> for all CPs
                </>
            );
        }

        return "Assets control";
    }, [assets, cp, cpName]);

    const handleLimitPerAssetUpdate = async (inputs: AssetsModalInputs) => {
        let requestBodies = convertToRequestBodies(
            inputs,
            counterparties,
            currencyGroups,
            getCpType,
        );

        if (!inputs.limitPerAsset) {
            requestBodies = requestBodies.filter(
                ({ counterpartyId, asset }) => limitsMap[counterpartyId]?.[asset]?.limitCurrency,
            );
        }

        if (requestBodies.length === 0) {
            return true;
        }

        const result = await updateLimits(requestBodies, inputs.limitPerAsset);
        onUpdate(result);
        await mutate(limitByAssetSWRKey);
        return false;
    };

    const handleOvernightFeesUpdate = async (inputs: AssetsModalInputs) => {
        const prepareOvernightValue = (value: number | string) =>
            Math.trunc(Number(value + "e2")) * 100;

        const { positiveRate, negativeRate, overnightFees } = inputs;
        const apiMethod = "settings/setOvernightRates";
        const basicParams = getBasicParams(inputs);

        let overnightFeesParam = {};

        if (!isNullOrUndefined(negativeRate) && overnightFees) {
            overnightFeesParam = {
                ...overnightFeesParam,
                negativeRate: prepareOvernightValue(negativeRate),
            };
        }
        if (!isNullOrUndefined(positiveRate) && overnightFees) {
            overnightFeesParam = {
                ...overnightFeesParam,
                positiveRate: prepareOvernightValue(positiveRate),
            };
        }

        await post(apiMethod, {
            ...basicParams,
            ...overnightFeesParam,
        });
        await mutate(overnightFeesSWRKey);
    };

    const handleShortSalesUpdate = async (inputs: AssetsModalInputs) => {
        const { shortSalesBan } = inputs;
        const apiMethod = shortSalesBan ? "setShortSalesBan" : "delShortSalesBan";
        const basicParams = getBasicParams(inputs);
        await post(apiMethod, {
            ...basicParams,
        });
        await mutate(shortSalesBanSWRKey);
    };

    const validateGrossLimitOnBalanceStep = (inputs: AssetsModalInputs) => {
        if (!inputs.limitPerAsset) {
            return true;
        }

        const { balanceStep } = currencies[inputs.limitCurrency ?? ""];
        const isInvalidGrossLimit = Boolean(
            ValueParse.size(String(inputs.grossLimit)) % BigInt(balanceStep),
        );

        if (isInvalidGrossLimit) {
            setError(
                "grossLimit",
                {
                    type: "custom",
                    message: `Balance step of ${inputs.limitCurrency} is ${ValueFormat.size(
                        balanceStep,
                    )}`,
                },
                { shouldFocus: true },
            );
        }

        return !isInvalidGrossLimit;
    };

    const submitHandler = async (data: AssetsModalInputs) => {
        try {
            setApiError(null);

            if (adjustWatch === "tradingLimits") {
                if (!validateGrossLimitOnBalanceStep(data)) {
                    return;
                }

                const shouldSkipSuccessNotification = await handleLimitPerAssetUpdate(data);
                if (shouldSkipSuccessNotification) {
                    onSubmit();
                    return;
                }
            }
            if (adjustWatch === "shortSalesBan") {
                await handleShortSalesUpdate(data);
            }
            if (adjustWatch === "overnightFees") {
                await handleOvernightFeesUpdate(data);
            }

            dispatch(
                createNotification({
                    type: "success",
                    content: "Success",
                }),
            );
            onSubmit(data.adjust === null ? undefined : data.adjust);
        } catch (err) {
            setApiError(String(err));
            dispatch(
                createNotification({
                    type: "error",
                    content: String(err),
                }),
            );
        }
    };

    return (
        <Form onSubmit={handleSubmit(submitHandler)}>
            <VStack minWidth="360px" asCard>
                <VStack padding={12}>
                    <H2>{modalTitle}</H2>
                </VStack>
                <FieldGroup direction="column" paddingX={12} paddingBottom={8}>
                    {Boolean(isCurrencyType(assets) || assets === DEFAULT_ASSETS_OPTION) && (
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <SingleDropdown
                                    value={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <SingleDropdown.Trigger
                                            {...trigger}
                                            size="large"
                                            variant="simple"
                                        >
                                            <CurrencyTriggerEssence
                                                {...trigger}
                                                noIcon={ALL_ASSETS_OPTIONS.includes(
                                                    trigger.selectedOption as DropdownOption<AssetsGroupType>,
                                                )}
                                                option={trigger.selectedOption}
                                                size="large"
                                            />
                                        </SingleDropdown.Trigger>
                                    )}
                                    options={[...ALL_ASSETS_OPTIONS, ...currenciesOptions]}
                                    caption="Assets"
                                    placeholder="Placeholder"
                                    align="end"
                                    fullWidth
                                >
                                    <CurrencyDropdownSheet
                                        size="large"
                                        options={currenciesOptions}
                                        groupingOptions={ALL_ASSETS_OPTIONS}
                                        Dropdown={SingleDropdown}
                                    />
                                </SingleDropdown>
                            )}
                            name="assets"
                        />
                    )}
                    {(isCpGroupType(cp) || showCpSelector) && (
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <SingleDropdown
                                    value={field.value}
                                    onChange={field.onChange}
                                    options={[
                                        ...cpOptionsGroups.grouping,
                                        ...cpOptionsGroups.other,
                                    ]}
                                    renderTrigger={(trigger) => (
                                        <SingleDropdown.Trigger
                                            {...trigger}
                                            size="large"
                                            variant="simple"
                                        >
                                            <CounterpartyTriggerEssence
                                                {...trigger}
                                                groupingOption={
                                                    trigger.selectedOption &&
                                                    cpOptionsGroups.grouping.includes(
                                                        trigger.selectedOption as DropdownOption<CpGroupType>,
                                                    )
                                                }
                                                size="large"
                                                option={trigger.selectedOption}
                                            />
                                        </SingleDropdown.Trigger>
                                    )}
                                    caption="Counterparties"
                                    align="end"
                                    fullWidth
                                >
                                    <CounterpartiesSheet
                                        size="large"
                                        options={cpOptionsGroups.other}
                                        groupingOptions={cpOptionsGroups.grouping}
                                        Dropdown={SingleDropdown}
                                    />
                                </SingleDropdown>
                            )}
                            name="cp"
                        />
                    )}

                    {hasAvailableAdjustOptions && (
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <SingleDropdown
                                    value={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <SingleDropdown.Trigger
                                            {...trigger}
                                            size="large"
                                            variant="simple"
                                        >
                                            <SingleDropdown.TriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOption}
                                                size="large"
                                            />
                                        </SingleDropdown.Trigger>
                                    )}
                                    options={adjustOptions}
                                    placeholder="Placeholder"
                                    caption="Adjust"
                                    align="end"
                                    fullWidth
                                    error={errors.adjust?.message}
                                >
                                    <SingleDropdown.BasicSheet
                                        size="large"
                                        options={adjustOptions}
                                    />
                                </SingleDropdown>
                            )}
                            name="adjust"
                        />
                    )}
                </FieldGroup>

                {!hasAvailableAdjustOptions && (
                    <VStack padding={12}>
                        <FormInfo>No available adjust options for currency selection</FormInfo>
                    </VStack>
                )}

                {hasAvailableAdjustOptions && (
                    <>
                        {adjustWatch === "shortSalesBan" && (
                            <VStack paddingX={12} paddingBottom={16}>
                                <SwitchField
                                    variant="simple"
                                    fullWidth
                                    text="Short sales ban"
                                    control={control}
                                    {...register("shortSalesBan")}
                                />
                            </VStack>
                        )}
                        {adjustWatch === "tradingLimits" && (
                            <FieldGroup direction="column" paddingX={12} paddingBottom={8}>
                                <SwitchField
                                    variant="simple"
                                    fullWidth
                                    text="Limit per asset"
                                    disabled={isPreparing}
                                    control={control}
                                    {...register("limitPerAsset")}
                                />
                                {limitPerAssetWatch && (
                                    <>
                                        <Controller
                                            control={control}
                                            render={({ field }) => (
                                                <SingleDropdown
                                                    disabled={isPreparing}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    renderTrigger={(trigger) => (
                                                        <SingleDropdown.Trigger
                                                            {...trigger}
                                                            size="large"
                                                            variant="simple"
                                                        >
                                                            <CurrencyTriggerEssence
                                                                {...trigger}
                                                                option={trigger.selectedOption}
                                                                size="large"
                                                            />
                                                        </SingleDropdown.Trigger>
                                                    )}
                                                    options={currenciesOptions}
                                                    caption="Limit currency"
                                                    placeholder="Select"
                                                    fullWidth
                                                    error={errors.limitCurrency?.message}
                                                >
                                                    <CurrencyDropdownSheet
                                                        size="large"
                                                        options={currenciesOptions}
                                                        Dropdown={SingleDropdown}
                                                    />
                                                </SingleDropdown>
                                            )}
                                            name="limitCurrency"
                                        />
                                        <SimpleInput
                                            label="Amount"
                                            placeholder="0.00"
                                            disabled={isPreparing}
                                            {...register("grossLimit")}
                                            error={errors.grossLimit?.message}
                                        />
                                    </>
                                )}
                            </FieldGroup>
                        )}
                        {adjustWatch === "overnightFees" && (
                            <FieldGroup direction="column" paddingX={12} paddingBottom={8}>
                                <SwitchField
                                    variant="simple"
                                    fullWidth
                                    text="Overnight fee"
                                    control={control}
                                    {...register("overnightFees")}
                                />
                                {overnightFeesWatch && (
                                    <>
                                        <SimpleInput
                                            label="Long, %"
                                            placeholder="0.00"
                                            {...register("positiveRate")}
                                            error={errors.positiveRate?.message}
                                        />
                                        <SimpleInput
                                            label="Shorts, %"
                                            placeholder="0.00"
                                            {...register("negativeRate")}
                                            error={errors.negativeRate?.message}
                                        />
                                    </>
                                )}
                            </FieldGroup>
                        )}
                    </>
                )}

                {apiError && <Error>{apiError}</Error>}
                <VStack paddingX={12} paddingBottom={16} spacing={8}>
                    <PrimaryButton
                        fullWidth
                        size="large"
                        type="submit"
                        disabled={isPreparing}
                        loading={isSubmitting}
                    >
                        Apply
                    </PrimaryButton>
                    <PlainButton fullWidth size="large" type="button" onClick={() => onClose()}>
                        Cancel
                    </PlainButton>
                </VStack>
            </VStack>
        </Form>
    );
};

type AssetModalProps = {
    modalConfig: ModalConfig;
    counterparties: DropdownOption[];
    isOpened: boolean;
    onSubmit: (adjust?: AdjustType) => void;
    onClose: () => void;
};

export const AssetsModal: React.FC<AssetModalProps> = ({
    counterparties,
    modalConfig,
    isOpened,
    onSubmit,
    onClose,
}) => {
    const [limitsResult, setLimitsResult] = useState<{
        response: UpdateLimitResponse[];
        shouldAdd: boolean;
    }>({ response: [], shouldAdd: false });
    const {
        closeModal: closeUpdateLimitsResult,
        isModalOpen: isUpdateLimitsResultOpened,
        openModal: openUpdateLimitsResultModal,
    } = useModalControl();
    const handleUpdateLimitsResult = async (result: {
        response: UpdateLimitResponse[];
        shouldAdd: boolean;
    }) => {
        if (result.response.some(({ errorCode }) => errorCode !== undefined)) {
            setLimitsResult(result);
            openUpdateLimitsResultModal();
        }
    };

    return (
        <>
            <Modal isOpen={isOpened} onClose={onClose} style={FIXED_MODAL_STYLE}>
                <AssetsModalView
                    onSubmit={onSubmit}
                    onClose={onClose}
                    counterparties={counterparties}
                    modalConfig={modalConfig}
                    onUpdate={handleUpdateLimitsResult}
                />
            </Modal>
            <Modal
                isOpen={isUpdateLimitsResultOpened}
                onClose={closeUpdateLimitsResult}
                style={FIXED_MODAL_STYLE}
            >
                <LimitsSetupResultsModal
                    onClose={closeUpdateLimitsResult}
                    result={limitsResult}
                    onUpdate={handleUpdateLimitsResult}
                />
            </Modal>
        </>
    );
};
