import {
    BasicInput,
    Form,
    FormActions,
    FormAttention,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    LinkButton,
    P,
    PrimaryButton,
    ShadowCard,
} from "@fm-frontend/uikit";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Copyright } from "components/Copyright";
import { IconError } from "components/icons";
import { LinkResetPassword, LinkSupport } from "components/Links";
import { useSelector } from "hooks";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { signIn } from "store/auth/actions";
import { authenticationSlice } from "store/auth/slice";
import { BottomLogin } from "../style";
import { SignInInputs, signInSchema } from "./schema";

export const SignIn: FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const authError = useSelector((state) => state.authentication.error);
    const authLoading = useSelector((state) => state.authentication.loading);

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
    } = useForm<SignInInputs>({
        resolver: yupResolver(signInSchema),
    });

    useEffect(() => {
        dispatch(authenticationSlice.actions.resetError());
    }, []);

    const handleSignInSubmit = handleSubmit(async (signInInputs) => {
        // @ts-ignore
        await dispatch(signIn(signInInputs));
    });

    return (
        <>
            <Form onSubmit={handleSignInSubmit}>
                <ShadowCard>
                    <FormHeader>
                        <HeaderTitle title="Log In" />
                    </FormHeader>

                    <FormBody>
                        <BasicInput
                            label="Email"
                            placeholder="name@company.com"
                            {...register("email")}
                            error={errors.email?.message}
                        />
                        <BasicInput
                            type="password"
                            label="Password"
                            placeholder="Password"
                            {...register("password")}
                            error={errors.password?.message}
                        />
                        {authError && (
                            <FormAttention>
                                <IconError />
                                <P>
                                    {authError.message}{" "}
                                    <LinkButton
                                        onClick={() => history.push("/resetpassword")}
                                        type="button"
                                        size="small"
                                    >
                                        Forgot password?
                                    </LinkButton>
                                </P>
                            </FormAttention>
                        )}
                    </FormBody>
                    <FormActions variant="plain">
                        <PrimaryButton
                            type="submit"
                            fullWidth
                            size="large"
                            loading={isSubmitting || authLoading}
                        >
                            Login
                        </PrimaryButton>
                    </FormActions>
                </ShadowCard>
            </Form>
            <BottomLogin>
                <LinkSupport />
                <LinkResetPassword />
                <span>
                    <Copyright />
                </span>
            </BottomLogin>
        </>
    );
};
