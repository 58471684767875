import { ClientId } from "@fm-frontend/uikit";
import { Container, Wrapper } from "@fm-frontend/uikit/src/components/badges/Badge";
import { FC, useMemo } from "react";
import styled from "styled-components";
import { ClientType } from "types";
import { POSITION_COUNTERPARTY_ID } from "../../../const/position";
import { useCpInfoHelpers } from "../../../hooks/useCpInfoHelpers";
import { useFilteredAndExtendedCounterpartyLimitsSnapshot, useUsdPrices } from "../../../store/hooks";
import { getFmtPrecisionConfig } from "../../../utils";
import { fmt } from "../../../utils/format";
import { FMT_LIMIT_CONFIG, getAppliedLimit } from "../counterparty/utils";
import { LimitType } from "../types";
import { isSubaccountCp } from "../utils";

const NotificationContainer = styled.div`
    ${Container} {
        display: inline;

        ${Wrapper} {
            background-color: ${(p) => p.theme.colors.uiWhite8};
            border: 1px solid ${(p) => p.theme.colors.uiWhite32};

            * {
                color: ${(p) => p.theme.colors.uiWhite72};
            }
        }
    }
`;

type NewLimitNotificationMessageProps = {
    grossLimit: number;
    currency: string;
    cpId: number;
    cpType: ClientType | undefined;
};
const NewLimitNotificationMessage: FC<NewLimitNotificationMessageProps> = ({ grossLimit, currency, cpId, cpType }) => {
    const isSubaccount = isSubaccountCp(cpType);
    const { data: counterpartyLimits, isLoading: isSnapshotLoading } =
        useFilteredAndExtendedCounterpartyLimitsSnapshot();
    const { priceObj, isLoading: isPriceObjLoading } = useUsdPrices();
    const { getCpName } = useCpInfoHelpers();
    const cpName = getCpName(cpId, "full");

    const { limit } = counterpartyLimits.find((cpLimit) => cpLimit.limit[POSITION_COUNTERPARTY_ID] === cpId) ?? {};
    const { appliedLimitType } = useMemo(() => {
        if (limit) {
            return getAppliedLimit(limit, priceObj);
        }

        return { appliedLimitType: null };
    }, [limit, priceObj]);
    const isBothLimitsSet = limit?.[2] !== null && limit?.[4] !== null;

    if (isSnapshotLoading || isPriceObjLoading) {
        return null;
    }

    const cpTypeLabel = isSubaccount ? "SA" : "CP";

    if (isBothLimitsSet) {
        const formattedCurrency = fmt(grossLimit, {
            ...FMT_LIMIT_CONFIG,
            ...getFmtPrecisionConfig(currency),
            unit: ` ${currency}`,
            unitPosition: "post",
        });

        if (appliedLimitType === LimitType.UserLimit) {
            return (
                <>
                    The applied {cpTypeLabel} gross limit is {formattedCurrency.formattedValue} based on your limit
                </>
            );
        }

        return (
            <>
                The applied {cpTypeLabel} gross limit is {formattedCurrency.formattedValue} based on {cpName}’s limit
            </>
        );
    }

    return (
        <NotificationContainer>
            When Counterparty <ClientId id={cpId} /> sets its {cpTypeLabel} gross limit, the lowest limit will be
            applied
        </NotificationContainer>
    );
};

type NotificationMessageProps = {
    isNewLimit: boolean;
    grossLimit: number;
    currency: string;
    cpId: number;
    cpType: ClientType | undefined;
};
export const NotificationMessage: FC<NotificationMessageProps> = ({
    isNewLimit,
    grossLimit,
    currency,
    cpId,
    cpType,
}) => {
    if (isNewLimit) {
        return <NewLimitNotificationMessage grossLimit={grossLimit} currency={currency} cpId={cpId} cpType={cpType} />;
    }

    return <>Limit has been changed</>;
};
