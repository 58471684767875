import {
    DropdownOption,
    MultipleDropdown,
    OPTIONS_SEARCH_COUNT,
    SheetSize,
    SingleDropdown,
} from "@fm-frontend/uikit/src/components/v2/Dropdown";
import React from "react";
import { CounterpartiesOptionEssence } from "../CounterpartiesOptionEssence";

type DropdownCounterpartiesOption = DropdownOption<number | string>;
type CounterpartiesSheetProps = {
    size: SheetSize;
    options: DropdownCounterpartiesOption[];
    groupingOptions?: DropdownCounterpartiesOption[];
    Dropdown: typeof SingleDropdown | typeof MultipleDropdown;
    renderActions?: () => React.ReactNode;
};
export const CounterpartiesSheet: React.FC<CounterpartiesSheetProps> = ({
    size,
    options,
    groupingOptions = [],
    Dropdown,
    renderActions,
}) => {
    return (
        <Dropdown.Sheet size={size}>
            {({ query, normalizedQuery, onSearch }) => (
                <>
                    {options.length > OPTIONS_SEARCH_COUNT && (
                        <Dropdown.Search query={query} onSearch={onSearch} />
                    )}
                    {!query && (
                        <Dropdown.EmptySearch>
                            {renderActions?.()}
                            {renderActions && <Dropdown.Divider />}
                            {groupingOptions.map((option) => (
                                <Dropdown.Option key={`${option.value}`} value={option.value}>
                                    <CounterpartiesOptionEssence
                                        groupingOption
                                        option={option}
                                        query={query}
                                    />
                                </Dropdown.Option>
                            ))}
                            {Boolean(groupingOptions.length && options.length) && (
                                <Dropdown.Divider />
                            )}
                            {options.map((option) => (
                                <Dropdown.Option key={`${option.value}`} value={option.value}>
                                    <CounterpartiesOptionEssence option={option} query={query} />
                                </Dropdown.Option>
                            ))}
                        </Dropdown.EmptySearch>
                    )}
                    {query && (
                        <Dropdown.EmptySearch>
                            {options
                                .filter(
                                    (opt) =>
                                        opt.text?.toLowerCase().includes(normalizedQuery) ||
                                        `${opt.value}`?.toLowerCase().includes(normalizedQuery),
                                )
                                .map((option) => (
                                    <Dropdown.Option key={`${option.value}`} value={option.value}>
                                        <CounterpartiesOptionEssence
                                            option={option}
                                            query={query}
                                        />
                                    </Dropdown.Option>
                                ))}
                        </Dropdown.EmptySearch>
                    )}
                </>
            )}
        </Dropdown.Sheet>
    );
};
