import { TopMenu } from "components/TopMenu";
import { SubaccountTransactionsPageContent } from "feature/specificSubaccountAndPBPagesContents";
import React from "react";
import { Page } from "style";

export const TransactionsPage: React.FC = () => {
    return (
        <Page noBottomSpace>
            <TopMenu>Transactions</TopMenu>
            <SubaccountTransactionsPageContent />
        </Page>
    );
};
