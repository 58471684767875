import { VStack } from "@fm-frontend/uikit";
import { AssetInfo } from "components/AssetInfo";
import { AmountInfo } from "../AmountInfo";
import { Statistic } from "../Statistic";
import { WithdrawalRequest } from "./types";

export const WithdrawalRequestInfo = ({ withdrawalRequest }: { withdrawalRequest: WithdrawalRequest }) => (
    <VStack spacing={12} padding={12} width="100%" asCard>
        <Statistic
            title="Asset"
            value={<AssetInfo asset={withdrawalRequest.asset} network={withdrawalRequest.network} />}
        />
        <AmountInfo amount={withdrawalRequest.amount} />
        <Statistic title="Fee" value={withdrawalRequest.isFeePaidByRecipient ? "by Recipient" : "by Sender"} />
        {withdrawalRequest.comment && <Statistic title="Comment" value={withdrawalRequest.comment} />}
    </VStack>
);
