import { H3Bold } from "@fm-frontend/uikit";
import styled, { useTheme } from "styled-components";
import { HeaderLIBold, Li, PrimaryOL } from "./NumberedList.style";

/**
 * Like <ol>, but allows to set numbering as requred in designs
 */
const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    gap: 12px;
`;

export const NumberedList: React.FC<{
    children: React.ReactNode[];
    start: number;
    header?: React.ReactNode;
}> = ({ children, start, header }) => {
    const { colors } = useTheme();

    return (
        <Container>
            {header ? (
                <PrimaryOL start={start}>
                    <HeaderLIBold>
                        <H3Bold color={colors.ui100}>{header}</H3Bold>
                    </HeaderLIBold>
                </PrimaryOL>
            ) : null}

            <PrimaryOL>
                <Li>
                    <ol>
                        {children.map((text, index) => {
                            return (
                                <Li content={`${start}.${index + 1}`} key={index}>
                                    {text}
                                </Li>
                            );
                        })}
                    </ol>
                </Li>
            </PrimaryOL>
        </Container>
    );
};
