import { SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { useInstruments } from "hooks";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useNetworksOptions } from "../../../../hooks";
import { CryptoAddressFormInput } from "../../../../types";
import { NETWORKS_OPTION_SHEET_INFO } from "../../../../utils";
import { CryptoFormContext } from "../../CryptoFormContext";

type NetworkControlProps = {
    index: number;
    rowId: string;
    disabled: boolean;
};

export const NetworkControl: React.VFC<NetworkControlProps> = ({ index, rowId, disabled }) => {
    const {
        control,
        formState: { errors, defaultValues },
        watch,
        setValue,
    } = useFormContext<CryptoAddressFormInput>();

    const { currencyIds } = useInstruments();

    const networkControlName = `addresses.${index}.network.id` as const;
    const currencyId = watch(`addresses.${index}.currency.id` as const);
    const updatedCurrencyId = useRef<number | null | undefined>(currencyId);

    const networksOptions = useNetworksOptions({
        availableNetworks: currencyIds?.[currencyId]?.networks ?? [],
    });

    useEffect(() => {
        if (currencyId && updatedCurrencyId.current !== currencyId) {
            updatedCurrencyId.current = currencyId;

            const singleOption = networksOptions.length === 1;
            const valueToSet = singleOption ? networksOptions[0].value : null;
            const shouldValidate = singleOption;
            const shouldDirty = singleOption;

            setValue(networkControlName, valueToSet, {
                shouldValidate,
                shouldDirty,
            });
        }
    }, [networksOptions, setValue, currencyId]);

    const { updateDirtyRow } = useContext(CryptoFormContext);
    const handleChange = useCallback(
        (value) => {
            const defaultValue = defaultValues?.addresses?.[index]?.network?.id;
            updateDirtyRow(rowId, "network.id", defaultValue !== value);
        },
        [updateDirtyRow, rowId],
    );

    return (
        <Controller
            control={control}
            render={({ field }) => (
                <SingleDropdown
                    value={field.value}
                    onChange={(value) => {
                        field.onChange(value);
                        handleChange(value);
                    }}
                    renderTrigger={(trigger) => (
                        <SingleDropdown.Trigger {...trigger} size="medium" variant="minimum">
                            <SingleDropdown.TriggerEssence {...trigger} option={trigger.selectedOption} size="small" />
                        </SingleDropdown.Trigger>
                    )}
                    options={networksOptions}
                    placeholder="Network"
                    disabled={networksOptions.length === 0 || disabled}
                    error={errors?.addresses?.[index]?.network?.id?.message}
                    fullWidth
                >
                    <>
                        <SingleDropdown.BasicSheet size="medium" options={networksOptions} />
                        {networksOptions.length > 1 && (
                            <SingleDropdown.SheetInfoBox $size="medium">
                                {NETWORKS_OPTION_SHEET_INFO}
                            </SingleDropdown.SheetInfoBox>
                        )}
                    </>
                </SingleDropdown>
            )}
            name={networkControlName}
        />
    );
};
