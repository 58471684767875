import { TopMenu } from "components/TopMenu";
import { SubaccountRequestsPageContent } from "feature/specificSubaccountAndPBPagesContents";
import React from "react";
import { Page } from "style";

export const RequestsPage: React.FC = () => {
    return (
        <Page>
            <TopMenu>Requests</TopMenu>
            <SubaccountRequestsPageContent />
        </Page>
    );
};
