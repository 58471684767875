import { post } from "api";
import { useMemo } from "react";
import useSWRImmutable from "swr/immutable";

type RestrictedRfqProviderInfo = {
    id: number;
    instruments: string[];
};
type FullRfqProviderInfo = RestrictedRfqProviderInfo & {
    clientId: number;
    clientName: string;
    adapterType: unknown;
};

type RfqProvidersApiResponse = FullRfqProviderInfo[] | RestrictedRfqProviderInfo[];

const isFullRfqProviderInfo = (
    providerInfos: RfqProvidersApiResponse,
): providerInfos is FullRfqProviderInfo[] => {
    return "clientId" in (providerInfos[0] ?? {});
};

type RfqProviders = Map<number, { name: string; providerId: number }>;
type RfqInstruments = Record<string, number[]>;

export const useRfqProvidersAndInstruments = () => {
    const {
        data: providers,
        isLoading,
        error,
        mutate,
        isValidating,
    } = useSWRImmutable<RfqProvidersApiResponse>("rfq/getProviders", post);

    const rfqProviders = useMemo<RfqProviders>(() => {
        if (providers === undefined) {
            return new Map();
        }

        if (isFullRfqProviderInfo(providers)) {
            return providers.reduce<RfqProviders>((acc, providerInfo) => {
                const { clientId, id, clientName } = providerInfo;
                acc.set(clientId, { name: clientName, providerId: id });
                return acc;
            }, new Map());
        }

        return providers.reduce<RfqProviders>((acc, { id }) => {
            acc.set(id, { name: "", providerId: id });
            return acc;
        }, new Map());
    }, [providers]);

    const rfqInstruments = useMemo<RfqInstruments>(() => {
        if (providers === undefined) {
            return {};
        }

        if (isFullRfqProviderInfo(providers)) {
            return providers.reduce<RfqInstruments>((acc, providerInfo) => {
                const { instruments, clientId } = providerInfo;

                for (const instrumentName of instruments) {
                    if (!Object.hasOwn(acc, instrumentName)) {
                        acc[instrumentName] = [clientId];
                        continue;
                    }
                    acc[instrumentName].push(clientId);
                }

                return acc;
            }, {});
        }

        return providers.reduce<RfqInstruments>((acc, providerInfo) => {
            const { instruments, id } = providerInfo;

            for (const instrumentName of instruments) {
                if (!Object.hasOwn(acc, instrumentName)) {
                    acc[instrumentName] = [id];
                    continue;
                }
                acc[instrumentName].push(id);
            }

            return acc;
        }, {});
    }, [providers]);

    return {
        rfqProviders,
        rfqInstruments,
        isLoading,
        isValidating,
        mutate,
        error,
    };
};
