import { P } from "@fm-frontend/uikit";
import { EmDash } from "const";
import React from "react";
import { fmt, FormatConfig } from "utils/format";
import { TradeHistory } from "../../../types";
import { CopyCell } from "../CopyCell";

type SizeProps = Pick<TradeHistory, "size">;

export const sizeCellFormatConfig: FormatConfig = {
    significantDigits: 8,
    type: "size",
    showZero: true,
};

export const SizeCell: React.FC<SizeProps> = ({ size }) => {
    if (size === undefined) {
        return <>{EmDash}</>;
    }

    const fmtSize = fmt(size, sizeCellFormatConfig);

    return (
        <CopyCell content={fmtSize.copyableValue}>
            <P>{fmtSize.formattedValue}</P>
        </CopyCell>
    );
};
