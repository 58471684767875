import { Icons } from "@fm-frontend/uikit";
import { FC, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { getLimitStatus } from "../../../utils";
import { CpRow } from "../../types";

const Container = styled.div`
    position: relative;
`;
const StyledIcon = styled(Icons.BannerAttention)`
    position: absolute;
    right: 2px;
    top: -2px;
`;
const WithWarningIcon: FC<{ warningColor: string | undefined }> = ({ warningColor, children }) => {
    return (
        <Container>
            {warningColor && <StyledIcon size={7} color={warningColor} />}
            {children}
        </Container>
    );
};

export const AssetExpandCell = ({
    counterparties,
    isExpanded,
}: {
    counterparties: CpRow[];
    isExpanded: boolean;
}) => {
    const theme = useTheme();
    const warningColor = useMemo(() => {
        let color: string | undefined = undefined;
        for (const { userLimit, cpLimit } of counterparties) {
            const userLimitStatus =
                userLimit !== undefined ? getLimitStatus(userLimit.freeLimitPercent) : "normal";
            const cpLimitStatus =
                cpLimit !== undefined ? getLimitStatus(cpLimit.freeLimitPercent) : "normal";
            if (userLimitStatus === "low" || cpLimitStatus === "low") {
                color = theme.colors.negative100;
                break;
            }
            if (userLimitStatus === "warn" || cpLimitStatus === "warn") {
                color = theme.colors.attention100;
            }
        }
        return color;
    }, [counterparties, theme]);

    return (
        <WithWarningIcon warningColor={warningColor}>
            {isExpanded ? <Icons.Collapse /> : <Icons.Expand />}
        </WithWarningIcon>
    );
};
