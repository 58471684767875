import { createColumnHelper } from "@tanstack/react-table";
import { InstrumentNameCell, WhitelistingSwitchCell } from "./cells";
import { RowData } from "./types";

export const columnHelper = createColumnHelper<RowData>();

export const columns = [
    columnHelper.accessor((row) => row.instrument, {
        id: "name",
        cell: (subject) => {
            return <InstrumentNameCell instrument={subject.getValue()} />;
        },
        header: "Name",
        enableSorting: false,
        meta: {
            cellStyleProps: {
                padding: "0 0 0 0",
            },
        },
    }),
    columnHelper.accessor((row) => row.instrument?.instrumentName ?? "", {
        id: "whitelistSwitch",
        cell: (subject) => {
            return <WhitelistingSwitchCell instrumentName={subject.getValue()} />;
        },
        header: "Whitelisting",
        enableSorting: false,
        meta: {
            cellStyleProps: {
                width: "140px",
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
        },
    }),
];
