import { EMPTY_ARRAY, SEARCH_PARAMS } from "const";
import { bindTo, compareFeedIds, unBind } from "feature/app/ws";
import { FeedId, MainFeeds } from "feature/app/ws.d";
import { isSubaccountCp } from "feature/counterparties/utils";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useSearchParamState } from "hooks/useSearchParamState";
import { useSelector } from "hooks/useSelector";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { IRootState } from "store";
import { useTheme } from "styled-components";
import {
    Asset,
    Book,
    BrokerViewType,
    ClientType,
    CounterpartyLimit,
    Order,
    Position,
    PositionRequest,
    SettlementOrder,
    SettlementRequest,
    SettlementTransaction,
} from "types";
import ClientFeeds from "utils/ClientFeeds";

// User and client types hooks
export const userTypeSelector = (state: IRootState): ClientType | undefined => {
    const clientData = state.authentication.clientData;

    if (clientData) {
        const { clientType, primeBrokerId } = clientData;
        if (primeBrokerId !== undefined) {
            return clientType === "maker" ? "subaccountMaker" : "subaccountTaker";
        }
        return clientType;
    }
};
export const useUserType = () => useSelector(userTypeSelector);

const primeBrokerIdSelector = (state: IRootState): number | undefined =>
    state.authentication.clientData?.primeBrokerId;
export const useUserPrimeBrokerId = () => useSelector(primeBrokerIdSelector);

export const useHasTakerRoleUser = () => {
    const userType = useUserType();

    return userType === "taker" || userType === "subaccountTaker";
};

export const useHasMakerRoleUser = () => {
    const userType = useUserType();

    return userType === "maker" || userType === "subaccountMaker" || userType === "primeBroker";
};

export const useIsMakerUser = () => {
    const userType = useUserType();

    return userType === "maker";
};

export const useIsTakerUser = () => {
    const userType = useUserType();

    return userType === "taker";
};

export const useIsSubaccountUser = () => {
    const userType = useUserType();

    return userType === "subaccountTaker" || userType === "subaccountMaker";
};

export const useIsPrimeBrokerUser = () => {
    const userType = useUserType();

    return userType === "primeBroker";
};

export const usePrimeBrokerViewType = () => {
    const isMasterUser = useIsPrimeBrokerUser();
    const [primeBrokerViewType] = useSearchParamState<BrokerViewType>(
        SEARCH_PARAMS.primeBrokerViewType,
        "counterparties",
        {
            persist: true,
        },
    );

    if (!isMasterUser) {
        return "counterparties";
    }

    return primeBrokerViewType;
};

export const useViewTypeBackground = () => {
    const theme = useTheme();
    const primeBrokerViewType = usePrimeBrokerViewType();

    if (primeBrokerViewType === "subaccounts") {
        return theme.customGradients.subBg;
    }

    return theme.colors.mainBG;
};

const DEFAULT_SNAPSHOT_DATA = null;

export const useSnapshot = <T = unknown, Data = T | null>({ feed, feedId = 0 }: FeedId) => {
    const dispatch = useDispatch();
    const data = useSelector(({ ws }) => {
        const snapshots = ws.snapshots;
        const snapshot = snapshots[feed] || snapshots[ClientFeeds.masterFeed(feed) as MainFeeds];
        const snapshotFeed = snapshot.find((i) => compareFeedIds(i.feedId, feedId));

        return (snapshotFeed?.value ?? DEFAULT_SNAPSHOT_DATA) as Data;
    });
    const [isBinding, setIsBinding] = useState(true);
    const isLoading = isBinding && data === DEFAULT_SNAPSHOT_DATA;
    const isLoaded = isBinding && data !== DEFAULT_SNAPSHOT_DATA;

    if (isLoaded) {
        setIsBinding(false);
    }

    useEffect(() => {
        if (!ClientFeeds.isValidCombination(feed, feedId)) {
            return;
        }

        setIsBinding(true);
        dispatch(bindTo({ feed, feedId }));

        return () => {
            // TODO: check if setTimeout is necessary
            setTimeout(() => dispatch(unBind({ feed, feedId })), 0);
        };
    }, [feed, feedId]);

    return { data, isLoading };
};

export const useInstrumentBook = (instrumentName: string) => {
    const hasMakerRoleUser = useHasMakerRoleUser();
    const snapshotConfig: FeedId = useMemo(
        () => ({
            feed: hasMakerRoleUser ? "B" : "F",
            feedId: instrumentName,
        }),
        [hasMakerRoleUser, instrumentName],
    );
    const { data: book, isLoading } = useSnapshot<Book | { errorCode: number }>(snapshotConfig);
    const errorCode = book && "errorCode" in book ? book.errorCode : undefined;

    return {
        data: errorCode === undefined ? (book as Book) : null,
        isLoading,
        errorCode,
    };
};

const useFilteredAndExtendedWithCpTypesSnapshot = <Snapshot, ExtendedSnapshot>(
    useSnapshotHook: (feedId?: number) => Snapshot,
    getUniqueCpIdsSet: (snapshot: Snapshot) => Set<number>,
    filterSnapshotByExistedTypesOfCounterparties: (
        snapshot: Snapshot,
        typesOfCounterparties: { [cpId: number]: ClientType },
    ) => Snapshot,
    extendSnapshot: (
        filteredSnapshot: Snapshot,
        typesOfCounterparties: { [cpId: number]: ClientType },
    ) => ExtendedSnapshot,
    defaultExtendedSnapshot: ExtendedSnapshot,
    feedId?: number,
) => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const { getCpType } = useCpInfoHelpers();
    const snapshot = useSnapshotHook(feedId);
    const [extendedSnapshot, setExtendedSnapshot] =
        useState<ExtendedSnapshot>(defaultExtendedSnapshot);

    useEffect(() => {
        const uniqueCpIdsSet = getUniqueCpIdsSet(snapshot);
        const uniqueCpIds = Array.from(uniqueCpIdsSet);

        const typesOfCounterparties = uniqueCpIds.map(getCpType).reduce((acc, type, i) => {
            if (type === undefined) {
                return acc;
            }
            if (isPrimeBrokerUser) {
                const isSubaccountType = isSubaccountCp(type);
                if (
                    (isSubaccountType && primeBrokerViewType === "counterparties") ||
                    (!isSubaccountType && primeBrokerViewType === "subaccounts")
                ) {
                    return acc;
                }
            }

            acc[uniqueCpIds[i]] = type;
            return acc;
        }, {} as { [cpId: number]: ClientType });

        const filteredSnapshot = filterSnapshotByExistedTypesOfCounterparties(
            snapshot,
            typesOfCounterparties,
        );

        setExtendedSnapshot(extendSnapshot(filteredSnapshot, typesOfCounterparties));
    }, [snapshot, isPrimeBrokerUser, primeBrokerViewType, getCpType]);

    return extendedSnapshot;
};

export const useCounterpartyLimitsSnapshot = (feedId?: number) => {
    const { data, isLoading } = useSnapshot<CounterpartyLimit[]>({
        feed: "L",
        feedId,
    });

    return useMemo(
        () => ({
            data: data ?? [],
            isLoading,
        }),
        [data, isLoading],
    );
};

export interface ExtendedLimit {
    limit: CounterpartyLimit;
    cpType: ClientType;
}
export const useFilteredAndExtendedCounterpartyLimitsSnapshot = (feedId?: number) =>
    useFilteredAndExtendedWithCpTypesSnapshot(
        useCounterpartyLimitsSnapshot,
        (snapshot) =>
            snapshot.data.reduce((acc, limit) => {
                const [cpId] = limit;
                acc.add(cpId);

                return acc;
            }, new Set<number>()),
        (snapshot, typesOfCounterparties) => ({
            data: snapshot.data.filter(([cpId]) => typesOfCounterparties[cpId]),
            isLoading: snapshot.isLoading,
        }),
        (filteredSnapshot, typesOfCounterparties) => ({
            data: filteredSnapshot.data.map((limit) => ({
                limit,
                cpType: typesOfCounterparties[limit[0]],
            })),
            isLoading: filteredSnapshot.isLoading,
        }),
        {
            data: [],
            isLoading: true,
        },
        feedId,
    );

export const useAssetsSnapshot = (feedId?: number) => {
    const { data, isLoading } = useSnapshot<Asset[]>({ feed: "A", feedId });

    return {
        data: data ?? (EMPTY_ARRAY as Asset[]),
        isLoading,
    };
};

export const usePositionsSnapshot = (feedId?: number) => {
    const { data, isLoading } = useSnapshot<PositionRequest>({ feed: "K", feedId });

    return useMemo(
        () => ({
            data: data ?? (EMPTY_ARRAY as Position[]),
            isLoading,
        }),
        [data, isLoading],
    );
};

interface ExtendedPosition {
    position: Position;
    cpType: ClientType;
}
type PositionsSnapshot = {
    data: Position[];
    isLoading: boolean;
};
type ExtendedPositionsSnapshot = {
    data: ExtendedPosition[];
    isLoading: boolean;
};
export const useFilteredAndExtendedPositionsSnapshot = (
    feedId?: number,
): ExtendedPositionsSnapshot =>
    useFilteredAndExtendedWithCpTypesSnapshot<PositionsSnapshot, ExtendedPositionsSnapshot>(
        usePositionsSnapshot,
        (snapshot) =>
            snapshot.data.reduce((acc, position) => {
                const cpId = position[2];
                acc.add(cpId);

                return acc;
            }, new Set<number>()),
        (snapshot, typesOfCounterparties) => ({
            data: snapshot.data.filter((position) => typesOfCounterparties[position[2]]),
            isLoading: snapshot.isLoading,
        }),
        (filteredSnapshot, typesOfCounterparties) => ({
            data: filteredSnapshot.data.map((position) => ({
                position,
                cpType: typesOfCounterparties[position[2]],
            })),
            isLoading: filteredSnapshot.isLoading,
        }),
        {
            data: [],
            isLoading: true,
        },
        feedId,
    );

export const useOrdersSnapshot = (feedId?: number) => {
    const { data, isLoading } = useSnapshot<Order[]>({ feed: "O", feedId });

    return useMemo(
        () => ({
            data: data ?? (EMPTY_ARRAY as Order[]),
            isLoading,
        }),
        [data, isLoading],
    );
};

const useSettlementOrdersSnapshot = (feedId?: number) => {
    const { data, isLoading } = useSnapshot<SettlementOrder[]>({ feed: "S", feedId });

    return useMemo(
        () => ({
            data: data ?? (EMPTY_ARRAY as SettlementOrder[]),
            isLoading,
        }),
        [data, isLoading],
    );
};

export const useFilteredAndExtendedSettlementOrdersSnapshot = (feedId?: number) =>
    useFilteredAndExtendedWithCpTypesSnapshot(
        useSettlementOrdersSnapshot,
        (snapshot) =>
            snapshot.data.reduce((acc, settlementOrder) => {
                acc.add(settlementOrder.counterpartyId);
                return acc;
            }, new Set<number>()),
        (snapshot, typesOfCounterparties) => ({
            data: snapshot.data.filter(
                (settlementOrder) => typesOfCounterparties[settlementOrder.counterpartyId],
            ),
            isLoading: snapshot.isLoading,
        }),
        (filteredSnapshot, typesOfCounterparties) => ({
            data: filteredSnapshot.data.map((settlementOrder) => ({
                settlementOrder,
                cpType: typesOfCounterparties[settlementOrder.counterpartyId],
            })),
            isLoading: filteredSnapshot.isLoading,
        }),
        {
            data: [],
            isLoading: true,
        },
        feedId,
    );

export type SettlementRequestsSnapshot = [SettlementRequest[], SettlementRequest[]];
const DEFAULT_SETTLEMENT_REQUESTS = [[], []] as SettlementRequestsSnapshot;
const useSettlementRequestsSnapshot = (feedId?: number) => {
    const { data, isLoading } = useSnapshot<SettlementRequestsSnapshot>({
        feed: "R",
        feedId,
    });

    return useMemo(
        () => ({
            data: data ?? DEFAULT_SETTLEMENT_REQUESTS,
            isLoading,
        }),
        [data, isLoading],
    );
};

export const useFilteredAndExtendedSettlementRequestsSnapshot = (feedId?: number) =>
    useFilteredAndExtendedWithCpTypesSnapshot(
        useSettlementRequestsSnapshot,
        (snapshot) =>
            snapshot.data.reduce((acc, settlementRequests) => {
                settlementRequests.forEach(([cpId]) => {
                    acc.add(cpId);
                });
                return acc;
            }, new Set<number>()),
        (snapshot, typesOfCounterparties) => ({
            data: snapshot.data.map((settlementRequests) =>
                settlementRequests.filter(([cpId]) => typesOfCounterparties[cpId]),
            ) as SettlementRequestsSnapshot,
            isLoading: snapshot.isLoading,
        }),
        (filteredSnapshot, typesOfCounterparties) => ({
            data: filteredSnapshot.data.map((settlementRequests) =>
                settlementRequests.map((settlementRequest) => ({
                    settlementRequest,
                    cpType: typesOfCounterparties[settlementRequest[0]],
                })),
            ),
            isLoading: filteredSnapshot.isLoading,
        }),
        {
            data: [[], []],
            isLoading: true,
        },
        feedId,
    );

export type SettlementTransactions = [number, SettlementTransaction[], SettlementTransaction[]];
const DEFAULT_SETTLEMENT_TRANSACTIONS = [0, [], []] as SettlementTransactions;
const useSettlementTransactionsSnapshot = (feedId?: number) => {
    const { data, isLoading } = useSnapshot<SettlementTransactions>({ feed: "N", feedId });

    return useMemo(
        () => ({
            data: data ?? DEFAULT_SETTLEMENT_TRANSACTIONS,
            isLoading,
        }),
        [data, isLoading],
    );
};

export interface ExtendedSettlementTransaction {
    settlementTransaction: SettlementTransaction;
    cpType: ClientType;
}
type ExtendedSettlementTransactions = [
    number,
    ExtendedSettlementTransaction[],
    ExtendedSettlementTransaction[],
];
export const useFilteredAndExtendedSettlementTransactionsSnapshot = (feedId?: number) =>
    useFilteredAndExtendedWithCpTypesSnapshot(
        useSettlementTransactionsSnapshot,
        (snapshot) =>
            [snapshot.data[1], snapshot.data[2]].reduce((acc, settlementTransactions) => {
                settlementTransactions.forEach(([cpId]) => {
                    acc.add(cpId);
                });
                return acc;
            }, new Set<number>()),
        (snapshot, typesOfCounterparties) => ({
            data: [
                snapshot.data[0],
                ...[snapshot.data[1], snapshot.data[2]].map((settlementTransactions) =>
                    settlementTransactions.filter(([cpId]) => typesOfCounterparties[cpId]),
                ),
            ] as SettlementTransactions,
            isLoading: snapshot.isLoading,
        }),
        (filteredSnapshot, typesOfCounterparties) => ({
            data: [
                filteredSnapshot.data[0],
                ...[filteredSnapshot.data[1], filteredSnapshot.data[2]].map(
                    (settlementTransactions) =>
                        settlementTransactions.map((settlementTransaction) => ({
                            settlementTransaction,
                            cpType: typesOfCounterparties[settlementTransaction[0]],
                        })),
                ),
            ] as ExtendedSettlementTransactions,
            isLoading: filteredSnapshot.isLoading,
        }),
        {
            data: [0, [], []],
            isLoading: true,
        },
        feedId,
    );

export const useUsdPrices = () => {
    const { data, isLoading } = useAssetsSnapshot();

    const priceObj = useMemo(
        () => Object.fromEntries(data.map((asset) => [asset.name, BigInt(asset.price)])),
        [data],
    );

    return {
        priceObj,
        isLoading,
    };
};
