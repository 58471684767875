import { usePositionsMap } from "feature/assetsControl/components/AssetsTabContent/hooks";
import { useGetAssetFractionDigits } from "hooks/useAssetFractionDigits";
import { useExtendedLimitsByAsset } from "hooks/useExtentedLimitsByAsset";
import { useShortSalesBan } from "hooks/useShortSalesBan";
import { useCallback } from "react";
import { useUsdPrices } from "store/hooks";
import { getAbsoluteValue } from "utils/format";

export const useAssetLimitInfo = () => {
    const { limitsByAssets, isLoading: isLoadingLimitsByAssets } = useExtendedLimitsByAsset();
    const getAssetFractionDigits = useGetAssetFractionDigits();
    const { shortSalesBans, isLoading: isLoadingShortSalesBans } = useShortSalesBan();
    const { priceObj, isLoading: isUsdPriceLoading } = useUsdPrices();
    const { positionsMap, isLoading: isPositionsMapLoading } = usePositionsMap();

    return {
        getAssetLimitInfo: useCallback(
            (asset: string, cpId: number) => {
                const limitByAsset = limitsByAssets.find(
                    (limit) => limit.counterpartyId === cpId && limit.asset === asset,
                );
                const assetShortSalesBan = shortSalesBans[cpId]?.find((ban) => ban.asset === asset);

                let freeLimit: bigint | undefined = undefined;
                let freeLimitPercent: number | undefined = undefined;

                if (limitByAsset?.grossLimit !== undefined && limitByAsset?.limitCurrency) {
                    const assetPosition = positionsMap[cpId]?.[asset] ?? 0n;

                    const usdPrice = priceObj[limitByAsset.limitCurrency] ?? 0n;
                    const totalLimitUsd = (limitByAsset.grossLimit * usdPrice) / BigInt(1e8);
                    const positionUsd =
                        (BigInt(getAbsoluteValue(assetPosition)) * (priceObj[asset] ?? 0n)) /
                        BigInt(1e8);
                    const freeLimitUsd = totalLimitUsd - positionUsd;

                    freeLimit = (freeLimitUsd * BigInt(1e8)) / usdPrice;
                    freeLimitPercent =
                        limitByAsset.grossLimit > 0n
                            ? Number((freeLimit * 100n) / limitByAsset.grossLimit)
                            : 0;
                }

                return {
                    assetLimitAmount: limitByAsset?.grossLimit,
                    assetLimitCurrency: limitByAsset?.limitCurrency
                        ? limitByAsset?.limitCurrency
                        : undefined,
                    shortSalesBan: assetShortSalesBan?.isUserSetBan,
                    freeLimit,
                    freeLimitPercent,
                };
            },
            [limitsByAssets, getAssetFractionDigits, shortSalesBans, priceObj, positionsMap],
        ),
        isLoading:
            isLoadingLimitsByAssets ||
            isLoadingShortSalesBans ||
            isUsdPriceLoading ||
            isPositionsMapLoading,
    };
};
