import { ConfirmationModalContent } from "feature/app";
import { useOpenConfirmationModal } from "hooks";

export const useWithConfirmationModalDecorator = (modalContent: ConfirmationModalContent) => {
    const openConfirmationModal = useOpenConfirmationModal();

    return (shouldShowModal = true) => {
        if (!shouldShowModal) {
            modalContent.onConfirm();
            return;
        }

        openConfirmationModal(modalContent);
    };
};
