import { FC } from "react";
import styled, { css } from "styled-components";
import { Icons } from "../../../icons";

type SortDirection = "asc" | "desc";
type SortPaddingPosition = "left" | "right";

export const IconContainer = styled.span<{
    $direction: SortDirection;
    active: boolean;
    paddingPosition: SortPaddingPosition;
}>`
    ${(p) => {
        switch (p.paddingPosition) {
            case "left": {
                return css`
                    padding-left: 6px;
                `;
            }
            case "right": {
                return css`
                    padding-right: 6px;
                `;
            }
            default: {
                return null;
            }
        }
    }}

    opacity: ${(p) => (p.active ? 1 : 0)};
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    svg {
        transform: rotate(${(p) => (p.$direction === "asc" ? "-90deg" : "90deg")});
    }
`;

export const SortIcon: FC<{
    defaultDirection: SortDirection;
    sortedState: SortDirection | false;
    paddingPosition: SortPaddingPosition;
}> = ({ defaultDirection, sortedState, paddingPosition }) => {
    let active = true;
    let direction: SortDirection = defaultDirection;

    if (typeof sortedState === "boolean") {
        active = sortedState;
    } else {
        direction = sortedState;
    }

    return (
        <IconContainer $direction={direction} active={active} paddingPosition={paddingPosition}>
            <Icons.ArrowRight />
        </IconContainer>
    );
};
