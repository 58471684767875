import { PBold } from "@fm-frontend/uikit";
import { EmDash } from "const";
import React from "react";
import { TradeHistory } from "../../../types";
import { CopyCell } from "../CopyCell";

type InstrumentCellProps = Pick<TradeHistory, "instrument">;

export const InstrumentCell: React.FC<InstrumentCellProps> = ({ instrument }) => {
    if (instrument === undefined) {
        return <>{EmDash}</>;
    }
    if (instrument === null) {
        return <></>;
    }

    return (
        <CopyCell content={instrument}>
            <PBold textWrap="nowrap">{instrument}</PBold>
        </CopyCell>
    );
};
