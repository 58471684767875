export * from "./theme";
import { createGlobalStyle, css } from "styled-components";
import { modalAnimationCss } from "../components/Modal";

export const resetCss = css`
    * {
        font-family: InterVariable, sans-serif;
        font-variant-numeric: lining-nums tabular-nums;
        font-variant-ligatures: none;
        font-feature-settings: "cv01" 1, "ss01" 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;

        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        outline: none;
        transition: all 0.2s;

        &:before {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }

        &:after {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
    }

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        margin: 0;
        padding: 0;
        border: 0;
    }

    button {
        -webkit-appearance: none;
        margin: 0;
        border: none;
        padding: 0;
        -moz-appearance: none;
        font-family: "InterVariable", sans-serif;
    }

    input {
        -webkit-appearance: none;
        margin: 0;
        border: none;
        padding: 0;
        -moz-appearance: none;
        font-family: "InterVariable", sans-serif;

        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
            border: none;
            padding: 0;
            -moz-appearance: none;
        }

        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            border: none;
            padding: 0;
            -moz-appearance: none;
        }
    }

    progress[value] {
        -webkit-appearance: none;
        appearance: none;
        -moz-appearance: none;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    input[type="file"] {
        opacity: 0;
        display: none;
    }

    textarea {
        resize: none;
        border: none;
        font-family: "InterVariable", sans-serif;
    }

    html {
        scroll-behavior: smooth;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    body {
        font-family: "InterVariable", sans-serif;
        font-weight: 400;
        margin: 0;
        width: 100%;
    }

    a {
        text-decoration: none;
        transition: all 0.3s;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const scrollCss = css`
    -webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
`;

export const checkBoxResetCss = css`
    input[type="checkbox"] {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
`;

export const chartResetCss = css`
    .chart_tooltip {
        background: rgba(36, 48, 52, 0.96);
        border-radius: 12px;
        color: #fff;
        padding: 8px 10px;
        position: absolute;
        opacity: 1;
        cursor: default;

        .row {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.005em;
            margin-bottom: 2px;

            .name {
                min-width: 144px;
                position: relative;
                padding-left: 14px;

                span {
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 7px;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            .data {
                min-width: 101px;
                text-align: right;
            }
        }
    }
`;

export const fontCss = css`
    @font-face {
        font-family: "InterVariable";
        src: url("/fonts/InterVariable.woff2") format("woff2");
        font-weight: 400 600;
        font-style: normal;
    }
`;

export const spinKeyframesCss = css`
    @-webkit-keyframes spin2 {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }

    @keyframes spin2 {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }
`;

export const movingGradientKeyframesCss = css`
    @-webkit-keyframes moving-gradient {
        0% {
            background-position: -250px 0;
        }
        100% {
            background-position: 250px 0;
        }
    }
`;

export const backgroundFadeOutKeyframesCss = css`
    @-webkit-keyframes backgroundFadeOut {
        0% {
            background-color: rgba(103, 208, 53, 0.08);
        }
        100% {
            background-color: rgba(103, 208, 53, 0);
        }
    }
`;

export const GlobalStyle = createGlobalStyle`
  ${fontCss}
  ${spinKeyframesCss}
  ${movingGradientKeyframesCss}
  ${backgroundFadeOutKeyframesCss}
  ${resetCss}
  ${scrollCss}
  ${modalAnimationCss}
  ${chartResetCss}
  ${checkBoxResetCss}
`;
