import { HStack, P } from "@fm-frontend/uikit";
import styled from "styled-components";
import { CoinIcon } from "./CoinIcon";

const Network = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;

export const AssetInfo = ({
    currency,
    asset,
    network,
}: {
    currency?: string;
    asset: string | React.ReactNode;
    network?: string | React.ReactNode;
}) => (
    <HStack spacing={4} alignItems="center">
        {currency !== undefined && <CoinIcon size={16} coin={currency.toLocaleLowerCase()} />}
        <P>{asset}</P>
        {network && <Network>{network}</Network>}
    </HStack>
);
