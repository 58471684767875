import { useInstruments } from "hooks";
import { useMemo } from "react";
import { RowData } from "../types";

export const useTableData = () => {
    const { instruments, isLoading: isInstrumentsLoading } = useInstruments();

    const instrumentsData: RowData[] = useMemo(
        () =>
            Object.values(instruments)
                .sort(({ instrumentName: name1 }, { instrumentName: name2 }) => name1.localeCompare(name2))
                .map((instrument) => ({
                    key: instrument.id,
                    instrument,
                })),
        [instruments],
    );

    const isLoading = isInstrumentsLoading;

    return {
        instrumentsData,
        refetchInstrumentsData: () => {},
        isLoading,
    };
};
