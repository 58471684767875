import styled from "styled-components";
import { HStack } from "../../../layout";
import { P, P as Text, PSmall } from "../../../typography";
import { DropdownOption, TriggerSize } from "../types";

const Placeholder = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;
const Container = styled(HStack)`
    align-items: center;
    gap: 6px;
    overflow: hidden;
`;

type BasicTriggerEssenceProps<T> = {
    option: DropdownOption<T> | DropdownOption<T>[] | undefined;
    size: TriggerSize;
    placeholder?: string;
    totalOptionsCount?: number;
};
export const BasicTriggerEssence = <T,>({
    option,
    size,
    placeholder,
    totalOptionsCount = 0,
}: BasicTriggerEssenceProps<T>) => {
    const optionCount = Array.isArray(option) ? option.length : 1;
    const firstOption = Array.isArray(option) ? option[0] : option;

    const TextElement = size === "large" ? P : PSmall;

    if (!firstOption) {
        return (
            <Container>
                <Placeholder as={TextElement} ellipsis>
                    {placeholder}
                </Placeholder>
            </Container>
        );
    }

    const rest = Array.isArray(option) ? option.length - 1 : 0;

    return (
        <Container>
            {totalOptionsCount === optionCount && <Text>All</Text>}
            {totalOptionsCount !== optionCount && (
                <>
                    <Text as={TextElement} ellipsis>
                        {firstOption.text}
                    </Text>
                    {rest > 0 && <Text as={TextElement}>+{rest}</Text>}
                </>
            )}
        </Container>
    );
};
