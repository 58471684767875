import { useDelayedState } from "hooks";
import { useFilterState } from "hooks/useFilterState";
import { useSearchParamState } from "hooks/useSearchParamState";
import { use2WayBinding } from "hooks/use2WayBinding";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { ASSETS_PAGE_COMMON_FILTER_KEY } from "feature/assetsControl/utils";
import { useExtendedCurrencies } from "store/useExtentedCurrencies";
import { DEFAULT_ASSETS_OPTION } from "../../../types";
import { CurrencyTypeToAssetGroupMap } from "../consts";
import { ShortSalesStatus } from "./filters/ShortSalesStatusFilter";
import { OvernightsStatus } from "./filters/OvernightsFilter";
import { AssetLimitStatus } from "./filters/LimitPerAssetFilter";
import { AssetRow } from "./types";

export type Filters = {
    cpFilter: number[];
    limitPerAssetFilter: AssetLimitStatus[];
    overnightsFilter: OvernightsStatus[];
    shortSalesFilter: ShortSalesStatus[];
};
const DEFAULT_FILTER_VALUE: Filters = {
    cpFilter: [],
    limitPerAssetFilter: [],
    overnightsFilter: [],
    shortSalesFilter: [],
};

const PAGE_FILTER_KEY = "assets_and_instruments-assets";

export const useFilters = (data: AssetRow[], isLoading: boolean) => {
    const [
        selectedAssetsGroupFilter,
        setSelectedAssetsGroupFilter,
        deleteSelectedAssetsGroupFilter,
    ] = useSearchParamState("assetGroup", DEFAULT_ASSETS_OPTION);
    const [searchFilter, setSearchFilter, deleteSearchFilter] = useSearchParamState("search", "");
    const [cpFilter, setCpFilter, deleteCpFilter] = useFilterState<number[]>(
        ASSETS_PAGE_COMMON_FILTER_KEY,
        "cp",
        [],
        {
            isArray: true,
            parseValue: Number,
            availableValues: isLoading ? undefined : data[0]?.counterparties.map(({ id }) => id),
        },
    );
    const [limitPerAssetFilter, setLimitPerAssetFilter, deleteLimitPerAssetFilter] = useFilterState<AssetLimitStatus[]>(
        PAGE_FILTER_KEY,
        "limitPerAsset",
        [],
        { isArray: true, availableValues: Object.values(AssetLimitStatus) },
    );
    const [overnightsFilter, setOvernightsFilter, deleteOvernightsFilter] = useFilterState<OvernightsStatus[]>(
        PAGE_FILTER_KEY,
        "overnights",
        [],
        { isArray: true, availableValues: Object.values(OvernightsStatus) },
    );
    const [shortSalesFilter, setShortSalesFilter, deleteShortSalesFilter] = useFilterState<ShortSalesStatus[]>(
        PAGE_FILTER_KEY,
        "shortsales",
        [],
        { isArray: true, availableValues: Object.values(ShortSalesStatus) }
    );
    const [delayedSearchQuery, setSearchQuery, searchQuery, setSearchQueryImmediately] =
        useDelayedState(750, searchFilter);

    const { control, watch, setValue } = useForm<Filters>({
        defaultValues: {
            ...DEFAULT_FILTER_VALUE,
            cpFilter,
            limitPerAssetFilter,
            overnightsFilter,
            shortSalesFilter,
        },
    });
    // cpFilter
    const cpFilterValue = watch("cpFilter");
    use2WayBinding(
        cpFilterValue,
        (filterValue) => setValue("cpFilter", filterValue),
        cpFilter,
        setCpFilter,
    );
    // limitPerAssetFilter
    const limitPerAssetFilterValue = watch("limitPerAssetFilter");
    use2WayBinding(
        limitPerAssetFilterValue,
        (filterValue) => setValue("limitPerAssetFilter", filterValue),
        limitPerAssetFilter,
        setLimitPerAssetFilter,
    );
    // overnightsFilter
    const overnightsFilterValue = watch("overnightsFilter");
    use2WayBinding(
        overnightsFilterValue,
        (filterValue) => setValue("overnightsFilter", filterValue),
        overnightsFilter,
        setOvernightsFilter,
    );
    // shortSalesFilter
    const shortSalesFilterValue = watch("shortSalesFilter");
    use2WayBinding(
        shortSalesFilterValue,
        (filterValue) => setValue("shortSalesFilter", filterValue),
        shortSalesFilter,
        setShortSalesFilter,
    );
    // searchFilter
    use2WayBinding(delayedSearchQuery, setSearchQueryImmediately, searchFilter, setSearchFilter);

    const { currencyGroups } = useExtendedCurrencies();

    const resetFilters = (pushToHistory = true) => {
        deleteSearchFilter(!pushToHistory);
        deleteSelectedAssetsGroupFilter(true);
        deleteLimitPerAssetFilter(true);
        deleteOvernightsFilter(true);
        deleteShortSalesFilter(true);
        deleteCpFilter(true);
    };

    const areFiltersDirty =
        cpFilterValue.length !== 0 ||
        limitPerAssetFilterValue.length !== 0 ||
        overnightsFilterValue.length !== 0 ||
        shortSalesFilterValue.length !== 0 ||
        delayedSearchQuery.trim() !== "" ||
        selectedAssetsGroupFilter !== "all";

    const normalizedSearchQuery = delayedSearchQuery.trim().toLocaleLowerCase();
    const filteredData = useMemo(() => {
        const result: AssetRow[] = [];

        for (const assetData of data) {
            const { currency, counterparties } = assetData;

            const assetGroupFilterMatched =
                selectedAssetsGroupFilter === "all" ||
                CurrencyTypeToAssetGroupMap[currencyGroups[currency]] === selectedAssetsGroupFilter;
            if (!assetGroupFilterMatched) continue;

            const currencyMatched = currency.toLocaleLowerCase().includes(normalizedSearchQuery);
            const filteredCounterparties = counterparties.filter((cpData) => {
                const {
                    name,
                    id,
                    isUserSetShortSalesBan,
                    isCpSetShortSalesBan,
                    negativeRate,
                    positiveRate,
                    userLimit,
                    cpLimit,
                } = cpData;

                const isSearchMatched =
                    !normalizedSearchQuery ||
                    name.toLocaleLowerCase().includes(normalizedSearchQuery) ||
                    String(id).includes(normalizedSearchQuery);
                if (!isSearchMatched && !currencyMatched) return false;

                const cpFilterMatched =
                    cpFilterValue.length === 0 ||
                    cpFilterValue.some((filterValue) => filterValue === id);
                if (!cpFilterMatched) return false;

                const limitPerAssetFilterMatched =
                    limitPerAssetFilterValue.length === 0 ||
                    limitPerAssetFilterValue.some((filterValue) => {
                        if (filterValue === AssetLimitStatus.enabledByUser) {
                            return userLimit;
                        }
                        if (filterValue === AssetLimitStatus.disabledByUser) {
                            return !userLimit;
                        }
                        if (filterValue === AssetLimitStatus.enabledByCp) {
                            return cpLimit;
                        }
                        if (filterValue === AssetLimitStatus.disabledByCp) {
                            return !cpLimit;
                        }
                        return false;
                    });
                if (!limitPerAssetFilterMatched) return false;

                const overnightsFilterMatched =
                    overnightsFilterValue.length === 0 ||
                    overnightsFilterValue.some((filterValue) => {
                        switch (filterValue) {
                            case OvernightsStatus.enabledLong:
                                return positiveRate;
                            case OvernightsStatus.disabledLong:
                                return !positiveRate;
                            case OvernightsStatus.enabledShort:
                                return negativeRate;
                            case OvernightsStatus.disabledShort:
                                return !negativeRate;
                            default:
                                return false;
                        }
                    });
                if (!overnightsFilterMatched) return false;

                const shortSalesBanFilterMatched =
                    shortSalesFilterValue.length === 0 ||
                    shortSalesFilterValue.some((filterValue) => {
                        switch (filterValue) {
                            case ShortSalesStatus.onByUser:
                                return !isUserSetShortSalesBan;
                            case ShortSalesStatus.banByUser:
                                return isUserSetShortSalesBan;
                            case ShortSalesStatus.onByCp:
                                return !isCpSetShortSalesBan;
                            case ShortSalesStatus.banByCp:
                                return isCpSetShortSalesBan;
                            default:
                                return false;
                        }
                    });
                if (!shortSalesBanFilterMatched) return false;

                return true;
            });

            if (filteredCounterparties.length === 0) {
                continue;
            }

            result.push({
                ...assetData,
                counterparties:
                    filteredCounterparties.length === 0 ? counterparties : filteredCounterparties,
            });
        }

        return result;
    }, [
        data,
        normalizedSearchQuery,
        cpFilterValue,
        selectedAssetsGroupFilter,
        limitPerAssetFilterValue,
        overnightsFilterValue,
        shortSalesFilterValue,
    ]);

    return {
        areFiltersDirty,
        resetFilters,
        control,
        setSearchQuery,
        searchQuery,
        delayedSearchQuery,
        selectedAssetsGroupFilter,
        setSelectedAssetsGroupFilter,
        filteredData,
    };
};
