import { VStack } from "@fm-frontend/uikit";
import { EmDash } from "@fm-frontend/utils";
import { AssetInfo } from "components/AssetInfo";
import styled from "styled-components";
import EfxTypes from "utils/EfxTypes";
import { fmt } from "utils/format";
import { AmountInfo } from "../../AmountInfo";
import { Statistic } from "../../Statistic";
import { TransactionFormData } from "../modals/CreateTransactionModal";
import { PAYER_TITLES } from "../TransactionsTable";
import { SubaccountInfo } from "./SubaccountInfo";

const Container = styled(VStack)`
    position: relative;
`;

export const NotCommittedTransactionInfo = ({ transaction }: { transaction: TransactionFormData }) => (
    <Container spacing={12} padding={12} width="100%" asCard>
        <SubaccountInfo subaccountId={transaction.subaccountId} />
        <Statistic title="Asset" value={<AssetInfo asset={transaction.asset} network={transaction.network} />} />
        <AmountInfo amount={BigInt(EfxTypes.parseValue(String(transaction.amount), "size64"))} />
        <Statistic
            title={`Fee paid ${PAYER_TITLES[transaction.isFeePaidByRecipient ? "recipient" : "sender"]}`}
            value={
                transaction.fee
                    ? fmt(transaction.fee, {
                          significantDigits: 8,
                          type: "size64",
                      }).formattedValue
                    : EmDash
            }
        />
        {transaction.comment && <Statistic title="Comment" value={transaction.comment} />}
    </Container>
);
