import { PERCENT_DECIMAL_PRECISION_REGEX } from "feature/counterparties/utils";
import { ValidationMessages } from "utils/validationMessages";
import { AnySchema, number, object } from "yup";
import { InstrumentMarkupInputs } from "./InstrumentMarkupsSetupModal";

export const defaultMarkupSchema = object<Record<keyof InstrumentMarkupInputs, AnySchema>>().shape({
    defaultMarkup: number()
        .required(ValidationMessages.REQUIRED)
        .typeError(ValidationMessages.INVALID_MARKUP)
        .test("maxDigitsAfterDecimal", ValidationMessages.INVALID_MARKUP, (value) =>
            PERCENT_DECIMAL_PRECISION_REGEX.test(String(value)),
        )
        .min(-10, ValidationMessages.MIN_MARKUP_ERROR)
        .max(10, ValidationMessages.MAX_MARKUP_ERROR),

});

export const markupSchema = object<Record<keyof InstrumentMarkupInputs, AnySchema>>().shape({
    bidMarkup: number()
        .transform((value, originalValue) => (originalValue === "" ? undefined : value))
        .when("enableMarkups", (enableMarkups, schema) =>
            enableMarkups
                ? schema
                      .required(ValidationMessages.REQUIRED)
                      .typeError(ValidationMessages.INVALID_MARKUP)
                      .test("maxDigitsAfterDecimal", ValidationMessages.INVALID_MARKUP, (value: number) =>
                          PERCENT_DECIMAL_PRECISION_REGEX.test(String(value)),
                      )
                      .min(-10, ValidationMessages.MIN_MARKUP_ERROR)
                      .max(10, ValidationMessages.MAX_MARKUP_ERROR)
                : schema.optional(),
        ),
    askMarkup: number()
        .transform((value, originalValue) => (originalValue === "" ? undefined : value))
        .when("enableMarkups", (enableMarkups, schema) =>
            enableMarkups
                ? schema
                      .required(ValidationMessages.REQUIRED)
                      .typeError(ValidationMessages.INVALID_MARKUP)
                      .test("maxDigitsAfterDecimal", ValidationMessages.INVALID_MARKUP, (value: number) =>
                          PERCENT_DECIMAL_PRECISION_REGEX.test(String(value)),
                      )
                      .min(-10, ValidationMessages.MIN_MARKUP_ERROR)
                      .max(10, ValidationMessages.MAX_MARKUP_ERROR)
                : schema.optional(),
        ),
});
