import { MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { CounterpartiesOptionEssence } from "components/CounterpartiesOptionEssence";
import { CounterpartyTriggerEssence } from "components/CounterpartiesTriggerEssence";
import { useFilteredCpsIdsFromLimits } from "hooks/useCounterparties";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useMemo } from "react";
import { usePrimeBrokerViewType } from "store/hooks";
import { ClientType } from "types";
import { MultipleDropdownGroupedSheet } from "./MultipleDropdownGroupedSheet";

export const CounterpartiesMultipleGroupedDropdown = ({
    values = [],
    onChange,
    error,
    caption = "Counterparties",
    cpsIds = [],
}: {
    values: number[];
    onChange: (values: number[]) => void;
    error?: string;
    caption?: string;
    cpsIds?: number[];
}) => {
    const { getCpName, getCpType } = useCpInfoHelpers();
    const primeBrokerViewtype = usePrimeBrokerViewType();
    const cpsDetails = useMemo(
        () =>
            cpsIds.reduce<Record<string, { name: string; type: ClientType | undefined }>>(
                (acc, cpId) => {
                    acc[cpId] = {
                        name: getCpName(cpId, "full"),
                        type: getCpType(cpId),
                    };
                    return acc;
                },
                {},
            ),
        [cpsIds, getCpName, getCpType],
    );
    const groupOptions = useMemo(
        () => [
            {
                text: primeBrokerViewtype === "counterparties" ? "All makers" : "All sub-makers",
                isOptionInGroup: (optionValue: number) =>
                    cpsDetails[optionValue]?.type === "maker" ||
                    cpsDetails[optionValue]?.type === "subaccountMaker",
            },
            {
                text: primeBrokerViewtype === "counterparties" ? "All takers" : "All sub-takers",
                isOptionInGroup: (optionValue: number) =>
                    cpsDetails[optionValue]?.type === "taker" ||
                    cpsDetails[optionValue]?.type === "subaccountTaker",
            },
            {
                text: "All masters",
                isOptionInGroup: (optionValue: number) =>
                    cpsDetails[optionValue]?.type === "primeBroker",
            },
        ],
        [cpsDetails, primeBrokerViewtype],
    );

    const options = useMemo(
        () =>
            Object.entries(cpsDetails)
                .map(([cpId, { name }]) => ({
                    text: name,
                    value: Number(cpId),
                }))
                .sort((a, b) => a.text.localeCompare(b.text)),
        [cpsDetails],
    );

    return (
        <MultipleDropdown
            values={values}
            onChange={onChange}
            renderTrigger={(trigger) => (
                <MultipleDropdown.Trigger {...trigger} size="large" variant="simple">
                    <CounterpartyTriggerEssence
                        {...trigger}
                        size="large"
                        option={trigger.selectedOptions}
                        noIcon
                    />
                </MultipleDropdown.Trigger>
            )}
            options={options}
            caption={caption}
            placeholder="Select"
            align="end"
            fullWidth
            error={error}
        >
            <MultipleDropdownGroupedSheet<number>
                values={values}
                onChange={onChange}
                Dropdown={MultipleDropdown}
                allOptionText="All counterparties"
                groupOptions={groupOptions}
                options={options}
                OptionEssence={CounterpartiesOptionEssence}
                size="large"
            />
        </MultipleDropdown>
    );
};

export const FilledCounterpartiesMultipleGroupedDropdown = ({
    values = [],
    onChange,
    error,
    caption = "Counterparties",
}: {
    values: number[];
    onChange: (values: number[]) => void;
    error?: string;
    caption?: string;
}) => {
    const { cpsIds } = useFilteredCpsIdsFromLimits();

    return (
        <CounterpartiesMultipleGroupedDropdown
            values={values}
            onChange={onChange}
            error={error}
            caption={caption}
            cpsIds={cpsIds}
        />
    );
};
