import { Loading as UIKitLoading, PSmall, PSmallBold, VStack } from "@fm-frontend/uikit";
import React, { FC, Suspense } from "react";
import styled from "styled-components";

const LoadingContainer = styled.div`
    margin: 0 auto;
`

const Loading = () => (
    <LoadingContainer>
        <UIKitLoading />
    </LoadingContainer>
);

export const Container = styled(VStack)`
    gap: 6px;
    width: 240px;

    ${PSmall} {
        color: ${(p) => p.theme.colors.uiWhite52};

        span {
          color: ${(p) => p.theme.colors.uiWhite100};
        }
    }
    ${PSmallBold} {
        color: ${(p) => p.theme.colors.uiWhite100};

        a {
            color: ${(p) => p.theme.colors.brand100};
        }
    }
`;

export const HelperContainer: FC<{ className?: string }> = ({ className, children }) => (
    <Container className={className}>
        <Suspense fallback={<Loading />}>
            {children}
        </Suspense>
    </Container>
);
