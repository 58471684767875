import jwtDecode from "jwt-decode";
import { InviteToken } from "../types";

type RawInviteTokenType = Omit<InviteToken, "signTnc"> &
    Partial<{
        signTnc: string;
        allowChangeInvitedUsername: boolean;
    }>;

export const decodeInviteJwtToken = (inviteToken: string | undefined): InviteToken | undefined => {
    if (!inviteToken) {
        return undefined;
    }

    const decodedToken: RawInviteTokenType = jwtDecode(inviteToken);

    return {
        ...decodedToken,
        signTnc: decodedToken.signTnc === undefined ? true : decodedToken.signTnc,
        allowChangeInvitedUsername:
            decodedToken.allowChangeInvitedUsername === undefined ? true : decodedToken.allowChangeInvitedUsername,
    } as InviteToken;
};
