import { P } from "@fm-frontend/uikit";
import React from "react";
import { fmt, FormatConfig } from "utils/format";
import { TradeHistory } from "../../../types";
import { CopyCell } from "../CopyCell";

type VolumeProps = Pick<TradeHistory, "volume">;

export const volumeCellFormatConfig: FormatConfig = {
    significantDigits: 8,
    type: "size",
    showZero: true,
};

export const VolumeCell: React.FC<VolumeProps> = ({ volume }) => {
    if (volume === undefined) {
        return <></>;
    }

    const fmtVolume = fmt(volume, volumeCellFormatConfig);

    return (
        <CopyCell content={fmtVolume.copyableValue}>
            <P>{fmtVolume.formattedValue}</P>
        </CopyCell>
    );
};
