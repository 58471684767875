import { pushPreloadedValues } from "feature/form/initialValuesUtils";
import { setDefaultCreateTransactionState } from "feature/specificSubaccountAndPBPagesContents";
import { setSubaccountDefaultRequestState } from "feature/specificSubaccountAndPBPagesContents/subaccountRequestsPageContent/AddNewWithdrawalRequestForm";
import { setSubaccountDefaultTransactionState } from "feature/specificSubaccountAndPBPagesContents/subaccountTransactionsPageContent/modals/CreateDepositModal";
import { useCallback } from "react";
import { useHistory } from "react-router";
import { useIsPrimeBrokerUser, useIsSubaccountUser, usePrimeBrokerViewType } from "store/hooks";
import { getFmtPrecisionConfig } from "utils";
import { fmt, getAbsoluteValue } from "utils/format";

export const useCreatePrefilledTransactionOrRequest = () => {
    const history = useHistory();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const isSubaccountUser = useIsSubaccountUser();

    return useCallback(
        ({ asset, value, cpId, comment }: { asset: string; value: bigint; cpId?: number; comment?: string }) => {
            if (isSubaccountUser) {
                if (value > 0) {
                    setSubaccountDefaultRequestState({
                        asset,
                        amount: fmt(value, {
                            type: "size64",
                            ...getFmtPrecisionConfig(asset),
                        }).parsedValue,
                        comment,
                    });
                    history.push("/subaccountRequests");
                    return;
                }

                setSubaccountDefaultTransactionState({
                    asset,
                    amount: fmt(getAbsoluteValue(value), {
                        type: "size64",
                        ...getFmtPrecisionConfig(asset),
                    }).parsedValue,
                    comment,
                });
                history.push("/subaccountTransactions");
                return;
            }
            if (isPrimeBrokerUser && primeBrokerViewType === "subaccounts") {
                if (value > 0) {
                    setDefaultCreateTransactionState({
                        openedTab: "deposit",
                        defaultFormValues: {
                            subaccountId: cpId,
                            asset,
                            amount: fmt(value, {
                                type: "size64",
                                ...getFmtPrecisionConfig(asset),
                            }).parsedValue,
                            comment,
                        },
                    });
                } else {
                    setDefaultCreateTransactionState({
                        openedTab: "withdrawal",
                        defaultFormValues: {
                            subaccountId: cpId,
                            asset,
                            amount: fmt(getAbsoluteValue(value), {
                                type: "size64",
                                ...getFmtPrecisionConfig(asset),
                            }).parsedValue,
                            comment,
                        },
                    });
                }
                history.push("/subaccountsViewTransactions?viewType=subaccounts");
                return;
            }
            pushPreloadedValues("settlement", {
                cpId,
                asset,
                amount: fmt(getAbsoluteValue(value), {
                    type: "size64",
                    ...getFmtPrecisionConfig(asset),
                }).parsedValue,
                type: value > 0 ? "request" : "transaction",
                isAllAvailableAmount: false,
                comment: comment ?? "",
            });
            history.push("/transactions");
        },
        [history, isPrimeBrokerUser, primeBrokerViewType, isSubaccountUser],
    );
};
