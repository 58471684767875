import { confirmSignIn, updateMFAPreference, type VerifyTOTPSetupInput } from "aws-amplify/auth";

export const verifyTOTPSetup = async (input: VerifyTOTPSetupInput): Promise<void> => {
    await confirmSignIn({
        challengeResponse: input.code,
    });
    await updateMFAPreference({
        totp: "PREFERRED",
    });
};
