import { Column } from "@tanstack/react-table";
import { useEffect, useRef, useState } from "react";

export const useTableStickyCellInsets = <T>(cells: Column<T, unknown>[]) => {
    const tableRef = useRef<HTMLTableElement | null>(null);
    const [leftStickyCellInsets, setLeftStickyCellInsets] = useState<number[]>([]);

    const defineLeftStickyCellInsets = () => {
        if (tableRef.current) {
            const stickyCells = tableRef.current.querySelectorAll(".sticky-table-cell");

            const stickyCellsArray = [...stickyCells];
            const insets = [];
            let insetsAccumulator = 0;

            for (let i = 0; i < stickyCellsArray.length; i++) {
                if (i !== 0) {
                    const previousCellWidth = stickyCellsArray[i - 1].getBoundingClientRect().width;
                    insetsAccumulator += previousCellWidth;
                }

                insets.push(insetsAccumulator);
            }

            if (insets.length) {
                setLeftStickyCellInsets(insets);
            }
        }
    };

    useEffect(() => {
        defineLeftStickyCellInsets();
    }, [cells]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(defineLeftStickyCellInsets);
        resizeObserver.observe(document.documentElement);

        return () => resizeObserver.unobserve(document.documentElement);
    }, []);

    return {
        tableRef,
        leftStickyCellInsets,
    };
};
