import { when } from "@fm-frontend/utils";
import React, { useMemo, useState } from "react";
import { useController } from "react-hook-form";
import { useTheme } from "styled-components";
import { EmptySearch } from "../../common/EmptySearch";
import { HintVariantComponents } from "../../common/HintVariantComponents";
import { VStack } from "../../layout";
import { Popover } from "../../Popover";
import { TextSmall } from "../../typography";
import {
    Container,
    DropdownGroup,
    Item,
    MatchedPart,
    OptionContainer,
    OptionSheetInfoBanner,
    PopoverContent,
    useCustomHint,
} from "../common";
import { DropdownSearch } from "../common/DropdownSearch";
import { search } from "../MultiDropdown/utils";
import { BasicTrigger, InlineTrigger, MinimumTrigger, SimpleTrigger, TriggerProps } from "../Trigger";
import { DropdownPosition, DropdownProps, DropdownVariant } from "./Dropdown.types";

const DEFAULT_POSITIONS: DropdownPosition[] = ["bottom"];
const MIN_OPTIONS_COUNT_WITHOUT_SEARCH = 7;

const TriggerVariantComponents: {
    [Key in DropdownVariant]: React.VFC<TriggerProps>;
} = {
    basic: BasicTrigger,
    inline: InlineTrigger,
    minimum: MinimumTrigger,
    simple: SimpleTrigger,
};

export const Dropdown = <TValue,>(props: DropdownProps<TValue>) => {
    const {
        variant,
        hint,
        error,
        label,
        text,
        renderText,
        renderOption: renderOptionContent,
        disabled,
        fullWidth,
        placeholder,
        options = [],
        align = "start",
        positions = DEFAULT_POSITIONS,
        searchable = options.length > MIN_OPTIONS_COUNT_WITHOUT_SEARCH,
        size = "large",
        optionSheetInfo,
        presetSearchQuery = "",
        $onChange,
        className,
    } = props;

    const { colors } = useTheme();
    const [query, setQuery] = useState(presetSearchQuery);

    const { field } = useController({
        ...props,
    });

    const { value: selectedValue, onChange, onBlur } = field;

    const innerValue = useMemo(() => {
        return options?.find(({ value }) => value === selectedValue)?.text;
    }, [options, selectedValue]);

    const displayValue = text ?? innerValue;

    const [isOpen, setIsOpen] = useState(false);
    const handleClickOutside = (e: MouseEvent) => {
        e.stopPropagation();
        setIsOpen(false);
    };

    const { hintVariant } = useCustomHint(error, hint);
    const HintComponent = HintVariantComponents[hintVariant.variant];

    const handleSelect = (item: TValue) => {
        onChange(item);
        $onChange?.(item);
        setIsOpen(false);
        onBlur();
    };

    const mappedOptions = useMemo(() => {
        const searchedOptions = search(query, options);

        if (searchedOptions.length) {
            return searchedOptions.map((option) => {
                const { text, value, icon, disabled, match } = option;

                return (
                    <Item
                        icon={icon}
                        isActive={value === selectedValue}
                        key={String(value)}
                        content={
                            renderOptionContent ? (
                                renderOptionContent(option)
                            ) : match ? (
                                <MatchedPart fontColor={colors.ui100}>{match}</MatchedPart>
                            ) : (
                                text
                            )
                        }
                        value={value}
                        onSelect={handleSelect}
                        disabled={disabled}
                    />
                );
            });
        }

        return <EmptySearch description="Option not found" />;
    }, [query, options, selectedValue]);

    const TriggerComponent = TriggerVariantComponents[variant];

    return (
        <Container size={size} isDisabled={disabled} fullWidth={fullWidth} className={className}>
            <Popover
                isOpen={isOpen}
                padding={8}
                positions={positions}
                align={align}
                onClickOutside={handleClickOutside}
                content={
                    <VStack spacing={8}>
                        <PopoverContent>
                            <VStack spacing={12} width="100%">
                                {when(
                                    searchable,
                                    <DropdownSearch value={query} onChange={(query) => setQuery(query)} size={size} />,
                                )}
                                <OptionContainer>{mappedOptions}</OptionContainer>
                            </VStack>
                        </PopoverContent>
                        {optionSheetInfo && <OptionSheetInfoBanner>{optionSheetInfo}</OptionSheetInfoBanner>}
                    </VStack>
                }
            >
                <DropdownGroup>
                    <TriggerComponent
                        isOpen={isOpen}
                        label={label}
                        text={displayValue}
                        renderText={renderText ? (text) => renderText?.(text, selectedValue) : undefined}
                        placeholder={placeholder}
                        isError={hintVariant.variant === "error"}
                        isDisabled={Boolean(disabled)}
                        onTrigger={() => !disabled && setIsOpen(!isOpen)}
                        size={size}
                    />
                </DropdownGroup>
            </Popover>
            {hintVariant.text && (
                <HintComponent>
                    <TextSmall>{hintVariant.text}</TextSmall>
                </HintComponent>
            )}
        </Container>
    );
};
