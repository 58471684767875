import { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";

export type Key = string | number;
type _TransitionState = "entering" | "active" | "exiting" | "moving";
export type TransitionState = _TransitionState | undefined;
export type DelayOptions = { enterDelay?: number; exitDelay?: number };

export function useListTransition<T extends { key: Key }>(
    list: Array<T>,
    { enterDelay = 50, exitDelay = 200 }: DelayOptions = {},
) {
    type MoveContext = {
        index: number;
        style?: CSSProperties;
    };
    const [ids, setIds] = useState<Key[]>([]);
    const [entries, setEntries] = useState<Record<Key, T>>({});
    const [states, setStates] = useState<Record<Key, _TransitionState>>({});
    const [moveContext, setMoveContext] = useState<Record<Key, MoveContext>>({});
    const enqueueUpdate = useCallback(
        (type: _TransitionState, items: Key[]) => {
            if (items.length === 0) return;
            const delays: {
                [State in _TransitionState]?: number;
            } = {
                entering: enterDelay,
                exiting: exitDelay,
            };
            setTimeout(() => {
                if (type === "entering" || type === "active") {
                    setStates((states) => ({
                        ...states,
                        ...Object.fromEntries(items.map((id) => [id, type])),
                    }));
                    if (type === "entering") enqueueUpdate("active", items);
                }
                if (type === "exiting") {
                    setIds((ids) => ids.filter((id) => !items.includes(id)));
                    setStates((states) =>
                        Object.fromEntries(Object.entries(states).filter(([id]) => !items.includes(id))),
                    );
                    setEntries((entries) =>
                        Object.fromEntries(Object.entries(entries).filter(([id]) => !items.includes(id))),
                    );
                }
                if (type === "moving") {
                    setMoveContext((moveContext) =>
                        Object.fromEntries(
                            Object.entries(moveContext).map(
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                ([key, { style, ...value }]) => [key, value],
                            ),
                        ),
                    );
                }
            }, delays[type] || 0);
        },
        [setStates, setEntries],
    );
    useEffect(() => {
        const newIds: Key[] = [];
        const addedIds: Key[] = [];
        const removedIds: Key[] = [];
        const newEntries: Record<Key, T> = {};
        const newStates = { ...states };
        for (let index = 0; index < list.length; index += 1) {
            const item = list[index];
            newEntries[item.key] = item;
            newIds.push(item.key);
            if (newStates[item.key] === undefined) {
                addedIds.push(item.key);
            }
        }
        const resultingList = [...newIds];
        for (const id of ids) {
            if (!newEntries[id]) {
                newEntries[id] = entries[id];
                const idx = ids.indexOf(id);
                resultingList.splice(idx, 0, id);
                removedIds.push(id);
                newStates[id] = "exiting";
            }
        }
        const newMoveContext: Record<Key, MoveContext> = {};
        const movedIds: Key[] = [];
        for (let index = 0; index < resultingList.length; index += 1) {
            const item = resultingList[index];
            const originalContext = moveContext[item];
            if (originalContext) {
                newMoveContext[item] = {
                    ...originalContext,
                    index,
                };
                if (originalContext.index !== index) {
                    const s: CSSProperties = {};
                    s.transform = s.WebkitTransform = `translateY(${originalContext.index < index ? "-" : ""}50%)`;
                    s.transitionDuration = "0s";
                    newMoveContext[item].style = s;
                    movedIds.push(item);
                }
            } else {
                newMoveContext[item] = {
                    index,
                } as MoveContext;
            }
        }
        setIds(resultingList);
        setEntries(newEntries);
        setStates(newStates);
        setMoveContext(newMoveContext);
        enqueueUpdate("entering", addedIds);
        enqueueUpdate("exiting", removedIds);
        enqueueUpdate("moving", movedIds);
    }, [list]);
    return useMemo(
        () =>
            ids.map((id) => ({
                ...entries[id],
                ...moveContext[id],
                state: states[id],
            })),
        [ids, entries, states, moveContext],
    );
}

export function useItemTransition<T = { [key: string]: unknown }>(
    item: null | T | boolean,
    { enterDelay = 0, exitDelay = 50 }: DelayOptions = {},
) {
    const [itemSnapshot, setItemSnapshot] = useState<T | undefined>(
        typeof item !== "boolean" && item !== null ? item : undefined,
    );
    useEffect(() => {
        if (typeof item === "boolean" || item === null || !item) return;
        setItemSnapshot(item);
    }, [item]);

    const [isActive, setIsActive] = useState(Boolean(item));
    const [transitionState, setTransitionState] = useState<TransitionState>(isActive ? "active" : undefined);
    useEffect(() => {
        switch (transitionState) {
            case "entering": {
                const taskId = setTimeout(() => {
                    setTransitionState("active");
                }, enterDelay);
                return () => clearTimeout(taskId);
            }
            case "exiting": {
                const taskId = setTimeout(() => {
                    setTransitionState(undefined);
                    setIsActive(false);
                    setItemSnapshot(undefined);
                }, exitDelay);
                return () => clearTimeout(taskId);
            }
        }
    }, [transitionState, enterDelay, exitDelay]);

    const isItemActive = useMemo(() => {
        if (typeof item === "boolean") return item;
        return Boolean(item);
    }, [item]);
    useEffect(() => {
        if (isItemActive) {
            if (transitionState !== "active") {
                setIsActive(true);
                setTransitionState("entering");
            }
        } else {
            setTransitionState("exiting");
        }
    }, [isItemActive]);

    return useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return { transitionState, isActive, props: itemSnapshot! };
    }, [transitionState, isActive, itemSnapshot]);
}
