import { parse, stringify } from "./json";

export const LS_PREFIX = "efx-value-";

/**
 * Get key with prefix for localStorage value
 * @param key non-namespaced key
 * @param userId optional authenticated user id
 * @returns namespaced key
 */
export function getLSKey(key: string, userId?: number | null): string {
    const userPrefix = userId ? `${userId}-` : "";

    return `${LS_PREFIX}${userPrefix}${key}`;
}

/**
 * Encode and write value to localStorage
 * @param key non-namespaced key
 * @param value any js value
 * @param userId optional authenticated user id
 * @returns @void
 */
export function setLSValue(key: string, value: unknown, userId?: number | null) {
    localStorage.setItem(getLSKey(key, userId), btoa(stringify(value)));
}

/**
 * Clear localStorage value by key
 * @param key non-namespaced key
 * @param userId optional authenticated user id
 * @returns @void
 */
export function removeLSValue(key: string, userId?: number | null) {
    localStorage.removeItem(getLSKey(key, userId));
}

/**
 * Read and parse value from localStorage
 * @param key non-namespaced key
 * @param userId optional authenticated user id
 * @returns parsed value or null
 */
export function getLSValue<T>(key: string, userId?: number | null): T | null {
    const fallbackLSValue = localStorage.getItem(getLSKey(key));
    const lsValue = localStorage.getItem(getLSKey(key, userId)) ?? fallbackLSValue;
    if (lsValue === null) {
        return null;
    }
    try {
        return parse(atob(lsValue));
    } catch {
        localStorage.removeItem(key);
    }
    try {
        const value = parse(lsValue);
        setLSValue(key, value, userId);
        return value;
    } catch {
        return null;
    }
}
