export { AUTH_ERRORS } from "./authErrors";
export * from "./currencyInfos";
export * from "./regex";

export const CSV_SEPARATOR = ",";
export const CSV_SEPARATOR_ADDITIONAL = ";";
export const CSV_NEW_LINE_SEPARATOR = "\n";

export const MAX_API_VALUE = 1e18;
export const SIZE_VALUE_MULTIPLICATOR = 1e8;

export const NOT_SET = "Not set";
export const EmDash = "—";

export const booleanify = (value: string): boolean => {
    const truthy: string[] = ["true", "True", "1"];

    return truthy.includes(value);
};

export function getEnvVariable<T extends string | number>(variable?: string) {
    if (variable) {
        try {
            const parsed: T = JSON.parse(variable);
            return parsed;
        } catch {
            return null;
        }
    }
    return null;
}

export function getBooleanEnvVariable(variable?: string) {
    if (!variable) {
        return false;
    }

    return booleanify(variable);
}

export const FACTION_UNIT_COMPENSATOR = BigInt(1e8);
export const MAX_FRACTION_DIGITS = 8;
