import { HStack, PSmall, VStack } from "@fm-frontend/uikit";
import styled, { css, useTheme } from "styled-components";
import { getFmtPrecisionConfig } from "utils";
import { fmt } from "utils/format";
import { Amount } from "../styled";

const PositionContainer = styled(HStack)<{ selectable?: boolean }>`
    padding: 6px 8px;
    border-radius: 8px;

    ${(p) =>
        p.selectable &&
        css`
            cursor: pointer;
            :hover {
                background: ${p.theme.colors.ui4};
            }
        `}
`;

export const PositionOption = ({
    value,
    usdValue,
    asset,
    title,
    onAmountSelected,
}: {
    value: bigint;
    usdValue: bigint;
    asset: string;
    title: string;
    onAmountSelected: (amount: bigint) => void;
}) => {
    const { colors } = useTheme();
    const handleClick = () => {
        if (value < 0) {
            onAmountSelected(-value);
        }
    };

    return (
        <PositionContainer selectable={value < 0} justifyContent="space-between" key={title} onClick={handleClick}>
            <PSmall color={colors.ui72}>{title}</PSmall>
            <VStack alignItems="flex-end">
                <PSmall color={value >= 0 ? colors.brand100 : colors.negative100}>
                    {value > 0 && "+"}
                    {
                        fmt(value, {
                            significantDigits: 8,
                            showZero: true,
                            type: "size64",
                            ...getFmtPrecisionConfig(asset),
                        }).formattedValue
                    }{" "}
                    {asset}
                </PSmall>
                <Amount>
                    {usdValue > 0 && "+"}
                    {
                        fmt(usdValue, {
                            significantDigits: 2,
                            showZero: true,
                            type: "size64",
                        }).formattedValue
                    }{" "}
                    USD
                </Amount>
            </VStack>
        </PositionContainer>
    );
};
