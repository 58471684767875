import {
    BasicButton,
    Center,
    H1,
    H2,
    HeaderTitle,
    HStack,
    Icons,
    Notifications,
    P as CopyrightContainer,
    P as LinkWrapper,
    P,
    PBold,
    PrimaryButton,
    ShadowCard,
    VStack,
} from "@fm-frontend/uikit";
import { openUrl, useToggle } from "@fm-frontend/utils";
import { Copyright } from "components/Copyright";
import { LinkFAQ, LinkLegal, LinkLogout, LinkSupport } from "components/Links";
import { ConfirmationModal } from "components/modal/ConfirmationModal";
import { TnCPrint } from "components/TnCPrint";
import { onMobile } from "const";
import { LayoutContainer } from "feature/auth/style";
import { OnboardingMenu } from "feature/onboarding";
import { TermsAndConditionsContent } from "feature/onboarding/businessAndLegal";
import { SignTAndC } from "feature/onboarding/businessAndLegal/termsAndConditions/modals/SignTAndC";
import { getAgreementNumber } from "feature/onboarding/businessAndLegal/termsAndConditions/TermsAndConditions";
import {
    Header,
    Layout,
    LinksContainer,
    LogoutWrapper,
    OnboardingContent,
    OnboardingFooter,
} from "feature/onboarding/common";
import { PermissionsLackErrorModal } from "feature/usersManagement";
import { useSelector } from "hooks";
import { useSettings } from "hooks/useSettings";
import jwtDecode from "jwt-decode";
import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { LegalDataInputs } from "store/onboardingSlice";
import styled, { useTheme } from "styled-components";

const TAndCContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 360px;
    min-width: 640px;
    gap: 12px;

    ${ShadowCard} {
        gap: unset;
    }

    ${onMobile} {
        min-width: unset;
    }

    ${ShadowCard} {
        width: 100%;
    }
`;

type Params = Partial<
    LegalDataInputs & {
        readOnly: boolean;
        clientId: number;
    }
>;

const Container = styled(VStack)`
    width: 360px;
    padding-top: 212px;
    background: white url("/img/done-bg.png") no-repeat right top;
    background-size: contain;
`;

const FM_HOME_URL = "https://finerymarkets.com/";

const TermsAndConditionsSinged: React.FC = () => {
    const { colors } = useTheme();

    const handleHomePageNavigation = () => {
        openUrl(FM_HOME_URL, "_self");
    };

    return (
        <Container asCard>
            <VStack spacing={12} paddingX={12} paddingBottom={16}>
                <H2>T&C are signed</H2>
                <P color={colors.ui52}>Great job! Continue onboarding, we're excited to have you join us!</P>
            </VStack>
            <VStack spacing={12} paddingX={12} paddingBottom={16}>
                <PrimaryButton size="large" fullWidth onClick={handleHomePageNavigation}>
                    Go to finerymarkets.com
                </PrimaryButton>
            </VStack>
        </Container>
    );
};

export const TermsAndConditions: React.VFC = () => {
    const { isMobile, isMobileMenuOpen } = useSelector((state) => state.app);
    const { notifications } = useSelector((state) => state.app);
    const { colors } = useTheme();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const { readOnly, ...legalData }: Params = useMemo(() => {
        if (!token) {
            return {};
        }

        return jwtDecode(token);
    }, [location, token]);
    const agreementNumber = useMemo(() => getAgreementNumber(legalData.clientId), [legalData]);
    const { isActive: isAgreementSigned, activate: setIsAgreementSigned } = useToggle();
    const isEditable = !readOnly;
    const isInfoEnabled = !Boolean(readOnly || isAgreementSigned);
    const settings = useSettings();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    return (
        <LayoutContainer isOverlayEnabled={isMobile && isMobileMenuOpen}>
            <OnboardingMenu />
            <OnboardingContent>
                <Layout>
                    <Header>
                        <H1>Terms & Conditions</H1>
                        {isInfoEnabled && (
                            <VStack maxWidth="480px" spacing={8}>
                                <P>
                                    You have been shared with a signing link to complete the onboarding process on
                                    Finery Markets.
                                </P>
                                <PBold>Read and sign the T&C as an authorised person</PBold>
                            </VStack>
                        )}
                    </Header>
                    {isAgreementSigned ? (
                        <TermsAndConditionsSinged />
                    ) : (
                        <>
                            <TAndCContainer>
                                <VStack asCard padding={12} paddingBottom={40}>
                                    <HStack paddingBottom={16} spacing={12} justifyContent="space-between">
                                        <HeaderTitle title="Terms & Conditions" />
                                        <TnCPrint>
                                            <TermsAndConditionsContent {...legalData} />
                                        </TnCPrint>
                                    </HStack>
                                    <TermsAndConditionsContent {...legalData} />
                                    {isEditable && (
                                        <Center>
                                            <VStack maxWidth="336px" alignItems="center" spacing={12}>
                                                <BasicButton size="small" type="button">
                                                    Copy read-only link <Icons.CopyIcon />
                                                </BasicButton>
                                                <P textAlign="center" color={colors.ui52}>
                                                    You can copy and send our T&C text to your lawyers or any other
                                                    interested parties
                                                </P>
                                            </VStack>
                                        </Center>
                                    )}
                                </VStack>
                            </TAndCContainer>
                            {isEditable && (
                                <SignTAndC
                                    agreementNumber={agreementNumber}
                                    token={token}
                                    onSingedComplete={setIsAgreementSigned}
                                />
                            )}
                        </>
                    )}
                </Layout>
            </OnboardingContent>
            <Notifications notifications={notifications} />
            <ConfirmationModal />
            <PermissionsLackErrorModal />
            {isMobile && (
                <OnboardingFooter>
                    <LinksContainer>
                        {settings.footer_links && (
                            <>
                                <LinkWrapper>
                                    <LinkLegal />
                                </LinkWrapper>
                                <LinkWrapper>
                                    <LinkFAQ />
                                </LinkWrapper>
                            </>
                        )}
                        <LinkWrapper>
                            <LinkSupport />
                        </LinkWrapper>
                        <LogoutWrapper>
                            <Icons.LogoutArrow />
                            <LinkLogout />
                        </LogoutWrapper>
                    </LinksContainer>
                    <CopyrightContainer>
                        <Copyright />
                    </CopyrightContainer>
                </OnboardingFooter>
            )}
        </LayoutContainer>
    );
};
