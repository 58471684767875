import { FC } from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    border-top: 1px solid ${(p) => p.theme.colors.ui12};
`;

type LightboxFooterProps = {
    className?: string;
};
export const LightboxFooter: FC<LightboxFooterProps> = ({ className, children }) => {
    return <Container className={className}>{children}</Container>;
};
