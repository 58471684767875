import { CounterpartyLimit } from "../../../../types";
import { fmt, fmtDeltaratePercent } from "../../../../utils/format";
import { CounterpartyStatusType, getMarginLimitValue, parseLimit } from "../../utils";
import { getFmtModalIndicatorConfig } from "../utils";

export const getMaintenanceMarginLimitIndicatorData = (
    limit: CounterpartyLimit | undefined,
    isMaker: boolean | undefined,
) => {
    if (!limit) {
        return null;
    }

    const { currency, grossLimit, mutualGrossLimit, mutualLimitCurrencyName, maintenanceMargin } = parseLimit(limit);

    const makerGrossLimit = isMaker ? grossLimit : mutualGrossLimit ?? 0n;
    const makerCurrency = isMaker ? currency : mutualLimitCurrencyName ?? "";

    const marginLimitValue = getMarginLimitValue(makerGrossLimit, maintenanceMargin);
    const fmtResult = fmt(marginLimitValue, getFmtModalIndicatorConfig(makerCurrency));
    const fmtPercentResult = fmtDeltaratePercent(maintenanceMargin, { showZero: false });

    return {
        title: "Maintenance",
        value: fmtResult.formattedValue,
        subValue: fmtPercentResult.formattedValue,
        copyableValue: fmtResult.copyableValue,
    };
};

export const getRestrictedTradingLimitIndicatorData = (
    limit: CounterpartyLimit | undefined,
    isMaker: boolean | undefined,
) => {
    if (!limit) {
        return null;
    }

    const { currency, grossLimit, mutualLimitCurrencyName, mutualGrossLimit, restrictedTrading } = parseLimit(limit);

    const makerGrossLimit = isMaker ? grossLimit : mutualGrossLimit ?? 0n;
    const makerCurrency = isMaker ? currency : mutualLimitCurrencyName ?? "";

    const marginLimitValue = getMarginLimitValue(makerGrossLimit, restrictedTrading);
    const fmtResult = fmt(marginLimitValue, getFmtModalIndicatorConfig(makerCurrency));
    const fmtPercentResult = fmtDeltaratePercent(restrictedTrading, { showZero: false });

    return {
        title: "Restricted trading",
        value: fmtResult.formattedValue,
        subValue: fmtPercentResult.formattedValue,
        copyableValue: fmtResult.copyableValue,
    };
};

export const getInitialMarginLimitIndicatorData = (
    limit: CounterpartyLimit | undefined,
    isMaker: boolean | undefined,
) => {
    if (!limit) {
        return null;
    }

    const { currency, grossLimit, mutualLimitCurrencyName, mutualGrossLimit, initialMargin } = parseLimit(limit);

    const makerGrossLimit = isMaker ? grossLimit : mutualGrossLimit ?? 0n;
    const makerCurrency = isMaker ? currency : mutualLimitCurrencyName ?? "";

    const marginLimitValue = getMarginLimitValue(makerGrossLimit, initialMargin);
    const fmtResult = fmt(marginLimitValue, getFmtModalIndicatorConfig(makerCurrency));
    const fmtPercentResult = fmtDeltaratePercent(initialMargin, { showZero: false });

    return {
        title: "Initial margin",
        value: fmtResult.formattedValue,
        subValue: fmtPercentResult.formattedValue,
        copyableValue: fmtResult.copyableValue,
    };
};

export const getMakerGrossLimitIndicator = (
    limit: CounterpartyLimit | undefined,
    cpName: string,
    isMaker: boolean | undefined,
    isSubaccountUser: boolean,
) => {
    if (!limit) {
        return null;
    }

    const { currency, grossLimit, mutualLimitCurrencyName, mutualGrossLimit } = parseLimit(limit);
    const makerGrossLimit = isMaker ? grossLimit : mutualGrossLimit ?? 0n;
    const makerCurrency = isMaker ? currency : mutualLimitCurrencyName ?? "";
    const fmtResult = fmt(makerGrossLimit, getFmtModalIndicatorConfig(makerCurrency));

    const getTitle = () => {
        if (isSubaccountUser) {
            return "Your SA gross limit";
        }
        if (isMaker) {
            return `Your CP gross limit`;
        }

        return `Limit by ${cpName}`;
    }

    return {
        title: getTitle(),
        value: fmtResult.formattedValue,
        copyableValue: fmtResult.copyableValue,
    };
};

export const getMakerMarginLinesIndicator = (limit: CounterpartyLimit | undefined) => {
    if (!limit) {
        return [];
    }

    const { maintenanceMargin, initialMargin, restrictedTrading } = parseLimit(limit);

    return [
        {
            value: Number(fmtDeltaratePercent(initialMargin).copyableValue),
            status: CounterpartyStatusType.MARGIN_CALL,
        },
        {
            value: Number(fmtDeltaratePercent(restrictedTrading).copyableValue),
            status: CounterpartyStatusType.RESTRICTED_TRADING,
        },
        {
            value: Number(fmtDeltaratePercent(maintenanceMargin).copyableValue),
        },
    ];
};
