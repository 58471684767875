import { ValidationMessages } from "utils/validationMessages";
import { AnySchema, object, string } from "yup";
import { SendSigningLinkInputs } from "./SendSigningLink";
import { SignTAndCInputs } from "./SignTAndC";

export const signTAndCSchema = object<Record<keyof SignTAndCInputs, AnySchema>>().shape({
    fullName: string()
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim())
        .matches(/^['a-zA-Z.-]{1,40}(?: ['a-zA-Z.-]{1,40})+$/, "Should be a full name (at least two words)"),
    position: string()
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim()),
});

export const sendSigningLinkSchema = object<Record<keyof SendSigningLinkInputs, AnySchema>>().shape({
    email: string().trim().email(ValidationMessages.INVALIDEmail).required(ValidationMessages.REQUIRED),
});
