import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIsActive } from "../feature/app";

export const useIsActiveTracker = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const listener = () => {
            const isActive = document.visibilityState === "visible";
            dispatch(setIsActive(isActive));
        };
        document.addEventListener("visibilitychange", listener);
        return () => {
            document.removeEventListener("visibilitychange", listener);
        };
    }, []);
};
