import { VStack } from "@fm-frontend/uikit";
import { TextArea } from "feature/form/style";
import { forwardRef, TextareaHTMLAttributes, useCallback, useEffect, useImperativeHandle, useRef } from "react";
import styled from "styled-components";

type AutoResizableTextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
    error?: string;
};

const Error = styled.span`
    color: ${(p) => p.theme.colors.negative100};
`;

export const AutoResizableTextArea = forwardRef(({ error, ...otherProps }: AutoResizableTextAreaProps, ref: any) => {
    let inputRef = useRef<any>();
    useImperativeHandle(ref, () => inputRef.current);

    const autoResize = useCallback(function () {
        // @ts-ignore
        this.style.height = "auto";
        // @ts-ignore
        this.style.height = this.scrollHeight + "px";
    }, []);

    useEffect(() => {
        inputRef.current?.addEventListener("input", autoResize);

        return () => inputRef.current?.removeEventListener("input", autoResize);
    }, []);

    return (
        <VStack spacing={4}>
            <TextArea ref={inputRef} {...otherProps} invalid={Boolean(error)} />
            {error && <Error>{error}</Error>}
        </VStack>
    );
});
