import { ShadowCard, TextSmall } from "@fm-frontend/uikit";
import styled from "styled-components";
import { IndicatorContainer } from "../styled";

export const MarginBanner = styled(ShadowCard)`
    ${IndicatorContainer} {
        padding: 0;

        &:nth-child(4) > {
            ${TextSmall} {
                color: ${(p) => p.theme.colors.attention100};
            }
        }

        &:nth-child(5) > {
            ${TextSmall} {
                color: ${(p) => p.theme.colors.negative100};
            }
        }

        &:nth-child(6) > {
            ${TextSmall} {
                color: ${(p) => p.theme.colors.negative100};
            }
        }
    }
`;

export const MarginBannerHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    ${TextSmall} {
        color: ${(p) => p.theme.colors.ui100};
        font-weight: 600;
        margin: 0;
    }
`;
