import { SyntheticEvent, VFC } from "react";
import styled, { useTheme } from "styled-components";
import { noop } from "../../const";
import { SmallLoader } from "../animations";
import { Icons } from "../icons";

const StyledButton = styled.button`
    background-color: ${(p) => p.theme.colors.ui72};
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: ${(p) => p.theme.colors.ui100};
    }
`;

const DeleteButton = styled(StyledButton)`
    margin-top: 18px;
    &:hover {
        background-color: ${(p) => p.theme.colors.negative100};
    }
`;

type Variant = "close" | "help" | "delete";
const BUTTON_ICONS: { [V in Variant]: VFC<{ color: string }> } = {
    close: Icons.X,
    help: Icons.QuestionMark,
    delete: Icons.Bin,
};

export const ActionButton = ({
    variant,
    onClick,
    loading = false,
}: {
    variant: Variant;
    onClick: (e: SyntheticEvent) => void;
    loading?: boolean;
}) => {
    const theme = useTheme();
    const Icon = BUTTON_ICONS[variant];
    const Button = variant === "delete" ? DeleteButton : StyledButton;

    return (
        <Button onClick={loading ? noop : onClick}>
            {loading ? <SmallLoader color={theme.colors.uiWhite100} /> : <Icon color={theme.colors.uiWhite100} />}
        </Button>
    );
};
