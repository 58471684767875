import { TradingSide } from "feature/trade/trading/types";

export const calculateTotalAmountAsset = ({
    tradingSide,
    quoteAsset,
    baseAsset,
}: {
    tradingSide: TradingSide;
    quoteAsset: string;
    baseAsset: string;
}) => {
    return tradingSide === "size" ? quoteAsset : baseAsset;
};
