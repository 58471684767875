import React, { createContext, useContext } from "react";
import { DropzoneOptions, DropzoneState, useDropzone } from "react-dropzone";
import { UploadFileInfo, useFileContext } from "./FileContext";

type DropzoneContextType = {
    dropzone: DropzoneState;
};

const DropzoneContext = createContext<DropzoneContextType | null>(null);

export const useDropzoneContext = () => {
    const context = useContext(DropzoneContext);

    if (!context) {
        throw new Error("useDropzoneContext must be used within a DropzoneContextProvider");
    }

    return context;
};

type DropzoneContextProviderProps = {
    options: Pick<DropzoneOptions, "disabled" | "multiple" | "accept">;
};
export const DropzoneContextProvider: React.FC<DropzoneContextProviderProps> = ({
    children,
    options,
}) => {
    const { setAcceptedFiles, setAcceptedFilesInfo, setRejectedFilesInfo, setExternalError } =
        useFileContext();

    const dropzone = useDropzone({
        ...options,
        noClick: true,
        onError: (err) => setExternalError(err.message),
        onDrop: (acceptedFiles, fileRejections) => {
            setAcceptedFilesInfo(
                acceptedFiles.map(
                    (acceptedFile) =>
                        ({
                            name: acceptedFile.name,
                            size: acceptedFile.size,
                            errors: [],
                        } as UploadFileInfo),
                ),
            );
            setRejectedFilesInfo(
                fileRejections.map(
                    (rejectedFile) =>
                        ({
                            name: rejectedFile.file.name,
                            size: rejectedFile.file.size,
                            errors: rejectedFile.errors,
                        } as UploadFileInfo),
                ),
            );
            setAcceptedFiles(acceptedFiles);
        },
    });

    return (
        <DropzoneContext.Provider
            value={{
                dropzone,
            }}
        >
            {children}
        </DropzoneContext.Provider>
    );
};
