import { forwardRef } from "react";
import { DefaultTheme, StyledComponent } from "styled-components";
import { useId } from "../../hooks/useId";
import { Checkmark } from "../icons/styled";
import {
    CheckboxContainer,
    LargeCheckmarkContainer,
    MediumCheckmarkContainer,
    SmallCheckmarkContainer,
} from "./styled";

export type CheckboxSize = "small" | "medium" | "large";

interface CheckboxProps {
    id?: string;
    checked: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    size?: CheckboxSize;
    [key: string]: unknown;
}

const CheckmarkContainerVariantComponents: {
    [Key in CheckboxSize]: StyledComponent<"span", DefaultTheme, {}, never>;
} = {
    small: SmallCheckmarkContainer,
    medium: MediumCheckmarkContainer,
    large: LargeCheckmarkContainer,
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    ({ id: checkboxId, checked, onChange, disabled, size = "large", ...otherProps }, inputRef) => {
        const generatedId = useId("checkbox-");
        const id = checkboxId || generatedId;
        const CheckmarkContainer = CheckmarkContainerVariantComponents[size];

        return (
            <CheckboxContainer>
                <input
                    id={id}
                    type="checkbox"
                    checked={checked}
                    onChange={(e) => onChange(e.target.checked)}
                    disabled={disabled}
                    ref={inputRef}
                    {...otherProps}
                />
                <CheckmarkContainer>
                    <Checkmark />
                </CheckmarkContainer>
            </CheckboxContainer>
        );
    },
);
