import styled, { css, DefaultTheme } from "styled-components";

export const ShelfContainer = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 8px;
    width: 100%;
    > * {
        max-width: calc(50% - 4px);
    }
`;

export const Grid = styled.div`
    display: grid;
    gap: 20px;
    grid-template-columns: 200px 100%;
    align-items: center;
`;

export const GridIcons = styled.div`
    display: grid;
    grid-auto-rows: 36px;
    align-items: center;
`;

export const ShowIcon = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 12px;
    background-color: ${(p) => p.theme.colors.ui8};
    border-radius: 12px;
`;

export const Swatch = styled.div<{ color: keyof DefaultTheme["colors"]; textColor?: string }>`
    color: ${(p) => p.textColor};
    width: 100px;
    height: 100px;
    border-radius: 8px;
    font-size: 12px;
    border: 1px solid ${(p) => p.theme.colors[p.color]};
    background-color: ${(p) => p.theme.colors[p.color]};
    display: flex;
`;

export const ColorPreview = styled.div``;

export const ColorName = styled.div`
    margin-top: 5px;
    text-align: center;
    font-size: 14px;
`;

export const Palette = styled.section<{ bgColor?: string }>`
    background-color: ${(p) => p.bgColor ?? "transparent"};
    display: flex;
    margin-bottom: 6px;
    padding: 6px;
    gap: 8px;

    ${ColorPreview} + ${ColorPreview} {
        margin-left: 6px;
    }
`;

export const shadowCss = css`
    background-color: ${(p) => p.theme.colors.uiWhite100};

    box-shadow: 0 1px 2px ${(p) => p.theme.colors.ui4}, inset 0 -1px 0 ${(p) => p.theme.colors.ui12},
        inset 0 0 0 1px ${(p) => p.theme.colors.ui12};
    border-radius: 12px;
    overflow: hidden;
`;

export const underline = css`
    box-shadow: inset 0 -1px 0 ${(p) => p.theme.colors.ui8};
`;

export const ShadowCard = styled.div<{ isDefault?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;

    ${(p) =>
        !p.isDefault &&
        css`
            padding: 12px 12px 16px;
            gap: 8px;
        `}

    width: 100%;
    min-height: 44px;
    border-radius: 12px;

    ${shadowCss}
`;

export const ShadowItem = styled.section<{ padding?: string; isUnderlined?: boolean }>`
    width: 100%;
    padding: ${(p) => p.padding ?? "12px"};
    ${(p) => p.isUnderlined && underline};
`;
