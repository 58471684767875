import { Accordion, HStack } from "@fm-frontend/uikit";
import { useAccordion } from "@fm-frontend/uikit/src/components/Accordion/useAccordion";
import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { StatusOfRfqHistoryItem } from "feature/rfq/apiHooks/useLastRfqTrades";
import { FC, useCallback } from "react";
import { Spacer } from "style";
import styled from "styled-components";

const Container = styled(HStack)`
    gap: 8px;
    align-items: center;
`;

const options: DropdownOption<StatusOfRfqHistoryItem>[] = [
    { text: "Executed", value: "COMMITTED" },
    { text: "Cancelled", value: "CANCELED" },
    { text: "Created", value: "CREATED" },
    { text: "Expired", value: "EXPIRED" },
];

type LastRfqHeaderProps = {
    selectedStatuses: StatusOfRfqHistoryItem[];
    setSelectedStatuses: (selectedStatuses: StatusOfRfqHistoryItem[]) => void;
};

export const LastRfqAccordionHeader: FC<LastRfqHeaderProps> = ({
    selectedStatuses,
    setSelectedStatuses,
}) => {
    const { openAccordion } = useAccordion();

    const handleSelect = useCallback(
        (values: StatusOfRfqHistoryItem[]) => {
            setSelectedStatuses(values);
            openAccordion();
        },
        [openAccordion, setSelectedStatuses],
    );

    return (
        <Container>
            <Accordion.Title>LAST RFQs</Accordion.Title>
            <Spacer />
            <div
                onClick={(ev) => {
                    ev.stopPropagation();
                }}
            >
                <MultipleDropdown
                    values={selectedStatuses}
                    onChange={handleSelect}
                    renderTrigger={(trigger) => (
                        <MultipleDropdown.Trigger {...trigger} size="small" variant="simple">
                            <MultipleDropdown.TriggerEssence
                                {...trigger}
                                option={trigger.selectedOptions}
                                size="small"
                            />
                        </MultipleDropdown.Trigger>
                    )}
                    options={options}
                    align="end"
                    caption="Status"
                    asFilter
                >
                    <MultipleDropdown.BasicSheet size="small" options={options} />
                </MultipleDropdown>
            </div>
        </Container>
    );
};
