import { Modal } from "@fm-frontend/uikit";
import { useModalControl } from "@fm-frontend/utils";
import { parseLimit } from "feature/counterparties/utils";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useMemo } from "react";
import { useCounterpartyLimitsSnapshot } from "store/hooks";
import { isEnabledTradingByUser } from "utils";
import { LiquidityManagementModalContent } from "./LiquidityManagementModalContent/LiquidityManagementModalContent";
import { useCpsStatuses } from "./LiquidityManagementModalContent/useCpsStatuses";
import { WidgetButton } from "./WidgetButton";

export const LpManagementWidget = () => {
    const { getCpName, getCpType } = useCpInfoHelpers();
    const { data: counterpartyLimits } = useCounterpartyLimitsSnapshot();
    const { data: cpStatuses } = useCpsStatuses();

    const allLimits = useMemo(
        () =>
            counterpartyLimits
                .map((limit) => ({
                    ...parseLimit(limit),
                    limit,
                }))
                .filter(({ counterpartyId }) => {
                    const cpType = getCpType(counterpartyId);

                    return (
                        cpType === "maker" ||
                        cpType === "subaccountMaker" ||
                        cpType === "primeBroker"
                    );
                })
                .map(({ counterpartyId, tradingStatus, limit }) => ({
                    counterpartyId,
                    cpName: getCpName(counterpartyId, "full"),
                    cpStatus: cpStatuses[counterpartyId]?.status,
                    key: counterpartyId,
                    limit,
                    enabledTradingByUser: isEnabledTradingByUser(tradingStatus),
                }))
                .sort(({ cpName: n1 }, { cpName: n2 }) => n1.localeCompare(n2)),
        [counterpartyLimits, getCpName, getCpType, cpStatuses],
    );
    const enabledLimits = useMemo(
        () => allLimits.filter(({ enabledTradingByUser }) => enabledTradingByUser),
        [allLimits],
    );

    const { isModalOpen, openModal, closeModal } = useModalControl(false);

    if (allLimits.length === 0) {
        return null;
    }

    return (
        <>
            <WidgetButton allLimits={allLimits} onClick={openModal} />
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <LiquidityManagementModalContent
                    enabledLimitsCount={enabledLimits.length}
                    allLimits={allLimits}
                />
            </Modal>
        </>
    );
};
