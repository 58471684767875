import { noop } from "@fm-frontend/utils";
import React, { createContext, useCallback, useState } from "react";

type DirtyRows = Record<string, { isDirty: boolean; fields: Record<string, boolean> }>;
type CryptoFormContextValue = {
    dirtyRows: DirtyRows;
    addDirtyRow: (rowId: string) => void;
    removeDirtyRow: (rowId: string) => void;
    updateDirtyRow: (rowId: string, key: string, isFieldDirty: boolean) => void;
};

export const CryptoFormContext = createContext<CryptoFormContextValue>({
    dirtyRows: {},
    addDirtyRow: noop,
    removeDirtyRow: noop,
    updateDirtyRow: noop,
});

export const CryptoFormContextProvider: React.FC = ({ children }) => {
    const [dirtyRows, setDirtyRows] = useState<DirtyRows>({});

    const updateDirtyRow = useCallback(
        (rowId: string, key: string, isFieldDirty: boolean): void => {
            setDirtyRows((prevDirtyRows) => ({
                ...prevDirtyRows,
                [rowId]: {
                    isDirty: isFieldDirty,
                    fields: { ...(prevDirtyRows[rowId]?.fields ?? {}), [key]: isFieldDirty },
                },
            }));
        },
        [setDirtyRows],
    );

    const addDirtyRow = useCallback(
        (rowId: string) => {
            setDirtyRows((prevDirtyRows) => ({
                ...prevDirtyRows,
                [rowId]: {
                    isDirty: true,
                    fields: {
                        [rowId]: true,
                    },
                },
            }));
        },
        [setDirtyRows],
    );

    const removeDirtyRow = useCallback(
        (rowId: string) => {
            setDirtyRows((prevDirtyRows) => {
                if (!prevDirtyRows[rowId]) {
                    return prevDirtyRows;
                }

                const { [rowId]: deletedRow, ...restRows } = prevDirtyRows;

                return restRows;
            });
        },
        [setDirtyRows],
    );

    return (
        <CryptoFormContext.Provider
            value={{
                dirtyRows,
                addDirtyRow,
                updateDirtyRow,
                removeDirtyRow,
            }}
        >
            {children}
        </CryptoFormContext.Provider>
    );
};
