import { Flex, H1, P, ShadowCard } from "@fm-frontend/uikit";
import { MobileLogo } from "components/icons";
import { UserMenuControl } from "components/UserMenuControl/UserMenuControl";
import { onMobile, onTablet } from "const";
import { useMakersInfo, useSelector } from "hooks";
import React from "react";
import { useIsOnboardingRequired } from "store/onboardingSlice";
import { Spacer } from "style";
import styled from "styled-components";
import { Header } from "../common";
import { Filter } from "./filter/Filter";
import { LiquidityProviders } from "./LiquidityProviders";
import { LiquidityProviderDetails } from "./liquidityProvidersDetails/LiquidityProviderDetails";
import { FilterContextProvider } from "./LiquidityProvidersFilterContext";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
    margin-top: 18px;
    padding: 0 12px;

    ${H1} {
        line-height: 26px;
    }

    ${onTablet} {
        position: relative;
        left: unset;
    }
`;

const Content = styled.div`
    width: 100%;
    max-width: 600px;

    ${ShadowCard} {
        margin-bottom: 12px;
    }
`;

export const AbsolutePositionedTitle = styled(H1)`
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);

    ${onTablet} {
        position: relative;
        left: unset;
        transform: unset;
    }
`;

const Shifter = styled.div`
    width: 228px;
    height: 100vh;

    ${onTablet}, ${onMobile} {
        display: none;
    }
`;

const ShifterContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

export const Providers: React.FC = () => {
    const { makersInfo, isLoading } = useMakersInfo();
    const { makerInfoDetailed } = useSelector((state) => state.onboarding);
    const isOnboardingRequired = useIsOnboardingRequired();

    const { isMobile } = useSelector((state) => state.app);

    return (
        <Container>
            <Header>
                <Flex spacing={4} alignItems="center" width="100%">
                    {isMobile ? (
                        <>
                            <MobileLogo />
                            <Spacer />
                            <H1>Liquidity providers</H1>
                        </>
                    ) : (
                        <AbsolutePositionedTitle>Liquidity providers</AbsolutePositionedTitle>
                    )}
                    <Spacer />
                    <UserMenuControl />
                </Flex>
                {isOnboardingRequired && <P>Select the providers you wish to trade with</P>}
            </Header>
            <ShifterContainer>
                <Content>
                    <FilterContextProvider>
                        {makerInfoDetailed ? (
                            <LiquidityProviderDetails makerInfo={makerInfoDetailed} />
                        ) : (
                            <>
                                <Filter />
                                <LiquidityProviders isLoading={isLoading} makersInfo={makersInfo} />
                            </>
                        )}
                    </FilterContextProvider>
                </Content>
                <Shifter />
            </ShifterContainer>
        </Container>
    );
};
