import { PXSmall, VStack } from "@fm-frontend/uikit";
import styled from "styled-components";

const Container = styled(VStack)`
    padding-left: 12px;
    text-align: right;
    color: ${(p) => p.theme.colors.ui52};
`;

export const AssetRefPriceHeaderCell = () => (
    <Container>
        <PXSmall>Ref. price</PXSmall>
        <PXSmall>Balance step</PXSmall>
    </Container>
);
