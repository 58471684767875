import {
    BasicButton,
    BasicInput,
    HStack,
    IconButton,
    Icons,
    P,
    PSmall,
    PSmallBold,
    Textarea,
    TextareaContainer,
    TextareaElement,
    VStack,
} from "@fm-frontend/uikit";
import { useToggle, when } from "@fm-frontend/utils";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import styled, { useTheme } from "styled-components";
import { ActionContainer, CurrencyLightboxCell } from "../components";
import { BankAddressRow } from "../components/BankAddressRow";
import { useScrollToBottom } from "../hooks";
import { AccountStatusType, BankAddress, BanksAddressFormInput, FormApiErrors } from "../types";
import {
    ADDRESSES_COLLECTION_NAME,
    BANK_CURRENCY_TYPES,
    OTHER_DETAILS_PLACEHOLDER,
    PUBLIC_DETAILS_PLACEHOLDER,
} from "../utils";

const DEFAULT_BANK_ADDRESS: BankAddress = {
    currency: null,
    company: null,
    wallet: "",
    revisions: [],
    status: AccountStatusType.Verification,
    bankPrimary: null,
    bankIntermediate: null,
    rule: null,
};

interface BankAddressBoxProps {
    apiErrors: FormApiErrors;
    onAddressDelete: (id: string, address: BankAddress) => void;
}

const CardHeader = styled(HStack)`
    justify-content: space-between;
`;

const CardActionContainer = styled(HStack)`
    gap: 4px;
`;

const Container = styled(VStack)`
    ${TextareaContainer} {
        padding: 6px 8px;
        ${TextareaElement} {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.06px;
        }
    }

    padding-top: 12px;
    padding-bottom: 20px;
`;

export const BanksLightBoxContent: React.FC<BankAddressBoxProps> = ({ apiErrors, onAddressDelete }) => {
    const { colors } = useTheme();
    const {
        control,
        register,
        formState: { errors, isDirty },
        getValues,
    } = useFormContext<BanksAddressFormInput>();
    const scrollToBottom = useScrollToBottom();

    const apiErrorsOccurred = Boolean(Object.keys(apiErrors).length);

    const { fields, append, remove, insert } = useFieldArray({
        control,
        name: ADDRESSES_COLLECTION_NAME,
    });

    const { activate: touchFrom, isActive: wasTouched } = useToggle();

    useEffect(() => {
        const hasNoRows = fields.length === 0;
        const wasNotTouched = !wasTouched;

        if (hasNoRows && wasNotTouched) {
            append({
                ...DEFAULT_BANK_ADDRESS,
            });
        }
    }, [append, fields, wasTouched]);

    useEffect(() => {
        if (isDirty) {
            touchFrom();
        }
    }, [isDirty]);

    useEffect(() => {
        scrollToBottom();
    }, [fields.length]);

    const handleRowDelete = (id: string, index: number) => {
        const address = getValues()?.addresses?.[index];

        if (address.accountId) {
            onAddressDelete(id, address);
        }

        remove(index);
        touchFrom();
    };

    const handleRowCopy = (index: number) => {
        const itemToCopy = getValues()?.addresses?.[index];

        const indexToInsert = index + 1;

        if (itemToCopy) {
            const addressCopy: BankAddress = JSON.parse(JSON.stringify(itemToCopy));
            insert(indexToInsert, {
                ...DEFAULT_BANK_ADDRESS,
                wallet: addressCopy.wallet,
                currency: addressCopy.currency,
                company: addressCopy.company,
                bankPrimary: addressCopy.bankPrimary,
                bankIntermediate: addressCopy.bankIntermediate,
                publicDetails: addressCopy.publicDetails,
                otherDetails: addressCopy.otherDetails,
            });
        }
    };

    return (
        <VStack>
            <VStack>
                {fields.map((item, index) => (
                    <BankAddressRow
                        key={item.id}
                        error={apiErrors[item.id]}
                        animateShowUp={item.accountId === undefined}
                    >
                        <input
                            hidden
                            defaultValue={item.id}
                            {...register(`${ADDRESSES_COLLECTION_NAME}.${index}._validationItemId`)}
                        />
                        <Container spacing={16}>
                            <VStack>
                                <CardHeader>
                                    <P>#{index + 1}</P>
                                    <CardActionContainer>
                                        <IconButton
                                            type="button"
                                            variant="plain"
                                            Icon={Icons.Duplicate}
                                            onClick={() => handleRowCopy(index)}
                                        />
                                        <IconButton
                                            type="button"
                                            variant="plain"
                                            Icon={Icons.Bin}
                                            onClick={() => handleRowDelete(item.id, index)}
                                        />
                                    </CardActionContainer>
                                </CardHeader>
                                <HStack spacing={8}>
                                    <VStack width="50%" spacing={8}>
                                        <CurrencyLightboxCell
                                            index={index}
                                            disabled={apiErrorsOccurred}
                                            availableCurrencyTypes={BANK_CURRENCY_TYPES}
                                        />
                                        <BasicInput
                                            disabled={apiErrorsOccurred}
                                            _size="medium"
                                            placeholder="Account number/IBAN"
                                            error={errors?.addresses?.[index]?.wallet?.message}
                                            {...register(`addresses.${index}.wallet`)}
                                        />
                                        <BasicInput
                                            disabled={apiErrorsOccurred}
                                            _size="medium"
                                            placeholder="BIC/SWIFT"
                                            error={errors?.addresses?.[index]?.memoOrTag?.message}
                                            {...register(`addresses.${index}.memoOrTag`)}
                                        />
                                    </VStack>
                                    <VStack width="50%" spacing={8}>
                                        <BasicInput
                                            disabled={apiErrorsOccurred}
                                            _size="medium"
                                            placeholder="Bank name"
                                            error={errors?.addresses?.[index]?.bankPrimary?.name?.message}
                                            {...register(`addresses.${index}.bankPrimary.name`)}
                                        />
                                        <BasicInput
                                            disabled={apiErrorsOccurred}
                                            _size="medium"
                                            placeholder="Bank address: house, street, city, state/province, ZIP"
                                            error={errors?.addresses?.[index]?.bankPrimary?.address?.message}
                                            {...register(`addresses.${index}.bankPrimary.address`)}
                                        />
                                        <BasicInput
                                            disabled={apiErrorsOccurred}
                                            _size="medium"
                                            placeholder="Company name"
                                            error={errors?.addresses?.[index]?.company?.name?.message}
                                            {...register(`addresses.${index}.company.name`)}
                                        />
                                        <BasicInput
                                            disabled={apiErrorsOccurred}
                                            _size="medium"
                                            placeholder="Company address: house, street, city, state/province, ZIP"
                                            error={errors?.addresses?.[index]?.company?.address?.message}
                                            {...register(`addresses.${index}.company.address`)}
                                        />
                                    </VStack>
                                </HStack>
                            </VStack>
                            <VStack spacing={4}>
                                <CardHeader>
                                    <PSmall color={colors.ui72}>Other details</PSmall>
                                </CardHeader>
                                <HStack spacing={8}>
                                    <VStack width="50%" spacing={8}>
                                        <Textarea
                                            placeholder={OTHER_DETAILS_PLACEHOLDER}
                                            rows={5}
                                            disabled={apiErrorsOccurred}
                                            error={errors?.addresses?.[index]?.otherDetails?.message}
                                            {...register(`${ADDRESSES_COLLECTION_NAME}.${index}.otherDetails`)}
                                        />
                                    </VStack>
                                    <VStack width="50%" spacing={8}>
                                        <Textarea
                                            rows={5}
                                            disabled={apiErrorsOccurred}
                                            placeholder={PUBLIC_DETAILS_PLACEHOLDER}
                                            error={errors?.addresses?.[index]?.publicDetails?.message}
                                            {...register(`${ADDRESSES_COLLECTION_NAME}.${index}.publicDetails`)}
                                        />
                                    </VStack>
                                </HStack>
                            </VStack>
                        </Container>
                    </BankAddressRow>
                ))}
            </VStack>
            {when(
                apiErrorsOccurred,
                <></>,
                <ActionContainer paddingY={12}>
                    <BasicButton type="button" size="small" onClick={() => append({ ...DEFAULT_BANK_ADDRESS })}>
                        <HStack spacing={4}>
                            <Icons.Add />
                            <PSmallBold>Account</PSmallBold>
                        </HStack>
                    </BasicButton>
                </ActionContainer>,
            )}
        </VStack>
    );
};
