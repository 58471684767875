import { useOpenConfirmationModal } from "hooks";

export const useWithDiscardDataConfirmationModal = () => {
    const openConfirmationModal = useOpenConfirmationModal();

    return (onConfirm: () => void) =>
        (shouldShowModal = true) => {
            if (!shouldShowModal) {
                onConfirm();
                return;
            }

            openConfirmationModal({
                title: "Cancel the creation",
                description: "Are you sure you want to cancel? Any entered data will be discarded.",
                confirmButtonTitle: "Yes, cancel",
                cancelButtonTitle: "Continue editing",
                onConfirm,
            });
        };
};
