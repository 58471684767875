import { getNextReqId } from "api/methods/postViaWS";
import { sendRESTRequestViaWS } from "feature/app/ws";
import { subscribeToWsRestResponses } from "feature/app/ws.saga";
import { actionChannel } from "store/actionChannel";
import EfxTypes from "utils/EfxTypes";
import { UpdateMarkupRequestBody, UpdateMarkupResponse } from "../../types";

export type MarkupData = {
    instruments: string[];
    cps: number[];
    enableMarkups: boolean;
    bidMarkup: string | number;
    askMarkup: string | number;
    applySameMarkups: boolean;
};

export const getMarkupsCount = ({
    selectedInstruments,
    selectedCps,
}: {
    selectedInstruments: MarkupData["instruments"];
    selectedCps: MarkupData["cps"];
}) => {
    return selectedInstruments.length * selectedCps.length;
};

export const convertToRequestBodies = (data: MarkupData): UpdateMarkupRequestBody[] => {
    const { instruments, cps, enableMarkups, bidMarkup, askMarkup } = data;

    return instruments.flatMap((instrumentName) =>
        cps.map((cpId) => {
            return {
                counterpartyId: Number(cpId),
                instrument: instrumentName,
                ...(enableMarkups
                    ? {
                          bidMarkup: EfxTypes.parseValue(
                              String(bidMarkup),
                              "deltaratePercent",
                          ) as number,
                          askMarkup: EfxTypes.parseValue(
                              String(askMarkup),
                              "deltaratePercent",
                          ) as number,
                      }
                    : {}),
            };
        }),
    );
};

const waitMarkupsOutput = (
    requestsBodiesWithReqIds: {
        reqId: number;
        requestBody: UpdateMarkupRequestBody;
    }[],
) =>
    new Promise<UpdateMarkupResponse[]>((resolve) => {
        const requestsObj = requestsBodiesWithReqIds.reduce<
            Record<number, UpdateMarkupRequestBody>
        >((acc, { reqId, requestBody }) => {
            acc[reqId] = requestBody;
            return acc;
        }, {});
        const reqIdsWithoutResponse = new Set(Object.keys(requestsObj).map(Number));
        const failedReqIds: Record<number, number> = {};

        const unsubscribe = subscribeToWsRestResponses((resp) => {
            const { reqId, error } = resp;
            reqIdsWithoutResponse.delete(reqId);
            if (error !== undefined) {
                failedReqIds[reqId] = error;
            }
            if (reqIdsWithoutResponse.size === 0) {
                unsubscribe();
                const failedRequestResponses: UpdateMarkupResponse[] = Object.entries(
                    failedReqIds,
                ).map(([failedReqId, errorCode]) => ({
                    requestBody: requestsObj[Number(failedReqId)],
                    errorCode,
                }));
                const succededRequestResponses: UpdateMarkupResponse[] = Object.entries(requestsObj)
                    .filter(([requestId]) => !failedReqIds[Number(requestId)])
                    .map(([, requestBody]) => ({
                        requestBody,
                    }));

                const result: UpdateMarkupResponse[] = [
                    ...failedRequestResponses,
                    ...succededRequestResponses,
                ];

                resolve(result);
            }
        });
    });

const getUpdateMarkupApiMethod = (requestsBodies: UpdateMarkupRequestBody[]) =>
    requestsBodies[0].askMarkup === undefined ? "delInstrumentMarkups" : "addInstrumentMarkups";

const UPDATE_MARKUP_REQUESTS_IN_CHUCK = 5;
export const updateMarkups = async (requestsBodies: UpdateMarkupRequestBody[]) => {
    const apiMethod = getUpdateMarkupApiMethod(requestsBodies);
    const requestsBodiesWithReqIds = requestsBodies.map((requestBody) => ({
        reqId: getNextReqId(),
        requestBody,
    }));

    let startIndex = 0;
    let endIndex = Math.min(requestsBodiesWithReqIds.length, UPDATE_MARKUP_REQUESTS_IN_CHUCK);

    const send = () =>
        setTimeout(() => {
            for (let i = startIndex; i < endIndex; i++) {
                const { reqId, requestBody } = requestsBodiesWithReqIds[i];
                actionChannel.put(
                    sendRESTRequestViaWS({ method: apiMethod, reqId, content: requestBody }),
                );
            }

            if (endIndex === requestsBodiesWithReqIds.length) {
                return;
            }

            startIndex = endIndex;
            endIndex = Math.min(
                requestsBodiesWithReqIds.length,
                endIndex + UPDATE_MARKUP_REQUESTS_IN_CHUCK,
            );
            send();
        }, 50);
    send();

    return waitMarkupsOutput(requestsBodiesWithReqIds);
};
