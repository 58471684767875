import { Level } from "types";
import { NOT_ENOUGH_LIQUIDITY } from "../../utils";

export const weightedAverageBySize = ({
    size,
    orderBookLevels,
}: {
    size: bigint;
    orderBookLevels: Level[];
}): bigint => {
    let remainderSize = size;
    let totalVolume = 0n;
    let totalSize = 0n;

    for (const [bookPrice, bookSize] of orderBookLevels) {
        if (remainderSize <= 0) {
            break;
        }

        const chunkSize = BigInt(remainderSize < bookSize ? remainderSize : bookSize);
        remainderSize -= BigInt(chunkSize);
        totalVolume += chunkSize * BigInt(bookPrice);
        totalSize += chunkSize;
    }

    if (remainderSize) {
        return NOT_ENOUGH_LIQUIDITY;
    }
    if (!totalSize) {
        return 0n;
    }

    return totalVolume / totalSize;
};
