import { Link, VStack } from "@fm-frontend/uikit";
import styled from "styled-components";

const Container = styled(VStack)`
    background-color: ${(p) => p.theme.colors.ui4};
    border-radius: 12px;
    height: 110px;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const Title = styled.span`
    color: ${(p) => p.theme.colors.ui100};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
`;

const Content = styled.span`
    color: ${(p) => p.theme.colors.ui52};
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
`;

export const MasterRfqTrading = () => (
    <Container>
        <Title>Authorized trading will be available here soon</Title>
        <Content>
            If you demand this functionality please reach us via{" "}
            <Link href="https://help@finerymarkets.com" target="_blank">
                help@finerymarkets.com
            </Link>
        </Content>
    </Container>
);
