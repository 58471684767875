import {
    DatePicker,
    ExportCSV,
    HStack,
    IconButton,
    Icons,
    RefreshButton,
    Tab,
    TabContext,
    TabList,
    Tooltip,
} from "@fm-frontend/uikit";
import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { CpDropdownSelector } from "components/Selectors/CpDropdown";
import { InstrumentDropdownSelector } from "components/Selectors/InstrumentDropdown";
import { EMPTY_ARRAY } from "const";
import { StatusOfRfqHistoryItem } from "feature/rfq/apiHooks/useLastRfqTrades";
import { getConfirmModalAction } from "hooks/useModalCloseWithConfirm";
import React, { ComponentProps, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useIsSubaccountUser, usePrimeBrokerViewType } from "store/hooks";
import { Spacer } from "style";
import styled, { useTheme } from "styled-components";
import { HistoryTab } from "./hooks";
import { OrderTypeSelector } from "./OrderTypeSelector";
import { TradesHistoryMenu } from "./TradesHistoryMenu";

const GroupingTab = styled(TabList)`
    width: fit-content;
`;

const ExportCounterpartiesTitle = ({ historyType }: { historyType: HistoryTab }) => {
    const theme = useTheme();
    return (
        <>
            Export{" "}
            <span style={{ color: theme.colors.brand100 }}>
                {historyType === HistoryTab.RFQ ? "RFQ trades of counterparties" : "Counterparties"}
            </span>{" "}
            trades in .CSV
        </>
    );
};

const ExportSubaccountsTitle = ({ historyType }: { historyType: HistoryTab }) => {
    const theme = useTheme();
    return (
        <>
            Export{" "}
            <span style={{ color: theme.colors.subpurple100 }}>
                {historyType === HistoryTab.RFQ ? "RFQ trades of sub-accounts" : "Sub-accounts"}
            </span>{" "}
            trades in .CSV
        </>
    );
};

const ExportTitle = ({ historyType }: { historyType: HistoryTab }) => {
    const primeBrokerViewType = usePrimeBrokerViewType();

    if (primeBrokerViewType === "subaccounts") {
        return <ExportSubaccountsTitle historyType={historyType} />;
    }

    return <ExportCounterpartiesTitle historyType={historyType} />;
};

export type Range = { startDate?: Date | null; endDate?: Date | null };

export enum GroupingOrder {
    ByOrder = "byOrder",
    ByTrade = "byTrade",
}

type HeaderProps = {
    range: Range;
    groupingOrder: GroupingOrder;
    counterparties: number[];
    instruments: string[];
    historyType: HistoryTab;
    onHistoryTypeChange: (historyType: HistoryTab) => void;
    selectedCounterparties: number[];
    selectedInstruments: string[];
    selectedStatuses?: StatusOfRfqHistoryItem[];
    isRefreshing: boolean;
    getExportData: ComponentProps<typeof ExportCSV>["getData"];
    onRangeChange: ({}: Range) => void;
    onRangeReset: () => void;
    onFilterReset: () => void;
    onGroupingOrderChange: (groupingOrder: GroupingOrder) => void;
    onSelectedCounterpartiesChange: (counterparties: number[]) => void;
    onSelectedInstrumentsChange: (instruments: string[]) => void;
    onSelectedStatusesChange?: (statuses: StatusOfRfqHistoryItem[]) => void;
    selectedOrderTypes?: string[];
    onSelectedOrderTypesChange?: (orderTypes: string[]) => void;
    onRefresh: () => void;
};

const options: DropdownOption<StatusOfRfqHistoryItem>[] = [
    { text: "Executed", value: "COMMITTED" },
    { text: "Cancelled", value: "CANCELED" },
    { text: "Created", value: "CREATED" },
    { text: "Expired", value: "EXPIRED" },
];

export const Header: React.FC<HeaderProps> = ({
    groupingOrder,
    range,
    counterparties,
    instruments,
    historyType,
    onHistoryTypeChange,
    selectedCounterparties,
    selectedInstruments,
    selectedStatuses,
    isRefreshing,
    getExportData,
    onGroupingOrderChange,
    onRangeChange,
    onRangeReset,
    onFilterReset,
    onSelectedCounterpartiesChange,
    onSelectedInstrumentsChange,
    onSelectedStatusesChange,
    selectedOrderTypes,
    onSelectedOrderTypesChange,
    onRefresh,
}) => {
    const dispatch = useDispatch();
    const isSubaccount = useIsSubaccountUser();
    const [refreshDate, setRefreshDate] = useState<Date>();
    const { control, watch, setValue } = useForm({
        defaultValues: {
            selectedCounterparties,
            selectedInstruments,
            selectedOrderTypes: selectedOrderTypes ?? (EMPTY_ARRAY as string[]),
            selectedStatuses,
        },
    });

    const formSelectedCounterparties = watch("selectedCounterparties");
    const formSelectedInstruments = watch("selectedInstruments");
    const formSelectedOrderTypes = watch("selectedOrderTypes");

    const isDirty =
        formSelectedCounterparties.length > 0 ||
        formSelectedInstruments.length > 0 ||
        formSelectedOrderTypes.length > 0 ||
        (selectedStatuses?.length ?? 0) > 0;

    useEffect(
        () => onSelectedCounterpartiesChange(formSelectedCounterparties),
        [formSelectedCounterparties, onSelectedCounterpartiesChange],
    );
    useEffect(
        () => onSelectedInstrumentsChange(formSelectedInstruments),
        [formSelectedInstruments, onSelectedInstrumentsChange],
    );
    useEffect(
        () => onSelectedOrderTypesChange?.(formSelectedOrderTypes),
        [formSelectedOrderTypes, onSelectedOrderTypesChange],
    );
    useEffect(() => {
        if (!isRefreshing) {
            setRefreshDate(new Date());
        }
    }, [isRefreshing]);

    const handleResetFilters = useCallback(() => {
        onRangeReset();
        onFilterReset();
        setValue("selectedCounterparties", []);
        setValue("selectedInstruments", []);
        setValue("selectedOrderTypes", []);
    }, [onRangeReset, onFilterReset]);

    const confirmCloseExport = (cb: () => void) => dispatch(getConfirmModalAction(cb));

    return (
        <TabContext value={groupingOrder} handleClick={onGroupingOrderChange}>
            <HStack padding={12} paddingBottom={0} width="100%" justifyContent="space-between">
                <TradesHistoryMenu
                    historyType={historyType}
                    onHistoryTypeChange={onHistoryTypeChange}
                />
                <HStack spacing={8}>
                    <ExportCSV
                        key={`${range.startDate}-${range.endDate}`}
                        size="small"
                        getData={getExportData}
                        title={<ExportTitle historyType={historyType} />}
                        defaultStartDate={range.startDate}
                        defaultEndDate={range.endDate}
                        onConfirm={confirmCloseExport}
                    />
                </HStack>
            </HStack>
            <HStack
                padding={12}
                paddingTop={0}
                spacing={8}
                width="100%"
                justifyContent="space-between"
            >
                {historyType === HistoryTab.FirmBook && (
                    <GroupingTab size="small">
                        <Tab value={GroupingOrder.ByOrder} title="Orders" />
                        <Tab value={GroupingOrder.ByTrade} title="Trades" />
                    </GroupingTab>
                )}
                <DatePicker
                    key={`${range.startDate}-${range.endDate}`}
                    startDate={range.startDate}
                    endDate={range.endDate}
                    onConfirm={onRangeChange}
                    onReset={onRangeReset}
                />
                {!isSubaccount && (
                    <CpDropdownSelector
                        name="selectedCounterparties"
                        control={control}
                        cpsIds={counterparties}
                    />
                )}
                <InstrumentDropdownSelector
                    name="selectedInstruments"
                    control={control}
                    instruments={instruments}
                />
                {onSelectedStatusesChange && (
                    <MultipleDropdown
                        values={selectedStatuses}
                        onChange={onSelectedStatusesChange}
                        renderTrigger={(trigger) => (
                            <MultipleDropdown.Trigger {...trigger} size="small" variant="simple">
                                <MultipleDropdown.TriggerEssence
                                    {...trigger}
                                    option={trigger.selectedOptions}
                                    size="small"
                                />
                            </MultipleDropdown.Trigger>
                        )}
                        options={options}
                        align="end"
                        caption="Status"
                        asFilter
                    >
                        <MultipleDropdown.BasicSheet size="small" options={options} />
                    </MultipleDropdown>
                )}
                {onSelectedOrderTypesChange !== undefined && (
                    <OrderTypeSelector name="selectedOrderTypes" control={control} />
                )}
                {isDirty && (
                    <Tooltip content="Reset filter" align="center">
                        <IconButton
                            variant="plain"
                            type="button"
                            Icon={Icons.Recent}
                            onClick={handleResetFilters}
                        />
                    </Tooltip>
                )}
                <Spacer />
                <RefreshButton
                    refreshDate={refreshDate}
                    refreshing={isRefreshing}
                    onRefresh={onRefresh}
                />
            </HStack>
        </TabContext>
    );
};
