import { ClientId, H2, HStack, Icons, Mark, PBold, PrimaryButton, PSmall, VStack } from "@fm-frontend/uikit";
import { useCopyToClipboard } from "@fm-frontend/utils";
import { createNotification } from "feature/app";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import React from "react";
import { useDispatch } from "react-redux";
import styled, { useTheme } from "styled-components";
import {
    accountSectionItem,
    assetSectionItem,
    bankLegalAddressSectionItem,
    bankNameSectionItem,
    companyLegalAddressSectionItem,
    companyNameSectionItem,
    FormSectionDivider,
    otherDetailsSectionItem,
    publicDetailsSectionItem,
    SectionItem,
    TSectionItem,
} from "../../components";
import { AccordionCell } from "../../components/AccordionCell";
import { BanksStatusCell } from "../../components/BanksStatusCell";
import { ContentCell } from "../../components/ContentCell";
import { AccountStatusType, AddressSide, BankAddress, CpRevisionHash } from "../../types";
import { ALL_ACCOUNT_INFO_COPIED_TEXT, emptyStringSubstitution } from "../../utils";

type OverviewBankAddressModalProps = {
    side: AddressSide;
    address: BankAddress;
    actions: () => React.ReactNode;
};

const AddressConfirmCpContainer = styled(HStack)<{ status: AccountStatusType }>`
    flex-wrap: wrap;
    row-gap: 6px;
    column-gap: 12px;

    ${PBold} {
        color: ${(p) => {
            switch (p.status) {
                case AccountStatusType.Approved: {
                    return p.theme.colors.positive100;
                }
                case AccountStatusType.Verification: {
                    return p.theme.colors.process100;
                }
                default: {
                    return null;
                }
            }
        }};
    }
`;

const AddressConfirmCp: React.FC<{ status: AccountStatusType; revisions: CpRevisionHash[] }> = ({
    status,
    revisions,
}) => {
    const { getCpName } = useCpInfoHelpers();

    if (revisions.length === 0) {
        return null;
    }

    return (
        <AddressConfirmCpContainer status={status}>
            {revisions.map(({ cpId }) => (
                <HStack spacing={6} alignItems="center">
                    {<PBold>{getCpName(cpId, "full")}</PBold>}
                    <ClientId id={cpId} />
                </HStack>
            ))}
        </AddressConfirmCpContainer>
    );
};

export const StatusOverview: React.FC<{ status: AccountStatusType; revisions: CpRevisionHash[] }> = ({
    status,
    revisions,
}) => {
    const { colors } = useTheme();
    const approved = revisions.filter(({ revisionHash }) => revisionHash);
    const pending = revisions.filter(({ revisionHash }) => !revisionHash);

    return (
        <AccordionCell
            paddingY={6}
            paddingX={12}
            renderAccordionContent={() => (
                <VStack paddingY={6} paddingX={12} spacing={6}>
                    {Boolean(approved.length) && (
                        <ContentCell
                            title="Approved"
                            renderTitle={(title) => (
                                <HStack>
                                    <PSmall color={colors.ui72}>
                                        {title} <Mark color={colors.ui52}>{approved.length}</Mark>
                                    </PSmall>
                                </HStack>
                            )}
                            renderContent={() => (
                                <VStack paddingY={3}>
                                    <AddressConfirmCp status={AccountStatusType.Approved} revisions={approved} />
                                </VStack>
                            )}
                        />
                    )}
                    {Boolean(pending.length) && (
                        <ContentCell
                            title="Pending approval"
                            renderTitle={(title) => (
                                <HStack>
                                    <PSmall color={colors.ui72}>
                                        {title} <Mark color={colors.ui52}>{pending.length}</Mark>
                                    </PSmall>
                                </HStack>
                            )}
                            renderContent={() => (
                                <VStack paddingY={3}>
                                    <AddressConfirmCp status={AccountStatusType.Verification} revisions={pending} />
                                </VStack>
                            )}
                        />
                    )}
                </VStack>
            )}
        >
            <ContentCell
                title="Status"
                renderContent={() => <BanksStatusCell status={status} revisions={revisions} />}
            />
        </AccordionCell>
    );
};

export const OverviewBankAddressModal: React.FC<OverviewBankAddressModalProps> = ({ actions, address, side }) => {
    const { colors } = useTheme();
    const dispatch = useDispatch();
    const copyToClipboard = useCopyToClipboard();
    const isYourSide = side === "your";

    const { wallet, currency, bankPrimary, otherDetails, publicDetails, company, revisions, status } = address ?? {};

    const sectionOne: TSectionItem[] = [
        assetSectionItem(currency?.name),
        accountSectionItem(wallet),
        bankNameSectionItem(bankPrimary?.name),
        bankLegalAddressSectionItem(bankPrimary?.address),
    ];
    const sectionTwo: TSectionItem[] = [
        companyNameSectionItem(company?.name),
        companyLegalAddressSectionItem(company?.address),
    ];
    const sectionThree: TSectionItem[] = [
        otherDetailsSectionItem(otherDetails),
        publicDetailsSectionItem(publicDetails),
    ];

    const handleAllInfoCopy = () => {
        const copyText = [...sectionOne, ...sectionTwo, ...sectionThree].reduce(
            (acc, { title, copyTitle, value, copyValue }) => {
                acc += `${copyTitle ?? title}:\n${emptyStringSubstitution(copyValue ?? value)}\n`;

                return acc;
            },
            "",
        );

        copyToClipboard(copyText, () => {
            dispatch(
                createNotification({
                    type: "success",
                    content: ALL_ACCOUNT_INFO_COPIED_TEXT,
                }),
            );
        });
    };

    return (
        <VStack minWidth="360px" asCard>
            <VStack padding={12}>
                <H2>{isYourSide ? "Your account overview" : "Account overview"}</H2>
            </VStack>
            <VStack paddingBottom={12}>
                {isYourSide && <StatusOverview status={status} revisions={revisions} />}
                {sectionOne.map((sectionItem) => (
                    <SectionItem key={sectionItem.title} sectionItem={sectionItem} />
                ))}
                <FormSectionDivider />
                {sectionTwo.map((sectionItem) => (
                    <SectionItem key={sectionItem.title} sectionItem={sectionItem} />
                ))}
                <FormSectionDivider />
                {sectionThree.map((sectionItem) => (
                    <SectionItem key={sectionItem.title} sectionItem={sectionItem} />
                ))}
            </VStack>
            <VStack paddingX={12} paddingBottom={16} spacing={8}>
                <PrimaryButton fullWidth size="large" type="submit" onClick={handleAllInfoCopy}>
                    <Icons.Copy color={colors.uiWhite100} />
                    Copy All
                </PrimaryButton>
                {actions()}
            </VStack>
        </VStack>
    );
};
