export const downloadBlob = (content: string, filename: string, contentType: string) => {
    const blob = new Blob([content], { type: contentType });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    link.click();
};

export const downloadCsv = (content: string, filename: string) =>
    downloadBlob(content, `${filename}.csv`, "text/csv;charset=utf-8;");
