import { when } from "@fm-frontend/utils";
import React from "react";
import styled from "styled-components";
import { ErrorLine } from "./ErrorLine";
import { LightboxErrorContainer } from "./styled";

const ErrorTr = styled.tr`
    td {
        padding-bottom: 8px;
    }
`;

export const BankAddressRow: React.FC<{
    error: string | undefined;
    animateShowUp?: boolean;
}> = ({ error, animateShowUp = false, children }) => {
    const [rowFormIdElement, ...rest] = React.Children.toArray(children);

    return (
        <LightboxErrorContainer isError={Boolean(error)} animateShowUp={animateShowUp}>
            {rowFormIdElement}
            {rest}
            {when(
                error,
                <ErrorTr>
                    <td colSpan={8}>
                        <ErrorLine error={error} />
                    </td>
                </ErrorTr>,
            )}
        </LightboxErrorContainer>
    );
};
