import { EmDash } from "@fm-frontend/utils";
import styled from "styled-components";
import { Tooltip } from "../../../common";
import { VStack } from "../../../layout";
import { Container, DiffContainer, NewValue, NormalValue, OldValue } from "./styled";

type DataCellProps = {
    diff: boolean;
    oldValue: string | undefined;
    newValue: string | undefined;
    errors: string[];
    formatValue?: (value: string | undefined) => string | undefined;
    className?: string;
};

const StyledTooltip = styled(Tooltip)`
    width: 100%;
    height: 100%;
`;

export const DataCell = ({
    diff,
    oldValue,
    newValue,
    errors,
    formatValue,
    className,
}: DataCellProps) => {
    const formatedOldValue = formatValue ? formatValue(oldValue) : oldValue;
    const formatedNewValue = formatValue ? formatValue(newValue) : newValue;
    const hasError = Boolean(errors.length);

    if (!diff && !hasError) {
        return (
            <Container className={className}>
                <NormalValue>{formatedNewValue ?? EmDash}</NormalValue>
            </Container>
        );
    }

    return (
        <DiffContainer hasError={hasError} className={className}>
            <StyledTooltip
                content={hasError ? errors.join("\n") : undefined}
                positions={["bottom"]}
                align="center"
            >
                <VStack>
                    {formatedOldValue && <OldValue>{formatedOldValue}</OldValue>}
                    <NewValue>{formatedNewValue || EmDash}</NewValue>
                </VStack>
            </StyledTooltip>
        </DiffContainer>
    );
};
