export const MAX_TOLERANCE_PERCENT = 5;
export const TOLERANCE_PERCENT_SIGNIFICANT_DIGITS = 2;
export const TOLERANCE_MULTIPLICATOR = 1e4;
export const TOLERANCE_STEP = 1e2;

export const incrementTolerance = (tolerance: string | number) => {
    let parsedTolerance = parseFloat(String(tolerance));
    parsedTolerance =
        (Number.isNaN(parsedTolerance) ? 0 : parsedTolerance) * TOLERANCE_MULTIPLICATOR +
        TOLERANCE_STEP;

    if (parsedTolerance > MAX_TOLERANCE_PERCENT * TOLERANCE_MULTIPLICATOR) {
        parsedTolerance = MAX_TOLERANCE_PERCENT * TOLERANCE_MULTIPLICATOR;
    }

    return (parsedTolerance / TOLERANCE_MULTIPLICATOR).toFixed(
        TOLERANCE_PERCENT_SIGNIFICANT_DIGITS,
    );
};

export const decrementTolerance = (tolerance: string | number) => {
    let parsedTolerance = parseFloat(String(tolerance));
    parsedTolerance =
        (Number.isNaN(parsedTolerance) ? 0 : parsedTolerance) * TOLERANCE_MULTIPLICATOR -
        TOLERANCE_STEP;

    if (parsedTolerance <= 0) {
        return "";
    }

    return (parsedTolerance / TOLERANCE_MULTIPLICATOR).toFixed(
        TOLERANCE_PERCENT_SIGNIFICANT_DIGITS,
    );
};
