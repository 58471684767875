import { post } from "api";
import { getFullIntent } from "components/modal/utils";
import { setConfirmModalContent } from "feature/app";
import { DeleteButton, SmallButton } from "feature/form/Buttons";
import { Table } from "feature/table";
import { useDispatch } from "react-redux";
import { useOrdersSnapshot } from "store/hooks";
import { Order } from "types";
import { displayError } from "utils";
import { columns } from "./table";

export const OrdersTable = () => {
    const dispatch = useDispatch();
    const ordersSnapshot = useOrdersSnapshot();
    // TODO temporary solution for liveupdating table
    const last50Orders = ordersSnapshot.data.slice(-50).reverse();

    const handleDeleteAllOrders = () => {
        dispatch(
            setConfirmModalContent({
                title: "Delete Orders",
                onConfirm: () => post("delAll", {}).catch(displayError),
                description: getFullIntent("delete all orders"),
                confirmButtonTitle: "Delete",
            }),
        );
    };

    return (
        <Table
            title="Open orders"
            columns={columns}
            data={last50Orders}
            data-test-id="orders"
            isLoading={ordersSnapshot.isLoading}
            actions={(order: Order) => (
                <DeleteButton
                    onClick={() => {
                        dispatch(
                            setConfirmModalContent({
                                title: "Delete Order",
                                onConfirm: () =>
                                    post("del", {
                                        orderId: order[4],
                                    }),
                                description: getFullIntent("delete this order"),
                                confirmButtonTitle: "Delete",
                            }),
                        );
                    }}
                />
            )}
            globalActions={
                ordersSnapshot.data.length > 0 && <SmallButton onClick={handleDeleteAllOrders}>Delete all</SmallButton>
            }
        />
    );
};
