import { Flex, Icons, Loading, Tooltip } from "@fm-frontend/uikit";
import { SIZE64 } from "@fm-frontend/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { EmDash } from "const";
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import { RfqHistoryItem, StatusOfRfqHistoryItem } from "feature/rfq/apiHooks/useLastRfqTrades";
import { useInstruments } from "hooks";
import { useSize64AssetFormatHelpers } from "hooks/useSize64AssetFormatHelpers";
import styled, { useTheme } from "styled-components";

const columnHelper = createColumnHelper<RfqHistoryItem>();

const SIDE_TITLES: Record<RfqHistoryItem["side"], string> = {
    BUY: "Buy",
    SELL: "Sell",
};

const useStatusSettings = (): Record<
    StatusOfRfqHistoryItem,
    { title: string; color: string; bgColor: string }
> => {
    const { colors } = useTheme();

    return {
        CREATED: {
            title: "Created",
            color: colors.ui52,
            bgColor: colors.ui4,
        },
        CANCELED: {
            title: "Cancelled",
            color: colors.ui52,
            bgColor: colors.ui4,
        },
        EXPIRED: {
            title: "Expired",
            color: colors.ui52,
            bgColor: colors.ui4,
        },
        COMMITTED: {
            title: "Executed",
            color: colors.positive100,
            bgColor: colors.positive4,
        },
    };
};

const StatusSpecificContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    line-height: 16px;
    padding: 0 4px;
    border-radius: 4px;
    background-color: ${(p) => p.theme.colors.ui4};
    height: 16px;
`;

const CancelIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_8482_14649)">
            <path
                d="M12.0001 10.8003L9.20004 8.00022L12.0001 5.20016C12.3601 4.84014 12.3601 4.36012 12.0001 4.00011C11.6401 3.6401 11.1601 3.6401 10.8001 4.00011L8 6.80018L5.19993 4.00011C4.83992 3.6401 4.3599 3.6401 3.99989 4.00011C3.63988 4.36012 3.63988 4.84014 3.99989 5.20016L6.79996 8.00022L3.99989 10.8003C3.63988 11.1603 3.63988 11.6403 3.99989 12.0003C4.3599 12.3603 4.83992 12.3603 5.19993 12.0003L8 9.20027L10.8001 12.0003C11.1601 12.3603 11.6401 12.3603 12.0001 12.0003C12.3601 11.6403 12.3601 11.1603 12.0001 10.8003Z"
                fill="#243034"
                fill-opacity="0.32"
            />
        </g>
        <defs>
            <clipPath id="clip0_8482_14649">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
const Cancelled = () => {
    return (
        <Tooltip content="Cancelled">
            <StatusSpecificContainer>
                <CancelIcon />
            </StatusSpecificContainer>
        </Tooltip>
    );
};

const CommittedContainer = styled(StatusSpecificContainer)`
    background-color: ${(p) => p.theme.colors.positive8};
`;
const Committed = () => {
    const theme = useTheme();

    return (
        <Tooltip content="Executed">
            <CommittedContainer>
                <Icons.Checkmark color={theme.colors.positive100} />
            </CommittedContainer>
        </Tooltip>
    );
};

const CreatedContainer = styled(StatusSpecificContainer)`
    background-color: ${(p) => p.theme.colors.process4};
    padding: 0 7px;
`;
const Created = () => {
    const theme = useTheme();

    return (
        <Tooltip content="Created">
            <CreatedContainer>
                <Loading size={10} strokeWidth={1.5} color={theme.colors.process100} />
            </CreatedContainer>
        </Tooltip>
    );
};

const ExpiredIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.44708 2.21967C4.73997 2.51256 4.73997 2.98744 4.44708 3.28033L2.69708 5.03033C2.40418 5.32322 1.92931 5.32322 1.63642 5.03033C1.34352 4.73744 1.34352 4.26256 1.63642 3.96967L3.38642 2.21967C3.67931 1.92678 4.15418 1.92678 4.44708 2.21967ZM11.5531 2.21967C11.846 1.92678 12.3209 1.92678 12.6137 2.21967L14.3637 3.96967C14.6566 4.26256 14.6566 4.73744 14.3637 5.03033C14.0709 5.32322 13.596 5.32322 13.3031 5.03033L11.5531 3.28033C11.2602 2.98744 11.2602 2.51256 11.5531 2.21967ZM4.16992 4.75317C5.18574 3.73735 6.56349 3.16667 8.00008 3.16667C9.43667 3.16667 10.8144 3.73735 11.8302 4.75317C12.8461 5.76899 13.4167 7.14674 13.4167 8.58333C13.4167 9.76756 13.029 10.9118 12.3237 11.8463L13.1971 12.7197C13.49 13.0126 13.49 13.4874 13.1971 13.7803C12.9042 14.0732 12.4293 14.0732 12.1364 13.7803L11.263 12.9069C10.3286 13.6122 9.18431 14 8.00008 14C6.81586 14 5.67161 13.6122 4.73713 12.9069L3.86374 13.7803C3.57085 14.0732 3.09598 14.0732 2.80308 13.7803C2.51019 13.4874 2.51019 13.0126 2.80308 12.7197L3.67647 11.8463C2.97121 10.9118 2.58341 9.76756 2.58341 8.58333C2.58341 7.14674 3.1541 5.76899 4.16992 4.75317ZM8.00008 4.66667C6.96132 4.66667 5.9651 5.07931 5.23058 5.81383C4.49606 6.54835 4.08341 7.54457 4.08341 8.58333C4.08341 9.6221 4.49606 10.6183 5.23058 11.3528C5.9651 12.0874 6.96132 12.5 8.00008 12.5C9.03885 12.5 10.0351 12.0874 10.7696 11.3528C11.5041 10.6183 11.9167 9.6221 11.9167 8.58333C11.9167 7.54457 11.5041 6.54835 10.7696 5.81383C10.0351 5.07931 9.03885 4.66667 8.00008 4.66667Z"
            fill="#243034"
            fillOpacity="0.32"
        />
        <path
            d="M7.53041 6.96967C7.23752 6.67678 6.76264 6.67678 6.46975 6.96967C6.17686 7.26256 6.17686 7.73744 6.46975 8.03033L6.93942 8.5L6.46975 8.96967C6.17686 9.26256 6.17686 9.73744 6.46975 10.0303C6.76264 10.3232 7.23752 10.3232 7.53041 10.0303L8.00008 9.56066L8.46975 10.0303C8.76264 10.3232 9.23752 10.3232 9.53041 10.0303C9.82331 9.73744 9.82331 9.26256 9.53041 8.96967L9.06074 8.5L9.53041 8.03033C9.82331 7.73744 9.82331 7.26256 9.53041 6.96967C9.23752 6.67678 8.76264 6.67678 8.46975 6.96967L8.00008 7.43934L7.53041 6.96967Z"
            fill="#243034"
            fillOpacity="0.32"
        />
    </svg>
);
const Expired = () => (
    <Tooltip content="Expired">
        <StatusSpecificContainer>
            <ExpiredIcon />
        </StatusSpecificContainer>
    </Tooltip>
);

const StatusContainer = styled.div<{ $color: string; $bgColor: string }>`
    display: inline-block;
    padding: 0 2px;
    border-radius: 4px;
    color: ${({ $color }) => $color};
    background-color: ${({ $bgColor }) => $bgColor};
`;
export const Status = ({ status }: { status: StatusOfRfqHistoryItem }) => {
    const settingsObj = useStatusSettings();

    if (status === "CREATED") {
        return <Created />;
    }
    if (status === "CANCELED") {
        return <Cancelled />;
    }
    if (status === "COMMITTED") {
        return <Committed />;
    }
    if (status === "EXPIRED") {
        return <Expired />;
    }

    const { title, color, bgColor } = settingsObj[status];

    return (
        <StatusContainer $color={color} $bgColor={bgColor}>
            {title}
        </StatusContainer>
    );
};

const InstrumentCell = styled.div`
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
`;
const SidePriceCell = styled.div<{ $side: RfqHistoryItem["side"] }>`
    color: ${({ $side, theme: { colors } }) =>
        $side === "BUY" ? colors.positive100 : colors.negative100};
    font-size: 12px;
    line-height: 16px;
`;
const SizeCell = styled.div`
    font-size: 12px;
    line-height: 16px;
`;

const DateTimeCellContainer = styled(Flex)`
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;

    flex-direction: column;
`;
const Time = styled.span`
    color: ${(p) => p.theme.colors.ui100};
`;
const Date = styled.span`
    color: ${(p) => p.theme.colors.ui52};
`;
const DateTimeCell = ({ dateTime }: { dateTime: number }) => {
    const timeStr = format(dateTime, "HH:mm:ss");

    if (isToday(dateTime)) {
        return (
            <DateTimeCellContainer>
                <Time>{timeStr}</Time>
            </DateTimeCellContainer>
        );
    }

    const dateStr = format(dateTime, "dd LLL");

    return (
        <DateTimeCellContainer>
            <Time>{timeStr}</Time>
            <Date>{dateStr}</Date>
        </DateTimeCellContainer>
    );
};

export const tableColumns = [
    columnHelper.accessor((row) => row.date, {
        id: "time",
        cell: (rowData) => {
            const createdAt = rowData.getValue();
            return <DateTimeCell dateTime={createdAt} />;
        },
        header: "Time",
        meta: {
            cellStyleProps: {
                width: "58px",
                textAlign: "left",
            },
            headerStyleProps: {
                width: "58px",
                textAlign: "left",
            },
            headerSmall: true,
        },
    }),
    columnHelper.accessor((row) => row.instrumentName, {
        id: "instrument",
        cell: (rowData) => {
            const instrumentName = rowData.getValue();
            return <InstrumentCell>{instrumentName}</InstrumentCell>;
        },
        header: "Instrument",
        meta: {
            cellStyleProps: {
                width: "110px",
                textAlign: "left",
            },
            headerStyleProps: {
                width: "110px",
                textAlign: "left",
            },
            headerSmall: true,
        },
    }),
    columnHelper.accessor((row) => row.side, {
        id: "side",
        cell: (rowData) => {
            const side = rowData.getValue();
            return <SidePriceCell $side={side}>{SIDE_TITLES[side]}</SidePriceCell>;
        },
        header: "Side",
        meta: {
            cellStyleProps: {
                width: "36px",
                textAlign: "left",
            },
            headerStyleProps: {
                width: "36px",
                textAlign: "left",
            },
            headerSmall: true,
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "price",
        cell: (rowData) => {
            const { side, price } = rowData.getValue();
            if (price === 0n) {
                return EmDash;
            }
            return (
                <SidePriceCell $side={side}>{SIZE64.toFormattedDecimalString(price)}</SidePriceCell>
            );
        },
        header: "Price",
        meta: {
            cellStyleProps: {
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
            headerSmall: true,
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "size",
        cell: (rowData) => {
            const { size, instrumentName } = rowData.getValue();
            const { intrumentsByNameObj } = useInstruments();
            const { toFormattedDecimalStringByAsset } = useSize64AssetFormatHelpers();
            return (
                <SizeCell>
                    {toFormattedDecimalStringByAsset(
                        size,
                        intrumentsByNameObj[instrumentName]?.assetCurrency,
                    )}
                </SizeCell>
            );
        },
        header: "Size",
        meta: {
            cellStyleProps: {
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
            headerSmall: true,
        },
    }),
    columnHelper.accessor((row) => row.status, {
        id: "status",
        cell: (rowData) => {
            const status = rowData.getValue();

            return <Status status={status} />;
        },
        header: "Status",
        meta: {
            cellStyleProps: {
                width: "75px",
                maxWidth: "75px",
                textAlign: "right",
            },
            headerStyleProps: {
                width: "75px",
                maxWidth: "75px",
                textAlign: "right",
            },
            headerSmall: true,
        },
    }),
];
