import { EXPAND_TABLE_COLUMN_KEY, Flex, Table, VStack } from "@fm-frontend/uikit";
import { ExpandedState, getCoreRowModel, getExpandedRowModel } from "@tanstack/react-table";
import { TradeHistory } from "feature/history/types";
import { useState } from "react";
import styled from "styled-components";
import { tableColumns } from "./table";

const Container = styled(Flex)`
    overflow: auto;
    padding: 0 1px 1px 1px;
    border-radius: 16px;

    min-height: 320px;
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow-x: auto;
`;

export const LastOrdersTable = ({
    orders,
    isLoading,
}: {
    orders: TradeHistory[];
    isLoading: boolean;
}) => {
    const [expanded, setExpanded] = useState<ExpandedState>({});

    return (
        <Container spacing={8}>
            <CardContainer flex={1}>
                <Table
                    tableOptions={{
                        data: orders,
                        columns: tableColumns,
                        getCoreRowModel: getCoreRowModel(),
                        getSubRows: (row) => row.subRows,
                        getExpandedRowModel: getExpandedRowModel(),
                        state: {
                            expanded,
                        },
                        onExpandedChange: setExpanded,
                    }}
                    paginator={null}
                    onRowClick={(row, cellId) => {
                        if (row.subRows?.length && cellId === EXPAND_TABLE_COLUMN_KEY) {
                            row.toggleExpanded();
                        }
                    }}
                    isLoading={isLoading}
                    condensed
                />
            </CardContainer>
        </Container>
    );
};
