import { ValueFormat } from "@fm-frontend/utils";
import { FC, useMemo } from "react";
import { ErrorCode } from "react-dropzone";
import styled, { css, useTheme } from "styled-components";
import { BasicButton, IconButton as DropzoneButton } from "../buttons";
import { Icons } from "../icons";
import { HStack, VStack } from "../layout";
import { P, PBold, PSmall, PSmallBold } from "../typography";
import { useDropzoneContext } from "./DropzoneContext";
import { UploadFileInfo, UploadingState, useFileContext } from "./FileContext";

export const DropzoneContainer = styled.div<{
    isDragActive: boolean;
    dropzoneState: "initial" | "loaded" | "error";
}>`
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    min-height: 100px;
    height: 100%;
    width: 100%;

    box-shadow: 0 1px 2px 0 ${(p) => p.theme.colors.ui24};
    box-shadow: 0 0.5px 0 0 ${(p) => p.theme.colors.ui4} inset;

    ${(p) =>
        p.dropzoneState === "initial" &&
        css`
            align-items: center;
            justify-content: center;
            border: 1px dashed ${(p) => p.theme.colors.ui20};
            background-color: ${(p) => p.theme.colors.ui4};

            ${p.isDragActive &&
            css`
                background-color: ${(p) => p.theme.colors.ui12};
            `}
        `}
    ${(p) =>
        p.dropzoneState === "loaded" &&
        css`
            ::before {
                content: "";
                position: absolute;
                display: block;

                background: linear-gradient(
                    0deg,
                    ${(p) => p.theme.colors.positive4} 0%,
                    ${(p) => p.theme.colors.positive16} 100%
                );

                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                pointer-events: none;
                transition: right 0.5s ease-out;
            }
        `}
    ${(p) =>
        p.dropzoneState === "error" &&
        css`
            border: 1px dashed ${(p) => p.theme.colors.negative16};

            ::before {
                content: "";
                position: absolute;
                display: block;

                background: linear-gradient(
                    0deg,
                    ${(p) => p.theme.colors.negative4} 0%,
                    ${(p) => p.theme.colors.negative16} 100%
                );
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                pointer-events: none;
                transition: right 0.5s ease-out;
            }
        `}
`;

const ClickContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    cursor: copy;

    :hover {
        background-color: ${(p) => p.theme.colors.ui8};
    }
`;

const FileInfo = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui52};
`;

const FileErrorsContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    max-height: 100px;
`;

const FileInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px;
    overflow: scroll;
    max-height: 100px;

    margin: auto;
`;

const DROPZONE_ERRORS_MAP: Partial<Record<ErrorCode, string>> = {
    [ErrorCode.FileInvalidType]: "The uploaded file is not in CSV format",
};

const formatFileInfo = (fileInfo: UploadFileInfo, uploading: UploadingState) => {
    let info = `File size: ${ValueFormat.formatBytes(fileInfo.size)}`;

    if (uploading.active) {
        info += `, uploaded: ${uploading.percents}%`;
    }

    return info;
};

type ErrorInfoBannerProps = {
    title: string;
    essence?: string;
};

const ErrorInfoBanner: FC<ErrorInfoBannerProps> = ({ title, essence }) => {
    const { colors } = useTheme();

    return (
        <VStack padding={12} maxWidth="430px" spacing={2}>
            <HStack alignItems="flex-start" spacing={4}>
                <Icons.BannerAttention color={colors.negative100} size={16} />
                <PSmallBold color={colors.negative100}>{title}</PSmallBold>
            </HStack>
            {essence && <PSmall color={colors.negative100}>{essence}</PSmall>}
        </VStack>
    );
};

const FileInfoBannerTitle = styled(PBold)`
    max-width: 300px;
`;

type FileInfoProps = {
    fileInfo: UploadFileInfo;
    uploadingState: UploadingState;
};
export const FileInfoBanner: FC<FileInfoProps> = ({ fileInfo, uploadingState }) => {
    return (
        <VStack alignItems="center" spacing={2}>
            <FileInfoBannerTitle ellipsis title={fileInfo.name}>
                <Icons.File /> {fileInfo.name}
            </FileInfoBannerTitle>
            <FileInfo>{formatFileInfo(fileInfo, uploadingState)}</FileInfo>
        </VStack>
    );
};

type DropZoneProps = {
    onCancel: () => void;
};
export const DropZone: FC<DropZoneProps> = ({ onCancel }) => {
    const { dropzone } = useDropzoneContext();
    const { acceptedFilesInfo, rejectedFilesInfo, externalError, uploading } = useFileContext();

    const handleSelectAnotherFile = () => {
        onCancel();
        dropzone?.open();
    };

    const dropzoneState = useMemo(
        () =>
            rejectedFilesInfo.length > 0 || externalError
                ? "error"
                : acceptedFilesInfo.length > 0
                ? "loaded"
                : "initial",
        [rejectedFilesInfo, externalError, acceptedFilesInfo],
    );

    return (
        <DropzoneContainer
            {...dropzone.getRootProps()}
            isDragActive={dropzone.isDragActive}
            dropzoneState={dropzoneState}
        >
            <input {...dropzone.getInputProps()} />
            {dropzoneState === "initial" && (
                <ClickContainer onClick={dropzone.open}>
                    <Icons.UploadCloud />
                    <P>
                        <strong>Select .CSV file</strong> or just drop it here
                    </P>
                </ClickContainer>
            )}
            {dropzoneState === "error" && (
                <>
                    <HStack padding={12} justifyContent="end">
                        <DropzoneButton
                            type="button"
                            variant="plain"
                            Icon={Icons.Bin}
                            onClick={onCancel}
                        />
                    </HStack>

                    {externalError && (
                        <>
                            <FileInfoContainer>
                                {acceptedFilesInfo?.map((info) => (
                                    <FileInfoBanner
                                        key={info.name}
                                        fileInfo={info}
                                        uploadingState={uploading}
                                    />
                                ))}
                            </FileInfoContainer>
                            <FileErrorsContainer>
                                <ErrorInfoBanner title={externalError} />
                            </FileErrorsContainer>
                        </>
                    )}
                    {!externalError && (
                        <>
                            <FileInfoContainer>
                                {rejectedFilesInfo.map((info) => (
                                    <FileInfoBanner
                                        key={info.name}
                                        fileInfo={info}
                                        uploadingState={uploading}
                                    />
                                ))}
                            </FileInfoContainer>

                            <FileErrorsContainer>
                                {rejectedFilesInfo.map(({ errors }, index) => (
                                    <ErrorInfoBanner
                                        key={index}
                                        title="Upload failed"
                                        essence={errors
                                            .map(
                                                (error) =>
                                                    DROPZONE_ERRORS_MAP[error.code as ErrorCode] ??
                                                    error.message,
                                            )
                                            .join(", ")}
                                    />
                                ))}
                            </FileErrorsContainer>
                        </>
                    )}
                    <HStack paddingBottom={12} paddingX={12}>
                        <BasicButton size="small" onClick={handleSelectAnotherFile}>
                            Select another file
                        </BasicButton>
                    </HStack>
                </>
            )}
            {dropzoneState === "loaded" && (
                <>
                    <HStack padding={12} justifyContent="end">
                        <DropzoneButton
                            type="button"
                            variant="plain"
                            Icon={Icons.Bin}
                            onClick={onCancel}
                        />
                    </HStack>
                    <FileInfoContainer>
                        {acceptedFilesInfo.map((info) => (
                            <FileInfoBanner
                                key={info.name}
                                fileInfo={info}
                                uploadingState={uploading}
                            />
                        ))}
                    </FileInfoContainer>
                    <HStack paddingBottom={12} paddingX={12}>
                        <BasicButton size="small" onClick={handleSelectAnotherFile}>
                            Select another file
                        </BasicButton>
                    </HStack>
                </>
            )}
        </DropzoneContainer>
    );
};
