import { useFormCloseConfirmer } from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { useAddressContext } from "../AddressesContext";
import { ACCOUNT_REVISIONS_URL, PAYMENT_ACCOUNTS_RULES_URL, PAYMENT_ACCOUNTS_URL } from "../api";
import { AddressLightBox } from "../components";
import { useAddOrUpdateAddresses, useDeleteAddresses, useRefreshAddresses, useSaveSuccessNotification } from "../hooks";
import { cryptoAddressSchema } from "../schema";
import { CryptoAddress, CryptoAddressFormInput, DeleteAccountType, FormApiErrors, FormValidationError } from "../types";
import { ADDRESSES_ARE_SAVED_NOTIFICATION, SAB_CRYPTO_LIGHTBOX_KEY } from "../utils";
import { CryptoAddressLightBoxContent } from "./CryptoAddressLightBoxContent";
import { CryptoFormContext } from "./form/CryptoFormContext";
import { DEFAULT_CRYPTO_ADDRESS } from "./utils";

type CryptoAddressLightBoxProps = {
    cryptoAddresses: CryptoAddress[];
    onValidate: (addresses: CryptoAddress[], addressesToDelete: DeleteAccountType[]) => FormValidationError[];
};

export const CryptoAddressLightBox: React.FC<CryptoAddressLightBoxProps> = ({ cryptoAddresses, onValidate }) => {
    const { closeLightBox } = useAddressContext();
    const { dirtyRows } = useContext(CryptoFormContext);
    const addOrUpdateAddresses = useAddOrUpdateAddresses();
    const deleteAddresses = useDeleteAddresses();
    const refreshAddresses = useRefreshAddresses();
    const [apiErrors, setApiErrors] = useState<FormApiErrors>({});
    const apiErrorsCount = Object.keys(apiErrors).length;
    const [deleteAddressesList, setDeleteAddressesList] = useState<DeleteAccountType[]>([]);
    const addressesSaveNotification = useSaveSuccessNotification();

    const methods = useForm<CryptoAddressFormInput>({
        defaultValues: {
            addresses: cryptoAddresses.length === 0 ? [{ ...DEFAULT_CRYPTO_ADDRESS }] : cryptoAddresses,
        },
        resolver: yupResolver(cryptoAddressSchema),
        mode: "onSubmit",
    });

    const {
        handleSubmit,
        formState: { isDirty, isSubmitting },
        getValues,
        setError,
        clearErrors,
        watch,
    } = methods;

    const validateAddresses = (addresses: CryptoAddress[]) => {
        const validationErrors = onValidate(addresses, deleteAddressesList);

        addresses.forEach(({ _validationItemId: validationItemId }) => {
            const validationError = validationErrors.find(
                ({ _validationItemId }) => _validationItemId === validationItemId,
            )?.error;
            if (validationError) {
                setError(`root.${validationItemId}` as const, {
                    message: validationError,
                });
            } else {
                clearErrors(`root.${validationItemId}`);
            }
        });
    };

    watch(({ addresses }, { type }) => {
        if (type === "change") {
            validateAddresses(addresses as CryptoAddress[]);
        }
    });

    useFormCloseConfirmer(SAB_CRYPTO_LIGHTBOX_KEY, isDirty);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(isDirty, closeLightBox);

    const totalAddressesCount = getValues().addresses.length;

    const handleLightBoxSubmit = handleSubmit(async ({ addresses }: CryptoAddressFormInput) => {
        const addressesToUpdate = addresses.filter(({ _validationItemId = "" }) => {
            const isDirtyRow = dirtyRows[_validationItemId]?.isDirty;
            const hasApiError = _validationItemId && apiErrors?.[_validationItemId] !== undefined;

            return isDirtyRow || hasApiError;
        });

        const validationErrors = onValidate(addressesToUpdate, deleteAddressesList);

        if (validationErrors.length) {
            validationErrors.forEach(({ _validationItemId, error }) => {
                setError(`root.${_validationItemId}` as const, {
                    message: error,
                });
            });

            return;
        }

        const deleteErrors = await deleteAddresses(deleteAddressesList);
        const updateErrors = await addOrUpdateAddresses(addressesToUpdate);
        await refreshAddresses([PAYMENT_ACCOUNTS_URL, ACCOUNT_REVISIONS_URL, PAYMENT_ACCOUNTS_RULES_URL]);

        setDeleteAddressesList([]);

        const composedApiErrors = {
            ...deleteErrors,
            ...updateErrors,
        };

        setApiErrors(composedApiErrors);

        if (!Object.values(composedApiErrors).length) {
            closeLightBox();
            addressesSaveNotification(ADDRESSES_ARE_SAVED_NOTIFICATION);
        }
    });

    const handleAddressDelete = (id: string, deleteAddress: CryptoAddress) => {
        setDeleteAddressesList((list) => [
            ...list,
            {
                id,
                accountId: deleteAddress.accountId,
                accountRuleId: deleteAddress.rule?.id,
            } as DeleteAccountType,
        ]);
    };

    return (
        <FormProvider {...methods}>
            <AddressLightBox
                totalAddressesCount={totalAddressesCount}
                isSubmitting={isSubmitting}
                apiErrorsCount={apiErrorsCount}
                onSubmit={handleLightBoxSubmit}
                isDisabled={!isDirty}
                onCancel={closeModalWithConfirm}
            >
                <CryptoAddressLightBoxContent onAddressDelete={handleAddressDelete} apiErrors={apiErrors} />
            </AddressLightBox>
        </FormProvider>
    );
};
