export function handleNumberInputChange(
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: (...event: any[]) => void,
    { restrictionPattern }: { restrictionPattern?: RegExp },
) {
    const value = e.target.value ?? "";

    if (value === "" || restrictionPattern === undefined || restrictionPattern.test(value)) {
        onChange(e);
    }
}
