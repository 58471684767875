import React from "react";
import styled from "styled-components";
import { TextSmall } from "../typography";

const Container = styled.div`
    display: flex;
    padding: 4px 8px 4px 4px;
    gap: 4px;

    border: 1px solid ${(p) => p.theme.colors.ui8};
    border-radius: 160px;

    svg,
    img {
        height: 16px;
        width: 16px;
    }
`;

const Label = styled(TextSmall)`
    font-weight: 600;
`;

interface HeaderBadgeProps {
    Icon?: React.ReactNode;
    label: string;
}

export const HeaderBadge: React.VFC<HeaderBadgeProps> = (props) => {
    const { Icon, label } = props;

    const iconContent = Icon ?? null;

    return (
        <Container>
            {iconContent}
            <Label>{label}</Label>
        </Container>
    );
};
