import { POSITION_COUNTERPARTY_ID } from "const/position";
import { isSubaccountCp } from "feature/counterparties/utils";
import { useSelector } from "hooks";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useEffect, useMemo, useState } from "react";
import { useCounterpartyLimitsSnapshot, useIsPrimeBrokerUser, usePrimeBrokerViewType } from "store/hooks";
import { ClientType } from "types";
import { composeUsername } from "utils";

export const useCounterpartiesOptions = (clientTypeFilter?: ClientType) => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const { getCpType } = useCpInfoHelpers();

    const { data: counterpartiesLimits } = useCounterpartyLimitsSnapshot();
    const counterparties: number[] = useMemo(
        () => counterpartiesLimits?.map((limit) => Number(limit[POSITION_COUNTERPARTY_ID])) ?? [],
        [counterpartiesLimits],
    );

    const [filteredCounterparties, setFilteredCounterparties] = useState<number[]>([]);
    useEffect(() => {
        if (isPrimeBrokerUser || clientTypeFilter) {
            const cpsTypes: any = counterparties.reduce(
                (acc, cpId) => ({
                    ...acc,
                    [cpId]: getCpType(cpId),
                }),
                {},
            );
            if (clientTypeFilter) {
                setFilteredCounterparties(counterparties.filter((cpId) => cpsTypes[cpId] === clientTypeFilter));
                return;
            }
            setFilteredCounterparties(
                counterparties.filter((cpId) =>
                    primeBrokerViewType === "counterparties"
                        ? !isSubaccountCp(cpsTypes[cpId])
                        : isSubaccountCp(cpsTypes[cpId]),
                ),
            );
            return;
        }
        setFilteredCounterparties(counterparties);
    }, [isPrimeBrokerUser, primeBrokerViewType, counterparties, getCpType]);

    const allAvailableCounterpartiesInfo = useSelector((state) => state.app.allAvailableCounterpartiesInfo);
    const counterpartiesOptions = useMemo(
        () =>
            filteredCounterparties
                .map((value: number) => {
                    const username = allAvailableCounterpartiesInfo[value]?.username;
                    return {
                        text: composeUsername(username),
                        value,
                    };
                })
                .sort((a, b) => a.text.localeCompare(b.text)),
        [filteredCounterparties, allAvailableCounterpartiesInfo],
    );

    return counterpartiesOptions;
};
