import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useMemo } from "react";
import {
    SettlementTransactions,
    useFilteredAndExtendedSettlementRequestsSnapshot,
    useFilteredAndExtendedSettlementTransactionsSnapshot,
} from "store/hooks";
import { TypeTransaction, useHistoricalSettlementTransactions } from "store/useHistoricalSettlementTransactions";
import { convertApiRequestsToSettlements, convertApiTransactionsToSettlements } from "./utils";

export const useSettlements = () => {
    // get active transactions
    const { data: filteredAndExtendedTransactions, isLoading: isWsTransactionsLoading } =
        useFilteredAndExtendedSettlementTransactionsSnapshot();
    const filteredSettlementTransactions = useMemo(
        () =>
            [
                filteredAndExtendedTransactions[0],
                ...[filteredAndExtendedTransactions[1], filteredAndExtendedTransactions[2]].map(
                    (extendedSettlementTransactions) =>
                        extendedSettlementTransactions.map(({ settlementTransaction }) => settlementTransaction),
                ),
            ] as SettlementTransactions,
        [filteredAndExtendedTransactions],
    );
    const { getCpName } = useCpInfoHelpers();
    const outgoingTransactions = convertApiTransactionsToSettlements(
        filteredSettlementTransactions[2],
        getCpName,
        true,
        "out",
    );
    const incomingTransactions = convertApiTransactionsToSettlements(
        filteredSettlementTransactions[1],
        getCpName,
        true,
        "in",
    );
    // get historical transactions
    const { data: historicalApiTransactions, isLoading: historicalTransactionsLoading } =
        useHistoricalSettlementTransactions(TypeTransaction.Transactions);
    const historicalTransactions = convertApiTransactionsToSettlements(historicalApiTransactions, getCpName, false);
    // get requests
    const { data: filteredAndExtendedRequests, isLoading: isWsRequestsLoading } =
        useFilteredAndExtendedSettlementRequestsSnapshot();
    const filteredSettlementRequests = useMemo(
        () =>
            [filteredAndExtendedRequests[0], filteredAndExtendedRequests[1]].map((extendedSettlementRequests) =>
                extendedSettlementRequests.map(({ settlementRequest }) => settlementRequest),
            ),
        [filteredAndExtendedRequests],
    );
    const outgoingRequests = convertApiRequestsToSettlements(filteredSettlementRequests[0], getCpName, "out");
    const incomingRequests = convertApiRequestsToSettlements(filteredSettlementRequests[1], getCpName, "in");
    // combine all settlements
    const settlements = [
        ...incomingTransactions,
        ...outgoingTransactions,
        ...historicalTransactions,
        ...incomingRequests,
        ...outgoingRequests,
    ].sort((t1, t2) => t2.createdAt - t1.createdAt);

    return {
        settlements,
        isLoading: isWsTransactionsLoading || isWsRequestsLoading || historicalTransactionsLoading,
    };
};
