import { Skeleton as UiKitSkeleton } from "@fm-frontend/uikit";
import React from "react";
import styled from "styled-components";

const ListContainer = styled.div`
    flex: 1;
`;

const ItemContainer = styled.div`
    display: flex;
    padding: 0 12px;
    flex: 1;
    gap: 12px;
    height: 32px;
    align-items: center;
`;

const Skeleton = styled(UiKitSkeleton)`
    height: 14px;
`;

const LEVEL_DEPTH = 10;

export const Loading: React.FC = () => {
    return (
        <>
            <ListContainer>
                {[...Array(LEVEL_DEPTH).keys()].map((key) => (
                    <ItemContainer key={`bid-${key}`}>
                        <Skeleton width="20%" />
                        <Skeleton width="10%" />
                        <Skeleton width="70%" />
                    </ItemContainer>
                ))}
            </ListContainer>
            <ListContainer>
                {[...Array(LEVEL_DEPTH).keys()].map((key) => (
                    <ItemContainer key={`ask-${key}`}>
                        <Skeleton width="70%" />
                        <Skeleton width="10%" />
                        <Skeleton width="20%" />
                    </ItemContainer>
                ))}
            </ListContainer>
        </>
    );
};
