import { Table, Td, Th } from "@fm-frontend/uikit";
import {
    ColumnDef,
    createColumnHelper,
    ExpandedState,
    getCoreRowModel,
    getExpandedRowModel,
    Row,
} from "@tanstack/react-table";
import { BottomFixTableContainer } from "components/BottomFixTableContainer";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import {
    actionColumn,
    nameColumn,
    statusColumn,
    TableEmptyState,
    toggleColumn,
    walletColumn as generalWalletColumn,
} from "../components";
import { GroupCellWrapper } from "../components/CellWrapper";
import { MemoOrTagCell } from "../components/MemoOrTagCell";
import { NetworkCell } from "../components/NetworkCell";
import { PrivateNoteCell } from "../components/PrivateNoteCell";
import { AccountStatusType, AddressesTableProps, AddressGroup, CryptoAddress, CryptoTableData } from "../types";
import { isAddressGroupType } from "../utils";

const columnHelper = createColumnHelper<CryptoTableData>();

const networkColumn = columnHelper.accessor((row) => row, {
    header: "Network",
    cell: (info) => (
        <GroupCellWrapper
            data={info.getValue()}
            renderItemRow={(itemRow) => <NetworkCell network={itemRow.network?.name} />}
        />
    ),
    enableSorting: false,
    meta: {
        headerStyleProps: {
            width: "140px",
            minWidth: "140px",
            maxWidth: "140px",
        },
        cellStyleProps: {
            width: "140px",
            minWidth: "140px",
            maxWidth: "140px",
        },
    },
});

const privateNoteColumn = columnHelper.accessor((row) => row, {
    header: "Private note",
    cell: (info) => (
        <GroupCellWrapper
            data={info.getValue()}
            renderItemRow={(itemRow) => <PrivateNoteCell privateNote={itemRow.privateNote} />}
        />
    ),
    enableSorting: false,
    meta: {
        headerStyleProps: {
            width: "100%",
            minWidth: "272px",
        },
        cellStyleProps: {
            width: "100%",
            minWidth: "272px",
        },
    },
});
const memoOrTagColumn = columnHelper.accessor((row) => row, {
    header: "Memo or tag",
    cell: (info) => (
        <GroupCellWrapper
            data={info.getValue()}
            renderItemRow={(itemRow) => <MemoOrTagCell memoOrTag={itemRow.memoOrTag} />}
        />
    ),
    enableSorting: false,
    meta: {
        headerStyleProps: {
            width: "120px",
            minWidth: "120px",
            maxWidth: "120px",
        },
    },
});

const Container = styled.div`
    ${Th}:first-of-type {
        padding-left: 8px;
        padding-bottom: 6px;
    }

    ${Th}:last-of-type, ${Td}:last-of-type {
        padding-left: 8px;
        padding-right: 8px;
    }
`;

const CryptoTableView: React.FC<{
    totalAddressesCount: number;
    columns: ColumnDef<any, any>[];
    data: CryptoAddress[];
    isLoading: boolean;
    onRowClick: (row: Row<CryptoTableData>, cellId: string) => void;
}> = ({ totalAddressesCount, columns, data, isLoading, onRowClick }) => {
    const [expanded, setExpanded] = useState<ExpandedState>(true);

    const memoColumns = useMemo(() => [toggleColumn, ...columns], [columns]);
    const memoData: CryptoTableData[] = useMemo(
        () =>
            data.reduce((acc, cur) => {
                const cp = acc.find((a) => a.cp?.id === cur.rule?.cp?.id);

                if (cp) {
                    cp.addresses.push(cur);
                    cp.addresses.sort((a, b) => (a.currency?.name ?? "")?.localeCompare(b.currency?.name ?? ""));
                    cp.count += 1;

                    if (cur.status === AccountStatusType.NeedToConfirm) {
                        cp.needToConfirmCount += 1;
                    }
                } else {
                    acc = [
                        ...acc,
                        {
                            cp: cur.rule?.cp,
                            addresses: [cur],
                            count: 1,
                            needToConfirmCount: cur.status === AccountStatusType.NeedToConfirm ? 1 : 0,
                        } as AddressGroup<CryptoAddress>,
                    ];
                }

                return acc;
            }, [] as AddressGroup<CryptoAddress>[]),
        [data],
    );

    return (
        <Container>
            <Table
                tableOptions={{
                    data: memoData,
                    columns: memoColumns,
                    getCoreRowModel: getCoreRowModel(),
                    getSubRows: (row) => (isAddressGroupType(row) ? row.addresses : undefined),
                    getExpandedRowModel: getExpandedRowModel(),
                    state: {
                        expanded,
                    },
                    onExpandedChange: setExpanded,
                }}
                isLoading={isLoading}
                paginator={null}
                onRowClick={onRowClick}
                renderEmptyState={() => <TableEmptyState defaultActionAvailable={totalAddressesCount === 0} />}
            />
        </Container>
    );
};

export const CryptoTable: React.FC<AddressesTableProps<CryptoAddress, CryptoTableData>> = ({
    totalAddressesCount,
    side,
    addresses,
    sharedAddresses,
    onRowClick,
}) => {
    const walletColumn = useMemo(
        () =>
            side === "your"
                ? generalWalletColumn
                : {
                      ...generalWalletColumn,
                      meta: {
                          headerStyleProps: {
                              width: "100%",
                              textAlign: "start",
                              minWidth: "272px",
                          },
                          cellStyleProps: {
                              width: "100%",
                              textAlign: "start",
                              minWidth: "272px",
                          },
                      },
                  },
        [side],
    );

    return (
        <BottomFixTableContainer>
            {side === "your" && (
                <CryptoTableView
                    totalAddressesCount={totalAddressesCount}
                    columns={[
                        nameColumn,
                        networkColumn,
                        walletColumn,
                        privateNoteColumn,
                        memoOrTagColumn,
                        statusColumn,
                        actionColumn,
                    ]}
                    data={addresses.data}
                    isLoading={addresses.isLoading}
                    onRowClick={onRowClick}
                />
            )}
            {side === "shared" && (
                <CryptoTableView
                    totalAddressesCount={totalAddressesCount}
                    columns={[nameColumn, networkColumn, walletColumn, memoOrTagColumn, statusColumn, actionColumn]}
                    data={sharedAddresses.data}
                    isLoading={sharedAddresses.isLoading}
                    onRowClick={onRowClick}
                />
            )}
        </BottomFixTableContainer>
    );
};
