import { useMemo } from "react";
import { CLIENT_TYPE_TITLES, EmDash } from "const";
import { useCounterparties, useInstruments } from "hooks";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useExtendedLimitsByAsset } from "hooks/useExtentedLimitsByAsset";
import { useShortSalesBan } from "hooks/useShortSalesBan";
import { useOvernightFees } from "hooks/useOvernightFees";

export const useBulkEditData = () => {
    const { shortSalesBans } = useShortSalesBan();
    const { groupedOvernightFees } = useOvernightFees();
    const { currencies } = useInstruments();
    const { limitsByAssets } = useExtendedLimitsByAsset();
    const { counterparties } = useCounterparties();
    const { getCpType } = useCpInfoHelpers();

    return useMemo(() => {
        return Object.values(currencies)
            .map((currency) => {
                return Object.entries(counterparties).map(([cpIdAsString, cpName]) => {
                    const cpId = Number(cpIdAsString);
                    const limit = limitsByAssets.find(
                        (l) => l.counterpartyId === cpId && l.asset === currency.name,
                    );
                    const shortSalesBan = shortSalesBans[cpId]?.some(
                        (shorSalesInfo) => shorSalesInfo.asset === currency.name,
                    );
                    const overnightFee = groupedOvernightFees[cpId]?.find(
                        (overnight) => overnight.currency === currency.name,
                    );
                    const cpType = getCpType(cpId);

                    return {
                        currencyName: currency.name,
                        currencyType: currency.currencyType,
                        cpName: cpName,
                        cpType: cpType === undefined ? EmDash : CLIENT_TYPE_TITLES[cpType],
                        cpId: Number(cpId),
                        shortSales: !shortSalesBan,
                        overnightsShort: overnightFee?.negativeRate,
                        overnightsLong: overnightFee?.positiveRate,
                        limitAmount: limit?.grossLimit,
                        limitCurrency: limit?.limitCurrency || limit?.asset,
                    };
                });
            })
            .flat();
    }, [currencies, counterparties]);
};
