import styled from "styled-components";

export const SheetInfoBox = styled.div<{ $size: "small" | "medium" | "large" }>`
    display: flex;
    flex-direction: column;
    width: ${(p) => {
        switch (p.$size) {
            case "large":
                return "336px";
            case "medium":
                return "264px";
            case "small":
                return "192px";
        }
    }};
    margin-top: 8px;
    padding: 8px 12px;
    border-radius: 8px;
    background: ${(p) => p.theme.colors.ui96};

    box-shadow: 0 1px 2px 0 ${(p) => p.theme.colors.ui24}, 0 0.5px 0 0 ${(p) => p.theme.colors.ui20} inset;

    color: ${(p) => p.theme.colors.uiWhite100};
    white-space: break-spaces;
    overflow-wrap: break-word;
`;
