import {
    Icons,
    PSmall,
} from "@fm-frontend/uikit";
import { useCounterparties } from "hooks";
import { useTheme } from "styled-components";
import { ResultLine, ResultsContainer } from "../../components/ResultsContainer";
import { BulkResponse } from "../../services/bulkUpdate";
import { BulkUpdateRequest } from "../ConfigurationModal/bulkService";

type Props = {
    responses: BulkResponse<BulkUpdateRequest>[];
    onUpdate: (responses: BulkResponse<BulkUpdateRequest>[]) => void;
    onClose: () => void;
};

export const AssetsBulkEditResultModal: React.FC<Props> = ({ responses, onUpdate, onClose }) => {
    const { colors } = useTheme();
    const { counterparties } = useCounterparties();

    return (
        <ResultsContainer
            responses={responses}
            onUpdate={onUpdate}
            onClose={onClose}
        >
            {responses.map(({ error, request: { body } }, index) => (
                <ResultLine key={index}>
                    {error !== undefined ? (
                        <Icons.Attention color={colors.negative100} />
                    ) : (
                        <Icons.CheckShape />
                    )}
                    <PSmall>
                        {counterparties[body.counterpartyId]} ({body.counterpartyId})
                        {error}
                    </PSmall>
                </ResultLine>
            ))}
        </ResultsContainer>
    );
};
