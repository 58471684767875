import { Form, FormActions, FormBody, Modal, PlainButton, PrimaryButton, ShadowCard } from "@fm-frontend/uikit";
import { post } from "api";
import { useSaveSuccessNotification } from "feature/addresses/hooks";
import { useState } from "react";
import { Settlement } from "../../types";
import { ModalHeader } from "./components/ModalHeader";
import { SettlementInfo } from "./components/SettlementInfo";
import { Error } from "./styled";

type RecievedTransactionModalProps = {
    closeModal: () => void;
    settlement: Settlement;
};

export const RecievedTransactionModal = ({ closeModal, settlement }: RecievedTransactionModalProps) => {
    const showSuccessNotification = useSaveSuccessNotification();
    const [apiError, setApiError] = useState<string | null>(null);
    const [isCommitting, setIsCommitting] = useState(false);

    const commitTransaction = () => {
        setApiError(null);
        setIsCommitting(true);

        post("commitIncomingSettlementTransaction", {
            transactionId: settlement.id,
        })
            .then(() => {
                setIsCommitting(false);
                showSuccessNotification("Transaction was committed");
                closeModal();
            })
            .catch((e) => {
                setIsCommitting(false);
                setApiError(e);
            });
    };

    return (
        <Modal isOpen onClose={closeModal}>
            <Form>
                <ShadowCard>
                    <ModalHeader settlement={settlement} />
                    <FormBody spacing={4}>
                        <SettlementInfo settlement={settlement} />
                        {apiError && <Error>{apiError}</Error>}
                    </FormBody>
                    <FormActions variant="plain">
                        <PrimaryButton
                            type="button"
                            fullWidth
                            size="large"
                            loading={isCommitting}
                            onClick={commitTransaction}
                        >
                            Commit transaction
                        </PrimaryButton>
                        <PlainButton type="button" fullWidth size="large" onClick={closeModal}>
                            Skip for now
                        </PlainButton>
                    </FormActions>
                </ShadowCard>
            </Form>
        </Modal>
    );
};
