import { PXSmallBold } from "@fm-frontend/uikit";
import { EmDash, ValueFormat } from "@fm-frontend/utils";
import { calculateSpread } from "feature/trade/trading/TradingTile/utils/calculateSpread";
import { FC } from "react";
import styled from "styled-components";
import { EmptyState } from "./EmptyState";

const SpreadValue = styled(PXSmallBold)`
    text-wrap: nowrap;
    color: ${(p) => p.theme.colors.ui100};

    span {
        color: ${(p) => p.theme.colors.ui52};
    }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 8px;

    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.colors.ui12};
`;

type SpreadProps = {
    bidPrice: bigint | null;
    askPrice: bigint | null;
    quoteAsset: string | undefined;
};

export const Spread: FC<SpreadProps> = ({ bidPrice, askPrice, quoteAsset }) => {
    if (bidPrice === null && askPrice === null) {
        return <EmptyState title="No quotes" />;
    }

    if (bidPrice === null || askPrice === null) {
        return <EmptyState title={`Spread: ${EmDash}`} />;
    }

    const { spread, spreadInPercentage } = calculateSpread({
        bidPrice: bidPrice,
        askPrice: askPrice,
    });

    const formattedSpread = spread === null ? EmDash : `${ValueFormat.price(spread)} ${quoteAsset}`;
    const formattedSpreadInPercentage =
        spreadInPercentage === null ? "" : `${spreadInPercentage.toFixed(3)}%`;

    return (
        <Container>
            <SpreadValue>
                Spread: {formattedSpread} <span>{formattedSpreadInPercentage}</span>
            </SpreadValue>
        </Container>
    );
};
