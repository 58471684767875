import { EMPTY_ARRAY } from "const";
import { useMemo } from "react";
import useSWR from "swr";
import { displayError } from "utils";
import { postDataFromResult, SHARED_PAYMENT_ACCOUNTS_URL } from "../api";
import { ApiAccountEntity } from "../entities/ApiAccountEntity";
import { ApiBankEntity } from "../entities/ApiBankEntity";

type SWRType = string;
type DataType = {
    accounts: ApiAccountEntity[];
    banks: ApiBankEntity[];
};
export const useSharedPaymentAccounts = () => {
    const fetcher = (url: string) => postDataFromResult(url, {});
    const { data = { accounts: [], banks: [] }, isLoading } = useSWR<DataType, any, SWRType>(
        SHARED_PAYMENT_ACCOUNTS_URL,
        fetcher,
        {
            onError: displayError,
            fallbackData: {
                accounts: EMPTY_ARRAY as ApiAccountEntity[],
                banks: EMPTY_ARRAY as ApiBankEntity[],
            },
        },
    );

    return useMemo(
        () => ({
            sharedAccounts: data.accounts,
            sharedBanks: data.banks,
            isCpDataLoading: isLoading,
        }),
        [data, isLoading],
    );
};
