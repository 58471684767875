import styled, { css } from "styled-components";
import { errorCss } from "../../common";
import { P, PSmallBold, TextSmall } from "../../typography";
import { DropdownSize } from "../Dropdown/Dropdown.types";

const disabledCss = css`
    cursor: not-allowed;
    background-color: ${(p) => p.theme.colors.ui4};
    color: ${(p) => p.theme.colors.ui20};
`;

const hoverCss = css`
    :hover {
        background-color: ${(p) => p.theme.colors.ui12};
    }
`;

const CommonContainer = styled.div<{ isOpen?: boolean; isDisabled?: boolean; isError?: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;

    background-color: ${(p) => p.theme.colors.ui8};
    border-radius: 8px;

    ${({ isDisabled }) => (isDisabled ? disabledCss : hoverCss)}
    ${({ isError }) => isError && errorCss}
    background-color: ${(p) => (p.isOpen ? p.theme.colors.ui12 : p.theme.colors.ui8)};

    ${(p) =>
        p.isOpen &&
        css`
            box-shadow: 0 0 0 3px ${(p) => p.theme.colors.brand12}, inset 0 0 0 1px ${(p) => p.theme.colors.brand100};
        `}

    svg {
        position: absolute;
        right: 8px;
    }
`;

export const BasicContainer = styled(CommonContainer)`
    padding-right: 16px;
`;

export const SimpleContainer = styled(BasicContainer)`
    justify-content: flex-end;

    ${P} {
        padding-right: 17px;
    }
`;

export const InlineContainer = styled(CommonContainer)<{ size?: DropdownSize }>`
    width: fit-content;
    min-width: max-content;
    height: 24px;
    padding: 4px 8px;
    gap: 8px;
    align-items: center;

    ${(p) =>
        p.size === "medium" &&
        css`
            height: 32px;
        `}
    ${(p) =>
        p.size === "large" &&
        css`
            height: 44px;
        `}

    svg {
        margin-left: 4px;
        position: unset;
        right: unset;
        transform: unset;
    }

    ${PSmallBold} {
        min-width: fit-content;
    }
`;

export const CommonLabel = styled(TextSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

export const InlineLabel = styled(CommonLabel)`
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translate(0, -50%);
`;
