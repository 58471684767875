import { Flex } from "@fm-frontend/uikit";
import { QuoteSide } from "feature/rfq/apiHooks/useRfqWs";
import { FakeButtonIndicator } from "feature/rfq/components/RfqTilesTrading/tiles/NoTilesTile";
import styled from "styled-components";
import { QUOTE_CONTAINER_HEIGHT } from "../consts";

const LoadingQuoteContainer = styled(Flex)`
    min-height: ${QUOTE_CONTAINER_HEIGHT}px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${(p) => p.theme.colors.ui8};
    box-sizing: border-box;
`;

export const LoadingQuote = ({ side }: { side: QuoteSide }) => (
    <LoadingQuoteContainer>
        <FakeButtonIndicator $isSell={side === "SELL"} />
    </LoadingQuoteContainer>
);
