export enum SubaccountTabs {
    ALL = "all",
    ACTIONABLE = "act",
    DEPOSITS = "deposits",
    WITHDRAWALS = "withdrawals",
}

export enum PBSubaccountTabs {
    ALL = "all",
    ACTIONABLE = "act",
    DEPOSITS = "deposits",
    WITHDRAWALS = "withdrawals",
    REQUESTS = "requests",
}
