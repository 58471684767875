import { DropdownOption, SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { CurrencyType } from "@fm-frontend/utils";
import { CurrencyDropdownSheet } from "components/CurrencySheet";
import { CurrencyTriggerEssence } from "components/CurrencyTriggerEssence";
import { useInstruments } from "hooks";
import React, { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useCurrenciesPositions } from "../hooks";
import { CryptoAddressFormInput } from "../types";
import { hasCurrencyMemoOrTag } from "../utils";

type CurrencyDropdownOption = DropdownOption<
    number,
    {
        position?: bigint;
    }
>;
type CellProps = {
    index: number;
    disabled: boolean;
};
export const CurrencyLightboxCell: React.FC<
    CellProps & {
        availableCurrencyTypes: CurrencyType[];
    }
> = ({ index, disabled, availableCurrencyTypes }) => {
    const {
        control,
        formState: { errors },
        watch,
        setValue,
    } = useFormContext<CryptoAddressFormInput>();

    const currencyControlName = `addresses.${index}.currency.id` as const;
    const selectedCurrencyId = watch(currencyControlName);
    const selectedCpId = watch(`addresses.${index}.rule.cp.id`);
    const currenciesPositions = useCurrenciesPositions(selectedCpId);

    const { currencyIds } = useInstruments();
    const currencyOptions = useMemo(
        (): CurrencyDropdownOption[] =>
            Object.values(currencyIds)
                .filter(({ currencyType }) => availableCurrencyTypes.includes(currencyType))
                .map(
                    ({ name, id }) =>
                        ({
                            text: name,
                            value: id,
                            position: currenciesPositions[name]?.value,
                        } as CurrencyDropdownOption),
                )
                .sort((a, b) => a.text.localeCompare(b.text)),
        [currencyIds, currenciesPositions],
    );

    useEffect(() => {
        const currencyName = currencyOptions.find(({ value }) => Number(value) === Number(selectedCurrencyId))?.text;

        setValue(`addresses.${index}.memoOrTagRequired` as const, hasCurrencyMemoOrTag(currencyName));
    }, [selectedCurrencyId, currencyOptions]);

    return (
        <Controller
            control={control}
            render={({ field }) => (
                <SingleDropdown
                    value={field.value}
                    onChange={field.onChange}
                    renderTrigger={(trigger) => (
                        <SingleDropdown.Trigger {...trigger} size="medium" variant="minimum">
                            <CurrencyTriggerEssence {...trigger} option={trigger.selectedOption} size="small" />
                        </SingleDropdown.Trigger>
                    )}
                    options={currencyOptions}
                    placeholder="Asset"
                    disabled={disabled}
                    error={errors?.addresses?.[index]?.currency?.id?.message}
                    fullWidth
                >
                    <CurrencyDropdownSheet Dropdown={SingleDropdown} options={currencyOptions} size="medium" />
                </SingleDropdown>
            )}
            name={currencyControlName}
        />
    );
};
