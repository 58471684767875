import { ClientId, HStack, Icons, PSmallBold, PXSmall, VStack } from "@fm-frontend/uikit";
import { format8FractionDigits } from "@fm-frontend/utils";
import { useRfqProvidersAndInstruments } from "feature/rfq/apiHooks/useRfqProvidersAndInstruments";
import { QuoteSide } from "feature/rfq/apiHooks/useRfqWs";
import styled, { useTheme } from "styled-components";
import { ExecutingQuote as ExecutingQuoteType } from "../../../RfqRequestModal";
import { QUOTE_CONTAINER_HEIGHT } from "../../consts";
import { Quote } from "../../QuotesList";
import { ExecutingQuote } from "../ExecutingQuote";

const NotSuccessedQuote = styled(HStack)`
    gap: 4px;
    color: ${({ theme: { colors } }) => colors.attention100};
    font-size: 10px;
    line-height: 12px;
    position: relative;
    bottom: 2px;
`;
const QuoteInfo = styled(VStack)`
    align-items: center;
    justify-content: center;
    gap: 2px;
    margin-top: auto;
    margin-bottom: auto;
`;
const QuoteContainer = styled(VStack)<{ $side: QuoteSide; $disabled: boolean }>`
    flex: 1 0 ${QUOTE_CONTAINER_HEIGHT}px;
    border-bottom: 1px solid ${(p) => p.theme.colors.ui8};
    align-items: center;
    cursor: ${(p) => (p.$disabled ? "not-allowed" : "pointer")};
    ${QuoteInfo} {
        ${PXSmall} {
            color: ${(p) =>
                p.$side === "BUY"
                    ? p.$disabled
                        ? p.theme.colors.positive32
                        : p.theme.colors.positive72
                    : p.$disabled
                    ? p.theme.colors.negative32
                    : p.theme.colors.negative72};
        }
        ${PSmallBold} {
            color: ${(p) =>
                p.$side === "BUY"
                    ? p.$disabled
                        ? p.theme.colors.positive32
                        : p.theme.colors.positive100
                    : p.$disabled
                    ? p.theme.colors.negative32
                    : p.theme.colors.negative100};
        }
    }

    :hover {
        background-color: ${({ $disabled, theme: { colors } }) => !$disabled && colors.ui4};
    }
`;
const ProviderInfo = styled(HStack)<{ $disabled: boolean }>`
    border-radius: 0px 0px 6px 6px;
    background: #f6f7f7;
    padding: 4px 4px 4px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    opacity: ${(p) => (p.$disabled ? 0.32 : 1)};
`;

export const QuoteListItem = ({
    quote,
    side,
    assetCurrency,
    onClick,
    executingQuote,
    actionIsInactive,
}: {
    quote: Quote;
    side: QuoteSide;
    assetCurrency: string;
    onClick: () => void;
    executingQuote: null | ExecutingQuoteType;
    actionIsInactive: boolean;
}) => {
    const theme = useTheme();
    const { rfqProviders } = useRfqProvidersAndInstruments();
    const { providerId } = quote;
    if (
        !executingQuote?.isBestQuote &&
        executingQuote?.providerId === providerId &&
        executingQuote?.side === side
    ) {
        return <ExecutingQuote side={side} />;
    }

    const [providerClientId, { name: providerName }] = Array.from(rfqProviders.entries()).find(
        ([, { providerId: pId }]) => pId === providerId,
    )!;

    const title = side === "BUY" ? `Buy ${assetCurrency}` : `Sell ${assetCurrency}`;
    const isDisabledTile =
        Boolean(executingQuote) || actionIsInactive || "rejectReason" in quote || "error" in quote;
    const providerInfoDisabled = actionIsInactive || "rejectReason" in quote || "error" in quote;

    return (
        <QuoteContainer
            onClick={isDisabledTile ? undefined : onClick}
            $side={side}
            $disabled={isDisabledTile}
        >
            <ProviderInfo $disabled={providerInfoDisabled}>
                <PXSmall>{providerName}</PXSmall>
                <ClientId id={providerClientId} isSmall />
            </ProviderInfo>
            <QuoteInfo>
                {"price" in quote && (
                    <>
                        <PXSmall>{title}</PXSmall>
                        <PSmallBold>{format8FractionDigits(quote.price)}</PSmallBold>
                    </>
                )}
                {("rejectReason" in quote || "error" in quote) && (
                    <NotSuccessedQuote>
                        <Icons.BannerAttention size={12} color={theme.colors.attention100} />
                        <span>{"rejectReason" in quote ? quote.rejectReason : quote.error}</span>
                    </NotSuccessedQuote>
                )}
            </QuoteInfo>
        </QuoteContainer>
    );
};
