import { P, VStack } from "@fm-frontend/uikit";
import { SettlementInfo } from "feature/settlements/SettlementDetailsModal/detailsModals/components/SettlementInfo";
import { Settlement } from "feature/settlements/types";
import styled from "styled-components";

const Description = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;

export const DeleteSettlementModalBody = ({ settlement }: { settlement: Settlement }) => (
    <VStack spacing={8}>
        <Description>
            This action cannot be undone. Are you sure you want to delete this{" "}
            {settlement.status === "request" ? "request" : "transaction"}?
        </Description>
        <SettlementInfo settlement={settlement} />
    </VStack>
);
