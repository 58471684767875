import { H2, PlainButton } from "@fm-frontend/uikit";
import { createNotification, setConfirmModalContent } from "feature/app";
import { FormError } from "feature/form/style";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import type { LpInfo } from "../types";
import { disableTrading, enableTrading } from "./api";
import { LpRow } from "./LpRow";
import { useCpsStatuses } from "./useCpsStatuses";

const Description = styled.div`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
`;

const StatsText = styled.div`
    color: ${(p) => p.theme.colors.ui72};
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.44px;
    text-transform: uppercase;

    padding-bottom: 8px;
    padding-left: 12px;
`;

const Header = styled.div`
    width: 100%;
    padding: 12px;

    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const Content = styled.div`
    width: 100%;
    padding: 0 12px 16px 12px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    flex: 1;
    overflow-y: auto;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    border-top: 1px solid ${(p) => p.theme.colors.ui12};
`;

const Action = styled(PlainButton)`
    flex: 1;

    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
`;

const Container = styled.div`
    border-radius: 12px;
    width: 360px;

    overflow: auto;
    white-space: normal;

    display: flex;
    flex-direction: column;
    max-height: 587px;

    ${H2} {
        margin: 0;
    }
`;

const CHANGE_TRADING_STATUS_IN_BULK_CONFIG = {
    enabled: {
        method: enableTrading,
        action: "Start",
        title: "Start your trading activity with all LPs?",
        description: "",
        notification: "Trading with all LPs has been started",
    },
    disabled: {
        method: disableTrading,
        action: "Stop",
        title: "Stop your trading activity with all LPs?",
        description: "Your orders will be removed from the trading environment",
        notification: "Trading with all LPs has been stopped",
    },
};

export const LiquidityManagementModalContent = ({
    enabledLimitsCount,
    allLimits,
}: {
    enabledLimitsCount: number;
    allLimits: LpInfo[];
}) => {
    const dispatch = useDispatch();
    const [error, setError] = useState<string | null>(null);
    const { data: cpStatuses } = useCpsStatuses();
    const [isBulkSubmitting, setIsBulkSubmitting] = useState(false);

    const changeTradingStatusInBulk = async (status: "enabled" | "disabled") => {
        const config = CHANGE_TRADING_STATUS_IN_BULK_CONFIG[status];

        const requestFunc = async () => {
            try {
                setError(null);

                setIsBulkSubmitting(true);

                const cpsIds = allLimits
                    .filter(({ enabledTradingByUser }) =>
                        status === "enabled" ? !enabledTradingByUser : enabledTradingByUser,
                    )
                    .map(({ counterpartyId }) => counterpartyId);
                const bulkResult = await Promise.allSettled(cpsIds.map(config.method));

                const rejectedCpsIds = bulkResult
                    .map(({ status: res }, i) => (res === "rejected" ? i : null))
                    .filter((v) => v !== null)
                    .map((v) => cpsIds[v as number]);
                if (rejectedCpsIds.length > 0) {
                    throw new Error(`Error while updating status of ${rejectedCpsIds.join()}`);
                }

                dispatch(
                    createNotification({
                        type: "success",
                        content: config.notification,
                    }),
                );
            } catch (e) {
                setError(String(e));
            } finally {
                setIsBulkSubmitting(false);
            }
        };

        dispatch(
            setConfirmModalContent({
                title: config.title,
                onConfirm: async () => {
                    await requestFunc();
                },
                description: config.description,
                confirmButtonTitle: config.action,
            }),
        );
    };

    return (
        <Container tabIndex={1}>
            <Header>
                <H2>Liquidity management</H2>
                <Description>Enable or disable trading with specific LPs</Description>
            </Header>
            <StatsText>
                {enabledLimitsCount} of {allLimits.length} liquidity providers enabled
            </StatsText>
            <Content>
                {allLimits.map(({ key, limit, counterpartyId }) => (
                    <LpRow
                        key={key}
                        limit={limit}
                        cpStatus={cpStatuses[counterpartyId]?.status}
                        isBulkSubmitting={isBulkSubmitting}
                        setError={setError}
                    />
                ))}
                {error && <FormError>{error}</FormError>}
            </Content>
            <Actions>
                <Action
                    size="large"
                    onClick={() => changeTradingStatusInBulk("disabled")}
                    disabled={isBulkSubmitting}
                >
                    Disable all
                </Action>
                <Action
                    size="large"
                    onClick={() => changeTradingStatusInBulk("enabled")}
                    disabled={isBulkSubmitting}
                >
                    Enable all
                </Action>
            </Actions>
        </Container>
    );
};
