import { useHeightToBottom } from "@fm-frontend/utils/src/hooks/useHeightToBottom";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
    overflow: auto;
    flex: 1;
`;

export const TradingBottomFixContainer: React.FC<{
    className?: string;
}> = ({ children, className }) => {
    const { ref, heightToBottom } = useHeightToBottom();

    return (
        <Container
            style={{ height: heightToBottom, minHeight: heightToBottom, maxHeight: heightToBottom }}
            ref={ref}
            className={className}
        >
            {children}
        </Container>
    );
};
