import React from "react";
import styled, { DefaultTheme, StyledComponent } from "styled-components";

const CommonContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 8px;

    padding-top: 16px;
`;

const ContainerBasic = styled(CommonContainer)`
    flex-direction: row;
`;

const ContainerPlain = styled(CommonContainer)`
    flex-direction: column;
`;

type ContainerVariant = "basic" | "plain";

const ContainerVariantComponents: {
    [Key in ContainerVariant]: StyledComponent<"div", DefaultTheme, {}, never>;
} = {
    basic: ContainerBasic,
    plain: ContainerPlain,
};

export const FormActions: React.FC<{ variant: ContainerVariant }> = (props) => {
    const { variant, children } = props;
    const ContainerComponent = ContainerVariantComponents[variant];

    return <ContainerComponent>{children}</ContainerComponent>;
};
