import { BasicInput } from "@fm-frontend/uikit";
import React, { useCallback, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CryptoAddressFormInput } from "../../../../types";
import { CryptoFormContext } from "../../CryptoFormContext";

type WalletControlProps = {
    index: number;
    rowId: string;
    disabled: boolean;
};

export const WalletControl: React.VFC<WalletControlProps> = ({ index, rowId, disabled }) => {
    const {
        register,
        formState: { errors, defaultValues },
    } = useFormContext<CryptoAddressFormInput>();

    const { updateDirtyRow } = useContext(CryptoFormContext);
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const defaultValue = defaultValues?.addresses?.[index]?.wallet;
            updateDirtyRow(rowId, "wallet", defaultValue !== event.target.value);
        },
        [updateDirtyRow, rowId],
    );

    return (
        <BasicInput
            data-1pignore
            disabled={disabled}
            _size="medium"
            placeholder="000x..."
            error={errors?.addresses?.[index]?.wallet?.message}
            {...register(`addresses.${index}.wallet`, {
                onChange: handleChange,
            })}
        />
    );
};
