import { CSV_NEW_LINE_SEPARATOR, CSV_SEPARATOR } from "@fm-frontend/utils";
import { call, put, takeEvery } from "@redux-saga/core/effects";
import { post } from "api";
import add from "date-fns/add";
import { fmt } from "utils/format";
import { closeNotification, createNotification, requestDataExport } from "..";
import { EmDash } from "../../../../const";
import { ColumnTemplate } from "../../../table/columns";

export function* sagaExporter() {
    yield takeEvery(
        requestDataExport,
        function* ({
            payload: { from, to, filename, includeHeaders, url, columns, itemIdColumn, urlParams },
        }: ReturnType<typeof requestDataExport>) {
            const limit = 250;
            let params: {
                from?: number;
                to?: number;
                till?: number;
                [key: string]: any;
            } = {};
            if (from) {
                params.from = new Date(from).valueOf();
            }
            if (to) {
                const lastDay = add(new Date(to), { days: 1 });
                params.to = lastDay.valueOf();
            }
            params = { ...params, ...urlParams };
            const notification = createNotification({
                type: "success",
                content: "Processing download request",
                timeout: false,
            });
            yield put(notification);
            try {
                let reachedEnd = false;
                const allData: number[][] = [];
                while (!reachedEnd) {
                    // iterate all pages
                    const data: number[][] = yield call(post, url, params);
                    allData.push(...data);
                    if (data.length === limit) {
                        params.till = data[data.length - 1][itemIdColumn];
                    } else {
                        reachedEnd = true;
                    }
                }
                const csv: string[] = [];

                if (includeHeaders) {
                    csv.push(columns.map(({ Header }: { Header: string }) => Header).join(CSV_SEPARATOR));
                }

                for (const item of allData) {
                    csv.push(
                        columns
                            .map(({ accessor, format }: ColumnTemplate<unknown>) => {
                                const value = accessor(item);
                                const formattedValue = format ? fmt(value, format).copyableValue : value;

                                if (formattedValue === EmDash) {
                                    return "-";
                                }
                                if (typeof formattedValue === "string") {
                                    return formattedValue?.includes(CSV_SEPARATOR) ||
                                        formattedValue?.includes(CSV_NEW_LINE_SEPARATOR)
                                        ? `"${formattedValue}"`
                                        : formattedValue;
                                }

                                return formattedValue;
                            })
                            .join(CSV_SEPARATOR),
                    );
                }

                const blob = new Blob([csv.join("\n")], {
                    type: "text/csv;charset=utf-8;",
                });

                const link = document.createElement("a");

                const blobURL = URL.createObjectURL(blob);
                link.setAttribute("href", blobURL);
                const name = filename || `export_${new Date().valueOf()}`;
                link.setAttribute("download", `${name}.csv`);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobURL);
            } catch {
                yield put(
                    createNotification({
                        type: "error",
                        content: "Error downloading data for export",
                    }),
                );
            } finally {
                yield put(closeNotification(notification.payload.id));
            }
        },
    );
}
