import { HStack, Icons, PSmall, Tooltip } from "@fm-frontend/uikit";
import { Switch } from "@fm-frontend/uikit/src/components/v2";
import { useOpenConfirmationModal } from "hooks";
import { useIsPrimeBrokerUser } from "store/hooks";
import styled, { css } from "styled-components";
import { useWhitelistingContext } from "./whitelistingContext";

const TextLabelWrapper = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

const Description = styled.span`
    color: ${(p) => p.theme.colors.ui72};
`;

const ConfirmButton = styled.span<{ $isWhitelistingOn: boolean }>`
    ${(p) =>
        p.$isWhitelistingOn &&
        css`
            color: ${p.theme.colors.brand100};
        `}
`;

export const WhitelistingSwitch = () => {
    const isMasterUser = useIsPrimeBrokerUser();
    const openConfirmationModal = useOpenConfirmationModal();
    const { isLoading, isWhitelistingOn, setWhitelistingStatus } = useWhitelistingContext();

    const onMessage = isMasterUser
        ? "Whitelisting will impact both CP and sub-account trading. Trading will be restricted solely to whitelisted instruments. Any new instruments added to the platform will require manual whitelisting."
        : "Trading will be restricted solely to whitelisted instruments. Any new instruments added to the platform will require manual whitelisting.";
    const offMessage = isMasterUser
        ? "Trading restrictions will be lifted for CPs and sub-accounts, granting access to all instruments. Any new instruments added to the platform will not require manual whitelisting."
        : "Trading restrictions will be lifted, granting access to all instruments. Any new instruments added to the platform will not require manual whitelisting.";

    const handleWhitelistingSwitch = (isOn: boolean) => {
        const title = `${isOn ? "Enabling" : "Disabling"} whitelisting`;
        const description = <Description>{isOn ? onMessage : offMessage}</Description>;

        const confirmButtonTitle = (
            <ConfirmButton $isWhitelistingOn={isOn}>{`${
                isOn ? "Enable" : "Disable"
            } whitelisting`}</ConfirmButton>
        );

        openConfirmationModal({
            title,
            description,
            confirmButtonTitle,
            cancelButtonTitle: "Cancel",
            onConfirm: () => setWhitelistingStatus(isOn),
        });
    };

    return (
        <Switch
            innerCaption={() => (
                <HStack spacing={4}>
                    <TextLabelWrapper>Whitelisting</TextLabelWrapper>
                    <Tooltip
                        content="Enabling this will restrict trading to the approved set of instruments. New instruments must be manually added to the whitelist."
                        positions={["bottom", "left"]}
                    >
                        <Icons.Help />
                    </Tooltip>
                </HStack>
            )}
            size="small"
            disabled={isLoading}
            checked={isWhitelistingOn}
            onChange={handleWhitelistingSwitch}
            variant="simple"
        />
    );
};
