import styled from "styled-components";
import { ShadowCard } from "../layout";

export const FormAttention = styled(ShadowCard)`
    border-radius: 8px;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    position: relative;
    padding: 12px 12px 12px 40px;

    img,
    svg {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 12px;
        left: 12px;
    }
`;
