import {
    Form,
    FormActions,
    FormBody,
    FormInfo,
    FormProgress,
    H1,
    Icons,
    PrimaryButton,
    ShadowCard,
} from "@fm-frontend/uikit";
import { Header as FormHeader } from "@fm-frontend/uikit/src/components/headers/Header";
import { HeaderTitle } from "@fm-frontend/uikit/src/components/headers/HeaderTitle";
import { CopyToClipboard } from "components/CopyToClipboard";
import { useSelector } from "hooks";
import { useFaqLinks } from "hooks/useFaqLinks";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useIsMakerUser, useIsPrimeBrokerUser } from "store/hooks";
import {
    OnboardingMap,
    ONBOARDING_STEPS,
    setCurrentStep,
    setIsApiIntegrationRequired,
} from "store/onboardingSlice";
import styled from "styled-components";
import { Header, Layout, OnboardingInfoBanner } from "../common";

const AppLinkContainer = styled.a`
    min-width: 136px;
    min-height: 24px;
    overflow: scroll;
    display: flex;
    align-items: center;
    padding: 0 8px;
    gap: 4px;

    background-color: ${(p) => p.theme.colors.ui8};
    border-radius: 8px;
    margin-right: 4px;

    font-weight: 600;
    text-decoration: none !important;

    :hover {
        background-color: ${(p) => p.theme.colors.ui12};
    }
`;

const Container = styled(ShadowCard)`
    padding-top: 212px;
    background: white url("/img/api-integration-bg.png") no-repeat right top;
    background-size: contain;
`;

const LinksContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

interface Inputs {
    apiLink: string;
}

const CREATE_TEST_ACCOUNT_URL = "https://test.finerymarkets.com/signup";

export const ApiIntegration: React.FC = () => {
    const faqLinks = useFaqLinks();
    const dispatch = useDispatch();
    const isMakerUser = useIsMakerUser();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const { isTermsAndConditionsDelegated, isTermsAndConditionsRequired } = useSelector(
        (state) => state.onboarding.onboardingStages,
    );
    const { userId } = useSelector((state) => state.authentication);

    const isMakerOrBrokerApiIntegration = isMakerUser || isPrimeBrokerUser;
    const isEnterToProductDisabled =
        (isTermsAndConditionsRequired || isTermsAndConditionsDelegated) &&
        isMakerOrBrokerApiIntegration;

    const { handleSubmit } = useForm<Inputs>({
        mode: "onSubmit",
    });

    const submit = () => {
        dispatch(setIsApiIntegrationRequired({
            isApiIntegrationRequired: false,
            userId,
        }));
    };

    return (
        <Layout>
            <Header>
                <H1>Business & legal</H1>
            </Header>
            <OnboardingInfoBanner />
            <Form onSubmit={handleSubmit(submit)}>
                <FormProgress
                    currentStep={
                        OnboardingMap[ONBOARDING_STEPS.BL_API_INTEGRATION].progress.current
                    }
                    totalSteps={OnboardingMap[ONBOARDING_STEPS.BL_API_INTEGRATION].progress.total}
                    onBackClick={
                        isMakerOrBrokerApiIntegration
                            ? undefined
                            : () => dispatch(setCurrentStep(ONBOARDING_STEPS.BL_ASSETS))
                    }
                />
                <Container>
                    <FormHeader>
                        <HeaderTitle title="API integration" />
                    </FormHeader>
                    <FormBody>
                        <FormInfo>1. Share the API specifications link with your tech team.</FormInfo>
                        {faqLinks?.faq && (
                            <LinksContainer>
                                <AppLinkContainer href={faqLinks?.faq} target="_blank">
                                    {faqLinks?.faq.split("//")[1]}
                                    <Icons.ExternalLink />
                                </AppLinkContainer>
                                <CopyToClipboard value={faqLinks?.faq}>
                                    <Icons.CopyIcon />
                                </CopyToClipboard>
                            </LinksContainer>
                        )}
                        <FormInfo>2. Create a separate test account for the API integration.</FormInfo>
                        <AppLinkContainer href={CREATE_TEST_ACCOUNT_URL} target="_blank">
                            Create test account
                            <Icons.ExternalLink />
                        </AppLinkContainer>
                    </FormBody>
                    <FormActions variant="plain">
                        <PrimaryButton
                            type="submit"
                            fullWidth
                            size="large"
                            disabled={isEnterToProductDisabled}
                        >
                            {isMakerOrBrokerApiIntegration
                                ? "Start exploring the product"
                                : "Continue onboarding"}
                        </PrimaryButton>
                    </FormActions>
                </Container>
            </Form>
        </Layout>
    );
};
