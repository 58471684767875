import { Regex } from "@fm-frontend/utils";
import { ValidationMessages } from "utils/validationMessages";
import { AnySchema, array, lazy, number, object, string } from "yup";
import {
    AddressEditInputs,
    AddressForAllCpFormInputs,
    BankAddressEditInputs,
    BanksAddressFormInput,
    CryptoAddressFormInput,
} from "./types";

const idValidation = number()
    .integer()
    .positive()
    .transform((value) => (Number.isNaN(value) ? undefined : Number(value)))
    .required(ValidationMessages.REQUIRED);

const walletValidation = string()
    .transform((value) => value?.trim())
    .required(ValidationMessages.REQUIRED)
    .max(150, ValidationMessages.INVALID);

const memoOrTagValidation = string().when("memoOrTagRequired", (memoOrTagRequired: boolean, schema) =>
    memoOrTagRequired
        ? schema
              .transform((value: any) => value?.trim())
              .required(ValidationMessages.REQUIRED)
              .test("test-memo-or-tag", ValidationMessages.INVALID, (value: string) =>
                  Regex.MEMO_OR_TAG_REGEX.test(value),
              )
        : schema.transform(() => undefined).optional(),
);

const bankMemoOrTagValidation = string().when("memoOrTagRequired", {
    is: true,
    then: string()
        .trim()
        .required(ValidationMessages.REQUIRED)
        .matches(Regex.MEMO_OR_TAG_REGEX, ValidationMessages.INVALID),
    otherwise: lazy((value) =>
        value ? string().trim().matches(Regex.MEMO_OR_TAG_REGEX, ValidationMessages.INVALID) : string().optional(),
    ),
});

const privateNoteValidation = string()
    .optional()
    .transform((value) => value?.trim())
    .max(250, ValidationMessages.FIELD_MAX_LENGTH);

const detailsValidation = string()
    .optional()
    .transform((value) => value?.trim())
    .max(250, ValidationMessages.FIELD_MAX_LENGTH);

const bankInfoValidation = string()
    .transform((value) => value?.trim())
    .required(ValidationMessages.REQUIRED)
    .max(100, ValidationMessages.FIELD_MAX_LENGTH);

const companyInfoValidation = string()
    .transform((value) => value?.trim())
    .required(ValidationMessages.REQUIRED)
    .max(100, ValidationMessages.FIELD_MAX_LENGTH);

export const cryptoAddressSchema = object<Record<keyof CryptoAddressFormInput, AnySchema>>().shape({
    addresses: array().of(
        object().shape({
            rule: object({
                cp: object({
                    id: idValidation,
                }).required(),
            }),
            currency: object({
                id: idValidation,
            }),
            network: object({
                id: idValidation,
            }),
            wallet: walletValidation,
            privateNote: privateNoteValidation,
            memoOrTag: memoOrTagValidation,
        }),
    ),
});

export const cryptoAddressForAllSchema = object<Record<keyof AddressForAllCpFormInputs, AnySchema>>().shape({
    currencyId: idValidation,
    networkId: idValidation,
    wallet: walletValidation,
    memoOrTag: memoOrTagValidation,
});

export const editCryptoAddressSchema = object<Record<keyof AddressEditInputs, AnySchema>>().shape({
    wallet: walletValidation,
    privateNote: privateNoteValidation,
    memoOrTag: memoOrTagValidation,
});

export const editBankAddressSchema = object<Record<keyof BankAddressEditInputs, AnySchema>>().shape({
    wallet: walletValidation,
    privateNote: privateNoteValidation,
    memoOrTag: bankMemoOrTagValidation,
    otherDetails: detailsValidation,
    publicDetails: detailsValidation,
    bankName: bankInfoValidation,
    bankAddress: bankInfoValidation,
    companyName: companyInfoValidation,
    companyAddress: companyInfoValidation,
});

export const banksAddressSchema = object<Record<keyof BanksAddressFormInput, AnySchema>>().shape({
    addresses: array().of(
        object().shape({
            currency: object({
                id: idValidation,
            }),
            wallet: walletValidation,
            bankPrimary: object({
                name: bankInfoValidation,
                address: bankInfoValidation,
            }),
            otherDetails: detailsValidation,
            publicDetails: detailsValidation,
            memoOrTag: bankMemoOrTagValidation,
            company: object().shape({
                name: companyInfoValidation,
                address: companyInfoValidation,
            }),
        }),
    ),
});
