import { all, call, put, spawn, take, takeEvery } from "@redux-saga/core/effects";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { appSlice } from "feature/app";
import { appSaga } from "feature/app/store/saga";
import { wsSlice } from "feature/app/ws";
import { wsSaga } from "feature/app/ws.saga";
import { authSaga, sagaUpdateClientData } from "feature/auth/auth.saga";
import { notificationsSaga } from "feature/inboxPopover/notifications.saga";
import { notificationsSlice } from "feature/inboxPopover/notifications.store";
import { onboardingSaga } from "feature/onboarding/onboarding.saga";
import {
    loadRfqSettingsSaga,
    rfqSettingSlice,
    rfqSettingsSaga,
} from "feature/rfq/loadRfqSettingsSaga";
import { sagaLoadSettings, sagaSettings, settingSlice } from "feature/settings";
import createSagaMiddleware, { SagaIterator, Task } from "redux-saga";
import { getIsUserAuthenticated } from "services/authService";
import { actionChannel } from "store/actionChannel";
import { completeNewPassword, confirmSignIn, signOut, verifyMfa } from "store/auth/actions";
import { logout } from "store/auth/logout";
import { authenticationSlice } from "store/auth/slice";
import { counterpartiesSlice } from "store/counterpartiesSlice";
import { onboardingSlice } from "store/onboardingSlice";

export function* actionPortal(action: any) {
    yield put(action);
}

function* actionSaga() {
    yield takeEvery(actionChannel, actionPortal);
}

function* safeCallSagaWithRetry(saga: () => SagaIterator, maxRetries = 5) {
    let count = 0;

    while (count < maxRetries) {
        try {
            yield call(saga);
            break;
        } catch (e) {
            console.error(`Error in saga ${saga.name}:`, e);
            count += 1;
            if (count >= maxRetries) {
                console.error(`Saga ${saga.name} failed after ${maxRetries} retries.`);
            }
        }
    }
}

const sagasToRunWhenAuthenticated = [
    wsSaga,
    notificationsSaga,
    sagaLoadSettings,
    sagaUpdateClientData,
    loadRfqSettingsSaga,
];

function* watchAuthStatusSaga() {
    while (true) {
        yield take([
            authenticationSlice.actions.setAuthenticated,
            confirmSignIn.fulfilled,
            completeNewPassword.fulfilled,
            verifyMfa.fulfilled,
        ]);

        const initialAuthenticated: boolean = yield call(getIsUserAuthenticated);

        if (initialAuthenticated) {
            const tasks: Task[] = yield all(sagasToRunWhenAuthenticated.map(
                (saga) => spawn(safeCallSagaWithRetry, saga)
            ));

            yield take(signOut.fulfilled);

            tasks.map((task) => task.cancel());
        }
    }
}

export function* rootSaga() {
    const sagas = [
        actionSaga,
        authSaga,
        onboardingSaga,
        appSaga,
        sagaSettings,
        rfqSettingsSaga,
        watchAuthStatusSaga,
    ];

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                let count = 0;
                while (count < 5) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e) {
                        console.error(e);
                        count += 1;
                    }
                }
            }),
        ),
    );
}

const saga = createSagaMiddleware();
const appReducer = combineReducers({
    app: appSlice.reducer,
    settings: settingSlice.reducer,
    ws: wsSlice.reducer,
    notifications: notificationsSlice.reducer,
    counterparties: counterpartiesSlice.reducer,
    onboarding: onboardingSlice.reducer,
    authentication: authenticationSlice.reducer,
    rfqSettings: rfqSettingSlice.reducer,
});
const rootReducer: typeof appReducer = (state, action) => {
    if (action.type === logout.toString()) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(saga),
});
saga.run(rootSaga);

export type IRootState = ReturnType<typeof appReducer>;

export type AppDispatch = typeof store.dispatch;

export default store;
