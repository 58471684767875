import { HStack, IconButton, Icons } from "@fm-frontend/uikit";
import React from "react";

type ActionsProps = {
    hide: boolean;
    onRowCopy: () => void;
    onRowDelete: () => void;
};

export const ActionsControl: React.VFC<ActionsProps> = ({ hide, onRowCopy, onRowDelete }) => {
    if (hide) {
        return <></>;
    }

    return (
        <HStack paddingRight={4} justifyContent="end">
            <IconButton type="button" variant="plain" Icon={Icons.Duplicate} onClick={onRowCopy} />
            <IconButton type="button" variant="plain" Icon={Icons.Bin} onClick={onRowDelete} />
        </HStack>
    );
};
