import { BasicButton, Form, FormActions, FormBody, Modal, ShadowCard } from "@fm-frontend/uikit";
import { useSaveSuccessNotification } from "feature/addresses/hooks";
import { setConfirmModalContent } from "feature/app";
import { deleteSettlementRequest } from "feature/settlements/api";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Settlement } from "../../types";
import { ModalHeader } from "./components/ModalHeader";
import { SettlementInfo } from "./components/SettlementInfo";
import { Error } from "./styled";

type OutgoingRequestModalProps = {
    closeModal: () => void;
    settlement: Settlement;
};

export const OutgoingRequestModal = ({ closeModal, settlement }: OutgoingRequestModalProps) => {
    const showSuccessNotification = useSaveSuccessNotification();
    const [apiError, setApiError] = useState<string | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);

    const dispatch = useDispatch();
    const handleRequestDelete = async () => {
        dispatch(
            setConfirmModalContent({
                title: "Delete request?",
                onConfirm: async () => {
                    try {
                        setApiError(null);
                        setIsDeleting(true);
                        await deleteSettlementRequest({
                            requestId: settlement.id,
                        });

                        showSuccessNotification("Request was deleted");
                        closeModal();
                    } catch (e) {
                        setApiError(String(e));
                    } finally {
                        setIsDeleting(false);
                    }
                },
                description: "",
                confirmButtonTitle: "Delete",
            }),
        );
    };

    return (
        <Modal isOpen onClose={closeModal}>
            <Form>
                <ShadowCard>
                    <ModalHeader settlement={settlement} />
                    <FormBody spacing={4}>
                        <SettlementInfo
                            settlement={settlement}
                            onDeleteSettlement={handleRequestDelete}
                            isDeleteButtonLoading={isDeleting}
                        />
                        {apiError && <Error>{apiError}</Error>}
                    </FormBody>
                    <FormActions variant="plain">
                        <BasicButton type="button" fullWidth size="large" onClick={closeModal}>
                            Close
                        </BasicButton>
                    </FormActions>
                </ShadowCard>
            </Form>
        </Modal>
    );
};
