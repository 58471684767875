import { Tooltip } from "@fm-frontend/uikit";
import { FC, useCallback } from "react";
import { WhitelistingBulkEditModal } from "../ConfigurationModal";
import { BulkEditButton } from "../../components/BulkEditButton";
import { useWhitelistingContext } from "../../../components/WhitelistingTabContent/whitelistingContext";

type WhitelistingBulkEditButtonProps = {
    loading: boolean;
    disabled: boolean;
};

export const WhitelistingBulkEditButton: FC<WhitelistingBulkEditButtonProps> = ({ loading, disabled }) => {
    const { refetchWhitelist } = useWhitelistingContext();

    const handleModalClose = useCallback(() => {
        refetchWhitelist();
    }, [refetchWhitelist]);

    const tooltipContent = disabled
        ? "Bulk edit will be available when the whitelisting option is ON"
        : null;

    return (
        <Tooltip content={tooltipContent}>
            <BulkEditButton
                loading={loading}
                disabled={disabled}
                renderModal={(cancel) => <WhitelistingBulkEditModal onCancel={cancel} />}
                onClose={handleModalClose}
            />
        </Tooltip>
    );
};
