import { noop, useModalControl } from "@fm-frontend/utils";
import { ROUTES } from "feature/app/router";
import { useFilteredCpsIdsFromLimits } from "hooks/useCounterparties";
import { useFilterState } from "hooks/useFilterState";
import { useUrlWithViewType } from "hooks/useViewTypeUrl";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { AccountStatusType, AddressSide, AddressType, AddressUrlParams, isAddressSide, isAddressType } from "./types";

type AddressesContextValue = {
    side: AddressSide;
    type: AddressType;
    setSide: (side: AddressSide) => void;
    setType: (type: AddressType) => void;
    setSelectedCps: (cpIds: number[]) => void;
    deleteSelectedCps: () => void;
    cpsIds: number[] | undefined;
    selectedCps: number[];
    setSelectedStatuses: (status: AccountStatusType[]) => void;
    deleteSelectedStatuses: () => void;
    selectedStatuses: AccountStatusType[];
    isLightBoxOpen: boolean;
    openLightBox: () => void;
    closeLightBox: () => void;
    cryptoNeedToConfirm: number;
    setCryptoNeedToConfirm: (count: number) => void;
    bankNeedToConfirm: number;
    setBankNeedToConfirm: (count: number) => void;
};

const PAGE_FILTER_KEY = "addresses";

const REFETCH_LIMITS_INTERVAL_MS = 60_000;

const DEFAULT_SIDE: AddressSide = "your";
const DEFAULT_TYPE: AddressType = AddressType.Crypto;
export const AddressesContext = createContext<AddressesContextValue>({
    side: DEFAULT_SIDE,
    type: DEFAULT_TYPE,
    setSide: noop,
    setType: noop,
    setSelectedCps: noop,
    deleteSelectedCps: noop,
    cpsIds: undefined,
    selectedCps: [],
    setSelectedStatuses: noop,
    deleteSelectedStatuses: noop,
    selectedStatuses: [],
    isLightBoxOpen: false,
    openLightBox: noop,
    closeLightBox: noop,
    cryptoNeedToConfirm: 0,
    setCryptoNeedToConfirm: noop,
    bankNeedToConfirm: 0,
    setBankNeedToConfirm: noop,
});

export const useCurrentAddressPage = () => {
    const history = useHistory();
    const urlWithViewType = useUrlWithViewType();
    const { side, type } = useParams<AddressUrlParams>();

    useEffect(() => {
        if (isAddressSide(side) && isAddressType(type)) {
            return;
        }

        history.replace(urlWithViewType(`${ROUTES.addresses}/${DEFAULT_SIDE}/${DEFAULT_TYPE}`));
    }, [history, side, type]);

    const setSide = (newSide: AddressSide) => {
        history.replace(urlWithViewType(`${ROUTES.addresses}/${newSide}/${type}`));
    };

    const setType = (newType: AddressType) => {
        history.replace(urlWithViewType(`${ROUTES.addresses}/${side}/${newType}`));
    };

    return useMemo(
        () => ({
            side: side || DEFAULT_SIDE,
            type: type || DEFAULT_TYPE,
            setSide,
            setType,
        }),
        [side, type, setSide, setType],
    );
};

export const useAddressContext = () => useContext(AddressesContext);

export const AddressesContextProvider: React.FC = ({ children }) => {
    const addressPageValue = useCurrentAddressPage();
    const { closeModal: closeLightBox, isModalOpen: isLightBoxOpen, openModal: openLightBox } = useModalControl();

    const { cpsIds, isLoading } = useFilteredCpsIdsFromLimits(REFETCH_LIMITS_INTERVAL_MS);

    const [cryptoNeedToConfirm, setCryptoNeedToConfirm] = useState<number>(0);
    const [bankNeedToConfirm, setBankNeedToConfirm] = useState<number>(0);

    const [selectedCps, setSelectedCps, deleteSelectedCps] = useFilterState<number[]>(
        PAGE_FILTER_KEY,
        "cp",
        [],
        { isArray: true, parseValue: Number, availableValues: isLoading ? undefined : cpsIds },
    );
    const [selectedStatuses, setSelectedStatuses, deleteSelectedStatuses] = useFilterState<AccountStatusType[]>(
        PAGE_FILTER_KEY,
        "status",
        [],
        { isArray: true, availableValues: Object.values(AccountStatusType) },
    );

    const addressContextValue = {
        ...addressPageValue,
        closeLightBox,
        openLightBox,
        isLightBoxOpen,
        cpsIds,
        selectedCps,
        setSelectedCps,
        deleteSelectedCps,
        selectedStatuses,
        setSelectedStatuses,
        deleteSelectedStatuses,
        cryptoNeedToConfirm,
        setCryptoNeedToConfirm,
        bankNeedToConfirm,
        setBankNeedToConfirm,
    };

    return <AddressesContext.Provider value={addressContextValue}>{children}</AddressesContext.Provider>;
};
