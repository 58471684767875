import React from "react";
import styled from "styled-components";
import { TopMenu } from "../../components/TopMenu";
import { Page } from "../../style";
import { SettingsMenu } from "./SettingsMenu";

const Content = styled.div`
    flex: 1 1 0%;
    min-height: 638px;
`;

const Settings = styled.div`
    // FIX change with our color from UI Kit
    background: var(--white-e08de7);
    // FIX change with our color from UI Kit
    border: 1px solid var(--gainsboro-57fc22);
    // FIX change with our color from UI Kit
    box-shadow: 0px 4px 6px var(--black-b25c21);
    border-radius: 12px;
    max-width: 904px;
    margin-right: 12px;
    display: flex;
`;

const SettingsLayout: React.FC = ({ children }) => (
    <Page>
        <TopMenu>Settings</TopMenu>
        <Settings>
            <SettingsMenu />
            <Content>{children}</Content>
        </Settings>
    </Page>
);

export const withSettingsLayout = (SettingsView: React.FC) => () =>
    (
        <SettingsLayout>
            <SettingsView />
        </SettingsLayout>
    );
