import { EMPTY_ARRAY } from "const";
import { useMemo } from "react";
import useSWR from "swr";
import { displayError } from "utils";
import { PAYMENT_ACCOUNTS_RULES_URL, postDataFromResult } from "../api";
import { ApiAccountRuleEntity } from "../entities/ApiAccountRuleEntity";

type SWRType = string;
export const usePaymentAccountsRules = () => {
    const fetcher = (url: string) => postDataFromResult(url, {});
    const { data = [], isLoading } = useSWR<ApiAccountRuleEntity[], any, SWRType>(PAYMENT_ACCOUNTS_RULES_URL, fetcher, {
        onError: displayError,
        fallbackData: EMPTY_ARRAY as ApiAccountRuleEntity[],
    });

    return useMemo(
        () => ({
            paymentAccountsRules: data,
            isPaymentAccountsRulesLoading: isLoading,
        }),
        [data, isLoading],
    );
};
