import { noop } from "@fm-frontend/utils";
import { Switch as HeadlessSwitch } from "@headlessui/react";
import React from "react";
import styled, { css, DefaultTheme, StyledComponent } from "styled-components";
import { PSmall } from "../../typography";
import { GroupFn } from "./Group";
import { LabelFn } from "./Label";
import { SwitchCore } from "./SwitchCore";
import { SwitchSize, SwitchVariant } from "./types";

const Caption = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;
const InlineCaption = styled.div`
    display: flex;
    align-items: center;
    padding-right: 6px;
    color: ${(p) => p.theme.colors.ui52};
`;
const Error = styled(PSmall)`
    color: ${(p) => p.theme.colors.negative100};
`;
const Hint = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui32};
`;

const Essence = styled.div<{ $variant: SwitchVariant }>`
    display: flex;
    flex: 1;
    gap: 6px;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 6px;
    justify-content: flex-end;

    ${(p) =>
        p.$variant === "minimum" &&
        css`
            justify-content: flex-start;
        `}

    > span,
    p {
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

const StyledSwitch = styled.div<{ $error: boolean; $filterActive: boolean }>`
    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.ui8};
    width: 100%;

    :hover {
        cursor: pointer;
        background-color: ${(p) => p.theme.colors.ui12};
        box-shadow: none;
    }

    :focus-visible {
        background-color: ${(p) => p.theme.colors.ui12};
        box-shadow: 0 0 0 3px rgba(22, 199, 176, 0.2), 0 0 0 1px #16c7b0 inset;
        outline: none;
    }

    ${(p) =>
        p.$filterActive &&
        css`
            background-color: ${(p) => p.theme.colors.brand8};
            box-shadow: 0 0 0 1px ${(p) => p.theme.colors.brand20} inset,
                0 -1px 0 0 ${(p) => p.theme.colors.brand52} inset;
        `};

    ${(p) =>
        p.$error &&
        css`
            box-shadow: 0 0 0 3px rgba(246, 60, 48, 0.12), 0 0 0 1px #f63c30 inset !important;
        `};

    overflow: hidden;
`;

export const LargeSwitchLayout = styled(StyledSwitch)`
    display: flex;
    padding: 12px;
    max-height: 44px;
    height: 44px;
    align-items: center;
`;

const MediumSwitchLayout = styled(StyledSwitch)`
    display: flex;
    padding: 6px 8px;
    max-height: 32px;
    height: 32px;
    align-items: center;
`;

const SmallSwitchLayout = styled(StyledSwitch)`
    display: flex;
    padding: 2px 8px;
    max-height: 24px;
    height: 24px;
    align-items: center;
`;

const SwitchContainer = styled.div<{ $disabled: boolean; $fullWidth: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: ${(p) => (p.$fullWidth ? "100%" : "fit-content")};

    ${(p) =>
        p.$disabled &&
        css`
            ${StyledSwitch} {
                cursor: not-allowed !important;
                background-color: ${(p) => p.theme.colors.ui4} !important;

                svg path {
                    fill: ${(p) => p.theme.colors.ui32};
                }
            }
            ${Essence}, ${InlineCaption} , ${Caption}, ${Hint} {
                color: ${(p) => p.theme.colors.ui20}!important;

                * {
                    color: ${(p) => p.theme.colors.ui20}!important;
                }
            }
        `};
`;

const SWITCH_LAYOUT_MAP: Record<
    SwitchSize,
    StyledComponent<"div", DefaultTheme, { $error: boolean; $filterActive: boolean }>
> = {
    small: SmallSwitchLayout,
    medium: MediumSwitchLayout,
    large: LargeSwitchLayout,
};

type SwitchFnProps = {
    checked: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    asFilter?: boolean;
    caption?: (() => React.ReactNode) | string;
    innerCaption?: (() => React.ReactNode) | string;
    hint?: (() => React.ReactNode) | string;
    error?: string;
    fullWidth?: boolean;
    size: SwitchSize;
    variant: SwitchVariant;
    asSimpleSwitch?: boolean;
};
const SwitchFn: React.FC<SwitchFnProps> = ({
    onChange,
    checked,
    disabled = false,
    error,
    size,
    hint,
    caption,
    innerCaption,
    variant,
    fullWidth = false,
    asFilter = false,
    asSimpleSwitch = false,
    children: essence,
}) => {
    const SwitchLayout = SWITCH_LAYOUT_MAP[size];
    const hasError = Boolean(error);
    const hasHint = hint && !hasError;
    const hintContent = typeof hint === "function" ? hint?.() : <Hint>{hint}</Hint>;
    const hasCaption = Boolean(caption) && variant === "basic";
    const captionContent = typeof caption === "function" ? caption?.() : <Caption>{caption}</Caption>;
    const hasInnerCaption = Boolean(innerCaption) && variant !== "minimum";
    const innerCaptionContent =
        typeof innerCaption === "function" ? (
            innerCaption?.()
        ) : (
            <InlineCaption>
                <Caption>{innerCaption}</Caption>
            </InlineCaption>
        );

    const handleKeyUp = (e: React.KeyboardEvent) => {
        if (e.code === "Enter") {
            e.preventDefault();
            onChange(!checked);
        }
    };

    if (asSimpleSwitch) {
        return (
            <HeadlessSwitch checked={checked} onChange={disabled ? noop : onChange} as={React.Fragment}>
                {(props) => (
                    <SwitchCore onKeyUp={handleKeyUp} size={size} checked={props.checked} disabled={disabled} />
                )}
            </HeadlessSwitch>
        );
    }

    return (
        <SwitchContainer $disabled={disabled} $fullWidth={fullWidth}>
            {hasCaption && captionContent}
            <HeadlessSwitch checked={checked} onChange={disabled ? noop : onChange} as={React.Fragment}>
                {(props) => (
                    <SwitchLayout
                        onKeyUp={handleKeyUp}
                        data-field-group
                        $error={hasError}
                        $filterActive={checked && asFilter}
                    >
                        {hasInnerCaption && innerCaptionContent}
                        <Essence $variant={variant}>{essence}</Essence>
                        <SwitchCore size={size} checked={props.checked} disabled={disabled} />
                    </SwitchLayout>
                )}
            </HeadlessSwitch>
            {hasError && <Error>{error}</Error>}
            {hasHint && hintContent}
        </SwitchContainer>
    );
};

export * from "./types";

export const Switch = Object.assign(SwitchFn, {
    Group: GroupFn,
    Label: LabelFn,
});
