import React from "react";
import styled, { useTheme } from "styled-components";
import { TextSmall } from "../typography";

const Container = styled.div<{ width?: string }>`
    display: flex;
    flex-direction: column;
    width: ${(p) => p.width ?? "100%"};
    gap: 2px;
`;

const Header = styled.div`
    display: flex;
    width: 100%;
`;

const Body = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 6px;
`;

export const CardItem: React.FC<{
    title: string | React.ReactElement;
    label?: string | React.ReactElement;
    width?: string;
}> = ({ title, width, children }) => {
    const { colors } = useTheme();
    const titleElement = typeof title === "string" ? <TextSmall color={colors.ui72}>{title}</TextSmall> : title;

    return (
        <Container width={width}>
            <Header>{titleElement}</Header>
            <Body>{children}</Body>
        </Container>
    );
};
