import { P } from "@fm-frontend/uikit";
import React from "react";
import { TradeHistory } from "../../../types";
import { CopyCell } from "../CopyCell";

type OrderIdProps = Pick<TradeHistory, "orderId">;

export const OrderIdCell: React.VFC<OrderIdProps> = ({ orderId }) => {
    if (orderId === null || orderId === undefined) {
        return <></>;
    }

    return (
        <CopyCell content={orderId}>
            <P>{orderId}</P>
        </CopyCell>
    );
};
