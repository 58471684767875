import { addMinutes, differenceInMinutes, format, formatDistanceStrict } from "date-fns";

export const getDistanceLabel = (dateA: Date, dateB: Date) => {
    if (differenceInMinutes(dateA, dateB) < 1) {
        return "now";
    }

    return formatDistanceStrict(dateB, dateA, { addSuffix: true });
};

export const getTimeLabel = (date: Date) => {
    return `${format(addMinutes(date, date.getTimezoneOffset()), "HH:mm:ss")}, UTC +0`;
};
