import { Badge } from "@fm-frontend/uikit";
import React from "react";
import { AccountStatusType } from "../../types";

export const StatusCell: React.FC<{ status: AccountStatusType }> = ({ status }) => {
    if (status === AccountStatusType.Verification) {
        return <Badge value="Verification" variant="process" />;
    }
    if (status === AccountStatusType.Approved) {
        return <Badge value="Approved" variant="positive" />;
    }
    if (status === AccountStatusType.NeedToConfirm) {
        return <Badge value="Need to confirm" variant="attention" />;
    }

    return null;
};
