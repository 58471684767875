import { deleteAddress } from "../api";
import { DeleteAccountType, FormApiErrors } from "../types";

export const useDeleteAddresses = () => {
    return async (deleteAddressesList: DeleteAccountType[]) => {
        const requests: Promise<string | null>[] = [];
        deleteAddressesList.forEach(({ accountId, accountRuleId }) =>
            requests.push(deleteAddress(accountId, accountRuleId)),
        );

        const result = await Promise.allSettled(requests);

        return result.reduce((acc, curr, currentIndex) => {
            if (curr.status === "fulfilled" && curr.value) {
                const id = deleteAddressesList[currentIndex].id;

                acc = {
                    ...acc,
                    [id]: curr.value,
                };
            }
            return acc;
        }, {} as FormApiErrors);
    };
};
