import { DesctructiveButton, Form, Header, HeaderTitle, HStack, P, PlainButton, VStack } from "@fm-frontend/uikit";
import { cancelInvitationApi } from "api/Invitation";
import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

const FormContainer = styled(Form)`
    width: 100%;
    min-width: initial;
    max-width: unset;
    gap: unset;
`;

const FormHeader = styled(Header)`
    align-items: center;
    padding: 12px;
`;

const FormActionsContainer = styled(HStack)`
    width: 100%;
    gap: 8px;
    padding: 16px 12px;

    border-top: 1px solid ${(p) => p.theme.colors.ui8};
`;

const FormContent = styled(VStack)`
    padding: 0 12px 24px;
`;

const Info = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;

const Email = styled.span`
    color: ${(p) => p.theme.colors.ui100};
    overflow-wrap: break-word;
`;

type CancelInvitationFormProps = {
    onDone: () => void;
    referralEmail: string;
};

export const CancelInvitationForm: React.FC<CancelInvitationFormProps> = ({ referralEmail, onDone }) => {
    const {
        handleSubmit,
        formState: { isSubmitting },
    } = useForm();

    const handleCancelInvitation = handleSubmit(async () => {
        await cancelInvitationApi({
            referralEmail,
        });
        onDone();
    });

    return (
        <FormContainer onSubmit={handleCancelInvitation}>
            <FormHeader>
                <HeaderTitle title="Cancel invitation?" />
            </FormHeader>
            <FormContent>
                <Info>
                    The activation link for <Email>{referralEmail}</Email> will be deactivated and no longer accessible.
                </Info>
            </FormContent>
            <FormActionsContainer>
                <PlainButton disabled={isSubmitting} type="button" size="large" fullWidth>
                    No
                </PlainButton>
                <DesctructiveButton loading={isSubmitting} size="large" fullWidth>
                    Yes, cancel
                </DesctructiveButton>
            </FormActionsContainer>
        </FormContainer>
    );
};
