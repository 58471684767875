import { EmDash } from "const";
import React from "react";
import { CopyCell } from "../CopyCell";

type GeneralCellProps = {
    value?: number | string;
};

export const GeneralCell: React.FC<GeneralCellProps> = ({ value }) => {
    if (value === undefined) {
        return <>{EmDash}</>;
    }

    return <CopyCell content={value}>{value}</CopyCell>;
};
