import { useCallback } from "react";

type ValueType = string | number | bigint | undefined | null;
type CallbackValueType = string | number | bigint;

export const useCopyToClipboard = () => {
    return useCallback((value: ValueType, cb?: (value: CallbackValueType) => void) => {
        if (value === undefined || value === null) {
            return;
        }

        navigator.clipboard.writeText(String(value));
        cb?.(value);
    }, []);
};
