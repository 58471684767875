import { H2, Modal, VStack } from "@fm-frontend/uikit";
import { useFormShouldCloseConfirm, useModalControl } from "@fm-frontend/utils";
import { FIXED_MODAL_STYLE } from "const/modal";
import { POSITION_COUNTERPARTY_ID } from "const/position";
import { CounterpartyLimitModal } from "feature/counterparties/counterpartyLimitModal";
import { useSubaccounts } from "feature/subaccounts/api";
import { useFaqLinks } from "hooks/useFaqLinks";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { usePrimeBrokerViewTypeChange } from "hooks/usePrimeBrokerViewTypeChange";
import { FC, ReactNode, useState } from "react";
import { useCounterpartyLimitsSnapshot, useIsPrimeBrokerUser, usePrimeBrokerViewType } from "store/hooks";
import { setPreopenedCpIdLimit } from "store/preopenedCpIdLimit";
import { CounterpartyIdModal, CpData } from "./CounterpartyIdModal";
import { getFormTitle } from "./messages";
import { COUNTERPARTY_LIMIT_MODAL_KEY } from "./utils";

enum State {
    CounterpartyId,
    CounterpartyLimit,
}

type AddNewLimitProps = {
    renderButton: (openModal: () => void) => ReactNode;
    onClose?: () => void;
};

export const AddNewLimit: FC<AddNewLimitProps> = ({ renderButton, onClose }) => {
    const faqLinks = useFaqLinks();
    const { data: counterpartyLimits } = useCounterpartyLimitsSnapshot();
    const { subaccounts } = useSubaccounts();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const changePrimeBrokerViewType = usePrimeBrokerViewTypeChange();
    // TODO: check State.CounterpartyLimit
    const [state, setState] = useState(isPrimeBrokerUser ? State.CounterpartyId : State.CounterpartyId);
    const [cpData, setCpData] = useState<CpData>();
    const { isModalOpen, openModal, closeModal: closeModalPopup } = useModalControl(false);
    const primeBrokerViewType = usePrimeBrokerViewType();

    const closeModal = () => {
        closeModalPopup();
        onClose?.();
    };
    const shouldConfirmClose = useFormShouldCloseConfirm(COUNTERPARTY_LIMIT_MODAL_KEY);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(shouldConfirmClose, closeModal);

    const handleStep1Done = (data: CpData) => {
        const { cpId } = data;

        const newLimit = counterpartyLimits.every((limit) => limit[POSITION_COUNTERPARTY_ID] !== cpId);
        if (newLimit) {
            setCpData(data);
            setState(State.CounterpartyLimit);
            return;
        }

        const isSubaccount = subaccounts.some((subaccount) => subaccount.info.clientId === cpId);
        if (isSubaccount && primeBrokerViewType === "counterparties") {
            changePrimeBrokerViewType("subaccounts", true);
        }
        if (!isSubaccount && primeBrokerViewType === "subaccounts") {
            changePrimeBrokerViewType("counterparties", true);
        }

        // Wait for change Prime Broker View Type
        setTimeout(() => {
            setPreopenedCpIdLimit(cpId);
            closeModal();
        }, 100);
    };

    return (
        <>
            {renderButton(openModal)}
            <Modal
                isOpen={isModalOpen}
                onClose={closeModalWithConfirm}
                additionalActions={{
                    helpButtonUrlOrClickHandler: faqLinks?.faqRiskManagement,
                }}
                style={FIXED_MODAL_STYLE}
            >
                <VStack width="360px" asCard>
                    <VStack padding={12}>
                        <H2>{getFormTitle(primeBrokerViewType)}</H2>
                    </VStack>
                    <VStack padding={12} paddingTop={0}>
                        {state === State.CounterpartyId && (
                            <CounterpartyIdModal onCounterpartySelected={handleStep1Done} />
                        )}
                        {state === State.CounterpartyLimit && (
                            <CounterpartyLimitModal
                                title="Limits for new"
                                cpData={cpData!}
                                onSubmit={closeModal}
                                onClose={closeModalWithConfirm}
                            />
                        )}
                    </VStack>
                </VStack>
            </Modal>
        </>
    );
};
