import { TopMenu } from "../../../components/TopMenu";
import { Page, Sidebar } from "../../../style";
import { OrderForm } from "./OrderForm";
import { OrdersTable } from "./OrdersTable";

export const PageOrders = () => (
    <Page>
        <TopMenu>Orders</TopMenu>
        <Sidebar>
            <OrderForm />
            <div>
                <OrdersTable />
            </div>
        </Sidebar>
    </Page>
);
