import { TopMenu } from "components/TopMenu";
import { SettlementsPageContent } from "feature/settlements";
import React from "react";
import { Page } from "style";

export const SettlementsPage: React.FC = () => {
    return (
        <Page noBottomSpace>
            <TopMenu>Settlement transactions</TopMenu>
            <SettlementsPageContent />
        </Page>
    );
};
