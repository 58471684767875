import { useTheme } from "styled-components";
import { Settlement } from "./types";

export const useTransactionStatusStyles = (type: Settlement["type"]) => {
    const theme = useTheme();

    return {
        request: {
            title: "Request",
            bgColor: type === "in" ? theme.colors.attention8 : theme.colors.process8,
            color: type === "in" ? theme.colors.attention100 : theme.colors.process100,
        },
        received: {
            title: "Received",
            bgColor: theme.colors.attention8,
            color: theme.colors.attention100,
        },
        committed: {
            title: "Committed",
            bgColor: theme.colors.brand8,
            color: theme.colors.brand100,
        },
        cancelled: {
            title: "Cancelled",
            bgColor: theme.colors.ui4,
            color: theme.colors.ui52,
        },
        sent: {
            title: "Sent",
            bgColor: theme.colors.process8,
            color: theme.colors.process100,
        },
        created: {
            title: "Created",
            bgColor: theme.colors.attention8,
            color: theme.colors.attention100,
        },
    };
};
