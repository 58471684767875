import { PXSmall } from "@fm-frontend/uikit";
import { isToday } from "date-fns";
import format from "date-fns/format";
import { TradeHistory } from "feature/history/types";
import { FC } from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const TimeInfo = styled(PXSmall)``;

const DateInfo = styled(PXSmall)`
    color: ${(p) => p.theme.colors.ui52};
`;

type DateTimeCellProps = Pick<TradeHistory, "date">;

export const DateTimeCell: FC<DateTimeCellProps> = ({ date }) => {
    if (date === undefined) {
        return null;
    }

    const isTodayDealMoment = isToday(date);

    return (
        <Container>
            <TimeInfo>{format(date, "HH:mm:ss")}</TimeInfo>
            {!isTodayDealMoment && <DateInfo>{format(date, "dd LLL")}</DateInfo>}
        </Container>
    );
};
