import { useCallback, useEffect, useState } from "react";

export const useDelayedState = <T>(
    delay: number,
    initialValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>, T, (immediateValue: T) => void] => {
    const [value, setValue] = useState(initialValue);
    const [delayedValue, setDelayedValue] = useState(initialValue);

    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            setDelayedValue(value);
        }, delay);
        return () => clearTimeout(delayInputTimeoutId);
    }, [value]);

    const setValueImmediately = useCallback((immediateValue: T) => {
        setValue(immediateValue);
        setDelayedValue(immediateValue);
    }, []);

    return [delayedValue, setValue, value, setValueImmediately];
};
