import { post } from "api";
import { EMPTY_ARRAY } from "const";
import { usePrimeBrokerViewType } from "store/hooks";
import useSWR from "swr";
import { CounterpartyLimit, SubaccountLimit } from "types";
import { displayError } from "utils";

type Limit = CounterpartyLimit | SubaccountLimit;

const fetcher = ([url, filter]: [string, "external" | "subaccounts"]) => post(url, { filter });

export const useFilteredLimits = (refreshIntervalMs?: number) => {
    const primeBrokerViewType = usePrimeBrokerViewType();
    const apiUrl = "climits";
    const filter = primeBrokerViewType === "subaccounts"
            ? "subaccounts"
            : "external";

    return useSWR<Limit[]>([apiUrl, filter], fetcher, {
        onError: displayError,
        fallbackData: EMPTY_ARRAY as Limit[],
        revalidateOnMount: true,
        revalidateOnFocus: false,
        refreshInterval: refreshIntervalMs,
    });
};
