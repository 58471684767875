import { H1, ShadowCard } from "@fm-frontend/uikit";
import { onTablet } from "const";
import React, { useState } from "react";
import styled from "styled-components";
import { usePreloadInvitesList } from "hooks/useInvitesList";
import { SubaccountDetails } from "./SubaccountDetails";
import { Subaccounts } from "./Subaccounts";
import { FilterContextProvider } from "./SubaccountsFilterContext";
import { ListItem, ListItemType } from "./types";
import { InviteDetails } from "./InviteDetails";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
    margin-top: 18px;
    padding: 0 12px;

    ${H1} {
        line-height: 26px;
    }

    ${onTablet} {
        position: relative;
        left: unset;
    }
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 700px;

    ${ShadowCard} {
        margin-bottom: 12px;
    }
`;

export const AbsolutePositionedTitle = styled(H1)`
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);

    ${onTablet} {
        position: relative;
        left: unset;
        transform: unset;
    }
`;

const ShifterContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

export const SubaccountsPageContent: React.FC = () => {
    usePreloadInvitesList();

    const [selectedItem, setSelectedItem] = useState<ListItem | null>(null);

    return (
        <Container>
            <ShifterContainer>
                <Content>
                    <FilterContextProvider>
                        {selectedItem === null && (
                            <Subaccounts onItemSelect={setSelectedItem} />
                        )}
                        {selectedItem?.type === ListItemType.Subaccount && (
                            <SubaccountDetails
                                subaccount={selectedItem.item}
                                onBackClick={() => setSelectedItem(null)}
                            />
                        )}
                        {selectedItem?.type === ListItemType.Invite && (
                            <InviteDetails
                                invite={selectedItem.item}
                                onBackClick={() => setSelectedItem(null)}
                            />
                        )}
                    </FilterContextProvider>
                </Content>
            </ShifterContainer>
        </Container>
    );
};
