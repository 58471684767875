import {
    CheckboxField,
    Form,
    FormActions,
    FormBody,
    HStack,
    Icons,
    Modal,
    PrimaryButton,
    ShadowCard,
    SimpleInput,
    Tooltip
} from "@fm-frontend/uikit";
import { yupResolver } from "@hookform/resolvers/yup";
import { post } from "api";
import { useSaveSuccessNotification } from "feature/addresses/hooks";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { setConfirmModalContent } from "feature/app";
import { FieldGrouper } from "feature/counterparties/styled";
import { TextArea } from "feature/form/style";
import { deleteSettlementRequest } from "feature/settlements/api";
import {
    ShouldRemoveRequest
} from "feature/specificSubaccountAndPBPagesContents/pbSubaccountsViewTransactionsPageContent/modals/CreateTransactionModal";
import { getAmountSchema, getFeeValidator } from "feature/specificSubaccountAndPBPagesContents/yupValidators";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useFilteredAndExtendedPositionsSnapshot } from "store/hooks";
import { preparePosition } from "types";
import { displayError, getCurrencyPlaceholder } from "utils";
import EfxTypes from "utils/EfxTypes";
import { getAbsoluteValue } from "utils/format";
import { AnySchema, boolean, object, string } from "yup";
import { Settlement, SettlementStatus } from "../../types";
import { ModalHeader } from "./components/ModalHeader";
import { SettlementInfo } from "./components/SettlementInfo";
import { Error } from "./styled";

type Inputs = {
    amount: string;
    comment: string;
    isFeePaidByRecipient: boolean;
    fee?: string;
    shouldRemoveRequest?: boolean;
};

export const validationSchema = object<Record<keyof Inputs, AnySchema>>().shape({
    amount: getAmountSchema(),
    comment: string().optional(),
    isFeePaidByRecipient: boolean(),
    fee: string().when(["isFeePaidByRecipient"], {
        is: (isFeePaidByRecipient: boolean) => isFeePaidByRecipient,
        then: (schema) => getFeeValidator(schema),
        otherwise: (schema) => schema.optional(),
    }),
});

const useAssetShortPositions = ({ asset, cpId }: { asset: string; cpId: number }) => {
    const { data: filteredExtendedPositionsSnapshot = [] } =
        useFilteredAndExtendedPositionsSnapshot();

    return useMemo(() => {
        return filteredExtendedPositionsSnapshot.reduce<{ value: bigint }[]>(
            (acc, { position }) => {
                const { counterpartyId, value, currencyName } = preparePosition(position);

                if (asset === currencyName && cpId === counterpartyId) {
                    acc.push({
                        value: getAbsoluteValue(value) as bigint,
                    });
                }
                return acc;
            },
            [],
        );
    }, [filteredExtendedPositionsSnapshot, asset, cpId]);
};

type IncomingRequestModalContentProps = {
    assetShortPosition: bigint | undefined;
    onRequestDelete: () => void;
    onRequestSubmit: (formInputs: Inputs) => void;
    apiError: string | null;
    isDeleting: boolean;
    settlement: Settlement;
    setIsModalDirty: (isDirty: boolean) => void;
};

const IncomingRequestModalContent = ({
    settlement,
    assetShortPosition,
    onRequestDelete,
    onRequestSubmit,
    apiError,
    isDeleting,
    setIsModalDirty,
}: IncomingRequestModalContentProps) => {
    const appliedAmount = settlement.amount || assetShortPosition || 0n;

    const {
        control,
        formState: { errors, isSubmitting, isDirty },
        watch,
        handleSubmit,
        register,
        setValue,
    } = useForm<Inputs>({
        // @ts-ignore
        defaultValues: {
            // @ts-ignore
            amount:
                appliedAmount !== 0n
                    ? EfxTypes.formatValue(getAbsoluteValue(appliedAmount), "size64")
                    : "",
            isFeePaidByRecipient: Boolean(settlement.fee?.payer === "recipient"),
            comment: "",
            shouldRemoveRequest: true,
        },
        mode: "onSubmit",
        reValidateMode: "onChange",
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        setIsModalDirty(isDirty);
    }, [isDirty]);

    const isFeePaidByRecipient = watch("isFeePaidByRecipient");
    useEffect(() => {
        if (!isFeePaidByRecipient) {
            setValue("fee", undefined);
        }
    }, [isFeePaidByRecipient]);

    return (
        <Form onSubmit={handleSubmit(onRequestSubmit)}>
            <ShadowCard>
                <ModalHeader settlement={settlement} />
                <FormBody spacing={8}>
                    <SettlementInfo
                        settlement={settlement}
                        onDeleteSettlement={onRequestDelete}
                        isDeleteButtonLoading={isDeleting}
                    />
                    <SimpleInput
                        label="Amount"
                        {...register("amount")}
                        placeholder={getCurrencyPlaceholder(settlement.asset)}
                        error={errors.amount?.message}
                    />
                    <FieldGrouper isExpanded={isFeePaidByRecipient} fullWidth>
                        <CheckboxField
                            name="isFeePaidByRecipient"
                            label={
                                <HStack spacing={4}>
                                    Fee paid by recipient{" "}
                                    <Tooltip content="If the CP is ready to cover the network fee, please, mark this field and indicate the total amount that is a sum of the blockchain transaction amount and the network fee.">
                                        <Icons.Help />
                                    </Tooltip>
                                </HStack>
                            }
                            size="large"
                            fullWidth
                            control={control}
                        />
                        {isFeePaidByRecipient && (
                            <SimpleInput
                                label="Size"
                                {...register("fee")}
                                placeholder={getCurrencyPlaceholder(settlement.asset)}
                                error={errors.fee?.message}
                            />
                        )}
                    </FieldGrouper>
                    <TextArea rows={4} placeholder="Comment" autoFocus {...register("comment")} />
                    {apiError && <Error>{apiError}</Error>}
                </FormBody>
                <FormActions variant="plain">
                    <ShouldRemoveRequest name="shouldRemoveRequest" control={control} />
                    <PrimaryButton type="submit" fullWidth loading={isSubmitting} size="large">
                        Create transaction
                    </PrimaryButton>
                </FormActions>
            </ShadowCard>
        </Form>
    );
};

type IncomingRequestModalProps = {
    closeModal: () => void;
    settlement: Settlement;
    onContinueClick: (createdTransaction: Settlement) => void;
};
export const IncomingRequestModal = ({
    closeModal,
    settlement,
    onContinueClick,
}: IncomingRequestModalProps) => {
    const dispatch = useDispatch();
    const showSuccessNotification = useSaveSuccessNotification();
    const { getCpName } = useCpInfoHelpers();
    const assetShortPositions = useAssetShortPositions({
        asset: settlement.asset,
        cpId: settlement.cpId,
    });

    const [apiError, setApiError] = useState<string | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isModalDirty, setIsModalDirty] = useState(false);

    const { closeModalWithConfirm } = useModalCloseWithConfirm(isModalDirty, closeModal);

    const handleRequestDelete = async () => {
        dispatch(
            setConfirmModalContent({
                title: "Delete request?",
                onConfirm: async () => {
                    try {
                        setApiError(null);
                        setIsDeleting(true);
                        await deleteSettlementRequest({
                            requestId: settlement.id,
                        });
                        showSuccessNotification("Request was deleted");
                        closeModal();
                    } catch (e) {
                        setApiError(String(e));
                    } finally {
                        setIsDeleting(false);
                    }
                },
                description: "",
                confirmButtonTitle: "Delete",
            }),
        );
    };

    const handleRequestSubmit = async (formValues: Inputs) => {
        try {
            setApiError(null);
            const amountValue = EfxTypes.parseValue(String(formValues.amount), "size64");
            const feeValue =
                formValues.fee && EfxTypes.parseValue(String(formValues.fee), "size64");
            const requestBody: Record<string, number | string> = {
                counterpartyId: settlement.cpId,
                currency: settlement.asset,
                amount: amountValue,
                fee: feeValue,
                comment: formValues.comment,
            };
            if (settlement.network) {
                requestBody.network = settlement.network;
            }

            const now = Date.now();
            const { settlementTransactionId } = await post(
                "addOutgoingSettlementTransaction",
                requestBody,
            );

            showSuccessNotification("Transaction was created");

            if (formValues.shouldRemoveRequest) {
                await deleteSettlementRequest({
                    requestId: settlement.id,
                }).catch(displayError);
            }

            const createdTransaction: Settlement = {
                status: SettlementStatus.created,
                type: "out",
                cpId: settlement.cpId,
                cpName: getCpName(settlement.cpId, "short"),
                asset: settlement.asset,
                network: settlement.network,
                amount: amountValue,
                fee: {
                    size: feeValue,
                    payer: Boolean(feeValue) ? "recipient" : "sender",
                },
                comment: formValues.comment,
                createdAt: now,
                lastActionAt: now,
                txId: "",
                id: settlementTransactionId,
            };
            onContinueClick(createdTransaction);
        } catch (err) {
            setApiError(err as string);
        }
    };

    return (
        <Modal isOpen onClose={closeModalWithConfirm}>
            <IncomingRequestModalContent
                settlement={settlement}
                assetShortPosition={assetShortPositions[0]?.value}
                key={`${assetShortPositions[0]?.value}`}
                onRequestDelete={handleRequestDelete}
                onRequestSubmit={handleRequestSubmit}
                apiError={apiError}
                isDeleting={isDeleting}
                setIsModalDirty={setIsModalDirty}
            />
        </Modal>
    );
};
