import { ClientId, DropdownOption, HStack, P, PSmall } from "@fm-frontend/uikit";
import styled from "styled-components";

const Placeholder = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;
const Container = styled(HStack)`
    align-items: center;
    gap: 6px;
    overflow: hidden;
`;
type ProvidersTriggerEssenceProps = {
    selectedProvidersIds: number[] | undefined;
    size: "large" | "medium" | "small";
    placeholder?: string;
    options: DropdownOption<number>[];
};
export const ProvidersTriggerEssence = ({
    selectedProvidersIds,
    size,
    placeholder,
}: ProvidersTriggerEssenceProps) => {
    const TextElement = size === "large" ? P : PSmall;

    if (!selectedProvidersIds || selectedProvidersIds.length === 0) {
        return (
            <Container>
                <Placeholder as={TextElement} ellipsis>
                    {placeholder}
                </Placeholder>
            </Container>
        );
    }

    return (
        <Container>
            {selectedProvidersIds.slice(0, 3).map((providerId) => (
                <ClientId key={providerId} id={providerId} tooltip={null} isSmall />
            ))}
            {selectedProvidersIds.length - 3 > 0 && (
                <TextElement>+{selectedProvidersIds.length - 3}</TextElement>
            )}
        </Container>
    );
};
