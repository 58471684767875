import { memo, useEffect, useMemo, useState } from "react";
import { Cell, useExpanded, useSortBy, useTable } from "react-table";
import { MaybeKeyableObject, TableProps } from ".";
import { IconCollapse } from "../../components/icons";
import { IS_TEST_ENV } from "const/env";
import { ForceDropdownDirection } from "../../hooks/useDropdownDirection";
import { Spacer } from "../../style";
import { noop, when } from "../../utils";
import { DummyButton } from "../form/Buttons";
import {
    Banner,
    ExpandedButton,
    GlobalActions,
    HeaderActions,
    StyledTable,
    TableBadgeRow,
    TableContainer,
    TableHeader,
    TitleBadgeContainer,
    TR,
} from "./style";

export function Table<T extends MaybeKeyableObject | unknown[]>({
    title,
    data,
    actions,
    columns,
    sortBy,
    globalActions,
    onRowClick,
    onSelectedRowChange = noop,
    getRowId,
    getSubRows,
    emptyMessage,
    titleSlot,
    getRowProps,
    disableItemBorders,
    appendToRow,
    prependAtIndex,
    titleBadge,
    isLoading,
    areActionsStickied,
    isExpandable,
    isVisibleExpandableToolbar,
    isRowSelect,
    isDefaultExtended,
    expendedRows,
    onChangeExpand = noop,
    "data-test-id": testId,
    ...props
}: TableProps<T>) {
    const [selected, setSelected] = useState<string | undefined>();
    const [firstBindData, setFirstData] = useState<boolean>(false);
    const actionField = useMemo(
        () => ({
            id: "action",
            Header: "",
            accessor: actions,
            Cell: ({ value }: { value: React.ReactNode }) => (
                <ForceDropdownDirection vertical="top">{value}</ForceDropdownDirection>
            ),
        }),
        [Boolean(actions)],
    );
    const expandField = useMemo(
        () => ({
            id: "expand",
            Header: "",
            Cell: ({ row }: Cell) => {
                if (!row.canExpand) return null;
                return (
                    <DummyButton
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <IconCollapse isOpen={row.isExpanded} />
                    </DummyButton>
                );
            },
        }),
        [],
    );
    const effectiveColumns = useMemo(
        () => [...(isExpandable ? [expandField] : []), ...columns, ...(actions ? [actionField] : [])],
        [actionField, columns, isExpandable],
    );
    const tableArgs = useMemo(() => {
        let initialState = {};

        if (sortBy) {
            initialState = { ...initialState, sortBy };
        }

        if (expendedRows) {
            initialState = { ...initialState, expanded: expendedRows };
        }

        return {
            columns: effectiveColumns,
            data: data || [],
            autoResetSortBy: false,
            getSubRows,
            getRowId,
            initialState,
        };
    }, [effectiveColumns, data, sortBy, getRowId, expendedRows]);
    const plugins = useMemo(() => {
        const plugins = [];

        if (sortBy) plugins.push(useSortBy);

        if (isExpandable) plugins.push(useExpanded);

        return plugins;
    }, [sortBy, isExpandable]);
    const {
        getTableProps,
        rows,
        headers,
        prepareRow,
        rowsById,
        toggleAllRowsExpanded,
        state: { expanded },
    } = useTable(tableArgs, ...plugins);

    const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: any) => {
        onRowClick?.(e, row);
        if (isExpandable) {
            row.toggleRowExpanded();
        }
        const parentRowId = row.id.split(".").slice(0, -1).join(".");
        const parentRow = rowsById[parentRowId];
        setSelected(row.id);
        onSelectedRowChange(row, parentRow, expanded);
    };

    useEffect(() => {
        onChangeExpand(expanded);
    }, [expanded]);

    useEffect(() => {
        if (isDefaultExtended && !firstBindData && data.length > 0) {
            toggleAllRowsExpanded(true);
            setFirstData(false);
        }
    }, [data]);

    return (
        <TableContainer>
            {title ? (
                <TableHeader>
                    {title}
                    {titleBadge ? (
                        <>
                            <Spacer />
                            <TitleBadgeContainer>{titleBadge}</TitleBadgeContainer>
                        </>
                    ) : null}
                    {isVisibleExpandableToolbar ? (
                        <>
                            <Spacer />
                            <TitleBadgeContainer>
                                <ExpandedButton onClick={() => toggleAllRowsExpanded(true)}>Expand all</ExpandedButton>
                                <ExpandedButton onClick={() => toggleAllRowsExpanded(false)}>
                                    Collapse all
                                </ExpandedButton>
                            </TitleBadgeContainer>
                        </>
                    ) : null}
                </TableHeader>
            ) : null}
            {when(titleSlot && data?.length > 1, <HeaderActions>{titleSlot}</HeaderActions>)}
            {rows.length === 0 ? (
                <Banner>{isLoading ? "Loading" : emptyMessage || "No data yet"}</Banner>
            ) : (
                <StyledTable
                    disableItemBorders={disableItemBorders}
                    hasActions={Boolean(actions)}
                    areActionsStickied={areActionsStickied}
                    {...getTableProps()}
                    {...props}
                    data-test-id={IS_TEST_ENV ? `table-${testId}` : undefined}
                >
                    <thead>
                        <tr>
                            {headers.map((column: any) => (
                                <th {...column.getHeaderProps()} style={column.headerStyle}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, rowIdx) => {
                            prepareRow(row);
                            const contentToPrepend = prependAtIndex?.[rowIdx];
                            const rowProps = row.getRowProps();
                            const renderedRows = contentToPrepend
                                ? [
                                      <TableBadgeRow key={`prepend-${rowProps.key}`}>
                                          <td colSpan={9}>
                                              <div>{contentToPrepend}</div>
                                          </td>
                                      </TableBadgeRow>,
                                  ]
                                : [];
                            renderedRows.push(
                                <TR
                                    {...rowProps}
                                    {...(getRowProps?.(rowIdx) as any)}
                                    onClick={(e) => handleRowClick(e, row)}
                                    isSubRow={row.depth > 0 && !isRowSelect}
                                    isSelected={isRowSelect && selected === row.id}
                                >
                                    {row.cells.map((cell: any) => {
                                        const testId = IS_TEST_ENV
                                            ? `cell-${cell.column.id?.toLowerCase().replaceAll(" ", "-") || ""}`
                                            : undefined;
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                style={cell.column.cellStyle}
                                                data-test-id={testId}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                    {appendToRow?.(row, rowIdx)}
                                </TR>,
                            );
                            return renderedRows;
                        })}
                    </tbody>
                </StyledTable>
            )}
            {globalActions && (
                <>
                    <Spacer />
                    <GlobalActions>{globalActions}</GlobalActions>
                </>
            )}
        </TableContainer>
    );
}

export const MemoTable = memo(Table) as typeof Table;
