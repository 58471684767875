import { Icons, PSmall, PXSmall } from "@fm-frontend/uikit";
import React, { FC } from "react";
import styled, { useTheme } from "styled-components";
import { HintContent } from "components/modal/Hints/HintContent";
import { HintIconContainer } from "components/modal/Hints/HintIconContainer";
import { HintContainer } from "components/modal/Hints/HintContainer";

const SubTitle = styled(PXSmall)`
    color: ${(p) => p.theme.colors.ui52};
`;

type HintItemProps = {
    title: string;
    subtitle?: string;
    onClick?: () => void;
};

export const HintItem: FC<HintItemProps> = ({ title, subtitle, onClick }) => {
    const theme = useTheme();

    return (
        <HintContainer onClick={onClick}>
            <HintContent>
                <PSmall>{title}</PSmall>
                <SubTitle>{subtitle}</SubTitle>
            </HintContent>
            <HintIconContainer>
                <Icons.Forward color={theme.colors.ui20} />
            </HintIconContainer>
        </HintContainer>
    );
};
