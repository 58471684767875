import { Switch } from "@fm-frontend/uikit";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { createNotification } from "../../feature/app";
import { settingSlice } from "../../feature/settings";

const InteractionSettingContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    border-radius: 8px;

    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.005em;
    color: ${(p) => p.theme.colors.ui72};

    height: 44px;
    background: ${(p) => p.theme.colors.ui8};
`;

interface InteractionSettingProps {
    title: string;
    value: boolean;
    settingId: "showRealTimeNotification" | "enableSound";
}

export const InteractionSetting = ({
    title,
    value: settingValue,
    settingId,
}: InteractionSettingProps) => {
    const dispatch = useDispatch();
    const handleChange = (value: boolean) => {
        dispatch(
            settingSlice.actions.setSetting({
                key: settingId,
                value,
            }),
        );
        dispatch(
            createNotification({
                type: "success",
                content: "Preference has been saved",
            }),
        );
    };

    return (
        <InteractionSettingContainer>
            {title}
            <Switch checked={settingValue} onChange={handleChange} />
        </InteractionSettingContainer>
    );
};
