import {
    CheckboxField,
    Form,
    FormActions,
    FormBody,
    Header,
    HeaderTitle,
    HStack,
    Icons,
    Modal,
    PrimaryButton,
    ShadowCard,
    SimpleInput,
    Tab,
    TabContext,
    TabList,
    Tooltip,
} from "@fm-frontend/uikit";
import { SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { yupResolver } from "@hookform/resolvers/yup";
import { post } from "api";
import { CounterpartiesSheet } from "components/CounterpartiesSheet";
import { CounterpartyTriggerEssence } from "components/CounterpartiesTriggerEssence";
import { useSaveSuccessNotification } from "feature/addresses/hooks";
import { FieldGrouper } from "feature/counterparties/styled";
import { TextArea } from "feature/form/style";
import { AssetAndNetworkSelector } from "feature/specificSubaccountAndPBPagesContents/AssetAndNetworkSelector";
import {
    getAmountSchema,
    getFeeValidator,
} from "feature/specificSubaccountAndPBPagesContents/yupValidators";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { useCounterpartiesOptions } from "pages/trade/orders/useCounterpartiesOptions";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { getCurrencyPlaceholder, getFmtPrecisionConfig } from "utils";
import EfxTypes from "utils/EfxTypes";
import { fmt } from "utils/format";
import { ValidationMessages } from "utils/validationMessages";
import { AnySchema, boolean, number, object, string } from "yup";
import { Error } from "../styled";
import { Settlement, SettlementStatus } from "../types";
import { AddressHints } from "./AddressHints";
import { OpenPositionHints } from "./OpenPositionHints";
import { RequestHints } from "./RequestHints";
import { TransactionHints } from "./TransactionHints";
import { Hint } from "./types";

type SettlementType = "transaction" | "request";
type Inputs = {
    cpId: number;
    asset: string;
    hasNetworks: boolean;
    hasNoLimitForSelectedCP: boolean;
    network?: string;
    amount: string;
    comment: string;
    isFeePaidByRecipient: boolean;
    fee?: string;
    type: SettlementType;
    isAllAvailableAmount: boolean;
    isFeePaidByMe: boolean;
};

interface CreateSettlementModalProps {
    onContinueClick: (settlement: Settlement) => void;
    closeModal: () => void;
    defaultValues?: Partial<Inputs>;
}

const FormContent = styled(FormBody)`
    height: 100%;
`;

const StyledForm = styled(Form)`
    max-width: 320px;
    min-width: 320px;
    min-height: 580px;
`;

const FormContainer = styled(ShadowCard)`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
`;

const MS_IN_WEEK = 7 * 24 * 60 * 60 * 1000;

export const validationSchema = object<Record<keyof Inputs, AnySchema>>().shape({
    cpId: number()
        .transform((value: any, originalValue: any) => (originalValue === "" ? undefined : value))
        .required(ValidationMessages.REQUIRED)
        .when("hasNoLimitForSelectedCP", {
            is: true,
            then: (schema) =>
                schema.test("limit-test", ValidationMessages.NO_CP_LIMIT, () => false),
        }),
    asset: string().required(ValidationMessages.REQUIRED),
    amount: string().when("type", {
        is: (type: SettlementType) => type === "transaction",
        then: (schema) => getAmountSchema(schema),
        otherwise: (schema) => schema.optional(),
    }),
    comment: string().optional(),
    isFeePaidByRecipient: boolean(),
    fee: string().when(["isFeePaidByRecipient"], {
        is: (isFeePaidByRecipient: boolean) => isFeePaidByRecipient,
        then: (schema) => getFeeValidator(schema),
        otherwise: (schema) => schema.optional(),
    }),
    hasNoLimitForSelectedCP: boolean().optional(),
    hasNetworks: boolean().required(),
    network: string().when("hasNetworks", {
        is: (hasNetworks: boolean) => hasNetworks,
        then: (schema) => schema.required(ValidationMessages.REQUIRED),
        otherwise: (schema) => schema.optional(),
    }),
    isFeePaidByMe: boolean(),
});

export const CreateSettlementModal = ({
    closeModal,
    onContinueClick,
    defaultValues,
}: CreateSettlementModalProps) => {
    const [isAssetAndNetworkReady, setIsAssetAndNetworkReady] = useState(false);
    const showSuccessNotification = useSaveSuccessNotification();
    const { getCpName } = useCpInfoHelpers();
    const {
        control,
        formState: { errors, isDirty, isSubmitting, defaultValues: isResetted },
        watch,
        handleSubmit,
        getValues,
        register,
        setValue,
        reset,
        trigger,
    } = useForm<Inputs>({
        // @ts-ignore
        defaultValues: {
            asset: "",
            // @ts-ignore
            amount: "",
            isFeePaidByRecipient: false,
            comment: "",
            hasNoLimitForSelectedCP: false,
            hasNetworks: false,
            type: "transaction",
            isAllAvailableAmount: true,
            isFeePaidByMe: false,
            ...(defaultValues ?? {}),
        },
        mode: "onSubmit",
        reValidateMode: "onChange",
        resolver: yupResolver(validationSchema),
    });
    const { closeModalWithConfirm } = useModalCloseWithConfirm(isDirty, closeModal);
    const [apiError, setApiError] = useState<string | null>(null);

    const cpOptions = useCounterpartiesOptions();

    const [amountFocused, setAmountFocused] = useState(false);

    const type = watch("type");
    const asset = watch("asset");
    const isAllAvailableAmount = watch("isAllAvailableAmount");
    useEffect(() => {
        if (type === "request") {
            setValue("isAllAvailableAmount", !getValues().amount);
        }
    }, [type]);
    useEffect(() => {
        if (isAllAvailableAmount) {
            setValue("amount", "");
        }
    }, [isAllAvailableAmount]);

    const selectedCpId = watch("cpId");
    useEffect(() => {
        // rare case when limit for CP was deleted but positions with him still exist
        if (
            selectedCpId !== undefined &&
            cpOptions.every(({ value }) => Number(value) !== selectedCpId)
        ) {
            setValue("hasNoLimitForSelectedCP", true);
        } else {
            setValue("hasNoLimitForSelectedCP", false);
        }
        if (Boolean(selectedCpId)) {
            trigger("cpId");
        }
    }, [isResetted, asset, selectedCpId, cpOptions, trigger]);

    const isFeePaidByRecipient = watch("isFeePaidByRecipient");
    useEffect(() => {
        if (!isFeePaidByRecipient) {
            setValue("fee", undefined);
        }
    }, [isFeePaidByRecipient]);

    const onSubmit = async (formValues: Inputs) => {
        try {
            setApiError(null);

            const amountInputValue = EfxTypes.parseValue(String(formValues.amount), "size64");
            const requestBody: Record<string, number | string> = {
                counterpartyId: formValues.cpId,
                currency: formValues.asset,
                amount: amountInputValue,
                comment: formValues.comment,

                ...(type === "transaction"
                    ? {
                          fee:
                              formValues.fee &&
                              EfxTypes.parseValue(String(formValues.fee), "size64"),
                      }
                    : {
                          cancelTimestamp: Date.now() + MS_IN_WEEK,
                          amount: formValues.isAllAvailableAmount ? 0n : amountInputValue,
                          flags: Number(formValues.isFeePaidByMe),
                      }),
            };

            if (formValues.network) {
                requestBody.network = formValues.network;
            }

            const apiMethod =
                type === "transaction"
                    ? "addOutgoingSettlementTransaction"
                    : "addIncomingSettlementRequest";
            const { settlementTransactionId } = await post(apiMethod, requestBody);

            showSuccessNotification(
                `${type === "transaction" ? "Transaction" : "Request"} was created`,
            );

            closeModal();

            if (type === "transaction") {
                const feeValue =
                    formValues.fee && EfxTypes.parseValue(String(formValues.fee), "size64");
                const now = Date.now();
                const settlement: Settlement = {
                    status: SettlementStatus.created,
                    type: "out",
                    cpId: formValues.cpId,
                    cpName: getCpName(formValues.cpId, "short"),
                    asset: formValues.asset,
                    network: formValues.network,
                    amount: amountInputValue,
                    fee: {
                        size: feeValue,
                        payer: Boolean(feeValue) ? "recipient" : "sender",
                    },
                    comment: formValues.comment,
                    createdAt: now,
                    lastActionAt: now,
                    txId: "",
                    id: settlementTransactionId,
                };
                onContinueClick(settlement);
            }
        } catch (err) {
            setApiError(err as string);
        }
    };

    const showOpenPositions = Boolean(
        type === "transaction" && amountFocused && getValues().cpId && asset,
    );
    const showAddresses = Boolean(type === "request" && getValues().cpId && asset);

    return (
        <Modal isOpen onClose={closeModalWithConfirm}>
            <HStack maxHeight="90vh">
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                    <FormContainer>
                        <Header>
                            <HeaderTitle title="New transaction" />
                        </Header>
                        <FormContent spacing={8}>
                            <TabContext
                                value={type}
                                handleClick={(value) => setValue("type", value as SettlementType)}
                            >
                                <TabList>
                                    <Tab title="Send" value="transaction" />
                                    <Tab title="Request" value="request" />
                                </TabList>
                            </TabContext>
                            <Controller
                                control={control}
                                render={({ field }) => (
                                    <SingleDropdown
                                        value={field.value}
                                        onChange={field.onChange}
                                        renderTrigger={(trigger) => (
                                            <SingleDropdown.Trigger
                                                {...trigger}
                                                size="large"
                                                variant="simple"
                                            >
                                                <CounterpartyTriggerEssence
                                                    {...trigger}
                                                    option={trigger.selectedOption}
                                                    size="large"
                                                />
                                            </SingleDropdown.Trigger>
                                        )}
                                        options={cpOptions}
                                        placeholder="Select"
                                        caption="Counterparty"
                                        align="end"
                                        error={errors.cpId?.message}
                                        fullWidth
                                    >
                                        <CounterpartiesSheet
                                            size="medium"
                                            options={cpOptions}
                                            Dropdown={SingleDropdown}
                                        />
                                    </SingleDropdown>
                                )}
                                name="cpId"
                            />
                            <AssetAndNetworkSelector
                                control={control}
                                setValue={setValue}
                                assetError={errors.asset?.message}
                                networkError={errors.network?.message}
                                onReady={setIsAssetAndNetworkReady}
                            />
                            {type === "request" && (
                                <>
                                    <FieldGrouper isExpanded={!isAllAvailableAmount} fullWidth>
                                        <CheckboxField
                                            name="isAllAvailableAmount"
                                            label={
                                                <HStack spacing={4}>
                                                    All available amount{" "}
                                                    <Tooltip content="If you want to withdraw a specific amount, please uncheck it.">
                                                        <Icons.Help />
                                                    </Tooltip>
                                                </HStack>
                                            }
                                            size="large"
                                            fullWidth
                                            control={control}
                                        />
                                        {!isAllAvailableAmount && (
                                            <SimpleInput
                                                label="Amount"
                                                placeholder={getCurrencyPlaceholder(asset)}
                                                {...register("amount")}
                                                error={errors.amount?.message}
                                            />
                                        )}
                                    </FieldGrouper>
                                    <CheckboxField
                                        name="isFeePaidByMe"
                                        label={
                                            <HStack spacing={4}>
                                                Fee is paid by me{" "}
                                                <Tooltip content="This option allows you to request a transaction for which you will pay the fee — the CP will send a requested amount minus the network fee.">
                                                    <Icons.Help />
                                                </Tooltip>
                                            </HStack>
                                        }
                                        size="large"
                                        fullWidth
                                        control={control}
                                    />
                                </>
                            )}
                            {type === "transaction" && (
                                <SimpleInput
                                    label="Amount"
                                    placeholder={getCurrencyPlaceholder(asset)}
                                    {...register("amount")}
                                    error={errors.amount?.message}
                                    onFocus={() => setAmountFocused(true)}
                                    onBlur={() => setTimeout(() => setAmountFocused(false), 200)}
                                />
                            )}
                            {type === "transaction" && (
                                <FieldGrouper isExpanded={isFeePaidByRecipient} fullWidth>
                                    <CheckboxField
                                        name="isFeePaidByRecipient"
                                        label={
                                            <HStack spacing={4}>
                                                Fee paid by recipient{" "}
                                                <Tooltip content="If the CP is ready to cover the network fee, please, mark this field and indicate the total amount that is a sum of the blockchain transaction amount and the network fee.">
                                                    <Icons.Help />
                                                </Tooltip>
                                            </HStack>
                                        }
                                        size="large"
                                        fullWidth
                                        control={control}
                                    />
                                    {isFeePaidByRecipient && (
                                        <SimpleInput
                                            label="Size"
                                            {...register("fee")}
                                            placeholder={getCurrencyPlaceholder(asset)}
                                            error={errors.fee?.message}
                                        />
                                    )}
                                </FieldGrouper>
                            )}
                            <TextArea
                                rows={4}
                                placeholder="Comment"
                                autoFocus
                                {...register("comment")}
                            />
                            {apiError && <Error>{apiError}</Error>}
                        </FormContent>
                        <div style={{ flex: 1 }}></div>
                        <FormActions variant="plain">
                            <PrimaryButton
                                type="submit"
                                fullWidth
                                loading={isSubmitting}
                                disabled={!isAssetAndNetworkReady}
                                size="large"
                            >
                                Create and continue
                            </PrimaryButton>
                        </FormActions>
                    </FormContainer>
                </StyledForm>
                {type === "transaction" && !showOpenPositions && (
                    <TransactionHints
                        onHintSelected={({ cpId, asset, amount, network }) =>
                            reset((prev) => ({
                                ...prev,
                                cpId,
                                asset,
                                amount:
                                    amount > 0
                                        ? fmt(amount, {
                                              significantDigits: 8,
                                              type: "size64",
                                              ...getFmtPrecisionConfig(asset),
                                          }).parsedValue
                                        : "",
                                network,
                                type: "transaction",
                                isAllAvailableAmount: BigInt(amount) === 0n,
                            }))
                        }
                    />
                )}
                {type === "transaction" && showOpenPositions && (
                    <OpenPositionHints
                        asset={asset}
                        cpId={getValues().cpId}
                        onAmountSelected={(amount) => {
                            setValue(
                                "amount",
                                fmt(amount, {
                                    significantDigits: 8,
                                    type: "size64",
                                    ...getFmtPrecisionConfig(asset),
                                }).parsedValue,
                            );
                        }}
                    />
                )}
                {type === "request" && !showAddresses && (
                    <RequestHints
                        onHintSelected={({ cpId, asset, amount, network }: Hint) =>
                            reset((prev) => ({
                                ...prev,
                                cpId,
                                asset,
                                amount:
                                    amount > 0
                                        ? fmt(amount, {
                                              significantDigits: 8,
                                              type: "size64",
                                              ...getFmtPrecisionConfig(asset),
                                          }).parsedValue
                                        : "",
                                network,
                                type: "request",
                                isAllAvailableAmount: BigInt(amount) === 0n,
                            }))
                        }
                    />
                )}
                {showAddresses && (
                    <AddressHints
                        cpId={getValues().cpId}
                        asset={asset}
                        network={getValues().network}
                    />
                )}
            </HStack>
        </Modal>
    );
};
