import { PSmall } from "@fm-frontend/uikit";
import { EmDash, ValueFormat } from "@fm-frontend/utils";
import { CurrencyIcon } from "components/CurrencyIcon";
import { AuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { useGetAssetFractionDigits } from "hooks/useAssetFractionDigits";
import { FC } from "react";
import { Header } from "../Header";
import { Loading } from "../Loading";
import { useModalContext } from "../ModalContext";
import {
    Action,
    Container,
    Content,
    Indicator,
    Item,
    ItemContent,
    ItemTitle,
    ItemWrapper,
    Status,
    Title,
    WidgetForwardIcon,
} from "../styled";
import { AssetLimitWidgetModal } from "./modal";
import { useAssetLimitInfo } from "./useAssetLimitInfo";

type AssetLimitProps = {
    asset: string;
    authorizedClientId: AuthorizedClientId;
    noCpLimit: boolean;
};
export const AssetLimit: FC<AssetLimitProps> = ({ asset, authorizedClientId, noCpLimit }) => {
    const getAssetFractionDigits = useGetAssetFractionDigits();
    const { getAssetLimitInfo, isLoading } = useAssetLimitInfo();
    const { openModal } = useModalContext();

    if (authorizedClientId === null || isLoading) {
        return <Loading />;
    }

    const { assetLimitAmount, assetLimitCurrency, shortSalesBan, freeLimitPercent, freeLimit } =
        getAssetLimitInfo(asset, authorizedClientId);

    const assetFractionDigits =
        assetLimitCurrency !== undefined ? getAssetFractionDigits(assetLimitCurrency) : undefined;

    const shortSalesBanFormatted = noCpLimit ? EmDash : shortSalesBan ? "Off" : "On";
    const assetLimitFormatted =
        assetLimitAmount !== undefined && assetFractionDigits !== undefined
            ? ValueFormat.price(assetLimitAmount, assetFractionDigits.fractionDigits)
            : EmDash;
    const freeLimitFormatted =
        freeLimit !== undefined && assetFractionDigits !== undefined
            ? ValueFormat.price(freeLimit, assetFractionDigits.fractionDigits)
            : EmDash;
    const freeLimitPercentFormatted =
        freeLimitPercent !== undefined ? `${freeLimitPercent.toFixed(2)}%` : "";

    return (
        <>
            <Container>
                <Header disabled={noCpLimit} onOpen={openModal}>
                    <Title>
                        <CurrencyIcon size={12} name={asset} />
                        {asset}
                    </Title>
                    {!noCpLimit && (
                        <Action>
                            <WidgetForwardIcon />
                        </Action>
                    )}
                </Header>
                <Content noCpLimit={noCpLimit}>
                    {freeLimitPercent !== undefined && <Indicator $percent={freeLimitPercent} />}
                    <Item>
                        <ItemTitle>Free</ItemTitle>
                        <ItemContent>
                            <PSmall ellipsis title={`${freeLimitFormatted} ${assetLimitCurrency}`}>
                                {freeLimitFormatted}
                            </PSmall>
                            <PSmall>{assetLimitCurrency}</PSmall>
                            {freeLimitPercent !== undefined && (
                                <Status $percent={freeLimitPercent}>
                                    {freeLimitPercentFormatted}
                                </Status>
                            )}
                        </ItemContent>
                    </Item>
                    <ItemWrapper>
                        <Item>
                            <ItemTitle>Asset limit</ItemTitle>
                            <ItemContent>
                                <PSmall
                                    ellipsis
                                    title={`${assetLimitFormatted} ${assetLimitCurrency}`}
                                >
                                    {assetLimitFormatted}
                                </PSmall>
                                <PSmall>{assetLimitCurrency}</PSmall>
                            </ItemContent>
                        </Item>
                        <Item>
                            <ItemTitle>Short sales</ItemTitle>
                            <ItemContent>
                                <PSmall>{shortSalesBanFormatted}</PSmall>
                            </ItemContent>
                        </Item>
                    </ItemWrapper>
                </Content>
            </Container>
            <AssetLimitWidgetModal
                asset={asset}
                assetLimitAmount={assetLimitAmount}
                assetLimitCurrency={assetLimitCurrency}
                authorizedClientId={authorizedClientId}
            />
        </>
    );
};
