import { useUsers } from "./useUsers";

export const useOwnerUser = () => {
    const { data, isLoading } = useUsers();
    const ownerUser = data?.find((item) => item.owner);

    return {
        ownerUser,
        isLoading,
    };
};
