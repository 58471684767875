import { useMemo } from "react";
import { InviteStatus, useInvitesList } from "hooks/useInvitesList";
import { SubaccountStatus, useSubaccounts } from "../api";
import { ListItem, ListItemType } from "../types";
import { LIST_ITEMS_SORT_FUNCTIONS } from "../utils";
import { useFilterContext } from "../SubaccountsFilterContext";

export const useListItems = () => {
    const { filters } = useFilterContext();
    const { subaccounts, isLoading: isSubaccountsLoading } = useSubaccounts();
    const { invitesList, isLoading: isInvitesListLoading } = useInvitesList();

    const isLoading = isSubaccountsLoading || isInvitesListLoading;

    const items = useMemo(() => {
        const subaccountItems = subaccounts
            .filter(({ status }) => status === SubaccountStatus.Active || status === SubaccountStatus.Disabled)
            .reduce<ListItem[]>((acc, item) => {
                acc.push({
                    type: ListItemType.Subaccount,
                    item,
                });
                return acc;
            }, []);
        const invitesItems = invitesList
            .filter(({ isSubaccount, status }) => isSubaccount && (status === InviteStatus.Sent || status === InviteStatus.Expired))
            .reduce<ListItem[]>((acc, item) => {
                acc.push({
                    type: ListItemType.Invite,
                    item,
                });
                return acc;
            }, []);

        return [
            ...subaccountItems,
            ...invitesItems,
        ];
    }, [subaccounts, invitesList])

    const filteredAndSortedItems = useMemo(() => {
        if (!filters) {
            return [];
        }

        const normalizedSearchQuery = filters.searchQuery.toLocaleLowerCase();
        const filteredItems = items.filter((item) => {
            if (item.type === ListItemType.Subaccount) {
                const searchMatched = [
                    String(item.item.info.clientId ?? ""),
                    item.item.info.email,
                    item.item.info.username,
                    item.item.type,
                ]
                    .map((str) => str.toLocaleLowerCase())
                    .some((str) => str.includes(normalizedSearchQuery));
                const typeMatched = filters.types.length ? filters.types.includes(item.item.type) : true;
                const statusMatched = filters.status.length ? filters.status.includes(item.item.status) : true;

                return searchMatched && typeMatched && statusMatched;
            }
            if (item.type === ListItemType.Invite) {
                const searchMatched = [
                    String(item.item.clientId ?? ""),
                    item.item.email,
                    item.item.name,
                    item.item.clientType,
                ]
                    .map((str) => str.toLocaleLowerCase())
                    .some((str) => str.includes(normalizedSearchQuery));
                const typeMatched = filters.types.length ? filters.types.includes(item.item.clientType) : true;
                const statusMatched = filters.status.length ? filters.status.includes(item.item.status) : true;

                return searchMatched && typeMatched && statusMatched;
            }
        });

        return filteredItems.sort(LIST_ITEMS_SORT_FUNCTIONS[filters.sort]);
    }, [filters, items]);

    return {
        items: filteredAndSortedItems,
        isEmpty: items.length === 0,
        isLoading,
    }
};
