import { Invite } from "hooks/useInvitesList";
import { Subaccount } from "./api";

export enum ListItemType {
    Subaccount,
    Invite,
}

export type ListItem = {
    type: ListItemType.Subaccount,
    item: Subaccount,
} | {
    type: ListItemType.Invite,
    item:  Invite,
}
