import { CurrencyType } from "@fm-frontend/utils";
import { post } from "api";
import { useMemo } from "react";
import useSWRImmutable from "swr/immutable";
import { displayError } from "utils";

type ApiCurrency = [
    name: string,
    id: number,
    balanceStep: number,
    usdVal: number,
    currencyType: CurrencyType,
    networks: string[],
];
type ApiInstrument = [
    instrumentName: string,
    id: number,
    assetCurrency: string,
    balanceCurrency: string,
];
type ApiNetwork = [name: string, description: string, id: number];
type ApiInstruments = [ApiCurrency[], ApiInstrument[], ApiNetwork[]];

type Network = {
    name: string;
    id: number;
    description: string;
};

export type Instrument = {
    instrumentName: string;
    id: number;
    assetCurrency: string;
    balanceCurrency: string;
};

export type Currency = {
    name: string;
    id: number;
    balanceStep: number;
    usdVal: number | bigint;
    currencyType: CurrencyType;
    networks: string[];
};

const prepareInstrument = (instrument: ApiInstrument): Instrument => ({
    instrumentName: instrument[0],
    id: instrument[1],
    assetCurrency: instrument[2],
    balanceCurrency: instrument[3],
});

const prepareNetwork = (network: ApiNetwork): Network => ({
    name: network[0],
    description: network[1],
    id: network[2],
});

const fetcher = (url: string) => post(url, {});

export const useInstruments = () => {
    const { data, isLoading, isValidating } = useSWRImmutable<ApiInstruments>("instruments", fetcher, {
        onError: displayError,
    });

    return useMemo(() => {
        const apiData = data ?? [[], [], []];
        const [apiCurrencies, apiInstruments, apiNetworks] = apiData;
        const preparedInstruments = apiInstruments
            .map((instrument) => prepareInstrument(instrument))
            .sort((a, b) => a.instrumentName.localeCompare(b.instrumentName));
        const intrumentsByNameObj = preparedInstruments.reduce<Record<string, Instrument>>(
            (acc, instrument) => {
                acc[instrument.instrumentName] = instrument;
                return acc;
            },
            {},
        );
        const preparedNetworks = apiNetworks.map((network) => prepareNetwork(network));
        const currencies = [...apiCurrencies]
            .sort(([name1], [name2]) => name1.localeCompare(name2))
            .reduce<{ [currency: string]: Currency }>(
                (acc, [name, id, balanceStep, usdVal, currencyType, networks]) => ({
                    ...acc,
                    [name]: {
                        name,
                        id,
                        balanceStep,
                        usdVal,
                        currencyType,
                        networks,
                    },
                }),
                {},
            );

        const currencyIds = [...apiCurrencies]
            .sort(([name1], [name2]) => name1.localeCompare(name2))
            .reduce<{ [currencyId: number]: Currency }>(
                (acc, [name, id, balanceStep, usdVal, currencyType, networks]) => ({
                    ...acc,
                    [id]: {
                        name,
                        id,
                        balanceStep,
                        usdVal,
                        currencyType,
                        networks,
                    },
                }),
                {},
            );

        return {
            currencies,
            currencyIds,
            instruments: preparedInstruments,
            intrumentsByNameObj,
            networks: preparedNetworks,
            isLoading,
            isValidating,
        };
    }, [data, isLoading]);
};
