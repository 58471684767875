import { Level } from "types";
import { NOT_ENOUGH_LIQUIDITY } from "../../utils";

export const weightedAverageByVolume = ({
    volume,
    orderBookLevels,
}: {
    volume: bigint;
    orderBookLevels: Level[];
}) => {
    const totalVolume = volume * BigInt(1e8);
    let remainingVolume = totalVolume;
    let totalSize = 0n;

    for (const [bookPrice, bookSize] of orderBookLevels) {
        if (remainingVolume <= 0n) {
            break;
        }

        const levelVolume = BigInt(bookPrice) * BigInt(bookSize);
        const nextRemainingVolume =
            remainingVolume > levelVolume ? remainingVolume - levelVolume : 0n;
        const usedVolume = remainingVolume - nextRemainingVolume;
        remainingVolume = nextRemainingVolume;

        totalSize += (BigInt(bookSize) * usedVolume) / levelVolume;
    }

    if (remainingVolume > 0) {
        return NOT_ENOUGH_LIQUIDITY;
    }
    if (!totalSize) {
        return 0n;
    }

    return totalVolume / totalSize;
};
