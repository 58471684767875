import { FC, forwardRef, useEffect, useRef } from "react";
import type { PopoverProps } from "react-tiny-popover";
import { Popover as TinyPopover } from "react-tiny-popover";
import { PopoverContent as PopoverContentContainer } from "./styled";
import { DelayMode, useDelayedIsOpen } from "./useDelayedIsOpen";

type PopoverContentProps = {
    opened: boolean;
    onPositionChanged?: () => void;
};

const PopoverContent: FC<PopoverContentProps> = ({ opened, onPositionChanged, children }) => {
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const parentElement = contentRef.current?.parentElement ?? null;

        if (parentElement !== null) {
            const observer = new MutationObserver((mutationsList) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === "attributes") {
                        if (mutation.oldValue?.includes("translate")) {
                            onPositionChanged?.();
                        }
                    }
                }
            });

            observer.observe(parentElement, {
                attributes: true,
                attributeFilter: ['style'],
                attributeOldValue: true,
            });

            return () => observer.disconnect();
        }
    }, []);

    return (
        <PopoverContentContainer ref={contentRef} opened={opened}>
            {children}
        </PopoverContentContainer>
    )
};

type UIKitPopoverProps = Omit<PopoverProps, "containerClassName" | "containerStyle"> & {
    delayMode?: DelayMode;
    delayAnimationTimeout?: number;
    onPositionChanged?: () => void;
};

export const Popover: FC<UIKitPopoverProps> = forwardRef(({
    children,
    isOpen: opened,
    content,
    delayMode = DelayMode.OnClose,
    delayAnimationTimeout,
    onPositionChanged,
    ...restPopoverProps
}, ref) => {
    const isOpen = useDelayedIsOpen(opened, delayMode, delayAnimationTimeout);

    const popoverContent = (
        <PopoverContent opened={isOpen} onPositionChanged={onPositionChanged}>
            {content}
        </PopoverContent>
    );

    return (
        <TinyPopover
            ref={ref}
            isOpen={isOpen}
            content={popoverContent}
            containerClassName=""
            containerStyle={{ zIndex: "1", transition: "none" }}
            {...restPopoverProps}
        >
            {children}
        </TinyPopover>
    );
});
