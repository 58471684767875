import { getNextReqId } from "api/methods/postViaWS";
import { sendRESTRequestViaWS } from "feature/app/ws";
import { subscribeToWsRestResponses } from "feature/app/ws.saga";
import { actionChannel } from "store/actionChannel";
import { format } from "utils/ErrorCodes";

export type BulkRequestBase = {
    apiMethod: string;
    body: Record<string, unknown>;
}

export type BulkResponse<Request> = {
    request: Request;
    error?: string;
}

const UPDATE_REQUESTS_IN_CHUCK = 5;

const waitBulkOutput = <BulkRequest extends BulkRequestBase>(
    requestsWithReqIds: {
        reqId: number;
        request: BulkRequest;
    }[],
) =>
    new Promise<BulkResponse<BulkRequest>[]>((resolve) => {
        const requestsObj = requestsWithReqIds.reduce<Record<number, BulkRequest>>(
            (acc, { reqId, request }) => {
                acc[reqId] = request;
                return acc;
            },
            {},
        );
        const reqIdsWithoutResponse = new Set(Object.keys(requestsObj).map(Number));
        let failedReqIds: Record<number, number> = {};

        const unsubscribe = subscribeToWsRestResponses((resp) => {
            const { reqId, error } = resp;
            reqIdsWithoutResponse.delete(reqId);
            if (error !== undefined) {
                failedReqIds[reqId] = error;
            }
            if (reqIdsWithoutResponse.size === 0) {
                unsubscribe();
                const failedRequestResponses: BulkResponse<BulkRequest>[] = Object.entries(
                    failedReqIds,
                ).map(([id, errorCode]) => ({
                    request: requestsObj[Number(id)],
                    error: format(errorCode),
                }));
                const succededRequestResponses: BulkResponse<BulkRequest>[] = Object.entries(requestsObj)
                    .filter(([id]) => !failedReqIds[Number(id)])
                    .map(([, request]) => ({
                        request,
                    }));

                const result: BulkResponse<BulkRequest>[] = [
                    ...failedRequestResponses,
                    ...succededRequestResponses,
                ];

                resolve(result);
            }
        });
    });

export const bulkUpdate = async <BulkRequest extends BulkRequestBase>(requests: BulkRequest[]) => {
    const requestsWithReqIds = requests.map((request) => ({
        reqId: getNextReqId(),
        request,
    }));
    let startIndex = 0;
    let endIndex = Math.min(requestsWithReqIds.length, UPDATE_REQUESTS_IN_CHUCK);

    const send = () =>
        setTimeout(() => {
            for (let i = startIndex; i < endIndex; i++) {
                const { reqId, request } = requestsWithReqIds[i];
                actionChannel.put(
                    sendRESTRequestViaWS({
                        method: request.apiMethod,
                        reqId,
                        content: request.body,
                    }),
                );
            }

            if (endIndex === requestsWithReqIds.length) {
                return;
            }

            startIndex = endIndex;
            endIndex = Math.min(
                requestsWithReqIds.length,
                endIndex + UPDATE_REQUESTS_IN_CHUCK,
            );
            send();
        }, 50);
    send();

    return {
        responses: await waitBulkOutput(requestsWithReqIds),
    };
};
