import { PBold, PXSmall } from "@fm-frontend/uikit";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

const commonStyle = css`
    padding: 8px;
    margin-bottom: 6px;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.colors.ui12};
    background-color: transparent !important;
    display: flex !important;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;

    ${PBold} {
        color: ${(p) => p.theme.colors.ui100} !important;
        font-weight: 500;
        letter-spacing: -0.07px;
    }

    ${PXSmall} {
        color: ${(p) => p.theme.colors.ui52} !important;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
    }

    .plus-icon-container {
        cursor: pointer;
    }
`;

const hoverStyle = css`
    &:hover {
        background-color: ${(p) => p.theme.colors.ui4} !important;
        text-decoration: none;
    }

    &:has(> .plus-icon-container:hover) {
        background-color: transparent !important;
    }
`;

export const Container = styled.div`
    ${commonStyle};
`;

export const ClickContainer = styled.div`
    ${commonStyle};
    ${hoverStyle};
`;

export const NavContainer = styled(NavLink)`
    ${commonStyle};
    ${hoverStyle};

    &:active,
    &.active {
        background-color: ${(p) => p.theme.colors.subpurple8} !important;
        border-color: transparent;
        cursor: default;

        ${PBold} {
            color: ${(p) => p.theme.colors.subpurple100} !important;
        }

        ${PXSmall} {
            color: ${(p) => p.theme.colors.subpurple100} !important;
        }

        &:hover {
            background-color: ${(p) => p.theme.colors.subpurple8} !important;
        }

        &:has(> .plus-icon-container:hover) {
            background-color: ${(p) => p.theme.colors.subpurple8} !important;
        }

        svg > path {
            fill-opacity: 1;
        }
    }
`;
