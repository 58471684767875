import { ClientType } from "types";

export const mobileQuery = "max-width: 640px";
export const tabletQuery = "max-width: 1064px";
export const onMobile = `@media (${mobileQuery})`;
export const onTablet = `@media (${tabletQuery})`;
export const onHover = `&:hover, &:active, &:focus, &:focus-within, &:focus-visible, &[aria-expanded=true]`;
export const broadcastChannelId = "tech.finery.efx";
export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const EmDash = "—";
export const DoubleEmDash = "——";
export const EFX_TOKEN = "efxtoken";
export const IS_LOG_ENABLED = "IS_LOG_ENABLED";
export const LOG_IDS = "LOG_IDS";

export const DEFAULT_CP_NAME = "Counterparty";

export const DEFAULT_NOTIFICATION_TIMEOUT = 5 * 1000;
export const MOUSE_LEAVE_NOTIFICATION_TIMEOUT = 1000;

// use it only as default value for api responses etc. (for permanent reference, just not mutable array)
export const EMPTY_ARRAY: unknown[] = [];
Object.freeze(EMPTY_ARRAY);

export const FMT_FIAT_PRECISION = 2;
export const FMT_CRYPTO_PRECISION = 8;

export const FIAT_LIST = ["USD", "GBP", "EUR"];

export const PREFERRED_CURRENCIES = ["BTC", "ETH", "EUR", "USD", "USDT"];
export const DEFAULT_PREFERRED_CURRENCY = PREFERRED_CURRENCIES[3];

// fill with preferred instruments
export const PREFERRED_INSTRUMENTS: string[] = [];

export const CLIENT_TYPE_TITLES: { [Key in ClientType]: string } = {
    maker: "Maker",
    taker: "Taker",
    primeBroker: "Master",
    subaccountMaker: "Sub-maker",
    subaccountTaker: "Sub-taker",
};

export const FM_TAndC_FILE_NAME = "FineryMarkets T&C";

export const SEARCH_PARAMS = {
    primeBrokerViewType: "viewType",
    assetsAndInstrumentsTab: "activeTab",
    historyType: "historyType",
    tradingType: "tradingType",
    cp: "cp",
    activeRfqInstrument: "activeRfqInstrument",
    activeFirmInstrument: "activeFirmInstrument",
    authorizedClientId: "authorizedClientId",
} as const;

export const RESET_PASSWORD_MODAL_KEY = Symbol("RESET_PASSWORD_MODAL_KEY");
