// import './wdyr';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import * as serviceWorker from './serviceWorker';
// import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root"),
);

// !isDev && serviceWorker.register();
// reportWebVitals(
//     isDev
//         ? console.log
//         : ({ id, name, value }) => {
//               const windowWithMaybeGA: Window & { ga?: Function } = window;
//               if (windowWithMaybeGA.ga)
//                   windowWithMaybeGA?.ga("send", "event", {
//                       eventCategory: "Web Vitals",
//                       eventAction: name,
//                       eventValue: Math.round(
//                           name === "CLS" ? value * 1000 : value
//                       ), // values must be integers
//                       eventLabel: id, // id unique to current page load
//                       nonInteraction: true, // avoids affecting bounce rate
//                   });
//           }
// );
