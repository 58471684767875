import { FieldErrors } from "react-hook-form";

type DirtyFields<T> = {
    [K in keyof T]?: boolean | undefined;
};

export const getFormFieldData = <T>({
    field,
    dirtyFields,
    errors,
}: {
    field: keyof T;
    dirtyFields: DirtyFields<T>;
    errors: FieldErrors<any>;
}) => {
    const isTouched = dirtyFields[field] !== undefined;
    const isValid = errors[field] === undefined;

    return { isTouched, isValid };
};
