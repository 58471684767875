import { useWidth } from "@fm-frontend/uikit";
import { useButton } from "@react-aria/button";
import { HiddenSelect, useSelect } from "@react-aria/select";
import { useSelectState } from "@react-stately/select";
import { cover } from "polished";
import React, { ReactText, useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { IconUpDown } from "../../components/icons";
import { Popover } from "../../components/Popover";
import { ListBoxPopup } from "../../components/select/ListBox";
import { onHover } from "../../const";
import { DropdownDirection, useDropdownDirection } from "../../hooks/useDropdownDirection";
import { TransitionState, useItemTransition } from "../../hooks/useTransition";
import { Fieldset, InputContainer, InputSelectedValue, Spacer } from "../../style";
import { selectors } from "../../style/theme";
import { when } from "../../utils";

export const ListItem = styled.li<{ isSelected: boolean; isFocused?: boolean }>`
    ${selectors.fontMedium}
    position: relative;
    list-style: none;
    font-size: 16px;
    line-height: 18px;
    padding: 9px 16px;
    cursor: pointer;
    scroll-snap-align: end;
    transition: ${(p) => p.theme.transitions.default};
    display: flex;
    justify-content: space-between;

    ${({ isSelected, theme }) =>
        isSelected &&
        css`
            color: ${theme.colors.textFG};
        `}

    ${onHover} {
        background-color: ${(p) => p.theme.colors.listItemHover};
        z-index: 2;
    }
`;

export const ListContainer = styled.div<
    {
        transitionState: TransitionState;
        compact?: boolean;
        isMobile: boolean;
    } & DropdownDirection
>`
    position: absolute;
    padding: 8px 0;
    min-width: ${(p) => (p.compact ? "240px" : "296px")};
    margin-top: 6px;
    background: ${(p) => p.theme.colors.activeBG};
    box-shadow: 0 16px 48px rgba(0, 0, 0, 0.16), 0 4px 6px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    white-space: nowrap;
    opacity: 0;
    z-index: 2;
    transition: opacity 75ms 25ms ease-in-out, transform 100ms ease-in-out;
    ${({ horizontal, vertical }) => {
        if (horizontal === "left") {
            return css`
                right: 0;
                transform-origin: ${vertical === "top" ? "bottom" : "top"} right;
            `;
        }
        if (horizontal === "right") {
            return css`
                left: ${vertical === "center" ? "calc(100% - 4px)" : "0"};
                transform-origin: ${vertical === "top" ? "bottom" : "top"} left;
            `;
        }
    }}
    ${({ vertical }) => {
        if (vertical === "center") {
            return css`
                top: -14px;
            `;
        }
        if (vertical === "top") {
            return css`
                bottom: 0;
            `;
        }
        if (vertical === "bottom") {
            return css`
                top: 100%;
            `;
        }
    }}}
    ${({ transitionState, vertical }) => {
        switch (transitionState) {
            case "entering":
            case "exiting": {
                return css`
                    transform: translateY(${vertical === "bottom" ? "-" : ""}6px) scale(0.8);
                `;
            }
            case "active": {
                return css`
                    opacity: 1;
                    transform: translate(0, 0);
                `;
            }
        }
    }}
`;

export const List = styled.ul`
    min-width: 240px;
    padding: 0;
    margin: 0;
    border: none;
    color: ${(p) => p.theme.colors.textSubdued};
    list-style: none;
    width: 100%;
    max-height: ${36 * 10 + 18}px;
    overflow: auto;
    scroll-snap-type: x mandatory;
    &:after {
        content: "";
        ${cover()}
        pointer-events: none;
        user-select: none;
        box-shadow: inset 0 0 5px 5px white;
        margin-top: 8px;
        margin-bottom: 8px;
        z-index: 1;
    }
`;

export const FormSelect: React.FC<{
    id?: string;
    label: string;
    label2?: string;
    name: string;
    children: any;
    errorValue?: string;
    onSelectionChange: (key: ReactText) => any;
    selectedKey?: string;
    disabled?: boolean;
    "aria-label"?: string;
    showValue?: boolean;
    hidden?: boolean;
}> = (props) => {
    // Create state based on the incoming props
    const state = useSelectState(props);

    // Get props for child elements from useSelect
    const triggerRef = useRef<HTMLButtonElement & HTMLDivElement>(null);
    const { labelProps, triggerProps, valueProps, menuProps } = useSelect(props, state, triggerRef);
    const { offset, ref } = useWidth();
    const { horizontal, vertical } = useDropdownDirection(triggerRef);

    const { label, label2 } = props;

    const labelSlot = useMemo(() => {
        if (!label && !label2) return null;
        return (
            <>
                <label ref={ref} {...labelProps}>
                    {label}
                    {label2 && <> {label2}</>}
                </label>
                <Spacer />
            </>
        );
    }, [label, label2, labelProps, ref]);

    // Get props for the button based on the trigger props from useSelect
    const { buttonProps } = useButton(triggerProps, ref);
    const selectedValue =
        state.selectedItem &&
        (props.showValue && state.selectedItem.key ? state.selectedItem.key : state.selectedItem.rendered);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        // react-aria select for some reason closes the popup before the click event is dispatched on document
        // leading to clicking through list on mobile, so we'll close it next cycle
        const isOpen = !props.disabled && state.isOpen;
        if (isOpen) {
            setIsOpen(isOpen);
        } else {
            setTimeout(() => setIsOpen(isOpen), 0);
        }
    }, [props.disabled, state.isOpen]);
    const { isActive, transitionState } = useItemTransition(isOpen, {
        enterDelay: 100,
        exitDelay: 100,
    });

    if (props.hidden) {
        return null;
    }

    return (
        <Fieldset>
            <InputContainer as="button" leftPad={offset} {...buttonProps} ref={triggerRef} disabled={props.disabled}>
                {labelSlot}
                <HiddenSelect state={state} triggerRef={triggerRef} label={props.label} name={props.name} />
                <InputSelectedValue {...valueProps}>{selectedValue || ""}</InputSelectedValue>
                {when(label, null, <Spacer />)}
                <IconUpDown />
            </InputContainer>
            {isActive && (
                <Popover containerRef={triggerRef}>
                    <ListBoxPopup
                        {...menuProps}
                        horizontal={horizontal}
                        vertical={vertical}
                        state={state}
                        transitionState={transitionState}
                    />
                </Popover>
            )}
        </Fieldset>
    );
};
