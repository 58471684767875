import { TextSmall } from "@fm-frontend/uikit";
import { BITMASK_UTILS } from "@fm-frontend/utils";
import React from "react";
import styled from "styled-components";
import { CounterpartyStatus, CounterpartyStatusType } from "../../utils";

const Highlighted = styled.span`
    font-weight: 600;
`;

const Container = styled.div`
    display: flex;
    gap: 7px;

    cursor: default;
`;

const { isBitKeyApplied } = BITMASK_UTILS;

export const IndicatorHint: React.FC<{
    status: CounterpartyStatusType;
    statusMask: number | CounterpartyStatusType;
}> = (props) => {
    const { status, statusMask } = props;

    if (!isBitKeyApplied(statusMask, status)) {
        return null;
    }

    return (
        <Container>
            <TextSmall color="white">
                {CounterpartyStatus[status].actions.map((action, index) => {
                    return index ? (
                        <span key={index}>
                            {" "}
                            or <Highlighted>{action}</Highlighted>
                        </span>
                    ) : (
                        <Highlighted key={index}>{action}</Highlighted>
                    );
                })}
            </TextSmall>
        </Container>
    );
};
