import { post } from "api";

const API_METHODS = {
    enabled: "enableTrading",
    disabled: "disableTrading",
};
const setTradingStatus = async (cpId: number, status: "enabled" | "disabled") => {
    const apiMethod = API_METHODS[status];

    await post(apiMethod, {
        counterpartyId: cpId,
    });
};

export const disableTrading = (cpId: number) => setTradingStatus(cpId, "disabled");
export const enableTrading = (cpId: number) => setTradingStatus(cpId, "enabled");
