import { useSelector } from "hooks";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    fetchOnboardingBanks,
    fetchOnboardingCurrencies,
    fetchOnboardingJurisdictions,
} from "store/onboardingActions";
import { StatusKYBVariant } from "store/onboardingSlice";

const HIGHEST_ASSET_KEY = "top";
const LOWEST_ASSET_KEY = "other";
export const isHighestAsset = (name: string) => name.toLowerCase().includes(HIGHEST_ASSET_KEY);
export const isLowestAsset = (name: string) => name.toLowerCase().includes(LOWEST_ASSET_KEY);

export const useOnboardingBanks = () => {
    const dispatch = useDispatch();
    const { banks } = useSelector((state) => state.onboarding);

    useEffect(() => {
        if (!banks?.length) {
            dispatch(fetchOnboardingBanks());
        }
    }, [banks]);
};

export const useOnboardingJurisdictions = () => {
    const dispatch = useDispatch();
    const { jurisdictions } = useSelector((state) => state.onboarding);

    useEffect(() => {
        if (!jurisdictions?.length) {
            dispatch(fetchOnboardingJurisdictions());
        }
    }, [jurisdictions]);
};

export const useOnboardingCurrencies = () => {
    const dispatch = useDispatch();
    const { currencies } = useSelector((state) => state.onboarding);

    useEffect(() => {
        if (!currencies?.length) {
            dispatch(fetchOnboardingCurrencies());
        }
    }, [currencies]);
};

export const useOnboardingFilterApi = () => {
    useOnboardingBanks();
    useOnboardingCurrencies();
    useOnboardingJurisdictions();
};

export const useEnterToProduct = (condition: boolean = true) => {
    const {
        statusKYB,
        onboardingStages: { isTermsAndConditionsRequired, isTermsAndConditionsDelegated },
    } = useSelector((state) => state.onboarding);
    const hasFinishedKVC = Object.values(statusKYB).some((s) => s === StatusKYBVariant.Finished);
    const isEnterToProductAllowed =
        !isTermsAndConditionsRequired &&
        !isTermsAndConditionsDelegated &&
        hasFinishedKVC &&
        condition;

    return {
        isEnterToProductAllowed,
    };
};
