import { useMemo } from "react";
import { useSettlements } from "feature/settlements/useSettlements";
import { Settlement } from "feature/settlements/types";

const isActionable = ({ status, type }: Partial<Settlement>) => (status === "request" && type === "in") || (status === "created") || (status === "received");

export const usePendingSettlementsCount = (cpId: number) => {
    const { settlements, isLoading } = useSettlements();
    const pendingSettlementsCount = useMemo(
        () => settlements.filter((settlement) => settlement.cpId === cpId && isActionable(settlement)).length,
        [settlements, cpId]
    );

    return {
        pendingSettlementsCount,
        isLoading,
    };
}
