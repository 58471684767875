import { AccordionWithConfirmation } from "components/AccordionWithConfirmation";
import { ROUTES } from "feature/app/router";
import { HistoryTab } from "feature/history/trades/hooks";
import { StatusOfRfqHistoryItem } from "feature/rfq/apiHooks/useLastRfqTrades";
import { useState } from "react";
import { LastRfqAccordionHeader } from "./Header";
import { LastRfqAccordionContent } from "./LastRfqAccordionContent";

export const LastRfqAccordion = () => {
    const [selectedStatuses, setSelectedStatuses] = useState<StatusOfRfqHistoryItem[]>([]);

    return (
        <AccordionWithConfirmation
            defaultExpanded
            header={
                <LastRfqAccordionHeader
                    selectedStatuses={selectedStatuses}
                    setSelectedStatuses={setSelectedStatuses}
                />
            }
            content={<LastRfqAccordionContent selectedStatuses={selectedStatuses} />}
            moveToDetails={{
                detailsPageUrl: `${ROUTES.historyTrades}/${HistoryTab.RFQ}`,
                fromSectionTitle: "Trading Page",
                toSectionTitle: "RFQ History Page",
                buttonTitle: "View history",
            }}
        />
    );
};
