import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createNotification } from "../../../app";
import { SIGNING_LINK_UTILS } from "./utils";

export const useSigningLink = ({ isReadOnly }: { isReadOnly: boolean }) => {
    const dispatch = useDispatch();
    const [signingLink, setSigningLink] = useState("");

    useEffect(() => {
        SIGNING_LINK_UTILS.getSingingLink(isReadOnly)
            .then((link) => {
                setSigningLink(link);
            })
            .catch((e) => {
                dispatch(
                    createNotification({
                        type: "error",
                        content: String(e),
                    }),
                );
            });
    }, [isReadOnly]);

    return { signingLink };
};
