import { getMatch } from "@fm-frontend/uikit";
import { DropdownOption } from "../common/types";

const arraySearch = <TValue,>(options: DropdownOption<TValue>[], normalizedQuery: string) => {
    return options?.reduce<DropdownOption<TValue>[]>((options, { text, value, icon }) => {
        const match = getMatch(text ?? String(value), normalizedQuery);

        if (match) {
            options.push({
                value,
                text,
                match,
                icon,
            });
        }

        return options;
    }, []);
};

export function search<Options extends DropdownOption<TValue>[], TValue>(
    query: string | undefined,
    options: Options,
): Options;
export function search<Options extends Record<string, DropdownOption<TValue>[]>, TValue>(
    query: string | undefined,
    options: Options,
): Options;
export function search<Options extends DropdownOption<TValue>[] | Record<string, DropdownOption<TValue>[]>, TValue>(
    query: string | undefined,
    options: Options,
): Options;
export function search<TValue>(
    query: string | undefined,
    options: DropdownOption<TValue>[] | Record<string, DropdownOption<TValue>[]>,
): DropdownOption<TValue>[] | Record<string, DropdownOption<TValue>[]> {
    if (!query) {
        return options;
    }

    const normalizedQuery = query.toLowerCase().trim();

    if (Array.isArray(options)) {
        return arraySearch(options, normalizedQuery);
    }

    return Object.entries(options)?.reduce<Record<string, DropdownOption<TValue>[]>>((optionsObj, [key, options]) => {
        optionsObj[key] = arraySearch(options, normalizedQuery);
        return optionsObj;
    }, {});
}
