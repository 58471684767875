import { FC } from "react";
import { useTheme } from "styled-components";

type PlusIconProps = {
    active?: boolean;
}

// specific icon, no need to add it to uikit
export const PlusIcon: FC<PlusIconProps> = ({ active = false }) => {
    const { colors } = useTheme();

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="12" fill={active ? colors.subpurple100 : colors.ui8} />
            <path
                d="M16 11H13V8C13 7.4 12.6 7 12 7C11.4 7 11 7.4 11 8V11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H11V16C11 16.6 11.4 17 12 17C12.6 17 13 16.6 13 16V13H16C16.6 13 17 12.6 17 12C17 11.4 16.6 11 16 11Z"
                fill={active ? colors.uiWhite100 : colors.ui32}
            />
        </svg>
    );
};
