import { ConfirmationModalContent, setConfirmModalContent } from "feature/app";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

export const useOpenConfirmationModal = () => {
    const dispatch = useDispatch();

    return useCallback((modalContent: ConfirmationModalContent) => {
        dispatch(setConfirmModalContent(modalContent));
    }, []);
};
