import { useMemo } from "react";
import { useOwnerUser } from "./useOwnerUser";
import { useUserEmail } from "./useUserEmail";

export const useIsOwnerUser = () => {
    const { email, isLoading: isEmailLoading } = useUserEmail();
    const { ownerUser, isLoading: isOwnerUserLoading } = useOwnerUser();

    return useMemo(() => {
        if (isOwnerUserLoading || isEmailLoading || email === undefined || ownerUser === undefined) {
            return false;
        }

        return ownerUser.email === email;
    }, [email, isEmailLoading, ownerUser, isOwnerUserLoading]);
};
