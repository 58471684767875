import { useSettings } from "hooks/useSettings";
import { FC } from "react";

export const Copyright: FC = () => {
    const settings = useSettings();

    if (settings.copyright_from_date === undefined) {
        return null;
    }

    if (settings.copyright_from_date === null) {
        return <>© {new Date().getFullYear()}</>;
    }

    if (settings.copyright_from_date === new Date().getFullYear()) {
        return <>© {settings.copyright_from_date}</>;
    }

    return (
        <>
            © {settings.copyright_from_date}–{new Date().getFullYear()}
        </>
    );
};
