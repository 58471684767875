import { H2, P, PlainButton, PrimaryButton, VStack } from "@fm-frontend/uikit";
import React from "react";
import { useTheme } from "styled-components";

type SigningTAndCProps = {
    onSign: () => void;
    onForwardLink: () => void;
};

export const SigningTAndC: React.FC<SigningTAndCProps> = ({ onSign, onForwardLink }) => {
    const { colors } = useTheme();

    return (
        <VStack asCard minWidth="360px" maxWidth="360px" paddingX={12} paddingTop={12} paddingBottom={16}>
            <VStack spacing={12} paddingBottom={16}>
                <H2>Signing T&C</H2>
                <P color={colors.ui52}>
                    Only director or an authorised person with power of attorney can sign this agreement
                </P>
            </VStack>
            <VStack spacing={8}>
                <PrimaryButton size="large" fullWidth onClick={onSign}>
                    Sign T&C as authorised person
                </PrimaryButton>
                <PlainButton size="large" fullWidth onClick={onForwardLink}>
                    Forward signing link
                </PlainButton>
            </VStack>
        </VStack>
    );
};
