import { HStack, Skeleton } from "@fm-frontend/uikit";
import styled from "styled-components";

const Container = styled.article`
    display: flex;
    flex-direction: column;

    border-top: 1px solid ${(p) => p.theme.colors.ui8};
    :nth-child(odd) {
        border-right: 1px solid ${(p) => p.theme.colors.ui8};
    }
`;

const Header = styled.header`
    display: flex;
    height: 28px;
    padding: 6px 12px;
    justify-content: space-between;
    align-items: center;

    background: ${(p) => p.theme.colors.ui4};
`;

const Content = styled.section`
    display: flex;
    padding: 8px 0;
    flex-direction: column;
`;

const Item = styled.div`
    display: flex;
    flex: 1;
    min-height: 40px;
    padding: 4px 12px;
    flex-direction: column;
    gap: 4px;
`;

export const Loading = () => {
    return (
        <Container>
            <Header>
                <Skeleton height="12px" width="100px" />
            </Header>
            <Content>
                <Item>
                    <Skeleton height="10px" width="50px" />
                    <Skeleton height="12px" width="100px" />
                </Item>
                <HStack width="100%">
                    <Item>
                        <Skeleton height="10px" width="50px" />
                        <Skeleton height="12px" width="100px" />
                    </Item>
                    <Item>
                        <Skeleton height="10px" width="50px" />
                        <Skeleton height="12px" width="100px" />
                    </Item>
                </HStack>
            </Content>
        </Container>
    );
};
