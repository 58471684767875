import { post } from "api";
import { useMemo } from "react";
import useSWR from "swr";
import { displayError } from "utils";

type InstrumentMarkupApi = [cpId: number, instrument: string, bidMarkup: number, askMarkup: number];

export const instrumentMarkupsSWRKey = "instrumentMarkups";

export const useInstrumentMarkups = () => {
    const fetcher = (url: string) => post(url, {});
    const { data, isLoading, mutate } = useSWR<InstrumentMarkupApi[]>(instrumentMarkupsSWRKey, fetcher, {
        onError: displayError,
        fallbackData: [],
        revalidateOnMount: true,
        revalidateOnFocus: false,
    });

    return useMemo(() => {
        return {
            instrumentMarkups: (data ?? []).reduce<
                Record<string, Record<number, { bidMarkup: number; askMarkup: number }>>
            >((acc, instrumentMaprkupApi) => {
                const [cpId, instrument, bidMarkup, askMarkup] = instrumentMaprkupApi;

                acc[instrument] ??= {};
                acc[instrument][cpId] = { bidMarkup, askMarkup };

                return acc;
            }, {}),
            isLoading,
            mutate,
        };
    }, [data, isLoading, mutate]);
};
