import styled from "styled-components";
import { UserCard as Card } from "../../styled";

export const Error = styled.div`
    text-align: center;
    color: ${(p) => p.theme.colors.error};
    font-size: 14px;
`;

export const UserCard = styled(Card)`
    margin-bottom: 16px;
`;

export const UserInfo = styled.div`
    flex: 1;
`;

export const Actions = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 12px;
    margin-bottom: 12px;
`;
