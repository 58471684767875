export const getHashParams = (locationHash: string = "") => {
    const paramsMap = new Map<string, string>();

    if (locationHash[0] === "#") {
        const hashParams = new URLSearchParams(locationHash.substring(1));

        hashParams.forEach((value, key) => {
            paramsMap.set(key, value);
        });
    }

    return paramsMap;
};
