import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useFilteredLimits } from "hooks/useFilteredLimits";
import { useMemo } from "react";
import { ClientType, CounterpartyLimitIndex } from "types";

export const useCounterpartiesWithTypes = () => {
    const { data: counterpartyLimits, isLoading: isCpsLoading } = useFilteredLimits();

    const cpIds: number[] = useMemo(() => {
        return (counterpartyLimits ?? []).map((limit) => limit[CounterpartyLimitIndex.counterpartyId]);
    }, [counterpartyLimits]);

    const { getCpName, getCpType } = useCpInfoHelpers();

    return useMemo(() => {
        return {
            isLoading: isCpsLoading,
            cpIds,
            counterparties: cpIds.reduce<
                Record<number, { cpId: number; name: string; cpType: ClientType | undefined }>
            >(
                (acc, cpId) => ({
                    ...acc,
                    [cpId]: {
                        cpId,
                        name: getCpName(cpId, "full"),
                        cpType: getCpType(cpId),
                    },
                }),
                {},
            ),
        };
    }, [isCpsLoading, cpIds, getCpName, getCpType]);
};

export const useNotMakerCpsIds = () => {
    const { counterparties: cpsWithTypes } = useCounterpartiesWithTypes();

    return useMemo(
        () =>
            Object.values(cpsWithTypes)
                .filter(({ cpType }) => cpType !== "maker")
                .map(({ cpId }) => cpId),
        [cpsWithTypes],
    );
};
