import {
    DiffLightbox,
    FIXED_MODAL_STYLE,
    HStack,
    Modal,
    P,
    PlainButton,
    PrimaryButton,
} from "@fm-frontend/uikit";
import { Gap } from "@fm-frontend/uikit/src/components/common/Gap";
import { useFileContext } from "@fm-frontend/uikit/src/components/dropZone/FileContext";
import {
    DiffDataResult,
    EmDash,
    useModalControl,
    ValueFormat,
} from "@fm-frontend/utils";
import format from "date-fns/format";
import { createNotification, stopBusyProcess, startBusyProcess } from "feature/app";
import { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { DiffLightBoxFooterInfo } from "../DiffLightBoxFooterInfo";
import { BulkRequestBase, BulkResponse, bulkUpdate } from "../../services/bulkUpdate";

const FileInfo = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;
const FileErrorInfo = styled(P)`
    color: ${(p) => p.theme.colors.negative100};
`;

type DiffLightBoxProps<BulkUpdateRequest extends BulkRequestBase> = {
    diffResult: DiffDataResult;
    getUpdateData: () => BulkUpdateRequest[];
    renderResultModal: (props: {
        responses: BulkResponse<BulkUpdateRequest>[];
        onUpdate: (responses: BulkResponse<BulkUpdateRequest>[]) => void;
        onClose: () => void;
    }) => JSX.Element;
    onClose: () => void;
    onBack: () => void;
    children: ReactNode;
};

export const DiffLightBox = <BulkUpdateRequest extends BulkRequestBase>({
    diffResult,
    getUpdateData,
    renderResultModal,
    onClose,
    onBack,
    children,
}: DiffLightBoxProps<BulkUpdateRequest>) => {
    const dispatch = useDispatch();
    const [isApplyingUpdates, setIsApplyingUpdates] = useState<boolean>(false);
    const { acceptedFiles } = useFileContext();
    const fileName = acceptedFiles[0]?.name || EmDash;
    const fileInfo = `File size: ${ValueFormat.formatBytes(
        acceptedFiles[0]?.size ?? 0,
    )} Last changes: ${format(acceptedFiles[0]?.lastModified ?? 0, "d MMMM yyyy 'at' HH:mm")}`;

    const [bulkUpdateResponses, setBulkUpdateResponses] = useState<BulkResponse<BulkUpdateRequest>[]>([]);
    const { isModalOpen: isBulkUpdateResultModalOpened, openModal: openBulkUpdateResultModal } =
        useModalControl();
    const { closeModal: closeBulkUpdateLightbox, isModalOpen: isBulkUpdateLightboxOpened } =
        useModalControl(true);

    const handleBulkUpdateResult = async (responses: BulkResponse<BulkUpdateRequest>[]) => {
        closeBulkUpdateLightbox();

        const hasErrors = responses.some(({ error }) => error !== undefined);

        if (hasErrors) {
            setBulkUpdateResponses(responses);
            openBulkUpdateResultModal();
        } else {
            dispatch(
                createNotification({
                    type: "success",
                    content: "All changes were successfully applied.",
                }),
            );
            onClose();
        }
    };

    const handleSave = async () => {
        const updateData = getUpdateData();

        dispatch(startBusyProcess());
        setIsApplyingUpdates(true);
        const { responses } = await bulkUpdate(updateData);
        setIsApplyingUpdates(false);
        handleBulkUpdateResult(responses);
        dispatch(stopBusyProcess());
    };

    return (
        <>
            <DiffLightbox isOpen={isBulkUpdateLightboxOpened} onClose={onClose}>
                <DiffLightbox.Header
                    title={fileName}
                    essence={() =>
                        diffResult.hasErrors ? (
                            <FileErrorInfo>
                                Some cells contains wrong values. Overview the issues, correct the
                                initial file and upload again.
                            </FileErrorInfo>
                        ) : (
                            <FileInfo>{fileInfo}</FileInfo>
                        )
                    }
                />
                <DiffLightbox.Body>
                    {children}
                </DiffLightbox.Body>
                <DiffLightbox.Footer>
                    <DiffLightBoxFooterInfo diffResult={diffResult} />
                    <Gap />
                    <HStack spacing={6}>
                        <PlainButton size="medium" onClick={onBack}>
                            Cancel
                        </PlainButton>
                        {diffResult.hasDiffs && !diffResult.hasErrors && (
                            <PrimaryButton
                                disabled={diffResult.isLoading}
                                size="medium"
                                onClick={handleSave}
                                loading={isApplyingUpdates}
                            >
                                Update
                            </PrimaryButton>
                        )}
                        {!diffResult.hasDiffs && !diffResult.hasErrors && (
                            <PrimaryButton
                                disabled={diffResult.hasErrors || diffResult.isLoading}
                                size="medium"
                                onClick={onClose}
                                loading={isApplyingUpdates}
                            >
                                Continue
                            </PrimaryButton>
                        )}
                    </HStack>
                </DiffLightbox.Footer>
            </DiffLightbox>
            <Modal
                isOpen={isBulkUpdateResultModalOpened}
                onClose={onClose}
                style={FIXED_MODAL_STYLE}
            >
                {renderResultModal({
                    responses: bulkUpdateResponses,
                    onUpdate: handleBulkUpdateResult,
                    onClose,
                })}
            </Modal>
        </>
    );
};
