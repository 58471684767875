import {
    DesctructiveButton,
    HStack,
    Icons,
    PlainButton,
    PrimaryButton,
    PSmall,
    SimpleInput,
    Switch,
    TextSmall,
} from "@fm-frontend/uikit";
import { getClickHelper } from "@fm-frontend/uikit/src/components/helper";
import { SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { useFormCloseConfirmer } from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CircularProgressbar } from "components/CircularProgressbar";
import { CoinIcon } from "components/CoinIcon";
import { CurrencyDropdownSheet } from "components/CurrencySheet";
import { CurrencyTriggerEssence } from "components/CurrencyTriggerEssence";
import { DEFAULT_PREFERRED_CURRENCY } from "const";
import { createNotification } from "feature/app";
import { fetchCounterpartiesInfo } from "feature/app/store/counterpartiesInfoFetcher";
import { FormError } from "feature/form/style";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useIsMakerUser, useIsPrimeBrokerUser, usePrimeBrokerViewType } from "store/hooks";
import { useCurrenciesWithPreferred } from "store/useCurrenciesWithPreferred";
import { Spacer } from "style";
import styled, { useTheme } from "styled-components";
import { setCLimit } from "services/limitService";
import { CounterpartyLimit } from "types";
import { getCurrencyPlaceholder, getFmtPrecisionConfig } from "utils";
import EfxTypes from "utils/EfxTypes";
import { fmt, fmtDeltaratePercent, formatPrecision } from "utils/format";
import { FMT_LIMIT_CONFIG } from "../counterparty/utils";
import { FieldGrouper, GroupSwitch } from "../styled";
import {
    getFreeLimit,
    isSubaccountCp,
    LIMIT_FORMAT_REGEX,
    MARGIN_PLACEHOLDER,
    parseLimit,
    PERCENT_FORMAT_REGEX,
} from "../utils";
import { Break } from "./Break";
import { CounterpartyInfo } from "./counterpartyInfo/CounterpartyInfo";
import { EnableTrading } from "./enableTrading/EnableTrading";
import { MakerRiskOverview } from "./markerRiskOverview/MakerRiskOverview";
import {
    getFormInfobox,
    getLimitLabel,
    getLimitLabelHelper,
    getMarginLabelHelper,
} from "./messages";
import { NotificationMessage } from "./NotificationMessage";
import { schema } from "./schema";
import * as Styled from "./styled";
import { Indicator, Inputs, Props } from "./types";
import {
    COUNTERPARTY_LIMIT_MODAL_KEY,
    FMT_PERCENT_CONFIG,
    getFmtModalIndicatorConfig,
    getMarginsAutocomplete,
    getRequestBodyForNotSubaccount,
} from "./utils";

const DeleteButton = styled(DesctructiveButton)`
    background: ${(p) => p.theme.colors.ui8};
    border: none;
    box-shadow: none;
    margin-bottom: 8px;
`;

const GroupSwitchLabel = styled.div`
    display: flex;
    gap: 5px;
`;

const Label = styled(TextSmall)`
    display: flex;
    gap: 8px;
`;

// TODO Rethink this component and split it in better readable ones
/*
    There are different cases for a usages of the modal.
    It should not be opened for subaccount users (subaccounts cannot create limits at all)
    1. For creating new limit (limit prop is empty):
        - if the user is master then initially he will input cpId in previous modal and here we will
          have cpData prop with id and type of the counterparty, so couterpartyId field is disabled
          and success creating modal will opened after limit creation
        - maker and taker users will see only this modal, so cpData will be empty
    2. For editing existed limit (limit prop is NOT empty):
        - cpData should be passed for all user types
*/
export const CounterpartyLimitModal: React.FC<Props> = (props) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const {
        cpData,
        limit,
        status,
        onSubmit,
        onClose,
        onDelete,
        error: outerError,
        deleting: isDeleting = false,
    } = props;
    const { cpId, cpType, cpName } = cpData;
    const isNewLimit = !limit;

    const currencies = useCurrenciesWithPreferred();
    const currenciesOptions = useMemo(
        () =>
            currencies.map((currency) => ({
                ...currency,
                icon: <CoinIcon coin={currency.value} size={16} />,
            })),
        [currencies],
    );

    const primeBrokerViewType = usePrimeBrokerViewType();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const isMakerUser = useIsMakerUser();
    const userHasMakerRoleInLimit =
        isMakerUser || (isPrimeBrokerUser && (cpType === "taker" || isSubaccountCp(cpType)));

    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        if (outerError) {
            setError(outerError);
        }
    }, [outerError]);

    const getDefaultFormValue = (newLimit: CounterpartyLimit | undefined) => {
        if (!newLimit) {
            return {
                counterpartyId: String(cpId),
                currency: DEFAULT_PREFERRED_CURRENCY,
            };
        }

        const {
            counterpartyId,
            currency,
            grossLimit,
            restrictedTrading,
            initialMargin,
            maintenanceMargin,
        } = parseLimit(newLimit);

        const isMarginRequirementEnabled = Boolean(
            maintenanceMargin || restrictedTrading || initialMargin,
        );

        return {
            currency: currency,
            counterpartyId: String(counterpartyId),
            grossLimit: fmt(grossLimit, {
                ...FMT_LIMIT_CONFIG,
                ...getFmtPrecisionConfig(currency),
            }).copyableValue,
            isMarginActive: userHasMakerRoleInLimit && isMarginRequirementEnabled,
            initialMargin: isMarginRequirementEnabled
                ? fmtDeltaratePercent(initialMargin, FMT_PERCENT_CONFIG).copyableValue
                : undefined,
            restrictedTrading: isMarginRequirementEnabled
                ? fmtDeltaratePercent(restrictedTrading, FMT_PERCENT_CONFIG).copyableValue
                : undefined,
            maintenanceMargin: isMarginRequirementEnabled
                ? fmtDeltaratePercent(maintenanceMargin, FMT_PERCENT_CONFIG).copyableValue
                : undefined,
        };
    };

    const {
        control,
        register,
        handleSubmit,
        watch,
        trigger,
        setValue,
        getFieldState,
        formState: { errors, isSubmitting, defaultValues, isDirty },
    } = useForm<Inputs>({
        mode: "onSubmit",
        defaultValues: getDefaultFormValue(limit),
        resolver: yupResolver(schema),
    });

    useFormCloseConfirmer(COUNTERPARTY_LIMIT_MODAL_KEY, isNewLimit || isDirty);

    const newGrossLimit = watch("grossLimit");
    const selectedCurrency = watch("currency");
    const newMaintenanceMargin = watch("maintenanceMargin");
    const newRestrictedTrading = watch("restrictedTrading");
    const newInitialMargin = watch("initialMargin");
    const isMarginActive = watch("isMarginActive");

    const [freeLimitIndicator, setFreeLimitIndicator] = useState<Indicator | null>(() => {
        if (!limit) {
            return null;
        }

        const { grossLimit, grossLimitUtilization } = parseLimit(limit);
        const { freeLimit, freeLimitPercent } = getFreeLimit(grossLimit, grossLimitUtilization);
        const { copyableValue: rawPercent, formattedValue: percent } = fmtDeltaratePercent(
            freeLimitPercent,
            FMT_PERCENT_CONFIG,
        );

        return {
            title: "Gross free",
            rawPercent,
            percent,
            value: fmt(freeLimit, getFmtModalIndicatorConfig(selectedCurrency)).formattedValue,
        };
    });

    useEffect(() => {
        if (!PERCENT_FORMAT_REGEX.test(String(newMaintenanceMargin))) {
            return;
        }

        const { restrictedTrading: autoRestrictedTrading, initialMargin: autoInitialMargin } =
            getMarginsAutocomplete(Number(newMaintenanceMargin));
        const { isTouched: isRestrictedTradingTouched } = getFieldState("restrictedTrading");
        const { isTouched: isInitialMarginTouched } = getFieldState("initialMargin");

        if (!isInitialMarginTouched && !defaultValues?.initialMargin) {
            setValue("initialMargin", formatPrecision(autoInitialMargin));
        }
        if (!isRestrictedTradingTouched && !defaultValues?.restrictedTrading) {
            setValue("restrictedTrading", formatPrecision(autoRestrictedTrading));
        }
    }, [newMaintenanceMargin]);

    useEffect(() => {
        if (newMaintenanceMargin && newRestrictedTrading && newInitialMargin) {
            trigger(["maintenanceMargin", "restrictedTrading", "initialMargin"]);
        }
    }, [newMaintenanceMargin, newRestrictedTrading, newInitialMargin]);

    useEffect(() => {
        if (!LIMIT_FORMAT_REGEX.test(String(newGrossLimit)) || !limit || !freeLimitIndicator) {
            return;
        }

        const { grossLimitUtilization } = parseLimit(limit);
        const parsedGrossLimit = BigInt(EfxTypes.parseValue(newGrossLimit, "limit"));
        const { freeLimit, freeLimitPercent } = getFreeLimit(
            parsedGrossLimit,
            grossLimitUtilization,
        );
        const { copyableValue: rawPercent, formattedValue: percent } = fmtDeltaratePercent(
            freeLimitPercent,
            FMT_PERCENT_CONFIG,
        );

        setFreeLimitIndicator({
            ...freeLimitIndicator,
            rawPercent,
            percent,
            value: fmt(freeLimit, getFmtModalIndicatorConfig(selectedCurrency)).formattedValue,
        });
    }, [newGrossLimit, limit, selectedCurrency]);

    const submitHandler: SubmitHandler<Inputs> = async (values) => {
        const { markupValue = 0 } = limit !== undefined ? parseLimit(limit) : {};
        const params = getRequestBodyForNotSubaccount(values, {
            isNewLimit,
            isMarginActive,
        });

        try {
            await setCLimit({
                ...params,
                takerMarkup: markupValue,
            });

            dispatch(fetchCounterpartiesInfo(cpId));

            const { grossLimit, currency } = params;

            // this delay gives a chance to load new data
            setTimeout(() => dispatch(
                createNotification({
                    type: "success",
                    content: (
                        <NotificationMessage
                            isNewLimit={isNewLimit}
                            grossLimit={grossLimit}
                            currency={currency}
                            cpId={cpId}
                            cpType={cpType}
                        />
                    ),
                }),
            ), 100);

            onSubmit?.();
        } catch (e) {
            setError(String(e));
        }
    };

    const handleMarginActiveChange = () => {
        setValue("isMarginActive", !isMarginActive, { shouldDirty: true });
    };

    return (
        <>
            {isNewLimit && (
                <>
                    <CounterpartyInfo
                        counterpartyId={cpId}
                        counterpartyType={cpType}
                        counterpartyName={cpName}
                    />
                    <Break />
                </>
            )}
            <Styled.Form onSubmit={handleSubmit(submitHandler)}>
                {isNewLimit && getFormInfobox(primeBrokerViewType)}
                {!isNewLimit && (
                    <MakerRiskOverview
                        counterpartyName={cpName}
                        counterpartyType={cpType}
                        limit={limit}
                        status={status}
                        userHasMakerRole={userHasMakerRoleInLimit}
                    />
                )}
                {!isNewLimit && <EnableTrading limit={limit} setError={setError} />}
                <FieldGrouper isExpanded>
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <SingleDropdown
                                value={field.value}
                                onChange={field.onChange}
                                renderTrigger={(triggerProps) => (
                                    <SingleDropdown.Trigger
                                        {...triggerProps}
                                        size="large"
                                        variant="simple"
                                    >
                                        <CurrencyTriggerEssence
                                            {...triggerProps}
                                            option={triggerProps.selectedOption}
                                            size="large"
                                        />
                                    </SingleDropdown.Trigger>
                                )}
                                options={currenciesOptions}
                                error={errors.currency?.message}
                                caption="Currency"
                                fullWidth
                            >
                                <CurrencyDropdownSheet
                                    size="large"
                                    options={currenciesOptions}
                                    Dropdown={SingleDropdown}
                                />
                            </SingleDropdown>
                        )}
                        name="currency"
                    />
                    <SimpleInput
                        label={getLimitLabel(primeBrokerViewType)}
                        labelHelper={getLimitLabelHelper(primeBrokerViewType)}
                        labelHelperOptions={{ positions: ["bottom"] }}
                        placeholder={getCurrencyPlaceholder(selectedCurrency ?? "")}
                        {...register("grossLimit")}
                        error={errors.grossLimit?.message?.toString()}
                    />
                </FieldGrouper>
                {freeLimitIndicator && (
                    <Styled.IndicatorContainer>
                        <TextSmall color={theme.colors.ui52}>
                            {freeLimitIndicator.title}
                        </TextSmall>
                        <Styled.Indicator>
                            <TextSmall color={theme.colors.ui52}>
                                {freeLimitIndicator.value}
                            </TextSmall>
                            <TextSmall color={theme.colors.ui52}>
                                {freeLimitIndicator.percent}
                            </TextSmall>
                            <CircularProgressbar $percent={freeLimitIndicator.rawPercent} />
                        </Styled.Indicator>
                    </Styled.IndicatorContainer>
                )}
                {userHasMakerRoleInLimit && (
                    <FieldGrouper isExpanded={isMarginActive}>
                        <GroupSwitch data-field-group>
                            <GroupSwitchLabel>
                                <Label>Margin requirement</Label>
                                {getClickHelper(getMarginLabelHelper(primeBrokerViewType))}
                            </GroupSwitchLabel>
                            <Switch
                                onChange={handleMarginActiveChange}
                                checked={isMarginActive}
                            />
                        </GroupSwitch>
                        {isMarginActive && (
                            <>
                                <SimpleInput
                                    label="Maintenance, %"
                                    placeholder={MARGIN_PLACEHOLDER}
                                    {...register("maintenanceMargin")}
                                    error={errors.maintenanceMargin?.message?.toString()}
                                />
                                <SimpleInput
                                    label="Restricted Trading, %"
                                    placeholder={MARGIN_PLACEHOLDER}
                                    {...register("restrictedTrading")}
                                    error={errors.restrictedTrading?.message?.toString()}
                                />
                                <SimpleInput
                                    label="Initial, %"
                                    placeholder={MARGIN_PLACEHOLDER}
                                    {...register("initialMargin")}
                                    error={errors.initialMargin?.message?.toString()}
                                />
                            </>
                        )}
                    </FieldGrouper>
                )}
                {error && <FormError>{error}</FormError>}
                {!isNewLimit && (
                    <DeleteButton
                        type="button"
                        size="large"
                        fullWidth
                        loading={isDeleting}
                        onClick={onDelete}
                    >
                        <HStack width="100%">
                            <PSmall>Delete risk profile</PSmall>
                            <Spacer />
                            <Icons.Bin color={theme.colors.negative80} />
                        </HStack>
                    </DeleteButton>
                )}
                {isNewLimit && (
                    <Styled.ButtonContainer>
                        <PrimaryButton size="large" fullWidth loading={isSubmitting}>
                            Add risk profile
                        </PrimaryButton>
                    </Styled.ButtonContainer>
                )}
                {!isNewLimit && (
                    <Styled.ButtonContainer>
                        <PrimaryButton
                            type="submit"
                            size="large"
                            fullWidth
                            disabled={!isDirty}
                            loading={isSubmitting}
                        >
                            Save
                        </PrimaryButton>
                        <PlainButton type="button" size="large" fullWidth onClick={onClose}>
                            Cancel
                        </PlainButton>
                    </Styled.ButtonContainer>
                )}
            </Styled.Form>
        </>
    );
};
