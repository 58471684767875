import { PXSmall } from "@fm-frontend/uikit";
import { RfqRequestSide } from "feature/rfq/apiHooks/useRfqWs";
import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

const REQUEST_TYPE_TITLES = {
    SELL: "Sell quote",
    BUY: "Buy quote",
    BOTH: "both sides quotes",
};

const useRequestTypeLabel = (requestSide: RfqRequestSide) => {
    const theme = useTheme();

    return useMemo(() => {
        const colors = {
            SELL: theme.colors.negative100,
            BUY: theme.colors.brand100,
            BOTH: theme.colors.ui100,
        };

        return {
            text: REQUEST_TYPE_TITLES[requestSide],
            color: colors[requestSide],
        };
    }, [requestSide, theme]);
};

const RequestType = styled(PXSmall)<{ $color: string }>`
    text-align: center;
    color: ${(p) => p.theme.colors.ui52};

    > span {
        color: ${(p) => p.$color};
    }
`;

export const RequestTypeLabel = ({ requestSide }: { requestSide: RfqRequestSide }) => {
    const { text, color } = useRequestTypeLabel(requestSide);

    return (
        <RequestType $color={color}>
            Request for <span>{text}</span>
        </RequestType>
    );
};
