import { FC, forwardRef } from "react";
import {
    Popover as TinyPopover,
    PopoverAlign as TinyPopoverAlign,
    PopoverPosition as TinyPopoverPosition,
    type PopoverProps,
} from "react-tiny-popover";
import styled, { css } from "styled-components";
import { TransitionState, useTransition } from "../useTransition";

type DropdownAnimationProps = {
    $transitionState: TransitionState;
    $align: PopoverAlign;
};

const StyledPopoverContent = styled.div<DropdownAnimationProps>`
    transition: opacity 75ms 25ms ease-in-out, transform 100ms ease-in-out;
    transform-origin: top
        ${(p) => {
            switch (p.$align) {
                case "start":
                    return "left";
                case "center":
                    return "center";
                case "end":
                    return "right";
                default:
                    return "";
            }
        }};

    ${({ $transitionState }) => {
        switch ($transitionState) {
            case "entering":
            case "exiting": {
                return css`
                    opacity: 0;
                    transform: scale(0.8);
                `;
            }
            case "active": {
                return css`
                    opacity: 1;
                    transform: scale(1);
                `;
            }
        }
    }}
`;

export type PopoverPosition = TinyPopoverPosition;
export type PopoverAlign = TinyPopoverAlign;

export const Popover: FC<PopoverProps> = forwardRef(({ children, ...props }, ref) => {
    const { isActive: isOpen, transitionState } = useTransition(props.isOpen, { enterDelay: 100, exitDelay: 100 });

    const popoverContent = (
        <StyledPopoverContent $transitionState={transitionState} $align={props.align ?? "start"}>
            {props.content}
        </StyledPopoverContent>
    );

    return (
        <TinyPopover
            ref={ref}
            {...props}
            isOpen={isOpen}
            content={popoverContent}
            containerStyle={{ zIndex: "1", transition: "none" }}
        >
            {children}
        </TinyPopover>
    );
});
