import { Icon } from "@fm-frontend/uikit";
import { CreateNotificationPayload } from "feature/app";
import { Notification, NotificationMessageType } from "services/notificationsService";
import { NotificationContent } from "./index";

const getNotificationIcon = (type: NotificationMessageType) => {
    switch (type) {
        case NotificationMessageType.INITIAL_MARGIN:
        case NotificationMessageType.RESTRICTED_TRADING:
        case NotificationMessageType.LIQUIDATION:
        case NotificationMessageType.LIMIT_SET:
        case NotificationMessageType.MUTUAL_LIMIT_SET:
        case NotificationMessageType.SUBACCOUNT_LIMIT_SET:
            return <Icon name="shield" />;

        case NotificationMessageType.INCOMING_SETTLEMENT:
        case NotificationMessageType.COMMITTED_TRANSACTION:
            return <Icon name="arrows" />;

        case NotificationMessageType.DAILY_REPORT_SENT:
        case NotificationMessageType.MONTHLY_REPORT_SENT:
            return <Icon name="file" />;

        case NotificationMessageType.ADDRESSES_NEED_CONFIRMATION:
        case NotificationMessageType.ADDRESSES_CONFIRMED:
            return <Icon name="wallet" />;
    }
}

export const getNotificationPayload = (notification: Notification): CreateNotificationPayload => ({
    type: "custom",
    icon: getNotificationIcon(notification.messageType),
    content: <NotificationContent notification={notification} />,
});
