import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Notification, NotificationId } from "services/notificationsService";

export type NotificationsFetchRequestAction = {
    page?: number;
}
export type NotificationsReadRequestAction = {
    ids: NotificationId[];
}

export const notificationsFetchRequest = createAction<NotificationsFetchRequestAction>("notifications/fetch/request");
export const notificationsFetchCountRequest = createAction("notifications/fetch-count/request");
export const notificationsReadRequest = createAction<NotificationsReadRequestAction>("notifications/read/request");
export const notificationsReadAllRequest = createAction("notifications/readAll/request");

// `-1` is a hack to make initial `unreadCount` fetch
export const INIT_UNREAD_COUNT = -1;

export type NotificationsState = {
    notifications: Notification[];
    currentPage: number;
    elementsOnPage: number;
    totalElements: number;
    unreadCount: number;
    loading: boolean;
};

const initialState: NotificationsState = {
    notifications: [],
    currentPage: 0,
    elementsOnPage: 0,
    totalElements: 0,
    unreadCount: INIT_UNREAD_COUNT,
    loading: false,
};

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        notificationsAdd(
            state,
            action: PayloadAction<{
                notifications: Notification[];
                totalElements: number;
                elementsOnPage: number;
                currentPage: number;
            }>,
        ) {
            const { notifications, currentPage, elementsOnPage, totalElements } = action.payload;

            const stateNotificationsMap = state.notifications.reduce((acc, notification) => {
                acc[notification.id] = notification;
                return acc;
            }, {} as Record<NotificationId, Notification>);

            notifications.forEach((newNotification) => {
                stateNotificationsMap[newNotification.id] = {
                    ...stateNotificationsMap[newNotification.id],
                    ...newNotification,
                };
            });

            state.notifications = Object.values(stateNotificationsMap);

            state.currentPage = currentPage;
            state.elementsOnPage = elementsOnPage;
            state.totalElements = totalElements;
            state.loading = false;
        },
        notificationsRead(
            state,
            action: PayloadAction<{
                ids: NotificationId[];
            }>,
        ) {
            const { ids } = action.payload;

            state.notifications.forEach((notification) => {
               if (ids.includes(notification.id)) {
                   notification.isRead = true;
                   state.unreadCount -= 1;
               }
            });
        },
        notificationsReadAll(
            state,
        ) {
            state.notifications.forEach((notification) => {
                notification.isRead = true;
            });
            state.unreadCount = 0;
        },
        notificationsLoadMore(state) {
            state.loading = true;
        },
        notificationsUnreadCountSet(
            state,
            action: PayloadAction<{ count: number; }>,
        ) {
            const { count } = action.payload;

            state.unreadCount = count;
        }
    },
});

export const {
    actions: { notificationsAdd, notificationsRead, notificationsReadAll, notificationsLoadMore, notificationsUnreadCountSet },
} = notificationsSlice;
