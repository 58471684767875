import { FC } from "react";
import styled from "styled-components";
import { IconButton } from "../../../buttons";
import { Icons } from "../../../icons";
import { VStack } from "../../../layout";

const Container = styled(VStack)`
    align-items: center;
    justify-content: center;

    ::before {
        pointer-events: none;
        content: "";
        display: block;
        border-right: 1px solid ${(p) => p.theme.colors.ui8};
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
`;

type ActionCellProps = {
    onClick?: () => void;
};
export const ActionCell: FC<ActionCellProps> = ({ onClick }) => {
    return (
        <Container>
            {onClick && <IconButton variant="plain" Icon={Icons.Reload} onClick={onClick} />}
        </Container>
    );
};
