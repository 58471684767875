import { deleteSettlementRequest } from "feature/settlements/api";
import { useOpenConfirmationModal } from "hooks";
import { displayError } from "utils";
import { DeleteWithdrawalRequestModalBody } from "./DeleteWithdrawalRequestModalBody";
import { WithdrawalRequest } from "./types";

export const useActions = () => {
    const openConfirmationModal = useOpenConfirmationModal();
    const deleteWithdrawalRequest = (withdrawalRequest: WithdrawalRequest) => {
        openConfirmationModal({
            title: "Delete request",
            description: <DeleteWithdrawalRequestModalBody withdrawal={withdrawalRequest} />,
            confirmButtonTitle: "Yes, delete",
            onConfirm: () => {
                deleteSettlementRequest({
                    requestId: withdrawalRequest.id,
                }).catch(displayError);
            },
        });
    };

    return {
        cancelRequest: deleteWithdrawalRequest,
    };
};
