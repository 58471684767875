import { useSelector } from "hooks";
import { createContext, FC, useContext, useMemo, useState } from "react";
import { useIsOnboardingRequired } from "store/onboardingSlice";
import { useOnboardingFilterApi } from "../../common";

export type FilterInputs = {
    banks: string[];
    assets: string[];
    jurisdiction: string;
    regulation: boolean;
};

type FilterContextType = {
    filters: FilterInputs;
    setFilters: (values: FilterInputs) => void;
};

export const DEFAULT_FILTER_INPUTS: FilterInputs = {
    banks: [],
    assets: [],
    jurisdiction: "",
    regulation: false,
};

const FilterContext = createContext<FilterContextType | null>(null);
FilterContext.displayName = "FilterContext";

export const useFilterContext = (): FilterContextType => {
    const context = useContext(FilterContext);

    if (context === null) {
        throw new Error("useFilterContext must be within FilterContextProvider");
    }

    return context;
};

type ProviderContentProps = {
    defaultFilterInputs: FilterInputs;
};
export const ProviderContent: FC<ProviderContentProps> = ({ children, defaultFilterInputs }) => {
    const [filters, setFilters] = useState<FilterInputs>(defaultFilterInputs);

    return (
        <FilterContext.Provider
            value={{
                filters,
                setFilters,
            }}
        >
            {children}
        </FilterContext.Provider>
    );
};

export const FilterContextProvider: FC = ({ children }) => {
    useOnboardingFilterApi();
    const { takerInfo } = useSelector((state) => state.onboarding);
    const isOnboardingRequired = useIsOnboardingRequired();

    const defaultFilterInputs = useMemo((): FilterInputs => {
        if (!isOnboardingRequired) {
            return DEFAULT_FILTER_INPUTS;
        }

        const defaultBanks = takerInfo?.bank.map((b) => String(b.id)) ?? [];
        const defaultCurrencies = takerInfo?.currency.map((c) => String(c.id)) ?? [];
        const hasDefaultFilters = Boolean(defaultBanks.length || defaultCurrencies.length);

        if (hasDefaultFilters) {
            return { ...DEFAULT_FILTER_INPUTS, banks: [...defaultBanks], assets: [...defaultCurrencies] };
        }

        return DEFAULT_FILTER_INPUTS;
    }, [isOnboardingRequired, takerInfo]);

    return <ProviderContent defaultFilterInputs={defaultFilterInputs}>{children}</ProviderContent>;
};
