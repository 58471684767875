import { PBold, PSmall } from "@fm-frontend/uikit";
import { FC } from "react";
import styled, { css } from "styled-components";

const Container = styled.div<{ type: "bid" | "ask" }>`
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;

    :before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: ${(p) => p.theme.colors.ui8};

        ${(p) =>
            p.type === "bid" &&
            css`
                right: 0;
            `}
        ${(p) =>
            p.type === "ask" &&
            css`
                left: 0;
            `}
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    height: 100%;
    max-width: 210px;

    span,
    p {
        text-align: center;
    }
`;

type NoQuotesSideProps = {
    type: "bid" | "ask";
};
export const NoQuotesSide: FC<NoQuotesSideProps> = ({ type }) => {
    const side = type === "bid" ? "buy" : "ask";

    return (
        <Container type={type}>
            <Wrapper>
                <PBold>No {side} quotes available</PBold>
                <PSmall>
                    To see the quotes, ensure that Liquidity providers are streaming {side}-side
                    quotes.
                </PSmall>
            </Wrapper>
        </Container>
    );
};
