import { HStack, Icons, PaddingProps } from "@fm-frontend/uikit";
import { useCopyToClipboard } from "@fm-frontend/utils";
import { createNotification } from "feature/app";
import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { HoverCell } from "../HoverCell";

interface CopyCellProps extends PaddingProps {
    content: string | undefined;
    notificationTitle?: string;
}

export const CopyCell: React.FC<CopyCellProps> = memo(({ content, notificationTitle, children, ...paddingProps }) => {
    const { colors } = useTheme();
    const copyToClipboard = useCopyToClipboard();
    const dispatch = useDispatch();

    const copyUnavailable = content === undefined || content === "";

    const [isHovered, setIsHovered] = useState(false);
    const handleHover = () => setIsHovered(true);
    const handleLeave = () => setIsHovered(false);

    const handleAddressCopy = useCallback(() => {
        if (copyUnavailable) {
            return;
        }

        copyToClipboard(content, (value) => {
            dispatch(
                createNotification({
                    type: "success",
                    content: notificationTitle
                        ? `${notificationTitle} was successfully copied to clipboard`
                        : `Value ${value} was successfully copied to clipboard`,
                }),
            );
        });
    }, [content, notificationTitle]);

    return (
        <HoverCell onHover={handleHover} onLeave={handleLeave}>
            <HStack
                {...paddingProps}
                onClick={handleAddressCopy}
                width="100%"
                justifyContent="space-between"
                spacing={8}
                alignItems="center"
            >
                {children}
                {isHovered && !copyUnavailable && <Icons.Copy color={colors.ui32} />}
            </HStack>
        </HoverCell>
    );
});
