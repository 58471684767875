import { forwardRef } from "react";
import { DefaultTheme, StyledComponent } from "styled-components";
import { LoadingContent } from "../LoadingContent/LoadingContent";
import { ButtonSize, ButtonVariant } from "../types";
import { ButtonProps } from "./Button.types";
import { BasicButton, DesctructiveButton, LinkButton, PlainButton, PrimaryButton } from "./styled";

const ButtonVariantComponents: {
    [Key in ButtonVariant]: StyledComponent<
        "button",
        DefaultTheme,
        {
            size: ButtonSize;
            loading: boolean;
            fullWidth: boolean;
        },
        never
    >;
} = {
    basic: BasicButton,
    destructive: DesctructiveButton,
    primary: PrimaryButton,
    plain: PlainButton,
    link: LinkButton,
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ variant, size, fullWidth = false, loading = false, children, disabled, ...restProps }, ref) => {
        const ButtonComponent = ButtonVariantComponents[variant];
        const content = loading ? <LoadingContent variant={variant}>{children}</LoadingContent> : children;

        return (
            <ButtonComponent
                disabled={disabled || loading}
                fullWidth={fullWidth}
                loading={loading}
                size={size}
                {...restProps}
                ref={ref}
            >
                {content}
            </ButtonComponent>
        );
    },
);
