import { Skeleton } from "@fm-frontend/uikit";
import { FC } from "react";
import { TopMenu } from "components/TopMenu";
import { CounterpartyStatusGroupType } from "feature/counterparties/utils";
import { useFilterState } from "hooks/useFilterState";
import { useIsSubaccountUser, usePrimeBrokerViewType } from "store/hooks";
import { Page, Sidebar } from "style";
import { CounterpartyList } from "./CounterpartyList";
import { useCounterpartiesStatuses } from "./hooks/useCounterpartiesStatuses";
import { Onboarding } from "./Onboarding";

const PAGE_FILTER_KEY = "risk_management"

export const RiskManagement: FC = () => {
    const primeBrokerViewType = usePrimeBrokerViewType();
    const [activeStatusGroups, setActiveStatusGroups] = useFilterState<CounterpartyStatusGroupType[]>(
        PAGE_FILTER_KEY,
        "status",
        [],
        {
            isArray: true,
            parseValue: Number,
            availableValues: Object.values(CounterpartyStatusGroupType) as CounterpartyStatusGroupType[],
        });
    const isSubaccountUser = useIsSubaccountUser();
    const { data: cpStatuses, isLoading } = useCounterpartiesStatuses();
    const counterpartyCount = Object.values(cpStatuses).length;

    return (
        <Page>
            <TopMenu>Risk management</TopMenu>
            <Sidebar>
                {isLoading && (
                    <Skeleton height="170px" />
                )}
                {!isLoading && !isSubaccountUser && counterpartyCount === 0 && (
                    <Onboarding />
                )}
                {!isLoading && (isSubaccountUser || counterpartyCount > 0) && (
                    <CounterpartyList
                        key={primeBrokerViewType}
                        counterpartiesStatuses={cpStatuses}
                        activeStatusGroups={activeStatusGroups}
                        onChangeStatusGroups={setActiveStatusGroups}
                    />
                )}
            </Sidebar>
        </Page>
    );
};
