import type { Notification } from "@fm-frontend/uikit";
import { Icon } from "@fm-frontend/uikit";
import { CreateNotificationPayload } from "../feature/app";

type Arg0 = CreateNotificationPayload & {
    id: string;
};

const getIcon = (type: CreateNotificationPayload["type"]) => {
    switch (type) {
        case "error":
            return <Icon name="error" />;
        case "success":
            return <Icon name="success" />;
        case "inbox":
            return <Icon name="inbox" />;
    }
};

export const buildNotification = (notification: Arg0): Notification => {
    if (notification.type === "custom") {
        return {
            id: notification.id,
            icon: notification.icon,
            content: notification.content,
        };
    } else {
        return {
            id: notification.id,
            icon: getIcon(notification.type),
            content: notification.content,
        };
    }
};
