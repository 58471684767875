import { getMatch, HStack, Icons, useItemTransition } from "@fm-frontend/uikit";
import { Popover } from "components/Popover";
import { useClickOutside } from "hooks/useClickOutside";
import { useDropdownDirection } from "hooks/useDropdownDirection";
import { Instrument } from "hooks/useInstruments";
import { createGroup } from "pages/trade/trading";
import {
    createContext,
    forwardRef,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from "react";
import styled, { useTheme } from "styled-components";
import { TradingAsset } from "types";
import { noop } from "utils";
import { PrimaryPopover, SecondaryPopover } from "./components";
import { SearchSelectContainer } from "./style";

const PlusIcon = styled(Icons.Plus)`
    position: absolute;
    left: 12px;
    top: 12px;
`;

const FavoritesCount = styled.span`
    color: ${(p) => p.theme.colors.ui32};
    font-size: 12px;
`;

const TriggerContainer = styled.div`
    width: 100%;
    position: relative;

    input {
        width: 100%;
        height: 40px;
        padding: 0 72px 0 34px;
        font-size: 14px;
        line-weight: 18px;
        border-radius: 12px;
        border: 2px solid ${(p) => p.theme.colors.ui12};
        background-color: ${(p) => p.theme.colors.ui4};
    }
    input::placeholder {
        color: ${(p) => p.theme.colors.ui52};
        font-weight: 400;
    }
    input:focus {
        background-color: ${(p) => p.theme.colors.uiWhite100};
        border: 2px solid ${(p) => p.theme.colors.brand100};
        box-shadow: 0px 12px 40px 0px ${(p) => p.theme.colors.ui20},
            0px 0px 0px 2px ${(p) => p.theme.colors.brand20};
    }
`;

const AppendArea = styled(HStack)`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    align-items: center;
    gap: 8px;
    margin-right: 12px;
`;

type InstrumentSelectorProps = {
    favoriteInstruments: string[];
    onInstrumentSelect: (instrumentName: string) => void;
    onInstrumentDeselect: (instrumentName: string) => void;
    className?: string;
    instruments: Instrument[];
};

const InstrumentSelectorContext = createContext<{
    favoriteInstruments: InstrumentSelectorProps["favoriteInstruments"];
    onInstrumentSelect: InstrumentSelectorProps["onInstrumentSelect"];
    onInstrumentDeselect: InstrumentSelectorProps["onInstrumentDeselect"];
}>({
    favoriteInstruments: [],
    onInstrumentSelect: noop,
    onInstrumentDeselect: noop,
});
export const useInstrumentSelectorContext = () => useContext(InstrumentSelectorContext);

export const InstrumentSelector = forwardRef<HTMLInputElement, InstrumentSelectorProps>(
    (
        {
            favoriteInstruments,
            onInstrumentSelect,
            onInstrumentDeselect,
            className,
            instruments: availableInstruments,
        },
        inputRef,
    ) => {
        const { colors } = useTheme();
        const [query, setQuery] = useState("");
        const [isOpen, setIsOpen] = useState(false);
        const { isActive, transitionState } = useItemTransition(isOpen, {
            enterDelay: 100,
            exitDelay: 100,
        });

        const searchItems = useMemo(
            () => availableInstruments.map((instrument) => instrument.instrumentName),
            [availableInstruments],
        );
        const instrumentDict = useMemo(() => {
            const dict: { [key: string]: ReturnType<typeof createGroup> } = {};
            for (const instrument of availableInstruments) {
                const { instrumentName, assetCurrency, balanceCurrency } = instrument;
                dict[assetCurrency] ??= createGroup();
                dict[assetCurrency].base.add(instrumentName);
                dict[balanceCurrency] ??= createGroup();
                dict[balanceCurrency].quote.add(instrumentName);
            }
            return Object.fromEntries(
                Object.entries(dict)
                    .map<[string, { base: string[]; quote: string[]; title: string }]>(
                        ([title, { base, quote }]) => [
                            title,
                            { title, base: [...base].sort(), quote: [...quote].sort() },
                        ],
                    )
                    .sort((a, b) => a[0].localeCompare(b[0])),
            );
        }, [availableInstruments]);
        const instrumentGroups = useMemo(() => {
            const { BTC, ETH, LTC, USDT, ...secondary } = instrumentDict;
            return [
                [BTC, ETH, LTC, USDT]
                    .filter(Boolean)
                    .map(({ base, quote, title }) => ({ title, children: { base, quote } })),
                Object.values(secondary).map(({ base, quote, title }) => ({
                    title,
                    children: { base, quote },
                })),
            ];
        }, [instrumentDict]);
        const items = useMemo(() => {
            return [...instrumentGroups];
        }, [instrumentGroups]);

        const onSelectOption = useCallback(
            (tradingAsset: TradingAsset, instrument: string) => {
                onInstrumentSelect(instrument);
                setIsOpen(false);
                setQuery("");
            },
            [onInstrumentSelect, setIsOpen, setQuery],
        );

        const searchResults = useMemo(() => {
            if (!query) return null;
            const normalizedQuery = query.toLowerCase().trim();

            return searchItems.sort().reduce<{ title: string; match: ReactNode }[]>((acc, item) => {
                const match = getMatch(item, normalizedQuery);
                if (match) acc.push({ title: item, match });
                return acc;
            }, []);
        }, [query, searchItems]);

        const ref = useRef(null);
        const popoverRef = useRef(null);
        const popoverSubRef = useRef(null);
        useClickOutside(() => setIsOpen(false), ref, popoverRef, popoverSubRef);

        const { vertical } = useDropdownDirection(ref);

        const onItemHover = () => {};

        return (
            <InstrumentSelectorContext.Provider
                value={{ favoriteInstruments, onInstrumentSelect, onInstrumentDeselect }}
            >
                <SearchSelectContainer ref={ref} className={className}>
                    <TriggerContainer>
                        <input
                            type="text"
                            value={query}
                            onChange={(e) => {
                                setQuery(e.currentTarget.value);
                            }}
                            onFocus={() => {
                                setIsOpen(true);
                            }}
                            placeholder="Add instrument"
                            spellCheck={false}
                            ref={inputRef}
                        />
                        <PlusIcon color={colors.ui32} />
                        <AppendArea>
                            {query.trim().length > 0 && (
                                <Icons.Clear onClick={() => setQuery("")} />
                            )}
                            <FavoritesCount>
                                {favoriteInstruments.length}/{searchItems.length}
                            </FavoritesCount>
                            <Icons.Bookmark />
                        </AppendArea>
                    </TriggerContainer>
                    {isActive && (
                        <Popover containerRef={ref}>
                            {searchResults ? (
                                <SecondaryPopover
                                    horizontal={"center"}
                                    vertical={vertical}
                                    transitionState={transitionState}
                                    items={searchResults}
                                    popoverRef={popoverRef}
                                    onSelect={onSelectOption}
                                />
                            ) : (
                                <PrimaryPopover
                                    horizontal={"center"}
                                    vertical={vertical}
                                    transitionState={transitionState}
                                    items={items}
                                    popoverRef={popoverRef}
                                    popoverSubRef={popoverSubRef}
                                    onSelect={onSelectOption}
                                    onItemHover={onItemHover}
                                />
                            )}
                        </Popover>
                    )}
                </SearchSelectContainer>
            </InstrumentSelectorContext.Provider>
        );
    },
);
