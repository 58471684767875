import { useIsMakerUser, useIsPrimeBrokerUser, useUserType } from "store/hooks";
import { ClientType, ClientTypeEnum } from "types";
import { isSubaccountCp } from "../../utils";

export const useIsMarkupAvailable = (cpType: ClientType) => {
    const userType = useUserType();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const isMakerUser = useIsMakerUser();

    return !isSubaccountCp(userType) && (isPrimeBrokerUser || isMakerUser) && cpType !== ClientTypeEnum.Maker;
};
