import { post } from "api";
import useSWR, { preload } from "swr";
import { EMPTY_ARRAY } from "const";
import { useEffect } from "react";

export enum InviteStatus {
    Accepted = "Accepted",
    Sent = "Sent",
    Expired = "Expired",
}

export type Invite = {
    clientId: number;
    clientType: "taker" | "maker";
    isSubaccount: boolean;
    email: string;
    name: string;
    status: InviteStatus;
    createdAt: number;
    expiresAt: number;
};

const fetcher = (url: string) => post(url, {});

export const usePreloadInvitesList = () => {
    useEffect(() => {
        preload("getInvitesList", fetcher)
    }, [])
};

export const useInvitesList = () => {
    const { data, isLoading, mutate } = useSWR<Invite[]>("getInvitesList", fetcher);

    return {
        invitesList: data ?? EMPTY_ARRAY as Invite[],
        mutate,
        isLoading,
    };
};
