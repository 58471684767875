import { CounterpartyIcon, PSmallBold, PXSmall, Tooltip } from "@fm-frontend/uikit";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { LpDefaultIcon } from "../LpDefaultIcon";
import type { LpInfo } from "../types";
import { CircleStatusWrapper } from "./CircleStatusWrapper";
import { HiddenLPsCount } from "./HiddenLPsCount";
import { TooltipContent } from "./TooltipContent";
import { useCpStatusHelpers } from "./useCpStatusHelpers";

const Container = styled.button`
    height: 40px;
    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.ui8};
    padding: 0 8px 0 6px;
    display: flex;
    align-items: center;
    gap: 6px;

    cursor: pointer;

    &:hover {
        background-color: ${(p) => p.theme.colors.ui12};
    }
`;

const LPsInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${PXSmall} {
        color: ${(p) => p.theme.colors.ui52};
        margin-top: -2px;
    }
`;

const LPsIcons = styled.div`
    display: flex;

    *:not(:first-child) {
        margin-left: -6px;
    }
`;

const MAX_VISIBLE_CPS_ICONS = 4;

export const WidgetButton = ({
    allLimits,
    onClick,
}: {
    allLimits: LpInfo[];
    onClick: () => void;
}) => {
    const { getCpStatusColor } = useCpStatusHelpers();
    const enabledLimits = useMemo(
        () => allLimits.filter(({ enabledTradingByUser }) => enabledTradingByUser),
        [allLimits],
    );
    const visibleLPs = useMemo(
        () =>
            enabledLimits.length > MAX_VISIBLE_CPS_ICONS
                ? enabledLimits.slice(0, MAX_VISIBLE_CPS_ICONS - 1)
                : enabledLimits,
        [enabledLimits],
    );
    const hiddenLPs = useMemo(
        () => enabledLimits.slice(visibleLPs.length),
        [enabledLimits, visibleLPs],
    );

    // hack for Safari issue: mouseleave is not fired after click on WidgetButton and modal with overlay showed
    // https://github.com/mohsinulhaq/react-popper-tooltip/issues/41
    const [tooltipKey, setTooltipKey] = useState(0);
    const handleClick = () => {
        onClick();
        setTooltipKey((v) => v + 1);
    };

    return (
        <Tooltip
            key={tooltipKey}
            content={<TooltipContent allLimits={allLimits} />}
            positions={["bottom"]}
        >
            <Container onClick={handleClick}>
                <LPsIcons>
                    {visibleLPs.map(({ key, cpStatus, counterpartyId }) => (
                        <CircleStatusWrapper size={24} statusColor={getCpStatusColor(cpStatus)}>
                            <CounterpartyIcon
                                cpId={counterpartyId}
                                key={key}
                                FallbackComponent={LpDefaultIcon}
                                size={24}
                            />
                        </CircleStatusWrapper>
                    ))}
                    {hiddenLPs.length > 0 && <HiddenLPsCount size={24} hiddenLPs={hiddenLPs} />}
                </LPsIcons>
                <LPsInfo>
                    <PSmallBold>
                        {enabledLimits.length} of {allLimits.length}
                    </PSmallBold>
                    <PXSmall>LPs enabled</PXSmall>
                </LPsInfo>
            </Container>
        </Tooltip>
    );
};
