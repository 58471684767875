import { addMonths } from "date-fns";
import { Book } from "types";

export const NOT_ENOUGH_LIQUIDITY = -1n;
export const DISPLAY_TRADING_LAST_TRADES_LIMIT = 10;
export const LOAD_TRADING_LAST_TRADES_LIMIT = 50;
export const EMPTY_BOOK: Book = [[], []];

export const getLastDealsFromParam = () => addMonths(new Date(), -1).valueOf();
export const isNotEnoughLiquidity = (price: bigint | null | undefined) =>
    price === NOT_ENOUGH_LIQUIDITY;
