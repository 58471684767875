import { H2, TextSmall } from "@fm-frontend/uikit";
import styled, { css } from "styled-components";
import { CounterpartyStatusGroupType, CounterpartyStatusType } from "../utils";

export const Header = styled.div`
    display: flex;
    justify-content: space-between;

    width: 100%;

    ${H2} {
        margin: 0;
    }

    button {
        padding: 4px 8px;
    }
`;

export const Label = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    ${H2} {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        margin: 0;

        :nth-child(2) {
            color: ${(p) => p.theme.colors.action};
        }
    }
`;

export const StatusWrapper = styled.div<{
    $selected?: boolean;
    $status?: CounterpartyStatusGroupType;
}>`
    display: flex;
    cursor: pointer;
    flex-direction: column;
    flex: 0 1 calc(33.33% - 2px);
    height: 60px;
    min-width: 82px;
    max-width: 82px;

    gap: 4px;
    padding: 8px;
    border-radius: 8px;

    ${TextSmall}, ${H2} {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    ${(p) =>
        p.$selected &&
        css`
            border: 2px solid ${p.theme.colors.brand100};
            padding: 6px;
        `}

    ${(p) => {
        switch (p.$status) {
            case CounterpartyStatusGroupType.NORMAL:
                return css`
                    ${TextSmall} {
                        color: ${p.theme.colors.positive100};
                    }

                    background-color: ${p.theme.colors.positive4};
                `;
            case CounterpartyStatusGroupType.AWAITING:
                return css`
                    ${TextSmall} {
                        color: ${p.theme.colors.process100};
                    }

                    background-color: ${p.theme.colors.process4};
                `;
            case CounterpartyStatusGroupType.LOW_LIMITS:
                return css`
                    ${TextSmall} {
                        color: ${p.theme.colors.attention100};
                    }

                    background-color: ${p.theme.colors.attention8};
                `;
            case CounterpartyStatusGroupType.STOPPED:
                return css`
                    ${TextSmall} {
                        color: ${p.theme.colors.ui52};
                    }

                    background-color: ${p.theme.colors.ui4};
                `;
            case CounterpartyStatusGroupType.CRITICAL:
                return css`
                    ${TextSmall} {
                        color: ${p.theme.colors.negative80};
                    }

                    background-color: ${p.theme.colors.negative8};
                `;
            default:
                return null;
        }
    }}
`;

export const StatusesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 4px;
    margin-top: 4px;

    width: 100%;
`;

export const CircleContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    gap: 8px 0;

    width: 100%;
`;

export const CircleIndicator = styled.span<{ status?: CounterpartyStatusType }>`
    width: 12px;
    height: 12px;

    border-radius: 50%;

    ${(p) => {
        switch (p.status) {
            case CounterpartyStatusType.NORMAL:
                return css`
                    background-color: ${p.theme.colors.positive100};
                `;
            case CounterpartyStatusType.AWAITING_CP:
                return css`
                    border: 1px solid ${p.theme.colors.process100};
                `;
            case CounterpartyStatusType.MARGIN_CALL:
                return css`
                    background-color: ${p.theme.colors.attention100};
                `;
            case CounterpartyStatusType.STOPPED:
            case CounterpartyStatusType.STOPPED_BY_CP:
                return css`
                    border: 1px solid ${p.theme.colors.ui72};
                `;
            case CounterpartyStatusType.LOW_GROSS_FREE:
            case CounterpartyStatusType.RESTRICTED_TRADING:
            case CounterpartyStatusType.LIQUIDATION:
                return css`
                    border: 1px solid ${p.theme.colors.negative80};
                `;
            default:
                return null;
        }
    }}
`;

export const CircleIndicatorContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    width: 100%;

    padding: 0 4px;
`;
