import { P, PXSmall, VStack } from "@fm-frontend/uikit";
import React from "react";
import styled from "styled-components";
import { TradeType } from "types";
import { TradeHistory } from "../../../types";

type OrderTypeProps = Pick<TradeHistory, "orderType" | "tradeType">;

export const ORDER_TYPE_TITLES: Record<Exclude<TradeHistory["orderType"], undefined>, string> = {
    limit: "Limit",
    postOnly: "Post only",
    limitIOC: "Limit IOC",
    limitFOK: "Limit FOK",
    marketIOC: "Market IOC",
    marketFOK: "Market FOK",
    voiceTrade: "Voice trade",
    liquidation: "Liquidation",
    rfq: "RFQ",
};

export const TRADE_TYPE_TITLES: Record<Exclude<TradeHistory["tradeType"], undefined>, string> = {
    [TradeType.Regular]: "",
    [TradeType.Liquidation]: "Liquidation",
    [TradeType.Authorized]: "Authorized",
};

const TradeTypeText = styled(PXSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

export const OrderTypeCell: React.VFC<OrderTypeProps> = ({
    orderType,
    tradeType = TradeType.Regular,
}) => {
    if (orderType === undefined) {
        return <></>;
    }

    return (
        <VStack>
            <P>{ORDER_TYPE_TITLES[orderType]}</P>
            {Boolean(TRADE_TYPE_TITLES[tradeType]) && (
                <TradeTypeText>{TRADE_TYPE_TITLES[tradeType]}</TradeTypeText>
            )}
        </VStack>
    );
};
