import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { useMemo } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { EMPTY_ARRAY } from "const";
import { InstrumentDropdownSheet } from "components/InstrumentSheet";
import { InstrumentTriggerEssence } from "components/InstrumentTriggerEssence";

type InstrumentDropdownSelectorProps<T extends FieldValues> = {
    control: Control<T>;
    name: Path<T>;
    instruments?: string[];
};

export const InstrumentDropdownSelector = <T extends FieldValues>({ control, name, instruments = EMPTY_ARRAY as string[] }: InstrumentDropdownSelectorProps<T>) => {
    const options = useMemo(
        () => instruments.map<DropdownOption<string>>(
            (instrument) => ({ text: instrument, value: instrument })
        ),
        [instruments],
    );

    return (
        <Controller
            control={control}
            render={({ field }) => (
                <MultipleDropdown
                    values={field.value}
                    onChange={field.onChange}
                    options={options}
                    renderTrigger={(trigger) => (
                        <MultipleDropdown.Trigger {...trigger} size="small" variant="simple">
                            <InstrumentTriggerEssence
                                {...trigger}
                                size="small"
                                option={trigger.selectedOptions}
                                noIcon
                            />
                        </MultipleDropdown.Trigger>
                    )}
                    caption="Instruments"
                    asFilter
                >
                    <InstrumentDropdownSheet size="medium" options={options} Dropdown={MultipleDropdown} />
                </MultipleDropdown>
            )}
            name={name}
        />
    );
};
