import { ACTIONS_TABLE_COLUMN_KEY, Skeleton, TableView } from "@fm-frontend/uikit";
import {
    ColumnDef,
    createColumnHelper,
    ExpandedState,
    getCoreRowModel,
    getExpandedRowModel,
    Row,
    useReactTable,
} from "@tanstack/react-table";
import { PositionsTableRowData, Subrow } from "feature/positions/types";
import React, { useMemo, useState } from "react";
import { GroupBy } from "store/usePositionsGrouped";
import { getColumns } from "./table";

const SKELETON_COUNT = 5;

type TableProps<T> = {
    data: T[];
    getSubRows?: (row: T, index: number) => T[];
    isLoading?: boolean;
    groupBy: GroupBy;
};

export const columnHelper = createColumnHelper<PositionsTableRowData | Subrow>();

export const PositionsTable: React.FC<
    TableProps<PositionsTableRowData> & { actionColumn: ColumnDef<any> }
> = ({ getSubRows, data, isLoading, groupBy, actionColumn }) => {
    const memoizedColumns = useMemo(() => {
        const tableColumns = getColumns({ groupBy });

        if (isLoading) {
            return tableColumns.map((column) => ({
                ...column,
                cell: <Skeleton height="16px" />,
            }));
        }

        return [...tableColumns, actionColumn];
    }, [isLoading, actionColumn, groupBy]);

    const memoizedData = useMemo(
        () => (isLoading ? Array(SKELETON_COUNT).fill({}) : data),
        [data, isLoading],
    );
    const [expanded, setExpanded] = useState<ExpandedState>(true);

    const table = useReactTable({
        data: memoizedData,
        columns: memoizedColumns as ColumnDef<PositionsTableRowData, any>[],
        state: {
            expanded,
        },
        onExpandedChange: setExpanded,
        getSubRows: getSubRows,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
    });

    const isEmpty = !memoizedData.length && !isLoading;

    const handleRowClick = (row: Row<any>, id: string) => {
        if (id === ACTIONS_TABLE_COLUMN_KEY) {
            return;
        }

        const isExpandable = row.getCanExpand();

        if (isExpandable) {
            row.toggleExpanded();
        }
    };

    return <TableView table={table} onRowClick={handleRowClick} isEmpty={isEmpty} condensed />;
};
