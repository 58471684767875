import { Icons, PSmallBold } from "@fm-frontend/uikit";
import React from "react";
import { useTheme } from "styled-components";
import { StatusKYBVariant } from "../../../store/onboardingSlice";
import { Status } from "./styled";

export const LimitStatus: React.VFC<{ statusKYB: StatusKYBVariant; isCpLimitSet: boolean }> = ({
    statusKYB,
    isCpLimitSet,
}) => {
    const { colors } = useTheme();

    if (!isCpLimitSet && statusKYB === StatusKYBVariant.Started) {
        return (
            <Status color={colors.ui8}>
                <Icons.Attention color={colors.attention100} />
                <PSmallBold color={colors.ui52}>Awaiting your limits</PSmallBold>
            </Status>
        );
    }
    if (isCpLimitSet && statusKYB === StatusKYBVariant.Started) {
        return (
            <Status color={colors.ui8}>
                <Icons.Attention color={colors.attention100} />
                <PSmallBold color={colors.ui52}>Awaiting provider’s limits to unlock the product</PSmallBold>
            </Status>
        );
    }
    if (statusKYB === StatusKYBVariant.Finished) {
        return (
            <Status color={colors.brand8}>
                <PSmallBold color={colors.brand100}>Limits are set</PSmallBold>
            </Status>
        );
    }

    return null;
};
