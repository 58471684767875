import { DateRange as DateRangeComponent, DefinedRange } from "@iroomit/react-date-range";
import { DateRange } from "@iroomit/react-date-range/dist/components/DayCell";
import { endOfDay, isSameDay, startOfDay, subDays, subMonths, subWeeks } from "date-fns";
import { FC, useCallback, useMemo } from "react";
import { DatePickerActions } from "../Actions";
import { DatePickerCalendarContainer } from "../CalendarContainer";

export const createStaticRanges = (ranges: { label: string; range: () => DateRange }[]) =>
    ranges.map(({ label, range }) => ({
        label,
        range,
        isSelected: (selectedRange: DateRange) => {
            const definedRange = range();
            return (
                isSameDay(selectedRange.startDate, definedRange.startDate) &&
                isSameDay(selectedRange.endDate, definedRange.endDate)
            );
        },
    }));

const staticRanges = createStaticRanges([
    {
        label: "Past day",
        range: () => ({
            startDate: startOfDay(subDays(new Date(), 1)),
            endDate: endOfDay(subDays(new Date(), 1)),
        }),
    },
    {
        label: "Past week",
        range: () => ({
            startDate: startOfDay(subWeeks(new Date(), 1)),
            endDate: new Date(),
        }),
    },
    {
        label: "Past month",
        range: () => ({
            startDate: startOfDay(subMonths(new Date(), 1)),
            endDate: new Date(),
        }),
    },
    {
        label: "Past 6 month",
        range: () => ({
            startDate: startOfDay(subMonths(new Date(), 6)),
            endDate: new Date(),
        }),
    },
]);

type DatePickerCalendarProps = {
    startDate: Date | null | undefined;
    endDate?: Date | null | undefined;
    minDate?: Date;
    maxDate?: Date;
    onChange?: ({ startDate, endDate }: { startDate: Date | undefined; endDate: Date | undefined }) => void;
    onConfirm?: () => void;
    onReset?: () => void;
};

export const DatePickerCalendar: FC<DatePickerCalendarProps> = ({
    startDate,
    endDate,
    minDate = new Date("2018-01-01"),
    maxDate = new Date(),
    onChange,
    onConfirm,
    onReset,
}) => {
    const ranges = useMemo(() => [{ key: "selection", autoFocus: true, startDate, endDate }], [startDate, endDate]);
    const handleChange = useCallback(
        (range) =>
            onChange?.({
                startDate: range.selection.startDate,
                endDate: endOfDay(range.selection.endDate),
            }),
        [onChange],
    );

    return (
        <DatePickerCalendarContainer>
            <DefinedRange
                // @ts-ignore   Type error: startDate/endDate can be null & undefined
                ranges={ranges}
                onChange={handleChange}
                staticRanges={staticRanges}
                inputRanges={[]}
            />
            <DateRangeComponent
                initialFocusedRange={[0, 0]}
                // @ts-ignore   Type error: startDate/endDate can be null & undefined
                ranges={ranges}
                onChange={handleChange}
                months={2}
                direction="horizontal"
                editableDateInputs
                dateDisplayFormat="dd.MM.yyyy"
                weekdayDisplayFormat="EEEEEE"
                weekStartsOn={1}
                minDate={minDate}
                maxDate={maxDate}
            />
            <DatePickerActions onConfirm={onConfirm} onReset={onReset} />
        </DatePickerCalendarContainer>
    );
};
