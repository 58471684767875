import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { Mark } from "../common";
import { PSmall } from "../typography";
import { Size } from "./types";
import { useTabContext } from "./utils";

const Title = styled(PSmall)<{ isActive?: boolean }>`
    color: ${(p) => p.theme.colors.ui72};
    font-weight: normal;

    ${(p) =>
        p.isActive &&
        css`
            color: ${p.theme.colors.ui100};
            font-weight: bold;
        `}
`;

const Container = styled.div<{ size: Size; disabled: boolean; isActive?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    cursor: pointer;
    border-radius: 8px;

    color: ${({ isActive, theme: { colors } }) => (isActive ? colors.ui100 : colors.ui72)};
    background-color: ${({ isActive, theme: { colors } }) =>
        isActive ? colors.uiWhite100 : "transparent"};

    ${Title} {
        margin: 0;
    }

    ${(p) =>
        p.size === "small" &&
        css`
            padding: 4px 8px;
        `}

    ${(p) =>
        p.isActive &&
        css`
            box-shadow: 0px 1px 2px 0px rgba(36, 48, 52, 0.04),
                0px 0px 0px 1px rgba(36, 48, 52, 0.12) inset,
                0px -1px 0px 0px rgba(36, 48, 52, 0.12) inset;

            ${Mark} {
                font-weight: 600;
            }
        `}

    ${(p) =>
        p.size === "medium" &&
        css`
            padding: 8px;
        `}

    ${(p) =>
        p.size === "large" &&
        css`
            height: 36px;
        `}

    ${(p) =>
        p.disabled &&
        css`
            ${Title} {
                color: ${p.theme.colors.ui52};
            }
            cursor: not-allowed;
        `}
`;

type TabProps = {
    title: string | ReactNode;
    value: string;
    size?: Size;
    disabled?: boolean;
    className?: string;
    isAvailable?: boolean;
};

export const Tab: React.FC<TabProps> = (props) => {
    const { value, size = "medium", disabled = false, className, isAvailable = true } = props;
    const context = useTabContext();
    const isActive = context?.value === value;

    if (!isAvailable) {
        return null;
    }

    return (
        <Container
            className={className}
            disabled={disabled}
            size={size}
            isActive={isActive}
            onClick={() => {
                if (disabled) {
                    return;
                }

                context?.handleClick?.(value);
            }}
        >
            <Title isActive={isActive}>{props.title}</Title>
        </Container>
    );
};
