import { SIZE_VALUE_MULTIPLICATOR } from "@fm-frontend/utils";

export const calculateEquivalentVolumeOrSize = ({
    totalVolumeOrSize,
    usdPrice = 0n,
}: {
    totalVolumeOrSize: bigint | null;
    usdPrice: bigint | undefined;
}) => {
    if (totalVolumeOrSize === null || usdPrice === undefined) {
        return null;
    }

    return (totalVolumeOrSize * usdPrice) / BigInt(SIZE_VALUE_MULTIPLICATOR);
};
