import { InfiniteTable } from "@fm-frontend/uikit";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { BottomFixTableContainer } from "components/BottomFixTableContainer";
import { memo } from "react";
import styled from "styled-components";
import { columns } from "./table";
import { RowData } from "./types";

const StyledBottomFixTableContainer = styled(BottomFixTableContainer)`
    display: flex;
    flex-direction: column;
`;

export const WhitelistingInstrumentsTable = memo(
    ({ isLoading, instrumentsData }: { isLoading: boolean; instrumentsData: RowData[] }) => {
        return (
            <StyledBottomFixTableContainer>
                <InfiniteTable
                    tableOptions={{
                        data: instrumentsData,
                        columns: columns as ColumnDef<RowData, any>[],
                        getCoreRowModel: getCoreRowModel(),
                    }}
                    isLoading={isLoading}
                />
            </StyledBottomFixTableContainer>
        );
    },
);
