import { ClientType, CounterpartyLimit, CounterpartyLimitByAsset } from "types";
import { getAppliedLimit } from "./counterparty/utils";

export const LIMIT_FORMAT_REGEX = /^[0-9]{1,9}(\.[0-9]{1,8})?$/;
export const COUNTERPARTY_ID_FORMAT_REGEX = /^[0-9]{1,9}?$/;
export const PERCENT_FORMAT_REGEX = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;
export const PERCENT_DECIMAL_PRECISION_REGEX = /^-?\d+(\.\d{1,4})?$/;

export const MODAL_ENTER_DELAY = 75;
export const MODAL_EXIT_DELAY = 75;

export const MARGIN_PLACEHOLDER = "00.00";

export const ONE_HUNDRED_PERCENT = 100;
export const PERCENT_SHIFT = 1e4;

const TRADING_DISABLED_BY_CLIENT = 1;
const TRADING_DISABLED_BY_CP = 2;
const TRADING_DISABLED_BY_BOTH = 3;
export enum LimitTradingStatus {
    ENABLED,
    DISABLED_BY_CLIENT,
    DISABLED_BY_CP,
    DISABLED_BY_BOTH,
}

export const bitmaskToTradingStatus = (bitMask: number) => {
    if ((bitMask & TRADING_DISABLED_BY_BOTH) === TRADING_DISABLED_BY_BOTH) {
        return LimitTradingStatus.DISABLED_BY_BOTH;
    }
    if (bitMask & TRADING_DISABLED_BY_CP) {
        return LimitTradingStatus.DISABLED_BY_CP;
    }
    if (bitMask & TRADING_DISABLED_BY_CLIENT) {
        return LimitTradingStatus.DISABLED_BY_CLIENT;
    }

    return LimitTradingStatus.ENABLED;
};

export const parseLimit = (limit: CounterpartyLimit) => {
    const [
        counterpartyId,
        currency,
        grossLimit,
        mutualLimitCurrencyName,
        mutualGrossLimit,
        grossLimitUtilization,
        maintenanceMargin,
        restrictedTrading,
        initialMargin,
        equity,
        markupValue,
        tradingDisabled,
    ] = limit;

    return {
        counterpartyId,
        currency,
        grossLimit: BigInt(grossLimit),
        mutualLimitCurrencyName,
        mutualGrossLimit: mutualGrossLimit !== null ? BigInt(mutualGrossLimit) : null,
        grossLimitUtilization: BigInt(grossLimitUtilization),
        maintenanceMargin,
        restrictedTrading,
        initialMargin,
        equity: BigInt(equity),
        markupValue,
        tradingStatus: bitmaskToTradingStatus(tradingDisabled),
    };
};

export const parseLimitByAsset = (limit: CounterpartyLimitByAsset) => {
    const [counterpartyId, asset, grossLimit, limitCurrency] = limit;

    return {
        counterpartyId,
        asset,
        grossLimit: BigInt(grossLimit ?? 0),
        limitCurrency,
    };
};

export enum CounterpartyStatusType {
    NORMAL = 1,
    MARGIN_CALL = 2,
    LOW_GROSS_FREE = 4,
    RESTRICTED_TRADING = 8,
    STOPPED_BY_CP = 16,
    STOPPED = 32,
    LIQUIDATION = 64,
    AWAITING_CP = 128,
}

export enum CounterpartyStatusGroupType {
    CRITICAL,
    LOW_LIMITS,
    AWAITING,
    NORMAL,
    STOPPED,
}

export const CounterpartyStatusGroup = {
    [CounterpartyStatusGroupType.CRITICAL]: {
        title: "Critical",
        status: [CounterpartyStatusType.RESTRICTED_TRADING, CounterpartyStatusType.LIQUIDATION],
    },
    [CounterpartyStatusGroupType.LOW_LIMITS]: {
        title: "Low limits",
        status: [CounterpartyStatusType.MARGIN_CALL, CounterpartyStatusType.LOW_GROSS_FREE],
    },
    [CounterpartyStatusGroupType.AWAITING]: {
        title: "Awaiting",
        status: [CounterpartyStatusType.AWAITING_CP],
    },
    [CounterpartyStatusGroupType.STOPPED]: {
        title: "Stopped",
        status: [CounterpartyStatusType.STOPPED, CounterpartyStatusType.STOPPED_BY_CP],
    },
    [CounterpartyStatusGroupType.NORMAL]: {
        title: "Normal",
        status: [CounterpartyStatusType.NORMAL],
    },
} as Record<CounterpartyStatusGroupType, { title: string; status: CounterpartyStatusType[] }>;

export const CounterpartyStatus = {
    [CounterpartyStatusType.NORMAL]: {
        title: "Normal",
        titleShort: "Normal",
        actions: [],
    },
    [CounterpartyStatusType.MARGIN_CALL]: {
        title: "Margin Call",
        titleShort: "Margin Call",
        actions: [],
    },
    [CounterpartyStatusType.LOW_GROSS_FREE]: {
        title: "Low Gross Free",
        titleShort: "Low Gross",
        actions: ["Update Gross Limit", "Request a settlement"],
    },
    [CounterpartyStatusType.RESTRICTED_TRADING]: {
        title: "Restricted Trading",
        titleShort: "Restricted",
        actions: [],
    },
    [CounterpartyStatusType.STOPPED_BY_CP]: {
        title: "Stopped by CP",
        titleShort: "Stopped",
        actions: [],
    },
    [CounterpartyStatusType.STOPPED]: {
        title: "Stopped",
        titleShort: "Stopped",
        actions: [],
    },
    [CounterpartyStatusType.LIQUIDATION]: {
        title: "Liquidation",
        titleShort: "Liquidation",
        actions: ["Deposit funds", "Close the position"],
    },
    [CounterpartyStatusType.AWAITING_CP]: {
        title: "Awaiting CP",
        titleShort: "Awaiting",
        actions: [],
    },
};

const checkStatus = (combinedStatus: number) => {
    if ((combinedStatus & CounterpartyStatusType.AWAITING_CP) === CounterpartyStatusType.AWAITING_CP) {
        return CounterpartyStatusType.AWAITING_CP;
    }
    if ((combinedStatus & CounterpartyStatusType.LIQUIDATION) === CounterpartyStatusType.LIQUIDATION) {
        return CounterpartyStatusType.LIQUIDATION;
    }
    if ((combinedStatus & CounterpartyStatusType.STOPPED) === CounterpartyStatusType.STOPPED) {
        return CounterpartyStatusType.STOPPED;
    }
    if ((combinedStatus & CounterpartyStatusType.STOPPED_BY_CP) === CounterpartyStatusType.STOPPED_BY_CP) {
        return CounterpartyStatusType.STOPPED_BY_CP;
    }
    if ((combinedStatus & CounterpartyStatusType.RESTRICTED_TRADING) === CounterpartyStatusType.RESTRICTED_TRADING) {
        return CounterpartyStatusType.RESTRICTED_TRADING;
    }
    if ((combinedStatus & CounterpartyStatusType.LOW_GROSS_FREE) === CounterpartyStatusType.LOW_GROSS_FREE) {
        return CounterpartyStatusType.LOW_GROSS_FREE;
    }
    if ((combinedStatus & CounterpartyStatusType.MARGIN_CALL) === CounterpartyStatusType.MARGIN_CALL) {
        return CounterpartyStatusType.MARGIN_CALL;
    }

    return CounterpartyStatusType.NORMAL;
};

export const getCpStatus = (
    limit: CounterpartyLimit,
    grossFreeStatus: CounterpartyStatusType,
    marginStatus: CounterpartyStatusType,
): CounterpartyStatusType => {
    const { mutualGrossLimit, tradingStatus } = parseLimit(limit);

    let combinedStatus: number = CounterpartyStatusType.NORMAL;

    combinedStatus |= grossFreeStatus;
    combinedStatus |= marginStatus;

    if (mutualGrossLimit === null) {
        combinedStatus |= CounterpartyStatusType.AWAITING_CP;
    }

    if (
        tradingStatus === LimitTradingStatus.DISABLED_BY_BOTH ||
        tradingStatus === LimitTradingStatus.DISABLED_BY_CLIENT
    ) {
        combinedStatus |= CounterpartyStatusType.STOPPED;
    }
    if (tradingStatus === LimitTradingStatus.DISABLED_BY_CP) {
        combinedStatus |= CounterpartyStatusType.STOPPED_BY_CP;
    }

    return checkStatus(combinedStatus);
};

export const getFreeLimit = (grossLimit: bigint, exposure: bigint) => {
    const freeLimit = grossLimit - exposure;
    let freeLimitPercent = 0;

    if (grossLimit !== 0n && freeLimit > 0n) {
        freeLimitPercent = Number((BigInt(ONE_HUNDRED_PERCENT * PERCENT_SHIFT) * freeLimit) / grossLimit);
    }

    return { freeLimit, freeLimitPercent };
};

export const getGrossFreeStatus = (freeLimitPercent: number): CounterpartyStatusType => {
    if (freeLimitPercent >= 10e4) {
        return CounterpartyStatusType.NORMAL;
    }

    return CounterpartyStatusType.LOW_GROSS_FREE;
};

export const getCpGrossFreeStatus = (
    limit: CounterpartyLimit,
    priceObj: Record<string, bigint>,
): CounterpartyStatusType => {
    const { grossLimitUtilization } = parseLimit(limit);

    const { appliedLimit } = getAppliedLimit(limit, priceObj);
    const { freeLimitPercent } = getFreeLimit(appliedLimit, grossLimitUtilization);

    return getGrossFreeStatus(freeLimitPercent);
};
export const convertMutualLimitToGrossLimit = (
    priceObj: Record<string, bigint>,
    mutualLimit: bigint | null,
    mutualLimitCurrency: string | null,
    currency: string,
) => {
    const mutualCurrencyInUsd = BigInt(priceObj[mutualLimitCurrency ?? ""] ?? 0n);
    const grossCurrencyInUsd = BigInt(priceObj[currency]);

    const result = ((mutualCurrencyInUsd * BigInt(1e8)) / grossCurrencyInUsd) * (mutualLimit ?? 0n);

    return result / BigInt(1e8);
};

export const isSubaccountCp = (cpType: ClientType | undefined) =>
    cpType === "subaccountMaker" || cpType === "subaccountTaker";

export const isTakerCp = (cpType: ClientType | undefined | null) => {
    if (!cpType) {
        return false;
    }

    return cpType === "taker";
};

export const shouldUseUserGrossLimit = (limitCpType: ClientType, isMakerUser: boolean) =>
    isSubaccountCp(limitCpType) || limitCpType === "taker" || isMakerUser;

export const getMarginLimitValue = (grossLimit: bigint, margin: number) => {
    return (grossLimit * BigInt(margin)) / BigInt(1e6);
};

export const getEquityStatus = (
    limit: CounterpartyLimit,
    priceObj: Record<string, bigint>,
    shouldTakeUserGrossLimit: boolean,
): CounterpartyStatusType => {
    const { grossLimit, mutualGrossLimit, equity, initialMargin, restrictedTrading, maintenanceMargin } =
        parseLimit(limit);

    const makerGrossLimit = shouldTakeUserGrossLimit ? grossLimit : mutualGrossLimit ?? 0n;

    const isMarginEnabled = Boolean(initialMargin || restrictedTrading || maintenanceMargin);

    if (!isMarginEnabled) {
        return CounterpartyStatusType.NORMAL;
    }

    const takerEquity = shouldTakeUserGrossLimit ? equity * -1n : equity;
    const initialMarginLimit = getMarginLimitValue(makerGrossLimit, initialMargin);
    const restrictedTradingLimit = getMarginLimitValue(makerGrossLimit, restrictedTrading);
    const maintenanceLimit = getMarginLimitValue(makerGrossLimit, maintenanceMargin);

    if (takerEquity < maintenanceLimit) {
        return CounterpartyStatusType.LIQUIDATION;
    }
    if (takerEquity < restrictedTradingLimit) {
        return CounterpartyStatusType.RESTRICTED_TRADING;
    }
    if (takerEquity < initialMarginLimit) {
        return CounterpartyStatusType.MARGIN_CALL;
    }

    return CounterpartyStatusType.NORMAL;
};
