import { H2, ShadowCard, TextSmall } from "@fm-frontend/uikit";
import { TEST_ALERT_HEIGHT } from "components/TestEnvAlert";
import { onMobile } from "const";
import { IS_TEST_ENV } from "const/env";
import { selectors } from "style/theme";
import styled, { css } from "styled-components";

export const BlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0 !important;

    overflow: clip !important;
`;

export const ActionsBar = styled(ShadowCard)`
    position: sticky;
    top: ${IS_TEST_ENV ? 11 + TEST_ALERT_HEIGHT : 11}px;
    z-index: 1;

    box-shadow: 0 1px 2px 0 rgba(36, 48, 52, 0.04), 0 0 0 1px rgba(36, 48, 52, 0.12) inset,
        0 -1px 0 0 rgba(36, 48, 52, 0.12) inset, 0 4px 12px 4px #f5f5f5, 0 -20px 0 0 #f5f5f5;

    flex-direction: column;
    padding: 12px;
    gap: 12px;
`;

export const Label = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    ${H2} {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        margin: 0;

        :nth-child(2) {
            color: ${(p) => p.theme.colors.action};
        }
    }
`;

export const SidebarContainer = styled.aside`
    position: sticky;
    top: ${IS_TEST_ENV ? 66 + TEST_ALERT_HEIGHT : 66}px;

    max-width: 278px;
    min-width: 278px;
    height: fit-content;

    ${onMobile} {
        max-width: unset;
    }

    & > * + * {
        margin-top: 12px;
    }
`;

export const GroupSwitch = styled.label<{ disabled?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 44px;

    padding: 12px;

    background: ${(p) => p.theme.colors.ui8};
    border-radius: 8px;

    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

    ${TextSmall} {
        color: ${(p) => p.theme.colors.textBody1};
    }
`;

export const FormError = styled.div`
    position: relative;
    margin: 8px 0;

    ${selectors.mixinBody2}

    color: ${(p) => p.theme.colors.error};
    text-align: center;
`;

export const FieldGrouper = styled.div<{ isExpanded?: boolean; fullWidth?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 1px;

    ${({ isExpanded }) =>
        isExpanded &&
        css`
            *[data-field-group] {
                border-radius: unset;
            }
            > :first-child[data-field-group],
            > :first-child *[data-field-group] {
                border-radius: 8px 8px 0 0;
            }
            > :last-child[data-field-group],
            > :last-child *[data-field-group] {
                border-radius: 0 0 8px 8px;
            }
            > :only-child[data-field-group],
            > :only-child *[data-field-group] {
                border-radius: 8px;
            }
        `}

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            width: 100%;
        `}
`;
