import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, delay, put, race, SagaReturnType, select, take, takeLatest } from "@redux-saga/core/effects";
import {
    getNotifications,
    getUnreadNotificationsCount,
    markAllNotificationsAsRead,
    markNotificationsAsRead,
} from "services/notificationsService";
import { IRootState } from "store";
import { setIsTermsAndConditionsRequired, setOnboardingStages } from "store/onboardingSlice";
import { getNotificationPayload } from "./NotificationContent/utils";
import {
    INIT_UNREAD_COUNT,
    notificationsAdd,
    notificationsFetchCountRequest,
    notificationsFetchRequest,
    NotificationsFetchRequestAction,
    notificationsLoadMore,
    notificationsRead,
    notificationsReadAll,
    notificationsUnreadCountSet,
    notificationsReadAllRequest,
    notificationsReadRequest,
    NotificationsReadRequestAction,
    NotificationsState,
} from "./notifications.store";
import { AppState, createNotification, setIsActive } from "../app";

const FETCH_NOTIFICATIONS_DELAY = 20_000; // 20 sec
const FETCH_ELEMENTS_COUNT = 20;

function* fetchNotificationsCount() {
    try {
        const { count }: SagaReturnType<typeof getUnreadNotificationsCount> = yield call(getUnreadNotificationsCount);
        yield put(notificationsUnreadCountSet({ count }));
        return count;
    } catch (e) {
        console.error("Fetch notifications count error:", e);
        yield put(notificationsUnreadCountSet({ count: 0 }));
        return 0;
    }
}

type FetchNotificationsOptions = {
    showPopUp?: boolean;
};

function* fetchNotifications(page = 1, options?: FetchNotificationsOptions) {
    try {
        const { notifications: prevNotifications } = (yield select((state) => state.notifications)) as NotificationsState;
        const { showPopUp = false } = options || {};

        const { totalElements, elementsOnPage, currentPage,  data: notifications }: SagaReturnType<typeof getNotifications> = yield call(
            getNotifications,
            {
                page,
                elementsOnPage: FETCH_ELEMENTS_COUNT,
            },
        );
        yield put(notificationsAdd({
            totalElements,
            elementsOnPage,
            currentPage,
            notifications,
        }));

        const { isInboxOpen } = (yield select((state) => state.app)) as AppState;

        if (showPopUp && !isInboxOpen) {
            for (const nextNotification of notifications) {
                const isNew = prevNotifications.every((prevNotification) => prevNotification.id !== nextNotification.id);

                if (isNew) {
                    yield put(createNotification(getNotificationPayload(nextNotification)));
                }
            }
        }
    } catch (e) {
        console.error("Fetch notifications error:", e);
    }
}

function* waitOnboarding() {
    const { isTermsAndConditionsRequired } = yield select((state) => state.onboarding.onboardingStages);

    if (isTermsAndConditionsRequired) {
        yield take([setOnboardingStages, setIsTermsAndConditionsRequired]);
    }
}

function* observeNotifications() {
    yield call(waitOnboarding);
    yield call(fetchNotificationsCount);
    yield call(fetchNotifications);

    while (true) {
        yield call(waitOnboarding);

        const { unreadCount: prevUnreadCount } = (yield select((state) => state.notifications)) as NotificationsState;
        const nextUnreadCount: SagaReturnType<typeof fetchNotificationsCount> = yield call(fetchNotificationsCount);

        const isInitFetch = prevUnreadCount === INIT_UNREAD_COUNT;
        const hasNewNotifications = prevUnreadCount !== nextUnreadCount;

        if (hasNewNotifications) {
            yield call(fetchNotifications, 1, { showPopUp: !isInitFetch });
        }

        yield race({
            delay: delay(FETCH_NOTIFICATIONS_DELAY),
            active: take(({ type, payload }: any) => {
                return type === setIsActive.toString() && payload === true;
            }),
        });
    }
}

function* handleNotificationsFetchRequest({ payload }: PayloadAction<NotificationsFetchRequestAction>) {
    const { page } = payload;

    yield call(fetchNotifications, page);
}

function* handleNotificationsFetchCountRequest() {
    yield call(fetchNotificationsCount);
}

function* handleNotificationsReadRequest({ payload }: PayloadAction<NotificationsReadRequestAction>) {
    const { ids } = payload;

    try {
        yield put(notificationsRead({ ids }));
        yield call(markNotificationsAsRead, { ids });
    } catch (e) {
        console.error("Mark notifications as `read` error:", e);
    }
}

function* handleNotificationsReadAllRequest() {
    try {
        yield put(notificationsReadAll());
        yield call(markAllNotificationsAsRead);
    } catch (e) {
        console.error("Mark all notifications as `read` error:", e);
    }
}

function* handleNotificationsLoadMore() {
    const { notifications } = yield select((state: IRootState) => state.notifications);
    const notificationsCount = notifications.length;
    const nextPage = Math.floor(notificationsCount / FETCH_ELEMENTS_COUNT) + 1;

    try {
        yield call(fetchNotifications, nextPage);
    } catch (e) {
        console.error("Load more` error:", e);
    }
}

export function* notificationsSaga() {
    yield all([
        call(observeNotifications),
        takeLatest(notificationsFetchRequest, handleNotificationsFetchRequest),
        takeLatest(notificationsFetchCountRequest, handleNotificationsFetchCountRequest),
        takeLatest(notificationsReadRequest, handleNotificationsReadRequest),
        takeLatest(notificationsReadAllRequest, handleNotificationsReadAllRequest),
        takeLatest(notificationsLoadMore, handleNotificationsLoadMore),
    ]);
}
