import { forwardRef } from "react";
import { useWidth } from "../../hooks";
import { getClickHelper } from "../helper";
import { InputProps } from "./Input.types";
import * as Styled from "./styled";

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const { customType = "complete", label, labelHelper, error, ...rest } = props;
    const { offset, ref: labelRef } = useWidth({ label });
    const isError = Boolean(error);
    const isComplete = customType === "complete";
    const isSimple = customType === "simple";
    const appliedOffset = isSimple ? offset : undefined;

    return (
        <Styled.Container>
            {isComplete && label && <Styled.Label>{label}</Styled.Label>}
            <Styled.InputContainer data-field-group isError={isError}>
                {isSimple && label && (
                    <Styled.InnerLabel ref={labelRef}>
                        {label}
                        {getClickHelper(labelHelper)}
                    </Styled.InnerLabel>
                )}
                <Styled.Input offset={appliedOffset} customType={customType} ref={ref} {...rest} />
            </Styled.InputContainer>
            {isError && <Styled.Error>{error}</Styled.Error>}
        </Styled.Container>
    );
});
