import { signIn as amplifySignIn, type SignInOutput } from "aws-amplify/auth";

type SignInParams = {
    email: string;
    password: string;
};

export const signIn = async (signInParams: SignInParams): Promise<SignInOutput> => {
    return amplifySignIn({
        username: signInParams.email,
        password: signInParams.password,
    });
};
