import { SEARCH_PARAMS } from "const";
import { useFilterState } from "hooks/useFilterState";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { useClientOptionsForAuthorizedTrading } from "./useClientOptionsForAuthorizedTrading";

const PAGE_FILTER_KEY = "trading-firm-book";

export type AuthorizedClientId = number | null;

export const useAuthorizedClientId = () => {
    const { clientOptionsForAuthorizedTrading } = useClientOptionsForAuthorizedTrading();
    const history = useHistory();
    const [state, setState, deleteState] = useFilterState<string>(
        PAGE_FILTER_KEY,
        SEARCH_PARAMS.authorizedClientId,
        "",
        {
            availableValues: clientOptionsForAuthorizedTrading.map((x) => String(x.value)),
        },
    );

    useEffect(() => {
        if (!state && clientOptionsForAuthorizedTrading.length !== 0) {
            setState(String(clientOptionsForAuthorizedTrading[0].value));
        }
    }, [clientOptionsForAuthorizedTrading, state, setState]);

    const updateQueryParams = useCallback(() => {
        const queryParams = new URLSearchParams(history.location.search);
        for (let param of queryParams.keys()) {
            if (param.includes(state)) {
                queryParams.delete(param);
            }
        }

        history.push({ search: queryParams.toString() });
    }, [history, state]);

    const setAuthorizedClientId = useCallback(
        (newAuthorizedClientId: number) => {
            updateQueryParams();
            setState(String(newAuthorizedClientId));
        },
        [setState, updateQueryParams],
    );

    const deleteAuthorizedClientId = useCallback(
        (replace?: boolean | undefined) => {
            updateQueryParams();
            deleteState(replace);
        },
        [deleteState, updateQueryParams],
    );

    return {
        authorizedClientId: (/^\d+$/.test(state) ? Number(state) : null) as AuthorizedClientId,
        setAuthorizedClientId,
        deleteAuthorizedClientId,
    };
};
