import { H2, HStack } from "@fm-frontend/uikit";
import { createContext, FC, useContext, useLayoutEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useIsMakerUser, useIsPrimeBrokerUser, useUserType } from "store/hooks";
import styled from "styled-components";
import { ROUTES } from "feature/app/router";
import { useUrlWithViewType } from "hooks/useViewTypeUrl";
import { noop } from "utils";
import { isSubaccountCp } from "../../counterparties/utils";

type MenuValue = "assets" | "instruments" | "whitelisting";

const Tab = styled(H2)<{ isActive?: boolean }>`
    cursor: pointer;
    color: ${(p) => (p.isActive ? p.theme.colors.ui100 : p.theme.colors.ui32)};

    :hover {
        color: ${(p) => p.theme.colors.ui100};
    }
`;

const AssetControlMenuContext = createContext<{
    selectedTab: MenuValue;
    setSelectedTab: (tab: MenuValue) => void;
}>({
    selectedTab: "assets",
    setSelectedTab: noop,
});

export const useSelectedAssetControlPage = () => useContext(AssetControlMenuContext).selectedTab;

export const MainTabsContextProvider: FC = ({ children }) => {
    const history = useHistory();
    const urlWithViewType = useUrlWithViewType();
    const { tab: selectedTab } = useParams<{ tab: MenuValue }>();

    const setSelectedTab = (newTab: MenuValue) => {
        history.replace(urlWithViewType(`${ROUTES.assetsControl}/${newTab}`));
    };

    useLayoutEffect(() => {
        if (selectedTab === undefined) {
            history.replace(urlWithViewType(`${ROUTES.assetsControl}/assets`));
        }
    }, [history, selectedTab]);

    return (
        <AssetControlMenuContext.Provider value={{ selectedTab, setSelectedTab }}>
            {children}
        </AssetControlMenuContext.Provider>
    );
};

export const AssetControlMenu = ({ onAfterChange }: { onAfterChange?: () => void }) => {
    const { selectedTab, setSelectedTab } = useContext(AssetControlMenuContext);

    const userType = useUserType();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const isMakerUser = useIsMakerUser();

    const isInstrumentsTabAvailable =
        !isSubaccountCp(userType) && (isPrimeBrokerUser || isMakerUser);

    const handleChange = (tab: MenuValue) => {
        setSelectedTab(tab);
        onAfterChange?.();
    };

    return (
        <HStack flex={1} spacing={16}>
            <Tab
                isActive={selectedTab === "assets"}
                key="assets"
                onClick={() => handleChange("assets")}
            >
                Assets
            </Tab>

            {isInstrumentsTabAvailable && (
                <Tab
                    isActive={selectedTab === "instruments"}
                    key="instruments"
                    onClick={() => handleChange("instruments")}
                >
                    Markups
                </Tab>
            )}
            <Tab
                isActive={selectedTab === "whitelisting"}
                key="whitelisting"
                onClick={() => handleChange("whitelisting")}
            >
                Whitelisting
            </Tab>
        </HStack>
    );
};
