import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { post } from "../api";
import { setTakerInfo } from "../store/onboardingSlice";
import { TakerInfoRequest } from "../types";
import { useSelector } from "./useSelector";

export const useTakerInfo = () => {
    const dispatch = useDispatch();
    const takerInfo = useSelector((state) => state.onboarding.takerInfo);

    const updateTakerInfo = useCallback((takerInfo: TakerInfoRequest) => {
        return post("onboarding/setTakerInfo", takerInfo).then((takerInfo) => {
            dispatch(setTakerInfo(takerInfo));
        });
    }, []);

    return {
        takerInfo,
        updateTakerInfo,
    };
};
