import { H3 } from "@fm-frontend/uikit";
import styled, { css } from "styled-components";

export const Li = styled.li<{ content?: string }>`
    margin-bottom: 12px;
    position: relative;
    color: ${(p) => p.theme.colors.ui52};

    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    letter-spacing: -0.005em;

    a {
        font-weight: 600;
        color: ${(p) => p.theme.colors.brand100};
    }

    &::marker {
        list-style-type: none;
        content: "";
        color: transparent;
    }
    &:before {
        ${({ content }) => {
            if (content)
                return css`
                    content: "${content}. ";
                    position: absolute;
                    right: 100%;
                    margin-right: 8px;
                `;
            return css``;
        }}
    }
`;

export const PrimaryOL = styled.ol`
    padding-inline-start: 12px;
    margin: 0;

    ::marker {
        font-weight: 600;
        color: ${(p) => p.color}!important;
    }
`;
export const HeaderLI = styled.li`
    color: ${(p) => p.theme.colors.ui52};
    ${H3} {
        margin: 0;
    }
`;
export const HeaderLIBold = styled.li`
    color: ${(p) => p.theme.colors.ui100};
    font-weight: 600;
    ${H3} {
        margin: 0;
    }
`;
