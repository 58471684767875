import { Type } from "./useForm";

export const CommonFieldTypes = {
    password: {
        placeholder: "************",
        props: {
            type: "password",
        },
    },
    passwordValidation: {
        placeholder: "************",
        type: "password" as const,
        props: {
            type: "password",
        },
    },
    email: { placeholder: "trader@example.com", type: "email" as const },
};

export const getPasswordField = ({
    placeholder = "************",
    validtion,
    autoComplete,
    typeValidate,
}: {
    placeholder?: string;
    validtion?: true;
    autoComplete?: "new-password" | "current-password";
    typeValidate?: "onchange" | undefined;
} = {}) => {
    const type: Type = validtion ? "password" : "string";
    return {
        placeholder,
        type,
        props: {
            type: "password",
            autoComplete,
        },
        typeValidate,
    };
};

export const CommonValidation = {
    passwordsMatch: (values: any) => {
        if (values.newPasswordRepeat && values.newPassword !== values.newPasswordRepeat)
            return { newPasswordRepeat: "Passwords do not match" };

        const isNotEmptyNewPassword = Boolean(values.newPassword);
        const isNotEmptyNewPasswordRepeat = Boolean(values.newPasswordRepeat);

        if (!isNotEmptyNewPassword || !isNotEmptyNewPasswordRepeat)
            return { newPasswordRepeat: "Fields cannot be empty" };

        return true;
    },
    passwordsMatchNonEmptyCompanyName: (values: any) => {
        const isNotEmptyCompanyName = Boolean(values.name);
        if (!isNotEmptyCompanyName) return { name: "Company name cannot be empty" };

        return CommonValidation.passwordsMatch(values);
    },
};
