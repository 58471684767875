import { PSmall, PXSmall, VStack } from "@fm-frontend/uikit";
import styled from "styled-components";

const Container = styled(VStack)`
    padding: 0 12px;
    text-align: left;
`;

const StyledPSmall = styled(PSmall)`
    min-height: 16px;
`;

const StyledPXSmall = styled(PXSmall)`
    min-height: 12px;
`;

export const TwoRowsHeader = ({ title, subtitle }: { title?: string; subtitle: string }) => (
    <Container>
        <StyledPSmall>{title}</StyledPSmall>
        <StyledPXSmall>{subtitle}</StyledPXSmall>
    </Container>
);
