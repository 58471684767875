import { P, PBold, PrimaryButton, ShadowCard, ShadowItem } from "@fm-frontend/uikit";
import { useSelector } from "hooks";
import React from "react";
import { useDispatch } from "react-redux";
import { setIsLpsRequired, useIsOnboardingRequired } from "store/onboardingSlice";
import styled, { css, useTheme } from "styled-components";

const Container = styled(ShadowCard)<{ inline?: boolean }>`
    z-index: 1;
    position: fixed;
    bottom: 24px;
    right: 16px;
    width: 320px;
    padding-top: 154px;
    background: white url("/img/welcome-to-product-bg.png") no-repeat top;
    background-size: 100%;

    ${(p) =>
        p.inline &&
        css`
            position: unset;
            width: 100%;
        `}
`;

const Content = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`;

export const WelcomeToProduct: React.VFC = () => {
    const dispatch = useDispatch();
    const { colors } = useTheme();
    const { isMobile } = useSelector((state) => state.app);
    const isOnboardingRequired = useIsOnboardingRequired();

    const handleToProductClick = () => {
        dispatch(setIsLpsRequired(false));
    };

    if (!isOnboardingRequired) {
        return null;
    }

    return (
        <Container isDefault inline={isMobile}>
            <ShadowItem padding="0 12px">
                <Content>
                    <PBold>You're all set!</PBold>
                    <P color={colors.ui52}>Verification is completed.</P>
                    <P color={colors.ui52}>
                        Start trading on our venue and continue verification with rest providers later.
                    </P>
                </Content>
            </ShadowItem>
            <ShadowItem padding="16px 12px">
                <PrimaryButton size="large" fullWidth onClick={handleToProductClick}>
                    Start trading
                </PrimaryButton>
            </ShadowItem>
        </Container>
    );
};
