import { post } from "api";
import { deleteSettlementRequest } from "feature/settlements/api";
import { Settlement } from "feature/settlements/types";
import { useOpenConfirmationModal } from "hooks";
import { useState } from "react";
import { displayError } from "utils";
import { formatValue } from "utils/EfxTypes";
import { getAbsoluteValue } from "utils/format";
import { parse, stringify } from "utils/json";
import { Transaction } from "../types";
import { useWithDiscardDataConfirmationModal } from "../useWithDiscardDataConfirmationModal";
import { CommitTransactionModal } from "./modals/CommitTransactionModal";
import {
    CreatedTransactionFormData,
    CreateTransactionModal,
    TransactionFormData,
} from "./modals/CreateTransactionModal";
import { DeleteSettlementModalBody } from "./modals/DeleteSettlementModalBody";
import { ReceivedDepositInfoModal } from "./modals/ReceivedDepositInfoModal";

export type BaseRequest = {
    requestId: number;
};

type DefaultActionsValues = {
    openedTab: Transaction["type"];
    defaultFormValues: Partial<TransactionFormData>;
    baseRequest?: BaseRequest;
};
const DEFAULT_CREATE_TRANSACTION_SESSION_STORAGE_KEY =
    "subaccountsViewTransactionsPageDefaultCreateTransactionState";
export const setDefaultCreateTransactionState = (values: DefaultActionsValues) => {
    sessionStorage.setItem(DEFAULT_CREATE_TRANSACTION_SESSION_STORAGE_KEY, stringify(values));
};
export const clearDefaultCreateTransactionState = () => {
    sessionStorage.removeItem(DEFAULT_CREATE_TRANSACTION_SESSION_STORAGE_KEY);
};
const getDefaultCreateTransactionValues = (): DefaultActionsValues => {
    const preloadedFormValues = sessionStorage.getItem(
        DEFAULT_CREATE_TRANSACTION_SESSION_STORAGE_KEY,
    );
    return preloadedFormValues ? parse(preloadedFormValues) : undefined;
};

export const useActions = () => {
    const openConfirmationModal = useOpenConfirmationModal();
    const defaultValues = getDefaultCreateTransactionValues();
    const [baseRequest, setBaseRequest] = useState<BaseRequest | undefined>(
        defaultValues?.baseRequest,
    );
    const withDiscardDataConfirmationModal = useWithDiscardDataConfirmationModal();
    const [depositInfoModalData, setDepositInfoModalData] = useState<Settlement | null>(null);
    const [transactionSettings, setTransactionSettings] = useState<
        Partial<TransactionFormData> | undefined
    >(defaultValues?.defaultFormValues);
    const [visibleSkipForLaterButton, setVisibleSkipForLaterButton] = useState(true);
    const [transactionType, setTransactionType] = useState<Transaction["type"] | null>(
        defaultValues?.openedTab ?? null,
    );
    const [commitTransactionModalData, setCommitTransactionModalData] =
        useState<CreatedTransactionFormData | null>(null);

    const deleteSettlement = (settlement: Settlement) => {
        openConfirmationModal({
            title: settlement.status === "request" ? "Delete request" : "Delete transaction",
            description: <DeleteSettlementModalBody settlement={settlement} />,
            confirmButtonTitle: "Yes, delete",
            onConfirm: () => {
                if (settlement.status === "request") {
                    deleteSettlementRequest({
                        requestId: settlement.id,
                    }).catch(displayError);
                } else {
                    post("delSettlementTransaction", {
                        transactionId: settlement.id,
                    }).catch(displayError);
                }
            },
        });
    };

    const createTransactionByRequest = (request: Settlement) => {
        setTransactionType("withdrawal");
        setTransactionSettings({
            subaccountId: request.cpId,
            asset: request.asset,
            network: request.network,
            amount: formatValue(getAbsoluteValue(request.amount), "size64"),
            shouldRemoveRequest: true,
            isFeePaidByRecipient: request.fee?.payer === "recipient",
        });
        setBaseRequest({
            requestId: request.id,
        });
    };

    const clearCreateTransactionModalData = () => {
        setTransactionType(null);
        setTransactionSettings(undefined);
        setBaseRequest(undefined);
    };

    return {
        commitDeposit: setDepositInfoModalData,
        createTransactionByRequest,
        createTransaction: setTransactionType,
        autoCommitTransaction: (settings: CreatedTransactionFormData) => {
            if (settings.id === undefined) {
                return;
            }
            setVisibleSkipForLaterButton(false);
            setCommitTransactionModalData(settings);
        },
        deleteSettlement,
        actionsContent: (
            <>
                {depositInfoModalData && (
                    <ReceivedDepositInfoModal
                        closeModal={() => setDepositInfoModalData(null)}
                        deposit={depositInfoModalData}
                    />
                )}
                {transactionType && (
                    <CreateTransactionModal
                        closeModal={withDiscardDataConfirmationModal(() => {
                            clearCreateTransactionModalData();
                        })}
                        onContinueClick={(formData: CreatedTransactionFormData) => {
                            clearCreateTransactionModalData();
                            setCommitTransactionModalData(formData);
                        }}
                        transactionType={transactionSettings?.type ?? transactionType}
                        defaultValues={transactionSettings}
                        baseRequest={baseRequest}
                    />
                )}
                {commitTransactionModalData && (
                    <CommitTransactionModal
                        transactionSettings={commitTransactionModalData}
                        visibleSkipForLaterButton={visibleSkipForLaterButton}
                        closeModal={() => {
                            setVisibleSkipForLaterButton(true);
                            setCommitTransactionModalData(null);
                        }}
                    />
                )}
            </>
        ),
    };
};
