import { Title as SettingTitle } from "pages/settings/styled";
import styled from "styled-components";

export const Title = styled(SettingTitle)`
    border-bottom: none;
    padding-bottom: 12px;
`;

export const Description = styled.div`
    color: ${(p) => p.theme.colors.ui72};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.005em;
`;

export const Settings = styled.div`
    margin-top: 30px;
`;

export const EventsSettingsTable = styled.table`
    border-collapse: separate;
    border-spacing: 0 1px;
    width: 100%;

    .col-checkbox {
        width: 45px;
    }

    th {
        color: ${(p) => p.theme.colors.black};
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.04em;
        padding-bottom: 5px;

        &:first-child {
            font-size: 11px;
            text-align: left;
        }
        &:last-child {
            padding-right: 6px;
        }
    }

    tbody tr {
        color: ${(p) => p.theme.colors.ui72};
        margin-bottom: 12px;
        height: 44px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.005em;

        &:first-child {
            td:first-child {
                border-top-left-radius: 8px;
            }
            td:last-child {
                border-top-right-radius: 8px;
            }
        }
        &:last-child {
            td:first-child {
                border-bottom-left-radius: 8px;
            }
            td:last-child {
                border-bottom-right-radius: 8px;
            }
        }

        td {
            background: ${(p) => p.theme.colors.ui8};
            padding: 0;

            &:first-child {
                padding-left: 12px;
            }
            &:last-child {
                padding-right: 6px;
            }
            &:not(:first-child) {
                text-align: center;
            }
        }
    }
`;

export const InteractionsSettings = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 30px;
    font-size: 11px;
    color: ${(p) => p.theme.colors.black};
`;
