import { MultipleDropdown, OPTIONS_SEARCH_COUNT, SingleDropdown } from "../index";
import { DropdownOption, SheetSize } from "../types";

type BasicSheetProps<T> = {
    size: SheetSize;
    options: DropdownOption<T>[];
    columns?: number;
    Dropdown: typeof SingleDropdown | typeof MultipleDropdown;
};

const DropdownSheet = <T,>({ size, options, columns, Dropdown }: BasicSheetProps<T>) => {
    return (
        <Dropdown.Sheet size={size}>
            {({ query, normalizedQuery, onSearch }) => (
                <>
                    {options.length > OPTIONS_SEARCH_COUNT && <Dropdown.Search query={query} onSearch={onSearch} />}
                    <Dropdown.EmptySearch>
                        {columns === undefined &&
                            options
                                .filter((o) => o.text?.toLowerCase().includes(normalizedQuery))
                                .map((option) => (
                                    <Dropdown.Option key={`${option.value}`} value={option.value}>
                                        <Dropdown.OptionEssence option={option} query={query} />
                                    </Dropdown.Option>
                                ))}
                        {columns !== undefined && (
                            <Dropdown.SheetGroup columns={columns}>
                                {options
                                    .filter((o) => o.text?.toLowerCase().includes(normalizedQuery))
                                    .map((option) => (
                                        <Dropdown.Option key={`${option.value}`} value={option.value}>
                                            <Dropdown.OptionEssence option={option} query={query} />
                                        </Dropdown.Option>
                                    ))}
                            </Dropdown.SheetGroup>
                        )}
                    </Dropdown.EmptySearch>
                </>
            )}
        </Dropdown.Sheet>
    );
};

export const SingleDropdownSheet = <T,>(props: Omit<BasicSheetProps<T>, "Dropdown">) => (
    <DropdownSheet {...props} Dropdown={SingleDropdown} />
);
export const MultipleDropdownSheet = <T,>(props: Omit<BasicSheetProps<T>, "Dropdown">) => (
    <DropdownSheet {...props} Dropdown={MultipleDropdown} />
);
