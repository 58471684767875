type UpdateWhitelistingRequestBody = {
    instruments: string[];
};

export type BulkUpdateRequest = {
    apiMethod: string;
    body: UpdateWhitelistingRequestBody;
};

export const mapInstrumentsToRequestBody = (instruments: string[]): BulkUpdateRequest => {
    return {
        apiMethod: "setInstrumentsWhitelist",
        body: {
            instruments,
        },
    };
};

