import { fetchAuthSession, type JWT } from "aws-amplify/auth";

type ErrorResponse = {
    code: string;
    message: string;
};
export type SessionResponse = {
    accessToken: JWT | null;
    idToken: JWT | null;
    error: ErrorResponse | null;
};

const formatErrorResponse = (message: string, code: string): SessionResponse => ({
    accessToken: null,
    idToken: null,
    error: { message, code },
});

export const getSession = async (): Promise<SessionResponse> => {
    try {
        const { tokens } = await fetchAuthSession();

        return {
            accessToken: tokens?.accessToken ?? null,
            idToken: tokens?.idToken ?? null,
            error: null,
        };
    } catch (error: unknown) {
        if (error instanceof Error) {
            return formatErrorResponse(error.message, error.name);
        }

        return formatErrorResponse("Unknown error", "unknown_error");
    }
};
