import React, { memo, PropsWithChildren, Ref } from "react";
import styled from "styled-components";
import { PSmall, PSmallBold } from "../typography";
import { BORDER_WIDTH } from "./consts";

const TabContainer = styled.div<{ activeColor?: string; isActive?: boolean }>`
    cursor: pointer;
    padding: 6px 0;
    color: ${(p) => (p.isActive ? p.activeColor ?? p.theme.colors.brand100 : p.theme.colors.ui52)};
    border-bottom: ${BORDER_WIDTH}px solid ${(p) => p.theme.colors.ui12};
    &:hover {
        color: ${(p) => !p.isActive && p.theme.colors.ui100};
    }
`;

interface ColoredTabProps<TVal> {
    isActive?: boolean;
    activeColor?: string;
    value: TVal;
    onChange: (value: TVal) => void;
}
const ColoredTabViewInner = <TVal,>(
    { children, isActive, activeColor, onChange, value }: PropsWithChildren<ColoredTabProps<TVal>>,
    ref: Ref<HTMLDivElement>,
) => {
    const LabelComponent = isActive ? PSmallBold : PSmall;

    return (
        <TabContainer ref={ref} isActive={isActive} activeColor={activeColor} onClick={() => onChange(value)}>
            <LabelComponent>{children}</LabelComponent>
        </TabContainer>
    );
};

const ColoredTabView = React.forwardRef(ColoredTabViewInner) as <TVal>(
    props: PropsWithChildren<ColoredTabProps<TVal>> & { ref?: React.ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof ColoredTabViewInner>;

export const ColoredTab = memo(ColoredTabView) as typeof ColoredTabView;
