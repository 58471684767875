import React from "react";
import styled from "styled-components";
import { P } from "../typography";

const Container = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
`;

const Info = styled(P)`
    color: ${(p) => p.theme.colors.ui52};

    span {
        color: ${(p) => p.theme.colors.ui100};
    }

    strong {
        font-weight: 600;
        color: ${(p) => p.theme.colors.ui100};
    }

    a {
        color: ${(p) => p.theme.colors.brand100};
    }
`;

export const FormInfo: React.FC = ({ children }) => {
    return (
        <Container>
            <Info>{children}</Info>
        </Container>
    );
};
