import React from "react";
import styled, { useTheme } from "styled-components";
import { shadowCss } from "../layout";
import { PBold, TextSmall } from "../typography";

const Container = styled.div`
    display: flex;
    width: min-content;
    align-items: center;
    gap: 4px;
    padding: 2px 4px;
    border-radius: 4px;
    ${shadowCss};
`;

export const AssetPill: React.VFC<{
    title: string | React.ReactElement;
    label?: string | React.ReactElement;
}> = ({ title, label }) => {
    const { colors } = useTheme();
    const titleElement = typeof title === "string" ? <PBold>{title}</PBold> : title;
    const labelElement = !label ? null : typeof label === "string" ? (
        <TextSmall color={colors.ui72}>{label}</TextSmall>
    ) : (
        label
    );

    return (
        <Container>
            {titleElement}
            {labelElement}
        </Container>
    );
};
