import { useEffect } from "react";

const PROJECT_TITLE = document.title;

export const useTitle = (title: string | null) => {
    useEffect(() => {
        if (title) {
            document.title = `${title} – ${PROJECT_TITLE}`;
        }
        return () => {
            document.title = PROJECT_TITLE;
        };
    }, []);
};
