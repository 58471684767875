import { FC } from "react";
import styled, { css } from "styled-components";

const OuterContainer = styled.div<{ $size: number; $borderColor: string }>`
    height: ${(p) => p.$size}px;
    width: ${(p) => p.$size}px;
    border-radius: ${(p) => p.$size / 2}px;
    ${(p) =>
        p.$borderColor !== "transparent" &&
        css`
            border: 1px solid ${p.$borderColor};
        `}
    overflow: hidden;
    z-index: 1;
`;

const InnerContainer = styled.div<{ $size: number; $borderColor: string }>`
    height: ${(p) => p.$size}px;
    width: ${(p) => p.$size}px;
    position: relative;
    ${(p) =>
        p.$borderColor !== "transparent" &&
        css`
            bottom: 1px;
            right: 1px;
        `}
`;

type Props = {
    statusColor?: string;
    size: number;
    className?: string;
};

export const CircleStatusWrapper: FC<Props> = ({
    children,
    statusColor = "transparent",
    size,
    className,
}) => (
    <OuterContainer $size={size} $borderColor={statusColor} className={className}>
        <InnerContainer $size={size} $borderColor={statusColor}>
            {children}
        </InnerContainer>
    </OuterContainer>
);
