import { Notifications } from "@fm-frontend/uikit";
import { FormHelperContextProvider, GA4Provider } from "@fm-frontend/utils";
import { OverlayProvider } from "@react-aria/overlays";
import { ErrorBoundary } from "components/ErrorBoundary";
import { Loading } from "components/Loading";
import { ConfirmationModal } from "components/modal/ConfirmationModal";
import { Portal } from "components/Popover";
import { TestEnvAlert } from "components/TestEnvAlert";
import { GA_MEASUREMENT_ID, IS_TEST_ENV } from "const/env";
import { broadcastEvent, updateIsMobile } from "feature/app";
import { GuestRouter } from "feature/app/router/GuestRouter";
import { LayoutContainer, MainContent } from "feature/auth/style";
import { useSignupQueryParams } from "feature/auth/useSignupQueryParams";
import { MainMenu } from "feature/mainMenu";
import { PermissionsLackErrorModal } from "feature/usersManagement";
import { useIsActiveTracker } from "hooks/useIsActiveTracker";
import { IsLoadingProvider, useIsLoading } from "hooks/useIsLoading";
import { useAddPersistedSearchParamsToUrl } from "hooks/useSearchParamState";
import { useSelector } from "hooks/useSelector";
import { useItemTransition } from "hooks/useTransition";
import { Onboarding } from "pages/onboarding/Onboarding";
import { lazy, memo, Suspense, useEffect, useMemo, useState } from "react";
import { Provider, useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "store";
import { usePrimeBrokerViewType } from "store/hooks";
import { useIsOnboardingRequired } from "store/onboardingSlice";
import { GlobalStyle, Overlay } from "style";
import { ThemeProvider } from "styled-components";
import { logout } from "./store/auth/logout";
import { isAuthenticated as isAuthenticatedAction } from "./store/auth/selectors";
import { MOUSE_LEAVE_NOTIFICATION_TIMEOUT } from "./const";

const themes = (() => {
    switch (process.env.REACT_APP_FM_BUILD_FLAVOR) {
        case "payperless":
            return require("style/whitelabel/payperless").themes;
        case "integrityone":
            return require("style/whitelabel/integrityone").themes;
        case "indodax":
            return require("style/whitelabel/indodax").themes;
        case "fm-cy":
            return require("style/whitelabel/fm-cy").themes;
        case "coinrate":
            return require("style/whitelabel/coinrate").themes;
        case "wincent":
            return require("style/whitelabel/wincent").themes;
        case "efi":
            return require("style/whitelabel/efi").themes;
        case "btc-bank":
            return require("style/whitelabel/btc-bank").themes;
        default:
            return require("@fm-frontend/uikit").themes;
    }
})();

const UserRouter = lazy(() => import("feature/app/router/UserRouter"));

const SetIsLoading = () => {
    const setIsLoading = useIsLoading();
    useEffect(() => {
        setIsLoading(true);
        return () => setIsLoading(false);
    }, []);
    return null;
};
const loader = <SetIsLoading />;

export const AppLayout: React.FC = ({ children }) => {
    const isMobile = useSelector((state) => state.app.isMobile);
    const isMobileMenuOpen = useSelector((state) => state.app.isMobileMenuOpen);
    const notifications = useSelector((state) => state.app.notifications);
    const primeBrokerViewType = usePrimeBrokerViewType();

    return (
        <LayoutContainer
            primeBrokerSubaccountsView={primeBrokerViewType === "subaccounts"}
            isOverlayEnabled={isMobile && isMobileMenuOpen}
        >
            <MainMenu isOpen={isMobile && isMobileMenuOpen} />
            <MainContent>{children}</MainContent>
            <Notifications notifications={notifications} mouseLeaveDelay={MOUSE_LEAVE_NOTIFICATION_TIMEOUT} />
            <ConfirmationModal />
            <PermissionsLackErrorModal />
        </LayoutContainer>
    );
};

const UserUI = () => {
    useIsActiveTracker();
    const { isMobile, isMobileMenuOpen } = useSelector((state) => state.app);

    useEffect(() => {
        // thanks apple for making me include this masterpiece of a crutch to make the menu work in ios safari
        if (isMobile && isMobileMenuOpen) window.scroll({ top: 1 });
    }, [isMobile, isMobileMenuOpen]);

    return <UserRouter />;
};

const App = memo(function App() {
    useAddPersistedSearchParamsToUrl();
    const dispatch = useDispatch();
    const { wsOnline, isLoading, isLoadingApp } = useSelector((state) => state.app);
    const isOnboardingRequired = useIsOnboardingRequired();
    const isAuthenticated = useSelector(isAuthenticatedAction);

    const { isActive, transitionState } = useItemTransition(!wsOnline || isLoading, {
        enterDelay: 100,
        exitDelay: 100,
    });

    const { invite } = useSignupQueryParams();

    useEffect(() => {
        if (invite) {
            dispatch(logout());
            dispatch(broadcastEvent(logout()));
            //Due to state reset
            dispatch(updateIsMobile());
        }
    }, [invite]);

    const overlay = useMemo(() => {
        if (!isActive) return null;

        return (
            <Portal>
                <Overlay transitionState={transitionState}>
                    <Loading size={56} />
                </Overlay>
            </Portal>
        );
    }, [isActive, isLoadingApp, transitionState]);

    if (isLoadingApp) {
        return (
            <Portal>
                <Overlay transitionState={"active"}>
                    <Loading size={56} />
                </Overlay>
            </Portal>
        );
    }

    if (isAuthenticated && isOnboardingRequired) {
        return <Onboarding />;
    }

    if (isAuthenticated) {
        return (
            <>
                <UserUI />
                {overlay}
            </>
        );
    }

    return <GuestRouter />;
});

const Contexts: React.FC = () => {
    const [theme] = useState(themes.light);

    return (
        <GA4Provider measurementId={GA_MEASUREMENT_ID}>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <OverlayProvider>
                        <IsLoadingProvider>
                            <ErrorBoundary>
                                <Suspense fallback={loader}>
                                    <FormHelperContextProvider>
                                        {IS_TEST_ENV && <TestEnvAlert />}
                                        <BrowserRouter>
                                            <App />
                                        </BrowserRouter>
                                        <div id="modalRoot" />
                                    </FormHelperContextProvider>
                                </Suspense>
                            </ErrorBoundary>
                        </IsLoadingProvider>
                    </OverlayProvider>
                    <GlobalStyle />
                </ThemeProvider>
            </Provider>
        </GA4Provider>
    );
};

export default Contexts;
