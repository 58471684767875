import { useMemo } from "react";
import { useInstruments } from "../hooks";
import { useUsdPrices } from "./hooks";

export const useExtendedCurrencies = () => {
    const { currencies } = useInstruments();
    const { priceObj, isLoading: isPricesLoading } = useUsdPrices();

    const sortedCurrencies = useMemo(
        () => Object.values(currencies).sort((a, b) => a.name.localeCompare(b.name)),
        [currencies],
    );

    const extendedCurrencies = useMemo(
        () => isPricesLoading
            ? sortedCurrencies
            : sortedCurrencies.map(
                (currency) => ({ ...currency, usdVal: priceObj[currency.name] }) as typeof currency,
            ),
        [sortedCurrencies, priceObj, isPricesLoading],
    );

    const currencyGroups = useMemo(
        () => Object.fromEntries(sortedCurrencies.map((currency) => [currency.name, currency.currencyType])),
        [sortedCurrencies],
    );

    return {
        extendedCurrencies,
        currencyGroups,
    };
};
