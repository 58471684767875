import { Header, HeaderTitle, Skeleton } from "@fm-frontend/uikit";
import styled from "styled-components";
import { onMobile } from "const";
import React from "react";

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    cursor: pointer;

    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }
`;

const Body = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 12px 0;

    ${onMobile} {
        margin-top: 5px;
        margin-bottom: 2px;
    }
`;

const PropertySkeleton = styled.div`
    display: flex;
    width: 50%;
    gap: 8px;

    ${onMobile} {
        width: 100%;
    }
`;

export const PlaceholderCard = () => (
    <Container>
        <Header>
            <HeaderTitle title={<Skeleton width="88px" height="18px" />} />
        </Header>
        <Body>
            {[...Array(4).keys()].map((i, index) => (
                <PropertySkeleton key={index}>
                    <Skeleton width="88px" height="16px" />
                    <Skeleton width="50%" height="16px" />
                </PropertySkeleton>
            ))}
        </Body>
    </Container>
);
