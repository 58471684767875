import { FC, useCallback } from "react";
import { useSWRConfig } from "swr";
import { instrumentMarkupsSWRKey } from "hooks/useInstrumentMarkups";
import { InstrumentsBulkEditModal } from "../ConfigurationModal";
import { BulkEditButton } from "../../components/BulkEditButton";

type InstrumentsBulkEditButtonProps = {
    disabled: boolean;
};

export const InstrumentsBulkEditButton: FC<InstrumentsBulkEditButtonProps> = ({ disabled }) => {
    const { mutate } = useSWRConfig();

    const handleModalClose = useCallback(() => {
        mutate(instrumentMarkupsSWRKey);
    }, [mutate]);

    return (
        <BulkEditButton
            disabled={disabled}
            renderModal={(cancel) => <InstrumentsBulkEditModal onCancel={cancel} />}
            onClose={handleModalClose}
        />
    );
};
