import { AssetsGroupType } from "feature/assetsControl/types";
import React, { useContext } from "react";

type AssetsTabContextType = {
    handleCpRowEditClick: (options: { asset: string; cpName: string; cpId: number }) => void;
    handleAssetRowEditClick: (asset: string) => void;
    handleConfigureButtonClick: (selectedAssetsGroupFilter: AssetsGroupType) => void;
};

const AssetsTabContext = React.createContext<AssetsTabContextType | null>(null);
AssetsTabContext.displayName = "AssetsTabContext";

export const useAssetsTabContext = () => {
    const contextData = useContext(AssetsTabContext);

    if (contextData === null) {
        throw new Error("useAssetsTabContext must be within AssetsTabContext");
    }

    return contextData;
};
export const AssetsTabContextProvider = AssetsTabContext.Provider;
