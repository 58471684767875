import { Accordion, HStack, Tab, TabContext, TabList } from "@fm-frontend/uikit";
import { useAccordion } from "@fm-frontend/uikit/src/components/Accordion/useAccordion";
import { FC, useCallback } from "react";
import { GroupBy } from "store/usePositionsGrouped";
import { Spacer } from "style";
import styled from "styled-components";

const TabsContainer = styled.div`
    min-width: 270px;
`;

const PositionsAccordionHeader = styled(HStack)`
    gap: 8px;
    align-items: center;
`;

type PositionAccordionHeaderProps = {
    groupBy: GroupBy;
    setGroupBy: (groupBy: GroupBy) => void;
    noPositions: boolean;
};

export const PositionAccordionHeader: FC<PositionAccordionHeaderProps> = ({
    groupBy,
    setGroupBy,
    noPositions,
}) => {
    const { openAccordion } = useAccordion();

    const handleTabChange = useCallback(
        (value: GroupBy) => {
            setGroupBy(value);
            openAccordion();
        },
        [openAccordion, setGroupBy],
    );

    return (
        <PositionsAccordionHeader>
            <TabsContainer
                onClick={(ev) => {
                    ev.stopPropagation();
                }}
            >
                <TabContext value={groupBy} handleClick={handleTabChange}>
                    <TabList size="small">
                        <Tab
                            title="Positions by Entities"
                            value="counterparty"
                            key="counterparty"
                        />
                        <Tab title="Positions by Assets" value="asset" key="asset" />
                    </TabList>
                </TabContext>
            </TabsContainer>
            {noPositions && (
                <>
                    <Spacer />
                    <Accordion.EmptyLabel>No open positions</Accordion.EmptyLabel>
                </>
            )}
        </PositionsAccordionHeader>
    );
};
