import React from "react";
import { Content, ContentCell } from "./ContentCell";
import { CopyCell } from "./CopyCell";
import { HoverCell } from "./HoverCell";

export type TSectionItem = {
    title: string;
    value: string;
    copyTitle?: string;
    copyValue?: string;
    renderContent?: (value: Content) => React.ReactNode;
    copyable?: boolean;
};

export const SectionItem: React.FC<{ sectionItem: TSectionItem }> = ({ sectionItem }) => {
    const { title, value, renderContent, copyable } = sectionItem;
    const content = <ContentCell title={title} content={value} renderContent={renderContent} />;

    if (copyable) {
        return (
            <CopyCell notificationTitle={title} content={value} paddingY={6} paddingX={12} key={title}>
                {content}
            </CopyCell>
        );
    }

    return (
        <HoverCell paddingY={6} paddingX={12} key={title}>
            {content}
        </HoverCell>
    );
};
