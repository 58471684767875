import { useSubaccounts } from "../api";
import { Loader } from "./Loader";
import { AddFirstSubaccountButton } from "./AddFirstSubaccountButton";
import { AddNewSubaccountButton } from "./AddNewSubaccountButton";


export const SubaccountsMenuButton = () => {
    const { subaccounts, isLoading } = useSubaccounts();
    const hasSubaccounts = subaccounts.length > 0;

    if (isLoading) {
        return <Loader />;
    }

    if (!hasSubaccounts) {
        return <AddFirstSubaccountButton />;
    }

    return <AddNewSubaccountButton subaccounts={subaccounts} />;
};
