import { Skeleton, TableView } from "@fm-frontend/uikit";
import { ColumnDef, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { PositionsTableRowData } from "feature/positions/types";
import { StatusOfRfqHistoryItem, useLastRfqTrades } from "feature/rfq/apiHooks/useLastRfqTrades";
import { useMemo } from "react";
import { tableColumns } from "./table";

const SKELETON_COUNT = 5;

export const LastRfqTable = ({
    selectedStatuses,
}: {
    selectedStatuses: StatusOfRfqHistoryItem[];
}) => {
    const { lastRfq: data, isLoading } = useLastRfqTrades({ selectedStatuses });

    const memoizedColumns = useMemo(() => {
        if (isLoading) {
            return tableColumns.map((column) => ({
                ...column,
                cell: <Skeleton height="16px" />,
            }));
        }

        return tableColumns;
    }, [isLoading]);

    const memoizedData = useMemo(
        () => (isLoading ? Array(SKELETON_COUNT).fill({}) : data),
        [data, isLoading],
    );

    const table = useReactTable({
        data: memoizedData,
        columns: memoizedColumns as ColumnDef<PositionsTableRowData, any>[],
        getCoreRowModel: getCoreRowModel(),
        enableSorting: false,
    });

    const isEmpty = !memoizedData.length && !isLoading;

    return <TableView table={table} isEmpty={isEmpty} condensed />;
};
