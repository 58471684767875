import { ClientId, CounterpartyIcon, HStack, P } from "@fm-frontend/uikit";
import { useSelector } from "hooks";
import React from "react";

export const CpCell: React.FC<{ cpId: number | undefined }> = ({ cpId }) => {
    const allAvailableCounterpartiesInfo = useSelector(
        (state) => state.app.allAvailableCounterpartiesInfo,
    );

    if (cpId === undefined) {
        return null;
    }

    const cpName = allAvailableCounterpartiesInfo[cpId]?.username;

    return (
        <HStack alignItems="center" spacing={4}>
            {cpName === undefined && <P>Counterparty</P>}
            {cpName !== undefined && (
                <>
                    <CounterpartyIcon size={16} cpId={cpId} />
                    <P>{cpName}</P>
                </>
            )}

            <ClientId id={cpId} />
        </HStack>
    );
};
