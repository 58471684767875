import { AuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { FC } from "react";
import { AuthorizedLastOrders } from "./AuthorizedLastOrders";
import { LastOrders } from "./LastOrders";

type LastOrdersWidgetProps = {
    authorizedClientId?: AuthorizedClientId;
};

export const LastOrdersWidget: FC<LastOrdersWidgetProps> = ({ authorizedClientId }) => {
    if (authorizedClientId !== undefined) {
        return <AuthorizedLastOrders authorizedClientId={authorizedClientId} />;
    }

    return <LastOrders />;
};
