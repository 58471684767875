import { Box, PBold, PSmall, VStack } from "@fm-frontend/uikit";
import { setIsMobileMenuOpen } from "feature/app";
import { useSelector } from "hooks";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { StatusKYBVariant } from "store/onboardingSlice";
import styled, { css } from "styled-components";

const activeStateCss = css`
    ${PSmall} {
        color: ${(p) => p.theme.colors.brand72};
    }
    ${PBold} {
        color: ${(p) => p.theme.colors.brand100};
    }

    background-color: ${(p) => p.theme.colors.brand8};
`;

const Container = styled(Box)<{ isActive?: boolean }>`
    background-color: ${(p) => p.theme.colors.ui4};
    border-radius: 6px;
    cursor: pointer;

    ${PSmall} {
        color: ${(p) => p.theme.colors.ui52};
    }

    ${(p) => p.isActive && activeStateCss}

    :hover {
        ${activeStateCss};
    }

    a {
        text-decoration: none !important;
    }
`;

export const LpBadge: React.VFC<{ to: string }> = ({ to }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const match = useRouteMatch(to);
    const isActive = match?.isExact;

    const { statusKYB } = useSelector((state) => state.onboarding);
    const lpsInVerification = useMemo(
        () => Object.values(statusKYB).filter((s) => s === StatusKYBVariant.Started),
        [statusKYB],
    );

    return (
        <Container
            isActive={isActive}
            onClick={() => {
                history.push(to);
                dispatch(setIsMobileMenuOpen(false));
            }}
        >
            <VStack padding={8}>
                <PBold>Liquidity providers</PBold>
                {lpsInVerification.length > 0 ? (
                    <PSmall>{lpsInVerification.length} verification in process</PSmall>
                ) : (
                    <PSmall>Discover new counterparties </PSmall>
                )}
            </VStack>
        </Container>
    );
};
