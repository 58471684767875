import { useMemo } from "react";
import { useIsMakerUser } from "store/hooks";
import { ClientType, CounterpartyLimit } from "types";
import { getEquityIndicatorData } from "../utils";
import { shouldUseUserGrossLimit } from "../../utils";

export const useTotalEquity = (limit: CounterpartyLimit, cpType: ClientType) => {
    const isMakerUser = useIsMakerUser();
    const shouldTakeUserGrossLimit = shouldUseUserGrossLimit(cpType, isMakerUser);
    const equityIndicator = useMemo(
        () => getEquityIndicatorData(limit, shouldTakeUserGrossLimit, cpType, ""),
        [limit, shouldTakeUserGrossLimit, cpType],
    );

    return equityIndicator.value;
}
