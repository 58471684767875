import { Flex } from "@fm-frontend/uikit";
import styled from "styled-components";
import { MarkupCell } from "./MarkupCell";

const DefaultMarkupContainer = styled(Flex)`
    height: 100%;
    width: 100%;
    background-color: ${(p) => p.theme.colors.attention4};
    border-left: 1px solid ${(p) => p.theme.colors.ui12};
    padding-right: 12px;
`;

export const DefaultMarkupCell = ({ markup, lineThrough = false }: { markup: number; lineThrough?: boolean }) => (
    <DefaultMarkupContainer>
        <MarkupCell markup={markup} lineThrough={lineThrough} withoutBorder />
    </DefaultMarkupContainer>
);
