import React, { CSSProperties, memo, useEffect, useRef, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import { ColoredTab } from "./ColoredTab";
import { BORDER_WIDTH } from "./consts";

const GapItem = styled.div<{ gap: "auto" | number }>`
    width: 10px;
    border-bottom: ${BORDER_WIDTH}px solid ${(p) => p.theme.colors.ui12};
    ${(p) =>
        typeof p.gap === "number"
            ? css`
                  flex: 0 0 ${p.gap}px;
              `
            : css`
                  flex: 1;
              `}
`;

const TabsContainer = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
`;

const Slider = styled.div`
    display: inline-block;
    height: ${BORDER_WIDTH}px;
    position: absolute;
    transition: all 0.1s linear;
`;

const ColoredTabsView = <TVal extends string | number = string>({
    tabs,
    value,
    gap = 25,
    onChange,
}: {
    tabs: Array<{ value: TVal; label: React.ReactNode; activeColor?: string }>;
    value: TVal;
    gap?: "auto" | number;
    onChange: (value: TVal) => void;
}) => {
    const theme = useTheme();
    const tabsRef = useRef<Partial<Record<TVal, HTMLDivElement | null>>>({});

    // we need to rerender component after mount because before refs are empty
    const [, forceRender] = useState(false);
    useEffect(() => {
        // dirty hack due to incorrect offsetWidth of container otherwise
        setTimeout(() => forceRender(true), 0);
    }, []);

    const activeTabRef = tabsRef.current[value];
    let sliderStyles: CSSProperties = {};
    if (activeTabRef) {
        // calculate bottom slider line position
        const activeTabColor = tabs.find((tab) => tab.value === value)?.activeColor ?? theme.colors.brand100;
        sliderStyles = {
            width: activeTabRef.offsetWidth,
            left: activeTabRef.offsetLeft,
            top: activeTabRef.offsetTop + activeTabRef.offsetHeight - BORDER_WIDTH,
            backgroundColor: activeTabColor,
        };
    }

    return (
        <TabsContainer>
            <Slider style={sliderStyles} />
            {tabs
                .flatMap((tab, i) => [
                    <ColoredTab<TVal>
                        key={tab.value}
                        isActive={tab.value === value}
                        activeColor={tab.activeColor}
                        onChange={onChange}
                        value={tab.value}
                        ref={(el) => (tabsRef.current[tab.value] = el)}
                    >
                        {tab.label}
                    </ColoredTab>,
                    <GapItem key={i} gap={gap} />,
                ])
                .slice(0, -1)}
        </TabsContainer>
    );
};

export const ColoredTabs = memo(ColoredTabsView) as typeof ColoredTabsView;
