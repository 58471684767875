import { IconButton, Icons, Tooltip } from "@fm-frontend/uikit";
import styled from "styled-components";
import { SelectableBadge } from "components/Badge";
import { ExportModal } from "components/modal/ExportModal";
import { Tab, TabList, Tabs } from "components/Tab";
import { TopMenu } from "components/TopMenu";
import { DummyButton } from "feature/form/Buttons";
import { Table } from "feature/table";
import { useCreatePrefilledTransactionOrRequest } from "hooks";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useItemTransition } from "hooks/useTransition";
import React, { useCallback, useEffect, useMemo, useState, VFC } from "react";
import {
    TypeTransaction,
    useHistoricalSettlementTransactions,
    useTransactionsHistoryEndpoints,
} from "store/useHistoricalSettlementTransactions";
import { Page } from "style";
import { Transaction } from "types";
import {
    columnsSettlementHistory,
    columnsTechnicalHistory,
    exportColumnsSettlementHistory,
    exportColumnsTechnicalHistory,
} from "./table";
import { Props } from "./typed";
import { TabPanel } from "react-tabs";

export type TransactionRowData = [...Transaction, string];

export const prepareData = (data: TransactionRowData) => ({
    counterpartyId: data[0],
    currencyName: data[1],
    amount: data[2],
    settlementOrderId: data[3],
    comment: data[4],
    createdAt: data[5],
    txId: data[6],
    sentAt: data[7],
    flags: data[8],
    transactionMoment: data[9],
    transaction: data[10],
    fee: data[11],
    network: data[12],
    cpName: data[13],
});

const HeaderTabs: VFC<Props> = ({ onSelectChange }) => {
    return (
        <Tabs selectedTabClassName="is-selected" onSelect={onSelectChange}>
            <TabList>
                <Tab>Transactions</Tab>
                <Tab>Technical</Tab>
            </TabList>
            <TabPanel />
            <TabPanel />
        </Tabs>
    );
};

const ActionWrapper = styled.div`
    margin-top: -6px;
`;

export const PageSettlementsTransactions = () => {
    const sort = useMemo(() => [{ id: "time", desc: true }], []);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState<TypeTransaction>(TypeTransaction.Transactions);
    const [columns, setColumns] = useState(columnsSettlementHistory);
    const [exportColumns, setExportColumns] = useState(exportColumnsSettlementHistory);
    const { getCpName } = useCpInfoHelpers();
    const { data, isLoading } = useHistoricalSettlementTransactions(selectedTab);
    const tableData = useMemo(
        () =>
            data.map(
                (apiTransaction) =>
                    [
                        ...apiTransaction,
                        getCpName(apiTransaction[selectedTab === TypeTransaction.Transactions ? 0 : 6], "full"),
                    ] as TransactionRowData,
            ),
        [data],
    );
    const { isActive, transitionState } = useItemTransition(isModalOpen);
    const exportModalEndpoints = useTransactionsHistoryEndpoints();
    const createPrefilledTransactionOrRequest = useCreatePrefilledTransactionOrRequest();

    useEffect(() => {
        if (selectedTab === TypeTransaction.Transactions) {
            setColumns(columnsSettlementHistory);
            setExportColumns(exportColumnsSettlementHistory);
        } else {
            setColumns(columnsTechnicalHistory);
            setExportColumns(exportColumnsTechnicalHistory);
        }
    }, [selectedTab]);

    const titleSlot = useMemo(
        () => (
            <>
                <DummyButton
                    onClick={(e) => {
                        e.preventDefault();
                        setIsModalOpen(true);
                    }}
                >
                    <SelectableBadge>
                        <header>Download .CSV</header>
                    </SelectableBadge>
                </DummyButton>
            </>
        ),
        [setIsModalOpen],
    );

    const renderAction = useCallback(
        (item: TransactionRowData) => {
            return selectedTab === TypeTransaction.Transactions ? (
                <ActionWrapper>
                    <Tooltip content="Repeat">
                        <IconButton
                            variant="primary"
                            Icon={Icons.Reload}
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                const { counterpartyId, currencyName, amount, comment } = prepareData(item);

                                createPrefilledTransactionOrRequest({
                                    cpId: counterpartyId,
                                    asset: currencyName,
                                    value: BigInt(amount),
                                    comment,
                                });
                            }}
                        />
                    </Tooltip>
                </ActionWrapper>
            ) : null;
        },
        [selectedTab],
    );

    const { apiMethod, filterParams } = exportModalEndpoints[selectedTab];

    return (
        <Page>
            <TopMenu>Settlements history</TopMenu>
            <Table<TransactionRowData>
                title={<HeaderTabs onSelectChange={setSelectedTab} />}
                titleBadge={titleSlot}
                columns={columns}
                data={tableData}
                isLoading={isLoading}
                sortBy={sort}
                actions={selectedTab === TypeTransaction.Transactions ? renderAction : undefined}
            />
            {isActive && (
                <ExportModal
                    url={apiMethod}
                    prefix="transactions"
                    onClose={() => setIsModalOpen(false)}
                    transitionState={transitionState}
                    urlParams={{ filterTransactions: true, ...filterParams }}
                    columns={exportColumns}
                    itemIdColumn={10}
                />
            )}
        </Page>
    );
};
