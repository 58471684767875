import { TopMenu } from "components/TopMenu";
import { TradesHistoryPageContent } from "feature/history/trades/TradesHistoryPageContent";
import { usePrimeBrokerViewType } from "store/hooks";
import { Page } from "style";

export const Trades = () => {
    const primeBrokerViewType = usePrimeBrokerViewType();

    return (
        <Page noBottomSpace>
            <TopMenu>Trades history</TopMenu>
            <TradesHistoryPageContent key={primeBrokerViewType} />
        </Page>
    );
};
