import { BITMASK_UTILS } from "@fm-frontend/utils";
import { createColumns } from "feature/table/columns";
import { Key } from "types";
import { ALLOW_LIMITS_MANAGEMENT, ALLOW_SETTLEMENTS, ALLOW_TRADING } from "./utils";

const { isBitKeyApplied } = BITMASK_UTILS;

export const columns = createColumns<Key>([
    {
        header: "Tag",
        accessor: (data) => data[3],
        mixin: { fontWeight: 500, align: "left" },
    },
    {
        header: "Key",
        accessor: (data) => data[0],
        mixin: { fontWeight: 500, align: "left" },
    },
    {
        header: "Trade",
        accessor: (data) => (isBitKeyApplied(data[1], ALLOW_TRADING) ? "Yes" : "No"),
    },
    {
        header: "Settle",
        accessor: (data) => (isBitKeyApplied(data[1], ALLOW_SETTLEMENTS) ? "Yes" : "No"),
    },
    {
        header: "Risks",
        accessor: (data) => (isBitKeyApplied(data[1], ALLOW_LIMITS_MANAGEMENT) ? "Yes" : "No"),
    },
    {
        header: "Created At",
        accessor: (data) => data[2],
        format: { type: "timems", withTime: true },
    },
]);
