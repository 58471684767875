import { TextSmall } from "@fm-frontend/uikit";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { CopyToClipboard } from "../../../../components/CopyToClipboard";
import { EmDash } from "../../../../const";
import { IndicatorData } from "../../types";
import { CounterpartyStatusType } from "../../utils";
import * as Styled from "./styled";

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 2px;
`;

export interface CounterpartyIndicatorProps {
    indicatorData: IndicatorData;
    mainIndicator?: boolean;
    highlighted?: boolean;
    status?: CounterpartyStatusType;
    subStatus?: CounterpartyStatusType;
    labelHelper?: ReactNode;
}

export const CounterpartyIndicator: React.FC<CounterpartyIndicatorProps> = (props) => {
    const { mainIndicator, highlighted, indicatorData, status, subStatus, labelHelper } = props;
    const { title, value, badge, subValue, copyableValue } = indicatorData;

    const Indicator = mainIndicator ? Styled.MainIndication : Styled.Indication;

    return (
        <Styled.Container>
            <TitleContainer>
                <Styled.Title>{title}</Styled.Title>
                {labelHelper}
            </TitleContainer>
            <Styled.SubContainer>
                <CopyToClipboard stopPropagation value={copyableValue}>
                    <Indicator toneDown={copyableValue === undefined} highlighted={highlighted}>
                        {value ?? EmDash}
                        {subValue && ","}
                    </Indicator>
                </CopyToClipboard>
                {subValue && <TextSmall>{subValue}</TextSmall>}
                {badge && (
                    <Styled.Badge status={status} subStatus={subStatus}>
                        {typeof badge.content === "string" ? <TextSmall>{badge.content}</TextSmall> : badge.content}
                    </Styled.Badge>
                )}
            </Styled.SubContainer>
        </Styled.Container>
    );
};
