import { HStack, PBold } from "@fm-frontend/uikit";
import { InstrumentIcon } from "components/InstrumentIcon";
import { Instrument } from "hooks/useInstruments";
import styled from "styled-components";

const Container = styled(HStack)`
    white-space: nowrap;
    flex-wrap: nowrap;
    height: 100%;

    align-items: center;
    padding: 0 12px;
`;

const InstrumentContainer = styled(HStack)`
    gap: 6px;
`;

export const InstrumentNameCell = ({ instrument }: { instrument: Instrument }) => {
    const { instrumentName } = instrument;

    return (
        <Container>
            <InstrumentContainer>
                <InstrumentIcon instrument={instrumentName} />
                <PBold>{instrumentName}</PBold>
            </InstrumentContainer>
        </Container>
    );
};
