import { linkStyles } from "@fm-frontend/uikit";
import { onHover } from "const";
import { useFaqLinks } from "hooks/useFaqLinks";
import { useHandleLogout } from "hooks/useHandleLogout";
import { useSettings } from "hooks/useSettings";
import { FC } from "react";
import { Link, NavLink } from "react-router-dom";
import { useIsPrimeBrokerUser, useIsSubaccountUser, usePrimeBrokerViewType } from "store/hooks";
import styled from "styled-components";

export const StyledAnchor = styled.a`
    text-decoration: none;
    white-space: normal;
    ${onHover} {
        text-decoration: none;
        background-color: initial;
    }
`;
export const Anchor: React.FC<{ href: string }> = ({ children, ...props }) => {
    return (
        <StyledAnchor target="_blank" rel="noreferrer" {...props}>
            {children}
        </StyledAnchor>
    );
};
export const RouterLink = styled(Link)`
    ${linkStyles}
`;

export const LinkRequests: FC<{}> = ({ children = "Requests" }) => {
    const isSubaccountUser = useIsSubaccountUser();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();

    let to = "/transactions";
    if (isSubaccountUser) {
        to = "/subaccountRequests";
    } else if (isPrimeBrokerUser && primeBrokerViewType === "subaccounts") {
        to = "/subaccountsViewTransactions";
    }

    return <RouterLink to={to}>{children}</RouterLink>;
};
export const LinkTransactions: FC<{}> = ({ children = "Transactions" }) => {
    const isSubaccountUser = useIsSubaccountUser();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();

    let to = "/transactions";
    if (isSubaccountUser) {
        to = "/subaccountTransactions";
    } else if (isPrimeBrokerUser && primeBrokerViewType === "subaccounts") {
        to = "/subaccountsViewTransactions";
    }

    return <RouterLink to={to}>{children}</RouterLink>;
};

export const LinkSupport = ({ caption = "Support" }: { caption?: string }) => {
    const settings = useSettings();

    return <a href={`mailto:${settings.support_email}`}>{caption}</a>;
};
export const LinkResetPassword = () => <NavLink to="/resetpassword">Forgot password?</NavLink>;
export const LinkLogin = () => <NavLink to="/login">Log in</NavLink>;
export const LinkPrivacyPolicy = () => {
    const settings = useSettings();

    if (!settings.privacy_policy_link) {
        return null;
    }

    return (
        <a href={settings.privacy_policy_link} target="_blank" rel="noreferrer">
            Privacy Policy
        </a>
    );
};
export const LinkSettingsProfile = () => <NavLink to="/settings/profile">Account data</NavLink>;
export const LinkSettingsNotifications = () => (
    <NavLink to="/settings/notifications">Notifications</NavLink>
);
export const LinkFAQ = () => {
    const faqLinks = useFaqLinks();

    if (!faqLinks?.faq) {
        return null;
    }

    return (
        <a href={faqLinks.faq} target="_blank" rel="noreferrer">
            FAQ
        </a>
    );
};

export const LinkFAQBulkEdit = () => {
    const faqLinks = useFaqLinks();

    if (!faqLinks?.faqBulkEdit) {
        return null;
    }

    return (
        <a href={faqLinks.faqBulkEdit} target="_blank" rel="noreferrer">
            Learn more about Bulk Edit
        </a>
    );
};

export const LinkFAQRiskManagement = () => {
    const faqLinks = useFaqLinks();

    if (!faqLinks?.faqRiskManagement) {
        return null;
    }

    return (
        <a href={faqLinks.faqRiskManagement} target="_blank" rel="noreferrer">
            Risk management in FAQ
        </a>
    );
};
export const LinkStatus = () => (
    <a href="https://status.finerymarkets.com/" target="_blank" rel="noreferrer">
        Status
    </a>
);
export const LinkLegal = () => (
    <a href="https://finerymarkets.com/legal.html" target="_blank" rel="noreferrer">
        Legal
    </a>
);
export const LinkPulse = () => (
    <a href="https://pulse.finerymarkets.com/analytics" target="_blank" rel="noreferrer">
        Pulse
    </a>
);

export const LinkLogout = () => {
    const handleLogout = useHandleLogout();

    return (
        <a href="#" onClick={handleLogout}>
            Logout
        </a>
    );
};
