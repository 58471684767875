import {
    DesctructiveButton,
    Flex,
    H2,
    HStack,
    Icons,
    P,
    PlainButton,
    PSmall,
    VStack,
} from "@fm-frontend/uikit";
import { useCounterparties } from "hooks";
import React, { useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { format } from "utils/ErrorCodes";
import { updateMarkups } from "../MakerMasterInstrumentsTabContent/InstrumentMarkupsSetupModal/utils";
import { UpdateMarkupResponse } from "../types";

const Details = styled(P)`
    color: ${(p) => p.theme.colors.ui72};
    padding: 0 12px;
`;

const Result = styled.div`
    overflow: auto;
    margin: 8px 12px 12px 12px;
    width: calc(100% - 24px);
    height: 164px;
    border: 1px solid ${(p) => p.theme.colors.ui8};
    border-radius: 8px;
`;

const ResultLine = styled(Flex)`
    padding: 4px 8px 8px 8px;
    align-items: center;
    gap: 8px;
`;

type Props = {
    onClose: () => void;
    onUpdate: (result: UpdateMarkupResponse[]) => Promise<void>;
    result: UpdateMarkupResponse[];
};

export const MarkupsSetupResultsModal: React.FC<Props> = ({ onClose, result, onUpdate }) => {
    const { colors } = useTheme();
    const { counterparties } = useCounterparties();
    const [isRetrying, setIsRetrying] = useState(false);

    const failedCount = useMemo(
        () => result.reduce((acc, { errorCode }) => acc + (errorCode === undefined ? 0 : 1), 0),
        [result],
    );

    const handleRetryClick = async () => {
        try {
            const failedRequests = result
                .filter(({ errorCode }) => errorCode !== undefined)
                .map(({ requestBody }) => requestBody);

            setIsRetrying(true);
            const newOutputItems = await updateMarkups(failedRequests);

            if (newOutputItems.every(({ errorCode }) => errorCode !== undefined)) {
                onUpdate(newOutputItems);
            }

            if (newOutputItems.every(({ errorCode }) => errorCode === undefined)) {
                onClose();
            }
        } finally {
            setIsRetrying(false);
        }
    };

    return (
        <VStack minWidth="360px" maxWidth="360px" asCard>
            <VStack padding={12}>
                <H2>Setup results</H2>
            </VStack>
            <Details>
                {result.length - failedCount} of {result.length} markups applied successfully.{" "}
                {failedCount} errors occurred. Retry or contact admin.
            </Details>
            <Result>
                {result.map(({ errorCode, requestBody: { counterpartyId, instrument } }) => (
                    <ResultLine key={`${counterpartyId}-${instrument}`}>
                        {errorCode !== undefined ? (
                            <Icons.Attention color={colors.negative100} />
                        ) : (
                            <Icons.CheckShape />
                        )}
                        <PSmall>
                            {instrument}, {counterparties[counterpartyId]} ({counterpartyId})
                            {errorCode !== undefined ? ` - ${format(errorCode)}` : null}
                        </PSmall>
                    </ResultLine>
                ))}
            </Result>
            <HStack padding={12} paddingTop={16} spacing={10}>
                <PlainButton fullWidth size="large" type="button" onClick={onClose}>
                    Skip
                </PlainButton>
                <DesctructiveButton
                    fullWidth
                    size="large"
                    type="submit"
                    loading={isRetrying}
                    onClick={handleRetryClick}
                >
                    Retry {failedCount}
                </DesctructiveButton>
            </HStack>
        </VStack>
    );
};
