import { Instrument } from "hooks/useInstruments";
import React, { useContext } from "react";
import { InstrumentWhitelistApi } from "./useWhitelistingManager";

type InstrumentName = Instrument["instrumentName"];

const promiseNoop = () => Promise.resolve();

const WhitelistingContext = React.createContext<{
    isLoading: boolean;
    isWhitelistingOn: boolean;
    refetchWhitelist: () => Promise<InstrumentWhitelistApi | undefined>;
    setWhitelistingStatus: (status: boolean) => Promise<void>;
    isInstrumentInWhitelist: (instrumentName: InstrumentName) => boolean;
    unwhitelistInstrument: (instrumentName: InstrumentName) => Promise<void>;
    whitelistInstrument: (instrumentName: InstrumentName) => Promise<void>;
}>({
    isLoading: false,
    isWhitelistingOn: false,
    refetchWhitelist: () => Promise.resolve(undefined),
    setWhitelistingStatus: promiseNoop,
    isInstrumentInWhitelist: () => false,
    unwhitelistInstrument: promiseNoop,
    whitelistInstrument: promiseNoop,
});

export const useWhitelistingContext = () => useContext(WhitelistingContext);
export const WhitelistingProvider = WhitelistingContext.Provider;
