import { POSITION_COUNTERPARTY_ID } from "const/position";
import { getCpGrossFreeStatus, getEquityStatus } from "feature/counterparties";
import { getCpStatus, shouldUseUserGrossLimit } from "feature/counterparties/utils";
import { useMemo } from "react";
import { CpStatus } from "store/counterpartiesSlice";
import { useFilteredAndExtendedCounterpartyLimitsSnapshot, useIsMakerUser, useUsdPrices } from "store/hooks";

export const useCounterpartiesStatuses = () => {
    const isMakerUser = useIsMakerUser();
    const { data: counterpartyLimits, isLoading: isLimitsLoading } = useFilteredAndExtendedCounterpartyLimitsSnapshot();
    const { priceObj, isLoading: isPriceObjLoading } = useUsdPrices();

    const isLoading = isLimitsLoading || isPriceObjLoading;

    const counterpartiesStatuses = useMemo(() => {
        if (isLoading) {
            return {};
        }

        const limitStatuses: Record<string, CpStatus> = {};

        for (const { limit, cpType: limitCpType } of counterpartyLimits) {
            const grossStatus = getCpGrossFreeStatus(limit, priceObj);
            const equityStatus = getEquityStatus(limit, priceObj, shouldUseUserGrossLimit(limitCpType, isMakerUser));
            const status = getCpStatus(limit, grossStatus, equityStatus);

            limitStatuses[limit[POSITION_COUNTERPARTY_ID]] = {
                status: status,
                equityStatus: equityStatus,
                grossStatus: grossStatus,
            };
        }

        return limitStatuses;
    }, [counterpartyLimits, priceObj, isLoading]);

    return useMemo(
        () => ({
            data: counterpartiesStatuses,
            isLoading,
        }),
        [counterpartiesStatuses, isLoading],
    );
};
