import { HStack, VStack } from "@fm-frontend/uikit";
import { ErrorBoundary } from "components/ErrorBoundary";
import { InstrumentSelectorProvider } from "components/InstrumentSelectorContext/InstrumentSelectorContext";
import { useInstrumentSelectorContext } from "components/InstrumentSelectorContext/useInstrumentSelectorContext";
import { AuthorizedPositionsAccordion } from "components/PositionsAccordion";
import { PositionsAccordion } from "components/PositionsAccordion/PositionsAccordion";
import {
    AuthorizedClientId,
    useAuthorizedClientId,
} from "feature/trade/hooks/useAuthorizedClientId";
import { useClientOptionsForAuthorizedTrading } from "feature/trade/hooks/useClientOptionsForAuthorizedTrading";
import { useInstrumentsBooks } from "feature/trade/hooks/useInstrumentsBooks";
import { useTradingInstruments } from "feature/trade/hooks/useTradingInstruments";
import { DisabledAuthorizedTradingInfo } from "feature/trade/trading/DisabledAuthorizedTradingInfo";
import { InstrumentSelector } from "feature/trade/trading/InstrumentSelector";
import { MarketDepthSection } from "feature/trade/trading/MarketDepthSection";
import { SelectedInstrumentContext } from "feature/trade/trading/SelectedInstrumentContext";
import { TradingBottomFixContainer } from "feature/trade/trading/TradingBottomFixContainer";
import { TradingTile } from "feature/trade/trading/TradingTile";
import { AddInstrumentTile } from "feature/trade/trading/TradingTile/AddInstrumentTile";
import { NoTilesTile } from "feature/trade/trading/TradingTile/NoTilesTile";
import { EMPTY_BOOK } from "feature/trade/trading/utils";
import { EventContext } from "hooks/useEventEmitter";
import { useCallback, useContext } from "react";
import { useHasTakerRoleUser, useIsPrimeBrokerUser, usePrimeBrokerViewType } from "store/hooks";
import styled from "styled-components";
import { Book } from "types";
import { useIsAuthorizedTradingAvailable } from "../hooks/useIsAuthorizedTradingAvailable";
import { AddingSubAccountsInfo } from "./AddingSubAccountsInfo";
import { LastOrdersWidget } from "./LastOrdersWidget";
import { RiskManagementWidget } from "./RiskManagementWidget";
import { Cluster } from "./style";

const TilesContainer = styled.div`
    display: grid;
    grid-gap: 16px 8px;
    grid-template-columns: repeat(auto-fill, minmax(278px, 1fr));
    padding-bottom: 40px;
`;

const LeftColumn = styled.div`
    width: 100%;
`;

const RightColumn = styled.div`
    width: 564px;
    min-width: 564px;
    max-width: 564px;
`;

const Layout = styled(HStack)`
    min-width: 850px;
    height: 100%;
    gap: 8px;
    padding-right: 12px;

    @media (max-width: 1440px) {
        ${LeftColumn} {
            width: 50%;
        }

        ${RightColumn} {
            width: 50%;
            max-width: unset;
        }
    }
    @media (max-width: 1359px) {
        ${LeftColumn} {
            width: 35%;
        }

        ${RightColumn} {
            width: 65%;
            max-width: unset;
        }
    }
    @media (max-width: 1144px) {
        ${LeftColumn} {
            width: 278px;
            min-width: 278px;
        }

        ${RightColumn} {
            flex: 1;
            max-width: unset;
        }
    }
`;

const WidgetsLayout = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 40px;
`;

const TakerTradingView = () => {
    const { instrumentSelectorRef, focusInstrumentSelect } = useInstrumentSelectorContext();
    const {
        favoriteInstrumentsIds,
        selectedInstrument,
        isTopFavoriteInstrument,
        removeInstrument,
        addInstrument,
        selectInstrument,
    } = useTradingInstruments();
    const { emit } = useContext(EventContext);
    const { instrumentsBooks } = useInstrumentsBooks();
    const selectedBook = instrumentsBooks.get(selectedInstrument ?? "") ?? null;
    const isBookLoading = selectedBook ? selectedBook.isLoading : selectedInstrument !== undefined;
    const resetErrors = useCallback(() => emit?.({ type: "reset" }), [emit]);

    return (
        <SelectedInstrumentContext.Provider value={{ instrument: selectedInstrument }}>
            <Layout>
                <LeftColumn>
                    <VStack height="100%">
                        <InstrumentSelector
                            favoriteInstruments={favoriteInstrumentsIds}
                            onInstrumentDeselect={removeInstrument}
                            onInstrumentSelect={addInstrument}
                            ref={instrumentSelectorRef}
                        />
                        <TradingBottomFixContainer>
                            <TilesContainer>
                                {favoriteInstrumentsIds.length > 0 && (
                                    <>
                                        {favoriteInstrumentsIds.map((instrumentName) => {
                                            return (
                                                <ErrorBoundary noUI key={instrumentName}>
                                                    <TradingTile
                                                        instrumentName={instrumentName}
                                                        selectedBook={
                                                            instrumentsBooks.get(instrumentName)
                                                                ?.data ?? EMPTY_BOOK
                                                        }
                                                        isActive={
                                                            instrumentName === selectedInstrument
                                                        }
                                                        isTopFavoriteInstrument={isTopFavoriteInstrument(
                                                            instrumentName,
                                                        )}
                                                        resetErrors={resetErrors}
                                                        removeInstrument={removeInstrument}
                                                        selectInstrument={selectInstrument}
                                                        selectedInstrument={selectedInstrument}
                                                    />
                                                </ErrorBoundary>
                                            );
                                        })}
                                        <AddInstrumentTile
                                            onAddInstrumentClick={focusInstrumentSelect}
                                        />
                                    </>
                                )}
                                {favoriteInstrumentsIds.length === 0 && <NoTilesTile />}
                            </TilesContainer>
                        </TradingBottomFixContainer>
                    </VStack>
                </LeftColumn>
                <RightColumn>
                    <TradingBottomFixContainer>
                        <WidgetsLayout>
                            <ErrorBoundary noUI>
                                <MarketDepthSection
                                    isLoading={isBookLoading}
                                    selectedBook={selectedBook?.data ?? EMPTY_BOOK}
                                    errorCode={selectedBook?.errorCode}
                                    selectedInstrument={selectedInstrument}
                                />
                            </ErrorBoundary>
                            <ErrorBoundary noUI>
                                <PositionsAccordion />
                            </ErrorBoundary>
                            <ErrorBoundary noUI>
                                <LastOrdersWidget />
                            </ErrorBoundary>
                        </WidgetsLayout>
                    </TradingBottomFixContainer>
                </RightColumn>
            </Layout>
        </SelectedInstrumentContext.Provider>
    );
};

const getSelectedBook = (
    instrumentName: string | undefined,
    instrumentsBooks: Map<
        string,
        {
            data: Book;
            isLoading: boolean;
            errorCode: number | undefined;
        }
    >,
    authorizedClientId: AuthorizedClientId,
) => {
    const key = JSON.stringify({ instrument: instrumentName, clientId: authorizedClientId });

    return instrumentsBooks.get(key);
};
const PrimeBrokerTradingView = () => {
    const { authorizedClientId } = useAuthorizedClientId();
    const { instrumentSelectorRef, focusInstrumentSelect } = useInstrumentSelectorContext();
    const {
        favoriteInstrumentsIds,
        selectedInstrument,
        isTopFavoriteInstrument,
        removeInstrument,
        addInstrument,
        selectInstrument,
    } = useTradingInstruments();
    const { emit } = useContext(EventContext);
    const { instrumentsBooks } = useInstrumentsBooks();
    const selectedBook =
        getSelectedBook(selectedInstrument, instrumentsBooks, authorizedClientId) ?? null;
    const isBookLoading = selectedBook ? selectedBook.isLoading : selectedInstrument !== undefined;
    const resetErrors = useCallback(() => emit?.({ type: "reset" }), [emit]);
    const isAuthorizedTradingAvailable = useIsAuthorizedTradingAvailable();
    const {
        clientOptionsForAuthorizedTrading,
        isLoading: isClientOptionsForAuthorizedTradingLoading,
    } = useClientOptionsForAuthorizedTrading();

    if (!isAuthorizedTradingAvailable) {
        return (
            <Layout>
                <LeftColumn>
                    <DisabledAuthorizedTradingInfo />
                </LeftColumn>
                <RightColumn>
                    <TradingBottomFixContainer>
                        <WidgetsLayout>
                            <ErrorBoundary noUI>
                                <PositionsAccordion />
                            </ErrorBoundary>
                            <ErrorBoundary noUI>
                                <LastOrdersWidget />
                            </ErrorBoundary>
                        </WidgetsLayout>
                    </TradingBottomFixContainer>
                </RightColumn>
            </Layout>
        );
    }

    if (
        clientOptionsForAuthorizedTrading.length === 0 &&
        isClientOptionsForAuthorizedTradingLoading
    ) {
        return (
            <Layout>
                <LeftColumn>
                    <InstrumentSelector
                        disabled
                        favoriteInstruments={favoriteInstrumentsIds}
                        onInstrumentDeselect={removeInstrument}
                        onInstrumentSelect={addInstrument}
                        ref={instrumentSelectorRef}
                    />
                    <TradingBottomFixContainer>
                        <TilesContainer>
                            <NoTilesTile />
                        </TilesContainer>
                    </TradingBottomFixContainer>
                </LeftColumn>
                <RightColumn>
                    <TradingBottomFixContainer>
                        <WidgetsLayout>
                            <ErrorBoundary noUI>
                                <PositionsAccordion />
                            </ErrorBoundary>
                            <ErrorBoundary noUI>
                                <LastOrdersWidget />
                            </ErrorBoundary>
                        </WidgetsLayout>
                    </TradingBottomFixContainer>
                </RightColumn>
            </Layout>
        );
    }
    if (
        clientOptionsForAuthorizedTrading.length === 0 &&
        !isClientOptionsForAuthorizedTradingLoading
    ) {
        return (
            <Layout>
                <LeftColumn>
                    <AddingSubAccountsInfo />
                </LeftColumn>
                <RightColumn>
                    <TradingBottomFixContainer>
                        <WidgetsLayout>
                            <ErrorBoundary noUI>
                                <PositionsAccordion />
                            </ErrorBoundary>
                            <ErrorBoundary noUI>
                                <LastOrdersWidget />
                            </ErrorBoundary>
                        </WidgetsLayout>
                    </TradingBottomFixContainer>
                </RightColumn>
            </Layout>
        );
    }

    return (
        <SelectedInstrumentContext.Provider value={{ instrument: selectedInstrument }}>
            <Layout>
                <LeftColumn>
                    <VStack height="100%">
                        <InstrumentSelector
                            favoriteInstruments={favoriteInstrumentsIds}
                            onInstrumentDeselect={removeInstrument}
                            onInstrumentSelect={addInstrument}
                            ref={instrumentSelectorRef}
                        />
                        <TradingBottomFixContainer>
                            <TilesContainer>
                                {favoriteInstrumentsIds.length > 0 && (
                                    <>
                                        {favoriteInstrumentsIds.map((instrumentName) => {
                                            return (
                                                <ErrorBoundary noUI key={instrumentName}>
                                                    <TradingTile
                                                        instrumentName={instrumentName}
                                                        selectedBook={
                                                            getSelectedBook(
                                                                instrumentName,
                                                                instrumentsBooks,
                                                                authorizedClientId,
                                                            )?.data ?? EMPTY_BOOK
                                                        }
                                                        isActive={
                                                            instrumentName === selectedInstrument
                                                        }
                                                        isTopFavoriteInstrument={isTopFavoriteInstrument(
                                                            instrumentName,
                                                        )}
                                                        resetErrors={resetErrors}
                                                        authorizedClientId={authorizedClientId}
                                                        key={`${instrumentName}-${authorizedClientId}`}
                                                        removeInstrument={removeInstrument}
                                                        selectInstrument={selectInstrument}
                                                        selectedInstrument={selectedInstrument}
                                                    />
                                                </ErrorBoundary>
                                            );
                                        })}
                                        <AddInstrumentTile
                                            onAddInstrumentClick={focusInstrumentSelect}
                                        />
                                    </>
                                )}
                                {favoriteInstrumentsIds.length === 0 && <NoTilesTile />}
                            </TilesContainer>
                        </TradingBottomFixContainer>
                    </VStack>
                </LeftColumn>
                <RightColumn>
                    <TradingBottomFixContainer>
                        <WidgetsLayout>
                            <ErrorBoundary noUI>
                                <RiskManagementWidget
                                    authorizedClientId={authorizedClientId}
                                    selectedInstrument={selectedInstrument}
                                />
                            </ErrorBoundary>
                            <ErrorBoundary noUI>
                                <MarketDepthSection
                                    isLoading={isBookLoading}
                                    selectedBook={selectedBook?.data ?? EMPTY_BOOK}
                                    errorCode={selectedBook?.errorCode}
                                    selectedInstrument={selectedInstrument}
                                />
                            </ErrorBoundary>
                            <ErrorBoundary noUI>
                                <AuthorizedPositionsAccordion
                                    authorizedClientId={authorizedClientId}
                                />
                            </ErrorBoundary>
                            <ErrorBoundary noUI>
                                <LastOrdersWidget authorizedClientId={authorizedClientId} />
                            </ErrorBoundary>
                        </WidgetsLayout>
                    </TradingBottomFixContainer>
                </RightColumn>
            </Layout>
        </SelectedInstrumentContext.Provider>
    );
};

export const FirmBookTradingPageContent = () => {
    const hasTakerRoleUser = useHasTakerRoleUser();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();

    const getContent = () => {
        if (hasTakerRoleUser) {
            return <TakerTradingView />;
        }
        if (isPrimeBrokerUser && primeBrokerViewType === "subaccounts") {
            return <PrimeBrokerTradingView />;
        }

        return (
            <TradingBottomFixContainer>
                <Cluster>
                    <ErrorBoundary noUI>
                        <PositionsAccordion />
                    </ErrorBoundary>
                    <ErrorBoundary noUI>
                        <LastOrdersWidget />
                    </ErrorBoundary>
                </Cluster>
            </TradingBottomFixContainer>
        );
    };

    return <InstrumentSelectorProvider>{getContent()}</InstrumentSelectorProvider>;
};
