import React from "react";
import { useId } from "../../hooks/useId";
import { HiddenInput, Switch as SwitchContainer, SwitchBackground, SwitchCircle, SwitchLabel } from "./styled";

interface SwitchProps {
    id?: string;
    checked: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    className?: string;
}

export const Switch = ({ id: switchId, checked, onChange, disabled, className }: SwitchProps) => {
    const generatedId = useId("switch-");
    const id = switchId || generatedId;

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.code !== "Enter") {
            return;
        }

        e.preventDefault();
        onChange(!checked);
    };

    return (
        <SwitchContainer className={className}>
            <HiddenInput
                type="checkbox"
                id={id}
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
                disabled={disabled}
            />
            <SwitchLabel disabled={disabled} htmlFor={id} tabIndex={disabled ? -1 : 1} onKeyDown={handleKeyPress}>
                <SwitchBackground tabIndex={-1} />
                <SwitchCircle tabIndex={-1} />
            </SwitchLabel>
        </SwitchContainer>
    );
};
