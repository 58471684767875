import { FC } from "react";
import styled from "styled-components";

const Container = styled.div`
    overflow: auto;
    margin-top: 14px;
    flex: 1;
`;

type LightboxBodyProps = {
    className?: string;
};
export const LightboxBody: FC<LightboxBodyProps> = ({ children, className }) => {
    return <Container className={className}>{children}</Container>;
};
