import { createContext } from "react";
import { GA4 } from "./types";
import { useGA4 } from "./useGA4";

export const GA4Context = createContext<GA4 | null>(null);

export const GA4Provider: React.FC<{ measurementId?: string }> = ({ measurementId = "", children }) => {
    const ga4 = useGA4(measurementId);

    return <GA4Context.Provider value={ga4}>{children}</GA4Context.Provider>;
};
