import { DiffDataResult } from "@fm-frontend/utils";
import { FC, useCallback } from "react";
import { WhitelistingDiffTable } from "../ConfigurationModal/components/WhitelistingDiffTable";
import { BulkUpdateRequest, mapInstrumentsToRequestBody } from "../ConfigurationModal/bulkService";
import { isWhitelisted, WhitelistingImportType } from "../ConfigurationModal/utils";
import { WhitelistingBulkEditResultModal } from "../ResultModal";
import { DiffLightBox } from "../../components/DiffLightBox";

type DiffLightBoxProps = {
    importData: WhitelistingImportType[];
    diffResult: DiffDataResult;
    diffTableColumns: string[];
    onClose: () => void;
    onBack: () => void;
};
export const WhitelistingBulkEditDiffLightBox: FC<DiffLightBoxProps> = ({
    importData,
    diffResult,
    diffTableColumns,
    onClose,
    onBack,
}) => {
    const getUpdateData = useCallback(() => {
        return [mapInstrumentsToRequestBody(
            importData
                .filter(({ whitelisting }) => isWhitelisted(whitelisting))
                .map(({ instrument }) => instrument),
        )];
    }, [importData]);

    return (
        <DiffLightBox<BulkUpdateRequest>
            diffResult={diffResult}
            getUpdateData={getUpdateData}
            renderResultModal={(modalProps) => (
                <WhitelistingBulkEditResultModal {...modalProps} />
            )}
            onClose={onClose}
            onBack={onBack}
        >
            <WhitelistingDiffTable
                isLoading={diffResult.isLoading}
                columns={diffTableColumns}
                data={diffResult.diff}
            />
        </DiffLightBox>
    );
};
