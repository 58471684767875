import styled from "styled-components";
import { shadowCss, VStack } from "../layout";
import { PBold, PSmall } from "../typography";

const FormCardCaption = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

const FormCardContent = styled(VStack)`
    padding: 6px 12px;
`;

const FormCardFn = styled(VStack)`
    width: 100%;
    ${shadowCss};
    padding-top: 4px;
    padding-bottom: 4px;
`;

export const FormCard = Object.assign(FormCardFn, {
    Content: FormCardContent,
    Caption: FormCardCaption,
    Essence: PBold,
});
