import { DiffTable } from "@fm-frontend/uikit";
import { DiffDataType, noop } from "@fm-frontend/utils";
import {
    createColumnHelper,
    getCoreRowModel,
    getExpandedRowModel,
    TableOptions,
} from "@tanstack/react-table";
import { FC, useMemo } from "react";
import styled from "styled-components";
import { getInstrumentDiffTableColumnName } from "../../utils";

const CPIdDiffDataContainer = styled(DiffTable.DataCell)`
    ${DiffTable.DataCell.NormalValue}, ${DiffTable.DataCell.NewValue}, ${DiffTable.DataCell
        .OldValue} {
        color: ${(props) => props.theme.colors.ui100};
    }
`;
const AssetDiffDataContainer = styled(DiffTable.DataCell)`
    ${DiffTable.DataCell.NormalValue}, ${DiffTable.DataCell.NewValue}, ${DiffTable.DataCell
    .OldValue} {
        color: ${(props) => props.theme.colors.ui100};
        font-weight: 600;
    }
`;

const getDiffCellComponent = (columnName: string) => {
    switch (columnName) {
        case "instrument":
            return AssetDiffDataContainer;
        case "counterpartyId":
            return CPIdDiffDataContainer;
        default:
            return DiffTable.DataCell;
    }
};

export type InstrumentsDiffTableProps = {
    isLoading: boolean;
    data: DiffDataType[];
    columns: string[];
};

const columnHelper = createColumnHelper<DiffDataType>();

export const InstrumentsDiffTable: FC<InstrumentsDiffTableProps> = ({ isLoading, data, columns }) => {
    const memoColumns = useMemo(() => {
        const dataColumns = columns.map((columnName, index) => {
            const DiffCell = getDiffCellComponent(columnName);

            return columnHelper.accessor((row) => row[columnName as keyof DiffDataType], {
                id: columnName,
                header: () => <>{getInstrumentDiffTableColumnName(columnName)}</>,
                cell: (info) => {
                    return <DiffCell {...info.getValue()} />;
                },
                enableSorting: false,
                ...(index === 0 && {
                    meta: {
                        sticky: true,
                    },
                }),
            });
        });

        return [
            columnHelper.accessor((_, index) => index + 1, {
                id: "order_number",
                header: () => <></>,
                cell: (info) => {
                    return (
                        <DiffTable.RowNumberCell diff={info.row.original} value={info.getValue()} />
                    );
                },
                enableSorting: false,
                meta: {
                    headerStyleProps: {
                        width: "32px",
                    },
                    cellStyleProps: {
                        width: "32px",
                    },
                },
            }),
            ...dataColumns,
            columnHelper.display({
                id: "column_spacer",
                header: () => <></>,
                enableSorting: false,
                meta: {
                    headerStyleProps: {
                        width: "32px",
                    },
                    cellStyleProps: {
                        width: "32px",
                    },
                },
            }),
        ];
    }, [columns]);

    const tableOptions: TableOptions<DiffDataType> = {
        data: data,
        columns: memoColumns,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
    };

    return <DiffTable isLoading={isLoading} tableOptions={tableOptions} onRowClick={noop} />;
};
