import {
    BasicInput,
    Form,
    FormActions,
    FormAttention,
    FormBody,
    FormInfo,
    FormProgress,
    Icons,
    P,
    PrimaryButton,
    ShadowCard,
    Skeleton,
} from "@fm-frontend/uikit";
import { Header as FormHeader } from "@fm-frontend/uikit/src/components/headers/Header";
import { HeaderTitle } from "@fm-frontend/uikit/src/components/headers/HeaderTitle";
import { ValueFormat } from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CopyToClipboard } from "components/CopyToClipboard";
import { IconError } from "components/icons";
import { get2FATitle } from "const/env";
import { useSelector } from "hooks";
import QRCode from "qrcode.react";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { verifyMfa } from "store/auth/actions";
import { authenticationSlice } from "store/auth/slice";
import {
    isOnboardingActive as isOnboardingActiveSelector,
    OnboardingMap,
    ONBOARDING_STEPS,
} from "store/onboardingSlice";
import styled, { useTheme } from "styled-components";
import { ConfirmMfaInputs, confirmTwoFaDataSchema } from "./schemas";

const SecretCodeContainer = styled.div`
    min-width: 136px;
    min-height: 24px;
    overflow: scroll;
    display: flex;
    align-items: center;
    padding: 0 8px;

    background: ${(p) => p.theme.colors.ui8};
    border-radius: 40px;
    margin-right: 4px;
`;

const SkeletonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 4px;

    & > div:first-of-type {
        width: 145px;
        border-radius: 40px;
    }
    & > div:nth-of-type(2) {
        width: 24px;
        border-radius: 50%;
    }
`;

const SecretCodeSkeleton = () => {
    const { colors } = useTheme();

    return (
        <>
            <Skeleton width="128px" height="128px" />
            <P color={colors.ui52}>or, input the secret key in app manually</P>
            <SkeletonContainer>
                <Skeleton width="172px" height="24px" />
                <Skeleton width="20px" height="24px" />
            </SkeletonContainer>
        </>
    );
};

export const VerifyMfa: FC = () => {
    const dispatch = useDispatch();
    const { colors } = useTheme();
    const isOnboardingActive = useSelector(isOnboardingActiveSelector);
    const sharedSecret = useSelector((state) => state.authentication.sharedSecret) || "";
    const authError = useSelector((state) => state.authentication.error);
    const companyEmail = useSelector((state) => state.authentication.clientData?.username);
    const mfaURL = `otpauth://totp/${get2FATitle()}(${companyEmail})?secret=${sharedSecret}`;

    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
    } = useForm<ConfirmMfaInputs>({
        defaultValues: {
            sharedSecret: sharedSecret,
        },
        mode: "onSubmit",
        resolver: yupResolver(confirmTwoFaDataSchema),
    });

    const submitVerifyMfa = async (confirmInputs: ConfirmMfaInputs) => {
        // @ts-ignore
        await dispatch(verifyMfa(confirmInputs));
    };

    const handleBackClick = () => {
        dispatch(authenticationSlice.actions.backToMfaSetUp());
    };

    return (
        <Form onSubmit={handleSubmit(submitVerifyMfa)}>
            {isOnboardingActive && (
                <FormProgress
                    currentStep={
                        OnboardingMap[ONBOARDING_STEPS.ACCOUNT_CONFIRM_TWO_FA].progress.current
                    }
                    totalSteps={
                        OnboardingMap[ONBOARDING_STEPS.ACCOUNT_CONFIRM_TWO_FA].progress.total
                    }
                    onBackClick={handleBackClick}
                />
            )}
            <ShadowCard>
                <FormHeader>
                    <HeaderTitle title="Set two-factor authentication" />
                </FormHeader>
                <FormBody>
                    <FormInfo>
                        First, <span>scan the QR code</span> below with Google Authenticator or
                        similar app
                    </FormInfo>
                    {sharedSecret ? (
                        <>
                            <QRCode value={mfaURL} />
                            <P color={colors.ui52}>or, input the secret key in app manually</P>
                            <CopyToClipboard value={sharedSecret}>
                                <SecretCodeContainer>
                                    {ValueFormat.trimLongText(sharedSecret)}
                                </SecretCodeContainer>
                                <Icons.CopyIcon />
                            </CopyToClipboard>
                        </>
                    ) : (
                        <SecretCodeSkeleton />
                    )}
                    <FormAttention>
                        <IconError />
                        <P>
                            We strongly recommend to keep this key in a safe place to restore the
                            access in case of a phone loss.
                        </P>
                    </FormAttention>
                </FormBody>
            </ShadowCard>
            <ShadowCard>
                <FormBody>
                    <FormInfo>
                        Then <span>enter the 6-digit</span> code from the app
                    </FormInfo>
                    <BasicInput
                        placeholder="000000"
                        {...register("code")}
                        error={errors.code?.message}
                    />
                    {authError && (
                        <FormAttention>
                            <IconError />
                            <P>{authError.message}</P>
                        </FormAttention>
                    )}
                </FormBody>
                <FormActions variant="plain">
                    <PrimaryButton fullWidth size="large" loading={isSubmitting}>
                        Send
                    </PrimaryButton>
                </FormActions>
            </ShadowCard>
        </Form>
    );
};
