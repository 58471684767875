import { FC } from "react";
import styled from "styled-components";

export const DEFAULT_HEIGHT = 24;

const Image = styled.img<{ $height: number }>`
    height: ${(p) => p.$height}px;
`;

type LogoProps = {
    height?: number;
};

export const Logo: FC<LogoProps> = ({ height = DEFAULT_HEIGHT }) => <Image src="/img/logo.svg" $height={height} />;
