import { downloadCsv, parseToCsv, useModalControl } from "@fm-frontend/utils";
import { FC, ReactNode, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { BasicButton, PrimaryButton } from "../buttons";
import { SwitchField } from "../common";
import { DatePicker } from "../DatePicker";
import { BasicInput } from "../inputs";
import { Modal, ModalProps, MODAL_TOP_OFFSET } from "../Modal";
import { H2 } from "../typography";

const FIXED_MODAL_STYLE = {
    overlay: {
        overflow: "auto",
    },
    content: {
        top: `${MODAL_TOP_OFFSET}px`,
        left: "50%",
        transform: "translate(-50%)",
    },
};

const Content = styled.div`
    width: 360px;
    padding: 12px;
`;

const Title = styled(H2)`
    padding-bottom: 12px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 16px;
`;

type ExportRow = Record<string, any>;
type ExportData = ExportRow[];

type ExportCSVProps = {
    getData: ({}: { startDate?: Date | null; endDate?: Date | null }) => ExportData | Promise<ExportData>;
    defaultStartDate?: Date | null;
    defaultEndDate?: Date | null;
    defaultFileName?: string;
    defaultIncludeHeaders?: boolean;
    title?: string | ReactNode;
    size?: "small" | "medium" | "large";
    modalStyles?: ModalProps["style"];
    onSuccess?: () => void;
    onError?: () => void;
    onConfirm?: (cb: () => void) => void;
};

export const ExportCSV: FC<ExportCSVProps> = ({
    getData,
    defaultStartDate,
    defaultEndDate,
    defaultFileName = "",
    defaultIncludeHeaders = true,
    title = "Export in .CSV",
    size = "medium",
    modalStyles = FIXED_MODAL_STYLE,
    onSuccess,
    onError,
    onConfirm,
}) => {
    const { isModalOpen, openModal, closeModal: closeModalPopup } = useModalControl(false);
    const [isExporting, setIsExporting] = useState(false);
    const [range, setRange] = useState<{ startDate?: Date | null; endDate?: Date | null }>({
        startDate: defaultStartDate,
        endDate: defaultEndDate,
    });

    const { register, control, watch, reset, formState: { isDirty } } = useForm({
        defaultValues: {
            fileName: defaultFileName,
            includeHeaders: defaultIncludeHeaders,
        },
    });
    const fileName = watch("fileName") ?? "untitled";
    const includeHeaders = watch("includeHeaders");

    const closeModal = () => {
        reset({
            fileName: defaultFileName,
            includeHeaders: defaultIncludeHeaders,
        });
        closeModalPopup();
    };
    const closeModalWithConfirm = () => {
        if (isDirty && onConfirm) {
            onConfirm(closeModal);
        } else {
            closeModal();
        }
    };

    const handleExport = async () => {
        try {
            setIsExporting(true);
            const exportData = await getData(range);
            const csv = parseToCsv(exportData, { header: includeHeaders });
            downloadCsv(csv, fileName);
            closeModal();
            onSuccess?.();
        } catch (e) {
            onError?.();
        } finally {
            setIsExporting(false);
        }
    };

    return (
        <>
            <Modal isOpen={isModalOpen} onClose={closeModalWithConfirm} style={modalStyles}>
                <Content>
                    <Title>{title}</Title>
                    <Body>
                        <DatePicker
                            key={`${range.startDate}-${range.endDate}`}
                            label="Time period"
                            startDate={range.startDate}
                            endDate={range.endDate}
                            onConfirm={setRange}
                            size="medium"
                            fullWidth
                            simple
                        />
                        <BasicInput label="File name" placeholder="file_name" autoFocus {...register("fileName")} />
                        <SwitchField
                            variant="basic"
                            label="Option"
                            text="Include headers"
                            name="includeHeaders"
                            control={control}
                            fullWidth
                        />
                    </Body>
                    <PrimaryButton size="large" fullWidth loading={isExporting} onClick={handleExport}>
                        Export .CSV
                    </PrimaryButton>
                </Content>
            </Modal>
            <BasicButton size={size} onClick={openModal}>
                Export in .CSV
            </BasicButton>
        </>
    );
};
