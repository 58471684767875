import { Icons, PSmallBold } from "@fm-frontend/uikit";
import React from "react";
import { useTheme } from "styled-components";
import { StatusKYBVariant } from "../../../store/onboardingSlice";
import { Status } from "./styled";

export const StatusKVB: React.VFC<{ statusKYB: StatusKYBVariant }> = ({ statusKYB }) => {
    const { colors } = useTheme();

    if (statusKYB === StatusKYBVariant.Started) {
        return (
            <Status color={colors.process8}>
                <PSmallBold color={colors.process100}>Verification</PSmallBold>
            </Status>
        );
    }
    if (statusKYB === StatusKYBVariant.Finished) {
        return (
            <Status color={colors.brand8}>
                <Icons.Checkmark color={colors.brand100} />
                <PSmallBold color={colors.brand100}>Counterparty</PSmallBold>
            </Status>
        );
    }

    return null;
};
