import { useCallback, useState } from "react";

export * from "./modal/useModalControl";
export * from "./useCombinedRef";
export * from "./useCopyToClipboard";

export const useToggle = (defaultActive?: boolean) => {
    const [isActive, setIsActive] = useState(Boolean(defaultActive));

    const activate = useCallback(() => setIsActive(true), [setIsActive]);
    const deactivate = useCallback(() => setIsActive(false), [setIsActive]);
    const toggle = useCallback(() => setIsActive((isActive) => !isActive), [setIsActive]);

    return {
        isActive,
        activate,
        deactivate,
        toggle,
    };
};
