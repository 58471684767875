import { Icons, PSmall, PSmallBold, Tooltip } from "@fm-frontend/uikit";
import { HelperContainer } from "components/Helper/HelperContainer";
import { LinkFAQRiskManagement } from "components/Links";
import { CounterpartyIndicator } from "feature/counterparties/counterparty/counterpartyIndicator";
import { getEquityIndicatorData } from "feature/counterparties/counterparty/utils";
import { shouldUseUserGrossLimit } from "feature/counterparties/utils";
import { useCounterpartiesStatuses } from "pages/riskManagement/hooks/useCounterpartiesStatuses";
import { FC, lazy, useMemo } from "react";
import { useIsMakerUser } from "store/hooks";
import { ClientType, CounterpartyLimit } from "types";

const EquityFormulaDark = lazy(async () => ({
    default: (await import("resources/equity_formula_dark.svg")).ReactComponent,
}));

const EquityHelper = () => (
    <HelperContainer>
        <PSmallBold>Margin requirement</PSmallBold>
        <PSmall>This is the sum of net open positions:</PSmall>
        <EquityFormulaDark />
        <PSmallBold>
            <LinkFAQRiskManagement />
        </PSmallBold>
    </HelperContainer>
);

type EquityProps = {
    limit: CounterpartyLimit;
    cpType: ClientType;
    cpName: string;
};

const Helper = () => (
    <Tooltip content={<EquityHelper />} isHoveredContent>
        <Icons.Help size={11} />
    </Tooltip>
);

export const Equity: FC<EquityProps> = ({ cpType, cpName, limit }) => {
    const [counterpartyId] = limit;
    const { data: cpStatuses } = useCounterpartiesStatuses();
    const cpStatus = cpStatuses[counterpartyId];
    const { status, equityStatus } = cpStatus ?? {};
    const isMakerUser = useIsMakerUser();
    const shouldTakeUserGrossLimit = shouldUseUserGrossLimit(cpType, isMakerUser);
    const equityIndicator = useMemo(
        () => getEquityIndicatorData(limit, shouldTakeUserGrossLimit, cpType, cpName),
        [limit, shouldTakeUserGrossLimit, cpType, cpName],
    );

    return (
        <CounterpartyIndicator
            status={status}
            subStatus={equityStatus}
            indicatorData={equityIndicator}
            mainIndicator={true}
            highlighted={true}
            labelHelper={<Helper />}
        />
    );
};
