const MS_IN_MIN = 60 * 1000;
const MS_IN_HOUR = MS_IN_MIN * 60;
const MS_IN_DAY = MS_IN_HOUR * 24;
const MS_IN_MONTH = MS_IN_DAY * 30;

export const getTimeAgoInfo = (timestamp: number): { label: string; updateIn: number } => {
    const timeSpanInMs = Date.now() - timestamp;

    if (timeSpanInMs < MS_IN_MIN) {
        return {
            label: "now",
            updateIn: MS_IN_MIN - timeSpanInMs,
        };
    }

    if (timeSpanInMs >= MS_IN_MONTH) {
        const monthNumber = Math.trunc(timeSpanInMs / MS_IN_MONTH);
        const label = monthNumber === 1 ? "month" : "months";

        return {
            label: `${monthNumber} ${label} ago`,
            updateIn: MS_IN_MONTH - (timeSpanInMs % MS_IN_MONTH),
        };
    }

    if (timeSpanInMs >= MS_IN_DAY) {
        const dayNumber = Math.trunc(timeSpanInMs / MS_IN_DAY);
        const label = dayNumber === 1 ? "day" : "days";

        return {
            label: `${dayNumber} ${label} ago`,
            updateIn: MS_IN_DAY - (timeSpanInMs % MS_IN_DAY),
        };
    }

    if (timeSpanInMs >= MS_IN_HOUR) {
        const hourNumber = Math.trunc(timeSpanInMs / MS_IN_HOUR);
        const label = hourNumber === 1 ? "hour" : "hours";

        return {
            label: `${hourNumber} ${label} ago`,
            updateIn: MS_IN_HOUR - (timeSpanInMs % MS_IN_HOUR),
        };
    }

    const minuteNumber = Math.trunc(timeSpanInMs / MS_IN_MIN);
    const label = minuteNumber === 1 ? "minute" : "minutes";

    return {
        label: `${minuteNumber} ${label} ago`,
        updateIn: MS_IN_MIN - (timeSpanInMs % MS_IN_MIN),
    };
};
