import styled from "styled-components";

type IndicatorProgressbarProps = {
    $percent: string | number;
}

export const CircularProgressbar = styled.div
    .attrs<IndicatorProgressbarProps>(
        (p) =>({ style: { "--percent": p.$percent }})
    )<IndicatorProgressbarProps>`
        @property --percent {
            syntax: "<number>";
            inherits: true;
            initial-value: 0;
        }
        @keyframes percent {
            from {
                --percent: 0;
            }
        }

        animation: percent 1s alternate;
        transition: --percent 1s;

        width: 16px;
        height: 16px;

        border-radius: 50%;
        background: conic-gradient(
            rgba(24, 189, 90, 1) calc(var(--percent) * 1%),
            ${(p) => p.theme.colors.ui12} 0
        );
        mask: radial-gradient(
            farthest-side,
            #0000 calc(99% - 3px),
            #000 calc(100% - 3px)
        );
    `;
