import styled from "styled-components";
import { InstrumentWhitelistingSwitch } from "../../InstrumentWhitelistingSwitch";

const Container = styled.span`
    float: right;
`;

type WhitelistingSwitchCellProps = {
    instrumentName: string;
};
export const WhitelistingSwitchCell = ({ instrumentName }: WhitelistingSwitchCellProps) => (
    <Container onClick={(ev) => ev.stopPropagation()}>
        <InstrumentWhitelistingSwitch instrumentName={instrumentName} />
    </Container>
);
