import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { ConfirmSignInOutput, SignInOutput } from "aws-amplify/auth";
import { ClientData } from "types";
import { completeNewPassword, confirmSignIn, signIn, signOut, signUp, verifyMfa } from "./actions";

type AuthError = {
    message: string;
    code: string;
};
type AuthenticationStatus =
    | "authenticated"
    | "unauthenticated"
    | "signUp"
    | "signIn"
    | "confirmSignIn"
    | "setUpMfa"
    | "verifyMfa"
    | "completedNewPassword";
type AuthenticationState = {
    status: AuthenticationStatus;
    error: AuthError | null;
    loading: boolean;
    sharedSecret: string | null;
    challengeName: string | null;

    clientData: ClientData | null;
    userId: number | null;
};

const initialState: AuthenticationState = {
    status: "unauthenticated",
    error: null,
    loading: false,
    sharedSecret: null,
    challengeName: null,

    clientData: null,
    userId: null,
};

const getNextAuthStatusFromChallenge = (challenge: string): AuthenticationStatus => {
    switch (challenge) {
        case "CONTINUE_SIGN_IN_WITH_TOTP_SETUP":
            return "setUpMfa";
        case "CONFIRM_SIGN_IN_WITH_TOTP_CODE":
            return "confirmSignIn";
        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
            return "completedNewPassword";
        case "DONE":
            return "authenticated";
        default:
            return "signIn";
    }
};

export const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        setAuthenticated: (state) => {
            state.status = "authenticated";
            state.error = null;
        },
        startSignIn: (state) => {
            state.status = "signIn";
            state.error = null;
        },
        startSignUp: (state) => {
            state.status = "signUp";
            state.error = null;
        },
        backToMfaSetUp: (state) => {
            state.status = "setUpMfa";
            state.error = null;
        },
        backToSignIn: (state) => {
            state.status = "signIn";
            state.error = null;
        },
        setClientData(state, { payload: clientData }: PayloadAction<ClientData | null>) {
            state.clientData = clientData;

            if (clientData) {
                state.userId = clientData.clientId;
            }

            state.error = null;
        },
        setUpMfa(state) {
            state.status = "verifyMfa";
            state.error = null;
        },
        resetError(state) {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(confirmSignIn.fulfilled, (state) => {
            state.status = "authenticated";
            state.loading = false;
            state.error = null;
        });
        builder.addCase(signOut.fulfilled, (state) => {
            state.status = "unauthenticated";
            state.loading = false;
            state.error = null;
        });
        builder.addCase(verifyMfa.fulfilled, (state) => {
            state.status = "authenticated";
            state.loading = false;
            state.error = null;
        });

        builder.addMatcher(
            isAnyOf(signUp.fulfilled, signIn.fulfilled, completeNewPassword.fulfilled),
            (state, action: PayloadAction<SignInOutput | ConfirmSignInOutput>) => {
                if (action.payload.nextStep.signInStep === "CONTINUE_SIGN_IN_WITH_TOTP_SETUP") {
                    state.sharedSecret = action.payload.nextStep.totpSetupDetails.sharedSecret;
                }

                state.status = getNextAuthStatusFromChallenge(action.payload.nextStep.signInStep);
                state.challengeName = action.payload.nextStep.signInStep;
                state.loading = false;
                state.error = null;
            },
        );
        builder.addMatcher(
            isAnyOf(
                signUp.pending,
                confirmSignIn.pending,
                signOut.pending,
                completeNewPassword.pending,
                signIn.pending,
                verifyMfa.pending,
            ),
            (state) => {
                state.loading = true;
                state.error = null;
            },
        );
        builder.addMatcher(
            isAnyOf(
                signUp.rejected,
                confirmSignIn.rejected,
                signOut.rejected,
                completeNewPassword.rejected,
                signIn.rejected,
                verifyMfa.rejected,
            ),
            (state, action: PayloadAction<unknown>) => {
                state.loading = false;
                state.error = action.payload as AuthError;
            },
        );
    },
});
