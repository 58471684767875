import { P } from "@fm-frontend/uikit";
import React from "react";
import styled from "styled-components";
import { DealSide } from "types";

const SellIndicator = styled(P)`
    color: ${(p) => p.theme.colors.negative100};
`;
const BuyIndicator = styled(P)`
    color: ${(p) => p.theme.colors.positive100};
`;

type SideCelProps = {
    side?: DealSide;
};
export const SideCell: React.FC<SideCelProps> = ({ side }) => {
    if (side === undefined) {
        return <></>;
    }

    return side === DealSide.Buy ? <BuyIndicator>Buy</BuyIndicator> : <SellIndicator>Sell</SellIndicator>;
};
