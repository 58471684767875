import styled from "styled-components";

export const Placeholder = styled.div<{
    width?: string;
    height?: string;
    bgColor?: string;
    highlightColor?: string;
}>`
    display: inline-block;
    height: ${(p) => p.height || "30px"};
    width: ${(p) => p.width || "100%"};

    background-color: ${(p) => p.bgColor || p.theme.colors.ui8};
    border-radius: 4px;
`;
