import {
    FieldGroup,
    H2,
    HStack,
    PlainButton,
    PrimaryButton,
    Tab,
    TabContext,
    TabList,
    TabPanel,
    VStack,
} from "@fm-frontend/uikit";
import { DropZone } from "@fm-frontend/uikit/src/components/dropZone";
import { DropzoneContextProvider } from "@fm-frontend/uikit/src/components/dropZone/DropzoneContext";
import { useFileContext } from "@fm-frontend/uikit/src/components/dropZone/FileContext";
import { ANIMATION_TIMEOUT } from "@fm-frontend/uikit/src/components/Modal/styled";
import { DropdownOption } from "@fm-frontend/uikit/src/components/v2";
import { Switch } from "@fm-frontend/uikit/src/components/v2/Switch";
import {
    DiffDataResult,
    downloadCsv,
    ExportData,
    parseToCsv,
    parseToJson,
    useFormCloseConfirmer,
    ValueFormat,
} from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AssetsMultipleGroupedDropdown } from "components/customMultipleDropdowns/AssetsMultipleGroupedDropdown";
import { FilledCounterpartiesMultipleGroupedDropdown } from "components/customMultipleDropdowns/CounterpartiesMultipleGroupedDropdown";
import { format } from "date-fns";
import { BULK_EDIT_CONTROL_MODAL_KEY } from "feature/assetsControl/utils";
import { useInstruments } from "hooks";
import { useActionPermissions } from "hooks/useActionPermissions";
import { fmtDeltaratePercent } from "utils/format";
import { AssetsBulkEditDiffLightBox } from "../DiffLightBox";
import { BulkEditModalHint } from "../../components/ModalHint";
import { useBulkLogger } from "../../hooks/useBulkLogger";
import { useBulkEditData } from "./hooks/useBuklEditData";
import { assetsBulkEditFormSchema } from "./schema";
import {
    AssetsImportType,
    AssetsOriginalDataType,
    CSV_COLUMNS_TO_DATA_TYPE,
    CSV_VALIDATION_ERROR_MESSAGES,
    DATA_TYPE_TO_CSV_COLUMNS,
    EMPTY_VALUE,
    formatShortSales,
    LIMIT_HEADERS_PAIR,
    REQUIRED_CSV_COLUMNS,
    setValueOrRestricted,
    ValidationContext,
} from "./utils";

enum TabEnum {
    Export = "export",
    Import = "import",
}

type AssetsBulkEditFormInputs = {
    assets: string[];
    cps: number[];
    shortSales: boolean;
    overnightFees: boolean;
    limits: boolean;
    // aggregated field for switches validation
    adjust?: boolean;
};

type AssetsBulkEditModalProps = {
    onCancel: () => void;
};

export const AssetsBulkEditModal: FC<AssetsBulkEditModalProps> = ({ onCancel }) => {
    const { logBulkExport, logBulkImport } = useBulkLogger();
    const bulkEditData = useBulkEditData();
    const [tab, setTab] = useState(TabEnum.Export);
    const [isLightboxActive, setIsLightboxActive] = useState(false);

    const { acceptedFiles, resetFiles, setExternalError, externalError } = useFileContext();
    const { getUserActionPermissions, actionsPermissions } = useActionPermissions();

    const [diffResult, setDiffResult] = useState<DiffDataResult>({
        hasErrors: false,
        hasDiffs: false,
        isLoading: true,
        diff: [],
    });
    const [importDataResult, setImportDataResult] = useState<AssetsImportType[]>([]);
    const [exportHeaders, setExportHeaders] = useState<string[]>([]);

    const originalExportData: AssetsOriginalDataType[] = useMemo(
        () =>
            bulkEditData.map((item) => {
                const permissions = getUserActionPermissions(item.cpId);

                return {
                    asset: item.currencyName,
                    cpId: item.cpId,
                    cpType: item.cpType,
                    cpName: item.cpName,
                    cp: item.cpName,
                    shortSales: permissions.isShortSalesBanAllowed ? item.shortSales : undefined,
                    overnightsLong: permissions.isOvernightsAllowed ? item.overnightsLong : undefined,
                    overnightsShort: permissions.isOvernightsAllowed ? item.overnightsShort : undefined,
                    limitCurrency: permissions.isLimitPerAssetAllowed
                        ? item.limitCurrency
                        : undefined,
                    limitAmount: permissions.isLimitPerAssetAllowed ? item.limitAmount : undefined,
                } as AssetsOriginalDataType;
            }),
        [bulkEditData],
    );

    const { currencies } = useInstruments();
    const currenciesOptions = useMemo(
        () =>
            Object.keys(currencies).map(
                (currencyId) =>
                    ({
                        text: currencyId,
                        value: currencyId,
                    } as DropdownOption),
            ),
        [currencies],
    );

    const validationContext: ValidationContext = useMemo(
        () => ({
            originalValues: originalExportData,
            availableAssets: currenciesOptions.map((o) => o.value),
            currencies,
            actionsPermissions,
        }),
        [originalExportData, currenciesOptions, currencies, actionsPermissions],
    );

    const {
        control,
        handleSubmit,
        formState: { isSubmitting, errors, isDirty },
    } = useForm<AssetsBulkEditFormInputs>({
        defaultValues: {
            assets: [],
            cps: [],
            shortSales: true,
            overnightFees: true,
            limits: true,
        },
        resolver: yupResolver(assetsBulkEditFormSchema),
        mode: "onChange",
    });

    useFormCloseConfirmer(
        BULK_EDIT_CONTROL_MODAL_KEY,
        tab === TabEnum.Export ? isDirty : false,
    );

    const handleFileParsing = async () => {
        if (!acceptedFiles.length) {
            setExternalError(CSV_VALIDATION_ERROR_MESSAGES.fileMissed);
            return;
        }

        const importFile = acceptedFiles[0];
        const result = await parseToJson(importFile, {
            header: true,
            transformHeader: (header) => {
                const normalizedHeader = header.replace(/^"|"$/g, "");

                return CSV_COLUMNS_TO_DATA_TYPE[normalizedHeader] ?? header;
            },
        });

        if (result.errors.length) {
            setExternalError(CSV_VALIDATION_ERROR_MESSAGES.invalidCharacters);
            return;
        }

        const csvImportFileColumns = result.meta?.fields ?? [];

        if (
            !Object.keys(REQUIRED_CSV_COLUMNS).every((c) =>
                csvImportFileColumns.some((k) => k === c),
            )
        ) {
            setExternalError(
                CSV_VALIDATION_ERROR_MESSAGES.missingRequiredColumns(
                    Object.values(REQUIRED_CSV_COLUMNS),
                ),
            );
            return;
        }
        if (
            !csvImportFileColumns.every((c) =>
                Object.keys(DATA_TYPE_TO_CSV_COLUMNS).some((k) => k === c),
            )
        ) {
            setExternalError(CSV_VALIDATION_ERROR_MESSAGES.headersMismatchFormat);
            return;
        }

        const limitColumns = csvImportFileColumns.filter((csvHeader) =>
            LIMIT_HEADERS_PAIR.includes(csvHeader as keyof AssetsOriginalDataType),
        );

        const hasNoLimitColumnPair =
            limitColumns.length > 0 && limitColumns.length !== LIMIT_HEADERS_PAIR.length;

        if (hasNoLimitColumnPair) {
            setExternalError(CSV_VALIDATION_ERROR_MESSAGES.headersMismatchFormat);
            return;
        }

        setExportHeaders(csvImportFileColumns);
        setImportDataResult(result.data as AssetsImportType[]);
        setIsLightboxActive(true);
        setExternalError("");

        try {
            const worker = new Worker(new URL("./bulkEditValidator.worker.ts", import.meta.url));

            worker.postMessage({
                importData: result.data,
                validationContext,
            });

            worker.addEventListener("message", ({ data }: MessageEvent<DiffDataResult>) => {
                worker.terminate();
                setDiffResult(data);
            });
        } catch (e) {
            setExternalError(String(e));

            throw e;
        }
    };

    useEffect(() => {
        if (!isLightboxActive && acceptedFiles.length && !externalError) {
            handleFileParsing();
            logBulkImport();
        }
    }, [isLightboxActive, acceptedFiles, externalError]);

    const handleExportFormSubmit = handleSubmit(async (inputs: AssetsBulkEditFormInputs) => {
        const exportData: ExportData[] = bulkEditData
            .filter(
                (item) =>
                    inputs.assets.includes(item.currencyName) && inputs.cps.includes(item.cpId),
            )
            .map((item) => {
                const permissions = getUserActionPermissions(item.cpId);

                return {
                    [DATA_TYPE_TO_CSV_COLUMNS.asset]: item.currencyName,
                    [DATA_TYPE_TO_CSV_COLUMNS.cpId]: String(item.cpId),
                    [DATA_TYPE_TO_CSV_COLUMNS.cpType]: item.cpType,
                    [DATA_TYPE_TO_CSV_COLUMNS.cpName]: item.cpName,
                    ...(inputs.shortSales && {
                        [DATA_TYPE_TO_CSV_COLUMNS.shortSales]: setValueOrRestricted(
                            permissions.isShortSalesBanAllowed,
                            formatShortSales(item.shortSales).toUpperCase(),
                        ),
                    }),
                    ...(inputs.overnightFees && {
                        [DATA_TYPE_TO_CSV_COLUMNS.overnightsLong]: setValueOrRestricted(
                            permissions.isOvernightsAllowed,
                            item.overnightsLong === undefined
                                ? EMPTY_VALUE
                                : fmtDeltaratePercent(item.overnightsLong).copyableValue,
                        ),
                        [DATA_TYPE_TO_CSV_COLUMNS.overnightsShort]: setValueOrRestricted(
                            permissions.isOvernightsAllowed,
                            item.overnightsShort === undefined
                                ? EMPTY_VALUE
                                : fmtDeltaratePercent(item.overnightsShort).copyableValue,
                        ),
                    }),
                    ...(inputs.limits && {
                        [DATA_TYPE_TO_CSV_COLUMNS.limitCurrency]: setValueOrRestricted(
                            permissions.isLimitPerAssetAllowed,
                            item.limitCurrency ?? EMPTY_VALUE,
                        ),
                        [DATA_TYPE_TO_CSV_COLUMNS.limitAmount]: setValueOrRestricted(
                            permissions.isLimitPerAssetAllowed,
                            item.limitAmount === undefined
                                ? EMPTY_VALUE
                                : ValueFormat.formSize(item.limitAmount),
                        ),
                    }),
                };
            });
        const csv = parseToCsv(exportData, {
            header: true,
        });
        downloadCsv(csv, `assets_bulk_configuration_${format(new Date(), "yyyyMMddHHmm")}`);
        logBulkExport();
    });

    const handleCancel = useCallback(() => {
        setIsLightboxActive(false);
        resetFiles();
        setImportDataResult([]);

        // reset table data after modal closing animation over
        setTimeout(() => {
            setDiffResult({
                hasErrors: false,
                hasDiffs: false,
                isLoading: true,
                diff: [],
            });
        }, ANIMATION_TIMEOUT);
    }, [resetFiles, setImportDataResult, setDiffResult]);

    return (
        <>
            <VStack width="360px" asCard>
                <VStack padding={12}>
                    <H2>Bulk edit configuration</H2>
                </VStack>
                <VStack paddingX={12} paddingBottom={12}>
                    <TabContext value={tab} handleClick={(v) => setTab(v as TabEnum)}>
                        <TabList size="small">
                            <Tab title="New template" value={TabEnum.Export} />
                            <Tab title="Import config" value={TabEnum.Import} />
                        </TabList>
                        <TabPanel value={TabEnum.Export}>
                            <form onSubmit={handleExportFormSubmit}>
                                <FieldGroup paddingBottom={12}>
                                    <BulkEditModalHint />
                                </FieldGroup>
                                <FieldGroup paddingBottom={16}>
                                    <Controller
                                        control={control}
                                        render={({ field }) => (
                                            <AssetsMultipleGroupedDropdown
                                                values={field.value}
                                                onChange={field.onChange}
                                                error={errors.assets?.message}
                                            />
                                        )}
                                        name="assets"
                                    />
                                    <Controller
                                        control={control}
                                        render={({ field }) => (
                                            <FilledCounterpartiesMultipleGroupedDropdown
                                                values={field.value}
                                                onChange={field.onChange}
                                                error={errors.cps?.message}
                                            />
                                        )}
                                        name="cps"
                                    />
                                </FieldGroup>
                                <FieldGroup.Title>Adjust</FieldGroup.Title>
                                <FieldGroup paddingBottom={12}>
                                    <Controller
                                        control={control}
                                        render={({ field }) => (
                                            <Switch
                                                size="large"
                                                variant="simple"
                                                checked={field.value}
                                                onChange={field.onChange}
                                                fullWidth
                                                innerCaption="Short sales"
                                                error={
                                                    errors.shortSales?.message ??
                                                    errors.adjust?.message
                                                }
                                            />
                                        )}
                                        name="shortSales"
                                    />
                                    <Controller
                                        control={control}
                                        render={({ field }) => (
                                            <Switch
                                                size="large"
                                                variant="simple"
                                                checked={field.value}
                                                onChange={field.onChange}
                                                fullWidth
                                                innerCaption="Overnight"
                                                error={
                                                    errors.overnightFees?.message ?? errors.adjust?.message
                                                }
                                            />
                                        )}
                                        name="overnightFees"
                                    />
                                    <Controller
                                        control={control}
                                        render={({ field }) => (
                                            <Switch
                                                size="large"
                                                variant="simple"
                                                checked={field.value}
                                                onChange={field.onChange}
                                                fullWidth
                                                innerCaption="Limits"
                                                error={
                                                    errors.limits?.message ?? errors.adjust?.message
                                                }
                                            />
                                        )}
                                        name="limits"
                                    />
                                </FieldGroup>

                                <VStack spacing={8}>
                                    <PrimaryButton
                                        type="submit"
                                        fullWidth
                                        size="large"
                                        disabled={isSubmitting}
                                    >
                                        Export .CSV
                                    </PrimaryButton>
                                    <PlainButton
                                        fullWidth
                                        size="large"
                                        onClick={onCancel}
                                        type="button"
                                    >
                                        Cancel
                                    </PlainButton>
                                </VStack>
                            </form>
                        </TabPanel>
                        <TabPanel value={TabEnum.Import}>
                            <FieldGroup paddingBottom={12}>
                                <BulkEditModalHint />
                            </FieldGroup>
                            <VStack width="100%" spacing={16}>
                                <HStack height="258px" width="100%">
                                    <DropzoneContextProvider
                                        options={{
                                            disabled: false,
                                            accept: { "text/csv": [".csv"] },
                                            multiple: false,
                                        }}
                                    >
                                        <DropZone onCancel={handleCancel} />
                                    </DropzoneContextProvider>
                                </HStack>
                                <PlainButton
                                    fullWidth
                                    size="large"
                                    onClick={onCancel}
                                    type="button"
                                    disabled={isSubmitting}
                                >
                                    Cancel
                                </PlainButton>
                            </VStack>
                            {isLightboxActive && (
                                <AssetsBulkEditDiffLightBox
                                    diffTableColumns={exportHeaders}
                                    diffResult={diffResult}
                                    importData={importDataResult}
                                    onClose={onCancel}
                                    onBack={handleCancel}
                                />
                            )}
                        </TabPanel>
                    </TabContext>
                </VStack>
            </VStack>
        </>
    );
};
