import {
    BasicButton,
    Center,
    FormProgress,
    H1,
    H2Bold,
    HStack,
    Icons,
    Modal,
    P,
    PBold,
    ShadowCard,
    VStack,
} from "@fm-frontend/uikit";
import { useModalControl } from "@fm-frontend/utils";
import { CopyToClipboard } from "components/CopyToClipboard";
import { TnCPrint } from "components/TnCPrint";
import { onMobile } from "const";
import format from "date-fns/format";
import { useSelector } from "hooks";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { startUserOnboarding } from "store/onboardingActions";
import {
    OnboardingMap,
    ONBOARDING_STEPS,
    setIsTermsAndConditionsDelegated,
} from "store/onboardingSlice";
import styled, { useTheme } from "styled-components";
import { Header, Layout, OnboardingInfoBanner } from "../../common";
import { ForwardSigningLink } from "./modals/ForwardSigningLink";
import { SignTAndC } from "./modals/SignTAndC";
import { SigningTAndC } from "./SigningxTAndC";
import { TermsAndConditionsContent } from "./TermsAndConditionsContent";
import { useSigningLink } from "./useSigningLink";

export interface Inputs {
    signatory: string;
    signatoryPosition: string;
}

const TAndCContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 360px;
    min-width: 640px;
    gap: 12px;

    ${ShadowCard} {
        gap: unset;
    }

    ${onMobile} {
        min-width: unset;
    }

    ${ShadowCard} {
        width: 100%;
    }
`;

const AttentionContainer = styled(HStack)`
    padding: 4px 8px;
    border-radius: 12px;
    background-color: ${(p) => p.theme.colors.ui4};

    ${PBold} {
        color: ${(p) => p.theme.colors.ui52};
    }
`;

export const getAgreementNumber = (userId?: number | null) => {
    if (!userId) {
        return "";
    }

    const dateForAgreement = format(new Date(), "ddMMy");
    return `${dateForAgreement}${userId}`;
};

export const TermsAndConditions: React.FC = () => {
    const { colors } = useTheme();
    const dispatch = useDispatch();
    const { userId, clientData = {} } = useSelector((state) => state.authentication);
    const {
        onboardingStages: { isTermsAndConditionsDelegated },
        currentStep,
    } = useSelector((state) => state.onboarding);
    const isUserReturnedToSigningTAndC =
        currentStep === ONBOARDING_STEPS.BL_TERMS_AND_CONDITIONS && isTermsAndConditionsDelegated;
    const agreementNumber = useMemo(() => getAgreementNumber(userId), [userId]);

    const {
        isModalOpen: isSignModalOpen,
        openModal: openSignModal,
        closeModal: closeSignModal,
    } = useModalControl();
    const {
        isModalOpen: isForwardLinkModalOpen,
        openModal: openForwardLinkModal,
        closeModal: closeForwardLinkModal,
    } = useModalControl();

    const handleBackClick = useCallback(() => {
        dispatch(setIsTermsAndConditionsDelegated({
            isTermsAndConditionsDelegated: true,
            userId
        }));
        dispatch(startUserOnboarding());
    }, []);

    const { signingLink: readOnlySigningLink } = useSigningLink({ isReadOnly: true });

    return (
        <Layout>
            <Header>
                <H1>Business & legal</H1>
                {isUserReturnedToSigningTAndC ? (
                    <AttentionContainer spacing={8} alignItems="center">
                        <Icons.Attention color={colors.attention100} />
                        <PBold>Sign T&C agreement to get the access to the product</PBold>
                    </AttentionContainer>
                ) : (
                    <P>Please, read and sign the agreement</P>
                )}
            </Header>
            <OnboardingInfoBanner />
            <TAndCContainer>
                <FormProgress
                    onBackClick={isUserReturnedToSigningTAndC ? handleBackClick : undefined}
                    currentStep={
                        OnboardingMap[ONBOARDING_STEPS.BL_TERMS_AND_CONDITIONS].progress.current
                    }
                    totalSteps={
                        OnboardingMap[ONBOARDING_STEPS.BL_TERMS_AND_CONDITIONS].progress.total
                    }
                />
                <VStack asCard padding={12} paddingBottom={40}>
                    <HStack paddingBottom={16} spacing={12} justifyContent="space-between">
                        <H2Bold>Terms & Conditions</H2Bold>
                        <TnCPrint>
                            <TermsAndConditionsContent {...clientData} />
                        </TnCPrint>
                    </HStack>
                    <TermsAndConditionsContent {...clientData} />
                    <Center>
                        <VStack maxWidth="336px" alignItems="center" spacing={12}>
                            <CopyToClipboard
                                title="Read-only signing link"
                                value={readOnlySigningLink}
                            >
                                <BasicButton size="small" type="button">
                                    Copy read-only link <Icons.CopyIcon color="none" />
                                </BasicButton>
                            </CopyToClipboard>
                            <P textAlign="center" color={colors.ui52}>
                                You can copy and send our T&C text to your lawyers or any other
                                interested parties
                            </P>
                        </VStack>
                    </Center>
                </VStack>
            </TAndCContainer>
            <SigningTAndC onSign={openSignModal} onForwardLink={openForwardLinkModal} />
            <Modal isOpen={isSignModalOpen} onClose={closeSignModal}>
                <SignTAndC isAutoFocus agreementNumber={agreementNumber} onClose={closeSignModal} />
            </Modal>
            <Modal isOpen={isForwardLinkModalOpen} onClose={closeForwardLinkModal}>
                <ForwardSigningLink onClose={closeForwardLinkModal} />
            </Modal>
        </Layout>
    );
};
