import { HStack } from "@fm-frontend/uikit";
import { BottomFixTableContainer } from "components/BottomFixTableContainer";
import { InstrumentSelectorProvider } from "components/InstrumentSelectorContext/InstrumentSelectorContext";
import styled from "styled-components";
import { RfqInfoTables } from "./components/RfqInfoTables";
import { RfqTilesTrading } from "./components/RfqTilesTrading";

const Layout = styled(HStack)`
    height: 100%;
    gap: 8px;
    padding-right: 12px;
    overflow: hidden;
`;

const LeftColumn = styled.div`
    flex: 1;
`;

const RightColumn = styled.div`
    flex: 0 0 564px;
    overflow-x: hidden;
`;

export const RfqTradingPageContent = () => (
    <InstrumentSelectorProvider>
        <BottomFixTableContainer noScroll>
            <Layout>
                <LeftColumn>
                    <RfqTilesTrading />
                </LeftColumn>
                <RightColumn>
                    <RfqInfoTables />
                </RightColumn>
            </Layout>
        </BottomFixTableContainer>
    </InstrumentSelectorProvider>
);
