import { PSmallBold, PXSmall, VStack } from "@fm-frontend/uikit";
import { LinkSupport } from "components/Links";
import { useSettings } from "hooks/useSettings";
import { FC } from "react";
import styled from "styled-components";

const Layout = styled(VStack)`
    width: 100%;
    padding: 0 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 12px;
    background-color: ${(p) => p.theme.colors.ui4};
    min-height: 110px;

    ${PXSmall} {
        color: ${(p) => p.theme.colors.ui52};
    }

    a {
        color: ${(p) => p.theme.colors.brand100};
    }
`;

export const DisabledAuthorizedTradingInfo: FC = () => {
    const settings = useSettings();

    return (
        <Layout>
            <PSmallBold>Authorized trading will be available here soon</PSmallBold>
            <PXSmall>
                If you demand this functionality please reach us via{" "}
                <LinkSupport caption={settings.support_email} />
            </PXSmall>
        </Layout>
    );
};
