import { Flex, HStack, PSmall, PSmallBold, SmallLoader, Tooltip, VStack } from "@fm-frontend/uikit";
import { Placeholder } from "@fm-frontend/uikit/src/components/Placeholder";
import { EmDash, ValueFormat } from "@fm-frontend/utils";
import { CoinIcon } from "components/CoinIcon";
import { TradingSide } from "feature/trade/trading/types";
import { useAssetFractionDigits } from "hooks/useAssetFractionDigits";
import { FC, forwardRef } from "react";
import styled, { css, useTheme } from "styled-components";
import { formatPosition } from "./utils/formatPosition";

export const FakeButtonIndicator = styled.div<{ $isSell?: boolean }>`
    width: 40px;
    height: 6px;
    background-color: ${(p) => (p.$isSell ? p.theme.colors.negative8 : p.theme.colors.brand8)};
`;

export const TileActions = styled(HStack)`
    position: relative;
    overflow: hidden;
    border-top: 1px solid ${(p) => p.theme.colors.ui8};
`;

export const TilePSmall = styled(PSmall)`
    font-size: 10px;
    line-height: 12px;
`;

const TileInfoContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
`;
const LeftItem = styled(VStack)`
    align-items: start;
    gap: 4px;
`;
const RightItem = styled(VStack)`
    align-items: end;
    gap: 4px;
`;

const Label = styled(TilePSmall)`
    color: ${(p) => p.theme.colors.ui52};
`;

const Essence = styled.div`
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    max-width: 80px;
    gap: 4px;
`;

const AssetIcon: FC<{ asset: string }> = ({ asset }) => <CoinIcon coin={asset} size={10} />;
const Part = styled(Tooltip)`
    overflow: hidden;
    max-width: 130px;
    align-items: center;
    gap: 4px;
`;

export const TileInfo = Object.assign(TileInfoContainer, {
    LeftItem: LeftItem,
    RightItem: RightItem,
    Label: Label,
    Part: Part,
    Essence: Essence,
    Text: TilePSmall,
    AssetIcon: AssetIcon,
    Placeholder: ({ width = "56px" }: { width?: string }) => (
        <HStack paddingY={3}>
            <Placeholder height="6px" width={width} />
        </HStack>
    ),
});

export const TileHeader = styled(HStack)<{ $isActive: boolean }>`
    padding: 0 8px;
    height: 32px;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
`;

export const TileContent = styled(VStack)<{ $isActive: boolean }>`
    padding: 0 8px 8px 8px;
    gap: 6px;

    opacity: ${(p) => (p.$isActive ? 1 : 0.6)};
`;

export const TileLayout = styled.form<{ $isActive: boolean }>`
    cursor: pointer;
    position: relative;
    min-width: 278px;
    border-radius: 12px;

    box-shadow: 0 0 0 1px ${(p) => p.theme.colors.ui12} inset;

    ${(p) =>
        p.$isActive &&
        css`
            box-shadow: 0 0 0 1px ${(p) => p.theme.colors.ui12} inset,
                0px 4px 16px 0px ${(p) => p.theme.colors.ui8};
        `}

    background-color: ${(p) => (p.$isActive ? p.theme.colors.uiWhite100 : "transparent")};

    :hover {
        background: ${(p) => !p.$isActive && p.theme.colors.ui4};
    }

    ${(p) =>
        !p.$isActive &&
        css`
            ::before {
                content: "";
                width: 100%;
                height: 100%;
                background-color: transparent;
                position: absolute;
                left: 0;
                z-index: 1;
            }
        `}

    overflow-y: auto;
`;

export const TileError = styled(Flex)`
    width: fit-content;
    padding: 6px 8px;
    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.ui100};
    color: ${(p) => p.theme.colors.uiWhite100};
    align-items: center;
    justify-content: center;
    font-size: 12px;

    position: relative;
    z-index: 1;
`;

export const BestPriceTitle = styled(PSmallBold)``;
export const BestPriceWithToleranceTitle = styled(PSmallBold)``;

const TileButton = styled.button<{ isActivated: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 56px;
    cursor: pointer;
    background-color: transparent;

    :disabled {
        cursor: not-allowed;
    }

    ${(p) =>
        !p.isActivated &&
        css`
            :hover {
                background: ${p.theme.colors.ui8};
            }
        `}
`;

const SellButtonContainer = styled(TileButton)`
    color: ${(p) => p.theme.colors.negative100};

    ${BestPriceWithToleranceTitle} {
        color: ${(p) => p.theme.colors.negative52};
    }

    &:focus-visible {
        box-shadow: 0 0 0 3px ${(p) => p.theme.colors.negative16},
            inset 0px 0px 0px 1px ${(p) => p.theme.colors.negative72};
    }

    ${(p) =>
        p.isActivated &&
        css`
            color: ${p.theme.colors.uiWhite100};
            background-color: ${p.theme.colors.negative100};

            ${BestPriceWithToleranceTitle} {
                color: ${p.theme.colors.uiWhite72};
            }
        `}
    ${(p) =>
        !p.isActivated &&
        css`
            :disabled {
                color: ${(p) => p.theme.colors.negative32};

                ${BestPriceWithToleranceTitle} {
                    color: ${(p) => p.theme.colors.negative16};
                }
            }
        `}
`;

const BuyButtonContainer = styled(TileButton)`
    color: ${(p) => p.theme.colors.positive100};

    ${BestPriceWithToleranceTitle} {
        color: ${(p) => p.theme.colors.positive52};
    }

    &:focus-visible {
        box-shadow: 0 0 0 3px ${(p) => p.theme.colors.positive16},
            inset 0px 0px 0px 1px ${(p) => p.theme.colors.positive72};
    }

    ${(p) =>
        p.isActivated &&
        css`
            color: ${p.theme.colors.uiWhite100};
            background-color: ${p.theme.colors.positive100};

            ${BestPriceWithToleranceTitle} {
                color: ${p.theme.colors.uiWhite72};
            }
        `}

    ${(p) =>
        !p.isActivated &&
        css`
            :disabled {
                color: ${(p) => p.theme.colors.positive32};

                ${BestPriceWithToleranceTitle} {
                    color: ${(p) => p.theme.colors.positive16};
                }
            }
        `}
`;

const ButtonTooltip = styled(Tooltip)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    cursor: inherit;
`;

type TradingButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    tooltipContent?: string;
    variant: "sell" | "buy";
    isActivated: boolean;
    onActivate: () => void;
    isSubmitting?: boolean;
};

const TradingButtonBase = forwardRef<HTMLButtonElement, TradingButtonProps>((props) => {
    const {
        tooltipContent,
        variant,
        children,
        onClick,
        onActivate,
        isActivated = false,
        isSubmitting = false,
        disabled,

        ...rest
    } = props;
    const { colors } = useTheme();
    const isLoading = isSubmitting && isActivated;

    const ButtonContainer = variant === "sell" ? SellButtonContainer : BuyButtonContainer;

    const handleClickWithConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
        onActivate();

        if (isActivated) {
            onClick?.(e);
        }
    };
    return (
        <ButtonContainer
            type="button"
            isActivated={isActivated}
            onClick={handleClickWithConfirm}
            disabled={isSubmitting || disabled}
            {...rest}
        >
            {isLoading && (
                <HStack spacing={4} alignItems="center">
                    <SmallLoader color={colors.uiWhite100} /> <PSmall>Execution ...</PSmall>
                </HStack>
            )}
            {!isLoading && (
                <ButtonTooltip align="center" positions={["bottom"]} content={tooltipContent}>
                    {children}
                </ButtonTooltip>
            )}
        </ButtonContainer>
    );
});

const NoQuotesTitle = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui32};
`;

type TradingButtonEssenceProps = {
    isSell: boolean;
    isTileSubscribed: boolean;
    priceToDisplay: bigint | null;
    priceWithTolerance: bigint | null;
    title: string;
};

export const TradingButtonEssence: FC<TradingButtonEssenceProps> = ({
    isSell,
    isTileSubscribed,
    title,
    priceToDisplay,
    priceWithTolerance,
}) => {
    if (isTileSubscribed && priceToDisplay === null) {
        return (
            <>
                <TilePSmall>{title}</TilePSmall>
                <NoQuotesTitle>No quotes</NoQuotesTitle>
            </>
        );
    }

    if (priceToDisplay === null) {
        return <FakeButtonIndicator $isSell={isSell} />;
    }

    return (
        <>
            <TilePSmall>{title}</TilePSmall>
            <BestPriceTitle>{ValueFormat.price(priceToDisplay)}</BestPriceTitle>
            {priceWithTolerance !== null && (
                <BestPriceWithToleranceTitle>
                    {ValueFormat.price(priceWithTolerance)}
                </BestPriceWithToleranceTitle>
            )}
        </>
    );
};

export const TradingButton = Object.assign(TradingButtonBase, {
    Essence: TradingButtonEssence,
});

type PositionInfoPartProps = {
    isAvailable: boolean;
    position: bigint | null | undefined;
    asset: string;
};
export const PositionInfoPart: FC<PositionInfoPartProps> = ({ isAvailable, position, asset }) => {
    const { fractionDigits } = useAssetFractionDigits(asset);

    if (!isAvailable) {
        return (
            <TileInfo.Part>
                <TileInfo.Placeholder />
            </TileInfo.Part>
        );
    }

    const tooltipContent = `${
        position ? ValueFormat.price(position, fractionDigits) : "0"
    } ${asset}.\nCurrent position before the trade`;

    const formatedPosition = formatPosition(
        Number(position ? ValueFormat.formPrice(position, fractionDigits) : "0"),
    );

    return (
        <TileInfo.Part positions={["right"]} align={"center"} content={tooltipContent}>
            <TileInfo.AssetIcon asset={asset} />
            <TileInfo.Text ellipsis>
                {formatedPosition} {asset}
            </TileInfo.Text>
        </TileInfo.Part>
    );
};

const Spread = styled.div<{ isActive: boolean }>`
    text-align: center;
    padding: 4px;
    min-width: 40px;
    min-height: 20px;
    border-radius: 0 0 6px 6px;
    right: 50%;
    transform: translate(50%, 0);
    background-color: ${(p) => (p.isActive ? "#f6f7f7" : "transparent")};
    border-right: 1px solid rgba(36, 48, 52, 0.08);
    border-bottom: 1px solid rgba(36, 48, 52, 0.08);
    border-left: 1px solid rgba(36, 48, 52, 0.08);
    position: absolute;
    top: 0;

    ::before {
        content: "";
        width: 1px;
        height: 32px;
        top: calc(100% + 1px);
        background-color: ${(p) => p.theme.colors.ui8};
        position: absolute;
        right: 50%;
        transform: translate(50%, 0);
    }
`;

const SpreadSideDivider = styled.div`
    right: 50%;
    transform: translate(50%, 0);
    top: 0;

    ::before {
        content: "";
        width: 1px;
        height: calc(100% - 1px);
        top: 0;
        background-color: ${(p) => p.theme.colors.ui8};
        position: absolute;
        right: 50%;
        transform: translate(50%, 0);
    }
`;

type InfoSpreadProps = {
    isActive: boolean;
    spreadInPercentage: number | null;
};
export const InfoSpread: FC<InfoSpreadProps> = ({ isActive, spreadInPercentage }) => {
    if (spreadInPercentage === null) {
        return <SpreadSideDivider />;
    }

    return (
        <Spread isActive={isActive}>
            <TilePSmall>{spreadInPercentage.toFixed(2)}%</TilePSmall>
        </Spread>
    );
};

type AmountInfoPartProps = {
    isActive: boolean;
    amount: bigint | null | undefined;
    asset: string;
    fallbackValue?: string;
};
export const AmountInfoPart: FC<AmountInfoPartProps> = ({
    isActive,
    amount,
    asset,
    fallbackValue = EmDash,
}) => {
    const { fractionDigits } = useAssetFractionDigits(asset);

    if (!isActive) {
        return (
            <TileInfo.Part>
                <TileInfo.Placeholder />
            </TileInfo.Part>
        );
    }

    if (amount === undefined || amount === null || amount === 0n) {
        return (
            <TileInfo.Part>
                <TileInfo.Text>{fallbackValue}</TileInfo.Text>
            </TileInfo.Part>
        );
    }

    const formatedAmount = ValueFormat.price(amount, fractionDigits);

    return (
        <>
            <TileInfo.Part>
                <TileInfo.Text title={`~${formatedAmount} ${asset}`} ellipsis>
                    ~{formatedAmount}
                </TileInfo.Text>
                <TileInfo.Text>{asset}</TileInfo.Text>
            </TileInfo.Part>
        </>
    );
};

const TOTAL_TITLE: Record<TradingSide, string> = {
    volume: "Total volume",
    size: "Total size",
};

type TileInfoSectionProps = {
    isActive: boolean;
    isLoading?: boolean;
    totalAmount: bigint | null;
    totalAsset: string;
    equivalentAmount: bigint | null;
    basePosition: bigint | null;
    baseAsset: string;
    quotePosition: bigint | null;
    quoteAsset: string;
    tradingSide: TradingSide;
};
export const TileInfoSection: FC<TileInfoSectionProps> = ({
    isActive,
    isLoading,
    totalAmount,
    totalAsset,
    equivalentAmount,
    basePosition,
    baseAsset,
    quoteAsset,
    quotePosition,
    tradingSide,
}) => {
    const isAvailable = isActive && !isLoading;

    return (
        <TileContent $isActive={isActive}>
            <TileInfo>
                <TileInfo.LeftItem>
                    {isActive ? (
                        <TileInfo.Label>Position</TileInfo.Label>
                    ) : (
                        <TileInfo.Placeholder width="20px" />
                    )}
                    <PositionInfoPart
                        isAvailable={isAvailable}
                        position={basePosition}
                        asset={baseAsset}
                    />
                    <PositionInfoPart
                        isAvailable={isAvailable}
                        position={quotePosition}
                        asset={quoteAsset}
                    />
                </TileInfo.LeftItem>
                <TileInfo.RightItem>
                    {isActive ? (
                        <TileInfo.Label>{TOTAL_TITLE[tradingSide]}</TileInfo.Label>
                    ) : (
                        <TileInfo.Placeholder width="20px" />
                    )}
                    <AmountInfoPart
                        isActive={isAvailable}
                        amount={totalAmount}
                        asset={totalAsset}
                        fallbackValue=""
                    />
                    <AmountInfoPart isActive={isAvailable} amount={equivalentAmount} asset="USD" />
                </TileInfo.RightItem>
            </TileInfo>
        </TileContent>
    );
};
