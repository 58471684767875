import { Switch } from "@fm-frontend/uikit/src/components/v2";
import { useWhitelistingContext } from "./whitelistingContext";

export const InstrumentWhitelistingSwitch = ({
    instrumentName,
    className,
}: {
    instrumentName: string;
    className?: string;
}) => {
    const {
        isWhitelistingOn,
        isInstrumentInWhitelist,
        whitelistInstrument,
        unwhitelistInstrument,
    } = useWhitelistingContext();

    if (!isWhitelistingOn) {
        return null;
    }

    const handleEnabledChange = (enabledValue: boolean) => {
        (enabledValue ? whitelistInstrument : unwhitelistInstrument)(instrumentName);
    };

    return (
        <span className={className}>
            <Switch
                asSimpleSwitch
                checked={isInstrumentInWhitelist(instrumentName)}
                onChange={handleEnabledChange}
                variant="minimum"
                size="large"
            />
        </span>
    );
};
