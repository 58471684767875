export const getSearchParams = (locationSearch: string = "") => {
    const searchParams = new URLSearchParams(locationSearch);

    const paramsMap = new Map<string, string>();
    searchParams.forEach((value, key) => {
        paramsMap.set(key, value);
    });

    return paramsMap;
};
