import styled, { css } from "styled-components";
import { TransitionState } from "../../hooks/useTransition";

export const Container = styled.aside`
    position: fixed;
    right: 8px;
    bottom: 12px;
    text-align: right;
    width: 320px;
    z-index: 9999;
    user-select: none;
    pointer-events: none;
    & > * {
        transition: ${(p) => p.theme.transitions.default};
    }
    & > * + * {
        margin-top: 4px;
    }
`;

export const Notification = styled.article<{ state: TransitionState }>`
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors.ui96};
    width: 320px;
    gap: 8px;
    color: ${(p) => p.theme.colors.activeBG};
    box-shadow: 0 4px 16px ${(p) => p.theme.colors.textBody6},
        inset 0px 0px 0px 1px ${(p) => p.theme.colors.textBody5};
    border-radius: 8px;
    padding: 12px 8px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.005em;
    word-break: break-word;
    text-align: start;
    pointer-events: all;
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    opacity: 0.8;

    &:last-of-type {
        opacity: 1;
    }

    ${({ state }) => {
        switch (state) {
            case "exiting": {
                return css`
                    transform: translateX(340px);
                    opacity: 0 !important;
                    box-shadow: none;
                `;
            }
        }
    }}

    svg {
        position: absolute;
        top: 12px;
        left: 12px;
    }
`;

export const Content = styled.div`
    align-self: center;
`;

export const Button = styled.button`
    background-color: transparent;
`;
