import { DropdownOption } from "@fm-frontend/uikit/src/components/v2";
import { useMemo } from "react";
import { FilterType } from "store/onboardingSlice";
import { BankType, JurisdictionType, OnboardingCurrencyType } from "types";
import { mapAssets } from "../utils";

export const DEFAULT_JURISDICTION: DropdownOption = {
    value: "",
    text: "Any Jurisdiction",
};

const LOWEST_BANK_LABEL = "Other";

export const useFilterResultMarks = (filters: FilterType, jurisdictions: DropdownOption[]) => {
    const { banks = [], assets = [], jurisdiction } = filters;

    const marks = [];

    marks.push(
        banks.length
            ? {
                  isHighlighted: true,
                  text: `Banks ${banks.length}`,
              }
            : {
                  isHighlighted: false,
                  text: "Any banks",
              },
    );
    marks.push(
        assets.length
            ? {
                  isHighlighted: true,
                  text: `Assets ${assets.length}`,
              }
            : {
                  isHighlighted: false,
                  text: "Any assets",
              },
    );
    marks.push(
        jurisdiction
            ? {
                  isHighlighted: true,
                  text: `LP jurisdiction ${jurisdictions.find((j) => j.value === jurisdiction)?.text ?? ""}`,
              }
            : {
                  isHighlighted: false,
                  text: "Any jurisdictions",
              },
    );

    return marks;
};

export const useMemoFilterData = (
    banks: BankType[],
    jurisdictions: JurisdictionType[],
    currencies: OnboardingCurrencyType[],
) => {
    const mappedBanks: DropdownOption[] =
        useMemo(
            () =>
                banks
                    ?.map(
                        ({ id, name }) =>
                            ({
                                text: name,
                                value: String(id),
                            } as DropdownOption),
                    )
                    .sort((left, right) => {
                        if (left.text === LOWEST_BANK_LABEL) {
                            return 1;
                        }
                        if (right.text === LOWEST_BANK_LABEL) {
                            return -1;
                        }

                        return 0;
                    }),
            [banks],
        ) ?? [];

    const mappedJurisdictions =
        useMemo(
            () => [
                DEFAULT_JURISDICTION,
                ...jurisdictions?.map(
                    ({ id, name }) =>
                        ({
                            text: name,
                            value: String(id),
                        } as DropdownOption),
                ),
            ],
            [jurisdictions],
        ) ?? [];

    const mappedCurrencies = useMemo(() => mapAssets(currencies), [currencies]);

    return { mappedBanks, mappedJurisdictions, mappedCurrencies };
};
