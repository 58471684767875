import { HStack, IconButton, Icons, Mark, Modal, PBold, Tooltip } from "@fm-frontend/uikit";
import { SingleDropdown, Switch } from "@fm-frontend/uikit/src/components/v2";
import { LeverageIcon } from "components/icons";
import { onMobile } from "const";
import { useSelector } from "hooks";
import { isEqual } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DEFAULT_FILTER, FilterType } from "store/onboardingSlice";
import styled, { useTheme } from "styled-components";
import { BankType, JurisdictionType, OnboardingCurrencyType } from "types";
import { AssetsDropdown } from "../AssetsDropdown";
import { BanksDropdown } from "../BanksDropdown";
import { useFilterContext } from "../LiquidityProvidersFilterContext";
import { FilterModal } from "./FilterModal";
import { DEFAULT_JURISDICTION, useFilterResultMarks, useMemoFilterData } from "./utils";

const Container = styled.form`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 16px;
    gap: 8px;
    justify-content: center;
    align-items: center;

    ${onMobile} {
        padding-bottom: 8px;
    }

    button {
        background-color: transparent;
    }
`;

const FilterTrigger = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    gap: 4px;
    background: ${(p) => p.theme.colors.ui8};
    border-radius: 8px;
    padding: 4px 8px;
    cursor: pointer;

    mark {
        margin-left: 4px;
    }

    svg {
        margin-right: 2px;
    }
`;

const FiltersContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap: 6px;
    padding: 0 12px;
`;

type FilterContentProps = {
    banks: BankType[];
    jurisdictions: JurisdictionType[];
    currencies: OnboardingCurrencyType[];
    filters: FilterType;
};
const FilterContent: React.FC<FilterContentProps> = ({ banks, jurisdictions, currencies, filters }) => {
    const { colors } = useTheme();
    const { isMobile } = useSelector((state) => state.app);
    const [isModalActive, setIsModalActive] = useState(false);
    const { setFilters } = useFilterContext();

    const { control, watch, reset } = useForm<FilterType>({
        defaultValues: {
            ...filters,
        },
        mode: "onChange",
    });

    useEffect(() => {
        const subscription = watch((values) => {
            if (!isModalActive) {
                setFilters(values as FilterType);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, isModalActive, setFilters]);

    const { mappedBanks, mappedJurisdictions, mappedCurrencies } = useMemoFilterData(banks, jurisdictions, currencies);
    const marks = useFilterResultMarks(filters, mappedJurisdictions);

    const handleFilterApply = (filter: FilterType) => {
        setIsModalActive(false);
        reset(filter);
    };

    const resetFilter = () => {
        reset({ ...DEFAULT_FILTER });
    };

    const isClearButtonVisible = !isEqual(filters, DEFAULT_FILTER);

    return (
        <Container>
            {isMobile ? (
                <FilterTrigger onClick={() => setIsModalActive(true)}>
                    <LeverageIcon />
                    {marks.map(({ isHighlighted, text }, index) => (
                        <>
                            <PBold key={index} color={isHighlighted ? colors.ui100 : colors.ui52}>
                                {text}
                                {marks.length > index + 1 && <Mark color={colors.ui20}>•</Mark>}
                            </PBold>
                        </>
                    ))}
                </FilterTrigger>
            ) : (
                <FiltersContainer>
                    <AssetsDropdown
                        variant="simple"
                        triggerSize="small"
                        control={control}
                        name="assets"
                        groupedOptions={mappedCurrencies}
                    />
                    <BanksDropdown
                        variant="simple"
                        triggerSize="small"
                        control={control}
                        name="banks"
                        options={mappedBanks}
                    />

                    <Controller
                        control={control}
                        render={({ field }) => (
                            <SingleDropdown
                                value={field.value}
                                onChange={field.onChange}
                                renderTrigger={(trigger) => (
                                    <SingleDropdown.Trigger {...trigger} size="small" variant="simple">
                                        {trigger.selectedValue === DEFAULT_JURISDICTION.value ? undefined : (
                                            <SingleDropdown.TriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOption}
                                                size="small"
                                            />
                                        )}
                                    </SingleDropdown.Trigger>
                                )}
                                options={mappedJurisdictions}
                                caption="LP jurisdiction"
                                asFilter
                            >
                                <SingleDropdown.BasicSheet size="small" options={mappedJurisdictions} />
                            </SingleDropdown>
                        )}
                        name="jurisdiction"
                    />
                    <HStack spacing={6}>
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <Switch
                                    size="small"
                                    variant="simple"
                                    checked={field.value}
                                    onChange={field.onChange}
                                    innerCaption="Regulation"
                                    asFilter
                                />
                            )}
                            name="regulation"
                        />
                        {isClearButtonVisible && (
                            <Tooltip content="Reset filter" align="center">
                                <IconButton variant="plain" type="button" Icon={Icons.Recent} onClick={resetFilter} />
                            </Tooltip>
                        )}
                    </HStack>
                </FiltersContainer>
            )}
            <Modal isOpen={isModalActive} onClose={() => setIsModalActive(false)}>
                <FilterModal
                    defaultFilter={watch()}
                    currencies={mappedCurrencies}
                    banks={mappedBanks}
                    jurisdictions={mappedJurisdictions}
                    onApply={handleFilterApply}
                />
            </Modal>
        </Container>
    );
};

export const Filter: FC = () => {
    const { banks, jurisdictions, currencies } = useSelector((state) => state.onboarding);
    const { filters } = useFilterContext();

    return <FilterContent banks={banks} currencies={currencies} jurisdictions={jurisdictions} filters={filters} />;
};
