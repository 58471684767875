import { EmailType } from "types";

export const SET_LIMIT_MODAL_KEY = Symbol();

export const handleContactProvider = (emails: EmailType[]) => {
    const emailsMap: {
        to: string[];
        cc: string[];
    } = {
        to: [],
        cc: [],
    };

    emails.reduce((map, { email, is_copy }) => {
        map[is_copy ? "cc" : "to"].push(email);

        return map;
    }, emailsMap);

    return `mailto:${emailsMap.to.join(",")}?cc=${emailsMap.cc.join(",")}`;
};
