import styled from "styled-components";

export const PopoverContent = styled.div`
    display: flex;
    width: 288px;
    gap: 16px;
    align-items: center;
    min-height: 20px;
    max-height: 296px;
    overflow-y: auto;
    flex-direction: column;
    background: ${(p) => p.theme.colors.uiWhite100};
    padding: 12px;
    box-shadow: 0 4px 16px ${(p) => p.theme.colors.ui8}, inset 0 0 0 1px ${(p) => p.theme.colors.ui12};
    border-radius: 8px;
`;

export const Option = styled.label`
    display: flex;
    gap: 4px;
    width: calc(50%);
    cursor: pointer;
`;
