import styled from "styled-components";

export const CellContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px 0;

    background: ${(p) => p.theme.colors.uiWhite100};
    border-radius: 12px;
    box-shadow: 0 0 0 1px ${(p) => p.theme.colors.ui12} inset, 0 -3px 0 0 ${(p) => p.theme.colors.ui8} inset,
        0 2px 4px 0 ${(p) => p.theme.colors.ui4};
`;

export const Cell = styled(CellContainer)`
    align-items: flex-start;
    align-self: stretch;
    justify-content: flex-end;
    gap: 8px;
`;
