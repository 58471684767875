import {
    DesctructiveButton,
    Flex,
    H2,
    HStack,
    P,
    PlainButton,
    VStack,
} from "@fm-frontend/uikit";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { stopBusyProcess, startBusyProcess } from "feature/app";
import { BulkRequestBase, BulkResponse, bulkUpdate } from "../../services/bulkUpdate";

const Details = styled(P)`
    color: ${(p) => p.theme.colors.ui72};
    padding: 0 12px;
`;

const Result = styled.div`
    overflow: auto;
    margin: 8px 12px 12px 12px;
    width: calc(100% - 24px);
    height: 164px;
    border: 1px solid ${(p) => p.theme.colors.ui8};
    border-radius: 8px;
`;

export const ResultLine = styled(Flex)`
    padding: 4px 8px 8px 8px;
    align-items: center;
    gap: 8px;
`;

type ResultsContainerProps<Request extends BulkRequestBase> = {
    responses: BulkResponse<Request>[];
    onUpdate: (responses: BulkResponse<Request>[]) => void;
    onClose: () => void;
    children?: React.ReactNode;
};

export const ResultsContainer = <Request extends BulkRequestBase>({ responses, onUpdate, onClose, children }: ResultsContainerProps<Request>) => {
    const dispatch = useDispatch();
    const [isRetrying, setIsRetrying] = useState(false);

    const failedCount = useMemo(
        () =>
            responses.reduce(
                (acc, { error }) => acc + (error === undefined ? 0 : 1),
                0,
            ),
        [responses],
    );

    const handleRetryClick = async () => {
        try {
            const failedRequests = responses
                .filter(({ error }) => error !== undefined)
                .map(({ request }) => request);

            dispatch(startBusyProcess());
            setIsRetrying(true);

            const { responses: retryResponses } = await bulkUpdate(failedRequests);

            if (retryResponses.every(({ error }) => error !== undefined)) {
                onUpdate(retryResponses);
            }

            if (retryResponses.every(({ error }) => error === undefined)) {
                onClose();
            }
        } finally {
            setIsRetrying(false);
            dispatch(stopBusyProcess());
        }
    };

    return (
        <VStack minWidth="360px" maxWidth="360px" asCard>
            <VStack padding={12}>
                <H2>Setup results</H2>
            </VStack>
            <Details>
                {responses.length - failedCount} of {responses.length} requests applied
                successfully. {failedCount} errors occurred. Retry or contact admin.
            </Details>
            <Result>
                {children}
            </Result>
            <HStack padding={12} paddingTop={16} spacing={10}>
                <PlainButton fullWidth size="large" type="button" onClick={onClose}>
                    Skip
                </PlainButton>
                <DesctructiveButton
                    fullWidth
                    size="large"
                    type="submit"
                    loading={isRetrying}
                    onClick={handleRetryClick}
                >
                    Retry {failedCount}
                </DesctructiveButton>
            </HStack>
        </VStack>
    );
};
