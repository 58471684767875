import {
    BasicInput,
    Form,
    FormActions,
    FormAttention,
    FormBody,
    FormProgress,
    H1,
    P,
    PrimaryButton,
    ShadowCard,
} from "@fm-frontend/uikit";
import { Header as FormHeader } from "@fm-frontend/uikit/src/components/headers/Header";
import { HeaderTitle } from "@fm-frontend/uikit/src/components/headers/HeaderTitle";
import { DropdownOption, SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { IconError } from "components/icons";
import { useFormHelpers, useSelector, useTakerInfo } from "hooks";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
    OnboardingMap,
    ONBOARDING_STEPS,
    setCurrentStep,
    setIsGeneralInfoRequired,
    setRestartVerificationMakerId,
    useIsPostOnboardingRequired,
} from "store/onboardingSlice";
import { resetPostOnboardingStages } from "store/onboardingActions";
import { TakerInfoRequest, TakerInfoType } from "types";
import {
    CheckboxContainer,
    CheckboxGroup,
    CheckboxGroupLabel,
    Header,
    Layout,
    OnboardingInfoBanner,
    useOnboardingBanks,
} from "../common";
import { CheckboxPill, FormCheckbox } from "./components";
import { generalInfoDataSchema } from "./schemas";

export interface Inputs {
    businessType: string | null;
    monthTurnover: number | null;
    tradeExpectation: string | null;
    settleExpectation: string | null;
    fiat: boolean | null;
    banks: Record<number, boolean>;
    website?: string | null;
    telegram?: string | null;
}

const typesOfBusiness: DropdownOption[] = [
    { value: "Bank or EMI", text: "Bank or EMI" },
    { value: "Payment provider or payment system", text: "Payment provider or payment system" },
    { value: "Broker or master", text: "Broker or master" },
    { value: "Exchange", text: "Exchange" },
    { value: "OTC desk", text: "OTC desk" },
    { value: "Custody provider", text: "Custody provider" },
    { value: "Hedge or crypto fund", text: "Hedge or crypto fund" },
    { value: "Wallet or instant exchange", text: "Wallet or instant exchange" },
    { value: "Other", text: "Other" },
];

const tradeFrequency: DropdownOption[] = [
    { value: "Daily", text: "Daily" },
    { value: "Weekly", text: "Weekly" },
    { value: "Monthly and less", text: "Monthly and less" },
];

const settleFrequency: DropdownOption[] = [
    { value: "Several times a day", text: "Several times a day" },
    { value: "Daily", text: "Daily" },
    { value: "Weekly", text: "Weekly" },
    { value: "Monthly and less", text: "Monthly and less" },
];

const mapTakerInfoToFromData = (takerInfo: TakerInfoType | undefined) => {
    const {
        business_type,
        fiat_settlements,
        telegram,
        website,
        settle_frequency,
        trade_frequency,
        monthly_turnover,
        bank,
    } = takerInfo ?? {};

    return {
        businessType: business_type,
        monthTurnover: monthly_turnover,
        tradeExpectation: trade_frequency,
        settleExpectation: settle_frequency,
        fiat: fiat_settlements,
        website: website,
        telegram: telegram,
        banks: bank?.reduce<Record<string, boolean>>((acc, { id }) => {
            acc[id] = true;
            return acc;
        }, {}),
    };
};

export const GeneralInfo: React.FC = () => {
    useOnboardingBanks();
    const dispatch = useDispatch();
    const { takerInfo, updateTakerInfo } = useTakerInfo();
    const { banks } = useSelector((state) => state.onboarding);
    const { error, setError } = useFormHelpers();
    const isPostOnboardingRequired = useIsPostOnboardingRequired();

    const {
        control,
        register,
        reset,
        watch,
        formState: { errors, isSubmitting },
        handleSubmit,
    } = useForm<Inputs>({
        defaultValues: mapTakerInfoToFromData(takerInfo),
        mode: "onSubmit",
        resolver: yupResolver(generalInfoDataSchema),
    });

    const isFiat = watch("fiat");
    const isFiatActive = Boolean(isFiat && banks?.length);

    useEffect(() => {
        reset(mapTakerInfoToFromData(takerInfo));
    }, [takerInfo]);

    const submit = async (data: Inputs) => {
        try {
            const {
                businessType,
                telegram,
                website,
                fiat,
                settleExpectation,
                tradeExpectation,
                monthTurnover = 0,
                banks,
            } = data;

            const params: TakerInfoRequest = {
                business_type: businessType,
                monthly_turnover: Number(monthTurnover),
                trade_frequency: tradeExpectation,
                settle_frequency: settleExpectation,
                fiat_settlements: fiat,
                website: website,
                telegram: telegram,
                currency_id: [],
                bank_id: fiat
                    ? Object.entries(banks)
                          .filter(([, value]) => value)
                          .map(([id]) => Number(id))
                    : [],
            };

            await updateTakerInfo(params);

            dispatch(setIsGeneralInfoRequired(false));
            dispatch(setCurrentStep(ONBOARDING_STEPS.BL_ASSETS));
        } catch (e) {
            setError(String(e));
        }
    };

    const handlePostOnboardingBackClick = () => {
        dispatch(resetPostOnboardingStages());
        dispatch(setRestartVerificationMakerId());
    };

    return (
        <Layout>
            <Header>
                <H1>{isPostOnboardingRequired ? "Start verification" : "Business & legal"}</H1>
                <P>Fill in the form and we will introduce you to suitable liquidity providers</P>
            </Header>
            <OnboardingInfoBanner />
            <Form onSubmit={handleSubmit(submit)}>
                <FormProgress
                    currentStep={OnboardingMap[ONBOARDING_STEPS.BL_GENERAL_INFO].progress.current}
                    totalSteps={OnboardingMap[ONBOARDING_STEPS.BL_GENERAL_INFO].progress.total}
                    onBackClick={isPostOnboardingRequired ? handlePostOnboardingBackClick : undefined}
                />
                <ShadowCard>
                    <FormHeader>
                        <HeaderTitle title="General info" />
                    </FormHeader>
                    <FormBody>
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <SingleDropdown
                                    value={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <SingleDropdown.Trigger {...trigger} size="large" variant="basic">
                                            <SingleDropdown.TriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOption}
                                                size="large"
                                            />
                                        </SingleDropdown.Trigger>
                                    )}
                                    options={typesOfBusiness}
                                    caption="Type of business"
                                    error={errors.businessType?.message}
                                    placeholder="Select an option"
                                    fullWidth
                                >
                                    <SingleDropdown.BasicSheet size="large" options={typesOfBusiness} />
                                </SingleDropdown>
                            )}
                            name="businessType"
                        />
                        <BasicInput
                            label="Expected monthly turnover"
                            placeholder="Value in USD"
                            {...register("monthTurnover")}
                            error={errors.monthTurnover?.message}
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <SingleDropdown
                                    value={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <SingleDropdown.Trigger {...trigger} size="large" variant="basic">
                                            <SingleDropdown.TriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOption}
                                                size="large"
                                            />
                                        </SingleDropdown.Trigger>
                                    )}
                                    options={tradeFrequency}
                                    caption="How often do you expect to trade?"
                                    error={errors.tradeExpectation?.message}
                                    placeholder="Select an option"
                                    fullWidth
                                >
                                    <SingleDropdown.BasicSheet size="large" options={tradeFrequency} />
                                </SingleDropdown>
                            )}
                            name="tradeExpectation"
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <SingleDropdown
                                    value={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <SingleDropdown.Trigger {...trigger} size="large" variant="basic">
                                            <SingleDropdown.TriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOption}
                                                size="large"
                                            />
                                        </SingleDropdown.Trigger>
                                    )}
                                    options={settleFrequency}
                                    caption="How often do you want to settle?"
                                    error={errors.settleExpectation?.message}
                                    placeholder="Select an option"
                                    fullWidth
                                >
                                    <SingleDropdown.BasicSheet size="large" options={settleFrequency} />
                                </SingleDropdown>
                            )}
                            name="settleExpectation"
                        />

                        <FormCheckbox
                            control={control}
                            name="fiat"
                            label="Fiat"
                            title="Would you need fiat settlements?"
                        />
                        {isFiatActive && (
                            <CheckboxGroup>
                                <CheckboxGroupLabel>Banks for settlements</CheckboxGroupLabel>
                                <CheckboxContainer>
                                    {banks?.map(({ id, name }) => (
                                        <CheckboxPill key={id} control={control} name={`banks.${id}`} text={name} />
                                    ))}
                                </CheckboxContainer>
                            </CheckboxGroup>
                        )}
                        <BasicInput
                            label="Company website (opt.)"
                            placeholder="trading.io"
                            {...register("website")}
                            error={errors.website?.message}
                        />
                        <BasicInput
                            label="Telegram (opt.)"
                            placeholder="@nickname"
                            {...register("telegram")}
                            error={errors.telegram?.message}
                        />
                        {error && (
                            <FormAttention>
                                <IconError />
                                <P>{error}</P>
                            </FormAttention>
                        )}
                    </FormBody>
                    <FormActions variant="plain">
                        <PrimaryButton type="submit" fullWidth size="large" loading={isSubmitting}>
                            Continue
                        </PrimaryButton>
                    </FormActions>
                </ShadowCard>
            </Form>
        </Layout>
    );
};
