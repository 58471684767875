import { DropdownOption } from "@fm-frontend/uikit/src/components/v2";
import { isCurrencyType, ValueParse } from "@fm-frontend/utils";
import { getNextReqId } from "api/methods/postViaWS";
import { sendRESTRequestViaWS } from "feature/app/ws";
import { subscribeToWsRestResponses } from "feature/app/ws.saga";
import { isCpGroupType } from "feature/assetsControl/types";
import { isSubaccountCp } from "feature/counterparties/utils";
import { actionChannel } from "store/actionChannel";
import { ClientType } from "types";
import { AssetsModalInputs } from "./AssetsModal";

type UpdateLimitsRequestBody = {
    counterpartyId: number;
    asset: string;
    grossLimit?: bigint;
    limitCurrency?: string | null;
};

export type UpdateLimitResponse = {
    errorCode?: number;
    requestBody: UpdateLimitsRequestBody;
};

export const convertToRequestBodies = (
    inputs: AssetsModalInputs,
    counterparties: DropdownOption[],
    currencyGroups: {
        [k: string]: "unknown" | "crypto" | "fiat" | "stablecoin";
    },
    getCpType: (cpId: number) => ClientType | undefined,
): UpdateLimitsRequestBody[] => {
    let cpIds = [];
    if (isCpGroupType(inputs.cp)) {
        let filterFunc: (option: DropdownOption) => boolean = () => true;
        switch (inputs.cp) {
            case "externalMakers":
                filterFunc = ({ value }) => getCpType(Number(value)) === "maker";
                break;
            case "externalTakers":
                filterFunc = ({ value }) => getCpType(Number(value)) === "taker";
                break;
            case "subaccountMakers":
                filterFunc = ({ value }) => getCpType(Number(value)) === "subaccountMaker";
                break;
            case "subaccountTakers":
                filterFunc = ({ value }) => getCpType(Number(value)) === "subaccountTaker";
                break;
            case "subaccounts":
                filterFunc = ({ value }) => isSubaccountCp(getCpType(Number(value)));
                break;
            case "externals":
                filterFunc = ({ value }) => !isSubaccountCp(getCpType(Number(value)));
                break;
        }
        cpIds = counterparties.filter(filterFunc).map(({ value }) => Number(value));
    } else {
        cpIds = [Number(inputs.cp)];
    }

    let currencies: string[] = [];
    if (isCurrencyType(inputs.assets) || inputs.assets === "all") {
        const allAssets = Object.keys(currencyGroups);
        let filterFunc: (asset: string) => boolean = () => true;

        switch (inputs.assets) {
            case "crypto":
                filterFunc = (asset) => currencyGroups[asset] === "crypto";
                break;
            case "fiat":
                filterFunc = (asset) => currencyGroups[asset] === "fiat";
                break;
            case "stablecoin":
                filterFunc = (asset) => currencyGroups[asset] === "stablecoin";
                break;
        }
        currencies = allAssets.filter(filterFunc);
    } else {
        currencies = [inputs.assets];
    }

    const requestBodies = cpIds.flatMap((cpId) =>
        currencies.map((asset) => ({
            counterpartyId: cpId,
            asset,
            ...(inputs.limitPerAsset && {
                grossLimit: ValueParse.size(String(inputs.grossLimit)),
                limitCurrency: inputs.limitCurrency,
            }),
        })),
    );

    return requestBodies;
};

const waitLimitsOutput = (
    requestsBodiesWithReqIds: {
        reqId: number;
        requestBody: UpdateLimitsRequestBody;
    }[],
) =>
    new Promise<UpdateLimitResponse[]>((resolve) => {
        const requestsObj = requestsBodiesWithReqIds.reduce<
            Record<number, UpdateLimitsRequestBody>
        >((acc, { reqId, requestBody }) => {
            acc[reqId] = requestBody;
            return acc;
        }, {});
        const reqIdsWithoutResponse = new Set(Object.keys(requestsObj).map(Number));
        let failedReqIds: Record<number, number> = {};

        const unsubscribe = subscribeToWsRestResponses((resp) => {
            const { reqId, error } = resp;
            reqIdsWithoutResponse.delete(reqId);
            if (error !== undefined) {
                failedReqIds[reqId] = error;
            }
            if (reqIdsWithoutResponse.size === 0) {
                unsubscribe();
                const failedRequestResponses: UpdateLimitResponse[] = Object.entries(
                    failedReqIds,
                ).map(([reqId, errorCode]) => ({
                    requestBody: requestsObj[Number(reqId)],
                    errorCode,
                }));
                const succededRequestResponses: UpdateLimitResponse[] = Object.entries(requestsObj)
                    .filter(([reqId]) => !failedReqIds[Number(reqId)])
                    .map(([, requestBody]) => ({
                        requestBody,
                    }));

                const result: UpdateLimitResponse[] = [
                    ...failedRequestResponses,
                    ...succededRequestResponses,
                ];

                resolve(result);
            }
        });
    });

const UPDATE_MARKUP_REQUESTS_IN_CHUCK = 5;
export const updateLimits = async (
    requestsBodies: UpdateLimitsRequestBody[],
    shouldAdd: boolean,
) => {
    const apiMethod = shouldAdd ? "setCAssetLimit" : "delCAssetLimit";
    const requestsBodiesWithReqIds = requestsBodies.map((requestBody) => ({
        reqId: getNextReqId(),
        requestBody,
    }));

    let startIndex = 0;
    let endIndex = Math.min(requestsBodiesWithReqIds.length, UPDATE_MARKUP_REQUESTS_IN_CHUCK);

    const send = () =>
        setTimeout(() => {
            for (let i = startIndex; i < endIndex; i++) {
                const { reqId, requestBody } = requestsBodiesWithReqIds[i];
                actionChannel.put(
                    sendRESTRequestViaWS({ method: apiMethod, reqId, content: requestBody }),
                );
            }

            if (endIndex === requestsBodiesWithReqIds.length) {
                return;
            }

            startIndex = endIndex;
            endIndex = Math.min(
                requestsBodiesWithReqIds.length,
                endIndex + UPDATE_MARKUP_REQUESTS_IN_CHUCK,
            );
            send();
        }, 50);
    send();

    return {
        response: await waitLimitsOutput(requestsBodiesWithReqIds),
        shouldAdd,
    };
};
