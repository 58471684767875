import { DropdownOption } from "@fm-frontend/uikit/src/components/v2";
import { useSubaccounts } from "feature/subaccounts/api";
import { useMemo } from "react";
import { composeUsername } from "utils";
import { useIsAuthorizedTradingAvailable } from "./useIsAuthorizedTradingAvailable";

export const useClientOptionsForAuthorizedTrading = () => {
    const isAuthorizedTradingAvailable = useIsAuthorizedTradingAvailable();

    const { subaccounts, isLoading: isSubaccountsLoading } = useSubaccounts();

    const clientOptionsForAuthorizedTrading = useMemo(() => {
        if (!isAuthorizedTradingAvailable) {
            return [];
        }

        const subTakersForAuthorizedTrading = subaccounts.filter(
            ({ info, status, type }) => info.clientId && type === "taker" && status === "active",
        );
        return subTakersForAuthorizedTrading
            .map((value) => {
                return {
                    text: composeUsername(value.info.username),
                    value: value.info.clientId,
                } as DropdownOption<number>;
            })
            .sort((a, b) => a.text.localeCompare(b.text));
    }, [subaccounts, isAuthorizedTradingAvailable]);

    return {
        clientOptionsForAuthorizedTrading,
        isLoading: isSubaccountsLoading,
    };
};
