import { SEARCH_PARAMS } from "const";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType } from "store/hooks";

export const useUrlWithViewType = () => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const subAccountUrlPart =
        isPrimeBrokerUser && primeBrokerViewType === "subaccounts"
            ? `?${SEARCH_PARAMS.primeBrokerViewType}=subaccounts`
            : undefined;

    return (url: string) => subAccountUrlPart ? `${url}${subAccountUrlPart}` : url;
};
