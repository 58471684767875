import { useTheme } from "styled-components";

export const OwnerIcon = () => {
    const { colors } = useTheme();

    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="12" fill={colors.textActiveFG} />
            <path
                d={`M23.3559 23.4745C23.1337 24.3109 22.2553 25.0001 21.4028 25.0001H15.6986C14.8461
                    25.0001 14.3294 24.3162 14.5516 23.4745L16.6441 15.5257C16.8663 14.6892 17.7447 14.0001
                    18.5972 14.0001H24.3014C25.1539 14.0001 25.6706 14.684 25.4484 15.5257L23.3559 23.4745Z`}
                fill="white" //{colors.white}
            />
        </svg>
    );
};
