import type { SelectState } from "@react-stately/select";
import type { Node } from "@react-types/shared";
import React from "react";
import { DismissButton, FocusScope, useListBox, useOption, useOverlay } from "react-aria";
import { List, ListContainer, ListItem } from "../../feature/form/FormSelect";
import { DropdownDirection } from "../../hooks/useDropdownDirection";
import { useSelector } from "../../hooks/useSelector";
import { TransitionState } from "../../hooks/useTransition";
import { ListSectionSeparator } from "./select.style";

export function ListBoxPopup({
    state,
    transitionState,
    compact,
    horizontal,
    vertical,
}: {
    state: SelectState<object>;
    transitionState: TransitionState;
    compact?: boolean;
} & DropdownDirection) {
    const ref = React.useRef(null);
    const isMobile = useSelector((state) => state.app.isMobile);

    // Get props for the listbox
    const { listBoxProps } = useListBox(
        {
            autoFocus: state.focusStrategy || true,
            disallowEmptySelection: true,
            label: "Select options",
        },
        state,
        ref,
    );

    // Handle events that should cause the popup to close,
    // e.g. blur, clicking outside, or pressing the escape key.
    const overlayRef = React.useRef(null);
    const { overlayProps } = useOverlay(
        {
            onClose: () => state.close(),
            shouldCloseOnBlur: true,
            isOpen: state.isOpen,
            isDismissable: true,
        },
        overlayRef,
    );

    // Wrap in <FocusScope> so that focus is restored back to the
    // trigger when the popup is closed. In addition, add hidden
    // <DismissButton> components at the start and end of the list
    // to allow screen reader users to dismiss the popup easily.
    return (
        <FocusScope restoreFocus>
            <ListContainer
                {...overlayProps}
                horizontal={horizontal}
                vertical={vertical}
                transitionState={transitionState}
                ref={overlayRef}
                compact={compact}
                isMobile={isMobile}
            >
                <DismissButton onDismiss={() => state.close()} />
                <List {...listBoxProps} ref={ref}>
                    {[...state.collection].map((item) =>
                        item.type === "section" ? (
                            [
                                <ListSectionSeparator key={item.key} />,
                                ...[...item.childNodes].map((child) => (
                                    <FormSelectOption key={`${item.key}.${child.key}`} item={child} state={state} />
                                )),
                            ]
                        ) : (
                            <FormSelectOption key={item.key} item={item} state={state} />
                        ),
                    )}
                </List>
                <DismissButton onDismiss={() => state.close()} />
            </ListContainer>
        </FocusScope>
    );
}

export function FormSelectOption({ item, state }: { state: SelectState<unknown>; item: Node<unknown> }) {
    // Get props for the option element
    const ref = React.useRef<HTMLLIElement>(null);
    const isDisabled = state.disabledKeys.has(item.key);
    const isSelected = state.selectionManager.isSelected(item.key);
    const { optionProps } = useOption(
        {
            key: item.key,
            isDisabled,
            isSelected,
            // shouldSelectOnPressUp: true,
            shouldFocusOnHover: true,
        },
        state,
        ref,
    );

    return (
        <ListItem {...optionProps} ref={ref} isSelected={isSelected}>
            {item.rendered}
        </ListItem>
    );
}
