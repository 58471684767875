import { delay, put, race, take, select } from "@redux-saga/core/effects";
import { IRootState } from "store";
import { addNotification, closeNotification, createNotification } from "..";
import { DEFAULT_NOTIFICATION_TIMEOUT } from "../../../../const";
import { buildNotification } from "../../../../utils/buildNotification";

const notificationSound = new Audio("/sfx/notification.wav");

const getEnableSoundSetting = (state: IRootState) => state.settings.enableSound;

export function* sagaCreateNotification({ payload: notification }: ReturnType<typeof createNotification>) {
    yield put(addNotification(buildNotification(notification)));
    const enableSound: boolean = yield select(getEnableSoundSetting);

    if (enableSound) {
        if (notificationSound.paused) {
            notificationSound.play();
        } else {
            try {
                notificationSound.fastSeek(0);
            } catch {
                notificationSound.currentTime = 0;
            }
        }
    }

    if (notification.timeout === false) return;

    // close notification after delay or receiving close event
    const { timeout } = yield race({
        timeout: delay(notification.timeout || DEFAULT_NOTIFICATION_TIMEOUT),
        closeAction: take(({ type, payload }: any) => {
            return type === "closeNotification" && notification.id === payload.notificationId;
        }),
    });
    if (timeout) {
        yield put(closeNotification(notification.id));
    }
}
