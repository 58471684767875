import React from "react";
import styled from "styled-components";
import { TextSmall } from "../typography";

const Container = styled.div`
    display: flex;
    padding: 4px 0;
    gap: 5px;

    svg,
    img {
        height: 16px;
        width: 16px;
    }
`;

const Label = styled(TextSmall)`
    color: ${(p) => p.theme.colors.ui52};
`;

interface HeaderBadgeProps {
    Icon?: React.ReactNode;
    label: string;
}

export const HeaderLabel: React.VFC<HeaderBadgeProps> = (props) => {
    const { Icon, label } = props;

    const iconContent = Icon ?? null;

    return (
        <Container>
            <Label>{label}</Label>
            {iconContent}
        </Container>
    );
};
