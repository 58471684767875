import { CpDropdownSelector } from "components/Selectors/CpDropdown";
import { Control } from "react-hook-form";
import { CpRow } from "../types";
import { Filters } from "../useFilters";

export const CpFilter = ({
    control,
    counterparties,
}: {
    control: Control<Filters>;
    counterparties: CpRow[];
}) => {
    const cpsIds = counterparties.map(({ id }) => id);

    return <CpDropdownSelector name="cpFilter" control={control} cpsIds={cpsIds} />;
};
