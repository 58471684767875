import styled from "styled-components";

export const Title = styled.div`
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
