import React from "react";
import styled from "styled-components";
import { useHandleLogout } from "../../hooks/useHandleLogout";
import { useIsSubaccountUser } from "../../store/hooks";
import { MenuLink as BaseMenuLink, menuLinkStyles } from "../../style/menu";

const MenuContainer = styled.div`
    padding: 12px;
    display: flex;
    flex-direction: column;
    // FIX change with our color from UI Kit
    border-right: 1px solid var(--whitesmoke-b2b502);

    @media (max-width: 1024px) {
        display: none;
    }
`;

const LogoutLink = styled.a`
    ${menuLinkStyles}
    &:not(.active,:active,:hover) {
        // FIX change with our color from UI Kit
        color: var(--darkgray-3837b1);
    }
`;

const MenuLink = styled(BaseMenuLink)`
    min-width: 196px;
`;

export const SettingsMenu = () => {
    const isSubaccountUser = useIsSubaccountUser();

    const handleLogout = useHandleLogout();
    const onLogoutClick = (ev: React.MouseEvent) => {
        ev.preventDefault();
        handleLogout();
    };

    return (
        <MenuContainer>
            <MenuLink exact to="/settings/profile">
                Account data
            </MenuLink>
            <MenuLink exact to="/settings/usersmanagement">
                Users and roles
            </MenuLink>
            {!isSubaccountUser && (
                <MenuLink exact to="/settings/referrals">
                    Referrals
                </MenuLink>
            )}
            <MenuLink exact to="/settings/security">
                Login and security
            </MenuLink>
            <MenuLink exact to="/settings/notifications">
                Notifications
            </MenuLink>
            <LogoutLink onClick={onLogoutClick}>Logout</LogoutLink>
        </MenuContainer>
    );
};
