import {
    BasicInput,
    Form,
    FormActions,
    FormAttention,
    FormBody,
    FormInfo,
    FormProgress,
    Header as FormHeader,
    HeaderTitle,
    LinkButton,
    P,
    PrimaryButton,
    ShadowCard,
} from "@fm-frontend/uikit";
import { FormCard } from "@fm-frontend/uikit/src/components/forms/FormCard";
import { AUTH_ERRORS } from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { IconError } from "components/icons";
import { LinkPrivacyPolicy } from "components/Links";
import { useSelector } from "hooks";
import { useSettings } from "hooks/useSettings";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { signUp } from "store/auth/actions";
import { authenticationSlice } from "store/auth/slice";
import { OnboardingMap, ONBOARDING_STEPS } from "store/onboardingSlice";
import { ClientData, InviteToken } from "types";
import { displayError, when } from "utils";
import { decodeInviteJwtToken } from "utils/decodeInviteJwtToken";
import { accountDataSchema, SignUpInputs } from "./schema";

type SignUpProps = {
    invite: string | undefined;
};
export const SignUp = ({ invite }: SignUpProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [email, setEmail] = useState<string | undefined>();

    const decodeInviteToken: InviteToken | undefined = useMemo(
        () => decodeInviteJwtToken(invite),
        [invite],
    );

    const settings = useSettings();
    const authError = useSelector((state) => state.authentication.error);
    const authLoading = useSelector((state) => state.authentication.loading);

    const {
        register,
        formState: { errors, isSubmitting },
        setValue,
        handleSubmit,
    } = useForm<SignUpInputs>({
        defaultValues: {},
        mode: "onSubmit",
        resolver: yupResolver(accountDataSchema),
    });

    useEffect(() => {
        if (!decodeInviteToken) {
            return;
        }

        try {
            setEmail(decodeInviteToken.email);
            setValue("companyName", decodeInviteToken.name);

            if (!decodeInviteToken.type) {
                throw new Error("Invalid invitation link provided");
            }

            dispatch(
                authenticationSlice.actions.setClientData({
                    clientType: decodeInviteToken.type,
                    primeBrokerId: decodeInviteToken.primeBrokerId,
                } as ClientData),
            );
        } catch (error) {
            displayError(error);
        }
    }, [decodeInviteToken]);

    const submit = async (data: SignUpInputs) => {
        if (!invite || !email) {
            return;
        }

        await dispatch(
            // @ts-ignore
            signUp({
                username: data.companyName,
                password: data.password,
                invite,
            }),
        );
    };

    const handleNavigateToLogin = () => {
        dispatch(authenticationSlice.actions.resetError());
        history.push("/login");
    };

    return (
        <Form onSubmit={handleSubmit(submit)}>
            <FormProgress
                currentStep={OnboardingMap[ONBOARDING_STEPS.ACCOUNT_DATA].progress.current}
                totalSteps={OnboardingMap[ONBOARDING_STEPS.ACCOUNT_DATA].progress.total}
            />
            <ShadowCard>
                <FormHeader>
                    <HeaderTitle title="Account data" />
                </FormHeader>
                <FormBody>
                    <FormCard>
                        <FormCard.Content>
                            <FormCard.Caption>Email</FormCard.Caption>
                            <FormCard.Essence>{decodeInviteToken?.email}</FormCard.Essence>
                        </FormCard.Content>
                        {!decodeInviteToken?.allowChangeInvitedUsername && (
                            <FormCard.Content>
                                <FormCard.Caption>Display name</FormCard.Caption>
                                <FormCard.Essence>{decodeInviteToken?.name}</FormCard.Essence>
                            </FormCard.Content>
                        )}
                    </FormCard>
                    {decodeInviteToken?.allowChangeInvitedUsername && (
                        <BasicInput
                            label="Your company name"
                            placeholder="Company Name"
                            hint="Use a brand name of your company for other users to see. Max 15 symbols"
                            {...register("companyName")}
                            error={errors.companyName?.message}
                        />
                    )}

                    <BasicInput
                        type="password"
                        label="Create password"
                        hint="Password must be at least 10 characters, contain one uppercase and one digit"
                        placeholder="Password"
                        {...register("password")}
                        error={errors.password?.message}
                    />
                    <BasicInput
                        type="password"
                        label="Repeat password"
                        placeholder="Repeat Password"
                        {...register("confirmPassword")}
                        error={errors.confirmPassword?.message}
                    />
                    {authError && (
                        <FormAttention>
                            <IconError />
                            <P>
                                {authError.message}{" "}
                                {when(
                                    authError.code === AUTH_ERRORS.UsernameExistsException,
                                    <LinkButton
                                        onClick={handleNavigateToLogin}
                                        type="button"
                                        size="small"
                                    >
                                        Login
                                    </LinkButton>,
                                )}
                            </P>
                        </FormAttention>
                    )}
                    {settings.privacy_policy_link && (
                        <FormInfo>
                            The obtained data is processed in accordance with our{" "}
                            <LinkPrivacyPolicy />
                        </FormInfo>
                    )}
                    {!settings.privacy_policy_link && (
                        <FormInfo>
                            The obtained data will be processed by {settings.display_name} in
                            accordance with client agreement
                        </FormInfo>
                    )}
                </FormBody>
                <FormActions variant="plain">
                    <PrimaryButton
                        type="submit"
                        fullWidth
                        size="large"
                        loading={isSubmitting || authLoading}
                    >
                        Continue
                    </PrimaryButton>
                </FormActions>
            </ShadowCard>
        </Form>
    );
};
