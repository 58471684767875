import { Settlement } from "../types";
import { IncomingRequestModal } from "./detailsModals/IncomingRequestModal";
import { NonActionableModal } from "./detailsModals/NonActionableModal";
import { OutgoingCreatedTransactionModal } from "./detailsModals/OutgoingCreatedTransactionModal";
import { OutgoingRequestModal } from "./detailsModals/OutgoingRequestModal";
import { RecievedTransactionModal } from "./detailsModals/RecievedTransactionModal";
import { SentTransactionModal } from "./detailsModals/SentTransactionModal";

type SettlementModalProps = {
    closeModal: () => void;
    settlement: Settlement;
    onContinueClick: (settlement: Settlement) => void;
};

export const SettlementDetailsModal = ({ closeModal, settlement, onContinueClick }: SettlementModalProps) => {
    switch (settlement.status) {
        case "committed":
        case "cancelled":
            return <NonActionableModal closeModal={closeModal} settlement={settlement} />;
        case "received":
            return <RecievedTransactionModal closeModal={closeModal} settlement={settlement} />;
        case "created":
            if (settlement.type === "out") {
                return <OutgoingCreatedTransactionModal closeModal={closeModal} settlement={settlement} />;
            }
            return <NonActionableModal closeModal={closeModal} settlement={settlement} />;
        case "sent":
            return <SentTransactionModal closeModal={closeModal} settlement={settlement} />;
        case "request":
            if (settlement.type === "out") {
                return <OutgoingRequestModal closeModal={closeModal} settlement={settlement} />;
            }
            return (
                <IncomingRequestModal
                    closeModal={closeModal}
                    settlement={settlement}
                    onContinueClick={onContinueClick}
                />
            );
    }

    return null;
};
