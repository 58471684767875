import { BasicButton, Center, H2Bold, HStack, Icons, PBold, VStack } from "@fm-frontend/uikit";
import { useToggle } from "@fm-frontend/utils";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import styled from "styled-components";
import { FM_TAndC_FILE_NAME } from "../const";
import { Logo } from "./Logo";

const Page = styled.div`
    @media print {
        @page {
            size: portrait;
            margin: calc(1in - 12px);
        }
    }

    padding: 12px;
`;

const ShiftContainer = styled.div`
    position: absolute;
    left: -9999px;
`;

export const TnCPrint: React.FC = ({ children }) => {
    const componentRef = useRef(null);
    const { isActive, activate, deactivate } = useToggle();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: FM_TAndC_FILE_NAME,
        onBeforeGetContent: async () => {
            activate();
        },
        onAfterPrint: () => deactivate(),
    });

    return (
        <>
            <BasicButton type="button" size="small" onClick={handlePrint} loading={isActive}>
                <HStack spacing={6} alignItems="center">
                    <Icons.File />
                    <PBold>Download PDF</PBold>
                </HStack>
            </BasicButton>
            <ShiftContainer>
                {isActive && (
                    <Page ref={componentRef}>
                        <VStack width="100%" spacing={12}>
                            <Logo />
                            <Center>
                                <H2Bold>Terms & Conditions</H2Bold>
                            </Center>
                            {children}
                        </VStack>
                    </Page>
                )}
            </ShiftContainer>
        </>
    );
};
