import { Checkbox } from "@fm-frontend/uikit";
import { createNotification } from "feature/app";
import { settingSlice, State } from "feature/settings";
import { useDispatch } from "react-redux";

type SettingsGroup = "system" | "settlements" | "riskManagement";
interface EventSettingsRowProps {
    settingId: SettingsGroup;
    isInboxSettingOn: boolean;
    isEmailSettingOn: boolean;
    title: string;
    isEmailSettingDisabled?: boolean;
}

const SETTING_ID_MAP: {
    inbox: Record<SettingsGroup, keyof State>;
    email: Record<SettingsGroup, keyof State>;
} = {
    inbox: {
        system: "systemInbox",
        settlements: "settlementsInbox",
        riskManagement: "riskManagementInbox",
    },
    email: {
        system: "systemEmail",
        settlements: "settlementsEmail",
        riskManagement: "riskManagementEmail",
    },
};

export const EventSettingsRow = ({
    settingId,
    isInboxSettingOn,
    isEmailSettingOn,
    title,
    isEmailSettingDisabled,
}: EventSettingsRowProps) => {
    const dispatch = useDispatch();
    const getHandleChange = (settingType: "inbox" | "email") => () => {
        dispatch(
            settingSlice.actions.setSetting({
                key: SETTING_ID_MAP[settingType][settingId],
                value: settingType === "inbox" ? !isInboxSettingOn : !isEmailSettingOn,
            }),
        );
        dispatch(
            createNotification({
                type: "success",
                content: "Preference has been saved",
            }),
        );
    };

    return (
        <tr>
            <td>{title}</td>
            <td>
                <Checkbox onChange={getHandleChange("inbox")} checked={isInboxSettingOn} />
            </td>
            <td>
                <Checkbox
                    onChange={getHandleChange("email")}
                    disabled={isEmailSettingDisabled}
                    checked={isEmailSettingOn}
                />
            </td>
        </tr>
    );
};
