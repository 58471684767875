import { HStack, Icons, PSmallBold, VStack } from "@fm-frontend/uikit";
import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

const Layout = styled(VStack)`
    height: 40px;
`;

const LeftSecondsContainer = styled(HStack)`
    align-items: center;
    justify-content: center;
    gap: 6px;
    flex: 1;
`;

const progressAnimationCss = css<{ $animationDurationMs: number }>`
    @keyframes progress {
        0% {
            width: 278px;
        }
        100% {
            width: 0;
        }
    }

    animation: progress ${(p) => p.$animationDurationMs}ms linear;
`;

const ProgressBarContainer = styled.div<{ $animationDurationMs: number }>`
    position: relative;
    overflow: hidden;
    width: 0;
    height: 3px;
    ${progressAnimationCss}
`;

const ProgressBarInner = styled.div`
    height: 3px;
    width: 278px;
    background: linear-gradient(90deg, rgba(234, 255, 254, 0) 0%, rgba(234, 255, 254, 0.64) 80%),
        #16c7b0;
`;

const Milliseconds = styled.span`
    font-size: 10px;
`;

export const TimerWithProgressBar = ({
    onTimeout,
    finishAt,
}: {
    onTimeout: () => void;
    finishAt: number;
}) => {
    const startedAtRef = useRef(Date.now());

    const [left100MsTimes, setLeft100MsTimes] = useState(
        Math.ceil((finishAt - startedAtRef.current) / 100),
    );

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (left100MsTimes > 0) {
            const nextLeft100MsTimes = left100MsTimes - 1;
            const nextLeft100MsTimesWillBeAt = finishAt - nextLeft100MsTimes * 100;
            const msToLeft100MsTimes = nextLeft100MsTimesWillBeAt - Date.now();

            timeoutId = setTimeout(() => {
                setLeft100MsTimes(nextLeft100MsTimes);

                if (nextLeft100MsTimes === 0) {
                    onTimeout();
                }
            }, msToLeft100MsTimes);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [left100MsTimes, setLeft100MsTimes, onTimeout]);

    const leftWholeSeconds = Math.trunc(left100MsTimes / 10);
    const leftFractionalSeconds = left100MsTimes % 10;

    return (
        <Layout>
            <LeftSecondsContainer>
                <Icons.BannerAwaiting size={12} />
                <PSmallBold>
                    00:{leftWholeSeconds < 10 && "0"}
                    {leftWholeSeconds}.<Milliseconds>{leftFractionalSeconds}</Milliseconds>s
                </PSmallBold>
            </LeftSecondsContainer>
            <ProgressBarContainer $animationDurationMs={finishAt - startedAtRef.current}>
                <ProgressBarInner />
            </ProgressBarContainer>
        </Layout>
    );
};
