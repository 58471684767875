import type { ApiUser, UserData, UserPermissions } from "./types";

const TRADING = 1;
const API = 2;
const SETTLE = 4;
const USERS = 8;
const RISKS = 16;
const SUBACCOUNTS = 32;

const bitmaskToPermissions = (bitMask: number): UserPermissions => ({
    trading: Boolean(bitMask & TRADING),
    settle: Boolean(bitMask & SETTLE),
    api: Boolean(bitMask & API),
    users: Boolean(bitMask & USERS),
    risks: Boolean(bitMask & RISKS),
    subaccounts: Boolean(bitMask & SUBACCOUNTS),
});

export const permissionsToBitmask = (permissions: UserPermissions): number => {
    const { trading, settle, api, users, risks, subaccounts } = permissions;

    return (
        Number(trading && TRADING) |
        Number(settle && SETTLE) |
        Number(api && API) |
        Number(users && USERS) |
        Number(risks && RISKS) |
        Number(subaccounts && SUBACCOUNTS)
    );
};

export const convertApiUserToUser = ({ name, email, owner, accessFlags }: ApiUser): UserData => ({
    name,
    email,
    permissions: bitmaskToPermissions(accessFlags),
    isOwner: owner,
});

const collator = new Intl.Collator();
// Owner should be first, other users should be sorted by email
export const sortApiUsers = (apiUsers: ApiUser[]) => {
    return apiUsers.sort((user1, user2) => {
        if (user1.owner) return -1;
        if (user2.owner) return 1;

        return collator.compare(user1.email, user2.email);
    });
};
