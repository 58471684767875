import { ClientId, PSmall } from "@fm-frontend/uikit";
import { EmDash, ValueFormat } from "@fm-frontend/utils";
import { AuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { useGetAssetFractionDigits } from "hooks/useAssetFractionDigits";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { FC } from "react";
import { Header } from "../Header";
import { Loading } from "../Loading";
import { useModalContext } from "../ModalContext";
import {
    Action,
    Container,
    Content,
    Indicator,
    Item,
    ItemContent,
    ItemTitle,
    ItemWrapper,
    Status,
    Title,
    WidgetForwardIcon,
} from "../styled";
import { CpLimitWidgetModal } from "./modal";
import { useCpLimitInfo } from "./useCpLimitInfo";

type CpLimitProps = {
    authorizedClientId: AuthorizedClientId;
    noCpLimit: boolean;
};
export const CpLimit: FC<CpLimitProps> = ({ authorizedClientId, noCpLimit }) => {
    const { openModal } = useModalContext();
    const getAssetFractionDigits = useGetAssetFractionDigits();
    const { getCpLimitInfo, isLoading } = useCpLimitInfo();
    const { getCpName } = useCpInfoHelpers();

    if (authorizedClientId === null || isLoading) {
        return <Loading />;
    }

    const username = getCpName(authorizedClientId, "full");
    const { tradingEnabled, freeLimit, freeLimitPercent, cpLimitCurrency, cpLimit } =
        getCpLimitInfo(authorizedClientId);
    const assetFractionDigits =
        cpLimitCurrency !== undefined ? getAssetFractionDigits(cpLimitCurrency) : undefined;

    const tradingEnabledFormatted = tradingEnabled ? "Enabled" : "Disabled";
    const cpLimitFormatted =
        cpLimit !== undefined && assetFractionDigits !== undefined
            ? ValueFormat.price(cpLimit, assetFractionDigits.fractionDigits)
            : EmDash;
    const freeLimitFormatted =
        freeLimit !== undefined && assetFractionDigits !== undefined
            ? ValueFormat.price(freeLimit, assetFractionDigits.fractionDigits)
            : EmDash;
    const freeLimitPercentFormatted =
        freeLimitPercent !== undefined ? `${freeLimitPercent.toFixed(2)}%` : "";

    return (
        <>
            <Container>
                <Header onOpen={openModal}>
                    <Title>
                        {username} <ClientId id={authorizedClientId} />
                    </Title>
                    <Action>
                        <WidgetForwardIcon />
                    </Action>
                </Header>
                <Content noCpLimit={noCpLimit}>
                    {freeLimitPercent !== undefined && (
                        <Indicator $disabled={!tradingEnabled} $percent={freeLimitPercent} />
                    )}
                    <Item>
                        <ItemTitle>Free</ItemTitle>
                        <ItemContent>
                            <PSmall ellipsis title={`${freeLimitFormatted} ${cpLimitCurrency}`}>
                                {freeLimitFormatted}
                            </PSmall>
                            <PSmall>{cpLimitCurrency}</PSmall>
                            {freeLimitPercent !== undefined && (
                                <Status $disabled={!tradingEnabled} $percent={freeLimitPercent}>
                                    {freeLimitPercentFormatted}
                                </Status>
                            )}
                        </ItemContent>
                    </Item>
                    <ItemWrapper>
                        <Item>
                            <ItemTitle>CP limit</ItemTitle>
                            <ItemContent>
                                <PSmall ellipsis title={`${cpLimitFormatted} ${cpLimitCurrency}`}>
                                    {cpLimitFormatted}
                                </PSmall>
                                <PSmall>{cpLimitCurrency}</PSmall>
                            </ItemContent>
                        </Item>
                        <Item>
                            <ItemTitle>Trading</ItemTitle>
                            <ItemContent>
                                <PSmall>{tradingEnabledFormatted}</PSmall>
                            </ItemContent>
                        </Item>
                    </ItemWrapper>
                </Content>
            </Container>
            <CpLimitWidgetModal authorizedClientId={authorizedClientId} />
        </>
    );
};
