import {
    Tab as ReactTab,
    TabList as ReactTabList,
    TabPanel as ReactTabPanel,
    Tabs as ReactTabs,
} from "react-tabs";
import styled from "styled-components";

export const Tabs = styled(ReactTabs)``;

export const TabList = styled(ReactTabList)`
    list-style-type: none;
    padding: 4px;
    display: flex;
    margin: 0;
`;

export const Tab = styled(ReactTab)`
    margin-right: 12px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    color: rgba(36, 48, 52, 0.32);
    cursor: pointer;

    &.is-selected {
        color: ${(p) => p.theme.colors.textFG};
    }
`;

export const TabPanel = styled(ReactTabPanel)`
    display: none;
    min-height: 40vh;
    border: 1px solid black;
    padding: 4px;
    margin-top: -5px;

    &.is-selected {
        display: block;
    }
`;
