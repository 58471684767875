import { isSubaccountCp } from "feature/counterparties/utils";
import { Redirect } from "react-router";
import { useUserType } from "store/hooks";
import { MakerMasterInstrumentsTabContent } from "./MakerMasterInstrumentsTabContent";

const RedirectToHome = () => <Redirect to="/" />;

export const InstrumentsTabContent = () => {
    const userType = useUserType();

    if (isSubaccountCp(userType)) {
        return <RedirectToHome />;
    }

    switch (userType) {
        case "maker":
        case "primeBroker":
            return <MakerMasterInstrumentsTabContent />;
        default:
            return <RedirectToHome />;
    }
};
