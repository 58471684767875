import { Switch, TextSmall } from "@fm-frontend/uikit";
import { post } from "api";
import { createNotification, setConfirmModalContent } from "feature/app";
import { GroupSwitch } from "feature/counterparties/styled";
import { EventContext } from "hooks/useEventEmitter";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { CounterpartyLimit } from "types";
import { isEnabledTradingByUser } from "utils";
import { parseLimit } from "../../utils";

const Label = styled(TextSmall)`
    display: flex;
    gap: 8px;
`;

export const EnableTrading: React.FC<{
    limit: CounterpartyLimit;
    setError: (error: string | null) => void;
    label?: string;
}> = (props) => {
    const { limit, setError, label = "Enable trading" } = props;
    const dispatch = useDispatch();
    const eventEmitter = useContext(EventContext);
    const { counterpartyId, tradingStatus } = parseLimit(limit);

    const isTradingActive = isEnabledTradingByUser(tradingStatus);
    const handleTradingStatusChange = async () => {
        const config = isTradingActive
            ? {
                  method: "disableTrading",
                  action: "Stop",
                  title: "Stop your trading activity?",
                  description: "Your orders will be removed from the trading environment",
                  notification: "Trading has been stopped",
              }
            : {
                  method: "enableTrading",
                  action: "Start",
                  title: "Start your trading activity?",
                  description: "",
                  notification: "Trading has been started",
              };

        const requestFunc = async () => {
            try {
                await post(config.method, {
                    counterpartyId: Number(counterpartyId),
                });
                eventEmitter?.emit?.({ type: "enabledCpsChanged" });

                dispatch(
                    createNotification({
                        type: "success",
                        content: config.notification,
                    }),
                );
            } catch (e) {
                setError(String(e));
            }
        };

        dispatch(
            setConfirmModalContent({
                title: config.title,
                onConfirm: async () => {
                    await requestFunc();
                },
                description: config.description,
                confirmButtonTitle: config.action,
            }),
        );
    };

    return limit ? (
        <GroupSwitch>
            <Label>{label}</Label>
            <Switch onChange={handleTradingStatusChange} checked={Boolean(isTradingActive)} />
        </GroupSwitch>
    ) : null;
};
