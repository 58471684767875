const ONE_THOUSAND = 1e3;
const ONE_MILLION = 1e6;
const ONE_BILLION = 1e9;

const FIXED_SIZE = 2;

export const formatPosition = (position: number) => {
    const formattedNumber = Number(position.toFixed(FIXED_SIZE));

    if (Math.abs(formattedNumber) < ONE_THOUSAND) {
        return `${formattedNumber}`;
    }
    if (Math.abs(formattedNumber) >= ONE_THOUSAND && Math.abs(formattedNumber) < ONE_MILLION) {
        return `${(formattedNumber / ONE_THOUSAND).toFixed(FIXED_SIZE)}K`;
    }
    if (Math.abs(formattedNumber) >= ONE_MILLION && Math.abs(formattedNumber) < ONE_BILLION) {
        return `${(formattedNumber / ONE_MILLION).toFixed(FIXED_SIZE)}M`;
    }
    if (Math.abs(formattedNumber) >= ONE_BILLION) {
        return `${(formattedNumber / ONE_BILLION).toFixed(FIXED_SIZE)}B`;
    }

    return "";
};
