import { post } from "api";
import { NotificationId } from "./index";

type RequestParams = {
    ids: NotificationId[];
};

type ResponseParams = {};

export const markNotificationsAsRead = async (params: RequestParams) => {
    const response = await post("notifications/markNotificationsAsRead", params);

    return response as ResponseParams;
};
