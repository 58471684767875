import { P, Switch } from "@fm-frontend/uikit";
import React from "react";
import { useController, UseControllerProps } from "react-hook-form";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
`;

const Content = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px 12px 12px;
    gap: 4px;

    height: 44px;

    background-color: ${(p) => p.theme.colors.ui8};
    border-radius: 8px;
`;

const Label = styled.label`
    color: ${(p) => p.theme.colors.ui72};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
`;

const Title = styled(P)`
    color: ${(p) => p.theme.colors.ui72};
`;

interface Props extends UseControllerProps<any> {
    label?: string;
    title: string;
}

export const FormCheckbox: React.FC<Props> = ({ label, title, ...rest }) => {
    const { field } = useController({
        ...rest,
    });
    const { value, onChange } = field;

    return (
        <Container>
            {label && <Label>{label}</Label>}
            <Content>
                <Title>{title}</Title>
                <Switch checked={value} onChange={onChange} />
            </Content>
        </Container>
    );
};
