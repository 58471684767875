import { BasicInput, Form, FormAttention, H2, HStack, P, PlainButton, PrimaryButton, VStack } from "@fm-frontend/uikit";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { IconError } from "components/icons";
import { createNotification } from "feature/app";
import { useFormHelpers } from "hooks";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { SIGNING_LINK_UTILS } from "../utils";
import { sendSigningLinkSchema } from "./schemas";

type SendSigningLinkProps = {
    onClose: () => void;
    onSend: () => void;
};

export interface SendSigningLinkInputs {
    email: string;
}

export const SendSigningLink: React.FC<SendSigningLinkProps> = ({ onClose, onSend }) => {
    const dispatch = useDispatch();
    const { colors } = useTheme();
    const { error, setError } = useFormHelpers();
    const {
        register,
        setFocus,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<SendSigningLinkInputs>({
        resolver: yupResolver(sendSigningLinkSchema),
    });

    useEffect(() => {
        setFocus("email");
    }, [setFocus]);

    const onSubmit = async ({ email }: SendSigningLinkInputs) => {
        try {
            await SIGNING_LINK_UTILS.sendSingingLinkToEmail(email);
            dispatch(
                createNotification({
                    type: "success",
                    content: "Terms & Conditions was successfully sent for signing",
                }),
            );
            onSend();
            onClose();
        } catch (e) {
            setError(String(e));
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <VStack padding={12} paddingBottom={16}>
                <VStack spacing={12}>
                    <H2>Send signing link via email</H2>
                    <P color={colors.ui52}>We will notify you when T&C are signed by authorised person.</P>
                    <BasicInput
                        label="Send to email"
                        placeholder="email@example.com"
                        error={errors.email?.message}
                        {...register("email")}
                    />
                    {error && (
                        <FormAttention>
                            <IconError />
                            <P>{error}</P>
                        </FormAttention>
                    )}
                </VStack>
                <HStack spacing={8} margin={[16, 0, 0, 0]}>
                    <PlainButton size="large" fullWidth onClick={onClose} type="button">
                        Back
                    </PlainButton>
                    <PrimaryButton size="large" fullWidth loading={isSubmitting}>
                        Forward signing link
                    </PrimaryButton>
                </HStack>
            </VStack>
        </Form>
    );
};
