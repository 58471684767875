import { AuthorizedTradingStatus, isAuthorizedTradingEnabled } from "services/tradingService";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType } from "store/hooks";
import useSWR from "swr";

export const useIsAuthorizedTradingAvailable = () => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const basicCondition = isPrimeBrokerUser && primeBrokerViewType === "subaccounts";

    const { data: authorizedTradingStatus } = useSWR<
        AuthorizedTradingStatus,
        unknown,
        string | null
    >(basicCondition ? "authorized/isEnabled" : null, isAuthorizedTradingEnabled, {
        fallbackData: { enabled: false } as AuthorizedTradingStatus,
    });

    return authorizedTradingStatus?.enabled ?? false;
};
