import styled from "styled-components";

export const Content = styled.div`
    padding: 0 16px;
`;

export const CenteredContent = styled.div`
    margin: 0 auto;
    max-width: 443px;
    width: 100%;
    padding-bottom: 20px;
`;

export const Card = styled.div`
    margin-top: 24px;
`;

export const Title = styled.div`
    padding: 0 0 16px 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.02em;
    // FIX change with our color from UI Kit
    border-bottom: 1px solid var(--whitesmoke-b2b502);
`;

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 12px 0 13px;
    // FIX change with our color from UI Kit
    border-bottom: 1px solid var(--whitesmoke-b2b502);
    align-items: center;
    font-weight: 600;

    &:last-child {
        border-bottom: none;
    }

    & > div {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.005em;
        // FIX change with our color from UI Kit
        color: var(--darkgray-3837b1);
        height: 32px;
        display: flex;
        align-items: center;
    }

    & > span {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        text-align: right;
        letter-spacing: -0.005em;
        // FIX change with our color from UI Kit
        color: var(--black-81ef71);
    }
`;
