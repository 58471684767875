import { ACTIONS_TABLE_COLUMN_KEY, LightBox } from "@fm-frontend/uikit";
import { useFormShouldCloseConfirm, useModalControl } from "@fm-frontend/utils";
import { Row } from "@tanstack/react-table";
import React, { useMemo, useState } from "react";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { useAddressContext } from "../AddressesContext";
import { BankAddress, BankTableData, UseAddressType } from "../types";
import { filterAddresses, isAddressGroupType, SAB_BANK_LIGHTBOX_KEY } from "../utils";
import { BankAddressModal } from "./BankAddressModal";
import { BanksAddressLightBox } from "./BanksAddressLightBox";
import { BanksTable } from "./BanksTable";

type BankAddressesProps = {
    banksAddresses: UseAddressType<BankAddress>;
    sharedBanksAddresses: UseAddressType<BankAddress>;
};

export const BankAddresses: React.FC<BankAddressesProps> = ({ banksAddresses, sharedBanksAddresses }) => {
    const [defaultEdit, setDefaultEdit] = useState(false);
    const { side, isLightBoxOpen, closeLightBox, selectedStatuses, selectedCps } = useAddressContext();

    const shouldCloseConfirm = useFormShouldCloseConfirm(SAB_BANK_LIGHTBOX_KEY);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(shouldCloseConfirm, closeLightBox);

    const filteredBanksAddresses = useMemo(() => {
        return {
            data: filterAddresses(banksAddresses.data, [], selectedStatuses),
            isLoading: banksAddresses.isLoading,
        };
    }, [selectedStatuses, banksAddresses]);
    const filteredSharedBanksAddresses = useMemo(() => {
        return {
            data: filterAddresses(sharedBanksAddresses.data, selectedCps, selectedStatuses),
            isLoading: sharedBanksAddresses.isLoading,
        };
    }, [selectedStatuses, sharedBanksAddresses]);

    const { isModalOpen, closeModal, openModal } = useModalControl();
    const [selectedBankAddress, setSelectedBankAddress] = useState<BankAddress>();

    const handleRowClick = (row: Row<BankTableData>, cellId: string) => {
        if (row.getCanExpand()) {
            row.toggleExpanded();
            return;
        }

        if (isAddressGroupType(row.original)) {
            return;
        }

        setSelectedBankAddress(row.original);
        setDefaultEdit(() => cellId === ACTIONS_TABLE_COLUMN_KEY);
        openModal();
    };

    return (
        <>
            <BanksTable
                totalAddressesCount={banksAddresses.data.length}
                addresses={filteredBanksAddresses}
                side={side}
                sharedAddresses={filteredSharedBanksAddresses}
                onRowClick={handleRowClick}
            />
            <BankAddressModal
                address={selectedBankAddress}
                closeModal={closeModal}
                side={side}
                isModalOpen={isModalOpen}
                defaultEdit={defaultEdit}
            />
            <LightBox isOpen={isLightBoxOpen} onClose={closeModalWithConfirm}>
                <BanksAddressLightBox banksAddresses={banksAddresses.data} />
            </LightBox>
        </>
    );
};
