import {
    DiffDataResult,
    ValueConvert,
    ValueParse,
} from "@fm-frontend/utils";
import { FC, useCallback } from "react";
import {
    BulkUpdateRequest,
    mapBulkDataToAssetLimitRequest,
    mapBulkDataToOvernightsRequest,
    mapBulkDataToShortSalesRequest
} from "../ConfigurationModal/bulkService";
import { AssetsDiffTable } from "../ConfigurationModal/components/AssetsDiffTable";
import {
    AssetsBulkPreparedDataType,
    AssetsImportType,
    isEmptyOrRestrictedValue,
    isShortSalesOn,
} from "../ConfigurationModal/utils";
import { AssetsBulkEditResultModal } from "../ResultModal";
import { DiffLightBox } from "../../components/DiffLightBox";

const prepareImportDataToApiData = (importItem: AssetsImportType): AssetsBulkPreparedDataType => ({
    asset: ValueConvert.normalize(importItem.asset),
    cpId: Number(importItem.cpId),
    shortSales: isEmptyOrRestrictedValue(importItem.shortSales)
        ? false
        : isShortSalesOn(importItem.shortSales),
    overnightsShort: isEmptyOrRestrictedValue(importItem.overnightsShort)
        ? undefined
        : Math.trunc(ValueParse.percent(importItem.overnightsShort) / 100) * 100,
    overnightsLong: isEmptyOrRestrictedValue(importItem.overnightsLong)
        ? undefined
        : Math.trunc(ValueParse.percent(importItem.overnightsLong) / 100) * 100,
    limitCurrency: isEmptyOrRestrictedValue(importItem.limitCurrency)
        ? undefined
        : ValueConvert.normalize(importItem.limitCurrency),
    limitAmount: isEmptyOrRestrictedValue(importItem.limitAmount)
        ? undefined
        : ValueParse.size(importItem.limitAmount),
});

type DiffLightBoxProps = {
    importData: AssetsImportType[];
    diffResult: DiffDataResult;
    diffTableColumns: string[];
    onClose: () => void;
    onBack: () => void;
};

export const AssetsBulkEditDiffLightBox: FC<DiffLightBoxProps> = ({
    importData,
    diffResult,
    diffTableColumns,
    onClose,
    onBack,
}) => {
    const getUpdateData = useCallback(() => {
        return importData.reduce<BulkUpdateRequest[]>(
            (acc, importItem, currentIndex) => {
                const diffItem = diffResult.diff[currentIndex];

                if (!diffItem) {
                    return acc;
                }

                if (diffItem.overnightsLong?.diff || diffItem.overnightsShort?.diff) {
                    acc.push(
                        mapBulkDataToOvernightsRequest(
                            prepareImportDataToApiData(importItem),
                        ),
                    );
                }
                if (diffItem.shortSales?.diff) {
                    acc.push(
                        mapBulkDataToShortSalesRequest(
                            prepareImportDataToApiData(importItem),
                        ),
                    );
                }
                if (diffItem.limitCurrency?.diff || diffItem.limitAmount?.diff) {
                    acc.push(
                        mapBulkDataToAssetLimitRequest(
                            prepareImportDataToApiData(importItem),
                        ),
                    );
                }

                return acc;
            },
            [],
        );
    }, [importData, diffResult.diff]);

    return (
        <DiffLightBox<BulkUpdateRequest>
            diffResult={diffResult}
            getUpdateData={getUpdateData}
            renderResultModal={(modalProps) => (
                <AssetsBulkEditResultModal {...modalProps} />
            )}
            onClose={onClose}
            onBack={onBack}
        >
            <AssetsDiffTable
                isLoading={diffResult.isLoading}
                columns={diffTableColumns}
                data={diffResult.diff}
            />
        </DiffLightBox>
    );
};
