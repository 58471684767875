import { Skeleton, VStack } from "@fm-frontend/uikit";
import { noop } from "@fm-frontend/utils";
import { BottomFixTableContainer } from "components/BottomFixTableContainer";
import { useInstrumentSelectorContext } from "components/InstrumentSelectorContext/useInstrumentSelectorContext";
import { SEARCH_PARAMS } from "const";
import { rfqSettingSlice } from "feature/rfq/loadRfqSettingsSaga";
import { useInstruments, useSelector } from "hooks";
import { useEventReceiver } from "hooks/useEventEmitter";
import { useFilterState } from "hooks/useFilterState";
import { Instrument } from "hooks/useInstruments";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useRfqProvidersAndInstruments } from "../../apiHooks/useRfqProvidersAndInstruments";
import { RfqRequestSide, useConnectToRfqWs } from "../../apiHooks/useRfqWs";
import { InstrumentSelector } from "../InstrumentSelector";
import { RfqRequestModal } from "../RfqRequestModal";
import { AddInstrumentTile } from "./tiles/AddInstrumentTile";
import { NoTilesTile } from "./tiles/NoTilesTile";
import { TradingTile } from "./tiles/TradingTile";

const TilesContainerScrollWrapper = styled.div`
    overflow-y: auto;
    height: inherit;
`;
const TilesContainer = styled.div`
    display: grid;
    grid-gap: 32px 8px;
    grid-template-columns: repeat(auto-fill, minmax(278px, 1fr));
    padding-bottom: 12px;
`;

const Layout = styled(VStack)`
    height: 100%;
    gap: 8px;
`;

const useRfqFavoriteInstruments = () => {
    const rfqFavoriteInstruments = useSelector((state) => state.rfqSettings.favoriteInstruments);
    const dispatch = useDispatch();

    const setRfqFavoriteInstruments = useCallback(
        (favoriteInstruments: string[]) => {
            dispatch(rfqSettingSlice.actions.setRfqFavoriteInstruments(favoriteInstruments));
        },
        [dispatch],
    );

    return [rfqFavoriteInstruments, setRfqFavoriteInstruments] as const;
};

const useActiveTileState = () => {
    return useFilterState<string>("activeRfqInstrument", SEARCH_PARAMS.activeRfqInstrument, "");
};

export const TakerRfqTilesTrading = () => {
    const { instrumentSelectorRef, focusInstrumentSelect } = useInstrumentSelectorContext();
    const { instruments, isLoading: isInstrumentsLoading } = useInstruments();
    const {
        rfqInstruments,
        isLoading: isRfqProvidersAndInstrumentsLoading,
        mutate: refreshRfqProvidersAndInstruments,
    } = useRfqProvidersAndInstruments();

    const handleEnabledCpsChange = useCallback(() => {
        refreshRfqProvidersAndInstruments();
    }, [refreshRfqProvidersAndInstruments]);
    useEventReceiver({
        fn: handleEnabledCpsChange,
        feed: "sd",
    });
    const isFirstRenderRef = useRef(true);
    useEffect(() => {
        if (!isRfqProvidersAndInstrumentsLoading && isFirstRenderRef.current) {
            refreshRfqProvidersAndInstruments();
        }
        isFirstRenderRef.current = false;
    }, [isRfqProvidersAndInstrumentsLoading]);

    const [activeTile, setActiveTile] = useActiveTileState();

    const [favoriteInstruments, setFavoriteInstruments] = useRfqFavoriteInstruments();
    const { availableInstruments, availableInstrumentsByNameMap, filteredFavoriteInstruments } =
        useMemo(() => {
            const availableInstruments = instruments.filter(({ instrumentName }) =>
                Object.hasOwn(rfqInstruments, instrumentName),
            );

            const availableInstrumentsByNameMap = availableInstruments.reduce<
                Record<string, Instrument>
            >((acc, instrument) => {
                acc[instrument.instrumentName] = instrument;
                return acc;
            }, {});

            const filteredFavoriteInstruments = favoriteInstruments.filter((instrumentName) =>
                Object.hasOwn(availableInstrumentsByNameMap, instrumentName),
            );

            return {
                availableInstruments,
                availableInstrumentsByNameMap,
                filteredFavoriteInstruments,
            };
        }, [favoriteInstruments, instruments, rfqInstruments]);
    const deleteTile = useCallback(
        (instrumentName: string) => {
            if (activeTile === instrumentName) {
                setActiveTile(filteredFavoriteInstruments[0] ?? null);
            }
            setFavoriteInstruments(
                filteredFavoriteInstruments.filter((name) => name !== instrumentName),
            );
        },
        [filteredFavoriteInstruments, setFavoriteInstruments, setActiveTile, activeTile],
    );
    const handleInstrumentSelect = (instrumentName: string) => {
        if (!filteredFavoriteInstruments.includes(instrumentName)) {
            setFavoriteInstruments([...filteredFavoriteInstruments, instrumentName]);
        }
        setActiveTile(instrumentName);
    };

    useConnectToRfqWs();

    const [rfqRequestData, setRfqRequestData] = useState<{
        requestSide: RfqRequestSide;
        providersClientsIds: number[];
        amount: bigint;
        instrumentName: string;
    } | null>(null);

    if (
        isInstrumentsLoading ||
        isRfqProvidersAndInstrumentsLoading ||
        isRfqProvidersAndInstrumentsLoading
    ) {
        return (
            <VStack spacing={20}>
                {Array(5)
                    .fill(0)
                    .map((_, i) => (
                        <Skeleton height="30px" key={i} />
                    ))}
            </VStack>
        );
    }

    const isEmptyList = filteredFavoriteInstruments.length === 0;

    return (
        <BottomFixTableContainer noScroll>
            <Layout>
                <InstrumentSelector
                    instruments={availableInstruments}
                    favoriteInstruments={filteredFavoriteInstruments}
                    onInstrumentSelect={handleInstrumentSelect}
                    onInstrumentDeselect={deleteTile}
                    ref={instrumentSelectorRef}
                />
                <TilesContainerScrollWrapper>
                    <TilesContainer>
                        {isEmptyList && <NoTilesTile />}
                        {!isEmptyList && (
                            <>
                                {filteredFavoriteInstruments.map((instrumentName) => {
                                    const { assetCurrency } =
                                        availableInstrumentsByNameMap[instrumentName];

                                    return (
                                        <TradingTile
                                            onDelete={deleteTile}
                                            defaultSelectedProvidersIds={null}
                                            onSelectedProvidersChange={noop}
                                            isActive={activeTile === instrumentName}
                                            setActiveTile={setActiveTile}
                                            key={instrumentName}
                                            instrumentName={instrumentName}
                                            assetCurrency={assetCurrency}
                                            onRfqRequest={({
                                                providersClientsIds,
                                                requestSide,
                                                amount,
                                            }) => {
                                                setRfqRequestData({
                                                    providersClientsIds,
                                                    requestSide,
                                                    amount,
                                                    instrumentName,
                                                });
                                            }}
                                        />
                                    );
                                })}
                                <AddInstrumentTile onAddInstrumentClick={focusInstrumentSelect} />
                            </>
                        )}
                    </TilesContainer>
                </TilesContainerScrollWrapper>
                {rfqRequestData && (
                    <RfqRequestModal
                        closeModal={() => setRfqRequestData(null)}
                        instrumentName={rfqRequestData.instrumentName}
                        providersClientsIds={rfqRequestData.providersClientsIds}
                        amount={rfqRequestData.amount}
                        balanceCurrency={
                            availableInstrumentsByNameMap[rfqRequestData.instrumentName]
                                .balanceCurrency
                        }
                        assetCurrency={
                            availableInstrumentsByNameMap[rfqRequestData.instrumentName]
                                .assetCurrency
                        }
                        requestSide={rfqRequestData.requestSide}
                    />
                )}
            </Layout>
        </BottomFixTableContainer>
    );
};
