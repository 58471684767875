import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    position: relative;
    height: 4px;
    width: 80px;
    border-radius: 4px;
    background-color: ${(p) => p.theme.colors.ui8};
`;

export const Line = styled.div<{ value: number }>`
    position: absolute;
    width: ${({ value }) => (value > 100 ? 100 : value)}%;
    height: 4px;
    border-radius: ${({ value }) => (value >= 100 ? "4px" : `4px 0 0 4px`)};

    background-color: ${(p) => p.theme.colors.brand100};
`;
export const LineProgress: React.VFC<{ value?: number }> = ({ value = 0 }) => {
    return (
        <Container>
            <Line value={value} />
        </Container>
    );
};
