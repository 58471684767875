import styled from "styled-components";

export const Content = styled.div`
    width: 360px;
`;

export const Strong = styled.span`
    color: ${(p) => p.theme.colors.brand};
`;

export const Name = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.005em;
`;

export const Email = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: ${(p) => p.theme.colors.textBody1};
`;

export const UserCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: space-between;
    padding: 12px;
    gap: 12px;

    min-height: 64px;
    // TODO think about usage of https://www.npmjs.com/package/color-alpha for such colors
    box-shadow: 0px 1px 2px rgba(36, 48, 52, 0.24),
        inset 0px 0.5px 0px ${(p) => p.theme.colors.textBody4};
    border-radius: 12px;
`;

export const TextButton = styled.button`
    border: none;
    background-color: inherit;
    cursor: pointer;
    display: inline-block;

    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    color: ${(p) => p.theme.colors.black};
`;

export const Pill = styled.div<{
    isActive?: boolean;
}>`
    padding: 4px 8px;
    height: 24px;
    background: ${(p) => p.theme.colors.textBody6};
    border-radius: 40px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: ${(p) => (p.isActive ? p.theme.colors.textBody1 : p.theme.colors.textBody3)};
    user-select: none;
`;

export const PillsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: flex-end;

    ${UserCard} & {
        flex: 1;
    }
`;
