import { ClientId, HStack, PBold } from "@fm-frontend/uikit";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { Statistic } from "../../Statistic";
import { Transaction } from "../../types";

export const SubaccountInfo = ({ subaccountId }: { subaccountId: Transaction["subaccount"]["id"] }) => {
    const { getCpName } = useCpInfoHelpers();
    const name = getCpName(subaccountId, "full");

    return (
        <Statistic
            title="Sub-account"
            value={
                <HStack spacing={6} alignItems="center">
                    <PBold>{name}</PBold>
                    <ClientId id={subaccountId} />
                </HStack>
            }
        />
    );
};
