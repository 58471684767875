import { H2, IconButton, Icons, P, PlainButton, PSmallBold } from "@fm-frontend/uikit";
import { useSelector } from "hooks/useSelector";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { NotificationItem } from "../NotificationItem";
import { notificationsLoadMore, notificationsReadAllRequest } from "../notifications.store";
import {
    Content,
    Description,
    EmptyContent,
    Footer,
    Header,
    LoadMoreButton,
    NotificationList,
    Panda,
    Strong,
} from "./styled";

const SettingsButton = ({ closePopover }: { closePopover: () => void }) => {
    const history = useHistory();
    const handleClick = () => {
        closePopover();
        history.push("/settings/notifications");
    };

    return <IconButton variant="basic" Icon={Icons.Settings} onClick={handleClick} />;
};

const EmptyInboxPopoverContent = ({ closePopover }: { closePopover: () => void }) => (
    <Content>
        <Header>
            <H2>Inbox</H2>
            <SettingsButton closePopover={closePopover} />
        </Header>
        <EmptyContent>
            <Panda>🐼</Panda>
            <P>Very quiet here</P> <br />
            <Description>
                <PSmallBold>Inbox</PSmallBold> is the place, where all important notifications will
                be located.
            </Description>
        </EmptyContent>
    </Content>
);

export const InboxPopoverContent = ({ closePopover }: { closePopover: () => void }) => {
    const dispatch = useDispatch();
    const { loading, totalElements, notifications, unreadCount } = useSelector(
        (state) => state.notifications,
    );

    if (!notifications.length) {
        return <EmptyInboxPopoverContent closePopover={closePopover} />;
    }

    const hasLoadMoreButton = notifications.length < totalElements;

    const handleLoadMoreClick = () => {
        dispatch(notificationsLoadMore());
    };

    const handleReadAllClick = () => {
        dispatch(notificationsReadAllRequest());
    };

    return (
        <Content>
            <Header>
                <H2>Inbox {unreadCount > 0 && <Strong>{unreadCount}</Strong>}</H2>
                <SettingsButton closePopover={closePopover} />
            </Header>
            <NotificationList>
                {[...notifications]
                    .sort((a, b) => b.sentAt - a.sentAt)
                    .map((notification) => (
                        <NotificationItem
                            key={notification.id}
                            notification={notification}
                            closePopover={closePopover}
                        />
                    ))}
                {hasLoadMoreButton && (
                    <LoadMoreButton
                        onClick={handleLoadMoreClick}
                        size="medium"
                        fullWidth
                        loading={loading}
                    >
                        Load more
                    </LoadMoreButton>
                )}
            </NotificationList>
            <Footer>
                <PlainButton size="small" onClick={handleReadAllClick}>
                    Mark all as read
                </PlainButton>
            </Footer>
        </Content>
    );
};
