import styled, { keyframes } from "styled-components";

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const Skeleton = styled.div<{
    width?: string;
    height?: string;
    bgColor?: string;
    highlightColor?: string;
}>`
    display: inline-block;
    height: ${(p) => p.height || "30px"};
    width: ${(p) => p.width || "100%"};
    animation: ${skeletonKeyframes} 1100ms ease-in-out infinite;
    // TODO not theme color due to issues with opacity in gradient
    background-color: ${(p) => p.bgColor || "#eee"};
    background-image: linear-gradient(
        90deg,
        ${(p) => p.bgColor || "#eee"},
        ${(p) => p.highlightColor || p.theme.colors.bg},
        ${(p) => p.bgColor || "#eee"}
    );
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
`;
