import { useSWRConfig } from "swr";
import { ScopedMutator } from "swr/_internal";
import { post } from "../../api";
import { useUsers as useUsersApi } from "../../hooks/useUsers";
import { displayError } from "../../utils";
import type { ApiUser, UserData } from "./types";
import { convertApiUserToUser, permissionsToBitmask, sortApiUsers } from "./utils";

const USERS_URL = "getUsers";

export const useUsers = () => {
    const { data } = useUsersApi();
    const apiUsers: ApiUser[] = sortApiUsers(data ?? []);

    return {
        activeUsers: apiUsers.filter(({ active }) => active).map(convertApiUserToUser),
        pendingUsers: apiUsers.filter(({ active }) => !active).map(convertApiUserToUser),
    };
};

const withRefetch = (promise: Promise<unknown>, mutate: ScopedMutator) => promise.then(() => mutate(USERS_URL));

const withErrorNotificationHandler = (promise: Promise<unknown>) =>
    promise.catch((err) => {
        displayError(err);

        throw err;
    });

export const useCreateUser = () => {
    const { mutate } = useSWRConfig();

    return ({ name, email, permissions }: Pick<UserData, "name" | "email" | "permissions">) => {
        const requestData = {
            name,
            email,
            accessRights: permissionsToBitmask(permissions),
        };

        return withRefetch(post("addUser", requestData), mutate);
    };
};

export const useUpdateUser = () => {
    const { mutate } = useSWRConfig();

    return ({ email, permissions }: Pick<UserData, "email" | "permissions">) => {
        const requestData = {
            email,
            accessRights: permissionsToBitmask(permissions),
        };

        return withRefetch(post("updateUserAccessRights", requestData), mutate);
    };
};

export const useDeleteUser = () => {
    const { mutate } = useSWRConfig();

    return ({ email }: Pick<UserData, "email">) => {
        const requestData = { email };

        return withErrorNotificationHandler(withRefetch(post("delUser", requestData), mutate));
    };
};
