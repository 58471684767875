import { nanoid } from "@reduxjs/toolkit";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../feature/app";

export const IsLoadingContext = createContext<null | ((id: string) => () => void)>(null);

export const IsLoadingProvider: React.FC = ({ children }) => {
    const dispatch = useDispatch();

    const [entries, setEntries] = useState<string[]>([]);
    const isLoading = useMemo(() => {
        return entries.length > 0;
    }, [entries]);
    useEffect(() => {
        dispatch(setIsLoading(isLoading));
    }, [isLoading]);
    const fn = useCallback((id: string) => {
        setEntries((entries) => [...entries, id]);
        return () => {
            setEntries((entries) => entries.filter((entry) => entry !== id));
        };
    }, []);
    return <IsLoadingContext.Provider value={fn}>{children}</IsLoadingContext.Provider>;
};
export const useIsLoading = () => {
    const id = useMemo(() => nanoid(), []);
    const [isLoading, setIsLoading] = useState(false);

    const ctx = useContext(IsLoadingContext);

    useEffect(() => {
        if (isLoading && ctx) {
            return ctx(id);
        }
    }, [isLoading, ctx]);
    return setIsLoading;
};
