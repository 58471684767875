import { Accordion } from "@fm-frontend/uikit";
import { AccordionWithConfirmation } from "components/AccordionWithConfirmation";
import { ROUTES } from "feature/app/router";
import { HistoryTab } from "feature/history/trades/hooks";
import { AuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { useAuthorizedClientInfo } from "feature/trade/hooks/useAuthorizedClientInfo";
import { useAuthorizedLastOrders } from "feature/trade/hooks/useAuthorizedLastOrders";
import { FC } from "react";
import { DISPLAY_TRADING_LAST_TRADES_LIMIT } from "../../utils";
import { LastOrdersTable } from "../LastOrdersTable";

type AuthorizedLastOrdersProps = {
    authorizedClientId: AuthorizedClientId;
};

export const AuthorizedLastOrders: FC<AuthorizedLastOrdersProps> = ({ authorizedClientId }) => {
    const authorizedClientInfo = useAuthorizedClientInfo(authorizedClientId);

    const authorizedTradingTitle = authorizedClientInfo
        ? `${authorizedClientInfo.username}'s last orders`
        : "";

    const { trades, isLoading } = useAuthorizedLastOrders(
        DISPLAY_TRADING_LAST_TRADES_LIMIT,
        authorizedClientId,
    );

    return (
        <AccordionWithConfirmation
            defaultExpanded
            header={<Accordion.Title upperCase>{authorizedTradingTitle}</Accordion.Title>}
            content={<LastOrdersTable orders={trades} isLoading={isLoading} />}
            moveToDetails={{
                detailsPageUrl: `${ROUTES.historyTrades}/${HistoryTab.FirmBook}?cp=${authorizedClientId}`,
                fromSectionTitle: "Trading Page",
                toSectionTitle: "History Page",
                buttonTitle: "View history",
            }}
        />
    );
};
