import format from "date-fns/format";
import { FC } from "react";
import styled from "styled-components";
import { BasicButton } from "../buttons";
import { Icons } from "../icons";
import { PSmall } from "../typography";

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    color: ${(p) => p.theme.colors.ui72};
    padding: 4px 12px 12px 12px;
    height: 40px;
`;

const Description = styled(PSmall)`
    padding: 0 4px;
`;

const Button = styled(BasicButton).attrs({ size: "small" })`
    svg path {
        fill: ${(p) => p.theme.colors.ui32};
    }
    &[disabled] svg path {
        fill: ${(p) => p.theme.colors.ui20};
    }
`;

type Range = [startDate: Date | number | null, endDate: Date | number | null];

export const calculateRange = <T extends Record<string, any>, K extends keyof T>(
    data: T[],
    dateField: K & (T[K] extends Date | number | undefined ? K : never),
) =>
    data.reduce(
        (acc, item) => {
            const date = item[dateField];
            if (date === undefined) {
                return acc;
            }
            if (acc[0] === null || acc[0] > date) {
                acc[0] = date;
            }
            if (acc[1] === null || acc[1] < date) {
                acc[1] = date;
            }
            return acc;
        },
        [null, null] as Range,
    );

const formatCounts = (pageItemsCount: number, allItemsCount: number) => {
    if (pageItemsCount === allItemsCount) {
        return `Last ${pageItemsCount} showed`;
    }

    return `${allItemsCount - pageItemsCount}–${allItemsCount} showed`;
};

const formatRange = ([startDate, endDate]: Range) => {
    const startDateDefined = startDate !== undefined && startDate !== null;
    const endDateDefined = endDate !== undefined && endDate !== null;

    if (startDateDefined && endDateDefined) {
        const preparedStartDate = startDate instanceof Date ? startDate : new Date(startDate);
        const preparedEndDate = endDate instanceof Date ? endDate : new Date(endDate);

        return `${format(preparedStartDate, "dd MMM")} - ${format(preparedEndDate, "dd MMM")}`;
    }

    if (startDateDefined && !endDateDefined) {
        const preparedStartDate = startDate instanceof Date ? startDate : new Date(startDate);

        return format(preparedStartDate, "dd MMM");
    }

    if (!startDateDefined && endDateDefined) {
        const preparedEndDate = endDate instanceof Date ? endDate : new Date(endDate);

        return format(preparedEndDate, "dd MMM");
    }

    return "";
};

type PaginatorProps = {
    range: Range;
    pageItemsCount: number;
    allItemsCount: number;
    isLoading?: boolean;
    hasPrevPage?: boolean;
    hasNextPage?: boolean;
    onPrevClick?: () => void;
    onNextClick?: () => void;
    className?: string;
};

export const Paginator: FC<PaginatorProps> = ({
    range,
    pageItemsCount,
    allItemsCount,
    isLoading = false,
    hasPrevPage = true,
    hasNextPage = true,
    onPrevClick,
    onNextClick,
    className,
}) => {
    return (
        <Container className={className}>
            <Button onClick={onPrevClick} disabled={isLoading || !hasPrevPage}>
                <Icons.ArrowLeft /> Prev
            </Button>
            <Button onClick={onNextClick} disabled={isLoading || !hasNextPage}>
                Next <Icons.ArrowRight />
            </Button>
            {!isLoading && allItemsCount !== 0 && (
                <Description>
                    {formatCounts(pageItemsCount, allItemsCount)}, {formatRange(range)}
                </Description>
            )}
        </Container>
    );
};
