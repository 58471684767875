import { FormAttention, H2, P, PrimaryButton, SimpleInput, VStack } from "@fm-frontend/uikit";
import { getEnv, useFormCloseConfirmer } from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { post } from "api";
import { object, SchemaOf, string } from "yup";
import { IconError } from "components/icons";
import { useFormHelpers } from "hooks";
import React from "react";
import { useForm } from "react-hook-form";
import styled, { useTheme } from "styled-components";
import { ValidationMessages } from "utils/validationMessages";
import { USERNAME_MAX_LENGTH, USERNAME_MIN_LENGTH, USERNAME_REGEX } from "utils/validators";
import { REFERRALS_MODAL_KEY } from "./utils";

const FormContainer = styled.form`
    width: 100%;
`;

export interface ReferralsModalInputs {
    name?: string | null;
    email: string;
}

const validationSchema: SchemaOf<ReferralsModalInputs> = object({
    name: string()
        .nullable()
        .transform((value: string) => {
            const trimmedValue = value?.trim();

            return !trimmedValue ? null : trimmedValue;
        })
        .min(USERNAME_MIN_LENGTH, ValidationMessages.INVALID_USERNAME_LENGTH)
        .max(USERNAME_MAX_LENGTH, ValidationMessages.INVALID_USERNAME_LENGTH)
        .matches(USERNAME_REGEX, ValidationMessages.INVALID_USERNAME_SYMBOLS),
    email: string()
        .trim()
        .email(ValidationMessages.INVALIDEmail)
        .required(ValidationMessages.REQUIRED),
});

type AddNewTakerFormProps = {
    onDone: () => void;
    referralEmail?: string;
};

export const AddNewTakerForm: React.FC<AddNewTakerFormProps> = ({ onDone, referralEmail }) => {
    const { colors } = useTheme();
    const { error, setError } = useFormHelpers();
    const {
        formState: { isSubmitting, isDirty, errors },
        handleSubmit,
        register,
    } = useForm<ReferralsModalInputs>({
        defaultValues: {
            email: referralEmail,
        },
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
    });

    useFormCloseConfirmer(REFERRALS_MODAL_KEY, isDirty);

    const onSubmit = async ({ name, email }: ReferralsModalInputs) => {
        try {
            await post("sendTakerInviteLink", {
                name: name || "user",
                email,
            });
            onDone();
        } catch (e) {
            setError(String(e));
        }
    };

    return (
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
            <VStack paddingX={12} paddingTop={12} spacing={12}>
                <H2>Invite new taker</H2>
                <P color={colors.ui52}>The invitation link will be available for 30 days</P>
                <SimpleInput
                    label="Company name"
                    placeholder={getEnv().PROJECT_NAME}
                    {...register("name")}
                    error={errors.name?.message}
                />
                <SimpleInput
                    label="Email"
                    placeholder="name@company.com"
                    {...register("email")}
                    error={errors.email?.message}
                />
                {error && (
                    <FormAttention>
                        <IconError />
                        <P>{error}</P>
                    </FormAttention>
                )}
            </VStack>
            <VStack paddingX={12} paddingBottom={12} paddingTop={16}>
                <PrimaryButton fullWidth size="large" type="submit" loading={isSubmitting}>
                    Send Invitation
                </PrimaryButton>
            </VStack>
        </FormContainer>
    );
};
