import { AnySchema, boolean, number, object, ref, string } from "yup";
import { ValidationMessages } from "../../../utils/validationMessages";
import { COUNTERPARTY_ID_FORMAT_REGEX, LIMIT_FORMAT_REGEX, PERCENT_DECIMAL_PRECISION_REGEX } from "../utils";
import { Inputs } from "./types";

export const schema = object<Record<keyof Inputs, AnySchema>>().shape(
    {
        counterpartyId: string()
            .matches(COUNTERPARTY_ID_FORMAT_REGEX, ValidationMessages.INVALID_CP_ID)
            .required(ValidationMessages.REQUIRED),
        isTradingActive: boolean(),
        currency: string().required(ValidationMessages.REQUIRED),
        grossLimit: string()
            .required(ValidationMessages.REQUIRED)
            .matches(LIMIT_FORMAT_REGEX, ValidationMessages.INVALID_LIMIT),
        isMarginActive: boolean(),
        maintenanceMargin: number()
            .transform((value: any, originalValue: any) => (originalValue === "" ? undefined : value))
            .when("isMarginActive", (isMarginActive: boolean, schema: any) => {
                return isMarginActive
                    ? schema
                          .required(ValidationMessages.REQUIRED)
                          .typeError(ValidationMessages.INVALID_MARGIN)
                          .min(0, ValidationMessages.MIN_MAINTENANCE_MARGIN_ERROR)
                          .when("restrictedTrading", (restrictedTrading: number | undefined, schema: any) => {
                              return restrictedTrading !== undefined && !Number.isNaN(restrictedTrading)
                                  ? schema.max(
                                        ref("restrictedTrading"),
                                        ValidationMessages.MAX_MAINTENANCE_MARGIN_ERROR,
                                    )
                                  : schema.max(100, ValidationMessages.MAX_MAINTENANCE_MARGIN_ERROR);
                          })
                          .test(
                              "maxDigitsAfterDecimal",
                              ValidationMessages.INVALID_MARGIN,
                              (number: number | undefined) => PERCENT_DECIMAL_PRECISION_REGEX.test(String(number)),
                          )
                    : schema.optional();
            }),
        restrictedTrading: number()
            .transform((value: any, originalValue: any) => (originalValue === "" ? undefined : value))
            .when("isMarginActive", (isMarginActive: boolean, schema: any) => {
                return isMarginActive
                    ? schema
                          .required(ValidationMessages.REQUIRED)
                          .typeError(ValidationMessages.INVALID_MARGIN)
                          .when("initialMargin", (initialMargin: number | undefined, schema: any) => {
                              return initialMargin !== undefined && !Number.isNaN(initialMargin)
                                  ? schema.max(ref("initialMargin"), ValidationMessages.MAX_RESTRICTED_TRADING_ERROR)
                                  : schema.max(100, ValidationMessages.MAX_RESTRICTED_TRADING_ERROR);
                          })
                          .test(
                              "maxDigitsAfterDecimal",
                              ValidationMessages.INVALID_MARGIN,
                              (number: number | undefined) => PERCENT_DECIMAL_PRECISION_REGEX.test(String(number)),
                          )
                    : schema.optional();
            }),
        initialMargin: number()
            .transform((value, originalValue) => (originalValue === "" ? undefined : value))
            .when("isMarginActive", (isMarginActive: boolean, schema: any) => {
                return isMarginActive
                    ? schema
                          .required(ValidationMessages.REQUIRED)
                          .typeError(ValidationMessages.INVALID_MARGIN)
                          .min(0, ValidationMessages.MIN_INITIAL_MARGIN_ERROR)
                          .max(100, ValidationMessages.MAX_INITIAL_MARGIN_ERROR)
                          .test(
                              "maxDigitsAfterDecimal",
                              ValidationMessages.INVALID_MARGIN,
                              (number: number | undefined) => PERCENT_DECIMAL_PRECISION_REGEX.test(String(number)),
                          )
                    : schema.optional();
            }),
    },
    [
        ["maintenanceMargin", "restrictedTrading"],
        ["restrictedTrading", "initialMargin"],
    ],
);
