import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
    gap: 6px;
    padding-bottom: 12px;

    button {
        background-color: transparent;
    }
`;

type HeaderProps = {
    className?: string;
};

export const Header: React.FC<HeaderProps> = (props) => {
    const { children, className } = props;

    return <Container className={className}>{children}</Container>;
};
