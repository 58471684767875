import { setConfirmModalContent } from "feature/app";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

type Callback = () => void;

export const getConfirmModalAction = (cb: Callback) =>
    setConfirmModalContent({
        title: "Discard Changes?",
        onConfirm: cb,
        description: "Are you sure you want to close without saving changes? Any unsaved data will be lost.",
        confirmButtonTitle: "Yes, discard",
    });

export const useModalCloseWithConfirm = (shouldConfirmClose: boolean, cb: Callback) => {
    const dispatch = useDispatch();

    const closeModalWithConfirm = useCallback(() => {
        if (shouldConfirmClose) {
            dispatch(getConfirmModalAction(cb));
        } else {
            cb();
        }
    }, [shouldConfirmClose, cb]);

    return {
        closeModalWithConfirm,
    };
};
