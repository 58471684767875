import styled, { css } from "styled-components";

const ANIMATION_DURATION = "0.1s";
// SWITCH_WIDTH should be greater than CIRCLE_SIZE+2*CIRCLE_MARGIN
const SWITCH_WIDTH = 34;
const CIRCLE_SIZE = 18;
const CIRCLE_MARGIN = 1;

export const SwitchCircle = styled.span`
    display: block;
    width: ${CIRCLE_SIZE}px;
    margin: ${CIRCLE_MARGIN}px;
    background: ${(p) => p.theme.colors.uiWhite100};
    position: absolute;
    top: 0;
    bottom: 0;
    right: ${SWITCH_WIDTH - CIRCLE_SIZE - 2 * CIRCLE_MARGIN}px;
    border-radius: ${CIRCLE_SIZE / 2}px;
    transition: all ${ANIMATION_DURATION} ease-in 0s;
`;

export const SwitchBackground = styled.span`
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin ${ANIMATION_DURATION} ease-in 0s;

    &:before,
    &:after {
        content: "";
        display: block;
        float: left;
        width: 50%;
        height: ${CIRCLE_SIZE + 2 * CIRCLE_MARGIN}px;
        padding: 0;
        box-sizing: border-box;
    }
    &:before {
        background-color: ${(p) => p.theme.colors.brand100};
    }
    &:after {
        background-color: ${(p) => p.theme.colors.ui8};
    }
`;

export const SwitchLabel = styled.label<{ disabled?: boolean }>`
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: ${(CIRCLE_SIZE + 2 * CIRCLE_MARGIN) / 2}px;
    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px ${(p) => p.theme.colors.brand12}, inset 0 0 0 1px ${(p) => p.theme.colors.brand100};
    }
    & > span:focus {
        outline: none;
    }

    ${(p) =>
        p.disabled &&
        css`
            cursor: not-allowed;

            & > ${SwitchBackground}:before {
                background-color: ${(p) => p.theme.colors.brand32};
            }
        `}
`;

export const HiddenInput = styled.input`
    display: none;

    &:checked + ${SwitchLabel} {
        ${SwitchBackground} {
            margin-left: 0;
        }
        ${SwitchCircle} {
            right: 0;
        }
    }
`;

export const Switch = styled.div`
    position: relative;
    width: ${SWITCH_WIDTH}px;
    min-width: ${SWITCH_WIDTH}px;
    display: inline-block;
`;
