import { FC, useEffect, useRef } from "react";
import { HStack } from "../../layout";
import { Search } from "../../search";
import { DropdownSize } from "../Dropdown/Dropdown.types";

type SearchProps = {
    size: DropdownSize;
    value: string;
    onChange: (value: string) => void;
};

export const DropdownSearch: FC<SearchProps> = ({ value, onChange, size }) => {
    const searchRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        searchRef.current?.focus();
    }, []);

    return (
        <HStack paddingX={4} paddingTop={4}>
            <Search ref={searchRef} size={size} query={value} onChange={onChange} />
        </HStack>
    );
};
