import { PXSmallBold } from "@fm-frontend/uikit";
import { useMemo } from "react";
import styled from "styled-components";
import { LpInfo } from "../types";
import { CircleStatusWrapper } from "./CircleStatusWrapper";
import { circleContainerCss } from "./styled";
import { useCpStatusHelpers } from "./useCpStatusHelpers";

const HiddenLPsCountContainer = styled.div<{ $size: number }>`
    ${circleContainerCss}

    background-color: ${(p) => p.theme.colors.uiWhite100};
    color: ${(p) => p.theme.colors.ui96};
    z-index: 1;
`;

export const HiddenLPsCount = ({ size, hiddenLPs }: { size: number; hiddenLPs: LpInfo[] }) => {
    const { getWorstStatusesColor } = useCpStatusHelpers();
    const hiddenLPsStatusColor = useMemo(
        () => getWorstStatusesColor(hiddenLPs.map(({ cpStatus }) => cpStatus)),
        [hiddenLPs, getWorstStatusesColor],
    );

    return (
        <CircleStatusWrapper size={size} statusColor={hiddenLPsStatusColor}>
            <HiddenLPsCountContainer $size={size}>
                <PXSmallBold>+{hiddenLPs.length}</PXSmallBold>
            </HiddenLPsCountContainer>
        </CircleStatusWrapper>
    );
};
