import { css, DefaultTheme } from "styled-components";

// TODO remove all (do not need to have selectors layer at all, only palette colors directly)
type ThemeCtx = { theme: DefaultTheme };
export const selectors = {
    colorSmallButtonBG: ({ theme }: ThemeCtx) => theme.colors.smallButtonBG,
    colorRed: ({ theme }: ThemeCtx) => theme.colors.red,
    brandColor: ({ theme }: ThemeCtx) => theme.colors.brand,
    mainBG: ({ theme }: ThemeCtx) => theme.colors.mainBG,
    activeBG: ({ theme }: ThemeCtx) => theme.colors.activeBG,
    textFG: ({ theme }: ThemeCtx) => theme.colors.textFG,
    textSubdued: ({ theme }: ThemeCtx) => theme.colors.textSubdued,
    textSubdued2: ({ theme }: ThemeCtx) => theme.colors.textSubdued2,
    textActiveFG: ({ theme }: ThemeCtx) => theme.colors.textActiveFG,
    colorSelectBg: ({ theme }: ThemeCtx) => theme.colors.selectBG,
    colorSelectFg: ({ theme }: ThemeCtx) => theme.colors.selectFG,
    mixinP: ({ theme }: ThemeCtx) => theme.mixins.p,
    mixinPBold: ({ theme }: ThemeCtx) => theme.mixins.pBold,
    mixinPSmall: ({ theme }: ThemeCtx) => theme.mixins.pSmall,
    mixinPSmallBold: ({ theme }: ThemeCtx) => theme.mixins.pSmallBold,
    mixinHeader1: ({ theme }: ThemeCtx) => theme.mixins.header1,
    mixinHeader2: ({ theme }: ThemeCtx) => theme.mixins.header2,
    mixinHeader3: ({ theme }: ThemeCtx) => theme.mixins.header3,
    mixinBody1: ({ theme }: ThemeCtx) => theme.mixins.body1,
    mixinBody2: ({ theme }: ThemeCtx) => theme.mixins.body2,
    mixinInterface1: ({ theme }: ThemeCtx) => theme.mixins.interface1,
    mixinInterface2: ({ theme }: ThemeCtx) => theme.mixins.interface2,
    mixinBubble: ({ theme }: ThemeCtx) => theme.mixins.bubble,
    mixinHover: ({ theme, disabled }: ThemeCtx & { disabled?: boolean }) =>
        disabled ? null : theme.mixins.hover,
    fontRegular: () => css`
        font-family: "InterVariable", sans-serif;
        font-weight: 400;
    `,
    fontMedium: () => css`
        font-family: "InterVariable", sans-serif;
        font-weight: 600;
    `,
};
