import { FC } from "react";
import { useTheme } from "styled-components";
import { ButtonVariant } from "../types";
import { Content, Invisible, Loader } from "./styled";

const useLoaderColor = (variant: ButtonVariant) => {
    const { colors } = useTheme();

    switch (variant) {
        case "basic":
            return colors.ui32;
        case "primary":
            return colors.uiWhite100;
    }
};

export const LoadingContent: FC<{ variant: ButtonVariant }> = ({ variant, children }) => {
    const loaderColor = useLoaderColor(variant);

    return (
        <Content>
            <Invisible>{children}</Invisible>
            <Loader color={loaderColor} />
        </Content>
    );
};
