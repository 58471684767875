import { getCurrentUser } from "aws-amplify/auth";

export const getIsUserAuthenticated = async (): Promise<boolean> => {
    try {
        await getCurrentUser();
        return true;
    } catch (error) {
        return false;
    }
};
