import {
    Form,
    FormActions,
    FormBody,
    Header,
    HeaderTitle,
    Modal,
    PlainButton,
    PrimaryButton,
    ShadowCard,
    VStack,
} from "@fm-frontend/uikit";
import { yupResolver } from "@hookform/resolvers/yup";
import { post } from "api";
import { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { isFiat } from "utils";
import { ValidationMessages } from "utils/validationMessages";
import { AnySchema, object, string } from "yup";
import { Error } from "../../styled";
import { AutoResizableTextArea } from "../AutoResizableTextArea";
import { NotCommittedDepositInfo } from "../infoCards";
import { CreatedTransaction } from "./CreateDepositModal";

interface CommitTransactionModalProps {
    closeModal: () => void;
    transactionSettings: CreatedTransaction;
}

const Label = styled.label`
    ${(p) => p.theme.mixins.pSmall}
`;

type Inputs = {
    txId: string;
};

const validationSchema = object<Record<keyof Inputs, AnySchema>>().shape({
    txId: string().trim().required(ValidationMessages.REQUIRED),
});

export const CommitDepositModal = ({ closeModal, transactionSettings }: CommitTransactionModalProps) => {
    const [apiError, setApiError] = useState<string | null>(null);

    const {
        formState: { isSubmitting, errors },
        register,
        handleSubmit,
    } = useForm<Inputs>({
        defaultValues: {},
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async ({ txId }: Inputs) => {
        try {
            setApiError(null);
            await post("sendOutgoingSettlementTransaction", {
                transactionId: transactionSettings.id,
                txId,
            });
            closeModal();
        } catch (err) {
            setApiError(err as string);
        }
    };

    let txIdLabel = "Enter TX ID to commit the transaction";
    let txIdPlaceholder = "sa000...mpl3e";
    if (isFiat(transactionSettings.asset)) {
        txIdLabel = "Enter bank reference to commit the transaction";
        txIdPlaceholder = "Settlement as per MPSA No.";
    }

    return (
        <Modal isOpen onClose={closeModal}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ShadowCard>
                    <Header>
                        <HeaderTitle title="Deposit transaction" />
                    </Header>
                    <FormBody spacing={8}>
                        <NotCommittedDepositInfo transaction={transactionSettings} />
                        <VStack spacing={4} width="100%">
                            <Label htmlFor="tx-id">{txIdLabel}</Label>
                            <AutoResizableTextArea
                                id="tx-id"
                                rows={1}
                                placeholder={txIdPlaceholder}
                                disabled={isSubmitting}
                                autoFocus
                                {...register("txId")}
                                error={errors.txId?.message}
                            />
                            {apiError && <Error>{apiError}</Error>}
                        </VStack>
                    </FormBody>
                    <FormActions variant="plain">
                        <PrimaryButton type="submit" fullWidth size="large" loading={isSubmitting}>
                            Send deposit
                        </PrimaryButton>
                        <PlainButton type="button" fullWidth size="large" onClick={closeModal}>
                            Skip for now
                        </PlainButton>
                    </FormActions>
                </ShadowCard>
            </Form>
        </Modal>
    );
};
