import { H3, Icons, PSmall, PSmallBold, VStack } from "@fm-frontend/uikit";
import { when } from "@fm-frontend/utils";
import { useSharedCryptoAddresses } from "feature/addresses/hooks/useSharedCryptoAddresses";
import { AccountStatusType } from "feature/addresses/types";
import { Settlement } from "feature/settlements/types";
import QRCode from "qrcode.react";
import styled, { css, useTheme } from "styled-components";
import { HintsLayout } from "./HintsLayout";
import { GroupContent, HintsHeader } from "./styled";

const Content = styled(GroupContent)<{ isUnderVerification: boolean }>`
    padding: 16px 8px 0 8px;
    gap: 16px;

    ${PSmallBold} {
        display: inline;
    }

    ${(p) =>
        p.isUnderVerification
            ? css`
                   {
                      opacity: 0.6;
                  }
              `
            : null}
`;

const ItemContainer = styled(VStack)`
    padding: 0;
    gap: 2px;

    ${PSmall} {
        color: ${(p) => p.theme.colors.ui72};
        word-break: break-word;
        display: flex;
        align-items: center;
        gap: 3px;
    }
    ${PSmallBold} {
        color: ${(p) => p.theme.colors.ui96};
        word-break: break-word;
    }
`;

const QRCodeContainer = styled.div`
    display: inline-block;
    width: fit-content;
    margin: 0 auto;
    background-color: ${(p) => p.theme.colors.uiWhite100};
    padding: 8px;
`;

const Item = ({ title, value }: { title: string | React.ReactNode; value: string }) => (
    <ItemContainer>
        <PSmall>{title}</PSmall>
        <PSmallBold>{value}</PSmallBold>
    </ItemContainer>
);

export const CryptoAddressHints = ({ settlement }: { settlement: Settlement }) => {
    const theme = useTheme();
    const { data: cryptoAddresses, isLoading } = useSharedCryptoAddresses();

    if (isLoading) {
        return null;
    }

    const { cpId, cpName, network, asset } = settlement;

    const cryptoAddress = cryptoAddresses.find((address) => {
        const { currency, network: addressNetwork, rule } = address;

        if (!currency?.name || (addressNetwork && addressNetwork.name !== network)) {
            return false;
        }

        return currency.name === asset && rule?.cp.id === cpId;
    });

    if (!cryptoAddress) {
        return null;
    }

    const isUnderVerification = cryptoAddress.status === AccountStatusType.NeedToConfirm;

    return (
        <HintsLayout>
            <HintsHeader>
                <H3>Payment information</H3>
            </HintsHeader>
            <Content isUnderVerification={isUnderVerification}>
                <span>
                    <PSmallBold>{cpName}</PSmallBold> informed which wallet he wants to receive payment for the{" "}
                    <PSmallBold>{asset}</PSmallBold>.
                </span>
                {when(
                    isUnderVerification,
                    "Be careful, this address is still unconfirmed. You can manage information for settlements in the new Addresses section.",
                )}
                <QRCodeContainer>
                    <QRCode value={cryptoAddress.wallet} style={{ alignSelf: "center" }} />
                </QRCodeContainer>
                <Item
                    title={
                        isUnderVerification ? (
                            "Address (not confirmed)"
                        ) : (
                            <>
                                Address <Icons.CheckShape color={theme.colors.brand} />
                            </>
                        )
                    }
                    value={cryptoAddress.wallet}
                />
                {when(
                    Boolean(cryptoAddress.memoOrTag),
                    <>
                        <div>Memo or tag</div>
                        <div>{cryptoAddress.memoOrTag}</div>
                    </>,
                )}
                {when(
                    Boolean(cryptoAddress.privateNote),
                    <>
                        <div>Note</div>
                        <div>{cryptoAddress.privateNote}</div>
                    </>,
                )}
            </Content>
        </HintsLayout>
    );
};
