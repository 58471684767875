import { useDefaultMarkups } from "feature/assetsControl/components/InstrumentsTabContent/MakerMasterInstrumentsTabContent/IntsrumentsTable/hooks/useDefaultMarkups";
import { PERCENT_SHIFT } from "feature/counterparties/utils";
import { useInstrumentMarkups } from "hooks/useInstrumentMarkups";
import { useCallback } from "react";

export const useMarkupInfo = () => {
    const { defaultMarkups, isLoading: isLoadingDefaultMarkups } = useDefaultMarkups();
    const { instrumentMarkups, isLoading: isLoadingInstrumentMarkups } = useInstrumentMarkups();

    return {
        getMarkupInfo: useCallback(
            (instrument: string, cpId: number) => {
                const defaultMarkup = defaultMarkups[cpId];
                const markups = instrumentMarkups[instrument]?.[cpId];

                return {
                    defaultMarkup:
                        defaultMarkup !== undefined ? defaultMarkup / PERCENT_SHIFT : undefined,
                    askMarkup:
                        markups?.askMarkup !== undefined
                            ? markups.askMarkup / PERCENT_SHIFT
                            : undefined,
                    bidMarkup:
                        markups?.bidMarkup !== undefined
                            ? markups.bidMarkup / PERCENT_SHIFT
                            : undefined,
                };
            },
            [instrumentMarkups, defaultMarkups],
        ),
        isLoading: isLoadingInstrumentMarkups || isLoadingDefaultMarkups,
    };
};
