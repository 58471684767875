import { RfqHistoryItem } from "feature/rfq/apiHooks/useLastRfqTrades";
import { ClientType } from "types";
import { ExportColumnType } from "../types";
import { deltaCellFormatConfig, priceCellFormatConfig, sizeCellFormatConfig } from "./cells";
import { ORDER_TYPE_TITLES } from "./cells/OrderTypeCell";

type Data = RfqHistoryItem & { counterpartyName: string };

const generalColumns: ExportColumnType<Data>[] = [
    {
        Header: "Order ID",
        accessor: (preparedDeal) => preparedDeal.id,
    },
    {
        Header: "Order type",
        accessor: () => ORDER_TYPE_TITLES.rfq,
    },
    {
        Header: "Client ID",
        accessor: (preparedDeal) => preparedDeal.counterpartyId,
    },
    {
        Header: "Client name",
        accessor: (preparedDeal) => preparedDeal.counterpartyName,
    },
    {
        Header: "Instrument",
        accessor: (preparedDeal) => preparedDeal.instrumentName,
    },
    {
        Header: "Side",
        accessor: (preparedDeal) => (preparedDeal.side === "BUY" ? "Buy" : "Sell"),
    },
    {
        Header: "Price",
        accessor: (preparedDeal) => preparedDeal.price,
        format: priceCellFormatConfig,
    },
    {
        Header: "Size",
        accessor: (preparedDeal) => preparedDeal.size,
        format: sizeCellFormatConfig,
    },
    {
        Header: "Status",
        accessor: (preparedDeal) => preparedDeal.status,
    },
];

const tradeIdColumn: ExportColumnType<Data> = {
    Header: "Trade ID",
    accessor: (preparedDeal) => preparedDeal.tradeId,
};
const deltaColumn: ExportColumnType<Data> = {
    Header: "Delta",
    accessor: (preparedDeal) => preparedDeal.clientDelta,
    format: deltaCellFormatConfig,
};
const dateColumn: ExportColumnType<Data> = {
    Header: "Date",
    accessor: (preparedDeal) => preparedDeal.date,
    format: { type: "timems", withTime: true },
};

const pbPartColumns = [dateColumn, deltaColumn, tradeIdColumn];
const takerPartColumns = [dateColumn, tradeIdColumn];

export const makeRfqExportColumns = (config: { userType: ClientType | undefined }) => {
    let columns: ExportColumnType<Data>[] = [...generalColumns];

    if (config.userType === "primeBroker") {
        columns = [...columns, ...pbPartColumns];
    }
    if (config.userType === "taker" || config.userType === "subaccountTaker") {
        columns = [...columns, ...takerPartColumns];
    }

    return columns;
};
