import {
    fetchUserAttributes,
    getCurrentUser,
    type FetchUserAttributesOutput,
    type GetCurrentUserOutput,
} from "aws-amplify/auth";

type ErrorResponse = {
    code: string;
    message: string;
};
type CurrentAuthenticatedUserResponse = {
    user: GetCurrentUserOutput | null;
    attributes: FetchUserAttributesOutput | null;
    error: ErrorResponse | null;
};

const formatErrorResponse = (message: string, code: string): CurrentAuthenticatedUserResponse => ({
    user: null,
    attributes: null,
    error: { message, code },
});

export const getAuthenticatedUser = async () => {
    try {
        const currentUser: GetCurrentUserOutput = await getCurrentUser();
        const userAttributes: FetchUserAttributesOutput = await fetchUserAttributes();

        return {
            user: currentUser,
            attributes: userAttributes,
            error: null,
        };
    } catch (error: unknown) {
        console.error(error);
        const errorMessage = error instanceof Error ? error.message : "Unknown error";
        const errorCode = error instanceof Error ? error.name : "unknown_error";

        return formatErrorResponse(errorMessage, errorCode);
    }
};
