import { TopMenu } from "components/TopMenu";
import { Content } from "feature/assetsControl";
import React from "react";
import { Page } from "style";

export const AssetsControl: React.FC = () => {
    return (
        <Page noBottomSpace>
            <TopMenu>Assets & Instruments</TopMenu>
            <Content />
        </Page>
    );
};
