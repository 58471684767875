import { useEffect, useRef } from "react";
import styled from "styled-components";
import { IconButton } from "../../../buttons";
import { Icons } from "../../../icons";

const StickyContainer = styled.div`
    position: sticky;
    top: -0.5px;
    padding: 8px;
    background-color: ${(p) => p.theme.colors.uiWhite100};
    z-index: 1;
`;

const Container = styled.div`
    position: relative;
    height: 32px;
    min-height: 32px;
`;

const Input = styled.input`
    width: 100%;
    padding: 6px 32px 6px 30px;
    border: none;

    height: inherit;
    min-height: inherit;
    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.ui4};
    color: ${(p) => p.theme.colors.ui100};

    :focus-visible {
        background-color: ${(p) => p.theme.colors.uiWhite100};
        box-shadow: 0 0 0 3px ${(p) => p.theme.colors.brand20}, 0 0 0 1px ${(p) => p.theme.colors.brand100} inset;
    }
`;

const SearchIcon = styled.span`
    display: flex;
    align-items: center;
    left: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

const CancelIcon = styled(IconButton)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
`;

type SearchFnProps = {
    query: string;
    onSearch: (query: string) => void;
    placeholder?: string;
};
export const SearchFn = ({ onSearch, query, placeholder = "Search" }: SearchFnProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setTimeout(() => inputRef.current?.focus(), 0);
    }, []);

    return (
        <StickyContainer>
            <Container>
                <SearchIcon>
                    <Icons.Search />
                </SearchIcon>
                <Input
                    ref={inputRef}
                    onChange={(e) => onSearch(e.target.value)}
                    value={query}
                    placeholder={placeholder}
                />
                {query.length > 0 && (
                    <CancelIcon
                        onClick={() => onSearch("")}
                        tabIndex={-1}
                        type="button"
                        variant="plain"
                        Icon={Icons.Clear}
                    />
                )}
            </Container>
        </StickyContainer>
    );
};
