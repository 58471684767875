import { Flex, IconButton, Icons, Tooltip } from "@fm-frontend/uikit";
import styled from "styled-components";
import { useAssetsTabContext } from "../../../assetsTabContext";

const CpActionCellContainer = styled(Flex)`
    height: 100%;
    width: 40px;
    align-items: center;
    justify-content: center;
`;

export const CpActionCell = ({
    asset,
    cpId,
    cpName,
}: {
    asset: string;
    cpId: number;
    cpName: string;
}) => {
    const { handleCpRowEditClick } = useAssetsTabContext();

    return (
        <CpActionCellContainer>
            <Tooltip content="Configure">
                <IconButton
                    variant="plain"
                    Icon={Icons.Pencil}
                    onClick={() => handleCpRowEditClick({ asset, cpId, cpName })}
                />
            </Tooltip>
        </CpActionCellContainer>
    );
};
