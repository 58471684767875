import styled from "styled-components";
import { onHover } from "../const";

export const Badge = styled.div<{
    isNegative?: boolean;
    value?: boolean;
    nonInteractive?: true;
    noColor?: true;
}>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
    padding: 3px 8px;
    height: 24px;
    transition: background-color ${(p) => p.theme.transitions.default};
    ${onHover} {
        background-color: ${({ theme, nonInteractive }) =>
            nonInteractive ? "initial" : theme.colors.mainBG};
    }

    background: ${({ theme, value }) => (value ? theme.colors.selectBG : theme.colors.activeBG)};
    outline: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
    border-radius: 12px;

    ${({ theme: { mixins } }) => mixins.interface2}
    font-weight: 600;

    color: ${({ theme, isNegative, noColor }) =>
        noColor ? theme.colors.textFG : isNegative ? theme.colors.red : theme.colors.blue};
    header {
        color: ${({ theme }) => theme.colors.textSubdued};
        margin-right: 6px;
    }
    svg {
        margin-right: -4px;
    }

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    white-space: nowrap;

    user-select: none;
`;

export const SelectableBadge = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
    padding: 3px 8px;
    height: 24px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    transition: background-color ${(p) => p.theme.transitions.default};
    ${onHover} {
        background-color: rgba(0, 0, 0, 0.07);
    }

    ${({ theme: { mixins } }) => mixins.interface2}
    font-weight: 600;
    header {
        color: ${({ theme }) => theme.colors.textSubdued};
        margin-right: 6px;
    }

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    white-space: nowrap;
`;
