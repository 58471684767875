import { ValidationMessages } from "utils/validationMessages";
import { AnySchema, object, string } from "yup";

export type SignInInputs = {
    email: string;
    password: string;
};

export const signInSchema = object<Record<keyof SignInInputs, AnySchema>>().shape({
    email: string()
        .trim()
        .email(ValidationMessages.INVALIDEmail)
        .required(ValidationMessages.REQUIRED),
    password: string()
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim()),
});
