import { VFC } from "react";
import { EMPTY_ARRAY } from "../../const";
import { useListTransition } from "../../hooks/useTransition";
import { NotificationsProps } from "./Notifications.types";
import * as Styled from "./styled";

export const Notifications: VFC<NotificationsProps> = ({ notifications = EMPTY_ARRAY, mouseLeaveDelay }) => {
    const notificationsWithTransitions = useListTransition(notifications, {
        exitDelay: 500,
        mouseLeaveDelay,
    });

    return (
        <Styled.Container>
            {notificationsWithTransitions.map(({ icon, content, id, state, onMouseEnter, onMouseLeave }) => (
                <Styled.Notification key={id} state={state} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    {icon}
                    <Styled.Content>{content}</Styled.Content>
                </Styled.Notification>
            ))}
        </Styled.Container>
    );
};
