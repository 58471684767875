import { HStack, Skeleton, VStack } from "@fm-frontend/uikit";
import styled from "styled-components";

export const Amount = styled.div`
    color: ${(p) => p.theme.colors.ui72};
    font-size: 10px;
    line-height: 16px;

    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;
`;

export const AssetAmount = styled.div`
    white-space: nowrap;
`;

export const UsdAmount = styled.div`
    white-space: nowrap;
`;

export const GroupContent = styled(VStack)`
    overflow-y: auto;
    flex: 1;
    gap: 2px;
    padding: 10px 0;
`;

export const Loading = () => (
    <VStack spacing={4} width="100%" paddingX={8}>
        <Skeleton />
        <Skeleton />
    </VStack>
);

const EmptyContainer = styled(HStack)`
    color: ${(p) => p.theme.colors.ui52};
`;
export const Empty = ({ message }: { message: string }) => (
    <EmptyContainer width="100%" justifyContent="center" paddingTop={27}>
        {message}
    </EmptyContainer>
);
