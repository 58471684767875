import { H2, PlainButton, PrimaryButton, VStack } from "@fm-frontend/uikit";
import { FormError } from "feature/form/style";
import { useFormHelpers } from "hooks";
import React from "react";
import { acknowledgeSharedAccountRevision } from "../../api";
import {
    accountSectionItem,
    assetSectionItem,
    bankLegalAddressSectionItem,
    bankNameSectionItem,
    companyLegalAddressSectionItem,
    companyNameSectionItem,
    FormSectionDivider,
    otherDetailsSectionItem,
    publicDetailsSectionItem,
    SectionItem,
    TSectionItem,
} from "../../components";
import { BankAddress } from "../../types";

type ConfirmBankAddressModalProps = {
    revisionHash: string;
    address: BankAddress;
    onCancel: () => void;
    onConfirmSuccess: () => void;
};

export const ConfirmBankAddressModal: React.FC<ConfirmBankAddressModalProps> = ({
    revisionHash,
    onConfirmSuccess,
    address,
    onCancel,
}) => {
    const { accountId, wallet, currency, bankPrimary, company, otherDetails, publicDetails } = address;
    const { isLoading, startLoading, stopLoading, error, setError } = useFormHelpers();

    const sectionOne: TSectionItem[] = [
        assetSectionItem(currency?.name),
        accountSectionItem(wallet),
        bankNameSectionItem(bankPrimary?.name),
        bankLegalAddressSectionItem(bankPrimary?.address),
    ];
    const sectionTwo: TSectionItem[] = [
        companyNameSectionItem(company?.name),
        companyLegalAddressSectionItem(company?.address),
    ];
    const sectionThree: TSectionItem[] = [
        otherDetailsSectionItem(otherDetails),
        publicDetailsSectionItem(publicDetails),
    ];

    const handleConfirmAddress = async () => {
        if (accountId === undefined) {
            return;
        }
        startLoading();
        const response = await acknowledgeSharedAccountRevision(accountId, revisionHash);
        setError(response.error);
        stopLoading();
        onConfirmSuccess();
    };

    return (
        <VStack minWidth="360px" asCard>
            <VStack padding={12}>
                <H2>Confirm new account</H2>
            </VStack>
            <VStack paddingBottom={12}>
                {sectionOne.map((sectionItem) => (
                    <SectionItem sectionItem={sectionItem} />
                ))}
                <FormSectionDivider />
                {sectionTwo.map((sectionItem) => (
                    <SectionItem sectionItem={sectionItem} />
                ))}
                <FormSectionDivider />
                {sectionThree.map((sectionItem) => (
                    <SectionItem sectionItem={sectionItem} />
                ))}
                {error && (
                    <VStack paddingY={6} paddingX={12}>
                        <FormError>{error}</FormError>
                    </VStack>
                )}
            </VStack>
            <VStack paddingX={12} paddingBottom={16} spacing={8}>
                <PrimaryButton fullWidth size="large" type="submit" onClick={handleConfirmAddress} loading={isLoading}>
                    Confirm account
                </PrimaryButton>
                <PlainButton fullWidth size="large" type="button" onClick={onCancel}>
                    Cancel
                </PlainButton>
            </VStack>
        </VStack>
    );
};
