import { Notifications } from "@fm-frontend/uikit";
import { useSelector } from "hooks";
import { CompleteNewPassword } from "./CompleteNewPassword";
import { ConfirmSignIn } from "./ConfirmSignIn";
import { SetUpMfa } from "./SetUpMfa";
import { SignIn } from "./SignIn";
import { SlimPage } from "./slimPage";
import { VerifyMfa } from "./VerifyMfa";

export const LoginFlow = () => {
    const authStatus = useSelector((state) => state.authentication.status);

    const getLoginComponent = () => {
        switch (authStatus) {
            case "unauthenticated":
            case "signIn":
                return <SignIn />;
            case "setUpMfa":
                return <SetUpMfa />;
            case "verifyMfa":
                return <VerifyMfa />;
            case "confirmSignIn":
                return <ConfirmSignIn />;
            case "completedNewPassword":
                return <CompleteNewPassword />;

            default:
                return <SignIn />;
        }
    };

    return (
        <SlimPage>
            {getLoginComponent()}
            <Notifications />
        </SlimPage>
    );
};
