import { VStack } from "@fm-frontend/uikit";
import { TopMenu } from "components/TopMenu";
import { AddressesContextProvider, CardContainer, Content, Header } from "feature/addresses";
import { addressesTextAdoptionDictionary } from "feature/addresses/addressesTextAdoptionDictionary";
import { TextAdaptorContext } from "hooks/useTextAdaptor";
import React from "react";
import { Page } from "style";

export const AddressesPage: React.VFC = () => {
    return (
        <Page noBottomSpace>
            <TextAdaptorContext.Provider value={addressesTextAdoptionDictionary}>
                <TopMenu>Addresses</TopMenu>
                <VStack margin={[0, 12, 0, 12]}>
                    <AddressesContextProvider>
                        <CardContainer asCard>
                            <Header />
                            <Content />
                        </CardContainer>
                    </AddressesContextProvider>
                </VStack>
            </TextAdaptorContext.Provider>
        </Page>
    );
};
