import { Center, P } from "@fm-frontend/uikit";
import { EmptySearch } from "@fm-frontend/uikit/src/components/common/EmptySearch";
import React from "react";

export const InstrumentIsNotWhitelisted: React.VFC = () => {
    return (
        <Center flex={1}>
            <EmptySearch description={<P textAlign="center">Instrument is not whitelisted</P>} />
        </Center>
    );
};
