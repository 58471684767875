import {
    Flex,
    FormTab,
    FormTabList,
    IconButton,
    Icons,
    PrimaryButton,
    Search,
    TabContext,
    Tooltip,
    VStack,
} from "@fm-frontend/uikit";
import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { useModalControl } from "@fm-frontend/utils";
import { useEffect, useMemo, useState, VFC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { post } from "api";
import { CounterpartiesSheet } from "components/CounterpartiesSheet";
import { CounterpartyTriggerEssence } from "components/CounterpartiesTriggerEssence";
import { CurrencyDropdownSheet } from "components/CurrencySheet";
import { CurrencyTriggerEssence } from "components/CurrencyTriggerEssence";
import { createNotification, setConfirmModalContent } from "feature/app";
import { useInitialValues } from "feature/form/initialValuesUtils";
import { deleteSettlementRequest } from "feature/settlements/api";
import { useFilterState } from "hooks/useFilterState";
import { use2WayBinding } from "hooks/use2WayBinding";
import { getTabNumericLabel } from "utils";
import { CreateSettlementModal } from "./CreateSettlementModal";
import { SettlementDetailsModal } from "./SettlementDetailsModal";
import { SettlementInfo } from "./SettlementDetailsModal/detailsModals/components/SettlementInfo";
import { SettlementsTable } from "./SettlementsTable";
import { Tabs } from "./const";
import { Settlement, SettlementStatus } from "./types";
import { useSettlements } from "./useSettlements";

const Container = styled(Flex)`
    overflow: auto;
    min-height: calc(100vh - 87px);

    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

const Header = styled.div`
    margin: 12px 12px 12px 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

const HeaderActions = styled.div`
    display: flex;
    gap: 8px;
    padding-top: 12px;
    padding-left: 4px;

    .search {
        width: 160px;
        input::placeholder {
            font-size: 12px;
        }
    }
`;

const isActionable = ({ status, type }: Partial<Settlement>) => (status === "request" && type === "in") || (status === "created") || (status === "received");
const isIncoming = ({ status, type }: Partial<Settlement>) => status !== "committed" && status !== "cancelled" && status !== "request" && type === "in";
const isOutgoing = ({ status, type }: Partial<Settlement>) => status !== "committed" && status !== "cancelled" && status !== "request" && type === "out";
const isRequest = ({ status }: Partial<Settlement>) => status === "request";

type FilterValue = {
    statusFilter: SettlementStatus[];
    assetFilter: Settlement["asset"][];
    cpFilter: number[];
};

const STATUS_OPTIONS: DropdownOption<SettlementStatus>[] = [
    {
        text: "Request",
        value: SettlementStatus.request,
    },
    {
        text: "Created",
        value: SettlementStatus.created,
    },
    {
        text: "Sent",
        value: SettlementStatus.sent,
    },
    {
        text: "Received",
        value: SettlementStatus.received,
    },
    {
        text: "Committed",
        value: SettlementStatus.committed,
    },
    {
        text: "Cancelled",
        value: SettlementStatus.cancelled,
    },
];

const PAGE_FILTER_KEY = "settlement";

export const SettlementsPageContent: VFC = () => {
    const dispatch = useDispatch();
    const { settlements, isLoading } = useSettlements();

    const [selectedSettlement, setSelectedSettlement] = useState<Settlement | null>(null);

    const [selectedFilterTab, setSelectedFilterTab] = useFilterState<Tabs>(
        PAGE_FILTER_KEY,
        "tab",
        Tabs.ALL,
        { availableValues: Object.values(Tabs) },
    );
    const [searchQuery, setSearchQuery] = useState("");

    const [statusFilter, setStatusFilter, deleteStatusFilter] = useFilterState<SettlementStatus[]>(
        PAGE_FILTER_KEY,
        "status",
        [],
        { isArray: true, availableValues: Object.values(SettlementStatus) },
    );

    const [assetFilter, setAssetFilter, deleteAssetFilter] = useFilterState<Settlement["asset"][]>(
        PAGE_FILTER_KEY,
        "asset",
        [],
        { isArray: true, availableValues: isLoading ? undefined : settlements.map(({ asset }) => asset) },
    );

    const [cpFilter, setCpFilter, deleteCpFilter] = useFilterState<number[]>(
        PAGE_FILTER_KEY,
        "cp",
        [],
        { isArray: true, parseValue: Number, availableValues: isLoading ? undefined : settlements.map(({ cpId }) => cpId) },
    );

    const removeSettlement = async (settlement: Settlement) => {
        const isRequestSettlement = settlement.status === "request";
        const isSentTransaction = settlement.status === "sent";
        const title = isRequestSettlement
            ? "Delete request?"
            : `${isSentTransaction ? "Cancel" : "Delete"} transaction?`;
        const contentMessage = `This action cannot be undone. Are you sure you want to ${
            isSentTransaction ? "cancel" : "delete"
        } this ${isRequestSettlement ? "request" : "transaction"}?`;
        const successMessage = isRequestSettlement
            ? "Request was deleted"
            : `Transaction was ${isSentTransaction ? "cancelled" : "deleted"}`;

        dispatch(
            setConfirmModalContent({
                title,
                onConfirm: async () => {
                    try {
                        if (isRequestSettlement) {
                            await deleteSettlementRequest({
                                requestId: settlement.id,
                            });
                        } else {
                            await post("delOutgoingSettlementTransaction", {
                                transactionId: settlement.id,
                            });
                        }

                        dispatch(
                            createNotification({
                                type: "success",
                                content: successMessage,
                            }),
                        );
                    } catch (e) {
                        dispatch(
                            createNotification({
                                type: "error",
                                content: String(e),
                            }),
                        );
                    }
                },
                description: (
                    <VStack spacing={8}>
                        <span>{contentMessage}</span>
                        <SettlementInfo settlement={settlement} />
                    </VStack>
                ),
                cancelButtonTitle: isSentTransaction ? "Keep transaction" : "Cancel",
                confirmButtonTitle: isSentTransaction ? "Yes, cancel" : "Yes, Delete",
            }),
        );
    };

    const [defaultNewSettlementValues, setDefaultNewSettlementValues] = useState<null | any>(null);
    const {
        isModalOpen: isCreateSettlementModalOpened,
        openModal: openCreateSettlementModal,
        closeModal: closeCreateSettlementModal,
    } = useModalControl();

    useInitialValues("settlement", setDefaultNewSettlementValues);
    useEffect(() => {
        if (defaultNewSettlementValues) {
            openCreateSettlementModal();
            setTimeout(() => setDefaultNewSettlementValues(null), 0);
        }
    }, [defaultNewSettlementValues]);

    const assetFilterOptions: DropdownOption<Settlement["asset"]>[] = [
        ...new Set(settlements.map(({ asset }) => asset)),
    ].map((asset) => ({
        text: asset.toLocaleUpperCase(),
        value: asset,
    }));
    const cpFilterOptions: DropdownOption<number>[] = Object.entries(
        settlements.reduce(
            (acc, { cpId, cpName }) => ({
                ...acc,
                [cpId]: cpName,
            }),
            {},
        ),
    ).map(([cpId, cpName]) => ({
        text: String(cpName),
        value: Number(cpId),
    }));
    const {
        control,
        watch,
        setValue,
    } = useForm<FilterValue>({
        defaultValues: {
            statusFilter,
            assetFilter,
            cpFilter,
        },
    });

    const statusFilterValue = watch("statusFilter");
    use2WayBinding(
        statusFilterValue,
        (filterValue) => setValue("statusFilter", filterValue),
        statusFilter,
        setStatusFilter,
    );

    const assetFilterValue = watch("assetFilter");
    use2WayBinding(
        assetFilterValue,
        (filterValue) => setValue("assetFilter", filterValue),
        assetFilter,
        setAssetFilter,
    );

    const cpFilterValue = watch("cpFilter");
    use2WayBinding(
        cpFilterValue,
        (filterValue) => setValue("cpFilter", filterValue),
        cpFilter,
        setCpFilter,
    );

    const areFiltersDirty =
        statusFilterValue.length !== 0 ||
        assetFilterValue.length !== 0 ||
        cpFilterValue.length !== 0 ||
        searchQuery.trim() !== "";

    const resetFilter = () => {
        deleteStatusFilter();
        deleteAssetFilter();
        deleteCpFilter();
        setSearchQuery("");
    };

    const actionableCount = useMemo(
        () =>
            settlements.reduce<number>(
                (acc, settlement) => {
                    if (isActionable(settlement)) {
                        acc++;
                    }
                    return acc;
                },
                0,
            ),
        [settlements],
    );
    const filteredSettlements = useMemo(
        () =>
            settlements.filter(({ type, comment, cpId, cpName, asset, network, status, txId }) => {
                let matched =
                    (statusFilter.length === 0 || statusFilter.includes(status)) &&
                    (assetFilter.length === 0 || assetFilter.includes(asset)) &&
                    (cpFilter.length === 0 || cpFilter.includes(cpId));
                if (matched && selectedFilterTab !== Tabs.ALL) {
                    if (selectedFilterTab === Tabs.INCOMING) {
                        matched = isIncoming({ status, type });
                    }
                    if (selectedFilterTab === Tabs.OUTGOING) {
                        matched = isOutgoing({ status, type });
                    }
                    if (selectedFilterTab === Tabs.REQUESTS) {
                        matched = isRequest({ status });
                    }
                    if (selectedFilterTab === Tabs.ACTIONABLE) {
                        matched = isActionable({ status, type });
                    }
                }

                const query = searchQuery.trim().toLocaleLowerCase();
                if (matched && query) {
                    matched =
                        comment.toLocaleLowerCase().includes(query) ||
                        String(cpId).toLocaleLowerCase().includes(query) ||
                        cpName.toLocaleLowerCase().includes(query) ||
                        Boolean(asset?.toLocaleLowerCase().includes(query)) ||
                        Boolean(network?.toLocaleLowerCase().includes(query)) ||
                        txId.toLocaleLowerCase().includes(query);
                }

                return matched;
            }),
        [settlements, selectedFilterTab, searchQuery, statusFilter, assetFilter, cpFilter],
    );

    return (
        <>
            <Container spacing={8} paddingLeft={8} paddingRight={8}>
                <CardContainer flex={1} asCard minWidth="445px">
                    <Header>
                        <Flex>
                            <TabContext
                                value={selectedFilterTab}
                                handleClick={setSelectedFilterTab}
                            >
                                <FormTabList>
                                    <FormTab
                                        title="All"
                                        value={Tabs.ALL}
                                    />
                                    <FormTab
                                        title="Actionable"
                                        label={getTabNumericLabel(actionableCount)}
                                        value={Tabs.ACTIONABLE}
                                    />
                                    <FormTab
                                        title="Incoming"
                                        value={Tabs.INCOMING}
                                    />
                                    <FormTab
                                        title="Outgoing"
                                        value={Tabs.OUTGOING}
                                    />
                                    <FormTab
                                        title="Requests"
                                        value={Tabs.REQUESTS}
                                    />
                                </FormTabList>
                            </TabContext>
                            <PrimaryButton size="small" onClick={openCreateSettlementModal}>
                                Create
                            </PrimaryButton>
                        </Flex>
                        <HeaderActions>
                            <Search
                                size="small"
                                placeholder="Search"
                                query={searchQuery}
                                onChange={(value) => setSearchQuery(value)}
                                className="search"
                            />
                            <Controller
                                control={control}
                                render={({ field }) => (
                                    <MultipleDropdown
                                        values={field.value}
                                        onChange={field.onChange}
                                        renderTrigger={(trigger) => (
                                            <MultipleDropdown.Trigger
                                                {...trigger}
                                                size="small"
                                                variant="simple"
                                            >
                                                <CurrencyTriggerEssence
                                                    {...trigger}
                                                    option={trigger.selectedOptions}
                                                    noIcon
                                                    size="small"
                                                />
                                            </MultipleDropdown.Trigger>
                                        )}
                                        options={assetFilterOptions}
                                        align="end"
                                        caption="Asset"
                                        asFilter
                                    >
                                        <CurrencyDropdownSheet
                                            size="medium"
                                            options={assetFilterOptions}
                                            Dropdown={MultipleDropdown}
                                        />
                                    </MultipleDropdown>
                                )}
                                name="assetFilter"
                            />
                            <Controller
                                control={control}
                                render={({ field }) => (
                                    <MultipleDropdown
                                        values={field.value}
                                        onChange={field.onChange}
                                        renderTrigger={(trigger) => (
                                            <MultipleDropdown.Trigger
                                                {...trigger}
                                                size="small"
                                                variant="simple"
                                            >
                                                <CounterpartyTriggerEssence
                                                    {...trigger}
                                                    option={trigger.selectedOptions}
                                                    noIcon
                                                    size="small"
                                                />
                                            </MultipleDropdown.Trigger>
                                        )}
                                        options={cpFilterOptions}
                                        align="end"
                                        caption="CP"
                                        asFilter
                                    >
                                        <CounterpartiesSheet
                                            size="medium"
                                            options={cpFilterOptions}
                                            Dropdown={MultipleDropdown}
                                        />
                                    </MultipleDropdown>
                                )}
                                name="cpFilter"
                            />
                            <Controller
                                control={control}
                                render={({ field }) => (
                                    <MultipleDropdown
                                        values={field.value}
                                        onChange={field.onChange}
                                        renderTrigger={(trigger) => (
                                            <MultipleDropdown.Trigger
                                                {...trigger}
                                                size="small"
                                                variant="simple"
                                            >
                                                <MultipleDropdown.TriggerEssence
                                                    {...trigger}
                                                    option={trigger.selectedOptions}
                                                    size="small"
                                                />
                                            </MultipleDropdown.Trigger>
                                        )}
                                        options={STATUS_OPTIONS}
                                        align="end"
                                        caption="Status"
                                        asFilter
                                    >
                                        <MultipleDropdown.BasicSheet
                                            size="small"
                                            options={STATUS_OPTIONS}
                                        />
                                    </MultipleDropdown>
                                )}
                                name="statusFilter"
                            />
                            {areFiltersDirty && (
                                <Tooltip content="Reset filter" align="center">
                                    <IconButton
                                        variant="plain"
                                        type="button"
                                        Icon={Icons.Recent}
                                        onClick={resetFilter}
                                    />
                                </Tooltip>
                            )}
                        </HeaderActions>
                    </Header>
                    <SettlementsTable
                        data={filteredSettlements}
                        onSendClick={setSelectedSettlement}
                        onRemoveClick={removeSettlement}
                        onCreateTransactionByRequestClick={setSelectedSettlement}
                        onCommitClick={setSelectedSettlement}
                        onRowSelected={setSelectedSettlement}
                        searchQuery={searchQuery}
                        isLoading={isLoading}
                    />
                </CardContainer>
            </Container>
            {selectedSettlement && (
                <SettlementDetailsModal
                    settlement={selectedSettlement}
                    onContinueClick={setSelectedSettlement}
                    closeModal={() => setSelectedSettlement(null)}
                />
            )}
            {isCreateSettlementModalOpened && (
                <CreateSettlementModal
                    closeModal={closeCreateSettlementModal}
                    onContinueClick={setSelectedSettlement}
                    defaultValues={defaultNewSettlementValues}
                />
            )}
        </>
    );
};
