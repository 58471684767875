import { useInstruments } from "hooks";
import { useCounterparties } from "hooks/useCounterparties";
import { useMemo } from "react";
import { CryptoAddress, UseAddressType } from "../types";
import { CRYPTO_CURRENCY_TYPES, getAccountStatus } from "../utils";
import { useAccountRevisions } from "./useAccountRevisions";
import { usePaymentAccounts } from "./usePaymentAccounts";
import { usePaymentAccountsRules } from "./usePaymentAccountsRules";

export const useCryptoAddresses = (): UseAddressType<CryptoAddress> => {
    const { paymentAccounts, isPaymentAccountsLoading } = usePaymentAccounts();
    const { accountsRevisions } = useAccountRevisions();
    const { paymentAccountsRules } = usePaymentAccountsRules();
    const { counterparties, isLoading: isCounterpartiesLoading } = useCounterparties();
    const { currencies, networks } = useInstruments();

    const data = useMemo(() => {
        return paymentAccounts
            .reduce((acc, curr) => {
                const accountRule = paymentAccountsRules.find(({ paymentAccountId }) => paymentAccountId === curr.id);
                const cpId = accountRule?.counterpartyId;

                if (cpId !== undefined && !counterparties[cpId]) {
                    return acc;
                }
                if (!CRYPTO_CURRENCY_TYPES.includes(curr.currencyType)) {
                    return acc;
                }

                const accountStatus = getAccountStatus("your", curr.id !== undefined ? accountsRevisions[curr.id] : []);

                const cp =
                    cpId !== undefined
                        ? {
                              id: cpId,
                              name: counterparties[cpId],
                          }
                        : null;
                const currency = Object.values(currencies).find(({ id }) => curr.currencyId === id);
                const network = networks.find(({ id }) => id === curr.networkId) ?? null;

                acc.push({
                    accountId: curr.id,
                    rule: {
                        id: accountRule?.id,
                        cp: cp,
                        accountId: curr.id,
                    },
                    currency: currency
                        ? {
                              id: currency.id,
                              name: currency.name,
                          }
                        : null,
                    network: network,
                    wallet: curr.data.wallet,
                    privateNote: curr.notes,
                    memoOrTag: curr.data.memoOrTag,
                    revisions: curr.id !== undefined ? accountsRevisions[curr.id] : undefined,
                    status: accountStatus,
                } as CryptoAddress);

                return acc;
            }, [] as CryptoAddress[])
            .sort((a, b) => {
                if (a?.rule?.cp?.name === undefined) {
                    return -1;
                }
                if (b?.rule?.cp?.name === undefined) {
                    return 1;
                }

                return a.rule.cp.name.localeCompare(b.rule.cp.name);
            });
    }, [accountsRevisions, paymentAccounts, paymentAccountsRules, currencies, networks, counterparties]);

    return {
        data,
        isLoading: isPaymentAccountsLoading || isCounterpartiesLoading,
    };
};
