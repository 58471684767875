import { ActionCell } from "./cells/DiffActionCell";
import { DataCell as DataCellComponent } from "./cells/DiffDataCell";
import { NewValue, NormalValue, OldValue } from "./cells/DiffDataCell/styled";
import { RowNumberCell } from "./cells/DiffRowNumberCell";
import { InfiniteTable } from "./InfiniteTable";

const DataCell = Object.assign(DataCellComponent, {
    NormalValue,
    OldValue,
    NewValue,
});

export const DiffTable = Object.assign(InfiniteTable, {
    RowNumberCell: RowNumberCell,
    DataCell: DataCell,
    ActionCell: ActionCell,
});
