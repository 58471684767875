import { TopMenuSelect } from "components/TopMenu";
import { InboxPopover } from "feature/inboxPopover";
import { Container } from "./styled";

export const UserMenuControl = () => (
    <Container>
        <TopMenuSelect />
        <InboxPopover />
    </Container>
);
