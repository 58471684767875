import { BasicButton, HStack, Icons, Modal, PSmallBold, VStack } from "@fm-frontend/uikit";
import { Table, Th, Thead } from "@fm-frontend/uikit/src/components/table/common/styled";
import { useModalControl, when } from "@fm-frontend/utils";
import { useCounterparties } from "hooks/useCounterparties";
import { useTextAdaptor } from "hooks/useTextAdaptor";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useIsSubaccountUser } from "store/hooks";
import { useAddressContext } from "../AddressesContext";
import { ActionContainer, CryptoAddressRow, HintCell, Tr } from "../components";
import { useScrollToBottom } from "../hooks";
import { AddressForAllCpFormInputs, CryptoAddress, CryptoAddressFormInput, FormApiErrors } from "../types";
import { ADDRESSES_COLLECTION_NAME, CRYPTO_CURRENCY_TYPES, PRIVATE_NOTE_HINT_TEXT } from "../utils";
import {
    ActionsControl,
    CpControl,
    CurrencyControl,
    MemoOrTagControl,
    NetworkControl,
    PrivateNoteControl,
    ValidationControl,
    WalletControl,
} from "./form/controlls";
import { AddAddressFroAllCpModal } from "./modals/AddAddressFroAllCpModal";
import { DEFAULT_CRYPTO_ADDRESS, removeDefaultRows } from "./utils";

type ColumnConfigType = { key: string; title: string; maxWidth?: string; width?: string; hint?: string };

interface CryptoAddressBoxProps {
    apiErrors: FormApiErrors;
    onAddressDelete: (id: string, address: CryptoAddress) => void;
}

export const CryptoAddressLightBoxContent: React.FC<CryptoAddressBoxProps> = ({ onAddressDelete, apiErrors }) => {
    const textAdaptor = useTextAdaptor();
    const isSubaccountUser = useIsSubaccountUser();
    const { selectedCps } = useAddressContext();
    const { isModalOpen, openModal, closeModal } = useModalControl();
    const scrollToBottom = useScrollToBottom();

    const {
        control,
        formState: { errors },
        getValues,
    } = useFormContext<CryptoAddressFormInput>();
    const { counterparties } = useCounterparties({
        specificCps: selectedCps.length ? selectedCps : undefined,
    });
    const apiErrorsOccurred = Boolean(Object.keys(apiErrors).length);

    const { fields, append, remove, insert } = useFieldArray({
        control,
        name: ADDRESSES_COLLECTION_NAME,
    });

    useEffect(() => {
        scrollToBottom();
    }, [fields.length]);

    const COLUMNS_CONFIG: ColumnConfigType[] = [
        {
            key: "cp",
            title: textAdaptor("cryptoLightboxNameColumn") ?? "",
            maxWidth: "146px",
            width: "146px",
        },
        {
            key: "asset",
            title: "Asset",
            maxWidth: "140px",
            width: "140px",
        },
        {
            key: "network",
            title: "Network",
            maxWidth: "140px",
            width: "140px",
        },
        {
            key: "address",
            title: "Your address",
            maxWidth: "146px",
            width: "146px",
        },
        {
            key: "privateNote",
            title: "Private note",
            hint: PRIVATE_NOTE_HINT_TEXT,
            maxWidth: "146px",
            width: "146px",
        },
        {
            key: "memoOrTag",
            title: "Memo or tag",
            maxWidth: "84px",
            width: "84px",
        },
        {
            key: "actions",
            title: "",
            maxWidth: "60px",
            width: "60px",
        },
    ];

    const handleRowDelete = (id: string, index: number) => {
        const addresses = getValues()?.addresses ?? [];
        const addressToDelete = addresses[index];

        if (addressToDelete.accountId) {
            onAddressDelete(id, addressToDelete);
        }

        remove(index);
    };

    const handleRowCopy = (index: number) => {
        const itemToCopy = getValues()?.addresses?.[index];

        const indexToInsert = index + 1;

        if (itemToCopy) {
            const addressCopy: CryptoAddress = JSON.parse(JSON.stringify(itemToCopy));
            insert(indexToInsert, {
                ...DEFAULT_CRYPTO_ADDRESS,
                currency: addressCopy.currency,
                network: addressCopy.network,
                wallet: addressCopy.wallet,
                privateNote: addressCopy.privateNote,
                memoOrTag: addressCopy.memoOrTag,
                memoOrTagRequired: addressCopy.memoOrTagRequired,
            });
        }
    };

    const handleSaveAddressForAllCps = (inputs: AddressForAllCpFormInputs) => {
        const formAddresses = getValues()?.addresses ?? [];
        removeDefaultRows(formAddresses, remove);

        const newAddressRows: CryptoAddress[] = Object.entries(counterparties).reduce<CryptoAddress[]>((acc, [id]) => {
            const exists = formAddresses.some(
                ({ rule, currency }) => rule?.cp.id === Number(id) && currency?.id === inputs.currencyId,
            );

            if (!exists) {
                acc.push({
                    ...DEFAULT_CRYPTO_ADDRESS,
                    rule: {
                        cp: {
                            id: Number(id),
                        },
                    },
                    currency: {
                        id: inputs.currencyId,
                    },
                    network: {
                        id: inputs.networkId,
                    },
                    wallet: inputs.wallet,
                    memoOrTag: inputs.memoOrTag,
                });
            }

            return acc;
        }, []);

        append(newAddressRows);
        closeModal();
    };

    return (
        <VStack>
            <Table>
                <Thead>
                    <Tr>
                        {COLUMNS_CONFIG.map(({ key, title, hint, width }) => (
                            <Th key={key} width={width}>
                                {when(hint, <HintCell text={title} hint={hint!} />, title)}
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                {fields.map((item, index) =>
                    when(
                        !apiErrorsOccurred || apiErrors[item.id],
                        <CryptoAddressRow
                            key={item.id}
                            validationError={errors.root?.[item.id]?.message}
                            apiError={apiErrors[item.id]}
                            columnMaxWidth={COLUMNS_CONFIG.map(({ maxWidth }) => maxWidth ?? "")}
                            columnWidth={COLUMNS_CONFIG.map(({ width }) => width ?? "")}
                            animateShowUp={item.accountId === undefined}
                        >
                            <ValidationControl
                                key={`${index}-${item.id}`}
                                index={index}
                                rowId={item.id}
                                accountId={item.accountId}
                            />
                            <CpControl index={index} rowId={item.id} disabled={apiErrorsOccurred} />
                            <CurrencyControl
                                index={index}
                                rowId={item.id}
                                disabled={apiErrorsOccurred}
                                availableCurrencyTypes={CRYPTO_CURRENCY_TYPES}
                            />
                            <NetworkControl index={index} rowId={item.id} disabled={apiErrorsOccurred} />
                            <WalletControl index={index} rowId={item.id} disabled={apiErrorsOccurred} />
                            <PrivateNoteControl index={index} rowId={item.id} disabled={apiErrorsOccurred} />
                            <MemoOrTagControl index={index} rowId={item.id} disabled={apiErrorsOccurred} />
                            <ActionsControl
                                hide={apiErrorsOccurred}
                                onRowCopy={() => handleRowCopy(index)}
                                onRowDelete={() => handleRowDelete(item.id, index)}
                            />
                        </CryptoAddressRow>,
                    ),
                )}
            </Table>

            {when(
                apiErrorsOccurred,
                <></>,
                <ActionContainer spacing={6} paddingY={12}>
                    <BasicButton type="button" size="small" onClick={() => append({ ...DEFAULT_CRYPTO_ADDRESS })}>
                        <HStack spacing={4}>
                            <Icons.Add />
                            <PSmallBold>Address</PSmallBold>
                        </HStack>
                    </BasicButton>
                    {!isSubaccountUser && (
                        <BasicButton type="button" onClick={openModal} size="small">
                            <HStack spacing={4}>
                                <Icons.Add />
                                <PSmallBold>{textAdaptor("addAndAddressForAll")}</PSmallBold>
                            </HStack>
                        </BasicButton>
                    )}
                </ActionContainer>,
            )}
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <AddAddressFroAllCpModal onClose={closeModal} onSave={handleSaveAddressForAllCps} />
            </Modal>
        </VStack>
    );
};
