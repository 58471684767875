import { P, PBold } from "@fm-frontend/uikit";
import { onMobile } from "const";
import styled, { css } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 4px 12px 4px;
`;

export const SettlementContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 4px);
    padding: 0 4px;
    gap: 12px 0;

    ${onMobile} {
        flex-direction: column;
        padding: unset;
        width: 100%;
        gap: 24px;
    }
`;

export const PropertiesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

export const VerificationContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;

    button {
        min-width: 136px;
    }

    ${onMobile} {
        flex-direction: column;
    }
`;

export const FinishedContainer = styled(VerificationContainer)`
    ${onMobile} {
        flex-direction: row;
    }
`;

export const CoverContainer = styled.div<{ isBackgroundActive: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 12px;
    ${(p) =>
        p.isBackgroundActive &&
        css`
            padding-top: 141px;
            background: white url("/img/onboarding-verification-bg.png") no-repeat top;
            background-size: 568px 141px;
        `}
`;

export const ActionContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 6px;
    flex-wrap: wrap;
`;

export const LimitStatusContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: end;
`;

export const NotificationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${P}, ${PBold} {
        color: ${(p) => p.theme.colors.uiWhite100};
    }
`;

export const ContactProvider = styled.a`
    padding: 6px 12px;
    background-color: ${(p) => p.theme.colors.ui8};
    border-radius: 8px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;

    :hover {
        background-color: ${(p) => p.theme.colors.ui12};
    }
`;
