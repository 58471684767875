import { flexRender, Row, Table } from "@tanstack/react-table";
import { EmptySearch } from "../common/EmptySearch";
import { Flex } from "../layout";
import { TableEmptyStateProps } from "./common";
import * as Styled from "./common/styled";
import { useTableStickyCellInsets } from "./common/useTableStickyCellInsets";
import { Thead } from "./Thead";

export const ACTIONS_TABLE_COLUMN_KEY = "_actions";
export const EXPAND_TABLE_COLUMN_KEY = "_expand";

export type TableViewProps<T extends object> = {
    className?: string;
    table: Table<T>;
    onRowClick?: (
        row: Row<T>,
        id: string,
        e?: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>,
    ) => void;
    isEmpty?: boolean;
    selectedRows?: Record<string, boolean>;
    condensed?: boolean;
} & TableEmptyStateProps;

const EmptyStateComponent = () => (
    <Flex paddingTop={60} paddingBottom={60}>
        <EmptySearch />
    </Flex>
);

export function TableView<T extends object = {}>({
    table,
    selectedRows = {},
    onRowClick,
    isEmpty,
    className,
    renderEmptyState,
    condensed = false,
}: TableViewProps<T>) {
    const { tableRef, leftStickyCellInsets } = useTableStickyCellInsets(table.getAllColumns());

    return (
        <>
            <Styled.Table ref={tableRef} className={className}>
                <Thead table={table} leftStickyCellInsets={leftStickyCellInsets} />
                <Styled.Tbody>
                    {table.getRowModel().rows.map((row) => (
                        <Styled.Tr
                            isGroup={row.getCanExpand()}
                            isSelected={selectedRows[row.id]}
                            key={row.id}
                        >
                            {row
                                .getVisibleCells()
                                .filter(
                                    (cell) => cell.column.columnDef.meta?.skipCell?.(cell) !== true,
                                )
                                .map((cell, index) => {
                                    let tdProps = cell.column.columnDef.meta?.tdProps;
                                    if (typeof tdProps === "function") {
                                        tdProps = tdProps(cell);
                                    }

                                    return (
                                        <Styled.Td
                                            expand={cell.column.id === EXPAND_TABLE_COLUMN_KEY}
                                            sticky={cell.column.columnDef.meta?.sticky}
                                            lastStickyColumn={
                                                cell.column.columnDef.meta?.lastStickyColumn
                                            }
                                            leftStickyCellInset={
                                                leftStickyCellInsets[index] ??
                                                leftStickyCellInsets[
                                                    leftStickyCellInsets.length - 1
                                                ]
                                            }
                                            onClick={(e) => onRowClick?.(row, cell.column.id, e)}
                                            style={cell.column.columnDef.meta?.cellStyleProps}
                                            key={cell.id}
                                            {...tdProps}
                                            condensed={condensed}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </Styled.Td>
                                    );
                                })}
                        </Styled.Tr>
                    ))}
                </Styled.Tbody>
            </Styled.Table>
            {isEmpty ? renderEmptyState?.() ?? <EmptyStateComponent /> : null}
        </>
    );
}
