import { Pill, PillsContainer } from "../styled";
import type { UserPermissions } from "../types";
import type { PermissionsTitles, PillsProps } from "./types";

const PERMISSIONS_TITLES: PermissionsTitles = {
    trading: "Trading",
    settle: "Settlement",
    api: "API",
    users: "Users",
    risks: "Risks",
    subaccounts: "Sub-accounts",
};

export const Pills = ({ permissions, isActive }: PillsProps) => {
    const enabledPermissions = Object.entries(permissions)
        .filter(([, enabled]) => enabled)
        .map(([permission]) => permission) as Array<keyof UserPermissions>;

    return (
        <PillsContainer>
            {enabledPermissions.length === 0 && <Pill isActive={isActive}>View-only</Pill>}
            {enabledPermissions.map((permission) => (
                <Pill key={permission} isActive={isActive}>
                    {PERMISSIONS_TITLES[permission]}
                </Pill>
            ))}
        </PillsContainer>
    );
};
