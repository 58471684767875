import { Icons, P, PBold, PrimaryButton, VStack } from "@fm-frontend/uikit";
import { useSelector } from "hooks";
import React from "react";
import { useDispatch } from "react-redux";
import { ONBOARDING_STEPS, setCurrentStep } from "store/onboardingSlice";
import styled, { css, useTheme } from "styled-components";

const Container = styled(VStack)<{ inline?: boolean }>`
    position: fixed;
    bottom: 24px;
    right: 16px;
    width: 320px;
    padding: 12px 12px 16px 12px;
    z-index: 1;

    ${(p) =>
        p.inline &&
        css`
            position: unset;
            width: 100%;
            margin-bottom: 12px;
        `}
`;

export const SignTAndCBanner: React.VFC = () => {
    const { colors } = useTheme();
    const dispatch = useDispatch();
    const { isMobile } = useSelector((state) => state.app);
    const { currentStep } = useSelector((state) => state.onboarding);

    const isTAndCStep = currentStep === ONBOARDING_STEPS.BL_TERMS_AND_CONDITIONS;

    return (
        <Container asCard inline={isMobile}>
            {isTAndCStep ? (
                <>
                    <VStack paddingBottom={16} spacing={12}>
                        <Icons.BannerAwaiting />
                        <PBold>Awaiting the signing of T&C</PBold>
                        <P color={colors.ui52}>
                            To get access to the product you must sign the T&C with Finery Markets
                        </P>
                    </VStack>
                    <PrimaryButton size="large" fullWidth disabled>
                        Access platform
                    </PrimaryButton>
                </>
            ) : (
                <>
                    <VStack paddingBottom={16} spacing={12}>
                        <Icons.BannerAttention color={colors.attention100} />
                        <PBold>Sign Terms & Conditions</PBold>
                        <P color={colors.ui52}>
                            To get access to the product you need to sign the T&C with Finery Markets
                        </P>
                    </VStack>
                    <PrimaryButton
                        size="large"
                        fullWidth
                        onClick={() => dispatch(setCurrentStep(ONBOARDING_STEPS.BL_TERMS_AND_CONDITIONS))}
                    >
                        Sign T&C
                    </PrimaryButton>
                </>
            )}
        </Container>
    );
};
