import { call, put, select, takeEvery } from "@redux-saga/core/effects";
import { createAction } from "@reduxjs/toolkit";
import { post } from "api";
import { IRootState } from "store";
import { ClientType } from "types";
import { setAllAvailableCounterpartiesInfo } from ".";

export const fetchCounterpartiesInfo = createAction("app/fetchCounterpartiesInfo", (cpId: number) => ({
    payload: cpId,
}));

export function* sagaCounterpartiesInfo() {
    yield takeEvery(fetchCounterpartiesInfo, sagaCounterpartiesInfoFetcher);
}

type ApiClientType = "primeBroker" | "maker" | "taker";

const getClientType = (apiClientType: ApiClientType, isSubaccount: boolean): ClientType => {
    if (isSubaccount) {
        return apiClientType === "maker" ? "subaccountMaker" : "subaccountTaker";
    }

    return apiClientType;
};

export function* sagaCounterpartiesInfoFetcher({ payload: counterpartyId }: { payload?: number }) {
    const allAvailableCounterpartiesInfo: IRootState["app"]["allAvailableCounterpartiesInfo"] = yield select(
        (state: IRootState) => state.app.allAvailableCounterpartiesInfo,
    );

    if (counterpartyId !== undefined && allAvailableCounterpartiesInfo[counterpartyId]?.username !== undefined) {
        return;
    }

    const infos: {
        clientId: number;
        username?: string;
        clientType: ApiClientType;
        subaccount?: boolean;
    }[] = yield call(() => post("getCounterpartyInfo", { counterpartyId }));
    const newInfos = infos.filter(
        ({ clientId, username }) =>
            !Object.hasOwn(allAvailableCounterpartiesInfo, clientId) ||
            (allAvailableCounterpartiesInfo[clientId].username === undefined && username !== undefined),
    );

    if (newInfos.length) {
        const newInfosObj = newInfos.reduce<IRootState["app"]["allAvailableCounterpartiesInfo"]>(
            (acc, { clientId, username, clientType, subaccount }) => ({
                ...acc,
                [clientId]: {
                    username,
                    clientType: getClientType(clientType, Boolean(subaccount)),
                    subaccount,
                },
            }),
            {},
        );

        yield put(setAllAvailableCounterpartiesInfo(newInfosObj));
    }
}
