import { useCallback, useMemo } from "react";
import { CpGroupTypeEnum } from "../feature/assetsControl/types";
import { useSelector } from "../hooks";
import { useUserType } from "../store/hooks";
import { ClientType, ClientTypeEnum } from "../types";

export type ActionPermissionsTypes = {
    isOnboardingAllowed: boolean;
    isCpLimitAllowed: boolean;
    isMarkupsAllowed: boolean;
    isMarginAndLiquidationAllowed: boolean;
    isLimitPerAssetAllowed: boolean;
    isShortSalesBanAllowed: boolean;
    isOvernightsAllowed: boolean;
    isManualTradeAllowed: boolean;
    isSettlementRequestAllowed: boolean;
    isTransactionsAllowed: boolean;
};

const DEFAULT_PERMISSIONS: ActionPermissionsTypes = {
    isOnboardingAllowed: false,
    isCpLimitAllowed: false,
    isMarkupsAllowed: false,
    isMarginAndLiquidationAllowed: false,
    isLimitPerAssetAllowed: false,
    isShortSalesBanAllowed: false,
    isOvernightsAllowed: false,
    isManualTradeAllowed: false,
    isSettlementRequestAllowed: false,
    isTransactionsAllowed: false,
};

type PermissionsMatrix = Record<string, ActionPermissionsTypes>;

const PERMISSIONS_MATRIX: PermissionsMatrix = {
    [`${ClientTypeEnum.PrimeBroker}-${ClientTypeEnum.PrimeBroker}`]: {
        isOnboardingAllowed: true,
        isCpLimitAllowed: true,
        isMarkupsAllowed: true,
        isMarginAndLiquidationAllowed: false,
        isLimitPerAssetAllowed: true,
        isShortSalesBanAllowed: false,
        isOvernightsAllowed: false,
        isManualTradeAllowed: false,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },
    [`${ClientTypeEnum.PrimeBroker}-${ClientTypeEnum.Maker}`]: {
        isOnboardingAllowed: true,
        isCpLimitAllowed: true,
        isMarkupsAllowed: false,
        isMarginAndLiquidationAllowed: false,
        isLimitPerAssetAllowed: true,
        isShortSalesBanAllowed: false,
        isOvernightsAllowed: false,
        isManualTradeAllowed: false,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },
    [`${ClientTypeEnum.PrimeBroker}-${CpGroupTypeEnum.ExternalMakers}`]: {
        isOnboardingAllowed: true,
        isCpLimitAllowed: true,
        isMarkupsAllowed: false,
        isMarginAndLiquidationAllowed: false,
        isLimitPerAssetAllowed: true,
        isShortSalesBanAllowed: false,
        isOvernightsAllowed: false,
        isManualTradeAllowed: false,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },
    [`${ClientTypeEnum.PrimeBroker}-${ClientTypeEnum.Taker}`]: {
        isOnboardingAllowed: false,
        isCpLimitAllowed: true,
        isMarkupsAllowed: true,
        isMarginAndLiquidationAllowed: true,
        isLimitPerAssetAllowed: true,
        isShortSalesBanAllowed: true,
        isOvernightsAllowed: true,
        isManualTradeAllowed: true,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },
    [`${ClientTypeEnum.PrimeBroker}-${CpGroupTypeEnum.ExternalTakers}`]: {
        isOnboardingAllowed: false,
        isCpLimitAllowed: true,
        isMarkupsAllowed: true,
        isMarginAndLiquidationAllowed: true,
        isLimitPerAssetAllowed: true,
        isShortSalesBanAllowed: true,
        isOvernightsAllowed: true,
        isManualTradeAllowed: true,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },
    [`${ClientTypeEnum.PrimeBroker}-${ClientTypeEnum.SubaccountMaker}`]: {
        isOnboardingAllowed: false,
        isCpLimitAllowed: true,
        isMarkupsAllowed: true,
        isMarginAndLiquidationAllowed: true,
        isLimitPerAssetAllowed: true,
        isShortSalesBanAllowed: true,
        isOvernightsAllowed: true,
        isManualTradeAllowed: false,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },
    [`${ClientTypeEnum.PrimeBroker}-${CpGroupTypeEnum.SubaccountMakers}`]: {
        isOnboardingAllowed: false,
        isCpLimitAllowed: true,
        isMarkupsAllowed: true,
        isMarginAndLiquidationAllowed: true,
        isLimitPerAssetAllowed: true,
        isShortSalesBanAllowed: true,
        isOvernightsAllowed: true,
        isManualTradeAllowed: false,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },
    [`${ClientTypeEnum.PrimeBroker}-${ClientTypeEnum.SubaccountTaker}`]: {
        isOnboardingAllowed: false,
        isCpLimitAllowed: true,
        isMarkupsAllowed: true,
        isMarginAndLiquidationAllowed: true,
        isLimitPerAssetAllowed: true,
        isShortSalesBanAllowed: true,
        isOvernightsAllowed: true,
        isManualTradeAllowed: true,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },
    [`${ClientTypeEnum.PrimeBroker}-${CpGroupTypeEnum.SubaccountTakers}`]: {
        isOnboardingAllowed: false,
        isCpLimitAllowed: true,
        isMarkupsAllowed: true,
        isMarginAndLiquidationAllowed: true,
        isLimitPerAssetAllowed: true,
        isShortSalesBanAllowed: true,
        isOvernightsAllowed: true,
        isManualTradeAllowed: true,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },

    [`${ClientTypeEnum.Maker}-${ClientTypeEnum.PrimeBroker}`]: {
        isOnboardingAllowed: false,
        isCpLimitAllowed: true,
        isMarkupsAllowed: true,
        isMarginAndLiquidationAllowed: true,
        isLimitPerAssetAllowed: true,
        isShortSalesBanAllowed: true,
        isOvernightsAllowed: true,
        isManualTradeAllowed: false,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },
    [`${ClientTypeEnum.Maker}-${ClientTypeEnum.Maker}`]: { ...DEFAULT_PERMISSIONS },
    [`${ClientTypeEnum.Maker}-${ClientTypeEnum.Taker}`]: {
        isOnboardingAllowed: false,
        isCpLimitAllowed: true,
        isMarkupsAllowed: true,
        isMarginAndLiquidationAllowed: true,
        isLimitPerAssetAllowed: true,
        isShortSalesBanAllowed: true,
        isOvernightsAllowed: true,
        isManualTradeAllowed: true,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },
    [`${ClientTypeEnum.Maker}-${ClientTypeEnum.SubaccountMaker}`]: { ...DEFAULT_PERMISSIONS },
    [`${ClientTypeEnum.Maker}-${ClientTypeEnum.SubaccountTaker}`]: { ...DEFAULT_PERMISSIONS },

    [`${ClientTypeEnum.Taker}-${CpGroupTypeEnum.All}`]: {
        isOnboardingAllowed: true,
        isCpLimitAllowed: true,
        isMarkupsAllowed: false,
        isMarginAndLiquidationAllowed: false,
        isLimitPerAssetAllowed: true,
        isShortSalesBanAllowed: false,
        isOvernightsAllowed: false,
        isManualTradeAllowed: false,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },
    [`${ClientTypeEnum.Taker}-${CpGroupTypeEnum.ExternalMakers}`]: {
        isOnboardingAllowed: true,
        isCpLimitAllowed: true,
        isMarkupsAllowed: false,
        isMarginAndLiquidationAllowed: false,
        isLimitPerAssetAllowed: true,
        isShortSalesBanAllowed: false,
        isOvernightsAllowed: false,
        isManualTradeAllowed: false,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },
    [`${ClientTypeEnum.Taker}-${ClientTypeEnum.PrimeBroker}`]: {
        isOnboardingAllowed: true,
        isCpLimitAllowed: true,
        isMarkupsAllowed: false,
        isMarginAndLiquidationAllowed: false,
        isLimitPerAssetAllowed: true,
        isShortSalesBanAllowed: false,
        isOvernightsAllowed: false,
        isManualTradeAllowed: false,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },
    [`${ClientTypeEnum.Taker}-${ClientTypeEnum.Maker}`]: {
        isOnboardingAllowed: true,
        isCpLimitAllowed: true,
        isMarkupsAllowed: false,
        isMarginAndLiquidationAllowed: false,
        isLimitPerAssetAllowed: true,
        isShortSalesBanAllowed: false,
        isOvernightsAllowed: false,
        isManualTradeAllowed: false,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },
    [`${ClientTypeEnum.Taker}-${ClientTypeEnum.Taker}`]: { ...DEFAULT_PERMISSIONS },
    [`${ClientTypeEnum.Taker}-${ClientTypeEnum.SubaccountMaker}`]: { ...DEFAULT_PERMISSIONS },
    [`${ClientTypeEnum.Taker}-${ClientTypeEnum.SubaccountTaker}`]: { ...DEFAULT_PERMISSIONS },

    [`${ClientTypeEnum.SubaccountMaker}-${ClientTypeEnum.PrimeBroker}`]: {
        isOnboardingAllowed: true,
        isCpLimitAllowed: false,
        isMarkupsAllowed: false,
        isMarginAndLiquidationAllowed: false,
        isLimitPerAssetAllowed: false,
        isShortSalesBanAllowed: false,
        isOvernightsAllowed: false,
        isManualTradeAllowed: false,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },
    [`${ClientTypeEnum.SubaccountMaker}-${ClientTypeEnum.Maker}`]: { ...DEFAULT_PERMISSIONS },
    [`${ClientTypeEnum.SubaccountMaker}-${ClientTypeEnum.Taker}`]: { ...DEFAULT_PERMISSIONS },
    [`${ClientTypeEnum.SubaccountMaker}-${ClientTypeEnum.SubaccountMaker}`]: {
        ...DEFAULT_PERMISSIONS,
    },
    [`${ClientTypeEnum.SubaccountMaker}-${ClientTypeEnum.SubaccountTaker}`]: {
        ...DEFAULT_PERMISSIONS,
    },

    [`${ClientTypeEnum.SubaccountTaker}-${ClientTypeEnum.PrimeBroker}`]: {
        isOnboardingAllowed: true,
        isCpLimitAllowed: false,
        isMarkupsAllowed: false,
        isMarginAndLiquidationAllowed: false,
        isLimitPerAssetAllowed: false,
        isShortSalesBanAllowed: false,
        isOvernightsAllowed: false,
        isManualTradeAllowed: false,
        isSettlementRequestAllowed: true,
        isTransactionsAllowed: true,
    },
    [`${ClientTypeEnum.SubaccountTaker}-${ClientTypeEnum.Maker}`]: { ...DEFAULT_PERMISSIONS },
    [`${ClientTypeEnum.SubaccountTaker}-${ClientTypeEnum.Taker}`]: { ...DEFAULT_PERMISSIONS },
    [`${ClientTypeEnum.SubaccountTaker}-${ClientTypeEnum.SubaccountMaker}`]: {
        ...DEFAULT_PERMISSIONS,
    },
    [`${ClientTypeEnum.SubaccountTaker}-${ClientTypeEnum.SubaccountTaker}`]: {
        ...DEFAULT_PERMISSIONS,
    },
};

export const getActionsPermissions = (
    userType: ClientType | undefined,
    cpType: ClientType | CpGroupTypeEnum | undefined,
): ActionPermissionsTypes | null => {
    const key = `${userType}-${cpType}`;
    return PERMISSIONS_MATRIX[key] ?? null;
};

export type UserPermissions = Record<string, ActionPermissionsTypes>;

export const useActionPermissions = () => {
    const allAvailableCounterpartiesInfo = useSelector(
        (state) => state.app.allAvailableCounterpartiesInfo,
    );
    const userType = useUserType();

    const getUserActionPermissions = useCallback(
        (cpId: number | undefined): ActionPermissionsTypes => {
            if (cpId === undefined) {
                return { ...DEFAULT_PERMISSIONS };
            }

            const cpType = allAvailableCounterpartiesInfo[cpId];

            return (
                getActionsPermissions(userType, cpType?.clientType) ?? { ...DEFAULT_PERMISSIONS }
            );
        },
        [userType],
    );
    const actionsPermissions = useMemo(() => {
        return Object.entries(allAvailableCounterpartiesInfo).reduce<UserPermissions>(
            (acc, [cpId, cpInfo]) => {
                acc[cpId] = getActionsPermissions(userType, cpInfo.clientType) ?? {
                    ...DEFAULT_PERMISSIONS,
                };

                return acc;
            },
            {},
        );
    }, [userType]);

    return {
        getUserActionPermissions,
        actionsPermissions,
    };
};
