import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useAsyncDebounce } from "react-table";

export type HorizontalDirectionOptions = "left" | "center" | "right";
export type VerticalDirectionOptions = "top" | "center" | "bottom";
export type DropdownDirection = {
    horizontal: HorizontalDirectionOptions;
    vertical: VerticalDirectionOptions;
};
const SAFE_WIDTH_MARGIN = 240;
const DEFAULT_DIRECTION: Readonly<DropdownDirection> = {
    horizontal: "center",
    vertical: "bottom",
};

const DropdownDirectionContext = createContext<Partial<DropdownDirection> | null>(null);

export function useDropdownDirection(ref: React.RefObject<HTMLElement>) {
    const forcedDirection = useContext(DropdownDirectionContext);
    const docWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const docHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const { top = 0, left = 0, width = 0 } = ref.current?.getClientRects()[0] || {};
    const direction: DropdownDirection = useMemo(() => {
        const horizontal = left + width / 2 > docWidth / 2 ? "left" : "right";
        const vertical = top < docHeight / 2 ? "bottom" : "top";
        return { horizontal, vertical, ...forcedDirection };
    }, [docWidth, docHeight, top, left, width, forcedDirection]);
    return direction;
}
export const usePopoverDirection = (ref: React.RefObject<HTMLElement>) => {
    const [direction, setDirection] = useState<{
        horizontal: HorizontalDirectionOptions;
        vertical: VerticalDirectionOptions;
    }>(DEFAULT_DIRECTION);

    const forcedDirection = useContext(DropdownDirectionContext);

    const updateDirection = useCallback(() => {
        if (!ref.current) return;
        const documentWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const documentHeight =
            window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        const { left, right, bottom, height } = ref.current.getClientRects()[0];
        const newDirection = { ...DEFAULT_DIRECTION };
        if (left < SAFE_WIDTH_MARGIN) {
            newDirection.horizontal = "right";
        } else if (documentWidth - right < SAFE_WIDTH_MARGIN) {
            newDirection.horizontal = "left";
        }
        if (documentHeight - bottom - 12 < height) newDirection.vertical = "top";
        setDirection(newDirection);
    }, [ref, setDirection]);

    const debouncedUpdateDirection = useAsyncDebounce(updateDirection, 50);

    useEffect(() => {
        debouncedUpdateDirection();
        document.addEventListener("scroll", debouncedUpdateDirection);
        document.addEventListener("resize", debouncedUpdateDirection);
        return () => {
            document.removeEventListener("scroll", debouncedUpdateDirection);
            document.removeEventListener("resize", debouncedUpdateDirection);
        };
    }, [debouncedUpdateDirection]);

    return useMemo(() => ({ ...direction, ...forcedDirection }), [forcedDirection, direction]);
};

export const ForceDropdownDirection: React.FC<Partial<DropdownDirection>> = ({ children, horizontal, vertical }) => {
    const direction = useMemo(() => {
        const direction: Partial<DropdownDirection> = {};
        if (horizontal) direction.horizontal = horizontal;
        if (vertical) direction.vertical = vertical;
        return direction;
    }, [horizontal, vertical]);
    return <DropdownDirectionContext.Provider value={direction}>{children}</DropdownDirectionContext.Provider>;
};
