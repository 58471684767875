import styled from "styled-components";

export const Hint = styled.span`
    color: ${(p) => p.theme.colors.textBody3};
`;

export const Actions = styled.div`
    margin-left: 16px;
    margin-right: 9px;
`;
