import { flexRender, Row } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { memo } from "react";
import { CSSProperties } from "styled-components";
import * as Styled from "../../common/styled";
import { RowClickHandler } from "../types";

type InfiniteTableRowProps<T extends object> = {
    index: number;
    row: Row<T>;
    isSelected: boolean;
    rowVirtualizer: ReturnType<typeof useVirtualizer>;
    onRowClick?: RowClickHandler<T>;
};

const InfiniteTableRowComponent = <T extends object>({
    index,
    row,
    isSelected,
    rowVirtualizer,
    onRowClick,
}: InfiniteTableRowProps<T>) => (
    <Styled.Tr
        ref={rowVirtualizer.measureElement}
        data-index={index}
        isGroup={row.getCanExpand()}
        isSelected={isSelected}
    >
        {row.getVisibleCells().map((cell) => (
            <Styled.Td
                onClick={(e) => onRowClick?.(row, cell.column.id, e)}
                {...cell.column.columnDef.meta?.cellStyleProps}
                style={(cell.column.columnDef.meta?.cellStyleProps as CSSProperties) ?? {}}
                key={cell.id}
            >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Styled.Td>
        ))}
    </Styled.Tr>
);

export const InfiniteTableRow = memo(InfiniteTableRowComponent) as typeof InfiniteTableRowComponent;
