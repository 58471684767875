import { ValueParse } from "@fm-frontend/utils";
import { VFC } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { post } from "api";
import { createNotification } from "feature/app";
import { MakerInfo } from "types";
import { LimitInputs } from "../utils";
import { OnboardingLimitEdit } from "./OnboardingLimitEdit";
import { CURRENCY } from "./utils";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 296px;
    max-width: 360px;
`;

export const OnboardingLimitsModal: VFC<{
    makerInfo: MakerInfo;
    onApply: () => void;
    onClose: () => void;
    cpLimit?: LimitInputs;
}> = ({ makerInfo, onApply, onClose, cpLimit: userCpLimit }) => {
    const dispatch = useDispatch();
    const { id, name } = makerInfo;

    const applyLimit = async ({ currency, grossLimit }: LimitInputs) => {
        try {
            await post("setCLimit", {
                currency,
                grossLimit: ValueParse.limit(String(grossLimit)),
                counterpartyId: id,
            });

            dispatch(
                createNotification({
                    type: "success",
                    content: "Limit has been set",
                }),
            );
            onApply();
        } catch (e) {
            dispatch(
                createNotification({
                    type: "error",
                    content: String(e),
                }),
            );
        }
    };

    return (
        <Container>
            <OnboardingLimitEdit
                onSetLimits={applyLimit}
                onClose={onClose}
                title={`Set limit for ${name}`}
                text={
                    "The counterparty gross limit regulates your maximum open position with a particular counterparty.\n" +
                    "If you set different limits against each other an applied gross limit will be a smaller one."
                }
                defaultLimit={userCpLimit ?? {
                    currency: CURRENCY,
                    grossLimit: BigInt(1e6 * 1e8),
                }}
            />
        </Container>
    );
};
