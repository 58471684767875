import { post } from "api";

type CLimitParams = {
    counterpartyId: number;
    currency: string;
    grossLimit: number | bigint;
    maintenanceMargin?: number;
    restrictedTrading?: number;
    initialMargin?: number;
    takerMarkup?: number;
};

export const setCLimit = async (params: CLimitParams) => {
    await post("setCLimit", params);
};
