import { RefObject, useEffect } from "react";

export const useClickOutside = (callback: () => void, ...refs: RefObject<HTMLElement>[]) => {
    const listener = (e: MouseEvent | TouchEvent) => {
        if (
            e.target &&
            refs.every((ref) => {
                // activate if click is outside all existing refs
                return !ref.current || !ref.current.contains(e.target as Node);
            })
        ) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener("click", listener, { passive: true });
        document.addEventListener("touchstart", listener, { passive: true });

        return () => {
            document.removeEventListener("click", listener);
            document.removeEventListener("touchstart", listener);
        };
    });
};
