import { useTheme } from "styled-components";
import type { PermissionDotProps, PermissionsIconProps } from "./types";

const PermissionDot = ({ x, y, enabled, isActive }: PermissionDotProps) => {
    const { colors } = useTheme();
    const color = isActive ? colors.textActiveFG : colors.textBody1;
    const opacity = isActive ? 1 : 0.32;
    const xPos = enabled ? x : x + 0.5;
    const yPos = enabled ? y : y + 0.5;
    const sideSize = enabled ? 6 : 5;
    const rx = enabled ? 2 : 1.5;

    return (
        <rect
            x={xPos}
            y={yPos}
            width={sideSize}
            height={sideSize}
            rx={rx}
            opacity={opacity}
            {...(enabled ? { fill: color } : { stroke: color })}
        />
    );
};

export const PermissionsIcon = ({ permissions, isActive }: PermissionsIconProps) => {
    const { colors } = useTheme();
    const fill = isActive ? colors.textActiveFG : colors.textBody1;
    const fillOpacity = isActive ? 0.12 : 0.04;

    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="12" fill={fill} fillOpacity={fillOpacity} />
            <PermissionDot x={13} y={13} enabled={permissions.risks} isActive={isActive} />
            <PermissionDot x={21} y={13} enabled={permissions.settle} isActive={isActive} />
            <PermissionDot x={17} y={17} enabled={permissions.trading || permissions.subaccounts} isActive={isActive} />
            <PermissionDot x={13} y={21} enabled={permissions.users} isActive={isActive} />
            <PermissionDot x={21} y={21} enabled={permissions.api} isActive={isActive} />
        </svg>
    );
};
