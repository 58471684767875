import { post } from "api";
import { Notification } from "./index";

type RequestParams = {
    page: number;
    elementsOnPage: number;
    isRead?: boolean;
};

type ResponseParams = {
    currentPage: number;
    elementsOnPage: number;
    totalElements: number;
    data: Notification[];
};

export const getNotifications = async (params: RequestParams) => {
    const response = await post("notifications/getNotifications", params);

    return response as ResponseParams;
};
