import { EMPTY_ARRAY } from "const";
import { useMemo } from "react";
import useSWR from "swr";
import { displayError } from "utils";
import { PAYMENT_ACCOUNTS_URL, postDataFromResult } from "../api";
import { ApiAccountEntity } from "../entities/ApiAccountEntity";

type SWRType = string;
export const usePaymentAccounts = () => {
    const fetcher = (url: string) => postDataFromResult(url, {});
    const { data = [], isLoading } = useSWR<ApiAccountEntity[], any, SWRType>(PAYMENT_ACCOUNTS_URL, fetcher, {
        onError: displayError,
        fallbackData: EMPTY_ARRAY as ApiAccountEntity[],
    });

    return useMemo(
        () => ({
            paymentAccounts: data,
            isPaymentAccountsLoading: isLoading,
        }),
        [data, isLoading],
    );
};
