import { HStack, ShadowCard } from "@fm-frontend/uikit";
import { EmptySearch } from "@fm-frontend/uikit/src/components/common/EmptySearch";
import { useEffect, VFC } from "react";
import { TopBar } from "./TopBar";
import { useWizard } from "./wizard/useWizard";
import { useListItems } from "./hooks/useListItems";
import { Card } from "./Card";
import { ListItem } from "./types";
import { PlaceholderCard } from "./PlaceholderCard";
import { getListItemEmail } from "./utils";

type SubaccountsProps = {
    onItemSelect: (item: ListItem) => void;
}

export const Subaccounts: VFC<SubaccountsProps> = ({ onItemSelect }) => {
    const { wizard, createSubaccount } = useWizard();
    const { items, isEmpty, isLoading } = useListItems();

    useEffect(() => {
        if (!isLoading && isEmpty) {
            createSubaccount();
        }
    }, [isLoading, isEmpty]);

    if (isLoading) {
        return (
            <ShadowCard>
                {[...Array(3).keys()].map((key) => (
                    <PlaceholderCard key={key} />
                ))}
            </ShadowCard>
        );
    }

    return (
        <>
            {wizard}
            <TopBar createSubaccount={createSubaccount} />
            <div style={{ flex: 1, overflow: "auto" }}>
                <ShadowCard isDefault>
                    {items.length === 0 && (
                        <HStack padding={16}>
                            <EmptySearch />
                        </HStack>
                    )}
                    {items.map((item) => (
                        <Card key={getListItemEmail(item)} item={item} onClick={onItemSelect} />
                    ))}
                </ShadowCard>
            </div>
        </>
    );
};
