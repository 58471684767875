import { ClientId, HStack } from "@fm-frontend/uikit";
import { EmDash } from "const";
import { TradeHistory } from "feature/history/types";
import React from "react";

const DotsIcon = () => (
    <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.99707 2.00293C9.71972 2.00293 9.48199 1.90635 9.28388 1.71319C9.09072 1.51508 8.99414 1.27735 8.99414 1C8.99414 0.722647 9.09072 0.487392 9.28388 0.294235C9.48199 0.0961251 9.71972 -0.00292969 9.99707 -0.00292969C10.2744 -0.00292969 10.5097 0.0961251 10.7028 0.294235C10.9009 0.487392 11 0.722647 11 1C11 1.27735 10.9009 1.51508 10.7028 1.71319C10.5097 1.90635 10.2744 2.00293 9.99707 2.00293Z"
            fill="#243034"
            fillOpacity="0.32"
        />
        <path
            d="M5.90796 2.00293C5.63061 2.00293 5.39287 1.90635 5.19476 1.71319C5.00161 1.51508 4.90503 1.27735 4.90503 1C4.90503 0.722647 5.00161 0.487392 5.19476 0.294235C5.39287 0.0961251 5.63061 -0.00292969 5.90796 -0.00292969C6.18531 -0.00292969 6.42057 0.0961251 6.61372 0.294235C6.81183 0.487392 6.91089 0.722647 6.91089 1C6.91089 1.27735 6.81183 1.51508 6.61372 1.71319C6.42057 1.90635 6.18531 2.00293 5.90796 2.00293Z"
            fill="#243034"
            fillOpacity="0.32"
        />
        <path
            d="M1.81909 2.00293C1.54174 2.00293 1.30401 1.90635 1.1059 1.71319C0.912741 1.51508 0.816162 1.27735 0.816162 1C0.816162 0.722647 0.912741 0.487392 1.1059 0.294235C1.30401 0.0961251 1.54174 -0.00292969 1.81909 -0.00292969C2.09645 -0.00292969 2.3317 0.0961251 2.52486 0.294235C2.72297 0.487392 2.82202 0.722647 2.82202 1C2.82202 1.27735 2.72297 1.51508 2.52486 1.71319C2.3317 1.90635 2.09645 2.00293 1.81909 2.00293Z"
            fill="#243034"
            fillOpacity="0.32"
        />
    </svg>
);

type CpIdCellProps = Pick<TradeHistory, "cpId"> & {
    isExpandableRow?: boolean;
};
export const CpIdCell: React.FC<CpIdCellProps> = ({ cpId, isExpandableRow }) => {
    if (cpId === undefined) {
        return <>{EmDash}</>;
    }
    if (cpId === null || isExpandableRow) {
        return (
            <HStack justifyContent="end">
                <DotsIcon />
            </HStack>
        );
    }

    return (
        <HStack justifyContent="end">
            <ClientId id={cpId} tooltip={null} />
        </HStack>
    );
};
