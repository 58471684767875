import { onMobile } from "@fm-frontend/uikit/src/const";
import { selectors } from "style/theme";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { transition } from "utils";

export const LayoutContainer = styled.main<{
    isOverlayEnabled: boolean;
    primeBrokerSubaccountsView?: boolean;
}>`
    display: flex;
    flex-wrap: wrap;

    min-width: 1024px;

    ${onMobile} {
        min-width: auto;
    }

    ${({ isOverlayEnabled }) =>
        isOverlayEnabled &&
        css`
            height: 100vh;
            overflow: hidden;
        `}
    ${({ primeBrokerSubaccountsView, theme }) =>
        primeBrokerSubaccountsView &&
        css`
            background: ${theme.customGradients.subBg};
        `}
`;
export const MainContent = styled.div`
    flex-basis: 0;
    flex-grow: 999;

    min-width: 812px;

    ${onMobile} {
        min-width: auto;
    }
`;

const cssBool = (
    value: boolean | undefined,
    cssTrue: FlattenSimpleInterpolation,
    cssFalse: FlattenSimpleInterpolation,
) => (value ? cssTrue : cssFalse);
export const SlimContainer = styled.div<{ isExpanded?: boolean }>`
    background: ${(p) => p.theme.colors.mainBG};
    padding: 40px 15px 15px 15px;
    margin: 0 auto;
    width: 100%;
    ${(p) => transition(p.theme.transitions.default, "max-width")}
    ${(p) =>
        cssBool(
            p.isExpanded,
            css`
                max-width: 934px;
            `,
            css`
                min-width: 320px;
                max-width: 390px;
            `,
        )}
    & > subtitle {
        margin: 0 12px 20px 12px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.005em;
        color: ${selectors.textSubdued};
    }
`;

export const FormRow = styled.div`
    margin-top: 16px;

    label {
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: -0.005em;
        color: ${selectors.textSubdued};
        display: block;
        padding-bottom: 8px;
        font-weight: 600;
    }

    input {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.005em;
        height: 44px;
        width: 100%;
        padding: 0px 12px 0px 12px;
        border-radius: 8px;
        background: #f2f2f2;

        :hover {
            background: linear-gradient(0deg, #ebebeb, #ebebeb), #f2f2f2;
        }

        ::placeholder {
            color: #ccc;
        }
    }
`;

export const Hint = styled.div`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #ccc;
    padding-top: 4px;
`;

export const Text = styled.div`
    padding: 8px 0;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: ${selectors.textSubdued};

    b {
        font-weight: normal;
        color: ${selectors.textFG};
    }
    a {
        font-weight: 600;
        color: ${(p) => p.theme.colors.brand};
    }
    strong {
        font-weight: normal;
        color: ${selectors.textFG};
    }
`;

export const BottomLogin = styled.div`
    padding: 24px 12px 0 12px;
    font-size: 14px;
    letter-spacing: -0.005em;
    font-weight: 600;

    a {
        color: ${(p) => p.theme.colors.brand100};
        margin-right: 16px;
        font-weight: 600;

        :last-child {
            margin-right: 0;
        }
    }

    span {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.005em;
        color: ${selectors.textSubdued};
        display: block;
    }

    a + span {
        margin-top: 8px;
    }
`;
