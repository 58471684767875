import { Book } from "types";

const BID_LEVELS = 0;
const ASK_LEVELS = 1;

export const getBookLevels = ({ book }: { book: Book }) => {
    return {
        bidLevels: book[BID_LEVELS] ?? [],
        askLevels: book[ASK_LEVELS] ?? [],
    };
};
