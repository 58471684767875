import React, { createContext, FC, useCallback, useContext, useState } from "react";
import { FileError } from "react-dropzone";

export type UploadFileInfo = {
    name: string;
    size: number;
    errors: FileError[];
};

export type UploadingState = {
    active: boolean;
    percents: number;
};
type FileContextType = {
    acceptedFiles: File[];
    setAcceptedFiles: (files: File[]) => void;
    acceptedFilesInfo: UploadFileInfo[];
    setAcceptedFilesInfo: (infos: UploadFileInfo[]) => void;
    rejectedFilesInfo: UploadFileInfo[];
    setRejectedFilesInfo: (infos: UploadFileInfo[]) => void;
    externalError: string;
    setExternalError: (error: string) => void;
    resetFiles: () => void;
    uploading: UploadingState;
    setUploading: React.Dispatch<React.SetStateAction<UploadingState>>;
};

const FileContext = createContext<FileContextType | null>(null);

export const useFileContext = () => {
    const context = useContext(FileContext);

    if (!context) {
        throw new Error("useFileContext must be inside FileContextProvider");
    }

    return context;
};

export const FileContextProvider: FC = ({ children }) => {
    const [uploadingState, setUploadingState] = useState<UploadingState>({
        active: false,
        percents: 0,
    });
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
    const [acceptedFilesInfo, setAcceptedFilesInfo] = useState<UploadFileInfo[]>([]);
    const [rejectedFilesInfo, setRejectedFilesInfo] = useState<UploadFileInfo[]>([]);
    const [externalError, setExternalError] = useState<string>("");

    const resetFiles = useCallback(() => {
        setAcceptedFiles([]);
        setAcceptedFilesInfo([]);
        setRejectedFilesInfo([]);
        setExternalError("");
        setUploadingState({
            active: false,
            percents: 0,
        });
    }, []);

    return (
        <FileContext.Provider
            value={{
                acceptedFiles,
                setAcceptedFiles,
                acceptedFilesInfo,
                setAcceptedFilesInfo,
                rejectedFilesInfo,
                setRejectedFilesInfo,
                externalError,
                setExternalError,
                resetFiles,
                uploading: uploadingState,
                setUploading: setUploadingState,
            }}
        >
            {children}
        </FileContext.Provider>
    );
};
