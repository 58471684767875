import { MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { CounterpartiesOptionEssence } from "components/CounterpartiesOptionEssence";
import { MultipleDropdownGroupedSheet } from "components/customMultipleDropdowns/MultipleDropdownGroupedSheet";
import { useRfqProvidersAndInstruments } from "feature/rfq/apiHooks/useRfqProvidersAndInstruments";
import { useMemo } from "react";
import { Control, Controller, Path } from "react-hook-form";
import { ProvidersTriggerEssence } from "./ProvidersTriggerEssence";

export const ProvidersDropdown = ({
    error,
    providersClientIds,
    control,
    name,
}: {
    error?: string;
    providersClientIds: number[];
    control: Control<any>;
    name: Path<any>;
}) => {
    const { rfqProviders } = useRfqProvidersAndInstruments();
    const providersDetails = useMemo(
        () =>
            providersClientIds.reduce<Record<string, string>>((acc, cpId) => {
                acc[cpId] = rfqProviders.get(cpId)?.name ?? "";
                return acc;
            }, {}),
        [providersClientIds],
    );

    const options = useMemo(
        () =>
            Object.entries(providersDetails)
                .map(([cpId, cpName]) => ({
                    text: cpName,
                    value: Number(cpId),
                }))
                .sort((a, b) => a.text.localeCompare(b.text)),
        [providersDetails],
    );

    return (
        <Controller
            control={control}
            render={({ field }) => (
                <MultipleDropdown
                    values={field.value}
                    onChange={field.onChange}
                    renderTrigger={(trigger) => (
                        <MultipleDropdown.Trigger {...trigger} size="medium" variant="simple">
                            <ProvidersTriggerEssence
                                {...trigger}
                                size="medium"
                                selectedProvidersIds={field.value}
                            />
                        </MultipleDropdown.Trigger>
                    )}
                    options={options}
                    caption="Providers"
                    placeholder="Select"
                    align="end"
                    fullWidth
                    error={error}
                    hideErrorHint
                >
                    <MultipleDropdownGroupedSheet<number>
                        values={field.value}
                        onChange={field.onChange}
                        Dropdown={MultipleDropdown}
                        allOptionText="All providers"
                        options={options}
                        OptionEssence={CounterpartiesOptionEssence}
                        size="medium"
                    />
                </MultipleDropdown>
            )}
            name={name}
        />
    );
};
