import { HStack, PSmall, PSmallBold, VStack } from "@fm-frontend/uikit";
import { ROUTES } from "feature/app/router";
import { useHistory } from "react-router";
import styled, { useTheme } from "styled-components";

const WalletIcon = () => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_30603_165742)">
            <g filter="url(#filter0_di_30603_165742)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M35.996 18.4798C35.9711 23.2889 35.7908 25.9487 34.4832 27.9366C33.8266 28.935 32.9828 29.7921 32.0001 30.4592C29.7301 32 26.5701 32 20.25 32H15.75C9.42992 32 6.26988 32 3.99987 30.4592C3.01716 29.7921 2.1734 28.935 1.51677 27.9366C0 25.6306 0 22.4204 0 16C0 9.5796 0 6.3694 1.51677 4.06336C2.1734 3.06505 3.01716 2.2079 3.99987 1.54085C6.26988 0 9.42992 0 15.75 0H20.25C26.5701 0 29.7301 0 32.0001 1.54085C32.9828 2.2079 33.8266 3.06505 34.4832 4.06336C35.7908 6.0513 35.9711 8.71115 35.996 13.5202C35.9987 13.5846 36 13.6493 36 13.7143V18.2857C36 18.3507 35.9987 18.4154 35.996 18.4798ZM15.75 4.57143H20.25C23.5037 4.57143 25.6052 4.57696 27.1884 4.74056C28.6964 4.8964 29.2277 5.15697 29.5001 5.34185C29.9914 5.67538 30.4133 6.10395 30.7416 6.60311C30.9236 6.8798 31.1801 7.41955 31.3335 8.95145C31.3398 9.01443 31.3459 9.07822 31.3517 9.14286H24.75C22.2647 9.14286 20.25 11.1896 20.25 13.7143V18.2857C20.25 20.8104 22.2647 22.8571 24.75 22.8571H31.3517C31.3459 22.9218 31.3398 22.9856 31.3335 23.0486C31.1801 24.5805 30.9236 25.1202 30.7416 25.3969C30.4133 25.896 29.9914 26.3246 29.5001 26.6581C29.2277 26.843 28.6964 27.1036 27.1884 27.2594C25.6052 27.423 23.5037 27.4286 20.25 27.4286H15.75C12.4963 27.4286 10.3948 27.423 8.81158 27.2594C7.30362 27.1036 6.7723 26.843 6.49993 26.6581C6.00858 26.3246 5.5867 25.896 5.25839 25.3969C5.07639 25.1202 4.81989 24.5805 4.66649 23.0486C4.50544 21.4403 4.5 19.3053 4.5 16C4.5 12.6947 4.50544 10.5597 4.66649 8.95145C4.81989 7.41955 5.07639 6.8798 5.25839 6.60311C5.5867 6.10395 6.00858 5.67538 6.49993 5.34185C6.7723 5.15697 7.30362 4.8964 8.81158 4.74056C10.3948 4.57696 12.4963 4.57143 15.75 4.57143ZM31.5 16C31.5 16.8328 31.4997 17.5912 31.4966 18.2857H24.75V13.7143H31.4966C31.4997 14.4088 31.5 15.1672 31.5 16Z"
                    fill="white"
                />
            </g>
        </g>
        <defs>
            <filter
                id="filter0_di_30603_165742"
                x="-16"
                y="-12"
                width="68"
                height="64"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="8" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.140666 0 0 0 0 0.189687 0 0 0 0 0.205006 0 0 0 0.08 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30603_165742" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30603_165742" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect2_innerShadow_30603_165742" />
                <feOffset />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.140666 0 0 0 0 0.189687 0 0 0 0 0.205006 0 0 0 0.12 0" />
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_30603_165742" />
            </filter>
            <clipPath id="clip0_30603_165742">
                <rect width="36" height="36" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

const BannerWrapper = styled.div`
    cursor: pointer;
    border: 1px solid ${(p) => p.theme.colors.ui8};
    margin: 16px 12px 0 12px;
    border-radius: 8px;
    overflow: hidden;
`;
const Banner = styled(HStack)`
    height: 74px;
    padding: 12px;
    gap: 35px;
    background: linear-gradient(66deg, transparent -11.42%, ${(p) => p.theme.colors.brand52} 130.18%);
`;
const BannerContent = styled(VStack)`
    flex: 1;
`;

export const AddressBookBanner = () => {
    const { colors } = useTheme();
    const history = useHistory();

    const handleClick = () => {
        history.push(`${ROUTES.addresses}/your/crypto`);
    };

    return (
        <BannerWrapper onClick={handleClick}>
            <Banner alignItems="center">
                <BannerContent>
                    <PSmallBold>Address book</PSmallBold>
                    <PSmall color={colors.ui72}>Provide payment details for your partners</PSmall>
                </BannerContent>
                <WalletIcon />
            </Banner>
        </BannerWrapper>
    );
};
