import { useCallback, useMemo } from "react";
import { CSSProperties } from "styled-components";
import { IconCheckbox, IconHelp } from "../../components/icons";
import { Tooltip } from "../../components/Tooltip";
import { Fieldset, InputContainer, Spacer } from "../../style";

type Props = {
    value: boolean;
    id?: string;
    label?: string;
    disabled?: boolean;
    errorValue?: string;
    helpTooltip?: string;
    onChange?: (value: boolean) => void;
    style?: CSSProperties;
};

export const FormCheckbox = ({ value, label = "", errorValue, onChange, helpTooltip, disabled, ...rest }: Props) => {
    const tooltip = useMemo(() => {
        if (helpTooltip)
            return (
                <>
                    <Tooltip text={helpTooltip}>
                        <IconHelp />
                    </Tooltip>
                </>
            );
        return undefined;
    }, [helpTooltip]);
    const onClick = useCallback(() => {
        if (!disabled) {
            onChange?.(!value);
        }
    }, [disabled, onChange, value]);
    return (
        <Fieldset {...rest}>
            <InputContainer checkboxValue={value} onClick={onClick} disabled={disabled}>
                <label>
                    {label}
                    {tooltip}
                </label>
                <Spacer />
                <IconCheckbox checked={value} disabled={disabled} />
            </InputContainer>
            {errorValue && <div>{errorValue}</div>}
        </Fieldset>
    );
};

export default FormCheckbox;
