import { Owner } from "../Owner";
import { User } from "../User";
import { Container, Title } from "./styled";
import type { UsersListProps } from "./types";

export const UsersList = ({ users, type, editUser }: UsersListProps) => {
    if (!users.length) {
        return null;
    }

    const isActive = type === "active";
    const title = isActive ? `Active users ${users.length}` : `Pending ${users.length}`;

    return (
        <Container>
            <Title>{title}</Title>
            {users.map(({ isOwner, ...userProps }) =>
                isOwner ? (
                    <Owner key={userProps.email} name={userProps.name} email={userProps.email} />
                ) : (
                    <User key={userProps.email} isActive={isActive} editUser={editUser} {...userProps} />
                ),
            )}
        </Container>
    );
};
