import { ELLIPSIS_CSS, TextSmall } from "@fm-frontend/uikit";
import { BulletTypeLiquidationIcon, BulletTypeLowIcon, BulletTypeTooLowIcon } from "components/icons";
import React, { useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { ClientType, CounterpartyLimit } from "types";
import { CounterpartyStatusType, isSubaccountCp } from "../../utils";
import { Equity } from "../equity/Equity";
import { MakerMarginLinesIndicator } from "../lineIndicator";
import { Indicator, IndicatorContainer } from "../styled";
import * as Styled from "./styled";
import {
    getInitialMarginLimitIndicatorData,
    getMaintenanceMarginLimitIndicatorData,
    getMakerGrossLimitIndicator,
    getRestrictedTradingLimitIndicatorData,
} from "./utils";

const Container = styled(Styled.MarginBanner)<{ $background: string }>`
    background-color: ${(p) => p.$background};
    box-shadow: 0 0 0 1px ${(p) => p.theme.colors.ui12} inset, 0 -3px 0 0 ${(p) => p.theme.colors.ui8} inset,
        0 2px 4px 0 ${(p) => p.theme.colors.ui4};
    padding: 16px 12px;
`;

const LimitTitle = styled(TextSmall)`
    ${ELLIPSIS_CSS};
`;

const LimitValue = styled(TextSmall)`
    white-space: nowrap;
`;


export const MakerRiskOverview: React.FC<{
    counterpartyName?: string;
    counterpartyType: ClientType;
    limit: CounterpartyLimit | undefined;
    status?: CounterpartyStatusType;
    userHasMakerRole: boolean | undefined;
}> = (props) => {
    const theme = useTheme();
    const { counterpartyName = "", counterpartyType, limit, status, userHasMakerRole } = props;
    const isSubaccount = isSubaccountCp(counterpartyType);
    const makerGrossLimitIndicator = useMemo(
        () => getMakerGrossLimitIndicator(limit, counterpartyName, userHasMakerRole, isSubaccount),
        [limit, counterpartyName, userHasMakerRole, isSubaccount],
    );
    const maintenanceMarginLimitIndicator = useMemo(
        () => getMaintenanceMarginLimitIndicatorData(limit, userHasMakerRole),
        [limit, userHasMakerRole],
    );
    const restrictedTradingLimitIndicator = useMemo(
        () => getRestrictedTradingLimitIndicatorData(limit, userHasMakerRole),
        [limit, userHasMakerRole],
    );
    const initialMarginLimitIndicator = useMemo(
        () => getInitialMarginLimitIndicatorData(limit, userHasMakerRole),
        [limit, userHasMakerRole],
    );

    const isMarginRequirementsEnabled = Boolean(
        maintenanceMarginLimitIndicator?.copyableValue ||
            restrictedTradingLimitIndicator?.copyableValue ||
            initialMarginLimitIndicator?.copyableValue,
    );
    const isAwaitingCp = status === CounterpartyStatusType.AWAITING_CP;

    if (isAwaitingCp) {
        return (
            <Container $background={theme.colors.process4}>
                <Styled.MarginBannerHeader>
                    <TextSmall>Awaiting CP’s requirements</TextSmall>
                </Styled.MarginBannerHeader>
                <TextSmall color={theme.colors.ui52}>
                    Counterparty is adjusting risks settings. When it’s done all requirements will be listed here
                </TextSmall>
            </Container>
        );
    }

    return (
        <Container $background="transparent">
            {isMarginRequirementsEnabled && limit && (
                <Equity limit={limit} cpType={counterpartyType} cpName={counterpartyName} />
            )}
            {isMarginRequirementsEnabled && limit && (
                <MakerMarginLinesIndicator limit={limit} cpType={counterpartyType} />
            )}
            <IndicatorContainer>
                <TextSmall color={theme.colors.ui72}>{makerGrossLimitIndicator?.title}</TextSmall>
                <Indicator>
                    <TextSmall>{makerGrossLimitIndicator?.value}</TextSmall>
                </Indicator>
            </IndicatorContainer>
            {isMarginRequirementsEnabled && !userHasMakerRole && !isSubaccount && (
                <>
                    <IndicatorContainer>
                        <LimitTitle>
                            <BulletTypeLowIcon /> {initialMarginLimitIndicator?.title}
                        </LimitTitle>
                        <Indicator>
                            <TextSmall color={theme.colors.ui52}>{initialMarginLimitIndicator?.subValue}</TextSmall>
                            <LimitValue>{initialMarginLimitIndicator?.value}</LimitValue>
                        </Indicator>
                    </IndicatorContainer>
                    <IndicatorContainer>
                        <LimitTitle>
                            <BulletTypeTooLowIcon /> {restrictedTradingLimitIndicator?.title}
                        </LimitTitle>
                        <Indicator>
                            <TextSmall color={theme.colors.ui52}>{restrictedTradingLimitIndicator?.subValue}</TextSmall>
                            <LimitValue>{restrictedTradingLimitIndicator?.value}</LimitValue>
                        </Indicator>
                    </IndicatorContainer>
                    <IndicatorContainer>
                        <LimitTitle>
                            <BulletTypeLiquidationIcon /> {maintenanceMarginLimitIndicator?.title}
                        </LimitTitle>
                        <Indicator>
                            <TextSmall color={theme.colors.ui52}>{maintenanceMarginLimitIndicator?.subValue}</TextSmall>
                            <LimitValue>{maintenanceMarginLimitIndicator?.value}</LimitValue>
                        </Indicator>
                    </IndicatorContainer>
                </>
            )}
        </Container>
    );
};
