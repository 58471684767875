import { useInstruments } from "hooks";
import { useCounterparties } from "hooks/useCounterparties";
import { useMemo } from "react";
import { CryptoAddress, UseAddressType } from "../types";
import { CRYPTO_CURRENCY_TYPES, getAccountStatus } from "../utils";
import { useSharedAccountsRevisions } from "./useSharedAccountsRevisions";
import { useSharedPaymentAccounts } from "./useSharedPaymentAccounts";

export const useSharedCryptoAddresses = (): UseAddressType<CryptoAddress> => {
    const { sharedAccountsRevisions, isRevisionsLoading } = useSharedAccountsRevisions();
    const { sharedAccounts, isCpDataLoading } = useSharedPaymentAccounts();
    const { counterparties, cpIds, isLoading: isCounterpartiesLoading } = useCounterparties();
    const { currencies, networks, isLoading: isInstrumentsLoading } = useInstruments();

    const isLoading = isCpDataLoading || isCounterpartiesLoading || isRevisionsLoading || isInstrumentsLoading;

    const data = useMemo(() => {
        return sharedAccounts
            .reduce((acc, curr) => {
                const cpId = curr.ownerId;

                const isValidCp = cpId !== undefined && cpIds.includes(cpId);
                const isValidCurrencyType = CRYPTO_CURRENCY_TYPES.includes(curr.currencyType);

                if (!isValidCp || !isValidCurrencyType) {
                    return acc;
                }

                const accountStatus = getAccountStatus(
                    "shared",
                    curr.id !== undefined ? sharedAccountsRevisions[curr.id] : [],
                );

                const cp =
                    cpId !== undefined
                        ? {
                              id: cpId,
                              name: counterparties[cpId],
                          }
                        : null;
                const currency = Object.values(currencies).find(({ id }) => curr.currencyId === id);
                const network = networks.find(({ id }) => id === curr.networkId) ?? null;

                acc.push({
                    accountId: curr.id,
                    rule: {
                        id: undefined,
                        cp: cp,
                        accountId: curr.id,
                    },
                    currency: currency
                        ? {
                              id: currency.id,
                              name: currency.name,
                          }
                        : null,
                    network: network,
                    wallet: curr.data.wallet,
                    privateNote: curr.notes,
                    memoOrTag: curr.data.memoOrTag,
                    revisions: curr.id !== undefined ? sharedAccountsRevisions[curr.id] : undefined,
                    status: accountStatus,
                } as CryptoAddress);

                return acc;
            }, [] as CryptoAddress[])
            .sort((a, b) => {
                if (a?.rule?.cp?.name === undefined) {
                    return -1;
                }
                if (b?.rule?.cp?.name === undefined) {
                    return 1;
                }

                return a.rule.cp.name.localeCompare(b.rule.cp.name);
            });
    }, [sharedAccounts, sharedAccountsRevisions, currencies, networks, counterparties, cpIds]);

    return {
        data,
        isLoading,
    };
};
