import { P, TextSmall } from "@fm-frontend/uikit";
import styled, { css } from "styled-components";
import { CounterpartyStatusType } from "../../utils";

const highlightedCss = css`
    font-weight: 600;
`;

const toneDownCss = css`
    color: ${(p) => p.theme.colors.ui32};
`;

export const Indication = styled(TextSmall)<{ highlighted?: boolean; toneDown?: boolean }>`
    color: ${(p) => p.theme.colors.ui100};
    ${({ highlighted }) => highlighted && highlightedCss}
    ${({ toneDown }) => toneDown && toneDownCss}
`;

export const MainIndication = styled(P)<{ highlighted?: boolean; toneDown?: boolean }>`
    color: ${(p) => p.theme.colors.ui100};
    ${({ highlighted }) => highlighted && highlightedCss}
    ${({ toneDown }) => toneDown && toneDownCss}
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-width: max-content;
    width: 100%;
`;

export const Title = styled.p`
    margin: 0;

    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.005em;
    color: ${(p) => p.theme.colors.ui72};
`;

export const Badge = styled.div<{
    status?: CounterpartyStatusType;
    subStatus?: CounterpartyStatusType;
}>`
    display: flex;
    border-radius: 4px;
    padding: 0 4px;
    align-items: center;
    min-width: 36px;
    justify-content: center;
    gap: 3px;

    ${TextSmall} {
        font-weight: 600;
        margin: 0;
    }

    ${(p) => {
        switch (p.subStatus) {
            case CounterpartyStatusType.NORMAL:
                return css`
                    ${TextSmall} {
                        color: ${p.theme.colors.positive100};
                    }

                    background-color: ${p.theme.colors.positive8};
                `;
            case CounterpartyStatusType.MARGIN_CALL:
                return css`
                    ${TextSmall} {
                        color: ${p.theme.colors.attention100};
                    }

                    background-color: ${p.theme.colors.attention8};
                `;
            case CounterpartyStatusType.LOW_GROSS_FREE:
            case CounterpartyStatusType.RESTRICTED_TRADING:
            case CounterpartyStatusType.LIQUIDATION:
                return css`
                    ${TextSmall} {
                        color: ${p.theme.colors.negative80};
                    }

                    background-color: ${p.theme.colors.negative4};
                `;
            default:
                return css`
                    ${TextSmall} {
                        font-weight: 400;
                        color: ${p.theme.colors.ui72};
                    }

                    background-color: transparent;
                    border: 1px solid ${p.theme.colors.ui8};
                `;
        }
    }}
}

${(p) => {
    switch (p.status) {
        case CounterpartyStatusType.STOPPED:
        case CounterpartyStatusType.STOPPED_BY_CP:
        case CounterpartyStatusType.AWAITING_CP:
            return css`
                ${TextSmall} {
                    color: ${p.theme.colors.ui72};
                }

                background-color: ${p.theme.colors.ui8};
            `;
    }
}}
`;

export const SubContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 4px;
    align-items: center;
`;
