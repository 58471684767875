import styled from "styled-components";
import { SmallLoader } from "../../animations";

export const Loader = styled(SmallLoader)`
    position: absolute;
    left: calc(50% - 8px);
    top: calc(50% - 8px);
`;

export const Invisible = styled.div`
    opacity: 0;
`;

export const Content = styled.div`
    position: relative;
`;
