import { confirmResetPassword as awsConfirmResetPassword } from "aws-amplify/auth";

type ConfirmResetPasswordParams = {
    username: string;
    newPassword: string;
    confirmationCode: string;
};
export const confirmResetPassword = async (params: ConfirmResetPasswordParams) => {
    await awsConfirmResetPassword(params);
};
