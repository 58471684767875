import { TextSmall } from "@fm-frontend/uikit";
import { onMobile } from "const";
import styled from "styled-components";

export const Status = styled.div<{ color?: string }>`
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;

    height: 24px;

    background-color: ${(p) => p.color ?? p.theme.colors.ui4};
    border-radius: 12px;
`;

export const Body = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 12px 0;

    ${onMobile} {
        margin-top: 5px;
        margin-bottom: 2px;
    }
`;

export const Property = styled.div`
    display: flex;
    width: 50%;

    ${TextSmall} {
        width: 88px;
        min-width: 88px;
    }

    ${onMobile} {
        width: 100%;

        ${TextSmall} {
            min-width: 110px;
        }
    }
`;
