import { BrokerViewType, ClientType, DealSide, ORDER_TYPES, PreparedDeal } from "types";
import { ExportColumnType } from "../types";
import {
    deltaCellFormatConfig,
    priceCellFormatConfig,
    sizeCellFormatConfig,
    volumeCellFormatConfig,
} from "./cells";
import { ORDER_TYPE_TITLES, TRADE_TYPE_TITLES } from "./cells/OrderTypeCell";

type Data = PreparedDeal & { counterpartyName: string };

const generalColumns: ExportColumnType<Data>[] = [
    {
        Header: "Order ID",
        accessor: (preparedDeal) => preparedDeal.orderId,
    },
    {
        Header: "Order type",
        accessor: (preparedDeal) => ORDER_TYPE_TITLES[ORDER_TYPES[preparedDeal.orderType]!] ?? "",
    },
    {
        Header: "Client ID",
        accessor: (preparedDeal) => preparedDeal.counterpartyId,
    },
    {
        Header: "Client name",
        accessor: (preparedDeal) => preparedDeal.counterpartyName,
    },
    {
        Header: "Instrument",
        accessor: (preparedDeal) => preparedDeal.instrument,
    },
    {
        Header: "Side",
        accessor: (preparedDeal) => (preparedDeal.side === DealSide.Buy ? "Buy" : "Sell"),
    },
    {
        Header: "Price",
        accessor: (preparedDeal) => preparedDeal.price,
        format: priceCellFormatConfig,
    },
    {
        Header: "Size",
        accessor: (preparedDeal) => preparedDeal.size,
        format: sizeCellFormatConfig,
    },
    {
        Header: "Volume",
        accessor: (preparedDeal) => preparedDeal.volume,
        format: volumeCellFormatConfig,
    },
];

const tradeIdColumn: ExportColumnType<Data> = {
    Header: "Trade ID",
    accessor: (preparedDeal) => preparedDeal.dealId,
};
const deltaColumn: ExportColumnType<Data> = {
    Header: "Delta",
    accessor: (preparedDeal) => preparedDeal.delta,
    format: deltaCellFormatConfig,
};
const linkedTradeColumn: ExportColumnType<Data> = {
    Header: "Linked trade",
    accessor: (preparedDeal) => preparedDeal.linkedDealId,
};
const dateColumn: ExportColumnType<Data> = {
    Header: "Date",
    accessor: (preparedDeal) => preparedDeal.dealMoment,
    format: { type: "timems", withTime: true },
};
const clientOrderIdColumn: ExportColumnType<Data> = {
    Header: "Client order ID",
    accessor: (preparedDeal) => preparedDeal.clientOrderId,
};
const tradeTypeColumn: ExportColumnType<Data> = {
    Header: "Trade type",
    accessor: (preparedDeal) => TRADE_TYPE_TITLES[preparedDeal.tradeType],
};

const pbPartColumns = [
    deltaColumn,
    tradeIdColumn,
    linkedTradeColumn,
    dateColumn,
    clientOrderIdColumn,
];
const makerPartColumns = [deltaColumn, tradeIdColumn, dateColumn, clientOrderIdColumn];
const takerPartColumns = [tradeIdColumn, dateColumn, clientOrderIdColumn];

export const makeExportColumns = (config: {
    userType: ClientType | undefined;
    primeBrokerViewType: BrokerViewType;
}) => {
    let columns: ExportColumnType<Data>[] = [...generalColumns];

    if (config.userType === "primeBroker") {
        columns = [...columns, ...pbPartColumns];

        if (config.primeBrokerViewType === "subaccounts") {
            columns = [...columns, tradeTypeColumn];
        }
    }
    if (config.userType === "maker" || config.userType === "subaccountMaker") {
        columns = [...columns, ...makerPartColumns];
    }
    if (config.userType === "taker") {
        columns = [...columns, ...takerPartColumns];
    }
    if (config.userType === "subaccountTaker") {
        columns = [...columns, ...takerPartColumns, tradeTypeColumn];
    }

    return columns;
};
