import { BasicButton, Modal } from "@fm-frontend/uikit";
import { FileContextProvider } from "@fm-frontend/uikit/src/components/dropZone/FileContext";
import { useFormShouldCloseConfirm, useModalControl } from "@fm-frontend/utils";
import { FC, useCallback } from "react";
import styled from "styled-components";
import { FIXED_MODAL_STYLE } from "const/modal";
import { BULK_EDIT_CONTROL_MODAL_KEY } from "feature/assetsControl/utils";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";

const StyledBasicButton = styled(BasicButton)`
    margin-right: 6px;
`;

type BulkEditButtonProps = {
    loading?: boolean;
    disabled: boolean;
    renderModal: (cancel: () => void) => JSX.Element;
    onClose: () => void;
};

export const BulkEditButton: FC<BulkEditButtonProps> = ({ loading = false, disabled, renderModal, onClose }) => {
    const { isModalOpen, openModal, closeModal } = useModalControl(false);

    const handleModalClose = useCallback(() => {
        onClose();
        closeModal();
    }, [onClose, closeModal]);

    const shouldConfirmClose = useFormShouldCloseConfirm(BULK_EDIT_CONTROL_MODAL_KEY);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(
        shouldConfirmClose,
        handleModalClose,
    );

    return (
        <>
            <StyledBasicButton size="small" onClick={openModal} disabled={disabled} loading={loading}>
                Bulk edit...
            </StyledBasicButton>
            <Modal isOpen={isModalOpen} onClose={closeModalWithConfirm} style={FIXED_MODAL_STYLE}>
                <FileContextProvider>
                    {renderModal(closeModalWithConfirm)}
                </FileContextProvider>
            </Modal>
        </>
    );
};
