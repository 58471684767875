import { HStack, PSmall, SmallLoader } from "@fm-frontend/uikit";
import styled from "styled-components";

const Layout = styled(HStack)`
    align-items: center;
    justify-content: center;
    gap: 6px;
    height: 40px;
`;

export const WaitingForQuotes = () => (
    <Layout>
        <SmallLoader />
        <PSmall>Waiting for quotes ...</PSmall>
    </Layout>
);
