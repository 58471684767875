import React from "react";
import { useDispatch } from "react-redux";
import { createNotification } from "../feature/app";
import { DummyButton } from "../feature/form/Buttons";

export const CopyToClipboard: React.FC<{
    value?: string | number | bigint;
    title?: string;
    stopPropagation?: boolean;
    className?: string;
}> = ({ value, title, stopPropagation = false, children, ...props }) => {
    const dispatch = useDispatch();
    return (
        <DummyButton
            {...props}
            type="button"
            disabled={!value}
            onClick={(e) => {
                e.preventDefault();
                if (stopPropagation) {
                    e.stopPropagation();
                }
                if (!value) return;
                navigator.clipboard.writeText(String(value));
                dispatch(
                    createNotification({
                        type: "success",
                        content: title
                            ? `${title} was successfully copied to clipboard`
                            : `Value ${value} was successfully copied to clipboard`,
                    }),
                );
            }}
        >
            {children}
        </DummyButton>
    );
};
