import { FormatConfig } from "../../../../utils/format";

export const FMT_LIMIT_CONFIG: FormatConfig = {
    significantDigits: 8,
    type: "limit",
    showZero: true,
    unitPosition: "pre",
    minSignificantDigits: 2,
};
export const CURRENCY = "USD";
