import React from "react";
import styled from "styled-components";
import { H2 as Title, TextSmall } from "../typography";

interface HeaderTitleProps {
    title: string | React.ReactElement;
    label?: string;
    Icon?: React.ReactNode;
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-right: auto;

    img {
        border-radius: 50%;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const Label = styled(TextSmall)`
    color: ${(p) => p.theme.colors.ui52};
`;

export const HeaderTitle: React.VFC<HeaderTitleProps> = (props) => {
    const { Icon, title, label } = props;

    const iconContent = Icon ?? null;
    const labelContent = label ? <Label>{label}</Label> : null;
    const titleContent = typeof title === "string" ? <Title>{title}</Title> : title;

    return (
        <Container>
            {iconContent}
            <TitleContainer>
                {titleContent}
                {labelContent}
            </TitleContainer>
        </Container>
    );
};
