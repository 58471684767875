import { signUp as awsSignUp } from "aws-amplify/auth";
import { InviteToken } from "types";
import { decodeInviteJwtToken } from "utils/decodeInviteJwtToken";

type SignUpParams = {
    username: string;
    password: string;
    invite: string;
};

export const CUSTOM_SIGNUP_ERROR_CODES = {
    InvalidInvite: "invalid_invite",
};

export const CUSTOM_SIGNUP_ERROR_MESSAGES = {
    InvalidInvite: "Invite is invalid",
};

export const signUp = async (signUpParams: SignUpParams) => {
    const decodedInviteToken: InviteToken | undefined = decodeInviteJwtToken(signUpParams.invite);

    if (!decodedInviteToken) {
        const error = new Error(CUSTOM_SIGNUP_ERROR_MESSAGES.InvalidInvite);
        error.name = CUSTOM_SIGNUP_ERROR_CODES.InvalidInvite;

        throw error;
    }

    await awsSignUp({
        username: decodedInviteToken.email,
        password: signUpParams.password,
        options: {
            userAttributes: {
                email: decodedInviteToken.email,
                "custom:inviteId": decodedInviteToken.id,
                "custom:fm_username": signUpParams.username,
            },
            clientMetadata: {
                invite: signUpParams.invite,
            },
            autoSignIn: true,
        },
    });
};
