import { useSelector } from "hooks/useSelector";
import { Card, CenteredContent } from "pages/settings/styled";
import { EventSettingsRow } from "../EventSettingsRow";
import { InteractionSetting } from "../InteractionSetting";
import { Description, EventsSettingsTable, InteractionsSettings, Settings, Title } from "./styled";

export const NotificationsSettings = () => {
    const {
        systemInbox,
        systemEmail,
        settlementsInbox,
        settlementsEmail,
        riskManagementInbox,
        riskManagementEmail,
        showRealTimeNotification,
        enableSound,
    } = useSelector((state) => state.settings);

    return (
        <CenteredContent>
            <Card>
                <Title>Notifications</Title>
                <Description>Specify notification events and channels</Description>
                <Settings>
                    <EventsSettingsTable>
                        <colgroup>
                            <col />
                            <col className="col-checkbox" />
                            <col className="col-checkbox" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>EVENTS</th>
                                <th>Inbox</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <EventSettingsRow
                                settingId="riskManagement"
                                isInboxSettingOn={riskManagementInbox}
                                isEmailSettingOn={riskManagementEmail}
                                title="Risk Management"
                            />
                            <EventSettingsRow
                                settingId="settlements"
                                isInboxSettingOn={settlementsInbox}
                                isEmailSettingOn={settlementsEmail}
                                title="Settlements"
                            />
                            <EventSettingsRow
                                settingId="system"
                                isInboxSettingOn={systemInbox}
                                isEmailSettingOn={systemEmail}
                                isEmailSettingDisabled
                                title="System"
                            />
                        </tbody>
                    </EventsSettingsTable>
                    <InteractionsSettings>
                        <div>INTERACTION</div>
                        <InteractionSetting
                            settingId="showRealTimeNotification"
                            value={showRealTimeNotification}
                            title="Show real-time notifications"
                        />
                        <InteractionSetting settingId="enableSound" value={enableSound} title="Enable sound" />
                    </InteractionsSettings>
                </Settings>
            </Card>
        </CenteredContent>
    );
};
