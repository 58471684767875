import { PSmall } from "@fm-frontend/uikit";
import { CounterpartyStatus, CounterpartyStatusType } from "feature/counterparties/utils";
import styled, { css } from "styled-components";

const Badge = styled(PSmall)<{ $cpStatus: CounterpartyStatusType }>`
    display: flex;
    align-items: center;
    align-self: center;
    cursor: default;
    padding: 4px 8px;
    gap: 4px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border: 1px solid transparent;

    ${(p) => {
        switch (p.$cpStatus) {
            case CounterpartyStatusType.NORMAL:
                return css`
                    color: ${p.theme.colors.positive100};
                    background-color: ${p.theme.colors.positive8};
                    border-color: ${p.theme.colors.positive16};
                `;
            case CounterpartyStatusType.AWAITING_CP:
                return css`
                    color: ${p.theme.colors.process100};
                    background-color: ${p.theme.colors.process8};
                    border-color: ${p.theme.colors.process16};
                `;
            case CounterpartyStatusType.MARGIN_CALL:
                return css`
                    color: ${p.theme.colors.attention100};
                    background-color: ${p.theme.colors.attention8};
                    border-color: ${p.theme.colors.attention16};
                `;
            case CounterpartyStatusType.STOPPED:
            case CounterpartyStatusType.STOPPED_BY_CP:
                return css`
                    color: ${p.theme.colors.ui72};
                    background-color: ${p.theme.colors.ui8};
                    border-color: ${p.theme.colors.ui20};
                `;
            case CounterpartyStatusType.LOW_GROSS_FREE:
            case CounterpartyStatusType.RESTRICTED_TRADING:
            case CounterpartyStatusType.LIQUIDATION:
                return css`
                    color: ${p.theme.colors.negative80};
                    background-color: ${p.theme.colors.negative4};
                    border-color: ${p.theme.colors.negative16};
                `;
            default:
                return null;
        }
    }}
`;

export const CpStatusBadge = ({ cpStatus }: { cpStatus: CounterpartyStatusType }) => (
    <Badge $cpStatus={cpStatus}>{cpStatus ? CounterpartyStatus[cpStatus].title : ""}</Badge>
);
