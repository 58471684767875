import styled, { css } from "styled-components";
import { selectors } from "../../styles/theme";

// Large
const largePrimaryCss = css`
    color: ${(p) => p.theme.colors["action"]};
    box-shadow: inset 0 0 0 1px ${(p) => p.theme.colors["textBody5"]};
`;

const largeSecondaryCss = css`
    color: ${(p) => p.theme.colors["textBody1"]};

    :focus,
    :hover {
        color: ${(p) => p.theme.colors["textBody"]};
    }
`;

export const ButtonLarge = styled.button<{ loading?: boolean; primary?: boolean }>`
    ${selectors.mixinBody1};
    font-weight: 600;

    background-color: ${(p) => p.theme.colors["background"]};

    display: flex;
    justify-content: center;
    align-items: center;

    height: 44px;
    width: 100%;

    border-radius: 8px;
    cursor: pointer;

    :focus-visible {
        box-shadow: 0 0 0 3px ${(p) => p.theme.colors["action4"]},
            inset 0 0 0 1px rgba(22, 199, 176, 0.52);
    }

    :hover,
    :focus {
        background-color: ${(p) => p.theme.colors["textBody7"]};
    }

    :disabled {
        color: ${(p) => p.theme.colors["textBody3"]};
        cursor: not-allowed;
    }

    ${({ loading }) =>
        loading &&
        css`
            cursor: wait;
        `}

    ${({ primary }) => (primary ? largePrimaryCss : largeSecondaryCss)}
`;

//Medium
const mediumPrimaryCss = css`
    background-color: ${(p) => p.theme.colors["action4"]};
    color: ${(p) => p.theme.colors["action"]};
    box-shadow: inset 0 0 0 1px ${(p) => p.theme.colors["textBody5"]};

    :focus,
    :hover {
        background-color: ${(p) => p.theme.colors["action3"]};
    }
`;

const mediumSecondaryCss = css`
    background-color: ${(p) => p.theme.colors["textBody6"]};
    color: ${(p) => p.theme.colors["textBody"]};

    :focus,
    :hover {
        background-color: ${(p) => p.theme.colors["textBody5"]};
        color: ${(p) => p.theme.colors["textBody"]};
    }
`;

export const ButtonMedium = styled.button<{ primary?: boolean; fullWith?: boolean }>`
    ${selectors.mixinBody1};
    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 32px;
    width: ${({ fullWith }) => (fullWith ? "100%" : "max-content")};

    padding: 7px 12px;

    border-radius: 8px;
    cursor: pointer;

    :focus-visible {
        box-shadow: 0 0 0 3px ${(p) => p.theme.colors["action4"]},
            inset 0 0 0 1px rgba(22, 199, 176, 0.52);
    }

    ${({ primary }) => (primary ? mediumPrimaryCss : mediumSecondaryCss)}
    :disabled {
        background-color: ${(p) => p.theme.colors["textBody6"]};
        color: ${(p) => p.theme.colors["textBody3"]};
        cursor: not-allowed;
    }
`;

//Small
export const ButtonSmall = styled(ButtonMedium)`
    font-size: 12px;
    line-height: 16px;

    height: 24px;
`;
