import { DropdownOption, MultipleDropdown, TriggerSize, TriggerVariant } from "@fm-frontend/uikit/src/components/v2";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

type BanksDropdownProps<T extends FieldValues> = {
    control: Control<T>;
    name: Path<T>;
    triggerSize: TriggerSize;
    variant: TriggerVariant;
    options: DropdownOption<string>[];
    placeholder?: string;
    fullWidth?: boolean;
};

export const BanksDropdown = <T extends FieldValues>({
    control,
    triggerSize,
    fullWidth,
    variant,
    options,
    name,
    placeholder,
}: BanksDropdownProps<T>) => {
    return (
        <Controller
            control={control}
            render={({ field }) => (
                <MultipleDropdown
                    values={field.value}
                    onChange={field.onChange}
                    renderTrigger={(trigger) => (
                        <MultipleDropdown.Trigger {...trigger} size={triggerSize} variant={variant}>
                            <MultipleDropdown.TriggerEssence
                                {...trigger}
                                option={trigger.selectedOptions}
                                size={triggerSize === "large" ? "large" : "small"}
                                totalOptionsCount={options.length}
                            />
                        </MultipleDropdown.Trigger>
                    )}
                    options={options}
                    placeholder={placeholder}
                    caption="Banks"
                    asFilter
                    fullWidth={fullWidth}
                >
                    <MultipleDropdown.BasicSheet columns={2} size="large" options={options} />
                </MultipleDropdown>
            )}
            name={name}
        />
    );
};
