import { H2, Icons, PrimaryButton, VStack } from "@fm-frontend/uikit";
import { useCopyToClipboard } from "@fm-frontend/utils";
import { createNotification } from "feature/app";
import React from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import {
    addressSectionItem,
    assetSectionItem,
    fromSectionItem,
    memoOrTagSectionItem,
    privateNoteSectionItem,
    SectionItem,
    TSectionItem,
} from "../../components";
import { AddressSide, CryptoAddress } from "../../types";
import { ALL_ADDRESS_INFO_COPIED_TEXT, emptyStringSubstitution, parseCryptoAddress } from "../../utils";

type OverviewCryptoAddressModalProps = {
    side: AddressSide;
    address: CryptoAddress;
    actions: () => React.ReactNode;
};

export const OverviewCryptoAddressModal: React.FC<OverviewCryptoAddressModalProps> = ({ address, side, actions }) => {
    const { colors } = useTheme();
    const dispatch = useDispatch();
    const copyToClipboard = useCopyToClipboard();
    const isYourSide = side === "your";

    const { wallet, currency, cpId, cpName, network, memoOrTag, privateNote } = parseCryptoAddress(address);

    const sections: TSectionItem[] = [
        fromSectionItem(cpId, cpName),
        assetSectionItem(currency, network),
        addressSectionItem(wallet),
        memoOrTagSectionItem(memoOrTag),
    ];

    if (isYourSide) {
        sections.push(privateNoteSectionItem(privateNote));
    }

    const handleAllInfoCopy = () => {
        const copyText = sections.reduce((acc, { title, copyTitle, value, copyValue }) => {
            acc += `${copyTitle ?? title}:\n${emptyStringSubstitution(copyValue ?? value)}\n`;

            return acc;
        }, "");

        copyToClipboard(copyText, () => {
            dispatch(
                createNotification({
                    type: "success",
                    content: ALL_ADDRESS_INFO_COPIED_TEXT,
                }),
            );
        });
    };

    return (
        <VStack minWidth="360px" asCard>
            <VStack padding={12}>
                <H2>{isYourSide ? "Your address overview" : "Address overview"}</H2>
            </VStack>
            <VStack paddingBottom={12}>
                {sections.map((sectionItem) => (
                    <SectionItem key={sectionItem.title} sectionItem={sectionItem} />
                ))}
            </VStack>
            <VStack paddingX={12} paddingBottom={16} spacing={8}>
                <PrimaryButton fullWidth size="large" type="submit" onClick={handleAllInfoCopy}>
                    <Icons.Copy color={colors.uiWhite100} />
                    Copy All
                </PrimaryButton>
                {actions()}
            </VStack>
        </VStack>
    );
};
