import { Modal } from "@fm-frontend/uikit";
import { useModalControl } from "@fm-frontend/utils";
import { FIXED_MODAL_STYLE } from "const/modal";
import { InstrumentMarkupsSetupModal } from "feature/assetsControl/components/InstrumentsTabContent/MakerMasterInstrumentsTabContent/InstrumentMarkupsSetupModal";
import { useTableData } from "feature/assetsControl/components/InstrumentsTabContent/MakerMasterInstrumentsTabContent/IntsrumentsTable/hooks";
import { MarkupsSetupResultsModal } from "feature/assetsControl/components/InstrumentsTabContent/MarkupsSetupResultsModal";
import { UpdateMarkupResponse } from "feature/assetsControl/components/InstrumentsTabContent/types";
import { AuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { FC, useState } from "react";
import { useModalContext } from "../../ModalContext";

type MarkupsWidgetModalProps = {
    authorizedClientId: AuthorizedClientId;
    instrumentName: string;
    bidMarkup: number | undefined;
    askMarkup: number | undefined;
};

export const MarkupsWidgetModal: FC<MarkupsWidgetModalProps> = ({
    authorizedClientId,
    instrumentName,
    bidMarkup,
    askMarkup,
}) => {
    const resultModalControl = useModalControl();
    const [markupsResult, setMarkupsResult] = useState<UpdateMarkupResponse[]>([]);
    const { instrumentsData, refetchInstrumentsData } = useTableData();

    const { closeModal, isModalOpen } = useModalContext();

    if (!authorizedClientId) {
        return null;
    }

    const handleUpdateMarkups = async (result: UpdateMarkupResponse[]) => {
        if (result.some(({ errorCode }) => errorCode === undefined)) {
            await refetchInstrumentsData();
        }

        if (result.some(({ errorCode }) => errorCode !== undefined)) {
            setMarkupsResult(result);
            resultModalControl.openModal();
        }
    };

    return (
        <>
            {isModalOpen && (
                <InstrumentMarkupsSetupModal
                    onClose={closeModal}
                    initialSetupModalSettings={{
                        defaultValues: {
                            cps: [authorizedClientId],
                            instruments: instrumentName ? [instrumentName] : [],
                            bidMarkup,
                            askMarkup,
                        },
                        isPerInstruments: Boolean(instrumentName),
                        isParticularMarkup: true,
                    }}
                    onUpdate={handleUpdateMarkups}
                    instrumentsData={instrumentsData}
                />
            )}

            <Modal
                isOpen={resultModalControl.isModalOpen}
                onClose={resultModalControl.closeModal}
                style={FIXED_MODAL_STYLE}
            >
                <MarkupsSetupResultsModal
                    onClose={resultModalControl.closeModal}
                    result={markupsResult}
                    onUpdate={handleUpdateMarkups}
                />
            </Modal>
        </>
    );
};
