import { useStepper } from "hooks/useStepper";
import { useCreateSubaccount } from "../api";
import { SubaccountInfoModal } from "./SubaccountInfoModal";
import { SubaccountWasCreatedModal } from "./SubaccountWasCreatedModal";

enum Step {
    Closed = 0,
    SubaccountInfo,
    SubaccountWasCreated,
}

export const useWizard = () => {
    const createSubaccount = useCreateSubaccount();
    const { currentStep, goToStep } = useStepper(Step.Closed);

    const handleClose = () => goToStep(Step.Closed);

    const wizard = (
        <>
            <SubaccountInfoModal
                isOpen={currentStep === Step.SubaccountInfo}
                onClose={handleClose}
                onNextStep={async (data) => {
                    const { name, email, accountType } = data;

                    return createSubaccount({
                        name,
                        email,
                        type: accountType as "maker" | "taker",
                    }).then(() => goToStep(Step.SubaccountWasCreated));
                }}
            />
            <SubaccountWasCreatedModal isOpen={currentStep === Step.SubaccountWasCreated} onClose={handleClose} />
        </>
    );

    return {
        wizard,
        createSubaccount: () => {
            goToStep(Step.SubaccountInfo);
        },
    };
};
