import { useSelector } from "hooks";
import React, { useMemo } from "react";
import { useIsMakerUser, useIsPrimeBrokerUser, useIsTakerUser } from "store/hooks";
import { ONBOARDING_STEPS, StatusKYBVariant } from "store/onboardingSlice";
import { SignTAndCBanner } from "./SignTAndCBanner";
import { useEnterToProduct } from "./utils";
import { WelcomeToProduct } from "./WelcomeToProduct";

const ONBOARDING_STEPS_FOR_TAKERS_BANNER = [ONBOARDING_STEPS.BL_TERMS_AND_CONDITIONS, ONBOARDING_STEPS.PROVIDERS];
const ONBOARDING_STEPS_FOR_MAKERS_AND_BROKERS_BANNER = [
    ONBOARDING_STEPS.BL_TERMS_AND_CONDITIONS,
    ONBOARDING_STEPS.API_INTEGRATION,
];

export const OnboardingInfoBanner: React.VFC = () => {
    const { isEnterToProductAllowed } = useEnterToProduct();
    const isMakerUser = useIsMakerUser();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const isTakerUser = useIsTakerUser();
    const {
        onboardingStages: { isTermsAndConditionsDelegated },
        currentStep,
        statusKYB = {},
    } = useSelector((state) => state.onboarding);
    const isMakerOrBroker = isMakerUser || isPrimeBrokerUser;
    const hasKYBStatusFinished = useMemo(
        () => Object.values(statusKYB).some((status) => status === StatusKYBVariant.Finished),
        [statusKYB],
    );
    const isTAndBannerAvailableForTakers =
        currentStep && ONBOARDING_STEPS_FOR_TAKERS_BANNER.includes(currentStep) && isTakerUser && hasKYBStatusFinished;
    const isTAndBannerAvailableForMakersOrBrokers =
        currentStep && ONBOARDING_STEPS_FOR_MAKERS_AND_BROKERS_BANNER.includes(currentStep) && isMakerOrBroker;

    if (isTermsAndConditionsDelegated && (isTAndBannerAvailableForMakersOrBrokers || isTAndBannerAvailableForTakers)) {
        return <SignTAndCBanner />;
    }

    if (isEnterToProductAllowed) {
        return <WelcomeToProduct />;
    }

    return null;
};
