import { ChangeEvent, forwardRef, KeyboardEvent, MouseEvent } from "react";
import { DefaultTheme, StyledComponent } from "styled-components";
import { IconButton } from "../../buttons";
import { Icons } from "../../icons";
import {
    MediumContainer,
    MediumSearchInput,
    SearchInputContainer,
    SmallContainer,
    SmallSearchInput
} from "./styled";
import { Props, SearchSize } from "./Styled.types";

const SearchContainerSizes: {
    [Key in SearchSize]: StyledComponent<typeof MediumContainer, DefaultTheme, {}, never>;
} = {
    small: SmallContainer,
    medium: MediumContainer,
    large: MediumContainer,
};

const SearchInputSizes: {
    [Key in SearchSize]: StyledComponent<typeof MediumSearchInput, DefaultTheme, {}, never>;
} = {
    small: SmallSearchInput,
    medium: MediumSearchInput,
    large: MediumSearchInput,
};

export const Search = forwardRef<HTMLInputElement, Props>(({ query, onChange, placeholder, size, className }, ref) => {
    const Container = SearchContainerSizes[size];
    const SearchInput = SearchInputSizes[size];
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    };
    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Escape") {
            onChange("");
        }
    };
    const handleClear = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onChange("");
    };

    return (
        <Container className={className}>
            <Icons.Search />
            <SearchInputContainer>
                <SearchInput
                    ref={ref}
                    placeholder={placeholder ?? "Search..."}
                    value={query}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
            </SearchInputContainer>
            {query && <IconButton variant="basic" Icon={Icons.Clear} onClick={handleClear} />}
        </Container>
    );
});
