import { ClientId, HStack, PBold } from "@fm-frontend/uikit";
import { getLabelWithHighlightedSearch } from "feature/settlements";
import styled from "styled-components";
import { useSearchQueryContext } from "../../searchQueryContext";

const Container = styled(HStack)`
    gap: 4px;
    white-space: nowrap;
    flex-wrap: nowrap;
`;

export const CpNameCell = ({ cpName, cpId }: { cpName: string; cpId: number }) => {
    const searchQuery = useSearchQueryContext();

    return (
        <Container>
            <PBold>{getLabelWithHighlightedSearch(cpName, searchQuery)}</PBold>
            <ClientId id={getLabelWithHighlightedSearch(String(cpId), searchQuery)} />
        </Container>
    );
};
