import {
    getFreeLimit,
    LimitTradingStatus,
    parseLimit,
    PERCENT_SHIFT,
} from "feature/counterparties/utils";
import { useCallback, useMemo } from "react";
import { useCounterpartyLimitsSnapshot } from "store/hooks";

export const useCpLimitInfo = () => {
    const { data: counterpartiesLimits = [], isLoading } = useCounterpartyLimitsSnapshot();
    const parsedLimits = useMemo(
        () => counterpartiesLimits.map(parseLimit),
        [counterpartiesLimits],
    );

    return {
        getCpLimitInfo: useCallback(
            (cpId: number) => {
                const limit = parsedLimits.find((l) => l.counterpartyId === cpId);

                if (!limit) {
                    return {
                        cpLimit: undefined,
                        cpLimitCurrency: undefined,
                        tradingEnabled: false,
                        freeLimit: undefined,
                        freeLimitPercent: undefined,
                    };
                }

                const { freeLimit, freeLimitPercent } = getFreeLimit(
                    limit.grossLimit,
                    limit.grossLimitUtilization,
                );

                return {
                    cpLimit: limit.grossLimit,
                    cpLimitCurrency: limit.currency,
                    tradingEnabled: limit.tradingStatus === LimitTradingStatus.ENABLED,
                    freeLimit,
                    freeLimitPercent: freeLimitPercent / PERCENT_SHIFT,
                };
            },
            [parsedLimits],
        ),
        isLoading,
    };
};
