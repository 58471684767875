import { useContext } from "react";
import { GA4Context } from "./context";
import { GA4 } from "./types";

export const useGA4Context = (): GA4 => {
    const ga4 = useContext(GA4Context);
    if (!ga4) {
        throw new Error("useGA4Context must be used within a GA4Provider");
    }
    return ga4;
};
