import { cover, ellipsis } from "polished";
import styled, { css } from "styled-components";
import { onHover } from "../../const";
import { selectors } from "../../style/theme";
import { increaseSpecificity } from "../../utils";
import { SmallButton } from "../form/Buttons";

export const BadgeGreen = styled.div`
    ${selectors.fontMedium}
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: ${(p) => p.theme.colors.activeBG};
    padding: 0 6px 1px;
    background: ${(p) => p.theme.colors.brand100};
    border-radius: 16px;
`;

export const Banner = styled.div<{ type?: "marketdepth" }>`
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex: 1;

    ${({ type }) => {
        switch (type) {
            case "marketdepth": {
                return css`
                    ${selectors.fontMedium}
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: -0.005em;
                    color: ${selectors.textSubdued};
                `;
            }
            default: {
                return css`
                    ${selectors.fontMedium}
                    font-size: 20px;
                    line-height: 22px;
                    color: ${selectors.textSubdued};
                `;
            }
        }
    }}

    cursor: default;
    ${(p) => p.theme.mixins.nonInteractive}
`;

export const Indicator = styled.div<{ isNegative?: boolean }>`
    ${selectors.mixinInterface2}
    ${selectors.mixinHover}
    background: ${selectors.mainBG};
    border-radius: 8px;
    padding: 6px 8px;
    margin-right: 6px;
    header {
        margin-bottom: 2px;
        color: ${selectors.textSubdued};
    }
    div {
        line-height: 18px;
        color: ${(p) => (p.isNegative ? p.theme.colors.red : p.theme.colors.blue)};
    }
`;

export const TableBadgeRow = styled.tr`
    ${increaseSpecificity(2)} {
        height: 32px;
        background: ${(p) => p.theme.colors.activeBG};
        td {
            padding: 12px 0 0 12px;
        }
    }
    td > div {
        display: flex;
        & > * + * {
            margin-left: 6px;
        }
    }
`;

export const HeaderActions = styled.div`
    display: flex;
    margin: 8px 12px;
    & > * {
        flex: 0 0 140px;
        text-align: start;
    }
`;

export const GlobalActions = styled.div`
    display: flex;
    margin: 8px 12px;
`;
export const TableHeader = styled.div`
    ${selectors.mixinHeader2}
    padding: 10px 12px 0;
    min-height: 46px;
    display: flex;
    z-index: 1;
`;

export const TableContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    max-height: calc(100vh - 150px);
    min-width: 49%;
    min-height: 200px;
    overflow-x: auto;
    background: ${selectors.activeBG};
    ${selectors.mixinBubble}

    ${TableHeader} {
        position: sticky;
        left: 0;
    }

    ${Banner} {
        margin: 16px 12px 24px;
    }
`;

export const TR = styled.tr<{ isSubRow?: boolean; isSelected?: boolean }>`
    ${(p) =>
        (p.isSubRow || p.isSelected) &&
        css`
            ${increaseSpecificity()} {
                background: ${(p) => p.theme.colors.listItemHover};
            }
        `}
`;

export const StyledTable = styled.table<{
    disableItemBorders?: boolean;
    hasActions?: boolean;
    areActionsStickied?: boolean;
}>`
    position: relative;
    width: 100%;
    min-width: 320px;
    white-space: nowrap;

    border-collapse: collapse;
    border-spacing: 0;
    thead th {
        box-shadow: inset 0 -2px black;
    }
    tbody tr {
        height: 44px;
        position: relative;
        transition: background-color ${(p) => p.theme.transitions.default};
        ${onHover} {
            background-color: #f7f7f7;
        }
    }
    th,
    td {
        vertical-align: middle;
        text-align: right;
        padding: 6px 3px;
        &:first-of-type {
            padding-left: 16px;
        }
        &:last-of-type {
            padding-right: 16px;
        }
    }
    th {
        ${(p) => p.theme.mixins.interface2}
        color: ${selectors.textSubdued};
    }
    td {
        ${selectors.mixinBody2}
        background-clip: padding-box;
    }
    ${(p) =>
        !p.disableItemBorders &&
        css`
            tbody tr:not(${TableBadgeRow}) {
                border-bottom: 1px solid #f0eeee;
            }
        `}
    ${(p) =>
        p.hasActions &&
        css`
            td:last-of-type {
                padding-top: 10px;
                padding-bottom: 4px;
            }
        `}
        ${(p) =>
        p.areActionsStickied &&
        css`
            td:last-of-type {
                position: sticky;
                right: 0;
                background: linear-gradient(to left, rgba(255, 255, 255, 0.95) 69% 90%, rgba(255, 255, 255, 0) 100%);
                background-clip: padding-box;
            }
        `}
    .marketDepthIndicator {
        ${cover()}
        ${(p) => p.theme.mixins.nonInteractive}
        opacity: 0.08;
        border-bottom: 0;
        margin: 0;
    }
`;

export const TableFlexCell = styled.div`
    vertical-align: middle;
    text-align: right;
`;
export const TableFlexRow = styled.div`
    display: flex;
    position: relative;
    ${TableFlexCell} {
        padding: 6px 3px;
        &:first-of-type {
            padding-left: 16px;
        }
        &:last-of-type {
            padding-right: 16px;
        }
    }
`;
export const TableFlexHeader = styled.section`
    ${(p) => p.theme.mixins.interface2}
    color: ${selectors.textSubdued};
    box-shadow: inset 0px -2px 0px #000000;

    overflow-y: auto;
    overflow-x: hidden;
`;
export const TableFlexBody = styled.section`
    overflow-y: auto;
    overflow-x: hidden;
    ${TableFlexRow} {
        position: relative;
        transition: background-color ${(p) => p.theme.transitions.default};
        ${onHover} {
            background-color: #f7f7f7;
        }
    }
    ${TableFlexCell} {
        ${selectors.mixinBody2}
    }
`;

export const StyledTableFlex = styled.article<{
    disableItemBorders?: boolean;
    minWidth?: number;
}>`
    position: relative;
    width: 100%;
    min-width: ${({ minWidth }) => `${minWidth || 320}px`};
    ${(p) =>
        !p.disableItemBorders &&
        css`
            ${TableFlexRow} + ${TableFlexRow} {
                border-top: 1px solid #f0eeee;
            }
        `}
`;

export const TableStackVertical = styled.section`
    ${TableContainer} + ${TableContainer} {
        margin-top: 16px;
    }
`;

export const Ellipsis = styled.span`
    ${ellipsis(88)}
`;

export const TitleBadgeContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: baseline;
    & > * + * {
        margin-left: 6px;
    }
    & > * {
        margin-bottom: 6px;
    }
`;

export const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
    & > * + * {
        margin-left: 8px;
    }
`;

export const ExpandedButton = styled(SmallButton)`
    color: ${({ theme }) => theme.colors.textSubdued};
    font-size: 14px;
    height: 24px;
    padding: 0px 8px;
    min-width: 80px; ;
`;
