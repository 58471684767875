import { TopMenu } from "components/TopMenu";
import { PositionsPageContent } from "feature/positions";
import { Page } from "style";

export const PagePositions = () => {
    return (
        <Page noBottomSpace>
            <TopMenu>Positions</TopMenu>
            <PositionsPageContent />
        </Page>
    );
};
