import { sendRESTRequestViaWS } from "feature/app/ws";
import { subscribeToWsRestResponses } from "feature/app/ws.saga";
import { actionChannel } from "store/actionChannel";
import { format } from "utils/ErrorCodes";

let wsReqId = 1;
export const getNextReqId = () => wsReqId++;

export const postViaWS = async <TRes = any, TData extends { [key: string]: unknown } = any>(
    method: string,
    data: TData,
) => {
    const reqId = getNextReqId();
    actionChannel.put(sendRESTRequestViaWS({ method, reqId, content: data }));

    return new Promise<TRes>((resolve, reject) => {
        let timeoutId: NodeJS.Timeout;
        const unsubscribe = subscribeToWsRestResponses((response) => {
            const { reqId: responseReqId, error: errorCode, reply: responseBody } = response;

            if (responseReqId === reqId) {
                clearTimeout(timeoutId);
                unsubscribe();

                if (errorCode !== undefined) {
                    reject(format(errorCode));
                } else {
                    resolve(responseBody as TRes);
                }
            }
        });

        timeoutId = setTimeout(() => {
            unsubscribe();
            reject(`Timeout of api/${method} request`);
        }, 10000);
    });
};
