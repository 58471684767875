import { ReactNode } from "react";
import { CpStatus } from "store/counterpartiesSlice";
import { ExtendedLimit } from "store/hooks";

export interface IndicatorData {
    title: string;
    value?: string;
    subValue?: string;
    badge?: {
        content: string | ReactNode;
    };
    copyableValue?: string;
}

export type CounterpartyLimitDict = Record<string, ExtendedLimit>;

export type LimitProps = {
    cpId: string;
    cpTitle: string;
    cpStatus: CpStatus;
    searchQuery?: string;
    extendedLimit: ExtendedLimit;
};

export enum LimitType {
    Empty = -1,
    UserLimit = 0,
    MutualLimit = 1,
}
