import { Flex, VStack } from "@fm-frontend/uikit";
import { QuoteSide } from "feature/rfq/apiHooks/useRfqWs";
import styled from "styled-components";
import { noop } from "utils";
import { ExecutingQuote } from "../RfqRequestModal";
import { BestQuote } from "./quotes/BestQuote";
import { Quote, QuotesList, SuccessedQuote } from "./QuotesList";

const QuotesContainer = styled(VStack)`
    border-top: 1px solid ${(p) => p.theme.colors.ui8};
`;

const AllProvidersLabelContainer = styled(Flex)<{ $inactive: boolean }>`
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${(p) => p.theme.colors.ui4};
    background: ${(p) => p.theme.colors.ui4};
    color: ${({ $inactive, theme: { colors } }) => ($inactive ? colors.ui32 : colors.ui52)};
    font-size: 10px;
    line-height: 12px;
    height: 20px;
`;
const AllProvidersLabel = ({ inactive }: { inactive: boolean }) => (
    <AllProvidersLabelContainer $inactive={inactive}>All providers</AllProvidersLabelContainer>
);

export const QuotesPanel = ({
    quotes,
    requestQuotesCount,
    requestSide,
    assetCurrency,
    onQuoteSelected,
    executingQuote,
    actionIsInactive,
}: {
    quotes: Quote[];
    requestQuotesCount: number;
    requestSide: QuoteSide;
    assetCurrency: string;
    onQuoteSelected: (selectedQuote: ExecutingQuote) => void;
    executingQuote: null | ExecutingQuote;
    actionIsInactive: boolean;
}) => {
    const allQuotesRejected =
        quotes.length === requestQuotesCount &&
        quotes.every((quote) => "rejectReason" in quote || "error" in quote);
    const bestQuote = quotes.reduce<SuccessedQuote | null>((acc, quote) => {
        if ("price" in quote && (acc === null || quote.price < acc.price)) {
            if (acc === null) {
                return quote;
            }
            if (requestSide === "SELL" && quote.price > acc.price) {
                return quote;
            }
            if (requestSide === "BUY" && quote.price < acc.price) {
                return quote;
            }
        }
        return acc;
    }, null);

    return (
        <QuotesContainer>
            <BestQuote
                quote={bestQuote}
                assetCurrency={assetCurrency}
                executingQuote={executingQuote}
                actionIsInactive={actionIsInactive}
                side={requestSide}
                allQuotesRejected={allQuotesRejected}
                onClick={
                    bestQuote !== null
                        ? () =>
                              onQuoteSelected({
                                  providerId: bestQuote.providerId,
                                  side: requestSide,
                                  isBestQuote: true,
                              })
                        : noop
                }
            />
            <AllProvidersLabel inactive={actionIsInactive} />
            <QuotesList
                quotes={quotes}
                quotesCount={requestQuotesCount}
                requestSide={requestSide}
                onQuoteSelected={onQuoteSelected}
                assetCurrency={assetCurrency}
                executingQuote={executingQuote}
                actionIsInactive={actionIsInactive}
            />
        </QuotesContainer>
    );
};
