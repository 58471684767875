import { VStack } from "@fm-frontend/uikit";
import EfxTypes from "utils/EfxTypes";
import { AmountInfo } from "../../AmountInfo";
import { Statistic } from "../../Statistic";
import { Inputs } from "../modals/CreateDepositModal";

export const NotCommittedDepositInfo = ({ transaction }: { transaction: Inputs }) => {
    return (
        <VStack spacing={12} padding={12} width="100%" asCard>
            <Statistic title="Asset" value={transaction.asset} />
            <AmountInfo amount={EfxTypes.parseValue(transaction.amount, "size64")} />
            {transaction.comment && <Statistic title="Comment" value={transaction.comment} />}
        </VStack>
    );
};
