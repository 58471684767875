import { getMatch, HStack, P as Currency, VStack } from "@fm-frontend/uikit";
import { DropdownOption } from "@fm-frontend/uikit/src/components/v2";
import styled from "styled-components";
import { InstrumentIcon } from "../InstrumentIcon";

const Container = styled(HStack)`
    align-items: center;
    gap: 8px;
    overflow: hidden;
`;

type InstrumentOptionEssenceProps = {
    option: DropdownOption<string> | undefined;
    query?: string;
    groupingOption?: boolean;
};

export const InstrumentOptionEssence = ({ option, query = "", groupingOption }: InstrumentOptionEssenceProps) => {
    if (!option) {
        return <></>;
    }

    return (
        <Container>
            {!groupingOption && <InstrumentIcon instrument={option.text} size={20} />}
            <VStack overflow="hidden">
                <HStack spacing={8} alignItems="center">
                    <Currency title={option.text} ellipsis>
                        {getMatch(option.text, query) ?? option.text}
                    </Currency>
                </HStack>
            </VStack>
        </Container>
    );
};
