export const ROUTES = {
    defaultCurrency: "/books/BTC-EUR",
    trading: "/trading",
    termsAndConditions: "/terms-and-conditions",
    riskManagement: "/riskmanagement",
    assets: "/assets",
    assetsControl: "/assets-and-instruments",
    addresses: "/addresses",
    positions: "/positions",
    historyTrades: "/trades",
    subaccounts: "/subaccounts",
    transactions: "/transactions",
    subaccountsViewTransactions: "/subaccountsViewTransactions",
    subaccountTransactions: "/subaccountTransactions",
};
