import { FC } from "react";
import styled from "styled-components";
import { BasicButton, PrimaryButton } from "../../buttons";
import { Icons } from "../../icons";

type DatePickerActionsProps = {
    onConfirm?: () => void;
    onReset?: () => void;
};

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 6px;
    padding: 0 8px 8px 8px;
`;

export const DatePickerActions: FC<DatePickerActionsProps> = ({ onConfirm, onReset }) => (
    <Container>
        <BasicButton size="medium" onClick={onReset}>
            <Icons.Recent /> Reset
        </BasicButton>
        <PrimaryButton size="medium" onClick={onConfirm}>
            Confirm
        </PrimaryButton>
    </Container>
);
