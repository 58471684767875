import { Icons, PSmallBold } from "@fm-frontend/uikit";
import styled, { useTheme } from "styled-components";

const ButtonContainer = styled.button`
    reset: all;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 40px;
    background: linear-gradient(90deg, rgba(234, 255, 254, 0) 0%, rgba(234, 255, 254, 0.64) 80%),
        ${(p) => p.theme.colors.brand100};
    color: ${(p) => p.theme.colors.uiWhite100};
    cursor: pointer;
`;

export const RequestAgainButton = ({
    onClick,
    allQuotesRejected,
}: {
    onClick: () => void;
    allQuotesRejected: boolean;
}) => {
    const theme = useTheme();
    const title = allQuotesRejected ? "Request again" : "Quote expired. Request again";

    return (
        <ButtonContainer onClick={onClick}>
            <PSmallBold>{title}</PSmallBold> <Icons.Reload color={theme.colors.uiWhite100} />
        </ButtonContainer>
    );
};
