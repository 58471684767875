import { ValidationMessages } from "utils/validationMessages";
import { AnySchema, object, string } from "yup";

export type ConfirmSignInInputs = {
    code: string;
};

export const confirmSignInSchema = object<Record<keyof ConfirmSignInInputs, AnySchema>>().shape({
    code: string()
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim())
        .matches(/^\d{6}$/, "Code must be 6-digit"),
});
