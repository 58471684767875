import { SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import React, { useCallback, useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CounterpartiesSheet } from "components/CounterpartiesSheet";
import { CounterpartyTriggerEssence } from "components/CounterpartiesTriggerEssence";
import { CryptoAddressFormInput } from "feature/addresses/types";
import { useTextAdaptor } from "hooks/useTextAdaptor";
import { useCounterpartiesOptions } from "hooks/useCounterpartiesOptions";
import { CryptoFormContext } from "../../CryptoFormContext";

type CpControlProps = {
    index: number;
    rowId: string;
    disabled: boolean;
};

export const CpControl: React.FC<CpControlProps> = ({ index, rowId, disabled }) => {
    const textAdaptor = useTextAdaptor();
    const counterpartiesOptions = useCounterpartiesOptions();
    const {
        control,
        formState: { errors, defaultValues },
    } = useFormContext<CryptoAddressFormInput>();

    const { updateDirtyRow } = useContext(CryptoFormContext);
    const handleChange = useCallback(
        (value) => {
            const defaultValue = defaultValues?.addresses?.[index]?.rule?.cp?.id;
            updateDirtyRow(rowId, "rule.cp.id", defaultValue !== value);
        },
        [updateDirtyRow, rowId],
    );

    return (
        <Controller
            control={control}
            render={({ field }) => (
                <SingleDropdown
                    value={field.value}
                    onChange={(value) => {
                        field.onChange(value);
                        handleChange(value);
                    }}
                    options={counterpartiesOptions}
                    renderTrigger={(trigger) => (
                        <SingleDropdown.Trigger {...trigger} size="medium" variant="minimum">
                            <CounterpartyTriggerEssence {...trigger} option={trigger.selectedOption} size="small" />
                        </SingleDropdown.Trigger>
                    )}
                    placeholder={textAdaptor("cryptoLightboxNameColumn")}
                    disabled={disabled}
                    error={errors?.addresses?.[index]?.rule?.cp?.id?.message}
                    fullWidth
                >
                    <CounterpartiesSheet size="medium" options={counterpartiesOptions} Dropdown={SingleDropdown} />
                </SingleDropdown>
            )}
            name={`addresses.${index}.rule.cp.id`}
        />
    );
};
