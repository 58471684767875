import { PSmallBold } from "@fm-frontend/uikit";
import styled from "styled-components";

const Container = styled.button`
    reset: all;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(p) => p.theme.colors.ui4};
    cursor: ${(p) => (p.onClick ? "pointer" : "not-allowed")};
    color: ${(p) => (p.onClick ? p.theme.colors.ui100 : p.theme.colors.ui32)};

    :hover {
        background-color: ${(p) => p.theme.colors.ui8};
    }
`;

export const CancelRequestButton = ({ onClick }: { onClick?: () => void }) => {
    return (
        <Container onClick={onClick}>
            <PSmallBold>Cancel request</PSmallBold>
        </Container>
    );
};
