import { noop } from "@fm-frontend/utils";
import { createContext } from "react";

type MarketDepthSectionContextType = {
    onMarketDepthItemClick: (price: number | bigint) => void;
};

export const MarketDepthSectionContext = createContext<MarketDepthSectionContextType>({
    onMarketDepthItemClick: noop,
});
