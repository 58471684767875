import { getHashParams, getSearchParams } from "@fm-frontend/utils";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const INVITE_KEY = "invite" as const;

export type SignUpQueryParams = {
    [INVITE_KEY]?: string;
};

export const useSignupQueryParams = () => {
    const location = useLocation();

    return useMemo((): SignUpQueryParams => {
        const searchParams = getSearchParams(location.search);
        const hashParams = getHashParams(location.hash);

        const invite = searchParams.get(INVITE_KEY) || hashParams.get(INVITE_KEY);

        return invite ? { [INVITE_KEY]: invite } : {};
    }, [location.search, location.hash]);
};
