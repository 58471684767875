import { H3, VStack } from "@fm-frontend/uikit";
import styled from "styled-components";
import { HintsHeader } from "components/modal/Hints/HintsHeader";
import { HintsContainer } from "components/modal/Hints/HintsContainer";
import { GroupContent } from "../styled";
import { Hint } from "../types";
import { AddressBookBanner } from "./AddressBookBanner";
import { LongPositions } from "./LongPositions";

const Group = styled(VStack)`
    flex: 1;
`;

const List = styled(GroupContent)`
    max-height: 431px;
`;

export const RequestHints = ({ onHintSelected }: { onHintSelected: (hint: Hint) => void }) => (
    <HintsContainer>
        <Group>
            <HintsHeader>
                <H3>Current long positions</H3>
            </HintsHeader>
            <List>
                <LongPositions onPositionSelected={onHintSelected} />
            </List>
        </Group>
        <AddressBookBanner />
    </HintsContainer>
);
