import { call, put, select, takeEvery } from "@redux-saga/core/effects";
import { post } from "api";
import { IS_ONBOARDING_ACTIVE } from "const/env";
import { createNotification, setIsLoadingApp } from "feature/app";
import { LS_VARIABLES } from "hooks/useLSState";
import { IRootState } from "store";
import { userTypeSelector } from "store/hooks";
import {
    cancelVerification,
    fetchOnboardingBanks,
    fetchOnboardingCurrencies,
    fetchOnboardingJurisdictions,
    fetchStatusKYB,
    fetchStepsKYB,
    resetPostOnboardingStages,
    startUserOnboarding,
    startVerification,
    stopUserOnboarding,
} from "store/onboardingActions";
import {
    OnboardingStages,
    setBanks,
    setCurrencies,
    setCurrentStep,
    setIsStatusKYBLoading,
    setIsStepsLoading,
    setJurisdictions,
    setOnboardingStages,
    setRestartVerificationMakerId,
    setStatusKYB,
    setStepsKYB,
    setTakerInfo,
    setVerificationStatus,
    StatusKYB,
    StatusKYBVariant,
    StepKYB,
} from "store/onboardingSlice";
import {
    BankType,
    ClientType,
    JurisdictionType,
    OnboardingCurrencyType,
    TakerInfoType,
} from "types";
import { kAPIMethodNotAvailable } from "utils/ErrorCodes";
import { getLSValue } from "utils/localStorage";

enum ProductAccess {
    Restricted,
    Allowed,
}

const getTakerOnboardingStages = (
    onboardingStages: OnboardingStages,
    statuses: StatusKYB[],
    takerInfo: TakerInfoType,
    userId: number | null,
) => {
    const stagesObj: OnboardingStages = {
        ...onboardingStages,
        isGeneralInfoRequired: false,
        isAssetsRequired: false,
        isApiIntegrationRequired: false,
        isLpsRequired: statuses.every((s) => s.status !== StatusKYBVariant.Finished),
    };

    if (!takerInfo) {
        return stagesObj;
    }

    const { fiat_settlements, bank, website, telegram, currency, ...dataForValidation } = takerInfo;

    if (stagesObj.isLpsRequired) {
        stagesObj.isGeneralInfoRequired = Object.values(dataForValidation).some(
            (prop) => !Boolean(prop),
        );
        stagesObj.isAssetsRequired = !Boolean(currency?.length);
        stagesObj.isApiIntegrationRequired =
            getLSValue<boolean>(LS_VARIABLES.API_INTEGRATION_REQUIRED, userId) ?? false;
    }

    return stagesObj;
};

const getMakerAndBrokerOnboardingStages = (onboardingStages: OnboardingStages, userId: number | null) => {
    const stagesObj: OnboardingStages = {
        ...onboardingStages,
        isApiIntegrationRequired:
            getLSValue<boolean>(LS_VARIABLES.API_INTEGRATION_REQUIRED, userId) ?? false,
    };

    return stagesObj;
};

const getUserId = (state: IRootState) => state.authentication.userId;
const getUserOnboardingStages = (state: IRootState) => state.onboarding.onboardingStages;
const getTakerInfo = (state: IRootState) => state.onboarding.takerInfo;

function* sagaPrimeBrokerOnboarding() {
    const userId: number = yield select(getUserId);
    const onboardingStages: ReturnType<typeof getUserOnboardingStages> = yield select(
        getUserOnboardingStages,
    );
    const makerOnboardingStages = getMakerAndBrokerOnboardingStages(onboardingStages, userId);
    yield put(setOnboardingStages(makerOnboardingStages));

    const statuses: StatusKYB[] = yield call(post, "onboarding/statusKYB", {});
    yield put(
        setStatusKYB(
            statuses?.reduce((acc, { id, status }) => {
                acc[id] = status;
                return acc;
            }, {} as Record<number, StatusKYBVariant>) ?? {},
        ),
    );
}

function* sagaMakerOnboarding() {
    const userId: number = yield select(getUserId);
    const onboardingStages: ReturnType<typeof getUserOnboardingStages> = yield select(
        getUserOnboardingStages,
    );
    const makerOnboardingStages = getMakerAndBrokerOnboardingStages(onboardingStages, userId);
    yield put(setOnboardingStages(makerOnboardingStages));
}

function* sagaTakerOnboarding() {
    yield put(
        setIsLoadingApp({
            isLoading: true,
            key: "sagaStartUserOnboarding",
        }),
    );

    try {
        const userId: number = yield select(getUserId);
        const productAccess: { status: number } = yield call(
            post,
            "onboarding/takerProductAccess",
            {
                clientId: userId,
            },
        );

        const statuses: StatusKYB[] = yield call(post, "onboarding/statusKYB", {});
        yield put(
            setStatusKYB(
                statuses?.reduce((acc, { id, status }) => {
                    acc[id] = status;
                    return acc;
                }, {} as Record<number, StatusKYBVariant>) ?? {},
            ),
        );

        if (productAccess.status === ProductAccess.Allowed) {
            yield put(
                setIsLoadingApp({
                    isLoading: false,
                    key: "sagaStartUserOnboarding",
                }),
            );

            return;
        }

        const onboardingStages: ReturnType<typeof getUserOnboardingStages> = yield select(
            getUserOnboardingStages,
        );

        const takerInfo: TakerInfoType = yield call(post, "onboarding/takerInfo", {
            clientId: userId,
        });

        const takerOnboardingStages = getTakerOnboardingStages(
            onboardingStages,
            statuses,
            takerInfo,
            userId,
        );
        yield put(setTakerInfo(takerInfo));
        yield put(setOnboardingStages(takerOnboardingStages));
    } catch (error) {
        console.error(error);
    }

    yield put(
        setIsLoadingApp({
            isLoading: false,
            key: "sagaStartUserOnboarding",
        }),
    );
}

function* sagaStartUserOnboarding() {
    if (!IS_ONBOARDING_ACTIVE) {
        return;
    }

    const userType: ClientType = yield select(userTypeSelector);

    switch (userType) {
        case "maker": {
            yield sagaMakerOnboarding();
            break;
        }
        case "primeBroker": {
            yield sagaPrimeBrokerOnboarding();
            break;
        }
        case "taker": {
            yield sagaTakerOnboarding();
            break;
        }
    }
}

function* sagaStopUserOnboarding() {
    yield put(setCurrentStep(null));
}

function* sagaFetchStepsKVB({ payload: clientId }: ReturnType<typeof fetchStepsKYB>) {
    yield put(setIsStepsLoading(true));
    try {
        const stepKYB: StepKYB[] = yield call(post, "onboarding/stepsKYB", {
            clientId,
        });

        yield put(setStepsKYB(stepKYB));
    } catch (error) {
        console.error(error);
    }
    yield put(setIsStepsLoading(false));
}

function* sagaFetchStatusKYB({ payload: clientIds }: ReturnType<typeof fetchStatusKYB>) {
    yield put(setIsStatusKYBLoading(true));
    try {
        const statuses: StatusKYB[] = yield call(post, "onboarding/statusKYB", {
            clientId: clientIds,
        });

        const defaultStatusKVB: Record<number, StatusKYBVariant> = {};

        yield put(
            setStatusKYB(
                statuses?.reduce((acc, { id, status }) => {
                    acc[id] = status;
                    return acc;
                }, defaultStatusKVB) ?? {},
            ),
        );
    } catch (error) {
        console.error(error);
    }
    yield put(setIsStatusKYBLoading(false));
}

function* sagaFetchBanks() {
    try {
        const banks: BankType[] = yield call(post, `onboarding/bank`, {});
        yield put(setBanks(banks ?? []));
    } catch (error) {
        console.error(error);
    }
}
function* sagaFetchJurisdictions() {
    try {
        const jurisdictions: JurisdictionType[] = yield call(post, `onboarding/jurisdiction`, {});
        yield put(setJurisdictions(jurisdictions ?? []));
    } catch (error) {
        console.error(error);
    }
}
function* sagaFetchCurrencies() {
    try {
        const currencies: OnboardingCurrencyType[] = yield call(post, `onboarding/currency`, {});
        yield put(setCurrencies(currencies ?? []));
    } catch (error) {
        console.error(error);
    }
}

function* sagaCheckTakerOnboardingStages() {
    const userId: number = yield select(getUserId);
    let takerInfo: TakerInfoType = yield select(getTakerInfo);

    if (!takerInfo) {
        takerInfo = yield call(post, "onboarding/takerInfo", {
            clientId: userId,
        });

        yield put(setTakerInfo(takerInfo));
    }

    const onboardingStages: ReturnType<typeof getUserOnboardingStages> = yield select(
        getUserOnboardingStages,
    );
    const { fiat_settlements, bank, website, telegram, currency, ...dataForValidation } =
        takerInfo ?? {};

    yield put(
        setOnboardingStages({
            ...onboardingStages,
            isGeneralInfoRequired: Object.values(dataForValidation).some((prop) => !Boolean(prop)),
            isAssetsRequired: !Boolean(currency?.length),
        }),
    );
}

function* sagaResetPostOnboardingStages() {
    const onboardingStages: ReturnType<typeof getUserOnboardingStages> = yield select(
        getUserOnboardingStages,
    );

    yield put(
        setOnboardingStages({
            ...onboardingStages,
            isGeneralInfoRequired: false,
            isAssetsRequired: false,
        }),
    );
}

function* sagaStartVerification({ payload: clientId }: ReturnType<typeof startVerification>) {
    yield put(
        setVerificationStatus({
            id: clientId,
            status: {
                isStartLoading: true,
            },
        }),
    );

    try {
        yield call(post, "onboarding/startKYB", {
            clientId,
        });

        yield put(fetchStatusKYB([clientId]));
        yield put(setRestartVerificationMakerId());
        yield put(
            setVerificationStatus({
                id: clientId,
                status: {
                    isStartLoading: false,
                    status: "started",
                },
            }),
        );
    } catch (e) {
        yield put(
            setVerificationStatus({
                id: clientId,
                status: {
                    isStartLoading: false,
                },
            }),
        );
        if (e === kAPIMethodNotAvailable) {
            yield sagaCheckTakerOnboardingStages();
            yield put(setRestartVerificationMakerId(clientId));
            return;
        }

        yield put(
            createNotification({
                type: "error",
                content: String(e),
            }),
        );
    }
}

function* sagaCancelVerification({ payload: clientId }: ReturnType<typeof cancelVerification>) {
    yield put(
        setVerificationStatus({
            id: clientId,
            status: {
                isCancelLoading: true,
            },
        }),
    );

    try {
        yield call(post, "onboarding/cancelKYB", {
            clientId,
        });

        yield put(fetchStatusKYB([clientId]));
        yield put(
            setVerificationStatus({
                id: clientId,
                status: {
                    isCancelLoading: false,
                    status: "canceled",
                },
            }),
        );
    } catch (e) {
        yield put(
            setVerificationStatus({
                id: clientId,
                status: {
                    isCancelLoading: false,
                },
            }),
        );

        yield put(
            createNotification({
                type: "error",
                content: String(e),
            }),
        );
    }
}

export function* onboardingSaga() {
    yield takeEvery(startUserOnboarding, sagaStartUserOnboarding);
    yield takeEvery(stopUserOnboarding, sagaStopUserOnboarding);
    yield takeEvery(fetchStatusKYB, sagaFetchStatusKYB);
    yield takeEvery(fetchStepsKYB, sagaFetchStepsKVB);
    yield takeEvery(fetchOnboardingBanks, sagaFetchBanks);
    yield takeEvery(fetchOnboardingCurrencies, sagaFetchCurrencies);
    yield takeEvery(fetchOnboardingJurisdictions, sagaFetchJurisdictions);
    yield takeEvery(resetPostOnboardingStages, sagaResetPostOnboardingStages);
    yield takeEvery(startVerification, sagaStartVerification);
    yield takeEvery(cancelVerification, sagaCancelVerification);
}
