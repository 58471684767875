import { Form, FormActions, FormBody, Header, HeaderTitle, Modal, PrimaryButton, ShadowCard } from "@fm-frontend/uikit";
import { post } from "api";
import { SettlementInfo } from "feature/settlements/SettlementDetailsModal/detailsModals/components/SettlementInfo";
import { Settlement } from "feature/settlements/types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Error } from "../../styled";

interface ReceivedDepositInfoModalProps {
    closeModal: () => void;
    deposit: Settlement;
}

export const ReceivedDepositInfoModal = ({ closeModal, deposit }: ReceivedDepositInfoModalProps) => {
    const [apiError, setApiError] = useState<string | null>(null);
    const {
        formState: { isSubmitting },
        handleSubmit,
    } = useForm({
        mode: "onSubmit",
    });

    const onSubmit = () =>
        post("commitIncomingSettlementTransaction", {
            transactionId: deposit.id,
        })
            .then(closeModal)
            .catch(setApiError);

    return (
        <Modal isOpen onClose={closeModal}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ShadowCard>
                    <Header>
                        <HeaderTitle title="Received deposit" />
                    </Header>
                    <FormBody spacing={8}>
                        <SettlementInfo settlement={deposit} />
                        {apiError && <Error>{apiError}</Error>}
                    </FormBody>
                    <FormActions variant="plain">
                        <PrimaryButton type="submit" fullWidth size="large" loading={isSubmitting}>
                            Commit deposit
                        </PrimaryButton>
                    </FormActions>
                </ShadowCard>
            </Form>
        </Modal>
    );
};
