import { Flex, P, PSmall } from "@fm-frontend/uikit";
import { EmDash } from "@fm-frontend/utils";
import styled from "styled-components";

const AssetBanContent = styled(Flex)`
    height: 100%;
    width: 100%;
    align-items: center;
    padding: 0 12px;
`;

const Empty = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui20};
`;

const Mixed = styled(P)`
    font-size: 10px;
`;

export const GroupAssetCell = ({ value }: { value?: any | "mixed" }) => {
    return (
        <AssetBanContent>
            {value === "mixed" && <Mixed>•••</Mixed>}
            {value === undefined && <Empty>{EmDash}</Empty>}
            {value !== "mixed" && value !== undefined && <P>{value}</P>}
        </AssetBanContent>
    );
};
