import { forwardRef, HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { Action, StyledHeader } from "../styled";

const Container = styled(StyledHeader)<{ disabled: boolean }>`
    padding: 6px 6px 6px 12px;
    user-select: none;

    ${(p) =>
        !p.disabled &&
        css`
            :hover {
                ${Action} {
                    cursor: pointer;
                    background-color: ${({ theme }) => theme.colors.ui4};
                }
            }
        `}
    :focus-visible {
        box-shadow: 0px 0px 0px 3px ${(p) => p.theme.colors.brand12},
            inset 0px 0px 0px 1px ${(p) => p.theme.colors.brand72};
        outline: none;
    }
`;

interface HeaderProps extends HTMLAttributes<HTMLHeadingElement> {
    onOpen?: () => void;
    disabled?: boolean;
}

export const Header = forwardRef<HTMLHeadingElement, HeaderProps>(
    ({ children, onOpen, disabled = false, ...rest }, ref) => {
        return (
            <Container
                disabled={disabled}
                onClick={disabled ? undefined : onOpen}
                onKeyDown={(e: React.KeyboardEvent<HTMLHeadingElement>) => {
                    e.preventDefault();

                    if (!disabled && (e.key === "Enter" || e.key === " ")) {
                        onOpen?.();
                    }
                }}
                role="button"
                tabIndex={disabled ? -1 : 0}
                aria-disabled={disabled}
                aria-label="Edit card"
                ref={ref}
                {...rest}
            >
                {children}
            </Container>
        );
    },
);

Header.displayName = "Header";
