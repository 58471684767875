import { BasicButton, HStack, Icons, PSmall, PSmallBold, VStack } from "@fm-frontend/uikit";
import { BankAddress } from "feature/addresses/types";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

const itemCss = css`
    padding: 4px 8px;

    ${PSmall} {
        color: ${(p) => p.theme.colors.ui72};
    }

    ${PSmallBold} {
        color: ${(p) => p.theme.colors.ui100};
    }
`;

const HeaderContainer = styled(HStack)`
    cursor: pointer;
    border-radius: 8px;
    background: ${(p) => p.theme.colors.ui4};
    align-items: center;

    ${VStack} {
        flex: 1;
    }

    ${itemCss}
`;

const ItemContainer = styled(VStack)`
    gap: 2px;

    ${itemCss}
`;

const DetailsButton = styled(BasicButton)`
    margin: 12px 0 12px 8px;
`;

const Item = ({ title, value }: { title: string; value: string }) => (
    <ItemContainer>
        <PSmall>{title}</PSmall>
        <PSmallBold>{value}</PSmallBold>
    </ItemContainer>
);

export const BankAccount = ({
    bankAccount,
    number,
    isOpened,
    onClick,
}: {
    bankAccount: BankAddress;
    number: number;
    isOpened: boolean;
    onClick: () => void;
}) => {
    const [openedDetails, setOpenedDetails] = useState(false);
    const detailsButtonText = openedDetails ? "Show less" : "Show more";

    useEffect(() => {
        setOpenedDetails(false);
    }, [isOpened]);

    return (
        <div>
            <HeaderContainer onClick={onClick}>
                <VStack spacing={2}>
                    <PSmall>Bank #{number}</PSmall>
                    <PSmallBold>{bankAccount.bankPrimary?.name}</PSmallBold>
                </VStack>
                {isOpened ? <Icons.Collapse /> : <Icons.Expand />}
            </HeaderContainer>
            {isOpened && (
                <VStack spacing={4}>
                    <Item title="Bank name" value={bankAccount.bankPrimary?.name ?? ""} />
                    <Item title="Account number/IBAN" value={bankAccount.wallet} />
                    {bankAccount.memoOrTag && <Item title="SWIFT/BIC" value={bankAccount.memoOrTag} />}

                    {openedDetails && (
                        <>
                            {bankAccount.company?.name && (
                                <Item title="Company name" value={bankAccount.company.name} />
                            )}
                            {bankAccount.company?.address && (
                                <Item title="Company legal address" value={bankAccount.company.address} />
                            )}
                            {bankAccount.otherDetails && (
                                <Item title="Other details" value={bankAccount.otherDetails} />
                            )}
                            {bankAccount.publicDetails && (
                                <Item
                                    title="Public description, payment instructions"
                                    value={bankAccount.publicDetails}
                                />
                            )}
                        </>
                    )}

                    <DetailsButton size="small" onClick={() => setOpenedDetails(!openedDetails)}>
                        {detailsButtonText}
                    </DetailsButton>
                </VStack>
            )}
        </div>
    );
};
