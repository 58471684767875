import { Flex, P, Tooltip } from "@fm-frontend/uikit";
import { useIsPrimeBrokerUser } from "store/hooks";
import styled, { css } from "styled-components";
import { ClientType } from "types";

const commonStyles = css`
    height: 100%;
    width: 100%;
    align-items: center;
    padding-left: 12px;
`;

const CpBanWrapper = styled.div`
    ${(p) => p.theme.mixins.p}
`;

const CpBanContent = styled(Flex)<{ $isBanned: boolean }>`
    ${commonStyles};
    background-color: ${(p) => (p.$isBanned ? p.theme.colors.negative4 : "transparent")};

    ${CpBanWrapper} {
        color: ${(p) => (p.$isBanned ? p.theme.colors.ui100 : p.theme.colors.ui52)};
    }
`;

export type CellType = "user" | "cp";
const useIsBanApplicable = (cellType: CellType, cpType: ClientType) => {
    const isMasterUser = useIsPrimeBrokerUser();

    if (cellType === "user" && isMasterUser && (cpType === "maker" || cpType === "primeBroker")) {
        return false;
    }
    if (cellType === "cp" && isMasterUser && cpType === "taker") {
        return false;
    }

    return true;
};
export const CpBanCell = ({
    isBanned,
    cellType,
    cpType,
}: {
    isBanned: boolean;
    cellType: "user" | "cp";
    cpType: ClientType;
}) => {
    const isApplicable = useIsBanApplicable(cellType, cpType);

    return (
        <CpBanContent $isBanned={isBanned}>
            <CpBanWrapper>
                {isApplicable ? (
                    isBanned ? (
                        "Ban"
                    ) : (
                        "On"
                    )
                ) : (
                    <Tooltip content="Not applicable">n/a</Tooltip>
                )}
            </CpBanWrapper>
        </CpBanContent>
    );
};

export type AssetBanStatus = "ban" | "on" | "mixed";
const ASSET_BAN_TITLES: Record<AssetBanStatus, string> = {
    ban: "Ban",
    on: "On",
    mixed: "•••",
};
const AssetBanContent = styled(Flex)`
    ${commonStyles}
`;
const Mixed = styled(P)`
    font-size: 10px;
`;

export const AssetBanCell = ({ status }: { status: AssetBanStatus }) => (
    <AssetBanContent>
        {status === "mixed" && <Mixed>{ASSET_BAN_TITLES[status]}</Mixed>}
        {status !== "mixed" && <P>{ASSET_BAN_TITLES[status]}</P>}
    </AssetBanContent>
);
