import { addOrUpdateBankAddress, addOrUpdateCryptoAddress } from "../api";
import { BankAddress, CryptoAddress, FormApiErrors } from "../types";
import { isBankAccountType } from "../utils";

export const useAddOrUpdateAddresses = () => {
    return async (addressesList: CryptoAddress[] | BankAddress[]) => {
        const requests: Promise<string | null>[] = [];
        addressesList.forEach((address) => {
            requests.push(
                isBankAccountType(address) ? addOrUpdateBankAddress(address) : addOrUpdateCryptoAddress(address),
            );
        });

        const result = await Promise.allSettled(requests);

        return result.reduce((acc, curr, currentIndex) => {
            if (curr.status === "fulfilled" && curr.value) {
                const id = addressesList[currentIndex]._validationItemId ?? `${currentIndex}`;

                acc = {
                    ...acc,
                    [id]: curr.value,
                };
            }
            return acc;
        }, {} as FormApiErrors);
    };
};
