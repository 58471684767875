import { useMemo } from "react";
import { useInstruments } from "./useInstruments";

export const useInstrumentInfo = (instrumentName: string) => {
    const { instruments } = useInstruments();

    return useMemo(() => {
        const instrument = instruments.find((i) => i.instrumentName === instrumentName);

        return {
            baseAsset: instrument?.assetCurrency,
            quoteAsset: instrument?.balanceCurrency,
        };
    }, [instrumentName, instruments]);
};
