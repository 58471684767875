import { SIZE_VALUE_MULTIPLICATOR } from "./const/index";

export const toNonExponential = (value: number) => {
    const stringifiedValue = value.toString();
    const [base, exponent] = stringifiedValue.split(/[eE]/);

    if (exponent !== undefined) {
        const decimalPlaces = (base.split(".")[1] || "").length;
        const totalDigits = decimalPlaces - Number(exponent);

        return value.toFixed(Math.max(0, totalDigits));
    } else {
        return stringifiedValue;
    }
};

export const formatPercent = (value: number | bigint) => {
    const formatter = new Intl.NumberFormat("en-GB", {
        maximumFractionDigits: 4,
        minimumFractionDigits: 2,
        style: "percent",
    });

    return formatter.format(Number(value) / 1e4 / 100);
};

export const format6FractionDigits = (value: number | bigint, fixed = 6) => {
    const quotient = Number(BigInt(value) / BigInt(1e6));
    const remainder = Number(BigInt(value) % BigInt(1e6));

    return (quotient + remainder * 1e-6).toFixed(fixed);
};

export const format6FractionDigitsAsPercent = (value: number | bigint, fixed = 2) => {
    const quotient = Number(BigInt(value) / BigInt(1e4));
    const remainder = Number(BigInt(value) % BigInt(1e4));

    return (quotient + remainder * 1e-4).toFixed(fixed);
};

export const format8FractionDigits = (value: number | bigint, fixed = 8, minFixed = fixed) => {
    const quotient = Number(BigInt(value) / BigInt(1e8));
    const remainder = Number(BigInt(value) % BigInt(1e8));

    const formatter = new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: minFixed,
        maximumFractionDigits: fixed,
    });

    return formatter.format(quotient + remainder * 1e-8);
};

export const parse8FractionDigits = (value: string): bigint => {
    const [quotient = "0", remainder = "0"] = value.split(/[,.]/);

    return (
        BigInt(quotient) * BigInt(1e8) +
        BigInt(Math.floor(parseFloat(`0.${remainder}`) * 1e8 + 0.5))
    );
};

const formatBytes = (bytes: number, decimals: number = 2): string => {
    if (bytes <= 0) {
        return "0 Bytes";
    }

    const bytesInKilobyte = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "kB", "MB", "GB"];

    const measurementUnit = Math.floor(Math.log(bytes) / Math.log(bytesInKilobyte));

    return `${parseFloat((bytes / Math.pow(bytesInKilobyte, measurementUnit)).toFixed(dm))} ${
        sizes[measurementUnit]
    }`;
};

export const trimLongText = (text: string, length: number = 16) => {
    if (text.length <= length) {
        return text;
    }

    return `${text.slice(0, length / 2)}...${text.slice(text.length - length / 2, text.length)}`;
};

export const ValueParse = {
    percent: (value: string): number => Math.floor(parseFloat(value) * 1e4 + 0.5),
    deltaRate: (value: string): number => Math.floor(parseFloat(value) * 1e6 + 0.5),
    u32: (value: string): number => parseInt(value, 10),
    price: (value: string): bigint => parse8FractionDigits(value),
    size: (value: string): bigint => parse8FractionDigits(value),
    size64: (value: string): bigint => parse8FractionDigits(value),
    limit: (value: string): bigint => parse8FractionDigits(value),
};

export const ValueFormat = {
    deltaRate: (value: number | bigint) => format6FractionDigits(value),
    u32: (value: number) => `${value}`,
    price: (value: number | bigint, fixed = 8) => format8FractionDigits(value, fixed),
    formPrice: (value: number | bigint, fixed = 8) =>
        format8FractionDigits(value, fixed).replaceAll(",", ""),
    size: (value: number | bigint) => format8FractionDigits(value),
    formSize: (value: number | bigint) => format8FractionDigits(value).replaceAll(",", ""),
    percent: (value: number | bigint) => format6FractionDigitsAsPercent(value),
    formatBytes,
    trimLongText,
};

export const ValueConvert = {
    usdLimitEquivalent: (limit: bigint, usdPrice: bigint) =>
        (limit * usdPrice + BigInt(SIZE_VALUE_MULTIPLICATOR) - 1n) /
        BigInt(SIZE_VALUE_MULTIPLICATOR),
    normalize: (value: unknown) => String(value).toUpperCase().trim(),
    size: (value: number | null | undefined | string) =>
        value === null || value === undefined || value === ""
            ? null
            : ValueParse.size(toNonExponential(Number(value))),
    price: (value: number | null | undefined | string) =>
        value === null || value === undefined || value === ""
            ? null
            : ValueParse.price(toNonExponential(Number(value))),
    percent: (value: number | null | undefined | string) =>
        value === null || value === undefined || value === ""
            ? null
            : ValueParse.percent(String(value)),
};
