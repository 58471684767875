import { Checkbox, P } from "@fm-frontend/uikit";
import { useId } from "@fm-frontend/uikit/src/hooks/useId";
import { MatchedPart } from "components/select/SearchSelect/style";
import React from "react";
import { useController, UseControllerProps } from "react-hook-form";
import styled, { useTheme } from "styled-components";

interface Props extends UseControllerProps<any> {
    text: string;
    label?: string;
    match?: React.ReactNode;
    disabled?: boolean;
}

const Container = styled.label<{ disabled?: boolean }>`
    display: flex;
    flex: calc(50% - 5px);
    align-items: center;
    gap: 8px;
    cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};

    span {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.005em;
        color: ${(p) => p.theme.colors.ui72};
        mark {
            color: ${(p) => p.theme.colors.ui72};
            background-color: ${(p) => p.theme.colors.brand32};
        }
    }

    :hover {
        & > label > span {
            border: 1px solid ${(p) => p.theme.colors.ui32};
        }
    }
`;

export const CheckboxPill: React.FC<Props> = (props) => {
    const generatedId = useId("checkbox-pill-");
    const { colors } = useTheme();
    const { text, label, match, disabled, ...rest } = props;

    const { field } = useController({
        ...rest,
    });
    const { value, onChange } = field;
    const labelElement = label ? <P color={colors.ui32}>{label}</P> : null;

    return (
        <Container htmlFor={generatedId} disabled={disabled}>
            <Checkbox
                disabled={disabled}
                id={generatedId}
                size="small"
                checked={value}
                onChange={onChange}
            />
            {match ? (
                <MatchedPart>
                    {match} {labelElement}
                </MatchedPart>
            ) : (
                <P color={colors.ui72}>
                    {text} {labelElement}
                </P>
            )}
        </Container>
    );
};
