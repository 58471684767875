import { IconButton, Icons, VStack } from "@fm-frontend/uikit";
import { EmDash } from "@fm-frontend/utils";
import { TransactionLabel } from "feature/settlements/components/TransactionLabel";
import styled from "styled-components";
import EfxTypes from "utils/EfxTypes";
import { fmt, getAbsoluteValue } from "utils/format";
import { PAYER_TITLES } from "../../../../SettlementsTable";
import { Settlement } from "../../../../types";
import { AssetItem } from "./infoItems/AssetItem";
import { CpItem } from "./infoItems/CpItem";
import { Statistic } from "./infoItems/Statistic";
import { TimestampItem } from "./infoItems/TimestampItem";

const Container = styled(VStack)`
    position: relative;
    margin-bottom: 4px;
`;

const DeleteButtonWrapper = styled.span`
    position: absolute;
    top: 10px;
    right: 10px;
`;

export const SettlementInfo = ({
    settlement,
    onDeleteSettlement,
    isDeleteButtonLoading = false,
}: {
    settlement: Settlement;
    onDeleteSettlement?: () => void;
    isDeleteButtonLoading?: boolean;
}) => {
    const amountValue =
        settlement.amount === 0n
            ? "All available"
            : `${EfxTypes.formatValue(getAbsoluteValue(settlement.amount), "size64")} ${settlement.asset}`;

    return (
        <Container spacing={12} padding={12} width="100%" asCard>
            {onDeleteSettlement && (
                <DeleteButtonWrapper>
                    <IconButton
                        type="button"
                        variant="basic"
                        Icon={Icons.Bin}
                        onClick={onDeleteSettlement}
                        loading={isDeleteButtonLoading}
                    />
                </DeleteButtonWrapper>
            )}
            <CpItem settlement={settlement} />
            <AssetItem settlement={settlement} />
            <Statistic title="Amount" value={amountValue} />
            <TimestampItem settlement={settlement} />
            <Statistic
                title={`Fee paid ${PAYER_TITLES[settlement.fee?.payer ?? "sender"]}`}
                value={
                    settlement.fee
                        ? fmt(settlement.fee.size ?? 0, {
                              significantDigits: 8,
                              type: "size64",
                          }).formattedValue
                        : EmDash
                }
            />
            {settlement.txId && <Statistic title="TX ID" value={<TransactionLabel txId={settlement.txId} />} />}
            {settlement.comment && <Statistic title="Comment" value={settlement.comment} />}
        </Container>
    );
};
