import {
    ClientId,
    CounterpartyIcon,
    IconButton,
    Icons,
    Skeleton,
    Tooltip,
} from "@fm-frontend/uikit";
import { Switch } from "@fm-frontend/uikit/src/components/v2";
import { noop } from "@fm-frontend/utils";
import { CpStatusBadge } from "components/CpStatusBadge";
import { createNotification, setConfirmModalContent } from "feature/app";
import { ROUTES } from "feature/app/router";
import { CounterpartyStatusType, isSubaccountCp, parseLimit } from "feature/counterparties/utils";
import { LpDefaultIcon } from "feature/lpManagementWidget/LpDefaultIcon";
import { useOpenConfirmationModal } from "hooks";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { usePrimeBrokerViewTypeChange } from "hooks/usePrimeBrokerViewTypeChange";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setPreopenedCpIdLimit } from "store/preopenedCpIdLimit";
import styled, { useTheme } from "styled-components";
import { CounterpartyLimit } from "types";
import { isEnabledTradingByUser } from "utils";
import { disableTrading, enableTrading } from "../api";

const LimitDescription = styled.div`
    padding: 0 4px 0 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-left: 1px solid ${(p) => p.theme.colors.ui8};
    overflow: hidden;
`;

const Container = styled.div`
    min-height: 44px;
    border-radius: 8px;
    display: flex;

    background-color: ${(p) => p.theme.colors.ui8};

    ${LimitDescription} {
        flex: 1;
    }
`;

const ActionContainer = styled.div`
    padding: 0 8px;
    display: flex;
    align-items: center;
`;

const LpTitle = styled.div<{ $isEnabled: boolean }>`
    flex: 1;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: ${(p) => (p.$isEnabled ? p.theme.colors.ui100 : p.theme.colors.ui72)};
    overflow: hidden;

    display: flex;
    gap: 4px;
`;

const LpName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Actions = styled.div`
    display: flex;
    gap: 2px;
    align-items: center;
`;

const ConfirmTitle = styled.span`
    color: ${(p) => p.theme.colors.brand100};
`;

export const LpRow: React.FC<{
    limit: CounterpartyLimit;
    cpStatus?: CounterpartyStatusType;
    setError: (error: string | null) => void;
    isBulkSubmitting: boolean;
}> = ({ limit, setError, cpStatus, isBulkSubmitting }) => {
    const changePrimeBrokerViewType = usePrimeBrokerViewTypeChange();
    const openConfirmationModal = useOpenConfirmationModal();
    const history = useHistory();
    const theme = useTheme();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useDispatch();
    const { counterpartyId, tradingStatus } = parseLimit(limit);
    const { getCpName, getCpType } = useCpInfoHelpers();
    const cpName = getCpName(counterpartyId, "full");
    const lpType = getCpType(counterpartyId);

    const handleMoveToLimit = () =>
        openConfirmationModal({
            title: "Leave Trading Page?",
            description: (
                <>
                    You’re about to navigate to the <strong>Risk Management</strong>. This will take
                    you away from the trading page. Do you want to continue?
                </>
            ),
            confirmButtonTitle: <ConfirmTitle>Yes, continue</ConfirmTitle>,
            cancelButtonTitle: "Cancel",
            onConfirm: () => {
                setPreopenedCpIdLimit(counterpartyId);
                changePrimeBrokerViewType(
                    isSubaccountCp(lpType) ? "subaccounts" : "counterparties",
                );
                history.push(ROUTES.riskManagement);
            },
        });

    const isTradingActive = isEnabledTradingByUser(tradingStatus);
    const handleTradingStatusChange = async () => {
        const config = isTradingActive
            ? {
                  method: disableTrading,
                  action: "Stop",
                  title: "Stop your trading activity?",
                  description: "Your orders will be removed from the trading environment",
                  notification: "Trading has been stopped",
              }
            : {
                  method: enableTrading,
                  action: "Start",
                  title: "Start your trading activity?",
                  description: "",
                  notification: "Trading has been started",
              };

        const requestFunc = async () => {
            try {
                setError(null);
                setIsSubmitting(true);

                await config.method(counterpartyId);

                dispatch(
                    createNotification({
                        type: "success",
                        content: config.notification,
                    }),
                );
            } catch (e) {
                setError(String(e));
            } finally {
                setIsSubmitting(false);
            }
        };

        dispatch(
            setConfirmModalContent({
                title: config.title,
                onConfirm: async () => {
                    await requestFunc();
                },
                description: config.description,
                confirmButtonTitle: config.action,
            }),
        );
    };

    const isLoading = isSubmitting || isBulkSubmitting;

    return (
        <Container>
            <ActionContainer>
                {isLoading ? (
                    <Skeleton height="12px" width="22px" bgColor={theme.colors.uiWhite100} />
                ) : (
                    <Switch
                        size="small"
                        disabled={isSubmitting || isBulkSubmitting}
                        checked={isTradingActive}
                        onChange={isLoading ? noop : handleTradingStatusChange}
                        variant="minimum"
                        asSimpleSwitch
                    />
                )}
            </ActionContainer>
            <LimitDescription>
                <CounterpartyIcon
                    cpId={counterpartyId}
                    key={counterpartyId}
                    FallbackComponent={LpDefaultIcon}
                    size={24}
                />
                <LpTitle $isEnabled={isTradingActive}>
                    <LpName>
                        <Tooltip content={cpName}>{cpName}</Tooltip>
                    </LpName>
                    <ClientId id={counterpartyId} tooltip={String(counterpartyId)} />
                </LpTitle>
                <Actions>
                    {cpStatus !== undefined && <CpStatusBadge cpStatus={cpStatus} />}
                    <IconButton
                        variant="plain"
                        Icon={Icons.Greater}
                        disabled={lpType === undefined}
                        onClick={handleMoveToLimit}
                    />
                </Actions>
            </LimitDescription>
        </Container>
    );
};
