import { ClientId, HStack, PBold } from "@fm-frontend/uikit";
import { getEnv } from "@fm-frontend/utils";
import { AssetInfo } from "components/AssetInfo";
import { createColumns } from "feature/table/columns";
import { TransactionRowData } from "./PageSettlementTransactions";

export const columnsSettlementHistory = createColumns<TransactionRowData>([
    {
        header: "CP",
        accessor: (data) => data[0],
        Cell: ({ row: { original: transaction } }) => {
            const cpId = transaction[0];
            const cpName = transaction[13];

            return (
                <HStack spacing={4}>
                    <PBold>{cpName}</PBold>
                    <ClientId id={cpId} />
                </HStack>
            );
        },
        format: { type: "u32" },
        mixin: { fontWeight: 500, align: "left" },
    },
    {
        header: "Asset",
        accessor: (data) => data[1],
        Cell: ({ row: { original: transaction } }) => {
            const asset = transaction[1];
            const network = transaction[12];

            return <AssetInfo asset={asset} network={network} />;
        },
        mixin: { align: "left" },
    },
    {
        header: "Amount",
        accessor: (data) => data[2],
        format: { significantDigits: 8, type: "size64" },
    },
    {
        header: "Fee",
        accessor: (data) => data[11],
        format: { significantDigits: 8, type: "size" },
    },
    {
        header: "Fee payer",
        accessor: (data) => (data[11] === 0 ? "Sender" : "Recipient"),
    },
    {
        header: "Tx ID",
        accessor: (data) => data[6],
        truncateAt: "middle",
    },
    {
        header: "Comment",
        accessor: (data) => data[4],
        truncateAt: "end",
    },
    {
        header: "Status",
        accessor: (data) => {
            switch (data[8] & 3) {
                case 0:
                case 1: {
                    return "Committed";
                }
                case 2:
                case 3: {
                    return "Cancelled";
                }
            }
            return "";
        },
        getColor: (_, data) => {
            switch (data[8] & 3) {
                case 0:
                case 1: {
                    return "brand";
                }
                case 2:
                case 3: {
                    return "red";
                }
            }
            return undefined;
        },
        mixin: { fontWeight: 500 },
    },
    {
        header: "Time",
        accessor: (data) => data[9],
        format: { type: "timems", withTime: true },
    },
    {
        header: "Order Id",
        accessor: (data) => data[3],
        format: { type: "u32" },
    },
]);
export const exportColumnsSettlementHistory = [...columnsSettlementHistory];
exportColumnsSettlementHistory[0] = {
    ...exportColumnsSettlementHistory[0],
    Header: "CP ID",
};
exportColumnsSettlementHistory.splice(
    2,
    0,
    ...createColumns<TransactionRowData>([
        {
            header: "Network",
            accessor: (data) => data[12],
        },
    ]),
);

const { HRP_MASTER_ID } = getEnv();

export const columnsTechnicalHistory = createColumns<TransactionRowData>([
    {
        header: "CP",
        accessor: (data) => {
            const cpId = Number(data[6]);
            const comment = data[7];
            return cpId === HRP_MASTER_ID && comment === "Position given-up to PB / Clearing venue"
                ? `${cpId} (Clearing broker)`
                : String(cpId);
        },
        Cell: ({ row: { original: transaction } }) => {
            const cpId = transaction[6];
            const cpName = transaction[13];

            return (
                <HStack spacing={4}>
                    <PBold>{cpName}</PBold>
                    <ClientId id={cpId} />
                </HStack>
            );
        },
        mixin: { fontWeight: 500, align: "left" },
    },
    {
        header: "Asset",
        accessor: (data) => data[1],
        Cell: ({ row: { original: transaction } }) => {
            const asset = transaction[1];
            const network = transaction[12];

            return <AssetInfo asset={asset} network={network} />;
        },
        mixin: { align: "left" },
    },
    {
        header: "Amount",
        accessor: (data) => data[3],
        format: { significantDigits: 8, type: "size64" },
    },
    {
        header: "Comment",
        accessor: (data) => {
            return data[7];
        },
        mixin: { minWidth: "300px" },
    },
    {
        header: "Time",
        accessor: (data) => data[5],
        format: { type: "timems", withTime: true },
    },
    {
        header: "Order Id",
        accessor: (data) => data[0],
        format: { type: "u32" },
    },
]);
export const exportColumnsTechnicalHistory = [...columnsTechnicalHistory];
exportColumnsTechnicalHistory[0] = {
    ...exportColumnsTechnicalHistory[0],
    Header: "CP ID",
};
exportColumnsTechnicalHistory.splice(
    2,
    0,
    ...createColumns<TransactionRowData>([
        {
            header: "Network",
            accessor: (data) => data[12],
        },
    ]),
);
