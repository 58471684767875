import {
    CheckboxField,
    Form,
    FormActions,
    FormBody,
    Header,
    HeaderTitle,
    HStack,
    Icons,
    Modal,
    PrimaryButton,
    ShadowCard,
    SimpleInput,
    Tooltip,
} from "@fm-frontend/uikit";
import { yupResolver } from "@hookform/resolvers/yup";
import { post } from "api";
import { FieldGrouper } from "feature/counterparties/styled";
import { TextArea } from "feature/form/style";
import { AssetAndNetworkSelector } from "feature/specificSubaccountAndPBPagesContents/AssetAndNetworkSelector";
import {
    getAmountSchema,
    getFeeValidator,
} from "feature/specificSubaccountAndPBPagesContents/yupValidators";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useUserPrimeBrokerId } from "store/hooks";
import { getCurrencyPlaceholder } from "utils";
import EfxTypes from "utils/EfxTypes";
import { parse, stringify } from "utils/json";
import { ValidationMessages } from "utils/validationMessages";
import { AnySchema, boolean, object, string } from "yup";
import { Error } from "../../styled";
import { Transaction } from "../../types";

export type Inputs = {
    asset: string;
    hasNetworks: boolean;
    network?: string;
    amount: string;
    comment: string;
    isFeePaidByRecipient: boolean;
    fee?: string;
    type: Transaction["type"];
};
export type CreatedTransaction = Inputs & { id: number };

interface CreateDepositModalProps {
    onContinueClick: (transactionSettings: CreatedTransaction) => void;
    closeModal: (shouldShowModal: boolean) => void;
}

type DefaultDepositValues = {
    asset: string;
    amount: string;
    comment?: string;
};
const DEFAULT_TRANSACTION_SESSION_STORAGE_KEY = "subaccountTransactionsPageDefaultDepositState";
export const setSubaccountDefaultTransactionState = (values: DefaultDepositValues) => {
    sessionStorage.setItem(DEFAULT_TRANSACTION_SESSION_STORAGE_KEY, stringify(values));
};
const clearSubaccountDefaultTransactionState = () => {
    sessionStorage.removeItem(DEFAULT_TRANSACTION_SESSION_STORAGE_KEY);
};
export const getSubaccountDefaultTransactionState = (): DefaultDepositValues => {
    const preloadedFormValues = sessionStorage.getItem(DEFAULT_TRANSACTION_SESSION_STORAGE_KEY);
    return preloadedFormValues ? parse(preloadedFormValues) : undefined;
};

export const validationSchema = object<Record<keyof Inputs, AnySchema>>().shape({
    asset: string().required(ValidationMessages.REQUIRED),
    amount: getAmountSchema(),
    comment: string().optional(),
    isFeePaidByRecipient: boolean(),
    fee: string().when(["isFeePaidByRecipient"], {
        is: (isFeePaidByRecipient: boolean) => isFeePaidByRecipient,
        then: (schema) => getFeeValidator(schema),
        otherwise: (schema) => schema.optional(),
    }),
    hasNetworks: boolean().required(),
    network: string().when("hasNetworks", {
        is: (hasNetworks: boolean) => hasNetworks,
        then: (schema) => schema.required(ValidationMessages.REQUIRED),
        otherwise: (schema) => schema.optional(),
    }),
});

export const CreateDepositModal = ({ closeModal, onContinueClick }: CreateDepositModalProps) => {
    const [isAssetAndNetworkReady, setIsAssetAndNetworkReady] = useState(false);
    const {
        control,
        formState: { errors, isSubmitting, isDirty },
        watch,
        handleSubmit,
        register,
        setValue,
    } = useForm<Inputs>({
        defaultValues: {
            isFeePaidByRecipient: false,
            comment: "",
            hasNetworks: false,
            ...(getSubaccountDefaultTransactionState() ?? {}),
        },
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
    });
    const primeBrokerId = useUserPrimeBrokerId();
    const [apiError, setApiError] = useState<string | null>(null);

    useEffect(() => {
        clearSubaccountDefaultTransactionState();
    }, []);

    const asset = watch("asset");

    const isFeePaidByRecipient = watch("isFeePaidByRecipient");
    useEffect(() => {
        if (!isFeePaidByRecipient) {
            setValue("fee", undefined);
        }
    }, [isFeePaidByRecipient]);

    const onSubmit = async (formValues: Inputs) => {
        try {
            setApiError(null);

            const requestBody: any = {
                counterpartyId: primeBrokerId,
                currency: formValues.asset,
                amount: EfxTypes.parseValue(String(formValues.amount), "size64"),
                fee: formValues.fee && EfxTypes.parseValue(String(formValues.fee), "size64"),
                comment: formValues.comment,
            };
            if (formValues.network) {
                requestBody.network = formValues.network;
            }

            const { settlementTransactionId } = await post(
                "addOutgoingSettlementTransaction",
                requestBody,
            );

            closeModal(false);
            onContinueClick({ ...formValues, id: settlementTransactionId });
        } catch (err) {
            setApiError(err as string);
        }
    };

    return (
        <Modal isOpen onClose={() => closeModal(isDirty)}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ShadowCard>
                    <Header>
                        <HeaderTitle title="New deposit" />
                    </Header>
                    <FormBody spacing={8}>
                        <AssetAndNetworkSelector
                            control={control}
                            setValue={setValue}
                            assetError={errors.asset?.message}
                            networkError={errors.network?.message}
                            onReady={setIsAssetAndNetworkReady}
                        />
                        <SimpleInput
                            label="Amount"
                            {...register("amount")}
                            placeholder={getCurrencyPlaceholder(asset)}
                            error={errors.amount?.message}
                        />
                        <FieldGrouper isExpanded={isFeePaidByRecipient} fullWidth>
                            <CheckboxField
                                name="isFeePaidByRecipient"
                                label={
                                    <HStack spacing={4}>
                                        Fee paid by recipient{" "}
                                        <Tooltip content="If the CP is ready to cover the network fee, please, mark this field and indicate the total amount that is a sum of the blockchain transaction amount and the network fee.">
                                            <Icons.Help />
                                        </Tooltip>
                                    </HStack>
                                }
                                size="large"
                                fullWidth
                                control={control}
                            />
                            {isFeePaidByRecipient && (
                                <SimpleInput
                                    label="Size"
                                    {...register("fee")}
                                    placeholder={getCurrencyPlaceholder(asset)}
                                    error={errors.fee?.message}
                                />
                            )}
                        </FieldGrouper>
                        <TextArea
                            rows={4}
                            placeholder="Comment"
                            autoFocus
                            {...register("comment")}
                        />
                        {apiError && <Error>{apiError}</Error>}
                    </FormBody>
                    <FormActions variant="plain">
                        <PrimaryButton
                            type="submit"
                            fullWidth
                            loading={isSubmitting}
                            disabled={!isAssetAndNetworkReady}
                            size="large"
                        >
                            Create deposit
                        </PrimaryButton>
                    </FormActions>
                </ShadowCard>
            </Form>
        </Modal>
    );
};
