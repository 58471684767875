import { useEffect, useState } from "react";
import { HintVariant } from "../../common/HintVariantComponents";

export { Item } from "./Item";
export * from "./styled";

export const useCustomHint = (error: string | undefined, hint: string | undefined) => {
    const [hintVariant, setHintVariant] = useState<{ variant: HintVariant; text?: string }>({
        variant: "hint",
    });

    useEffect(() => {
        if (error) {
            setHintVariant({ variant: "error", text: error });
        } else {
            setHintVariant({ variant: "hint", text: hint });
        }
    }, [error, hint]);

    return { hintVariant };
};
