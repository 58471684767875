import { CounterpartyStatusType, LimitTradingStatus } from "feature/counterparties/utils";
import { useTheme } from "styled-components";
import { isEnabledTradingByUser } from "utils";
import { useCpStatusHelpers } from "../useCpStatusHelpers";

const DisabledStatusIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6" y="6" width="4" height="4" fill="white" fillOpacity="0.32" />
    </svg>
);

const EnabledStatusIcon = ({ color }: { color: string }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 8L6 11.4641L6 4.5359L12 8Z" fill={color} />
    </svg>
);

export const CpStatusIcon = ({
    limitTradingStatus,
    cpStatus,
}: {
    limitTradingStatus: LimitTradingStatus;
    cpStatus: CounterpartyStatusType;
}) => {
    const { colors } = useTheme();
    const { getCpStatusColor } = useCpStatusHelpers();

    if (isEnabledTradingByUser(limitTradingStatus)) {
        return <EnabledStatusIcon color={getCpStatusColor(cpStatus) ?? colors.brand100} />;
    }

    return <DisabledStatusIcon />;
};
