import styled from "styled-components";

import "@iroomit/react-date-range/dist/styles.css";
import "@iroomit/react-date-range/dist/theme/default.css";

export const DatePickerCalendarContainer = styled.div`
    background: ${(p) => p.theme.colors.uiWhite100};
    border-radius: 8px;
    box-shadow: 0 4px 16px 0 ${(p) => p.theme.colors.ui8}, 0 0 0 1px ${(p) => p.theme.colors.ui12};
    overflow: hidden;

    * {
        transition: none;
    }
    .rdrDateDisplayWrapper {
        background-color: inherit;
    }
    .rdrDateDisplayItem {
        display: flex;
        align-items: center;
        padding: 0 8px;
        background-color: ${(p) => p.theme.colors.ui8};
        box-shadow: none;
        border-radius: 8px;
    }
    .rdrDateDisplayItem input {
        ${(p) => p.theme.mixins.pSmall}
        color: ${(p) => p.theme.colors.ui100};
        text-align: right;
    }
    .rdrDateDisplayItem:nth-child(1):before {
        content: "From";
        color: ${(p) => p.theme.colors.ui72};
    }
    .rdrDateDisplayItem:nth-child(2):before {
        content: "To";
        color: ${(p) => p.theme.colors.ui72};
    }
    .rdrDefinedRangesWrapper {
        border: none;
        width: 100%;
    }
    .rdrStaticRanges {
        border-bottom: 1px solid ${(p) => p.theme.colors.ui8};
        flex-direction: row;
    }
    .rdrStaticRange {
        border: none;
        padding: 4px;

        &.rdrStaticRangeSelected,
        &:hover {
            color: ${(p) => p.theme.colors.ui100} !important;

            .rdrStaticRangeLabel {
                border-radius: 6px;
                background: ${(p) => p.theme.colors.ui4};
            }
        }
    }
    .rdrStaticRangeLabel {
        ${(p) => p.theme.mixins.p}
        padding: 8px;
    }
    .rdrInputRanges {
        display: none;
    }

    .rdrStartEdge {
        background: ${(p) => p.theme.colors.brand100};
        border-radius: 8px;
        left: 2px;
        right: 2px;
    }
    .rdrInRange {
        background: ${(p) => p.theme.colors.brand8};
        border-radius: 0;
    }
    .rdrEndEdge {
        background: ${(p) => p.theme.colors.brand100};
        border-radius: 8px;
        left: 2px;
        right: 2px;
    }

    .rdrDay:not(.rdrDayPassive) {
        .rdrStartEdge:not(.rdrEndEdge) + span + .rdrDayNumber,
        .rdrStartEdge:not(.rdrEndEdge) + .rdrDayNumber {
            :before {
                content: "";
                position: absolute;
                right: 0;
                width: 10px;
                height: 100%;
                background: ${(p) => p.theme.colors.brand8};
            }
        }
    }

    .rdrDay:not(.rdrDayPassive) {
        .rdrEndEdge:not(.rdrStartEdge) + span + .rdrDayNumber,
        .rdrEndEdge:not(.rdrStartEdge) + .rdrDayNumber {
            :before {
                content: "";
                position: absolute;
                left: 0;
                width: 10px;
                height: 100%;
                background: ${(p) => p.theme.colors.brand8};
            }
        }
    }

    .rdrDay:not(.rdrDayPassive) {
        .rdrDayNumber span {
            :after {
                background: ${(p) => p.theme.colors.brand100};
            }
        }

        .rdrInRange,
        .rdrSelected {
            & ~ .rdrDayNumber span {
                color: ${(p) => p.theme.colors.ui100} !important;

                :after {
                    background: ${(p) => p.theme.colors.ui100};
                }
            }
        }

        .rdrStartEdge,
        .rdrEndEdge {
            & ~ .rdrDayNumber span {
                color: ${(p) => p.theme.colors.uiWhite100} !important;

                :after {
                    background: ${(p) => p.theme.colors.uiWhite100};
                }
            }
        }
    }

    .rdrDayToday .rdrDayNumber span {
        &:after {
            width: 3px;
            height: 3px;
        }
    }

    .rdrDayNumber, .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
        top: 1px;
        bottom: 1px;
    }

    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
        background: ${(p) => p.theme.colors.brand8};
        border: none;
        top: 1px;
        bottom: 1px;
    }

    .rdrDayStartOfMonth,
    .rdrDayStartOfWeek {
        .rdrDayInPreview{
            border-radius: 0;
            left: 2px;
        }
    }

    .rdrDayEndOfMonth,
    .rdrDayEndOfWeek {
        .rdrDayInPreview {
            border-radius: 0;
            right: 2px;
        }
    }

    .rdrDateDisplayItemActive {
        border-color: ${(p) => p.theme.colors.brand100};
    }

    .rdrDayNumber span {
        ${(p) => p.theme.mixins.pSmallBold}
        color: ${(p) => p.theme.colors.ui100};
        z-index: 1;
    }

    .rdrDayPassive .rdrDayNumber span {
        display: none;
    }

    .rdrDayHovered {
        .rdrDayStartPreview, .rdrDayEndPreview {
            background: #C3EDE6;
            border: none;
            border-radius: 8px;
            top: 1px;
            bottom: 1px;
            left: 2px;
            right: 2px;
        }
    }

    .rdrDayStartPreview:not(.rdrDayEndPreview) + .rdrDayNumber {
        :before {
            content: "";
            position: absolute;
            right: 0;
            width: 10px;
            height: 100%;
            background: ${(p) => p.theme.colors.brand8};
        }
    }
    .rdrDayEndPreview:not(.rdrDayStartPreview) + .rdrDayNumber {
        :before {
            content: "";
            position: absolute;
            left: 0;
            width: 10px;
            height: 100%;
            background: ${(p) => p.theme.colors.brand8};
        }
    }

    .rdrDayDisabled {
        background: transparent;

        span {
            color: ${(p) => p.theme.colors.ui32};
        }
    }

    .rdrWeekDays {
        .rdrWeekDay {
            color: ${(p) => p.theme.colors.ui52};
        }

        .rdrWeekDay:nth-last-child(-n + 2) {
            color: ${(p) => p.theme.colors.negative52};
        }
    }

    .rdrNextPrevButton {
        border-radius: 12px;
        background-position: center;
        background-repeat: no-repeat;

        i {
            display: none;
        }
    }
    .rdrNextButton {
        background-image: url("data:image/svg+xml,<svg width=\\"11\\" height=\\"8\\" viewBox=\\"0 0 11 8\\" fill=\\"none\\" xmlns=\\"http://www.w3.org/2000/svg\\"><path fill-rule=\\"evenodd\\" clip-rule=\\"evenodd\\" d=\\"M7.1 7.75643L11 3.97387L7.1 0.286954C6.7 -0.0956513 6.1 -0.0956513 5.7 0.286954C5.3 0.669559 5.3 1.24347 5.7 1.62607L7.2 3.01736L1 3.01736C0.4 3.01736 4.05441e-08 3.39996 4.73879e-08 3.97387C5.42317e-08 4.54778 0.4 4.93038 1 4.93038L7.2 4.93038L5.7 6.32166C5.3 6.70427 5.3 7.27818 5.7 7.66078C6.1 8.04339 6.7 8.13904 7.1 7.75643Z\\" fill=\\"rgba(36, 48, 52, 0.32)\\" /></svg>");
    }
    .rdrPprevButton {
        background-image: url("data:image/svg+xml,<svg width=\\"11\\" height=\\"8\\" viewBox=\\"0 0 11 8\\" fill=\\"none\\" xmlns=\\"http://www.w3.org/2000/svg\\"><path fill-rule=\\"evenodd\\" clip-rule=\\"evenodd\\" d=\\"M3.9 0.243567L-1.73703e-07 4.02613L3.9 7.71305C4.3 8.09565 4.9 8.09565 5.3 7.71305C5.7 7.33044 5.7 6.75653 5.3 6.37393L3.8 4.98265L10 4.98265C10.6 4.98265 11 4.60004 11 4.02613C11 3.45222 10.6 3.06962 10 3.06962L3.8 3.06962L5.3 1.67834C5.7 1.29573 5.7 0.721823 5.3 0.339218C4.9 -0.0433876 4.3 -0.139038 3.9 0.243567Z\\" fill=\\"rgba(36, 48, 52, 0.32)\\" /></svg>");
    }

    .rdrMonth {
        padding: 0 12px 8px 12px;
        width: 300px;
    }
    .rdrMonthAndYearWrapper {
        height: auto;
        padding: 0;
    }
    .rdrMonthName {
        ${(p) => p.theme.mixins.header3}
        text-align: center;
        padding: 4px;
    }

    .rdrMonthAndYearPickers select {
        background-image: url("data:image/svg+xml,<svg width=\\"16\\" height=\\"16\\" viewBox=\\"0 0 16 16\\" fill=\\"none\\" xmlns=\\"http://www.w3.org/2000/svg\\"><path d=\\"M8 11L4 6H12L8 11Z\\" fill=\\"rgba(36, 48, 52, 0.32)\\" /></svg>");
        background-position: right 4px center;
        border-radius: 8px;
        padding: 5px 20px 5px 6px;
    }

`;
