import { onMobile } from "const";
import { setIsMobileMenuOpen } from "feature/app";
import { PlainIconButton } from "feature/form/Buttons";
import { useSelector } from "hooks/useSelector";
import { useTitle } from "hooks/useTitle";
import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Spacer } from "style";
import { selectors } from "style/theme";
import styled from "styled-components";
import { IconMenu, IconMenuClose, MobileLogo } from "./icons";
import { ProfileDropdown } from "./ProfileDropdown";
import { UserMenuControl } from "./UserMenuControl/UserMenuControl";

export const TopMenuHeader = styled.header`
    ${selectors.mixinHeader1}
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    gap: 8px;
    align-items: start;

    & > * {
        margin-bottom: 12px;
    }

    & > span {
        margin-right: 4px;
        margin-top: 6px;
        display: flex;
    }

    & > svg {
        margin-top: 8px;
    }

    ${onMobile} {
        white-space: nowrap;
        flex-wrap: wrap;
    }

    @media (max-width: 860px) {
        aside {
            order: 1;
            width: 100%;
        }
    }
`;

export const TopMenuSelect = () => {
    const dispatch = useDispatch();
    const { isMobile, isMobileMenuOpen } = useSelector((state) => state.app);

    const onClickMobileMenu = useCallback(
        () => dispatch(setIsMobileMenuOpen(!isMobileMenuOpen)),
        [isMobileMenuOpen],
    );

    return isMobile ? (
        <PlainIconButton onClick={onClickMobileMenu}>
            {isMobileMenuOpen ? <IconMenuClose /> : <IconMenu />}
        </PlainIconButton>
    ) : (
        <ProfileDropdown />
    );
};

export const TopMenu: React.FC = memo(({ children }) => {
    const { isMobile } = useSelector((state) => state.app);
    useTitle(typeof children === "string" ? children : null);

    return (
        <TopMenuHeader>
            {isMobile && (
                <>
                    <MobileLogo />
                    <Spacer />
                </>
            )}
            <span>{children}</span>
            <Spacer />
            <UserMenuControl />
        </TopMenuHeader>
    );
});
