import { Row } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import React, { memo, useMemo } from "react";
import * as Styled from "../../common/styled";
import { DEFAULT_SELECTED_ROWS } from "../constants";
import { InfiniteTablePadding } from "../Padding";
import { InfiniteTableRows } from "../Rows";
import { RowClickHandler } from "../types";

const InfiniteTableBodyComponent = <T extends object>({
    tableContainerRef,
    rows,
    virtualizerOptions,
    selectedRows = DEFAULT_SELECTED_ROWS,
    onRowClick,
}: {
    tableContainerRef: React.RefObject<Element>;
    rows: Row<T>[];
    virtualizerOptions?: Partial<Parameters<typeof useVirtualizer>[0]>;
    selectedRows: Record<string, boolean>;
    onRowClick?: RowClickHandler<T>;
}) => {
    const options = useMemo<Parameters<typeof useVirtualizer>[0]>(
        () => ({
            overscan: 50,
            estimateSize: () => 44,
            ...virtualizerOptions,
            count: rows.length,
            getScrollElement: () => tableContainerRef.current,
        }),
        [rows.length, virtualizerOptions, tableContainerRef],
    );
    const rowVirtualizer = useVirtualizer(options);
    const virtualRows = rowVirtualizer.getVirtualItems();

    const renderRows = useMemo(() => virtualRows, [virtualRows[0]?.index, virtualRows[virtualRows.length - 1]?.index]);

    const [paddingTop, paddingBottom] = useMemo(() => {
        const totalSize = rowVirtualizer.getTotalSize();
        const paddingTop = virtualRows?.[0]?.start ?? 0;
        const paddingBottom = totalSize - (virtualRows?.[virtualRows.length - 1]?.end ?? 0);

        return [paddingTop, paddingBottom];
    }, [virtualRows]);

    return (
        <Styled.Tbody>
            {paddingTop > 0 && <InfiniteTablePadding size={paddingTop} />}
            <InfiniteTableRows
                virtualRows={renderRows}
                rows={rows}
                selectedRows={selectedRows}
                rowVirtualizer={rowVirtualizer}
                onRowClick={onRowClick}
            />
            {paddingBottom > 0 && <InfiniteTablePadding size={paddingBottom} />}
        </Styled.Tbody>
    );
};

export const InfiniteTableBody = memo(InfiniteTableBodyComponent) as typeof InfiniteTableBodyComponent;
