import { isHighestAsset, isLowestAsset } from "feature/onboarding/common";
import { useMemo } from "react";
import { OnboardingCurrencyType } from "types";
import { useSelector } from "./useSelector";

export const useGroupedCurrencies = ({ isUnique }: { isUnique?: boolean }) => {
    const { currencies } = useSelector((state) => state.onboarding);

    return useMemo(() => mapCurrencies(currencies ?? [], isUnique), [currencies, isUnique]);
};

export const mapCurrencies = (currency: OnboardingCurrencyType[], isUnique?: boolean) => {
    const mappedCurrencies = currency.reduce((acc, currency) => {
        const {
            currency_group: { name: groupName = "" },
        } = currency;

        acc[groupName] ??= [];

        if (acc[groupName].findIndex((c) => c.name === currency.name) > 0 && isUnique) {
            return acc;
        }

        acc[groupName].push(currency);
        return acc;
    }, {} as Record<string, OnboardingCurrencyType[]>);

    return Object.keys(mappedCurrencies)
        .sort((left, right) => {
            if (isHighestAsset(left) || isLowestAsset(right)) {
                return -1;
            }
            if (isHighestAsset(right) || isLowestAsset(left)) {
                return 1;
            }

            return left.localeCompare(right);
        })
        .reduce((acc, key) => {
            acc[key] = mappedCurrencies[key];
            return acc;
        }, {} as Record<string, OnboardingCurrencyType[]>);
};
