import { Icons } from "@fm-frontend/uikit";
import { forwardRef } from "react";
import { useTheme } from "styled-components";
import { useSelector } from "hooks";
import { Badge, NotificationsButton as StyledNotificationsButton } from "./styled";

export const NotificationsButton = forwardRef<
    HTMLButtonElement,
    { onClick: () => void; }
>(({ onClick }, ref) => {
    const { colors } = useTheme();
    const { unreadCount } = useSelector((state) => state.notifications);
    const hasUnreadNotifications = unreadCount > 0;

    return (
        <StyledNotificationsButton onClick={onClick} ref={ref}>
            <Icons.Bell color={colors.ui32} />
            {hasUnreadNotifications && <Badge>{unreadCount}</Badge>}
        </StyledNotificationsButton>
    );
});
