import { DropdownOption } from "@fm-frontend/uikit/src/components/v2";
import { useCounterparties } from "hooks/useCounterparties";
import { useMemo } from "react";

type Options = {
    specificCps?: number[];
};
export const useCounterpartiesOptions = (options?: Options) => {
    const { counterparties } = useCounterparties({ specificCps: options?.specificCps });

    return useMemo((): DropdownOption<number>[] => {
        return Object.entries(counterparties).map(([id, name]) => ({ text: name, value: Number(id) }));
    }, [counterparties]);
};
