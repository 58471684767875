import { useCallback } from "react";
import { useUsdPrices } from "store/hooks";
import { getAbsoluteValue } from "utils/format";

export const useUsdAmount = () => {
    const { priceObj, isLoading } = useUsdPrices();

    const getUsdAmount = useCallback(
        (asset: string, amount: number | bigint) => (BigInt(getAbsoluteValue(amount)) * BigInt(priceObj[asset])) / BigInt(1e8),
        [priceObj],
    );

    return {
        getUsdAmount,
        isLoading,
    };
}
