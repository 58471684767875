import { AnySchema, object, string } from "yup";
import { ValidationMessages } from "../../../../utils/validationMessages";
import { LIMIT_FORMAT_REGEX } from "../../../counterparties/utils";
import { LimitInputs } from "../utils";

export const schema = object<Record<keyof LimitInputs, AnySchema>>().shape({
    currency: string().required(ValidationMessages.REQUIRED),
    grossLimit: string()
        .required(ValidationMessages.REQUIRED)
        .matches(LIMIT_FORMAT_REGEX, ValidationMessages.INVALID_LIMIT),
});
