import { COLOR_OPACITIES, themes as baseTheme } from "@fm-frontend/uikit";
import alpha from "color-alpha";
import { DefaultTheme } from "styled-components";
import merge from "lodash/merge";

const brandColor = "#C742F7";

const brandColors = COLOR_OPACITIES.brand.reduce(
    (generatedColors, opacity) => ({
        ...generatedColors,
        [`brand${opacity}`]: alpha(brandColor, opacity / 100),
    }),
    {},
);

const light: Partial<DefaultTheme> = {
    colors: {
        brand: brandColor,
        ...brandColors,
        textActiveFG: brandColor,
        action: brandColor,
        action1: alpha(brandColor, 0.72),
        action2: alpha(brandColor, 0.32),
        action3: alpha(brandColor, 0.16),
        action4: alpha(brandColor, 0.12),
    }
};


export const themes = merge(baseTheme, { light });
