import { VStack } from "@fm-frontend/uikit";
import { QuoteSide, RfqRequestSide } from "feature/rfq/apiHooks/useRfqWs";
import styled from "styled-components";
import { QUOTE_CONTAINER_HEIGHT } from "./consts";
import { LoadingQuote } from "./quotes/LoadingQuote";
import { QuoteListItem } from "./quotes/QuoteListItem";

export type SuccessedQuote = {
    providerId: number;
    price: bigint;
    expiresAt: number;
};
type RejectedQuote = {
    providerId: number;
    rejectReason: string;
};
type FailedQuote = {
    providerId: number;
    error: string;
};
export type Quote = SuccessedQuote | RejectedQuote | FailedQuote;

const QuotesListContainer = styled(VStack)`
    max-height: ${QUOTE_CONTAINER_HEIGHT * 3.5}px;
    overflow-y: auto;
    border-top: 1px solid ${(p) => p.theme.colors.ui8};
    border-bottom: 1px solid ${(p) => p.theme.colors.ui8};
`;

export const QuotesList = ({
    quotes,
    quotesCount,
    requestSide,
    assetCurrency,
    onQuoteSelected,
    executingQuote,
    actionIsInactive,
}: {
    quotes: Quote[];
    quotesCount: number;
    requestSide: RfqRequestSide;
    assetCurrency: string;
    onQuoteSelected: (options: { providerId: number; side: QuoteSide }) => void;
    executingQuote: null | {
        providerId: number;
        side: QuoteSide;
    };
    actionIsInactive: boolean;
}) => {
    // We do not support BOTH
    if (requestSide === "BOTH") {
        return null;
    }

    return (
        <QuotesListContainer>
            {quotes?.map((quote) => (
                <QuoteListItem
                    key={quote.providerId}
                    quote={quote}
                    side={requestSide}
                    assetCurrency={assetCurrency}
                    onClick={() =>
                        onQuoteSelected({ providerId: quote.providerId, side: requestSide })
                    }
                    executingQuote={executingQuote}
                    actionIsInactive={actionIsInactive}
                />
            ))}
            {Array(quotesCount - quotes.length)
                .fill(null)
                .map((_, i) => (
                    <LoadingQuote key={i} side={requestSide} />
                ))}
        </QuotesListContainer>
    );
};
