import { useInstruments } from "hooks";
import { useMemo } from "react";
import { InstrumentTableData } from "../types";
import { useCounterpartiesWithTypes } from "./useCounterpartiesWithTypes";
import { useDefaultMarkups } from "./useDefaultMarkups";
import { useInstrumentMarkups } from "../../../../../../../hooks/useInstrumentMarkups";

export const useTableData = () => {
    const { instruments, isLoading: isInstrumentsLoading } = useInstruments();
    const {
        instrumentMarkups,
        isLoading: isInstrumentMarkupsLoading,
        mutate,
    } = useInstrumentMarkups();
    const { defaultMarkups, isLoading: isDefaultMarkupsLoading } = useDefaultMarkups();
    const { counterparties, cpIds, isLoading: isCpsLoading } = useCounterpartiesWithTypes();

    const instrumentsData: InstrumentTableData[] = useMemo(
        () =>
            Object.values(instruments)
                .sort(({ instrumentName: name1 }, { instrumentName: name2 }) =>
                    name1.localeCompare(name2),
                )
                .map((instrument) => ({
                    key: instrument.id,
                    instrument,
                    counterparties: cpIds
                        .map((cpId) => ({
                            id: cpId,
                            name: counterparties[cpId].name,
                            linkedInstrumentName: instrument.instrumentName,
                            cpType: counterparties[cpId].cpType,
                            defaultMarkup: defaultMarkups[cpId],
                            ...(instrumentMarkups[instrument.instrumentName]?.[cpId] ?? {}),
                        }))
                        .filter(({ cpType }) => cpType !== "maker")
                        .sort(({ name: name1, id: id1 }, { name: name2, id: id2 }) =>
                            name1 !== name2 ? name1.localeCompare(name2) : id1 - id2,
                        ),
                })),
        [cpIds, defaultMarkups, counterparties, instruments, instrumentMarkups],
    );

    const isLoading =
        isInstrumentsLoading ||
        isInstrumentMarkupsLoading ||
        isDefaultMarkupsLoading ||
        isCpsLoading;

    return {
        instrumentsData,
        refetchInstrumentsData: mutate,
        isLoading,
    };
};
