import { settingSlice } from "feature/settings";
import { useInstruments } from "hooks";
import { useFavoriteInstruments } from "hooks/useFavoriteInstruments";
import { useCallback, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "store";
import { useIsPrimeBrokerUser } from "store/hooks";
import { useActiveFirmInstrument } from "./useActiveFirmInstrument";
import { useAuthorizedClientId } from "./useAuthorizedClientId";

const MAX_SUBSCRIBED_BOOKS = 6;

export const useTradingInstruments = () => {
    const [selectedInstrument, setSelectedInstrument, deleteSelectedInstrument] =
        useActiveFirmInstrument();
    const dispatch = useDispatch();
    const { instruments } = useInstruments();
    const { favoriteInstruments: favoriteInstrumentsIds } = useFavoriteInstruments();
    const { authorizedClientId } = useAuthorizedClientId();
    const clientId = useSelector((state: IRootState) => state.authentication.clientData?.clientId);
    const isPrimeBrokerUser = useIsPrimeBrokerUser();

    useLayoutEffect(() => {
        if (selectedInstrument && favoriteInstrumentsIds.length === 0) {
            deleteSelectedInstrument();
            return;
        }
        if (!selectedInstrument && favoriteInstrumentsIds.length !== 0) {
            setSelectedInstrument(favoriteInstrumentsIds[0]);
        }
    }, [
        selectedInstrument,
        favoriteInstrumentsIds,
        deleteSelectedInstrument,
        setSelectedInstrument,
    ]);

    const favoriteInstruments = useMemo(
        () =>
            instruments.filter((instrument) =>
                favoriteInstrumentsIds.includes(instrument.instrumentName),
            ),
        [instruments, favoriteInstrumentsIds],
    );

    const setFavorites = useCallback(
        (newFavoriteList: string[]) => {
            if (isPrimeBrokerUser && authorizedClientId) {
                dispatch(
                    settingSlice.actions.setFavorites({
                        clientId: authorizedClientId,
                        favoriteInstruments: newFavoriteList,
                    }),
                );

                return;
            }
            if (clientId) {
                dispatch(
                    settingSlice.actions.setFavorites({
                        clientId,
                        favoriteInstruments: newFavoriteList,
                    }),
                );

                return;
            }
        },
        [clientId, authorizedClientId, isPrimeBrokerUser],
    );

    const addInstrument = useCallback(
        (instrumentName: string) => {
            const isNotInFavoriteList = !favoriteInstrumentsIds.includes(instrumentName);

            if (isNotInFavoriteList) {
                const newFavoritesInstruments = [...favoriteInstrumentsIds, instrumentName];
                setFavorites(newFavoritesInstruments);
            }

            setSelectedInstrument(instrumentName);
        },
        [favoriteInstrumentsIds, setSelectedInstrument, setFavorites],
    );

    const removeInstrument = useCallback(
        (instrumentName: string) => {
            const removeInstrumentIndex = favoriteInstrumentsIds.indexOf(instrumentName);

            if (removeInstrumentIndex === -1) {
                return;
            }

            const newFavoriteList = favoriteInstrumentsIds.filter(
                (item) => item !== instrumentName,
            );
            setFavorites(newFavoriteList);

            const newSelectedInstrument =
                favoriteInstrumentsIds[removeInstrumentIndex - 1] ??
                favoriteInstrumentsIds[removeInstrumentIndex + 1];

            if (newSelectedInstrument) {
                setSelectedInstrument(newSelectedInstrument);
            } else {
                deleteSelectedInstrument();
            }
        },
        [favoriteInstrumentsIds, setSelectedInstrument, deleteSelectedInstrument, setFavorites],
    );

    const selectInstrument = useCallback(
        (instrumentName: string) => {
            setSelectedInstrument(instrumentName);
        },
        [setSelectedInstrument],
    );

    const isTopFavoriteInstrument = useCallback(
        (instrumentName: string) =>
            favoriteInstrumentsIds.indexOf(instrumentName) < MAX_SUBSCRIBED_BOOKS,
        [favoriteInstrumentsIds],
    );

    return {
        addInstrument,
        removeInstrument,
        selectInstrument,
        favoriteInstruments,
        favoriteInstrumentsIds,
        selectedInstrument: Boolean(selectedInstrument) ? selectedInstrument : undefined,
        isTopFavoriteInstrument,
    };
};
