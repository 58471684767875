import {
    BasicButton,
    CardItem,
    Flex,
    FormProgress,
    H2,
    Header,
    HeaderTitle,
    HStack,
    Icons,
    ModalButton,
    PBold,
    ShadowCard,
    ShadowItem,
    Tooltip,
    TooltipContainer,
} from "@fm-frontend/uikit";
import { onMobile } from "@fm-frontend/uikit/src/const";
import { EmDash } from "@fm-frontend/utils";
import React from "react";
import styled, { useTheme } from "styled-components";
import { CancelInvitationForm } from "components/CancelInvitationForm";
import { ResentInvitationForm } from "components/ResentInvitationForm";
import { Invite, InviteStatus, useInvitesList } from "hooks/useInvitesList";
import { toLongDateString } from "utils/format";
import { SUBACCOUNT_TYPE_TITLES } from "./consts";
import { Status } from "./Status";

const FormProgressContainer = styled.div`
    margin-bottom: 16px;
`;

export const CardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px 0;

    ${TooltipContainer} {
        overflow: hidden;
        & > ${PBold} {
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 4px;
        }
    }

    ${onMobile} {
        flex-direction: column;
        padding: unset;
        width: 100%;
        gap: 24px;
    }
`;

export const InviteDetails: React.VFC<{
    invite: Invite;
    onBackClick: () => void;
}> = ({ invite, onBackClick }) => {
    const { colors } = useTheme();
    const { mutate: mutateInvitesList } = useInvitesList();

    return (
        <div>
            <FormProgressContainer>
                <FormProgress onBackClick={onBackClick}></FormProgress>
            </FormProgressContainer>
            <ShadowCard isDefault>
                <ShadowItem padding="16px 16px 0 16px" isUnderlined>
                    <Header>
                        <HeaderTitle
                            title={
                                <Flex spacing={8} alignItems="center" width="100%">
                                    <H2>{invite.name}</H2>
                                </Flex>
                            }
                        />
                        <Status variant={invite.status} />
                    </Header>
                </ShadowItem>
                <ShadowItem padding="16px">
                    <CardsContainer>
                        <CardItem width="50%" title="Display name">
                            <PBold>{invite.name}</PBold>
                        </CardItem>
                        <CardItem width="50%" title="Legal name">
                            <PBold>{EmDash}</PBold>
                        </CardItem>
                        <CardItem width="50%" title="Email">
                            <Tooltip content={invite.email}>
                                <PBold>{invite.email}</PBold>
                            </Tooltip>
                        </CardItem>
                        <CardItem width="50%" title="Invited">
                            <PBold>{toLongDateString(invite.createdAt)}</PBold>
                        </CardItem>
                        <CardItem width="50%" title="Type">
                            <PBold>{SUBACCOUNT_TYPE_TITLES[invite.clientType]}</PBold>
                        </CardItem>
                        {invite.status === InviteStatus.Sent && (
                            <CardItem width="50%" title="Expired">
                                <PBold>{toLongDateString(invite.expiresAt)}</PBold>
                            </CardItem>
                        )}
                        {invite.status === InviteStatus.Expired && (
                            <CardItem width="50%" title="Expired">
                                <PBold>{toLongDateString(invite.expiresAt)}</PBold>
                            </CardItem>
                        )}
                    </CardsContainer>
                </ShadowItem>
                {invite.status === InviteStatus.Sent && (
                    <ShadowItem padding="16px">
                        <HStack justifyContent="space-between">
                            <ModalButton
                                beforeModalClose={mutateInvitesList}
                                renderModalContent={(closeModal) => (
                                    <CancelInvitationForm referralEmail={invite.email} onDone={() => {
                                        closeModal();
                                        onBackClick();
                                    }} />
                                )}
                                renderButton={(handleClick) => (
                                    <BasicButton size="small" onClick={handleClick}>
                                        <Icons.Disabled color={colors.negative100} />
                                        Cancel invitation
                                    </BasicButton>
                                )}
                            />
                        </HStack>
                    </ShadowItem>
                )}
                {invite.status === InviteStatus.Expired && (
                    <ShadowItem padding="16px">
                        <HStack justifyContent="space-between">
                            <ModalButton
                                beforeModalClose={mutateInvitesList}
                                renderModalContent={(closeModal) => (
                                    <ResentInvitationForm invite={invite} onDone={() => {
                                        closeModal();
                                        onBackClick();
                                    }} />
                                )}
                                renderButton={(handleClick) => (
                                    <BasicButton size="small" onClick={handleClick}>
                                        <Icons.Reload color={colors.ui32} />
                                        Resend invitation
                                    </BasicButton>
                                )}
                            />
                        </HStack>
                    </ShadowItem>
                )}
            </ShadowCard>
            <ShadowCard isDefault>
                <ShadowItem padding="16px 16px 0 16px" isUnderlined>
                    <Header>
                        <HeaderTitle
                            title={
                                <Flex spacing={8} alignItems="center" width="100%">
                                    <H2>Terms and conditions</H2>
                                </Flex>
                            }
                        />
                    </Header>
                </ShadowItem>
                <ShadowItem padding="16px">
                    <CardsContainer>
                        <CardItem width="50%" title="Status">
                            <PBold>Not signed</PBold>
                        </CardItem>
                        <CardItem width="50%" title="Date and time">
                            <PBold>{EmDash}</PBold>
                        </CardItem>
                        <CardItem width="50%" title="Signatory name">
                            <PBold>{EmDash}</PBold>
                        </CardItem>
                        <CardItem width="50%" title="Position">
                            <PBold>{EmDash}</PBold>
                        </CardItem>
                    </CardsContainer>
                </ShadowItem>
            </ShadowCard>
        </div>
    );
};
