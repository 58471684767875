import { BasicInput } from "@fm-frontend/uikit";
import React, { useCallback, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CryptoAddressFormInput } from "../../../../types";
import { CryptoFormContext } from "../../CryptoFormContext";

type MemoOrTagControlProps = {
    index: number;
    rowId: string;
    disabled: boolean;
};

export const MemoOrTagControl: React.VFC<MemoOrTagControlProps> = ({ index, rowId, disabled }) => {
    const {
        register,
        formState: { errors, defaultValues },
        watch,
    } = useFormContext<CryptoAddressFormInput>();
    const memoOrTagRequired = watch(`addresses.${index}.memoOrTagRequired` as const);

    const { updateDirtyRow } = useContext(CryptoFormContext);
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const defaultValue = defaultValues?.addresses?.[index]?.memoOrTag;
            updateDirtyRow(rowId, "memoOrTag", defaultValue !== event.target.value);
        },
        [updateDirtyRow, rowId],
    );

    if (!memoOrTagRequired) {
        return <></>;
    }

    return (
        <BasicInput
            disabled={disabled}
            _size="medium"
            placeholder="Memo"
            error={errors?.addresses?.[index]?.memoOrTag?.message}
            {...register(`addresses.${index}.memoOrTag`, {
                onChange: handleChange,
            })}
        />
    );
};
