import { MODAL_TOP_OFFSET } from "@fm-frontend/uikit";
import { TEST_ALERT_HEIGHT } from "components/TestEnvAlert";

import { IS_TEST_ENV } from "./env";

export const FIXED_MODAL_STYLE = {
    overlay: {
        overflow: "auto",
    },
    content: {
        top: `${IS_TEST_ENV ? MODAL_TOP_OFFSET + TEST_ALERT_HEIGHT : MODAL_TOP_OFFSET}px`,
        left: "50%",
        transform: "translate(-50%)",
        paddingBottom: MODAL_TOP_OFFSET,
        backgroundColor: "transparent",
        boxShadow: "none",
    },
};
