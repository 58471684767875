import styled from "styled-components";
import { selectors } from "../../../style/theme";
import { Pill, UserCard } from "../styled";

export const OwnerPill = styled(Pill)`
    background: ${(p) => p.theme.colors.action3};
    color: ${selectors.textActiveFG};

    ${UserCard} > & {
        margin-left: auto;
    }
`;
