import { AnySchema, boolean, number, object, string } from "yup";
import { AdjustType } from "feature/assetsControl/types";
import { LIMIT_FORMAT_REGEX, PERCENT_DECIMAL_PRECISION_REGEX } from "feature/counterparties/utils";
import { MAX_OVERNIGHT_VALUE, MIN_OVERNIGHT_VALUE } from "const/overnight";
import { ValidationMessages } from "utils/validationMessages";
import { AssetsModalInputs } from "./AssetsModal";

const overnightFeeSchema = number()
    .transform((value, originalValue) => (originalValue === "" ? undefined : value))
    .when(["overnightFees", "adjust"], {
        is: (overnightFees: boolean, adjust: AdjustType) =>
            Boolean(overnightFees && adjust === "overnightFees"),
        then: (schema) =>
            schema
                .required(ValidationMessages.REQUIRED)
                .typeError(ValidationMessages.INVALID)
                .test("maxDigitsAfterDecimal", ValidationMessages.INVALID, (number?: number) => {
                    if (number === undefined) {
                        return false;
                    }

                    return PERCENT_DECIMAL_PRECISION_REGEX.test(String(number));
                })
                .min(MIN_OVERNIGHT_VALUE, ValidationMessages.MIN_OVERNIGHT_ERROR)
                .max(MAX_OVERNIGHT_VALUE, ValidationMessages.MAX_OVERNIGHT_ERROR),
        otherwise: (schema) => schema.optional(),
    });

export const schema = object<Record<keyof AssetsModalInputs, AnySchema>>().shape({
    adjust: string()
        .transform((value, originalValue) => (originalValue === "" ? null : value))
        .nullable()
        .required(ValidationMessages.REQUIRED),
    limitPerAsset: boolean(),
    grossLimit: string()
        .transform((value, originalValue) => (originalValue === "" ? undefined : value))
        .when(["limitPerAsset", "adjust"], {
            is: (limitPerAsset: boolean, adjust: AdjustType) =>
                Boolean(limitPerAsset && adjust === "tradingLimits"),
            then: (schema) =>
                schema
                    .required(ValidationMessages.REQUIRED)
                    .matches(LIMIT_FORMAT_REGEX, ValidationMessages.INVALID_LIMIT),
            otherwise: (schema) => schema.optional(),
        }),
    limitCurrency: string()
        .nullable()
        .transform((value, originalValue) => (originalValue === "" ? undefined : value))
        .when(["limitPerAsset", "adjust"], {
            is: (limitPerAsset: boolean, adjust: AdjustType) =>
                Boolean(limitPerAsset && adjust === "tradingLimits"),
            then: (schema) => schema.nullable().required(ValidationMessages.REQUIRED),
            otherwise: (schema) => schema.optional(),
        }),
    overnightFees: boolean(),
    positiveRate: overnightFeeSchema,
    negativeRate: overnightFeeSchema,
});
