import { InviteStatus } from "hooks/useInvitesList";
import { ListItem, ListItemType } from "./types";
import { SubaccountStatus } from "./api";

const collator = new Intl.Collator();

const STATUS_WEIGHTS = {
    [SubaccountStatus.Active]: 4,
    [InviteStatus.Accepted]: 4,
    [SubaccountStatus.Pending]: 3,
    [InviteStatus.Sent]: 3,
    [SubaccountStatus.Disabled]: 2,
    [InviteStatus.Expired]: 1,
    "": 0,
};

export const getLabelWithHighlightedSearch = (
    input: string | undefined,
    match: string,
    getHighlightedText = (str: string) => <mark>{str}</mark>,
) => {
    if (!input || match === "") {
        return input;
    }

    const normalizedInput = input.toLowerCase();
    const normalizedMatch = match.toLowerCase();
    const parts = normalizedInput.split(normalizedMatch);

    if (parts.length < 2) {
        return input;
    }

    const { result } = parts.reduce(
        (acc, part, partIndex) => {
            const matchPart = (
                <>
                    {acc.result}
                    {input.substring(acc.currentIdx, acc.currentIdx + part.length)}
                    {partIndex !== parts.length - 1 &&
                        getHighlightedText(
                            input.substring(acc.currentIdx + part.length, acc.currentIdx + part.length + match.length),
                        )}
                </>
            );

            return { result: matchPart, currentIdx: acc.currentIdx + part.length + match.length };
        },
        { result: <></>, currentIdx: 0 },
    );

    return result;
};

export const getListItemEmail = (item: ListItem) => {
    if (item.type === ListItemType.Subaccount) {
        return item.item.info.email;
    }
    if (item.type === ListItemType.Invite) {
        return item.item.email;
    }
    return "";
}

export const getListItemType = (item: ListItem) => {
    if (item.type === ListItemType.Subaccount) {
        return item.item.type;
    }
    if (item.type === ListItemType.Invite) {
        return item.item.clientType;
    }
    return "";
}

export const getListItemName = (item: ListItem) => {
    if (item.type === ListItemType.Subaccount) {
        return item.item.info.username;
    }
    if (item.type === ListItemType.Invite) {
        return item.item.name;
    }
    return "";
}

export const getListItemStatus = (item: ListItem) => {
    if (item.type === ListItemType.Subaccount) {
        return item.item.status;
    }
    if (item.type === ListItemType.Invite) {
        return item.item.status;
    }
    return "";
}

export const LIST_ITEMS_SORT_FUNCTIONS = {
    type: (a: ListItem, b: ListItem) => {
        const t1 = getListItemType(a);
        const t2 = getListItemType(b);
        if (t1 === t2) {
            const u1 = getListItemName(a);
            const u2 = getListItemName(b);
            return collator.compare(u1, u2);
        }
        return collator.compare(t1, t2);
    },
    status: (a: ListItem, b: ListItem) => {
        const s1 = getListItemStatus(a);
        const s2 = getListItemStatus(b);
        if (s1 === s2) {
            const u1 = getListItemName(a);
            const u2 = getListItemName(b);
            return collator.compare(u1, u2);
        }
        return STATUS_WEIGHTS[s2] - STATUS_WEIGHTS[s1];
    },
};
