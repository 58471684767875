export const BITMASK_UTILS = {
    isBitKeyApplied(bitmask: number, key: number) {
        return (bitmask & key) === key;
    },

    toggleBitKey(bitmask: number, key: number) {
        return bitmask ^ key;
    },

    combineFlags(...flags: number[]) {
        return flags.reduce((acc, flag) => acc | flag, 0);
    },
};
