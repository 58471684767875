import { IconButton, Icons } from "@fm-frontend/uikit";
import { useAssetsTabContext } from "../../../assetsTabContext";

export const AssetActionCell = ({ asset }: { asset: string }) => {
    const { handleAssetRowEditClick } = useAssetsTabContext();

    return (
        <IconButton
            variant="plain"
            Icon={Icons.Settings}
            onClick={() => handleAssetRowEditClick(asset)}
        />
    );
};
