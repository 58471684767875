import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { post } from "api";
import { useMemo } from "react";
import { usePositionsSnapshot, usePrimeBrokerViewType } from "store/hooks";
import useSWR from "swr";
import { CounterpartyLimitByAsset } from "types";
import { displayError } from "utils";

export const usePositionsMap = () => {
    const { data: positions, isLoading } = usePositionsSnapshot();

    const positionsMap = useMemo(() => {
        return positions.reduce<Record<number, Record<string, bigint>>>(
            (acc, [currencyName, position, cpId]) => {
                acc[cpId] = acc[cpId] ?? {};
                acc[cpId][currencyName] = BigInt(position);
                return acc;
            },
            {},
        );
    }, [positions]);

    return {
        positionsMap,
        isLoading,
    };
};

const fetcher = ([url, filter]: [string, "external" | "subaccounts"]) => post(url, { filter });

export const useLimitsMap = () => {
    const primeBrokerViewType = usePrimeBrokerViewType();
    const apiUrl = "cAssetLimits";
    const filter = primeBrokerViewType === "subaccounts"
        ? "subaccounts"
        : "external";

    const { data, isLoading, isValidating } = useSWR([apiUrl, filter], fetcher, {
        onError: displayError,
        fallbackData: EMPTY_ARRAY,
        revalidateOnMount: true,
        revalidateOnFocus: false,
    });

    const limitsMap = useMemo(() => {
        return data.reduce(
            (
                acc: any,
                [
                    cpId,
                    asset,
                    grossLimit,
                    limitCurrency,
                    cpGrossLimit,
                    cpLimitCurrency,
                ]: CounterpartyLimitByAsset,
            ) => {
                acc[cpId] = acc[cpId] ?? {};
                acc[cpId][asset] = {
                    grossLimit: grossLimit !== null ? BigInt(grossLimit) : null,
                    limitCurrency,
                    cpGrossLimit:
                        cpGrossLimit !== null && cpGrossLimit !== undefined
                            ? BigInt(cpGrossLimit)
                            : null,
                    cpLimitCurrency,
                };
                return acc;
            },
            {},
        ) as Record<
            number,
            Record<
                string,
                {
                    grossLimit: bigint | null;
                    limitCurrency: string | null;
                    cpGrossLimit: bigint | null;
                    cpLimitCurrency: string | null;
                }
            >
        >;
    }, [data]);

    return {
        limitsMap,
        isLoading,
        isValidating,
    };
};
