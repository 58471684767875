import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "api";
import {
    confirmSignIn as confirmSignInService,
    getSession,
    refreshSession,
    signIn as signInService,
    signOut as signOutService,
    signUp as signUpService,
    verifyTOTPSetup,
} from "services/authService";
import { InviteToken } from "types";
import { decodeInviteJwtToken } from "utils/decodeInviteJwtToken";

export const updateClientData = createAction("updateClientData");

const ensureUser = async (count = 3): Promise<string | null> => {
    try {
        const userSession = await getSession();
        const data = await post("ensureUser", {
            accessToken: userSession.accessToken?.toString(),
        });

        if (data.tokenRefreshNeeded) {
            await refreshSession();
        }
    } catch (e) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        count--;
        if (count > 0) {
            return await ensureUser(count);
        }
        if (e instanceof Error) {
            return e?.message;
        }
        return String(e);
    }
    return null;
};

export const verifyMfa = createAsyncThunk(
    "auth/verifyMfa",
    async ({ code }: { code: string }, { rejectWithValue }) => {
        try {
            await verifyTOTPSetup({
                code,
            });
            await ensureUser();
        } catch (error) {
            const message = error instanceof Error ? error.message : "Unknown error";
            const code = error instanceof Error ? error.name : "unknown_error";

            return rejectWithValue({
                message: message,
                code: code,
            });
        }
    },
);

export const confirmSignIn = createAsyncThunk(
    "auth/confirmSignIn",
    async ({ code }: { code: string }, { rejectWithValue }) => {
        try {
            await confirmSignInService({
                challengeResponse: code,
            });
            await ensureUser();
        } catch (error) {
            const message = error instanceof Error ? error.message : "Unknown error";
            const code = error instanceof Error ? error.name : "unknown_error";

            return rejectWithValue({
                message: message,
                code: code,
            });
        }
    },
);
export const signIn = createAsyncThunk(
    "auth/signIn",
    async ({ email, password }: { email: string; password: string }, { rejectWithValue }) => {
        try {
            return await signInService({
                email,
                password,
            });
        } catch (error: unknown) {
            const message = "Incorrect username or password";
            const code = error instanceof Error ? error.name : "unknown_error";

            return rejectWithValue({
                message: message,
                code: code,
            });
        }
    },
);

export const signUp = createAsyncThunk(
    "auth/signUp",
    async (
        {
            username,
            password,
            invite,
        }: {
            username: string;
            password: string;
            invite: string;
        },
        { rejectWithValue },
    ) => {
        try {
            const inviteToken: InviteToken | undefined = decodeInviteJwtToken(invite);

            if (inviteToken === undefined) {
                throw new Error("No invite token");
            }

            await signUpService({
                username,
                password,
                invite,
            });

            return await signInService({
                email: inviteToken.email,
                password,
            });
        } catch (error: unknown) {
            const message = error instanceof Error ? error.message : "Unknown error";
            const code = error instanceof Error ? error.name : "unknown_error";

            return rejectWithValue({
                message: message,
                code: code,
            });
        }
    },
);
export const signOut = createAsyncThunk("auth/signOut", async () => {
    await signOutService();
});

export const completeNewPassword = createAsyncThunk(
    "auth/completeNewPassword",
    async ({ newPassword }: { newPassword: string }, { rejectWithValue }) => {
        try {
            const confirmSignInResponse = await confirmSignInService({
                challengeResponse: newPassword,
            });

            return confirmSignInResponse;
        } catch (error: unknown) {
            const message = error instanceof Error ? error.message : "Unknown error";
            const code = error instanceof Error ? error.name : "unknown_error";

            return rejectWithValue({
                message: message,
                code: code,
            });
        }
    },
);
