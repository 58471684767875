import { IconButton, Icons, ModalButton } from "@fm-frontend/uikit";
import { ROUTES } from "feature/app/router";
import { pushPreloadedValues } from "feature/form/initialValuesUtils";
import React from "react";
import { useHistory } from "react-router";
import { CancelInvitationForm } from "components/CancelInvitationForm";
import { InviteStatus, useInvitesList } from "hooks/useInvitesList";
import { useIsMakerUser } from "store/hooks";
import { AddNewTakerForm } from "../AddNewTakerForm";

type ReferralsActionProps = {
    userId: number;
    referralEmail: string;
    status: InviteStatus;
};

export const ReferralsAction: React.FC<ReferralsActionProps> = ({
    userId,
    referralEmail,
    status,
}) => {
    const history = useHistory();
    const isMakerUser = useIsMakerUser();
    const { mutate: mutateInvitesList } = useInvitesList();

    if (isMakerUser && status === "Accepted") {
        return (
            <IconButton
                variant="plain"
                Icon={Icons.Settings}
                onClick={(e) => {
                    e.preventDefault();
                    pushPreloadedValues("limits", {
                        counterpartyId: String(userId),
                    });
                    history.push(ROUTES.riskManagement);
                }}
            />
        );
    }
    if (status === "Sent") {
        return (
            <ModalButton
                beforeModalClose={mutateInvitesList}
                renderModalContent={(closeModal) => (
                    <CancelInvitationForm referralEmail={referralEmail} onDone={closeModal} />
                )}
                renderButton={(handleClick) => (
                    <IconButton
                        title="Cancel"
                        variant="plain"
                        Icon={Icons.X}
                        onClick={handleClick}
                    />
                )}
            />
        );
    }
    if (status === "Expired") {
        return (
            <ModalButton
                beforeModalClose={mutateInvitesList}
                renderModalContent={(closeModal) => (
                    <AddNewTakerForm onDone={closeModal} referralEmail={referralEmail} />
                )}
                renderButton={(handleClick) => (
                    <IconButton
                        title="Resend"
                        variant="plain"
                        Icon={Icons.Reload}
                        onClick={handleClick}
                    />
                )}
            />
        );
    }

    return null;
};
