import styled from "styled-components";

const CommonCheckmarkContainer = styled.span`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: ${(p) => p.theme.colors.uiWhite100};
    border: 1px solid ${(p) => p.theme.colors.ui12};
    cursor: pointer;

    :hover {
        border: 1px solid ${(p) => p.theme.colors.ui32};
    }
`;

export const SmallCheckmarkContainer = styled(CommonCheckmarkContainer)`
    width: 16px;
    min-width: 16px;
    height: 16px;
    border-radius: 4px;
`;
export const MediumCheckmarkContainer = styled(CommonCheckmarkContainer)`
    width: 18px;
    min-width: 18px;
    height: 18px;
    border-radius: 4px;
`;
export const LargeCheckmarkContainer = styled(CommonCheckmarkContainer)`
    width: 28px;
    min-width: 28px;
    height: 28px;
    border-radius: 6px;
`;

export const CheckboxContainer = styled.label`
    input {
        display: none;

        &:checked ~ span {
            background: ${(p) => p.theme.colors.brand100};
            border-color: ${(p) => p.theme.colors.brand100};
        }

        &:disabled {
            ~ span {
                cursor: not-allowed;
            }
            &:checked ~ span {
                background: ${(p) => p.theme.colors.ui32};
                border-color: transparent;
            }
            &:not(:checked) ~ span {
                border-color: ${(p) => p.theme.colors.ui8};
            }
        }
    }
`;
