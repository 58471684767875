import styled, { css } from "styled-components";
import { VStack } from "../../layout";
import { TextSmall } from "../../typography";
import { DropdownSize } from "../Dropdown/Dropdown.types";
import { BasicContainer } from "../Trigger/styled";
export const PopoverContent = styled.div`
    position: relative;
    display: flex;
    width: 288px;
    align-items: center;
    min-height: 20px;
    max-height: 296px;
    overflow-y: auto;
    flex-direction: column;
    background: ${(p) => p.theme.colors.uiWhite100};
    padding: 4px;
    box-shadow: 0 4px 16px 0 ${(p) => p.theme.colors.ui8}, 0 0 0 1px ${(p) => p.theme.colors.ui12};
    border-radius: 8px;
`;

export const DropdownGroup = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    gap: 4px;
`;

export const Container = styled.div<{
    size?: DropdownSize;
    isDisabled?: boolean;
    fullWidth?: boolean;
}>`
    display: flex;
    flex-direction: column;
    width: ${(p) => (p.fullWidth ? "100%" : "fit-content")};
    gap: 4px;

    ${(p) =>
        p.size === "large" &&
        css`
            ${BasicContainer} {
                height: 44px;
                padding-left: 12px;
            }
        `}
    ${(p) =>
        p.size === "medium" &&
        css`
            ${BasicContainer} {
                height: 32px;
                padding-left: 6px;
            }
        `}

    ${(p) =>
        p.isDisabled &&
        css`
            ${TextSmall}, input, input::placeholder {
                color: ${p.theme.colors.ui20} !important;
            }
        `}
`;

export const GroupLabel = styled.label`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;

    color: ${(p) => p.theme.colors.ui72};
`;

export const OptionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;

    ${GroupLabel} {
        width: 100%;
    }
`;

export const MatchedPart = styled.span<{ fontColor?: string }>`
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.005em;
    color: ${(p) => p.fontColor ?? p.theme.colors.ui72};
    mark {
        color: ${(p) => p.fontColor ?? p.theme.colors.ui72};
        background-color: ${(p) => p.theme.colors.brand32};
    }
`;

export const OptionSheetInfoBanner = styled(VStack)`
    width: 288px;
    padding: 8px 12px;
    border-radius: 8px;
    background: ${(p) => p.theme.colors.ui96};

    box-shadow: 0 1px 2px 0 ${(p) => p.theme.colors.ui24},
        0 0.5px 0 0 ${(p) => p.theme.colors.ui20} inset;

    color: ${(p) => p.theme.colors.uiWhite100};
    white-space: break-spaces;
    overflow-wrap: break-word;
`;
