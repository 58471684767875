import styled from "styled-components";

export const Divider = styled.hr`
    width: 100%;
    margin: 0;
    height: 0;
    border: none;
    border-top: 1px solid ${(p) => p.theme.colors.ui12};
    overflow: visible;
    text-align: center;
`;
