import styled, { DefaultTheme, StyledComponent } from "styled-components";
import { TextSmall } from "../typography";

export type HintVariant = "hint" | "error";

const Hint = styled.div`
    ${TextSmall} {
        color: ${(p) => p.theme.colors.ui52};
    }
`;

const Error = styled.div`
    ${TextSmall} {
        color: ${(p) => p.theme.colors.negative100};
    }
`;

export const HintVariantComponents: { [Key in HintVariant]: StyledComponent<"div", DefaultTheme, {}, never> } = {
    hint: Hint,
    error: Error,
};
