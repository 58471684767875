import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { usePrimeBrokerViewType, useUserType } from "store/hooks";
import { useOtherUserTypesColumns } from "./columns/otherUserTypesColumns";
import { useSubaccountColumns } from "./columns/subaccountColumns";

export const useColumns = () => {
    const userType = useUserType();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const {
        makerColumns,
        masterColumnsCounterpartiesView,
        masterColumnsSubaccountsView,
        takerColumns,
    } = useOtherUserTypesColumns();
    const subaccountColumns = useSubaccountColumns();

    switch (userType) {
        case "primeBroker":
            return primeBrokerViewType === "counterparties"
                ? masterColumnsCounterpartiesView
                : masterColumnsSubaccountsView;
        case "maker":
            return makerColumns;
        case "taker":
            return takerColumns;
        case "subaccountMaker":
        case "subaccountTaker":
            return subaccountColumns;
        default:
            return EMPTY_ARRAY;
    }
};
