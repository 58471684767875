import { Flex, PSmallBold } from "@fm-frontend/uikit";
import styled from "styled-components";
import { getLimitStatus } from "../../utils";

const Content = styled(Flex)`
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
`;
const Background = styled(Flex)`
    position: absolute;
    flex-direction: row-reverse;
    width: 100%;
    height: 100%;
`;
const PercentBar = styled.div<{ $percent: number }>`
    text-align: right;
    flex: 0 0 ${(p) => p.$percent}%;
    height: 100%;
    background-color: ${(p) => {
        switch (getLimitStatus(p.$percent)) {
            case "normal":
                return p.theme.colors.positive8;
            case "warn":
                return p.theme.colors.attention8;
            case "low":
                return p.theme.colors.negative8;
            default:
                return "transparent";
        }
    }};
`;
const WrappedContent = styled.div`
    flex: 1;
`;
const PercentBadge = styled(PSmallBold)<{ $percent: number }>`
    color: ${(p) => {
        switch (getLimitStatus(p.$percent)) {
            case "normal":
                return p.theme.colors.positive100;
            case "warn":
                return p.theme.colors.attention100;
            case "low":
                return p.theme.colors.negative100;
            default:
                return "transparent";
        }
    }};
    align-items: center;
    justify-content: center;
    padding: 0 4px;
`;

export const PercentBadgeWrapper: React.FC<{ percent: number }> = ({ percent, children }) => {
    const normalizedPercent = Math.round(percent);

    return (
        <Content>
            <Background>
                <PercentBar $percent={percent > 0 ? percent : 0} />
            </Background>
            <WrappedContent>{children}</WrappedContent>
            <PercentBadge $percent={percent}>{normalizedPercent}%</PercentBadge>
        </Content>
    );
};
