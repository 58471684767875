import { HStack, PaddingProps } from "@fm-frontend/uikit";
import { noop } from "@fm-frontend/utils";
import React from "react";
import styled from "styled-components";

const HoverContainer = styled(HStack)`
    cursor: pointer;

    :hover {
        background-color: ${(p) => p.theme.colors.ui4};
    }
`;

interface HoverCellProps extends PaddingProps {
    onHover?: () => void;
    onLeave?: () => void;
}
export const HoverCell: React.FC<HoverCellProps> = ({ onHover = noop, onLeave = noop, children, ...paddingProps }) => (
    <HoverContainer {...paddingProps} onMouseEnter={onHover} onMouseLeave={onLeave}>
        {children}
    </HoverContainer>
);
