import { IS_ONBOARDING_ACTIVE } from "const/env";
import { useIsPrimeBrokerUser, useIsTakerUser, usePrimeBrokerViewType } from "store/hooks";

export const useIsProvidersSectionAvailable = () => {
    const isTakerUser = useIsTakerUser();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();

    return (isTakerUser || isPrimeBrokerUser) && primeBrokerViewType === "counterparties" && IS_ONBOARDING_ACTIVE;
};
