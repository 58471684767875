import { Modal, PrimaryButton } from "@fm-frontend/uikit";
import styled from "styled-components";

const Strong = styled.strong`
    font-weight: 600;
`;
const Content = styled.div`
    background: ${(p) => p.theme.colors.uiWhite100} url("/img/user-created-bg.png") no-repeat right
        top;
    background-size: contain;
    width: 360px;
    box-shadow: 0px 8px 32px ${(p) => p.theme.colors.ui12},
        inset 0px 0px 0px 1px ${(p) => p.theme.colors.ui12};
    border-radius: 12px;
    padding: 16px;
    padding-top: 200px;
`;
const Title = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    color: ${(p) => p.theme.colors.ui100};
    margin: 12px 0;
`;
const Message = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: ${(p) => p.theme.colors.ui72};
    margin: 12px 0;
`;

export const ManualOrderCreatedModal = ({
    onClose,
    isOpen,
}: {
    onClose: () => void;
    isOpen: boolean;
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Content>
                <Title>The manual trade has been added</Title>
                <Message>
                    We changed your positions and added the order to <Strong>Trades history</Strong>
                    .
                </Message>
                <PrimaryButton size="large" fullWidth onClick={onClose}>
                    Continue
                </PrimaryButton>
            </Content>
        </Modal>
    );
};
