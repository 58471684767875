import { PSmall, VStack } from "@fm-frontend/uikit";
import { EmDash } from "const";
import { format } from "date-fns";
import React from "react";
import { CopyCell } from "../CopyCell";

const TIME_FORMAT = "HH:mm:ss";
const DATE_FORMAT = "dd MMM y";
const DATE_TIME_FORMAT = `${DATE_FORMAT}, ${TIME_FORMAT}`;

type DateCellProps = {
    date?: number;
};

export const DateCell: React.FC<DateCellProps> = ({ date }) => {
    if (date === undefined) {
        return <>{EmDash}</>;
    }

    return (
        <CopyCell content={format(date, DATE_TIME_FORMAT)}>
            <VStack>
                <PSmall>{format(date, DATE_FORMAT)}</PSmall>
                <PSmall>{format(date, TIME_FORMAT)}</PSmall>
            </VStack>
        </CopyCell>
    );
};
