import { PrimaryButton } from "@fm-frontend/uikit";
import { Card, Content, Title } from "pages/settings/styled";
import { useUsers } from "../api";
import { UsersList } from "../UsersList";
import { useWizard } from "../wizard/useWizard";
import { Container, Description, DescriptionContainer } from "./styled";

export const UsersManagement = () => {
    const { activeUsers, pendingUsers } = useUsers();
    const { wizard, createNewUser, editUser } = useWizard();

    return (
        <Content>
            <Card>
                <Title>Users and roles</Title>
                <Container>
                    <DescriptionContainer>
                        <Description>
                            Add additional users to your company and set their permissions. All connected to the project
                            users will be shown here and available for anytime changes.
                        </Description>
                        <PrimaryButton size="medium" onClick={createNewUser}>
                            Add new user
                        </PrimaryButton>
                    </DescriptionContainer>
                    <UsersList users={activeUsers} editUser={editUser} type="active" />
                    <UsersList users={pendingUsers} editUser={editUser} type="pending" />
                </Container>
            </Card>
            {wizard}
        </Content>
    );
};
