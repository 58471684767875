import { useModalControl } from "@fm-frontend/utils";
import React, { createContext, useContext } from "react";

type ModalContextType = {
    isModalOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModalContext = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error("useModalContext must be used within a ModalContextProvider");
    }
    return context;
};

export const ModalContextProvider: React.FC = ({ children }) => {
    const modalControl = useModalControl();

    return <ModalContext.Provider value={modalControl}>{children}</ModalContext.Provider>;
};
