import { PSmall } from "@fm-frontend/uikit";
import { CLIENT_TYPE_TITLES } from "const";
import { useIsTakerUser } from "store/hooks";
import styled from "styled-components";
import { ClientType } from "types";

const CpType = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui52};
`;

export const CpTypeCell = ({ cpType }: { cpType: ClientType | undefined }) => {
    const isTakerUser = useIsTakerUser();

    return cpType && !isTakerUser ? <CpType>{CLIENT_TYPE_TITLES[cpType]}</CpType> : null;
};
