import {
    calculateRange,
    EXPAND_TABLE_COLUMN_KEY,
    Paginator,
    PSmall,
    Table,
} from "@fm-frontend/uikit";
import { EmDash } from "@fm-frontend/utils";
import {
    ColumnDef,
    createColumnHelper,
    getCoreRowModel,
    getExpandedRowModel,
    getSortedRowModel,
    SortingState,
} from "@tanstack/react-table";
import { BottomFixTableContainer } from "components/BottomFixTableContainer";
import { HelperContainer } from "components/Helper/HelperContainer";
import { RfqHistoryItem, StatusOfRfqHistoryItem } from "feature/rfq/apiHooks/useLastRfqTrades";
import React, { useState } from "react";
import { useUserType } from "store/hooks";
import styled, { useTheme } from "styled-components";
import { ClientType, DealSide } from "types";
import {
    ClientOrderIdCell,
    CpIdCell,
    DateCell,
    DeltaCell,
    GeneralCell,
    InstrumentCell,
    OrderIdCell,
    PriceCell,
    SideCell,
    SizeCell,
} from "./cells";
import { LinkedTradeCell } from "./cells/LinkedTradeCell";
import { OrderTypeCell } from "./cells/OrderTypeCell";

const SizeHelper = () => (
    <HelperContainer>
        <PSmall>The size is indicated in the base currency</PSmall>
    </HelperContainer>
);

const DeltaHelper = () => (
    <HelperContainer>
        <PSmall>
            The client delta is indicated in the quote currency and shows the difference between
            client's position and your position caused by the trade.
        </PSmall>
        <PSmall>
            A <span>negative client delta</span> means the client's trade volume is less than yours,
            and <span>you gained on a trade</span>.
        </PSmall>
        <PSmall>
            A <span>positive client delta</span> indicates that{" "}
            <span>the client gained on a trade</span>, usually due to a negative markup.
        </PSmall>
    </HelperContainer>
);

const columnHelper = createColumnHelper<RfqHistoryItem>();

type TradesHistoryTableProps = {
    data: RfqHistoryItem[];
    pageItemsCount: number;
    allItemsCount: number;
    isLoading: boolean;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    onPrevClick?: () => void;
    onNextClick?: () => void;
};

const orderIdColumn = columnHelper.accessor((row) => row.id, {
    header: "Order ID",
    cell: (info) => {
        return <OrderIdCell orderId={info.getValue()} />;
    },
    meta: {
        sticky: true,
        lastStickyColumn: false,
    },
});
const orderTypeColumn = columnHelper.display({
    header: "Order type",
    cell: () => {
        return <OrderTypeCell orderType="rfq" />;
    },
    meta: {
        sticky: true,
        lastStickyColumn: true,
    },
});
const cpColumn = columnHelper.accessor(
    (row) => ({ cpId: row.counterpartyId, cpName: row.counterpartyName }),
    {
        header: "Counterparty",
        cell: (info) => {
            const { cpId, cpName } = info.getValue();
            return <CpIdCell cpId={cpId} cpName={cpName} />;
        },
        meta: {
            cellStyleProps: {
                maxWidth: "160px",
            },
        },
    },
);
const instrumentColumn = columnHelper.accessor((row) => row.instrumentName, {
    header: "Instrument",
    cell: (info) => <InstrumentCell instrument={info.getValue()} />,
});
const sideColumn = columnHelper.accessor((row) => row.side, {
    header: "Side",
    cell: (info) => <SideCell side={info.getValue() === "BUY" ? DealSide.Buy : DealSide.Sell} />,
});
const priceColumn = columnHelper.accessor((row) => row.price, {
    header: "Price",
    cell: (info) => {
        const price = info.getValue();
        const { side } = info.row.original;

        if (!price) {
            return EmDash;
        }

        return <PriceCell price={price} side={side === "BUY" ? DealSide.Buy : DealSide.Sell} />;
    },
    meta: {
        headerStyleProps: {
            minWidth: "160px",
            textAlign: "right",
        },
        cellStyleProps: {
            textAlign: "right",
        },
    },
});
const sizeColumn = (header: string) =>
    columnHelper.accessor((row) => row.size, {
        header: header,
        cell: (info) => <SizeCell size={info.getValue()} />,
        meta: {
            headerHelper: <SizeHelper />,
            headerStyleProps: {
                minWidth: "160px",
                textAlign: "right",
            },
            cellStyleProps: {
                textAlign: "right",
            },
        },
    });
const deltaColumn = columnHelper.accessor((row) => row.clientDelta, {
    header: "Client delta",
    cell: (info) => <DeltaCell delta={info.getValue()} />,
    meta: {
        headerHelper: <DeltaHelper />,
        headerHelperOptions: { positions: ["bottom"] },
        headerStyleProps: {
            minWidth: "124px",
            textAlign: "right",
        },
        cellStyleProps: {
            textAlign: "right",
        },
    },
});
const tradeIdColumn = columnHelper.accessor((row) => row.tradeId, {
    header: "Trade ID",
    cell: (info) => <GeneralCell value={info.getValue()} />,
    meta: {
        headerStyleProps: {
            textAlign: "right",
        },
        cellStyleProps: {
            textAlign: "right",
        },
    },
});
const linkedTradeColumn = columnHelper.accessor((row) => row.tradeId, {
    header: "Linked trade",
    cell: (info) => <LinkedTradeCell linkedTradeId={info.getValue()} />,
    meta: {
        headerStyleProps: {
            textAlign: "right",
        },
        cellStyleProps: {
            textAlign: "right",
        },
    },
});
const dateColumn = columnHelper.accessor((row) => row.date, {
    id: "date",
    header: "Date",
    cell: (info) => <DateCell date={info.getValue()} />,
    meta: {
        headerStyleProps: {
            textAlign: "right",
        },
        cellStyleProps: {
            minWidth: "88px",
            textAlign: "right",
        },
    },
});
const clientOrderIdColumn = columnHelper.accessor((row) => row.tradeId, {
    header: "Client order ID",
    cell: (info) => <ClientOrderIdCell clientOrderId={info.getValue()} />,
    meta: {
        headerStyleProps: {
            textAlign: "right",
        },
        cellStyleProps: {
            textAlign: "right",
        },
    },
});

const useStatusSettings = (): Record<
    StatusOfRfqHistoryItem,
    { title: string; color: string; bgColor: string }
> => {
    const { colors } = useTheme();

    return {
        CREATED: {
            title: "Created",
            color: colors.positive100,
            bgColor: colors.positive8,
        },
        CANCELED: {
            title: "Cancelled",
            color: colors.ui52,
            bgColor: colors.ui4,
        },
        EXPIRED: {
            title: "Expired",
            color: colors.ui52,
            bgColor: colors.ui4,
        },
        COMMITTED: {
            title: "Executed",
            color: colors.positive100,
            bgColor: colors.positive8,
        },
    };
};
const StatusContainer = styled.div<{ $color: string; $bgColor: string }>`
    display: inline-block;
    padding: 0 4px;
    border-radius: 4px;
    color: ${({ $color }) => $color};
    background-color: ${({ $bgColor }) => $bgColor};
    font-weight: 600;
`;
const StatusCell = ({ status }: { status: StatusOfRfqHistoryItem }) => {
    const settingsObj = useStatusSettings();
    const { title, color, bgColor } = settingsObj[status];

    return (
        <StatusContainer $color={color} $bgColor={bgColor}>
            {title}
        </StatusContainer>
    );
};
const statusColumn = columnHelper.accessor((row) => row.status, {
    id: "status",
    cell: (rowData) => {
        const status = rowData.getValue();

        return <StatusCell status={status} />;
    },
    header: "Status",
    meta: {
        cellStyleProps: {
            width: "75px",
            textAlign: "right",
        },
        headerStyleProps: {
            textAlign: "right",
        },
    },
});

const generalColumns = [
    orderIdColumn,
    orderTypeColumn,
    cpColumn,
    instrumentColumn,
    sideColumn,
    priceColumn,
    sizeColumn("Total size"),
    statusColumn,
];
const pbPartColumns = [
    deltaColumn,
    dateColumn,
    tradeIdColumn,
    linkedTradeColumn,
    clientOrderIdColumn,
];
const takerPartColumns = [dateColumn, tradeIdColumn, clientOrderIdColumn];

const makeTableColumns = (userType?: ClientType) => {
    let columns: ColumnDef<RfqHistoryItem, any>[] = [...generalColumns];

    if (userType === "primeBroker") {
        columns = [...columns, ...pbPartColumns];
    }
    if (userType === "taker" || userType === "subaccountTaker") {
        columns = [...columns, ...takerPartColumns];
    }

    return columns;
};

export const RfqContent: React.FC<TradesHistoryTableProps> = ({
    data,
    pageItemsCount,
    allItemsCount,
    isLoading,
    hasPrevPage,
    hasNextPage,
    onPrevClick,
    onNextClick,
}) => {
    const userType = useUserType();
    const [sorting, setSorting] = useState<SortingState>([
        {
            id: "date",
            desc: true,
        },
    ]);

    const columns = makeTableColumns(userType);

    return (
        <>
            <BottomFixTableContainer bottomOffset={40}>
                <Table
                    tableOptions={{
                        data,
                        columns,
                        getCoreRowModel: getCoreRowModel(),
                        getExpandedRowModel: getExpandedRowModel(),
                        state: {
                            sorting,
                        },
                        onSortingChange: setSorting,
                        getSortedRowModel: getSortedRowModel(),
                    }}
                    paginator={null}
                    onRowClick={(row, cellId) => {
                        if (row.subRows?.length && cellId === EXPAND_TABLE_COLUMN_KEY) {
                            row.toggleExpanded();
                        }
                    }}
                    isLoading={isLoading}
                />
            </BottomFixTableContainer>
            <Paginator
                range={calculateRange(data, "date")}
                pageItemsCount={pageItemsCount}
                allItemsCount={allItemsCount}
                isLoading={isLoading}
                hasPrevPage={hasPrevPage}
                hasNextPage={hasNextPage}
                onPrevClick={onPrevClick}
                onNextClick={onNextClick}
            />
        </>
    );
};
