import { Cell, ClientId, PBold, PSmall } from "@fm-frontend/uikit";
import { CLIENT_TYPE_TITLES } from "const";
import { FC } from "react";
import { ReactComponent as CounterpartyIcon } from "resources/counterparty_icon.svg";
import styled from "styled-components";
import { ClientType } from "types";

const Container = styled(Cell)`
    padding: 10px 12px;
    gap: 0;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    height: 24px;
`;

const CounterpartyType = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

type CounterpartyInfo = {
    counterpartyId: number | undefined;
    counterpartyType: ClientType | undefined;
    counterpartyName: string | undefined;
};

export const CounterpartyInfo: FC<CounterpartyInfo> = ({ counterpartyId, counterpartyType, counterpartyName }) => {
    return (
        <Container>
            <Header>
                <CounterpartyIcon />
                <PBold>{counterpartyName ?? "Counterparty"}</PBold>
                <ClientId id={counterpartyId} />
            </Header>
            <CounterpartyType>{counterpartyType && CLIENT_TYPE_TITLES[counterpartyType]}</CounterpartyType>
        </Container>
    );
};
