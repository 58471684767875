import { CoinIcon } from "components/CoinIcon";
import { useInstrumentInfo } from "hooks/useInstrumentInfo";
import styled from "styled-components";

const InstrumentIcons = styled.div<{ $coinSize: number }>`
    width: ${(p) => p.$coinSize + (p.$coinSize * 14) / 16}px;
    height: ${(p) => p.$coinSize}px;
    position: relative;

    img {
        top: 1px;
        position: absolute;
        border-radius: 8px;
    }

    img:first-child {
        left: ${(p) => (p.$coinSize * 14) / 16}px;
        -webkit-mask-image: radial-gradient(
            circle closest-corner at -37% center,
            rgba(0, 0, 0, 0) 88%,
            rgba(0, 0, 0, 1) 0
        );
    }
`;

export const InstrumentIcon = ({
    instrument,
    className,
    size = 16,
}: {
    instrument: string;
    className?: string;
    size?: number;
}) => {
    const { baseAsset = "", quoteAsset = "" } = useInstrumentInfo(instrument);

    return (
        <InstrumentIcons className={className} $coinSize={size}>
            <CoinIcon coin={quoteAsset} size={size} />
            <CoinIcon coin={baseAsset} size={size} />
        </InstrumentIcons>
    );
};
