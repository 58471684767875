import { Row } from "@tanstack/react-table";

export enum AddressType {
    Crypto = "crypto",
    Bank = "bank",
}
export const isAddressType = (value: string | null | undefined): value is AddressType => {
    if (value === null || value === undefined) {
        return false;
    }

    return Object.values(AddressType).includes(value as AddressType);
};

export type AddressSide = "your" | "shared";
const ADDRESSES_SIDES: AddressSide[] = ["your", "shared"];
export const isAddressSide = (value: any): value is AddressSide => {
    return ADDRESSES_SIDES.includes(value as AddressSide);
};
export interface AddressUrlParams {
    side?: AddressSide;
    type?: AddressType;
}

interface FormItemId {
    _validationItemId?: string;
}

export enum AccountStatusType {
    Approved = "approved",
    Verification = "verification",
    NeedToConfirm = "needToConfirm",
}

export interface AddressRule {
    id?: number;
    cp: { id: number; name?: string };
    accountId?: number;
}

export interface AddressCurrency {
    id: number;
    name?: string;
}
export interface AddressNetwork {
    id?: number | null;
    name?: string;
}

export interface CryptoAddress extends FormItemId {
    id?: string;
    accountId?: number;
    rule: AddressRule | null;
    currency: AddressCurrency | null;
    network: AddressNetwork | null;
    wallet: string;
    privateNote?: string;
    memoOrTag?: string;
    memoOrTagRequired?: boolean;
    revisions: CpRevisionHash[];
    status: AccountStatusType;
}

export interface BankAddressBank {
    id: number;
    name: string;
    address?: string;
}

export interface BankAddress extends FormItemId {
    accountId?: number;
    rule: {
        id?: number;
        accountId?: number;
        cp: { id: number; name?: string };
    } | null;
    currency: { id: number; name?: string } | null;
    wallet: string;
    revisions: CpRevisionHash[];
    status: AccountStatusType;
    bankPrimary: BankAddressBank | null;
    bankIntermediate: BankAddressBank | null;
    otherDetails?: string;
    publicDetails?: string;
    memoOrTag?: string;
    memoOrTagRequired?: boolean;
    company: {
        name: string | undefined;
        address: string | undefined;
    } | null;
}

export interface AddressGroup<TAddress> {
    cp: { id: number; name?: string };
    addresses: TAddress[];
    count: number;
    needToConfirmCount: number;
}

export type DeleteAccountType = { id: string; accountId: number; accountRuleId?: number };

export interface AddressForAllCpFormInputs {
    currencyId: number;
    networkId?: number | null;
    wallet: string;
    memoOrTag: string;
    memoOrTagRequired?: boolean;
}

interface AddressesFormInputs<AddressType> {
    addresses: AddressType[];
}

export type CryptoAddressFormInput = AddressesFormInputs<CryptoAddress>;
export type BanksAddressFormInput = AddressesFormInputs<BankAddress>;

export interface AddressEditInputs {
    wallet: string;
    privateNote: string;
    memoOrTag: string;
    memoOrTagRequired: boolean;
}

export interface BankAddressEditInputs {
    wallet: string;
    memoOrTag: string | undefined;
    memoOrTagRequired: boolean;
    bankName: string;
    bankAddress: string;
    companyName: string;
    companyAddress: string;
    otherDetails: string | undefined;
    publicDetails: string | undefined;
}

export type CryptoTableData = CryptoAddress | AddressGroup<CryptoAddress>;
export type BankTableData = BankAddress | AddressGroup<BankAddress>;

export interface CpRevisionHash {
    cpId: number;
    revisionHash?: string;
}

export type AddressesTableProps<TableData, RowData> = {
    totalAddressesCount: number;
    side: AddressSide;
    addresses: {
        data: TableData[];
        isLoading: boolean;
    };
    sharedAddresses: {
        data: TableData[];
        isLoading: boolean;
    };
    onRowClick: (row: Row<RowData>, cellId: string) => void;
};

export type FormApiErrors = Record<string, string>;
export type FormValidationError = { _validationItemId?: string; error: string };

export interface AddressModalProps<AddressType> {
    isModalOpen: boolean;
    closeModal: () => void;
    side: AddressSide;
    address: AddressType | undefined;
    defaultEdit?: boolean;
}

export type UseAddressType<AddressType> = {
    data: AddressType[];
    isLoading: boolean;
};
