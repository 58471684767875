import styled, { css } from "styled-components";
import { CustomInputType } from "./Input.types";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Input = styled.input<{ customType: CustomInputType; offset?: number }>`
    width: 100%;
    background-color: transparent;
    color: ${(p) => p.theme.colors["textBody"]};

    ${({ offset }) =>
        offset &&
        css`
            padding-left: ${offset}px;
        `}
    ${({ customType }) =>
        customType === "simple" &&
        css`
            text-align: end;
        `}
  ::placeholder {
        color: ${(p) => p.theme.colors["textBody3"]};
    }
`;

export const Label = styled.label`
    ${(p) => p.theme.mixins["body1"]};
    font-size: 12px;
    line-height: 16px;

    color: ${(p) => p.theme.colors["textBody1"]};
`;

export const InnerLabel = styled(Label)`
    display: flex;
    gap: 5px;
    position: absolute;
`;

export const Error = styled.div`
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.005em;

    color: ${(p) => p.theme.colors["error"]};
    padding: 3px 0;
`;

export const InputContainer = styled.div<{ isError?: boolean }>`
    min-height: 44px;
    padding: 13px 12px;

    display: flex;
    justify-content: space-between;
    position: relative;

    background-color: ${(p) => p.theme.colors["textBody6"]};
    border-radius: 8px;

    ${(p) => p.theme.mixins["body1"]};

    :focus-within {
        box-shadow: 0 0 0 3px ${(p) => p.theme.colors["action4"]}, inset 0 0 0 1px rgba(22, 199, 176, 0.52);
    }

    ${({ isError }) =>
        isError &&
        css`
            box-shadow: inset 0 0 0 1px rgba(254, 24, 51, 0.32);
        `}
`;
