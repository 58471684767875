import { post } from "api";
import { appUrl } from "const/env";
import { ROUTES } from "feature/app/router/routes";

const AGREEMENT_TOKEN_API_METHOD = "generateAgreementToken";
const APP_DOMAIN = `${window.location.protocol}//${appUrl}`;

export const SIGNING_LINK_UTILS = {
    getSingingLink: async (isReadOnly: boolean) => {
        const { token }: { token: string } = await post(AGREEMENT_TOKEN_API_METHOD, {
            readOnly: isReadOnly,
        });

        return `${APP_DOMAIN}${ROUTES.termsAndConditions}?token=${token}`;
    },
    sendSingingLinkToEmail: async (email: string) => {
        return post(AGREEMENT_TOKEN_API_METHOD, {
            readOnly: false,
            email,
        });
    },
};
