import React, { useMemo } from "react";
import { Redirect, Route, Switch } from "react-router";
import { SWRConfig } from "swr";
import {
    ApiIntegration,
    Assets,
    GeneralInfo,
    LegalData,
    LiquidityProvidersFlow,
    TermsAndConditions,
} from "feature/onboarding";
import { ONBOARDING_STEPS } from "store/onboardingSlice";

const ONBOARDING_PATH_PREFIX = "/onboarding";
const ONBOARDING_STEPS_PATH: Record<ONBOARDING_STEPS, string> = {
    [ONBOARDING_STEPS.ACCOUNT_DATA]: `${ONBOARDING_PATH_PREFIX}/account-data`,
    [ONBOARDING_STEPS.ACCOUNT_ENABLE_TWO_FA]: `${ONBOARDING_PATH_PREFIX}/enable-two-fa`,
    [ONBOARDING_STEPS.ACCOUNT_CONFIRM_TWO_FA]: `${ONBOARDING_PATH_PREFIX}/confirm-two-fa`,
    [ONBOARDING_STEPS.BL_LEGAL_DATA]: `${ONBOARDING_PATH_PREFIX}/legal-data`,
    [ONBOARDING_STEPS.BL_TERMS_AND_CONDITIONS]: `${ONBOARDING_PATH_PREFIX}/terms-and-conditions`,
    [ONBOARDING_STEPS.BL_GENERAL_INFO]: `${ONBOARDING_PATH_PREFIX}/general-info`,
    [ONBOARDING_STEPS.BL_ASSETS]: `${ONBOARDING_PATH_PREFIX}/assets`,
    [ONBOARDING_STEPS.BL_API_INTEGRATION]: `${ONBOARDING_PATH_PREFIX}/bl-api-integration`,
    [ONBOARDING_STEPS.PROVIDERS]: `${ONBOARDING_PATH_PREFIX}/providers`,
    [ONBOARDING_STEPS.API_INTEGRATION]: `${ONBOARDING_PATH_PREFIX}/api-integration`,
};

const ONBOARDING_STEPS_ROUTES = [
    {
        path: ONBOARDING_STEPS_PATH[ONBOARDING_STEPS.BL_LEGAL_DATA],
        StepComponent: () => <LegalData />,
        step: ONBOARDING_STEPS.BL_LEGAL_DATA,
    },
    {
        path: ONBOARDING_STEPS_PATH[ONBOARDING_STEPS.BL_TERMS_AND_CONDITIONS],
        StepComponent: () => <TermsAndConditions />,
        step: ONBOARDING_STEPS.BL_TERMS_AND_CONDITIONS,
    },
    {
        path: ONBOARDING_STEPS_PATH[ONBOARDING_STEPS.BL_GENERAL_INFO],
        StepComponent: () => <GeneralInfo />,
        step: ONBOARDING_STEPS.BL_GENERAL_INFO,
    },
    {
        path: ONBOARDING_STEPS_PATH[ONBOARDING_STEPS.BL_ASSETS],
        StepComponent: () => <Assets />,
        step: ONBOARDING_STEPS.BL_ASSETS,
    },
    {
        path: ONBOARDING_STEPS_PATH[ONBOARDING_STEPS.BL_API_INTEGRATION],
        StepComponent: () => <ApiIntegration />,
        step: ONBOARDING_STEPS.BL_API_INTEGRATION,
    },
    {
        path: ONBOARDING_STEPS_PATH[ONBOARDING_STEPS.PROVIDERS],
        StepComponent: () => <LiquidityProvidersFlow />,
        step: ONBOARDING_STEPS.PROVIDERS,
    },
    {
        path: ONBOARDING_STEPS_PATH[ONBOARDING_STEPS.API_INTEGRATION],
        StepComponent: () => <ApiIntegration />,
        step: ONBOARDING_STEPS.API_INTEGRATION,
    },
];

export const StepRedirect: React.FC<{ currentStep: ONBOARDING_STEPS; condition: boolean }> = ({
    currentStep,
    condition,
    children,
}) => {
    if (condition) {
        return <>{children}</>;
    }

    return <Redirect to={ONBOARDING_STEPS_PATH[currentStep]} />;
};

export const OnboardingRouter: React.FC<{ currentStep: ONBOARDING_STEPS | null }> = ({ currentStep }) => {
    const config = useMemo(() => ({ provider: () => new Map() }), []);

    if (currentStep === null) {
        return <Redirect to="/" />;
    }

    return (
        <SWRConfig value={config}>
            <Switch>
                {ONBOARDING_STEPS_ROUTES.map(({ path, StepComponent, step }) => (
                    <Route exact path={path} key={path}>
                        <StepRedirect currentStep={currentStep} condition={currentStep === step}>
                            <StepComponent />
                        </StepRedirect>
                    </Route>
                ))}
                <Redirect to={ONBOARDING_STEPS_PATH[currentStep]} />
            </Switch>
        </SWRConfig>
    );
};

OnboardingRouter.displayName = "OnboardingRouter";

export default OnboardingRouter;
