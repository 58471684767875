import { GreenLink } from "../../style/menu";

import { useSettings } from "hooks/useSettings";
import { useIsPrimeBrokerUser, useIsSubaccountUser, usePrimeBrokerViewType } from "store/hooks";
import styled from "styled-components";

// TODO rework with design
export const NoticeBlock = styled.section`
    position: relative;
    font-size: 16px;
    line-height: 22px;
    color: ${(p) => p.theme.colors.textSubdued};

    /* or 137% */
    letter-spacing: -0.005em;
    padding: 10px 40px 10px 12px;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    hyphens: auto;
    b {
        font-weight: 600;
        color: ${(p) => p.theme.colors.textFG};
    }
    a {
        font-size: inherit;
    }
    button {
        position: absolute;
        right: 12px;
        top: 10px;
    }
`;

export const Notice = () => {
    const settings = useSettings();
    const isSubaccountUser = useIsSubaccountUser();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();

    let notice = (
        <>
            <b>Trading is only possible if Counterparty limits are set on both sides.</b> The system
            applies the minimum value of the limits set by a maker and a taker on each other — we
            show the USD value of the applied.
        </>
    );
    if (isSubaccountUser) {
        notice = (
            <>
                <b>Awaiting Master.</b> Trading will start when the counterparty will confirm his
                risks policy.
            </>
        );
    }
    if (isPrimeBrokerUser && primeBrokerViewType === "subaccounts") {
        notice = (
            <>
                <b>Configure your risks policy on sub-accounts to allow them trading.</b> Set up the
                Gross Limit, Margin requirements and Markup are optional.
            </>
        );
    }

    return (
        <NoticeBlock>
            {notice}{" "}
            {settings?.faq_link && (
                <GreenLink
                    target="_blank"
                    href={`${settings?.faq_link}/release-notes/news-and-updates/version-04.11.2022`}
                >
                    About Limits
                </GreenLink>
            )}
        </NoticeBlock>
    );
};
