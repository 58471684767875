import { TradingSide } from "feature/trade/trading/types";
import { NOT_ENOUGH_LIQUIDITY } from "feature/trade/trading/utils";
import { Level } from "types";
import { weightedAverageBySize } from "./weightedAverageBySize";
import { weightedAverageByVolume } from "./weightedAverageByVolume";

export const getWeightedAveragePrice = ({
    tradingSide,
    orderBookLevels,
    sizeOrVolume,
}: {
    tradingSide: TradingSide;
    orderBookLevels: Level[];
    sizeOrVolume: bigint | null;
}) => {
    if (sizeOrVolume === null || sizeOrVolume === 0n) {
        return null;
    }

    switch (tradingSide) {
        case "size": {
            return weightedAverageBySize({
                size: sizeOrVolume,
                orderBookLevels,
            });
        }
        case "volume": {
            return weightedAverageByVolume({
                volume: sizeOrVolume,
                orderBookLevels,
            });
        }
        default:
            return NOT_ENOUGH_LIQUIDITY;
    }
};
