import styled from "styled-components";
import { ShadowCard } from "../layout";

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    max-width: 360px;
    min-width: 360px;
    gap: 12px;

    ${ShadowCard} {
        gap: unset;
    }
`;

export type FormBodyProps = Partial<{
    alignItems: "stretch" | "center" | "flex-start" | "flex-end";
    spacing: number;
}>;

export const FormBody = styled.section<FormBodyProps>`
    display: flex;
    flex-direction: column;
    align-items: ${(p) => p.alignItems ?? "center"};
    gap: ${(p) => (p.spacing ? p.spacing : 16)}px;
    width: 100%;
`;
