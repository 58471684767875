import { ClientId, HStack, PSmallBold, PXSmall, VStack } from "@fm-frontend/uikit";
import { format8FractionDigits } from "@fm-frontend/utils";
import { useRfqProvidersAndInstruments } from "feature/rfq/apiHooks/useRfqProvidersAndInstruments";
import { QuoteSide } from "feature/rfq/apiHooks/useRfqWs";
import styled, { css } from "styled-components";
import { ExecutingQuote as ExecutingQuoteType } from "../../RfqRequestModal";
import { QUOTE_CONTAINER_HEIGHT } from "../consts";
import { SuccessedQuote } from "../QuotesList";
import { ExecutingQuote } from "./ExecutingQuote";
import { LoadingQuote } from "./LoadingQuote";

const QuoteInfo = styled(VStack)`
    align-items: center;
    justify-content: center;
    gap: 2px;
    margin-top: auto;
    margin-bottom: auto;
`;
const StyledClientId = styled(ClientId)``;
const QuoteContainer = styled(VStack)<{ $side: QuoteSide; $disabled: boolean }>`
    flex: 1 0 ${QUOTE_CONTAINER_HEIGHT}px;
    border-bottom: 1px solid ${(p) => p.theme.colors.ui8};
    align-items: center;
    justify-content: center;
    cursor: ${(p) => (p.$disabled ? "not-allowed" : "pointer")};
    ${QuoteInfo} {
        ${HStack} > ${PXSmall} {
            color: ${(p) =>
                p.$side === "BUY"
                    ? p.$disabled
                        ? p.theme.colors.positive32
                        : p.theme.colors.positive72
                    : p.$disabled
                    ? p.theme.colors.negative32
                    : p.theme.colors.negative72};
        }
        ${PSmallBold} {
            color: ${(p) =>
                p.$side === "BUY"
                    ? p.$disabled
                        ? p.theme.colors.positive32
                        : p.theme.colors.positive100
                    : p.$disabled
                    ? p.theme.colors.negative32
                    : p.theme.colors.negative100};
        }
        ${StyledClientId} {
            opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
        }
    }

    :hover {
        background-color: ${({ $disabled, theme: { colors } }) => !$disabled && colors.ui4};
    }
`;

const LightningIcon = () => (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.1875 5L5.40179 0.625L5.05689 3.75464C5.02423 4.05098 5.21411 4.32634 5.5027 4.40116L7.8125 5L4.59821 9.375L4.9433 6.5568C4.97665 6.28442 4.82097 6.02413 4.56522 5.92467L2.1875 5Z"
            fill="#243034"
        />
    </svg>
);

const BestLabelContainer = styled(HStack)<{ $side: QuoteSide }>`
    position: absolute;
    ${({ $side }) =>
        $side === "BUY"
            ? css`
                  right: 1px;
                  border-radius: 0px 0px 0px 4px;
              `
            : css`
                  left: 1px;
                  border-radius: 0px 0px 4px 0px;
              `}
    top: 0;
    padding: 2px 4px 2px 2px;
    gap: 1px;
    font-size: 9px;
    font-weight: 600;
    line-height: 12px;
    text-transform: uppercase;
    align-items: center;
    background-color: ${(p) => p.theme.colors.yellow100};
`;

const BestQuoteContainer = styled(QuoteContainer)`
    position: relative;
    background-color: ${(p) => p.theme.colors.yellow8};

    ${BestLabelContainer} {
        opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
    }

    :hover {
        background-color: ${({ $disabled, theme: { colors } }) => !$disabled && colors.yellow16};
    }
`;
const RejectedBestQuoteContainer = styled(QuoteContainer)`
    color: ${(p) => p.theme.colors.attention100};
`;

export const BestQuote = ({
    quote,
    side,
    assetCurrency,
    onClick,
    executingQuote,
    actionIsInactive,
    allQuotesRejected,
}: {
    quote: SuccessedQuote | null;
    side: QuoteSide;
    assetCurrency: string;
    onClick: () => void;
    executingQuote: null | ExecutingQuoteType;
    actionIsInactive: boolean;
    allQuotesRejected: boolean;
}) => {
    const { rfqProviders, isLoading: isRfqProvidersLoading } = useRfqProvidersAndInstruments();
    const [providerClientId] =
        quote !== null
            ? Array.from(rfqProviders.entries()).find(
                  ([, { providerId: pId }]) => pId === quote.providerId,
              )!
            : [0];

    if (allQuotesRejected) {
        return (
            <RejectedBestQuoteContainer $disabled $side={side}>
                All requests were rejected
            </RejectedBestQuoteContainer>
        );
    }

    if (quote === null || isRfqProvidersLoading) {
        return <LoadingQuote side={side} />;
    }

    if (executingQuote?.isBestQuote) {
        return <ExecutingQuote side={side} />;
    }

    const title = side === "BUY" ? `Buy ${assetCurrency} from` : `Sell ${assetCurrency} to`;
    const isDisabledTile =
        Boolean(executingQuote) || actionIsInactive || "rejectReason" in quote || "error" in quote;

    return (
        <BestQuoteContainer
            onClick={isDisabledTile ? undefined : onClick}
            $side={side}
            $disabled={isDisabledTile}
        >
            <BestLabelContainer $side={side}>
                <LightningIcon />
                <span>BEST</span>
            </BestLabelContainer>
            <QuoteInfo>
                <HStack alignItems="baseline" spacing={3}>
                    <PXSmall>{title}</PXSmall>
                    <StyledClientId id={providerClientId} isSmall />
                </HStack>
                <PSmallBold>{format8FractionDigits(quote.price)}</PSmallBold>
            </QuoteInfo>
        </BestQuoteContainer>
    );
};
