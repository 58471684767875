import { HStack, Icons, PSmallBold } from "@fm-frontend/uikit";
import React from "react";
import styled, { useTheme } from "styled-components";

const Container = styled(HStack)`
    height: 24px;
    padding: 4px 8px;
    border-radius: 12px;
    background: var(--luiui-4, rgba(36, 48, 52, 0.04));
`;

type Props = {
    info: string;
    renderIcon?: () => React.ReactNode;
};
export const InfoBadge: React.FC<Props> = ({ info, renderIcon }) => {
    const { colors } = useTheme();

    return (
        <Container spacing={4} alignItems="center">
            {renderIcon?.() ?? <Icons.Attention color={colors.red} />}
            <PSmallBold color={colors.ui52}>{info}</PSmallBold>
        </Container>
    );
};
