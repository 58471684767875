import {
    BasicInput,
    Form,
    FormActions,
    FormAttention,
    FormBody,
    FormProgress,
    H1,
    P,
    PBold,
    PrimaryButton,
    ShadowCard,
    ShelfContainer,
} from "@fm-frontend/uikit";
import { Header as FormHeader } from "@fm-frontend/uikit/src/components/headers/Header";
import { HeaderTitle } from "@fm-frontend/uikit/src/components/headers/HeaderTitle";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { post } from "api";
import { IconError } from "components/icons";
import { createNotification } from "feature/app";
import { useFormHelpers, useSelector } from "hooks";
import { LS_VARIABLES, useLSState } from "hooks/useLSState";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateClientData } from "store/auth/actions";
import { useIsSubaccountUser } from "store/hooks";
import {
    LegalDataInputs,
    OnboardingMap,
    ONBOARDING_STEPS,
    setIsApiIntegrationRequired,
    setIsLegalDataRequired,
} from "store/onboardingSlice";
import { Header, Layout, WelcomeContainer } from "../common";
import { legalDataSchema } from "./schemas";

export const LegalData: React.FC = () => {
    const dispatch = useDispatch();
    const { error, setError, setLoading, isLoading } = useFormHelpers();
    const isSubaccountUser = useIsSubaccountUser();
    const { isLegalDataRequired } = useSelector((state) => state.onboarding.onboardingStages);
    const { userId } = useSelector((state) => state.authentication);
    const isReadOnly = !isLegalDataRequired;

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<LegalDataInputs>({
        defaultValues: {},
        mode: "onSubmit",
        resolver: yupResolver(legalDataSchema),
    });

    useEffect(() => {
        dispatch(
            createNotification({
                type: "success",
                content: (
                    <WelcomeContainer>
                        <PBold>Good to see you aboard!</PBold>
                        <P>Your account was created and your progress was saved. Let’s move on!</P>
                    </WelcomeContainer>
                ),
            }),
        );
    }, []);

    const [, setApiIntegrationLocalStorage] = useLSState(
        LS_VARIABLES.API_INTEGRATION_REQUIRED,
        true,
    );
    const [, setTnCDelegated] = useLSState(LS_VARIABLES.TnC_DELEGATED, true);

    useEffect(() => {
        setTnCDelegated(false);
    }, []);

    useEffect(() => {
        if (!isSubaccountUser) {
            setApiIntegrationLocalStorage(true);
            dispatch(setIsApiIntegrationRequired({
                isApiIntegrationRequired: true,
                userId,
            }));
        }
    }, [isSubaccountUser]);

    const submit = async (legalData: LegalDataInputs) => {
        try {
            setLoading(true);
            await post("saveClientData", legalData);
            dispatch(setIsLegalDataRequired(false));
            dispatch(updateClientData());
        } catch (e) {
            setError(String(e));
        }

        setLoading(false);
    };

    return (
        <Layout>
            <Header>
                <H1>Business & legal</H1>
                <P>This part is necessary to prepare Terms & Condition for signing</P>
            </Header>
            <Form onSubmit={handleSubmit(submit)}>
                <FormProgress
                    currentStep={OnboardingMap[ONBOARDING_STEPS.BL_LEGAL_DATA].progress.current}
                    totalSteps={OnboardingMap[ONBOARDING_STEPS.BL_LEGAL_DATA].progress.total}
                />
                <ShadowCard>
                    <FormHeader>
                        <HeaderTitle title="Legal data" />
                    </FormHeader>
                    <FormBody>
                        <BasicInput
                            disabled={isReadOnly}
                            label="Legal company name"
                            placeholder="Company Name"
                            hint="Please add LLC, LLP or other mark if it’s necessary"
                            {...register("companyName")}
                            error={errors.companyName?.message}
                        />
                        <BasicInput
                            disabled={isReadOnly}
                            label="Registration number"
                            placeholder="1215-3456788"
                            {...register("registrationNumber")}
                            error={errors.registrationNumber?.message}
                        />
                        <BasicInput
                            disabled={isReadOnly}
                            label="Legal address"
                            placeholder="Erik-Mann Street 9"
                            {...register("address")}
                            error={errors.address?.message}
                        />
                        <ShelfContainer>
                            <BasicInput
                                disabled={isReadOnly}
                                label="City"
                                placeholder="Munich"
                                {...register("city")}
                                error={errors.city?.message}
                            />
                            <BasicInput
                                disabled={isReadOnly}
                                label="ZIP code"
                                placeholder="000000"
                                {...register("zipCode")}
                                error={errors.zipCode?.message}
                            />
                        </ShelfContainer>
                        <BasicInput
                            disabled={isReadOnly}
                            label="Country"
                            placeholder="Germany"
                            {...register("country")}
                            error={errors.country?.message}
                        />
                        {error && (
                            <FormAttention>
                                <IconError />
                                <P>{error}</P>
                            </FormAttention>
                        )}
                    </FormBody>
                    <FormActions variant="plain">
                        <PrimaryButton
                            type="submit"
                            fullWidth
                            size="large"
                            disabled={isReadOnly}
                            loading={isLoading}
                        >
                            Continue
                        </PrimaryButton>
                    </FormActions>
                </ShadowCard>
            </Form>
        </Layout>
    );
};
