import { PBold, PSmall, VStack } from "@fm-frontend/uikit";
import { FC } from "react";
import { useWizard } from "../../wizard/useWizard";
import { ClickContainer } from "../Container";
import { PlusIcon } from "../PlusIcon";

export const AddFirstSubaccountButton: FC = () => {
    const { wizard, createSubaccount } = useWizard();

    return (
        <>
            {wizard}
            <ClickContainer onClick={createSubaccount}>
                <VStack>
                    <PBold>Sub-accounts</PBold>
                    <PSmall>Add new sub-account</PSmall>
                </VStack>
                <PlusIcon />
            </ClickContainer>
        </>
    );
};
