import { ValueConvert } from "@fm-frontend/utils";

export enum WhitelistingValues {
    Enabled = "ENABLED",
    Disabled = "DISABLED",
}

export const isWhitelisted = (value: string | undefined) => {
    return ValueConvert.normalize(value) === WhitelistingValues.Enabled;
};

export const formatWhitelisting = (value: boolean | undefined) => {
    return value ? WhitelistingValues.Enabled : WhitelistingValues.Disabled;
};

export type WhitelistingOriginalDataType = {
    instrument: string;
    whitelisting: boolean;
};
export type WhitelistingImportType = {
    instrument: string;
    whitelisting: string;
};

export type ValidationContext = {
    originalValues: WhitelistingOriginalDataType[];
    availableInstruments: string[];
};

const WHITELISTING_COLUMN_NAME = `Whitelisting (${WhitelistingValues.Enabled}/${WhitelistingValues.Disabled})`;

export const CSV_COLUMNS_TO_DATA_TYPE: Record<string, keyof WhitelistingOriginalDataType> = {
    Instrument: "instrument",
    [WHITELISTING_COLUMN_NAME]: "whitelisting",
};
export const DATA_TYPE_TO_CSV_COLUMNS: Record<keyof WhitelistingOriginalDataType, string> = {
    instrument: "Instrument",
    whitelisting: WHITELISTING_COLUMN_NAME,
};

export const DATA_TYPE_TO_DIFF_TABLE_COLUMNS: Record<keyof WhitelistingOriginalDataType, string> = {
    instrument: "Instrument",
    whitelisting: "Whitelisting",
};

export const REQUIRED_CSV_COLUMNS: Record<
    keyof Pick<WhitelistingOriginalDataType, "instrument" | "whitelisting">,
    string
> = {
    instrument: "Instrument",
    whitelisting: WHITELISTING_COLUMN_NAME,
};

export const CSV_VALIDATION_ERROR_MESSAGES = {
    get fileMissed() {
        return "File missed";
    },
    get invalidCharacters() {
        return "There are invalid characters in one or more cells of the CSV file";
    },
    missingRequiredColumns(requiredColumns: string[]) {
        return `The required columns in the CSV file are missed. Required columns are: ${requiredColumns.join(
            ", ",
        )}`;
    },
    get headersMismatchFormat() {
        return "The headers in the CSV file do not match the expected format";
    },
};

export const getWhitelistingDiffTableColumnName = (name: string) => {
    return DATA_TYPE_TO_DIFF_TABLE_COLUMNS[name as keyof WhitelistingOriginalDataType];
};
