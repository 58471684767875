import { compareFeedIds } from "feature/app/ws";
import { EMPTY_BOOK } from "feature/trade/trading/utils";
import { useSelector } from "hooks";
import { Book } from "types";

const BOOK_FEED = "F";

export const useInstrumentsBooks = () => {
    const instrumentsBooks = useSelector(({ ws }) => {
        const bookSnapshots = ws.snapshots[BOOK_FEED] ?? [];
        const bookListeners = ws.listeners[BOOK_FEED] ?? [];

        const response = new Map<
            string,
            { data: Book; isLoading: boolean; errorCode: number | undefined }
        >();

        for (const { feedId: blFeedId } of bookListeners) {
            const instrument =
                typeof blFeedId === "object" ? JSON.stringify(blFeedId) : String(blFeedId);

            const bookSnapshot = bookSnapshots.find(({ feedId: bsFeedId }) =>
                compareFeedIds(blFeedId, bsFeedId),
            );

            if (bookSnapshot) {
                const book = bookSnapshot.value as Book | { errorCode: number };
                const errorCode = book && "errorCode" in book ? book.errorCode : undefined;

                response.set(instrument, {
                    data: errorCode === undefined ? (book as Book) : EMPTY_BOOK,
                    isLoading: false,
                    errorCode,
                });
            } else {
                response.set(instrument, {
                    data: EMPTY_BOOK,
                    isLoading: true,
                    errorCode: undefined,
                });
            }
        }

        return response;
    });

    return {
        instrumentsBooks,
    };
};
