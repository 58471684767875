import { format8FractionDigits } from "./formatters";

const isBigInt = (num: bigint) => {
    if (typeof num !== "bigint") {
        throw new TypeError(`${num} is not BigInt!`);
    }
};

export const SIZE64_MAX_WHOLE_DIGITS_COUNT = 10;
export const SIZE64_MAX_FLOAT_DIGITS_COUNT = 8;

// leave only digits in string
const normalizeIntegerString = (str: string) => str.replace(/\D/g, "");

const size64DecimalStringRegex = /^(\-)?[0-9]+(\.[0-9]*)?$/;
export const SIZE64 = {
    /** Returns a formatted representation of Size64 number.
     * @param {bigint} size64Num Size64 number
     * @param {number} [fixedFractionDigits] number of fraction digits is optional
     * @param {number} [minFixedFractionDigits] number of minimum fixed fraction digits
     * @example
     * // returns 1,234.56789
     * toFormattedDecimalString(123456789000n)
     */
    toFormattedDecimalString(
        size64Num: bigint,
        fixedFractionDigits?: number,
        minFixedFractionDigits?: number,
    ) {
        isBigInt(size64Num);
        if (fixedFractionDigits === undefined) {
            return format8FractionDigits(
                size64Num,
                SIZE64_MAX_FLOAT_DIGITS_COUNT,
                minFixedFractionDigits ?? 0,
            );
        }
        return format8FractionDigits(size64Num, fixedFractionDigits, minFixedFractionDigits);
    },
    toDecimalString(size64Num?: bigint | null) {
        if (size64Num === undefined || size64Num === null) {
            return "";
        }
        isBigInt(size64Num);
        if (size64Num === 0n) {
            return "0";
        }
        const isNegative = size64Num < 0n;
        const absoluteNumStr = String(isNegative ? -size64Num : size64Num);
        const remainderStr = absoluteNumStr.slice(-SIZE64_MAX_FLOAT_DIGITS_COUNT);
        const trailingZeroIndex = [...remainderStr].findLastIndex((char) => char !== "0");
        const normalizedRemainderStr = remainderStr.slice(0, trailingZeroIndex + 1);
        const quotient = absoluteNumStr.slice(0, -SIZE64_MAX_FLOAT_DIGITS_COUNT);
        return `${isNegative ? "-" : ""}${quotient || "0"}${
            normalizedRemainderStr.length > 0 ? `.${normalizedRemainderStr}` : ""
        }`;
    },
    fromString(str: string) {
        const firstDotIndex = str.indexOf(".");
        const wholePart = firstDotIndex === -1 ? str : str.slice(0, firstDotIndex);
        const floatPart = firstDotIndex === -1 ? "" : str.slice(firstDotIndex + 1);
        const normalizedWhole = normalizeIntegerString(wholePart);
        const normalizedFloatPart = normalizeIntegerString(floatPart);

        if (!normalizedWhole && !normalizedFloatPart) {
            return null;
        }

        const size64InputStr = `${normalizedWhole || 0}${
            normalizedFloatPart ? `.${normalizedFloatPart}` : ""
        }`;
        const [quotient = "", remainder = ""] = size64InputStr.split(/[.]/);

        return (
            BigInt(quotient) * BigInt(10 ** SIZE64_MAX_FLOAT_DIGITS_COUNT) +
            BigInt(
                Number(
                    `${remainder}${"0".repeat(SIZE64_MAX_FLOAT_DIGITS_COUNT + 1)}`.slice(
                        0,
                        SIZE64_MAX_FLOAT_DIGITS_COUNT + 1,
                    ),
                ) + 5,
            ) /
                10n
        );
    },
    isValidDecimalString(size64InputStr: string) {
        const normalizedSize64Str = size64InputStr.trim();
        if (normalizedSize64Str === "" || !size64DecimalStringRegex.test(size64InputStr)) {
            return false;
        }
        const [, remainder = ""] = normalizedSize64Str.split(/[.]/);
        if (
            remainder.length > SIZE64_MAX_FLOAT_DIGITS_COUNT &&
            !/^0+$/.test(remainder.slice(SIZE64_MAX_FLOAT_DIGITS_COUNT))
        ) {
            return false;
        }
        return true;
    },
    multiple2Size64Numbers(size64Num1: bigint, size64Num2: bigint) {
        isBigInt(size64Num1);
        isBigInt(size64Num2);

        const product = size64Num1 * size64Num2;
        const firstCutDigit = Number(String(product).at(-SIZE64_MAX_FLOAT_DIGITS_COUNT) ?? "0");

        return (
            (size64Num1 * size64Num2) / BigInt(10 ** SIZE64_MAX_FLOAT_DIGITS_COUNT) +
            (firstCutDigit >= 5 ? 1n : 0n)
        );
    },
};
