import { useFilteredLimits } from "hooks/useFilteredLimits";
import { useMemo } from "react";
import { CounterpartyLimitIndex } from "types";

export const useDefaultMarkups = () => {
    const { data, isLoading } = useFilteredLimits();

    return useMemo(() => {
        return {
            defaultMarkups: (data ?? []).reduce<Record<number, number>>((acc, limit) => {
                const cpId = limit[CounterpartyLimitIndex.counterpartyId];
                const defaultMarkup = limit[CounterpartyLimitIndex.takerMarkup];

                return {
                    ...acc,
                    [cpId]: defaultMarkup,
                };
            }, {}),
            isLoading,
        };
    }, [data, isLoading]);
};
