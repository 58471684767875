import { post } from "api";
import { useOpenConfirmationModal } from "hooks";
import { useState } from "react";
import { displayError } from "utils";
import { Transaction } from "../types";
import { CommitDepositModal } from "./modals/CommitDepositModal";
import {
    CreateDepositModal,
    CreatedTransaction,
    getSubaccountDefaultTransactionState,
} from "./modals/CreateDepositModal";
import { DeleteTransactionModalBody } from "./modals/DeleteTransactionModalBody";
import { SendDepositModal } from "./modals/SendDepositModal";

export const useActions = () => {
    const openConfirmationModal = useOpenConfirmationModal();
    const [sendDepositModalData, setSendDepositModalData] = useState<Transaction | null>(null);
    const [openedDepositModal, setOpenedDepositModal] = useState(Boolean(getSubaccountDefaultTransactionState()));
    const [commitDepositModalData, setCommitDepositModalData] = useState<CreatedTransaction | null>(null);

    const removeTransaction = (transaction: Transaction) => {
        openConfirmationModal({
            title: "Delete transaction",
            description: <DeleteTransactionModalBody transaction={transaction} />,
            confirmButtonTitle: "Yes, delete",
            onConfirm: () => {
                post("delOutgoingSettlementTransaction", {
                    transactionId: transaction.id,
                }).catch(displayError);
            },
        });
    };

    return {
        sendDeposit: setSendDepositModalData,
        createDeposit: () => setOpenedDepositModal(true),
        removeTransaction,
        actionsContent: (
            <>
                {sendDepositModalData && (
                    <SendDepositModal closeModal={() => setSendDepositModalData(null)} deposit={sendDepositModalData} />
                )}
                {openedDepositModal && !commitDepositModalData && (
                    <CreateDepositModal
                        closeModal={() => setOpenedDepositModal(false)}
                        onContinueClick={(transactionSettings: CreatedTransaction) => {
                            setOpenedDepositModal(false);
                            setCommitDepositModalData(transactionSettings);
                        }}
                    />
                )}
                {commitDepositModalData && (
                    <CommitDepositModal
                        transactionSettings={commitDepositModalData}
                        closeModal={() => {
                            setCommitDepositModalData(null);
                            setOpenedDepositModal(false);
                        }}
                    />
                )}
            </>
        ),
    };
};
