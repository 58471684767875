import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { InstrumentOptionEssence } from "components/InstrumentOptionEssence";
import { InstrumentTriggerEssence } from "components/InstrumentTriggerEssence";
import { useInstruments } from "hooks";
import { Instrument } from "hooks/useInstruments";
import { useMemo } from "react";
import { useExtendedCurrencies } from "store/useExtentedCurrencies";
import { MultipleDropdownGroupedSheet } from "./MultipleDropdownGroupedSheet";

export const InstrumentsMultipleGroupedDropdown = ({
    values = [],
    onChange,
    error,
    caption = "Instruments",
    allSelectedText = "All instruments",
    allOptionText = "All instruments",
}: {
    values: string[];
    onChange: (values: string[]) => void;
    error?: string;
    caption?: string;
    allSelectedText?: string;
    allOptionText?: string;
}) => {
    const { currencyGroups } = useExtendedCurrencies();
    const { instruments } = useInstruments();
    const instrumentsMap = useMemo(
        () =>
            instruments.reduce<Record<string, Instrument>>((acc, instrument) => {
                acc[instrument.instrumentName] = instrument;
                return acc;
            }, {}),
        [instruments],
    );
    const groupOptions = useMemo(
        () => [
            {
                text: "All crypto-crypto",
                isOptionInGroup: (optionValue: string) => {
                    const { assetCurrency, balanceCurrency } = instrumentsMap[optionValue];
                    return (
                        currencyGroups[assetCurrency] === "crypto" &&
                        currencyGroups[balanceCurrency] === "crypto"
                    );
                },
            },
            {
                text: "All crypto-fiat",
                isOptionInGroup: (optionValue: string) => {
                    const { assetCurrency, balanceCurrency } = instrumentsMap[optionValue];
                    return (
                        currencyGroups[assetCurrency] === "crypto" &&
                        currencyGroups[balanceCurrency] === "fiat"
                    );
                },
            },
            {
                text: "All crypto-stablecoins",
                isOptionInGroup: (optionValue: string) => {
                    const { assetCurrency, balanceCurrency } = instrumentsMap[optionValue];
                    return (
                        currencyGroups[assetCurrency] === "crypto" &&
                        currencyGroups[balanceCurrency] === "stablecoin"
                    );
                },
            },
            {
                text: "All stablecoins-fiat",
                isOptionInGroup: (optionValue: string) => {
                    const { assetCurrency, balanceCurrency } = instrumentsMap[optionValue];
                    return (
                        currencyGroups[assetCurrency] === "stablecoin" &&
                        currencyGroups[balanceCurrency] === "fiat"
                    );
                },
            },
            {
                text: "All stablecoins-stablecoins",
                isOptionInGroup: (optionValue: string) => {
                    const { assetCurrency, balanceCurrency } = instrumentsMap[optionValue];
                    return (
                        currencyGroups[assetCurrency] === "stablecoin" &&
                        currencyGroups[balanceCurrency] === "stablecoin"
                    );
                },
            },
        ],
        [currencyGroups, instrumentsMap],
    );
    const instrumentsOptions = useMemo(() => {
        return instruments
            .map(({ instrumentName }) => ({
                text: instrumentName,
                value: instrumentName,
            }))
            .sort(({ value: v1 }, { value: v2 }) => v1.localeCompare(v2)) as DropdownOption[];
    }, [instruments]);

    return (
        <MultipleDropdown
            values={values}
            onChange={onChange}
            renderTrigger={(trigger) => (
                <MultipleDropdown.Trigger {...trigger} size="large" variant="simple">
                    <InstrumentTriggerEssence
                        {...trigger}
                        option={trigger.selectedOptions}
                        size="large"
                        allSelectedText={allSelectedText}
                    />
                </MultipleDropdown.Trigger>
            )}
            options={instrumentsOptions}
            caption={caption}
            placeholder="Select"
            align="end"
            fullWidth
            error={error}
        >
            <MultipleDropdownGroupedSheet
                values={values}
                onChange={onChange}
                Dropdown={MultipleDropdown}
                allOptionText={allOptionText}
                groupOptions={groupOptions}
                options={instrumentsOptions}
                OptionEssence={InstrumentOptionEssence}
                size="large"
            />
        </MultipleDropdown>
    );
};
