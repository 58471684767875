import { setConfirmModalContent } from "feature/app";
import { useDispatch } from "react-redux";

export const useAccountDeleteConfirmation = () => {
    const dispatch = useDispatch();

    return (cb: () => void) =>
        dispatch(
            setConfirmModalContent({
                title: "Delete the account",
                onConfirm: cb,
                description: "This action cannot be undone. Do you want to delete this account? ",
                confirmButtonTitle: "Yes, delete",
            }),
        );
};
