import { PaddingProps, PBold, PSmall, SpacingProps, VStack } from "@fm-frontend/uikit";
import { EmDash } from "@fm-frontend/utils";
import React, { memo } from "react";
import styled, { useTheme } from "styled-components";

type Title = string;
export type Content = string | undefined;

export type ContentCell = {
    title: Title;
    renderTitle?: (title: Title) => React.ReactNode;
    content?: Content;
    renderContent?: (content?: Content) => React.ReactNode;
    maxWidth?: string;
} & PaddingProps &
    SpacingProps;

const ContentContainer = styled(PBold)`
    word-wrap: break-word;
`;

export const ContentCell: React.FC<ContentCell> = memo(
    ({ title, content, renderContent, renderTitle, maxWidth = "304px", ...styleProps }) => {
        const { colors } = useTheme();

        return (
            <VStack {...styleProps} maxWidth={maxWidth} width="100%">
                {renderTitle?.(title) ?? <PSmall color={colors.ui72}>{title}</PSmall>}
                <VStack paddingY={3}>
                    {renderContent?.(content) ?? (
                        <ContentContainer color={colors.ui100}>
                            {content !== undefined && content !== "" ? content : EmDash}
                        </ContentContainer>
                    )}
                </VStack>
            </VStack>
        );
    },
);
