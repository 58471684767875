export const PercentageIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_di_7647_65942)">
            <rect x="2" y="1" width="12" height="12" rx="6" fill="white" />
            <path
                d="M6.35 5.35H6.356M9.95 8.95H9.956M10.55 4.75L5.75 9.55M6.65 5.35C6.65 5.51569 6.51569 5.65 6.35 5.65C6.18431 5.65 6.05 5.51569 6.05 5.35C6.05 5.18431 6.18431 5.05 6.35 5.05C6.51569 5.05 6.65 5.18431 6.65 5.35ZM10.25 8.95C10.25 9.11569 10.1157 9.25 9.95 9.25C9.78432 9.25 9.65 9.11569 9.65 8.95C9.65 8.78432 9.78432 8.65 9.95 8.65C10.1157 8.65 10.25 8.78432 10.25 8.95Z"
                stroke="#243034"
                strokeOpacity="0.72"
                strokeWidth="1.125"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <filter
                id="filter0_di_7647_65942"
                x="0"
                y="0"
                width="16"
                height="16"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.140666 0 0 0 0 0.189687 0 0 0 0 0.205006 0 0 0 0.24 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_7647_65942"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_7647_65942"
                    result="shape"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="0.5" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.140666 0 0 0 0 0.189687 0 0 0 0 0.205006 0 0 0 0.2 0"
                />
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_7647_65942" />
            </filter>
        </defs>
    </svg>
);
