import { H2, Icons, PlainButton, ShadowCard, TextSmall } from "@fm-frontend/uikit";
import React, { FC, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { CpStatus } from "store/counterpartiesSlice";
import * as Styled from "./styled";
import {
    CounterpartyStatusGroup,
    CounterpartyStatusGroupType,
    CounterpartyStatusType
} from "../utils";

const ClearButton = styled(PlainButton)`
    gap: 4px;
`;

const StatusGroupByStatus = Object.entries(CounterpartyStatusGroup)
    .reduce(
        (acc, [statusGroup, { status }]) => {
            status.forEach((s) => {
                acc[s] = Number(statusGroup) as CounterpartyStatusGroupType;
            });
            return acc;
        },
        {} as Record<CounterpartyStatusType, CounterpartyStatusGroupType>,
    );

type CounterpartyListProps = {
    counterpartiesStatuses: Record<string, CpStatus>;
    activeStatusGroups: CounterpartyStatusGroupType[];
    onChangeStatusGroups: (statusGroups: CounterpartyStatusGroupType[]) => void;
};

export const CounterpartiesPreview: FC<CounterpartyListProps> = ({ counterpartiesStatuses, activeStatusGroups, onChangeStatusGroups }) => {
    const theme = useTheme();
    const statuses = useMemo(
        () => Object.entries(counterpartiesStatuses)
            .map(([, { status }]) => StatusGroupByStatus[status])
            .sort()
            .reduce((acc, type) => {
                acc[type] ??= 0;
                acc[type] += 1;

                return acc;
            }, {} as Record<CounterpartyStatusGroupType, number>),
        [counterpartiesStatuses],
    );
    const handleClear = () => onChangeStatusGroups([]);

    return (
        <ShadowCard>
            <Styled.Header>
                <Styled.Label>
                    <H2>Overview</H2>
                </Styled.Label>
                {activeStatusGroups.length !== 0 && (
                    <ClearButton size="small" onClick={handleClear}>
                        <Icons.X color={theme.colors.ui32} />
                        Clear
                    </ClearButton>
                )}
            </Styled.Header>
            <Styled.StatusesContainer>
                {Object.entries(statuses)
                    .map(([key, count]) => {
                        const status = Number(key) as CounterpartyStatusGroupType;
                        const selected = activeStatusGroups.includes(status);
                        const handleClick = () => onChangeStatusGroups(
                            selected
                                ? activeStatusGroups.filter(s => s !== status)
                                : [...(new Set([...activeStatusGroups, status]))]
                        );

                        return (
                            <Styled.StatusWrapper
                                key={status}
                                $status={status}
                                $selected={selected}
                                onClick={handleClick}
                            >
                                <TextSmall>{CounterpartyStatusGroup[status].title}</TextSmall>
                                <H2>{count}</H2>
                            </Styled.StatusWrapper>
                        );
                    })}
            </Styled.StatusesContainer>
        </ShadowCard>
    );
};
