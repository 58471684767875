import { useMemo, useReducer } from "react";

type State = {
    buyActive: boolean;
    sellActive: boolean;
};

type Action = { type: "ACTIVATE_BUY" } | { type: "ACTIVATE_SELL" } | { type: "RESET" };

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case "ACTIVATE_BUY":
            return {
                ...state,
                buyActive: true,
                sellActive: false,
            };
        case "ACTIVATE_SELL":
            return {
                ...state,
                buyActive: false,
                sellActive: true,
            };
        case "RESET":
            return {
                ...state,
                buyActive: false,
                sellActive: false,
            };
        default:
            throw new Error("Unknown action type");
    }
}

const initialState: State = {
    buyActive: false,
    sellActive: false,
};

export const useTradingButtonsActivationState = ({
    canBeActivated,
}: {
    canBeActivated: () => Promise<boolean>;
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const activateBuy = useMemo(() => {
        return async () => {
            const result = await canBeActivated();

            if (result) {
                dispatch({ type: "ACTIVATE_BUY" });
            }
        };
    }, [canBeActivated]);

    const activateSell = useMemo(() => {
        return async () => {
            const result = await canBeActivated();

            if (result) {
                dispatch({ type: "ACTIVATE_SELL" });
            }
        };
    }, [canBeActivated]);

    const reset = useMemo(() => {
        return () => dispatch({ type: "RESET" });
    }, []);

    return {
        state,
        activateBuy,
        activateSell,
        reset,
    };
};
