import { parseLimit } from "feature/counterparties/utils";
import styled from "styled-components";
import type { LpInfo } from "../../types";
import { CpStatusIcon } from "./CpStatusIcon";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 8px;
    padding: 4px 8px 4px 4px;
    width: 156px;
`;

const Header = styled.div`
    display: flex;

    & > div:first-child {
        padding-left: 4px;
        flex: 1;
    }

    color: ${(p) => p.theme.colors.uiWhite52};
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
`;

const LpRow = styled.div<{ $isEnabled: boolean }>`
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: ${(p) => (p.$isEnabled ? 600 : 400)};
    line-height: 16px;
    color: ${(p) => (p.$isEnabled ? p.theme.colors.uiWhite100 : p.theme.colors.uiWhite72)};
`;

const LpName = styled.div`
    flex: 1;
    padding-left: 4px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const TooltipContent = ({ allLimits }: { allLimits: LpInfo[] }) => {
    return (
        <Container>
            <Header>
                <div>LPs name</div>
                <div>ID</div>
            </Header>
            {allLimits.map(({ counterpartyId, cpName, limit, cpStatus }) => (
                <LpRow $isEnabled key={counterpartyId}>
                    <CpStatusIcon
                        cpStatus={cpStatus}
                        limitTradingStatus={parseLimit(limit).tradingStatus}
                    />
                    <LpName>{cpName}</LpName>
                    <div>{counterpartyId}</div>
                </LpRow>
            ))}
        </Container>
    );
};
