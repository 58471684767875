import { ACTIONS_TABLE_COLUMN_KEY, EXPAND_TABLE_COLUMN_KEY, Icons } from "@fm-frontend/uikit";
import { createColumnHelper } from "@tanstack/react-table";
import { BankAddress, CryptoAddress } from "../types";
import { ActionCell } from "./ActionCell";
import { GroupCellWrapper } from "./CellWrapper";
import { CpCell } from "./CpCell";
import { CurrencyCell } from "./CurrencyCell";
import { GroupRowInfoCell } from "./GroupRowInfoCell";
import { StatusCell } from "./StatusCell";
import { AddressTableRowExpandContainer } from "./styled";
import { WalletCell } from "./WalletCell";

const columnHelper = createColumnHelper<CryptoAddress | BankAddress>();

export const toggleColumn = columnHelper.display({
    id: EXPAND_TABLE_COLUMN_KEY,
    header: ({ table }) => {
        const isAllRowsExpanded = table.getIsAllRowsExpanded();

        return (
            <AddressTableRowExpandContainer onClick={table.getToggleAllRowsExpandedHandler()}>
                {isAllRowsExpanded ? <Icons.CollapseAll /> : <Icons.ExpandAll />}
            </AddressTableRowExpandContainer>
        );
    },
    cell: ({ row }) => {
        return !row.getCanExpand() ? null : row.getIsExpanded() ? <Icons.Collapse /> : <Icons.Expand />;
    },
    meta: {
        headerStyleProps: {
            width: "32px",
            maxWidth: "32px",
        },
        cellStyleProps: {
            width: "32px",
            maxWidth: "32px",
        },
    },
});

export const nameColumn = columnHelper.accessor((row) => row, {
    header: "Name",
    cell: (info) => (
        <GroupCellWrapper
            data={info.getValue()}
            renderGroupRow={(groupRow) => <CpCell cpId={groupRow.cp?.id} />}
            renderItemRow={(itemRow) => <CurrencyCell currency={itemRow.currency?.name} />}
        />
    ),
    enableSorting: false,
    meta: {
        headerStyleProps: {
            width: "140px",
            minWidth: "140px",
            maxWidth: "140px",
        },
    },
});

export const walletColumn = columnHelper.accessor((row) => row, {
    header: "Your address",
    cell: (info) => (
        <GroupCellWrapper data={info.getValue()} renderItemRow={(itemRow) => <WalletCell wallet={itemRow.wallet} />} />
    ),
    enableSorting: false,
    meta: {
        headerStyleProps: {
            width: "272px",
            textAlign: "start",
            minWidth: "272px",
        },
        cellStyleProps: {
            width: "272px",
            textAlign: "start",
            minWidth: "272px",
        },
    },
});

export const statusColumn = columnHelper.accessor((row) => row, {
    header: "Status",
    cell: (info) => (
        <GroupCellWrapper
            data={info.getValue()}
            renderGroupRow={({ count, needToConfirmCount }) => (
                <GroupRowInfoCell subRowCount={count} needToConfirmCount={needToConfirmCount} />
            )}
            renderItemRow={({ status }) => <StatusCell status={status} />}
        />
    ),
    enableSorting: false,
    meta: {
        headerStyleProps: {
            textAlign: "end",
            width: "120px",
            minWidth: "120px",
            maxWidth: "120px",
        },
        cellStyleProps: {
            textAlign: "end",
            width: "120px",
            maxWidth: "120px",
        },
    },
});

export const actionColumn = columnHelper.accessor((row) => row, {
    id: ACTIONS_TABLE_COLUMN_KEY,
    header: "",
    cell: (info) => (
        <GroupCellWrapper data={info.getValue()} renderItemRow={({ status }) => <ActionCell status={status} />} />
    ),
    enableSorting: false,
    meta: {
        headerStyleProps: {
            width: "40px",
            minWidth: "40px",
            maxWidth: "40px",
        },
        cellStyleProps: {
            width: "40px",
            minWidth: "40px",
            maxWidth: "40px",
        },
    },
});
