import { OutlinedButton } from "feature/form/Buttons";
import EfxFormCheckbox from "feature/form/FormCheckbox";
import FormInput from "feature/form/FormInput";
import { FormHeader } from "feature/form/style";
import { FormConfig, useForm } from "feature/form/useForm";
import React from "react";
import { ButtonGroup, Form } from "style";

const keyForm: FormConfig = {
    fields: {
        tag: { placeholder: "Tag", optional: true },
        allowTrade: { title: "Trade", type: "boolean" },
        allowSettlement: { title: "Settlement", type: "boolean" },
        allowLimitsManagement: { title: "Risk management", type: "boolean" },
    },
    url: "addKey",
};
export const KeyForm = ({ onSubmit: afterSubmit }: { onSubmit: (key: object) => void }) => {
    const { propsFor, globalError, onSubmit, areActionsDisabled } = useForm(keyForm);

    return (
        <Form>
            <FormHeader>Create API Key</FormHeader>
            <FormInput {...propsFor("tag")} />
            <EfxFormCheckbox {...(propsFor("allowTrade") as any)} />
            <EfxFormCheckbox {...(propsFor("allowSettlement") as any)} />
            <EfxFormCheckbox {...(propsFor("allowLimitsManagement") as any)} />
            {globalError}
            <ButtonGroup>
                <OutlinedButton
                    type="submit"
                    disabled={areActionsDisabled}
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        onSubmit().then((key) => key && afterSubmit(key));
                    }}
                >
                    Add Key
                </OutlinedButton>
            </ButtonGroup>
        </Form>
    );
};
