import { parseLimit } from "feature/counterparties/utils";
import { useMemo } from "react";
import { useFilteredAndExtendedCounterpartyLimitsSnapshot } from "store/hooks";
import { useCpInfoHelpers } from "./useCpInfoHelpers";
import { useFilteredLimits } from "./useFilteredLimits";
import { CounterpartyLimitIndex } from "../types";

type Options = {
    isMutual?: boolean;
    specificCps?: number[];
};
export const useCounterparties = (options?: Options) => {
    const { data: counterpartyLimits, isLoading } =
        useFilteredAndExtendedCounterpartyLimitsSnapshot();

    const matchedCpIds = counterpartyLimits.reduce<number[]>((acc, cpLimit) => {
        const limit = parseLimit(cpLimit.limit);
        const includedOrMutual = options?.isMutual ? limit.mutualGrossLimit !== null : true;
        const includedOrSpecific = options?.specificCps?.length
            ? options.specificCps.includes(limit.counterpartyId)
            : true;

        const shouldInclude = includedOrMutual && includedOrSpecific;

        if (shouldInclude) {
            acc.push(limit.counterpartyId);
        }

        return acc;
    }, []);
    const cpIds: number[] = useMemo(() => matchedCpIds, [matchedCpIds.join("")]);

    const { getCpName } = useCpInfoHelpers();

    const counterparties = useMemo(() => {
        return cpIds.reduce((acc, cpId) => {
            if (!acc[cpId]) {
                acc[cpId] = getCpName(String(cpId), "full");
            }

            return acc;
        }, {} as Record<number, string>);
    }, [cpIds, getCpName]);

    return {
        counterparties,
        cpIds,
        isLoading,
    };
};

export const useFilteredCpsIdsFromLimits = (refetchIntervalMs?: number) => {
    const { data: filteredLimits, isLoading } = useFilteredLimits(refetchIntervalMs);

    return useMemo(
        () => ({
            cpsIds: filteredLimits?.map((limit) => limit[CounterpartyLimitIndex.counterpartyId]),
            isLoading,
        }),
        [filteredLimits, isLoading],
    );
};
