import { useEffect, useState } from "react";
import { ANIMATION_TIMEOUT } from "./styled";

export enum DelayMode {
    All,
    None,
    OnOpen,
    OnClose,
}

export const useDelayedIsOpen = (
    isOpen: boolean,
    delayMode = DelayMode.OnClose,
    animationTimeout = ANIMATION_TIMEOUT,
) => {
    const [delayedIsOpen, setDelayedIsOpen] = useState(isOpen);

    useEffect(() => {
        if ([DelayMode.None, DelayMode.OnOpen].includes(delayMode) && !isOpen) {
            setDelayedIsOpen(false);
            return;
        }

        if ([DelayMode.None, DelayMode.OnClose].includes(delayMode) && isOpen) {
            setDelayedIsOpen(true);
            return;
        }

        if ([DelayMode.All, DelayMode.OnOpen].includes(delayMode) && isOpen && !delayedIsOpen) {
            const timeoutId = setTimeout(() => setDelayedIsOpen(true), animationTimeout);

            return () => clearTimeout(timeoutId);
        }

        if ([DelayMode.All, DelayMode.OnClose].includes(delayMode) && !isOpen && delayedIsOpen) {
            const timeoutId = setTimeout(() => setDelayedIsOpen(false), animationTimeout);

            return () => clearTimeout(timeoutId);
        }
    }, [isOpen]);

    return delayedIsOpen;
};
