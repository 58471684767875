import { FC } from "react";
import { Icons } from "../../icons";
import { HStack, VStack } from "../../layout";
import { H2 } from "../../typography";

type LightboxHeaderProps = {
    title: string;
    essence?: string | (() => React.ReactNode);
    className?: string;
};

export const LightboxHeader: FC<LightboxHeaderProps> = ({ title, essence, className }) => {
    return (
        <VStack className={className} padding={12} spacing={2}>
            <HStack spacing={4} alignItems="center">
                <Icons.File />
                <H2>{title}</H2>
            </HStack>
            {typeof essence === "string" ? essence : essence?.()}
        </VStack>
    );
};
