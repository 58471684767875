import styled, { css } from "styled-components";
import { onMobile } from "../../const";

export const ANIMATION_TIMEOUT = 200;

export const Actions = styled.div`
    position: absolute;
    right: -44px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${onMobile} {
        top: -44px;
        flex-direction: row-reverse;
        right: 0;
    }
`;

export const modalAnimationCss = css`
    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity ${ANIMATION_TIMEOUT}ms ease-in-out;
    }

    .ReactModal__Overlay--after-open {
        opacity: 1;
    }

    .ReactModal__Overlay--before-close {
        opacity: 0;
    }
`;
