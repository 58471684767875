import { P, VStack } from "@fm-frontend/uikit";
import styled from "styled-components";
import { WithdrawalRequest } from "./types";
import { WithdrawalRequestInfo } from "./WithdrawalRequestInfo";

const Description = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;

export const DeleteWithdrawalRequestModalBody = ({ withdrawal }: { withdrawal: WithdrawalRequest }) => (
    <VStack spacing={8}>
        <Description>This action cannot be undone. Are you sure you want to delete this request?</Description>
        <WithdrawalRequestInfo withdrawalRequest={withdrawal} />
    </VStack>
);
