import { Box, P } from "@fm-frontend/uikit";
import { trimAddress } from "@fm-frontend/utils";
import React from "react";

export const WalletCell: React.FC<{ wallet: string | undefined }> = ({ wallet }) => {
    return (
        <Box ellipsis width="180px">
            <P>{trimAddress(wallet)}</P>
        </Box>
    );
};
