import { OwnerIcon } from "../OwnerIcon";
import { Email, Name, UserCard } from "../styled";
import { OwnerPill } from "./styled";
import type { OwnerProps } from "./types";

export const Owner = ({ name, email }: OwnerProps) => {
    const userInfo = (
        <div>
            <Name>{name}</Name>
            <Email>{email}</Email>
        </div>
    );

    return (
        <UserCard>
            <OwnerIcon />
            {userInfo}
            <OwnerPill>Owner</OwnerPill>
        </UserCard>
    );
};
