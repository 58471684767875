import { H2, Mark, P, PlainButton, PrimaryButton, VStack } from "@fm-frontend/uikit";
import { EmDash } from "@fm-frontend/utils";
import { FormError } from "feature/form/style";
import { useFormHelpers } from "hooks";
import React from "react";
import { useTheme } from "styled-components";
import { acknowledgeSharedAccountRevision } from "../../api";
import {
    addressSectionItem,
    assetSectionItem,
    fromSectionItem,
    memoOrTagSectionItem,
    SectionItem,
    TSectionItem,
} from "../../components";
import { CryptoAddress } from "../../types";
import { parseCryptoAddress } from "../../utils";

type ConfirmCryptoModalProps = {
    revisionHash: string;
    address: CryptoAddress;
    onCancel: () => void;
    onConfirmSuccess: () => void;
};

export const ConfirmCryptoAddressModal: React.FC<ConfirmCryptoModalProps> = ({
    address,
    revisionHash,
    onCancel,
    onConfirmSuccess,
}) => {
    const { colors } = useTheme();
    const { accountId, wallet, currency, cpId, cpName, network, memoOrTag } = parseCryptoAddress(address);
    const { isLoading, startLoading, stopLoading, error, setError } = useFormHelpers();

    const sections: TSectionItem[] = [
        fromSectionItem(cpId, cpName),
        assetSectionItem(currency, network),
        addressSectionItem(wallet),
        memoOrTagSectionItem(memoOrTag),
    ];

    const handleConfirmAddress = async () => {
        if (accountId === undefined) {
            return;
        }
        startLoading();
        const response = await acknowledgeSharedAccountRevision(accountId, revisionHash);
        setError(response.error);
        stopLoading();
        onConfirmSuccess();
    };

    const getInfoElement = () => {
        return (
            <P color={colors.ui52}>
                Once the address is confirmed, you will be able to send settlement transactions to{" "}
                <Mark color={colors.ui100} weight={500}>
                    {cpName ?? EmDash}
                </Mark>{" "}
                for{" "}
                <Mark color={colors.ui100} weight={500}>
                    {currency ?? EmDash} ({network ?? EmDash} network)
                </Mark>{" "}
                positions.
            </P>
        );
    };

    return (
        <VStack minWidth="360px" asCard>
            <VStack padding={12}>
                <H2>Confirm new address</H2>
            </VStack>
            <VStack paddingBottom={12}>
                {sections.map((sectionItem) => (
                    <SectionItem key={sectionItem.title} sectionItem={sectionItem} />
                ))}
                <VStack paddingY={6} paddingX={12} justifyContent="center">
                    <VStack paddingY={6} paddingX={12} width="336px" asCard spacing={4}>
                        <P color={colors.ui52}>Ensure that you have done a Satoshi test.</P>
                        {getInfoElement()}
                    </VStack>
                </VStack>
                {error && (
                    <VStack paddingY={6} paddingX={12}>
                        <FormError>{error}</FormError>
                    </VStack>
                )}
            </VStack>
            <VStack paddingX={12} paddingBottom={16} spacing={8}>
                <PrimaryButton fullWidth size="large" type="submit" onClick={handleConfirmAddress} loading={isLoading}>
                    Confirm address
                </PrimaryButton>
                <PlainButton fullWidth size="large" type="button" onClick={onCancel}>
                    Cancel
                </PlainButton>
            </VStack>
        </VStack>
    );
};
