import { ReactNode, useMemo } from "react";
import styled from "styled-components";
import { Context } from "./utils";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    overflow: auto;
`;

type TabContextProps<TValue extends string> = {
    value: TValue;
    handleClick: (value: TValue) => void;
    className?: string;
    children?: ReactNode;
};

export const TabContext = <TValue extends string>({ value, handleClick, className, children }: TabContextProps<TValue>) => {
    const context = useMemo(
        () => ({
            value: value as string,
            handleClick: handleClick as (value: string) => void,
        }),
        [value, handleClick],
    );

    return (
        <Context.Provider value={context}>
            <Container className={className}>{children}</Container>
        </Context.Provider>
    );
};
