import { cancelled, select, take } from "@redux-saga/core/effects";
import { IRootState } from "../store";

// runs selector on each action dispatch or takePattern
// when value changes, fn is called
export function* observe<T>(
    selector: (state: IRootState) => T,
    fn: (value: T) => { effect: unknown; cleanup?: unknown } | void,
    takePattern = "*",
) {
    let cleanupEffect: unknown | undefined;
    let value: T | undefined;
    try {
        while (true) {
            const newValue: T = yield select(selector);
            if (newValue !== undefined && !Object.is(value, newValue)) {
                if (cleanupEffect) {
                    yield cleanupEffect;
                    cleanupEffect = undefined;
                }
                const { effect, cleanup } = fn(newValue) || {};
                value = newValue;
                if (effect) yield effect;
                if (cleanup) cleanupEffect = cleanup;
            }
            yield take(takePattern);
        }
    } finally {
        const isCancelled: boolean = yield cancelled();
        if (isCancelled && cleanupEffect) {
            yield cleanupEffect;
        }
    }
}
