import { H1, P as CopyrightContainer, P as LinkWrapper, P, PBold } from "@fm-frontend/uikit";
import { onMobile } from "const";
import { IS_TEST_ENV } from "const/env";
import styled from "styled-components";

export const OnboardingContent = styled.div`
    margin-bottom: 64px;
    flex-basis: 0;
    flex-grow: 999;
    min-width: 50%;
    margin-left: -220px;
    ${onMobile} {
        margin-bottom: 16px;
        margin-left: unset;
    }
`;

export const Layout = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    align-items: center;
    margin-top: 18px;
    padding: 0 12px;

    ${H1} {
        line-height: 26px;
    }
`;

export const Header = styled.header`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
    width: 100%;

    ${H1} {
        margin: 0;
    }

    ${P} {
        color: ${(p) => p.theme.colors.ui72};
    }
`;

export const UserOptionsContainer = styled.div`
    position: absolute;
    right: 10px;
    top: ${IS_TEST_ENV ? 37 : 13}px;
`;

export const CheckboxGroupLabel = styled(P)`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: ${(p) => p.theme.colors.ui72};

    span {
        color: ${(p) => p.theme.colors.ui32};
    }
`;

export const CheckboxGroup = styled.section`
    display: flex;
    width: 100%;
    gap: 12px;
    flex-direction: column;
    padding-bottom: 2px;
`;

export const CheckboxContainer = styled.section`
    display: flex;
    width: 100%;
    gap: 10px;
    flex-flow: row wrap;
`;

export const OnboardingFooter = styled.footer`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    align-items: center;
    justify-content: center;

    ${CopyrightContainer} {
        margin: 0;
        font-weight: 400;
        color: ${(p) => p.theme.colors.ui72};
    }

    ${onMobile} {
        margin-bottom: 64px;
    }
`;

export const LinksContainer = styled.div`
    display: flex;
    gap: 8px;

    ${LinkWrapper} > a {
        margin: 0;
        color: ${(p) => p.theme.colors.ui52};
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
    }
`;

export const LogoutWrapper = styled(LinkWrapper)`
    display: flex;
    align-items: center;
    gap: 6px;
    margin-left: 17px;
`;

export const WelcomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${P}, ${PBold} {
        color: ${(p) => p.theme.colors.uiWhite100};
    }
`;
