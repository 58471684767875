import styled, { css } from "styled-components";

export const errorCss = css`
    :enabled:active,
    :enabled:focus {
        box-shadow: 0 0 0 3px ${(p) => p.theme.colors.negative16}, inset 0 0 0 1px ${(p) => p.theme.colors.negative100};
    }

    box-shadow: 0 0 0 3px ${(p) => p.theme.colors.negative16}, inset 0 0 0 1px ${(p) => p.theme.colors.negative100};
`;

export const Mark = styled.mark<{ color?: string; weight?: number }>`
    color: ${(p) => p.color ?? p.theme.colors.brand100};
    font-weight: ${(p) => p.weight ?? 400};
`;
