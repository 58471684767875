import { Icons, PlainButton } from "@fm-frontend/uikit";
import { useFormShouldCloseConfirm, useToggle } from "@fm-frontend/utils";
import { useIsOwnerUser } from "hooks";
import React from "react";
import { useTheme } from "styled-components";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { ACCOUNT_REVISIONS_URL, BANK_URL, PAYMENT_ACCOUNTS_URL, PENDING_ACCOUNTS_REVISIONS_URL } from "../api";
import { AddressesModal } from "../components/AddressesModal";
import { InfoBadge } from "../components/InfoBadge";
import { useRefreshAddresses, useSaveSuccessNotification } from "../hooks";
import { AddressModalProps, BankAddress } from "../types";
import { ACCOUNT_IS_SAVED_NOTIFICATION, EDITING_UNAVAILABLE_MESSAGE, SAB_BANK_EDIT_MODAL_KEY } from "../utils";
import { ConfirmBankAddressModal } from "./modals/ConfirmBankAddressModal";
import { EditBankAddressModal } from "./modals/EditBankAddressModal";
import { OverviewBankAddressModal } from "./modals/OverviewBankAddressModal";

type ContentProps = Omit<AddressModalProps<BankAddress>, "isModalOpen">;

const BankAddressModalContent: React.FC<ContentProps> = ({ address, side, defaultEdit, closeModal }) => {
    const { colors } = useTheme();
    const isOwnerUser = useIsOwnerUser();
    const {
        isActive: isEditActive,
        activate: activateEdit,
        deactivate: deactivateEdit,
    } = useToggle(Boolean(defaultEdit));

    const shouldConfirmClose = useFormShouldCloseConfirm(SAB_BANK_EDIT_MODAL_KEY);
    const { closeModalWithConfirm: closeEditModalWithConfirm } = useModalCloseWithConfirm(
        shouldConfirmClose,
        defaultEdit ? closeModal : deactivateEdit,
    );
    const addressSaveNotification = useSaveSuccessNotification();

    const isSharedSide = side === "shared";
    const isYourSide = side === "your";

    const refreshAddresses = useRefreshAddresses();

    if (!address) {
        return null;
    }

    const handleEditSuccess = async () => {
        closeModal();
        addressSaveNotification(ACCOUNT_IS_SAVED_NOTIFICATION);
        await refreshAddresses([PAYMENT_ACCOUNTS_URL, BANK_URL, ACCOUNT_REVISIONS_URL]);
    };

    const handleConfirmSuccess = async () => {
        closeModal();
        await refreshAddresses([PENDING_ACCOUNTS_REVISIONS_URL]);
    };

    if (isEditActive && isYourSide && isOwnerUser) {
        return (
            <EditBankAddressModal
                address={address}
                onCancel={closeEditModalWithConfirm}
                onEditSuccess={handleEditSuccess}
            />
        );
    }

    const revisionHash = address.revisions?.[0]?.revisionHash;

    if (revisionHash && isSharedSide && isOwnerUser) {
        return (
            <ConfirmBankAddressModal
                revisionHash={revisionHash}
                address={address}
                onCancel={closeModal}
                onConfirmSuccess={handleConfirmSuccess}
            />
        );
    }

    const getEditAction = () => {
        if (isSharedSide) {
            return null;
        }

        if (!isOwnerUser) {
            return (
                <InfoBadge
                    info={EDITING_UNAVAILABLE_MESSAGE}
                    renderIcon={() => <Icons.Attention color={colors.ui32} />}
                />
            );
        }

        return (
            <PlainButton fullWidth size="large" type="button" onClick={activateEdit}>
                Edit
            </PlainButton>
        );
    };

    return <OverviewBankAddressModal side={side} address={address} actions={getEditAction} />;
};

export const BankAddressModal: React.FC<AddressModalProps<BankAddress>> = ({ closeModal, isModalOpen, ...rest }) => {
    const shouldCloseConfirm = useFormShouldCloseConfirm(SAB_BANK_EDIT_MODAL_KEY);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(shouldCloseConfirm, closeModal);

    return (
        <AddressesModal isOpen={isModalOpen} onClose={closeModalWithConfirm}>
            <BankAddressModalContent closeModal={closeModal} {...rest} />
        </AddressesModal>
    );
};
