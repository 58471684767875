import { css } from "styled-components";
export const buttonBase = css`
    margin: 0;
    padding: 0;
    border: none;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    background: none;
    user-select: none;
    cursor: pointer;
`;
