import { createNotification } from "feature/app";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

export const useSaveSuccessNotification = () => {
    const dispatch = useDispatch();

    return useCallback(
        (content: string | JSX.Element) => {
            dispatch(
                createNotification({
                    type: "success",
                    content: content,
                }),
            );
        },
        [dispatch],
    );
};
