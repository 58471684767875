export interface Inputs {
    negative: number | string;
    positive: number | string;
}

export enum InstrumentGroupType {
    All = "all",
    CryptoCrypto = "cryptoCrypto",
    CryptoFiat = "cryptoFiat",
    CryptoStablecoin = "cryptoStablecoin",
    StablecoinFiat = "stablecoinFiat",
    StablecoinStablecoin = "stablecoinStablecoin",
}

export enum CounterpartyGroupType {
    All = "all",
    Takers = "takers",
    Masters = "masters",
}

export enum AssetsGroupType {
    All = "all",
    Crypto = "crypto",
    Stablecoin = "stablecoin",
    Fiat = "fiat",
}

const CP_GROUP_TYPES = [
    "all",
    "externalTakers",
    "externalMakers",
    "externals",
    "subaccountTakers",
    "subaccountMakers",
    "subaccounts",
] as const;
type CpGroupTypeTuple = typeof CP_GROUP_TYPES;
export type CpGroupType = CpGroupTypeTuple[number];
export enum CpGroupTypeEnum {
    All = "all",
    ExternalTakers = "externalTakers",
    ExternalMakers = "externalMakers",
    Externals = "externals",
    SubaccountTakers = "subaccountTakers",
    SubaccountMakers = "subaccountMakers",
    Subaccounts = "subaccounts",
}

export const isCpGroupType = (value: string): value is CpGroupType => {
    return CP_GROUP_TYPES.includes(value as CpGroupType);
};

export type AdjustType = "tradingLimits" | "shortSalesBan" | "overnightFees";

export interface ModalConfig {
    cpName?: string;
    assets: string | AssetsGroupType;
    cp?: string | CpGroupType;
    adjust?: AdjustType;
    grossLimit?: bigint | null;
    limitCurrency?: string | null;
    showCpSelector?: boolean;
}

export const DEFAULT_ASSETS_OPTION: AssetsGroupType = AssetsGroupType.All;
export const DEFAULT_MODAL_CONFIG: ModalConfig = {
    assets: DEFAULT_ASSETS_OPTION,
};

export type LimitInfo = {
    totalLimit: bigint;
    limitAsset: string;
    freeLimit: bigint;
    freeLimitPercent: number;
    freeLimitUsd: bigint;
    significantDigits: number;
};
