import { PBold } from "@fm-frontend/uikit";
import React, { ButtonHTMLAttributes, FC, forwardRef } from "react";
import styled from "styled-components";
import { Loading } from "./EmptyState";
import { NoQuotesSide } from "./EmptyState/NoQuoteSide";
import { List } from "./List";

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    font-size: 12px;
    min-width: 540px;

    :before {
        position: absolute;
        z-index: 1;
        content: "";
        height: 1px;
        background-color: ${(p) => p.theme.colors.ui8};
        width: 100%;
    }
`;

const ActionContainer = styled.button`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 12px;
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    cursor: pointer;

    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.ui8};
    backdrop-filter: blur(13.5px);

    opacity: 0;
    z-index: 1;
`;

const Body = styled.div<{ isExpanded: boolean }>`
    position: relative;
    min-height: ${(p) => (p.isExpanded ? "700px" : "280px")};
    display: flex;
    flex-direction: row;
    overflow: auto;

    :hover {
        ${ActionContainer} {
            opacity: 1;
            transition: opacity 500ms;
        }
    }
`;

export interface MarkerDepthExpanderProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    isExpanded: boolean;
}

export const MarkerDepthExpander = forwardRef<HTMLButtonElement, MarkerDepthExpanderProps>(
    ({ isExpanded, ...props }, ref) => {
        return (
            <ActionContainer ref={ref} {...props} type="button">
                <PBold>{isExpanded ? "Collapse" : "Expand"}</PBold>
            </ActionContainer>
        );
    },
);

type Level = [price: number | bigint, size: number | bigint];
type MarketDepthProps = {
    restrictionState?: React.ReactNode | null;
    loading: boolean;
    bidList: Level[];
    askList: Level[];
    fullDepth: boolean;
    handleFullDepth: () => void;
};

export const MarketDepth: FC<MarketDepthProps> = ({
    restrictionState,
    loading,
    bidList,
    askList,
    fullDepth,
    handleFullDepth,
}) => {
    const getContent = () => {
        if (loading) {
            return <Loading />;
        }
        if (restrictionState) {
            return <>{restrictionState}</>;
        }

        return (
            <>
                {bidList.length === 0 && <NoQuotesSide type="bid" />}
                {bidList.length > 0 && <List type="bid" list={bidList} />}
                {askList.length === 0 && <NoQuotesSide type="ask" />}
                {askList.length > 0 && <List type="ask" list={askList} />}
            </>
        );
    };

    return (
        <Container>
            <Body isExpanded={fullDepth}>
                {getContent()}
                <MarkerDepthExpander isExpanded={fullDepth} onClick={handleFullDepth} />
            </Body>
        </Container>
    );
};
