import { useHistory } from "react-router";
import { Notification } from "services/notificationsService";
import { isSubaccountCp } from "feature/counterparties/utils";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { usePrimeBrokerViewTypeChange } from "hooks/usePrimeBrokerViewTypeChange";
import { actionChannel } from "store/actionChannel";
import { useIsPrimeBrokerUser, useIsSubaccountUser } from "store/hooks";
import { getNotificationDetailsUrl } from "../const";
import { notificationsReadRequest } from "../notifications.store";

export const markNotificationAsRead = (id: number, isRead: boolean) => {
    if (!isRead) {
        actionChannel.put(notificationsReadRequest({ ids: [id] }));
    }
};

export const useNotificationTransition = (notification: Notification) => {
    const { id, messageType, isRead, metadata } = notification;

    const history = useHistory();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const isSubaccountUser = useIsSubaccountUser();
    const changePrimeBrokerViewType = usePrimeBrokerViewTypeChange();
    const { getCpType } = useCpInfoHelpers();

    return () => {
        markNotificationAsRead(id, isRead);

        const detailsPageUrl = getNotificationDetailsUrl(messageType, metadata);

        if (!detailsPageUrl) {
            return;
        }

        if (metadata.cpId === undefined) {
            history.push(detailsPageUrl);
            return;
        }

        const isSubaccount = isSubaccountCp(getCpType(metadata.cpId));

        if (isPrimeBrokerUser && isSubaccount) {
            changePrimeBrokerViewType("subaccounts", true);
            history.push(detailsPageUrl.replace("/transactions", "/subaccountsViewTransactions"));
            return;
        }

        if (isPrimeBrokerUser && !isSubaccount) {
            changePrimeBrokerViewType("counterparties", true);
            history.push(detailsPageUrl);
            return;
        }

        if (isSubaccountUser) {
            history.push(detailsPageUrl.replace("/transactions", "/subaccountTransactions"));
            return;
        }

        history.push(detailsPageUrl);
    };
}
