import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { ModalDialog } from "../../../components/modal/Modal";
import { useSelector } from "../../../hooks/useSelector";
import { useItemTransition } from "../../../hooks/useTransition";
import { ButtonGroup } from "../../../style";
import { setIsPermissionsLackErrorOccured } from "../../app";
import { OutlinedButton } from "../../form/Buttons";
import { Content, Message, OwnerButton, Title } from "./styled";

export const PermissionsLackErrorModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isOpened = useSelector((state) => state.app.isPermissionsLackErrorOccured);
    const { isActive, transitionState } = useItemTransition(isOpened);
    const handleClose = () => {
        dispatch(setIsPermissionsLackErrorOccured(false));
    };
    const handleOwnerButtonClick = () => {
        history.push("/settings/usersmanagement");
        handleClose();
    };

    if (!isActive) {
        return null;
    }

    return (
        <ModalDialog isOpen isDismissable onClose={handleClose} transitionState={transitionState} size="small">
            <Content>
                <Title>Sorry, not enough permissions</Title>
                <Message>
                    To change or add your permisions contact the{" "}
                    <OwnerButton onClick={handleOwnerButtonClick}>Owner</OwnerButton>.
                </Message>
                <ButtonGroup>
                    <OutlinedButton onClick={handleClose}>Ok, that is clear</OutlinedButton>
                </ButtonGroup>
            </Content>
        </ModalDialog>
    );
};
