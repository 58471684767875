import { useEffect } from "react";
import ReactGA from "react-ga4";
import { GA4, Options } from "./types";

export const useGA4 = (measurementId: string, initOptions?: Options): GA4 => {
    useEffect(() => {
        if (measurementId === "") {
            return;
        }

        try {
            ReactGA.initialize(measurementId, initOptions);
        } catch (e) {
            console.error(e);
        }
    }, [measurementId, initOptions]);

    const logPageView = () => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    };

    const logEvent = (category: string, action: string, label?: string, value?: number) => {
        ReactGA.event({ category, action, label, value });
    };

    const logCustomEvent = (eventName: string, eventParams?: Record<string, string | number>) => {
        ReactGA.event(eventName, eventParams);
    };

    return { logPageView, logEvent, logCustomEvent };
};
