import { Icons, PSmall, SimpleInput } from "@fm-frontend/uikit";
import { BasicInput } from "@fm-frontend/uikit/src/components/inputs/Input/styled";
import { SpecificInputProps } from "@fm-frontend/uikit/src/components/inputs/types";
import { forwardRef, useState } from "react";
import styled, { useTheme } from "styled-components";

const Container = styled.div`
    position: relative;

    ${BasicInput} {
        padding-right: 46px;
    }
`;

const InputAppend = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    overflow: hidden;
    height: 100%;

    gap: 8px;

    align-items: center;
`;

const ActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 24px;
    overflow: hidden;
    height: 100%;

    border-left: 1px solid ${(p) => p.theme.colors.ui8};
`;

const IncrementButton = styled.button`
    background-color: transparent;
    border-bottom: 0.5px solid ${(p) => p.theme.colors.ui8};
    height: 50%;
    cursor: pointer;

    border-top-right-radius: 8px;

    :hover {
        background-color: ${(p) => p.theme.colors.ui8};
    }
    :focus-visible {
        box-shadow: 0px 0px 0px 2px ${(p) => p.theme.colors.brand12},
        inset 0px 0px 0px 1px ${(p) => p.theme.colors.brand72};
`;
const DecrementButton = styled.button`
    background-color: transparent;
    height: 50%;
    cursor: pointer;
    border-top: 0.5px solid ${(p) => p.theme.colors.ui8};
    border-bottom-right-radius: 8px;

    :hover {
        background-color: ${(p) => p.theme.colors.ui8};
    }

    :focus-visible {
        box-shadow: 0px 0px 0px 2px ${(p) => p.theme.colors.brand12},
        inset 0px 0px 0px 1px ${(p) => p.theme.colors.brand72};
`;

interface ToleranceInputProps extends SpecificInputProps {
    onIncrement: () => void;
    onDecrement: () => void;
}

export const ToleranceInput = forwardRef<HTMLInputElement, ToleranceInputProps>(
    ({ onIncrement, onDecrement, ...props }, ref) => {
        const [focused, setFocused] = useState(false);
        const onFocus = () => setFocused(true);
        const onBlur = () => setFocused(false);

        const { colors } = useTheme();

        return (
            <Container>
                <SimpleInput
                    data-field-group
                    ref={ref}
                    {...props}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                <InputAppend>
                    <PSmall color={focused ? colors.ui100 : colors.ui32}>%</PSmall>
                    <ActionContainer>
                        <IncrementButton type="button" onClick={onIncrement}>
                            <Icons.Increment />
                        </IncrementButton>
                        <DecrementButton type="button" onClick={onDecrement}>
                            <Icons.Decrement />
                        </DecrementButton>
                    </ActionContainer>
                </InputAppend>
            </Container>
        );
    },
);
