import { EMPTY_ARRAY } from "const";
import { useMemo } from "react";
import useSWR from "swr";
import { displayError } from "utils";
import { ACCOUNT_REVISIONS_URL, postDataFromResult } from "../api";
import { ApiAddressRevisionEntity } from "../entities/ApiAddressRevisionEntity";
import { CpRevisionHash } from "../types";

type SWRKey = string;
export const useAccountRevisions = (cpId?: number) => {
    const fetcher = (url: string) =>
        postDataFromResult(url, {
            cpId,
        });
    const { data, isLoading } = useSWR<ApiAddressRevisionEntity[], any, SWRKey>(ACCOUNT_REVISIONS_URL, fetcher, {
        onError: displayError,
        fallbackData: EMPTY_ARRAY as ApiAddressRevisionEntity[],
    });

    const accountsRevisions = useMemo(() => {
        return (
            data?.reduce((acc, curr) => {
                acc[curr.accountId] ??= [];
                acc[curr.accountId].push({
                    cpId: curr.clientId,
                    revisionHash: curr.revisionHash,
                } as CpRevisionHash);

                return acc;
            }, {} as Record<number, CpRevisionHash[]>) ?? {}
        );
    }, [data]);

    return useMemo(
        () => ({
            accountsRevisions,
            isRevisionsLoading: isLoading,
        }),
        [data, isLoading],
    );
};
