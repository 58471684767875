import { VFC } from "react";
import { MainTabsContextProvider, useSelectedAssetControlPage } from "./AssetControlMenu";
import { AssetsTabContent } from "./AssetsTabContent";
import { InstrumentsTabContent } from "./InstrumentsTabContent";
import { WhitelistingTabContent } from "./WhitelistingTabContent";

export const ContentView: VFC = () => {
    const selectedPage = useSelectedAssetControlPage();

    switch (selectedPage) {
        case "assets":
            return <AssetsTabContent />;
        case "instruments":
            return <InstrumentsTabContent />;
        case "whitelisting":
            return <WhitelistingTabContent />;
        default:
            return null;
    }
};

export const Content = () => (
    <MainTabsContextProvider>
        <ContentView />
    </MainTabsContextProvider>
);
