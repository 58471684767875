import { P } from "@fm-frontend/uikit";
import { EmDash } from "const";
import React from "react";
import styled from "styled-components";
import { DealSide } from "types";
import { fmt, FormatConfig } from "utils/format";
import { TradeHistory } from "../../../types";
import { CopyCell } from "../CopyCell";

const SellIndicator = styled(P)`
    color: ${(p) => p.theme.colors.negative100};
`;
const BuyIndicator = styled(P)`
    color: ${(p) => p.theme.colors.positive100};
`;

type PriceCellProps = Pick<TradeHistory, "price" | "side">;

export const priceCellFormatConfig: FormatConfig = {
    significantDigits: 8,
    type: "price",
    showZero: true,
};

export let PriceCell: React.FC<PriceCellProps> = ({ price, side }) => {
    if (price === undefined || side === undefined) {
        return <>{EmDash}</>;
    }

    const fmtPrice = fmt(price, priceCellFormatConfig);

    return (
        <CopyCell content={fmtPrice.copyableValue}>
            {side === DealSide.Buy ? (
                <BuyIndicator>{fmtPrice.formattedValue}</BuyIndicator>
            ) : (
                <SellIndicator>{fmtPrice.formattedValue}</SellIndicator>
            )}
        </CopyCell>
    );
};
