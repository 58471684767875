import { Flex, HStack, PBold, PXSmall, VStack } from "@fm-frontend/uikit";
import styled from "styled-components";

const TileLayout = styled(VStack)`
    position: relative;
    border-radius: 12px;
    background-color: ${(p) => p.theme.colors.ui4};
    height: 166px;
`;

const Content = styled(VStack)`
    flex: 1;
    padding: 0 24px;
    gap: 8px;
    justify-content: center;
`;

const Title = styled(VStack)`
    text-align: center;
`;

const Description = styled(VStack)`
    gap: 4px;
    color: ${(p) => p.theme.colors.ui52};
    text-align: center;
`;

const FakeActions = styled(HStack)`
    height: 57px;
    border-top: 1px solid ${(p) => p.theme.colors.ui8};
`;

const LeftFakeButton = styled(Flex)`
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const RightFakeButton = styled(LeftFakeButton)`
    border-left: 1px solid ${(p) => p.theme.colors.ui8};
`;

export const FakeButtonIndicator = styled.div<{ $isSell?: boolean }>`
    width: 40px;
    height: 6px;
    border-radius: 8px;
    background-color: ${(p) => (p.$isSell ? p.theme.colors.negative8 : p.theme.colors.brand8)};
`;

const CurveArrowIcon = (props: React.SVGProps<SVGSVGElement> = {}) => (
    <svg
        width="61"
        height="82"
        viewBox="0 0 61 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M-14.8113 80.1862C-4.20272 82.0621 5.85071 80.8735 15.349 76.6202C24.8472 72.367 32.2028 65.7259 37.4157 56.6969C40.9037 50.6555 42.9668 44.2119 43.6051 37.3661C44.2433 30.5203 43.4721 23.7238 41.2916 16.9767L55.584 25.2284L60.1836 17.2617L31.1845 0.519071L15.0859 28.4026L23.3714 33.1862L31.8806 18.4478C33.8614 24.1058 34.6265 29.8365 34.1758 35.6399C33.7251 41.4433 32.0433 46.8677 29.1302 51.9133C25.2206 58.685 19.7629 63.8327 12.7572 67.3563C5.75159 70.88 -1.77059 72.2687 -9.80929 71.5224L-14.8113 80.1862Z"
            fill="url(#paint0_linear_2664_68669)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_2664_68669"
                x1="37.7976"
                y1="0.631212"
                x2="14.393"
                y2="86.8733"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#16C7B0" stop-opacity="0.80974" />
                <stop offset="1" stop-color="#16C7B0" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>
);
const StyledCurveArrowIcon = styled(CurveArrowIcon)`
    position: absolute;
    left: 0;
    top: 11px;
`;

const Strong = styled.span`
    color: ${(p) => p.theme.colors.brand100};
`;

export const NoTilesTile = () => (
    <TileLayout>
        <StyledCurveArrowIcon />
        <Content>
            <Title>
                <PBold>
                    <Strong>Add</Strong> new instrument
                </PBold>
            </Title>
            <Description>
                <PXSmall>Select it from a list or search manually.</PXSmall>
                <PXSmall>
                    The instrument will be available in the selected trading mode after adding it
                </PXSmall>
            </Description>
        </Content>
        <FakeActions>
            <LeftFakeButton>
                <FakeButtonIndicator $isSell />
            </LeftFakeButton>
            <RightFakeButton>
                <FakeButtonIndicator />
            </RightFakeButton>
        </FakeActions>
    </TileLayout>
);
