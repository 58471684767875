import { onMobile, onTablet } from "const";
import styled from "styled-components";

export const Shifter = styled.div`
    width: 228px;

    ${onTablet}, ${onMobile} {
        display: none;
    }
`;
