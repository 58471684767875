import { FC, SyntheticEvent } from "react";
import styled from "styled-components";
import { Notification } from "services/notificationsService";
import { getNotificationLinkText } from "../const";
import { useNotificationTransition } from "../hooks/useNotificationTransition";

const Content = styled.div`
    color: ${p => p.theme.colors.uiWhite80};
`;

const Link = styled.div`
    color: ${p => p.theme.colors.brand100};
    cursor: pointer;
`;

type NotificationContentProps = {
    notification: Notification
}

export const NotificationContent: FC<NotificationContentProps> = ({ notification }) => {
    const { messageType, content } = notification;
    const linkText = getNotificationLinkText(messageType);
    const notificationTransition = useNotificationTransition(notification);

    const handleLinkClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        notificationTransition();
    };

    return (
        <div>
            <Content>{content}</Content>
            {linkText && (
                <Link onClick={handleLinkClick}>{linkText}</Link>
            )}
        </div>
    );
}
