import { Icons, VStack } from "@fm-frontend/uikit";
import { FC } from "react";
import styled, { css } from "styled-components";
import { selectors } from "style/theme";

const Container = styled(VStack)`
    border-radius: 16px;
    background: ${(p) => p.theme.colors.ui4};
    padding: 12px;
    gap: 6px;
    max-width: 826px;
    width: 100%;
`;

const Circle = styled.div<{ $highlighted?: boolean; $done?: boolean }>`
    ${selectors.mixinPSmallBold};
    background: white;
    color: ${(p) => p.theme.colors.ui100};
    border: 1px solid ${(p) => p.theme.colors.ui20};
    border-radius: 12px;
    width: 24px;
    height: 24px;
    line-height: 22px;
    text-align: center;

    ${(p) => p.$highlighted && css`
        color: ${p.theme.colors.brand};
        border: 1px solid ${p.theme.colors.brand};
    `}

    ${(p) => p.$done && css`
        background: ${p.theme.colors.ui12};
        border: 1px solid transparent;
    `}
`;

const Content = styled.div`
    ${selectors.mixinP};

    text-align: center;
`;

type CardProps = {
    mark?: string;
    active?: boolean;
    done?: boolean;
}

export const Card: FC<CardProps> = ({ mark, active = false, done = false, children }) => {
    return (
        <Container>
            {!done && mark !== undefined && <Circle $highlighted={active}>{mark}</Circle>}
            {done && <Circle $done><Icons.Check /></Circle>}
            <Content>{children}</Content>
        </Container>
    );
};
