import { useEffect } from "react";
import isEqual from "lodash/isEqual";

export const use2WayBinding = <TValue = unknown>(
    value1: TValue,
    setValue1: (val: TValue) => void,
    value2: TValue,
    setValue2: (val: TValue) => void
) => {
    useEffect(() => {
        if (!isEqual(value1, value2)) {
            setValue2(value1);
        }
    }, [value1]);
    useEffect(() => {
        if (!isEqual(value1, value2)) {
            setValue1(value2);
        }
    }, [value2]);
};
