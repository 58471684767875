import { FC, memo, useCallback, useEffect, useState } from "react";

const GENERIC_ICON_PATH = `/icons/generic.svg`;

const CoinOverrides = {
    dai: "dai.png",
    shib: "shib.png",
    maticp: "matic.svg",
    "matic.polygon": "matic.svg",
    "bnb.bsc": "bnb.svg",
    bnbbsc: "bnb.svg",
    avaxc: "avax.png",
    avax: "avax.png",
    busdbs: "busd.svg",
    usdcp: "usdc.svg",
    usdttr: "usdt.svg",
    usdtsol: "usdt.svg",
} as Record<string, string | undefined>;

let nonExistingCoins: string[] = [];

type CoinIconProps = { coin: string; size?: number };

export const CoinIcon: FC<CoinIconProps> = memo(({ coin, size = 24 }) => {
    const normalizedCoinName = coin.toLowerCase();
    const [nonExistingCoin, setNonExistingCoin] = useState(nonExistingCoins.includes(normalizedCoinName));

    const iconImage = CoinOverrides[normalizedCoinName] ?? `${normalizedCoinName}.svg`;
    const iconPath = `/icons/${iconImage}`;

    const setExist = useCallback(() => {
        nonExistingCoins = nonExistingCoins.filter((i) => i !== normalizedCoinName);
        setNonExistingCoin(false);
    }, [normalizedCoinName]);

    const setNotExist = useCallback(() => {
        nonExistingCoins.push(normalizedCoinName);
        setNonExistingCoin(true);
    }, [normalizedCoinName]);

    useEffect(() => {
        if (nonExistingCoin) {
            const checkImage = new Image();
            checkImage.onload = setExist;
            checkImage.src = iconPath;

            return () => {
                checkImage.onload = null;
            };
        }
    }, [nonExistingCoin, iconPath]);

    return (
        <img
            src={nonExistingCoin ? GENERIC_ICON_PATH : iconPath}
            alt={coin}
            width={size}
            height={size}
            onError={setNotExist}
        />);
});
