import styled from "styled-components";

export const Badge = styled.span`
    display: inline-block;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    height: 15px;
    border-radius: 8px;
    background: ${(p) => p.theme.colors.brand100};
    color: ${(p) => p.theme.colors.uiWhite100};
    padding: 1px 4px;
`;

export const NotificationsButton = styled.button`
    position: relative;
    background: ${(p) => p.theme.colors.ui8};
    border-radius: 8px;
    width: 40px;
    height: 40px;
    cursor: pointer;

    &:hover {
        background: ${(p) => p.theme.colors.ui12};
    }

    &:focus-visible {
        box-shadow: 0px 0px 0px 3px ${(p) => p.theme.colors.brand12},
            inset 0px 0px 0px 1px ${(p) => p.theme.colors.brand72};
    }

    & > ${Badge} {
        position: absolute;
        right: -4px;
        top: -4px;
    }
`;
