import { P, PBold, PSmall, PSmallBold } from "@fm-frontend/uikit";
import React, { lazy } from "react";
import styled, { css } from "styled-components";
import { BrokerViewType } from "types";
import { BulletTypeLiquidationIcon, BulletTypeLowIcon, BulletTypeTooLowIcon } from "components/icons";
import { InfoBox } from "components/InfoBox";
import { HelperContainer } from "components/Helper/HelperContainer";
import { LinkFAQRiskManagement } from "components/Links";

const CounterpartyFormulaOne = lazy(async () => ({
    default: (await import("resources/cp_formula_1_light.svg")).ReactComponent,
}));
const CounterpartyFormulaTwo = lazy(async () => ({
    default: (await import("resources/cp_formula_2_light.svg")).ReactComponent,
}));
const CounterpartyFormulaOneDark = lazy(async () => ({
    default: (await import("resources/cp_formula_1_dark.svg")).ReactComponent,
}));
const CounterpartyFormulaTwoDark = lazy(async () => ({
    default: (await import("resources/cp_formula_2_dark.svg")).ReactComponent,
}));
const SubaccountFormulaOneDark = lazy(async () => ({
    default: (await import("resources/sa_formula_1_dark.svg")).ReactComponent,
}));
const SubaccountFormulaTwoDark = lazy(async () => ({
    default: (await import("resources/sa_formula_2_dark.svg")).ReactComponent,
}));

const FormTitleType = styled.span<{ $type: string }>`
    ${(p) => {
        if (p.$type === "counterparties") {
            return css`
                color: ${p.theme.colors.brand100};
            `;
        }
        if (p.$type === "subaccounts") {
            return css`
                color: #7463e0;
            `;
        }
    }}
`;

const BulletLabel = styled(PSmallBold)`
    display: flex;
    align-items: baseline;
    gap: 4px;
`;

export const getLimitLabel = (primeBrokerViewType: BrokerViewType) => {
    if (primeBrokerViewType === "subaccounts") {
        return "SA gross limit";
    }

    return "CP gross limit";
};

export const getLimitLabelHelper = (primeBrokerViewType: BrokerViewType) => {
    if (primeBrokerViewType === "subaccounts") {
        return (
            <HelperContainer>
                <PSmallBold>Sub-account gross limit</PSmallBold>
                <SubaccountFormulaOneDark />
                <PSmall>
                    The <span>exposure</span> is calculated by summing the absolute values of each open position using
                    the formula:
                </PSmall>
                <SubaccountFormulaTwoDark />
                <PSmall>Thus, we recommend setting the limit so that the gross free is always at least 20%.</PSmall>
                <PSmallBold>
                    <LinkFAQRiskManagement />
                </PSmallBold>
            </HelperContainer>
        );
    }

    return (
        <HelperContainer>
            <PSmallBold>Counterparty gross limit</PSmallBold>
            <CounterpartyFormulaOneDark />
            <PSmall>
                The <span>exposure</span> is calculated by summing the absolute values of each open position using the
                formula:
            </PSmall>
            <CounterpartyFormulaTwoDark />
            <PSmall>Thus, we recommend setting the limit so that the gross free is always at least 20%.</PSmall>
            <PSmall>The system will apply the lowest limit between you and the CP.</PSmall>
            <PSmallBold>
                <LinkFAQRiskManagement />
            </PSmallBold>
        </HelperContainer>
    );
};

export const getMarginLabelHelper = (primeBrokerViewType: BrokerViewType) => {
    if (primeBrokerViewType === "subaccounts") {
        return (
            <HelperContainer>
                <PSmallBold>Margin requirement</PSmallBold>
                <PSmall>
                    This is the ability to restrict trading if the Sub-account <span>Equity</span> exceeds the preset
                    criteria, specified in <span>% of the SA gross limit:</span>
                </PSmall>
                <BulletLabel>
                    <BulletTypeLowIcon />
                    Initial margin
                </BulletLabel>
                <PSmall>
                    The minimum amount of collateral required for trading. Settlement transactions that result in Equity
                    falling below the initial margin will be rejected.
                </PSmall>
                <BulletLabel>
                    <BulletTypeTooLowIcon />
                    Restricted trading
                </BulletLabel>
                <PSmall>
                    Takers will not be able to execute more trades, unless the Equity increases above this threshold.
                </PSmall>
                <BulletLabel>
                    <BulletTypeLiquidationIcon bgColor="#243034" />
                    Maintenance
                </BulletLabel>
                <PSmall>
                    The minimum required Equity level. Makers and Masters have the authority to liquidate open
                    positions.
                </PSmall>
                <PSmallBold>
                    <LinkFAQRiskManagement />
                </PSmallBold>
            </HelperContainer>
        );
    }

    return (
        <HelperContainer>
            <PSmallBold>Margin requirement</PSmallBold>
            <PSmall>
                This is the ability to restrict trading if the Taker’s <span>Equity</span> exceeds the preset criteria,
                specified in <span>% of the CP gross limit:</span>
            </PSmall>
            <BulletLabel>
                <BulletTypeLowIcon />
                Initial margin
            </BulletLabel>
            <PSmall>
                The minimum amount of collateral required for trading. Settlement transactions that result in Equity
                falling below the initial margin will be rejected.
            </PSmall>
            <BulletLabel>
                <BulletTypeTooLowIcon />
                Restricted trading
            </BulletLabel>
            <PSmall>
                Takers will not be able to execute more trades, unless the Equity increases above this threshold.
            </PSmall>
            <BulletLabel>
                <BulletTypeLiquidationIcon bgColor="#243034" />
                Maintenance
            </BulletLabel>
            <PSmall>
                The minimum required Equity level. Makers and Masters have the authority to liquidate open positions.
            </PSmall>
            <PSmallBold>
                <LinkFAQRiskManagement />
            </PSmallBold>
        </HelperContainer>
    );
};

export const getFormTitle = (primeBrokerViewType: BrokerViewType) => {
    if (primeBrokerViewType === "counterparties") {
        return (
            <>
                Add risk profile for <FormTitleType $type="counterparties">Counterparty</FormTitleType>
            </>
        );
    }

    if (primeBrokerViewType === "subaccounts") {
        return (
            <>
                Add risk profile for <FormTitleType $type="subaccounts">Sub-account</FormTitleType>
            </>
        );
    }

    return "Add risk profile";
};

export const getFormInfobox = (primeBrokerViewType: BrokerViewType) => {
    if (primeBrokerViewType === "subaccounts") {
        return (
            <InfoBox
                title={
                    <P>
                        How to set <span>SA gross limit</span>
                    </P>
                }
            >
                <CounterpartyFormulaOne />
                <P>
                    The <span>exposure</span> is calculated by summing the absolute values of each open position using
                    the formula:
                </P>
                <CounterpartyFormulaTwo />
                <P>We recommend setting the limit to ensure that your SA gross free is always at least 20%.</P>
                <P>The system will apply the lowest limit between you and the SA.</P>
                <PBold>
                    <LinkFAQRiskManagement />
                </PBold>
            </InfoBox>
        );
    }

    return (
        <InfoBox
            title={
                <P>
                    How to set <span>CP gross limit</span>
                </P>
            }
        >
            <CounterpartyFormulaOne />
            <P>
                The <span>exposure</span> is calculated by summing the absolute values of each open position using the
                formula:
            </P>
            <CounterpartyFormulaTwo />
            <P>We recommend setting the limit to ensure that your CP gross free is always at least 20%.</P>
            <P>The system will apply the lowest limit between you and the CP.</P>
            <PBold>
                <LinkFAQRiskManagement />
            </PBold>
        </InfoBox>
    );
};

export const getInputLabel = (primeBrokerViewType: BrokerViewType) => {
    if (primeBrokerViewType === "counterparties") {
        return "Counterparty";
    }

    if (primeBrokerViewType === "subaccounts") {
        return "Sub-account";
    }

    return "Counterparty";
};

export const getInputPlaceholder = (primeBrokerViewType: BrokerViewType) => {
    if (primeBrokerViewType === "counterparties") {
        return "Enter counterparty Client ID";
    }

    if (primeBrokerViewType === "subaccounts") {
        return "Enter sub-account Client ID";
    }

    return "Enter counterparty Client ID";
};

export const getSubmitButtonText = (
    primeBrokerViewType: BrokerViewType,
    counterpartyId: number,
    isSubaccount: boolean,
) => {
    if (primeBrokerViewType === "counterparties" && isSubaccount) {
        return `Continue with Sub-account (${counterpartyId})`;
    }

    return "Continue";
};
