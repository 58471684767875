import { Tabs } from "feature/settlements/const";
import { NotificationMessageType } from "services/notificationsService";

// there should be uses ROUTES, but due to module issue, there are string literals
export const NOTIFICATION_DETAILS_URL_TEMPLATES: Record<NotificationMessageType, string> = {
    [NotificationMessageType.INITIAL_MARGIN]: "/riskmanagement",
    [NotificationMessageType.RESTRICTED_TRADING]: "/riskmanagement",
    [NotificationMessageType.LIQUIDATION]: "/riskmanagement",
    [NotificationMessageType.LIMIT_SET]: "/riskmanagement",
    [NotificationMessageType.MUTUAL_LIMIT_SET]: "/riskmanagement",
    [NotificationMessageType.SUBACCOUNT_LIMIT_SET]: "/riskmanagement",

    [NotificationMessageType.INCOMING_SETTLEMENT]: `/transactions?cp={cpId}&asset={currencyName}&tab=${Tabs.ALL}`,
    [NotificationMessageType.COMMITTED_TRANSACTION]: `/transactions?cp={cpId}&asset={currencyName}&tab=${Tabs.ALL}`,

    [NotificationMessageType.DAILY_REPORT_SENT]: "",
    [NotificationMessageType.MONTHLY_REPORT_SENT]: "",

    [NotificationMessageType.ADDRESSES_NEED_CONFIRMATION]: `/addresses/shared/crypto`,
    [NotificationMessageType.ADDRESSES_CONFIRMED]: `/addresses/your/crypto`
};

export const NOTIFICATION_LINK_TEXTS: Record<NotificationMessageType, string> = {
    [NotificationMessageType.INITIAL_MARGIN]: "See positions",
    [NotificationMessageType.RESTRICTED_TRADING]: "See positions",
    [NotificationMessageType.LIQUIDATION]: "See positions",
    [NotificationMessageType.LIMIT_SET]: "See limits",
    [NotificationMessageType.MUTUAL_LIMIT_SET]: "See limits",
    [NotificationMessageType.SUBACCOUNT_LIMIT_SET]: "See limits",

    [NotificationMessageType.INCOMING_SETTLEMENT]: "Check transaction",
    [NotificationMessageType.COMMITTED_TRANSACTION]: "Check positions",

    [NotificationMessageType.DAILY_REPORT_SENT]: "",
    [NotificationMessageType.MONTHLY_REPORT_SENT]: "",

    [NotificationMessageType.ADDRESSES_NEED_CONFIRMATION]: "Confirm addresses",
    [NotificationMessageType.ADDRESSES_CONFIRMED]: "See addresses"
};

const setFilters = (template: string, params: Record<string, any>) => {
    return template.replace(/{(\w+)}/g, (match, key) => {
        return params.hasOwnProperty(key) ? params[key] : "";
    });
}

export const getNotificationDetailsUrl = (messageType: NotificationMessageType, metadata: Record<string, any>) => {
    const detailsPageUrlTemplate = NOTIFICATION_DETAILS_URL_TEMPLATES[messageType] ?? "";

    return setFilters(detailsPageUrlTemplate, metadata);
}

export const getNotificationLinkText = (messageType: NotificationMessageType) => {
    return NOTIFICATION_LINK_TEXTS[messageType];
}
