import { PrimaryButton } from "@fm-frontend/uikit";
import { FormHeader, FormInputError, FormLabel } from "feature/form/style";
import { useSelector } from "hooks";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { completeNewPassword } from "store/auth/actions";
import { Form } from "style";
import { FormRow } from "../style";

export type CompleteNewPasswordInputs = {
    newPassword: string;
    newPasswordRepeat: string;
};

export const CompleteNewPassword: FC = () => {
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isSubmitting },
    } = useForm<CompleteNewPasswordInputs>({
        mode: "onChange",
    });

    const authError = useSelector((state) => state.authentication.error);

    const handleCompleteNewPassword = handleSubmit(async (newPasswordInputs) => {
        // @ts-ignore
        await dispatch(completeNewPassword(newPasswordInputs));
    });

    return (
        <Form onSubmit={handleCompleteNewPassword}>
            <FormHeader>Reset your password</FormHeader>
            <FormRow>
                <FormLabel>New password</FormLabel>
                <input type="password" placeholder="New password" {...register("newPassword")} />
                {errors.newPassword && (
                    <FormInputError>{errors.newPassword.message}</FormInputError>
                )}
            </FormRow>
            <FormRow>
                <FormLabel>Confirm new password</FormLabel>
                <input
                    type="password"
                    placeholder="Confirm new password"
                    {...register("newPasswordRepeat", {
                        validate: (value) =>
                            value === watch("newPassword") || "The passwords do not match",
                    })}
                />
                {errors.newPasswordRepeat && (
                    <FormInputError>{errors.newPasswordRepeat.message}</FormInputError>
                )}
            </FormRow>
            <FormRow>
                <FormInputError isCentered>{authError?.message}</FormInputError>
                <PrimaryButton fullWidth size="large" type="submit" loading={isSubmitting}>
                    Reset Password
                </PrimaryButton>
            </FormRow>
        </Form>
    );
};
