import { PSmall } from "@fm-frontend/uikit";
import styled from "styled-components";

export const Container = styled.div<{ isRead?: boolean }>`
    display: flex;
    padding: 14px 0px;
    color: ${(p) => p.theme.colors.ui100};
`;

export const StatusWrapper = styled.div`
    flex: 0 0 16px;
    margin-right: 12px;
    margin-top: 3px;
`;

export const Status = styled.div`
    min-width: 16px;
    height: 16px;
    border-radius: 8px;
    background: ${(p) => p.theme.colors.brand100};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        & > svg {
            opacity: 1 !important;
        }
    }
`;

export const ContentWrapper = styled.div`
    cursor: pointer;
`;

export const Timestamp = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
    white-space: nowrap;
`;
