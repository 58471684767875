import { PrimaryButton } from "@fm-frontend/uikit";
import { createNotification } from "feature/app";
import { FormHeader, FormInputError, FormLabel } from "feature/form/style";
import { useRef, useState, VFC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { confirmResetPassword } from "services/authService";
import { Form } from "style";
import { validators } from "utils/validators";
import { FormRow } from "../style";
import { Inputs } from "./ConfirmCode.types";

export const ConfirmCode: VFC<{ email: string }> = ({ email }) => {
    const [globalError, setGlobalError] = useState<string | null>(null);
    const password = useRef({});
    const history = useHistory();
    const dispath = useDispatch();
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors, isSubmitting },
    } = useForm<Inputs>({
        mode: "onChange",
    });
    password.current = watch("newPassword");

    const onSubmit: SubmitHandler<Inputs> = async (submitInputs) => {
        try {
            await confirmResetPassword({
                confirmationCode: submitInputs.code,
                newPassword: submitInputs.newPassword,
                username: email,
            });

            setGlobalError(null);
            history.push("/login");
            dispath(
                createNotification({
                    type: "success",
                    content: "Password has been changed",
                }),
            );
        } catch (error: unknown) {
            const message = error instanceof Error ? error.message : "Unknown error";
            setGlobalError(String(message));
        }
    };

    const handleChangePassword = () => {
        if (watch("newPasswordRepeat").length > 0) {
            setValue("newPasswordRepeat", watch("newPasswordRepeat"), { shouldValidate: true });
        }
    };

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <FormHeader>Reset your password</FormHeader>
            <FormRow>
                <FormLabel>Code from email</FormLabel>
                <input
                    placeholder="000000"
                    autoComplete="off"
                    {...register("code", { ...validators.code })}
                />
                {errors.code && <FormInputError>{errors.code.message}</FormInputError>}
            </FormRow>
            <FormRow>
                <FormLabel>New password</FormLabel>
                <input
                    type="password"
                    placeholder="New password"
                    {...register("newPassword", {
                        ...validators.password,
                        onChange: handleChangePassword,
                    })}
                />
                {errors.newPassword && (
                    <FormInputError>{errors.newPassword.message}</FormInputError>
                )}
            </FormRow>
            <FormRow>
                <FormLabel>Confirm new password</FormLabel>
                <input
                    type="password"
                    placeholder="Confirm new password"
                    {...register("newPasswordRepeat", {
                        validate: (value) =>
                            value === watch("newPassword") || "The passwords do not match",
                    })}
                />
                {errors.newPasswordRepeat && (
                    <FormInputError>{errors.newPasswordRepeat.message}</FormInputError>
                )}
            </FormRow>
            <FormRow>
                <FormInputError isCentered>{globalError}</FormInputError>
                <PrimaryButton
                    loading={isSubmitting}
                    size="large"
                    fullWidth
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                >
                    Reset Password
                </PrimaryButton>
            </FormRow>
        </Form>
    );
};
