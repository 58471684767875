import { getAssetSignificantDigits } from "@fm-frontend/utils";
import { LimitInfo } from "feature/assetsControl/types";
import { useInstruments } from "hooks";
import { useCounterparties } from "hooks/useCounterparties";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useOvernightFees } from "hooks/useOvernightFees";
import { useShortSalesBan } from "hooks/useShortSalesBan";
import { useMemo } from "react";
import { usePositionsSnapshot, useUsdPrices } from "store/hooks";
import { useCurrencies } from "store/useCurrencies";
import { getAbsoluteValue } from "utils/format";
import { useLimitsMap, usePositionsMap } from "../hooks";
import { AssetRow, CpRow } from "./types";

export const isAssetRow = (value: any): value is AssetRow => {
    if (!value) {
        return false;
    }

    return "counterparties" in value;
};

export const useTableData = () => {
    const { groupedOvernightFees } = useOvernightFees();
    const { shortSalesBans, isLoading: isShortSalesBansLoading } = useShortSalesBan();
    const currencies = useCurrencies();
    const { getCpType } = useCpInfoHelpers();
    const { counterparties, cpIds, isLoading: isCounterpartiesLoading } = useCounterparties();
    const { data: positions, isLoading: isPositionsLoading } = usePositionsSnapshot();

    const { positionsMap, isLoading: isPositionsMapLoading } = usePositionsMap();
    const { limitsMap, isLoading: isLimitsMapLoading } = useLimitsMap();
    const { priceObj, isLoading: isUsdPriceLoading } = useUsdPrices();
    const instrumentsInfo = useInstruments();

    const assetsControlData: AssetRow[] = useMemo(() => {
        const getLimitsWithCpByAsset = (cpId: number, asset: string) => {
            const assetLimit = limitsMap[cpId]?.[asset];

            if (assetLimit === undefined || isUsdPriceLoading) {
                return {
                    cpLimit: undefined,
                    userLimit: undefined,
                };
            }

            const assetPosition = positionsMap[cpId]?.[asset] ?? 0n;
            const getLimitInfo = ({
                limitCurrency,
                grossLimit,
            }: {
                limitCurrency: string;
                grossLimit: number | bigint;
            }) => {
                const normalizedLimitAsset = limitCurrency.toUpperCase();
                const totalLimit = BigInt(grossLimit);
                const usdPrice = priceObj[normalizedLimitAsset] ?? 0n;
                const totalLimitUsd = (BigInt(totalLimit) * usdPrice) / BigInt(1e8);
                const positionUsd =
                    (BigInt(getAbsoluteValue(assetPosition)) * (priceObj[asset] ?? 0n)) /
                    BigInt(1e8);
                const freeLimitUsd = totalLimitUsd - positionUsd;
                const freeLimit = (freeLimitUsd * BigInt(1e8)) / usdPrice;
                const freeLimitPercent =
                    totalLimit > 0n ? Number((freeLimit * 100n) / totalLimit) : 0;
                const significantDigits = getAssetSignificantDigits(
                    instrumentsInfo.currencies[normalizedLimitAsset].balanceStep,
                );

                return {
                    totalLimit,
                    limitAsset: limitCurrency,
                    freeLimit,
                    freeLimitPercent,
                    freeLimitUsd,
                    significantDigits,
                };
            };

            let userLimit: LimitInfo | undefined = undefined;
            if (assetLimit.limitCurrency !== null && assetLimit.grossLimit !== null) {
                userLimit = getLimitInfo({
                    limitCurrency: assetLimit.limitCurrency,
                    grossLimit: assetLimit.grossLimit,
                });
            }

            let cpLimit: LimitInfo | undefined = undefined;
            if (assetLimit.cpLimitCurrency !== null && assetLimit.cpGrossLimit !== null) {
                cpLimit = getLimitInfo({
                    limitCurrency: assetLimit.cpLimitCurrency,
                    grossLimit: assetLimit.cpGrossLimit,
                });
            }

            return {
                userLimit,
                cpLimit,
            };
        };

        return currencies.reduce((acc, { value: currency }) => {
            const assetsControl = acc.find((control) => control.currency === currency);

            if (!assetsControl) {
                const assetControlGroup: AssetRow = {
                    key: currency,
                    currency: currency,
                    counterparties: [],
                    userLimit: "mixed",
                    cpLimit: "mixed",
                    negativeRate: "mixed",
                    positiveRate: "mixed",
                    isUserSetShortSalesBan: "mixed",
                    isCpSetShortSalesBan: "mixed",
                };
                assetControlGroup.counterparties = cpIds
                    .filter((cpId) => getCpType(cpId) !== undefined)
                    .map((cpId) => {
                        const currencyShortSalesBans = shortSalesBans[cpId];

                        const { negativeRate, positiveRate } =
                            groupedOvernightFees[cpId]?.find(
                                (overnight) => overnight.currency === currency,
                            ) ?? {};

                        const shortSalesInfo = currencyShortSalesBans?.find(
                            ({ asset }) => asset === currency,
                        );
                        const { userLimit, cpLimit } = getLimitsWithCpByAsset(cpId, currency);
                        return {
                            id: cpId,
                            name: counterparties[cpId],
                            cpType: getCpType(cpId),
                            negativeRate,
                            positiveRate,
                            currency: currency,
                            isUserSetShortSalesBan: shortSalesInfo?.isUserSetBan ?? false,
                            isCpSetShortSalesBan: shortSalesInfo?.isCpSetBan ?? false,
                            userLimit,
                            cpLimit,
                        } as CpRow;
                    })
                    .sort((left, right) => left.name.localeCompare(right.name));
                // calculate common user's limit
                if (
                    assetControlGroup.counterparties.every(
                        ({ userLimit }) => userLimit === undefined,
                    )
                ) {
                    assetControlGroup.userLimit = undefined;
                } else if (
                    assetControlGroup.counterparties.every(
                        ({ userLimit }) =>
                            assetControlGroup.counterparties[0].userLimit?.totalLimit ===
                                userLimit?.totalLimit &&
                            assetControlGroup.counterparties[0].userLimit?.limitAsset ===
                                userLimit?.limitAsset,
                    )
                ) {
                    assetControlGroup.userLimit = {
                        limitAsset: assetControlGroup.counterparties[0].userLimit!.limitAsset,
                        totalLimit: assetControlGroup.counterparties[0].userLimit!.totalLimit,
                        significantDigits:
                            assetControlGroup.counterparties[0].userLimit!.significantDigits,
                    };
                }
                if (
                    assetControlGroup.counterparties.every(({ cpLimit }) => cpLimit === undefined)
                ) {
                    assetControlGroup.cpLimit = undefined;
                } else if (
                    assetControlGroup.counterparties.every(
                        ({ cpLimit }) =>
                            assetControlGroup.counterparties[0].cpLimit?.totalLimit ===
                                cpLimit?.totalLimit &&
                            assetControlGroup.counterparties[0].cpLimit?.limitAsset ===
                                cpLimit?.limitAsset,
                    )
                ) {
                    assetControlGroup.cpLimit = {
                        limitAsset: assetControlGroup.counterparties[0].cpLimit!.limitAsset,
                        totalLimit: assetControlGroup.counterparties[0].cpLimit!.totalLimit,
                        significantDigits:
                            assetControlGroup.counterparties[0].cpLimit!.significantDigits,
                    };
                }
                // calculate common overnights
                if (
                    assetControlGroup.counterparties.every(
                        ({ negativeRate }) => negativeRate === undefined,
                    )
                ) {
                    assetControlGroup.negativeRate = undefined;
                } else if (
                    assetControlGroup.counterparties.every(
                        ({ negativeRate }) =>
                            assetControlGroup.counterparties[0].negativeRate === negativeRate,
                    )
                ) {
                    assetControlGroup.negativeRate =
                        assetControlGroup.counterparties[0]?.negativeRate;
                }
                if (
                    assetControlGroup.counterparties.every(
                        ({ positiveRate }) => positiveRate === undefined,
                    )
                ) {
                    assetControlGroup.positiveRate = undefined;
                } else if (
                    assetControlGroup.counterparties.every(
                        ({ positiveRate }) =>
                            assetControlGroup.counterparties[0]?.positiveRate === positiveRate,
                    )
                ) {
                    assetControlGroup.positiveRate =
                        assetControlGroup.counterparties[0]?.positiveRate;
                }
                // calculate common short sales ban
                if (
                    assetControlGroup.counterparties.every(
                        ({ isUserSetShortSalesBan }) =>
                            assetControlGroup.counterparties[0].isUserSetShortSalesBan ===
                            isUserSetShortSalesBan,
                    )
                ) {
                    assetControlGroup.isUserSetShortSalesBan = assetControlGroup.counterparties[0]
                        ?.isUserSetShortSalesBan
                        ? "ban"
                        : "on";
                }
                if (
                    assetControlGroup.counterparties.every(
                        ({ isCpSetShortSalesBan }) =>
                            assetControlGroup.counterparties[0].isCpSetShortSalesBan ===
                            isCpSetShortSalesBan,
                    )
                ) {
                    assetControlGroup.isCpSetShortSalesBan = assetControlGroup.counterparties[0]
                        ?.isCpSetShortSalesBan
                        ? "ban"
                        : "on";
                }

                acc.push(assetControlGroup);
            }

            return acc;
        }, [] as AssetRow[]);
    }, [
        currencies,
        shortSalesBans,
        groupedOvernightFees,
        cpIds,
        counterparties,
        getCpType,
        positions,
        positionsMap,
        limitsMap,
        positionsMap,
        priceObj,
    ]);

    return {
        assetsControlData,
        isLoading:
            !currencies.length ||
            isUsdPriceLoading ||
            isPositionsMapLoading ||
            isLimitsMapLoading ||
            isCounterpartiesLoading ||
            isPositionsLoading ||
            instrumentsInfo.isLoading ||
            isShortSalesBansLoading,
    };
};
