import { LightBox } from "./LightBox";
import { LightboxBody } from "./LightboxBody";
import { LightboxFooter } from "./LightboxFooter";
import { LightboxHeader } from "./LightboxHeader";

export const DiffLightbox = Object.assign(LightBox, {
    Header: LightboxHeader,
    Body: LightboxBody,
    Footer: LightboxFooter,
});
