import { TextSmall } from "@fm-frontend/uikit";
import { TickIcon } from "components/icons";
import { ClientType, CounterpartyLimit } from "types";
import { getFmtPrecisionConfig } from "utils";
import { fmt, fmtDeltaratePercent, FormatConfig } from "utils/format";
import { IndicatorData, LimitType } from "../types";
import {
    convertMutualLimitToGrossLimit,
    getFreeLimit,
    getMarginLimitValue,
    ONE_HUNDRED_PERCENT,
    parseLimit,
    PERCENT_SHIFT,
} from "../utils";

const APPLIED_BEIGE = (
    <>
        <TickIcon />
        <TextSmall>applied</TextSmall>
    </>
);

export const FMT_LIMIT_CONFIG: FormatConfig = {
    significantDigits: 8,
    type: "limit",
    showZero: true,
    unitPosition: "pre",
    minSignificantDigits: 2,
};

export const getAppliedLimit = (limit: CounterpartyLimit, priceObj: Record<string, bigint>) => {
    const { currency, grossLimit, mutualLimitCurrencyName, mutualGrossLimit } = parseLimit(limit);
    const convertedMutualLimit = convertMutualLimitToGrossLimit(
        priceObj,
        mutualGrossLimit,
        mutualLimitCurrencyName,
        currency,
    );

    if (!grossLimit || !convertedMutualLimit) {
        return {
            appliedLimit: 0n,
            appliedLimitType: LimitType.Empty,
        };
    }
    if (grossLimit <= convertedMutualLimit) {
        return {
            appliedLimit: grossLimit,
            appliedLimitType: LimitType.UserLimit,
        };
    }

    return {
        appliedLimit: convertedMutualLimit,
        appliedLimitType: LimitType.MutualLimit,
    };
};

export const getGrossFreeIndicatorData = (
    limit: CounterpartyLimit,
    appliedLimit: bigint,
    isSubaccountUser: boolean,
) => {
    const { currency, grossLimitUtilization } = parseLimit(limit);
    const { freeLimit, freeLimitPercent } = getFreeLimit(appliedLimit, grossLimitUtilization);

    const fmtResult = fmt(freeLimit, {
        ...FMT_LIMIT_CONFIG,
        unit: ` ${currency}`,
        unitPosition: "post",
        ...getFmtPrecisionConfig(currency),
    });
    const fmtPercentResult = fmtDeltaratePercent(freeLimitPercent);

    return {
        title: isSubaccountUser ? "Gross free" : "Applied gross free",
        value: fmtResult.formattedValue,
        copyableValue: fmtResult.copyableValue,
        badge: {
            content: fmtPercentResult.formattedValue,
        },
    };
};

export const getGrossLimitIndicatorData = (limit: CounterpartyLimit, appliedLimitType: LimitType) => {
    const { currency, grossLimit } = parseLimit(limit);
    const fmtResult = fmt(grossLimit, {
        ...FMT_LIMIT_CONFIG,
        unit: ` ${currency}`,
        unitPosition: "post",
        ...getFmtPrecisionConfig(currency),
    });

    const indicator: IndicatorData = {
        title: "Limit by You",
        value: fmtResult.formattedValue,
        copyableValue: fmtResult.copyableValue,
    };

    if (appliedLimitType === LimitType.UserLimit) {
        indicator.badge = {
            content: APPLIED_BEIGE,
        };
    }

    return indicator;
};

export const getCpLimitIndicatorData = (
    limit: CounterpartyLimit,
    appliedLimitType: LimitType,
    isSubaccountUser: boolean,
    cpName: string,
) => {
    const indicator: IndicatorData = {
        title: isSubaccountUser ? "Limit by Master" : `Limit by ${cpName}`,
        value: "Not set",
    };

    const { mutualGrossLimit, mutualLimitCurrencyName } = parseLimit(limit);

    if (mutualGrossLimit === null) {
        return indicator;
    }

    const fmtResult = fmt(mutualGrossLimit, {
        ...FMT_LIMIT_CONFIG,
        unit: ` ${mutualLimitCurrencyName}`,
        unitPosition: "post",
        ...getFmtPrecisionConfig(mutualLimitCurrencyName),
    });

    indicator.value = fmtResult.formattedValue;
    indicator.copyableValue = fmtResult.copyableValue;

    if (appliedLimitType === LimitType.MutualLimit) {
        indicator.badge = {
            content: APPLIED_BEIGE,
        };
    }

    return indicator;
};

const EQUITY_INDICATOR_TITLES: { [Key in ClientType]: string } = {
    subaccountTaker: "{ClientName}'s Equity",
    subaccountMaker: "{ClientName}'s Equity",
    primeBroker: "Your Equity",
    maker: "Your Equity",
    taker: "Your Equity",
};
export const getEquityIndicatorData = (
    limit: CounterpartyLimit,
    shouldTakeUserGrossLimit: boolean,
    cpType: ClientType,
    cpName: string,
) => {
    const { currency, grossLimit, equity, mutualGrossLimit, mutualLimitCurrencyName } = parseLimit(limit);
    const makerLimitCurrency = shouldTakeUserGrossLimit ? currency : mutualLimitCurrencyName ?? "USD";

    const makerLimit = shouldTakeUserGrossLimit ? grossLimit : mutualGrossLimit ?? 0n;

    const takerEquity = shouldTakeUserGrossLimit ? equity * -1n : equity;
    const freeLimitPercent =
        makerLimit && takerEquity >= 0
            ? Number((BigInt(ONE_HUNDRED_PERCENT * PERCENT_SHIFT) * takerEquity) / makerLimit)
            : 0;
    const fmtPercentResult = fmtDeltaratePercent(freeLimitPercent);
    const fmtResult = fmt(takerEquity, {
        ...FMT_LIMIT_CONFIG,
        unit: ` ${makerLimitCurrency}`,
        unitPosition: "post",
        ...getFmtPrecisionConfig(makerLimitCurrency),
    });

    return {
        title: EQUITY_INDICATOR_TITLES[cpType].replace("{ClientName}", cpName),
        value: fmtResult.formattedValue,
        copyableValue: fmtResult.copyableValue,
        badge: {
            content: fmtPercentResult.formattedValue,
        },
    };
};

export const getMaintenanceMarginLimitIndicatorData = (limit: CounterpartyLimit, shouldTakeUserGrossLimit: boolean) => {
    const { currency, grossLimit, mutualGrossLimit, mutualLimitCurrencyName, maintenanceMargin } = parseLimit(limit);

    const makerGrossLimit = shouldTakeUserGrossLimit ? grossLimit : mutualGrossLimit ?? 0n;
    const makerCurrency = shouldTakeUserGrossLimit ? currency : mutualLimitCurrencyName ?? "";

    const marginLimitValue = getMarginLimitValue(makerGrossLimit, maintenanceMargin);
    const fmtResult = fmt(marginLimitValue, {
        ...FMT_LIMIT_CONFIG,
        showZero: false,
        unitPosition: "post",
        unit: ` ${makerCurrency}`,
        ...getFmtPrecisionConfig(makerCurrency),
    });
    const fmtPercentResult = fmtDeltaratePercent(maintenanceMargin, { showZero: false });

    return {
        title: "Maintenance Margin",
        value: fmtResult.formattedValue,
        subValue: fmtPercentResult.formattedValue,
        copyableValue: fmtResult.copyableValue,
    };
};

export const getInitialMarginLimitIndicatorData = (limit: CounterpartyLimit, shouldTakeUserGrossLimit: boolean) => {
    const { currency, grossLimit, mutualLimitCurrencyName, mutualGrossLimit, initialMargin } = parseLimit(limit);

    const makerGrossLimit = shouldTakeUserGrossLimit ? grossLimit : mutualGrossLimit ?? 0n;
    const makerCurrency = shouldTakeUserGrossLimit ? currency : mutualLimitCurrencyName ?? "";

    const marginLimitValue = getMarginLimitValue(makerGrossLimit, initialMargin);
    const fmtResult = fmt(marginLimitValue, {
        ...FMT_LIMIT_CONFIG,
        showZero: false,
        unitPosition: "post",
        unit: ` ${makerCurrency}`,
        ...getFmtPrecisionConfig(makerCurrency),
    });
    const fmtPercentResult = fmtDeltaratePercent(initialMargin, { showZero: false });

    return {
        title: "Initial Margin",
        value: fmtResult.formattedValue,
        subValue: fmtPercentResult.formattedValue,
        copyableValue: fmtResult.copyableValue,
    };
};

export const getRestrictedTradingLimitIndicatorData = (limit: CounterpartyLimit, shouldTakeUserGrossLimit: boolean) => {
    const { currency, grossLimit, mutualLimitCurrencyName, mutualGrossLimit, restrictedTrading } = parseLimit(limit);

    const makerGrossLimit = shouldTakeUserGrossLimit ? grossLimit : mutualGrossLimit ?? 0n;
    const makerCurrency = shouldTakeUserGrossLimit ? currency : mutualLimitCurrencyName ?? "";

    const marginLimitValue = getMarginLimitValue(makerGrossLimit, restrictedTrading);
    const fmtResult = fmt(marginLimitValue, {
        ...FMT_LIMIT_CONFIG,
        showZero: false,
        unitPosition: "post",
        unit: ` ${makerCurrency}`,
        ...getFmtPrecisionConfig(makerCurrency),
    });
    const fmtPercentResult = fmtDeltaratePercent(restrictedTrading, { showZero: false });

    return {
        title: "Restricted Trading",
        value: fmtResult.formattedValue,
        subValue: fmtPercentResult.formattedValue,
        copyableValue: fmtResult.copyableValue,
    };
};

export const getTradingMarkupIndicatorData = (limit: CounterpartyLimit) => {
    const { markupValue } = parseLimit(limit);
    const fmtResult = fmtDeltaratePercent(markupValue, {
        significantDigits: 4,
        minSignificantDigits: 2,
        showZero: true,
    });

    return {
        value: fmtResult.formattedValue,
        copyableValue: fmtResult.copyableValue,
        title: "Trading Markup",
    };
};
