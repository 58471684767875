import styled from "styled-components";

export const InfiniteTableContainer = styled.div`
    overflow: auto;
    height: 100%;

    * {
        transition: none !important;
    }
`;
