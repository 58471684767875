import { Modal, PrimaryButton } from "@fm-frontend/uikit";
import { setConfirmModalContent } from "feature/app";
import FormCheckbox from "feature/form/FormCheckbox";
import { FormHeader } from "feature/form/style";
import { FormConfig, useForm } from "feature/form/useForm";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useIsPrimeBrokerUser } from "store/hooks";
import { Form } from "style";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { Content, Email, Name, Strong, TextButton } from "../../styled";
import { Actions, Error, UserCard, UserInfo } from "./styled";
import type { FormData, UserPermissionsModalProps } from "./types";

const getPermissionsFormFields = (isPrimeBrokerUser: boolean) => {
    const baseFieldsEntries = [
        [
            "viewMode",
            {
                type: "boolean",
                title: "View-mode",
                disabled: true,
            },
        ],
        [
            "trading",
            {
                type: "boolean",
                title: "Trading",
            },
        ],
        [
            "risks",
            {
                type: "boolean",
                title: "Risk management",
            },
        ],
        [
            "settle",
            {
                type: "boolean",
                title: "Settlement operations",
            },
        ],
        [
            "api",
            {
                type: "boolean",
                title: "API keys",
            },
        ],
        [
            "users",
            {
                type: "boolean",
                title: "User management",
            },
        ],
    ];

    if (isPrimeBrokerUser) {
        return Object.fromEntries([
            ...baseFieldsEntries,
            [
                "subaccounts",
                {
                    type: "boolean",
                    title: "Sub-account management",
                },
            ],
        ]);
    }

    return Object.fromEntries(baseFieldsEntries);
};

export const UserPermissionsModal = ({
    onChange,
    onChangeUserInfoClick,
    onClose,
    userData,
    onNextStep,
    onDeleteUser,
    mode,
}: UserPermissionsModalProps) => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const [isDirty, setIsDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deletingUser, setDeletingUser] = useState(false);
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const isCreateMode = mode === "create";
    const form: FormConfig = useMemo(
        () => ({
            fields: getPermissionsFormFields(isPrimeBrokerUser),
            onChange: ({
                parsedValues: {
                    trading = false,
                    settle = false,
                    api = false,
                    users = false,
                    risks = false,
                    subaccounts = false,
                },
            }) => {
                setIsDirty(true);
                onChange({
                    permissions: {
                        trading,
                        settle,
                        api,
                        users,
                        risks,
                        subaccounts,
                    },
                } as FormData);
            },
            onSubmit: ({
                trading = false,
                settle = false,
                api = false,
                users = false,
                risks = false,
                subaccounts = false,
            }) => {
                onChange({ permissions: { trading, settle, api, users, risks, subaccounts } });
                setIsLoading(true);
                onNextStep()
                    .catch((err: unknown) => setError(String(err)))
                    .finally(() => setIsLoading(false));
            },
            getInitialValues: () => {
                const {
                    permissions: {
                        trading = false,
                        settle = false,
                        api = false,
                        users = false,
                        risks = false,
                        subaccounts = false,
                    } = {},
                } = userData;

                return { viewMode: true, trading, settle, api, users, risks, subaccounts };
            },
        }),
        [isCreateMode, isPrimeBrokerUser],
    );
    const { inputProps, onSubmit } = useForm(form);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(isDirty, onClose);

    const title = isCreateMode ? (
        <>
            Specify <Strong>user’s permissions</Strong>
        </>
    ) : (
        "Edit permissions"
    );
    const mainButtonTitle = isCreateMode ? "Create new user" : "Confirm changes";
    const handleMainButtonClick = (ev: React.MouseEvent) => {
        ev.preventDefault();
        onSubmit();
    };
    const handleChangeUserInfoClick = (ev: React.MouseEvent) => {
        ev.preventDefault();
        onChangeUserInfoClick();
    };

    const handleDeleteUserClick = () => {
        dispatch(
            setConfirmModalContent({
                title: "Delete user",
                onConfirm: () => {
                    setDeletingUser(true);
                    onDeleteUser().finally(() => setDeletingUser(false));
                },
                description: "Are you sure you want to delete the user?",
                confirmButtonTitle: "Delete",
            }),
        );
    };

    const additionalProps = isCreateMode
        ? {}
        : {
              additionalActions: {
                  onDeleteButtonClick: handleDeleteUserClick,
                  deleteButtonLoading: deletingUser,
              },
          };

    return (
        <Modal isOpen onClose={closeModalWithConfirm} {...additionalProps}>
            <Content>
                <Form>
                    <FormHeader>{title}</FormHeader>
                    <UserCard>
                        <UserInfo>
                            <Name>{userData.name}</Name>
                            <Email>{userData.email}</Email>
                        </UserInfo>
                        {isCreateMode && <TextButton onClick={handleChangeUserInfoClick}>Change</TextButton>}
                    </UserCard>
                    <FormCheckbox {...inputProps.viewMode} />
                    {inputProps.trading && <FormCheckbox {...inputProps.trading} />}
                    <FormCheckbox {...inputProps.risks} />
                    <FormCheckbox {...inputProps.settle} />
                    <FormCheckbox {...inputProps.api} />
                    <FormCheckbox {...inputProps.users} />
                    {inputProps.subaccounts && <FormCheckbox {...inputProps.subaccounts} />}
                    <Error>{error}</Error>
                    <Actions>
                        <PrimaryButton
                            size="large"
                            fullWidth
                            onClick={handleMainButtonClick}
                            disabled={deletingUser}
                            loading={isLoading}
                        >
                            {mainButtonTitle}
                        </PrimaryButton>
                    </Actions>
                </Form>
            </Content>
        </Modal>
    );
};
