import { post } from "../api";
import useSWR from "swr";
import { displayError } from "../utils";

export interface ApiUser {
    accessFlags: number;
    active: boolean;
    clientId: number;
    createdAt: number;
    email: string;
    fullname: string;
    name: string;
    owner: boolean;
}

const fetcher = (url: string) => post(url, {});

export const useUsers = () => useSWR<ApiUser[]>("getUsers", fetcher, {
    onError: displayError,
});
