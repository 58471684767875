import { TradingSide } from "feature/trade/trading/types";

export const calculateTradingSide = ({
    tradeAsset,
    baseAsset,
}: {
    tradeAsset: string;
    baseAsset: string;
}): TradingSide => {
    return tradeAsset === baseAsset ? "size" : "volume";
};
