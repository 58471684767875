import { CopyableValue } from "feature/settlements/components/CopyableValue";

export const CopyableLabel = ({
    value,
    title,
    hideIcon,
}: {
    value: string;
    title: string;
    hideIcon?: boolean;
}) => {
    return <CopyableValue value={value} title={title} stopPropagation hideIcon={hideIcon} />;
};
