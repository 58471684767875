import React from "react";
import styled from "styled-components";
import { VStack } from "../layout";
import { Modal, ModalProps, MODAL_TOP_OFFSET } from "./Modal";

const Container = styled(VStack)`
    height: calc(100vh - ${MODAL_TOP_OFFSET}px);
    overflow: hidden;
`;

export const LightBox: React.FC<ModalProps> = ({ children, ...props }) => {
    return (
        <Modal
            {...props}
            style={{
                content: {
                    top: "unset",
                    right: "unset",
                    left: "50%",
                    bottom: "0",
                    transform: "translate(-50%, 0)",
                    borderBottomLeftRadius: "unset",
                    borderBottomRightRadius: "unset",
                },
            }}
        >
            <Container width="936px" minWidth="936px">
                {children}
            </Container>
        </Modal>
    );
};
