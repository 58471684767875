import { Table } from "@tanstack/react-table";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { InlineDropdown } from "../../dropdowns";
import { FormBackAction, FormNextAction } from "../../icons/styled";

type PaginatorProps<RowData> = {
    table: Table<RowData>;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 15px 12px 10px;
    gap: 8px;
`;

const Button = styled.button`
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: ${(p) => p.theme.colors.ui8};
    border-radius: 24px;
    padding: 4px;
`;

const PageCounter = styled.b`
    color: ${(p) => p.theme.colors.ui72};
    font-weight: 600;
    font-size: 12px;
`;

const CurrentPageNumber = styled.span`
    color: ${(p) => p.theme.colors.ui100};
`;

export const Paginator = <RowData extends object>({ table }: PaginatorProps<RowData>) => {
    const pageNumber = table.getState().pagination.pageIndex + 1;
    const pageCount = table.getPageCount();
    const hasPrevPage = table.getCanPreviousPage();
    const hasNextPage = table.getCanNextPage();
    const handlePrevClick = () => table.previousPage();
    const handleNextClick = () => table.nextPage();

    const { control, setValue, watch } = useForm();
    const options = Array.from({ length: pageCount }, (_, index) => ({
        text: `${index + 1}`,
        value: `${index + 1}`,
    }));
    const page = watch("page");

    useEffect(() => {
        setValue("page", `${pageNumber}`);
    }, [pageNumber, setValue]);

    useEffect(() => {
        if (page !== undefined) {
            table.setPageIndex(page - 1);
        }
    }, [page, table]);

    return (
        <Container>
            <Button disabled={!hasPrevPage} onClick={handlePrevClick}>
                <FormBackAction />
            </Button>
            <InlineDropdown
                options={options}
                control={control}
                size="small"
                name="page"
                label="Page"
                positions={["top"]}
                renderText={(text) => (
                    <PageCounter>
                        <CurrentPageNumber>{text}</CurrentPageNumber>/{pageCount}
                    </PageCounter>
                )}
            />
            <Button disabled={!hasNextPage} onClick={handleNextClick}>
                <FormNextAction />
            </Button>
        </Container>
    );
};
