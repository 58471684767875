import * as Yup from "yup";

export const whitelistingBulkEditFormSchema = Yup.object().shape({
    instruments: Yup.array()
        .of(Yup.string().required())
        .min(1, "At least one instrument is required"),
    cps: Yup.array()
        .of(Yup.number().required())
        .min(1, "At least one counterparty is required"),
});
