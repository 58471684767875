import { isNullOrUndefined } from "@fm-frontend/utils";

export type Space = number;

export type BoxProps = Partial<{
    asCard: boolean;
    width: string;
    height: string;
    minWidth: string;
    minHeight: string;
    maxWidth: string;
    maxHeight: string;
    overflow: "auto" | "hidden" | "scroll" | "visible" | "clip";
    backgroundColor: string;
    ellipsis?: boolean;
}>;

type SpaceUnit = Space | string;
type SpaceType =
    | SpaceUnit
    | [SpaceUnit, SpaceUnit]
    | [SpaceUnit, SpaceUnit, SpaceUnit]
    | [SpaceUnit, SpaceUnit, SpaceUnit, SpaceUnit];

export type MarginProps = Partial<{
    margin: SpaceType;
}>;

export type PaddingProps = Partial<{
    padding: SpaceType;
}>;

export const getSpaceCss = (space?: SpaceType) => {
    if (isNullOrUndefined(space)) {
        return null;
    }

    let result: string;
    const handleSpaceUnit = (unit: SpaceUnit) => (typeof unit === "string" ? unit : `${unit}px`);

    if (Array.isArray(space)) {
        result = space.map(handleSpaceUnit).join(" ");
    } else {
        result = handleSpaceUnit(space!);
    }

    return result + "!important";
};
