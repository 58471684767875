import { PBold, PSmall, VStack } from "@fm-frontend/uikit";

interface StatisticProps {
    title: string;
    value: string | React.ReactNode;
}

export const Statistic = ({ title, value }: StatisticProps) => (
    <VStack spacing={2}>
        <PSmall>{title}</PSmall>
        <PBold>{value}</PBold>
    </VStack>
);
