import { ClientId, H2, HStack, Modal, VStack } from "@fm-frontend/uikit";
import { useFormShouldCloseConfirm } from "@fm-frontend/utils";
import { post } from "api";
import { CpStatusBadge } from "components/CpStatusBadge";
import { FIXED_MODAL_STYLE } from "const/modal";
import { createNotification, setConfirmModalContent } from "feature/app";
import { useFaqLinks } from "hooks/useFaqLinks";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "store";
import { ExtendedLimit } from "store/hooks";
import { Spacer } from "style";
import styled from "styled-components";
import { CounterpartyHints } from "../counterparty/CounterpartyHints";
import { CounterpartyLimitModal } from "../counterpartyLimitModal";
import { COUNTERPARTY_LIMIT_MODAL_KEY, URL_DELETE_CLIMIT } from "../counterpartyLimitModal/utils";
import { CounterpartyStatusType } from "../utils";

const EditFormHead = styled(HStack)`
    white-space: nowrap;
    gap: 8px;
    padding: 12px;
`;

const EditFormTitle = styled(HStack)`
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    align-items: baseline;
    gap: 8px;

    ${H2} {
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

type RiskManagementModalProps = {
    isOpened: boolean;
    onClose: () => void;
    extendedLimit: ExtendedLimit | undefined;
    cpTitle: string;
    counterpartyId: number;
    status: CounterpartyStatusType;
    defaultTab: string;
    type: string;
    noCpHint?: boolean;
};
export const RiskManagementModal: FC<RiskManagementModalProps> = ({
    isOpened,
    onClose,
    extendedLimit,
    cpTitle,
    counterpartyId,
    status,
    defaultTab,
    type,
    noCpHint,
}) => {
    const dispatch = useDispatch();
    const faqLinks = useFaqLinks();
    const [limitDeleting, setLimitDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState<string>();
    const allAvailableCounterpartiesInfo = useSelector(
        (state: IRootState) => state.app.allAvailableCounterpartiesInfo,
    );
    const limitCpType = allAvailableCounterpartiesInfo[counterpartyId]?.clientType;

    const { limit } = extendedLimit ?? {};

    const closeModal = () => {
        setDeleteError(undefined);
        onClose();
    };
    const shouldConfirmClose = useFormShouldCloseConfirm(COUNTERPARTY_LIMIT_MODAL_KEY);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(shouldConfirmClose, closeModal);

    const handleLimitDelete = async () => {
        dispatch(
            setConfirmModalContent({
                title: "Delete your trading activity with this client?",
                onConfirm: async () => {
                    try {
                        setLimitDeleting(true);
                        await post(URL_DELETE_CLIMIT, {
                            counterpartyId,
                        });

                        dispatch(
                            createNotification({
                                type: "success",
                                content: "Limit has been deleted",
                            }),
                        );
                        closeModal();
                    } catch (e) {
                        setDeleteError(String(e));
                    } finally {
                        setLimitDeleting(false);
                    }
                },
                description: "",
                confirmButtonTitle: "Delete",
            }),
        );
    };

    return (
        <Modal
            isOpen={isOpened}
            onClose={closeModalWithConfirm}
            additionalActions={{
                helpButtonUrlOrClickHandler: faqLinks?.faqRiskManagement,
            }}
            style={FIXED_MODAL_STYLE}
        >
            <HStack asCard>
                <VStack width="360px">
                    <EditFormHead>
                        <EditFormTitle>
                            <H2>{cpTitle}</H2>
                            <ClientId id={`${type} ${counterpartyId}`} />
                            <Spacer />
                            <CpStatusBadge cpStatus={status} />
                        </EditFormTitle>
                    </EditFormHead>
                    <VStack padding={12} paddingTop={0}>
                        <CounterpartyLimitModal
                            defaultTab={defaultTab}
                            status={status}
                            title={cpTitle}
                            limit={limit}
                            cpData={{ cpId: counterpartyId, cpType: limitCpType, cpName: cpTitle }}
                            deleting={limitDeleting}
                            error={deleteError}
                            onSubmit={closeModal}
                            onClose={closeModalWithConfirm}
                            onDelete={handleLimitDelete}
                        />
                    </VStack>
                </VStack>
                {limit !== undefined && !noCpHint && (
                    <CounterpartyHints
                        cpId={counterpartyId}
                        cpType={limitCpType}
                        cpName={cpTitle}
                        limit={limit}
                    />
                )}
            </HStack>
        </Modal>
    );
};
