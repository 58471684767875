import { LpBadge } from "components/LpBadge";
import { setIsMobileMenuOpen } from "feature/app";
import { useIsProvidersSectionAvailable } from "hooks/useIsProvidersSectionAvailable";
import { memo } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { useHasMakerRoleUser, useIsSubaccountUser } from "store/hooks";
import { Spacer } from "style";
import { MenuContent } from "style/menu";
import { when } from "utils";
import { ROUTES } from "../app/router";
import { AdditionalMobileMenuOptions } from "./AdditionalMobileMenuOptions";

export const CommonMenuContent = memo(() => {
    const dispatch = useDispatch();
    const history = useHistory();
    const hasMakerRoleUser = useHasMakerRoleUser();
    const isSubaccountUser = useIsSubaccountUser();
    const isProvidersSectionAvailable = useIsProvidersSectionAvailable();

    return (
        <>
            <MenuContent
                primeBrokerSubaccountsView={false}
                onClick={(event) => {
                    if ((event.target as any).tagName === "A") {
                        dispatch(setIsMobileMenuOpen(false));
                    }
                }}
            >
                <div>Trade</div>
                <NavLink
                    to="/trading"
                    onClick={(e) => {
                        e.preventDefault();
                        history.push(ROUTES.trading);
                    }}
                >
                    Trading
                </NavLink>
                <NavLink to={ROUTES.positions}>Positions</NavLink>
                <NavLink to="/riskmanagement">Risk management</NavLink>
                {when(hasMakerRoleUser, <NavLink to="/orders">Orders</NavLink>)}
                <NavLink to={ROUTES.assetsControl}>Assets & Instruments</NavLink>

                <div>Settlement</div>
                {when(
                    !isSubaccountUser,
                    <>
                        <NavLink to="/transactions">Transactions</NavLink>
                        <NavLink to={ROUTES.addresses}>Addresses</NavLink>
                    </>,
                )}
                {when(
                    isSubaccountUser,
                    <>
                        <NavLink to="/subaccountRequests">Requests</NavLink>
                        <NavLink to="/subaccountTransactions">Transactions</NavLink>
                        <NavLink to={ROUTES.addresses}>Addresses</NavLink>
                    </>,
                )}

                <div>History</div>
                <NavLink to={ROUTES.historyTrades}>Trading</NavLink>
                <NavLink to="/settlements">Settlements</NavLink>

                <div>API</div>
                <NavLink to="/keys">Keys</NavLink>

                <AdditionalMobileMenuOptions />
            </MenuContent>
            <Spacer />
            {isProvidersSectionAvailable && <LpBadge to="/providers" />}
        </>
    );
});

CommonMenuContent.displayName = "CommonMenuContent";
