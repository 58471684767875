import { DropdownOption, SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { CurrencyType } from "@fm-frontend/utils";
import { CurrencyDropdownSheet } from "components/CurrencySheet";
import { CurrencyTriggerEssence } from "components/CurrencyTriggerEssence";
import { useInstruments } from "hooks";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useCurrenciesPositions } from "../../../../hooks";
import { CryptoAddressFormInput } from "../../../../types";
import { hasCurrencyMemoOrTag } from "../../../../utils";
import { CryptoFormContext } from "../../CryptoFormContext";

type AssetsControlProps = {
    index: number;
    rowId: string;
    disabled: boolean;
    availableCurrencyTypes: CurrencyType[];
};
type DropdownCurrencyOption = DropdownOption<
    number,
    {
        position?: bigint;
    }
>;

export const CurrencyControl: React.FC<AssetsControlProps> = ({ index, rowId, disabled, availableCurrencyTypes }) => {
    const {
        control,
        formState: { errors, defaultValues },
        watch,
        setValue,
    } = useFormContext<CryptoAddressFormInput>();

    const currencyControlName = `addresses.${index}.currency.id` as const;
    const selectedCurrencyId = watch(currencyControlName);
    const selectedCpId = watch(`addresses.${index}.rule.cp.id`);
    const currenciesPositions = useCurrenciesPositions(selectedCpId);

    const { currencyIds } = useInstruments();

    const currencyOptions = useMemo(() => {
        return Object.values(currencyIds)
            .filter(({ currencyType }) => availableCurrencyTypes.includes(currencyType))
            .map(
                ({ name, id }) =>
                    ({
                        text: name,
                        value: id,
                        position: currenciesPositions[name]?.value,
                    } as DropdownCurrencyOption),
            )
            .sort((a, b) => a.text.localeCompare(b.text));
    }, [currencyIds, availableCurrencyTypes, currenciesPositions]);

    useEffect(() => {
        const currencyName = currencyIds[selectedCurrencyId]?.name;

        setValue(`addresses.${index}.memoOrTagRequired` as const, hasCurrencyMemoOrTag(currencyName));
    }, [selectedCurrencyId, currencyIds]);

    const { updateDirtyRow } = useContext(CryptoFormContext);
    const handleChange = useCallback(
        (value) => {
            const defaultValue = defaultValues?.addresses?.[index]?.currency?.id;
            updateDirtyRow(rowId, "currency.id", defaultValue !== value);
        },
        [updateDirtyRow, rowId],
    );

    return (
        <Controller
            control={control}
            render={({ field }) => (
                <SingleDropdown
                    value={field.value}
                    onChange={(value) => {
                        field.onChange(value);
                        handleChange(value);
                    }}
                    renderTrigger={(trigger) => (
                        <SingleDropdown.Trigger {...trigger} size="medium" variant="minimum">
                            <CurrencyTriggerEssence {...trigger} option={trigger.selectedOption} size="small" />
                        </SingleDropdown.Trigger>
                    )}
                    options={currencyOptions}
                    placeholder="Asset"
                    disabled={disabled}
                    fullWidth
                    error={errors?.addresses?.[index]?.currency?.id?.message}
                >
                    <CurrencyDropdownSheet Dropdown={SingleDropdown} options={currencyOptions} size="medium" />
                </SingleDropdown>
            )}
            name={currencyControlName}
        />
    );
};
