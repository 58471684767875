import { useCallback, useMemo } from "react";
import { useInstruments } from "./useInstruments";

function getAssetFractionDigits(balanceStep: number = 1): number {
    return 9 - String(balanceStep).length;
}

export function getAssetPlaceholder(fractionDigits: number): string {
    return fractionDigits <= 0 ? "0" : `0.${"0".repeat(fractionDigits)}`;
}

export const useAssetFractionDigits = (asset: string) => {
    const { currencies } = useInstruments();

    return useMemo(() => {
        const balanceStep = currencies[asset]?.balanceStep;
        const fractionDigits = getAssetFractionDigits(balanceStep);

        return {
            fractionDigits,
            placeholder: getAssetPlaceholder(fractionDigits),
        };
    }, [currencies, asset]);
};

export const useGetAssetFractionDigits = () => {
    const { currencies } = useInstruments();

    return useCallback(
        (asset: string) => {
            const balanceStep = currencies[asset]?.balanceStep;
            const fractionDigits = getAssetFractionDigits(balanceStep);

            return {
                fractionDigits,
                placeholder: getAssetPlaceholder(fractionDigits),
            };
        },
        [currencies],
    );
};
