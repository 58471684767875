import { fork, put, race, select, take } from "@redux-saga/core/effects";
import { LinkRequests, LinkTransactions } from "components/Links";
import { settingSlice } from "feature/settings";
import { channel } from "redux-saga";
import { IRootState } from "store";
import { Message } from "types";
import { observe } from "utils/saga";
import { createNotification } from "..";
import { bindTo, unBind } from "../../ws";

export const eventNotificationChannel = channel();

export function* sagaEventNotifications() {
    yield fork(sagaObserveAndBind);
    let notificationSetting: IRootState["settings"] = {
        ...(yield select((state: IRootState) => state.settings)),
    };
    while (true) {
        const {
            settingUpdate,
            message,
        }: {
            settingUpdate: ReturnType<typeof settingSlice.actions.setSetting>;
            message: Message;
        } = yield race({
            settingUpdate: take(settingSlice.actions.setSetting),
            message: take(eventNotificationChannel),
        });
        if (settingUpdate) {
            const { key, value } = settingUpdate.payload;
            notificationSetting = {
                ...notificationSetting,
                [key]: value,
            };
        }
        if (message && notificationSetting.showRealTimeNotification) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [feed, feedId, action, data] = message;

            if (feed === "R" && action === "+" && Array.isArray(data) && data[1].length > 0) {
                yield put(
                    createNotification({
                        type: "inbox",
                        content: (
                            <>
                                Request from {data[1][0][0]} received. <LinkRequests>View</LinkRequests>
                            </>
                        ),
                    }),
                );
            }
            if (feed === "N" && action === "M" && Array.isArray(data) && data[0]?.[0]?.[7]) {
                yield put(
                    createNotification({
                        type: "inbox",
                        content: (
                            <>
                                Transaction received. <LinkTransactions>View</LinkTransactions>
                            </>
                        ),
                    }),
                );
            }

            if (feed === "N" && action === "D" && Array.isArray(data) && data[1].length > 0) {
                yield put(
                    createNotification({
                        type: "inbox",
                        content: (
                            <>
                                Transaction committed. <LinkTransactions>View</LinkTransactions>
                            </>
                        ),
                    }),
                );
            }
        }
    }
}

export function* sagaObserveAndBind() {
    yield fork(
        observe,
        (state) => state.settings.showRealTimeNotification,
        (isEnabled) => {
            if (!isEnabled) return;
            return {
                effect: put(bindTo({ feed: "R" })),
                cleanup: put(unBind({ feed: "R" })),
            };
        },
        settingSlice.actions.setSetting.toString(),
    );
    yield fork(
        observe,
        ({ settings: { showRealTimeNotification } }) => showRealTimeNotification,
        (isEnabled) => {
            if (!isEnabled) return;
            return {
                effect: put(bindTo({ feed: "N" })),
                cleanup: put(unBind({ feed: "N" })),
            };
        },
        settingSlice.actions.setSetting.toString(),
    );
}
