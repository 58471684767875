import styled from "styled-components";
import { Logo } from "components/Logo";
import { useSettings } from "hooks/useSettings";

const LogoStyle = styled.div`
    margin: 0 0 24px 12px;
`;

export const LogoWidget = () => {
    const settings = useSettings();

    return (
        <LogoStyle>
            <Logo height={settings.logo_height} />
        </LogoStyle>
    );
};
