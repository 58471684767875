import { DiffDataResult, ValueParse } from "@fm-frontend/utils";
import { FC, useCallback } from "react";
import { BulkUpdateRequest, mapInstrumentsToRequestBody } from "../ConfigurationModal/bulkService";
import { InstrumentsDiffTable } from "../ConfigurationModal/components/InstrumentsDiffTable";
import { InstrumentImportType } from "../ConfigurationModal/utils";
import { InstrumentsBulkEditResultModal } from "../ResultModal";
import { isEmptyOrRestrictedValue } from "../../BulkEditAssets/ConfigurationModal/utils";
import { DiffLightBox } from "../../components/DiffLightBox";

type DiffLightBoxProps = {
    importData: InstrumentImportType[];
    diffResult: DiffDataResult;
    diffTableColumns: string[];
    onClose: () => void;
    onBack: () => void;
};
export const InstrumentsBulkEditDiffLightBox: FC<DiffLightBoxProps> = ({
    importData,
    diffResult,
    diffTableColumns,
    onClose,
    onBack,
}) => {

    const getUpdateData = useCallback(() => {
        const updateData = diffResult.diff
            // Filter out the data that has no changes
            .filter((d) => d.bidMarkup.diff || d.askMarkup.diff)
            .map((d) => {
                const data = importData.find((i) => i.instrument === d.instrument.newValue && i.counterpartyId === d.counterpartyId.newValue);
                if (data === undefined) {
                    throw new Error("Update data not found");
                }

                return mapInstrumentsToRequestBody({
                    instrument: data.instrument,
                    counterpartyId: Number(data.counterpartyId),
                    bidMarkup: isEmptyOrRestrictedValue(data.bidMarkup) || isEmptyOrRestrictedValue(data.askMarkup)
                        ? undefined
                        : ValueParse.percent(data.bidMarkup),
                    askMarkup: isEmptyOrRestrictedValue(data.bidMarkup) || isEmptyOrRestrictedValue(data.askMarkup)
                        ? undefined
                        : ValueParse.percent(data.askMarkup),
                });
            });

        if (updateData.length === 0) {
            throw new Error("No data to update");
        }

        return updateData;
    }, [importData, diffResult.diff]);

    return (
        <DiffLightBox<BulkUpdateRequest>
            diffResult={diffResult}
            getUpdateData={getUpdateData}
            renderResultModal={(modalProps) => (
                <InstrumentsBulkEditResultModal {...modalProps} />
            )}
            onClose={onClose}
            onBack={onBack}
        >
            <InstrumentsDiffTable
                isLoading={diffResult.isLoading}
                columns={diffTableColumns}
                data={diffResult.diff}
            />
        </DiffLightBox>
    );
};
