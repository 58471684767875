import { H1 } from "@fm-frontend/uikit";
import { SetUpMfa } from "feature/auth/SetUpMfa";
import { SignUp } from "feature/auth/SignUp";
import { VerifyMfa } from "feature/auth/VerifyMfa";
import { useSelector } from "hooks";
import React from "react";
import { Header, Layout } from "../common";

export const AccountFlow: React.FC<{ invite?: string }> = ({ invite }) => {
    const authState = useSelector((state) => state.authentication);

    const getSignUpComponent = () => {
        switch (authState.status) {
            case "unauthenticated":
            case "signUp": {
                return <SignUp invite={invite} />;
            }
            case "setUpMfa": {
                return <SetUpMfa />;
            }
            case "verifyMfa": {
                return <VerifyMfa />;
            }
            default:
                return <SignUp invite={invite} />;
        }
    };

    return (
        <Layout>
            <Header>
                <H1>Create account</H1>
            </Header>
            {getSignUpComponent()}
        </Layout>
    );
};
