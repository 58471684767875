import { HStack, LinkButton, PSmall, PSmallBold, VStack } from "@fm-frontend/uikit";
import { EmptySearch } from "@fm-frontend/uikit/src/components/common/EmptySearch";
import { useIsOwnerUser } from "hooks";
import React from "react";
import { useTheme } from "styled-components";
import { useAddressContext } from "../AddressesContext";

export const TableEmptyState: React.VFC<{ defaultActionAvailable?: boolean }> = ({ defaultActionAvailable = true }) => {
    const { colors } = useTheme();
    const { side, openLightBox } = useAddressContext();
    const isOwnerUser = useIsOwnerUser();

    const actionAvailable = isOwnerUser && side === "your" && defaultActionAvailable;

    return (
        <VStack alignItems="center" paddingTop={40} paddingBottom={60} spacing={8}>
            <EmptySearch />
            {actionAvailable && (
                <HStack spacing={4} alignItems="center">
                    <PSmall color={colors.ui52}>Press</PSmall>
                    <LinkButton size="small" onClick={openLightBox}>
                        <PSmallBold>Add & edit</PSmallBold>
                    </LinkButton>
                    <PSmall color={colors.ui52}>to list addresses and simplify settlements</PSmall>
                </HStack>
            )}
        </VStack>
    );
};
