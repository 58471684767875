import { Notifications, PrimaryButton } from "@fm-frontend/uikit";
import { Copyright } from "components/Copyright";
import { LinkLogin, LinkSupport } from "components/Links";
import { CommonFieldTypes } from "feature/form/fields";
import FormInput from "feature/form/FormInput";
import { FormHeader, FormInputError, FormLabel } from "feature/form/style";
import { FormConfig, useForm } from "feature/form/useForm";
import { useFormHelpers } from "hooks";
import { useStepper } from "hooks/useStepper";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { resetPassword } from "services/authService";
import { ButtonGroup, Form } from "style";
import { SlimPage } from "../slimPage";
import { BottomLogin } from "../style";
import { ConfirmCode } from "./ConfirmCode";

const form: FormConfig = {
    fields: {
        email: CommonFieldTypes.email,
        code: {},
    },
    url: "resetPasswordRequest",
};

export const ResetPasswordRequest = () => {
    const { propsFor, globalError, areActionsDisabled, parsedValues } = useForm(form);
    const { errorValue: emailErrorValue, ...propsForEmail } = propsFor("email");
    const { toemail } = useParams<{ toemail?: string }>();
    const { currentStep, next, goToStep } = useStepper();
    const [email, setEmail] = useState<string | null>();
    const { error, setError, isLoading, startLoading, stopLoading } = useFormHelpers();

    const handleSendEmail = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        startLoading();

        resetPassword({
            username: parsedValues.email as string,
        })
            .then(() => {
                setError(null);
                setEmail(String(parsedValues.email));
                next();
            })
            .catch((error) => {
                setError(error?.message);
            })
            .finally(() => {
                stopLoading();
            });
    };

    useEffect(() => {
        setEmail(toemail);
        if (toemail) {
            goToStep(1);
        }
    }, [toemail]);

    const steps = [
        <>
            <Form>
                <FormHeader>Enter your email</FormHeader>
                <FormLabel>Email</FormLabel>
                <FormInput {...propsForEmail} />
                {globalError}
                <FormInputError isCentered>{error}</FormInputError>
                <ButtonGroup>
                    <PrimaryButton
                        fullWidth
                        size="large"
                        type="submit"
                        disabled={parsedValues.email === undefined || areActionsDisabled}
                        onClick={handleSendEmail}
                        loading={isLoading}
                    >
                        Continue
                    </PrimaryButton>
                </ButtonGroup>
            </Form>
            <BottomLogin>
                <LinkLogin />
                <LinkSupport />
                <span>
                    <Copyright />
                </span>
            </BottomLogin>
        </>,
        <ConfirmCode email={String(email)} />,
    ];
    return steps[currentStep];
};

export const ResetPasswordFlow = () => {
    return (
        <SlimPage>
            <ResetPasswordRequest />
            <Notifications />
        </SlimPage>
    );
};
