import { DropdownOption, MultipleDropdown, TriggerSize, TriggerVariant } from "@fm-frontend/uikit/src/components/v2";
import { OPTIONS_SEARCH_COUNT } from "@fm-frontend/uikit/src/components/v2/Dropdown";
import { CurrencyOptionEssence } from "components/CurrencyOptionEssence";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

type AssetsDropdownProps<T extends FieldValues> = {
    control: Control<T>;
    name: Path<T>;
    triggerSize: TriggerSize;
    variant: TriggerVariant;
    groupedOptions: Record<string, DropdownOption<string, { network?: string }>[]>;
    fullWidth?: boolean;
    placeholder?: string;
};

export const AssetsDropdown = <T extends FieldValues>({
    control,
    triggerSize,
    fullWidth,
    variant,
    groupedOptions,
    name,
    placeholder,
}: AssetsDropdownProps<T>) => {
    const allOptions = Object.values(groupedOptions)?.flat() ?? [];

    return (
        <Controller
            control={control}
            render={({ field }) => (
                <MultipleDropdown
                    values={field.value}
                    onChange={field.onChange}
                    renderTrigger={(trigger) => (
                        <MultipleDropdown.Trigger {...trigger} size={triggerSize} variant={variant}>
                            <MultipleDropdown.TriggerEssence
                                {...trigger}
                                option={trigger.selectedOptions}
                                size={triggerSize === "large" ? "large" : "small"}
                                totalOptionsCount={allOptions.length}
                            />
                        </MultipleDropdown.Trigger>
                    )}
                    options={allOptions}
                    caption="Assets"
                    fullWidth={fullWidth}
                    placeholder={placeholder}
                    asFilter
                >
                    <MultipleDropdown.Sheet size="large">
                        {({ query, normalizedQuery, onSearch }) => (
                            <>
                                {allOptions.length > OPTIONS_SEARCH_COUNT && (
                                    <MultipleDropdown.Search query={query} onSearch={onSearch} />
                                )}
                                {!query &&
                                    Object.entries(groupedOptions).map(([groupKey, groupOptions]) => (
                                        <MultipleDropdown.SheetGroup caption={groupKey} columns={2}>
                                            {groupOptions.map((option) => (
                                                <MultipleDropdown.Option key={`${option.value}`} value={option.value}>
                                                    <CurrencyOptionEssence option={option} query={query} />
                                                </MultipleDropdown.Option>
                                            ))}
                                        </MultipleDropdown.SheetGroup>
                                    ))}
                                {query && (
                                    <MultipleDropdown.EmptySearch>
                                        <MultipleDropdown.SheetGroup columns={2}>
                                            {allOptions
                                                .filter(
                                                    (o) =>
                                                        o.text.toLowerCase().includes(normalizedQuery) ||
                                                        o.network?.toLowerCase().includes(normalizedQuery),
                                                )
                                                .map((option) => (
                                                    <MultipleDropdown.Option
                                                        key={`${option.value}`}
                                                        value={option.value}
                                                    >
                                                        <CurrencyOptionEssence option={option} query={query} />
                                                    </MultipleDropdown.Option>
                                                ))}
                                        </MultipleDropdown.SheetGroup>
                                    </MultipleDropdown.EmptySearch>
                                )}
                            </>
                        )}
                    </MultipleDropdown.Sheet>
                </MultipleDropdown>
            )}
            name={name}
        />
    );
};
