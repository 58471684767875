import { HStack, PBold } from "@fm-frontend/uikit";
import { CoinIcon } from "components/CoinIcon";
import { getLabelWithHighlightedSearch } from "feature/settlements";
import { useSearchQueryContext } from "../../searchQueryContext";

export const AssetNameCell = ({ asset }: { asset: string }) => {
    const searchQuery = useSearchQueryContext();

    return (
        <HStack spacing={6} alignItems="center">
            <CoinIcon coin={asset} size={16} />
            <PBold>{getLabelWithHighlightedSearch(asset, searchQuery)}</PBold>
        </HStack>
    );
};
