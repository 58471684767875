export type MixinAttributes = {
    align?: "left" | "right";
    fontWeight?: 400 | 500;
    minWidth?: string;
};
export const createMixin = ({ align, fontWeight, minWidth }: MixinAttributes) => {
    const headerStyle: Partial<HTMLElement["style"]> = {};
    const cellStyle: Partial<HTMLElement["style"]> = {};
    if (align) {
        headerStyle.textAlign = align;
        cellStyle.textAlign = align;
    }
    if (fontWeight) {
        cellStyle.fontWeight = String(fontWeight);
    }
    if (minWidth) {
        headerStyle.minWidth = minWidth;
    }
    return { headerStyle, cellStyle };
};
export const createTitle = (title: string) => {
    return { id: title, Header: title };
};
