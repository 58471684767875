import { BasicButton, Modal } from "@fm-frontend/uikit";
import { useMemo, useState } from "react";
import FormInput from "feature/form/FormInput";
import { FormHeader, FormLabel } from "feature/form/style";
import { FormConfig, useForm } from "feature/form/useForm";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { ButtonGroup, Form } from "style";
import { ValidationMessages } from "utils/validationMessages";
import { USERNAME_MAX_LENGTH, USERNAME_MIN_LENGTH, USERNAME_REGEX } from "utils/validators";
import { Content, Strong } from "../../styled";
import type { FormData, UserInfoModalProps } from "./types";

export const UserInfoModal = ({ onClose, userData, onNextStep, onChange }: UserInfoModalProps) => {
    const [isDirty, setIsDirty] = useState<boolean>(false);
    // TODO Use react-hook-form instead and remove USERNAME_REGEX and other constants
    const form: FormConfig = useMemo(
        () => ({
            fields: {
                name: {
                    placeholder: "John Smith",
                },
                email: {
                    placeholder: "name@company.com",
                },
            },
            onSubmit: (data: FormData) => {
                onChange(data);
                onNextStep();
            },
            onChange: () => {
                setIsDirty(true);
            },
            getInitialValues: () => ({
                name: userData.name ?? "",
                email: userData.email ?? "",
            }),
            validate: (values: any) => {
                const errors: Partial<FormData> = {};
                const { name, email } = values as FormData;

                if (!name.match(USERNAME_REGEX)) {
                    errors.name = ValidationMessages.INVALID_USERNAME_SYMBOLS;
                }
                if (name.length < USERNAME_MIN_LENGTH || name.length > USERNAME_MAX_LENGTH) {
                    errors.name = ValidationMessages.INVALID_USERNAME_LENGTH;
                }
                if (!name) {
                    errors.name = "Name is required field";
                }
                if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                    errors.email = "Incorrect email";
                }
                if (!email) {
                    errors.email = "Email is required field";
                }

                if (Object.keys(errors).length > 0) {
                    return errors;
                }

                return true;
            },
        }),
        [],
    );
    const { inputProps, onSubmit, areActionsDisabled } = useForm(form);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(isDirty, onClose);

    return (
        <Modal isOpen onClose={closeModalWithConfirm}>
            <Content>
                <Form>
                    <FormHeader>
                        <Strong>Add new user</Strong> to your company
                    </FormHeader>
                    <FormLabel>Name</FormLabel>
                    <FormInput {...inputProps.name} />
                    <FormLabel>Email</FormLabel>
                    <FormInput {...inputProps.email} />
                    <ButtonGroup>
                        <BasicButton disabled={areActionsDisabled} onClick={onSubmit} size="large" fullWidth>
                            Select permissions ➞
                        </BasicButton>
                    </ButtonGroup>
                </Form>
            </Content>
        </Modal>
    );
};
