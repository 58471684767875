import { Box, P } from "@fm-frontend/uikit";
import { EmDash } from "const";
import React from "react";

export const BankCell: React.FC<{ bankName: string | undefined }> = ({ bankName }) => {
    return (
        <Box ellipsis width="180px">
            <P>{bankName || EmDash}</P>
        </Box>
    );
};
