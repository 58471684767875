import { useWidth } from "@fm-frontend/uikit";
import { HTMLProps, useMemo } from "react";
import { IconHelp } from "../../components/icons";
import { Tooltip } from "../../components/Tooltip";
import { Fieldset, InputContainer } from "../../style";
import { when } from "../../utils";
import { FormInputError } from "./style";

type SharedProps = {
    label?: string;
    helpTooltip?: string;
    errorValue?: string;
    disabled?: boolean;
    buttonTitle?: React.ReactElement;
    buttonAction?: (e: React.MouseEvent) => void;
    buttonValue?: boolean;
};

type Props = SharedProps & HTMLProps<HTMLInputElement>;

export const FormInput = ({
    label,
    helpTooltip,
    errorValue,
    buttonAction,
    buttonTitle,
    buttonValue,
    ...props
}: Props) => {
    const { offset, ref } = useWidth({ label });
    const tooltip = useMemo(() => {
        if (helpTooltip)
            return (
                <>
                    <Tooltip text={helpTooltip}>
                        <IconHelp />
                    </Tooltip>
                </>
            );
        return undefined;
    }, [helpTooltip]);
    const forcePlaceholder = props.type === "date" && !props.value;

    return (
        <Fieldset hasError={Boolean(errorValue)}>
            <InputContainer
                leftPad={offset}
                disabled={props.disabled}
                buttonValue={buttonValue}
                forcePlaceholder={forcePlaceholder}
            >
                <label ref={ref}>
                    {label}
                    {tooltip}
                </label>
                <input {...props} />
                {buttonTitle ? <button onClick={buttonAction}>{buttonTitle}</button> : null}
            </InputContainer>
            {when(errorValue, <FormInputError>{errorValue}</FormInputError>)}
        </Fieldset>
    );
};

export default FormInput;
