import { HStack } from "@fm-frontend/uikit";
import styled from "styled-components";

export const HintContainer = styled(HStack)`
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    min-height: 36px;
    padding: 4px 8px;

    :hover {
        background: ${(p) => p.theme.colors.ui4};
        cursor: pointer;
    }
`;
