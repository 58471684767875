import { HStack, MODAL_TOP_OFFSET, VStack } from "@fm-frontend/uikit";
import React from "react";
import styled from "styled-components";
import { LIGHTBOX_SCROLL_CONTAINER } from "../utils";

const ActionsContainer = styled(HStack)`
    grid-column: 3;
`;

const InfoContainer = styled(HStack)`
    grid-column: 2;
`;

const Footer = styled.div`
    width: 100%;
    gap: 6px;
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    border-top: 1px solid ${(p) => p.theme.colors.ui8};
`;

const ScrollContainer = styled(VStack)`
    margin: 1px;
    position: relative;
    flex: 1;
    overflow: auto;
`;

const LIGHTBOX_HEADER_FOOTER_HEIGHT = 164;

export const AddressBox: React.FC = ({ children }) => {
    const [title, description, addressBox, info, actions] = React.Children.toArray(children);

    return (
        <VStack flex={1}>
            <VStack paddingX={12}>
                <VStack paddingY={12}>{title}</VStack>
                <VStack paddingBottom={16}>{description}</VStack>
            </VStack>
            <ScrollContainer
                id={LIGHTBOX_SCROLL_CONTAINER}
                maxHeight={`calc(100vh - ${MODAL_TOP_OFFSET + LIGHTBOX_HEADER_FOOTER_HEIGHT}px)`}
                flex={1}
                paddingX={12}
            >
                <VStack>{addressBox}</VStack>
            </ScrollContainer>
            <Footer>
                <InfoContainer>{info}</InfoContainer>
                <ActionsContainer justifyContent="end" spacing={6}>
                    {actions}
                </ActionsContainer>
            </Footer>
        </VStack>
    );
};
