import { useInstruments } from "hooks";
import { useMemo } from "react";
import { usePositionsGrouped } from "store/usePositionsGrouped";

export type UseCurrenciesPositions = Record<string, { value: bigint; usdValue: bigint }>;

export const useCurrenciesPositions = (cpId?: number | null) => {
    const { result } = usePositionsGrouped({
        includeSettlementOrders: false,
        groupBy: cpId ? "counterparty" : "asset",
    });
    const { currencies } = useInstruments();

    return useMemo(() => {
        const filteredResult = !cpId ? result : result.filter(({ counterparty }) => counterparty === Number(cpId));

        return filteredResult.reduce<UseCurrenciesPositions>((acc, curr) => {
            acc[curr.asset] ??= {
                value: 0n,
                usdValue: 0n,
            };
            acc[curr.asset].value += curr.value;
            acc[curr.asset].usdValue += curr.usdValue;

            return acc;
        }, {});
    }, [result, currencies, cpId]);
};
