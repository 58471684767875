import { DiffDataResult } from "@fm-frontend/utils";
import { Mark, P } from "@fm-frontend/uikit";
import { FC } from "react";
import { useTheme } from "styled-components";

type DiffLightBoxFooterInfoProps = {
    diffResult: DiffDataResult;
};

export const DiffLightBoxFooterInfo: FC<DiffLightBoxFooterInfoProps> = ({ diffResult }) => {
    const { colors } = useTheme();

    const updatedCellsCount = diffResult.diff.reduce((total, diffItem) => {
        const diffItemUpdatedCellCount = Object.values(diffItem).reduce(
            (totalPerItem, currentItem) => {
                if (currentItem.diff) {
                    totalPerItem++;
                }

                return totalPerItem;
            },
            0,
        );

        total += diffItemUpdatedCellCount;

        return total;
    }, 0);

    const errorCellsCount = diffResult.diff.reduce((total, diffItem) => {
        const diffItemErrorsCellCount = Object.values(diffItem).reduce(
            (totalPerItem, currentItem) => {
                totalPerItem += currentItem.errors.length;

                return totalPerItem;
            },
            0,
        );

        total += diffItemErrorsCellCount;

        return total;
    }, 0);

    if (diffResult.isLoading) {
        return <P>Loading...</P>;
    }

    if (diffResult.hasErrors) {
        return (
            <P>
                <Mark weight={600} color={colors.negative100}>
                    {errorCellsCount === 1
                        ? `${errorCellsCount} error. `
                        : `${errorCellsCount} errors. `}
                </Mark>
                Please verify and update CSV file.
            </P>
        );
    }

    if (diffResult.hasDiffs) {
        return (
            <P>
                <Mark weight={600} color={colors.positive100}>
                    {updatedCellsCount === 1
                        ? `${updatedCellsCount} update. `
                        : `${updatedCellsCount} updates. `}
                </Mark>
                Please verify and start config update.
            </P>
        );
    }

    return (
        <P>
            <strong>No changes found.</strong> This configuration is up to date.
        </P>
    );
};
