import * as Yup from "yup";

export const assetsBulkEditFormSchema = Yup.object()
    .shape({
        assets: Yup.array().of(Yup.string().required()).min(1, "At least one asset is required"),
        cps: Yup.array().of(Yup.number().required()).min(1, "At least one cp is required"),
        shortSales: Yup.boolean(),
        overnightFees: Yup.boolean(),
        limits: Yup.boolean(),
    })
    .test("at-least-one-true", "", ({ shortSales, limits, overnightFees }) => {
        return shortSales || limits || overnightFees
            ? true
            : new Yup.ValidationError(
                  "At least one adjust parameter must be turned on",
                  null,
                  "adjust",
              );
    });
