import { Flex, FormTab, FormTabList, TabContext, VStack } from "@fm-frontend/uikit";
import { VFC } from "react";
import { useFilteredAndExtendedSettlementRequestsSnapshot } from "store/hooks";
import styled from "styled-components";
import { prepareSettlementRequest } from "types";
import { noop } from "utils";
import { AddNewWithdrawalRequestForm } from "./AddNewWithdrawalRequestForm";
import { WithdrawalRequest } from "./types";
import { useActions } from "./useActions";
import { WithdrawalRequestsTable } from "./WithdrawalRequestsTable";

const Container = styled(Flex)`
    overflow: auto;
    height: calc(100vh - 103px);
`;

const Header = styled.div`
    margin: 12px;
    margin-left: 8px;
`;

export const SubaccountRequestsPageContent: VFC = () => {
    const { cancelRequest } = useActions();
    const filteredAndExtendedSettlementRequestsSnapshot =
        useFilteredAndExtendedSettlementRequestsSnapshot();
    const requestsFromYou: WithdrawalRequest[] =
        filteredAndExtendedSettlementRequestsSnapshot.data[0]
            .map(({ settlementRequest }) => {
                const preparedSettlementRequest = prepareSettlementRequest(settlementRequest);

                return {
                    id: preparedSettlementRequest.id,
                    subaccount: { name: "-", id: preparedSettlementRequest.counterpartyId },
                    asset: preparedSettlementRequest.currencyName,
                    network: preparedSettlementRequest.network,
                    amount: preparedSettlementRequest.amount,
                    isFeePaidByRecipient: Boolean(preparedSettlementRequest.flags),
                    comment: preparedSettlementRequest.comment,
                    date: preparedSettlementRequest.expirationTimestamp,
                };
            })
            .sort((request1, request2) => request2.date - request1.date);

    return (
        <Container spacing={8} paddingLeft={8} paddingRight={8}>
            <AddNewWithdrawalRequestForm />
            <VStack flex={1} asCard minWidth="445px" paddingBottom={8}>
                <Header>
                    <TabContext value="withdrawalRequests" handleClick={noop}>
                        <FormTabList>
                            <FormTab
                                title="Withdrawal requests"
                                label={String(requestsFromYou.length)}
                                value="withdrawalRequests"
                            />
                        </FormTabList>
                    </TabContext>
                </Header>
                <WithdrawalRequestsTable
                    data={requestsFromYou}
                    onCancelRequestClick={cancelRequest}
                    isLoading={filteredAndExtendedSettlementRequestsSnapshot.isLoading}
                />
            </VStack>
        </Container>
    );
};
