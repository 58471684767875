import { ClientId } from "@fm-frontend/adminery/src/entities/client";

export type NotificationId = number;

export enum NotificationMessageType {
    // NotificationRiskManagement
    INITIAL_MARGIN = "INITIAL_MARGIN",
    RESTRICTED_TRADING = "RESTRICTED_TRADING",
    LIQUIDATION = "LIQUIDATION",
    LIMIT_SET = "LIMIT_SET",
    MUTUAL_LIMIT_SET = "MUTUAL_LIMIT_SET",
    SUBACCOUNT_LIMIT_SET = "SUBACCOUNT_LIMIT_SET",

    // NotificationSettlements
    INCOMING_SETTLEMENT = "INCOMING_SETTLEMENT",
    COMMITTED_TRANSACTION = "COMMITTED_TRANSACTION",

    // NotificationSystem
    DAILY_REPORT_SENT = "DAILY_REPORT_SENT",
    MONTHLY_REPORT_SENT = "MONTHLY_REPORT_SENT",

    // NotificationSab
    ADDRESSES_NEED_CONFIRMATION = "ADDRESSES_NEED_CONFIRMATION",
    ADDRESSES_CONFIRMED = "ADDRESSES_CONFIRMED",
}

interface BaseNotification {
    id: NotificationId;
    messageType: NotificationMessageType;
    subject: string;
    content: string;
    metadata: Record<string, unknown>;
    sentAt: number;
    isRead: boolean;
}

interface InitialMarginNotification extends BaseNotification {
    messageType: NotificationMessageType.INITIAL_MARGIN;
    metadata: {
        cpId: number;
        whitelabel: number;
    };
}

interface RestrictedTradingNotification extends BaseNotification {
    messageType: NotificationMessageType.RESTRICTED_TRADING;
    metadata: {
        cpId: number;
        whitelabel: number;
    };
}

interface LiquidationNotification extends BaseNotification {
    messageType: NotificationMessageType.LIQUIDATION;
    metadata: {
        cpId: number;
        whitelabel: number;
    };
}

interface LimitSetNotification extends BaseNotification {
    messageType: NotificationMessageType.LIMIT_SET | NotificationMessageType.MUTUAL_LIMIT_SET;
    metadata: {
        cpId: number;
        whitelabel: number;
    };
}

interface SettlementNotification extends BaseNotification {
    messageType:
        | NotificationMessageType.INCOMING_SETTLEMENT
        | NotificationMessageType.COMMITTED_TRANSACTION;
    metadata: {
        cpId: ClientId;
        currencyName: string;
        whitelabel: number;
    };
}

interface ReportSentNotification extends BaseNotification {
    messageType:
        | NotificationMessageType.DAILY_REPORT_SENT
        | NotificationMessageType.MONTHLY_REPORT_SENT;
    metadata: {
        cpId: undefined;
        whitelabel: number;
    };
}

export type Notification =
    | InitialMarginNotification
    | RestrictedTradingNotification
    | LiquidationNotification
    | LimitSetNotification
    | SettlementNotification
    | ReportSentNotification;

export { getNotifications } from "./getNotifications";
export { getUnreadNotificationsCount } from "./getUnreadNotificationsCount";
export { markAllNotificationsAsRead } from "./markAllNotificationsAsRead";
export { markNotificationsAsRead } from "./markNotificationsAsRead";
