import React from "react";
import styled from "styled-components";
import { EmptySearch } from "../../../common/EmptySearch";
import { MultipleOptionFn, OptionFn } from "../Option";

const StyledEmptySearch = styled(EmptySearch)`
    padding-top: 6px;
    padding-bottom: 6px;
`;

const findAllOptions = (children: React.ReactNode, options: React.ReactElement[] = []): React.ReactElement[] => {
    React.Children.forEach(children, (child) => {
        if (!React.isValidElement(child)) {
            return;
        }

        if (child.type === OptionFn || child.type === MultipleOptionFn) {
            options.push(child);
        }

        findAllOptions(child.props.children, options);
    });

    return options;
};

type EmptySearchFnProps = {
    emptyPlaceholder?: React.ReactNode;
    description?: string;
};

export const EmptySearchFn: React.FC<EmptySearchFnProps> = ({
    description,
    emptyPlaceholder = <StyledEmptySearch description={description ?? "Option not found"} />,
    children,
}) => {
    const options = findAllOptions(children);

    if (options.length === 0) {
        return <>{emptyPlaceholder}</>;
    }

    return <>{children}</>;
};
