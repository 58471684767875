import { post } from "api";

type ResponseParams = {
    count: number;
};

export const getUnreadNotificationsCount = async () => {
    const response = await post("notifications/getUnreadNotificationsCount", {});

    return response as ResponseParams;
};
