import { FC, useEffect, useState } from "react";
import { getDistanceLabel } from "../utils";

export const Distance: FC<{ date: Date }> = ({ date }) => {
    const [now, setNow] = useState(new Date());

    useEffect(() => {
        const timeoutId = setTimeout(() => setNow(new Date()), 1000);

        return () => clearTimeout(timeoutId);
    }, [now, setNow]);

    return <>{getDistanceLabel(now, date)}</>;
};
