import { Flex, VStack } from "@fm-frontend/uikit";
import { StatusOfRfqHistoryItem } from "feature/rfq/apiHooks/useLastRfqTrades";
import styled from "styled-components";
import { LastRfqTable } from "./LastRfqTable";

const Container = styled(Flex)`
    overflow: auto;
    padding: 0 1px 1px 1px;
    border-radius: 16px;
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow-x: auto;
`;

export const LastRfqAccordionContent = ({
    selectedStatuses,
}: {
    selectedStatuses: StatusOfRfqHistoryItem[];
}) => {
    return (
        <Container spacing={8}>
            <CardContainer flex={1} asCard>
                <LastRfqTable selectedStatuses={selectedStatuses} />
            </CardContainer>
        </Container>
    );
};
