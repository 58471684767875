import { BasicInput, ClientId, getMatch, getMatchStartsWith, Icons, Popover } from "@fm-frontend/uikit";
import { Subaccount } from "feature/subaccounts/api";
import { ChangeEvent, FC, KeyboardEvent, useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as CounterpartyIcon } from "resources/counterparty_icon.svg";
import styled from "styled-components";

type SubaccountSelectorType = {
    subaccounts: Subaccount[];
    error?: string;
    onSelected?: (subaccountId: number) => void;
    onChange?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
};

const Container = styled.div``;

const SearchContainer = styled.div`
    position: relative;
`;

const SearchInput = styled(BasicInput)`
    background-color: ${(p) => p.theme.colors.ui12} !important;
`;

const SearchActions = styled.div`
    display: flex;
    gap: 6px;
    position: absolute;
    top: 14px;
    right: 12px;
`;

const SearchAction = styled.div`
    cursor: pointer;
`;

const DropdownContainer = styled.div`
    background: ${(p) => p.theme.colors.uiWhite100};
    border-radius: 8px;
    padding: 4px;
    width: 336px;
    max-height: 272px;
    box-shadow: 0 4px 16px 0 rgba(36, 48, 52, 0.08), 0 0 0 1px rgba(36, 48, 52, 0.12) inset;
    overflow: auto;
`;

const DropdownItem = styled.div<{ $selected: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 36px;
    cursor: pointer;
    background: ${(p) => (p.$selected ? p.theme.colors.ui4 : "transparent")};
    border-radius: 6px;
    padding: 8px;

    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }

    :hover {
        background: ${(p) => p.theme.colors.ui4};
    }
`;

const DropdownCounterpartyIcon = styled(CounterpartyIcon)`
    flex-shrink: 0;
`;

const DropdownUsername = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const SubaccountSelector: FC<SubaccountSelectorType> = ({
    subaccounts,
    error,
    onSelected,
    onChange,
    onFocus,
    onBlur,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const filteredSubaccounts = useMemo(() => {
        const activeSubaccounts = subaccounts.filter((subaccount) => subaccount.status === "active");

        if (searchQuery === "") {
            return activeSubaccounts;
        }

        return activeSubaccounts.filter((subaccount) => {
            const matchId = String(subaccount.info.clientId).startsWith(searchQuery);
            const matchUsername = subaccount.info.username.toLowerCase().includes(searchQuery.toLowerCase());
            return matchId || matchUsername;
        });
    }, [searchQuery, subaccounts]);

    const handleSelect = (clientId: number | undefined) => {
        if (clientId !== undefined) {
            onSelected?.(clientId);
        }
    };
    const handleFocus = () => {
        onFocus?.();
        setShowDropdown(true);
    };
    const handleBlur = () => {
        onBlur?.();
        setShowDropdown(false);
    };
    const handleClear = () => {
        setSearchQuery("");
        inputRef.current?.focus();
    };
    const handleExpand = () => {
        setShowDropdown((state) => !state);
    };
    const handleClick = (clientId: number | undefined) => () => handleSelect(clientId);
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange?.();
        setSearchQuery(event.target.value);
    };
    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        const lastIndex = filteredSubaccounts.length - 1;

        if (event.key === "ArrowDown") {
            event.preventDefault();
            const nextIndex = selectedIndex === lastIndex ? 0 : selectedIndex + 1;
            setSelectedIndex(nextIndex);
        }
        if (event.key === "ArrowUp") {
            event.preventDefault();
            const nextIndex = selectedIndex === 0 ? lastIndex : selectedIndex - 1;
            setSelectedIndex(nextIndex);
        }
        if (event.key === "Enter") {
            event.preventDefault();
            if (filteredSubaccounts.length !== 0) {
                handleSelect(filteredSubaccounts[selectedIndex].info.clientId);
            } else {
                handleSelect(Number(event.currentTarget.value));
            }
        }
    };

    /** reset selection on search */
    useEffect(() => {
        if (filteredSubaccounts.length === 0) {
            return;
        }
        setSelectedIndex(0);
    }, [filteredSubaccounts]);

    return (
        <Container>
            <SearchContainer>
                <Popover
                    isOpen={showDropdown}
                    positions={["bottom"]}
                    padding={6}
                    content={
                        filteredSubaccounts.length !== 0 ? (
                            <DropdownContainer>
                                {filteredSubaccounts.map((subaccount, index) => (
                                    <DropdownItem
                                        key={subaccount.info.clientId}
                                        $selected={index === selectedIndex}
                                        onClick={handleClick(subaccount.info.clientId)}
                                    >
                                        <DropdownCounterpartyIcon />
                                        <DropdownUsername>
                                            {getMatch(subaccount.info.username, searchQuery) ??
                                                subaccount.info.username}
                                        </DropdownUsername>
                                        <ClientId
                                            id={
                                                getMatchStartsWith(String(subaccount.info.clientId), searchQuery) ??
                                                subaccount.info.clientId
                                            }
                                            tooltip={null}
                                        />
                                    </DropdownItem>
                                ))}
                            </DropdownContainer>
                        ) : (
                            <></>
                        )
                    }
                >
                    <SearchInput
                        ref={inputRef}
                        value={searchQuery}
                        placeholder="Select Sub-account or enter Client ID"
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        error={error}
                        autoFocus={error === undefined}
                        data-1pignore
                    />
                </Popover>
                <SearchActions>
                    <SearchAction onClick={handleClear}>{searchQuery !== "" && <Icons.Clear />}</SearchAction>
                    <SearchAction onClick={handleExpand}>
                        {showDropdown ? <Icons.Collapse /> : <Icons.Expand />}
                    </SearchAction>
                </SearchActions>
            </SearchContainer>
        </Container>
    );
};
