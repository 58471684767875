import { ValidationMessages } from "utils/validationMessages";
import { string, StringSchema } from "yup";

const NUMBER_REGEX = /^-?[0-9]+(\.[0-9]+)?$/;
const EFX_SIZE_REGEX = /^-?[0-9]{1,12}(\.[0-9]{1,8})?$/;

const normalizeNumber = (num: string) => {
    const parts = num.split(".");
    let normalizedNumber = BigInt(parseInt(parts[0])) * BigInt(1e8);
    if (parts.length > 1) {
        normalizedNumber += BigInt(parseInt(parts[1]) * 10 ** (8 - parts[1].length));
    }
    return normalizedNumber;
};
const isNumber = (schema: StringSchema) =>
    schema.test("test-is-correct-number", ValidationMessages.MUST_BE_NUMBER, (value) =>
        Boolean(value?.match(NUMBER_REGEX)),
    );
const isPositiveNumber = (schema: StringSchema) =>
    schema.test("test-is-positive-number", ValidationMessages.POSITIVE_NUMBER, (value) => {
        if (!value?.match(EFX_SIZE_REGEX) || value[0] === "-") {
            return false;
        }

        return normalizeNumber(value) > 0;
    });
const isNonNegativeNumber = (schema: StringSchema) =>
    schema.test("test-is-non-negative-number", ValidationMessages.NOT_NEGATIVE_NUMBER, (value) => {
        if (!value?.match(EFX_SIZE_REGEX) || value[0] === "-") {
            return false;
        }

        return normalizeNumber(value) >= 0;
    });
const MIN_EFX_SIZE_NUMBER = -100000000000000000n;
const MAX_EFX_SIZE_NUMBER = 100000000000000000n;
const isInEfxSizeRange = (schema: StringSchema) =>
    schema.test("test-is-in-efx-size-range", ValidationMessages.EFX_SIZE_OUT_OF_RANGE, (value) => {
        if (!value?.match(EFX_SIZE_REGEX)) {
            return false;
        }

        const normalizedNumber = normalizeNumber(value);

        return normalizedNumber >= MIN_EFX_SIZE_NUMBER && normalizedNumber <= MAX_EFX_SIZE_NUMBER;
    });

const applyValidators = (validators: ((schema: StringSchema) => StringSchema)[]) => (schema: StringSchema) =>
    validators.reduce<StringSchema>((updatedSchema, validator) => validator(updatedSchema), schema);

export const getAmountSchema = (schema = string()) =>
    applyValidators([isNumber, isInEfxSizeRange, isPositiveNumber])(schema.required(ValidationMessages.REQUIRED));
export const getFeeValidator = (schema = string()) =>
    applyValidators([isNumber, isInEfxSizeRange, isNonNegativeNumber])(schema.required(ValidationMessages.REQUIRED));
