import { ConfirmationModal as ConfirmationModalView } from "@fm-frontend/uikit";
import { setConfirmModalContent } from "feature/app";
import { useSelector } from "hooks/useSelector";
import { useDispatch } from "react-redux";

export const ConfirmationModal = () => {
    const dispatch = useDispatch();
    const modalProps = useSelector((state) => state.app.confirmModalContent);

    if (!modalProps) {
        return null;
    }

    const closeModal = () => {
        dispatch(setConfirmModalContent(null));
    };

    const handleConfirm = () => {
        modalProps.onConfirm();
        closeModal();
    };

    return (
        <ConfirmationModalView
            onConfirm={handleConfirm}
            onClose={closeModal}
            title={modalProps.title}
            description={modalProps.description}
            confirmButtonTitle={modalProps.confirmButtonTitle}
            cancelButtonTitle={modalProps.cancelButtonTitle}
        />
    );
};
