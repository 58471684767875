import styled, { css } from "styled-components";
import { onHover } from "../../const";
import { TransitionState } from "../../hooks/useTransition";
import { selectors } from "../../style/theme";

export const Banner = styled.article<{ transitionState: TransitionState }>`
    background: rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding: 12px;
    user-select: none;
    transition: background-color ${(p) => p.theme.transitions.default};
    header {
        ${selectors.fontMedium}
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.005em;
        svg {
            vertical-align: top;
            path {
                transition: fill ${(p) => p.theme.transitions.default};
            }
        }
    }
    p {
        ${selectors.fontMedium}
        font-size: 11px;
        line-height: 14px;
        letter-spacing: -0.005em;
        margin-block-start: 4px;
        margin-block-end: 0;
        color: ${(p) => p.theme.colors.textSubdued};
    }
    button {
        width: 100%;
        text-align: start;
        ${selectors.fontRegular}

        font-size: 11px;
        line-height: 14px;

        letter-spacing: -0.005em;
        color: ${(p) => p.theme.colors.textSubdued};
        margin-block-start: 4px;
    }
    ${onHover} {
        background-color: #e3e3e3;
        cursor: pointer;
    }
    opacity: 0;
    transition: transform ${(p) => p.theme.transitions.default}, opacity ${(p) => p.theme.transitions.default};
    ${({ transitionState }) => {
        switch (transitionState) {
            case "entering":
            case "exiting": {
                return css`
                    transform: scale(0.9);
                `;
            }
            case "active": {
                return css`
                    opacity: 1;
                    transform: scale(1);
                `;
            }
        }
    }};
`;
