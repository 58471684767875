import React, { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CryptoAddressFormInput } from "../../../../types";
import { ADDRESSES_COLLECTION_NAME } from "../../../../utils";
import { CryptoFormContext } from "../../CryptoFormContext";

type ValidationControlProps = {
    index: number;
    rowId: string;
    accountId: number | undefined;
};

export const ValidationControl: React.FC<ValidationControlProps> = ({ index, accountId, rowId }) => {
    const { register } = useFormContext<CryptoAddressFormInput>();
    const { removeDirtyRow, addDirtyRow } = useContext(CryptoFormContext);

    useEffect(() => {
        if (accountId === undefined) {
            addDirtyRow(rowId);
        }

        return () => {
            removeDirtyRow(rowId);
        };
    }, [rowId]);

    return (
        <input hidden defaultValue={rowId} {...register(`${ADDRESSES_COLLECTION_NAME}.${index}._validationItemId`)} />
    );
};
