import { VStack } from "@fm-frontend/uikit";
import { AssetInfo } from "components/AssetInfo";
import { EmDash } from "const";
import { fmt, toLongDateString } from "utils/format";
import { AmountInfo } from "../../AmountInfo";
import { Statistic } from "../../Statistic";
import { Transaction } from "../../types";
import { PAYER_TITLES } from "../TransactionsTable";
import { SubaccountInfo } from "./SubaccountInfo";

export const TransactionInfo = ({ transaction }: { transaction: Transaction }) => (
    <VStack spacing={12} padding={12} width="100%" asCard>
        <SubaccountInfo subaccountId={transaction.subaccount.id} />
        <Statistic title="Created" value={toLongDateString(transaction.created)} />
        <Statistic title="Asset" value={<AssetInfo asset={transaction.asset} network={transaction.network} />} />
        <AmountInfo amount={transaction.amount} />
        <Statistic
            title={`Fee paid ${PAYER_TITLES[transaction.fee.payer]}`}
            value={
                transaction.fee.size
                    ? fmt(transaction.fee.size, {
                          significantDigits: 8,
                          type: "size64",
                      }).formattedValue
                    : EmDash
            }
        />
        {transaction.txId && <Statistic title="TX ID" value={transaction.txId} />}
        {transaction.comment && <Statistic title="Comment" value={transaction.comment} />}
    </VStack>
);
