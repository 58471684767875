import { MAX_API_VALUE } from "@fm-frontend/utils";
import { string } from "yup";
import { ValidationMessages } from "./validationMessages";

export const REQUIRED_MESSAGE = "Field Required";

export const USERNAME_REGEX = /^['0-9a-zA-Z()._& -]{3,32}$/;
export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 32;

export const validators = {
    required: {
        required: REQUIRED_MESSAGE,
    },
    code: {
        required: REQUIRED_MESSAGE,
        validate: {
            isDigit: (v: string) => /^\d{6}$/.test(v) || "Code must be 6-digit",
        },
    },
    username: {
        required: REQUIRED_MESSAGE,
        yupValidator: string()
            .transform((value: string) => value?.trim())
            .required(ValidationMessages.REQUIRED)
            .min(USERNAME_MIN_LENGTH, ValidationMessages.INVALID_USERNAME_LENGTH)
            .max(USERNAME_MAX_LENGTH, ValidationMessages.INVALID_USERNAME_LENGTH)
            .matches(USERNAME_REGEX, ValidationMessages.INVALID_USERNAME_SYMBOLS),
    },
    confirmPassword(password: string) {
        return {
            required: REQUIRED_MESSAGE,
            validate: {
                isMatch: (v: string) => password === v || "Your passwords must match",
            },
        };
    },
    email: {
        required: REQUIRED_MESSAGE,
        pattern: {
            value: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
            message: "Invalid Email",
        },
    },
    password: {
        required: REQUIRED_MESSAGE,
        minLength: {
            value: 8,
            message: "Must be at least 8 characters",
        },
        validate: {
            hasNumber: (v: string) => /\d/.test(v) || "Must contain at least 1 digit",
            hasUpperCase: (v: string) => /[A-Z]/.test(v) || "Must contain at least 1 upper case",
            hasLowerCase: (v: string) => /[a-z]/.test(v) || "Must contain at least 1 lower case",
            hasSpecialSymbol: (v: string) =>
                /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(v) ||
                "Must contain at least 1 special character",
        },
    },
    isUsdLimitEquivalentValid(usdLimitEquivalent: bigint) {
        return usdLimitEquivalent < MAX_API_VALUE;
    },
};
