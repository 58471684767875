import { FC, useCallback } from "react";
import { useSWRConfig } from "swr";
import { useLimitByAssetSWRKey } from "hooks/useExtentedLimitsByAsset";
import { useShortSalesBanSWRKey } from "hooks/useShortSalesBan";
import { useOvernightFeesSWRKey } from "hooks/useOvernightFees";
import { AssetsBulkEditModal } from "../ConfigurationModal";
import { BulkEditButton } from "../../components/BulkEditButton";

type AssetsBulkEditButtonProps = {
    disabled: boolean;
};

export const AssetsBulkEditButton: FC<AssetsBulkEditButtonProps> = ({ disabled }) => {
    const { mutate } = useSWRConfig();
    const limitByAssetSWRKey = useLimitByAssetSWRKey();
    const shortSalesBanSWRKey = useShortSalesBanSWRKey();
    const overnightFeesSWRKey = useOvernightFeesSWRKey();

    const handleModalClose = useCallback(() => {
        mutate(limitByAssetSWRKey);
        mutate(shortSalesBanSWRKey);
        mutate(overnightFeesSWRKey);
    }, [limitByAssetSWRKey, shortSalesBanSWRKey, overnightFeesSWRKey, mutate]);

    return (
        <BulkEditButton
            disabled={disabled}
            renderModal={(cancel) => <AssetsBulkEditModal onCancel={cancel} />}
            onClose={handleModalClose}
        />
    );
};
