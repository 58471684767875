import { Flex } from "@fm-frontend/uikit";
import styled from "styled-components";
import { fmtDeltaratePercent } from "utils/format";

const Container = styled(Flex)`
    align-items: center;
    padding: 0 12px;
`;

export const CpOvernightCell = ({ value }: { value: number | undefined }) => (
    <Container>{value ? `${fmtDeltaratePercent(value).copyableValue}%` : null}</Container>
);
