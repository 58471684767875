import { Row } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { VirtualItem } from "@tanstack/virtual-core/src";
import { memo } from "react";
import { InfiniteTableRow } from "../Row";
import { RowClickHandler } from "../types";

type InfiniteTableRowsProps<T extends object> = {
    virtualRows: VirtualItem[];
    rows: Row<T>[];
    selectedRows: Record<string, boolean>;
    rowVirtualizer: ReturnType<typeof useVirtualizer>;
    onRowClick?: RowClickHandler<T>;
};
const InfiniteTableRowsComponent = <T extends object>({
    virtualRows,
    rows,
    selectedRows,
    rowVirtualizer,
    onRowClick,
}: InfiniteTableRowsProps<T>) => (
    <>
        {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index];
            const selected = selectedRows[row.id];

            return (
                <InfiniteTableRow
                    key={virtualRow.key}
                    index={virtualRow.index}
                    row={row}
                    isSelected={selected}
                    rowVirtualizer={rowVirtualizer}
                    onRowClick={onRowClick}
                />
            );
        })}
    </>
);

export const InfiniteTableRows = memo(InfiniteTableRowsComponent) as typeof InfiniteTableRowsComponent;
