import { Accordion } from "@fm-frontend/uikit";
import { parseLimit } from "feature/counterparties/utils";
import { AuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { useInstrumentInfo } from "hooks/useInstrumentInfo";
import { FC, useMemo } from "react";
import { useCounterpartyLimitsSnapshot } from "store/hooks";
import styled from "styled-components";
import { AssetLimit } from "./AssetLimit";
import { AssetLimitsEmptyState } from "./AssetLimit/AssetLimitsEmptyState";
import { CpLimit } from "./CpLimit";
import { Markup } from "./Markup";
import { ModalContextProvider } from "./ModalContext";
import { NoInstrumentBanner } from "./NoInstrumentBanner";
import { NoLimitBanner } from "./NoLimitBanner";

const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(50%, 1fr));

    position: relative;
`;

type RiskManagementWidgetProps = {
    authorizedClientId: AuthorizedClientId;
    selectedInstrument: string | undefined;
};

export const RiskManagementWidget: FC<RiskManagementWidgetProps> = ({
    authorizedClientId,
    selectedInstrument = "",
}) => {
    const { baseAsset, quoteAsset } = useInstrumentInfo(selectedInstrument);

    // temporary solution until limits become optional
    const { data: counterpartiesLimits = [] } = useCounterpartyLimitsSnapshot();
    const noCpLimit = useMemo(() => {
        return (
            counterpartiesLimits
                .map(parseLimit)
                .filter((limit) => limit.counterpartyId === authorizedClientId).length === 0
        );
    }, [counterpartiesLimits, authorizedClientId]);

    return (
        <Accordion
            defaultExpanded
            header={<Accordion.Title upperCase>Risk management overview</Accordion.Title>}
            content={
                <Content>
                    {noCpLimit && <NoLimitBanner />}
                    <ModalContextProvider>
                        <CpLimit
                            key={`cpLimit-${noCpLimit}`}
                            authorizedClientId={authorizedClientId}
                            noCpLimit={noCpLimit}
                        />
                    </ModalContextProvider>
                    <ModalContextProvider>
                        <Markup
                            key={`markup-${noCpLimit}`}
                            noCpLimit={noCpLimit}
                            instrument={selectedInstrument}
                            authorizedClientId={authorizedClientId}
                        />
                    </ModalContextProvider>
                    {(!baseAsset || !quoteAsset) && (
                        <>
                            <AssetLimitsEmptyState />
                            {!noCpLimit && <NoInstrumentBanner />}
                        </>
                    )}
                    {baseAsset && quoteAsset && (
                        <>
                            <ModalContextProvider>
                                <AssetLimit
                                    key={`assetLimit-${noCpLimit}`}
                                    noCpLimit={noCpLimit}
                                    asset={baseAsset}
                                    authorizedClientId={authorizedClientId}
                                />
                            </ModalContextProvider>
                            <ModalContextProvider>
                                <AssetLimit
                                    key={`assetLimit-${noCpLimit}`}
                                    noCpLimit={noCpLimit}
                                    asset={quoteAsset}
                                    authorizedClientId={authorizedClientId}
                                />
                            </ModalContextProvider>
                        </>
                    )}
                </Content>
            }
        />
    );
};
