import { H2, HStack } from "@fm-frontend/uikit";
import { useIsRfqViewingEnabled } from "store/useRfqSettings";
import styled from "styled-components";
import { HistoryTab } from "./hooks";

const Tab = styled(H2)<{ isActive?: boolean }>`
    cursor: pointer;
    color: ${(p) => (p.isActive ? p.theme.colors.ui100 : p.theme.colors.ui32)};

    :hover {
        color: ${(p) => p.theme.colors.ui100};
    }
`;

export const TradesHistoryMenu = ({
    historyType,
    onHistoryTypeChange,
}: {
    historyType: HistoryTab;
    onHistoryTypeChange: (historyType: HistoryTab) => void;
}) => {
    const isRfqViewingEnabled = useIsRfqViewingEnabled();

    return (
        <HStack flex={1} spacing={16}>
            <Tab
                isActive={historyType === HistoryTab.FirmBook}
                key={HistoryTab.FirmBook}
                onClick={() => onHistoryTypeChange(HistoryTab.FirmBook)}
            >
                Trades
            </Tab>
            {isRfqViewingEnabled && (
                <Tab
                    isActive={historyType === HistoryTab.RFQ}
                    key={HistoryTab.RFQ}
                    onClick={() => onHistoryTypeChange(HistoryTab.RFQ)}
                >
                    RFQs
                </Tab>
            )}
        </HStack>
    );
};
