import { useContext } from "react";
import { InstrumentSelectorContext } from "./InstrumentSelectorContext";

export const useInstrumentSelectorContext = () => {
    const context = useContext(InstrumentSelectorContext);
    if (!context) {
        throw new Error("useTradingContext must be used within a TradingContextProvider");
    }
    return context;
};
