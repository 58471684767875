import { BasicButton, DesctructiveButton, H2, SimpleInput } from "@fm-frontend/uikit";
import { SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { post } from "api";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { AnySchema, number, object, string } from "yup";
import { useInstruments } from "hooks";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType, useUserType } from "store/hooks";
import { ORDER_TYPES } from "types";
import EfxTypes from "utils/EfxTypes";
import { ValidationMessages } from "utils/validationMessages";
import { CounterpartiesSheet } from "components/CounterpartiesSheet";
import { CounterpartyTriggerEssence } from "components/CounterpartiesTriggerEssence";
import { ManualOrderCreatedModal } from "./ManualOrderCreatedModal";
import { useCounterpartiesOptions } from "./useCounterpartiesOptions";

type OrderType = typeof ORDER_TYPES[number];
type FormOrderType = Extract<OrderType, "limit" | "postOnly" | "voiceTrade" | "liquidation">;

const BuyButton = styled(BasicButton)`
    color: ${(p) => p.theme.colors.green};
`;

interface OrderFormInputs {
    instrument: string;
    takerId?: number;
    clientOrderId?: number;
    price: number;
    size: number;
    type: FormOrderType;
}

const MANUAL_TRADE_ORDER_TYPES: FormOrderType[] = ["voiceTrade", "liquidation"];
const validationSchema = object<Record<keyof OrderFormInputs, AnySchema>>().shape({
    instrument: string().required(ValidationMessages.REQUIRED),
    takerId: number().when("type", (type: FormOrderType, schema: any) => {
        return MANUAL_TRADE_ORDER_TYPES.includes(type)
            ? schema
                  .typeError(ValidationMessages.MUST_BE_NUMBER)
                  .transform((value: any, originalValue: any) => (originalValue === "" ? undefined : value))
                  .required(ValidationMessages.REQUIRED)
            : schema.nullable(true);
    }),
    clientOrderId: number()
        .optional()
        .typeError(ValidationMessages.MUST_BE_NUMBER)
        .transform((value: any, originalValue: any) => (originalValue === "" ? undefined : value)),
    price: number()
        .typeError(ValidationMessages.MUST_BE_NUMBER)
        .transform((value: any, originalValue: any) => (originalValue === "" ? undefined : value))
        .required(ValidationMessages.REQUIRED),
    size: number()
        .typeError(ValidationMessages.MUST_BE_NUMBER)
        .transform((value: any, originalValue: any) => (originalValue === "" ? undefined : value))
        .required(ValidationMessages.REQUIRED),
    type: string().required(ValidationMessages.REQUIRED),
});

const FormTitle = styled(H2)`
    margin: 0;
`;
const FormElementsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
const StyledOrderForm = styled.form`
    flex: 0 1 278px !important;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    background-color: ${(p) => p.theme.colors.uiWhite100};
    box-shadow:
        0 1px 2px ${(p) => p.theme.colors.ui4},
        inset 0 -1px 0 ${(p) => p.theme.colors.ui12},
        inset 0 0 0 1px ${(p) => p.theme.colors.ui12};
    border-radius: 12px;
`;
const Error = styled.div`
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: ${(p) => p.theme.colors.negative100};
`;

const getApiMethod = (type: "limit" | "postOnly" | "voiceTrade" | "liquidation") => {
    switch (type) {
        case "limit":
        case "postOnly":
            return "add";
        case "voiceTrade":
            return "voiceTrade";
        case "liquidation":
            return "liquidationTrade";
    }
};

type OrderTypeOption = { text: string; value: FormOrderType };
const LIMIT_OPTION: OrderTypeOption = { text: "Limit", value: "limit" };
const POST_ONLY_OPTION: OrderTypeOption = { text: "Post Only", value: "postOnly" };
const VOICE_TRADE_OPTION: OrderTypeOption = { text: "Voice trade", value: "voiceTrade" };
const LIQUIDATION_OPTION: OrderTypeOption = { text: "Liquidation", value: "liquidation" };
const SUBACCOUNTS_VIEW_ORDER_TYPES: OrderTypeOption[] = [VOICE_TRADE_OPTION, LIQUIDATION_OPTION];
const SUBMAKER_ORDER_TYPES: OrderTypeOption[] = [LIMIT_OPTION, POST_ONLY_OPTION];
const DEFAULT_ORDER_TYPES: OrderTypeOption[] = [LIMIT_OPTION, POST_ONLY_OPTION, VOICE_TRADE_OPTION, LIQUIDATION_OPTION];

export const OrderForm = () => {
    const { instruments } = useInstruments();
    const userType = useUserType();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const {
        register,
        control,
        handleSubmit,
        resetField,
        reset: resetAllFields,
        watch,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm<OrderFormInputs>({
        mode: "onChange",
        resolver: yupResolver(validationSchema),
    });

    const [isManualTradingModalOpened, setIsManualTradingModalOpened] = useState(false);
    const typeFieldValue = watch("type");

    const [apiError, setApiError] = useState<string | null>(null);
    useEffect(() => {
        setApiError(null);
    }, [typeFieldValue]);

    const isManualType = typeFieldValue === "voiceTrade" || typeFieldValue === "liquidation";
    useEffect(() => {
        if (!isManualType) {
            resetField("takerId");
        }
    }, [isManualType]);

    const instrumentOptions = useMemo(
        () => instruments
            .map(({ instrumentName }) => ({ value: instrumentName, text: instrumentName }))
            .sort((a, b) => a.text.localeCompare(b.text)),
        [instruments],
    );

    useEffect(() => {
        resetAllFields();
        setValue("type", isPrimeBrokerUser && primeBrokerViewType === "subaccounts" ? "voiceTrade" : "limit");
    }, [isPrimeBrokerUser, primeBrokerViewType]);

    let typeOptions: { text: string; value: FormOrderType }[] = DEFAULT_ORDER_TYPES;
    if (isPrimeBrokerUser && primeBrokerViewType === "subaccounts") {
        typeOptions = SUBACCOUNTS_VIEW_ORDER_TYPES;
    } else if (userType === "subaccountMaker") {
        typeOptions = SUBMAKER_ORDER_TYPES;
    }

    const takerOptions = useCounterpartiesOptions(
        !isPrimeBrokerUser || primeBrokerViewType === "counterparties" ? "taker" : undefined,
    );

    const clickedButton = useRef<"bid" | "ask">();
    const createOrder = (side: "bid" | "ask") => {
        clickedButton.current = side;
        handleSubmit(async (formValues) => {
            setApiError(null);
            try {
                const { instrument, takerId, clientOrderId, price, size, type } = formValues;
                const apiMethod = getApiMethod(type);
                const requestBody: any = {
                    price: EfxTypes.parseValue(String(price), "price"),
                    size: EfxTypes.parseValue(String(size), "size"),
                    instrument,
                    side,
                };
                if (!isManualType) {
                    requestBody.type = type;
                }
                if (typeof clientOrderId === "number") {
                    requestBody.clientOrderId = EfxTypes.parseValue(String(clientOrderId), "u64");
                }
                if (typeof takerId === "number") {
                    requestBody.counterpartyId = takerId;
                }

                await post(apiMethod, requestBody);

                if (isManualType) {
                    setIsManualTradingModalOpened(true);
                }

                resetAllFields();
            } catch (err) {
                setApiError(String(err));
            }
        })();
    };

    return (
        <>
            <StyledOrderForm>
                <FormTitle>New order</FormTitle>
                <FormElementsContainer>
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <SingleDropdown
                                value={field.value}
                                onChange={field.onChange}
                                renderTrigger={(trigger) => (
                                    <SingleDropdown.Trigger {...trigger} size="large" variant="simple">
                                        <SingleDropdown.TriggerEssence
                                            {...trigger}
                                            option={trigger.selectedOption}
                                            size="large"
                                        />
                                    </SingleDropdown.Trigger>
                                )}
                                options={typeOptions}
                                caption="Type"
                                hint={
                                    isManualType
                                        ? "Order will be immediately executed as a completed trade."
                                        : undefined
                                }
                                fullWidth
                            >
                                <SingleDropdown.BasicSheet size="medium" options={typeOptions} />
                            </SingleDropdown>
                        )}
                        name="type"
                    />
                    {isManualType && (
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <SingleDropdown
                                    value={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <SingleDropdown.Trigger {...trigger} size="large" variant="simple">
                                            <CounterpartyTriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOption}
                                                size="large"
                                            />
                                        </SingleDropdown.Trigger>
                                    )}
                                    options={takerOptions}
                                    error={errors.takerId?.message}
                                    caption={primeBrokerViewType === "counterparties" ? "Taker" : "Sub-account"}
                                    fullWidth
                                >
                                    <CounterpartiesSheet
                                        size="medium"
                                        options={takerOptions}
                                        Dropdown={SingleDropdown}
                                    />
                                </SingleDropdown>
                            )}
                            name="takerId"
                        />
                    )}
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <SingleDropdown
                                value={field.value}
                                onChange={field.onChange}
                                renderTrigger={(trigger) => (
                                    <SingleDropdown.Trigger {...trigger} size="large" variant="simple">
                                        <SingleDropdown.TriggerEssence
                                            {...trigger}
                                            option={trigger.selectedOption}
                                            size="large"
                                        />
                                    </SingleDropdown.Trigger>
                                )}
                                options={instrumentOptions}
                                error={errors.instrument?.message}
                                caption="Instrument"
                                fullWidth
                            >
                                <SingleDropdown.BasicSheet size="medium" options={instrumentOptions} />
                            </SingleDropdown>
                        )}
                        name="instrument"
                    />
                    <SimpleInput label="Price" {...register("price")} error={errors.price?.message} />
                    <SimpleInput label="Size" {...register("size")} error={errors.size?.message} />
                    <SimpleInput
                        label="Client Order ID"
                        {...register("clientOrderId")}
                        error={errors.clientOrderId?.message}
                    />
                    {apiError && <Error>{apiError}</Error>}
                </FormElementsContainer>
                <FormElementsContainer>
                    <BuyButton
                        type="button"
                        size="large"
                        fullWidth
                        disabled={isSubmitting && clickedButton.current === "ask"}
                        loading={isSubmitting && clickedButton.current === "bid"}
                        onClick={() => createOrder("bid")}
                    >
                        {isManualType ? "Buy" : "Bid"}
                    </BuyButton>
                    <DesctructiveButton
                        type="button"
                        size="large"
                        fullWidth
                        disabled={isSubmitting && clickedButton.current === "bid"}
                        loading={isSubmitting && clickedButton.current === "ask"}
                        onClick={() => createOrder("ask")}
                    >
                        {isManualType ? "Sell" : "Ask"}
                    </DesctructiveButton>
                </FormElementsContainer>
            </StyledOrderForm>
            <ManualOrderCreatedModal
                isOpen={isManualTradingModalOpened}
                onClose={() => setIsManualTradingModalOpened(false)}
            />
        </>
    );
};
