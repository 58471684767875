import React, { ReactNode } from "react";
import { Badge } from "../badges";
import { DelayMode } from "../Popover";

const DEFAULT_TOOLTIP = "Client ID";

export const ClientId: React.VFC<{
    id: ReactNode;
    tooltip?: string | null;
    disabled?: boolean;
    isSmall?: boolean;
    className?: string;
}> = ({ id, tooltip = DEFAULT_TOOLTIP, disabled, isSmall = false, className }) => {
    return (
        <Badge
            value={id}
            disabled={disabled}
            tooltip={tooltip}
            delayMode={DelayMode.OnOpen}
            variant={isSmall ? "smallClientId" : "clientId"}
            className={className}
        />
    );
};
