import { BITMASK_UTILS } from "@fm-frontend/utils";
import {
    prepareSettlementRequest,
    SettlementRequest,
    SettlementTransaction,
    Transaction as TransactionHistory,
} from "types";
import { Settlement, SettlementStatus } from "./types";

const { isBitKeyApplied } = BITMASK_UTILS;

export const convertApiTransactionsToSettlements = (
    transactions: (SettlementTransaction | TransactionHistory)[],
    getUsername: (counterpartyId: string | number, type: "short" | "full") => string,
    isActive: boolean,
    type?: "in" | "out",
): Settlement[] =>
    transactions.map((socketTransaction) => {
        const [
            counterpartyId, // (Efx::ClientId)
            currencyName, // (string)
            amount, // (Efx::Size)
            settlementOrderId, // (Efx::OrderId)
            comment, // (string)
            createdAt, // (Efx::Timestamp)
            txId, // (string)
            sentAt, // (Efx::Timestamp)
            transactionFlags, // (Efx::Flags)
            transactionMoment,
            ,
            networkFee, // (unsigned int64)
            network, // asset network (ERC20, tron etc.)
        ] = socketTransaction;

        // calculate correct status
        let status: SettlementStatus = SettlementStatus.cancelled;
        if (!isActive) {
            const statusFlags = transactionFlags & 3;
            status = statusFlags === 0 || statusFlags === 1 ? SettlementStatus.committed : SettlementStatus.cancelled;
        } else {
            if (type === "out") {
                status = sentAt ? SettlementStatus.sent : SettlementStatus.created;
            }
            if (type === "in") {
                status = sentAt ? SettlementStatus.received : SettlementStatus.created;
            }
        }

        let settlementType = type ? type : amount > 0 ? "in" : "out";
        let settlementAmount = BigInt(amount);
        if (status === "cancelled" || status === "committed") {
            settlementType = amount > 0 ? "out" : "in";
        }
        if (
            (settlementType === "out" && settlementAmount > 0) ||
            (settlementType === "in" && settlementAmount < 0)
        ) {
            settlementAmount = -settlementAmount;
        }

        const settlement: Settlement = {
            status,
            type: settlementType,
            cpId: counterpartyId,
            cpName: getUsername(counterpartyId, "full"),
            asset: currencyName,
            network,
            amount: settlementAmount,
            fee: { payer: "sender" },
            comment,
            createdAt: Number(createdAt),
            lastActionAt: Number(transactionMoment || createdAt),
            id: settlementOrderId,
            txId,
            isCreatedByRecipient: isBitKeyApplied(transactionFlags, 1 << 4),
        };

        if (networkFee > 0) {
            settlement.fee = { payer: "recipient", size: networkFee };
        }

        return settlement;
    });

export const convertApiRequestsToSettlements = (
    apiRequests: SettlementRequest[],
    getUsername: (counterpartyId: string | number, type: "short" | "full") => string,
    type: "in" | "out",
): Settlement[] =>
    apiRequests.map((apiRequest) => {
        const {
            counterpartyId,
            currencyName,
            flags,
            amount,
            comment,
            network,
            creationTimestamp,
            id: settlementRequestId,
        } = prepareSettlementRequest(apiRequest);
        const isFeePaidByRecipient = Boolean(flags);

        const settlement: Settlement = {
            status: SettlementStatus.request,
            type,
            cpId: counterpartyId,
            cpName: getUsername(counterpartyId, "full"),
            asset: currencyName,
            network,
            amount: BigInt(amount),
            fee: { payer: isFeePaidByRecipient ? "recipient" : "sender" },
            comment,
            txId: "",
            createdAt: creationTimestamp,
            lastActionAt: creationTimestamp,
            id: settlementRequestId,
        };

        if (settlement.type === "in" && settlement.amount > 0) {
            settlement.amount = -settlement.amount;
        }

        return settlement;
    });

export const getLabelWithHighlightedSearch = (
    input: string | undefined,
    match: string,
    getHighlightedText = (str: string) => <mark>{str}</mark>,
) => {
    if (!input || match === "") {
        return input;
    }

    const normalizedInput = input.toLowerCase();
    const normalizedMatch = match.toLowerCase();
    const parts = normalizedInput.split(normalizedMatch);

    if (parts.length < 2) {
        return input;
    }

    const { result } = parts.reduce(
        (acc, part, partIndex) => {
            const matchPart = (
                <>
                    {acc.result}
                    {input.substring(acc.currentIdx, acc.currentIdx + part.length)}
                    {partIndex !== parts.length - 1 &&
                        getHighlightedText(
                            input.substring(
                                acc.currentIdx + part.length,
                                acc.currentIdx + part.length + match.length,
                            ),
                        )}
                </>
            );

            return { result: matchPart, currentIdx: acc.currentIdx + part.length + match.length };
        },
        { result: <></>, currentIdx: 0 },
    );

    return result;
};
