import {
    Flex,
    Icons,
    PSmall,
} from "@fm-frontend/uikit";
import styled, { useTheme } from "styled-components";
import { BulkResponse } from "../../services/bulkUpdate";
import { BulkUpdateRequest } from "../ConfigurationModal/bulkService";
import { ResultsContainer } from "../../components/ResultsContainer";

const ResultLine = styled(Flex)`
    padding: 4px 8px 8px 8px;
    align-items: center;
    gap: 8px;
`;

type Props = {
    responses: BulkResponse<BulkUpdateRequest>[];
    onUpdate: (response: BulkResponse<BulkUpdateRequest>[]) => void;
    onClose: () => void;
};

export const InstrumentsBulkEditResultModal: React.FC<Props> = ({
    responses,
    onUpdate,
    onClose,
}) => {
    const { colors } = useTheme();

    return (
        <ResultsContainer<BulkUpdateRequest>
            responses={responses}
            onUpdate={onUpdate}
            onClose={onClose}
        >
            {responses.map(({ error, request: { body } }, index) => (
                <ResultLine key={index}>
                    {error !== undefined ? (
                        <Icons.Attention color={colors.negative100} />
                    ) : (
                        <Icons.CheckShape />
                    )}
                    <PSmall>
                        {body.instrument}
                        {error}
                    </PSmall>
                </ResultLine>
            ))}
        </ResultsContainer>
    );
};
