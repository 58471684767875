import { ACTIONS_TABLE_COLUMN_KEY, IconButton, Icons, Tooltip } from "@fm-frontend/uikit";
import { getEnv } from "@fm-frontend/utils";
import { useCreatePrefilledTransactionOrRequest, useOpenConfirmationModal } from "hooks";
import { useMemo, useState } from "react";
import { GroupBy, usePositionsTableData } from "store/usePositionsGrouped";
import styled from "styled-components";
import { CommonPositionsAccordion } from "./CommonPositionsAccordion";
import { PositionAccordionHeader } from "./Header";
import { PositionsAccordionContent } from "./PositionsAccordionContent";
import { columnHelper } from "./PositionsAccordionContent/PositionsTable/table";
import { isSubrow } from "./PositionsAccordionContent/utils";

const ConfirmButton = styled.span`
    color: ${(p) => p.theme.colors.brand100};
`;

const { HRP_MASTER_ID } = getEnv();

export const PositionsAccordion = () => {
    const openConfirmationModal = useOpenConfirmationModal();

    const [groupBy, setGroupBy] = useState<GroupBy>("counterparty");
    const { totalUSD, groupedPositionsData, isLoading } = usePositionsTableData({
        groupBy,
        includeSettlementOrders: true,
    });

    const noPositions = useMemo(
        () => !isLoading && Object.keys(groupedPositionsData).length === 0,
        [isLoading, groupedPositionsData],
    );

    const createPrefilledTransactionOrRequest = useCreatePrefilledTransactionOrRequest();

    const actionColumn = useMemo(
        () =>
            columnHelper.accessor((row) => row, {
                id: ACTIONS_TABLE_COLUMN_KEY,
                header: "",
                cell: (rowData) => {
                    const rowValue = rowData.getValue();

                    if (isSubrow(rowValue)) {
                        const { cpId, asset, value } = rowValue;

                        if (cpId === HRP_MASTER_ID) {
                            return null;
                        }

                        return (
                            <Tooltip content="Settle">
                                <IconButton
                                    variant="plain"
                                    Icon={rowValue.value < 0 ? Icons.ArrowUp : Icons.ArrowReply}
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();

                                        openConfirmationModal({
                                            title: `Leave Trading Page?`,
                                            description: (
                                                <>
                                                    You’re about to navigate to the{" "}
                                                    <strong>Positions Page</strong>. This will take
                                                    you away from the trading page. Do you want to
                                                    continue?
                                                </>
                                            ),
                                            confirmButtonTitle: (
                                                <ConfirmButton>Yes, continue</ConfirmButton>
                                            ),
                                            cancelButtonTitle: "Cancel",
                                            onConfirm: () => {
                                                createPrefilledTransactionOrRequest({
                                                    cpId,
                                                    asset,
                                                    value,
                                                });
                                            },
                                        });
                                    }}
                                />
                            </Tooltip>
                        );
                    }

                    return null;
                },
                meta: {
                    cellStyleProps: {
                        width: "40px",
                        textAlign: "right",
                        padding: "0 8px 0 0",
                    },
                },
            }),
        [createPrefilledTransactionOrRequest, openConfirmationModal],
    );

    const content = (
        <PositionsAccordionContent
            groupBy={groupBy}
            groupedPositionsData={groupedPositionsData}
            isLoading={isLoading}
            actionColumn={actionColumn}
        />
    );

    return (
        <CommonPositionsAccordion
            totalUSD={totalUSD}
            groupedPositionsData={groupedPositionsData}
            isLoading={isLoading}
            content={content}
            header={
                <PositionAccordionHeader
                    groupBy={groupBy}
                    setGroupBy={setGroupBy}
                    noPositions={noPositions}
                />
            }
        />
    );
};
