import { IconButton, Icons, Tooltip } from "@fm-frontend/uikit";
import { useIsOwnerUser } from "hooks";
import React from "react";
import { useAddressContext } from "../../AddressesContext";
import { AccountStatusType } from "../../types";
import { CONFIRMATION_UNAVAILABLE_MESSAGE, EDITING_UNAVAILABLE_MESSAGE } from "../../utils";

export const ActionCell: React.FC<{ status: AccountStatusType }> = ({ status }) => {
    const { side } = useAddressContext();
    const isOwnerUser = useIsOwnerUser();

    if (status === AccountStatusType.NeedToConfirm && side === "shared") {
        return (
            <Tooltip content={isOwnerUser ? undefined : CONFIRMATION_UNAVAILABLE_MESSAGE}>
                <IconButton disabled={!isOwnerUser} variant="primary" Icon={Icons.Checkmark} />
            </Tooltip>
        );
    }
    if ((status === AccountStatusType.Approved || status === AccountStatusType.Verification) && side === "your") {
        return (
            <Tooltip content={isOwnerUser ? undefined : EDITING_UNAVAILABLE_MESSAGE}>
                <IconButton disabled={!isOwnerUser} variant="plain" Icon={Icons.Pencil} />
            </Tooltip>
        );
    }

    return null;
};
