import { post } from "api";
import { EMPTY_ARRAY } from "const";
import {
    groupTradeHistoriesByOrder,
    mapPreparedDealToTradingHistory,
} from "feature/history/trades/utils";
import { TradeHistory } from "feature/history/types";
import { useMemo } from "react";
import useSWR from "swr";
import { PreparedDeal, prepareDeal } from "types";
import { getLastDealsFromParam, LOAD_TRADING_LAST_TRADES_LIMIT } from "../trading/utils";
import { AuthorizedClientId } from "./useAuthorizedClientId";

const getAuthorizedDealHistoryApi = (
    authorizedClientId: number,
    params: {
        limit: number;
        till?: number;
        from?: number;
    },
): Promise<PreparedDeal[]> =>
    post("authorized/dealHistory", {
        clientId: authorizedClientId,
        limit: params.limit,
        till: params.till,
        from: params.from,
    }).then((data) => (Array.isArray(data) ? data.map(prepareDeal) : []));

type SWRKey = string;

export const getAuthorizedLastOrdersSWRKey = (limit: number, cpId?: number | null) => {
    return `getAuthorizedLastOrdersSWRKey-${limit}-${cpId}`;
};

export const useAuthorizedLastOrders = (
    ordersLimit: number,
    authorizedClientId: AuthorizedClientId,
) => {
    const key = getAuthorizedLastOrdersSWRKey(ordersLimit, authorizedClientId);

    const {
        data = [],
        isLoading,
        isValidating,
        mutate,
    } = useSWR<PreparedDeal[], undefined, SWRKey>(
        key,
        async () => {
            if (!authorizedClientId) {
                throw new Error("Authorized client ID is not provided");
            }

            let deals = await getAuthorizedDealHistoryApi(authorizedClientId, {
                limit: LOAD_TRADING_LAST_TRADES_LIMIT,
                from: getLastDealsFromParam(),
            });

            if (deals.length < ordersLimit) {
                return deals;
            }

            const ordersSet = new Set(deals.map((acc) => acc.orderId));

            while (ordersSet.size < ordersLimit) {
                const olderDeals = await getAuthorizedDealHistoryApi(authorizedClientId, {
                    limit: LOAD_TRADING_LAST_TRADES_LIMIT,
                    till: deals[deals.length - 1].dealId,
                    from: getLastDealsFromParam(),
                });

                if (!olderDeals.length) {
                    break;
                }

                deals.forEach((deal) => ordersSet.add(deal.orderId));
                deals = [...deals, ...olderDeals];
            }

            return deals;
        },
        {
            fallbackData: EMPTY_ARRAY as PreparedDeal[],
            revalidateOnMount: true,
            revalidateOnFocus: false,
        },
    );

    const trades: TradeHistory[] = useMemo(() => {
        return groupTradeHistoriesByOrder(data.map(mapPreparedDealToTradingHistory))
            .sort((a, b) => (b.date ?? 0) - (a.date ?? 0))
            .slice(0, ordersLimit);
    }, [data, ordersLimit]);

    return {
        trades,
        isLoading,
        isValidating,
        mutate,
    };
};
