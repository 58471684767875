import { H1, P, ShadowCard } from "@fm-frontend/uikit";
import { useMakersInfo, useSelector } from "hooks";
import { useIsOnboardingRequired } from "store/onboardingSlice";
import styled from "styled-components";
import { Header, Layout } from "../common";
import { Filter } from "./filter/Filter";
import { LiquidityProviders } from "./LiquidityProviders";
import { LiquidityProviderDetails } from "./liquidityProvidersDetails/LiquidityProviderDetails";
import { FilterContextProvider } from "./LiquidityProvidersFilterContext";

export { Providers } from "./Providers";

const Content = styled.div`
    width: 100%;
    max-width: 600px;

    ${ShadowCard} {
        margin-bottom: 12px;
    }
`;

export const LiquidityProvidersFlow = () => {
    const { makersInfo, isLoading } = useMakersInfo();
    const { makerInfoDetailed } = useSelector((state) => state.onboarding);
    const isOnboardingRequired = useIsOnboardingRequired();

    return (
        <Layout>
            <Header>
                <H1>Liquidity providers</H1>
                {isOnboardingRequired && <P>Select the providers you wish to trade with</P>}
            </Header>
            <Content>
                <FilterContextProvider>
                    {makerInfoDetailed ? (
                        <LiquidityProviderDetails makerInfo={makerInfoDetailed} />
                    ) : (
                        <>
                            <Filter />
                            <LiquidityProviders isLoading={isLoading} makersInfo={makersInfo} />
                        </>
                    )}
                </FilterContextProvider>
            </Content>
        </Layout>
    );
};
