import { NOT_ENOUGH_LIQUIDITY } from "../../utils";

export const getPriceToDisplay = ({
    marketPrice,
    waPrice,
}: {
    marketPrice: bigint | null;
    waPrice: bigint | null;
}) => {
    if (waPrice === null) {
        return marketPrice;
    }

    return waPrice === NOT_ENOUGH_LIQUIDITY ? marketPrice : waPrice;
};
