import { FC } from "react";
import { SubaccountCard } from "./SubaccountCard";
import { InviteCard } from "./InviteCard";
import { ListItem, ListItemType } from "../types";
import { useFilterContext } from "../SubaccountsFilterContext";

type CardProps = {
    item: ListItem;
    onClick: (item: ListItem) => void;
};

export const Card: FC<CardProps> = ({ item, onClick }) => {
    const { filters } = useFilterContext();

    switch (item.type) {
        case ListItemType.Subaccount:
            return (
                <SubaccountCard
                    key={item.item.createdAt}
                    searchQuery={filters.searchQuery}
                    subaccount={item.item}
                    onSelect={() => onClick(item)}
                />
            );
        case ListItemType.Invite:
            return (
                <InviteCard
                    key={item.item.email}
                    searchQuery={filters.searchQuery}
                    invite={item.item}
                    onSelect={() => onClick(item)}
                />
            );
        default:
            return null;
    }

};
