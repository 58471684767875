import { Badge } from "@fm-frontend/uikit";
import React from "react";
import { AccountStatusType, CpRevisionHash } from "../../types";

export const BanksStatusCell: React.FC<{ status: AccountStatusType; revisions: CpRevisionHash[] }> = ({
    status,
    revisions = [],
}) => {
    if (status === AccountStatusType.Verification) {
        return <Badge value="Verification" variant="process" />;
    }
    if (status === AccountStatusType.Approved) {
        const revisionHashes = revisions.filter(({ revisionHash }) => revisionHash !== undefined);
        const totalCount = revisions.length;
        const approvedCount = revisionHashes.length;

        if (totalCount === approvedCount) {
            return <Badge value="Approved" variant="positive" />;
        } else {
            return <Badge value={`${approvedCount}/${totalCount} approved`} variant="process" />;
        }
    }
    if (status === AccountStatusType.NeedToConfirm) {
        return <Badge value="Need to confirm" variant="attention" />;
    }

    return null;
};
