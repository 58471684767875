import { flexRender, SortDirection, Table } from "@tanstack/react-table";
import React, { CSSProperties } from "react";
import styled, { css } from "styled-components";
import { getHoverHelper } from "../../helper";
import * as Styled from "../common/styled";
import { EXPAND_TABLE_COLUMN_KEY } from "../TableView";
import { IconContainer, SortIcon } from "./SortIcon";

type SortContainerProps = {
    cellTextAlignment: "left" | "center" | "right" | undefined;
    canSort: boolean;
    sortState: false | SortDirection;
    onClick?: (event: unknown) => void;
    defaultSortIconDirection: SortDirection;
};

const Container = styled.div<{ $canSort: boolean; isSorted: boolean }>`
    display: inline-flex;

    ${({ $canSort }) =>
        $canSort &&
        css`
            cursor: pointer;
            user-select: none;
        `}

    ${(p) =>
        !p.isSorted &&
        css`
            :hover {
                ${IconContainer} {
                    opacity: 0.5;
                }
            }
        `}
`;

const CellContainer = styled.div<{ cellTextAlignment: "left" | "center" | "right" | undefined }>`
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: ${(p) => {
        switch (p.cellTextAlignment) {
            case "right":
                return "flex-end";
            case "center":
                return "center";
            case "left":
            default:
                return "flex-start";
        }
    }};
`;

export const SortContainer: React.FC<SortContainerProps> = ({
    cellTextAlignment,
    canSort,
    sortState,
    onClick,
    children,
    defaultSortIconDirection,
}) => {
    const displaySortIconToLeft = cellTextAlignment === "right" && canSort;
    const displaySortIconToRight =
        canSort && (cellTextAlignment === "left" || cellTextAlignment === undefined);

    return (
        <Container $canSort={canSort} onClick={onClick} isSorted={Boolean(sortState)}>
            {displaySortIconToLeft && (
                <SortIcon
                    defaultDirection={defaultSortIconDirection}
                    sortedState={sortState}
                    paddingPosition="right"
                />
            )}
            {children}
            {displaySortIconToRight && (
                <SortIcon
                    defaultDirection={defaultSortIconDirection}
                    sortedState={sortState}
                    paddingPosition="left"
                />
            )}
        </Container>
    );
};

const SMALL_TABLE_HEADER_CSS: CSSProperties = {
    fontSize: "10px",
    lineHeight: "12px",
    paddingBottom: "4px",
};

export const Thead = <T extends object = {}>({
    table,
    leftStickyCellInsets,
}: {
    table: Table<T>;
    leftStickyCellInsets: number[];
}) => {
    return (
        <Styled.Thead>
            {table.getHeaderGroups().map((headerGroup) => (
                <Styled.Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index) => (
                        <Styled.Th
                            key={header.id}
                            expand={header.column.id === EXPAND_TABLE_COLUMN_KEY}
                            sticky={header.column.columnDef.meta?.sticky}
                            lastStickyColumn={header.column.columnDef.meta?.lastStickyColumn}
                            leftStickyCellInset={
                                leftStickyCellInsets[index] ??
                                leftStickyCellInsets[leftStickyCellInsets.length - 1]
                            }
                            className={
                                header.column.columnDef.meta?.sticky ? "sticky-table-cell" : ""
                            }
                            style={{
                                ...(header.column.columnDef.meta
                                    ?.headerStyleProps as CSSProperties),
                                ...(header.column.columnDef.meta?.headerSmall
                                    ? SMALL_TABLE_HEADER_CSS
                                    : {}),
                            }}
                        >
                            <CellContainer
                                cellTextAlignment={
                                    header.column.columnDef.meta?.headerStyleProps?.textAlign
                                }
                            >
                                <SortContainer
                                    onClick={header.column.getToggleSortingHandler()}
                                    canSort={header.column.getCanSort()}
                                    sortState={header.column.getIsSorted()}
                                    cellTextAlignment={
                                        header.column.columnDef.meta?.headerStyleProps?.textAlign
                                    }
                                    defaultSortIconDirection={header.column.getAutoSortDir()}
                                >
                                    {header.isPlaceholder ? null : (
                                        <span>
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext(),
                                            )}
                                        </span>
                                    )}
                                </SortContainer>
                                {getHoverHelper(
                                    header.column.columnDef.meta?.headerHelper,
                                    header.column.columnDef.meta?.headerHelperOptions,
                                )}
                            </CellContainer>
                        </Styled.Th>
                    ))}
                </Styled.Tr>
            ))}
        </Styled.Thead>
    );
};
