import React from "react";
import { AddressGroup } from "../../types";
import { isAddressGroupType } from "../../utils";

export const GroupCellWrapper = <T extends {}>(props: {
    data: AddressGroup<T> | T;
    renderItemRow?: (row: T) => React.ReactNode;
    renderGroupRow?: (row: AddressGroup<T>) => React.ReactNode;
}) => {
    const { data, renderItemRow = () => null, renderGroupRow = () => null } = props;
    return <>{isAddressGroupType(data) ? renderGroupRow(data) : renderItemRow(data)}</>;
};
