import { useEffect, useRef, useState } from "react";

export const useWidth = ({ label }: { label?: string } = {}) => {
    const ref = useRef<HTMLLabelElement>(null);
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        let newOffset = label ? label.length * 10 : 0;
        const width = ref.current !== null && ref.current?.getBoundingClientRect().width;
        if (width) newOffset = width + 20;
        if (newOffset !== offset) setOffset(newOffset);
    }, [label, ref]);
    return { offset, ref };
};
