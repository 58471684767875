import { CounterpartyStatusType } from "feature/counterparties/utils";
import { useCallback } from "react";
import { useTheme } from "styled-components";

export const useCpStatusHelpers = () => {
    const { colors } = useTheme();

    const getCpStatusColor = useCallback(
        (status: CounterpartyStatusType): string | undefined => {
            switch (status) {
                case CounterpartyStatusType.AWAITING_CP:
                    return colors.process100;
                case CounterpartyStatusType.MARGIN_CALL:
                    return colors.attention100;
                case CounterpartyStatusType.LOW_GROSS_FREE:
                case CounterpartyStatusType.RESTRICTED_TRADING:
                case CounterpartyStatusType.LIQUIDATION:
                    return colors.negative100;
            }
        },
        [colors],
    );

    const getWorstStatusesColor = useCallback(
        (statuses: CounterpartyStatusType[]): string | undefined => {
            let result;
            for (const status of statuses) {
                switch (status) {
                    case CounterpartyStatusType.AWAITING_CP:
                        if (result === undefined) {
                            result = colors.process100;
                        }
                        break;
                    case CounterpartyStatusType.MARGIN_CALL:
                        result = colors.attention100;
                        break;
                    case CounterpartyStatusType.LOW_GROSS_FREE:
                    case CounterpartyStatusType.RESTRICTED_TRADING:
                    case CounterpartyStatusType.LIQUIDATION:
                        return colors.negative100;
                }
            }
            return result;
        },
        [colors],
    );

    return {
        getCpStatusColor,
        getWorstStatusesColor,
    };
};
