import { SIZE_VALUE_MULTIPLICATOR } from "@fm-frontend/utils";
import { TradingSide } from "feature/trade/trading/types";
import { NOT_ENOUGH_LIQUIDITY } from "feature/trade/trading/utils";

export const calculateTotalVolumeOrSize = ({
    sizeOrVolume,
    tradingSide,
    buyPrice,
    sellPrice,
}: {
    sizeOrVolume: bigint | null;
    tradingSide: TradingSide;
    buyPrice: bigint | null;
    sellPrice: bigint | null;
}) => {
    if (sizeOrVolume === null) {
        return null;
    }

    const validPrices = [buyPrice, sellPrice].filter(
        (price) => price !== null && price !== NOT_ENOUGH_LIQUIDITY,
    ) as bigint[];

    if (validPrices.length === 0) {
        return null;
    }

    let midPrice = 0n;

    if (validPrices.length === 1) {
        midPrice = validPrices[0];
    }
    if (validPrices.length === 2) {
        midPrice = (validPrices[0] + validPrices[1]) / 2n;
    }
    if (midPrice === 0n) {
        return null;
    }

    switch (tradingSide) {
        case "volume": {
            return (sizeOrVolume * BigInt(SIZE_VALUE_MULTIPLICATOR)) / midPrice;
        }
        case "size": {
            return (sizeOrVolume * midPrice) / BigInt(SIZE_VALUE_MULTIPLICATOR);
        }
        default:
            return null;
    }
};
