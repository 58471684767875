import { FC, SyntheticEvent } from "react";
import { noop } from "../../const";
import { ActionButton } from "./ActionButton";
import { Actions } from "./styled";
import type { ModalActions } from "./types";

type ContentWithActionsProps = {
    additionalActions?: ModalActions;
    onClose?: () => void;
};

export const ContentWithActions: FC<ContentWithActionsProps> = ({
    children,
    onClose,
    additionalActions: { helpButtonUrlOrClickHandler, onDeleteButtonClick, deleteButtonLoading = false } = {},
}) => {
    const handleHelpButtonClick = helpButtonUrlOrClickHandler
        ? typeof helpButtonUrlOrClickHandler === "string"
            ? () => window.open(helpButtonUrlOrClickHandler, "_blank")!.focus()
            : helpButtonUrlOrClickHandler
        : noop;
    const handleClose = (e: SyntheticEvent) => {
        e.stopPropagation();
        onClose?.();
    };

    return (
        <>
            <Actions>
                {onClose && <ActionButton variant="close" onClick={handleClose} />}
                {helpButtonUrlOrClickHandler && <ActionButton variant="help" onClick={handleHelpButtonClick} />}
                {onDeleteButtonClick && (
                    <ActionButton variant="delete" loading={deleteButtonLoading} onClick={onDeleteButtonClick} />
                )}
            </Actions>
            {children}
        </>
    );
};
