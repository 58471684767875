import { getAssetSignificantDigits, ValueFormat } from "@fm-frontend/utils";
import { useInstruments } from "hooks";

const isBigInt = (num: bigint) => {
    if (typeof num !== "bigint") {
        throw new TypeError(`${num} is not BigInt!`);
    }
};

export const useSize64AssetFormatHelpers = () => {
    const { currencies } = useInstruments();

    return {
        toFormattedDecimalStringByAsset(size64Num: bigint | null, asset: string) {
            if (size64Num === null || currencies[asset] === undefined) {
                return "";
            }

            isBigInt(size64Num);

            const { balanceStep } = currencies[asset];
            const fractionDigits = getAssetSignificantDigits(balanceStep);

            return ValueFormat.price(size64Num, fractionDigits);
        },
        isValidBalanceStep(size64Num: bigint | null, asset: string) {
            if (size64Num === null) {
                return false;
            }
            const { balanceStep } = currencies[asset];
            return !(size64Num % BigInt(balanceStep));
        },
    };
};
