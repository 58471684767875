import { PSmall, PXSmall, VStack } from "@fm-frontend/uikit";
import { LimitInfo } from "feature/assetsControl/types";
import styled from "styled-components";
import { fmt } from "utils/format";
import { PercentBadgeWrapper } from "../PercentBadgeWrapper";

const Container = styled(VStack)`
    padding-left: 12px;
`;

const FreeLimitContainer = styled(PXSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

export const CpLimitCell = ({ limitInfo }: { limitInfo: LimitInfo | undefined }) => {
    if (!limitInfo) {
        return null;
    }

    const { totalLimit, limitAsset, freeLimit, freeLimitPercent, freeLimitUsd, significantDigits } = limitInfo;

    return (
        <PercentBadgeWrapper percent={freeLimitPercent}>
            <Container>
                <PSmall>
                    {
                        fmt(totalLimit, {
                            significantDigits,
                            type: "limit",
                            showZero: true,
                        }).formattedValue
                    }{" "}
                    {limitAsset}
                </PSmall>
                <FreeLimitContainer>
                    Free{" "}
                    {
                        fmt(freeLimit, {
                            type: "limit",
                            significantDigits,
                            showZero: true,
                        }).formattedValue
                    }{" "}
                    {limitAsset}
                    {limitAsset !== "USD"
                        ? `, $${
                              fmt(freeLimitUsd, {
                                  significantDigits: 2,
                                  type: "limit",
                                  showZero: true,
                              }).formattedValue
                          }`
                        : null}
                </FreeLimitContainer>
            </Container>
        </PercentBadgeWrapper>
    );
};
