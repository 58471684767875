import { Item } from "@react-stately/collections";
import { ChangeEvent, HTMLProps, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { post } from "../../api";
import { EmDash } from "../../const";
import { displayError } from "../../utils";
import EfxTypes from "../../utils/EfxTypes";
import { format } from "../../utils/ErrorCodes";
import { FormInputError } from "./style";

export type Type =
    | "string"
    | "stringRequired"
    | "price"
    | "size"
    | "size64"
    | "limit"
    | "u32"
    | "u64"
    | "boolean"
    | "deltarate"
    | "deltaratePercent"
    | "email"
    | "select"
    | "2faCode"
    | "password"
    | "username"
    | "flags";

const AuxillaryTypeFallbackMap = {
    select: "string",
    ["2faCode"]: "u32",
} as const;

export type Option = { text: string | number; value: string };

export type Field = {
    type?: Type;
    title?: string;
    defaultValue?: string;
    disabled?: boolean;
    optional?: boolean;
    preselectedValue?: "empty" | "first";
    placeholder?: string;
    options?: Option[] | Record<string, Option[]>;
    _children?: JSX.Element[] | Option[];
    props?: {
        type?: string;
        autoComplete?: "off" | "new-password" | "current-password";
    };
    transformErrorMap?: Record<string, string>;
    typeValidate?: "onchange" | undefined;
    hidden?: boolean;
    emptyValue?: any;
};

export type FormConfig = {
    fields: Record<string, Field>;
    url?: string;
    disabled?: boolean;
    implicitValues?: Record<string, unknown>;
    onChange?: (a1: { parsedValues: Record<string, unknown>; implicitValues: Record<string, unknown> }) => void;
    onSubmit?: (values: any) => void;
    preSubmit?: (values: any) => any;
    getInitialValues?: () => Record<string, unknown>;
    validate?: (values: Record<string, unknown>, fields: Record<string, Field>) => true | Record<string, string>;
    submit?: typeof post;
    transformError?: (error: string) => string | JSX.Element;
    resetValues?: boolean;
    shouldShowError?: boolean;
};
const defaults = {
    boolean: false,
    string: "",
};

const getFlatOptions = (field: Field) => {
    return field.options && Array.isArray(field.options) ? field.options : Object.values(field.options || {}).flat();
};
const shouldAddEmptyValue = (field: Field) => {
    return field.preselectedValue === "empty" && getFlatOptions(field).length > 1;
};

export function useForm<Form extends FormConfig>({
    fields: originalFields,
    url = "",
    implicitValues = {},
    onChange,
    preSubmit,
    disabled,
    getInitialValues,
    validate,
    submit = post,
    transformError = (input) => input,
    resetValues = true,
    shouldShowError,
    onSubmit: customSubmit,
}: Form) {
    const [globalError, setGlobalError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [values, setValues] = useState<Record<string, unknown>>(getInitialValues || {});
    const [backendErrors, setBackendErrors] = useState<Record<string, unknown>>({});
    const wasChangedRef = useRef(false);

    const fields: Record<string, Field> = useMemo(() => {
        const fieldArray = Object.entries(originalFields).map(([key, field]) => {
            if (field.options) {
                const children = shouldAddEmptyValue(field)
                    ? [{ text: EmDash, value: "" }, ...getFlatOptions(field)]
                    : getFlatOptions(field);
                field._children = children.map(({ text, value }) => <Item key={value}>{text}</Item>);
            }
            return [key, field];
        });
        return Object.fromEntries(fieldArray);
    }, [originalFields]);

    const { errors: valueErrors, parsedValues } = useMemo(() => {
        const errors: Record<string, string> = {};
        const parsedValues: Record<string, unknown> = {};
        for (const name in fields) {
            if (Object.prototype.hasOwnProperty.call(fields, name)) {
                let type = fields[name]?.type || "string";
                let defaultValue;
                defaultValue = defaults[type as keyof typeof defaults];
                if (defaultValue === undefined && Object.prototype.hasOwnProperty.call(fields[name], "defaultValue")) {
                    defaultValue = fields[name].defaultValue;
                }
                let value = values[name] || defaultValue;
                const options = fields[name]?.options;
                if (options) {
                    type = AuxillaryTypeFallbackMap[type as keyof typeof AuxillaryTypeFallbackMap] || type;
                    let optionValue: undefined | { value: unknown } = undefined;
                    if (!value) {
                        if (shouldAddEmptyValue(fields[name])) {
                            optionValue = { value: "" };
                        } else {
                            optionValue =
                                options && Array.isArray(options)
                                    ? options[0]
                                    : Object.values(fields[name].options || {}).flat()[0];
                        }
                        // @ts-ignore undefined type
                        value = optionValue?.value;
                    }
                }
                try {
                    if (value) parsedValues[name] = EfxTypes.parseValue(value, type);
                    if (options && shouldAddEmptyValue(fields[name]) && !parsedValues[name])
                        parsedValues[name] = fields[name].hasOwnProperty("emptyValue") ? fields[name].emptyValue : "";
                } catch (e) {
                    const error = String(e);
                    errors[name] = fields[name]?.transformErrorMap?.[error] || error;
                }
            }
        }
        return { errors, parsedValues };
    }, [values, fields, backendErrors]);
    useEffect(() => {
        if (wasChangedRef.current) {
            onChange?.({ implicitValues, parsedValues });
        }
    }, [parsedValues]);

    useEffect(() => {
        Object.keys(originalFields).forEach((key) => {
            if (originalFields[key]?.defaultValue !== undefined) {
                setValues((values) => ({ ...values, [key]: originalFields[key]?.defaultValue }));
            }
        });
    }, []);
    const errors: Record<string, unknown> = useMemo(
        () => ({ ...valueErrors, ...backendErrors }),
        [backendErrors, valueErrors],
    );
    // const onSubmitPromise: <T extends unknown>(overrides?: {
    //     [key: string]: T;
    // }) => Promise<unknown> = useCallback(
    //     (overrides = {}) =>
    //         new Promise((resolve, reject) => {
    //             if (isSubmitting) return;
    //             setIsSubmitting(true);
    //             setGlobalError("");

    //             let values: any = {
    //                 ...parsedValues,
    //                 ...implicitValues,
    //                 ...overrides,
    //             };
    //             if (preSubmit) values = preSubmit(values);
    //             post(url, values, authParams).then(resolve).catch(reject);
    //         })
    //             .catch((error) => {
    //                 if (typeof error === "object") {
    //                     setBackendErrors(error);
    //                 } else {
    //                     setGlobalError(error);
    //                 }
    //             })
    //             .finally(() => {
    //                 setValues({});
    //                 setIsSubmitting(false);
    //             }),
    //     [
    //         authParams,
    //         isSubmitting,
    //         setIsSubmitting,
    //         setGlobalError,
    //         preSubmit,
    //         post,
    //         setBackendErrors,
    //         setValues,
    //         parsedValues,
    //         implicitValues,
    //     ]
    // );
    const onSubmit = useCallback(
        async (overrides = {}) => {
            if (isSubmitting) return;
            setIsSubmitting(true);
            try {
                let values = {
                    ...parsedValues,
                    ...implicitValues,
                    ...overrides,
                };
                if (preSubmit) {
                    const newValues = await preSubmit(values);
                    values = newValues || values;
                }
                const validationResults = validate?.(values, fields);
                if (validationResults && validationResults !== true) {
                    throw validationResults;
                }
                if (customSubmit) {
                    return customSubmit(values);
                }
                const result = await submit(url, values);
                if (resetValues) setValues({});
                return result;
            } catch (error) {
                if (typeof error === "object") {
                    setBackendErrors(error as Record<string, unknown>);
                } else {
                    setGlobalError(String(error));
                }
                if (shouldShowError) displayError(error);
            } finally {
                setIsSubmitting(false);
            }
        },
        [
            isSubmitting,
            parsedValues,
            implicitValues,
            validate,
            submit,
            resetValues,
            preSubmit,
            fields,
            url,
            setValues,
            shouldShowError,
            customSubmit,
        ],
    );
    const onValidate = useCallback(() => {
        try {
            const validationResults = validate?.(values, fields);
            if (validationResults && validationResults !== true) {
                throw validationResults;
            }
        } catch (error) {
            if (typeof error === "object") {
                setBackendErrors(error as Record<string, unknown>);
            } else {
                setGlobalError(String(error));
            }
            return error;
        }
        return null;
    }, [values, fields, validate]);
    const setFieldValue = useCallback((key: string, value: unknown) => {
        setValues((values) => ({ ...values, [key]: value }));
        setBackendErrors({});
        setGlobalError("");
        wasChangedRef.current = true;
    }, []);
    const inputProps = useMemo(() => {
        const props: Record<string, any> = {};
        const propTypes = [...Object.keys(fields), "submit"];
        for (const name of propTypes) {
            const type = fields[name]?.type || "string";
            const field = fields[name] || {};
            const sharedProps = {
                name,
                label: field.title && `${field.title}${field.optional ? " (opt.)" : ""}`,
                errorValue: errors[name],
                placeholder:
                    field.placeholder && `${field.placeholder}${field.optional && !field.title ? " (opt.)" : ""}`,
                disabled: field.disabled,
                hidden: field.hidden,
                ...field.props,
            };
            const options = field?._children;
            if (options) {
                props[name] = {
                    ...sharedProps,
                    disabled: sharedProps.disabled || options.length === 0,
                    selectedKey: String(parsedValues[name]),
                    onSelectionChange: (value: string) => {
                        setFieldValue(name, value);
                    },
                    children: options,
                    "aria-label": "Select",
                };
            } else {
                switch (type) {
                    case "boolean":
                    case "flags": {
                        props[name] = {
                            ...sharedProps,
                            value: values[name],
                            onChange: (value: boolean) => {
                                setFieldValue(name, value);
                            },
                        };
                        break;
                    }
                    default: {
                        const additionalProps: Partial<HTMLProps<HTMLInputElement>> = {};
                        if (type === "email") {
                            additionalProps.autoComplete = "username";
                            additionalProps.type = "email";
                        }
                        if (type === "password") {
                            additionalProps.type = "password";
                        }
                        props[name] = {
                            ...sharedProps,
                            value: values[name] || "",
                            onChange: (e: ChangeEvent<HTMLInputElement>) => {
                                e.preventDefault?.();
                                const value = e?.currentTarget?.value || "";
                                if (values[name] !== value) {
                                    setFieldValue(name, value);
                                }
                                if (field?.typeValidate === "onchange") {
                                    try {
                                        const result = validate?.({ ...values, [name]: value }, fields);
                                        if (result !== true && result !== undefined) throw result;
                                    } catch (e) {
                                        setBackendErrors(e as Record<string, unknown>);
                                    }
                                }
                            },
                            onBlur: () => {
                                try {
                                    const result = validate?.(values, fields);
                                    if (result !== true && result !== undefined) throw result;
                                } catch (e) {
                                    setBackendErrors(e as Record<string, unknown>);
                                }
                            },
                            ...additionalProps,
                        };
                    }
                }
            }
        }
        return props;
    }, [fields, values, errors, parsedValues, implicitValues]);
    const textError = useMemo(() => {
        if (globalError) {
            return transformError(format(globalError));
        }
        return null;
    }, [globalError, transformError]);
    const error = useMemo(() => {
        if (textError) {
            return <FormInputError isCentered>{textError}</FormInputError>;
        }
        return null;
    }, [textError]);
    const propsFor = useCallback((name: keyof typeof fields | "submit") => inputProps[name] || {}, [inputProps]);
    const setValuesCallback = useCallback(
        (...args: Parameters<typeof setValues>) => {
            const fnOrObject = args[0];
            if (typeof fnOrObject === "function") {
                setValues((oldValues) => ({
                    ...oldValues,
                    ...fnOrObject(oldValues),
                }));
            }
            if (typeof fnOrObject === "object") {
                setValues((oldValues) => ({ ...oldValues, ...fnOrObject }));
            }
        },
        [setValues],
    );
    const areActionsDisabled = useMemo(
        () =>
            disabled ||
            isSubmitting ||
            Object.values(valueErrors).filter(Boolean).length > 0 ||
            Object.values(backendErrors).filter(Boolean).length > 0,
        [disabled, isSubmitting, valueErrors, backendErrors],
    );
    return {
        globalError: error,
        formError: globalError ? format(globalError) : null,
        errors,
        values,
        parsedValues,
        onSubmit,
        // onSubmitPromise,
        propsFor,
        areActionsDisabled,
        setFieldValue,
        setValues: setValuesCallback,
        setGlobalError,
        inputProps,
        isFormDisabled: !!globalError,
        isSubmitting,
        textError,
        onValidate,
    };
}
