import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from "react";

export type Subscription = (msg?: { [key: string]: string | number | any }, snapshot?: any) => void;
export type SubUnsub = (feed: string, fn: Subscription) => void;
export const EventContext = createContext<
    Partial<{
        subscribe: SubUnsub;
        unsubscribe: SubUnsub;
        emit: Subscription;
    }>
>({});

export const useEventEmitter = () => {
    const subscribers = useRef<Record<string, Subscription[]>>({ global: [] });
    const subscribe = useCallback(
        (feed, fn) => {
            const oldSubs = subscribers.current[feed] || [];
            subscribers.current[feed] = [...oldSubs, fn];
        },
        [subscribers],
    );
    const unsubscribe = useCallback(
        (feed, fn) => {
            const oldSubs = subscribers.current[feed] || [];
            subscribers.current[feed] = oldSubs.filter((sb) => sb !== fn);
        },
        [subscribers],
    );
    const emit: Subscription = useCallback(
        (msg, snapshot) => {
            Object.values(subscribers.current).forEach((fns) => {
                fns.forEach((fn) => fn(msg, snapshot));
            });
        },
        [subscribers],
    );
    return useMemo(
        () => ({
            emit,
            subscribe,
            unsubscribe,
        }),
        [emit, subscribe, unsubscribe],
    );
};

export const useEventReceiver = ({ fn, feed }: { fn: Subscription; feed: string }) => {
    const { subscribe, unsubscribe } = useContext(EventContext);
    useEffect(() => {
        subscribe?.(feed, fn);
        return () => unsubscribe?.(feed, fn);
    }, [feed, fn]);
};
