import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { post } from "api";
import { useMemo, useRef } from "react";
import useSWRImmutable from "swr/immutable";
import { Transaction } from "types";
import { displayError } from "utils";
import { stringify } from "utils/json";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType, useSnapshot } from "./hooks";

export enum TypeTransaction {
    Transactions = 0,
    Technical = 1,
}

const feed = "N";

export const useTransactionsHistoryEndpoints = () => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();

    return useMemo(() => {
        const filterParams = {
            filter: isPrimeBrokerUser && primeBrokerViewType === "subaccounts" ? "subaccounts" : "external",
        };

        return {
            [TypeTransaction.Transactions]: {
                apiMethod: "settlementTransactionHistory",
                filterParams,
            },
            [TypeTransaction.Technical]: {
                apiMethod: "settlementHistory",
                filterParams,
            },
        };
    }, [isPrimeBrokerUser, primeBrokerViewType]);
};

export const useHistoricalSettlementTransactions = (
    type: TypeTransaction = TypeTransaction.Transactions,
    feedId?: number,
    limit = 100,
) => {
    const snapshot: any = useSnapshot({ feed, feedId }).data;
    const endpoints = useTransactionsHistoryEndpoints();
    const { apiMethod, filterParams } = endpoints[type];

    const prevSnapshotRef = useRef<typeof snapshot>(null);
    const prevSnapshotIndexRef = useRef<number>(0);

    const cacheKey = useMemo(() => {
        // https://swr.vercel.app/docs/conditional-fetching
        if (snapshot === null) {
            return null;
        }

        const changed = stringify(snapshot) !== stringify(prevSnapshotRef.current);
        prevSnapshotRef.current = snapshot;
        prevSnapshotIndexRef.current += changed ? 1 : 0;

        return [apiMethod, filterParams, prevSnapshotIndexRef.current];
    }, [apiMethod, filterParams, snapshot]);

    const fetcher = () => post(apiMethod, {
        till: snapshot?.[0]?.nextId,
        limit: limit,
        filterTransactions: type === TypeTransaction.Technical,
        ...filterParams,
    });

    const { data = EMPTY_ARRAY as Transaction[], isLoading } = useSWRImmutable<Transaction[]>(
        cacheKey,
        fetcher,
        {
            onError: displayError,
            revalidateOnMount: true,
        },
    );

    return {
        data,
        isLoading,
    };
};
