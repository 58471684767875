import {
    ClientId,
    Flex,
    HStack,
    IconButton,
    Icons,
    InfiniteTable,
    P,
    PBold,
    PSmall,
    Tooltip,
    VStack,
} from "@fm-frontend/uikit";
import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { AssetInfo } from "components/AssetInfo";
import { BottomFixTableContainer } from "components/BottomFixTableContainer";
import { EmDash } from "const";
import { useMemo } from "react";
import { useUsdPrices } from "store/hooks";
import styled, { useTheme } from "styled-components";
import { fmt, getAbsoluteValue, toLongDateString } from "utils/format";
import { TransactionLabel } from "./components/TransactionLabel";
import { Status } from "./Status";
import { Settlement } from "./types";
import { getLabelWithHighlightedSearch } from "./utils";

interface SettlementsTableProps {
    data: Settlement[];
    onRemoveClick: (settlement: Settlement) => void;
    onSendClick: (settlement: Settlement) => void;
    onCommitClick: (settlement: Settlement) => void;
    onCreateTransactionByRequestClick: (requestSettlement: Settlement) => void;
    onRowSelected: (settlement: Settlement) => void;
    isLoading: boolean;
    searchQuery: string;
}

export const CommentCell = styled(P)`
    word-break: break-all;
`;

const FeePayer = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

const TYPE_TITLES = {
    in: "In",
    out: "Out",
};

export const PAYER_TITLES = {
    recipient: "by Recipient",
    sender: "by Sender",
};

export const Amount = ({ amount, asset }: { amount: bigint; asset: string }) => {
    const { priceObj, isLoading } = useUsdPrices();
    const { colors } = useTheme();

    if (amount === 0n) {
        return <P color={colors.ui52}>All available</P>;
    }

    const color = amount < 0 ? colors.negative100 : colors.positive100;

    const usdAmount =
        (BigInt(getAbsoluteValue(amount)) * BigInt(priceObj[asset] ?? 0)) / BigInt(1e8);

    return (
        <VStack>
            <P color={color}>
                {amount > 0 && "+"}
                {
                    fmt(amount, {
                        significantDigits: 8,
                        type: "size64",
                    }).formattedValue
                }
            </P>
            <FeePayer>
                {isLoading
                    ? EmDash
                    : `$${
                          fmt(usdAmount, {
                              significantDigits: 2,
                              type: "size64",
                          }).formattedValue
                      }`}
            </FeePayer>
        </VStack>
    );
};

const columnHelper = createColumnHelper<Settlement>();

export const SettlementsTable = ({
    data,
    onRemoveClick,
    onSendClick,
    onCommitClick,
    onCreateTransactionByRequestClick,
    onRowSelected,
    isLoading,
    searchQuery,
}: SettlementsTableProps) => {
    const columns = useMemo(() => {
        return [
            columnHelper.accessor((row) => row, {
                id: "status",
                cell: (rowData) => {
                    const { status, type } = rowData.getValue();
                    return <Status value={status} type={type} />;
                },
                header: "Status",
                meta: {
                    cellStyleProps: {
                        width: "87px",
                    },
                },
            }),
            columnHelper.accessor((row) => row.type, {
                id: "type",
                cell: (type) => {
                    const value = type.getValue();

                    return TYPE_TITLES[value];
                },
                header: "Type",
                meta: {
                    cellStyleProps: {
                        width: "92px",
                    },
                },
            }),
            columnHelper.accessor((row) => row, {
                id: "name",
                cell: (rowData) => {
                    const { cpId, cpName } = rowData.getValue();

                    return (
                        <HStack spacing={4}>
                            <PBold>{getLabelWithHighlightedSearch(cpName, searchQuery)}</PBold>{" "}
                            <ClientId
                                id={getLabelWithHighlightedSearch(String(cpId), searchQuery)}
                                tooltip="Client ID"
                            />
                        </HStack>
                    );
                },
                header: "Name",
                meta: {
                    cellStyleProps: {
                        width: "200px",
                    },
                },
            }),
            columnHelper.accessor((row) => row, {
                id: "asset",
                cell: (rowData) => {
                    const { asset, network } = rowData.getValue();

                    return (
                        <AssetInfo
                            currency={asset}
                            asset={getLabelWithHighlightedSearch(asset, searchQuery)}
                            network={getLabelWithHighlightedSearch(network, searchQuery)}
                        />
                    );
                },
                header: "Asset",
                meta: {
                    cellStyleProps: {
                        width: "150px",
                    },
                },
            }),
            columnHelper.accessor((row) => row, {
                id: "amount",
                cell: (rowData) => {
                    const { amount, asset } = rowData.getValue();

                    return <Amount amount={amount} asset={asset} />;
                },
                header: "Amount",
                meta: {
                    cellStyleProps: {
                        width: "150px",
                        textAlign: "right",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                    },
                },
            }),
            columnHelper.accessor((row) => row.fee, {
                id: "fee",
                cell: (fee) => {
                    const value = fee.getValue();

                    if (!value) {
                        return null;
                    }

                    return (
                        <VStack>
                            {value.size && (
                                <PSmall>
                                    {
                                        fmt(value.size, {
                                            significantDigits: 8,
                                            type: "size64",
                                        }).formattedValue
                                    }
                                </PSmall>
                            )}
                            <FeePayer>{PAYER_TITLES[value.payer]}</FeePayer>
                        </VStack>
                    );
                },
                header: "Fee",
                meta: {
                    cellStyleProps: {
                        width: "130px",
                        textAlign: "right",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                    },
                },
            }),
            columnHelper.accessor((row) => row.txId, {
                id: "txId",
                cell: (txId) => {
                    const value = txId.getValue();

                    return <TransactionLabel txId={value} />;
                },
                header: "TX ID",
                meta: {
                    cellStyleProps: {
                        width: "110px",
                        textAlign: "right",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                        padding: "0 18px 8px 0",
                    },
                },
            }),
            columnHelper.accessor((row) => row.comment, {
                id: "comment",
                cell: (comment) => (
                    <CommentCell>
                        {getLabelWithHighlightedSearch(comment.getValue(), searchQuery)}
                    </CommentCell>
                ),
                header: "Comment",
                meta: {
                    cellStyleProps: {
                        textAlign: "right",
                        minWidth: "150px",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                    },
                },
            }),
            columnHelper.accessor((row) => row.lastActionAt, {
                id: "lastAction",
                cell: (created) => <P>{toLongDateString(created.getValue())}</P>,
                header: "Last action",
                meta: {
                    cellStyleProps: {
                        width: "180px",
                    },
                },
            }),
            columnHelper.display({
                id: "actions",
                cell: (props) => {
                    const settlement = props.row.original;
                    const hasCommitButton = settlement.status === "received";
                    const hasDeleteButton =
                        (settlement.status === "created" && settlement.type === "out") ||
                        settlement.status === "request";
                    const hasCancelTransactionButton = settlement.status === "sent";
                    const hasSendButton =
                        settlement.status === "created" && settlement.type === "out";
                    const hasCreateTransactionButton =
                        settlement.status === "request" && settlement.type === "in";

                    return (
                        <Flex spacing={8} justifyContent="end">
                            {hasCommitButton && (
                                <Tooltip content="Commit">
                                    <IconButton
                                        variant="primary"
                                        Icon={Icons.Checkmark}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            onCommitClick(settlement);
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {hasSendButton && (
                                <Tooltip content="Send">
                                    <IconButton
                                        variant="primary"
                                        Icon={Icons.ArrowUp}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            onSendClick(settlement);
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {hasCreateTransactionButton && (
                                <Tooltip content="Create transaction">
                                    <IconButton
                                        variant="primary"
                                        Icon={Icons.ArrowReply}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            onCreateTransactionByRequestClick(settlement);
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {hasDeleteButton && (
                                <Tooltip content="Delete">
                                    <IconButton
                                        variant="basic"
                                        Icon={Icons.Bin}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            onRemoveClick(settlement);
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {hasCancelTransactionButton && (
                                <Tooltip content="Cancel">
                                    <IconButton
                                        variant="basic"
                                        Icon={Icons.Bin}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            onRemoveClick(settlement);
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </Flex>
                    );
                },
                meta: {
                    cellStyleProps: {
                        width: "80px",
                        textAlign: "right",
                        padding: "0 12px 0 12px",
                    },
                    headerStyleProps: {
                        textAlign: "right",
                        padding: "0 12px 8px 12px",
                    },
                },
            }),
        ];
    }, [searchQuery]);

    const tableOptions = {
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        enableSorting: false,
        autoResetPageIndex: false,
    };

    return (
        <BottomFixTableContainer>
            <InfiniteTable
                tableOptions={tableOptions}
                onRowClick={({ original }) => onRowSelected(original)}
                isLoading={isLoading}
            />
        </BottomFixTableContainer>
    );
};
