import styled, { css } from "styled-components";

export const ELLIPSIS_CSS = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
export const H1 = styled.h1<{ color?: string }>`
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.005em;
    margin: 0;
    color: ${(p) => p.color ?? null};
`;

export const H2 = styled.h2<{ color?: string }>`
    ${(p) => p.theme.mixins.header2};
    margin: 0;
    color: ${(p) => p.color ?? null};
`;

export const H2Bold = styled(H2)`
    font-weight: 600;
`;

export const H3 = styled.h3<{ color?: string }>`
    ${(p) => p.theme.mixins.header3};
    margin: 0;
    color: ${(p) => p.color ?? null};
`;

export const H3Bold = styled(H3)`
    font-weight: 600;
`;

export const P = styled.p<{
    color?: string;
    textAlign?: string;
    ellipsis?: boolean;
    textWrap?: string;
}>`
    ${(p) => p.theme.mixins.p};

    margin: 0;
    display: inline;

    color: ${(p) => p.color ?? p.theme.colors.ui100};

    text-align: ${(p) => p.textAlign};
    text-wrap: ${(p) => p.textWrap};
    ${(p) => p.ellipsis && ELLIPSIS_CSS}
`;

export const PBold = styled(P)`
    font-weight: 600;
    color: ${(p) => p.color ?? "inherit"};
`;

export const PSmall = styled.span<{ color?: string; ellipsis?: boolean }>`
    ${(p) => p.theme.mixins.pSmall};
    font-size: 12px;
    line-height: 16px;

    color: ${(p) => p.color ?? null};
    ${(p) => p.ellipsis && ELLIPSIS_CSS}
`;

export const PSmallBold = styled(PSmall)`
    font-weight: 600;
`;

export const PXSmall = styled.span<{ color?: string; ellipsis?: boolean }>`
    ${(p) => p.theme.mixins.pXSmall};

    color: ${(p) => p.color ?? null};
    ${(p) => p.ellipsis && ELLIPSIS_CSS}
`;

export const PXSmallBold = styled(PXSmall)`
    font-weight: 600;
`;

export const TextLarge = styled(P)`
    font-size: 16px;
    line-height: 20px;
    opacity: 0.2;
`;

export const TextSmall = styled(P)`
    font-size: 12px;
    line-height: 16px;
`;

export const Currency = styled(P)`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.03em;
`;
