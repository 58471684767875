import React from "react";
import { Dropdown } from "./Dropdown/Dropdown";
import { MultiDropdown } from "./MultiDropdown/MultiDropdown";
import { SpecificDropdownProps, SpecificMultiDropdownProps } from "./types";

/**
 * @deprecated you should use DropdownOption v2 instead
 */
export type { DropdownOption } from "./common/types";

/**
 * @deprecated you should use Dropdown v2 instead
 */
export const BasicDropdown = <TValue,>(props: SpecificDropdownProps<TValue>) => {
    return <Dropdown fullWidth variant="basic" {...props} />;
};

/**
 * @deprecated you should use Dropdown v2 instead
 */
export const MinimumDropdown = <TValue,>(props: SpecificDropdownProps<TValue>) => {
    return <Dropdown fullWidth variant="minimum" {...props} />;
};

/**
 * @deprecated you should use Dropdown v2 instead
 */
export const SimpleDropdown = <TValue,>(props: SpecificDropdownProps<TValue>) => {
    return <Dropdown fullWidth variant="simple" {...props} />;
};

/**
 * @deprecated you should use Dropdown v2 instead
 */
export const InlineDropdown = <TValue,>(props: SpecificDropdownProps<TValue>) => {
    return <Dropdown variant="inline" {...props} />;
};

/**
 * @deprecated you should use MultipleDropdown v2 instead
 */
export const BasicMultiDropdown: React.FC<SpecificMultiDropdownProps> = (props) => {
    return <MultiDropdown variant="basic" {...props} />;
};

/**
 * @deprecated you should use MultipleDropdown v2 instead
 */
export const SimpleMultiDropdown: React.FC<SpecificMultiDropdownProps> = (props) => {
    return <MultiDropdown variant="simple" {...props} />;
};

/**
 * @deprecated you should use MultipleDropdown v2 instead
 */
export const MinimumMultiDropdown: React.FC<SpecificMultiDropdownProps> = (props) => {
    return <MultiDropdown variant="minimum" {...props} />;
};

/**
 * @deprecated you should use MultipleDropdown v2 instead
 */
export const InlineMultiDropdown: React.FC<SpecificMultiDropdownProps> = (props) => {
    return <MultiDropdown variant="inline" {...props} />;
};
