import { POSITION_COUNTERPARTY_ID } from "const/position";
import { isSubaccountCp } from "feature/counterparties/utils";
import { useSelector } from "hooks";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useEffect, useMemo, useState } from "react";
import { useCounterpartyLimitsSnapshot } from "store/hooks";
import { composeUsername } from "utils";

export const useSubaccountsOptions = () => {
    const { getCpType } = useCpInfoHelpers();
    const { data: counterpartiesLimits } = useCounterpartyLimitsSnapshot();
    const allAvailableCounterpartiesInfo = useSelector((state) => state.app.allAvailableCounterpartiesInfo);
    const counterparties = useMemo(
        () => counterpartiesLimits?.map((limit) => String(limit[POSITION_COUNTERPARTY_ID])) ?? [],
        [counterpartiesLimits],
    );
    const [userSubaccountsOptions, setUserSubaccountsOptions] = useState<any>([]);
    useEffect(() => {
        const cpIds = counterparties.map(Number);
        const subaccountIds = cpIds.filter((cpId) => isSubaccountCp(getCpType(cpId)));
        setUserSubaccountsOptions(
            subaccountIds
                .map((value: any) => {
                    const username = allAvailableCounterpartiesInfo[value]?.username;
                    return {
                        text: composeUsername(username),
                        value,
                    };
                })
                .sort((a, b) => a.text.localeCompare(b.text)),
        );
    }, [counterparties, allAvailableCounterpartiesInfo, getCpType]);

    return userSubaccountsOptions;
};
