import { BankAddress } from "feature/addresses/types";
import { useState } from "react";
import { BankAccount } from "./BankAccount";

export const BankAccounts = ({ bankAccounts }: { bankAccounts: BankAddress[] }) => {
    const [openedAccountId, setOpenedAccountId] = useState<number | null>(null);

    return (
        <>
            {bankAccounts.map((account, i) => (
                <BankAccount
                    key={account.accountId}
                    bankAccount={account}
                    number={i + 1}
                    isOpened={openedAccountId === account.accountId}
                    onClick={
                        openedAccountId === account.accountId
                            ? () => setOpenedAccountId(null)
                            : () => setOpenedAccountId(account.accountId ?? null)
                    }
                />
            ))}
        </>
    );
};
