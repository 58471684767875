import { Icons, PSmall, PSmallBold } from "@fm-frontend/uikit";
import { useInstrumentSelectorContext } from "components/InstrumentSelectorContext/useInstrumentSelectorContext";
import styled, { useTheme } from "styled-components";
import { Banner as BasicBanner } from "./styled";

const Banner = styled(BasicBanner)`
    bottom: 50px;
    cursor: pointer;
`;

export const NoInstrumentBanner = () => {
    const { colors } = useTheme();
    const { focusInstrumentSelect } = useInstrumentSelectorContext();

    return (
        <Banner onClick={focusInstrumentSelect}>
            <Icons.Attention color={colors.ui72} />
            <PSmallBold color={colors.ui72}>Select an instrument</PSmallBold>
            <PSmall color={colors.ui72}>to display asset-specific information</PSmall>
        </Banner>
    );
};
