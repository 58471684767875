import { createContext } from "react";
import { TradingAsset } from "types";

export const SelectedInstrumentContext = createContext<
    Partial<
        TradingAsset & {
            instrument: string;
        }
    >
>({});
