import { HStack, Icons, TextSmall } from "@fm-frontend/uikit";
import React from "react";
import { useTheme } from "styled-components";

export const ErrorLine: React.FC<{ error: string | undefined }> = ({ error }) => {
    const { colors } = useTheme();

    if (!error) {
        return null;
    }

    return (
        <HStack spacing={4} width="100%">
            <Icons.Attention color={colors.negative100} />
            <TextSmall color={colors.ui72}>{error}</TextSmall>
        </HStack>
    );
};
