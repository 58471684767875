import { createAction } from "@reduxjs/toolkit";

export const startUserOnboarding = createAction("startUserOnboarding");
export const stopUserOnboarding = createAction("stopUserOnboarding");

export const fetchStepsKYB = createAction("fetchStepsKYB", (clientId: number) => ({
    payload: clientId,
}));

export const fetchStatusKYB = createAction("fetchStatusKYB", (clientIds: number[]) => ({
    payload: clientIds,
}));

export const fetchOnboardingBanks = createAction("fetchOnboardingBanks");
export const fetchOnboardingCurrencies = createAction("fetchOnboardingCurrencies");
export const fetchOnboardingJurisdictions = createAction("fetchOnboardingJurisdictions");

export const resetPostOnboardingStages = createAction("resetPostOnboardingStages");
export const startVerification = createAction("startVerification", (clientId: number) => ({
    payload: clientId,
}));
export const cancelVerification = createAction("cancelVerification", (clientId: number) => ({
    payload: clientId,
}));

export const agreementNotSigned = createAction("agreementNotSigned");
