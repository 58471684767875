import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 12px 0;
`;

export const Description = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.005em;
    color: ${(p) => p.theme.colors.textBody1};
`;

export const DescriptionContainer = styled.div`
    display: flex;
    justify-items: space-between;
    align-items: center;
    gap: 16px;

    & > ${Description} {
        flex: 1;
    }
`;
