import { AssetsBulkPreparedDataType } from "./utils";

type UpdateShortSalesBanRequestBody = {
    counterpartyId: number;
    currency: string;
    shortSales: boolean;
};
type UpdateOvernightsRequestBody = {
    counterpartyId: number;
    currency: string;
    positiveRate: number | undefined;
    negativeRate: number | undefined;
};
type UpdateAssetLimitRequestBody = {
    counterpartyId: number;
    asset: string;
    grossLimit?: bigint;
    limitCurrency?: string | null;
};

export type AssetsBulkEditRequestBodyType =
    | UpdateOvernightsRequestBody
    | UpdateAssetLimitRequestBody
    | UpdateShortSalesBanRequestBody;

export type BulkUpdateRequest = {
    apiMethod: string;
    body: AssetsBulkEditRequestBodyType;
};

export const mapBulkDataToShortSalesRequest = (
    bulkDataItem: AssetsBulkPreparedDataType,
): BulkUpdateRequest => {
    return {
        body: {
            shortSales: bulkDataItem.shortSales,
            counterpartyId: bulkDataItem.cpId,
            currency: bulkDataItem.asset,
        },
        apiMethod: bulkDataItem.shortSales ? "delShortSalesBan" : "setShortSalesBan",
    };
};

export const mapBulkDataToOvernightsRequest = (
    bulkDataItem: AssetsBulkPreparedDataType,
): BulkUpdateRequest => {
    return {
        body: {
            positiveRate: bulkDataItem.overnightsLong,
            negativeRate: bulkDataItem.overnightsShort,
            counterpartyId: bulkDataItem.cpId,
            currency: bulkDataItem.asset,
        },
        apiMethod: "settings/setOvernightRates",
    };
};

export const mapBulkDataToAssetLimitRequest = (
    bulkDataItem: AssetsBulkPreparedDataType,
): BulkUpdateRequest => {
    return {
        body: {
            counterpartyId: bulkDataItem.cpId,
            asset: bulkDataItem.asset,
            grossLimit: bulkDataItem.limitAmount,
            limitCurrency: bulkDataItem.limitCurrency,
        },
        apiMethod: bulkDataItem.limitAmount === undefined ? "delCAssetLimit" : "setCAssetLimit",
    };
};
