import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useMemo } from "react";
import { SettlementTransactions, useFilteredAndExtendedSettlementTransactionsSnapshot } from "store/hooks";
import { TypeTransaction, useHistoricalSettlementTransactions } from "store/useHistoricalSettlementTransactions";
import { convertApiTransactions } from "./utils";
import { Transaction } from "./types";
import { SubaccountTabs } from "./const";

export const isActionable = ({ status }: Partial<Transaction>) => (status === "created") || (status === "received");

export const useFilteredTransactions = (isSubaccountUser = false) => {
    const { data: filteredAndExtendedSettlementTransactions, isLoading: isWsTransactionsLoading } =
        useFilteredAndExtendedSettlementTransactionsSnapshot();
    const filteredSettlementTransactions = useMemo(
        () =>
            [
                filteredAndExtendedSettlementTransactions[0],
                ...[filteredAndExtendedSettlementTransactions[1], filteredAndExtendedSettlementTransactions[2]].map(
                    (extendedSettlementTransactions) =>
                        extendedSettlementTransactions.map(({ settlementTransaction }) => settlementTransaction),
                ),
            ] as SettlementTransactions,
        [filteredAndExtendedSettlementTransactions],
    );
    const { getCpName } = useCpInfoHelpers();
    const socketDeposits = convertApiTransactions(
        filteredSettlementTransactions[1],
        getCpName,
        "active",
        isSubaccountUser,
        "toYou",
    );
    const socketWithdrawals = convertApiTransactions(
        filteredSettlementTransactions[2],
        getCpName,
        "active",
        isSubaccountUser,
        "fromYou",
    );
    const transactionsHistory = useHistoricalSettlementTransactions(TypeTransaction.Transactions);
    const transactions = [
        ...socketDeposits,
        ...socketWithdrawals,
        ...convertApiTransactions(transactionsHistory.data, getCpName, "finished", isSubaccountUser),
    ].sort((t1, t2) => t2.created - t1.created);

    return {
        [SubaccountTabs.ALL]: transactions,
        [SubaccountTabs.ACTIONABLE]: transactions.filter(isActionable),
        [SubaccountTabs.DEPOSITS]: transactions.filter(({ type }) => type === "deposit"),
        [SubaccountTabs.WITHDRAWALS]: transactions.filter(({ type }) => type === "withdrawal"),
        isLoading: isWsTransactionsLoading || transactionsHistory.isLoading,
    };
};
