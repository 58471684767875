import { FormBody } from "@fm-frontend/uikit";
import styled from "styled-components";

export const Error = styled.div`
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: ${(p) => p.theme.colors.negative100};
`;

export const StyledFormBody = styled(FormBody)`
    position: relative;
`;
