import { Header, HeaderTitle } from "@fm-frontend/uikit";
import { Settlement } from "../../../../types";
import { Status } from "./Status";

type ModalHeaderProps = {
    settlement: Settlement;
};

const getTitle = (settlement: Settlement) => {
    const direction = settlement.type === "in" ? "Incoming" : "Outgoing";
    const type = settlement.status === "request" ? "request" : "transaction";

    return `${direction} ${type}`;
};

export const ModalHeader = ({ settlement }: ModalHeaderProps) => (
    <Header>
        <HeaderTitle title={getTitle(settlement)} />
        <Status settlement={settlement} />
    </Header>
);
