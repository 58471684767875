import { useSettings } from "./useSettings";

export const useFaqLinks = () => {
    const settings = useSettings();

    if (!settings.faq_link) {
        return undefined;
    }

    return {
        faq: settings.faq_link,
        faqRiskManagement: `${settings.faq_link}/features/risk-management`,
        faqBulkEdit: `${settings.faq_link}/features/bulk-edit`,
        faqSubs: `${settings.faq_link}/features/fm-liquidity-match/master-account`,
    };
};
