import { HStack, P } from "@fm-frontend/uikit";
import { DropdownOption, SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { useHandleLogout } from "hooks/useHandleLogout";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useIsSubaccountUser } from "store/hooks";
import { ActionCustomOption, CustomOption, LogoutIcon, ProfileDropdownTrigger } from "./components";

const getPathNavigationOptions = ({
    isSubaccountUser,
}: {
    isSubaccountUser: boolean;
}): DropdownOption[] => {
    return [
        { text: "Account data", value: "/settings/profile" },
        { text: "Users and roles", value: "/settings/usersmanagement" },
        ...(!isSubaccountUser ? [{ text: "Referrals", value: "/settings/referrals" }] : []),
        { text: "Login and security", value: "/settings/security" },
        { text: "Notifications", value: "/settings/notifications" },
    ];
};
const actionsOptions: DropdownOption<string, { icon?: React.ReactNode }>[] = [
    { text: "Logout", value: "logout", icon: <LogoutIcon /> },
];

export const ProfileDropdown = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isSubaccountUser = useIsSubaccountUser();
    const onLogout = useHandleLogout();

    const pathNavigationOptions = useMemo(
        () =>
            getPathNavigationOptions({
                isSubaccountUser,
            }),
        [isSubaccountUser],
    );

    const onSelectionChange = useCallback(
        (key) => {
            switch (key) {
                case "/settings/profile":
                case "/settings/usersmanagement":
                case "/settings/referrals":
                case "/settings/security":
                case "/settings/notifications": {
                    history.push(key);
                    break;
                }
                case "logout": {
                    onLogout();
                    break;
                }
            }
        },
        [dispatch, onLogout, history],
    );

    return (
        <SingleDropdown
            value=""
            onChange={onSelectionChange}
            renderTrigger={(trigger) => <ProfileDropdownTrigger {...trigger} />}
            options={[...pathNavigationOptions, ...actionsOptions]}
            align="center"
        >
            <SingleDropdown.Sheet size="small">
                <SingleDropdown.SheetGroup>
                    {pathNavigationOptions.map((option) => (
                        <CustomOption key={`${option.value}`} value={option.value}>
                            <SingleDropdown.OptionEssence option={option} />
                        </CustomOption>
                    ))}
                    <SingleDropdown.Divider />
                    {actionsOptions.map((option) => (
                        <ActionCustomOption key={`${option.value}`} value={option.value}>
                            <HStack flex={1} alignItems="center" justifyContent="space-between">
                                <P>{option.text}</P>
                                {option.icon}
                            </HStack>
                        </ActionCustomOption>
                    ))}
                </SingleDropdown.SheetGroup>
            </SingleDropdown.Sheet>
        </SingleDropdown>
    );
};
