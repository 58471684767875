import styled from "styled-components";

const ListItem = styled.li`
    display: flex;
    justify-content: center;
`;
const Line = styled.div`
    width: 87%;
    height: 1px;
    background-color: #ebe9e9;
    border-radius: 1px;
`;

export const ListSectionSeparator = () => {
    return (
        <ListItem>
            <Line />
        </ListItem>
    );
};
