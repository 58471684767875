import {
    CheckboxField,
    Form,
    FormActions,
    FormBody,
    Header as FormHeader,
    HeaderTitle,
    HStack,
    Icons,
    PrimaryButton,
    ShadowCard,
    SimpleInput,
    Tooltip,
} from "@fm-frontend/uikit";
import { yupResolver } from "@hookform/resolvers/yup";
import { post } from "api";
import { FieldGrouper } from "feature/counterparties/styled";
import FormTextArea from "feature/form/FormTextArea";
import { useEffect, useState, VFC } from "react";
import { useForm } from "react-hook-form";
import { useUserPrimeBrokerId } from "store/hooks";
import styled from "styled-components";
import { getCurrencyPlaceholder } from "utils";
import EfxTypes from "utils/EfxTypes";
import { parse, stringify } from "utils/json";
import { ValidationMessages } from "utils/validationMessages";
import { AnySchema, boolean, object, string } from "yup";
import { AssetAndNetworkSelector } from "../AssetAndNetworkSelector";
import { Error } from "../styled";
import { getAmountSchema } from "../yupValidators";

const StyledFormTextArea = styled(FormTextArea)`
    font-size: 12px;
    &::placeholder {
        color: ${(p) => p.theme.colors.ui72};
    }
`;

const CreateWithdrawalForm = styled(Form)`
    min-width: 278px;
    max-width: 278px;

    ${FormBody} ${StyledFormTextArea} {
        width: 100%;
    }
`;

type SubaccountWithdrawalRequest = {
    asset: string;
    hasNetworks: boolean;
    network?: string;
    shouldSpecifyAmount: boolean;
    amount: string;
    isFeePaidByMe: boolean;
    comment: string;
};

type DefaultRequestValues = {
    asset: string;
    amount: string;
    comment?: string;
};
const DEFAULT_REQUEST_SESSION_STORAGE_KEY = "subaccountRequestsPageDefaultRequestState";
export const setSubaccountDefaultRequestState = (values: DefaultRequestValues) => {
    sessionStorage.setItem(DEFAULT_REQUEST_SESSION_STORAGE_KEY, stringify(values));
};
const clearSubaccountDefaultRequestState = () => {
    sessionStorage.removeItem(DEFAULT_REQUEST_SESSION_STORAGE_KEY);
};
const getSubaccountDefaultRequestState = (): DefaultRequestValues => {
    const preloadedFormValues = sessionStorage.getItem(DEFAULT_REQUEST_SESSION_STORAGE_KEY);
    return preloadedFormValues ? parse(preloadedFormValues) : undefined;
};

const schema = object<Record<keyof SubaccountWithdrawalRequest, AnySchema>>().shape({
    asset: string().required(ValidationMessages.REQUIRED),
    shouldSpecifyAmount: boolean(),
    amount: string().when(["shouldSpecifyAmount"], {
        is: (shouldSpecifyAmount: boolean) => shouldSpecifyAmount,
        then: (schema) => getAmountSchema(schema),
        otherwise: (schema) => schema.optional(),
    }),
    isFeePaidByMe: boolean(),
    hasNetworks: boolean().required(),
    network: string().when("hasNetworks", {
        is: (hasNetworks: boolean) => hasNetworks,
        then: (schema) => schema.required(ValidationMessages.REQUIRED),
        otherwise: (schema) => schema.optional(),
    }),
});

export const AddNewWithdrawalRequestForm: VFC = () => {
    const [isAssetAndNetworkReady, setIsAssetAndNetworkReady] = useState(false);
    const primeBrokerId = useUserPrimeBrokerId();
    const defaultRequestState = getSubaccountDefaultRequestState();
    const {
        control,
        formState: { isSubmitting, errors },
        handleSubmit,
        register,
        watch,
        reset,
        setValue,
    } = useForm<SubaccountWithdrawalRequest>({
        mode: "onSubmit",
        defaultValues: {
            shouldSpecifyAmount: false,
            comment: "",
            hasNetworks: false,
            ...(Boolean(defaultRequestState)
                ? {
                      shouldSpecifyAmount: true,
                      ...defaultRequestState,
                  }
                : {}),
        },
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        clearSubaccountDefaultRequestState();
    }, []);

    const asset = watch("asset");
    const shouldSpecifyAmount = watch("shouldSpecifyAmount");
    const [apiError, setApiError] = useState<string | null>(null);
    const onSubmit = async (formData: SubaccountWithdrawalRequest) => {
        setApiError(null);
        try {
            const requestBody: any = {
                amount: formData.amount
                    ? EfxTypes.parseValue(String(formData.amount), "size64")
                    : null,
                currency: formData.asset,
                comment: formData.comment,
                counterpartyId: primeBrokerId,
                flags: Number(formData.isFeePaidByMe),
                cancelTimestamp: Date.now() + 7 * 24 * 60 * 60 * 1000,
            };
            if (formData.network) {
                requestBody.network = formData.network;
            }
            await post("addIncomingSettlementRequest", requestBody);
            reset();
        } catch (err: unknown) {
            setApiError(err as string);
        }
    };

    return (
        <CreateWithdrawalForm onSubmit={handleSubmit(onSubmit)}>
            <ShadowCard>
                <FormHeader>
                    <HeaderTitle title="Add new" />
                </FormHeader>
                <FormBody spacing={8}>
                    <AssetAndNetworkSelector
                        control={control}
                        setValue={setValue}
                        assetError={errors.asset?.message}
                        networkError={errors.network?.message}
                        onReady={setIsAssetAndNetworkReady}
                    />
                    <FieldGrouper isExpanded={shouldSpecifyAmount} fullWidth>
                        <CheckboxField
                            name="shouldSpecifyAmount"
                            label={
                                <HStack spacing={4}>
                                    Specify amount{" "}
                                    <Tooltip content="If you want to withdraw a specific amount, please indicate it.">
                                        <Icons.Help />
                                    </Tooltip>
                                </HStack>
                            }
                            size="large"
                            fullWidth
                            control={control}
                        />
                        {shouldSpecifyAmount && (
                            <SimpleInput
                                label="Amount"
                                placeholder={getCurrencyPlaceholder(asset)}
                                {...register("amount")}
                                error={errors.amount?.message}
                            />
                        )}
                    </FieldGrouper>
                    <CheckboxField
                        name="isFeePaidByMe"
                        label={
                            <HStack spacing={4}>
                                Fee is paid by me{" "}
                                <Tooltip content="This option allows you to request a transaction for which you will pay the fee — the CP will send a requested amount minus the network fee.">
                                    <Icons.Help />
                                </Tooltip>
                            </HStack>
                        }
                        size="large"
                        fullWidth
                        control={control}
                    />
                    <StyledFormTextArea
                        {...register("comment")}
                        fullWidth
                        placeholder="Comment"
                        errorValue={errors.comment?.message}
                    />
                    {apiError && <Error>{apiError}</Error>}
                </FormBody>
                <FormActions variant="plain">
                    <PrimaryButton
                        type="submit"
                        fullWidth
                        size="large"
                        disabled={!isAssetAndNetworkReady}
                        loading={isSubmitting}
                    >
                        Request withdrawal
                    </PrimaryButton>
                </FormActions>
            </ShadowCard>
        </CreateWithdrawalForm>
    );
};
