import { H2Bold, HStack, Skeleton, VStack } from "@fm-frontend/uikit";
import { post } from "api";
import { ListItem } from "components/ListItem";
import { TnCPrint } from "components/TnCPrint";
import { TopMenu } from "components/TopMenu";
import { TermsAndConditionsContent } from "feature/onboarding/businessAndLegal";
import { useClientData } from "hooks/useClientData";
import { useMemo } from "react";
import { Page, PanelBottom, Sidebar, SignParams, Title } from "style";
import useSWR from "swr";
import { formatDate } from "utils/format";

export interface SignatoryData {
    signatory: string;
    signatoryPosition: string;
    signedAt: number;
    agreementNr: string;
}

export const useSignatoryData = () => {
    const fetcher = (key: string) => post(key, {});
    const { data: signatoryData, isValidating } = useSWR<SignatoryData>("getSignatoryData", fetcher, {
        revalidateOnFocus: false,
    });

    return {
        signatoryData,
        isLoading: isValidating,
    };
};

const AgreementSkeleton = () => {
    return (
        <VStack spacing={12}>
            <HStack justifyContent="space-between">
                <Skeleton width="170px" />
                <Skeleton width="135px" />
            </HStack>
            {[...Array(10).keys()].map((key) => (
                <Skeleton key={key} height="16px" />
            ))}
        </VStack>
    );
};

export const Agreement = () => {
    const { clientData, isLoading } = useClientData();
    const { signatoryData } = useSignatoryData();

    const additionalInfo = useMemo(
        () => (
            <>
                <hr />
                <PanelBottom>
                    <div>
                        <Title>These T&C were accepted by:</Title>
                        <SignParams>
                            <ListItem title="Signatory full name" value={signatoryData?.signatory ?? ""} />
                            <ListItem title="Position" value={signatoryData?.signatoryPosition ?? ""} />
                            <ListItem
                                title="Execution date"
                                value={signatoryData?.signedAt ? formatDate(signatoryData.signedAt * 1e3) : ""}
                            />
                        </SignParams>
                    </div>
                </PanelBottom>
            </>
        ),
        [signatoryData],
    );

    return (
        <Page>
            <TopMenu>Legal</TopMenu>
            <Sidebar>
                <VStack asCard padding={12} minHeight="200px" maxWidth="820px">
                    {isLoading ? (
                        <AgreementSkeleton />
                    ) : clientData ? (
                        <VStack>
                            <HStack paddingBottom={16} spacing={12} justifyContent="space-between">
                                <H2Bold>Terms & Conditions</H2Bold>
                                <TnCPrint>
                                    <TermsAndConditionsContent {...clientData} />
                                    {additionalInfo}
                                </TnCPrint>
                            </HStack>
                            <TermsAndConditionsContent {...clientData} />
                            {additionalInfo}
                        </VStack>
                    ) : null}
                </VStack>
            </Sidebar>
        </Page>
    );
};
