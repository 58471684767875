import { H2 } from "@fm-frontend/uikit";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
`;

const Title = styled(H2)`
  text-align: center;
`

type CardStackProps = {
    title?: ReactNode;
}

export const CardStack: FC<CardStackProps> = ({ title, children }) => (
    <Container>
        {title !== undefined && <Title>{title}</Title>}
        {children}
    </Container>
);
