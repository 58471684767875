import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { memo, useMemo } from "react";
import { CSSProperties, DefaultTheme } from "styled-components";
import { CopyToClipboard } from "../../components/CopyToClipboard";
import { Tooltip } from "../../components/Tooltip";
import { ColoredText } from "../../style";
import { fmt, FormatConfig } from "../../utils/format";

export type SimpleValue = string | number | bigint;
export type CellComponent = React.FC<{ value?: SimpleValue | null }>;

// FIXME any
export type CellProps<Row = any> = {
    value: SimpleValue;
    style: CSSProperties;
    row: { id: string; depth: number; canExpand?: boolean; original: any };
    rowsById: Record<string, any>;
    column: {
        Cell: CellComponent;
        id: string;
        Header: string;
        truncateAt?: "middle" | "end";
        format?: FormatConfig;
        accessor: (data: Row[]) => SimpleValue;
        hideInSubTree?: boolean;
        hideInRoot?: boolean;
        headerStyle: Partial<CSSProperties>;
        cellStyle: Partial<CSSProperties>;
        canExpandMixin: Partial<CSSProperties>;
        getColor?: (value: SimpleValue, data: Row) => keyof DefaultTheme["colors"];
    };
};

export const createCellRenderer = (config?: FormatConfig) => {
    const Cell: React.FC<CellProps> = ({ style, row, column, value }) => {
        const props = useMemo(() => {
            return fmt(value, config);
        }, [value]);

        const textValue = useMemo(() => {
            return props.value || "";
        }, [props]);

        const tooltipValue = useMemo(() => {
            if (!column.truncateAt || typeof value !== "string") return undefined;
            if (value.length > 10) {
                return column.truncateAt === "middle"
                    ? `${value.substring(0, 5)}…${value.substring(value.length - 5, value.length)}`
                    : `${value.substring(0, 9)}…`;
            }
        }, [value]);

        const defaultColor = useMemo(() => {
            return column.getColor?.(value, row.original);
        }, [value]);

        if ((column.hideInSubTree && row.depth > 0) || (row.canExpand && column.hideInRoot && row.depth === 0)) {
            return null;
        }

        if (tooltipValue) {
            return (
                <CopyToClipboard value={value}>
                    <Tooltip text={tooltipValue && value}>{tooltipValue || value || "—"}</Tooltip>
                </CopyToClipboard>
            );
        }

        return (
            <CopyToClipboard value={props.copyableValue || textValue}>
                <ColoredText
                    isNegative={props.isNegative}
                    defaultColor={defaultColor}
                    style={{
                        ...style,
                        ...(row.canExpand && column.canExpandMixin),
                    }}
                >
                    {props.formattedValue || textValue}
                </ColoredText>
            </CopyToClipboard>
        );
    };
    return Cell;
};

export const CellIdentity: CellComponent = ({ value }) => <span>{value}</span>;

export const CellCounterparty: CellComponent = memo(({ value }) => {
    const { getCpName } = useCpInfoHelpers();
    const username = getCpName(Number(value), "full");

    return (
        <>
            {username} ({value})
        </>
    );
});
