import { H2, P, PlainButton, PrimaryButton } from "@fm-frontend/uikit";
import { useTextAdaptor } from "hooks/useTextAdaptor";
import React from "react";
import styled, { useTheme } from "styled-components";
import { useAddressContext } from "../AddressesContext";
import { AddressType } from "../types";
import { AddressBox } from "./AddressBox";
import { InfoBadge } from "./InfoBadge";

const Form = styled.form`
    height: 100%;
`;

type CryptoLightBoxContentProps = {
    apiErrorsCount: number;
    totalAddressesCount: number;
    isSubmitting: boolean;
    isDisabled: boolean;
    onSubmit: () => void;
    onCancel: () => void;
};
export const AddressLightBox: React.FC<CryptoLightBoxContentProps> = ({
    apiErrorsCount,
    totalAddressesCount,
    onSubmit,
    isSubmitting,
    isDisabled,
    onCancel,
    children,
}) => {
    const { colors } = useTheme();
    const { type } = useAddressContext();

    const textAdaptor = useTextAdaptor();

    const ADDRESS_BOX_TEXT: Record<AddressType, { title: string; description: string }> = {
        crypto: {
            title: "Add & edit your crypto wallets",
            description: textAdaptor("cryptoAddressBoxDescription") ?? "",
        },
        bank: {
            title: "Add & edit bank accounts",
            description: textAdaptor("bankAddressBoxDescription") ?? "",
        },
    };

    const { title, description } = ADDRESS_BOX_TEXT[type];

    const savedAddresses = totalAddressesCount - apiErrorsCount;
    const issuesText = apiErrorsCount > 1 ? `${apiErrorsCount} have errors` : `${apiErrorsCount} has an error`;

    const errorInfo =
        totalAddressesCount > 0 && apiErrorsCount > 0
            ? `${savedAddresses}/${totalAddressesCount} saved, ${issuesText}. Please try again`
            : undefined;

    return (
        <AddressBox>
            <H2>{title}</H2>
            <P color={colors.ui52}>{description}</P>
            <Form>{children}</Form>
            {errorInfo ? <InfoBadge info={errorInfo} /> : <></>}
            <>
                <PlainButton type="button" size="medium" disabled={isSubmitting} onClick={onCancel}>
                    Cancel
                </PlainButton>
                <PrimaryButton
                    disabled={isDisabled}
                    loading={isSubmitting}
                    onClick={onSubmit}
                    type="button"
                    size="medium"
                >
                    {apiErrorsCount > 0 ? "Retry" : "Save"}
                </PrimaryButton>
            </>
        </AddressBox>
    );
};
