const SAB_API_ERRORS_MESSAGES = {
    400: "The specified owner is incorrect",
    401: "ID should not be provided when creating a new item",
    402: "ID must be provided when updating an item",
    410: "The selected bank is already in use",
    411: "The selected bank does not exist",
    412: "The selected payment account is already in use",
    413: "The selected payment account does not exist",
    414: "The payment account rule does not exist",
    415: "A rule for this payment account already exists",
    416: "The provided revision hash is incorrect",
    430: "The selected currency does not exist",
    431: "The specified counterparty does not exist",
    432: "The counterparty ID provided is incorrect",
};

// TODO should be an object
const messages = [
    "", // 0
    "Not implemented", // 1
    "Not connected", // 2
    "Not authorized", // 3
    "Already authorized", // 4
    "Invalid password", // 5
    "Invalid nonce", // 6
    "Invalid timestamp", // 7
    "API method not available", // 8
    "API method parameter is invalid", // 9
    "Internal error", // 10
    "Invalid MFA code", // 11
    "MFA not enabled", // 12
    null, // 13
    "Invalid signature", // 14,
    "The link has expired", // 15
    null, // 16
    null, // 17
    null, // 18
    null, // 19
    "Invalid currency flags", // 20
    "Invalid currency price", // 21
    "Invalid currency balance step", // 22
    "Invalid currency name", // 23
    "Currency name cannot be changed", // 24
    "Currency balance step cannot be changed", // 25
    "Currency not found", // 26
    "Currency cannot be removed", // 27
    null, // 28
    null, // 29
    "Invalid instrument flags", // 30
    "Invalid instrument name", // 31
    "Instrument asset currency cannot be changed", // 32
    "Instrument balance currency cannot be changed", // 33
    "Instrument not found", // 34
    "Instrument cannot be removed", // 35
    "Instrument is not whitelisted", // 36
    null, // 37
    null, // 38
    null, // 39
    "Invalid client flags", // 40
    "Invalid client taker delta ratio", // 41
    "Invalid name", // 42
    "Client type cannot be changed", // 43
    "Client already exists", // 44
    "Client not found", // 45
    "Username already exists", // 46
    null, // 47
    null, // 48
    null, // 49
    "Invalid limit flags", // 50
    null, // 51
    "Invalid limit gross limit", // 52
    "Limit not found", // 53
    "Identical clients not allowed", // 54
    "Identical client types not allowed", // 55
    "Invalid counterparty", // 56
    null, // 57
    null, // 58
    null, // 59
    "Invalid settlement order flags", // 60
    "Invalid settlement order size", // 61
    "Invalid settlement order comment", // 62
    "Identical settlement clients", // 63
    "Settlement order not found", // 64
    "Settlement order is from transaction", // 65
    null, // 66
    null, // 67
    null, // 68
    null, // 69
    "Invalid order size", // 70
    "Invalid order price", // 71
    "Invalid order flags", // 72
    "Order type not allowed", // 73
    "Client order id already in use", // 74
    "Add failed - Post-Only", // 75
    "Add failed - IOC: no orders to match", // 76
    "Add failed - FOK: not enough liquidity", // 77
    "Add failed - SMP (self-trade prevention)", // 78
    "Add failed - limits", // 79
    "Del failed - not found", // 80
    "Either volume or size should be specified", // 81
    "Orders by volume not supported for makers", // 82
    "Invalid order volume", // 83
    "Trading not allowed", // 84
    "No open positions in order currencies", // 85
    null, // 86
    null, // 87
    null, // 88
    null, // 89
    "Mod failed - no size after decrement", // 90
    "Mod failed - side mismatch", // 91
    null, // 92
    null, // 93
    null, // 94
    null, // 95
    null, // 96
    null, // 97
    null, // 98
    null, // 99
    "Binding already exists", // 100
    "Binding not found", // 101
    "Invalid feed name", // 102
    "Invalid feed id", // 103
    "Database out-of-sync", // 104
    null, // 105
    null, // 106
    null, // 107
    null, // 108
    null, // 109
    "Field Required", // 110
    "Field Invalid", // 111
    "Poor Username", // 112
    "Poor Password", // 113
    "Password Change Required", // 114
    null, // 115
    null, // 116
    null, // 117
    null, // 118
    null, // 119
    "Maximum number of keys reached", // 120
    "Key not found", // 121
    null, // 122
    null, // 123
    null, // 124
    null, // 125
    null, // 126
    null, // 127
    null, // 128
    null, // 129
    "Settlement request already exists", // 130
    "Settlement request not found", // 131
    "Invalid settlement request flags", // 132
    "Invalid settlement counterparty", // 133
    "Invalid settlment request comment", // 134
    "Invalid settlment request amount", // 135
    null, // 136
    null, // 137
    null, // 138
    null, // 139
    "Invalid settlement transaction flags", // 140
    "Invalid settlement transaction amount", // 141
    "Invalid settlement transaction txId", // 142
    "Identical clients not allowed", // 143
    "Settlement transaction not found", // 144
    "Incoming settlement transaction not allowed", // 145
    null, // 146
    null, // 147
    null, // 148
    null, // 149
    null, // 150
    null, // 151
    null, // 152
    null, // 153
    null, // 154
    null, // 155
    null, // 156
    null, // 157
    null, // 158
    null, // 159
    "Taker limit by asset reached", // 160
    "Taker global gross limit breached",
    "Maker limit by asset reached", // 162
    "Maker global gross limit breached", // 163
    "Not enough equity", // 164
    "Taker counterparty gross limit breached", // 165
    "Master counterparty gross limit breached", // 166
    "Maker counterparty gross limit breached", // 167
    "Master limit by asset reached", // 168
    "Master global gross limit breached", // 169
    "Short position forbidden", // 170
    "Master limit breached", // 171
    null, // 172
    null, // 173
    null, // 174
    null, // 175
    null, // 176
    null, // 177
    null, // 178
    null, // 179
    "Network not found", // 180
    "Network already exists", // 181
    "Network invalid name", // 182
    "Network invalid description", // 183
    null, // 184
    null, // 185
    null, // 186
    null, // 187
    null, // 188
    null, // 189
    null, // 190
    null, // 191
    null, // 192
    null, // 193
    null, // 194
    null, // 195
    null, // 196
    null, // 197
    null, // 198
    null, // 199
    "User not found", // 200
    "Invalid email", // 201
    "Invalid access rights", // 202
    "Invalid username", // 203
    "Invalid user fullname", // 204
    "Invite not found", // 205
    "Token not found", // 206
    "Token expired", // 207
    "Token revoked", // 208
    "Invalid key", // 209
    "Invalid content", // 210
    "Network error", // 211
    "Refresh token", // 212
    "Invalid invite id", // 213
    "User already exists", // 214
    "Migration in progress", // 215
    "Invite already used", // 216
    "Not enough permissions for the action", // 217
    "An account with the given email already exists", // 218
    "Client cannot change master", // 219
    "Invalid master id", // 220
    "Subaccount not found", // 221
    "Invalid margins", // 222
    "Invalid markup", // 223
    "Invalid client type", // 224
    "Invalid subaccount id", // 225
    "Feed not available", // 226
    "Email is already invited", // 227
    "Disable subaccount open position exists", // 228
    "Client is disabled", // 229
];

const kOk = 0;
const kNotImplemented = 1;
const kNotConnected = 2;
const kNotAuthorized = 3;
const kAlreadyAuthorized = 4;
const kInvalidPassword = 5;
const kInvalidNonce = 6;
const kInvalidTimestamp = 7;
const kAPIMethodNotAvailable = 8;
const kAPIMethodParamInvalid = 9;
const kInternalError = 10;

const kMFANotEnabled = 12;
const kAgreementNotSigned = 13;
const kInvalidSignature = 14;

const kCurrencyInvalidFlags = 20;
const kCurrencyInvalidPrice = 21;
const kCurrencyInvalidBalanceStep = 22;
const kCurrencyInvalidName = 23;
const kCurrencyCannotChangeName = 24;
const kCurrencyCannotChangeBalanceStep = 25;
const kCurrencyNotFound = 26;
const kCurrencyCannotBeRemoved = 27;

const kInstrumentInvalidFlags = 30;
const kInstrumentInvalidName = 31;
const kInstrumentCannotChangeAssetCurrency = 32;
const kInstrumentCannotChangeBalanceCurrency = 33;
const kInstrumentNotFound = 34;
const kInstrumentCannotBeRemoved = 35;
const kInstrumentForbidden = 36;

const kClientInvalidFlags = 40;
const kClientInvalidTakerDeltaRatio = 41;
const kClientInvalidName = 42;
const kClientCannotChangeClientType = 43;
const kClientAlreadyExists = 44;
const kClientNotFound = 45;

const kLimitInvalidFlags = 50;
const kLimitInvalidGrossLimit = 52;
const kLimitNotFound = 53;
const kLimitIdenticalClients = 54;
const kLimitIdenticalClientTypes = 55;
const kInvalidCounterparty = 56;

const kSettlementOrderInvalidFlags = 60;
const kSettlementOrderInvalidSize = 61;
const kSettlementOrderInvalidComment = 62;
const kSettlementOrderIdenticalClients = 63;
const kSettlementOrderNotFound = 64;
const kSettlementOrderIsFromTransaction = 65;

const kAddOrderInvalidSize = 70;
const kAddOrderInvalidPrice = 71;
const kAddOrderInvalidFlags = 72;
const kAddOrderOrderTypeNotAllowedForClient = 73;
const kAddOrderClientOrderIDAlreadyInUse = 74;
const kAddOrderPostOnlyFailed = 75;
const kAddOrderIOCFailed = 76;
const kAddOrderFOKFailed = 77;
const kAddOrderSelfMatchPreventionFailed = 78;
const kAddOrderLimitBreached = 79;

const kOrderNotFound = 80;
const kEitherSizeOrVolumeShouldBeSpecified = 81;
const kOrderByVolumeNotSupportedForMakers = 82;
const kInvalidVolume = 83;
const kTradingNotAllowed = 84;
const kNoOpenPositionsInOrderCurrencies = 85;

const kModOrderNoSizeAfterDecrement = 90;
const kModOrderSideMismatch = 91;

const kBindingAlreadyExists = 100;
const kBindingNotFound = 101;
const kInvalidFeedName = 102;
const kInvalidFeedId = 103;
const kDatabaseOutOfSync = 104;

const kFieldRequired = 110;
const kFieldInvalid = 111;
const kFieldPoorUsername = 112;
const kFieldPoorPassword = 113;
const kPasswordChangeRequired = 114;

const kKeyMaximumNumberReached = 120;
const kKeyNotFound = 121;

const kSettlementRequestAlreadyExists = 130;
const kSettlementRequestNotFound = 131;
const kSettlementRequestInvalidFlags = 132;
const kSettlementRequestInvalidCounterparty = 133;
const kSettlementRequestInvalidComment = 134;
const kSettlementRequestInvalidAmount = 135;

const kSettlementTransactionInvalidFlags = 140;
const kSettlementTransactionInvalidAmount = 141;
const kSettlementTransactionInvalidTxId = 142;
const kSettlementTransactionIdenticalClients = 143;
const kSettlementTransactionNotFound = 144;
const kIncomingSettlementTransactionNotAllowed = 145;

const kTakerLimitByAssetReached = 160;
const kTakerGlobalGrossLimitBreached = 161;
const kMakerLimitByAssetReached = 162;
const kMakerGlobalGrossLimitBreached = 163;
const kNotEnoughEquity = 164;
const kTakerCounterpartyGrossLimitBreached = 165;
const kBrokerCounterpartyGrossLimitBreached = 166;
const kMakerCounterpartyGrossLimitBreached = 167;
const kBrokerLimitByAssetReached = 168;
const kBrokerGlobalGrossLimitBreached = 169;
const kShortPositionForbidden = 170;
const kPrimeBrokerLimitBreached = 171;

const kNetworkNotFound = 180;
const kNetworkAlreadyExists = 181;
const kNetworkInvalidName = 182;
const kNetworkInvalidDescription = 183;

const kUserNotFound = 200;
const kInvalidEmail = 201;
const kInvalidAccessRights = 202;
const kInvalidUsername = 203;
const kInvalidUserFullName = 204;
const kInviteNotFound = 205;
const kTokenNotFound = 206;
const kTokenExpired = 207;
const kTokenRevoked = 208;
const kInvalidKey = 209;
const kInvalidContent = 210;
const kNetworkError = 211;
const kRefreshToken = 212;
const kInvalidInviteId = 213;
const kUserAlreadyExists = 214;
const kMigrationInProgress = 215;
const kInviteAlreadyUsed = 216;
const kInvalidPermissions = 217;
const kEmailAlreadyExists = 218;
const kClientCannotChangePrimeBroker = 219;
const kInvalidPrimeBrokerId = 220;
const kSubaccountNotFound = 221;
const kInvalidMargins = 222;
const kInvalidMarkup = 223;
const kInvalidClientType = 224;
const kInvalidSubaccountId = 225;
const kFeedNotAvailable = 226;
const kEmailAlreadyInvited = 227;
const kDisableSubaccountOpenPositionExists = 228;
const kClientDisabled = 229;

module.exports = {
    kOk,
    kNotImplemented,
    kNotConnected,
    kNotAuthorized,
    kAlreadyAuthorized,
    kInvalidPassword,
    kInvalidNonce,
    kInvalidTimestamp,
    kAPIMethodNotAvailable,
    kAPIMethodParamInvalid,
    kInternalError,

    kMFANotEnabled,
    kAgreementNotSigned,
    kInvalidSignature,

    kCurrencyInvalidFlags,
    kCurrencyInvalidPrice,
    kCurrencyInvalidBalanceStep,
    kCurrencyInvalidName,
    kCurrencyCannotChangeName,
    kCurrencyCannotChangeBalanceStep,
    kCurrencyNotFound,
    kCurrencyCannotBeRemoved,

    kInstrumentInvalidFlags,
    kInstrumentInvalidName,
    kInstrumentCannotChangeAssetCurrency,
    kInstrumentCannotChangeBalanceCurrency,
    kInstrumentNotFound,
    kInstrumentCannotBeRemoved,
    kInstrumentForbidden,

    kClientInvalidFlags,
    kClientInvalidTakerDeltaRatio,
    kClientInvalidName,
    kClientCannotChangeClientType,
    kClientAlreadyExists,
    kClientNotFound,

    kLimitInvalidFlags,
    kLimitInvalidGrossLimit,
    kLimitNotFound,
    kLimitIdenticalClients,
    kLimitIdenticalClientTypes,
    kInvalidCounterparty,

    kSettlementOrderInvalidFlags,
    kSettlementOrderInvalidSize,
    kSettlementOrderInvalidComment,
    kSettlementOrderIdenticalClients,
    kSettlementOrderNotFound,
    kSettlementOrderIsFromTransaction,

    kAddOrderInvalidSize,
    kAddOrderInvalidPrice,
    kAddOrderInvalidFlags,
    kAddOrderOrderTypeNotAllowedForClient,
    kAddOrderClientOrderIDAlreadyInUse,
    kAddOrderPostOnlyFailed,
    kAddOrderIOCFailed,
    kAddOrderFOKFailed,
    kAddOrderSelfMatchPreventionFailed,
    kAddOrderLimitBreached,

    kOrderNotFound,
    kEitherSizeOrVolumeShouldBeSpecified,
    kOrderByVolumeNotSupportedForMakers,
    kInvalidVolume,
    kTradingNotAllowed,
    kNoOpenPositionsInOrderCurrencies,

    kModOrderNoSizeAfterDecrement,
    kModOrderSideMismatch,

    kBindingAlreadyExists,
    kBindingNotFound,
    kInvalidFeedName,
    kInvalidFeedId,
    kDatabaseOutOfSync,

    kFieldRequired,
    kFieldInvalid,
    kFieldPoorUsername,
    kFieldPoorPassword,
    kPasswordChangeRequired,

    kKeyMaximumNumberReached,
    kKeyNotFound,

    kSettlementRequestAlreadyExists,
    kSettlementRequestNotFound,
    kSettlementRequestInvalidFlags,
    kSettlementRequestInvalidCounterparty,
    kSettlementRequestInvalidComment,
    kSettlementRequestInvalidAmount,

    kSettlementTransactionInvalidFlags,
    kSettlementTransactionInvalidAmount,
    kSettlementTransactionInvalidTxId,
    kSettlementTransactionIdenticalClients,
    kSettlementTransactionNotFound,
    kIncomingSettlementTransactionNotAllowed,

    kTakerLimitByAssetReached,
    kTakerGlobalGrossLimitBreached,
    kMakerLimitByAssetReached,
    kMakerGlobalGrossLimitBreached,
    kNotEnoughEquity,
    kTakerCounterpartyGrossLimitBreached,
    kBrokerCounterpartyGrossLimitBreached,
    kMakerCounterpartyGrossLimitBreached,
    kBrokerLimitByAssetReached,
    kBrokerGlobalGrossLimitBreached,
    kShortPositionForbidden,
    kPrimeBrokerLimitBreached,

    kNetworkNotFound,
    kNetworkAlreadyExists,
    kNetworkInvalidName,
    kNetworkInvalidDescription,

    kUserNotFound,
    kInvalidEmail,
    kInvalidAccessRights,
    kInvalidUsername,
    kInvalidUserFullName,
    kInviteNotFound,
    kTokenNotFound,
    kTokenExpired,
    kTokenRevoked,
    kInvalidKey,
    kInvalidContent,
    kNetworkError,
    kRefreshToken,
    kInvalidInviteId,
    kUserAlreadyExists,
    kMigrationInProgress,
    kInviteAlreadyUsed,
    kInvalidPermissions,
    kEmailAlreadyExists,
    kClientCannotChangePrimeBroker,
    kInvalidPrimeBrokerId,
    kSubaccountNotFound,
    kInvalidMargins,
    kInvalidMarkup,
    kInvalidClientType,
    kInvalidSubaccountId,
    kFeedNotAvailable,
    kEmailAlreadyInvited,
    kDisableSubaccountOpenPositionExists,
    kClientDisabled,

    messages,
    format: function (code) {
        if (parseInt(code) !== code) {
            return code;
        }
        if (code <= 229) return messages[code] || "Error " + code;

        if (code >= 400) {
            return SAB_API_ERRORS_MESSAGES[code] || "Error " + code;
        }

        return "Error " + code;
    },
};
