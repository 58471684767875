import React from "react";
import styled from "styled-components";
import { Icons } from "../../../icons";
import { TriggerSize } from "../types";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin-left: 6px;

    background: transparent;
`;

const TRIGGER_ICON_UP_MAP: Record<TriggerSize, React.ReactElement> = {
    small: <Icons.TriggerDown />,
    medium: <Icons.TriggerDown />,
    large: <Icons.Expand />,
};
const TRIGGER_ICON_DOWN_MAP: Record<TriggerSize, React.ReactElement> = {
    small: <Icons.TriggerUp />,
    medium: <Icons.TriggerUp />,
    large: <Icons.Collapse />,
};

type TriggerButtonProps = {
    size: TriggerSize;
    active: boolean;
    className?: string;
};
export const TriggerIcon: React.FC<TriggerButtonProps> = ({ size, active, className }) => {
    const icon = active ? TRIGGER_ICON_DOWN_MAP[size] : TRIGGER_ICON_UP_MAP[size];

    return <Container className={className}>{icon}</Container>;
};
