import { EMPTY_ARRAY } from "const";
import { useMemo } from "react";
import useSWR from "swr";
import { displayError } from "utils";
import { BANK_URL, postDataFromResult } from "../api";
import { ApiBankEntity } from "../entities/ApiBankEntity";

type SWRKey = string;
export const useBanks = () => {
    const fetcher = (url: string) => postDataFromResult(url, {});
    const { data = [], isLoading } = useSWR<ApiBankEntity[], any, SWRKey>(BANK_URL, fetcher, {
        onError: displayError,
        fallbackData: EMPTY_ARRAY as ApiBankEntity[],
    });

    return useMemo(
        () => ({
            banks: data,
            isBanksLoading: isLoading,
        }),
        [data, isLoading],
    );
};
