import { Modal, PrimaryButton } from "@fm-frontend/uikit";
import { ButtonGroup } from "style";
import { Content, Message, Strong, Title } from "./styled";
import type { UserWasCreatedModalProps } from "./types";

export const UserWasCreatedModal = ({ onClose }: UserWasCreatedModalProps) => {
    return (
        <Modal isOpen onClose={onClose}>
            <Content>
                <Title>New user is added</Title>
                <Message>
                    The user will become <Strong>active</Strong> after the login
                </Message>
                <ButtonGroup>
                    <PrimaryButton size="large" fullWidth onClick={onClose}>
                        Continue
                    </PrimaryButton>
                </ButtonGroup>
            </Content>
        </Modal>
    );
};
