import {
    DropdownOption,
    MultipleDropdown,
    OPTIONS_SEARCH_COUNT,
    SheetSize,
    SingleDropdown,
} from "@fm-frontend/uikit/src/components/v2/Dropdown";
import React, { useMemo } from "react";
import { PREFERRED_CURRENCIES } from "../../const";
import { CurrencyOptionEssence } from "../CurrencyOptionEssence";

type DropdownCurrencyOption = DropdownOption<
    number | string,
    {
        position?: bigint;
    }
>;
type CurrencySheetProps = {
    size: SheetSize;
    options: DropdownCurrencyOption[];
    groupingOptions?: DropdownCurrencyOption[];
    Dropdown: typeof SingleDropdown | typeof MultipleDropdown;
    renderActions?: () => React.ReactNode;
};
export const CurrencyDropdownSheet: React.FC<CurrencySheetProps> = ({
    size,
    options,
    groupingOptions = [],
    Dropdown,
    renderActions,
}) => {
    const currencyOptions = useMemo(() => {
        return options.reduce<{
            top: DropdownCurrencyOption[];
            other: DropdownCurrencyOption[];
        }>(
            (acc, curr) => {
                acc[PREFERRED_CURRENCIES.includes(curr.text) ? "top" : "other"].push(curr);

                return acc;
            },
            {
                top: [],
                other: [],
            },
        );
    }, [options]);

    return (
        <Dropdown.Sheet size={size}>
            {({ query, normalizedQuery, onSearch }) => (
                <>
                    {options.length > OPTIONS_SEARCH_COUNT && (
                        <Dropdown.Search query={query} onSearch={onSearch} />
                    )}
                    {query && (
                        <Dropdown.EmptySearch>
                            {options
                                .filter((o) => o.text?.toLowerCase().includes(normalizedQuery))
                                .map((option) => (
                                    <Dropdown.Option key={`${option.value}`} value={option.value}>
                                        <CurrencyOptionEssence option={option} query={query} />
                                    </Dropdown.Option>
                                ))}
                        </Dropdown.EmptySearch>
                    )}
                    {!query && (
                        <Dropdown.EmptySearch>
                            {renderActions?.()}
                            {renderActions && <Dropdown.Divider />}
                            {groupingOptions.map((option) => (
                                <Dropdown.Option key={`${option.value}`} value={option.value}>
                                    <CurrencyOptionEssence groupingOption option={option} />
                                </Dropdown.Option>
                            ))}
                            {Boolean(groupingOptions.length && currencyOptions.top.length) && (
                                <Dropdown.Divider />
                            )}
                            {currencyOptions.top.map((option) => (
                                <Dropdown.Option key={`${option.value}`} value={option.value}>
                                    <CurrencyOptionEssence option={option} query={query} />
                                </Dropdown.Option>
                            ))}
                            {Boolean(
                                currencyOptions.top.length && currencyOptions.other.length,
                            ) && <Dropdown.Divider />}
                            {currencyOptions.other.map((option) => (
                                <Dropdown.Option key={`${option.value}`} value={option.value}>
                                    <CurrencyOptionEssence option={option} query={query} />
                                </Dropdown.Option>
                            ))}
                        </Dropdown.EmptySearch>
                    )}
                </>
            )}
        </Dropdown.Sheet>
    );
};
