type ExtendedPromise<T> = Promise<T> & { status?: string; reason?: any; value?: T };

// TODO: replace with react implementation when it will be ready
export const use = <T = unknown>(promise: ExtendedPromise<T>) => {
    if (promise.status === 'fulfilled') {
        return promise.value;
    } else if (promise.status === 'rejected') {
        throw promise.reason;
    } else if (promise.status === 'pending') {
        throw promise;
    } else {
        promise.status = 'pending';
        promise.then(
            result => {
                promise.status = 'fulfilled';
                promise.value = result;
            },
            reason => {
                promise.status = 'rejected';
                promise.reason = reason;
            },
        );
        throw promise;
    }
}
