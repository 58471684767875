import { SIZE64 } from "@fm-frontend/utils";
import { AccordionWithConfirmation } from "components/AccordionWithConfirmation";
import { ROUTES } from "feature/app/router";
import { useState } from "react";
import { GroupedPositionsData } from "store/usePositionsGrouped";

export const CommonPositionsAccordion = ({
    totalUSD,
    groupedPositionsData,
    isLoading,
    header,
    content,
}: {
    totalUSD: bigint;
    groupedPositionsData: GroupedPositionsData;
    isLoading: boolean;
    header: JSX.Element;
    content: JSX.Element;
}) => {
    const [expanded, setExpanded] = useState(true);
    const noPositions = !isLoading && Object.keys(groupedPositionsData).length === 0;

    return (
        <AccordionWithConfirmation
            header={header}
            defaultExpanded={expanded}
            onExpandedChange={setExpanded}
            content={content}
            moveToDetails={
                noPositions
                    ? undefined
                    : {
                          detailsPageUrl: ROUTES.positions,
                          fromSectionTitle: "Trading Page",
                          toSectionTitle: "Positions Page",
                          buttonTitle: `Total $${SIZE64.toFormattedDecimalString(totalUSD, 2)}`,
                      }
            }
        />
    );
};
