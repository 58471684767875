import { ClientId, HStack, PSmall, PSmallBold } from "@fm-frontend/uikit";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { FC } from "react";
import styled from "styled-components";
import { AuthorizedClientId } from "../../hooks/useAuthorizedClientId";

const Container = styled(HStack)`
    min-height: 22px;
    padding: 4px 16px 6px 16px;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => p.theme.colors.ui4};
    gap: 4px;

    ${PSmall} {
        color: ${(p) => p.theme.colors.ui52};
    }
`;

type ClientTileInfoProps = {
    isTileActive: boolean;
    authorizedClientId?: AuthorizedClientId;
};

export const AuthorizedClientTileInfo: FC<ClientTileInfoProps> = ({
    authorizedClientId,
    isTileActive,
}) => {
    const { getCpName } = useCpInfoHelpers();

    if (!authorizedClientId || !isTileActive) {
        return null;
    }

    const cpName = getCpName(authorizedClientId, "full");

    return (
        <Container>
            <PSmall title={`On behalf of ${cpName}`} ellipsis>
                On behalf of <PSmallBold>{cpName}</PSmallBold>
            </PSmall>
            <ClientId id={authorizedClientId} />
        </Container>
    );
};
