import { useLSState } from "hooks/useLSState";
import { isEqual } from "lodash";
import { useCallback, useLayoutEffect } from "react";
import { usePrimeBrokerViewType } from "store/hooks";
import { LS_PREFIX, removeLSValue } from "utils/localStorage";
import { Options as SPStateOptions, useSearchParamState } from "./useSearchParamState";

const FILTER_STATE_PREFIX = "filter-";

type FilterState<TValue> = [
    state: TValue,
    setState: (value: TValue, replace?: boolean) => void,
    deleteState: (replace?: boolean) => void,
];

type Options<TValue> = SPStateOptions<TValue> & {
    availableValues?: TValue | TValue[];
};

export function useFilterState<TValue>(
    page: string,
    name: string,
    defaultValue: TValue,
    options?: Options<TValue>,
): FilterState<TValue> {
    const { availableValues: availableValue, ...SPOptions } = options ?? {};
    const availableValues = Array.isArray(availableValue)
        ? availableValue
        : availableValue !== undefined ? [availableValue] : undefined;
    const primeBrokerViewType = usePrimeBrokerViewType();
    const LSKey = `${FILTER_STATE_PREFIX}${page}-${name}-${primeBrokerViewType}`;

    const [SPFilterState, setSPFilterState, deleteSPFilterState] = useSearchParamState<TValue>(
        name,
        null,
        SPOptions,
    );
    const [LSFilterState, setLSFilterState, deleteLSFilterState] = useLSState(LSKey, defaultValue);

    const isSPExist = SPFilterState !== undefined && SPFilterState !== null;
    let state = isSPExist ? SPFilterState : LSFilterState;

    if (availableValues !== undefined) {
        if (Array.isArray(state)) {
            const filteredState = state.filter((value) =>
                availableValues.includes(value),
            ) as unknown as TValue;
            state = isEqual(state, filteredState) ? state : filteredState;
        } else {
            state = availableValues.includes(state) ? state : defaultValue;
        }
    }

    useLayoutEffect(() => {
        if (!isEqual(state, SPFilterState)) {
            setSPFilterState(state, true);
        }
    }, [state, SPFilterState, setSPFilterState]);

    useLayoutEffect(() => {
        if (!isEqual(state, LSFilterState)) {
            setLSFilterState(state);
        }
    }, [state, LSFilterState, setLSFilterState]);

    const setState = useCallback(
        (value: TValue, replace) => {
            setLSFilterState(value);
            setSPFilterState(value, replace);
        },
        [setSPFilterState, setLSFilterState],
    );

    const deleteState = useCallback(
        (replace) => {
            deleteLSFilterState();
            deleteSPFilterState(replace);
        },
        [deleteSPFilterState, deleteLSFilterState],
    );

    return [state, setState, deleteState];
}

export const clearFilters = () => {
    const filterKeyPattern = new RegExp(`^${LS_PREFIX}(\\d+-)?${FILTER_STATE_PREFIX}`);

    for (let localStorageKey of Object.keys(localStorage)) {
        if (filterKeyPattern.test(localStorageKey)) {
            removeLSValue(localStorageKey.slice(LS_PREFIX.length));
        }
    }
};
