import { BITMASK_UTILS } from "@fm-frontend/utils";
import { SettlementTransaction, Transaction as TransactionHistory } from "types";
import { getAbsoluteValue } from "utils/format";
import { Transaction, TransactionDirection, TransactionStatus } from "./types";

const { isBitKeyApplied } = BITMASK_UTILS;

export const convertApiTransactions = (
    transactions: (SettlementTransaction | TransactionHistory)[],
    getUsername: (counterpartyId: string | number, type: "short" | "full") => string,
    type: "active" | "finished",
    isSubaccountUser = false,
    side?: TransactionDirection,
): Transaction[] =>
    transactions.map((socketTransaction) => {
        const [
            counterpartyId, // (Efx::ClientId)
            currencyName, // (string)
            amount, // (Efx::Size)
            settlementOrderId, // (Efx::OrderId)
            comment, // (string)
            createdAt, // (Efx::Timestamp)
            txId, // (string)
            sentAt, // (Efx::Timestamp)
            transactionFlags, // (Efx::Flags)
            transactionMoment,
            ,
            networkFee, // (unsigned int64)
            network, // asset network (ERC20, tron etc.)
        ] = socketTransaction;

        // calculate correct status
        let status: TransactionStatus = TransactionStatus.cancelled;
        if (type === "finished") {
            const statusFlags = transactionFlags & 3;
            status = statusFlags === 0 || statusFlags === 1 ? TransactionStatus.committed : TransactionStatus.cancelled;
        } else if (type === "active") {
            if (side === "fromYou") {
                status = sentAt ? TransactionStatus.sent : TransactionStatus.created;
            }
            if (side === "toYou") {
                status = sentAt ? TransactionStatus.received : TransactionStatus.created;
            }
        }

        // calculate transaction type
        let transactionType: Transaction["type"] = "deposit";
        if (type === "active") {
            if ((isSubaccountUser && side === "toYou") || (!isSubaccountUser && side === "fromYou")) {
                transactionType = "withdrawal";
            }
        } else if (type === "finished") {
            if ((isSubaccountUser && amount < 0) || (!isSubaccountUser && amount > 0)) {
                transactionType = "withdrawal";
            }
        }

        const convertedTransaction: Transaction = {
            type: transactionType,
            id: settlementOrderId,
            subaccount: { name: getUsername(counterpartyId, "full"), id: counterpartyId },
            asset: currencyName,
            amount: BigInt(getAbsoluteValue(amount)),
            txId,
            comment,
            status,
            created: Number(transactionMoment || createdAt),
            // when networkFee is 0
            fee: { payer: "sender" },
            network,
            direction: side,
            isCreatedByRecipient: isBitKeyApplied(transactionFlags, 1 << 4),
        };

        if (networkFee > 0) {
            convertedTransaction.fee = { payer: "recipient", size: networkFee };
        }

        return convertedTransaction;
    });
