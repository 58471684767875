import { DropdownOption } from "@fm-frontend/uikit/src/components/v2";
import { useInstruments } from "hooks";
import { useMemo } from "react";

type Options = {
    availableNetworks: string[];
};

export const useNetworksOptions = (options?: Options) => {
    const { networks } = useInstruments();
    const { availableNetworks } = options ?? {};

    return useMemo(
        (): DropdownOption<number>[] =>
            networks
                .filter((n) => (availableNetworks === undefined ? true : availableNetworks.includes(n.name)))
                .map(({ id, name, description }) => {
                    return {
                        text: `${name}, ${description}`,
                        value: id,
                    };
                }),
        [networks, availableNetworks],
    );
};
