import React from "react";
import { useController, UseControllerProps } from "react-hook-form";
import styled, { css, useTheme } from "styled-components";
import { useId } from "../../hooks/useId";
import { Checkbox, CheckboxSize } from "../Checkbox";
import { PSmall } from "../typography";

const Container = styled.label<{ fullWidth?: boolean; size: CheckboxSize }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    padding: 2px 8px;
    background-color: ${(p) => p.theme.colors.ui8};
    border-radius: 8px;
    height: 24px;
    cursor: pointer;
    ${(p) =>
        p.fullWidth &&
        css`
            width: 100%;
        `}
    ${(p) =>
        p.size === "large" &&
        css`
            height: 44px;
        `}
`;

interface Props extends UseControllerProps<any> {
    label: string | React.ReactNode;
    fullWidth?: boolean;
    size?: CheckboxSize;
}

export const CheckboxField: React.VFC<Props> = ({ label, fullWidth, size = "small", ...props }) => {
    const generatedId = useId("checkbox-field-");

    const { colors } = useTheme();
    const { field } = useController({
        ...props,
    });
    const { value, onChange } = field;

    return (
        <Container htmlFor={generatedId} fullWidth={fullWidth} size={size}>
            <PSmall color={colors.ui72}>{label}</PSmall>
            <Checkbox id={generatedId} checked={value} onChange={onChange} size={size} />
        </Container>
    );
};
