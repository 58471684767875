import { BasicButton, Form, FormActions, FormBody, Modal, ShadowCard } from "@fm-frontend/uikit";
import { Settlement } from "../../types";
import { ModalHeader } from "./components/ModalHeader";
import { SettlementInfo } from "./components/SettlementInfo";

type NonActionableModalProps = {
    closeModal: () => void;
    settlement: Settlement;
};

export const NonActionableModal = ({ closeModal, settlement }: NonActionableModalProps) => {
    return (
        <Modal isOpen onClose={closeModal}>
            <Form>
                <ShadowCard>
                    <ModalHeader settlement={settlement} />
                    <FormBody spacing={4}>
                        <SettlementInfo settlement={settlement} />
                    </FormBody>
                    <FormActions variant="plain">
                        <BasicButton type="button" fullWidth size="large" onClick={closeModal}>
                            Close
                        </BasicButton>
                    </FormActions>
                </ShadowCard>
            </Form>
        </Modal>
    );
};
