const CURRENCY_TYPES = ["unknown", "crypto", "fiat", "stablecoin"] as const;
type CurrencyTypeTuple = typeof CURRENCY_TYPES;
export type CurrencyType = CurrencyTypeTuple[number];

export const isCurrencyType = (value: string): value is CurrencyType => {
    return CURRENCY_TYPES.includes(value as CurrencyType);
};

export const CurrencyTypeMap: Record<Exclude<CurrencyType, "unknown">, string> = {
    crypto: "Crypto",
    fiat: "Fiat",
    stablecoin: "Stablecoin",
};

export type Currency = [
    name: string,
    id: number,
    balanceStep: bigint | number,
    usdVal: bigint | number,
    currencyType: CurrencyType,
];
