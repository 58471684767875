import { Flex, P } from "@fm-frontend/uikit";
import { getLabelWithHighlightedSearch } from "feature/settlements";
import styled from "styled-components";
import { useSearchQuery } from "../../../searchQueryContext";
import { formatPercentValue } from "../utils";

const Markup = styled(P)<{ $lineThrough: boolean }>`
    color: ${(p) => (p.$lineThrough ? p.theme.colors.ui32 : p.theme.colors.ui100)};
    text-decoration: ${(p) => (p.$lineThrough ? "line-through" : "none")};
`;

const MarkupContainer = styled(Flex)<{ $withoutBorder: boolean }>`
    width: 100%;
    height: 100%;
    flex-direction: row-reverse;
    align-items: center;
    border-left: 1px solid ${(p) => (p.$withoutBorder ? "transparent" : p.theme.colors.ui4)};
`;

export const getMarkupText = (markup: number | undefined) =>
    markup === undefined ? "" : `${formatPercentValue(markup)}%`;

export const MarkupCell = ({
    markup,
    lineThrough = false,
    withoutBorder = false,
}: {
    markup?: number;
    lineThrough?: boolean;
    withoutBorder?: boolean;
}) => {
    const searchQuery = useSearchQuery();

    return (
        <MarkupContainer $withoutBorder={withoutBorder}>
            {markup !== undefined && (
                <Markup $lineThrough={lineThrough}>
                    {getLabelWithHighlightedSearch(getMarkupText(markup), searchQuery)}
                </Markup>
            )}
        </MarkupContainer>
    );
};
