import {
    Form,
    FormActions,
    FormInfo,
    Header,
    HeaderTitle,
    PlainButton,
    PrimaryButton,
    ShadowCard,
    ShadowItem,
    SimpleInput,
} from "@fm-frontend/uikit";
import { useFormCloseConfirmer } from "@fm-frontend/utils";
import { SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CurrencyDropdownSheet } from "components/CurrencySheet";
import { CurrencyTriggerEssence } from "components/CurrencyTriggerEssence";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useCurrenciesWithPreferred } from "store/useCurrenciesWithPreferred";
import styled from "styled-components";
import { getCurrencyPlaceholder, getFmtPrecisionConfig } from "utils";
import { fmt } from "utils/format";
import { SET_LIMIT_MODAL_KEY } from "../liquidityProvidersDetails/utils";
import { LimitInputs } from "../utils";
import { schema } from "./schema";
import { CURRENCY, FMT_LIMIT_CONFIG } from "./utils";

const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 16px 12px 0;
`;

export const OnboardingLimitEdit: React.VFC<{
    text: string;
    title: string;
    onSetLimits: (data: LimitInputs) => void;
    onClose: () => void;
    defaultLimit: LimitInputs;
}> = ({ text, title, onSetLimits, onClose, defaultLimit: { currency, grossLimit } }) => {
    const currencies = useCurrenciesWithPreferred();

    const {
        control,
        register,
        watch,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting },
    } = useForm<LimitInputs>({
        mode: "onSubmit",
        defaultValues: {
            currency: currency,
            grossLimit: fmt(grossLimit, {
                ...FMT_LIMIT_CONFIG,
                ...getFmtPrecisionConfig(currency ?? CURRENCY),
            }).copyableValue,
        },
        resolver: yupResolver(schema),
    });

    useFormCloseConfirmer(SET_LIMIT_MODAL_KEY, isDirty);

    const currencyField = watch("currency");

    return (
        <Form onSubmit={handleSubmit(onSetLimits)}>
            <ShadowCard isDefault>
                <ShadowItem padding="12px 12px 0">
                    <Header>
                        <HeaderTitle title={title} />
                    </Header>
                </ShadowItem>
                <ShadowItem padding="0 12px">
                    <FormInfo>{text}</FormInfo>
                </ShadowItem>
                <InputsContainer>
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <SingleDropdown
                                value={field.value}
                                onChange={field.onChange}
                                renderTrigger={(trigger) => (
                                    <SingleDropdown.Trigger {...trigger} size="large" variant="simple">
                                        <CurrencyTriggerEssence
                                            {...trigger}
                                            option={trigger.selectedOption}
                                            size="large"
                                        />
                                    </SingleDropdown.Trigger>
                                )}
                                options={currencies}
                                caption="Limit currency"
                                error={errors.currency?.message}
                                placeholder="Currency"
                                fullWidth
                            >
                                <CurrencyDropdownSheet size="large" options={currencies} Dropdown={SingleDropdown} />
                            </SingleDropdown>
                        )}
                        name="currency"
                    />
                    <SimpleInput
                        label="Gross Limit"
                        placeholder={getCurrencyPlaceholder(currencyField ?? "")}
                        {...register("grossLimit")}
                        error={errors.grossLimit?.message}
                    />
                </InputsContainer>
                <ShadowItem padding="0 12px 16px ">
                    <FormActions variant="basic">
                        <PlainButton fullWidth size="large" type="button" onClick={onClose}>
                            Back
                        </PlainButton>
                        <PrimaryButton fullWidth size="large" loading={isSubmitting}>
                            Set limit
                        </PrimaryButton>
                    </FormActions>
                </ShadowItem>
            </ShadowCard>
        </Form>
    );
};
