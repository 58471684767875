import React, { ReactNode } from "react";
import { useTheme } from "styled-components";
import { Icons } from "../../icons";
import { P, PSmall } from "../../typography";
import { DropdownSize } from "../Dropdown/Dropdown.types";
import { BasicContainer, CommonLabel, InlineContainer, InlineLabel, SimpleContainer } from "./styled";

export interface TriggerProps {
    text: string | number | undefined;
    renderText?: (text: string | number | undefined) => ReactNode;
    placeholder: string | undefined;
    label?: string;
    isOpen?: boolean;
    isError?: boolean;
    isDisabled?: boolean;
    onTrigger: () => void;
    size?: DropdownSize;
}

export const BasicTrigger: React.VFC<TriggerProps> = (props) => {
    const {
        text,
        label,
        placeholder,
        isError = false,
        isDisabled = false,
        isOpen,
        onTrigger,
        size = "large",
        renderText = (text) => <TextElement ellipsis>{text}</TextElement>,
    } = props;
    const { colors } = useTheme();

    const TextElement = size === "large" ? P : PSmall;

    const handleTrigger = () => {
        if (!isDisabled) {
            onTrigger();
        }
    };

    return (
        <>
            {label && <CommonLabel>{label}</CommonLabel>}
            <BasicContainer
                data-field-group
                onClick={handleTrigger}
                isError={isError}
                isDisabled={isDisabled}
                isOpen={isOpen}
            >
                {text ? (
                    renderText(text)
                ) : (
                    <TextElement ellipsis color={colors.ui32}>
                        {placeholder}
                    </TextElement>
                )}
                <Icons.Dropdown />
            </BasicContainer>
        </>
    );
};

export const SimpleTrigger: React.VFC<TriggerProps> = (props) => {
    const {
        text,
        renderText = (text) => <P>{text}</P>,
        label,
        placeholder,
        isError = false,
        isDisabled = false,
        isOpen,
        onTrigger,
    } = props;
    const { colors } = useTheme();

    const handleTrigger = () => {
        if (!isDisabled) {
            onTrigger();
        }
    };

    return (
        <>
            <SimpleContainer
                data-field-group
                onClick={handleTrigger}
                isError={isError}
                isDisabled={isDisabled}
                isOpen={isOpen}
            >
                <InlineLabel>{label}</InlineLabel>
                {text ? renderText(text) : <P color={colors.ui32}>{placeholder}</P>}
                <Icons.Dropdown />
            </SimpleContainer>
        </>
    );
};

export const MinimumTrigger: React.VFC<TriggerProps> = ({ label, ...props }) => <BasicTrigger {...props} />;

export const InlineTrigger: React.VFC<TriggerProps> = (props) => {
    const {
        text,
        renderText = (text) => <PSmall>{text}</PSmall>,
        label,
        isError = false,
        isDisabled = false,
        isOpen,
        onTrigger,
        size,
    } = props;
    const { colors } = useTheme();

    const triggerIcon = isOpen ? <Icons.TriggerUp color={colors.ui72} /> : <Icons.TriggerDown color={colors.ui32} />;

    const handleTrigger = () => {
        if (!isDisabled) {
            onTrigger();
        }
    };

    return (
        <InlineContainer
            data-field-group
            onClick={handleTrigger}
            isError={isError}
            isDisabled={isDisabled}
            isOpen={isOpen}
            size={size}
        >
            <PSmall color={colors.ui72}>{label}</PSmall>
            {Boolean(text) && renderText(text)}
            {triggerIcon}
        </InlineContainer>
    );
};
