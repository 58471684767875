import { Flex, IconButton, Icons, Search, Tooltip, VStack } from "@fm-frontend/uikit";
import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { ValueConvert } from "@fm-frontend/utils";
import { useDelayedState } from "hooks";
import { memo, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { useFilterState } from "hooks/useFilterState";
import { use2WayBinding } from "hooks/use2WayBinding";
import { WhitelistingBulkEditButton } from "../../bulkEdit/BulkEditWhitelisting/Button";
import { WhitelistingValues } from "../../bulkEdit/BulkEditWhitelisting/ConfigurationModal/utils";
import { AssetControlMenu } from "../AssetControlMenu";
import { SearchQueryProvider } from "../InstrumentsTabContent/searchQueryContext";
import { useWhitelistingManager } from "./useWhitelistingManager";
import { WhitelistingProvider } from "./whitelistingContext";
import { WhitelistingInstrumentsTable } from "./WhitelistingInstrumentsTable";
import { useTableData } from "./WhitelistingInstrumentsTable/hooks";
import { RowData } from "./WhitelistingInstrumentsTable/types";
import { WhitelistingSwitch } from "./WhitelistingSwitch";

const Container = styled(Flex)`
    overflow: auto;
    min-height: calc(100vh - 87px);

    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

const WHITELIST_OPTIONS: DropdownOption<WhitelistingValues>[] = [
    {
        text: "Enabled",
        value: WhitelistingValues.Enabled,
    },
    {
        text: "Disabled",
        value: WhitelistingValues.Disabled,
    },
];

const StyledSearch = styled(Search)`
    max-width: 160px;
`;

type Filters = {
    searchQuery: string;
    whitelistFilter: WhitelistingValues[];
};

const isWhitelistingFilterMatch = (
    whitelistFilter: WhitelistingValues[],
    isInstrumentInWhitelist: boolean,
) => {
    return (
        (isInstrumentInWhitelist && whitelistFilter.includes(WhitelistingValues.Enabled)) ||
        (!isInstrumentInWhitelist && whitelistFilter.includes(WhitelistingValues.Disabled))
    );
};

const useFilteredInstrumentsData = ({
    searchQuery,
    instrumentsData,
    whitelistFilter,
    isWhitelistingOn,
    isInstrumentInWhitelist,
}: {
    instrumentsData: RowData[];
    isWhitelistingOn: boolean;
    isInstrumentInWhitelist: (instrumentName: string) => boolean;
} & Filters) => {
    const normalizedSearchQuery = ValueConvert.normalize(searchQuery);

    return useMemo(() => {
        const isSearchQueryEmpty = normalizedSearchQuery === "";
        const isWhitelistFilterEmpty =
            whitelistFilter.length === 0 || whitelistFilter.length === WHITELIST_OPTIONS.length;
        const isEmptyFilters = isSearchQueryEmpty && isWhitelistFilterEmpty;

        if (isEmptyFilters) {
            return instrumentsData;
        }

        return instrumentsData.filter(({ instrument }) => {
            const instrumentName = instrument.instrumentName;

            const isSearchMatched = instrumentName.includes(normalizedSearchQuery);
            const isWhitelistFilterApplied = isWhitelistingOn && !isWhitelistFilterEmpty;
            const isWhitelistingMatched = isWhitelistFilterApplied
                ? isWhitelistingFilterMatch(
                      whitelistFilter,
                      isInstrumentInWhitelist(instrumentName),
                  )
                : true;

            return isSearchMatched && isWhitelistingMatched;
        });
    }, [normalizedSearchQuery, instrumentsData, whitelistFilter, isWhitelistingOn]);
};

const PAGE_FILTER_KEY = "assets_and_instruments-whitelisting";

export const WhitelistingTabContent = memo(() => {
    const { isLoading: isInstrumentsDataLoading, instrumentsData } = useTableData();
    const whitelistManager = useWhitelistingManager();
    const [delayedSearchQuery, setSearchQuery, searchQuery, setSearchQueryImmediately] =
        useDelayedState(750, "");

    const [whitelistFilter, setWhitelistFilter, deleteWhitelistFilter] = useFilterState<WhitelistingValues[]>(
        PAGE_FILTER_KEY,
        "whitelistFilter",
        [],
        { isArray: true, availableValues: Object.values(WhitelistingValues) },
    );

    const {
        control,
        watch,
        setValue,
    } = useForm<Filters>({
        defaultValues: {
            searchQuery: "",
            whitelistFilter,
        },
    });

    const whitelistFilterValue = watch("whitelistFilter");
    use2WayBinding(
        whitelistFilterValue,
        (filterValue) => setValue("whitelistFilter", filterValue),
        whitelistFilter,
        setWhitelistFilter,
    );

    const isDirty =
        whitelistFilterValue.length !== 0 ||
        searchQuery.trim() !== "";

    const resetFilter = () => {
        deleteWhitelistFilter();
        setSearchQueryImmediately("");
    };

    const filteredInstrumentsData = useFilteredInstrumentsData({
        searchQuery: delayedSearchQuery,
        instrumentsData,
        whitelistFilter,
        isWhitelistingOn: whitelistManager.isWhitelistingOn,
        isInstrumentInWhitelist: whitelistManager.isInstrumentInWhitelist,
    });

    return (
        <Container spacing={8} paddingLeft={8} paddingRight={8}>
            <CardContainer flex={1} asCard minWidth="445px">
                <WhitelistingProvider value={whitelistManager}>
                    <Flex padding={12} alignItems="flex-start">
                        <AssetControlMenu />
                        <WhitelistingBulkEditButton loading={whitelistManager.isLoading} disabled={!whitelistManager.isWhitelistingOn} />
                        <WhitelistingSwitch />
                    </Flex>

                    <Flex alignItems="flex-start" paddingX={12} paddingBottom={20} spacing={6}>
                        <StyledSearch
                            size="small"
                            placeholder="Search"
                            query={searchQuery}
                            onChange={(value) => setSearchQuery(value)}
                        />

                        {whitelistManager.isWhitelistingOn && (
                            <Controller
                                control={control}
                                render={({ field }) => (
                                    <MultipleDropdown
                                        values={field.value}
                                        onChange={field.onChange}
                                        renderTrigger={(trigger) => (
                                            <MultipleDropdown.Trigger
                                                {...trigger}
                                                size="small"
                                                variant="simple"
                                            >
                                                <MultipleDropdown.TriggerEssence
                                                    {...trigger}
                                                    option={trigger.selectedOptions}
                                                    size="small"
                                                />
                                            </MultipleDropdown.Trigger>
                                        )}
                                        options={WHITELIST_OPTIONS}
                                        align="end"
                                        caption="Whitelist"
                                        asFilter
                                    >
                                        <MultipleDropdown.BasicSheet
                                            size="small"
                                            options={WHITELIST_OPTIONS}
                                        />
                                    </MultipleDropdown>
                                )}
                                name="whitelistFilter"
                            />
                        )}

                        {(isDirty || searchQuery.trim() !== "") && (
                            <Tooltip content="Reset filter" align="center">
                                <IconButton
                                    variant="plain"
                                    type="button"
                                    Icon={Icons.Recent}
                                    onClick={resetFilter}
                                />
                            </Tooltip>
                        )}
                    </Flex>

                    <SearchQueryProvider value={delayedSearchQuery}>
                        <WhitelistingInstrumentsTable
                            instrumentsData={filteredInstrumentsData}
                            isLoading={isInstrumentsDataLoading}
                        />
                    </SearchQueryProvider>
                </WhitelistingProvider>
            </CardContainer>
        </Container>
    );
});
