import { fmt } from "utils/format";
import { Statistic } from "./Statistic";

export const AmountInfo = ({ amount }: { amount: bigint }) => (
    <Statistic
        title="Amount"
        value={
            fmt(amount, {
                significantDigits: 8,
                type: "size64",
            }).formattedValue
        }
    />
);
