import { forwardRef } from "react";
import { Fieldset } from "style";
import { when } from "utils";
import { FormInputError, TextArea } from "./style";

interface Props {
    errorValue?: string;
    fullWidth?: boolean;
    [key: string]: any;
}

export const FormTextArea = forwardRef<any, Props>(({ errorValue, fullWidth, ...props }, inputRef) => {
    return (
        <Fieldset hasError={Boolean(errorValue)} fullWidth={fullWidth}>
            <TextArea rows={3} ref={inputRef} {...props} />
            {when(errorValue, <FormInputError>{errorValue}</FormInputError>)}
        </Fieldset>
    );
});

export default FormTextArea;
