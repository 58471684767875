export type InstrumentOriginalDataType = {
    instrument: string;
    counterpartyId: number;
    counterparty: string;
    bidMarkup?: number;
    askMarkup?: number;
};
export type InstrumentImportType = {
    instrument: string;
    counterpartyId: string;
    counterparty: string;
    bidMarkup: string;
    askMarkup: string;
};

export type ValidationContext = {
    originalValues: InstrumentOriginalDataType[];
};

export const CSV_COLUMNS_TO_DATA_TYPE: Record<string, keyof InstrumentOriginalDataType> = {
    "Instrument": "instrument",
    "CP ID": "counterpartyId",
    "CP name": "counterparty",
    "Bid Markup": "bidMarkup",
    "Ask Markup": "askMarkup",
};
export const DATA_TYPE_TO_CSV_COLUMNS: Record<keyof InstrumentOriginalDataType, string> = {
    instrument: "Instrument",
    counterpartyId: "CP ID",
    counterparty: "CP name",
    bidMarkup: "Bid Markup",
    askMarkup: "Ask Markup",
};

export const DATA_TYPE_TO_DIFF_TABLE_COLUMNS: Record<keyof InstrumentOriginalDataType, string> = {
    instrument: "Instrument",
    counterpartyId: "CP ID",
    counterparty: "CP name",
    bidMarkup: "Bid Markup",
    askMarkup: "Ask Markup",
};

export const REQUIRED_CSV_COLUMNS: Record<
    keyof InstrumentOriginalDataType,
    string
> = {
    instrument: "Instrument",
    counterpartyId: "CP ID",
    counterparty: "CP name",
    bidMarkup: "Bid Markup",
    askMarkup: "Ask Markup",
};

export const CSV_VALIDATION_ERROR_MESSAGES = {
    get fileMissed() {
        return "File missed";
    },
    get invalidCharacters() {
        return "There are invalid characters in one or more cells of the CSV file";
    },
    missingRequiredColumns(requiredColumns: string[]) {
        return `The required columns in the CSV file are missed. Required columns are: ${requiredColumns.join(
            ", ",
        )}`;
    },
    get headersMismatchFormat() {
        return "The headers in the CSV file do not match the expected format";
    },
};

export const getInstrumentDiffTableColumnName = (name: string) => {
    return DATA_TYPE_TO_DIFF_TABLE_COLUMNS[name as keyof InstrumentOriginalDataType];
};
