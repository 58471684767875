import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

type ScrollContainerProps = { $height: number };
const ScrollContainer = styled.div.attrs<ScrollContainerProps>((p) => ({
    style: {
        height: p.$height,
        maxHeight: p.$height,
        minHeight: p.$height,
    },
}))<ScrollContainerProps>`
    margin: 1px;
    overflow: auto;
    flex: 1;

    ::-webkit-scrollbar {
        width: 18px;
        height: 18px;
    }
    ::-webkit-scrollbar-track {
        background-color: ${(p) => p.theme.colors.uiWhite100};
        border-radius: 6px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: ${(p) => p.theme.colors.ui12};
        border: 6px solid ${(p) => p.theme.colors.uiWhite100};
        transition: border 1s ease-in-out;
        &:hover {
            border-width: 5px;
        }
    }
`;

export const BottomFixTableContainer: React.FC<{
    className?: string;
    bottomOffset?: number;
    noScroll?: boolean;
}> = ({ children, className, bottomOffset = 0, noScroll }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [heightToBottom, setHeightToBottom] = useState(0);

    useEffect(() => {
        const calculateHeightToBottom = () => {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                const topPosition = rect.top + window.scrollY;

                setHeightToBottom(window.innerHeight - topPosition);
            }
        };

        setTimeout(() => calculateHeightToBottom(), 0);

        window.addEventListener("resize", calculateHeightToBottom);

        return () => {
            window.removeEventListener("resize", calculateHeightToBottom);
        };
    }, []);

    return (
        <ScrollContainer
            ref={ref}
            $height={heightToBottom - bottomOffset}
            className={className}
            {...(noScroll ? {} : { "data-scroll-container": true })}
        >
            {children}
        </ScrollContainer>
    );
};
