import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Feeds from "../../utils/ClientFeeds";
import { FeedId, Listeners, MainFeedId, MainFeeds, Snapshots } from "./ws.d";

export type State = {
    snapshots: Snapshots;
    listeners: Listeners;
};

type RestViaWsMessage = {
    reqId: number;
    method: string;
    content: { [key: string]: unknown };
};
type RestRequestResponse = {
    reqId: number;
    error?: number;
    reply?: unknown;
};

export const compareFeedIds = (feedIdA: MainFeedId, feedIdB: MainFeedId) =>
    JSON.stringify(feedIdA) === JSON.stringify(feedIdB);

export const addMessage = createAction("snapshot/addMessage", (msg: any) => ({
    payload: msg,
}));
export const syncFeeds = createAction("snapshot/syncFeeds");
export const snapshotUpdateAvailable = createAction("snapshot/updateAvailable");
export const wsReset = createAction("ws/reset");
export const wsResetRetries = createAction("ws/resetRetries");
export const wsRequestAuth = createAction("ws/requestAuth");
export const wsResubscribeToFeeds = createAction("ws/resubscribeToFeeds");
export const sendRESTRequestViaWS = createAction(
    "ws/sendRESTRequestViaWS",
    (messageBody: RestViaWsMessage) => ({
        payload: messageBody,
    }),
);
export const restRequestResponse = createAction(
    "ws/restRequestResponse",
    (response: RestRequestResponse) => ({
        payload: response,
    }),
);

export const getInitialRecord = () => ({
    A: [],
    I: [],
    P: [],
    G: [],
    L: [],
    B: [],
    F: [],
    R: [],
    N: [],
    M: [],
    K: [],
    O: [],
    S: [],
    NM: [],
});
const initialState: State = {
    snapshots: getInitialRecord(),
    listeners: getInitialRecord(),
};

export const wsSlice = createSlice({
    name: "ws",
    initialState,
    reducers: {
        clearSnapshots(state) {
            return {
                ...state,
                snapshots: getInitialRecord(),
            };
        },
        setSnapshots(state, { payload: snapshots }: PayloadAction<Snapshots>) {
            return { ...state, snapshots };
        },
        updateSnapshots(
            state,
            { payload: snapshots }: PayloadAction<Snapshots | Partial<Snapshots>>,
        ) {
            return {
                ...state,
                snapshots: { ...state.snapshots, ...snapshots },
            };
        },
        bindTo(state, { payload: { feed, feedId = 0 } }: PayloadAction<FeedId>) {
            const listener =
                state.listeners[feed as MainFeeds] ||
                state.listeners[Feeds.masterFeed(feed) as MainFeeds];
            const listenerFeed = listener.find((i) => compareFeedIds(i.feedId, feedId));

            if (listenerFeed === undefined) {
                listener.push({ feedId, count: 1 });
            } else {
                listenerFeed.count += 1;
            }
        },
        unBind(state, { payload: { feed, feedId = 0 } }: PayloadAction<FeedId>) {
            const listener =
                state.listeners[feed as MainFeeds] ||
                state.listeners[Feeds.masterFeed(feed) as MainFeeds];
            let listenerFeed = listener.find((i) => compareFeedIds(i.feedId, feedId));

            if (listenerFeed === undefined) {
                listenerFeed = { feedId, count: 0 };
                listener.push(listenerFeed);
            }

            if (listenerFeed.count > 0) {
                listenerFeed.count -= 1;
            }
        },
    },
});
export const {
    actions: { clearSnapshots, bindTo, unBind, setSnapshots, updateSnapshots },
} = wsSlice;
