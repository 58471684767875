import { VStack } from "@fm-frontend/uikit";
import { FC } from "react";
import styled from "styled-components";
import { HintsContainer } from "./HintsContainer";
import { HintsHeader } from "./HintsHeader";

const Group = styled(VStack)`
    ${HintsHeader}:not(:first-child) {
        padding-top: 32px;
    }
`;

export const HintsLayout: FC = ({ children }) => (
    <HintsContainer>
        <Group>{children}</Group>
    </HintsContainer>
);
