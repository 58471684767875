import { P, PSmall as LinkWrapper, PSmall } from "@fm-frontend/uikit";
import { Copyright } from "components/Copyright";
import { LinkFAQ, LinkLegal, LinkSupport } from "components/Links";
import { Logo } from "components/Logo";
import { IS_ONBOARDING_ACTIVE, IS_TEST_ENV } from "const/env";
import { useSignupQueryParams } from "feature/auth/useSignupQueryParams";
import { useSelector } from "hooks";
import { useSettings } from "hooks/useSettings";
import React, { memo, useMemo } from "react";
import { useIsMakerUser, useIsPrimeBrokerUser, useIsTakerUser } from "store/hooks";
import {
    OnboardingMap,
    OnboardingStepType,
    ONBOARDING_STEPS,
    useIsPostOnboardingRequired,
} from "store/onboardingSlice";
import { Spacer } from "style";
import styled, { useTheme } from "styled-components";
import { InviteToken } from "types";
import { decodeInviteJwtToken } from "utils/decodeInviteJwtToken";
import { MenuStep } from "./MenuStep";

const Container = styled.nav`
    position: sticky;
    padding: 24px 24px 24px 8px;
    margin-right: 6px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    top: ${IS_TEST_ENV ? 24 : 0}px;
    height: ${IS_TEST_ENV ? "calc(100vh - 24px)" : "100vh"};
    width: 220px;
    overflow-y: auto;
    gap: 8px;

    & > *:not(section) {
        padding-left: 8px;
    }
`;

export const OnboardingMenuContent = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 192px;
    gap: 8px;
    margin-top: 20px;
`;

export const OnboardingFooterContent = styled.footer`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const LinksContainer = styled.div`
    display: flex;
    gap: 8px;

    ${LinkWrapper} > a {
        color: ${(p) => p.theme.colors.ui72};
    }
`;

const MobileContainer = styled.nav`
    display: flex;
    position: sticky;
    padding: 8px;
    gap: 8px;
    width: 100%;
    justify-content: center;
`;

type NavigationStep = Record<
    string,
    {
        order: number;
        title: string;
        mobileTitle: string;
    }
>;

const accountNavigationStep: NavigationStep = {
    account: {
        order: 1,
        title: "Account",
        mobileTitle: "ACCOUNT",
    },
};
const businessAndLegalStep: NavigationStep = {
    businessAndLegal: {
        order: 2,
        title: "Business & legal",
        mobileTitle: "BUSINESS",
    },
};
const liquidityProvidersStep: NavigationStep = {
    liquidityProviders: {
        order: 3,
        title: "Liquidity providers",
        mobileTitle: "PROVIDERS",
    },
};
const apiIntegrationStep: NavigationStep = {
    apiIntegration: {
        order: 3,
        title: "API integration",
        mobileTitle: "INTEGRATION",
    },
};

const useNavigationSteps = () => {
    const isMakerUser = useIsMakerUser();
    const isTakerUser = useIsTakerUser();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const clientData = useSelector((state) => state.authentication.clientData);
    const { invite } = useSignupQueryParams();
    const decodeInviteToken: InviteToken | undefined = useMemo(
        () => decodeInviteJwtToken(invite),
        [invite],
    );

    return useMemo(() => {
        let navigationSteps: NavigationStep = { ...accountNavigationStep };

        if (decodeInviteToken?.signTnc || clientData?.signTnc) {
            navigationSteps = {
                ...navigationSteps,
                ...businessAndLegalStep,
            };
        }

        if (isTakerUser) {
            navigationSteps = {
                ...navigationSteps,
                ...liquidityProvidersStep,
            };
        }
        if (isMakerUser || isPrimeBrokerUser) {
            navigationSteps = {
                ...navigationSteps,
                ...apiIntegrationStep,
            };
        }

        return navigationSteps;
    }, [isTakerUser, isMakerUser, isPrimeBrokerUser, clientData, decodeInviteToken]);
};

const OnboardingMenuLayout: React.FC = ({ children }) => {
    const { colors } = useTheme();
    const settings = useSettings();

    return (
        <Container>
            <span>
                <Logo height={settings.logo_height} />
            </span>
            <P color={colors.ui72}>Onboarding</P>
            {children}
            <Spacer />
            <OnboardingFooterContent>
                <LinksContainer>
                    {settings.footer_links && (
                        <>
                            <LinkWrapper>
                                <LinkFAQ />
                            </LinkWrapper>
                            <LinkWrapper>
                                <LinkLegal />
                            </LinkWrapper>
                        </>
                    )}
                    <LinkWrapper>
                        <LinkSupport
                            caption={settings.footer_links ? undefined : settings.support_email}
                        />
                    </LinkWrapper>
                </LinksContainer>
                <PSmall color={colors.ui72}>
                    <Copyright />
                </PSmall>
            </OnboardingFooterContent>
        </Container>
    );
};

export const OnboardingMenu = memo<{ currentStep?: ONBOARDING_STEPS | null }>(({ currentStep }) => {
    const { isMobile } = useSelector((state) => state.app);
    const { isTermsAndConditionsDelegated } = useSelector(
        (state) => state.onboarding.onboardingStages,
    );
    const isPostOnboardingRequired = useIsPostOnboardingRequired();
    const isUserReturnedToSigningTAndC =
        currentStep === ONBOARDING_STEPS.BL_TERMS_AND_CONDITIONS && isTermsAndConditionsDelegated;
    const navigationSteps = useNavigationSteps();
    const isNavigationVisible =
        !isPostOnboardingRequired &&
        IS_ONBOARDING_ACTIVE &&
        !isUserReturnedToSigningTAndC &&
        Object.keys(navigationSteps).length > 1;

    if (!currentStep) {
        return isMobile ? null : <OnboardingMenuLayout />;
    }

    const getStatus = (navigationStepType: OnboardingStepType, currentStep: ONBOARDING_STEPS) => {
        const currentStepObj = OnboardingMap[currentStep];

        if (currentStepObj.type === navigationStepType) {
            return "active";
        }

        const navigationStepOrder = navigationSteps[navigationStepType].order;
        const currentStepOrder = navigationSteps[currentStepObj.type]?.order;

        if (currentStepOrder > navigationStepOrder) {
            return "done";
        }

        return "waiting";
    };

    const getNavigationSteps = (isMobile: boolean) => {
        return Object.entries(navigationSteps).map(
            ([navigationStepType, { mobileTitle, title }], index) => (
                <MenuStep
                    key={index}
                    order={index + 1}
                    title={isMobile ? mobileTitle : title}
                    status={getStatus(navigationStepType as OnboardingStepType, currentStep)}
                />
            ),
        );
    };

    return isMobile ? (
        <MobileContainer>{isNavigationVisible && getNavigationSteps(true)}</MobileContainer>
    ) : (
        <OnboardingMenuLayout>
            {isNavigationVisible && (
                <OnboardingMenuContent>{getNavigationSteps(false)}</OnboardingMenuContent>
            )}
        </OnboardingMenuLayout>
    );
});
