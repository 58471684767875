import {
    Flex,
    FormTab,
    FormTabList,
    IconButton,
    Icons,
    PrimaryButton,
    Search,
    TabContext,
    Tooltip,
    VStack,
} from "@fm-frontend/uikit";
import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { CurrencyDropdownSheet } from "components/CurrencySheet";
import { CurrencyTriggerEssence } from "components/CurrencyTriggerEssence";
import { useMemo, useState, VFC } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { SubaccountTabs } from "feature/specificSubaccountAndPBPagesContents/const";
import { useFilterState } from "hooks/useFilterState";
import { use2WayBinding } from "hooks/use2WayBinding";
import { getTabNumericLabel } from "utils";
import { Transaction, TransactionStatus } from "../types";
import { useFilteredTransactions } from "../useFilteredTransactions";
import { TransactionsTable } from "./TransactionsTable";
import { useActions } from "./useActions";

const Container = styled(Flex)`
    overflow: auto;
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

const Header = styled.div`
    margin: 12px 12px 12px 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

const HeaderActions = styled.div`
    display: flex;
    gap: 8px;
    padding-top: 12px;
    padding-left: 4px;

    .search {
        width: 160px;

        input::placeholder {
            font-size: 12px;
        }
    }
`;

type FilterValue = {
    statusFilter: TransactionStatus[];
    assetFilter: Transaction["asset"][];
};

const STATUS_OPTIONS: DropdownOption<TransactionStatus>[] = [
    {
        text: "Created",
        value: TransactionStatus.created,
    },
    {
        text: "Sent",
        value: TransactionStatus.sent,
    },
    {
        text: "Received",
        value: TransactionStatus.received,
    },
    {
        text: "Committed",
        value: TransactionStatus.committed,
    },
    {
        text: "Cancelled",
        value: TransactionStatus.cancelled,
    },
];

const PAGE_FILTER_KEY = "subaccount_transactions";

export const SubaccountTransactionsPageContent: VFC = () => {
    const allTabsData = useFilteredTransactions(true);

    const [selectedFilterTab, setSelectedFilterTab] = useFilterState<SubaccountTabs>(
        PAGE_FILTER_KEY,
        "tab",
        SubaccountTabs.ALL,
        { availableValues: Object.values(SubaccountTabs) },
    );
    const { sendDeposit, createDeposit, removeTransaction, actionsContent } = useActions();
    const [searchQuery, setSearchQuery] = useState("");

    const [assetFilter, setAssetFilter, deleteAssetFilter] = useFilterState<Transaction["asset"][]>(
        PAGE_FILTER_KEY,
        "asset",
        [],
        { isArray: true, availableValues: allTabsData.isLoading ? undefined : allTabsData[SubaccountTabs.ALL].map(({ asset }) => asset) },
    );
    const [statusFilter, setStatusFilter, deleteStatusFilter] = useFilterState<TransactionStatus[]>(
        PAGE_FILTER_KEY,
        "status",
        [],
        { isArray: true, availableValues: Object.values(TransactionStatus) },
    );

    const {
        control,
        watch,
        setValue,
    } = useForm<FilterValue>({
        defaultValues: {
            assetFilter,
            statusFilter,
        },
    });

    const statusFilterValue = watch("statusFilter");
    use2WayBinding(
        statusFilterValue,
        (filterValue) => setValue("statusFilter", filterValue),
        statusFilter,
        setStatusFilter,
    );

    const assetFilterValue = watch("assetFilter");
    use2WayBinding(
        assetFilterValue,
        (filterValue) => setValue("assetFilter", filterValue),
        assetFilter,
        setAssetFilter,
    );

    const areFiltersDirty =
        statusFilterValue.length !== 0 ||
        assetFilterValue.length !== 0;

    const resetFilter = () => {
        setSearchQuery("");
        deleteAssetFilter();
        deleteStatusFilter();
    };

    const assetFilterOptions: DropdownOption<Transaction["asset"]>[] = [
        ...new Set(allTabsData[SubaccountTabs.ALL].map(({ asset }) => asset)),
    ].map((asset) => ({
        text: asset.toLocaleUpperCase(),
        value: asset,
    }));

    const transactions = allTabsData[selectedFilterTab];

    const filteredTransactions = useMemo(
        () =>
            transactions.filter(({ comment, asset, network, status, txId }) => {
                let matched =
                    (statusFilter.length === 0 || statusFilter.includes(status)) &&
                    (assetFilter.length === 0 || assetFilter.includes(asset));

                const query = searchQuery.trim().toLocaleLowerCase();
                if (matched && query) {
                    matched =
                        comment.toLocaleLowerCase().includes(query) ||
                        Boolean(asset?.toLocaleLowerCase().includes(query)) ||
                        Boolean(network?.toLocaleLowerCase().includes(query)) ||
                        txId.toLocaleLowerCase().includes(query);
                }

                return matched;
            }),
        [transactions, selectedFilterTab, searchQuery, statusFilter, assetFilter],
    );

    return (
        <Container spacing={8} paddingLeft={8} paddingRight={8}>
            <CardContainer flex={1} asCard minWidth="445px">
                <Header>
                    <Flex>
                        <TabContext
                            value={selectedFilterTab}
                            handleClick={setSelectedFilterTab}
                        >
                            <FormTabList>
                                <FormTab
                                    title="All"
                                    value={SubaccountTabs.ALL}
                                />
                                <FormTab
                                    title="Actionable"
                                    label={getTabNumericLabel(allTabsData[SubaccountTabs.ACTIONABLE].length)}
                                    value={SubaccountTabs.ACTIONABLE}
                                />
                                <FormTab
                                    title="Deposits"
                                    value={SubaccountTabs.DEPOSITS}
                                />
                                <FormTab
                                    title="Withdrawals"
                                    value={SubaccountTabs.WITHDRAWALS}
                                />
                            </FormTabList>
                        </TabContext>
                        <PrimaryButton size="small" onClick={createDeposit}>
                            Add deposit
                        </PrimaryButton>
                    </Flex>
                    <HeaderActions>
                        <Search
                            size="small"
                            placeholder="Search"
                            query={searchQuery}
                            onChange={setSearchQuery}
                            className="search"
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <MultipleDropdown
                                    values={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <MultipleDropdown.Trigger
                                            {...trigger}
                                            size="small"
                                            variant="simple"
                                        >
                                            <CurrencyTriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOptions}
                                                noIcon
                                                size="small"
                                            />
                                        </MultipleDropdown.Trigger>
                                    )}
                                    options={assetFilterOptions}
                                    align="end"
                                    caption="Asset"
                                    asFilter
                                >
                                    <CurrencyDropdownSheet
                                        size="medium"
                                        options={assetFilterOptions}
                                        Dropdown={MultipleDropdown}
                                    />
                                </MultipleDropdown>
                            )}
                            name="assetFilter"
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <MultipleDropdown
                                    values={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <MultipleDropdown.Trigger
                                            {...trigger}
                                            size="small"
                                            variant="simple"
                                        >
                                            <MultipleDropdown.TriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOptions}
                                                size="small"
                                            />
                                        </MultipleDropdown.Trigger>
                                    )}
                                    options={STATUS_OPTIONS}
                                    align="end"
                                    caption="Status"
                                    asFilter
                                >
                                    <MultipleDropdown.BasicSheet
                                        size="small"
                                        options={STATUS_OPTIONS}
                                    />
                                </MultipleDropdown>
                            )}
                            name="statusFilter"
                        />
                        {areFiltersDirty && (
                            <Tooltip content="Reset filter" align="center">
                                <IconButton
                                    variant="plain"
                                    type="button"
                                    Icon={Icons.Recent}
                                    onClick={resetFilter}
                                />
                            </Tooltip>
                        )}
                    </HeaderActions>
                </Header>
                <TransactionsTable
                    data={filteredTransactions}
                    onSendDepositClick={sendDeposit}
                    onRemoveTransactionClick={removeTransaction}
                    isLoading={allTabsData.isLoading}
                />
            </CardContainer>
            {actionsContent}
        </Container>
    );
};
