import { Flex, HStack, Icons, PSmall, PSmallBold, VStack } from "@fm-frontend/uikit";
import styled, { useTheme } from "styled-components";
import { FakeButtonIndicator } from "./components";

const Content = styled(VStack)`
    flex: 1;
    padding: 0 24px;
    gap: 12px;
    justify-content: center;
    align-items: center;
`;

const Description = styled(VStack)`
    gap: 4px;
    color: ${(p) => p.theme.colors.ui52};
    text-align: center;
`;

const FakeActions = styled(HStack)`
    width: 100%;
    position: relative;
    height: 57px;
    border-top: 1px solid ${(p) => p.theme.colors.ui8};
`;

const LeftFakeButton = styled(Flex)`
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const RightFakeButton = styled(LeftFakeButton)`
    border-left: 1px solid ${(p) => p.theme.colors.ui8};
`;

const Layout = styled.button`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    cursor: pointer;

    position: relative;
    border-radius: 12px;
    background-color: ${(p) => p.theme.colors.ui4};
    height: 218px;

    :hover {
        background-color: ${(p) => p.theme.colors.ui8};
    }
`;

export const AddInstrumentTile = ({
    onAddInstrumentClick,
}: {
    onAddInstrumentClick: () => void;
}) => {
    const { colors } = useTheme();

    return (
        <Layout type="button" onClick={onAddInstrumentClick}>
            <Content>
                <HStack spacing={4} justifyContent="center">
                    <Icons.Plus color={colors.ui32} />
                    <PSmallBold>Add new instrument</PSmallBold>
                </HStack>
                <Description>
                    <PSmall>
                        The instrument will be available in the selected trading mode after adding
                        it
                    </PSmall>
                </Description>
            </Content>
            <FakeActions>
                <LeftFakeButton>
                    <FakeButtonIndicator $isSell />
                </LeftFakeButton>
                <RightFakeButton>
                    <FakeButtonIndicator />
                </RightFakeButton>
            </FakeActions>
        </Layout>
    );
};
