import { H3, VStack } from "@fm-frontend/uikit";
import styled from "styled-components";
import { HintsHeader } from "components/modal/Hints/HintsHeader";
import { HintsContainer } from "components/modal/Hints/HintsContainer";
import { GroupContent } from "../styled";
import { Hint } from "../types";
import { IncomingRequests } from "./IncomingRequests";
import { ShortPositions } from "./ShortPositions";

const Group = styled(VStack)`
    height: 50%;
`;

export const TransactionHints = ({ onHintSelected }: { onHintSelected: (hint: Hint) => void }) => (
    <HintsContainer>
        <Group>
            <HintsHeader>
                <H3>Incoming requests</H3>
            </HintsHeader>
            <GroupContent>
                <IncomingRequests onRequestSelected={onHintSelected} />
            </GroupContent>
        </Group>
        <Group>
            <HintsHeader>
                <H3>Current short positions</H3>
            </HintsHeader>
            <GroupContent>
                <ShortPositions onPositionSelected={onHintSelected} />
            </GroupContent>
        </Group>
    </HintsContainer>
);
