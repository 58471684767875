import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
    gap: 4px;
`;

export const FormTabList: React.FC = (props) => {
    const { children } = props;
    return <Container>{children}</Container>;
};
