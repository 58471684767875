import { HStack, Icons, PSmall, Tooltip } from "@fm-frontend/uikit";
import React from "react";
import { useTheme } from "styled-components";

export const HintCell: React.FC<{ text: string; hint: string }> = ({ text, hint }) => {
    const { colors } = useTheme();

    return (
        <HStack spacing={6} alignItems="center">
            <PSmall color={colors.ui72}>{text}</PSmall>
            <Tooltip content={hint}>
                <Icons.TooltipQuestion />
            </Tooltip>
        </HStack>
    );
};
