import { useIsRfqTradingEnabled, useIsRfqViewingEnabled } from "store/useRfqSettings";
import { MasterRfqTrading } from "./MasterRfqTrading";
import { TakerRfqTilesTrading } from "./TakerRfqTilesTrading";

export const RfqTilesTrading = () => {
    const isRfqTradingEnabled = useIsRfqTradingEnabled();
    const isRfqViewingEnabled = useIsRfqViewingEnabled();

    if (isRfqTradingEnabled) {
        return <TakerRfqTilesTrading />;
    }

    if (isRfqViewingEnabled) {
        return <MasterRfqTrading />;
    }

    return <>Not supported</>;
};
