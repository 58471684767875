import { updatePassword } from "@aws-amplify/auth";
import { PrimaryButton } from "@fm-frontend/uikit";
import { getEnv, useFormCloseConfirmer } from "@fm-frontend/utils";
import { RESET_PASSWORD_MODAL_KEY } from "const";
import { CommonValidation, getPasswordField } from "feature/form/fields";
import FormInput from "feature/form/FormInput";
import { FormHeader, FormHint, FormLabel } from "feature/form/style";
import { FormConfig, useForm } from "feature/form/useForm";
import { useFormHelpers } from "hooks";
import { useStepper } from "hooks/useStepper";
import React, { useState } from "react";
import { ButtonGroup, Form } from "style";
import { selectors } from "style/theme";
import styled from "styled-components";

export const Text = styled.div`
    padding: 8px 0;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: ${selectors.textSubdued};

    b {
        font-weight: normal;
        color: ${selectors.textFG};
    }
    a {
        font-weight: 600;
        color: ${(p) => p.theme.colors.brand};
    }
    strong {
        font-weight: normal;
        color: ${selectors.textFG};
    }
`;

export const PasswordChangeForm: React.FC<{
    onSuccess?: () => void;
}> = ({ onSuccess }) => {
    const [isDirty, setIsDirty] = useState(false);

    const passwordForm: FormConfig = {
        fields: {
            password: getPasswordField({
                autoComplete: "current-password",
            }),
            newPassword: getPasswordField({
                validtion: true,
                autoComplete: "new-password",
            }),
            newPasswordRepeat: getPasswordField({
                validtion: true,
                autoComplete: "new-password",
            }),
            code: {},
        },
        validate: CommonValidation.passwordsMatch,
        url: "changePassword",
        onChange: () => {
            setIsDirty(true);
        },
    };

    useFormCloseConfirmer(RESET_PASSWORD_MODAL_KEY, isDirty);

    const { propsFor, globalError, areActionsDisabled, values } = useForm(passwordForm);
    const { currentStep, next } = useStepper();
    const { startLoading, stopLoading, isLoading } = useFormHelpers();

    const handleChangePassword = async (e: React.MouseEvent) => {
        e.preventDefault();
        startLoading();

        try {
            await updatePassword({
                oldPassword: values.password as string,
                newPassword: values.newPassword as string,
            });
            next();
        } finally {
            stopLoading();
        }
    };

    return [
        <>
            <Form>
                <FormHeader>Change password</FormHeader>
                <FormLabel>Current password</FormLabel>
                <FormInput {...propsFor("password")} type="password" />
                <FormLabel>New password</FormLabel>
                <FormInput {...propsFor("newPassword")} type="password" />
                <FormHint>
                    Password must be at least 10 characters, contain one uppercase and one digit
                </FormHint>
                <FormLabel>Repeat password</FormLabel>
                <FormInput {...propsFor("newPasswordRepeat")} type="password" />
                {globalError}
                <ButtonGroup>
                    <PrimaryButton
                        size="large"
                        type="submit"
                        disabled={areActionsDisabled}
                        onClick={handleChangePassword}
                        loading={isLoading}
                        fullWidth
                    >
                        Submit
                    </PrimaryButton>
                </ButtonGroup>
            </Form>
        </>,
        <Form>
            <FormHeader>Password changed successfully</FormHeader>
            <Text>Well done! You can now use your new password to log into your account.</Text>
            <ButtonGroup>
                <PrimaryButton
                    size="large"
                    fullWidth
                    type="submit"
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        onSuccess?.();
                    }}
                >
                    Back to {getEnv().PROJECT_NAME}
                </PrimaryButton>
            </ButtonGroup>
        </Form>,
    ][currentStep];
};
