import { useMemo } from "react";
import {
    useFilteredAndExtendedPositionsSnapshot,
    useFilteredAndExtendedSettlementOrdersSnapshot,
    useUsdPrices,
} from "store/hooks";
import { SettlementOrder } from "types";
import { Empty, Loading } from "../styled";
import { PositionOption } from "./PositionOption";

const prepareSettlementOrder = ({ currency1, currency2, size1, size2, counterpartyId }: SettlementOrder) => ({
    currency: currency1 || currency2 || "",
    size: BigInt(size1 || size2 || 0),
    counterpartyId,
});

export const OpenPosition = ({
    onAmountSelected,
    cpId,
    asset,
}: {
    onAmountSelected: (amount: bigint) => void;
    cpId: number;
    asset: string;
}) => {
    const { priceObj, isLoading: usdPricesLoading } = useUsdPrices();
    const { data: filteredExtendedPositionsSnapshot, isLoading: isPositionsLoading } =
        useFilteredAndExtendedPositionsSnapshot();
    const { data: filteredSettlementOrdersSnapshot, isLoading: isSettlementsLoading } =
        useFilteredAndExtendedSettlementOrdersSnapshot();
    const isLoading = isPositionsLoading || usdPricesLoading || isSettlementsLoading;
    const { current, planned } = useMemo(() => {
        if (isLoading) {
            return {
                current: null,
                planned: null,
            };
        }

        const cpPosition = filteredExtendedPositionsSnapshot.find(
            ({ position: [currency, , counterparty] }) => currency === asset && counterparty === cpId,
        )?.position;
        if (!cpPosition) {
            return {
                current: null,
                planned: null,
            };
        }

        const current = {
            value: BigInt(cpPosition[1]),
            usdValue: (BigInt(priceObj[cpPosition[0]]) * BigInt(cpPosition[1])) / BigInt(1e8),
        };
        let planned = { ...current };
        for (const { currency, size, counterpartyId } of filteredSettlementOrdersSnapshot.map(({ settlementOrder }) =>
            prepareSettlementOrder(settlementOrder),
        )) {
            if (!priceObj[currency] || currency !== asset || counterpartyId !== cpId) continue;
            planned.value += size;
        }
        planned.usdValue = (planned.value * BigInt(priceObj[cpPosition[0]])) / BigInt(1e8);

        return {
            current,
            planned,
        };
    }, [filteredExtendedPositionsSnapshot, priceObj, cpId, asset, filteredSettlementOrdersSnapshot, isLoading]);

    if (isLoading) {
        return <Loading />;
    }

    if (current === null) {
        return <Empty message="No open positions" />;
    }

    return (
        <>
            <PositionOption
                onAmountSelected={onAmountSelected}
                value={current.value}
                usdValue={current.usdValue}
                title="Current"
                asset={asset}
            />
            <PositionOption
                onAmountSelected={onAmountSelected}
                value={planned.value}
                usdValue={planned.usdValue}
                title="Planned"
                asset={asset}
            />
        </>
    );
};
