import { use } from "utils/use";

type Settings = {
    support_email?: string;
    copyright_from_date?: number | null;
    footer_links?: boolean;
    logo_height?: number;
    privacy_policy_link?: string | null;
    faq_link?: string | null;
    display_name?: string | null;
};

const request = fetch("/settings.json?v=5").then<Settings>((res) => res.json());

export const useSettings = () => use(request) ?? {};
