import { HStack, Icons } from "@fm-frontend/uikit";
import { CopyToClipboard } from "components/CopyToClipboard";
import styled, { useTheme } from "styled-components";

const StyledCopyToClipboard = styled(CopyToClipboard)`
    color: inherit;
`;

const Container = styled(HStack)`
    color: inherit;
    svg {
        opacity: 0;
    }

    :hover {
        svg {
            opacity: 1;
        }
    }
`;

export const CopyableValue = ({
    value,
    title,
    stopPropagation = false,
    hideIcon,
}: {
    value: string;
    title?: string;
    stopPropagation?: boolean;
    hideIcon?: boolean;
}) => {
    const theme = useTheme();

    return (
        <StyledCopyToClipboard value={value} stopPropagation={stopPropagation}>
            <Container spacing={2} alignItems="center">
                <span>{title ?? value}</span>
                {!hideIcon && <Icons.Copy color={theme.colors.ui72} />}
            </Container>
        </StyledCopyToClipboard>
    );
};
