import { ClientId, HStack, PBold } from "@fm-frontend/uikit";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { Settlement } from "../../../../../types";
import { Statistic } from "./Statistic";

export const CpItem = ({ settlement }: { settlement: Settlement }) => {
    const { cpId, type } = settlement;
    const { getCpName } = useCpInfoHelpers();
    const name = getCpName(cpId, "full");
    const title = type === "in" ? "From" : "To";

    return (
        <Statistic
            title={title}
            value={
                <HStack spacing={6} alignItems="center">
                    <PBold>{name}</PBold>
                    <ClientId id={cpId} />
                </HStack>
            }
        />
    );
};
