import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

type OrderTypeSelectorProps<T extends FieldValues> = {
    control: Control<T>;
    name: Path<T>;
};
export const OrderTypeValues = [
    "LIMIT",
    "POST_ONLY",
    "LIMIT_IOC",
    "LIMIT_FOK",
    "MARKET_IOC",
    "MARKET_FOK",
    "MANUAL_TRADE",
    "LIQUIDATION_TRADE",
    "RFQ",
];
const enum OrderTypeApi {
    Limit = "LIMIT",
    PostOnly = "POST_ONLY",
    LimitIOC = "LIMIT_IOC",
    LimitFOK = "LIMIT_FOK",
    MarketIOC = "MARKET_IOC",
    MarketFOK = "MARKET_FOK",
    ManualTrade = "MANUAL_TRADE",
    LiquidationTrade = "LIQUIDATION_TRADE",
    Rfq = "RFQ",
}
const OPTIONS: DropdownOption<OrderTypeApi>[] = [
    { text: "Limit", value: OrderTypeApi.Limit },
    { text: "Post only", value: OrderTypeApi.PostOnly },
    { text: "Limit IOC", value: OrderTypeApi.LimitIOC },
    { text: "Limit FOK", value: OrderTypeApi.LimitFOK },
    { text: "Market IOC", value: OrderTypeApi.MarketIOC },
    { text: "Market FOK", value: OrderTypeApi.MarketFOK },
    { text: "Manual", value: OrderTypeApi.ManualTrade },
    { text: "Liquidation", value: OrderTypeApi.LiquidationTrade },
    { text: "Rfq", value: OrderTypeApi.Rfq },
];

export const OrderTypeSelector = <T extends FieldValues>({
    control,
    name,
}: OrderTypeSelectorProps<T>) => {
    return (
        <Controller
            control={control}
            render={({ field }) => (
                <MultipleDropdown
                    values={field.value}
                    onChange={field.onChange}
                    renderTrigger={(trigger) => (
                        <MultipleDropdown.Trigger {...trigger} size="small" variant="simple">
                            <MultipleDropdown.TriggerEssence
                                {...trigger}
                                option={trigger.selectedOptions}
                                size="small"
                            />
                        </MultipleDropdown.Trigger>
                    )}
                    options={OPTIONS}
                    align="end"
                    caption="Order types"
                    asFilter
                >
                    <MultipleDropdown.BasicSheet size="small" options={OPTIONS} />
                </MultipleDropdown>
            )}
            name={name}
        />
    );
};
