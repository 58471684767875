import { useEffect, useRef, useState } from "react";

export const useHeightToBottom = () => {
    const ref = useRef<HTMLDivElement>(null);
    const [heightToBottom, setHeightToBottom] = useState(0);

    useEffect(() => {
        const calculateHeightToBottom = () => {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                const topPosition = rect.top + window.scrollY;
                setHeightToBottom(window.innerHeight - topPosition);
            }
        };

        setTimeout(calculateHeightToBottom, 0);

        window.addEventListener("resize", calculateHeightToBottom);

        return () => {
            window.removeEventListener("resize", calculateHeightToBottom);
        };
    }, []);

    return { ref, heightToBottom };
};
