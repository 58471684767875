import { ValidationMessages } from "utils/validationMessages";
import { AnySchema, object, string } from "yup";

export interface ConfirmMfaInputs {
    sharedSecret: string;
    code: string;
}

export const confirmTwoFaDataSchema = object<Record<keyof ConfirmMfaInputs, AnySchema>>().shape({
    code: string()
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim())
        .matches(/^\d{6}$/, "Code must be 6-digit"),
});
