import { PSmall, VStack } from "@fm-frontend/uikit";
import styled from "styled-components";
import { selectors } from "style/theme";

interface StatisticProps {
    title: string;
    value: string | React.ReactNode;
}

const Value = styled.div`
    ${selectors.mixinPBold}
    word-break: break-all;
`;

export const Statistic = ({ title, value }: StatisticProps) => (
    <VStack spacing={2}>
        <PSmall>{title}</PSmall>
        <Value>{value}</Value>
    </VStack>
);
