export const getMatch = (
    input: string | undefined,
    match: string,
    getHighlightedText = (str: string) => <mark>{str}</mark>,
) => {
    if (!input || match === "") {
        return input;
    }

    const normalizedInput = input.toLowerCase();
    const normalizedMatch = match.toLowerCase();
    const parts = normalizedInput.split(normalizedMatch);

    if (parts.length < 2) {
        return;
    }

    const { result } = parts.reduce(
        (acc, part, partIndex) => {
            const matchPart = (
                <>
                    {acc.result}
                    {input.substring(acc.currentIdx, acc.currentIdx + part.length)}
                    {partIndex !== parts.length - 1 &&
                        getHighlightedText(
                            input.substring(acc.currentIdx + part.length, acc.currentIdx + part.length + match.length),
                        )}
                </>
            );

            return { result: matchPart, currentIdx: acc.currentIdx + part.length + match.length };
        },
        { result: <></>, currentIdx: 0 },
    );

    return result;
};

export const getMatchStartsWith = (input: string, match: string) => {
    if (match === "") {
        return;
    }

    if (input.toUpperCase().startsWith(match.toUpperCase())) {
        return (
            <>
                <mark>{input.substring(0, match.length)}</mark>
                {input.substring(match.length)}
            </>
        );
    }
};
