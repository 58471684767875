import { P } from "@fm-frontend/uikit";
import { EmDash } from "@fm-frontend/utils";
import React from "react";
import { useTheme } from "styled-components";

export const NetworkCell: React.FC<{ network: string | undefined }> = ({ network }) => {
    const { colors } = useTheme();

    return <P color={colors.ui52}>{network ?? EmDash}</P>;
};
