import styled, { keyframes } from "styled-components";

export const ANIMATION_TIMEOUT = 200;

const inAnimation = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;
const outAnimation = keyframes`
    0% { opacity: 1; }
    100% { opacity: 0; }
`;

export const PopoverContent = styled.div<{ opened: boolean }>`
    animation-name: ${(p) => (p.opened ? inAnimation : outAnimation)};
    animation-duration: ${ANIMATION_TIMEOUT}ms;
`;
