import { Mark } from "@fm-frontend/uikit";
import { DropdownOption } from "@fm-frontend/uikit/src/components/v2";
import { post } from "api";
import { EmDash } from "const";
import { POSITION_COUNTERPARTY_ID } from "const/position";
import { useMemo } from "react";
import { useCounterpartyLimitsSnapshot } from "store/hooks";
import { StatusKYBVariant } from "store/onboardingSlice";
import useSWR from "swr";
import { EmailType, MakerInfo, OnboardingCurrencyType } from "types";
import { displayError } from "utils";
import { isHighestAsset, isLowestAsset } from "../common";

export interface LimitInputs {
    currency: string;
    grossLimit: string | bigint;
}

const LIST_ITEMS_NUMBER = 3;
export const BANKS_LIST_ITEMS_NUMBER = 2;

export const getJurisdictionData = ({ jurisdiction }: MakerInfo) => {
    const markData =
        jurisdiction.length > LIST_ITEMS_NUMBER ? `+${jurisdiction.length - LIST_ITEMS_NUMBER}` : undefined;
    const label = jurisdiction
        .map((j) => j.name)
        .slice(0, LIST_ITEMS_NUMBER)
        .join(", ");

    return {
        title: "Jurisdiction",
        label: label ? label : EmDash,
        mark: <Mark weight={500}>{markData}</Mark>,
    };
};

export const getCryptoData = ({ currency }: MakerInfo) => {
    const cryptoAssets = currency
        .filter((c) => !c.is_fiat)
        .sort((left, right) => {
            const leftIsHighestAsset = isHighestAsset(left.currency_group.name);
            const rightIsHighestAsset = isHighestAsset(right.currency_group.name);

            if (leftIsHighestAsset && rightIsHighestAsset) {
                return left.name.localeCompare(right.name);
            }
            if (leftIsHighestAsset) {
                return -1;
            }
            if (rightIsHighestAsset) {
                return 1;
            }

            return left.name.localeCompare(right.name);
        });

    const cryptoCount = cryptoAssets.length;
    const markData = cryptoCount > LIST_ITEMS_NUMBER ? `+${cryptoCount - LIST_ITEMS_NUMBER}` : undefined;
    const label = cryptoAssets
        .map((c) => c.name)
        .slice(0, LIST_ITEMS_NUMBER)
        .join(", ");

    return {
        title: "Crypto",
        label: label ? label : EmDash,
        mark: <Mark weight={500}>{markData}</Mark>,
    };
};

export const getFiatData = ({ currency }: MakerInfo) => {
    const fiatAssets = currency.filter((c) => c.is_fiat);
    const fiatCount = fiatAssets.length;
    const markData = fiatCount > LIST_ITEMS_NUMBER ? `+${fiatCount - LIST_ITEMS_NUMBER}` : undefined;
    const label = fiatAssets
        .map((c) => c.name)
        .slice(0, LIST_ITEMS_NUMBER)
        .join(", ");

    return {
        title: "Fiat",
        label: label ? label : EmDash,
        mark: <Mark weight={500}>{markData}</Mark>,
    };
};

export const getRegulationData = ({ regulation }: MakerInfo) => {
    return {
        title: "Regulation",
        label: regulation ?? EmDash,
    };
};

export const getBanksData = ({ bank_request, bank }: MakerInfo, banksItemNumber?: number) => {
    const banksCount = bank.length;

    if (!banksItemNumber) {
        banksItemNumber = banksCount;
    }

    const markData = banksCount > banksItemNumber ? `+${banksCount - banksItemNumber}` : undefined;
    const label = bank
        .map((b) => b.name)
        .slice(0, banksItemNumber)
        .join(", ");

    return {
        title: "Banks",
        label: bank_request ? "On request" : label ? label : EmDash,
        mark: <Mark weight={500}>{markData}</Mark>,
    };
};

export const getSettlementsData = ({ settlements }: MakerInfo) => {
    return {
        title: "Settlement",
        label: settlements ?? EmDash,
    };
};

export const mapAssets = (currency: OnboardingCurrencyType[]) => {
    const mappedAssets = currency.reduce((acc, { currency_group: { name: groupName = "" }, name, id, venue }) => {
        const item: DropdownOption<string, { network?: string }> = {
            value: String(id),
            text: name,
            network: venue,
        };

        acc[groupName] ??= [];
        acc[groupName].push(item);

        return acc;
    }, {} as Record<string, DropdownOption[]>);

    return Object.keys(mappedAssets)
        .sort((left, right) => {
            if (isHighestAsset(left) || isLowestAsset(right)) {
                return -1;
            }
            if (isHighestAsset(right) || isLowestAsset(left)) {
                return 1;
            }

            return left.localeCompare(right);
        })
        .reduce((acc, key) => {
            acc[key] = mappedAssets[key];
            return acc;
        }, {} as Record<string, DropdownOption[]>);
};

export const useOnboardingLimits = (makerIds: number[]) => {
    const { data: counterpartyLimits } = useCounterpartyLimitsSnapshot();

    const cpLimits = useMemo(() => {
        return makerIds.reduce((acc, makerId) => {
            const limit = counterpartyLimits.find((cp) => cp[POSITION_COUNTERPARTY_ID] === makerId) ?? [];

            const cpLimit: LimitInputs = {
                currency: limit[1] ?? "",
                grossLimit: limit[2] ? BigInt(limit[2]) : 0n,
            };

            if (cpLimit.grossLimit !== 0n) {
                acc[makerId] = cpLimit;
            }

            return acc;
        }, {} as Record<number, LimitInputs>);
    }, [counterpartyLimits, makerIds]);

    return {
        cpLimits,
    };
};

export const useMakerEmails = (clientId: number, isEnabled: boolean) => {
    const fetcher = () =>
        post("onboarding/emailKYB", {
            clientId,
        });

    const key = isEnabled ? "/api/onboarding/emailKYB" : null;

    const { data, isValidating } = useSWR<EmailType[]>(key, fetcher, {
        onError: displayError,
        fallbackData: [],
    });
    return {
        emails: data ?? [],
        isLoading: isValidating,
    };
};

export const isObjectsEqual = (obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const sortLps = (left: MakerInfo, right: MakerInfo, statusKYB: Record<number, StatusKYBVariant>) => {
    let lStatus = statusKYB[left.id] ?? StatusKYBVariant.NotStarted;
    let rStatus = statusKYB[right.id] ?? StatusKYBVariant.NotStarted;

    if (lStatus === StatusKYBVariant.Cancelled) {
        lStatus = StatusKYBVariant.NotStarted;
    }
    if (rStatus === StatusKYBVariant.Cancelled) {
        rStatus = StatusKYBVariant.NotStarted;
    }

    return rStatus - lStatus;
};
