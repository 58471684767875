import { onMobile, onTablet } from "const/index";
import { DummyButton } from "feature/form/Buttons";
import { TableContainer } from "feature/table";
import { selectors } from "style/theme";
import styled from "styled-components";

export const FormContainer = styled.section`
    display: flex;
    overflow-x: auto;
    scrollbar-width: none;
    max-width: calc(100vw - 194px);
    align-items: flex-start;
    min-width: 100%;
    min-height: 274px;
    padding: 2px;
    margin: -2px;
    margin-bottom: 8px;

    ::-webkit-scrollbar {
        height: 6px;
        margin: 2px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
        margin: 10px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: ${(p) => p.theme.colors.selectBG};
        &:hover {
            background: ${(p) => p.theme.colors.selectBgHover};
        }
    }
    & > div {
        position: relative;
    }
    form {
        margin-right: 8px;
        margin-bottom: 8px;
        & > div > button {
            width: 100%;
        }
    }
    ${onMobile} {
        padding-left: 12px;
    }
`;

export const Header = styled.header`
    ${selectors.mixinHeader2}
    display: flex;
    margin: 10px 12px 8px;
    img {
        margin-right: 4px;
    }
    span {
        white-space: nowrap;
    }
`;

export const EmptyThing = styled.div`
    height: 1px;
    width: 4px;
    pointer-events: none;
    user-select: none;
`;

export const Cluster = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: baseline;
    & > * {
        overflow: hidden;
        margin-right: 8px;
        flex: 1 1 590px;
    }

    & > div:first-child {
        margin-bottom: 16px;
    }

    ${TableContainer} {
        min-width: unset;
    }

    overflow-y: auto;
`;

export const Spread = styled.div`
    ${(p) => p.theme.mixins.body2}
    font-weight: 600;
    background: ${(p) => p.theme.colors.activeBG};
    color: ${(p) => p.theme.colors.textSubdued};
    padding: 4px 8px;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    font-variant: tabular-nums;
    mark {
        color: ${({ theme }) => theme.colors.textFG};
    }
`;

export const NavigationContainer = styled.section<{ isMaker?: boolean }>`
    display: flex;
    overflow: scroll;
    white-space: nowrap;
    margin-left: -12px;
    ${DummyButton}:first-of-type {
        margin-left: 20px;
    }
    ${DummyButton} + ${DummyButton} {
        margin-left: 8px;
    }
    ${onMobile} {
        max-width: unset;
        margin-left: 0;
        flex: 1;
        & > div,
        & > div > div,
        input {
            min-width: 100%;
        }
    }
    ${onTablet} {
        button:nth-of-type(3),
        button:nth-of-type(4) {
            display: none;
        }
    }
`;
