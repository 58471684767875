import { useLimitsMap, usePositionsMap } from "feature/assetsControl/components/AssetsTabContent/hooks";
import { useCounterparties } from "hooks";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useUsdPrices } from "store/hooks";
import { useCurrencies } from "store/useCurrencies";
import { getAbsoluteValue } from "utils/format";

type Percents = {
    cpId: undefined | number,
    userPercent: undefined | null | number,
    cpPercent: undefined | null | number,
};

export const useFreeLimitPercent = () => {
    const { getCpType } = useCpInfoHelpers();
    const currencies = useCurrencies();
    const { priceObj, isLoading: isUsdPriceLoading } = useUsdPrices();
    const { cpIds, isLoading: isCounterpartiesLoading } = useCounterparties();
    const { limitsMap, isLoading: isLimitsMapLoading } = useLimitsMap();
    const { positionsMap, isLoading: isPositionsMapLoading } = usePositionsMap();

    const isLoading = isCounterpartiesLoading || isLimitsMapLoading || isPositionsMapLoading || isUsdPriceLoading;

    const getLimitInfo = (
        limitCurrency: string | null,
        grossLimit: number | bigint | null,
        cpId: number,
        asset: string,
    ) => {
        if (limitCurrency === null || grossLimit === null) {
            return null;
        }

        const assetPosition = positionsMap[cpId]?.[asset] ?? 0n;
        const normalizedLimitAsset = limitCurrency.toUpperCase();
        const totalLimit = BigInt(grossLimit);
        const usdPrice = priceObj[normalizedLimitAsset] ?? 0n;
        const totalLimitUsd = (BigInt(totalLimit) * usdPrice) / BigInt(1e8);
        const positionUsd =
            (BigInt(getAbsoluteValue(assetPosition)) * (priceObj[asset] ?? 0n)) /
            BigInt(1e8);
        const freeLimitUsd = totalLimitUsd - positionUsd;
        const freeLimit = (freeLimitUsd * BigInt(1e8)) / usdPrice;

        return totalLimit > 0n ? Number((freeLimit * 100n) / totalLimit) : 0;
    }

    const getLimitsWithCpByAsset = (cpId: number, asset: string): Percents => {
        const assetLimit = limitsMap[cpId]?.[asset];

        if (assetLimit === undefined || isLoading) {
            return {
                cpId: undefined,
                userPercent: undefined,
                cpPercent: undefined,
            };
        }

        return {
            cpId,
            userPercent: getLimitInfo(
                assetLimit.limitCurrency,
                assetLimit.grossLimit,
                cpId,
                asset,
            ),
            cpPercent: getLimitInfo(
                assetLimit.cpLimitCurrency,
                assetLimit.cpGrossLimit,
                cpId,
                asset,
            ),
        };
    }

    return currencies.reduce((acc, { value: currency }) => {
        const percents = cpIds
            .filter((cpId) => getCpType(cpId) !== undefined)
            .map((cpId) => getLimitsWithCpByAsset(cpId, currency));

        acc.push(percents);

        return acc;
    }, [] as Percents[][]).flat();
};
