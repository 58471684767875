import observeRect from "@reach/observe-rect";
import { CSSProperties, useLayoutEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

type TerseRect = { x: number; y: number; width: number; height: number };

const GhostContainer = styled.div`
    position: fixed;
    user-select: none;
    pointer-events: none;
    z-index: 101;
    & > * {
        pointer-events: all;
        user-select: all;
    }
`;

const Ghost: React.FC<TerseRect> = ({ x, y, width, height, children }) => {
    const style = useMemo(() => {
        const style: CSSProperties = {
            top: y,
            left: x,
            width,
            height,
        };
        return style;
    }, [x, y, width, height]);
    return <GhostContainer style={style}>{children}</GhostContainer>;
};
export const Popover: React.FC<{ containerRef: React.RefObject<HTMLElement> }> = (props) => {
    const node = useMemo(() => {
        return document.createElement("div");
    }, []);
    const [{ x, y, width, height }, setRect] = useState<TerseRect>({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    });
    useLayoutEffect(() => {
        const modalRoot = document.getElementById("modalRoot");
        modalRoot?.appendChild(node);
        return () => void modalRoot?.removeChild(node);
    }, []);
    useLayoutEffect(() => {
        if (props.containerRef.current) {
            const { observe, unobserve } = observeRect(props.containerRef.current, ({ x, y, width, height }) => {
                setRect({ x, y, width, height });
            });
            observe();
            return () => {
                unobserve();
            };
        }
    }, []);
    return createPortal(
        <Ghost x={x} y={y} width={width} height={height}>
            {props.children}
        </Ghost>,
        node,
    );
};

export const Portal: React.FC<{}> = (props) => {
    const node = useMemo(() => {
        return document.createElement("div");
    }, []);
    useLayoutEffect(() => {
        const modalRoot = document.getElementById("modalRoot");
        modalRoot?.appendChild(node);
        return () => void modalRoot?.removeChild(node);
    }, []);
    return createPortal(props.children, node);
};
