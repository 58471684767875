import {
    BasicInput,
    Center,
    H2,
    HStack,
    Icons,
    PBold,
    PlainButton,
    PrimaryButton,
    PSmallBold,
    Textarea,
    VStack,
} from "@fm-frontend/uikit";
import { useFormCloseConfirmer, when } from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CurrencyIcon } from "components/CurrencyIcon";
import { FormError } from "feature/form/style";
import { useFormHelpers } from "hooks";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTheme } from "styled-components";
import { addOrUpdateBankAddress, deleteAddress } from "../../api";
import { FormSectionDivider } from "../../components";
import { ContentCell } from "../../components/ContentCell";
import { useAccountDeleteConfirmation } from "../../hooks/useAccountDeleteConfirmation";
import { editBankAddressSchema } from "../../schema";
import { BankAddress, BankAddressEditInputs } from "../../types";
import {
    hasCurrencyMemoOrTag,
    OTHER_DETAILS_PLACEHOLDER,
    PUBLIC_DETAILS_PLACEHOLDER,
    SAB_BANK_EDIT_MODAL_KEY,
} from "../../utils";

type EditBankAddressModalProps = {
    address: BankAddress;
    onCancel: () => void;
    onEditSuccess: () => void;
};
export const EditBankAddressModal: React.FC<EditBankAddressModalProps> = ({ address, onEditSuccess, onCancel }) => {
    const { colors } = useTheme();
    const { wallet, currency, memoOrTag, publicDetails, otherDetails, company, bankPrimary } = address;
    const { name: currencyName } = currency ?? {};
    const { name: bankPrimaryName, address: bankPrimaryAddress } = bankPrimary ?? {};
    const { name: companyName, address: companyAddress } = company ?? {};

    const { error: apiError, setError: setApiError, isLoading, startLoading, stopLoading } = useFormHelpers();
    const memoOrTagRequired = useMemo(() => hasCurrencyMemoOrTag(currencyName), [currencyName]);

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isDirty },
    } = useForm<BankAddressEditInputs>({
        defaultValues: {
            wallet: wallet,
            memoOrTag: memoOrTag,
            memoOrTagRequired: memoOrTagRequired,
            otherDetails,
            publicDetails,
            companyName,
            companyAddress,
            bankAddress: bankPrimaryAddress,
            bankName: bankPrimaryName,
        },
        mode: "onSubmit",
        resolver: yupResolver(editBankAddressSchema),
    });

    useFormCloseConfirmer(SAB_BANK_EDIT_MODAL_KEY, isDirty);
    const accountDeleteConfirmation = useAccountDeleteConfirmation();

    const handleAddressUpdate = handleSubmit(async (inputs: BankAddressEditInputs) => {
        if (address?.bankPrimary?.id === undefined) {
            throw new Error("Invalid primary bank");
        }

        const updatedAddress: BankAddress = {
            ...address,
            wallet: inputs.wallet,
            memoOrTag: inputs.memoOrTag,
            publicDetails: inputs.publicDetails,
            otherDetails: inputs.otherDetails,
            company: {
                name: inputs.companyName,
                address: inputs.companyAddress,
            },
            bankPrimary: {
                id: address.bankPrimary.id,
                name: inputs.bankName,
                address: inputs.bankAddress,
            },
        };

        const apiErrorResponse = await addOrUpdateBankAddress(updatedAddress);

        setApiError(apiErrorResponse);
        onEditSuccess();
    });

    const handleDeleteAddress = () =>
        accountDeleteConfirmation(async () => {
            if (address.accountId === undefined) {
                return;
            }

            startLoading();
            const apiErrorResponse = await deleteAddress(address.accountId, address.rule?.id);
            setApiError(apiErrorResponse);
            stopLoading();
            onEditSuccess();
        });

    return (
        <VStack minWidth="360px" asCard>
            <VStack padding={12}>
                <H2>Edit your account</H2>
            </VStack>
            <form>
                <VStack paddingBottom={12} paddingX={12} spacing={16}>
                    <VStack paddingX={12} asCard>
                        <ContentCell
                            paddingY={6}
                            spacing={4}
                            title="Asset"
                            content={currencyName}
                            renderContent={() => (
                                <HStack spacing={4} alignItems="center">
                                    <CurrencyIcon size={16} name={currencyName} />
                                    <PBold ellipsis>{currencyName}</PBold>
                                </HStack>
                            )}
                        />
                    </VStack>
                    <BasicInput
                        label="Account number/IBAN"
                        placeholder="Account number/IBAN"
                        error={errors.wallet?.message}
                        {...register("wallet")}
                    />
                    <BasicInput
                        label="BIC/SWIFT"
                        placeholder="BIC/SWIFT"
                        error={errors.memoOrTag?.message}
                        {...register("memoOrTag")}
                    />
                    <BasicInput
                        label="Bank name"
                        placeholder="000x"
                        error={errors.bankName?.message}
                        {...register("bankName")}
                    />
                    <BasicInput
                        label="Bank legal address"
                        placeholder="Bank legal address"
                        error={errors.bankAddress?.message}
                        {...register("bankAddress")}
                    />
                    <FormSectionDivider />
                    <BasicInput
                        label="Your company name"
                        placeholder="our company name"
                        error={errors.companyName?.message}
                        {...register("companyName")}
                    />
                    <BasicInput
                        label="Your company legal address"
                        placeholder="Your company legal address"
                        error={errors.companyAddress?.message}
                        {...register("companyAddress")}
                    />
                    <FormSectionDivider />
                    <VStack spacing={4}>
                        <Textarea
                            label="Other details"
                            rows={5}
                            placeholder={OTHER_DETAILS_PLACEHOLDER}
                            error={errors.otherDetails?.message}
                            {...register("otherDetails")}
                        />
                    </VStack>
                    <VStack spacing={4}>
                        <Textarea
                            label="Public description, payment instructions"
                            rows={5}
                            placeholder={PUBLIC_DETAILS_PLACEHOLDER}
                            error={errors.publicDetails?.message}
                            {...register("publicDetails")}
                        />
                    </VStack>
                    {when(apiError, <FormError>{apiError}</FormError>)}
                </VStack>
            </form>
            <Center spacing={8} paddingBottom={16}>
                <PlainButton
                    size="small"
                    disabled={isSubmitting || isLoading}
                    loading={isLoading}
                    onClick={handleDeleteAddress}
                >
                    <Icons.Bin color={colors.negative100} />
                    <PSmallBold color={colors.negative100}>Delete account</PSmallBold>
                </PlainButton>
            </Center>
            <VStack paddingX={12} paddingBottom={16} spacing={8}>
                <PrimaryButton
                    fullWidth
                    size="large"
                    type="submit"
                    onClick={handleAddressUpdate}
                    loading={isSubmitting}
                    disabled={!isDirty || isLoading}
                >
                    Save
                </PrimaryButton>
                <PlainButton fullWidth size="large" type="button" onClick={onCancel}>
                    Cancel
                </PlainButton>
            </VStack>
        </VStack>
    );
};
