import {
    Flex,
    IconButton,
    Icons,
    PrimaryButton,
    Search,
    Tab as TabItem,
    TabContext,
    TabList,
    Tooltip,
    VStack,
} from "@fm-frontend/uikit";
import { VFC } from "react";
import { useIsSubaccountUser } from "store/hooks";
import styled from "styled-components";
import { AssetsBulkEditButton } from "../../../bulkEdit/BulkEditAssets/Button";
import * as Types from "../../../types";
import { AssetControlMenu } from "../../AssetControlMenu";
import { useAssetsTabContext } from "../assetsTabContext";
import { AssetsTable } from "./AssetsTable";
import { CpFilter } from "./filters/CpFilter";
import { LimitPerAssetFilter } from "./filters/LimitPerAssetFilter";
import { OvernightsFilter } from "./filters/OvernightsFilter";
import { ShortSalesStatusFilter } from "./filters/ShortSalesStatusFilter";
import { SearchQueryContextProvider } from "./searchQueryContext";
import { useFilters } from "./useFilters";
import { useTableData } from "./useTableData";

const AssetGroupsMap: Record<Types.AssetsGroupType, string> = {
    all: "All",
    crypto: "Crypto",
    stablecoin: "Stablecoins",
    fiat: "Fiat",
};

const Container = styled(Flex)`
    overflow: auto;
    min-height: calc(100vh - 87px);
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

const TabListContainer = styled(Flex)`
    width: 300px;
    align-items: flex-start;
`;

const StyledSearch = styled(Search)`
    max-width: 160px;
`;

export const TableContent: VFC = () => {
    const { assetsControlData: data, isLoading } = useTableData();

    const {
        areFiltersDirty,
        control,
        setSearchQuery,
        searchQuery,
        delayedSearchQuery,
        selectedAssetsGroupFilter,
        setSelectedAssetsGroupFilter,
        resetFilters,
        filteredData,
    } = useFilters(data, isLoading);

    const { handleConfigureButtonClick } = useAssetsTabContext();
    const isSubaccountUser = useIsSubaccountUser();
    const isConfigureButonAvailable = !isSubaccountUser;
    const isDisabled = Boolean(isLoading || !data?.length);

    return (
        <Container spacing={8} paddingLeft={8} paddingRight={8}>
            <CardContainer flex={1} asCard minWidth="445px">
                <Flex padding={12} alignItems="flex-start">
                    <AssetControlMenu />
                    {isConfigureButonAvailable && (
                        <>
                            <AssetsBulkEditButton disabled={isDisabled} />
                            <PrimaryButton
                                size="small"
                                onClick={() =>
                                    handleConfigureButtonClick(selectedAssetsGroupFilter)
                                }
                                disabled={isDisabled}
                            >
                                Configure
                            </PrimaryButton>
                        </>
                    )}
                </Flex>
                <Flex alignItems="flex-start" paddingX={12} paddingBottom={18} spacing={6}>
                    <StyledSearch
                        size="small"
                        placeholder="Search"
                        query={searchQuery}
                        onChange={(value) => setSearchQuery(value)}
                    />
                    <TabListContainer>
                        <TabContext
                            value={selectedAssetsGroupFilter}
                            handleClick={setSelectedAssetsGroupFilter as (group: string) => void}
                        >
                            <TabList size="small">
                                {Object.entries(AssetGroupsMap).map(([key, title]) => (
                                    <TabItem title={title} value={key} key={key} />
                                ))}
                            </TabList>
                        </TabContext>
                    </TabListContainer>
                    {!isSubaccountUser && (
                        <CpFilter
                            control={control}
                            counterparties={data[0]?.counterparties ?? []}
                        />
                    )}
                    <ShortSalesStatusFilter control={control} />
                    <OvernightsFilter control={control} />
                    <LimitPerAssetFilter control={control} />
                    {areFiltersDirty && (
                        <Tooltip content="Reset filter" align="center">
                            <IconButton
                                variant="plain"
                                type="button"
                                Icon={Icons.Recent}
                                onClick={() => resetFilters()}
                            />
                        </Tooltip>
                    )}
                </Flex>
                <SearchQueryContextProvider value={delayedSearchQuery}>
                    <AssetsTable isLoading={isLoading} data={filteredData} />
                </SearchQueryContextProvider>
            </CardContainer>
        </Container>
    );
};
