import { useCallback, useMemo, useState } from "react";

export const useModalControl = (defaultOpen?: boolean) => {
    const [isModalOpen, setIsModalOpen] = useState(Boolean(defaultOpen));

    const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);
    const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);

    return useMemo(
        () => ({
            isModalOpen,
            openModal,
            closeModal,
        }),
        [isModalOpen, openModal, closeModal],
    );
};
