import { DropdownOption, SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { CurrencyDropdownSheet } from "components/CurrencySheet";
import { CurrencyTriggerEssence } from "components/CurrencyTriggerEssence";
import { useInstruments } from "hooks";
import { useEffect, useMemo, useRef } from "react";
import {
    Control,
    Controller,
    FieldValues,
    Path,
    PathValue,
    UseFormSetValue,
    useWatch,
} from "react-hook-form";

interface BaseFormValues extends FieldValues {
    asset?: string;
    hasNetworks: boolean;
    network?: string;
}

export const AssetAndNetworkSelector = <FormValues extends BaseFormValues>({
    control,
    setValue,
    assetError,
    networkError,
    onReady,
}: {
    control: Control<FormValues, any>;
    setValue: UseFormSetValue<FormValues>;
    assetError?: string;
    networkError?: string;
    onReady?: (isReady: boolean) => void;
}) => {
    const { currencies } = useInstruments();
    const currencyOptions = useMemo(
        () =>
            Object.values(currencies).map(
                ({ name }) => ({ text: name, value: name } as DropdownOption),
            ),
        [currencies],
    );
    const selectedAsset = useWatch<FormValues, any>({
        control,
        name: "asset",
    });
    const selectedNetwork = useWatch<FormValues, any>({
        control,
        name: "network",
    });
    const networkOptions = useMemo(
        () =>
            currencies[selectedAsset]?.networks.map(
                (networkName) =>
                    ({
                        text: networkName,
                        value: networkName,
                    } as DropdownOption),
            ) ?? [],
        [selectedAsset, currencies],
    );
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (networkOptions.length === 1) {
            setValue(
                "network" as Path<FormValues>,
                networkOptions[0].value as PathValue<FormValues, Path<FormValues>>,
                {
                    shouldValidate: true,
                },
            );
            // tricky case when defaultValues of form has value for 'network' field we should not reset this field
            // so on first render we do not reset 'network' field
        } else if (!isFirstRender.current) {
            setValue(
                "network" as Path<FormValues>,
                undefined as PathValue<FormValues, Path<FormValues>>,
            );
        }
        setValue(
            "hasNetworks" as Path<FormValues>,
            Boolean(networkOptions.length > 0) as PathValue<FormValues, Path<FormValues>>,
            {
                shouldValidate: true,
            },
        );
        isFirstRender.current = false;
    }, [networkOptions, setValue]);
    useEffect(() => {
        if (!selectedNetwork && networkOptions.length === 1) {
            setValue(
                "network" as Path<FormValues>,
                networkOptions[0].value as PathValue<FormValues, Path<FormValues>>,
                {
                    shouldValidate: true,
                },
            );
        }
    }, [selectedNetwork, networkOptions]);

    const isReadyRef = useRef(false);
    useEffect(() => {
        if (Object.keys(currencies).length > 0 && !isReadyRef.current) {
            onReady?.(true);
        }
    }, [currencies, onReady]);

    return (
        <>
            <Controller
                control={control}
                render={({ field }) => (
                    <SingleDropdown
                        value={field.value as string}
                        onChange={field.onChange}
                        renderTrigger={(trigger) => (
                            <SingleDropdown.Trigger {...trigger} size="large" variant="simple">
                                <CurrencyTriggerEssence
                                    {...trigger}
                                    option={trigger.selectedOption}
                                    size="large"
                                />
                            </SingleDropdown.Trigger>
                        )}
                        options={currencyOptions}
                        placeholder="Select"
                        caption="Asset"
                        align="end"
                        error={assetError}
                        fullWidth
                    >
                        <CurrencyDropdownSheet
                            size="medium"
                            options={currencyOptions}
                            Dropdown={SingleDropdown}
                        />
                    </SingleDropdown>
                )}
                name={"asset" as Path<FormValues>}
            />
            {Boolean(networkOptions.length) && (
                <Controller
                    control={control}
                    render={({ field }) => (
                        <SingleDropdown
                            value={field.value as string}
                            onChange={field.onChange}
                            renderTrigger={(trigger) => (
                                <SingleDropdown.Trigger {...trigger} size="large" variant="simple">
                                    <SingleDropdown.TriggerEssence
                                        {...trigger}
                                        option={trigger.selectedOption}
                                        size="large"
                                    />
                                </SingleDropdown.Trigger>
                            )}
                            options={networkOptions}
                            placeholder="Select"
                            caption="Network"
                            align="end"
                            error={networkError}
                            disabled={networkOptions.length === 1}
                            fullWidth
                        >
                            <SingleDropdown.BasicSheet size="medium" options={networkOptions} />
                        </SingleDropdown>
                    )}
                    name={"network" as Path<FormValues>}
                />
            )}
        </>
    );
};
