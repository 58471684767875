import { forwardRef } from "react";
import { Button } from "./Button/Button";
import { SpecificButtonProps } from "./types";

export { IconButton } from "./IconButton/IconButton";
export const BasicButton = forwardRef<HTMLButtonElement, SpecificButtonProps>((props, ref) => (
    <Button variant="basic" ref={ref} {...props} />
));
export const DesctructiveButton = forwardRef<HTMLButtonElement, SpecificButtonProps>((props, ref) => (
    <Button variant="destructive" ref={ref} {...props} />
));
export const PrimaryButton = forwardRef<HTMLButtonElement, SpecificButtonProps>((props, ref) => (
    <Button variant="primary" ref={ref} {...props} />
));
export const PlainButton = forwardRef<HTMLButtonElement, SpecificButtonProps>((props, ref) => (
    <Button variant="plain" ref={ref} {...props} />
));
export const LinkButton = forwardRef<HTMLButtonElement, SpecificButtonProps>((props, ref) => (
    <Button variant="link" ref={ref} {...props} />
));
