import { PSmall } from "@fm-frontend/uikit";
import { TradeHistory } from "feature/history/types";
import React from "react";
import styled from "styled-components";
import { DealSide } from "types";

const SellIndicator = styled(PSmall)`
    color: ${(p) => p.theme.colors.negative100};
`;
const BuyIndicator = styled(PSmall)`
    color: ${(p) => p.theme.colors.positive100};
`;

type SideCelProps = Pick<TradeHistory, "side" | "isSubrow">;
export const SideCell: React.FC<SideCelProps> = ({ side, isSubrow }) => {
    if (side === undefined || isSubrow) {
        return <></>;
    }

    return side === DealSide.Buy ? (
        <BuyIndicator>Buy</BuyIndicator>
    ) : (
        <SellIndicator>Sell</SellIndicator>
    );
};
