import { post } from "api";
import { EMPTY_ARRAY } from "const";
import {
    groupTradeHistoriesByOrder,
    mapPreparedDealToTradingHistory,
} from "feature/history/trades/utils";
import { TradeHistory } from "feature/history/types";
import { useMemo } from "react";
import { usePrimeBrokerViewType } from "store/hooks";
import { DEAL_HISTORY_API_METHOD, useDealHistoryParams } from "store/useDealHistoryParams";
import useSWR from "swr";
import { PreparedDeal, prepareDeal } from "types";
import { displayError } from "utils";
import { getLastDealsFromParam, LOAD_TRADING_LAST_TRADES_LIMIT } from "../trading/utils";

const getDealHistoryApi = (params: {
    limit?: number;
    till?: number;
    from?: number;
}): Promise<PreparedDeal[]> =>
    post(DEAL_HISTORY_API_METHOD, {
        limit: params.limit,
        till: params.till,
        from: params.from,
    }).then((data) => (Array.isArray(data) ? data.map(prepareDeal) : []));

export const useLastOrdersSWRKey = (limit: number) => {
    const sideKey = usePrimeBrokerViewType();

    return `${sideKey}-${limit}`;
};

export const useLastOrders = (ordersLimit: number) => {
    const params = useDealHistoryParams(LOAD_TRADING_LAST_TRADES_LIMIT);

    const lastTradesSWRKey = useLastOrdersSWRKey(ordersLimit);

    const { data, isLoading, isValidating, mutate } = useSWR(
        lastTradesSWRKey,
        async () => {
            let deals = await getDealHistoryApi({
                ...params,
                from: getLastDealsFromParam(),
            });

            if (deals.length < ordersLimit) {
                return deals;
            }

            const ordersSet = new Set(deals.map((acc) => acc.orderId));

            while (ordersSet.size < ordersLimit) {
                const olderDeals = await getDealHistoryApi({
                    ...params,
                    till: deals[deals.length - 1].dealId,
                    from: getLastDealsFromParam(),
                });

                if (!olderDeals.length) {
                    break;
                }

                deals.forEach((deal) => ordersSet.add(deal.orderId));
                deals = [...deals, ...olderDeals];
            }

            return deals;
        },
        {
            onError: displayError,
            fallbackData: EMPTY_ARRAY as PreparedDeal[],
            revalidateOnMount: true,
            revalidateOnFocus: false,
        },
    );

    const trades: TradeHistory[] = useMemo(() => {
        return groupTradeHistoriesByOrder(data.map(mapPreparedDealToTradingHistory))
            .sort((a, b) => (b.date ?? 0) - (a.date ?? 0))
            .slice(0, ordersLimit);
    }, [data, ordersLimit]);

    return {
        trades,
        isLoading,
        isValidating,
        mutate,
    };
};
