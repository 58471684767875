import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { requestDataExport } from "../../feature/app";
import { OutlinedButton } from "../../feature/form/Buttons";
import FormCheckbox from "../../feature/form/FormCheckbox";
import FormInput from "../../feature/form/FormInput";
import { FormHeader, FormInputError, FormLabel } from "../../feature/form/style";
import { FormConfig, useForm } from "../../feature/form/useForm";
import { useModalCloseWithConfirm } from "../../hooks/useModalCloseWithConfirm";
import { TransitionState } from "../../hooks/useTransition";
import { ButtonGroup, Form } from "../../style";
import { ModalDialog } from "./Modal";

export const ExportModal: React.FC<{
    url: string;
    onClose: () => void;
    transitionState: TransitionState;
    prefix: string;
    columns: any;
    itemIdColumn: number;
    urlParams?: { [key: string]: any };
}> = ({ url, onClose, transitionState, prefix, columns, itemIdColumn, urlParams = {} }) => {
    const dispatch = useDispatch();
    const [isDirty, setIsDirty] = useState(false);
    const form: FormConfig = useMemo(
        () => ({
            fields: {
                includeHeaders: {
                    type: "boolean",
                    title: "Include headers",
                },
                filename: {},
                from: {
                    props: {
                        type: "date",
                    },
                },
                to: {
                    props: {
                        type: "date",
                    },
                },
            },
            url: "",
            submit: (_, data) => {
                dispatch(requestDataExport({ ...data, url, columns, itemIdColumn, urlParams }));
                return Promise.resolve({});
            },
            onChange: () => {
                setIsDirty(true);
            },
            getInitialValues: () => ({
                filename: `${prefix}_${new Date().valueOf()}`,
                includeHeaders: true,
            }),
            validate: (values) => {
                if ((values as { filename: string }).filename.match(/[?*]/))
                    return {
                        filename: "Filename cannon contain illegal characters (?, *)",
                    };
                return true;
            },
        }),
        [],
    );
    const { inputProps, onSubmit, values, areActionsDisabled } = useForm(form);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(isDirty, onClose);
    const fromProps = useMemo(() => {
        if (inputProps.to.value) {
            const date = new Date(inputProps.to.value);
            date.setFullYear(date.getFullYear() - 1);
            return {
                min: date.toISOString().split("T")[0],
            };
        }
        return null;
    }, [inputProps.to.value]);
    const toProps = useMemo(() => {
        if (inputProps.from.value) {
            const date = new Date(inputProps.from.value);
            const min = date.toISOString().split("T")[0];
            date.setFullYear(date.getFullYear() + 1);
            return {
                min,
                max: date.toISOString().split("T")[0],
            };
        }
        return null;
    }, [inputProps.from.value]);
    const incorrectDateRange = useMemo(() => {
        const { from, to } = values as { from: string; to: string };
        if (to && from && from > to) {
            return true;
        }
        return false;
    }, [values]);

    return (
        <ModalDialog isOpen isDismissable onClose={closeModalWithConfirm} transitionState={transitionState} size="small">
            <Form>
                <FormHeader>Download .CSV</FormHeader>
                <FormLabel>From date</FormLabel>
                <FormInput {...inputProps.from} {...fromProps} />
                <FormLabel>To date</FormLabel>
                <FormInput {...inputProps.to} {...toProps} />
                <FormLabel>Filename</FormLabel>
                <FormInput {...inputProps.filename} />
                {incorrectDateRange && <FormInputError isCentered>Date range is incorrect</FormInputError>}
                <FormLabel>Options</FormLabel>
                <FormCheckbox {...inputProps.includeHeaders} />
                <ButtonGroup>
                    <OutlinedButton
                        disabled={incorrectDateRange || areActionsDisabled}
                        onClick={(e) => {
                            e.preventDefault();
                            onSubmit();
                            onClose();
                        }}
                    >
                        Download
                    </OutlinedButton>
                </ButtonGroup>
            </Form>
        </ModalDialog>
    );
};
