import styled from "styled-components";

export const Error = styled.div`
    margin-top: auto;
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: ${(p) => p.theme.colors.negative100};
`;

export const AutoOverflow = styled.div`
    overflow: auto;
`;
