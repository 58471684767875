import { Box, Icons, P, PBold, PrimaryButton, VStack } from "@fm-frontend/uikit";
import { FC, useState } from "react";
import styled from "styled-components";
import { Card } from "components/Card";
import { CardStack } from "components/CardStack";
import { AddNewLimit } from "feature/counterparties/counterpartyLimitModal/AddNewLimit";
import { usePrimeBrokerViewType } from "store/hooks";

const CardContent = styled(VStack)`
    align-items: center;
    padding: 32px;
    gap: 12px;
`
const CardText = styled(Box)`
    p {
        display: block;
    }
`;

const CardBoldText = styled(PBold)`
    color: ${(p) => p.theme.colors.ui100};
`;

const CardNormalText = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;

export const Onboarding: FC = () => {
    const [riskProfileKey, setRiskProfileKey] = useState(1);
    const primeBrokerViewType = usePrimeBrokerViewType();
    const handleCloseAddRiskProfile = () => setRiskProfileKey(id => id + 1);

    return (
        <CardStack>
            <Card>
                <CardContent>
                    <CardText>
                        {primeBrokerViewType === "subaccounts" ? (
                            <CardBoldText>Set up risk profiles with your sub-accounts</CardBoldText>
                        ) : (
                            <CardBoldText>Set up risk profiles with your counterparties</CardBoldText>
                        )}
                        <CardNormalText>Add Gross Limit to initiate trading and manage risks</CardNormalText>
                    </CardText>
                    <AddNewLimit
                        key={riskProfileKey}
                        renderButton={(openModal) => (
                            <PrimaryButton size="small" onClick={openModal}>
                                <Icons.Plus />
                                Add first risk profile
                            </PrimaryButton>
                        )}
                        onClose={handleCloseAddRiskProfile}
                    />
                </CardContent>
            </Card>
        </CardStack>
    );
};
