import { H2, Header as UiKitHeader, HeaderTitle, Mark, ShadowCard, ShadowItem, Skeleton } from "@fm-frontend/uikit";
import { EmptySearch } from "@fm-frontend/uikit/src/components/common/EmptySearch";
import { useSelector } from "hooks";
import React, { useMemo } from "react";
import { DEFAULT_FILTER, FilterType } from "store/onboardingSlice";
import { MakerInfo } from "types";
import { OnboardingInfoBanner } from "../common";
import { LiquidityProviderCard, LiquidityProviderPlaceholderCard } from "./LiquidityProviderCard";
import { useFilterContext } from "./LiquidityProvidersFilterContext";
import { isObjectsEqual, sortLps } from "./utils";

const BEST_MATCH_NUMBER = 3;

interface MatchType<T> {
    isBestMatch: boolean;
    item: T;
}

const getMatch = (filter?: FilterType, providers?: MakerInfo[]) => {
    const matchedProviders: MatchType<MakerInfo>[] = [];

    const result: {
        match: MakerInfo[];
        rest: MakerInfo[];
    } = {
        match: [],
        rest: [],
    };

    if (!providers || !providers?.length) {
        return result;
    }
    if (!filter || isObjectsEqual(DEFAULT_FILTER, filter)) {
        result.rest = [...providers];
        return result;
    }

    providers.forEach((provider) => {
        const match: MatchType<MakerInfo> = {
            item: provider,
            isBestMatch: false,
        };

        const { jurisdiction, currency, bank, regulation } = provider;

        const jurisdictionMatch = filter.jurisdiction
            ? jurisdiction.map((j) => j.id).includes(Number(filter.jurisdiction))
            : true;
        const assetsMatch = filter.assets.length
            ? filter.assets.every((f) => currency.map((c) => c.id).some((c) => Number(f) === c))
            : true;
        const banksMatch = filter.banks.length
            ? filter.banks.some((f) => bank.map((b) => b.id).some((b) => Number(f) === b))
            : true;
        const regulationMatch = filter.regulation ? Boolean(regulation) : true;

        match.isBestMatch = Boolean(jurisdictionMatch && assetsMatch && banksMatch && regulationMatch);

        matchedProviders.push(match);
    });

    matchedProviders.reduce((acc, curr) => {
        if (curr.isBestMatch && acc.match.length < BEST_MATCH_NUMBER) {
            acc.match.push(curr.item);
        } else {
            acc.rest.push(curr.item);
        }

        return acc;
    }, result);

    return result;
};

export const LiquidityProviders: React.VFC<{
    makersInfo?: MakerInfo[];
    isLoading: boolean;
}> = ({ makersInfo, isLoading }) => {
    const { filters } = useFilterContext();
    const { match, rest } = useMemo(() => getMatch(filters, makersInfo), [makersInfo, filters]);
    const { statusKYB } = useSelector((state) => state.onboarding);

    if (isLoading) {
        return (
            <ShadowCard>
                <UiKitHeader>
                    <HeaderTitle title={<Skeleton width="109px" height="24px" />} />
                </UiKitHeader>
                {[...Array(3).keys()]?.map((key) => (
                    <LiquidityProviderPlaceholderCard key={key} />
                ))}
            </ShadowCard>
        );
    }
    if (!match.length && !rest.length) {
        return <EmptySearch />;
    }

    return (
        <>
            <OnboardingInfoBanner />
            {Boolean(match.length) && (
                <ShadowCard isDefault>
                    <ShadowItem padding="12px 12px 0 12px">
                        <UiKitHeader>
                            <HeaderTitle
                                title={
                                    <H2>
                                        Best match <Mark>{match?.length}</Mark>
                                    </H2>
                                }
                            />
                        </UiKitHeader>
                    </ShadowItem>
                    {match
                        .sort((left, right) => sortLps(left, right, statusKYB))
                        .map((makerInfo) => (
                            <LiquidityProviderCard key={makerInfo.id} makerInfo={makerInfo} />
                        ))}
                </ShadowCard>
            )}
            {Boolean(rest.length) && (
                <ShadowCard isDefault>
                    <ShadowItem padding="12px 12px 0 12px">
                        <UiKitHeader>
                            <HeaderTitle
                                title={
                                    <H2>
                                        {match?.length ? "More providers" : "All providers"}{" "}
                                        <Mark weight={500}>{rest?.length}</Mark>
                                    </H2>
                                }
                            />
                        </UiKitHeader>
                    </ShadowItem>
                    {rest
                        .sort((left, right) => sortLps(left, right, statusKYB))
                        .map((makerInfo) => (
                            <LiquidityProviderCard key={makerInfo.id} makerInfo={makerInfo} />
                        ))}
                </ShadowCard>
            )}
        </>
    );
};
