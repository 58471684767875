import { CSSProperties } from "react";
import { DefaultTheme } from "styled-components";
import { CellProps, createCellRenderer, SimpleValue } from ".";
import { FormatConfig } from "../../utils/format";
import { createMixin, MixinAttributes } from "./mixins";

export type ColumnTemplate<Data> = {
    header: string;
    truncateAt?: "middle" | "end";
    format?: FormatConfig;
    accessor: (data: Data) => SimpleValue;
    Cell?: React.FC<CellProps<SimpleValue>>;
    hideInSubTree?: boolean;
    hideInRoot?: boolean;
    canExpandMixin?: Partial<CSSProperties>;
    mixin?: MixinAttributes;
    getColor?: (value: SimpleValue, data: Data) => keyof DefaultTheme["colors"] | undefined;
};
export function createColumns<Data>(columns: Array<ColumnTemplate<Data>>) {
    return columns.map(({ header, mixin, format, Cell, ...column }) => ({
        id: header.toLowerCase(),
        Header: header,
        Cell: Cell || createCellRenderer(format),
        format,
        ...(mixin ? createMixin(mixin) : null),
        ...column,
    }));
}
