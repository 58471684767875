import { SubaccountsPageContent } from "feature/subaccounts";
import { CenteredPageLayout } from "feature/subaccounts/CenteredPageLayout";
import React from "react";
import { Page } from "style";

export const Subaccounts: React.FC = () => {
    return (
        <Page>
            <CenteredPageLayout title="Sub-accounts">
                <SubaccountsPageContent />
            </CenteredPageLayout>
        </Page>
    );
};
