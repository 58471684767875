import { PXSmall, VStack } from "@fm-frontend/uikit";
import { useInstruments } from "hooks";
import { useUsdPrices } from "store/hooks";
import styled from "styled-components";
import { fmt } from "utils/format";

const Container = styled(VStack)`
    justify-content: right;
    color: ${(p) => p.theme.colors.ui72};
`;

export const AssetRefPriceCell = ({ asset }: { asset: string }) => {
    const { currencies } = useInstruments();
    const { balanceStep } = currencies[asset];
    const { priceObj } = useUsdPrices();

    return (
        <Container>
            <PXSmall>
                $
                {
                    fmt(priceObj[asset], {
                        significantDigits: 2,
                        type: "limit",
                    }).formattedValue
                }
            </PXSmall>
            <PXSmall>{(balanceStep / 100_000_000).toFixed(8)}</PXSmall>
        </Container>
    );
};
