import React from "react";
import styled from "styled-components";
import { H3, PBold as Description } from "../typography";

export const EmptyContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const Title = styled(H3)`
    color: ${(p) => p.theme.colors.ui32};
`;

export const Panda = styled.div`
    font-size: 48px;
`;

export const EmptySearch: React.VFC<{ description?: React.ReactElement | string; className?: string }> = ({
    description,
    className,
}) => {
    const descriptionElement = !description ? null : typeof description === "string" ? (
        <Description>{description}</Description>
    ) : (
        description
    );

    return (
        <EmptyContent className={className}>
            <Title>Very quiet here</Title>
            <Panda>🐼</Panda>
            {descriptionElement}
        </EmptyContent>
    );
};
