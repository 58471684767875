import { post } from "api";

interface RawClientType {
    clientType: "maker" | "taker" | "primeBroker";
    subaccount?: boolean;
}

export const fetchClientType = async (counterpartyId: number) => {
    const [{ subaccount, clientType }]: RawClientType[] = await post("getCounterpartyInfo", { counterpartyId });

    if (subaccount) {
        return clientType === "maker" ? "subaccountMaker" : "subaccountTaker";
    }

    return clientType;
};
