import { Icons, PlainButton, PSmallBold, PXSmall, VStack } from "@fm-frontend/uikit";
import { ROUTES } from "feature/app/router";
import { FC } from "react";
import { useHistory } from "react-router";
import styled, { useTheme } from "styled-components";

const Description = styled(VStack)`
    gap: 4px;
    text-align: center;

    ${PXSmall} {
        color: ${(p) => p.theme.colors.ui52};
    }
`;

const Layout = styled(VStack)`
    width: 100%;
    padding: 0 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;

    border-radius: 12px;
    background-color: ${(p) => p.theme.colors.ui4};
    min-height: 110px;
`;

export const AddingSubAccountsInfo: FC = () => {
    const { colors } = useTheme();
    const history = useHistory();

    return (
        <Layout>
            <Description>
                <PSmallBold>Enable Authorized Trading by adding sub-accounts</PSmallBold>
                <PXSmall>
                    Authorized trading allows you to place orders on behalf of your sub-takers.
                </PXSmall>
            </Description>
            <PlainButton
                size="medium"
                type="button"
                onClick={() => history.push(ROUTES.subaccounts)}
            >
                <Icons.Plus color={colors.ui32} />
                <PSmallBold>Add new sub-account</PSmallBold>
            </PlainButton>
        </Layout>
    );
};
