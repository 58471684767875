import { fmtDeltaratePercent } from "utils/format";
import { CpTableData, InstrumentTableData, RowData } from "./types";

export const formatPercentValue = (value?: number) =>
    value === undefined
        ? undefined
        : fmtDeltaratePercent(value, { significantDigits: 4, minSignificantDigits: 2 })
              .copyableValue;

export const isInstrumentRow = (data: RowData): data is InstrumentTableData =>
    "counterparties" in data;
export const isCpRow = (data: RowData): data is CpTableData => "defaultMarkup" in data;

export const calculateDefaultMarkups = ({
    selectedInstruments,
    selectedCps,
    instrumentsData,
}: {
    selectedInstruments: string[];
    selectedCps: number[];
    instrumentsData: InstrumentTableData[];
}) => {
    const instrumentsNames = new Set(selectedInstruments);
    const counterpartiesIds = new Set(selectedCps);

    let isInitialValuesSet = false;
    let commonAskMarkup: number | undefined = undefined;
    let commonBidMarkup: number | undefined = undefined;
    let commonDefaultMarkup: number | undefined = undefined;
    for (const { counterparties, instrument } of instrumentsData) {
        if (!instrumentsNames.has(instrument.instrumentName)) {
            continue;
        }
        for (const { askMarkup, bidMarkup, defaultMarkup, id } of counterparties) {
            if (!counterpartiesIds.has(id)) {
                continue;
            }
            if (!isInitialValuesSet) {
                commonAskMarkup = askMarkup;
                commonBidMarkup = bidMarkup;
                commonDefaultMarkup = defaultMarkup;
                isInitialValuesSet = true;
                continue;
            }
            if (commonAskMarkup !== askMarkup) {
                commonAskMarkup = undefined;
            }
            if (commonBidMarkup !== bidMarkup) {
                commonBidMarkup = undefined;
            }
            if (commonDefaultMarkup !== defaultMarkup) {
                commonDefaultMarkup = undefined;
            }
        }
    }

    return {
        bidMarkup: commonBidMarkup === undefined ? undefined : formatPercentValue(commonBidMarkup),
        askMarkup: commonAskMarkup === undefined ? undefined : formatPercentValue(commonAskMarkup),
        defaultMarkup: commonDefaultMarkup === undefined ? undefined : formatPercentValue(commonDefaultMarkup),
    };
};
