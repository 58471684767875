import { Icons, P } from "@fm-frontend/uikit";
import { CellContainer } from "@fm-frontend/uikit/src/components/Cell/Cell";
import { useToggle } from "@fm-frontend/utils";
import { FC, ReactNode, useRef } from "react";
import styled from "styled-components";
import { Spacer } from "../style";

const Container = styled(CellContainer)`
    background: ${(p) => p.theme.colors.yellow8};
    padding: 0;
    overflow: hidden;
    transition: height 0.2s ease-in-out;
`;

const Header = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding: 12px;
`;

const Title = styled.div`
    > * {
        color: ${(p) => p.theme.colors.ui52};

        > * {
            color: ${(p) => p.theme.colors.ui100};
        }
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 12px 12px;

    > * {
        flex-shrink: 0;
    }

    > ${P} {
        color: ${(p) => p.theme.colors.ui52};

        > * {
            color: ${(p) => p.theme.colors.ui100};
        }
    }

    a {
        color: ${(p) => p.theme.colors.brand100};
    }
`;

type InfoBoxProps = {
    title: ReactNode;
    className?: string;
};

export const InfoBox: FC<InfoBoxProps> = ({ title, children, className }) => {
    const { isActive, toggle } = useToggle(false);
    const headerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const headerHeight = headerRef.current?.offsetHeight ?? 42;
    const contentHeight = contentRef.current?.offsetHeight ?? 0;
    const height = isActive ? headerHeight + contentHeight : headerHeight;

    return (
        <Container style={{ height }} className={className}>
            <Header ref={headerRef} onClick={toggle}>
                <Icons.Help />
                <Title>{title}</Title>
                <Spacer />
                {isActive ? <Icons.Collapse /> : <Icons.Expand />}
            </Header>
            <Content ref={contentRef}>{children}</Content>
        </Container>
    );
};
