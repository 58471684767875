import { createContext, FC, RefObject, useCallback, useRef } from "react";

type TradingContextType = {
    instrumentSelectorRef: RefObject<HTMLInputElement>;
    focusInstrumentSelect: () => void;
};

export const InstrumentSelectorContext = createContext<TradingContextType | null>(null);

export const InstrumentSelectorProvider: FC = ({ children }) => {
    const instrumentSelectorRef = useRef<HTMLInputElement>(null);

    const focusInstrumentSelect = useCallback(() => {
        setTimeout(() => instrumentSelectorRef.current?.focus(), 0);
    }, []);

    return (
        <InstrumentSelectorContext.Provider
            value={{ instrumentSelectorRef, focusInstrumentSelect }}
        >
            {children}
        </InstrumentSelectorContext.Provider>
    );
};
