import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export type Id = string | number;
type _TransitionState = "entering" | "active" | "exiting";
export type TransitionState = _TransitionState | undefined;
export type DelayOptions = { enterDelay?: number; exitDelay?: number; mouseLeaveDelay?: number };

export function useListTransition<T extends { id: Id }>(list: Array<T>, { exitDelay = 0, mouseLeaveDelay = 0 }: DelayOptions = {}) {
    const prevIds = useRef<Id[]>([]);
    const hoveredId = useRef<Id | null>(null);
    const [ids, setIds] = useState<Id[]>([]);
    const [entries, setEntries] = useState<Record<Id, T>>({});
    const [states, setStates] = useState<Record<Id, _TransitionState>>({});

    const enqueueUpdate = useCallback(
        (type: _TransitionState, items: Id[]) => {
            if (items.length === 0) return;
            const delays: {
                [State in _TransitionState]?: number;
            } = {
                exiting: exitDelay,
            };
            setTimeout(() => {
                if (type === "entering" || type === "active") {
                    setStates((states) => ({
                        ...states,
                        ...Object.fromEntries(items.map((id) => [id, type])),
                    }));
                    if (type === "entering") enqueueUpdate("active", items);
                }
                if (type === "exiting") {
                    setIds((ids) => ids.filter((id) => !items.includes(id)));
                    setStates((states) =>
                        Object.fromEntries(Object.entries(states).filter(([id]) => !items.includes(id))),
                    );
                    setEntries((entries) =>
                        Object.fromEntries(Object.entries(entries).filter(([id]) => !items.includes(id))),
                    );
                }
            }, delays[type] || 0);
        },
        [exitDelay, setStates, setEntries],
    );

    useEffect(() => {
        // do nothing if list is the same
        if (list.length === prevIds.current.length && list.every(({ id }, i) => id === prevIds.current[i])) return;
        prevIds.current = list.map(({ id }) => id);

        const newIds: Id[] = [];
        const addedIds: Id[] = [];
        const removedIds: Id[] = [];
        const newEntries: Record<Id, T> = {};
        const newStates = { ...states };

        for (let index = 0; index < list.length; index += 1) {
            const item = list[index];
            newEntries[item.id] = item;
            newIds.push(item.id);
            if (states[item.id] === undefined) {
                addedIds.push(item.id);
            }
        }

        const resultingList = [...newIds];
        for (const id of ids) {
            if (!newEntries[id]) {
                newEntries[id] = entries[id];
                const idx = ids.indexOf(id);
                resultingList.splice(idx, 0, id);
                // Only add to removedIds if not hovered
                if (id !== hoveredId.current) {
                    removedIds.push(id);
                    newStates[id] = "exiting";
                }
            }
        }
        setIds(resultingList);
        setEntries(newEntries);
        setStates(newStates);
        enqueueUpdate("entering", addedIds);
        enqueueUpdate("exiting", removedIds);
    }, [list, entries, states, ids, enqueueUpdate]);

    const handleMouseEnter = useCallback((id: Id) => {
        hoveredId.current = id;
    }, []);

    const handleMouseLeave = useCallback((id: Id) => {
        hoveredId.current = null;

        setTimeout(() => {
            if (!list.some(item => item.id === id) && hoveredId.current !== id) {
                setStates((prevState) => {
                    const newState = { ...prevState };
                    newState[id] = "exiting";
                    return newState;
                });
            }
        }, mouseLeaveDelay);
    }, [list, enqueueUpdate]);

    return useMemo(
        () =>
            ids.map((id) => ({
                ...entries[id],
                state: states[id],
                onMouseEnter: () => handleMouseEnter(id),
                onMouseLeave: () => handleMouseLeave(id),
            })),
        [ids, entries, states, handleMouseEnter, handleMouseLeave],
    );
}

export function useItemTransition<T = { [key: string]: unknown }>(
    item: null | T | boolean,
    { enterDelay = 0, exitDelay = 50 }: DelayOptions = {},
) {
    const [itemSnapshot, setItemSnapshot] = useState<T | undefined>(
        typeof item !== "boolean" && item !== null ? item : undefined,
    );
    useEffect(() => {
        if (typeof item === "boolean" || item === null || !item) return;
        setItemSnapshot(item);
    }, [item]);

    const [isActive, setIsActive] = useState(Boolean(item));
    const [transitionState, setTransitionState] = useState<TransitionState>(isActive ? "active" : undefined);
    useEffect(() => {
        switch (transitionState) {
            case "entering": {
                const taskId = setTimeout(() => {
                    setTransitionState("active");
                }, enterDelay);
                return () => clearTimeout(taskId);
            }
            case "exiting": {
                const taskId = setTimeout(() => {
                    setTransitionState(undefined);
                    setIsActive(false);
                    setItemSnapshot(undefined);
                }, exitDelay);
                return () => clearTimeout(taskId);
            }
        }
    }, [transitionState, enterDelay, exitDelay]);

    const isItemActive = useMemo(() => {
        if (typeof item === "boolean") return item;
        return Boolean(item);
    }, [item]);
    useEffect(() => {
        if (isItemActive) {
            if (transitionState !== "active") {
                setIsActive(true);
                setTransitionState("entering");
            }
        } else {
            setTransitionState("exiting");
        }
    }, [isItemActive]);

    return useMemo(() => {
        return { transitionState, isActive, props: itemSnapshot! };
    }, [transitionState, isActive, itemSnapshot]);
}
