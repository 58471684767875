import { H3, Icons, Skeleton } from "@fm-frontend/uikit";
import { useFormShouldCloseConfirm } from "@fm-frontend/utils";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { HintsHeader } from "components/modal/Hints/HintsHeader";
import { HintsContent } from "components/modal/Hints/HintsContent";
import { HintsLayout } from "components/modal/Hints/HintsLayout";
import { Tabs } from "feature/settlements/const";
import { useInstruments } from "hooks";
import { useActionPermissions } from "hooks/useActionPermissions";
import { getConfirmModalAction } from "hooks/useModalCloseWithConfirm";
import { ClientType, CounterpartyLimit } from "types";
import { useLimitData } from "./hooks/useLimitData";
import { HintItem } from "./HintItem";
import { useTotalEquity } from "./hooks/useTotalEquity";
import { usePendingSettlementsCount } from "./hooks/usePendingSettlementsCount";
import { useNeedConfirmedAddressesCount } from "./hooks/useNeedConfirmedAddressesCount";
import { useIsMarkupAvailable } from "./hooks/useIsMarkupAvailable";
import { COUNTERPARTY_LIMIT_MODAL_KEY } from "../counterpartyLimitModal/utils";

type CounterpartyHintsProps = {
    cpId: number;
    cpType: ClientType;
    cpName: string;
    limit: CounterpartyLimit;
};

export const CounterpartyHints: FC<CounterpartyHintsProps> = ({ limit, cpType }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        cpId,
        defaultMarkup,
        markupsPerInstrumentsCount,
        limitPerAssetsCount,
        shortSalesBansCount,
        overnightFeesCount,
        isLoading: isLimitDataLoading,
    } = useLimitData(limit);
    const { pendingSettlementsCount, isLoading: isPendingSettlementsCountLoading } = usePendingSettlementsCount(cpId);
    const { currencies, isLoading: isCurrencyLoading } = useInstruments();
    const totalEquity = useTotalEquity(limit, cpType);
    const { needConfirmedAddressesCount, isLoading: isAddressesCountLoading } = useNeedConfirmedAddressesCount(cpId);
    const assetsCount = Object.keys(currencies ?? {}).length;
    const isMarkupAvailable = useIsMarkupAvailable(cpType);
    const { getUserActionPermissions } = useActionPermissions();
    const { isShortSalesBanAllowed, isOvernightsAllowed } = getUserActionPermissions(cpId);

    const shouldConfirmClose = useFormShouldCloseConfirm(COUNTERPARTY_LIMIT_MODAL_KEY);
    const getTransitionHandler = (location: string) => () => {
        if (shouldConfirmClose) {
            dispatch(getConfirmModalAction(() => history.push(location)))
        } else {
            history.push(location);
        }
    };

    const isLoading =  isLimitDataLoading || isCurrencyLoading || isPendingSettlementsCountLoading || isAddressesCountLoading;

    return (
        <HintsLayout>
            <HintsHeader>
                <Icons.FilterPlus />
                <H3>More adjustments</H3>
            </HintsHeader>
            {isLoading && (
                <HintsContent>
                    {isMarkupAvailable && <Skeleton />}
                    <Skeleton />
                    {isShortSalesBanAllowed && <Skeleton />}
                    {isOvernightsAllowed && <Skeleton />}
                </HintsContent>
            )}
            {!isLoading && (
                <HintsContent>
                    {isMarkupAvailable && (
                        <HintItem
                            title={`Default markup ${defaultMarkup}`}
                            subtitle={
                                markupsPerInstrumentsCount === 0
                                    ? undefined
                                    : `And ${markupsPerInstrumentsCount} more markups per instrument`
                            }
                            onClick={getTransitionHandler(`/assets-and-instruments/instruments?cp=${cpId}&instrumentMarkups=-1`)}
                        />
                    )}
                    <HintItem
                        title="Limits per asset"
                        subtitle={
                            limitPerAssetsCount === 0
                                ? undefined
                                : `${limitPerAssetsCount} limits per asset`
                        }
                        onClick={getTransitionHandler(`/assets-and-instruments/assets?cp=${cpId}&shortsales=-1&overnights=-1&limitPerAsset=-1`)}
                    />
                    {isShortSalesBanAllowed && (
                        <HintItem
                            title="Short sales ban"
                            subtitle={
                                shortSalesBansCount === 0
                                    ? undefined
                                    : `${shortSalesBansCount} of ${assetsCount} assets banned`
                            }
                            onClick={getTransitionHandler(`/assets-and-instruments/assets?cp=${cpId}&shortsales=-1&overnights=-1&limitPerAsset=-1`)}
                        />
                    )}
                    {isOvernightsAllowed && (
                        <HintItem
                            title="Overnight fees"
                            subtitle={
                                overnightFeesCount === 0
                                    ? undefined
                                    : `${overnightFeesCount} of ${assetsCount} assets with fees`
                            }
                            onClick={getTransitionHandler(`/assets-and-instruments/assets?cp=${cpId}&shortsales=-1&overnights=-1&limitPerAsset=-1`)}
                        />
                    )}
                </HintsContent>
            )}
            <HintsHeader>
                <Icons.Funnel />
                <H3>Jump to section</H3>
            </HintsHeader>
            {isLoading && (
                <HintsContent>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </HintsContent>
            )}
            {!isLoading && (
                <HintsContent>
                    <HintItem
                        title="Positions"
                        subtitle={`Total Equity: ${totalEquity}`}
                        onClick={getTransitionHandler("/positions")}
                    />
                    <HintItem
                        title="Settlements"
                        subtitle={pendingSettlementsCount === 0
                            ? undefined
                            : `${pendingSettlementsCount} pending transactions`
                        }
                        onClick={getTransitionHandler(pendingSettlementsCount === 0
                            ? `/transactions?cp=${cpId}&asset=-1&status=-1&tab=${Tabs.ALL}`
                            : `/transactions?cp=${cpId}&asset=-1&status=-1&tab=${Tabs.ACTIONABLE}`
                        )}
                    />
                    <HintItem
                        title="Addresses"
                        subtitle={needConfirmedAddressesCount === 0
                            ? undefined
                            : `${needConfirmedAddressesCount} not confirmed`
                        }
                        onClick={getTransitionHandler(`/addresses/shared/crypto?cp=${cpId}&status=-1`)}
                    />
                    <HintItem
                        title="Trading history"
                        onClick={getTransitionHandler(`/trades?cp=${cpId}&instrument=-1`)}
                    />
                </HintsContent>
            )}
        </HintsLayout>
    );
};
