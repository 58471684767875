import { Td, VStack } from "@fm-frontend/uikit";
import { when } from "@fm-frontend/utils";
import React from "react";
import styled from "styled-components";
import { ErrorLine } from "./ErrorLine";
import { LightboxErrorContainer, Tr } from "./styled";

const ErrorTr = styled.tr`
    td {
        padding-bottom: 8px;
    }
`;

export const CryptoAddressRow: React.FC<{
    apiError: string | undefined;
    validationError: string | undefined;
    columnMaxWidth: Array<string>;
    columnWidth: Array<string>;
    animateShowUp?: boolean;
}> = ({ apiError, validationError, columnMaxWidth, columnWidth, animateShowUp = false, children }) => {
    const [rowFormIdElement, ...rest] = React.Children.toArray(children);

    const hasError = Boolean(apiError || validationError);

    return (
        <LightboxErrorContainer isError={hasError} animateShowUp={animateShowUp}>
            {rowFormIdElement}
            <Tr>
                {rest.map((item, index) => (
                    <Td key={index} width={columnWidth?.[index]} maxWidth={columnMaxWidth?.[index]}>
                        <VStack height="100%" width="100%">
                            {item}
                        </VStack>
                    </Td>
                ))}
            </Tr>
            {when(
                hasError,
                <ErrorTr>
                    <td colSpan={8}>
                        {apiError && <ErrorLine error={`${apiError}. Please, try saving it again.`} />}
                        {validationError && <ErrorLine error={validationError} />}
                    </td>
                </ErrorTr>,
            )}
        </LightboxErrorContainer>
    );
};
