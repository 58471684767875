import { P, VStack } from "@fm-frontend/uikit";
import styled from "styled-components";
import { Transaction } from "../../types";
import { TransactionInfo } from "../infoCards";

const Description = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;

export const DeleteTransactionModalBody = ({ transaction }: { transaction: Transaction }) => (
    <VStack spacing={8}>
        <Description>This action cannot be undone. Are you sure you want to delete this transaction?</Description>
        <TransactionInfo transaction={transaction} />
    </VStack>
);
