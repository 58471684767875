import { useIsPrimeBrokerUser, usePrimeBrokerViewType } from "store/hooks";

export const useCounterpartiesTitle = () => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();

    if (isPrimeBrokerUser && primeBrokerViewType === "subaccounts") {
        return "Sub-accounts";
    }

    return "Counterparties";
}
