import { createColumns } from "../../../feature/table/columns";
import { Order } from "../../../types";

export const columns = createColumns<Order>([
    {
        header: "Order Id",
        accessor: (data) => data[4],
        format: { type: "u64" },
    },
    {
        header: "Client Order ID",
        accessor: (data) => data[5],
        format: { type: "u64" },
    },
    {
        header: "Instrument",
        accessor: (data) => data[0],
        mixin: { fontWeight: 500 },
    },
    {
        header: "Side",
        accessor: (data) => (data[2] ? "Sell" : "Buy"),
    },
    {
        header: "Price",
        accessor: (data) => data[6],
        format: { type: "price", significantDigits: 8 },
    },
    {
        header: "Size Left",
        accessor: (data) => data[8],
        format: { type: "size", significantDigits: 8 },
    },
    {
        header: "Initial Size",
        accessor: (data) => data[7],
        format: { type: "size", significantDigits: 8 },
    },
    {
        header: "Created At",
        accessor: (data) => data[9],
        format: { type: "timems", withTime: true },
    },
]);
