import React from "react";
import styled, { css, useTheme } from "styled-components";
import { CheckShape } from "../../icons/styled";
import { P } from "../../typography";

export const Container = styled.div<{ isActive: boolean; disabled?: boolean }>`
    position: relative;
    display: flex;
    padding: 8px 8px;
    width: 100%;
    min-height: 36px;
    cursor: pointer;
    gap: 8px;
    align-items: center;
    border-radius: 6px;

    ${(p) =>
        p.isActive &&
        css`
            color: ${p.theme.colors.brand100};
        `}

    :hover {
        background-color: ${(p) => p.theme.colors.ui4};
    }

    svg {
        position: absolute;
        right: 15px;
    }

    ${(p) =>
        p.disabled &&
        css`
            :hover {
                background-color: unset;
            }
            cursor: not-allowed;
            color: ${(p) => p.theme.colors.ui20};
        `}
`;

export const Content = styled.div`
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.005em;
`;

type ItemProps<TValue> = {
    isActive: boolean;
    value: TValue;
    content: React.ReactNode;
    icon?: React.ReactNode;
    onSelect: (value: TValue) => void;
    disabled?: boolean;
};

export const Item = <TValue,>({
    isActive,
    value,
    content,
    onSelect,
    icon,
    disabled,
}: ItemProps<TValue>) => {
    const theme = useTheme();

    return (
        <Container
            disabled={disabled}
            isActive={isActive}
            onClick={() => !disabled && onSelect(value)}
        >
            {icon}
            {typeof content === "string" ? <P>{content}</P> : content}
            {isActive && <CheckShape color={theme.colors.brand100} />}
        </Container>
    );
};
