import React, { useCallback, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { MODAL_ENTER_DELAY, MODAL_EXIT_DELAY } from "../feature/counterparties/utils";
import { SimpleValue } from "../feature/table";
import { usePopoverDirection } from "../hooks/useDropdownDirection";
import { TransitionState, useItemTransition } from "../hooks/useTransition";
import { transition } from "../utils";
import { Popover } from "./Popover";

const TooltipContents = styled.div<{
    vertical?: "top" | "center" | "bottom";
    horizontal?: "left" | "center" | "right";
    transitionState: TransitionState;
}>`
    position: absolute;
    padding: 8px 10px;
    ${(p) => p.theme.mixins.body2}
    line-height: 20px;
    pointer-events: none;
    user-select: none;

    background: ${(p) => p.theme.colors.textFG};
    color: ${(p) => p.theme.colors.activeBG};
    border-radius: 8px;
    white-space: normal;
    max-width: 280px;
    z-index: 10;
    white-space: break-spaces;
    overflow-wrap: break-word;
    hyphens: none;
    display: block;
    width: max-content;
    opacity: 0;
    ${(p) => transition(p.theme.transitions.default)}
    ${({ vertical, horizontal }) => {
        let vCss = css``;
        let hCss = css`
            left: 50%;
            transform: translateX(-50%);
        `;
        if (vertical === "top") {
            vCss = css`
                bottom: calc(100% + 6px);
            `;
        }
        if (vertical === "bottom") {
            vCss = css`
                top: calc(100% + 6px);
            `;
        }
        if (horizontal === "left") {
            hCss = css`
                right: 0px;
            `;
        }
        if (horizontal === "right") {
            hCss = css`
                left: 0px;
            `;
        }

        return css`
            ${vCss}
            ${hCss}
        `;
    }}
  ${({ transitionState }) => {
        switch (transitionState) {
            case "active": {
                return css`
                    opacity: 1;
                `;
            }
        }
    }}
`;
export const TooltipContainer = styled.div`
    position: relative;
    pointer-events: all;
    display: inline-flex;
`;

export const Tooltip: React.FC<{
    text?: SimpleValue | React.ReactNode;
}> = ({ children, text }) => {
    const [isHovered, setIsHovered] = useState(false);
    const onMouseEnter = useCallback(() => {
        if (text) setIsHovered(true);
    }, [text, setIsHovered]);
    const onMouseLeave = useCallback(() => {
        if (text) setIsHovered(false);
    }, [text, setIsHovered]);
    const { transitionState, isActive } = useItemTransition(isHovered, {
        enterDelay: MODAL_ENTER_DELAY,
        exitDelay: MODAL_EXIT_DELAY,
    });
    const ref = useRef<HTMLDivElement>(null);
    const { horizontal, vertical } = usePopoverDirection(ref);
    return (
        <>
            <TooltipContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={ref}>
                {children}
            </TooltipContainer>
            {isActive && (
                <Popover containerRef={ref}>
                    <TooltipContents horizontal={horizontal} vertical={vertical} transitionState={transitionState}>
                        {text}
                    </TooltipContents>
                </Popover>
            )}
        </>
    );
};
