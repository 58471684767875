import { Flex, HStack, Icons, PrimaryButton, PXSmall, VStack } from "@fm-frontend/uikit";
import styled from "styled-components";

const TileLayout = styled(VStack)`
    position: relative;
    border-radius: 12px;
    background-color: ${(p) => p.theme.colors.ui4};
    height: 166px;
`;

const Content = styled(VStack)`
    flex: 1;
    padding: 0 24px;
    gap: 12px;
    justify-content: center;
    align-items: center;
`;

const Description = styled(VStack)`
    gap: 4px;
    color: ${(p) => p.theme.colors.ui52};
    text-align: center;
`;

const FakeActions = styled(HStack)`
    height: 57px;
    border-top: 1px solid ${(p) => p.theme.colors.ui8};
`;

const LeftFakeButton = styled(Flex)`
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const RightFakeButton = styled(LeftFakeButton)`
    border-left: 1px solid ${(p) => p.theme.colors.ui8};
`;

const FakeButtonIndicator = styled.div<{ $isSell?: boolean }>`
    width: 40px;
    height: 6px;
    background-color: ${(p) => (p.$isSell ? p.theme.colors.negative8 : p.theme.colors.brand8)};
`;

export const AddInstrumentTile = ({
    onAddInstrumentClick,
}: {
    onAddInstrumentClick: () => void;
}) => (
    <TileLayout>
        <Content>
            <PrimaryButton size="small" onClick={onAddInstrumentClick}>
                <Icons.Plus />
                Add new instrument
            </PrimaryButton>
            <Description>
                <PXSmall>
                    The instrument will be available in the selected trading mode after adding it
                </PXSmall>
            </Description>
        </Content>
        <FakeActions>
            <LeftFakeButton>
                <FakeButtonIndicator $isSell />
            </LeftFakeButton>
            <RightFakeButton>
                <FakeButtonIndicator />
            </RightFakeButton>
        </FakeActions>
    </TileLayout>
);
