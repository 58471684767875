import { BasicButton, PSmall } from "@fm-frontend/uikit";
import styled from "styled-components";

export const Content = styled.div`
    background: ${(p) => p.theme.colors.uiWhite100};
    width: 320px;
    border-radius: 8px;
    box-shadow: 0px 8px 32px ${(p) => p.theme.colors.ui12},
        inset 0px 0px 0px 1px ${(p) => p.theme.colors.ui12};
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 12px;
    border-bottom: 1px solid ${(p) => p.theme.colors.ui8};
    color: ${(p) => p.theme.colors.ui100};
`;

export const Strong = styled.span`
    color: ${(p) => p.theme.colors.brand100}; ;
`;

export const NotificationList = styled.div`
    max-height: 300px;
    overflow-y: auto;
    padding: 0 12px;
`;

export const Footer = styled.div`
    height: 48px;
    padding: 0 12px;
    padding-bottom: 4px;
    border-top: 1px solid ${(p) => p.theme.colors.ui8};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const EmptyContent = styled.div`
    text-align: center;
    padding: 0 32px 20px 32px;
`;

export const Panda = styled.div`
    font-size: 48px;
`;

export const Description = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui52};
`;

export const LoadMoreButton = styled(BasicButton)`
    margin-bottom: 12px;
`;
