import { P } from "@fm-frontend/uikit";
import { EmDash } from "@fm-frontend/utils";
import React from "react";
import styled from "styled-components";
import { fmt, FormatConfig } from "utils/format";
import { TradeHistory } from "../../../types";
import { CopyCell } from "../CopyCell";

const PositiveIndicator = styled(P)`
    color: ${(p) => p.theme.colors.positive100};
`;
const NegativeIndicator = styled(P)`
    color: ${(p) => p.theme.colors.negative100};
`;

type DeltaCellProps = Pick<TradeHistory, "delta">;

export const deltaCellFormatConfig: FormatConfig = {
    significantDigits: 8,
    type: "size",
    showZero: true,
};

export const DeltaCell: React.FC<DeltaCellProps> = ({ delta }) => {
    if (delta === undefined) {
        return <></>;
    }
    if (delta === 0n) {
        return <>{EmDash}</>;
    }

    const fmtTotalVolume = fmt(delta, deltaCellFormatConfig);

    return (
        <CopyCell content={fmtTotalVolume.copyableValue}>
            {fmtTotalVolume.isNegative ? (
                <NegativeIndicator>{fmtTotalVolume.formattedValue}</NegativeIndicator>
            ) : (
                <PositiveIndicator>{fmtTotalVolume.formattedValue}</PositiveIndicator>
            )}
        </CopyCell>
    );
};
