import styled from "styled-components";

export const Content = styled.div`
    background: ${(p) => p.theme.colors.uiWhite100} url("/img/user-created-bg.png") no-repeat right
        top;
    background-size: contain;
    width: 360px;
    border: 1px solid ${(p) => p.theme.colors.ui8};
    box-shadow: 0px 4px 6px rgb(0 0 0 / 4%);
    border-radius: 12px;
    padding: 16px;
    padding-top: 200px;
`;

export const Title = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    color: ${(p) => p.theme.colors.ui100};
    margin: 12px 0;
`;

export const Message = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
    color: ${(p) => p.theme.colors.ui72};
    margin: 12px 0;
`;
