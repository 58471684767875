import * as React from "react";

import { noop } from "../../utils";
import { IconArrow } from "../icons";
import * as Styled from "./styled";
import { IProps } from "./typed";

export const ListItem: React.FC<IProps> = ({ title, value, isFolder, onClick = noop }) => {
    return (
        <Styled.ListItem isPointer={isFolder} onClick={onClick}>
            <span>{title}</span>
            {value ? <Styled.Value>{value}</Styled.Value> : null}
            {isFolder ? <IconArrow /> : null}
        </Styled.ListItem>
    );
};
