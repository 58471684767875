import { Icons, PlainButton } from "@fm-frontend/uikit";
import { useFormShouldCloseConfirm, useToggle } from "@fm-frontend/utils";
import { useIsOwnerUser } from "hooks";
import React from "react";
import { useTheme } from "styled-components";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { ACCOUNT_REVISIONS_URL, PAYMENT_ACCOUNTS_URL, PENDING_ACCOUNTS_REVISIONS_URL } from "../api";
import { AddressesModal } from "../components/AddressesModal";
import { InfoBadge } from "../components/InfoBadge";
import { useRefreshAddresses, useSaveSuccessNotification } from "../hooks";
import { AddressModalProps, CryptoAddress } from "../types";
import { ADDRESS_IS_SAVED_NOTIFICATION, EDITING_UNAVAILABLE_MESSAGE, SAB_CRYPTO_EDIT_MODAL_KEY } from "../utils";
import { ConfirmCryptoAddressModal } from "./modals/ConfirmCryptoAddressModal";
import { EditCryptoAddressModal } from "./modals/EditCryptoAddressModal";
import { OverviewCryptoAddressModal } from "./modals/OverviewCryptoAddressModal";

type ContentProps = Omit<AddressModalProps<CryptoAddress>, "isModalOpen">;

const CryptoAddressModalContent: React.FC<ContentProps> = ({ closeModal, address, defaultEdit, side }) => {
    const { colors } = useTheme();
    const isOwnerUser = useIsOwnerUser();
    const {
        isActive: isEditActive,
        activate: activateEdit,
        deactivate: deactivateEdit,
    } = useToggle(Boolean(defaultEdit));

    const shouldConfirmClose = useFormShouldCloseConfirm(SAB_CRYPTO_EDIT_MODAL_KEY);
    const { closeModalWithConfirm: closeEditModalWithConfirm } = useModalCloseWithConfirm(
        shouldConfirmClose,
        defaultEdit ? closeModal : deactivateEdit,
    );
    const addressSaveNotification = useSaveSuccessNotification();

    const isSharedSide = side === "shared";
    const isYourSide = side === "your";

    const refreshAddresses = useRefreshAddresses();

    if (!address) {
        return null;
    }

    const handleEditSuccess = async () => {
        closeModal();
        addressSaveNotification(ADDRESS_IS_SAVED_NOTIFICATION);
        await refreshAddresses([PAYMENT_ACCOUNTS_URL, ACCOUNT_REVISIONS_URL]);
    };

    const handleConfirmSuccess = async () => {
        closeModal();
        await refreshAddresses([PENDING_ACCOUNTS_REVISIONS_URL]);
    };

    if (isEditActive && isYourSide && isOwnerUser) {
        return (
            <EditCryptoAddressModal
                address={address}
                onCancel={closeEditModalWithConfirm}
                onEditSuccess={handleEditSuccess}
            />
        );
    }

    const revisionHash = address.revisions?.[0].revisionHash;

    if (revisionHash && isSharedSide && isOwnerUser) {
        return (
            <ConfirmCryptoAddressModal
                revisionHash={revisionHash}
                address={address}
                onCancel={closeModal}
                onConfirmSuccess={handleConfirmSuccess}
            />
        );
    }

    const getEditAction = () => {
        if (isSharedSide) {
            return null;
        }

        if (!isOwnerUser) {
            return (
                <InfoBadge
                    info={EDITING_UNAVAILABLE_MESSAGE}
                    renderIcon={() => <Icons.Attention color={colors.ui32} />}
                />
            );
        }

        return (
            <PlainButton fullWidth size="large" type="button" onClick={activateEdit}>
                Edit
            </PlainButton>
        );
    };

    return <OverviewCryptoAddressModal side={side} address={address} actions={getEditAction} />;
};

export const CryptoAddressModal: React.FC<AddressModalProps<CryptoAddress>> = ({
    closeModal,
    isModalOpen,
    ...rest
}) => {
    const shouldConfirmClose = useFormShouldCloseConfirm(SAB_CRYPTO_EDIT_MODAL_KEY);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(shouldConfirmClose, closeModal);

    return (
        <AddressesModal isOpen={isModalOpen} onClose={closeModalWithConfirm}>
            <CryptoAddressModalContent closeModal={closeModal} {...rest} />
        </AddressesModal>
    );
};
