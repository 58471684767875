import { BITMASK_UTILS } from "@fm-frontend/utils";
import { post } from "api";
import { ModalDialog } from "components/modal/Modal";
import { getFullIntent } from "components/modal/utils";
import { TopMenu } from "components/TopMenu";
import { setConfirmModalContent } from "feature/app";
import { DeleteButton } from "feature/form/Buttons";
import { FormFooter, FormHeader, FormInline, FormLabel, FormValue } from "feature/form/style";
import { Table } from "feature/table";
import { useItemTransition } from "hooks/useTransition";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Page, Sidebar } from "style";
import styled from "styled-components";
import { KeyForm } from "./form";
import { columns } from "./table";
import { ALLOW_LIMITS_MANAGEMENT, ALLOW_SETTLEMENTS, ALLOW_TRADING } from "./utils";

const ModalContainer = styled.div`
    ${Form} {
        max-width: 80vw;
    }

    ${FormValue} {
        word-break: break-all;
    }
`;

const { isBitKeyApplied } = BITMASK_UTILS;

export const Keys = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState();
    const [showUntil, setShowUntil] = useState<number>();
    const [counter, setCounter] = useState(0);
    const [newKey, setNewKey] = useState<any>();
    const getKeys = () => post("keys", {}).then(setData);
    const deleteKey = (key: string) => post("delKey", { key });
    useEffect(() => {
        getKeys();
    }, []);
    useEffect(() => {
        if (newKey) {
            setShowUntil(new Date().valueOf() + 60 * 1000);
            setCounter(60);
        }
    }, [newKey]);
    useEffect(() => {
        if (showUntil && new Date().valueOf() < showUntil && newKey) {
            setTimeout(() => setCounter(counter - 1), 1000);
        } else {
            setNewKey(undefined);
            setShowUntil(undefined);
        }
    }, [counter, showUntil]);
    const { isActive, transitionState, props: newKeyMemo } = useItemTransition(newKey);

    return (
        <Page>
            <TopMenu>Keys</TopMenu>
            <Sidebar>
                <KeyForm
                    onSubmit={(key: any) => {
                        getKeys();
                        setNewKey(key);
                    }}
                />
                <div>
                    <Table
                        title="Your keys"
                        emptyMessage="You haven't created any keys yet"
                        data={data || []}
                        columns={columns}
                        actions={([key]: any) => (
                            <>
                                <DeleteButton
                                    onClick={() => {
                                        dispatch(
                                            setConfirmModalContent({
                                                title: "Delete Key",
                                                onConfirm: () => deleteKey(key).then(getKeys),
                                                description: getFullIntent("delete this key"),
                                                confirmButtonTitle: "Delete",
                                            }),
                                        );
                                    }}
                                />
                            </>
                        )}
                    />
                </div>
            </Sidebar>
            {isActive && (
                <ModalDialog
                    isOpen
                    isDismissable
                    onClose={() => setNewKey(undefined)}
                    transitionState={transitionState}
                >
                    {newKeyMemo && (
                        <ModalContainer>
                            <Form>
                                <FormHeader>New key created</FormHeader>
                                <FormInline>
                                    <FormLabel>Key</FormLabel>
                                    <FormValue>{newKeyMemo[0]}</FormValue>
                                    <FormLabel>Trade</FormLabel>
                                    <FormValue>{isBitKeyApplied(newKeyMemo[1], ALLOW_TRADING) ? "Y" : "N"}</FormValue>
                                    <FormLabel>Settlement</FormLabel>
                                    <FormValue>
                                        {isBitKeyApplied(newKeyMemo[1], ALLOW_SETTLEMENTS) ? "Y" : "N"}
                                    </FormValue>
                                    <FormLabel>Risk management</FormLabel>
                                    <FormValue>
                                        {isBitKeyApplied(newKeyMemo[1], ALLOW_LIMITS_MANAGEMENT) ? "Y" : "N"}
                                    </FormValue>
                                    <FormLabel>Secret</FormLabel>
                                    <FormValue>{newKeyMemo.length == 4 ? newKeyMemo[3] : newKeyMemo[4]}</FormValue>
                                </FormInline>
                                <FormFooter>This window will close in {counter}s</FormFooter>
                            </Form>
                        </ModalContainer>
                    )}
                </ModalDialog>
            )}
        </Page>
    );
};

export default Keys;
