import { PSmall } from "@fm-frontend/uikit";
import { EmDash } from "@fm-frontend/utils";
import { CurrencyIcon } from "components/CurrencyIcon";
import {
    Container,
    Content,
    Item,
    ItemContent,
    ItemTitle,
    ItemWrapper,
    StyledHeader,
    Title,
} from "../styled";

const EmptyStateItem = ({ title }: { title: string }) => (
    <Container>
        <StyledHeader>
            <Title>
                <CurrencyIcon size={12} name="" />
                {title}
            </Title>
        </StyledHeader>
        <Content noCpLimit>
            <Item>
                <ItemTitle>Free</ItemTitle>
                <ItemContent>
                    <PSmall>{EmDash}</PSmall>
                </ItemContent>
            </Item>
            <ItemWrapper>
                <Item>
                    <ItemTitle>Asset limit</ItemTitle>
                    <ItemContent>
                        <PSmall>{EmDash}</PSmall>
                    </ItemContent>
                </Item>
                <Item>
                    <ItemTitle>Short sales</ItemTitle>
                    <ItemContent>
                        <PSmall> {EmDash}</PSmall>
                    </ItemContent>
                </Item>
            </ItemWrapper>
        </Content>
    </Container>
);

export const AssetLimitsEmptyState = () => {
    return (
        <>
            <EmptyStateItem title="Base asset" />
            <EmptyStateItem title="Quote asset" />
        </>
    );
};
