import styled, { css } from "styled-components";
import { ButtonSize } from "../types";

const BUTTON_HEIGHT: { [Size in ButtonSize]: number } = {
    large: 44,
    medium: 32,
    small: 24,
};
const BUTTON_PADDING: { [Size in ButtonSize]: string } = {
    large: "12px",
    medium: "6px 12px",
    small: "4px 8px",
};

const smallButtonFontStyles = css`
    font-size: 12px;
    line-height: 16px;
`;
const buttonFontStyles = css`
    font-size: 14px;
    line-height: 18px;
`;

// common button styles for all specific button variants
const CommonButton = styled.button.withConfig({
    shouldForwardProp: (prop) => !["loading", "fullWidth"].includes(prop),
})<{
    size: ButtonSize;
    loading: boolean;
    fullWidth: boolean;
}>`
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    ${(p) => (p.size === "small" ? smallButtonFontStyles : buttonFontStyles)}
    letter-spacing: -0.005em;
    padding: ${(p) => BUTTON_PADDING[p.size]};
    width: ${(p) => (p.fullWidth ? "100%" : "fit-content")};
    min-height: ${(p) => BUTTON_HEIGHT[p.size]}px;
    white-space: nowrap;

    &:focus-visible {
        box-shadow: 0px 0px 0px 3px ${(p) => p.theme.colors.brand12},
            inset 0px 0px 0px 1px ${(p) => p.theme.colors.brand72};
    }
    ${({ loading }) =>
        loading &&
        css`
            cursor: wait;
        `}
`;

const largeBasicButtonStyles = css`
    background: transparent;
    color: ${(p) => p.theme.colors.brand100};
    border: 1px solid ${(p) => p.theme.colors.ui12};
    box-shadow: inset 0px -1px 0px ${(p) => p.theme.colors.ui8};

    &:hover {
        background: ${(p) => p.theme.colors.ui8};
    }
`;
const mediumAndSmallBasicButtonStyles = css`
    background: ${(p) => p.theme.colors.ui8};
    color: ${(p) => p.theme.colors.ui100};

    &:hover {
        background: ${(p) => p.theme.colors.ui12};
    }
`;
export const BasicButton = styled(CommonButton)`
    ${(p) => (p.size === "large" ? largeBasicButtonStyles : mediumAndSmallBasicButtonStyles)}

    ${(p) =>
        !p.loading &&
        css`
            &:disabled {
                cursor: not-allowed;
                background: ${(p) => p.theme.colors.ui4};
                color: ${(p) => p.theme.colors.ui20};
            }
        `}
`;

export const DesctructiveButton = styled(BasicButton)`
    color: ${(p) => p.theme.colors.negative80};
`;

export const PrimaryButton = styled(CommonButton)`
    background-color: ${(p) => p.theme.colors.brand100};
    color: ${(p) => p.theme.colors.uiWhite100};
    box-shadow: 0px -1px 0px 0px ${(p) => p.theme.colors.ui8} inset;

    &:hover {
        background: linear-gradient(
                0deg,
                ${(p) => p.theme.colors.ui12} 0%,
                ${(p) => p.theme.colors.ui12} 100%
            ),
            ${(p) => p.theme.colors.brand100};
    }

    ${(p) =>
        !p.loading &&
        css`
            &:disabled {
                cursor: not-allowed;
                background-color: ${(p) => p.theme.colors.ui4};
                color: ${(p) => p.theme.colors.ui20};
            }
        `}
`;

export const PlainButton = styled(CommonButton)`
    background: transparent;
    color: ${(p) => p.theme.colors.ui100};
    &:hover {
        background: ${(p) => p.theme.colors.ui8};
    }

    ${(p) =>
        !p.loading &&
        css`
            &:disabled {
                cursor: not-allowed;
                background: transparent;
                color: ${(p) => p.theme.colors.ui20};
            }
        `}
`;

export const LinkButton = styled(CommonButton)`
    background: none;
    border: none;
    color: ${(p) => p.theme.colors.action};
    font-weight: initial;
    letter-spacing: initial;
    line-height: initial;
    min-height: initial;
    padding: 0;

    ${(p) =>
        p.size === "small"
            ? css`
                  font-size: 14px;
              `
            : css`
                  font-size: 16px;
              `}

    &:hover,
    &:focus-visible {
        text-decoration: underline;
        box-shadow: none;
    }

    ${(p) =>
        !p.loading &&
        css`
            &:disabled {
                cursor: not-allowed;
                color: ${(p) => p.theme.colors.ui20};
                text-decoration: none;
            }
        `}
`;
