import { Flex, HStack, PBold, Tbody, Td, Th, VStack } from "@fm-frontend/uikit";
import styled, { css } from "styled-components";

export const Tr = styled.tr`
    ${Td} {
        cursor: default;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    ${Th}:first-of-type, ${Td}:first-of-type {
        padding-left: 0;
    }
    ${Th}:last-of-type, ${Td}:last-of-type {
        padding-right: 0;
    }
`;

export const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

export const ActionContainer = styled(HStack)`
    position: sticky;
    bottom: 0;
    background-color: ${(p) => p.theme.colors.uiWhite100};
`;

export const FormAddress = styled(PBold)`
    word-wrap: break-word;
`;

export const FormSectionDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${(p) => p.theme.colors.ui8};
    margin: 10px 0;
`;

export const AddressTableRowExpandContainer = styled(Flex)`
    cursor: pointer;
`;

const SHOW_UP_ANIMATION_DURATION = 1200;
const showUpAnimationCss = css`
    @keyframes showUp {
        0% {
            background-color: ${(p) => p.theme.colors.brand4};
        }
        100% {
            background-color: transparent;
        }
    }

    animation: showUp ${SHOW_UP_ANIMATION_DURATION}ms ease-out;
`;

export const LightboxErrorContainer = styled(Tbody)<{ isError: boolean; animateShowUp: boolean }>`
    ${(p) =>
        p.isError &&
        css`
            background-color: ${p.theme.colors.negative4};
        `}

    box-shadow: 0px -1px 0px 0px ${(p) => p.theme.colors.ui8} inset;

    ${(p) => p.animateShowUp && showUpAnimationCss}

    :hover {
        background-color: ${(p) => p.theme.colors.ui4};
    }
`;
