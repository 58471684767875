import { useInstruments } from "hooks";
import { useCounterparties } from "hooks/useCounterparties";
import { useMemo } from "react";
import { BankAddress, UseAddressType } from "../types";
import { BANK_CURRENCY_TYPES, getAccountStatus } from "../utils";
import { useAccountRevisions } from "./useAccountRevisions";
import { useBanks } from "./useBanks";
import { usePaymentAccounts } from "./usePaymentAccounts";
import { usePaymentAccountsRules } from "./usePaymentAccountsRules";

export const useBanksAddresses = (): UseAddressType<BankAddress> => {
    const { paymentAccounts, isPaymentAccountsLoading } = usePaymentAccounts();
    const { accountsRevisions, isRevisionsLoading } = useAccountRevisions();
    const { currencies, isLoading: isInstrumentsLoading } = useInstruments();
    const { banks, isBanksLoading } = useBanks();
    const { paymentAccountsRules, isPaymentAccountsRulesLoading } = usePaymentAccountsRules();
    const { cpIds, isLoading: isCounterpartiesLoading } = useCounterparties();

    const isLoading = isPaymentAccountsLoading || isRevisionsLoading || isInstrumentsLoading || isBanksLoading || isPaymentAccountsRulesLoading || isCounterpartiesLoading;

    const data = useMemo(() => {
        return paymentAccounts
            .reduce((acc, curr) => {
                if (!BANK_CURRENCY_TYPES.includes(curr.currencyType)) {
                    return acc;
                }

                const availableAddressRevisions =
                    accountsRevisions[curr.id]?.filter(({ cpId }) => cpIds.includes(cpId)) ?? [];

                const accountStatus = getAccountStatus("your", availableAddressRevisions);
                const currency = Object.values(currencies).find(({ id }) => curr.currencyId === id);
                const bankPrimary = banks.find((b) => b.id === curr.bankPrimaryId);
                const bankIntermediate = banks.find((b) => b.id === curr.bankIntermediateId);
                const accountRule = paymentAccountsRules.find(({ paymentAccountId }) => paymentAccountId === curr.id);

                acc.push({
                    rule: accountRule
                        ? {
                              id: accountRule.id,
                              accountId: curr.id,
                          }
                        : null,
                    accountId: curr.id,
                    currency: currency
                        ? {
                              id: currency.id,
                              name: currency.name,
                          }
                        : null,
                    wallet: curr.data.wallet,
                    privateNote: curr.notes,
                    revisions: availableAddressRevisions,
                    status: accountStatus,
                    bankPrimary: bankPrimary
                        ? {
                              id: bankPrimary.id,
                              name: bankPrimary.data.name,
                              address: bankPrimary.data.address,
                          }
                        : null,
                    bankIntermediate: bankIntermediate
                        ? {
                              id: bankIntermediate.id,
                              name: bankIntermediate.data.name,
                              address: bankIntermediate.data.address,
                          }
                        : null,
                    company: {
                        name: curr.data.companyName,
                        address: curr.data.companyAddress,
                    },
                    publicDetails: curr.data.publicDetails,
                    otherDetails: curr.data.otherDetails,
                    memoOrTag: curr.data.memoOrTag,
                } as BankAddress);

                return acc;
            }, [] as BankAddress[])
            .sort((a, b) => {
                if (a?.currency?.name === undefined) {
                    return -1;
                }
                if (b?.currency?.name === undefined) {
                    return 1;
                }

                return a.currency.name.localeCompare(b.currency.name);
            });
    }, [accountsRevisions, paymentAccounts, currencies, banks, paymentAccountsRules, cpIds]);

    return {
        data,
        isLoading,
    };
};
