import { H3Bold, Icons, PXSmall } from "@fm-frontend/uikit";
import { getLimitStatus } from "feature/assetsControl/components/AssetsTabContent/utils";
import styled, { css, useTheme } from "styled-components";

export const Container = styled.article`
    display: flex;
    flex-direction: column;

    border-top: 1px solid ${(p) => p.theme.colors.ui8};
    :nth-child(odd) {
        border-right: 1px solid ${(p) => p.theme.colors.ui8};
    }
`;

export const StyledHeader = styled.header`
    display: flex;
    height: 28px;
    padding: 6px 12px;
    justify-content: space-between;
    align-items: center;

    background: ${(p) => p.theme.colors.ui4};
`;

export const Content = styled.section<{ noCpLimit: boolean }>`
    display: flex;
    padding: 8px 0;
    flex-direction: column;
    position: relative;

    ${(p) =>
        p.noCpLimit &&
        css`
            filter: blur(1.5px);
        `}
`;

export const ItemContent = styled.div`
    display: flex;
    align-items: baseline;
    gap: 4px;

    max-width: 250px;
`;

export const Item = styled.div<{ $override?: boolean; $disabled?: boolean }>`
    display: flex;
    flex: 1;
    padding: 4px 12px;
    flex-direction: column;
    gap: 4px;

    ${(p) =>
        p.$disabled &&
        css`
            ${ItemContent} {
                color: ${p.theme.colors.ui32};
            }
        `}
    ${(p) =>
        p.$override &&
        css`
            ${ItemContent} {
                text-decoration: line-through;
                color: ${p.theme.colors.ui32};
            }
        `}
`;

export const ItemTitle = styled(PXSmall)`
    width: 100%;
    color: ${(p) => p.theme.colors.ui52};
`;

export const ItemWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(50%, 1fr));

    @media (max-width: 1440px) {
        ${ItemContent} {
            max-width: 150px;
        }
    }
    @media (max-width: 1359px) {
        ${ItemContent} {
            max-width: 140px;
        }
    }
    @media (max-width: 1144px) {
        ${ItemContent} {
            max-width: 130px;
        }
    }
`;

export const Status = styled(PXSmall)<{ $disabled?: boolean; $percent: number }>`
    font-weight: 600;

    color: ${(p) => {
        if (p.$disabled) {
            return p.theme.colors.ui52;
        }

        switch (getLimitStatus(p.$percent)) {
            case "normal":
                return p.theme.colors.positive100;
            case "warn":
                return p.theme.colors.attention100;
            case "low":
                return p.theme.colors.negative100;
            default:
                return p.theme.colors.ui100;
        }
    }};
`;

export const Indicator = styled.div<{ $disabled?: boolean; $percent: number }>`
    top: 0;
    height: 2px;
    width: ${(p) => (p.$percent > 100 ? 100 : p.$percent)}%;
    position: absolute;

    background-color: ${(p) => {
        if (p.$disabled) {
            return p.theme.colors.ui52;
        }

        switch (getLimitStatus(p.$percent)) {
            case "normal":
                return p.theme.colors.positive100;
            case "warn":
                return p.theme.colors.attention100;
            case "low":
                return p.theme.colors.negative100;
            default:
                return p.theme.colors.ui100;
        }
    }};
`;

export const Title = styled(H3Bold)`
    display: flex;
    align-items: center;
    gap: 6px;
`;

export const Action = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 50%;
`;

export const WidgetForwardIcon = () => {
    const { colors } = useTheme();

    return <Icons.Forward color={colors.ui32} />;
};

export const Banner = styled.label`
    position: absolute;
    display: flex;
    padding: 4px 8px 4px 6px;
    justify-content: center;
    align-items: center;
    gap: 6px;

    border-radius: 8px;
    background-color: white;
    box-shadow: 0 0.5px 0 0 rgba(36, 48, 52, 0.2) inset, 0 1px 2px 0 rgba(36, 48, 52, 0.24);

    text-wrap: nowrap;

    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
`;
