import { Flex, H2, HStack, Search, VStack } from "@fm-frontend/uikit";
import { getEnv } from "@fm-frontend/utils";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { LS_VARIABLES, useLSState } from "hooks/useLSState";
import { useCallback, useMemo, useState, VFC } from "react";
import { GroupBy, usePositionsTableData } from "store/usePositionsGrouped";
import styled from "styled-components";
import { PositionsTable } from "./PositionsTable";
import { PositionsTableRowData } from "./types";
import { isGroupedByAssetExpandableRow, isGroupedByCpExpandableRow } from "./utils";

const Container = styled(Flex)`
    overflow: auto;

    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

const Tab = styled(H2)<{ isActive?: boolean }>`
    cursor: pointer;
    color: ${(p) => (p.isActive ? p.theme.colors.ui100 : p.theme.colors.ui32)};

    :hover {
        color: ${(p) => p.theme.colors.ui100};
    }
`;

const FiltersContainer = styled.div`
    width: 150px;
`;

const GROUP_BY_TITLES: Record<GroupBy, string> = {
    counterparty: "Counterparties",
    asset: "Assets",
};
const { HRP_MASTER_ID } = getEnv();

export const PositionsPageContent: VFC = () => {
    const { getCpName } = useCpInfoHelpers();
    const [groupBy, setGroupBy] = useLSState<GroupBy>(LS_VARIABLES.POSITIONS_GROUP_BY, "counterparty");
    const [query, setQuery] = useState("");

    const { totalUSD, totalPlannedUSD, groupedPositionsData, isLoading } = usePositionsTableData({
        groupBy,
        includeSettlementOrders: true,
    });

    const data: PositionsTableRowData[] = useMemo(() => {
        if (isLoading) {
            return [];
        }

        return [
            {
                valueUSD: totalUSD,
                plannedValueUSD: totalPlannedUSD,
                isTotal: true,
            },
            ...Object.entries(groupedPositionsData)
                .filter(([groupId, groupData]) => {
                    const { subitems } = groupData;
                    const searchableTexts = (
                        groupBy === "asset" ? [String(groupId)] : [String(groupId), getCpName(groupId, "full")]
                    )
                        .concat(
                            Object.values(subitems).flatMap(({ cpId, cpName, asset }) =>
                                groupBy === "asset" ? [String(cpId), cpName] : [asset],
                            ),
                        )
                        .map((str) => str.toLocaleLowerCase());

                    return searchableTexts.some((str) => str.includes(query.toLocaleLowerCase()));
                })
                .map(([groupId, groupData]) => {
                    const { partUSD: valueUSD, partPlannedUSD: plannedValueUSD, subitems } = groupData;
                    const filteredSubrows = Object.values(subitems).filter(({ cpId, cpName, asset }) => {
                        const searchableTexts = groupBy === "asset" ? [String(cpId), cpName] : [asset];
                        return searchableTexts.some((str) =>
                            str.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
                        );
                    });

                    const subrows = filteredSubrows.length === 0 ? Object.values(subitems) : filteredSubrows;
                    subrows.sort((subrow1, subrow2) => {
                        const comparator = groupBy === "asset" ? "cpName" : "asset";

                        return subrow1[comparator].localeCompare(subrow2[comparator]);
                    });

                    if (groupBy === "asset") {
                        return {
                            asset: groupId,
                            subrows,
                            valueUSD,
                            plannedValueUSD,
                        };
                    }

                    return {
                        cpId: Number(groupId),
                        cpName: getCpName(groupId, "full"),
                        subrows:
                            Number(groupId) === HRP_MASTER_ID
                                ? [
                                      {
                                          isHRPClearingTimeInfoRow: true as const,
                                      },
                                      ...subrows,
                                  ]
                                : subrows,
                        valueUSD,
                        plannedValueUSD,
                    };
                })
                .sort((expandableRow1, expandableRow2) => {
                    if (
                        isGroupedByAssetExpandableRow(expandableRow1) &&
                        isGroupedByAssetExpandableRow(expandableRow2)
                    ) {
                        return expandableRow1.asset.localeCompare(expandableRow2.asset);
                    }
                    if (isGroupedByCpExpandableRow(expandableRow1) && isGroupedByCpExpandableRow(expandableRow2)) {
                        return expandableRow1.cpName.localeCompare(expandableRow2.cpName);
                    }
                    return 0;
                }),
        ];
    }, [isLoading, groupBy, groupedPositionsData, getCpName, query]);

    const getSubRows = useCallback((row) => row.subrows, []);

    return (
        <Container spacing={8} paddingLeft={8} paddingRight={8} className="positionsPageContent">
            <CardContainer flex={1} asCard minWidth="445px">
                <Flex padding={12} alignItems="flex-start">
                    <HStack flex={1} spacing={16}>
                        {Object.entries(GROUP_BY_TITLES).map(([value, title]) => (
                            <Tab isActive={value === groupBy} key={value} onClick={() => setGroupBy(value as GroupBy)}>
                                {title}
                            </Tab>
                        ))}
                    </HStack>
                    <FiltersContainer>
                        <Search size="small" query={query} onChange={setQuery} />
                    </FiltersContainer>
                </Flex>
                <PositionsTable
                    query={query}
                    isLoading={isLoading}
                    data={data}
                    getSubRows={getSubRows}
                    groupBy={groupBy}
                />
            </CardContainer>
        </Container>
    );
};
