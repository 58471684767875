import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CounterpartyStatusType } from "../feature/counterparties/utils";

export type State = {
    limits: Record<string, CpStatus>;
};

export type CpStatus = {
    status: CounterpartyStatusType;
    grossStatus: CounterpartyStatusType;
    equityStatus: CounterpartyStatusType;
};

const initialState: State = {
    limits: {},
};

export const counterpartiesSlice = createSlice({
    name: "counterparties",
    initialState,
    reducers: {
        setStatuses(state, { payload }: PayloadAction<Record<string, CpStatus>>) {
            state.limits = payload;
        },
    },
});

export const {
    actions: { setStatuses },
    reducer,
} = counterpartiesSlice;
