import { SEARCH_PARAMS } from "const";
import { BrokerViewType } from "types";
import { useSearchParamState } from "./useSearchParamState";

export const usePrimeBrokerViewTypeChange = () => {
    const [, handlePrimeBrokerViewTypeChange] = useSearchParamState<BrokerViewType>(
        SEARCH_PARAMS.primeBrokerViewType,
        "counterparties",
        {
            persist: true,
        },
    );

    return handlePrimeBrokerViewTypeChange;
};
