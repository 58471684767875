import styled from "styled-components";
import { onHover } from "../../const";

export const ListItem = styled.div<{ isPointer?: boolean }>`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #f0eeee;
    padding: 20px 0;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.005em;
    color: ${({ theme }) => theme.colors.textSubdued};
    cursor: ${({ isPointer }) => (isPointer ? "pointer" : "")};
    svg {
        margin-right: 8px;
    }
    ${onHover} {
        background-color: ${({ theme }) => theme.colors.listItemHover};
    }
`;

export const Value = styled.span`
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.005em;
    text-align: right;
    color: ${({ theme }) => theme.colors.textFG};
`;
