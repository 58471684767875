import { Form, Input, Loading, PrimaryButton } from "@fm-frontend/uikit";
import { useFormCloseConfirmer } from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { fetchClientType } from "api/methods";
import { POSITION_COUNTERPARTY_ID } from "const/position";
import { useSubaccounts } from "feature/subaccounts/api";
import { useSelector } from "hooks";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useCounterpartyLimitsSnapshot, usePrimeBrokerViewType } from "store/hooks";
import styled from "styled-components";
import { ClientType } from "types";
import { kClientNotFound, messages as errorMessages } from "utils/ErrorCodes";
import { ValidationMessages } from "utils/validationMessages";
import { AnySchema, number, object } from "yup";
import { getInputLabel, getInputPlaceholder, getSubmitButtonText } from "./messages";
import { SubaccountSelector } from "./subaccountSelector/SubaccountSelector";
import { COUNTERPARTY_LIMIT_MODAL_KEY } from "./utils";

interface FormInputs {
    cpId: number;
}

export type CpData = { cpId: number; cpName?: string; cpType: ClientType };

interface CounterpartyIdModalProps {
    onCounterpartySelected: (selectedCp: CpData) => void;
}

type CounterpartyIdFormFieldValues = {
    cpId: number;
};

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const Link = styled.div`
    cursor: pointer;
    color: ${(p) => p.theme.colors.brand100};
    text-decoration: underline;
`;

const CounterpartyIdForm = styled(Form)`
    min-width: auto;
    max-width: none;

    label {
        margin-bottom: 4px;
    }
`;

const validationSchema = object<Record<keyof FormInputs, AnySchema>>().shape({
    cpId: number()
        .typeError(ValidationMessages.MUST_BE_NUMBER)
        .transform((value: any, originalValue: any) => (originalValue === "" ? undefined : value))
        .required(ValidationMessages.REQUIRED),
});

const defaultValues = {
    cpId: undefined,
};

export const CounterpartyIdModal = ({ onCounterpartySelected }: CounterpartyIdModalProps) => {
    const allAvailableCounterpartiesInfo = useSelector((state) => state.app.allAvailableCounterpartiesInfo);
    const { data: counterpartyLimits } = useCounterpartyLimitsSnapshot();
    const {
        register,
        handleSubmit,
        watch,
        setError,
        clearErrors,
        formState: { errors, isDirty },
    } = useForm<CounterpartyIdFormFieldValues>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    useFormCloseConfirmer(COUNTERPARTY_LIMIT_MODAL_KEY, isDirty);

    const [submitting, setSubmitting] = useState(false);
    const primeBrokerViewType = usePrimeBrokerViewType();
    const { subaccounts } = useSubaccounts();
    const subaccountIdList = subaccounts.map((subaccount) => subaccount.info.clientId);
    const availableSubaccounts = subaccounts.filter((subaccount) =>
        counterpartyLimits.every((limit) => limit[POSITION_COUNTERPARTY_ID] !== subaccount.info.clientId),
    );

    const counterpartyId = watch("cpId");
    const isSubaccount = subaccountIdList.includes(Number(counterpartyId));

    const submit = async ({ cpId }: FormInputs) => {
        const limitExist = counterpartyLimits.find((limit) => limit[POSITION_COUNTERPARTY_ID] === cpId);
        const DEFAULT_CP_NAME = allAvailableCounterpartiesInfo[cpId]?.subaccount ? "Subaccount" : "Counterparty";

        if (limitExist) {
            setError("cpId", {
                type: "custom",
                message: `You already have the risk profile with ${
                    allAvailableCounterpartiesInfo[cpId]?.username ?? DEFAULT_CP_NAME
                } (${cpId})\nConfigure ${
                    allAvailableCounterpartiesInfo[cpId]?.username ?? DEFAULT_CP_NAME
                } (${cpId}) risk profile\n${cpId}`,
            });
            return;
        }

        setSubmitting(true);

        try {
            clearErrors();
            const cpType = await fetchClientType(cpId);
            const subaccount = subaccounts.find((s) => s.info.clientId === cpId);
            const cpName = subaccount?.info.username;

            setSubmitting(false);

            onCounterpartySelected({ cpId, cpName, cpType });
        } catch (e) {
            const message =
                e === errorMessages[kClientNotFound]
                    ? "You are not allowed to trade with a user, or the user does not exist. Check the Client ID"
                    : String(e);

            setSubmitting(false);
            setError("cpId", { type: "custom", message });
        }
    };

    if (submitting) {
        return (
            <LoadingContainer>
                <Loading />
            </LoadingContainer>
        );
    }

    const [errorMessage, linkMessage, linkCpId] = errors?.cpId?.message?.split("\n") ?? [];

    const handleEdit = async () => {
        const cpId = Number(linkCpId);
        const cpType = await fetchClientType(cpId);
        const subaccount = subaccounts.find((s) => s.info.clientId === cpId);
        const cpName = subaccount?.info.username;

        onCounterpartySelected({ cpId, cpName, cpType });
    };

    return (
        <CounterpartyIdForm onSubmit={handleSubmit(submit)}>
            {primeBrokerViewType === "counterparties" && (
                <Input
                    label={getInputLabel(primeBrokerViewType)}
                    placeholder={getInputPlaceholder(primeBrokerViewType)}
                    {...register("cpId")}
                    error={errorMessage}
                    autoFocus
                    data-1pignore
                />
            )}
            {primeBrokerViewType === "subaccounts" && (
                <SubaccountSelector
                    key={errorMessage ? "with-error" : "without-error"}
                    subaccounts={availableSubaccounts}
                    error={errorMessage}
                    onChange={clearErrors}
                    onFocus={clearErrors}
                    onSelected={(cpId) => submit({ cpId })}
                />
            )}
            {linkMessage !== undefined && <Link onClick={handleEdit}>{linkMessage}</Link>}
            {linkMessage === undefined && primeBrokerViewType === "counterparties" && (
                <PrimaryButton type="submit" fullWidth size="large">
                    {getSubmitButtonText(primeBrokerViewType, counterpartyId, isSubaccount)}
                </PrimaryButton>
            )}
        </CounterpartyIdForm>
    );
};
