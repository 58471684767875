import { all, call, put, select, take, takeEvery } from "@redux-saga/core/effects";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { post } from "api";
import { createNotification, setIsLoadingApp } from "feature/app";
import { sagaCounterpartiesInfoFetcher } from "feature/app/store/counterpartiesInfoFetcher";
import { getIsUserAuthenticated } from "services/authService";
import { IRootState } from "store/index";
import { AuthorizedFavoriteInstruments, FavoriteInstruments, Notifications } from "types";

export const URL_GET_SETTINGS = "settings/getNotifications";
export const URL_SET_SETTINGS = "settings/setNotifications";
export const URL_SET_FAVORITE = "settings/setFavoriteInstruments";
export const URL_GET_FAVORITE = "settings/getFavoriteInstruments";

const loadSettings = createAction("settings/load");

export type ClientFavoriteInstruments = {
    clientId: number;
    favoriteInstruments: string[];
};
export type ClientsFavoriteInstruments = {
    [clientId: number]: FavoriteInstruments;
};

export type State = {
    riskManagementInbox: boolean;
    riskManagementEmail: boolean;
    settlementsInbox: boolean;
    settlementsEmail: boolean;
    systemInbox: boolean;
    systemEmail: boolean;
    showRealTimeNotification: boolean;
    enableSound: boolean;

    favoriteInstruments: ClientsFavoriteInstruments;
    limitsAffinity: Record<number, string>;
};

const initialState: State = {
    riskManagementInbox: false,
    riskManagementEmail: false,
    settlementsInbox: false,
    settlementsEmail: false,
    systemInbox: false,
    systemEmail: false,

    showRealTimeNotification: false,
    enableSound: false,

    favoriteInstruments: {},
    limitsAffinity: {},
};

export const settingSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setSetting(
            state,
            { payload: { key, value } }: PayloadAction<{ key: keyof State; value: any }>,
        ) {
            return {
                ...state,
                [key]: value,
            };
        },
        setFavorites(state, { payload }: PayloadAction<ClientFavoriteInstruments>) {
            state.favoriteInstruments = {
                ...state.favoriteInstruments,
                ...{
                    [payload.clientId]: {
                        favoriteInstruments: payload.favoriteInstruments,
                    },
                },
            };
        },
        setSettings(state, { payload }: PayloadAction<Partial<State>>) {
            return { ...state, ...payload };
        },
        setLimitAffinity(
            state,
            {
                payload: { counterparty, currency },
            }: PayloadAction<{ counterparty: number; currency: string }>,
        ) {
            state.limitsAffinity[counterparty] = currency;
        },
    },
});

function* sagaSaveSettings() {
    const settings: State = yield select((state: IRootState) => state.settings);

    const notifications: Notifications = {
        NotificationRiskManagementInbox: settings.riskManagementInbox,
        NotificationRiskManagementEmail: settings.riskManagementEmail,
        NotificationSettlementsInbox: settings.settlementsInbox,
        NotificationSettlementsEmail: settings.settlementsEmail,
        NotificationSystemInbox: settings.systemInbox,
        NotificationSystemEmail: settings.systemEmail,
        ShowRealTimeNotification: settings.showRealTimeNotification,
        EnableSound: settings.enableSound,
    };

    try {
        yield call(post, URL_SET_SETTINGS, notifications);
    } catch {
        yield put(
            createNotification({
                type: "error",
                content: "Error save notifications",
            }),
        );
    }
}

const postFavoriteInstruments = (payload: FavoriteInstruments) => post(URL_SET_FAVORITE, payload);

const postAuthorizedFavoriteInstruments = (payload: AuthorizedFavoriteInstruments) =>
    post(URL_SET_FAVORITE, payload);

function* sagaSaveFavorites({ payload }: PayloadAction<ClientFavoriteInstruments>) {
    const clientId: number | undefined = yield select(
        (state: IRootState) => state.authentication.clientData?.clientId,
    );

    try {
        if (clientId === payload.clientId) {
            yield call(postFavoriteInstruments, {
                favoriteInstruments: payload.favoriteInstruments,
            });
        } else {
            yield call(postAuthorizedFavoriteInstruments, {
                subAccountId: payload.clientId,
                favoriteInstruments: payload.favoriteInstruments,
            });
        }
    } catch (error) {
        yield put(
            createNotification({
                type: "error",
                content: "Error saving favorites",
            }),
        );
    }
}

const isFavoriteInstruments = (
    value: FavoriteInstruments | ClientsFavoriteInstruments,
): value is FavoriteInstruments => {
    return Array.isArray((value as FavoriteInstruments).favoriteInstruments);
};

export function* sagaLoadSettings() {
    try {
        while (true) {
            const isUserAuthenticated: boolean = yield call(getIsUserAuthenticated);

            if (!isUserAuthenticated) {
                return;
            }

            yield put(
                setIsLoadingApp({
                    key: "sagaLoadSettings",
                    isLoading: true,
                }),
            );
            const [dataRequestFavorite, dataRequest]: [
                FavoriteInstruments | ClientsFavoriteInstruments,
                Notifications,
            ] = yield all([
                call(post, URL_GET_FAVORITE, null),
                call(post, URL_GET_SETTINGS, null),
                call(sagaCounterpartiesInfoFetcher, {}),
            ]);

            if (isFavoriteInstruments(dataRequestFavorite)) {
                const clientId: number | undefined = yield select(
                    (s: IRootState) => s.authentication.clientData?.clientId,
                );

                if (clientId === undefined) {
                    return;
                }

                const settings: Partial<State> = {
                    favoriteInstruments: {
                        [clientId]: dataRequestFavorite,
                    },
                };
                yield put(settingSlice.actions.setSettings(settings));
            } else {
                const settings: Partial<State> = {
                    favoriteInstruments: dataRequestFavorite,
                };
                yield put(settingSlice.actions.setSettings(settings));
            }

            const settings: Partial<State> = {
                riskManagementInbox: dataRequest.NotificationRiskManagementInbox ?? false,
                riskManagementEmail: dataRequest.NotificationRiskManagementEmail ?? false,
                settlementsInbox: dataRequest.NotificationSettlementsInbox ?? false,
                settlementsEmail: dataRequest.NotificationSettlementsEmail ?? false,
                systemInbox: dataRequest.NotificationSystemInbox ?? false,
                systemEmail: dataRequest.NotificationSystemEmail ?? false,
                showRealTimeNotification: dataRequest.ShowRealTimeNotification ?? false,
                enableSound: dataRequest.EnableSound ?? false,
            };
            yield put(settingSlice.actions.setSettings(settings));

            yield put(
                setIsLoadingApp({
                    key: "sagaLoadSettings",
                    isLoading: false,
                }),
            );

            yield take(loadSettings);
        }
    } catch (e) {
        console.error(e);

        yield put(
            setIsLoadingApp({
                key: "sagaLoadSettings",
                isLoading: false,
            }),
        );
    }
}

export function* sagaSettings() {
    yield takeEvery(settingSlice.actions.setSetting, sagaSaveSettings);
    yield takeEvery(settingSlice.actions.setFavorites, sagaSaveFavorites);
}
