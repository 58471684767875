type InstrumentMarkup = {
    instrument: string;
    counterpartyId: number;
    bidMarkup?: number;
    askMarkup?: number;
};

export type BulkUpdateRequest = {
    apiMethod: "addInstrumentMarkups" | "delInstrumentMarkups",
    body: InstrumentMarkup,
};

export const mapInstrumentsToRequestBody = (instrumentMarkup: InstrumentMarkup): BulkUpdateRequest => {
    if (instrumentMarkup.askMarkup === undefined && instrumentMarkup.bidMarkup === undefined) {
        return {
            apiMethod: "delInstrumentMarkups",
            body: instrumentMarkup,
        };
    }

    return {
        apiMethod: "addInstrumentMarkups",
        body: instrumentMarkup,
    };
};
