import { POSITION_COUNTERPARTY_ID } from "const/position";
import { getCpGrossFreeStatus, getEquityStatus } from "feature/counterparties";
import { getCpStatus, parseLimit, shouldUseUserGrossLimit } from "feature/counterparties/utils";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useMemo } from "react";
import { CpStatus } from "store/counterpartiesSlice";
import { useCounterpartyLimitsSnapshot, useIsMakerUser, useUsdPrices } from "store/hooks";

export const useCpsStatuses = () => {
    const isMakerUser = useIsMakerUser();
    const { getCpType } = useCpInfoHelpers();
    const { data: counterpartyLimits, isLoading: isLimitsLoading } =
        useCounterpartyLimitsSnapshot();
    const { priceObj, isLoading: isPriceObjLoading } = useUsdPrices();

    const isLoading = isLimitsLoading || isPriceObjLoading;

    const counterpartiesStatuses = useMemo(() => {
        if (isLoading) {
            return {};
        }

        const limitStatuses: Record<string, CpStatus> = {};

        for (const limit of counterpartyLimits) {
            const { counterpartyId } = parseLimit(limit);
            const cpType = getCpType(counterpartyId);
            const grossStatus = getCpGrossFreeStatus(limit, priceObj);
            const equityStatus = getEquityStatus(
                limit,
                priceObj,
                cpType !== undefined && shouldUseUserGrossLimit(cpType, isMakerUser),
            );
            const status = getCpStatus(limit, grossStatus, equityStatus);

            limitStatuses[limit[POSITION_COUNTERPARTY_ID]] = {
                status: status,
                equityStatus: equityStatus,
                grossStatus: grossStatus,
            };
        }

        return limitStatuses;
    }, [counterpartyLimits, priceObj, isLoading]);

    return useMemo(
        () => ({
            data: counterpartiesStatuses,
            isLoading,
        }),
        [counterpartiesStatuses, isLoading],
    );
};
