import { ACTIONS_TABLE_COLUMN_KEY, LightBox } from "@fm-frontend/uikit";
import { useFormShouldCloseConfirm, useModalControl } from "@fm-frontend/utils";
import { Row } from "@tanstack/react-table";
import React, { useMemo, useState } from "react";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { useAddressContext } from "../AddressesContext";
import { CryptoAddress, CryptoTableData, DeleteAccountType, FormValidationError, UseAddressType } from "../types";
import { filterAddresses, hasCryptoAddressDuplicate, isAddressGroupType, SAB_CRYPTO_LIGHTBOX_KEY } from "../utils";
import { CryptoAddressLightBox } from "./CryptoAddressLightBox";
import { CryptoAddressModal } from "./CryptoAddressModal";
import { CryptoTable } from "./CryptoTable";
import { CryptoFormContextProvider } from "./form/CryptoFormContext";

type CryptoAddressesProps = {
    cryptoAddresses: UseAddressType<CryptoAddress>;
    sharedCryptoAddresses: UseAddressType<CryptoAddress>;
};

export const CryptoAddresses: React.FC<CryptoAddressesProps> = ({ cryptoAddresses, sharedCryptoAddresses }) => {
    const [defaultEdit, setDefaultEdit] = useState(false);
    const { side, isLightBoxOpen, closeLightBox, selectedCps, selectedStatuses } = useAddressContext();

    const shouldCloseConfirm = useFormShouldCloseConfirm(SAB_CRYPTO_LIGHTBOX_KEY);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(shouldCloseConfirm, closeLightBox);

    const filteredCryptoAddresses = useMemo(() => {
        return {
            data: filterAddresses(cryptoAddresses.data, selectedCps, selectedStatuses),
            isLoading: cryptoAddresses.isLoading,
        };
    }, [selectedCps, selectedStatuses, cryptoAddresses]);
    const filteredSharedCryptoAddresses = useMemo(() => {
        return {
            data: filterAddresses(sharedCryptoAddresses.data, selectedCps, selectedStatuses),
            isLoading: sharedCryptoAddresses.isLoading,
        };
    }, [selectedCps, selectedStatuses, sharedCryptoAddresses]);

    const { isModalOpen, closeModal, openModal } = useModalControl();
    const [selectedCryptoAddress, setSelectedCryptoAddress] = useState<CryptoAddress>();

    const handleRowClick = (row: Row<CryptoTableData>, cellId: string) => {
        if (row.getCanExpand()) {
            row.toggleExpanded();
            return;
        }

        if (isAddressGroupType(row.original)) {
            return;
        }

        setSelectedCryptoAddress(row.original);
        setDefaultEdit(() => cellId === ACTIONS_TABLE_COLUMN_KEY);

        openModal();
    };

    const handleValidate = (addresses: CryptoAddress[], addressesToDelete: DeleteAccountType[]) => {
        const toDeleteAccountIds = new Set(addressesToDelete.map((a) => a.accountId));
        const allAddressExceptToDelete = cryptoAddresses.data.filter(
            ({ accountId }) => accountId !== undefined && !toDeleteAccountIds.has(accountId),
        );

        return addresses.reduce<FormValidationError[]>((acc, curr) => {
            const hasDuplicateError = hasCryptoAddressDuplicate(allAddressExceptToDelete, addresses, curr);

            if (hasDuplicateError) {
                acc.push({
                    _validationItemId: curr._validationItemId,
                    error: "You can't link multiple crypto addresses to one asset and CP",
                });
            }

            return acc;
        }, []);
    };

    return (
        <>
            <CryptoTable
                totalAddressesCount={cryptoAddresses.data.length}
                side={side}
                sharedAddresses={filteredSharedCryptoAddresses}
                addresses={filteredCryptoAddresses}
                onRowClick={handleRowClick}
            />
            <CryptoAddressModal
                address={selectedCryptoAddress}
                closeModal={closeModal}
                side={side}
                isModalOpen={isModalOpen}
                defaultEdit={defaultEdit}
            />
            <LightBox isOpen={isLightBoxOpen} onClose={closeModalWithConfirm}>
                <CryptoFormContextProvider>
                    <CryptoAddressLightBox cryptoAddresses={cryptoAddresses.data} onValidate={handleValidate} />
                </CryptoFormContextProvider>
            </LightBox>
        </>
    );
};
