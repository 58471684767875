import {
    Checkbox,
    CheckboxField,
    Form,
    FormActions,
    FormBody,
    Header,
    HeaderTitle,
    HStack,
    Icons,
    Modal,
    PrimaryButton,
    PSmall,
    ShadowCard,
    SimpleInput,
    Tab,
    TabContext,
    TabList,
    Tooltip,
} from "@fm-frontend/uikit";
import { SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { yupResolver } from "@hookform/resolvers/yup";
import { post } from "api";
import { CounterpartiesSheet } from "components/CounterpartiesSheet";
import { CounterpartyTriggerEssence } from "components/CounterpartiesTriggerEssence";
import { FieldGrouper } from "feature/counterparties/styled";
import { TextArea } from "feature/form/style";
import { AssetAndNetworkSelector } from "feature/specificSubaccountAndPBPagesContents/AssetAndNetworkSelector";
import {
    getAmountSchema,
    getFeeValidator,
} from "feature/specificSubaccountAndPBPagesContents/yupValidators";
import { useEffect, useState } from "react";
import { Controller, useController, useForm } from "react-hook-form";
import styled from "styled-components";
import { displayError, getCurrencyPlaceholder } from "utils";
import EfxTypes from "utils/EfxTypes";
import { ValidationMessages } from "utils/validationMessages";
import { AnySchema, boolean, number, object, string } from "yup";
import { Error } from "../../styled";
import { Transaction } from "../../types";
import { BaseRequest, clearDefaultCreateTransactionState } from "../useActions";
import { useSubaccountsOptions } from "../useSubaccountsOptions";

type Inputs = {
    subaccountId: number;
    asset: string;
    hasNetworks: boolean;
    network?: string;
    amount: string;
    comment: string;
    isFeePaidByRecipient: boolean;
    fee?: string;
    shouldRemoveRequest?: boolean;
};
export type TransactionFormData = Inputs & { type: Transaction["type"] };
export type CreatedTransactionFormData = TransactionFormData & { id?: number };

interface CreateTransactionModalProps {
    onContinueClick: (transactionSettings: CreatedTransactionFormData) => void;
    closeModal: (shouldShowModal: boolean) => void;
    transactionType: Transaction["type"];
    defaultValues?: Partial<Inputs>;
    baseRequest?: BaseRequest;
}

const FormContent = styled(FormBody)`
    min-height: 411px;
`;

const RemoveRequestContainer = styled.div`
    width: 100%;
    margin-top: 8px;
`;

const ShouldRemoveRequestContainer = styled.div`
    width: 100%;
`;
const ShouldRemoveRequestLabel = styled.label`
    cursor: pointer;
    color: ${(p) => p.theme.colors.ui72};
    ${(p) => p.theme.mixins.pSmall}
    ${PSmall} {
        margin-left: 4px;
    }
`;

export const ShouldRemoveRequest = (props: any) => {
    const { field } = useController({
        ...props,
    });
    const { value, onChange } = field;

    return (
        <ShouldRemoveRequestContainer>
            <ShouldRemoveRequestLabel htmlFor="should-remove-request">
                <Checkbox
                    id="should-remove-request"
                    size="small"
                    checked={value}
                    onChange={onChange}
                />
                <PSmall>Remove initial request</PSmall>
            </ShouldRemoveRequestLabel>
        </ShouldRemoveRequestContainer>
    );
};

export const validationSchema = object<Record<keyof Inputs, AnySchema>>().shape({
    subaccountId: number()
        .transform((value: any, originalValue: any) => (originalValue === "" ? undefined : value))
        .required(ValidationMessages.REQUIRED),
    asset: string().required(ValidationMessages.REQUIRED),
    amount: getAmountSchema(),
    comment: string().optional(),
    isFeePaidByRecipient: boolean(),
    fee: string().when(["isFeePaidByRecipient"], {
        is: (isFeePaidByRecipient: boolean) => isFeePaidByRecipient,
        then: (schema) => getFeeValidator(schema),
        otherwise: (schema) => schema.optional(),
    }),
    hasNetworks: boolean().required(),
    network: string().when("hasNetworks", {
        is: (hasNetworks: boolean) => hasNetworks,
        then: (schema) => schema.required(ValidationMessages.REQUIRED),
        otherwise: (schema) => schema.optional(),
    }),
});

export const CreateTransactionModal = ({
    closeModal,
    onContinueClick,
    transactionType,
    defaultValues,
    baseRequest,
}: CreateTransactionModalProps) => {
    const [isAssetAndNetworkReady, setIsAssetAndNetworkReady] = useState(false);
    const [type, setType] = useState<Transaction["type"]>(transactionType);
    const {
        control,
        formState: { errors, dirtyFields, isSubmitting },
        watch,
        handleSubmit,
        register,
        setValue,
    } = useForm<Inputs>({
        // @ts-ignore
        defaultValues: defaultValues ?? {
            asset: "",
            // @ts-ignore
            amount: "",
            isFeePaidByRecipient: false,
            comment: "",
            hasNetworks: false,
        },
        mode: "onSubmit",
        reValidateMode: "onChange",
        resolver: yupResolver(validationSchema),
    });
    const [apiError, setApiError] = useState<string | null>(null);

    useEffect(() => {
        clearDefaultCreateTransactionState();
    }, []);

    const userSubaccountsOptions = useSubaccountsOptions();

    const asset = watch("asset");

    const isFeePaidByRecipient = watch("isFeePaidByRecipient");
    useEffect(() => {
        if (!isFeePaidByRecipient) {
            setValue("fee", undefined);
        }
    }, [isFeePaidByRecipient]);
    useEffect(() => {
        if (type === "deposit") {
            setValue("isFeePaidByRecipient", false);
        }
    }, [type]);

    const isTransactionBasedOnRequest = baseRequest !== undefined;

    const onSubmit = async (formValues: Inputs) => {
        try {
            setApiError(null);

            const requestBody: Record<string, number | string> = {
                counterpartyId: formValues.subaccountId,
                currency: formValues.asset,
                amount: EfxTypes.parseValue(String(formValues.amount), "size64"),
                fee: formValues.fee && EfxTypes.parseValue(String(formValues.fee), "size64"),
                comment: formValues.comment,
            };
            if (formValues.network) {
                requestBody.network = formValues.network;
            }

            const apiMethod =
                type === "withdrawal"
                    ? "addOutgoingSettlementTransaction"
                    : "addIncomingSettlementTransaction";
            const { settlementTransactionId } = await post(apiMethod, requestBody);

            if (isTransactionBasedOnRequest && formValues.shouldRemoveRequest) {
                await post("delIncomingSettlementCPRequest", baseRequest).catch(displayError);
            }

            closeModal(false);
            onContinueClick({ ...formValues, type, id: settlementTransactionId });
        } catch (err) {
            setApiError(err as string);
        }
    };

    const modalTitle = isTransactionBasedOnRequest ? "New withdrawal" : "Create transaction";
    const createButtonTitle = isTransactionBasedOnRequest
        ? "Create withdrawal"
        : "Create transaction";

    return (
        <Modal isOpen onClose={() => closeModal(Object.keys(dirtyFields).length > 0)}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ShadowCard>
                    <Header>
                        <HeaderTitle title={modalTitle} />
                    </Header>
                    <FormContent spacing={8}>
                        {!isTransactionBasedOnRequest && (
                            <TabContext
                                value={type}
                                handleClick={(value) => setType(value as Transaction["type"])}
                            >
                                <TabList>
                                    <Tab title="Deposit" value="deposit" />
                                    <Tab title="Withdrawal" value="withdrawal" />
                                </TabList>
                            </TabContext>
                        )}

                        <Controller
                            control={control}
                            render={({ field }) => (
                                <SingleDropdown
                                    value={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <SingleDropdown.Trigger
                                            {...trigger}
                                            size="large"
                                            variant="simple"
                                        >
                                            <CounterpartyTriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOption}
                                                size="large"
                                            />
                                        </SingleDropdown.Trigger>
                                    )}
                                    options={userSubaccountsOptions}
                                    placeholder="Select"
                                    caption="Sub-account"
                                    error={errors.subaccountId?.message}
                                    fullWidth
                                >
                                    <CounterpartiesSheet
                                        size="medium"
                                        options={userSubaccountsOptions}
                                        Dropdown={SingleDropdown}
                                    />
                                </SingleDropdown>
                            )}
                            name="subaccountId"
                        />
                        <AssetAndNetworkSelector
                            control={control}
                            setValue={setValue}
                            assetError={errors.asset?.message}
                            networkError={errors.network?.message}
                            onReady={setIsAssetAndNetworkReady}
                        />
                        <SimpleInput
                            label="Amount"
                            {...register("amount")}
                            placeholder={getCurrencyPlaceholder(asset)}
                            error={errors.amount?.message}
                        />
                        {type === "withdrawal" && (
                            <FieldGrouper isExpanded={isFeePaidByRecipient} fullWidth>
                                <CheckboxField
                                    name="isFeePaidByRecipient"
                                    label={
                                        <HStack spacing={4}>
                                            Fee paid by recipient{" "}
                                            <Tooltip content="If the CP is ready to cover the network fee, please, mark this field and indicate the total amount that is a sum of the blockchain transaction amount and the network fee.">
                                                <Icons.Help />
                                            </Tooltip>
                                        </HStack>
                                    }
                                    size="large"
                                    fullWidth
                                    control={control}
                                />
                                {isFeePaidByRecipient && (
                                    <SimpleInput
                                        label="Size"
                                        {...register("fee")}
                                        placeholder={getCurrencyPlaceholder(asset)}
                                        error={errors.fee?.message}
                                    />
                                )}
                            </FieldGrouper>
                        )}
                        <TextArea
                            rows={4}
                            placeholder="Comment"
                            autoFocus
                            {...register("comment")}
                        />
                        {apiError && <Error>{apiError}</Error>}
                    </FormContent>
                    <FormActions variant="plain">
                        <PrimaryButton
                            type="submit"
                            fullWidth
                            loading={isSubmitting}
                            disabled={!isAssetAndNetworkReady}
                            size="large"
                        >
                            {createButtonTitle}
                        </PrimaryButton>
                    </FormActions>
                    {isTransactionBasedOnRequest && (
                        <RemoveRequestContainer>
                            <ShouldRemoveRequest name="shouldRemoveRequest" control={control} />
                        </RemoveRequestContainer>
                    )}
                </ShadowCard>
            </Form>
        </Modal>
    );
};
