import { useSWRConfig } from "swr";

export const useRefreshAddresses = () => {
    const { mutate } = useSWRConfig();

    return async (mutationKeys: string[]) => {
        const mutationPromises = mutationKeys.map((key) => mutate(key));

        await Promise.all(mutationPromises);
    };
};
