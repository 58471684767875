import { useContext } from "react";
import { AccordionContext } from "./AccordionContext";

export const useAccordion = () => {
    const context = useContext(AccordionContext);

    if (!context) {
        throw new Error("useAccordionContext must be used within a AccordionContextProvider");
    }

    return context;
};
