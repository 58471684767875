import { useMemo } from "react";
import { useSharedCryptoAddresses } from "feature/addresses/hooks/useSharedCryptoAddresses";
import { useSharedBanksAddresses } from "feature/addresses/hooks/useSharedBanksAddresses";
import { AccountStatusType } from "feature/addresses/types";

export const useNeedConfirmedAddressesCount = (cpId: number) => {
    const { data: sharedCryptoAddresses, isLoading: isSharedCryptoAddressesLoading } = useSharedCryptoAddresses();
    const { data: sharedBanksAddresses, isLoading: isSharedBanksAddressesLoading } = useSharedBanksAddresses();

    const isLoading = isSharedCryptoAddressesLoading || isSharedBanksAddressesLoading;

    const needConfirmedAddressesCount = useMemo(
        () => {
            const needToConfirmCryptoAddresses = sharedCryptoAddresses.filter(
                ({ status, rule }) => status === AccountStatusType.NeedToConfirm && rule?.cp.id === cpId,
            );
            const needToConfirmBanksAddresses = sharedBanksAddresses.filter(
                ({ status, rule }) => status === AccountStatusType.NeedToConfirm && rule?.cp.id === cpId,
            );

            return needToConfirmCryptoAddresses.length + needToConfirmBanksAddresses.length;
        },
        [cpId, sharedCryptoAddresses, sharedBanksAddresses],
    );

    return {
        needConfirmedAddressesCount,
        isLoading,
    };
}
