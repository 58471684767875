import { useDialog } from "@react-aria/dialog";
import { FocusScope } from "@react-aria/focus";
import { AriaOverlayProps, useModal, useOverlay, usePreventScroll } from "@react-aria/overlays";
import React from "react";
import styled, { css } from "styled-components";
import { onMobile } from "../../const";
import { TransitionState } from "../../hooks/useTransition";
import { Form } from "../../style";
import { transition } from "../../utils";
import { IconModalCloseAction, IconModalInfoAction } from "../icons";

export type ModalSize = "default" | "small";

export type DialogProps = AriaOverlayProps & {
    title?: string;
    children: React.ReactNode;
    isDismissable?: boolean;
    size?: ModalSize;
    transitionState: TransitionState;
    infoLink?: string;
};

export const ActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    & > svg {
        cursor: pointer;

        :nth-child(1) {
            ${(p) => p.theme.mixins.nonInteractive}
        }
    }
`;

const ModalContainer = styled.div<{
    transitionState: TransitionState;
    size: ModalSize;
}>`
    position: relative;

    & > :nth-child(1) {
        position: absolute;
        top: 0;
        right: -40px;
        ${(p) => p.theme.mixins.nonInteractive}
    }

    & > ${ActionContainer} {
        position: absolute;
        top: 38px;
        right: -40px;
    }

    ${onMobile} {
        & > svg {
            top: -38px;
            right: 6px;
        }
    }

    ${({ size }) => {
        switch (size) {
            case "default":
                return css`
                    ${Form} {
                        min-width: 40vw;
                    }
                `;
            case "small":
                return css`
                    ${Form} {
                        width: 360px;
                    }
                `;
        }
    }}
    opacity: 0;
    ${(p) => transition(p.theme.transitions.default, "transform", "opacity")}
    ${({ transitionState }) => {
        switch (transitionState) {
            case "entering":
            case "exiting": {
                return css`
                    transform: scale(0.9);
                `;
            }
            case "active": {
                return css`
                    opacity: 1;
                    transform: translateY(0);
                `;
            }
        }
    }}
`;
const Overlay = styled.div<{ transitionState: TransitionState }>`
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0);

    ${(p) => transition(p.theme.transitions.default, "background")}
    ${({ transitionState }) => {
        switch (transitionState) {
            case "entering":
            case "active": {
                return css`
                    background: rgba(0, 0, 0, 0.5);
                `;
            }
        }
    }}
  ${onMobile} {
        align-items: baseline;

        & > *[role="dialog"] {
            margin-top: 48px;
        }
    }
`;

export function ModalDialog(props: DialogProps) {
    const { children, transitionState, infoLink } = props;

    // Handle interacting outside the dialog and pressing
    // the Escape key to close the modal.
    const ref = React.useRef(null);
    const { overlayProps } = useOverlay(props, ref);

    const size = props.size || "default";

    // Prevent scrolling while the modal is open, and hide content
    // outside the modal from screen readers.
    usePreventScroll();
    const { modalProps } = useModal();

    // Get props for the dialog and its title
    const { dialogProps } = useDialog({ role: "dialog" }, ref);

    return (
        <Overlay transitionState={transitionState}>
            <FocusScope contain restoreFocus autoFocus>
                <ModalContainer
                    {...overlayProps}
                    {...dialogProps}
                    {...modalProps}
                    ref={ref}
                    size={size}
                    transitionState={transitionState}
                >
                    <IconModalCloseAction />
                    <ActionContainer>
                        {infoLink && (
                            <a href={infoLink} target="_blank" rel="noreferrer">
                                <IconModalInfoAction />
                            </a>
                        )}
                    </ActionContainer>
                    {children}
                </ModalContainer>
            </FocusScope>
        </Overlay>
    );
}
