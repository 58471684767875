import { H2, ShadowCard, TextSmall } from "@fm-frontend/uikit";
import styled, { css } from "styled-components";
import { CounterpartyStatusType } from "../utils";

export const ModalContainer = styled(ShadowCard)`
    position: relative;
    width: 360px;

    overflow: unset;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    gap: 8px;
    margin-top: 8px;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    ${H2} {
        margin: 0;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 8px;
`;

export const SubHeader = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;

    ${TextSmall} {
        margin: 0;
    }
`;

export const DeleteButtonWrapper = styled.div`
    display: flex;
    width: 40px;
`;

export const Indicator = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
`;

export const IndicatorContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 12px 12px;
`;

export const Badge = styled.span<{ status?: CounterpartyStatusType }>`
    cursor: default;
    padding: 4px 8px;
    gap: 10px;
    font-weight: 600;
    border-radius: 40px;

    ${(p) => {
        switch (p.status) {
            case CounterpartyStatusType.NORMAL:
                return css`
                    color: ${p.theme.colors.positive100};
                    background-color: ${p.theme.colors.positive8};
                `;
            case CounterpartyStatusType.AWAITING_CP:
                return css`
                    color: ${p.theme.colors.process100};
                    background-color: ${p.theme.colors.process8};
                `;
            case CounterpartyStatusType.MARGIN_CALL:
                return css`
                    color: ${p.theme.colors.attention100};
                    background-color: ${p.theme.colors.attention8};
                `;
            case CounterpartyStatusType.STOPPED:
            case CounterpartyStatusType.STOPPED_BY_CP:
                return css`
                    color: ${p.theme.colors.ui72};
                    background-color: ${p.theme.colors.ui8};
                `;
            case CounterpartyStatusType.LOW_GROSS_FREE:
            case CounterpartyStatusType.RESTRICTED_TRADING:
            case CounterpartyStatusType.LIQUIDATION:
                return css`
                    color: ${p.theme.colors.negative80};
                    background-color: ${p.theme.colors.negative4};
                `;
            default:
                return null;
        }
    }}
`;
