import React from "react";
import styled, { css } from "styled-components";
import { SwitchSize } from "../types";

const ANIMATION_DURATION = "0.1s";

type SizeConfig = {
    switchHeight: number;
    switchWidth: number;
    circleSize: number;
    circleMargin: number;
};

const SWITCH_CORE_SIZE_MAP: Record<SwitchSize, SizeConfig> = {
    small: {
        switchHeight: 12,
        switchWidth: 22,
        circleSize: 10,
        circleMargin: 1,
    },
    medium: {
        switchHeight: 12,
        switchWidth: 22,
        circleSize: 10,
        circleMargin: 1,
    },
    large: {
        switchHeight: 20,
        switchWidth: 36,
        circleSize: 18,
        circleMargin: 1,
    },
};

export const SwitchCircle = styled.span<{ $sizeConfig: SizeConfig }>`
    display: block;
    width: ${(p) => p.$sizeConfig.circleSize}px;
    margin: ${(p) => p.$sizeConfig.circleMargin}px;
    background: ${(p) => p.theme.colors.uiWhite100};
    position: absolute;
    top: 0;
    bottom: 0;
    right: ${(p) => p.$sizeConfig.switchWidth - p.$sizeConfig.circleSize - 2 * p.$sizeConfig.circleMargin}px;
    border-radius: ${(p) => p.$sizeConfig.circleSize / 2}px;
    transition: all ${ANIMATION_DURATION} ease-in 0s;
`;

export const SwitchBackground = styled.span<{ $sizeConfig: SizeConfig }>`
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin ${ANIMATION_DURATION} ease-in 0s;

    &:before,
    &:after {
        content: "";
        display: block;
        float: left;
        width: 50%;
        height: ${(p) => p.$sizeConfig.circleSize + 2 * p.$sizeConfig.circleMargin}px;
        padding: 0;
        box-sizing: border-box;
    }
    &:before {
        background-color: ${(p) => p.theme.colors.brand100};
    }
    &:after {
        background-color: ${(p) => p.theme.colors.ui8};
    }
`;

export const Container = styled.div<{ $disabled: boolean; $sizeConfig: SizeConfig; $checked: boolean }>`
    position: relative;
    width: ${(p) => p.$sizeConfig.switchWidth}px;
    min-width: ${(p) => p.$sizeConfig.switchWidth}px;
    max-height: ${(p) => p.$sizeConfig.switchHeight}px;
    display: inline-block;

    overflow: hidden;
    cursor: pointer;
    border-radius: ${(p) => (p.$sizeConfig.circleSize + 2 * p.$sizeConfig.circleMargin) / 2}px;
    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px ${(p) => p.theme.colors.brand12}, inset 0 0 0 1px ${(p) => p.theme.colors.brand100};
    }
    & > span:focus {
        outline: none;
    }

    ${(p) =>
        p.$disabled &&
        css`
            cursor: not-allowed;

            & > ${SwitchBackground}:before {
                background-color: ${(p) => p.theme.colors.brand32};
            }
        `}

    ${(p) =>
        p.$checked &&
        css`
            ${SwitchBackground} {
                margin-left: 0;
            }
            ${SwitchCircle} {
                right: 0;
            }
        `}
`;

type SwitchCoreProps = React.HTMLAttributes<HTMLDivElement> & {
    disabled?: boolean;
    checked: boolean;
    size: SwitchSize;
};
export const SwitchCore = React.forwardRef<HTMLDivElement, SwitchCoreProps>(
    ({ disabled = false, checked, size, ...rest }, ref) => {
        const sizeParams = SWITCH_CORE_SIZE_MAP[size];

        return (
            <Container ref={ref} {...rest} $sizeConfig={sizeParams} $disabled={disabled} $checked={checked}>
                <SwitchBackground $sizeConfig={sizeParams} />
                <SwitchCircle $sizeConfig={sizeParams} />
            </Container>
        );
    },
);
