import { CounterpartyStatusType } from "feature/counterparties/utils";
import styled, { css } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    height: 8px;
    width: 100%;
    margin: 10px 0;
`;

export const Line = styled.div<{ value: number; status?: CounterpartyStatusType }>`
    position: absolute;
    width: ${({ value }) => (value > 100 ? "100" : String(value))}%;
    height: 8px;
    border-right: 1px solid white;

    background-color: transparent;
    ${(p) => {
        switch (p.status) {
            case CounterpartyStatusType.NORMAL:
                return css`
                    background-color: ${p.theme.colors.positive100};
                `;
            case CounterpartyStatusType.MARGIN_CALL:
                return css`
                    background-color: ${p.theme.colors.attention100};
                `;
            case CounterpartyStatusType.RESTRICTED_TRADING:
                return css`
                    background-color: ${p.theme.colors.negative100};
                `;
            default:
                return css`
                    background-image: repeating-linear-gradient(
                        -45deg,
                        ${p.theme.colors.uiWhite100} 0 1.5px,
                        ${p.theme.colors.negative100} 1.5px 3px
                    );
                `;
        }
    }}
`;
