import { H3Bold, Mark, P } from "@fm-frontend/uikit";
import React from "react";
import styled, { useTheme } from "styled-components";
import { onMobile } from "../../../const";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px;

    background: ${(p) => p.theme.colors.ui4};
    border-radius: 16px;
`;

const StepNumber = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-bottom: 6px;

    background-color: ${(p) => p.theme.colors.blue};
    border-radius: 24px;
`;

const ActionSection = styled.section`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px 0 0 0;
    gap: 6px;
    flex-wrap: wrap;

    ${onMobile} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const InfoSection = styled.section`
    display: flex;
    width: 100%;
    gap: 6px;
    flex-wrap: wrap;
`;

export const StepItem: React.FC<{ stepNumber: number; title: string; description: string }> = (props) => {
    const { stepNumber, title, description, children } = props;
    const { colors } = useTheme();
    return (
        <Container>
            <StepNumber>
                <H3Bold color={colors.uiWhite100}>{stepNumber}</H3Bold>
            </StepNumber>
            <InfoSection>
                <P color={colors.ui52}>
                    <Mark weight={500} color={colors.ui100}>
                        {title}
                    </Mark>{" "}
                    {description}
                </P>
            </InfoSection>
            {children && <ActionSection>{children}</ActionSection>}
        </Container>
    );
};
