import { ClientId, HStack } from "@fm-frontend/uikit";
import { ValueFormat } from "@fm-frontend/utils";
import { FC } from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
    display: flex;
    position: relative;
    height: 28px;
    line-height: 28px;
    cursor: pointer;

    :hover {
        background-color: ${(p) => p.theme.colors.ui4};
    }
`;

const LineSeparator = styled.div<{ first?: boolean }>`
    :before {
        position: absolute;
        content: "";

        top: ${(p) => (p.first ? "1px" : 0)};
        bottom: 0;
        right: 0;
        width: 1px;
        background-color: white;
        z-index: 1;
    }
`;

const Price = styled.div<{ $align: string; $bold: boolean; $positive: boolean }>`
    color: ${(p) => (p.$positive ? p.theme.colors.positive100 : p.theme.colors.negative100)};
    font-weight: ${(p) => (p.$bold ? 600 : 400)};
    text-align: ${(p) => p.$align};
    padding: 0 22px;
    width: 100%;

    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: "ss01" on, "cv01" on, "liga" off;
    font-size: 12px;
    line-height: 28px;
`;

const Size = styled.div`
    font-feature-settings: "tnum";
    text-align: right;
    padding: 0 12px;
`;

const Info = styled(HStack)`
    width: 100%;
    justify-content: center;
    padding: 0 12px;
`;

const Chart = styled.div<{ $positive: boolean; $right: boolean }>`
    position: absolute;
    background: ${(p) => (p.$positive ? p.theme.colors.positive8 : p.theme.colors.negative8)};
    transition: transform 75ms ease-out;
    height: 100%;
    top: 0;
    ${(p) =>
        p.$right
            ? css`
                  right: 0;
              `
            : css`
                  left: 0;
              `};
`;

type MarketDepthItemProps = {
    price: number | bigint;
    size: number | bigint;
    fmId?: number;
    percent: number;
    type: "bid" | "ask";
    first: boolean;
    onClick: () => void;
};

export const MarketDepthItem: FC<MarketDepthItemProps> = ({
    price,
    size,
    fmId,
    percent,
    type,
    first,
    onClick,
}) => {
    if (type === "bid") {
        return (
            <Container onClick={onClick}>
                <Size>{ValueFormat.size(size)}</Size>
                {fmId !== undefined && (
                    <Info>
                        <ClientId id={fmId} />
                    </Info>
                )}
                <Price $align="right" $bold={first} $positive>
                    {ValueFormat.price(price)}
                </Price>
                <Chart $positive $right style={{ width: `${percent}%` }} />
                <LineSeparator first={first} />
            </Container>
        );
    }

    if (type === "ask") {
        return (
            <Container onClick={onClick}>
                <Chart $positive={false} $right={false} style={{ width: `${percent}%` }} />
                <Price $align="left" $bold={first} $positive={false}>
                    {ValueFormat.price(price)}
                </Price>
                {fmId !== undefined && (
                    <Info>
                        <ClientId id={fmId} />
                    </Info>
                )}
                <Size>{ValueFormat.size(size)}</Size>
            </Container>
        );
    }

    return null;
};
