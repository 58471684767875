import { Icons, Tooltip } from "@fm-frontend/uikit";
import { LimitInfo } from "feature/assetsControl/types";
import styled, { useTheme } from "styled-components";
import { getLimitStatus } from "../../../utils";

const Container = styled.div`
    position: relative;
    top: 2px;
`;

export const CpExpandCell = ({
    userLimit,
    cpLimit,
}: {
    userLimit: LimitInfo | undefined;
    cpLimit: LimitInfo | undefined;
}) => {
    const theme = useTheme();

    const userLimitStatus =
        userLimit !== undefined ? getLimitStatus(userLimit.freeLimitPercent) : "normal";
    const cpLimitStatus =
        cpLimit !== undefined ? getLimitStatus(cpLimit.freeLimitPercent) : "normal";

    if (userLimitStatus === "normal" && cpLimitStatus === "normal") {
        return null;
    }

    const color =
        userLimitStatus === "low" || cpLimitStatus === "low"
            ? theme.colors.negative100
            : theme.colors.attention100;

    const hintText =
        userLimitStatus === "low" || cpLimitStatus === "low" ? (
            <>
                <strong>Critical asset gross free</strong>. Update the asset limit or initiate a
                settlement
            </>
        ) : (
            <>
                <strong>Low asset gross free</strong>. Update the asset limit or initiate a
                settlement
            </>
        );

    return (
        <Container>
            <Tooltip content={hintText} positions={["right", "top"]}>
                <Icons.BannerAttention size={16} color={color} />
            </Tooltip>
        </Container>
    );
};
