import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { LineProgress } from "../animations/LineProgress";
import { PlainButton } from "../buttons";
import { Icons } from "../icons";

const hiddenCss = css`
    visibility: hidden;
`;

const BackButtonWrapper = styled.div<{ isActive: boolean }>`
    display: flex;
    align-items: center;
    ${({ isActive }) => !isActive && hiddenCss};
`;

const Container = styled.div`
    display: flex;
    position: relative;
    height: 16px;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 8px;

    ${BackButtonWrapper} {
        button {
            position: absolute;
            background-color: transparent;
            left: 0;
            padding-left: 2px;

            :hover {
                background-color: ${(p) => p.theme.colors.ui8};
            }
        }
    }
`;

const DEFAULT_PROGRESS_VALUE = 50;

export const FormProgress: React.FC<{
    onBackClick?: () => void;
    currentStep?: number;
    totalSteps?: number;
}> = (props) => {
    const { onBackClick, currentStep, totalSteps } = props;

    const isBackActionAvailable = Boolean(onBackClick);

    const value = useMemo(() => {
        if (!currentStep || !totalSteps) {
            return null;
        }

        const currentProgress = Math.round((currentStep / totalSteps) * 100);
        return currentProgress > DEFAULT_PROGRESS_VALUE ? currentProgress : DEFAULT_PROGRESS_VALUE;
    }, [currentStep, totalSteps]);

    return (
        <Container>
            <BackButtonWrapper isActive={isBackActionAvailable}>
                <PlainButton size="small" type="button" onClick={onBackClick}>
                    <Icons.FormBackAction />
                    Back
                </PlainButton>
            </BackButtonWrapper>
            {value && <LineProgress value={value} />}
        </Container>
    );
};
