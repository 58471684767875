import { useFormCloseConfirmer } from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { useAddressContext } from "../AddressesContext";
import { ACCOUNT_REVISIONS_URL, BANK_URL, PAYMENT_ACCOUNTS_RULES_URL, PAYMENT_ACCOUNTS_URL } from "../api";
import { AddressLightBox } from "../components";
import { useAddOrUpdateAddresses, useDeleteAddresses, useRefreshAddresses, useSaveSuccessNotification } from "../hooks";
import { banksAddressSchema } from "../schema";
import { BankAddress, BanksAddressFormInput, DeleteAccountType, FormApiErrors } from "../types";
import { ACCOUNTS_ARE_SAVED_NOTIFICATION, SAB_BANK_LIGHTBOX_KEY } from "../utils";
import { BanksLightBoxContent } from "./BanksLightBoxContent";

export const BanksAddressLightBox: React.FC<{ banksAddresses: BankAddress[] }> = ({ banksAddresses }) => {
    const { closeLightBox } = useAddressContext();
    const addOrUpdateAddresses = useAddOrUpdateAddresses();
    const deleteAddresses = useDeleteAddresses();
    const refreshAddresses = useRefreshAddresses();
    const [apiErrors, setApiErrors] = useState<FormApiErrors>({});
    const apiErrorsCount = Object.keys(apiErrors).length;
    const [deleteAddressesList, setDeleteAddressesList] = useState<DeleteAccountType[]>([]);
    const addressesSaveNotification = useSaveSuccessNotification();

    const methods = useForm<BanksAddressFormInput>({
        defaultValues: {
            addresses: banksAddresses,
        },
        resolver: yupResolver(banksAddressSchema),
        mode: "onSubmit",
    });

    const {
        handleSubmit,
        formState: {
            dirtyFields: { addresses: dirtyAddresses },
            touchedFields: { addresses: touchedAddresses },
            isDirty,
            isSubmitting,
        },
        getValues,
    } = methods;

    useFormCloseConfirmer(SAB_BANK_LIGHTBOX_KEY, isDirty);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(isDirty, closeLightBox);

    const totalAddressesCount = getValues().addresses.length;

    const handleLightBoxSubmit = handleSubmit(async ({ addresses }: BanksAddressFormInput) => {
        const addressesToUpdate = addresses.filter(({ _validationItemId }, index) => {
            const isDirtyAddress = dirtyAddresses?.[index] !== undefined;
            const isTouchedAddress = touchedAddresses?.[index] !== undefined;
            const hasApiError = _validationItemId && apiErrors?.[_validationItemId] !== undefined;

            return (isDirtyAddress && isTouchedAddress) || hasApiError;
        });

        const deleteErrors = await deleteAddresses(deleteAddressesList);
        const updateErrors = await addOrUpdateAddresses(addressesToUpdate);
        await refreshAddresses([PAYMENT_ACCOUNTS_URL, ACCOUNT_REVISIONS_URL, PAYMENT_ACCOUNTS_RULES_URL, BANK_URL]);

        setDeleteAddressesList([]);

        const composedApiErrors = {
            ...deleteErrors,
            ...updateErrors,
        };

        setApiErrors(composedApiErrors);

        if (!Object.values(composedApiErrors).length) {
            closeLightBox();
            addressesSaveNotification(ACCOUNTS_ARE_SAVED_NOTIFICATION);
        }
    });

    const handleAddressDelete = (id: string, deleteAddress: BankAddress) => {
        setDeleteAddressesList((list) => [
            ...list,
            {
                id,
                accountId: deleteAddress.accountId,
                accountRuleId: deleteAddress.rule?.id,
            } as DeleteAccountType,
        ]);
    };

    return (
        <FormProvider {...methods}>
            <AddressLightBox
                totalAddressesCount={totalAddressesCount}
                isSubmitting={isSubmitting}
                apiErrorsCount={apiErrorsCount}
                onSubmit={handleLightBoxSubmit}
                isDisabled={!isDirty}
                onCancel={closeModalWithConfirm}
            >
                <BanksLightBoxContent onAddressDelete={handleAddressDelete} apiErrors={apiErrors} />
            </AddressLightBox>
        </FormProvider>
    );
};
