import { AssetInfo } from "components/AssetInfo";
import { Settlement } from "../../../../../types";
import { Statistic } from "./Statistic";

export const AssetItem = ({ settlement }: { settlement: Settlement }) => {
    const { asset, network, status } = settlement;
    const title = status === "request" ? "Request" : "Asset";

    return <Statistic title={title} value={<AssetInfo currency={asset} asset={asset} network={network} />} />;
};
