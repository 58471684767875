import { HStack, Icons, PSmall, Tooltip } from "@fm-frontend/uikit";
import { createColumnHelper } from "@tanstack/react-table";
import { CLIENT_TYPE_TITLES } from "const";
import styled from "styled-components";
import { CpNameCell, DefaultMarkupCell, InstrumentNameCell, MarkupCell } from "./cells";
import { RowData } from "./types";
import { isCpRow, isInstrumentRow } from "./utils";

export const columnHelper = createColumnHelper<RowData>();

const InfoHeader = ({ header, tooltipText }: { header: string; tooltipText: string }) => (
    <HStack alignItems="center" spacing={4}>
        <div>{header}</div>
        <Tooltip content={tooltipText}>
            <Icons.Help />
        </Tooltip>
    </HStack>
);

const CpType = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui52};
`;

export const columns = [
    columnHelper.accessor((row) => row, {
        id: "name",
        cell: (subject) => {
            const value = subject.getValue();

            if (isInstrumentRow(value)) {
                return <InstrumentNameCell instrument={value.instrument} />;
            }

            const { id, name } = value;

            return <CpNameCell cpId={id} cpName={name} />;
        },
        header: "Name",
        enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
        id: "clientType",
        cell: (subject) => {
            const value = subject.getValue();

            if (isCpRow(value)) {
                if (value.cpType) {
                    return <CpType>{CLIENT_TYPE_TITLES[value.cpType]}</CpType>;
                }
            }

            return null;
        },
        header: "",
        enableSorting: false,
        meta: {
            cellStyleProps: {
                width: "140px",
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "defaultMarkup",
        cell: (subject) => {
            const value = subject.getValue();

            if (isCpRow(value)) {
                return (
                    <DefaultMarkupCell
                        markup={value.defaultMarkup}
                        lineThrough={value.bidMarkup !== undefined || value.askMarkup !== undefined}
                    />
                );
            }

            return null;
        },
        header: () => (
            <InfoHeader
                header="Default markup"
                tooltipText="Value is applied to Bid and to Ask across all instruments without markup customizations. Change markup for counterparty / sub-account in risk management."
            />
        ),
        enableSorting: false,
        meta: {
            cellStyleProps: {
                width: "165px",
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
                padding: "0 12px 8px 12px",
            },
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "bidMarkup",
        cell: (subject) => {
            const value = subject.getValue();

            if (isCpRow(value)) {
                return <MarkupCell markup={value.bidMarkup} />;
            }

            return null;
        },
        header: () => (
            <InfoHeader
                header="Bid markup"
                tooltipText="Applies to Bid prices instead of default markup."
            />
        ),
        enableSorting: false,
        meta: {
            cellStyleProps: {
                width: "165px",
                textAlign: "right",
                padding: "0 12px 0 0",
            },
            headerStyleProps: {
                textAlign: "right",
                padding: "0 12px 8px 12px",
            },
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "askMarkup",
        cell: (subject) => {
            const value = subject.getValue();

            if (isCpRow(value)) {
                return <MarkupCell markup={value.askMarkup} />;
            }

            return null;
        },
        header: () => (
            <InfoHeader
                header="Ask markup"
                tooltipText="Applies to Ask prices instead of default markup."
            />
        ),
        enableSorting: false,
        meta: {
            cellStyleProps: {
                width: "165px",
                textAlign: "right",
                padding: "0 12px 0 0",
            },
            headerStyleProps: {
                textAlign: "right",
                padding: "0 12px 8px 12px",
            },
        },
    }),
];
