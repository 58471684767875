import { DEFAULT_CP_NAME } from "const";
import { fetchCounterpartiesInfo } from "feature/app/store/counterpartiesInfoFetcher";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { ClientType } from "types";
import { useSelector } from "./useSelector";

const DELAY_BETWEEN_ATTEMPTS_MS = 60_000;
const fetchingAttemptsLog = {} as Record<number, number>;
const fetchingAttemptsScheduler = {} as Record<number, NodeJS.Timeout | undefined>;
const useFetchCounterpartyInfo = () => {
    const dispatch = useDispatch();

    return (cpId: number) => {
        const canSetTimeout = fetchingAttemptsScheduler[cpId] === undefined;

        if (canSetTimeout) {
            const lastAttemptAt = fetchingAttemptsLog[cpId] ?? 0;

            let nextAttemptInMs = 0;
            if (Date.now() - lastAttemptAt < DELAY_BETWEEN_ATTEMPTS_MS) {
                nextAttemptInMs = lastAttemptAt + DELAY_BETWEEN_ATTEMPTS_MS - Date.now();
                const delta = Math.random() * 60_000;
                nextAttemptInMs += delta;
            }

            fetchingAttemptsScheduler[cpId] = setTimeout(() => {
                fetchingAttemptsLog[cpId] = Date.now();
                fetchingAttemptsScheduler[cpId] = undefined;

                dispatch(fetchCounterpartiesInfo(cpId));
            }, nextAttemptInMs);
        }
    };
};

export const useCpInfoHelpers = (isDefaultCpNameApplied: boolean = true) => {
    const fetchCounterpartyInfo = useFetchCounterpartyInfo();
    const allAvailableCounterpartiesInfo = useSelector((state) => state.app.allAvailableCounterpartiesInfo);

    const getCpName = useCallback(
        (cpId: number | string, type: "short" | "full"): string => {
            const { username } = allAvailableCounterpartiesInfo[Number(cpId)] ?? {};
            let result = "";
            if (type === "short") {
                result = typeof username === "string" ? `${username}` : String(cpId);
            } else if (type === "full") {
                const text = typeof username === "string" ? username : isDefaultCpNameApplied ? DEFAULT_CP_NAME : "";
                result = `${text}`;
            }
            if (username === undefined) {
                fetchCounterpartyInfo(Number(cpId));
            }
            return result;
        },
        [allAvailableCounterpartiesInfo],
    );

    const getCpType = useCallback(
        (cpId: number): ClientType | undefined => {
            const cpType = allAvailableCounterpartiesInfo[cpId]?.clientType;

            if (cpType === undefined) {
                fetchCounterpartyInfo(cpId);
            }

            return cpType;
        },
        [allAvailableCounterpartiesInfo],
    );

    return useMemo(
        () => ({
            getCpName,
            getCpType,
        }),
        [getCpName, getCpType],
    );
};
