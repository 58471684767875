/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
const priceFormat = /^[0-9]{1,8}(\.[0-9]{1,8})?$/;
const sizeFormat = /^-?[0-9]{1,8}(\.[0-9]{1,8})?$/;
const size64Format = /^-?[0-9]{1,12}(\.[0-9]{1,8})?$/;
const limitFormat = /^[0-9]{1,9}(\.[0-9]{1,8})?$/;
const u32Format = /^[0-9]{1,9}$/;
const u64Format = /^[0-9]{1,20}$/;
const deltaRateFormat = /^-?0(\.[0-9]{1,6})?$/;
const deltaRatePercentFormat = /^-?[0-9]{1,2}(\.[0-9]{1,4})?$|^100(\.0{1,4})?$/;
const emailFormat =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passwordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;
const usernameFormat = /^[’0-9a-zA-Z()._& -]{2,15}$/;

export default {
    parseValue(value, efxType) {
        switch (efxType) {
            case "string": {
                if (null === value || "" === value) {
                    return null;
                }
                return value;
            }
            case "stringRequired": {
                if (null === value || "" === value) {
                    throw "Required";
                }
                return value;
            }
            case "price": {
                if (null === value || "" === value) {
                    return null;
                }
                if (value.match(priceFormat)) {
                    const v = parseFloat(value) * 1e8;
                    return Math.floor(v + 0.5);
                }
                throw "Invalid price";
            }
            case "size": {
                if (null === value || "" === value) {
                    return null;
                }
                if (value.match(sizeFormat)) {
                    const v = parseFloat(value) * 1e8;
                    return Math.floor(v + 0.5);
                }
                throw "Invalid size";
            }
            case "size64": {
                if (null === value || "" === value) {
                    return null;
                }
                if (value.match(size64Format)) {
                    const parts = value.split(".");
                    let v = BigInt(parseInt(parts[0])) * BigInt(1e8);
                    if (parts.length > 1) {
                        v += BigInt(parseInt(parts[1]) * 10 ** (8 - parts[1].length));
                    }
                    return v;
                }
                throw "Invalid size";
            }
            case "limit": {
                if (null === value || "" === value) {
                    return null;
                }
                if (value.match(limitFormat)) {
                    const parts = value.split(".");
                    let v = BigInt(parseInt(parts[0])) * BigInt(1e8);
                    if (parts.length > 1) {
                        v += BigInt(parseInt(parts[1]) * 10 ** (8 - parts[1].length));
                    }
                    return v;
                }
                throw "Invalid size";
            }
            case "u32": {
                if (null === value || "" === value) {
                    return null;
                }
                if (value.match(u32Format)) {
                    return parseInt(value);
                }
                throw "Invalid u32 value";
            }
            case "flags": {
                if (null === value || "" === value) {
                    return null;
                }
                if (value === true || value === false) {
                    return value ? 1 : 0;
                }
                throw "Invalid flags value";
            }
            case "u64": {
                if (null === value || "" === value) {
                    return null;
                }
                if (value.match(u64Format)) {
                    return BigInt(value);
                }
                throw "Invalid u64 value";
            }
            case "boolean": {
                if (null === value || "" === value) {
                    return null;
                }
                if (value) {
                    return true;
                } else {
                    return false;
                }
            }
            case "deltarate": {
                if (null === value || "" === value) {
                    return null;
                }
                if (value.match(deltaRateFormat)) {
                    const v = parseFloat(value) * 1e6;
                    return Math.floor(v + 0.5);
                }
                throw "Invalid delta ratio (" + value + ")";
            }
            case "deltaratePercent": {
                if (null === value || "" === value) {
                    return null;
                }
                if (value.match(deltaRatePercentFormat)) {
                    const v = parseFloat(value) * 1e4;
                    return Math.floor(v + 0.5);
                }
                throw "Invalid delta ratio percent (" + value + ")";
            }
            case "email": {
                if (null === value || "" === value) {
                    return null;
                }
                if (value.match(emailFormat)) {
                    return value;
                }
                throw "Invalid email";
            }
            case "2faCode": {
                if (value && typeof value === "string" && value.length === 6) return Number(value);
                throw "Invalid code";
            }
            case "password": {
                if (null === value || "" === value) {
                    return null;
                }
                if (value.match(passwordFormat)) return value;
                throw "Password does not meet security requirements";
            }
            case "username": {
                if (null === value || "" === value) {
                    return null;
                }
                if (!value.match(usernameFormat)) {
                    throw "Invalid user name";
                }
                if (value.length < 16) return value;
                throw "Username is too long";
            }
            default: {
                throw "Invalid control type";
            }
        }
    },

    formatValue(value, efxType, forTable) {
        if (null === value || "" === value) {
            return "";
        }

        switch (efxType) {
            case "string":
            case "stringRequired": {
                return value;
            }
            case "price": {
                return (value * 1e-8).toFixed(8);
            }
            case "size": {
                return (value * 1e-8).toFixed(8);
            }
            case "limit":
            case "size64": {
                const rem = BigInt(value) % BigInt(1e8);
                const whole = BigInt(value) / BigInt(1e8);
                const zeros = "0".repeat(8 - rem.toString().length);
                return `${whole}.${zeros}${rem}`;
            }
            case "u32": {
                return parseInt(value);
            }
            case "u64": {
                return BigInt(value);
            }
            case "boolean": {
                if (forTable) {
                    if (value) {
                        return "Y";
                    } else {
                        return "N";
                    }
                } else {
                    if (value) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
            case "deltarate": {
                return (value * 1e-6).toFixed(6);
            }
            case "deltaratePercent": {
                return (value * 1e-4).toFixed(4);
            }
            case "email": {
                return value;
            }
            case "timems": {
                const dt = new Date(parseInt(value));
                const h = dt.getHours();
                const m = dt.getMinutes();
                const s = dt.getSeconds();
                const y = dt.getFullYear();
                const n = dt.getMonth() + 1;
                const d = dt.getDate();
                return (
                    "" +
                    (h >= 10 ? h : "0" + h) +
                    ":" +
                    (m >= 10 ? m : "0" + m) +
                    ":" +
                    (s >= 10 ? s : "0" + s) +
                    " " +
                    y +
                    "-" +
                    (n >= 10 ? n : "0" + n) +
                    "-" +
                    (d >= 10 ? d : "0" + d)
                );
            }
            default: {
                throw "Invalid efx type";
            }
        }
    },
};

export function formatValue(value, efxType, forTable) {
    if (value === undefined || value === null || value === "") {
        return "";
    }

    switch (efxType) {
        case "string":
        case "stringRequired": {
            return value;
        }
        case "price": {
            return (value * 1e-8).toFixed(8);
        }
        case "size": {
            return (value * 1e-8).toFixed(8);
        }
        case "limit":
        case "size64": {
            const rem = BigInt(value) % BigInt(1e8);
            const whole = BigInt(value) / BigInt(1e8);
            const zeros = "0".repeat(8 - rem.toString().length);
            return `${whole}.${zeros}${rem}`;
        }
        case "u32": {
            return parseInt(value);
        }
        case "u64": {
            return BigInt(value);
        }
        case "boolean": {
            if (forTable) {
                if (value) {
                    return "Y";
                } else {
                    return "N";
                }
            } else {
                if (value) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        case "deltarate": {
            return (value * 1e-6).toFixed(6);
        }
        case "email": {
            return value;
        }
        case "timems": {
            const dt = new Date(parseInt(value));
            const h = dt.getHours();
            const m = dt.getMinutes();
            const s = dt.getSeconds();
            const y = dt.getFullYear();
            const n = dt.getMonth() + 1;
            const d = dt.getDate();
            return (
                "" +
                (h >= 10 ? h : "0" + h) +
                ":" +
                (m >= 10 ? m : "0" + m) +
                ":" +
                (s >= 10 ? s : "0" + s) +
                " " +
                y +
                "-" +
                (n >= 10 ? n : "0" + n) +
                "-" +
                (d >= 10 ? d : "0" + d)
            );
        }
        default: {
            throw "Invalid efx type";
        }
    }
}
