import { forwardRef } from "react";
import { Input } from "./Input/Input";
import { SpecificInputProps } from "./types";

export { TradeAmountInput } from "./Input/Input";
export * from "./Textarea/Textarea";

export const BasicInput = forwardRef<HTMLInputElement, SpecificInputProps>((props, ref) => {
    return <Input ref={ref} variant="basic" {...props} />;
});

export const MinimumInput = forwardRef<HTMLInputElement, SpecificInputProps>((props, ref) => {
    return <Input ref={ref} variant="minimum" {...props} />;
});

export const SimpleInput = forwardRef<HTMLInputElement, SpecificInputProps>((props, ref) => {
    return <Input ref={ref} variant="simple" {...props} />;
});
