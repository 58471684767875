import { Badge, H2, HStack, Icons, Mark, PrimaryButton, TextSmall, Tooltip } from "@fm-frontend/uikit";
import { useIsOwnerUser } from "hooks";
import { useTextAdaptor } from "hooks/useTextAdaptor";
import React from "react";
import styled, { useTheme } from "styled-components";
import { useAddressContext } from "../AddressesContext";
import { EDITING_UNAVAILABLE_MESSAGE } from "../utils";

const HeaderTab = styled(H2)<{ active: boolean }>`
    color: ${(p) => (p.active ? p.theme.colors.ui100 : p.theme.colors.ui32)};
    cursor: pointer;
    :hover {
        color: ${(p) => p.theme.colors.ui100};
    }
`;

type ConfirmationStatusBadgeProps = {
    bankNeedToConfirm: number;
    cryptoNeedToConfirm: number;
};

export const ConfirmationStatusBadge: React.FC<ConfirmationStatusBadgeProps> = ({
    bankNeedToConfirm,
    cryptoNeedToConfirm,
}) => {
    const { colors } = useTheme();

    const totalNeedToConfirmAddresses = bankNeedToConfirm + cryptoNeedToConfirm;

    if (totalNeedToConfirmAddresses === 0) {
        return null;
    }

    const infoText = `${totalNeedToConfirmAddresses} not confirmed`;

    return (
        <Badge
            value={
                <HStack spacing={4} width="100%">
                    <Icons.Attention color={colors.attention100} />
                    <TextSmall>{infoText}</TextSmall>
                </HStack>
            }
            size="large"
            variant="info"
        />
    );
};

export const Header: React.FC = () => {
    const { colors } = useTheme();
    const { side, setSide, openLightBox, bankNeedToConfirm, cryptoNeedToConfirm } = useAddressContext();

    const isOwnerUser = useIsOwnerUser();
    const textAdaptor = useTextAdaptor();
    const totalNeedToConfirm = bankNeedToConfirm + cryptoNeedToConfirm;

    return (
        <HStack padding={12} width="100%" justifyContent="space-between">
            <HStack spacing={8}>
                <HeaderTab onClick={() => setSide("your")} active={side === "your"}>
                    {textAdaptor("yourAccountHeaderTitle")}
                </HeaderTab>
                <HeaderTab onClick={() => setSide("shared")} active={side === "shared"}>
                    {textAdaptor("sharedAccountHeaderTitle")}{" "}
                    {totalNeedToConfirm > 0 && (
                        <Mark weight={500} color={colors.attention100}>
                            {totalNeedToConfirm}
                        </Mark>
                    )}
                </HeaderTab>
            </HStack>
            {side === "your" && (
                <Tooltip content={isOwnerUser ? undefined : EDITING_UNAVAILABLE_MESSAGE}>
                    <PrimaryButton disabled={!isOwnerUser} size="small" onClick={openLightBox}>
                        Add & edit
                    </PrimaryButton>
                </Tooltip>
            )}
            {side === "shared" && (
                <ConfirmationStatusBadge
                    bankNeedToConfirm={bankNeedToConfirm}
                    cryptoNeedToConfirm={cryptoNeedToConfirm}
                />
            )}
        </HStack>
    );
};
