import { useEffect, useState } from "react";

export type TransitionState = "entering" | "exiting" | "active" | undefined;
type DelayOptions = {
    enterDelay?: number;
    exitDelay?: number;
};
type TransitionStateData = {
    isActive: boolean;
    transitionState: TransitionState;
};

export const useTransition = (isOpen: boolean, { enterDelay = 0, exitDelay = 50 }: DelayOptions = {}) => {
    const [state, setState] = useState<TransitionStateData>({
        isActive: isOpen,
        transitionState: isOpen ? "active" : undefined,
    });

    const changeStateAfterDelay = (newState: TransitionStateData, delay: number) => {
        const taskId = setTimeout(() => {
            setState((prev) => ({ ...prev, ...newState }));
        }, delay);
        return () => clearTimeout(taskId);
    };

    useEffect(() => {
        switch (state.transitionState) {
            case "entering":
                return changeStateAfterDelay(
                    {
                        isActive: true,
                        transitionState: "active",
                    },
                    enterDelay,
                );
            case "exiting":
                return changeStateAfterDelay(
                    {
                        isActive: false,
                        transitionState: undefined,
                    },
                    exitDelay,
                );
        }
    }, [state.transitionState, enterDelay, exitDelay]);

    useEffect(() => {
        if (isOpen) {
            setState({ isActive: true, transitionState: "entering" });
            return;
        }
        if (state.transitionState !== "exiting") {
            setState((prev) => ({ ...prev, transitionState: "exiting" }));
            return;
        }
    }, [isOpen]);

    return {
        transitionState: state.transitionState,
        isActive: state.isActive,
    };
};
