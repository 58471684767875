import styled from "styled-components";

export const TEST_ALERT_HEIGHT = 24;

const TestEnv = styled.aside`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: ${TEST_ALERT_HEIGHT}px;
    background: #ffffff;
    box-shadow: 0px 1px rgba(0, 0, 0, 0.03);
    position: sticky;
    width: 100%;
    top: 0;
    isolation: isolate;
    z-index: 99;
    padding: 3px 20px;
    ${(p) => p.theme.mixins.nonInteractive}
    svg {
        margin-right: 5px;
    }
    font-family: "InterVariable", "sans-serif";
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: -0.005em;
    color: ${(p) => p.theme.colors.textSubdued};
    b {
        color: black;
        margin-right: 2px;
    }
`;
export const TestEnvAlert = () => {
    return (
        <TestEnv>
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="14" height="14" rx="7" fill="#FF2626" />
                <path
                    d="M6.47206 7.632H7.56406L7.82806 4.732V2.5H6.17206V4.732L6.47206 7.632ZM6.11206 11.2H7.88806V9.4H6.11206V11.2Z"
                    fill="white"
                />
            </svg>
            <b>Test environment.</b>
            <span>No market prices and no trades are binding.</span>
        </TestEnv>
    );
};
