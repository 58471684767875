import { H3 } from "@fm-frontend/uikit";
import { useSharedBanksAddresses } from "feature/addresses/hooks/useSharedBanksAddresses";
import { Settlement } from "feature/settlements/types";
import styled from "styled-components";
import { BankAccounts } from "../../../../components/BankAccounts";
import { HintsLayout } from "./HintsLayout";
import { GroupContent, HintsHeader } from "./styled";

const Content = styled(GroupContent)`
    max-height: 437px;
`;

export const BankAccountHints = ({ settlement }: { settlement: Settlement }) => {
    const { data: bankAccounts, isLoading } = useSharedBanksAddresses();

    if (isLoading) {
        return null;
    }

    const { asset, cpId } = settlement;

    const filteredBankAccounts = bankAccounts.filter(
        ({ currency, rule }) => currency?.name === asset && rule?.cp.id === cpId,
    );

    if (filteredBankAccounts.length === 0) {
        return null;
    }

    return (
        <HintsLayout>
            <HintsHeader>
                <H3>
                    {settlement.cpName}, {settlement.asset}
                </H3>
            </HintsHeader>
            <Content>
                <BankAccounts bankAccounts={filteredBankAccounts} />
            </Content>
        </HintsLayout>
    );
};
