import { bindTo, unBind } from "feature/app/ws";
import { AuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ClientFeeds from "utils/ClientFeeds";

const BOOK_FEED = "F";

export const useConditionalTradingBookSubscription = (
    instrumentName: string,
    isAvailableToSubscribe: boolean,
    authorizedClientId?: AuthorizedClientId,
) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const feedId = authorizedClientId
            ? { instrument: instrumentName, clientId: authorizedClientId }
            : instrumentName;

        if (!ClientFeeds.isValidCombination(BOOK_FEED, feedId)) {
            return;
        }

        if (isAvailableToSubscribe) {
            dispatch(bindTo({ feed: BOOK_FEED, feedId: feedId }));
        }

        return () => {
            dispatch(unBind({ feed: BOOK_FEED, feedId: feedId }));
        };
    }, [instrumentName, isAvailableToSubscribe, authorizedClientId]);
};
