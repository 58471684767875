import { LegalDataInputs } from "store/onboardingSlice";
import { ValidationMessages } from "utils/validationMessages";
import { AnySchema, number, object, string } from "yup";
import { Inputs as AssetsInputs, MAX_CURRENCIES_SELECT } from "./Assets";
import { Inputs as GeneralInfoInputs } from "./GeneralInfo";

export const legalDataSchema = object<Record<keyof LegalDataInputs, AnySchema>>().shape({
    companyName: string()
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim()),
    registrationNumber: string()
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim()),
    address: string()
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim()),
    city: string()
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim()),
    zipCode: string()
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim()),
    country: string()
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim()),
});

export const generalInfoDataSchema = object<Record<keyof GeneralInfoInputs, AnySchema>>().shape({
    businessType: string()
        .transform((value: any, originalValue: any) => (originalValue === null ? undefined : value))
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim()),
    monthTurnover: number()
        .typeError(ValidationMessages.INVALID)
        .transform((value: any, originalValue: any) => (originalValue === "" ? undefined : value))
        .moreThan(0, ValidationMessages.MIN_TURNOVER_ERROR)
        .required(ValidationMessages.REQUIRED),
    tradeExpectation: string()
        .transform((value: any, originalValue: any) => (originalValue === null ? undefined : value))
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim()),
    settleExpectation: string()
        .transform((value: any, originalValue: any) => (originalValue === null ? undefined : value))
        .required(ValidationMessages.REQUIRED)
        .transform((value: string) => value?.trim()),
});

export const assetsDataSchema = object<Record<keyof AssetsInputs, AnySchema>>().shape({
    currencies: object()
        .test("isCurrenciesSelected", "You must choose at least one asset that you are trading", (assets) => {
            return Object.values(assets).some((a) => Boolean(a));
        })
        .test(
            "isCountAllowed",
            "You have already chosen 10 assets. Uncheck some of previously selected assets.",
            (assets) => {
                return Object.values(assets).filter((a) => Boolean(a)).length <= MAX_CURRENCIES_SELECT;
            },
        ),
});
