export class Socket {
    private url: string;

    private webSocket: WebSocket | null = null;

    private dataCallBack: (ev: MessageEvent<any>) => void;

    private protocols: string | string[] | undefined;

    constructor(
        url: string,
        dataCallBack: (ev: MessageEvent<any>) => void,
        protocols: string | string[] | undefined = undefined,
    ) {
        this.url = url;
        this.dataCallBack = dataCallBack;
        this.protocols = protocols;
    }

    get ws() {
        return this.webSocket;
    }

    init = () => {
        console.info("WebSocket init", this.url);
        this.webSocket = new WebSocket(this.url, this.protocols);
        this.webSocket.addEventListener("message", this.onMessage);
        this.webSocket.addEventListener("close", this.onClose);
        this.webSocket.addEventListener("error", this.onError);
        this.webSocket.addEventListener("open", this.onOpen);
    };

    onClose = (ev: CloseEvent) => {
        console.info("WebSocket closed", ev?.code, this.url);
    };

    onError = (e: Event) => {
        console.error("WebSocket error", e);
    };

    onMessage = (ev: MessageEvent<any>) => {
        this?.dataCallBack(ev);
    };

    onOpen = () => {
        console.info("WebSocket opened", this.url);
    };

    send = (data: string | ArrayBufferLike | Blob | ArrayBufferView) => {
        this.webSocket?.send(data);
    };

    close = () => {
        this.webSocket?.removeEventListener("message", this.dataCallBack);
        this.webSocket?.removeEventListener("close", this.onClose);
        this.webSocket?.removeEventListener("error", this.onError);
        this.webSocket?.removeEventListener("open", this.onOpen);
        this.webSocket?.close();
    };
}
