import {
    Form,
    FormActions,
    FormAttention,
    FormBody,
    FormInfo,
    FormProgress,
    getMatch,
    H1,
    H3,
    IconButton,
    Input,
    Mark,
    P,
    PBold,
    PrimaryButton,
    ShadowCard,
    Skeleton,
    TextSmall,
} from "@fm-frontend/uikit";
import { Header as FormHeader } from "@fm-frontend/uikit/src/components/headers/Header";
import { HeaderTitle } from "@fm-frontend/uikit/src/components/headers/HeaderTitle";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { IconDelete, IconError, IconSearch } from "components/icons";
import { useFormHelpers, useTakerInfo } from "hooks";
import { useGroupedCurrencies } from "hooks/useGroupedCurrencies";
import React, { useEffect, useMemo, useState } from "react";
import { Control, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
    OnboardingMap,
    ONBOARDING_STEPS,
    setCurrentStep,
    setIsAssetsRequired,
    setRestartVerificationMakerId,
    useIsPostOnboardingRequired,
} from "store/onboardingSlice";
import { resetPostOnboardingStages } from "store/onboardingActions";
import { useTheme } from "styled-components";
import { OnboardingCurrencyType, TakerInfoRequest, TakerInfoType } from "types";
import {
    CheckboxContainer,
    CheckboxGroup,
    CheckboxGroupLabel,
    Header,
    Layout,
    OnboardingInfoBanner,
    useOnboardingCurrencies,
} from "../common";
import { CheckboxPill } from "./components";
import { assetsDataSchema } from "./schemas";
import { EmptyContent, Panda, SearchContainer } from "./styled";

export const MAX_CURRENCIES_SELECT = 10;

export interface Inputs {
    currencies: Record<number, boolean>;
}

const mapTakerInfoToFormData = (takerInfo: TakerInfoType | undefined) => {
    const { currency } = takerInfo ?? {};

    return {
        currencies: currency?.reduce<Record<string, boolean>>((acc, { id }) => {
            acc[id] = true;
            return acc;
        }, {}),
    };
};

const search = (groupedCurrencies: Record<string, OnboardingCurrencyType[]>, query: string) => {
    const normalizedQuery = query.toLowerCase().trim();

    return Object.entries(groupedCurrencies)?.reduce<
        Record<string, { match: React.ReactNode; currency: OnboardingCurrencyType }[]>
    >((acc, [group, currencies]) => {
        for (const currency of currencies) {
            if (!acc[group]) {
                acc[group] = [];
            }

            const { name } = currency;
            const match = getMatch(name, normalizedQuery);

            if (match) {
                const matchResult = {
                    match: match,
                    currency: currency,
                };

                acc[group].push(matchResult);
            }
        }

        return acc;
    }, {} as Record<string, { match: React.ReactNode; currency: OnboardingCurrencyType }[]>);
};

export const Assets: React.FC = () => {
    useOnboardingCurrencies();
    const dispatch = useDispatch();
    const { takerInfo, updateTakerInfo } = useTakerInfo();
    const [query, setQuery] = useState("");
    const { setLoading, isLoading, error, setError } = useFormHelpers();
    const groupedCurrencies = useGroupedCurrencies({ isUnique: true });
    const onboardingCurrencies = useMemo(() => search(groupedCurrencies, query), [groupedCurrencies, query]);
    const isPostOnboardingRequired = useIsPostOnboardingRequired();

    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: mapTakerInfoToFormData(takerInfo),
        mode: "onChange",
        resolver: yupResolver(assetsDataSchema),
    });

    const selectedCurrencies: Record<string, boolean> = watch("currencies");

    useEffect(() => {
        reset(mapTakerInfoToFormData(takerInfo));
    }, [takerInfo]);

    const submit = async ({ currencies }: Inputs) => {
        if (!takerInfo) {
            return;
        }

        try {
            setLoading(true);

            const { bank, currency, ...rest } = takerInfo;

            const params: TakerInfoRequest = {
                ...rest,
                bank_id: bank ? bank.map(({ id }) => Number(id)) : [],
                currency_id: Object.entries(currencies).reduce<Array<number>>((currencies, [id, value]) => {
                    if (value) {
                        currencies.push(Number(id));
                    }

                    return currencies;
                }, []),
            };

            await updateTakerInfo(params);

            dispatch(setIsAssetsRequired(false));
            dispatch(setCurrentStep(ONBOARDING_STEPS.BL_API_INTEGRATION));
        } catch (e) {
            setError(String(e));
        }

        setLoading(false);
    };

    const handlePostOnboardingBackClick = () => {
        dispatch(resetPostOnboardingStages());
        dispatch(setRestartVerificationMakerId());
    };

    return (
        <Layout>
            <Header>
                <H1>{isPostOnboardingRequired ? "Start verification" : "Business & legal"}</H1>
                <P>Fill in the form and we will introduce you to suitable liquidity providers</P>
            </Header>
            <OnboardingInfoBanner />
            <Form onSubmit={handleSubmit(submit)}>
                <FormProgress
                    currentStep={OnboardingMap[ONBOARDING_STEPS.BL_ASSETS].progress.current}
                    totalSteps={OnboardingMap[ONBOARDING_STEPS.BL_ASSETS].progress.total}
                    onBackClick={
                        isPostOnboardingRequired
                            ? handlePostOnboardingBackClick
                            : () => dispatch(setCurrentStep(ONBOARDING_STEPS.BL_GENERAL_INFO))
                    }
                />
                <ShadowCard>
                    <FormHeader>
                        <HeaderTitle title="Assets" />
                    </FormHeader>
                    <FormBody>
                        <FormInfo>Select max 10 most vital assets you are trading</FormInfo>
                        <SearchContainer>
                            <IconSearch />
                            <Input
                                placeholder="Search assets..."
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                            />
                            {query && <IconButton variant="basic" Icon={IconDelete} onClick={() => setQuery("")} />}
                        </SearchContainer>
                        {onboardingCurrencies ? (
                            <OnboardingCurrencies
                                control={control}
                                onboardingCurrencies={onboardingCurrencies}
                                selectedCurrencies={selectedCurrencies}
                            />
                        ) : (
                            [...Array(8).keys()].map((key) => <Skeleton key={key} height="16px" />)
                        )}
                        {errors.currencies?.message && (
                            <FormAttention>
                                <IconError />
                                <P>{errors.currencies?.message}</P>
                            </FormAttention>
                        )}
                        {error && (
                            <FormAttention>
                                <IconError />
                                <P>{error}</P>
                            </FormAttention>
                        )}
                    </FormBody>
                    <FormActions variant="plain">
                        <PrimaryButton type="submit" fullWidth size="large" loading={isLoading}>
                            Continue
                        </PrimaryButton>
                    </FormActions>
                </ShadowCard>
            </Form>
        </Layout>
    );
};

const OnboardingCurrencies: React.VFC<{
    onboardingCurrencies: Record<string, { match: React.ReactNode; currency: OnboardingCurrencyType }[]>;
    control: Control<Inputs>;
    selectedCurrencies: Record<string, boolean>;
}> = ({ onboardingCurrencies, control, selectedCurrencies }) => {
    const { colors } = useTheme();

    if (!onboardingCurrencies) {
        return null;
    }

    const selectedCurrenciesCount = Object.values(selectedCurrencies ?? {}).filter((v) => v).length;
    const currencies = Object.entries(onboardingCurrencies).filter(([, c]) => c.length);

    if (!currencies.length) {
        return (
            <EmptyContent>
                <H3 color={colors.ui32}>Very quiet here</H3>
                <Panda>🐼</Panda>
                <PBold>Asset not found</PBold>
                <FormInfo>
                    Use asset codes like ‘BTC’ or ‘ETH’. Don't worry if you haven't found one — you can request it later
                </FormInfo>
            </EmptyContent>
        );
    }

    return (
        <>
            {selectedCurrenciesCount > 0 && (
                <TextSmall color={colors.ui72}>
                    {selectedCurrenciesCount} selected{" "}
                    <Mark color={colors.ui32}>({MAX_CURRENCIES_SELECT - selectedCurrenciesCount} left)</Mark>
                </TextSmall>
            )}
            {currencies.map(([group, arr]) => (
                <CheckboxGroup key={group}>
                    <CheckboxGroupLabel>
                        {group} <span>{arr.length}</span>
                    </CheckboxGroupLabel>

                    <CheckboxContainer>
                        {arr.map(({ currency: { id, name }, match }) => (
                            <CheckboxPill
                                disabled={selectedCurrenciesCount >= MAX_CURRENCIES_SELECT && !selectedCurrencies[id]}
                                control={control}
                                key={id}
                                match={match}
                                name={`currencies.${id}`}
                                text={name}
                            />
                        ))}
                    </CheckboxContainer>
                </CheckboxGroup>
            ))}
        </>
    );
};
