import { createContext, PropsWithChildren, useContext } from "react";
import styled, { css } from "styled-components";
import { noop } from "../../const";

type Value = string;

const RadioGroupContext = createContext<{
    handleValueChange: (value: Value) => void;
    value?: Value | null;
}>({
    handleValueChange: noop,
});

interface RadioGroupProps {
    value?: Value | null;
    onChange: (value: Value) => void;
}

const RadioGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RadioGroup = ({ value, onChange, children }: PropsWithChildren<RadioGroupProps>) => (
    <RadioGroupContext.Provider
        value={{
            value,
            handleValueChange: onChange,
        }}
    >
        <RadioGroupContainer>{children}</RadioGroupContainer>
    </RadioGroupContext.Provider>
);

interface RadioProps {
    value: Value;
    checkmarkColor?: string;
}

const checkmarkStyles = css`
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 16px;
    text-align: center;
    position: absolute;
    left: 0;
`;

const RadioContainer = styled.div<{ isSelected: boolean; checkmarkColor?: string }>`
    position: relative;
    padding: 2px 7px 2px 21px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${(p) => p.theme.colors.ui100};
    cursor: pointer;

    ${(p) =>
        p.isSelected &&
        css`
            &::before {
                content: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.70711 0.302437C10.0976 0.705687 10.0976 1.35948 9.70711 1.76273L3.66667 8L0.292893 4.51629C-0.0976311 4.11304 -0.0976311 3.45925 0.292893 3.056C0.683417 2.65275 1.31658 2.65275 1.70711 3.056L3.66667 5.07941L8.29289 0.302437C8.68342 -0.100812 9.31658 -0.100812 9.70711 0.302437Z' fill='white'/%3E%3C/svg%3E");
                background-color: ${p.checkmarkColor ? p.checkmarkColor : p.theme.colors.brand100};
                color: ${p.theme.colors.uiWhite100};
                ${checkmarkStyles}
            }
        `}
    ${(p) =>
        !p.isSelected &&
        css`
            &::before {
                content: "";
                border: 1px solid ${p.theme.colors.ui12};
                ${checkmarkStyles}
            }
        `}
`;

RadioGroup.Radio = ({ value, checkmarkColor, children }: PropsWithChildren<RadioProps>) => {
    const { value: radioGroupValue, handleValueChange } = useContext(RadioGroupContext);
    const handleClick = () => handleValueChange(value);

    return (
        <RadioContainer onClick={handleClick} checkmarkColor={checkmarkColor} isSelected={value === radioGroupValue}>
            {children}
        </RadioContainer>
    );
};
