import { Listbox } from "@headlessui/react";
import React, { memo } from "react";
import styled, { css, useTheme } from "styled-components";

const CheckMark = memo(() => {
    const { colors } = useTheme();

    return (
        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.17139 8.6868L3.46855 12.8073L8.82861 3.19287"
                stroke="url(#paint0_linear_6694_3962)"
                strokeWidth="2.3"
                strokeLinecap="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_6694_3962"
                    x1="5"
                    y1="3.19287"
                    x2="5"
                    y2="12.8073"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={colors.brand100} />
                    <stop offset="1" stopColor={colors.brand100} />
                </linearGradient>
            </defs>
        </svg>
    )
});

export const CheckBoxUnchecked = memo(() => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="4" fillOpacity="1" />
        <rect
            x="0.5"
            y="0.5"
            width="15"
            height="15"
            rx="3.5"
            stroke="#243034"
            strokeOpacity="0.12"
        />
    </svg>
));

export const CheckBoxChecked = memo(() => {
    const { colors } = useTheme();

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="4" fill={colors.brand100} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7071 4.30244C13.0976 4.70569 13.0976 5.35948 12.7071 5.76273L6.66667 12L3.29289 8.51629C2.90237 8.11304 2.90237 7.45925 3.29289 7.056C3.68342 6.65275 4.31658 6.65275 4.70711 7.056L6.66667 9.07941L11.2929 4.30244C11.6834 3.89919 12.3166 3.89919 12.7071 4.30244Z"
                fill="white"
            />
        </svg>
    );
});

const OPTION_MARGIN = 4;
const Option = styled.div<{ $active: boolean }>`
    border-radius: 6px;
    background-color: transparent;

    display: flex;
    align-items: center;
    padding: 8px;
    width: calc(100% - ${OPTION_MARGIN * 2}px);
    margin-left: ${OPTION_MARGIN}px;
    margin-right: ${OPTION_MARGIN}px;
    min-height: 36px;

    overflow: hidden;
    cursor: pointer;

    :first-child {
        margin-top: 4px;
    }
    :last-child {
        margin-bottom: 4px;
    }

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.07px;

    color: ${(p) => p.theme.colors.ui100};

    :hover {
        background-color: ${(p) => p.theme.colors.ui4};
        color: ${(p) => p.theme.colors.ui100};
    }

    ${(p) =>
        p.$active &&
        css`
            background-color: ${(p) => p.theme.colors.ui4};
            color: ${(p) => p.theme.colors.ui100};
        `}

    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }
`;

const CheckMarkContainer = styled.span`
    width: 18px;
    padding-right: 8px;
    line-height: 100%;
`;

const CheckBoxContainer = styled.span`
    padding-right: 8px;
    line-height: 100%;
`;

type OptionFnProps<T> = {
    value: T;
    children: React.ReactNode;
    disabled?: boolean;
    className?: string;
};

export const OptionFn = <T,>({ value, children, disabled, className }: OptionFnProps<T>) => {
    return (
        <Listbox.Option disabled={disabled} as={React.Fragment} value={value}>
            {({ active, selected }) => (
                <Option $active={active} className={className}>
                    <CheckMarkContainer>{selected && <CheckMark />}</CheckMarkContainer>
                    {children}
                </Option>
            )}
        </Listbox.Option>
    );
};

export const MultipleOptionFn = <T,>({
    value,
    children,
    disabled,
    className,
}: OptionFnProps<T>) => {
    return (
        <Listbox.Option disabled={disabled} as={React.Fragment} value={value}>
            {({ active, selected }) => (
                <Option $active={active} className={className}>
                    <CheckBoxContainer>
                        {!selected && <CheckBoxUnchecked />}
                        {selected && <CheckBoxChecked />}
                    </CheckBoxContainer>
                    {children}
                </Option>
            )}
        </Listbox.Option>
    );
};
