import { SEARCH_PARAMS } from "const";
import { useFilterState } from "hooks/useFilterState";
import { useAuthorizedClientId } from "./useAuthorizedClientId";

const PAGE_FILTER_KEY = "trading-firm-book";

export const useActiveFirmInstrument = () => {
    const { authorizedClientId } = useAuthorizedClientId();

    const suffix = authorizedClientId ? `-${authorizedClientId}` : "";
    const filterKey = `${PAGE_FILTER_KEY}${suffix}`;
    const searchKey = `${SEARCH_PARAMS.activeFirmInstrument}${suffix}`;

    return useFilterState<string>(filterKey, searchKey, "");
};
