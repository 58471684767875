import { useCopyToClipboard } from "@fm-frontend/utils";
import { createNotification } from "feature/app";
import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

interface CopyCellProps {
    content: string | number | undefined | null;
    notificationTitle?: string;
}

export const CopyCell: React.FC<CopyCellProps> = memo(({ content, notificationTitle, children }) => {
    const copyToClipboard = useCopyToClipboard();
    const dispatch = useDispatch();

    const copyUnavailable = content === undefined || content === null || content === "";

    const handleAddressCopy = useCallback(() => {
        if (copyUnavailable) {
            return;
        }

        copyToClipboard(content, (value) => {
            dispatch(
                createNotification({
                    type: "success",
                    content: notificationTitle
                        ? `${notificationTitle} was successfully copied to clipboard`
                        : `Value ${value} was successfully copied to clipboard`,
                }),
            );
        });
    }, [content, notificationTitle]);

    return <div onClick={handleAddressCopy}>{children}</div>;
});
