import { ACTIONS_TABLE_COLUMN_KEY, InfiniteTable } from "@fm-frontend/uikit";
import {
    ColumnDef,
    ExpandedState,
    getCoreRowModel,
    getExpandedRowModel,
    getSortedRowModel,
    Row,
} from "@tanstack/react-table";
import { BottomFixTableContainer } from "components/BottomFixTableContainer";
import React, { useCallback, useMemo, useState } from "react";
import { useIsSubaccountUser } from "store/hooks";
import styled from "styled-components";
import { sortByPrefferedCurrencies } from "utils/prefferedCurrencies";
import { AssetRow } from "../types";
import { useColumns } from "./useColumns";

const Container = styled.div`
    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }

    table {
        table-layout: fixed;
        width: 100%;
        min-width: 1062px;
    }
`;

type TableProps<T> = {
    data: T[];
    isLoading?: boolean;
};

export const AssetsTable: React.FC<TableProps<AssetRow>> = ({ data, isLoading }) => {
    const isSubaccountUser = useIsSubaccountUser();
    const columns = useColumns();
    const memoizedData = useMemo(() => {
        return sortByPrefferedCurrencies(data, (rowData) => rowData.currency);
    }, [data]);
    const [expanded, setExpanded] = useState<ExpandedState>(true);

    const getSubRows = useCallback((row) => row.counterparties, []);
    const isExpandable = !isSubaccountUser;
    const tableOptions = isExpandable
        ? {
              data: memoizedData,
              columns: columns as ColumnDef<AssetRow, any>[],
              getSubRows,
              getCoreRowModel: getCoreRowModel(),
              getExpandedRowModel: getExpandedRowModel(),
              state: {
                  expanded,
              },
              onExpandedChange: setExpanded,
              getSortedRowModel: getSortedRowModel(),
          }
        : {
              data: memoizedData,
              columns: columns as ColumnDef<AssetRow, any>[],
              getCoreRowModel: getCoreRowModel(),
              getSortedRowModel: getSortedRowModel(),
          };

    const handleRowClick = (row: Row<AssetRow>, id: string) => {
        if (id === ACTIONS_TABLE_COLUMN_KEY) {
            return;
        }

        if (row.getCanExpand()) {
            row.toggleExpanded();
        }
    };

    return (
        <Container>
            <BottomFixTableContainer>
                <InfiniteTable
                    tableOptions={tableOptions}
                    onRowClick={handleRowClick}
                    isLoading={isLoading}
                />
            </BottomFixTableContainer>
        </Container>
    );
};
