import { Settlement } from "feature/settlements/types";
import { useInstruments } from "hooks";
import { BankAccountHints } from "./BankAccountHints";
import { CryptoAddressHints } from "./CryptoAddressHints";

export const AddressHints = ({ settlement }: { settlement: Settlement }) => {
    const { currencies, isLoading } = useInstruments();

    if (isLoading) {
        return null;
    }

    const isFiatAsset = currencies[settlement.asset].currencyType === "fiat";

    return isFiatAsset ? <BankAccountHints settlement={settlement} /> : <CryptoAddressHints settlement={settlement} />;
};
