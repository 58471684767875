import { useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router";

type ElementType<T extends any[]> = T extends (infer U)[] ? U : never;

export type Options<TValue> = {
    persist?: boolean;
    resetSearch?: boolean;
    isArray?: boolean;
    parseValue?: (
        searchParamValue: string | undefined,
    ) => TValue extends any[] ? ElementType<TValue> : TValue;
    stringifyValue?: (value: TValue extends any[] ? ElementType<TValue> : TValue) => string;
};

const persistedSearchParams: Record<string, string | string[]> = {};

export const useAddPersistedSearchParamsToUrl = () => {
    const history = useHistory();

    useEffect(() => {
        const unsubscribe = history.listen((_, action) => {
            if (action === "PUSH") {
                const currentSearchParams = new URLSearchParams(history.location.search);
                Object.entries(persistedSearchParams).forEach(
                    ([searchParamKey, searchParamValue]) => {
                        currentSearchParams.delete(searchParamKey);
                        const serializedValues = Array.isArray(searchParamValue)
                            ? searchParamValue
                            : [searchParamValue];
                        serializedValues.forEach((serializedValue) => {
                            currentSearchParams.append(searchParamKey, serializedValue);
                        });
                    },
                );
                history.replace({ search: currentSearchParams.toString() });
            }
        });

        return unsubscribe;
    }, [history]);
};

export const useSearchParamState = <TValue = string | undefined>(
    searchParamKey: string,
    defaultValue: TValue | null,
    options: Options<TValue> = {},
): [
    value: TValue,
    setValue: (value: TValue, replace?: boolean) => void,
    deleteSearchParam: (replace?: boolean) => void,
] => {
    const { persist = false, resetSearch = false, parseValue, stringifyValue, isArray } = options;
    const history = useHistory();
    const { search } = useLocation();

    const searchParams = new URLSearchParams(search);
    const paramValue = searchParams.has(searchParamKey)
        ? searchParams[isArray ? "getAll" : "get"](searchParamKey) ?? undefined
        : undefined;
    const stateValue = useMemo(() => {
        if (paramValue === undefined || paramValue === "") {
            return defaultValue;
        }

        if (parseValue === undefined) {
            return paramValue;
        }

        if (Array.isArray(paramValue)) {
            return paramValue.map(parseValue);
        }

        return parseValue(paramValue);
    }, [String(paramValue)]) as TValue;

    const setStateValue = useCallback(
        (value: TValue, replace = false) => {
            const currentSearchParams = new URLSearchParams(
                resetSearch ? "" : history.location.search,
            );
            currentSearchParams.delete(searchParamKey);

            if (Array.isArray(value)) {
                const serializedValues = value.map(stringifyValue ?? String);
                serializedValues.forEach((currValue) => {
                    currentSearchParams.append(searchParamKey, currValue);
                });

                if (persist) {
                    persistedSearchParams[searchParamKey] = serializedValues;
                }
            } else {
                const serializedValue =
                    stringifyValue !== undefined ? stringifyValue(value as any) : String(value);
                currentSearchParams.append(searchParamKey, serializedValue);

                if (persist) {
                    persistedSearchParams[searchParamKey] = serializedValue;
                }
            }

            if (!replace) {
                history.push({ search: currentSearchParams.toString() });
            } else {
                history.replace({ search: currentSearchParams.toString() });
            }
        },
        [history, persist, resetSearch, searchParamKey],
    );

    const deleteSearchParam = useCallback(
        (replace = false) => {
            const currentSearchParams = new URLSearchParams(
                resetSearch ? "" : history.location.search,
            );
            currentSearchParams.delete(searchParamKey);

            delete persistedSearchParams[searchParamKey];

            if (!replace) {
                history.push({ search: currentSearchParams.toString() });
            } else {
                history.replace({ search: currentSearchParams.toString() });
            }
        },
        [history, persist, resetSearch, searchParamKey],
    );

    useEffect(() => {
        if (persist) {
            const currentSearchParams = new URLSearchParams(history.location.search);
            const initialValue = currentSearchParams.get(searchParamKey);

            if (typeof initialValue === "string") {
                persistedSearchParams[searchParamKey] = initialValue;
            }
        } else {
            delete persistedSearchParams[searchParamKey];
        }
    }, [persist, searchParamKey, history]);

    return [stateValue, setStateValue, deleteSearchParam];
};
