export type TransactionDirection = "toYou" | "fromYou";

export enum TransactionStatus {
    committed = "committed",
    received = "received",
    cancelled = "cancelled",
    sent = "sent",
    created = "created",
}

export type Transaction = {
    id: number;
    type: "deposit" | "withdrawal";
    subaccount: { id: number; name: string };
    asset: string;
    amount: bigint;
    fee: { payer: "recipient" | "sender"; size?: number };
    txId: string;
    comment: string;
    status: TransactionStatus;
    created: number;
    network?: string;
    direction?: TransactionDirection;
    isCreatedByRecipient: boolean;
};
