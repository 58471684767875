import { useStepper } from "hooks/useStepper";
import { useRef } from "react";
import { useCreateUser, useDeleteUser, useUpdateUser } from "../api";
import type { UserWizardData } from "../types";
import { UserInfoModal } from "./UserInfoModal";
import type { FormData as UserInfoFormData } from "./UserInfoModal/types";
import { UserPermissionsModal } from "./UserPermissionsModal";
import type { FormData as PermissionsFormData } from "./UserPermissionsModal/types";
import { UserWasCreatedModal } from "./UserWasCreatedModal";

enum Step {
    Closed = 0,
    UserInfo,
    UserPermissions,
    UserWasCreated,
}

type WizardMode = "create" | "edit";

export type FilledUserWizardData = Required<UserWizardData>;

export const useWizard = () => {
    const createUser = useCreateUser();
    const updateUser = useUpdateUser();
    const deleteUser = useDeleteUser();
    const { currentStep, goToStep } = useStepper(Step.Closed);
    const userData = useRef<UserWizardData>({});
    const mode = useRef<WizardMode>("create");
    const resetWizardState = () => {
        userData.current = {};
        mode.current = "create";
    };
    const handleClose = () => {
        resetWizardState();
        goToStep(Step.Closed);
    };

    const handleChange = (data: PermissionsFormData | UserInfoFormData) => {
        userData.current = {
            ...userData.current,
            ...data,
        };
    };
    const handleNextStepOnPermissionsModal = () => {
        if (mode.current === "create") {
            return createUser(userData.current as FilledUserWizardData).then(() => goToStep(Step.UserWasCreated));
        }

        return updateUser(userData.current as FilledUserWizardData).then(handleClose);
    };

    const wizard = (
        <>
            {currentStep === Step.UserInfo && (
                <UserInfoModal
                    onClose={handleClose}
                    onChange={handleChange}
                    onNextStep={() => goToStep(Step.UserPermissions)}
                    userData={userData.current}
                />
            )}
            {currentStep === Step.UserPermissions && (
                <UserPermissionsModal
                    onClose={handleClose}
                    onChange={handleChange}
                    onNextStep={handleNextStepOnPermissionsModal}
                    userData={userData.current}
                    onChangeUserInfoClick={() => goToStep(Step.UserInfo)}
                    onDeleteUser={() => deleteUser(userData.current as FilledUserWizardData).then(handleClose)}
                    mode={mode.current}
                />
            )}
            {currentStep === Step.UserWasCreated && <UserWasCreatedModal onClose={handleClose} />}
        </>
    );
    const editUser = (user: FilledUserWizardData) => {
        userData.current = user;
        mode.current = "edit";
        goToStep(Step.UserPermissions);
    };

    return {
        wizard,
        editUser,
        createNewUser: () => {
            resetWizardState();
            goToStep(Step.UserInfo);
        },
    };
};
