import { H3, PlainButton, PSmall, PSmallBold, VStack } from "@fm-frontend/uikit";
import { when } from "@fm-frontend/utils";
import { useHistory } from "react-router";
import styled from "styled-components";
import { HintsLayout } from "components/modal/Hints/HintsLayout";
import { HintsHeader } from "components/modal/Hints/HintsHeader";
import { HintsContent as DefaultHintsContent } from "components/modal/Hints/HintsContent";
import { useCryptoAddresses } from "feature/addresses/hooks/useCryptoAddresses";
import { ROUTES } from "feature/app/router";
import { Empty, Loading } from "../styled";

const HintsContent = styled(DefaultHintsContent)`
    gap: 16px;
`;

const ItemContainer = styled(VStack)`
    padding: 0 8px;
    gap: 2px;

    ${PSmall} {
        color: ${(p) => p.theme.colors.ui72};
        word-break: break-word;
    }
    ${PSmallBold} {
        color: ${(p) => p.theme.colors.ui52};
        word-break: break-word;
    }
`;

const Actions = styled.div`
    margin-top: auto;
    text-align: center;
`;

const Item = ({ title, value }: { title: string; value: string }) => (
    <ItemContainer>
        <PSmall>{title}</PSmall>
        <PSmallBold>{value}</PSmallBold>
    </ItemContainer>
);

export const CryptoAddressHints = ({ cpId, asset, network }: { cpId: number; asset: string; network?: string }) => {
    const history = useHistory();
    const { data: cryptoAddresses, isLoading } = useCryptoAddresses();

    if (isLoading) {
        return (
            <HintsLayout>
                <Loading />
            </HintsLayout>
        );
    }

    const cryptoAddress = cryptoAddresses.find((address) => {
        const { currency, revisions, network: addressNetwork } = address;

        if (!currency?.name || (addressNetwork && addressNetwork.name !== network)) {
            return false;
        }

        return currency.name === asset && revisions?.some((revision) => revision.cpId === cpId);
    });

    if (!cryptoAddress) {
        return (
            <HintsLayout>
                <HintsHeader>
                    <H3>
                        Address for {asset}
                        {network && `, ${network}`}
                    </H3>
                </HintsHeader>
                <Empty message="No information provided" />
                <Actions>
                    <PlainButton size="small" onClick={() => history.push(`${ROUTES.addresses}/your/crypto`)}>
                        Edit your addresses to receive crypto
                    </PlainButton>
                </Actions>
            </HintsLayout>
        );
    }

    const isUnderVerification = cryptoAddress.revisions.some(
        (revision) => revision.cpId === cpId && revision.revisionHash === undefined,
    );

    return (
        <HintsLayout>
            <HintsHeader>
                <H3>Your crypto address</H3>
            </HintsHeader>
            <HintsContent>
                <Item title={isUnderVerification ? "Address (not verified)" : "Address"} value={cryptoAddress.wallet} />
                {when(
                    Boolean(cryptoAddress.memoOrTag),
                    <>
                        <div>Memo or tag</div>
                        <div>{cryptoAddress.memoOrTag}</div>
                    </>,
                )}
                {when(
                    Boolean(cryptoAddress.privateNote),
                    <>
                        <div>Note</div>
                        <div>{cryptoAddress.privateNote}</div>
                    </>,
                )}
                <Actions>
                    <PlainButton size="small" onClick={() => history.push(`${ROUTES.addresses}/your/crypto`)}>
                        Edit your addresses to receive crypto
                    </PlainButton>
                </Actions>
            </HintsContent>
        </HintsLayout>
    );
};
