import { Badge, HStack, P } from "@fm-frontend/uikit";
import React from "react";

export const GroupRowInfoCell: React.FC<{ subRowCount: number | undefined; needToConfirmCount?: number }> = ({
    subRowCount,
    needToConfirmCount,
}) => {
    if (subRowCount === undefined) {
        return null;
    }

    const addressInfo = `${subRowCount} ${subRowCount < 2 ? "address" : "addresses"}`;

    return (
        <HStack spacing={6} alignItems="center" justifyContent="end">
            <P>{addressInfo}</P>
            {Boolean(needToConfirmCount) && <Badge value={needToConfirmCount} variant="attention" />}
        </HStack>
    );
};
