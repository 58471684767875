import { post } from "api";
import useSWR from "swr";
import { displayError } from "utils";
import { OnboardingStatusType } from "./types";

export const useOnboardingStatuses = () => {
    const fetcher = (url: string) => post(url, {});
    const { data, isLoading } = useSWR<OnboardingStatusType[]>("onboarding/inviteStatus", fetcher, {
        onError: displayError,
        fallbackData: [],
        revalidateOnMount: true,
        revalidateOnFocus: false,
    });

    return {
        onboardingStatuses: data ?? [],
        isLoading,
    };
};
