import {
    BasicInput,
    Form,
    FormActions,
    FormAttention,
    FormBody,
    FormInfo,
    Header as FormHeader,
    HeaderTitle,
    P,
    PrimaryButton,
    ShadowCard,
} from "@fm-frontend/uikit";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { IconError } from "components/icons";
import { useSelector } from "hooks";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { confirmSignIn } from "store/auth/actions";
import { authenticationSlice } from "store/auth/slice";
import { BottomLogin } from "../style";
import { ConfirmSignInInputs, confirmSignInSchema } from "./schema";

export const ConfirmSignIn: FC = () => {
    const dispatch = useDispatch();
    const authError = useSelector((state) => state.authentication.error);

    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
    } = useForm<ConfirmSignInInputs>({
        mode: "onSubmit",
        resolver: yupResolver(confirmSignInSchema),
    });

    const submit = async (confirmInputs: ConfirmSignInInputs) => {
        // @ts-ignore
        await dispatch(confirmSignIn(confirmInputs));
    };

    const handleBackToSignIn = () => {
        // @ts-ignore
        dispatch(authenticationSlice.actions.backToSignIn());
    };

    return (
        <>
            <Form onSubmit={handleSubmit(submit)}>
                <ShadowCard>
                    <FormHeader>
                        <HeaderTitle title="Two-factor authentication" />
                    </FormHeader>
                    <FormBody>
                        <FormInfo>
                            Please <span>enter the 6-digit</span> code from the app
                        </FormInfo>
                        <BasicInput
                            placeholder="000000"
                            {...register("code")}
                            error={errors.code?.message as string}
                        />
                        {authError && (
                            <FormAttention>
                                <IconError />
                                <P>{authError.message}</P>
                            </FormAttention>
                        )}
                    </FormBody>
                    <FormActions variant="plain">
                        <PrimaryButton fullWidth size="large" loading={isSubmitting}>
                            Send
                        </PrimaryButton>
                    </FormActions>
                </ShadowCard>
            </Form>
            <BottomLogin>
                <a href="#" onClick={handleBackToSignIn}>
                    Back
                </a>
            </BottomLogin>
        </>
    );
};
