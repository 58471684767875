import {
    GroupedByAssetRowData,
    GroupedByCpRowData,
    HRPClearingTimeInfoRow,
    PositionsTableRowData,
    Subrow,
    TotalRow,
} from "./types";

type Row = Subrow | PositionsTableRowData | HRPClearingTimeInfoRow;

export const isExpandableRow = (data: Row): data is GroupedByAssetRowData | GroupedByCpRowData => "subrows" in data;
export const isGroupedByAssetExpandableRow = (data: Row): data is GroupedByAssetRowData =>
    "subrows" in data && "asset" in data;
export const isGroupedByCpExpandableRow = (data: Row): data is GroupedByCpRowData =>
    "subrows" in data && "cpId" in data;
export const isSubrow = (data: Row): data is Subrow => !("subrows" in data) && "cpId" in data && "asset" in data;
export const isTotalRow = (data: Row): data is TotalRow => "isTotal" in data && data.isTotal;
export const isHRPClearingTimeInfoRow = (data: Row): data is HRPClearingTimeInfoRow =>
    "isHRPClearingTimeInfoRow" in data && data.isHRPClearingTimeInfoRow;
