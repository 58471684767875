import { useReactTable } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { UIEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SmallLoader } from "../../animations";
import { EmptySearch } from "../../common/EmptySearch";
import { Flex } from "../../layout";
import { Skeleton } from "../../Skeleton";
import { TableProps } from "../common";
import * as Styled from "../common/styled";
import { useTableStickyCellInsets } from "../common/useTableStickyCellInsets";
import { Thead } from "../Thead";
import { InfiniteTableBody } from "./Body";
import { DEFAULT_SELECTED_ROWS, FETCH_MORE_TRIGGER_HEIGHT } from "./constants";
import { InfiniteTableContainer } from "./Container";

type InfiniteTableProps<T extends object> = {
    isFetching?: boolean;
    onFetchMore?: () => void;
    virtualizerOptions?: Partial<Parameters<typeof useVirtualizer>[0]>;
} & TableProps<T>;

export function InfiniteTable<T extends object>({
    selectedRows = DEFAULT_SELECTED_ROWS,
    isLoading = false,
    isFetching = false,
    tableOptions,
    virtualizerOptions,
    onRowClick,
    className,
    onInitialize,
    onFetchMore,
}: InfiniteTableProps<T>) {
    // TODO: hotfix. Prevent `Ref` eq `null` for inf table
    const [isInitializing, setIsInitializing] = useState(true);
    useEffect(() => {
        setIsInitializing(false);
    }, []);

    const { data, columns } = tableOptions;
    const memoizedData = useMemo(
        () => (isLoading || isInitializing ? Array(12).fill({}) : data),
        [data, isLoading, isInitializing],
    );
    const preparedColumns = useMemo(() => {
        if (isLoading || isInitializing) {
            return columns.map((column) => ({
                ...column,
                cell: () => <Skeleton height="16px" />,
            }));
        }

        return columns;
    }, [isLoading, isInitializing, columns]);
    const isEmpty = memoizedData.length === 0 && !isLoading;
    const table = useReactTable({
        ...tableOptions,
        data: memoizedData,
        columns: preparedColumns,
    });
    const { rows } = table.getRowModel();
    const { tableRef, leftStickyCellInsets } = useTableStickyCellInsets(table.getAllColumns());
    const tableContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        onInitialize?.(table);
    }, [table]);

    const fetchMoreOnBottomReached = useCallback(
        (e: UIEvent<HTMLDivElement>) => {
            if (onFetchMore && !isFetching) {
                const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;

                if (scrollHeight - scrollTop - clientHeight < FETCH_MORE_TRIGGER_HEIGHT) {
                    onFetchMore();
                }
            }
        },
        [onFetchMore, isFetching],
    );

    return (
        <InfiniteTableContainer
            data-scroll-container
            ref={tableContainerRef}
            onScroll={fetchMoreOnBottomReached}
        >
            <Styled.Table className={className} ref={tableRef}>
                <Thead table={table} leftStickyCellInsets={leftStickyCellInsets} />
                <InfiniteTableBody
                    tableContainerRef={tableContainerRef}
                    rows={rows}
                    selectedRows={selectedRows}
                    onRowClick={onRowClick}
                    virtualizerOptions={virtualizerOptions}
                />
                <Styled.Tfoot>
                    {isFetching && (
                        <Styled.Tr>
                            <Styled.Td colSpan={preparedColumns.length}>
                                <SmallLoader style={{ margin: "0 auto" }} />
                            </Styled.Td>
                        </Styled.Tr>
                    )}
                </Styled.Tfoot>
            </Styled.Table>
            {isEmpty && (
                <Flex paddingTop={60} paddingBottom={60}>
                    <EmptySearch />
                </Flex>
            )}
        </InfiniteTableContainer>
    );
}
