import React, { useMemo } from "react";
import { Tooltip } from "./Tooltip";

export const TruncatedText: React.FC<{
    text: string;
    maxLength?: number;
    renderWrapper?: (value: string) => JSX.Element;
}> = ({ text, maxLength = 20, renderWrapper }) => {
    const { isTruncated, normalizedText } = useMemo(() => {
        if (text.length > maxLength) {
            return {
                isTruncated: true,
                normalizedText: `${text.slice(0, maxLength)}...`,
            };
        }

        return {
            isTruncated: false,
            normalizedText: text,
        };
    }, [text, maxLength]);

    const content = renderWrapper ? renderWrapper(normalizedText) : normalizedText;

    return isTruncated ? <Tooltip content={text}>{content}</Tooltip> : <>{content}</>;
};
