import { Flex, IconButton, Icons } from "@fm-frontend/uikit";
import styled from "styled-components";

const CpActionCellContainer = styled(Flex)`
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-left: 1px solid ${(p) => p.theme.colors.ui12};
`;

export const CpActionCell = ({ onClick }: { onClick: () => void }) => (
    <CpActionCellContainer>
        <IconButton variant="plain" Icon={Icons.Pencil} onClick={onClick} />
    </CpActionCellContainer>
);
