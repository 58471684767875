import styled, { css } from "styled-components";
import type { ButtonVariant } from "../types";

export const IconButton = styled.button.withConfig({
    shouldForwardProp: (prop) => !["loading"].includes(prop),
})<{ variant: ButtonVariant; disabled?: boolean; loading?: boolean }>`
    border-radius: 50%;
    height: 24px;
    width: 24px;
    user-select: none;

    cursor: pointer;

    ${(p) =>
        p.loading
            ? css`
                  cursor: wait;
              `
            : css`
                  &:disabled {
                      cursor: not-allowed;
                      background: ${p.variant === "plain" ? "transparent" : p.theme.colors.ui4};
                  }
              `}

    display: inline-flex;
    justify-content: center;
    align-items: center;

    background: ${(p) =>
        p.variant === "basic" ? p.theme.colors.ui8 : p.variant === "primary" ? p.theme.colors.brand100 : "transparent"};

    ${(p) =>
        !p.loading &&
        !p.disabled &&
        css`
            &:hover {
                background: ${p.variant === "basic"
                    ? p.theme.colors.ui12
                    : p.variant === "primary"
                    ? p.theme.colors.brand100
                    : p.variant === "plain"
                    ? p.theme.colors.ui8
                    : "transparent"};
            }
        `}

    &:focus-visible {
        background: ${(p) =>
            p.variant === "basic"
                ? p.theme.colors.ui8
                : p.variant === "primary"
                ? p.theme.colors.brand100
                : "transparent"};
        box-shadow: 0px 0px 0px 3px ${(p) => p.theme.colors.brand12},
            inset 0px 0px 0px 1px ${(p) => p.theme.colors.brand72};
    }
`;
