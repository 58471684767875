import { useInstruments } from "hooks";
import { useMemo } from "react";

export const useBulkEditData = ({
    isInstrumentInWhitelist,
}: {
    isInstrumentInWhitelist: (instrumentName: string) => boolean;
}) => {
    const { instruments } = useInstruments();

    return useMemo(() => {
        return instruments.map((instrument) => ({
            instrument,
            whitelisting: isInstrumentInWhitelist(instrument.instrumentName),
        }));
    }, [instruments, isInstrumentInWhitelist]);
};
