import { BasicInput, H2, P, PlainButton, PrimaryButton, VStack } from "@fm-frontend/uikit";
import { DropdownOption, SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { when } from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CurrencyDropdownSheet } from "components/CurrencySheet";
import { CurrencyTriggerEssence } from "components/CurrencyTriggerEssence";
import { useInstruments } from "hooks";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTheme } from "styled-components";
import { useCurrenciesPositions, useNetworksOptions } from "../../hooks";
import { cryptoAddressForAllSchema } from "../../schema";
import { AddressForAllCpFormInputs } from "../../types";
import { CRYPTO_CURRENCY_TYPES, handleInnerFormSubmit, hasCurrencyMemoOrTag } from "../../utils";

type CurrencyDropdownOption = DropdownOption<
    number,
    {
        position?: bigint;
    }
>;

type AddAddressFroAllCpModalProps = {
    onSave: (inputs: AddressForAllCpFormInputs) => void;
    onClose: () => void;
};

export const AddAddressFroAllCpModal: React.FC<AddAddressFroAllCpModalProps> = ({ onSave, onClose }) => {
    const { colors } = useTheme();
    const {
        handleSubmit,
        register,
        control,
        formState: { isSubmitting, errors },
        setValue,
        watch,
    } = useForm<AddressForAllCpFormInputs>({
        mode: "onSubmit",
        resolver: yupResolver(cryptoAddressForAllSchema),
    });
    const { currencyIds } = useInstruments();
    const currenciesPositions = useCurrenciesPositions();
    const currencyOptions = useMemo(
        (): CurrencyDropdownOption[] =>
            Object.values(currencyIds)
                .filter(({ currencyType }) => CRYPTO_CURRENCY_TYPES.includes(currencyType))
                .map(
                    ({ name, id }) =>
                        ({
                            text: name,
                            value: id,
                            position: currenciesPositions[name]?.value,
                        } as CurrencyDropdownOption),
                )
                .sort((a, b) => a.text.localeCompare(b.text)),
        [currencyIds, currenciesPositions],
    );
    const [selectedCurrencyId, memoOrTagRequired] = watch(["currencyId", "memoOrTagRequired"]);

    useEffect(() => {
        const currencyName = currencyOptions.find(({ value }) => value === selectedCurrencyId)?.text;

        setValue("memoOrTagRequired", hasCurrencyMemoOrTag(currencyName));
    }, [selectedCurrencyId, currencyOptions]);

    const networksOptions = useNetworksOptions({
        availableNetworks: currencyIds?.[selectedCurrencyId]?.networks ?? [],
    });

    useEffect(() => {
        const singleOption = networksOptions.length === 1;

        const valueToSet = singleOption ? Number(networksOptions[0].value) : null;
        const shouldValidate = singleOption;
        const shouldDirty = singleOption;

        setValue("networkId", valueToSet, {
            shouldValidate,
            shouldDirty,
        });
    }, [networksOptions, selectedCurrencyId]);

    const onSubmit = handleSubmit(async (data: AddressForAllCpFormInputs) => onSave(data));

    return (
        <form onSubmit={handleInnerFormSubmit(onSubmit)}>
            <VStack minWidth="360px" asCard>
                <VStack padding={12}>
                    <H2>Add address for all CPs</H2>
                </VStack>
                <VStack paddingX={12} paddingBottom={16} spacing={16}>
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <SingleDropdown
                                value={field.value}
                                onChange={field.onChange}
                                renderTrigger={(trigger) => (
                                    <SingleDropdown.Trigger {...trigger} size="large" variant="basic">
                                        <CurrencyTriggerEssence
                                            {...trigger}
                                            option={trigger.selectedOption}
                                            size="large"
                                        />
                                    </SingleDropdown.Trigger>
                                )}
                                options={currencyOptions}
                                placeholder="Select an option"
                                fullWidth
                                error={errors?.currencyId?.message}
                                caption="Asset"
                            >
                                <CurrencyDropdownSheet
                                    size="large"
                                    options={currencyOptions}
                                    Dropdown={SingleDropdown}
                                />
                            </SingleDropdown>
                        )}
                        name="currencyId"
                    />
                    {when(
                        selectedCurrencyId !== undefined,
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <SingleDropdown
                                    value={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <SingleDropdown.Trigger {...trigger} size="large" variant="basic">
                                            <SingleDropdown.TriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOption}
                                                size="large"
                                            />
                                        </SingleDropdown.Trigger>
                                    )}
                                    options={networksOptions}
                                    disabled={networksOptions.length === 0}
                                    placeholder="Select an option"
                                    caption="Network"
                                    error={errors?.networkId?.message}
                                    fullWidth
                                >
                                    <SingleDropdown.BasicSheet size="large" options={networksOptions} />
                                </SingleDropdown>
                            )}
                            name="networkId"
                        />,
                    )}

                    <BasicInput
                        data-1pignore
                        label="Address"
                        placeholder="000x..."
                        error={errors?.wallet?.message}
                        {...register("wallet")}
                    />
                    {when(
                        memoOrTagRequired,
                        <BasicInput
                            data-1pignore
                            label="Memo or tag"
                            placeholder="Memo"
                            error={errors?.memoOrTag?.message}
                            {...register("memoOrTag")}
                        />,
                    )}
                    <P color={colors.ui52}>System will not replace already exising addresses</P>
                </VStack>
                <VStack paddingX={12} paddingBottom={16} spacing={8}>
                    <PrimaryButton fullWidth size="large" type="submit" loading={isSubmitting}>
                        Add address
                    </PrimaryButton>
                    <PlainButton fullWidth size="large" type="button" onClick={onClose}>
                        Cancel
                    </PlainButton>
                </VStack>
            </VStack>
        </form>
    );
};
