import { ValueFormat } from "@fm-frontend/utils";
import { NOT_ENOUGH_LIQUIDITY } from "../../utils";

export const getDisabledBuyActionState = ({
    isDisabled = false,
    buyMarketPrice,
    buyWAPrice,
    price,
}: {
    isDisabled?: boolean;
    buyMarketPrice: bigint | null;
    buyWAPrice: bigint | null;
    price: bigint | null;
}) => {
    if (buyMarketPrice === null && buyWAPrice === null) {
        return {
            isDisabled: true,
            reason: "",
        };
    }

    if (buyWAPrice === NOT_ENOUGH_LIQUIDITY) {
        return {
            isDisabled: true,
            reason: "Not enough liquidity",
        };
    }

    if (price !== null && buyWAPrice !== null && price < buyWAPrice) {
        return {
            isDisabled: true,
            reason: `The price must be higher than ${ValueFormat.price(buyWAPrice)}`,
        };
    }

    return {
        isDisabled: isDisabled,
        reason: "",
    };
};

export const getDisabledSellActionState = ({
    isDisabled = false,
    sellMarketPrice,
    sellWAPrice,
    price,
}: {
    isDisabled?: boolean;
    sellMarketPrice: bigint | null;
    sellWAPrice: bigint | null;
    price: bigint | null;
}) => {
    if (sellMarketPrice === null && sellWAPrice === null) {
        return {
            isDisabled: true,
            reason: "",
        };
    }

    if (sellWAPrice === NOT_ENOUGH_LIQUIDITY) {
        return {
            isDisabled: true,
            reason: "Not enough liquidity",
        };
    }

    if (price !== null && sellWAPrice !== null && price > sellWAPrice) {
        return {
            isDisabled: true,
            reason: `The price must be lower than ${ValueFormat.price(sellWAPrice)}`,
        };
    }

    return {
        isDisabled: isDisabled,
        reason: "",
    };
};
