import styled from "styled-components";

export const Spinner = styled.div`
    display: block;
    z-index: 9999;
    -webkit-animation: spin2 1s infinite linear;
    animation: spin2 1s infinite linear;
`;

export const SmallLoader = styled(Spinner)<{ color?: string }>`
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 2px solid ${(p) => p.color || p.theme.colors.textBody3};
    border-top: 2px solid transparent;
`;

export const LargeLoader = styled(Spinner)<{ color?: string }>`
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 2px solid ${(p) => p.color || p.theme.colors.textBody3};
    border-top: 2px solid transparent;
`;
