import { HStack, Icons, PaddingProps, VStack } from "@fm-frontend/uikit";
import { useToggle } from "@fm-frontend/utils";
import React from "react";
import { HoverCell } from "../HoverCell";

type AccordionCellProps = {
    renderAccordionContent: () => React.ReactElement;
} & PaddingProps;

export const AccordionCell: React.FC<AccordionCellProps> = ({ renderAccordionContent, children, ...paddingProps }) => {
    const { toggle, isActive } = useToggle();

    return (
        <HoverCell>
            <VStack width="100%">
                <HStack
                    {...paddingProps}
                    onClick={toggle}
                    width="100%"
                    justifyContent="space-between"
                    spacing={8}
                    alignItems="center"
                >
                    {children}
                    {isActive ? <Icons.Collapse /> : <Icons.Expand />}
                </HStack>
                {isActive && <VStack>{renderAccordionContent?.()}</VStack>}
            </VStack>
        </HoverCell>
    );
};
