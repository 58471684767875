import { Accordion } from "@fm-frontend/uikit";
import { AccordionWithConfirmation } from "components/AccordionWithConfirmation";
import { ROUTES } from "feature/app/router";
import { HistoryTab } from "feature/history/trades/hooks";
import { useLastOrders } from "feature/trade/hooks/useLastOrders";
import { FC } from "react";
import { DISPLAY_TRADING_LAST_TRADES_LIMIT } from "../../utils";
import { LastOrdersTable } from "../LastOrdersTable";

type LastOrdersProps = {};

export const LastOrders: FC<LastOrdersProps> = ({}) => {
    const { trades, isLoading } = useLastOrders(DISPLAY_TRADING_LAST_TRADES_LIMIT);

    return (
        <AccordionWithConfirmation
            defaultExpanded
            header={<Accordion.Title upperCase>Last orders</Accordion.Title>}
            content={<LastOrdersTable orders={trades} isLoading={isLoading} />}
            moveToDetails={{
                detailsPageUrl: `${ROUTES.historyTrades}/${HistoryTab.FirmBook}`,
                fromSectionTitle: "Trading Page",
                toSectionTitle: "History Page",
                buttonTitle: "View history",
            }}
        />
    );
};
