import { StatusOfRfqHistoryItem } from "feature/rfq/apiHooks/useLastRfqTrades";
import { useFilterState } from "hooks/useFilterState";
import { useSearchParamState } from "hooks/useSearchParamState";
import { useParams } from "react-router";

const PAGE_FILTER_KEY = "trades_history";
const RFQ_PAGE_FILTER_KEY = "rfq_trades_history";

export const enum HistoryTab {
    FirmBook = "firmBook",
    RFQ = "rfq",
}

const useTabFilterKey = () => {
    const { tab: activeHistoryTab = HistoryTab.FirmBook } = useParams<{ tab: HistoryTab }>();
    if (activeHistoryTab === HistoryTab.RFQ) {
        return RFQ_PAGE_FILTER_KEY;
    }
    return PAGE_FILTER_KEY;
};

export const usePagesState = (defaultValue: number[]) => {
    return useSearchParamState<typeof defaultValue>("page", defaultValue, {
        persist: false,
        parseValue: Number,
        isArray: true,
    });
};

export const useRangeState = (defaultValue: { startDate?: Date | null; endDate?: Date | null }) => {
    return useSearchParamState<typeof defaultValue>("range", defaultValue, {
        persist: false,
        parseValue: (value = "") => {
            try {
                const [startDate, endDate] = JSON.parse(value);
                return {
                    startDate: new Date(startDate),
                    endDate: new Date(endDate),
                };
            } catch (e) {
                return defaultValue;
            }
        },
        stringifyValue: (value) => {
            return JSON.stringify([value.startDate?.getTime(), value.endDate?.getTime()]);
        },
    });
};

export const useCounterpartiesState = (
    defaultValue: number[],
    availableValues: number[] | undefined,
) => {
    const tabFilterKey = useTabFilterKey();

    return useFilterState<typeof defaultValue>(tabFilterKey, "cp", defaultValue, {
        persist: false,
        parseValue: Number,
        isArray: true,
        availableValues,
    });
};

export const useInstrumentsState = (
    defaultValue: string[],
    availableValues: string[] | undefined,
) => {
    const tabFilterKey = useTabFilterKey();

    return useFilterState<typeof defaultValue>(tabFilterKey, "instrument", defaultValue, {
        persist: false,
        isArray: true,
        availableValues,
    });
};

export const useStatusesState = (
    defaultValue: StatusOfRfqHistoryItem[],
    availableValues: StatusOfRfqHistoryItem[],
) => {
    const tabFilterKey = useTabFilterKey();

    return useFilterState<StatusOfRfqHistoryItem[]>(tabFilterKey, "status", defaultValue, {
        persist: false,
        isArray: true,
        availableValues,
    });
};

export const useOrderTypeState = (
    defaultValue: string[],
    availableValues: string[] | undefined,
) => {
    const tabFilterKey = useTabFilterKey();

    return useFilterState<typeof defaultValue>(tabFilterKey, "orderType", defaultValue, {
        persist: false,
        isArray: true,
        availableValues,
    });
};
