import { PREFERRED_CURRENCIES } from "const";

const splitArrayByPrefferedCurrecies = <T>(array: T[], selectAsset: (item: T) => string) => {
    const prefferedCurrenciesMap = new Map<string, T | null>(
        PREFERRED_CURRENCIES.map((asset) => [asset, null]),
    );
    const otherCurrenciesItems = [];

    for (const item of array) {
        const asset = selectAsset(item);
        if (prefferedCurrenciesMap.has(asset)) {
            prefferedCurrenciesMap.set(asset, item);
            continue;
        }

        otherCurrenciesItems.push(item);
    }

    return {
        prefferedCurrencies: [...prefferedCurrenciesMap.values()].filter(
            (item) => item !== null,
        ) as T[],
        otherCurrencies: otherCurrenciesItems,
    };
};

export const sortByPrefferedCurrencies = <T>(array: T[], selectAsset: (item: T) => string) => {
    const { prefferedCurrencies, otherCurrencies } = splitArrayByPrefferedCurrecies(
        array,
        selectAsset,
    );

    return [
        ...prefferedCurrencies,
        ...otherCurrencies.sort((item1, item2) => {
            const asset1 = selectAsset(item1);
            const asset2 = selectAsset(item2);

            return asset1.localeCompare(asset2);
        }),
    ];
};
