import { MobileLogo } from "components/icons";
import { TopMenuHeader } from "components/TopMenu";
import { UserMenuControl } from "components/UserMenuControl/UserMenuControl";
import { useSelector } from "hooks/useSelector";
import { useTitle } from "hooks/useTitle";
import { memo } from "react";
import { Spacer } from "style";
import { selectors } from "style/theme";
import styled from "styled-components";
import { Shifter } from "./styled";

const Header = styled.div`
    ${selectors.mixinHeader1}
    margin: 11px 12px 0;
    display: flex;
    justify-content: center;
    position: relative;
    height: 40px;

    & > span {
        position: relative;
        top: 6px;
    }
`;

const UserMenuWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 0;
`;

const PageContent = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CenteredPageLayout: React.FC<{ title: string }> = memo(({ children, title }) => {
    const { isMobile } = useSelector((state) => state.app);
    useTitle(typeof children === "string" ? children : null);

    if (isMobile) {
        return (
            <>
                <TopMenuHeader>
                    <MobileLogo />
                    <Spacer />
                    <span>{title}</span>
                    <Spacer />
                    <UserMenuControl />
                </TopMenuHeader>
                {children}
            </>
        );
    }

    return (
        <Container>
            <Header>
                <span>{title}</span>
                <Shifter />
                <UserMenuWrapper>
                    <UserMenuControl />
                </UserMenuWrapper>
            </Header>
            <PageContent>
                {children}
                <Shifter />
            </PageContent>
        </Container>
    );
});
