import { post } from "api";
import { ApiAccountEntity, ApiAccountEntityRequest } from "./entities/ApiAccountEntity";
import { ApiAccountRuleEntity, ApiAccountRuleEntityRequest } from "./entities/ApiAccountRuleEntity";
import { ApiBankEntity, ApiBankEntityRequest } from "./entities/ApiBankEntity";
import { ApiEntityId } from "./entities/ApiEntityId";
import { BankAddress, CryptoAddress } from "./types";

const SAB_URL = "sab";

export const SHARED_PAYMENT_ACCOUNTS_URL = `${SAB_URL}/getCounterpartyPaymentAccounts`;
export const PAYMENT_ACCOUNTS_URL = `${SAB_URL}/getPaymentAccounts`;
const ADD_PAYMENT_ACCOUNT_URL = `${SAB_URL}/createPaymentAccount`;
const UPDATE_PAYMENT_ACCOUNT_URL = `${SAB_URL}/updatePaymentAccount`;
const DELETE_PAYMENT_ACCOUNT_URL = `${SAB_URL}/deletePaymentAccount`;

export const PAYMENT_ACCOUNTS_RULES_URL = `${SAB_URL}/getPaymentAccountsRules`;
const ADD_PAYMENT_ACCOUNT_RULE_URL = `${SAB_URL}/createPaymentAccountRule`;
const UPDATE_PAYMENT_ACCOUNT_RULE_URL = `${SAB_URL}/updatePaymentAccountRule`;
const DELETE_PAYMENT_ACCOUNT_RULE_URL = `${SAB_URL}/deletePaymentAccountRule`;

export const ACCOUNT_REVISIONS_URL = `${SAB_URL}/getRevisionsForAccount`;
export const PENDING_ACCOUNTS_REVISIONS_URL = `${SAB_URL}/getPendingPaymentAccountsRevisions`;
const ACKNOWLEDGE_ACCOUNT_REVISION_URL = `${SAB_URL}/acknowledgePaymentAccountRevision`;

export const BANK_URL = `${SAB_URL}/getBanks`;
const ADD_BANK_URL = `${SAB_URL}/createBank`;
const UPDATE_BANK_URL = `${SAB_URL}/updateBank`;

export const postDataFromResult = async <T>(method: string, data: T) => post(method, data).then(({ result }) => result);

const addOrUpdateAccount = async (account: ApiAccountEntityRequest) => {
    const method = account.id === undefined ? ADD_PAYMENT_ACCOUNT_URL : UPDATE_PAYMENT_ACCOUNT_URL;

    try {
        const accountResponse: ApiAccountEntity = await postDataFromResult(method, account);

        return {
            accountResponse,
            accountError: null,
        };
    } catch (e) {
        return {
            accountResponse: null,
            accountError: String(e),
        };
    }
};

const addOrUpdateAccountRule = async (accountRuleRequest: ApiAccountRuleEntityRequest) => {
    const method = accountRuleRequest.id === undefined ? ADD_PAYMENT_ACCOUNT_RULE_URL : UPDATE_PAYMENT_ACCOUNT_RULE_URL;

    try {
        const accountRule: ApiAccountRuleEntity = await postDataFromResult(method, accountRuleRequest);

        return {
            accountRule,
            accountRuleError: null,
        };
    } catch (e) {
        return {
            accountRule: null,
            accountRuleError: String(e),
        };
    }
};

const addOrUpdateBank = async (bank: ApiBankEntityRequest) => {
    const method = bank.id === undefined ? ADD_BANK_URL : UPDATE_BANK_URL;

    try {
        const bankResponse: ApiBankEntity = await postDataFromResult(method, bank);

        return {
            bankId: bankResponse.id,
            bankError: null,
        };
    } catch (e) {
        return {
            bankId: null,
            bankError: String(e),
        };
    }
};

const deleteResource = async (method: string, id: number) => {
    try {
        const response: ApiAccountEntity = await post(method, {
            id,
        });

        return {
            id: response.id ?? null,
            error: null,
        };
    } catch (e) {
        return {
            id: null,
            error: String(e),
        };
    }
};

export const addOrUpdateCryptoAddress = async (address: CryptoAddress) => {
    if (!address.rule || !address.network || !address.currency) {
        return "Invalid data";
    }

    const accountData: ApiAccountEntityRequest = {
        id: address.accountId,
        currencyId: address.currency.id,
        networkId: address.network.id,
        data: {
            wallet: address.wallet,
            memoOrTag: address.memoOrTag,
        },
        notes: address.privateNote,
    };

    const { accountResponse, accountError } = await addOrUpdateAccount(accountData);

    if (accountError || accountResponse === null) {
        return accountError;
    }

    const accountRuleData: ApiAccountRuleEntityRequest = {
        id: address.rule?.id,
        paymentAccountId: accountResponse.id,
        counterpartyId: address.rule.cp.id,
    };

    const { accountRuleError, accountRule } = await addOrUpdateAccountRule(accountRuleData);

    if (accountRuleError || accountRule === null) {
        return accountRuleError;
    }

    return null;
};

export const addOrUpdateBankAddress = async (address: BankAddress) => {
    if (!address.bankPrimary || !address.currency) {
        return "Invalid data";
    }

    const bankPrimaryRequest: ApiBankEntityRequest = {
        id: address.bankPrimary.id,
        data: {
            name: address.bankPrimary.name,
            address: address.bankPrimary.address,
        },
    };

    const { bankId: bankPrimaryId, bankError: bankPrimaryError } = await addOrUpdateBank(bankPrimaryRequest);

    if (bankPrimaryError || bankPrimaryId === null) {
        return bankPrimaryError;
    }

    let bankIntermediateId: number | null = null;

    if (address.bankIntermediate) {
        const bankIntermediateRequest: ApiBankEntityRequest = {
            id: address.bankIntermediate.id,
            data: {
                name: address.bankIntermediate.name,
                address: address.bankIntermediate.address,
            },
        };
        const { bankId, bankError } = await addOrUpdateBank(bankIntermediateRequest);

        if (bankError || bankId === null) {
            return bankError;
        }

        bankIntermediateId = bankId;
    }

    const accountData: ApiAccountEntityRequest = {
        id: address.accountId,
        currencyId: address.currency.id,
        data: {
            wallet: address.wallet,
            publicDetails: address.publicDetails,
            otherDetails: address.otherDetails,
            companyAddress: address.company?.address,
            companyName: address.company?.name,
            memoOrTag: address.memoOrTag,
        },
        bankIntermediateId: bankIntermediateId,
        bankPrimaryId: bankPrimaryId,
    };

    const { accountResponse, accountError } = await addOrUpdateAccount(accountData);

    if (accountError || accountResponse === null) {
        return accountError;
    }

    const accountRuleData: ApiAccountRuleEntityRequest = {
        id: address.rule?.id,
        paymentAccountId: accountResponse.id,
    };

    const { accountRuleError } = await addOrUpdateAccountRule(accountRuleData);

    if (accountRuleError) {
        return accountRuleError;
    }

    return null;
};

export const deleteAddress = async (accountId: number, accountRuleId?: number) => {
    if (accountRuleId !== undefined) {
        const { error: accountRuleError } = await deleteResource(DELETE_PAYMENT_ACCOUNT_RULE_URL, accountRuleId);

        if (accountRuleError) {
            return accountRuleError;
        }
    }

    const { error: accountError } = await deleteResource(DELETE_PAYMENT_ACCOUNT_URL, accountId);

    if (accountError) {
        return accountError;
    }

    return null;
};

export const acknowledgeSharedAccountRevision = async (accountId: number, revisionHash: string) => {
    try {
        const response: ApiEntityId = await post(ACKNOWLEDGE_ACCOUNT_REVISION_URL, {
            accountId,
            revisionHash,
        });

        return {
            id: response.id ?? null,
            error: null,
        };
    } catch (e) {
        return {
            id: null,
            error: String(e),
        };
    }
};
