import {
    Form,
    FormActions,
    FormBody,
    Header,
    HeaderTitle,
    Modal,
    PrimaryButton,
    ShadowCard,
    VStack,
} from "@fm-frontend/uikit";
import { Label } from "@fm-frontend/uikit/src/components/obsoleteInputs/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { post } from "api";
import { Error } from "feature/specificSubaccountAndPBPagesContents/styled";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { isFiat } from "utils";
import { ValidationMessages } from "utils/validationMessages";
import { AnySchema, object, string } from "yup";
import { Transaction } from "../../types";
import { AutoResizableTextArea } from "../AutoResizableTextArea";
import { TransactionInfo } from "../infoCards";

interface ReceivedDepositInfoModalProps {
    closeModal: () => void;
    deposit: Transaction;
}

type Inputs = {
    txId: string;
};

const validationSchema = object<Record<keyof Inputs, AnySchema>>().shape({
    txId: string().trim().required(ValidationMessages.REQUIRED),
});

export const SendDepositModal = ({ closeModal, deposit }: ReceivedDepositInfoModalProps) => {
    const {
        formState: { isSubmitting, errors },
        register,
        handleSubmit,
    } = useForm<Inputs>({
        defaultValues: {},
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
    });
    const [apiError, setApiError] = useState<string | null>(null);

    const onSubmit = async ({ txId }: Inputs) => {
        try {
            setApiError(null);
            await post("sendOutgoingSettlementTransaction", {
                transactionId: deposit.id,
                txId,
            });
            closeModal();
        } catch (err) {
            setApiError(err as string);
        }
    };

    let txIdLabel = "Enter TX ID to commit the transaction";
    let txIdPlaceholder = "sa000...mpl3e";
    if (isFiat(deposit.asset)) {
        txIdLabel = "Enter bank reference to commit the transaction";
        txIdPlaceholder = "Settlement as per MPSA No.";
    }

    return (
        <Modal isOpen onClose={closeModal}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ShadowCard>
                    <Header>
                        <HeaderTitle title="Send deposit" />
                    </Header>
                    <FormBody spacing={8}>
                        <TransactionInfo transaction={deposit} />
                        <VStack spacing={4} width="100%">
                            <Label htmlFor="tx-id">{txIdLabel}</Label>
                            <AutoResizableTextArea
                                id="tx-id"
                                rows={1}
                                placeholder={txIdPlaceholder}
                                disabled={isSubmitting}
                                autoFocus
                                {...register("txId")}
                                error={errors.txId?.message}
                            />
                            {apiError && <Error>{apiError}</Error>}
                        </VStack>
                    </FormBody>
                    <FormActions variant="plain">
                        <PrimaryButton type="submit" fullWidth size="large" loading={isSubmitting}>
                            Send deposit
                        </PrimaryButton>
                    </FormActions>
                </ShadowCard>
            </Form>
        </Modal>
    );
};
