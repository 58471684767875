import { BasicButton, Modal } from "@fm-frontend/uikit";
import { useFaqLinks } from "hooks/useFaqLinks";
import { ButtonGroup } from "style";
import { Content, Message, Title } from "./styled";

interface SubaccountWasCreatedModalProps {
    onClose: () => void;
    isOpen?: boolean;
}

export const SubaccountWasCreatedModal = ({ onClose, isOpen }: SubaccountWasCreatedModalProps) => {
    const faqLinks = useFaqLinks();

    return (
        <Modal
            isOpen={isOpen!}
            additionalActions={{ helpButtonUrlOrClickHandler: faqLinks?.faqSubs }}
            onClose={onClose}
        >
            <Content>
                <Title>Invitation was sent</Title>
                <Message>The sub-account’s status will become active after the authorization.</Message>
                <ButtonGroup>
                    <BasicButton size="large" fullWidth onClick={onClose}>
                        Continue
                    </BasicButton>
                </ButtonGroup>
            </Content>
        </Modal>
    );
};
