import { Icons, PSmall } from "@fm-frontend/uikit";
import { FC, ReactNode } from "react";
import styled, { useTheme } from "styled-components";

const Container = styled.div`
    cursor: pointer;
    display: flex;
    gap: 3px;
    padding: 2px 0 2px 4px;

    :hover {
        background-color: ${(p) => p.theme.colors.ui8};
        border-radius: 6px;
        cursor: pointer;
    }
`;

const Title = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui100};
`;

const Subtitle = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui52};
`;

type MenuItemComponentProps = {
    title: string;
    subtitle?: ReactNode;
    onClick?: () => void;
    className?: string;
};

const MenuItemComponent: FC<MenuItemComponentProps> = ({ title, subtitle, onClick, className }) => {
    const theme = useTheme();

    return (
        <Container className={className} onClick={onClick}>
            <Title>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
            <Icons.Forward color={theme.colors.ui20} />
        </Container>
    );
};

export const MenuItem = styled(MenuItemComponent)``;
