import { Icons, PXSmall } from "@fm-frontend/uikit";
import { useCopyToClipboard, ValueFormat } from "@fm-frontend/utils";
import { createNotification } from "feature/app";
import { AuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { TradingSide } from "feature/trade/trading/types";
import { useGetAssetFractionDigits } from "hooks/useAssetFractionDigits";
import React from "react";
import { useDispatch } from "react-redux";
import styled, { useTheme } from "styled-components";
import { TileActions } from "../TradingTile/components";

const Container = styled.div<{ $isTileActive: boolean }>`
    display: flex;
    width: 100%;
    height: 22px;
    position: relative;

    opacity: ${(p) => (p.$isTileActive ? 1 : 0.3)};

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 0;
        background-color: ${(p) => (p.$isTileActive ? p.theme.colors.ui4 : "transparent")};
        gap: 2px;
        cursor: pointer;
        width: 50%;

        &:hover {
            background-color: ${(p) => p.theme.colors.ui8};
        }
    }

    &::before {
        content: "";
        width: 1px;
        height: 10px;
        top: 6px;
        background-color: ${(p) => p.theme.colors.ui8};
        position: absolute;
        right: 50%;
        transform: translate(50%, 0);
    }
`;

const VOLUME_SIZE_TITLE: Record<TradingSide, string> = {
    volume: "Volume",
    size: "Size",
};

type CopyTileActionsProps = {
    authorizedClientId?: AuthorizedClientId;
    isTileActive: boolean;

    sizeOrVolume: bigint | null;
    totalVolumeOrSize: bigint | null;
    equivalentVolumeOrSize: bigint | null;
    tradingSide: TradingSide;
    instrument: string;
    baseAsset: string;
    quoteAsset: string;
    sellPrice: bigint | null;
    buyPrice: bigint | null;
};

export const CopyTileActions: React.FC<CopyTileActionsProps> = ({
    authorizedClientId,
    isTileActive,

    sizeOrVolume,
    tradingSide,
    instrument,
    baseAsset,
    quoteAsset,
    totalVolumeOrSize,
    equivalentVolumeOrSize,
    sellPrice,
    buyPrice,
}) => {
    const { colors } = useTheme();
    const dispatch = useDispatch();
    const copyToClipboard = useCopyToClipboard();

    const getAssetFractionDigits = useGetAssetFractionDigits();

    const formatOrderDetails = (action: "buy" | "sell") => {
        const isSizeBasedTrade = tradingSide === "size";
        const sideAsset = isSizeBasedTrade ? baseAsset : quoteAsset;
        const reverseSideAsset = isSizeBasedTrade ? quoteAsset : baseAsset;

        const sideAssetDigits = getAssetFractionDigits(sideAsset).fractionDigits;
        const reverseSideAssetDigits = getAssetFractionDigits(reverseSideAsset).fractionDigits;

        const items = [`${instrument} ${action} order`];

        const addItem = (title: string, value: bigint | null, digits: number, asset: string) => {
            if (value !== null) {
                items.push(`${title}: ${ValueFormat.price(value, digits)} ${asset}`);
            }
        };

        addItem(VOLUME_SIZE_TITLE[tradingSide], sizeOrVolume, sideAssetDigits, sideAsset);
        addItem("Total", totalVolumeOrSize, reverseSideAssetDigits, reverseSideAsset);
        addItem("Total, $", equivalentVolumeOrSize, 2, "USD");

        const price = action === "sell" ? sellPrice : buyPrice;
        addItem("Price", price, 8, reverseSideAsset);

        return items.join("\n");
    };

    const handleCopy = (action: "buy" | "sell") => {
        const content = formatOrderDetails(action);
        copyToClipboard(content, () => {
            dispatch(
                createNotification({
                    type: "success",
                    content: `${
                        action.charAt(0).toUpperCase() + action.slice(1)
                    } order details were successfully copied to clipboard`,
                }),
            );
        });
    };

    if (!authorizedClientId) {
        return null;
    }

    return (
        <TileActions>
            <Container $isTileActive={isTileActive}>
                <button
                    type="button"
                    onClick={() => handleCopy("sell")}
                    aria-label="Copy Sell Order"
                >
                    <Icons.Copy size={10} color={colors.negative100} />
                    <PXSmall>Copy Sell</PXSmall>
                </button>
                <button type="button" onClick={() => handleCopy("buy")} aria-label="Copy Buy Order">
                    <Icons.Copy size={10} color={colors.positive100} />
                    <PXSmall>Copy Buy</PXSmall>
                </button>
            </Container>
        </TileActions>
    );
};
