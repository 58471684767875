import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

type FormsKeyType = symbol;
type FormsConfirmStateType = Record<FormsKeyType, boolean>;
type FormHelperContextType = {
    formsConfirmState: FormsConfirmStateType;
    setShouldConfirmClose: (formKey: FormsKeyType, shouldConfirm: boolean) => void;
};

const FormHelperContext = createContext<FormHelperContextType>({
    formsConfirmState: {},
    setShouldConfirmClose: () => {},
});

export const useFormHelperContext = () => {
    const context = useContext(FormHelperContext);
    if (!context) {
        throw new Error("useFormHelperContext must be used within a FormHelperContextProvider");
    }
    return context;
};

export const useFormShouldCloseConfirm = (formKey: FormsKeyType) => {
    const { formsConfirmState } = useFormHelperContext();

    return useMemo(() => Boolean(formsConfirmState[formKey]), [formsConfirmState, formKey]);
};

export const useFormCloseConfirmer = (formKey: FormsKeyType, shouldConfirm: boolean) => {
    const { setShouldConfirmClose } = useFormHelperContext();

    useEffect(() => {
        setShouldConfirmClose(formKey, shouldConfirm);

        return () => setShouldConfirmClose(formKey, false);
    }, [formKey, shouldConfirm, setShouldConfirmClose]);
};

export const FormHelperContextProvider: React.FC = ({ children }) => {
    const [formsConfirmState, setFormsConfirmState] = useState<FormsConfirmStateType>({});

    const setShouldConfirmClose = useCallback(
        (formKey: FormsKeyType, shouldConfirm: boolean) =>
            setFormsConfirmState((prev) => ({
                ...prev,
                [formKey]: shouldConfirm,
            })),
        [setFormsConfirmState],
    );

    return (
        <FormHelperContext.Provider
            value={{
                formsConfirmState,
                setShouldConfirmClose,
            }}
        >
            {children}
        </FormHelperContext.Provider>
    );
};
