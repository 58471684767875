import styled from "styled-components";

export const HintsHeader = styled.div`
    border-bottom: 1px solid ${(p) => p.theme.colors.ui20};
    color: ${(p) => p.theme.colors.ui72};
    margin: 0 8px;
    padding-bottom: 4px;

    display: flex;
    align-items: center;
    gap: 8px;
`;
