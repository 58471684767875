import { EMPTY_ARRAY } from "const";
import { parseLimitByAsset } from "feature/counterparties/utils";
import { usePrimeBrokerViewType } from "store/hooks";
import useSWR from "swr";
import { CounterpartyLimitByAsset } from "types";
import { displayError } from "utils";
import { post } from "../api";

export const useLimitByAssetSWRKey = () => {
    const primeBrokerViewType = usePrimeBrokerViewType();
    const apiUrl = "cAssetLimits";
    const filter = primeBrokerViewType === "subaccounts"
        ? "subaccounts"
        : "external";

    return [apiUrl, filter];
}

const fetcher = ([url, filter]: [string, "external" | "subaccounts"]) => post(url, { filter });

export const useExtendedLimitsByAsset = () => {
    const apiMethod = useLimitByAssetSWRKey();
    const { data, mutate, isLoading } = useSWR<CounterpartyLimitByAsset[]>(apiMethod, fetcher, {
        onError: displayError,
        fallbackData: EMPTY_ARRAY as CounterpartyLimitByAsset[],
        revalidateOnMount: true,
        revalidateOnFocus: false,
    });

    return {
        limitsByAssets: data?.map((l) => parseLimitByAsset(l)) ?? [],
        isLoading,
        mutate,
    };
};
