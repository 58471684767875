import { getMatch, HStack, P as Currency, P, PSmall, VStack } from "@fm-frontend/uikit";
import { DropdownOption } from "@fm-frontend/uikit/src/components/v2";
import { getFiatCurrencyInfo, when } from "@fm-frontend/utils";
import { CurrencyIcon } from "components/CurrencyIcon";
import { CRYPTO_CURRENCY_TYPES } from "feature/addresses/utils";
import { useInstruments } from "hooks";
import React from "react";
import styled, { useTheme } from "styled-components";
import { fmt, FormatConfig } from "utils/format";

const Container = styled(HStack)`
    align-items: center;
    gap: 8px;
    overflow: hidden;
`;

const Network = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;

type PositionProps = {
    position: bigint;
    currency: string;
};
const Position: React.FC<PositionProps> = ({ position, currency }) => {
    const { colors } = useTheme();
    const { currencies } = useInstruments();

    const currencyType = currencies[currency]?.currencyType ?? "unknown";
    const fmtConfig: FormatConfig = CRYPTO_CURRENCY_TYPES.includes(currencyType)
        ? {
              significantDigits: 4,
              type: "size",
              unit: ` ${currency}`,
              unitPosition: "post",
          }
        : {
              significantDigits: 2,
              type: "size",
              unit: getFiatCurrencyInfo(currency)?.symbol,
          };

    const { formattedValue, isNegative } = fmt(position, fmtConfig);

    return (
        <PSmall title={formattedValue} ellipsis color={isNegative ? colors.negative52 : colors.ui32}>
            Total position: {formattedValue}
        </PSmall>
    );
};

type CurrencyOptionEssenceProps<T> = {
    option:
        | DropdownOption<
              T,
              {
                  position?: bigint;
                  network?: string;
              }
          >
        | undefined;
    query?: string;
    groupingOption?: boolean;
};

export const CurrencyOptionEssence = <T,>({ option, query = "", groupingOption }: CurrencyOptionEssenceProps<T>) => {
    if (!option) {
        return <></>;
    }

    return (
        <Container>
            {!groupingOption && <CurrencyIcon name={option.text} size={20} />}
            <VStack overflow="hidden">
                <HStack spacing={8} alignItems="center">
                    <Currency title={option.text} ellipsis>
                        {getMatch(option.text, query) ?? option.text}
                    </Currency>
                    {option.network && (
                        <Network title={option.network} ellipsis>
                            {getMatch(option.network, query) ?? option.network}
                        </Network>
                    )}
                </HStack>
                {when(option.position, <Position position={option.position!} currency={option.text} />)}
            </VStack>
        </Container>
    );
};
