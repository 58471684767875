import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { useFormShouldCloseConfirm } from "@fm-frontend/utils";
import { AssetsModal } from "feature/assetsControl/components/AssetsTabContent/AssetsModal";
import { ASSET_CONTROL_MODAL_KEY } from "feature/assetsControl/utils";
import { AuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { useAuthorizedClientInfo } from "feature/trade/hooks/useAuthorizedClientInfo";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { FC } from "react";
import { useModalContext } from "../../ModalContext";

type AssetLimitWidgetModalProps = {
    asset: string;
    assetLimitCurrency: string | undefined;
    assetLimitAmount: bigint | undefined;
    authorizedClientId: AuthorizedClientId;
};

export const AssetLimitWidgetModal: FC<AssetLimitWidgetModalProps> = ({
    asset,
    assetLimitCurrency,
    assetLimitAmount,
    authorizedClientId,
}) => {
    const authorizedClientName = useAuthorizedClientInfo(authorizedClientId)?.username;
    const { isModalOpen, closeModal } = useModalContext();
    const shouldConfirmClose = useFormShouldCloseConfirm(ASSET_CONTROL_MODAL_KEY);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(shouldConfirmClose, closeModal);

    return (
        <AssetsModal
            modalConfig={{
                assets: asset,
                adjust: "tradingLimits",
                grossLimit: assetLimitAmount,
                limitCurrency: assetLimitCurrency,
                cpName: authorizedClientName,
                cp: String(authorizedClientId),
            }}
            counterparties={EMPTY_ARRAY}
            isOpened={isModalOpen}
            onSubmit={closeModal}
            onClose={closeModalWithConfirm}
        />
    );
};
