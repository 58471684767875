export type TransactionDirection = "toYou" | "fromYou";

export enum SettlementStatus {
    request = "request",
    committed = "committed",
    received = "received",
    cancelled = "cancelled",
    sent = "sent",
    created = "created",
}

export type Settlement = {
    status: SettlementStatus;
    type: "in" | "out";
    cpId: number;
    cpName: string;
    asset: string;
    network?: string;
    amount: bigint;
    fee?: { payer: "recipient" | "sender"; size?: number };
    comment: string;
    createdAt: number;
    lastActionAt: number;
    id: number;
    txId: string;
    isCreatedByRecipient?: boolean;
};

export type Transaction = {
    id: number;
    type: "deposit" | "withdrawal";
    subaccount: { id: number; name: string };
    asset: string;
    amount: bigint;
    fee: { payer: "recipient" | "sender"; size?: number };
    txId: string;
    comment: string;
    status: "committed" | "received" | "cancelled" | "sent" | "created";
    created: number;
    network?: string;
    direction?: TransactionDirection;
    isCreatedByRecipient: boolean;
};
