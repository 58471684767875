import { post } from "api";
import { postViaWS } from "api/methods/postViaWS";
import { useSaveSuccessNotification } from "feature/addresses/hooks";
import { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { displayError } from "utils";

export type InstrumentWhitelistApi = {
    enabled: boolean;
    instruments: string[];
};

const FALLBACK_DATA: InstrumentWhitelistApi = {
    enabled: false,
    instruments: [],
};

const useInstrumentsWhitelist = () => {
    const fetcher = (url: string) => post(url, {});
    const { data, isLoading, mutate } = useSWR<InstrumentWhitelistApi>("instrumentsWhitelist", fetcher, {
        onError: displayError,
        fallbackData: FALLBACK_DATA,
        revalidateOnMount: true,
        revalidateOnFocus: false,
    });

    return useMemo(
        () => ({
            instrumentsWhitelist: data ?? FALLBACK_DATA,
            isLoading,
            mutate,
        }),
        [data, isLoading, mutate],
    );
};

const updateWhitelistingStatus = (status: boolean) => {
    const apiMethod = status ? "enableInstrumentsWhitelist" : "disableInstrumentsWhitelist";

    return postViaWS(apiMethod, {});
};

export const useWhitelistingManager = () => {
    const showSuccessNotification = useSaveSuccessNotification();
    const { instrumentsWhitelist, isLoading, mutate } = useInstrumentsWhitelist();
    const [isWhitelistingOn, setIsWhitelistingOn] = useState(false);
    const [whitelistedInstrumentsSet, setWhitelistedInstrumentsSet] = useState<Set<string>>(new Set());

    useEffect(() => {
        const { enabled, instruments } = instrumentsWhitelist;
        setIsWhitelistingOn(enabled);
        setWhitelistedInstrumentsSet(new Set(instruments));
    }, [instrumentsWhitelist]);

    return useMemo(() => {
        const withSuccessErrorHandlers = (promise: Promise<unknown>, successMsg: string) =>
            promise
                .then(() => showSuccessNotification(successMsg))
                .catch((err) => {
                    displayError(err);
                    mutate();
                    throw err;
                });

        const updateWhitelistedInstruments = () =>
            postViaWS("setInstrumentsWhitelist", { instruments: [...whitelistedInstrumentsSet] });

        return {
            isLoading,
            isWhitelistingOn,
            refetchWhitelist: () => mutate(),
            setWhitelistingStatus: (status: boolean) => {
                setIsWhitelistingOn(status);
                return withSuccessErrorHandlers(
                    updateWhitelistingStatus(status),
                    `Whitelisting has been turned ${status ? "ON" : "OFF"}`,
                );
            },
            isInstrumentInWhitelist: (instrumentName: string) => whitelistedInstrumentsSet.has(instrumentName),
            unwhitelistInstrument: (instrumentName: string) => {
                whitelistedInstrumentsSet.delete(instrumentName);
                setWhitelistedInstrumentsSet(new Set(whitelistedInstrumentsSet));

                return withSuccessErrorHandlers(
                    updateWhitelistedInstruments(),
                    `Instrument ${instrumentName} has been excluded from the whitelist`,
                );
            },
            whitelistInstrument: (instrumentName: string) => {
                whitelistedInstrumentsSet.add(instrumentName);
                setWhitelistedInstrumentsSet(new Set(whitelistedInstrumentsSet));

                return withSuccessErrorHandlers(
                    updateWhitelistedInstruments(),
                    `Instrument ${instrumentName} has been added into the whitelist`,
                );
            },
        };
    }, [isWhitelistingOn, whitelistedInstrumentsSet, mutate, showSuccessNotification]);
};
