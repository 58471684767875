import {
    ClientId,
    Flex,
    Header,
    HeaderTitle,
    IconButton,
    Icons,
    PBold,
    PSmallBold,
    ShadowItem,
    TextSmall,
    Tooltip,
    TooltipContainer,
} from "@fm-frontend/uikit";
import { EmDash } from "@fm-frontend/utils";
import { onMobile } from "const";
import React from "react";
import styled, { useTheme } from "styled-components";
import { toLongDateString } from "utils/format";
import { Subaccount, SubaccountStatus } from "../api";
import { SUBACCOUNT_TYPE_TITLES } from "../consts";
import { Status } from "../Status";
import { getLabelWithHighlightedSearch } from "../utils";

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    cursor: pointer;

    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }
`;

const HoverContainer = styled.div`
    width: 100%;
    :hover {
        background-color: ${(p) => p.theme.colors.ui4};
    }
`;

export const Body = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 12px 0;

    ${onMobile} {
        margin-top: 5px;
        margin-bottom: 2px;
    }
`;

export const Property = styled.div`
    display: flex;
    width: 50%;
    padding-right: 4px;

    ${TextSmall} {
        width: 88px;
        min-width: 88px;
    }

    ${TooltipContainer} {
        overflow: hidden;
    }

    ${PSmallBold} {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    ${onMobile} {
        width: 100%;

        ${TextSmall} {
            min-width: 110px;
        }
    }
`;

const DetailsButtonContainer = styled.div`
    position: absolute;
    right: -7px;
    top: 50%;
`;

export const SubaccountCard: React.VFC<{
    subaccount: Subaccount;
    searchQuery: string;
    onSelect: () => void;
}> = ({ subaccount, searchQuery, onSelect }) => {
    const { colors } = useTheme();

    return (
        <HoverContainer>
            <ShadowItem padding="16px" isUnderlined>
                <Container onClick={onSelect}>
                    <DetailsButtonContainer>
                        <IconButton variant="plain" Icon={Icons.Greater} />
                    </DetailsButtonContainer>
                    <Header>
                        <HeaderTitle
                            title={
                                <Flex spacing={4} alignItems="center" width="100%">
                                    <PBold>
                                        {getLabelWithHighlightedSearch(subaccount.info.username, searchQuery)}
                                    </PBold>
                                    {subaccount.status !== "pending" && (
                                        <ClientId
                                            id={getLabelWithHighlightedSearch(
                                                String(subaccount.info.clientId),
                                                searchQuery,
                                            )}
                                            tooltip="Client ID"
                                        />
                                    )}
                                </Flex>
                            }
                        />
                        <Status variant={subaccount.status} />
                    </Header>
                    <Body>
                        <Property>
                            <TextSmall color={colors.ui72}>Type</TextSmall>
                            <PSmallBold>
                                {getLabelWithHighlightedSearch(SUBACCOUNT_TYPE_TITLES[subaccount.type], searchQuery)}
                            </PSmallBold>
                        </Property>
                        {subaccount.status !== SubaccountStatus.Active && (
                            <Property>
                                <TextSmall color={colors.ui72}>Invited</TextSmall>
                                <PSmallBold>{toLongDateString(subaccount.createdAt)}</PSmallBold>
                            </Property>
                        )}
                        {subaccount.status === "active" && (
                            <Property>
                                <TextSmall color={colors.ui72}>Activated</TextSmall>
                                <PSmallBold>
                                    {subaccount.info.activatedAt
                                        ? toLongDateString(subaccount.info.activatedAt)
                                        : EmDash}
                                </PSmallBold>
                            </Property>
                        )}
                        <Property>
                            <TextSmall color={colors.ui72}>Email</TextSmall>

                            <Tooltip content={subaccount.info.email}>
                                <PSmallBold>
                                    {getLabelWithHighlightedSearch(subaccount.info.email, searchQuery)}
                                </PSmallBold>
                            </Tooltip>
                        </Property>
                        {subaccount.status === "active" && (
                            <Property>
                                <TextSmall color={colors.ui72}>Last trade</TextSmall>
                                <PSmallBold>
                                    {subaccount.info.lastTradeAt
                                        ? toLongDateString(subaccount.info.lastTradeAt)
                                        : EmDash}
                                </PSmallBold>
                            </Property>
                        )}
                    </Body>
                </Container>
            </ShadowItem>
        </HoverContainer>
    );
};
