import { P, PBold } from "@fm-frontend/uikit";
import { FC, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Notification } from "services/notificationsService";
import { markNotificationAsRead, useNotificationTransition } from "../hooks/useNotificationTransition";
import { ReactComponent as CheckIcon } from "./check.svg";
import { Container, ContentWrapper, Status, StatusWrapper, Timestamp } from "./styled";
import { getTimeAgoInfo } from "./utils";

// It's needed to guarantee that timeAgoInfo will be new
const CORRELATION_DELTA = 100;

type NotificationItemProps = {
    notification: Notification;
    closePopover: () => void;
};

export const NotificationItem: FC<NotificationItemProps> = ({ notification }) => {
    const { id, content, isRead, sentAt } = notification;

    const notificationTransition = useNotificationTransition(notification);

    const [, forceUpdate] = useState(0);
    const { label: timeAgoString, updateIn } = getTimeAgoInfo(sentAt);
    const timeoutId = useRef<NodeJS.Timeout | undefined>(undefined);

    useEffect(() => {
        clearTimeout(timeoutId.current);

        timeoutId.current = setTimeout(() => {
            forceUpdate((i) => i + 1);
        }, updateIn + CORRELATION_DELTA);
    }, [timeAgoString]);

    useEffect(() => {
        return () => clearTimeout(timeoutId.current);
    }, []);

    const Message = isRead ? P : PBold;

    const handleContentClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        notificationTransition();
    };

    return (
        <Container isRead={isRead}>
            <StatusWrapper>
                {!isRead && (
                    <Status onClick={() => markNotificationAsRead(id, isRead)}>
                        <CheckIcon style={{ opacity: 0 }} />
                    </Status>
                )}
            </StatusWrapper>
            <ContentWrapper onClick={handleContentClick}>
                <Message>{content} </Message>
                <Timestamp>{timeAgoString}</Timestamp>
            </ContentWrapper>
        </Container>
    );
};
