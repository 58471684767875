import { PSmall } from "@fm-frontend/uikit";
import { EmDash } from "@fm-frontend/utils";
import { AuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { FC } from "react";
import { Header } from "../Header";
import { Loading } from "../Loading";
import { useModalContext } from "../ModalContext";
import {
    Action,
    Container,
    Content,
    Item,
    ItemContent,
    ItemTitle,
    ItemWrapper,
    Title,
    WidgetForwardIcon,
} from "../styled";
import { MarkupsWidgetModal } from "./modal";
import { PercentageIcon } from "./styled";
import { useMarkupInfo } from "./useMarkupInfo";

const formatMarkup = (value: number | undefined, noCpLimit: boolean) => {
    if (noCpLimit) {
        return EmDash;
    }

    return value !== undefined ? `${value.toFixed(4)}%` : EmDash;
};

type MarkupProps = {
    instrument: string;
    authorizedClientId: AuthorizedClientId;
    noCpLimit: boolean;
};
export const Markup: FC<MarkupProps> = ({ instrument, authorizedClientId, noCpLimit }) => {
    const { openModal } = useModalContext();
    const { getMarkupInfo, isLoading } = useMarkupInfo();

    if (authorizedClientId === null || isLoading) {
        return <Loading />;
    }

    const { defaultMarkup, askMarkup, bidMarkup } = getMarkupInfo(instrument, authorizedClientId);

    const defaultMarkupFormatted = formatMarkup(defaultMarkup, noCpLimit);
    const askMarkupFormatted = formatMarkup(askMarkup, noCpLimit);
    const bidMarkupFormatted = formatMarkup(bidMarkup, noCpLimit);

    const isDefaultMarkupOverride = askMarkup !== undefined || bidMarkup !== undefined;
    const askMarkupDisabled = askMarkup === undefined;
    const bidMarkupDisabled = bidMarkup === undefined;

    return (
        <>
            <Container>
                <Header disabled={noCpLimit} onOpen={openModal}>
                    <Title>
                        <PercentageIcon />
                        {instrument} markup
                    </Title>
                    {!noCpLimit && (
                        <Action>
                            <WidgetForwardIcon />
                        </Action>
                    )}
                </Header>
                <Content noCpLimit={noCpLimit}>
                    <Item $override={isDefaultMarkupOverride}>
                        <ItemTitle>Default</ItemTitle>
                        <ItemContent>
                            <PSmall ellipsis>{defaultMarkupFormatted}</PSmall>
                        </ItemContent>
                    </Item>
                    {askMarkupDisabled && bidMarkupDisabled && (
                        <Item>
                            <ItemTitle>Per instrument</ItemTitle>
                            <ItemContent>
                                <PSmall>Off</PSmall>
                            </ItemContent>
                        </Item>
                    )}
                    {(!askMarkupDisabled || !bidMarkupDisabled) && (
                        <ItemWrapper>
                            <Item $disabled={bidMarkupDisabled}>
                                <ItemTitle>Instrument bid</ItemTitle>
                                <ItemContent>
                                    <PSmall ellipsis>{bidMarkupFormatted}</PSmall>
                                </ItemContent>
                            </Item>
                            <Item $disabled={askMarkupDisabled}>
                                <ItemTitle>Instrument ask</ItemTitle>
                                <ItemContent>
                                    <PSmall ellipsis>{askMarkupFormatted}</PSmall>
                                </ItemContent>
                            </Item>
                        </ItemWrapper>
                    )}
                </Content>
            </Container>

            <MarkupsWidgetModal
                authorizedClientId={authorizedClientId}
                instrumentName={instrument}
                askMarkup={askMarkup}
                bidMarkup={bidMarkup}
            />
        </>
    );
};
