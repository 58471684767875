import { Flex, HStack, PXSmall, SmallLoader } from "@fm-frontend/uikit";
import { QuoteSide } from "feature/rfq/apiHooks/useRfqWs";
import styled, { useTheme } from "styled-components";
import { QUOTE_CONTAINER_HEIGHT } from "../consts";

const ExecutingQuoteContainer = styled(Flex)<{ $side: QuoteSide }>`
    align-items: center;
    justify-content: center;
    flex: 1 0 ${QUOTE_CONTAINER_HEIGHT}px;
    border-bottom: 1px solid ${(p) => p.theme.colors.ui8};
    color: ${(p) => (p.$side === "BUY" ? p.theme.colors.positive100 : p.theme.colors.negative100)};
`;

const Content = styled(HStack)`
    gap: 4px;
    align-items: center;
`;

export const ExecutingQuote = ({ side }: { side: QuoteSide }) => {
    const { colors } = useTheme();
    const loaderColor = side === "BUY" ? colors.positive100 : colors.negative100;

    return (
        <ExecutingQuoteContainer $side={side}>
            <Content>
                <SmallLoader color={loaderColor} /> <PXSmall>Execution ...</PXSmall>
            </Content>
        </ExecutingQuoteContainer>
    );
};
