import { DiffDataType } from "@fm-frontend/utils";
import { FC, useMemo } from "react";
import styled from "styled-components";
import { PSmall } from "../../../typography";

const Value = styled(PSmall)<{ hasError: boolean }>`
    font-size: 10px;
    line-height: 12px;
    color: ${(p) => (p.hasError ? p.theme.colors.negative100 : p.theme.colors.ui52)};
`;

export const Container = styled.div<{ hasError: boolean }>`
    display: flex;
    justify-content: center;
    width: fit-content;
    max-width: 90px;

    padding-right: 12px;

    ::before {
        pointer-events: none;
        content: "";
        display: block;
        border-right: 1px solid
            ${(p) => (p.hasError ? p.theme.colors.negative100 : p.theme.colors.ui8)};
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
`;

type RowNumberCellProps = {
    diff: DiffDataType;
    value: number;
};
export const RowNumberCell: FC<RowNumberCellProps> = ({ diff, value }) => {
    const hasError = useMemo(
        () => Object.values(diff).some((cell) => cell.errors.length > 0),
        [diff],
    );

    return (
        <Container hasError={hasError}>
            <Value hasError={hasError}>{value}</Value>
        </Container>
    );
};
