import { css } from "styled-components";

export const circleContainerCss = css<{ $size: number }>`
    height: ${(p) => p.$size}px;
    width: ${(p) => p.$size}px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: ${(p) => p.$size / 2}px;
    border: 1px solid ${(p) => p.theme.colors.ui32};
`;
