import { SIZE_VALUE_MULTIPLICATOR } from "@fm-frontend/utils";
import { isNotEnoughLiquidity } from "../../utils";

const DEFAULT_SPREAD = {
    spread: null,
    spreadInPercentage: null,
};

export const calculateSpread = ({
    bidPrice,
    askPrice,
}: {
    bidPrice: bigint | null;
    askPrice: bigint | null;
}) => {
    if (
        isNotEnoughLiquidity(bidPrice) ||
        bidPrice === null ||
        isNotEnoughLiquidity(askPrice) ||
        askPrice === null
    ) {
        return {
            ...DEFAULT_SPREAD,
        };
    }

    const midpoint = (askPrice + bidPrice) / 2n;

    if (midpoint === 0n) {
        return { ...DEFAULT_SPREAD };
    }

    const spread = askPrice - bidPrice;
    const spreadInPercentage =
        (Number((spread * BigInt(SIZE_VALUE_MULTIPLICATOR)) / midpoint) /
            SIZE_VALUE_MULTIPLICATOR) *
        100;

    return {
        spread,
        spreadInPercentage,
    };
};
