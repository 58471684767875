import {
    Form,
    FormActions,
    FormBody,
    HStack,
    Modal,
    PlainButton,
    PrimaryButton,
    ShadowCard,
    VStack,
} from "@fm-frontend/uikit";
import { yupResolver } from "@hookform/resolvers/yup";
import { post } from "api";
import { useSaveSuccessNotification } from "feature/addresses/hooks";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { setConfirmModalContent } from "feature/app";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { isFiat } from "utils";
import { ValidationMessages } from "utils/validationMessages";
import { AnySchema, object, string } from "yup";
import { AutoResizableTextArea } from "../../../AutoResizableTextArea";
import { Settlement } from "../../../types";
import { ModalHeader } from "../components/ModalHeader";
import { SettlementInfo } from "../components/SettlementInfo";
import { Error } from "../styled";
import { AddressHints } from "./AddressHints";

type OutgoingCreatedTransactionModalProps = {
    closeModal: () => void;
    settlement: Settlement;
};

const Label = styled.label`
    ${(p) => p.theme.mixins.pSmall}
`;

type Inputs = {
    txId: string;
};

const StyledForm = styled(Form)`
    max-width: 320px;
    min-width: 320px;
`;

const validationSchema = object<Record<keyof Inputs, AnySchema>>().shape({
    txId: string().trim().required(ValidationMessages.REQUIRED),
});

export const OutgoingCreatedTransactionModal = ({ closeModal, settlement }: OutgoingCreatedTransactionModalProps) => {
    const showSuccessNotification = useSaveSuccessNotification();
    const [apiError, setApiError] = useState<string | null>(null);

    const {
        formState: { isSubmitting, errors, isDirty },
        register,
        handleSubmit,
    } = useForm<Inputs>({
        defaultValues: {},
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
    });
    const { closeModalWithConfirm } = useModalCloseWithConfirm(isDirty, closeModal);

    const [isDeleting, setIsDeleting] = useState(false);
    const dispatch = useDispatch();
    const handleTransactionDelete = async () => {
        dispatch(
            setConfirmModalContent({
                title: "Delete transaction?",
                onConfirm: async () => {
                    try {
                        setApiError(null);
                        setIsDeleting(true);
                        await post("delOutgoingSettlementTransaction", {
                            transactionId: settlement.id,
                        });

                        showSuccessNotification("Transaction was deleted");
                        closeModal();
                    } catch (e) {
                        setApiError(String(e));
                    } finally {
                        setIsDeleting(false);
                    }
                },
                description: "",
                confirmButtonTitle: "Delete",
            }),
        );
    };

    const onSubmit = async ({ txId }: Inputs) => {
        try {
            setApiError(null);
            await post("sendOutgoingSettlementTransaction", {
                transactionId: settlement.id,
                txId,
            });
            showSuccessNotification("Transaction was sent");
            closeModal();
        } catch (err) {
            setApiError(err as string);
        }
    };

    let txIdLabel = "Enter TX ID to commit the transaction";
    let txIdPlaceholder = "sa000...mpl3e";
    if (isFiat(settlement.asset)) {
        txIdLabel = "Enter bank reference to commit the transaction";
        txIdPlaceholder = "Settlement as per MPSA No.";
    }

    return (
        <Modal isOpen onClose={closeModalWithConfirm}>
            <HStack>
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                    <ShadowCard>
                        <ModalHeader settlement={settlement} />
                        <FormBody spacing={8}>
                            <SettlementInfo
                                settlement={settlement}
                                onDeleteSettlement={handleTransactionDelete}
                                isDeleteButtonLoading={isDeleting}
                            />
                            <VStack spacing={4} width="100%">
                                <Label htmlFor="tx-id">{txIdLabel}</Label>
                                <AutoResizableTextArea
                                    id="tx-id"
                                    rows={1}
                                    placeholder={txIdPlaceholder}
                                    disabled={isSubmitting}
                                    autoFocus
                                    {...register("txId")}
                                    error={errors.txId?.message}
                                />
                                {apiError && <Error>{apiError}</Error>}
                            </VStack>
                        </FormBody>
                        <FormActions variant="plain">
                            <PrimaryButton type="submit" fullWidth size="large" loading={isSubmitting}>
                                Send transaction
                            </PrimaryButton>
                            <PlainButton type="button" fullWidth size="large" onClick={closeModal}>
                                Skip for now
                            </PlainButton>
                        </FormActions>
                    </ShadowCard>
                </StyledForm>
                <AddressHints settlement={settlement} />
            </HStack>
        </Modal>
    );
};
