import { useMemo } from "react";
import { useInstruments } from "hooks";

export const useCurrencies = () => {
    const { currencies } = useInstruments();

    return useMemo(
        () =>
            Object.values(currencies)
                .map((asset) => asset.name)
                .sort((a, b) => a.localeCompare(b))
                .map((value) => ({ text: value, value })),
        [currencies],
    );
};
