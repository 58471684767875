import { useGA4Context } from "@fm-frontend/utils";
import { LoginFlow } from "feature/auth/loginFlow";
import { ResetPasswordFlow } from "feature/auth/ResetPassword/ResetPasswordRequest";
import { SignUpFlow } from "feature/auth/signUpFlow";
import { TermsAndConditions } from "pages/termsAndConditions/TermsAndConditions";
import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router";
import { ROUTES } from "./routes";

export const GuestRouter = () => {
    const { logPageView } = useGA4Context();
    useEffect(() => logPageView(), [logPageView]);

    return (
        <Switch>
            <Route path="/login" component={LoginFlow} />
            <Route path="/signup" component={SignUpFlow} />
            <Route path="/2fa" component={LoginFlow} />
            <Route path="/resetPassword/:toemail?" component={ResetPasswordFlow} />
            <Route path={`${ROUTES.termsAndConditions}/:token?`} component={TermsAndConditions} />
            <Redirect to="/login" />
        </Switch>
    );
};
