import { useMemo } from "react";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType } from "./hooks";

export const DEAL_HISTORY_API_METHOD = "dealHistory";

export const useDealHistoryParams = (limit?: number) => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const side = isPrimeBrokerUser ? primeBrokerViewType : "counterparties";

    return useMemo(
        () => ({
            limit,
            filter: side === "counterparties" ? "external" : "subaccounts",
        }),
        [side, limit],
    );
};
