import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { PREFERRED_CURRENCIES } from "const";
import { useInstruments } from "hooks";
import { useMemo } from "react";
import { CurrencyOptionEssence } from "../CurrencyOptionEssence";
import { CurrencyTriggerEssence } from "../CurrencyTriggerEssence";
import { MultipleDropdownGroupedSheet } from "./MultipleDropdownGroupedSheet";

export const AssetsMultipleGroupedDropdown = ({
    values = [],
    onChange,
    error,
    caption = "Assets",
}: {
    values: string[];
    onChange: (values: string[]) => void;
    error?: string;
    caption?: string;
}) => {
    const { currencies } = useInstruments();
    const groupOptions = useMemo(
        () => [
            {
                text: "All crypto",
                isOptionInGroup: (optionValue: string) =>
                    currencies[optionValue].currencyType === "crypto",
            },
            {
                text: "All stablecoins",
                isOptionInGroup: (optionValue: string) =>
                    currencies[optionValue].currencyType === "stablecoin",
            },
            {
                text: "All fiat",
                isOptionInGroup: (optionValue: string) =>
                    currencies[optionValue].currencyType === "fiat",
            },
        ],
        [currencies],
    );
    const currenciesOptions = useMemo(() => {
        const { prefferedOptions, otherOptions } = Object.keys(currencies).reduce<{
            prefferedOptions: DropdownOption[];
            otherOptions: DropdownOption[];
        }>(
            (acc, assetName) => {
                acc[
                    PREFERRED_CURRENCIES.includes(assetName) ? "prefferedOptions" : "otherOptions"
                ].push({
                    text: assetName,
                    value: assetName,
                });

                return acc;
            },
            {
                prefferedOptions: [],
                otherOptions: [],
            },
        );

        return [prefferedOptions, otherOptions];
    }, [currencies]);

    return (
        <MultipleDropdown
            values={values}
            onChange={onChange}
            renderTrigger={(trigger) => (
                <MultipleDropdown.Trigger {...trigger} size="large" variant="simple">
                    <CurrencyTriggerEssence
                        {...trigger}
                        option={trigger.selectedOptions}
                        size="large"
                    />
                </MultipleDropdown.Trigger>
            )}
            options={currenciesOptions.flat()}
            caption={caption}
            placeholder="Select"
            align="end"
            fullWidth
            error={error}
        >
            <MultipleDropdownGroupedSheet
                values={values}
                onChange={onChange}
                Dropdown={MultipleDropdown}
                allOptionText="All assets"
                groupOptions={groupOptions}
                options={currenciesOptions}
                OptionEssence={CurrencyOptionEssence}
                size="large"
            />
        </MultipleDropdown>
    );
};
