import { Accordion } from "@fm-frontend/uikit";
import { useOpenConfirmationModal } from "hooks";
import { useHistory } from "react-router";
import styled from "styled-components";

type Props = {
    header: JSX.Element | string;
    content: JSX.Element | string;
    defaultExpanded?: boolean;
    onExpandedChange?: (expanded: boolean) => void;
    moveToDetails?: {
        detailsPageUrl: string;
        fromSectionTitle: string;
        toSectionTitle: string;
        buttonTitle?: string;
    };
};

const ConfirmButton = styled.span`
    color: ${(p) => p.theme.colors.brand100};
`;

export const AccordionWithConfirmation = ({
    header,
    content,
    defaultExpanded,
    onExpandedChange,
    moveToDetails,
}: Props) => {
    const history = useHistory();
    const openConfirmationModal = useOpenConfirmationModal();

    if (moveToDetails === undefined) {
        return (
            <Accordion
                header={header}
                content={content}
                defaultExpanded={defaultExpanded}
                onExpandedChange={onExpandedChange}
            />
        );
    }

    const { detailsPageUrl, fromSectionTitle, toSectionTitle, buttonTitle } = moveToDetails;

    return (
        <Accordion
            header={header}
            content={content}
            defaultExpanded={defaultExpanded}
            onExpandedChange={onExpandedChange}
            moveToDetailsButtonTitle={buttonTitle}
            onMoveToDetailsButtonClick={(ev) => {
                ev.stopPropagation();
                openConfirmationModal({
                    title: `Leave ${fromSectionTitle}?`,
                    description: (
                        <>
                            You’re about to navigate to the <strong>{toSectionTitle}</strong>. This
                            will take you away from the trading page. Do you want to continue?
                        </>
                    ),
                    confirmButtonTitle: <ConfirmButton>Yes, continue</ConfirmButton>,
                    cancelButtonTitle: "Cancel",
                    onConfirm: () => {
                        history.push(detailsPageUrl);
                    },
                });
            }}
        />
    );
};
