import React, { useMemo } from "react";
import { Level } from "types";
import { kFeedNotAvailable, kInstrumentForbidden } from "utils/ErrorCodes";
import { EmptyTradingBook } from "./EmptyTradingBook";
import { InstrumentIsNotWhitelisted } from "./InstrumentIsNotWhitelisted";
import { NoInstrument } from "./NoInstrument";
import { SomeError } from "./SomeError";

type UseMarketDepthRestrictionStateProps = {
    errorCode: number | undefined;
    selectedInstrument?: string;
    bidLevels: Level[];
    askLevels: Level[];
};
export const useMarketDepthRestrictionState = ({
    errorCode,
    selectedInstrument,
    bidLevels,
    askLevels,
}: UseMarketDepthRestrictionStateProps) => {
    return useMemo((): React.ReactNode => {
        if (errorCode === kFeedNotAvailable || errorCode === kInstrumentForbidden) {
            return <InstrumentIsNotWhitelisted />;
        }
        if (errorCode !== undefined) {
            return <SomeError />;
        }
        if (!selectedInstrument) {
            return <NoInstrument />;
        }
        if (bidLevels.length === 0 && askLevels.length === 0) {
            return <EmptyTradingBook selectedInstrument={selectedInstrument} />;
        }

        return null;
    }, [bidLevels, askLevels, errorCode, selectedInstrument]);
};
