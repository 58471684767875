import { createContext, useCallback, useContext } from "react";
import {
    useIsMakerUser,
    useIsPrimeBrokerUser,
    useIsSubaccountUser,
    useIsTakerUser,
    usePrimeBrokerViewType,
} from "store/hooks";
import { BrokerViewType } from "types";

export interface TUserTypeTextAdoption {
    default: string;
    primeBroker?: Partial<Record<BrokerViewType, string>>;
    maker?: string;
    taker?: string;
    subAccount?: string;
}

export type TUserTextAdoptionDictionary = Record<string, TUserTypeTextAdoption>;
export type UseTextAdaptor = (key: string) => string | undefined;

export const TextAdaptorContext = createContext<TUserTextAdoptionDictionary | undefined>({});

export const useTextAdaptorContext = () => {
    const context = useContext(TextAdaptorContext);
    if (!context) {
        throw new Error("useUserTypeTextAdaptorContext must be used within a TextAdaptorContext");
    }
    return context;
};

export const useTextAdaptor = (): UseTextAdaptor => {
    const dictionary = useTextAdaptorContext();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const isSubaccountUser = useIsSubaccountUser();
    const isMakerUser = useIsMakerUser();
    const isTakerUser = useIsTakerUser();

    return useCallback(
        (key: string) => {
            const options = dictionary[key];

            if (!options) {
                return undefined;
            }

            if (isPrimeBrokerUser) {
                return options.primeBroker?.[primeBrokerViewType] ?? options.default;
            }
            if (isSubaccountUser) {
                return options.subAccount ?? options.default;
            }
            if (isTakerUser) {
                return options.taker ?? options.default;
            }
            if (isMakerUser) {
                return options.maker ?? options.default;
            }

            return options.default;
        },
        [primeBrokerViewType, isPrimeBrokerUser, isSubaccountUser, isTakerUser, isMakerUser, dictionary],
    );
};
