import {
    Form,
    FormActions,
    FormBody,
    Header,
    HeaderTitle,
    PlainButton,
    PrimaryButton,
    ShadowCard,
} from "@fm-frontend/uikit";
import { SwitchField } from "@fm-frontend/uikit/src/components/common/SwitchField";
import { DropdownOption, SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { DEFAULT_FILTER, FilterType } from "../../../../store/onboardingSlice";
import { AssetsDropdown } from "../AssetsDropdown";
import { BanksDropdown } from "../BanksDropdown";

interface Props {
    defaultFilter: FilterType;
    currencies: Record<string, DropdownOption[]>;
    banks: DropdownOption[];
    jurisdictions: DropdownOption[];
    onApply: (filter: FilterType) => void;
}

export const FilterModal: React.VFC<Props> = ({ defaultFilter, jurisdictions, banks, currencies, onApply }) => {
    const { control, reset, handleSubmit } = useForm<FilterType>({
        defaultValues: {
            ...defaultFilter,
        },
        mode: "onChange",
    });

    return (
        <Form onSubmit={handleSubmit(onApply)}>
            <ShadowCard>
                <Header>
                    <HeaderTitle title="Filters" />
                </Header>
                <FormBody>
                    <AssetsDropdown
                        variant="basic"
                        fullWidth
                        triggerSize="large"
                        control={control}
                        name="assets"
                        groupedOptions={currencies}
                        placeholder="Select assets"
                    />
                    <BanksDropdown
                        variant="basic"
                        triggerSize="large"
                        control={control}
                        name="banks"
                        options={banks}
                        placeholder="Select banks"
                        fullWidth
                    />
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <SingleDropdown
                                value={field.value}
                                onChange={field.onChange}
                                renderTrigger={(trigger) => (
                                    <SingleDropdown.Trigger {...trigger} size="large" variant="basic">
                                        <SingleDropdown.TriggerEssence
                                            {...trigger}
                                            option={trigger.selectedOption}
                                            size="large"
                                        />
                                    </SingleDropdown.Trigger>
                                )}
                                options={jurisdictions}
                                caption="LP jurisdiction"
                                placeholder="Select LP jurisdictions"
                                asFilter
                                fullWidth
                            >
                                <SingleDropdown.BasicSheet size="large" options={jurisdictions} />
                            </SingleDropdown>
                        )}
                        name="jurisdiction"
                    />
                    <SwitchField
                        variant="basic"
                        fullWidth
                        name="regulation"
                        text="Liquidity provider should be regulated?"
                        label="LP Regulation"
                        control={control}
                    />
                </FormBody>
                <FormActions variant="plain">
                    <PrimaryButton fullWidth size="large" type="submit">
                        Apply filters
                    </PrimaryButton>
                    <PlainButton fullWidth size="large" type="button" onClick={() => reset({ ...DEFAULT_FILTER })}>
                        Clear all
                    </PlainButton>
                </FormActions>
            </ShadowCard>
        </Form>
    );
};
