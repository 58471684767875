import { useCallback, useState } from "react";

interface InitValues {
    error: string | null;
    isLoading: boolean;
}

export const useFormHelpers = (defaultValues?: InitValues) => {
    const [error, setError] = useState<string | null>(defaultValues ? defaultValues.error : null);
    const [isLoading, setLoading] = useState(defaultValues ? defaultValues.isLoading : false);

    return {
        error,
        setError,
        isLoading,
        setLoading,
        startLoading: useCallback(() => setLoading(true), [setLoading]),
        stopLoading: useCallback(() => setLoading(false), [setLoading]),
    };
};
