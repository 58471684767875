import { BasicButton, Box, Center, Icons, Mark, Modal, P, PBold, ShadowItem, Skeleton } from "@fm-frontend/uikit";
import { openUrl, useFormShouldCloseConfirm } from "@fm-frontend/utils";
import { useSelector } from "hooks";
import { useFaqLinks } from "hooks/useFaqLinks";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled, { useTheme } from "styled-components";
import { fetchStatusKYB } from "store/onboardingActions";
import { StatusKYBVariant, useIsOnboardingRequired } from "store/onboardingSlice";
import { EmailType, MakerInfo } from "types";
import { LimitStatus } from "../LimitStatus";
import { OnboardingLimitsModal } from "../onboardingLimitsModal/OnboardingLimitsModal";
import { StepItem } from "../StepItem";
import { useOnboardingLimits } from "../utils";
import { ActionContainer, ContactProvider, CoverContainer, LimitStatusContainer } from "./styled";
import { handleContactProvider, SET_LIMIT_MODAL_KEY } from "./utils";

export const Info = styled(P)`
    max-width: 365px;
    text-align: center;
`;

const StepSkeletons = () => (
    <>
        {[...Array(3).keys()].map((key) => (
            <Skeleton key={key} height="120px" />
        ))}
    </>
);

export const Verification: React.VFC<{
    statusKYB: StatusKYBVariant;
    onCancelVerification: () => void;
    isCancelLoading: boolean;
    makerInfo: MakerInfo;
    makerEmails: EmailType[];
}> = ({ statusKYB, onCancelVerification, isCancelLoading, makerInfo, makerEmails }) => {
    const faqLinks = useFaqLinks();
    const dispatch = useDispatch();
    const { colors } = useTheme();
    const { stepsKYB, isStepsLoading } = useSelector((state) => state.onboarding);
    const isOnboardingRequired = useIsOnboardingRequired();

    const [isModalActive, setIsModalActive] = useState(false);

    const { cpLimits } = useOnboardingLimits([makerInfo.id]);
    const cpLimit = cpLimits?.[makerInfo.id];

    const handleModalClose = () => {
        setIsModalActive(false);
        dispatch(fetchStatusKYB([makerInfo.id]));
    };

    const shouldCloseConfirm = useFormShouldCloseConfirm(SET_LIMIT_MODAL_KEY);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(shouldCloseConfirm, handleModalClose);

    return (
        <>
            <ShadowItem padding="0 16px 16px" isUnderlined>
                <CoverContainer isBackgroundActive={statusKYB !== StatusKYBVariant.Finished}>
                    <Box>
                        <Center>
                            {statusKYB === StatusKYBVariant.Finished ? (
                                <P color={colors.ui52}>
                                    <Mark color={colors.ui100} weight={500}>
                                        Verification completed.
                                    </Mark>{" "}
                                    Passed steps are listed below
                                </P>
                            ) : (
                                <P color={colors.ui52}>The system sent a request to the provider. Follow next steps:</P>
                            )}
                        </Center>
                    </Box>
                    {isStepsLoading ? (
                        <StepSkeletons />
                    ) : (
                        <>
                            {stepsKYB?.map(({ id, description, title, actions }, index) => (
                                <StepItem
                                    key={`step-${id}`}
                                    stepNumber={index + 1}
                                    title={title}
                                    description={description}
                                >
                                    {actions?.map(({ id: actionId, title: actionTitle, url }) => (
                                        <BasicButton
                                            key={`action-${actionId}`}
                                            size="small"
                                            onClick={() => openUrl(url, url.includes("mailto:") ? "_self" : "_blank")}
                                        >
                                            {actionTitle}
                                        </BasicButton>
                                    ))}
                                </StepItem>
                            ))}
                            <StepItem
                                stepNumber={stepsKYB?.length ? stepsKYB.length + 1 : 1}
                                title="Risk management."
                                description="Set limits on the liquidity provider."
                            >
                                {statusKYB !== StatusKYBVariant.Finished && (
                                    <>
                                        <BasicButton size="small" onClick={() => setIsModalActive(true)}>
                                            Set limits
                                        </BasicButton>
                                        <LimitStatusContainer>
                                            <LimitStatus statusKYB={statusKYB} isCpLimitSet={Boolean(cpLimit)} />
                                        </LimitStatusContainer>
                                    </>
                                )}
                            </StepItem>
                        </>
                    )}
                    <Info color={colors.ui52}>
                        {isOnboardingRequired
                            ? "When the provider sets limits on you in response system will unlock the product."
                            : statusKYB !== StatusKYBVariant.Finished
                            ? "When the provider sets limits on you in response — you can trade with the new counterparty."
                            : null}
                    </Info>
                </CoverContainer>
            </ShadowItem>
            <ShadowItem padding="12px 16px 8px">
                <ActionContainer>
                    <ContactProvider href={handleContactProvider(makerEmails)}>
                        <PBold>Contact provider</PBold>
                    </ContactProvider>
                    {statusKYB !== StatusKYBVariant.Finished && (
                        <BasicButton size="medium" onClick={onCancelVerification} loading={isCancelLoading}>
                            <Icons.Stop />
                            Cancel verification
                        </BasicButton>
                    )}
                </ActionContainer>
            </ShadowItem>
            <Modal
                isOpen={isModalActive}
                onClose={closeModalWithConfirm}
                additionalActions={{ helpButtonUrlOrClickHandler: faqLinks?.faqRiskManagement }}
            >
                <OnboardingLimitsModal
                    makerInfo={makerInfo}
                    onApply={handleModalClose}
                    onClose={closeModalWithConfirm}
                    cpLimit={cpLimit}
                />
            </Modal>
        </>
    );
};
