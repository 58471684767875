import { PXSmallBold } from "@fm-frontend/uikit";
import { FC } from "react";
import styled from "styled-components";

const Title = styled(PXSmallBold)`
    color: ${(p) => p.theme.colors.ui52};
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 8px;

    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.colors.ui12};
`;

type EmptyStateProps = {
    title: string;
};

export const EmptyState: FC<EmptyStateProps> = ({ title }) => {
    return (
        <Container>
            <Title>{title}</Title>
        </Container>
    );
};
