import { getEnv } from "@fm-frontend/utils";
import { CLIENT_TYPE_TITLES } from "const";
import { POSITION_COUNTERPARTY_ID } from "const/position";
import { TRADING_LIMITS_TAB } from "feature/counterparties/counterpartyLimitModal/utils";
import { RiskManagementModal } from "feature/counterparties/modal";
import { AuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { useAuthorizedClientInfo } from "feature/trade/hooks/useAuthorizedClientInfo";
import { useCounterpartiesStatuses } from "pages/riskManagement/hooks/useCounterpartiesStatuses";
import { FC } from "react";
import { useFilteredAndExtendedCounterpartyLimitsSnapshot } from "store/hooks";
import { useModalContext } from "../../ModalContext";

const { HRP_MASTER_ID } = getEnv();

type CpLimitWidgetModalProps = {
    authorizedClientId: AuthorizedClientId;
};

export const CpLimitWidgetModal: FC<CpLimitWidgetModalProps> = ({ authorizedClientId }) => {
    const { isModalOpen, closeModal } = useModalContext();
    const authorizedClientName = useAuthorizedClientInfo(authorizedClientId)?.username ?? "";
    const { data: counterpartyLimits } = useFilteredAndExtendedCounterpartyLimitsSnapshot();
    const extendedLimit = counterpartyLimits.find(
        (cp) => cp.limit[POSITION_COUNTERPARTY_ID] === authorizedClientId,
    );
    const { data: cpStatuses } = useCounterpartiesStatuses();

    if (!isModalOpen || !authorizedClientId) {
        return null;
    }

    const { cpType: limitCpType } = extendedLimit ?? {};
    const cpStatus = cpStatuses[String(authorizedClientId)]?.status;

    const type =
        authorizedClientId === HRP_MASTER_ID
            ? "Clearing broker"
            : limitCpType === undefined
            ? "Sub-account"
            : CLIENT_TYPE_TITLES[limitCpType];

    return (
        <RiskManagementModal
            extendedLimit={extendedLimit}
            type={type}
            counterpartyId={authorizedClientId}
            status={cpStatus}
            isOpened={isModalOpen}
            onClose={closeModal}
            cpTitle={authorizedClientName}
            defaultTab={TRADING_LIMITS_TAB}
            noCpHint
        />
    );
};
