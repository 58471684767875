import { useEffect } from "react";
import { parse, stringify } from "../../utils/json";

export const pushPreloadedValues = (targetKey: string, values: object) => {
    sessionStorage.setItem(targetKey, stringify(values));
};

export const useInitialValues = (
    key: string,
    setValues: (value: React.SetStateAction<Record<string, unknown>>) => void,
) => {
    useEffect(() => {
        const preloadedFormValues = sessionStorage.getItem(key);
        if (preloadedFormValues) {
            setValues(parse(preloadedFormValues));
            sessionStorage.removeItem(key);
        }
    }, [key]);
};
