import { useMemo } from "react";
import { PREFERRED_CURRENCIES } from "const";
import { useCurrencies } from "./useCurrencies";

export const useCurrenciesWithPreferred = () => {
    const currencies = useCurrencies();

    return useMemo(
        () => PREFERRED_CURRENCIES.reduceRight((acc, preferredCurrency) => {
            const index = acc.findIndex((item) => item.value === preferredCurrency);

            if (index !== -1) {
                const [item] = acc.splice(index, 1);
                acc.unshift(item);
            }

            return acc;
        }, currencies),
        [currencies],
    );
};
