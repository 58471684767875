import { H3Bold, Icons, PBold, PSmallBold } from "@fm-frontend/uikit";
import { onMobile } from "const";
import { useSelector } from "hooks";
import React from "react";
import styled, { DefaultTheme, StyledComponent } from "styled-components";

const CommonContainer = styled.div`
    position: relative;
    display: flex;
    gap: 10px;
    padding: 6px 8px;
    align-items: center;

    border-radius: 20px;

    cursor: default;

    ${onMobile} {
        gap: 4px;
        padding: 2px 6px;
        text-transform: uppercase;

        svg path {
            fill: ${(p) => p.theme.colors.ui100};
        }
    }
`;

const OrderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;
    border-radius: 50%;

    background-color: ${(p) => p.theme.colors.uiWhite100};

    border: 2px solid ${(p) => p.theme.colors.ui8};
    box-shadow: 0 4px 6px ${(p) => p.theme.colors.ui4};
`;

const DoneContainer = styled(CommonContainer)`
    ${OrderContainer} {
        border: none;
        background-color: ${(p) => p.theme.colors.brand100};
    }
`;

const ActiveContainer = styled(CommonContainer)`
    background-color: ${(p) => p.theme.colors.brand12};

    ${OrderContainer} {
        border: 2px solid ${(p) => p.theme.colors.brand100};
        box-shadow: 0 4px 6px ${(p) => p.theme.colors.brand4};
        color: ${(p) => p.theme.colors.brand100};
    }

    ${H3Bold}, ${PBold} {
        color: ${(p) => p.theme.colors.brand100};
    }
`;

const WaitingContainer = styled(CommonContainer)`
    ${OrderContainer} {
        border: 1px solid ${(p) => p.theme.colors.ui8};
        box-shadow: 0 4px 6px ${(p) => p.theme.colors.ui4};
    }
`;

type StepStatus = "done" | "active" | "waiting";

const ContainerVariantComponents: {
    [Key in StepStatus]: StyledComponent<"div", DefaultTheme, {}, never>;
} = {
    done: DoneContainer,
    active: ActiveContainer,
    waiting: WaitingContainer,
};

export const MenuStep: React.FC<{ order: number; title: string; status: StepStatus }> = (props) => {
    const { order, title, status } = props;
    const isMobile = useSelector((state) => state.app.isMobile);

    const orderContent =
        status === "done" ? (
            <Icons.Checkmark />
        ) : isMobile ? (
            <H3Bold>{order}</H3Bold>
        ) : (
            <PSmallBold>{order}</PSmallBold>
        );
    const titleElement = isMobile ? <H3Bold>{title}</H3Bold> : <PBold>{title}</PBold>;
    const orderElement = isMobile ? orderContent : <OrderContainer>{orderContent}</OrderContainer>;
    const Container = ContainerVariantComponents[status];

    return (
        <Container>
            {orderElement}
            {titleElement}
        </Container>
    );
};
