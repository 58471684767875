import styled from "styled-components";
import { circleContainerCss } from "./WidgetButton/styled";

const LpIconContainer = styled.div<{ $size: number }>`
    ${circleContainerCss}
    background: linear-gradient(
            0deg,
            ${(p) => p.theme.colors.ui8} 0%,
            ${(p) => p.theme.colors.ui8} 100%
        ),
        ${(p) => p.theme.colors.uiWhite100};
    box-shadow: 0px -2px 5px 0px ${(p) => p.theme.colors.ui4} inset;

    height: ${(p) => p.$size}px;
    width: ${(p) => p.$size}px;
    border-radius: ${(p) => p.$size / 2}px;

    overflow: hidden;
`;

const PersonIcon = () => (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.2767 9.14325L3.27671 9.14326L3.2767 9.14325ZM3.54765 9.56347C3.54803 9.56322 3.54841 9.56298 3.54879 9.56274C4.48561 8.95891 5.64249 8.5 7 8.5C8.8408 8.5 10.3156 9.34517 11.3662 10.2483C11.5861 10.4373 11.698 10.5363 11.7757 10.637C11.837 10.7164 11.8833 10.8061 11.9164 10.9796C11.9278 11.0394 11.9231 11.1857 11.8736 11.3718C11.8242 11.5578 11.7556 11.6872 11.716 11.7334C11.5264 11.9548 11.389 11.9959 10.7429 12.1205C9.67068 12.3271 8.36453 12.5 7 12.5C6.10333 12.5 5.23272 12.4188 4.43591 12.3007C3.6468 12.1838 3.09271 12.1011 2.69084 12C2.28177 11.8971 2.1343 11.7978 2.06949 11.7204C2.00762 11.6465 1.93908 11.5065 1.90054 11.3282C1.86201 11.1498 1.86661 10.994 1.89245 10.9012C1.92287 10.7919 2.00806 10.6524 2.28285 10.4278C2.56088 10.2006 2.96311 9.94038 3.54765 9.56347Z"
            stroke="#243034"
            stroke-opacity="0.32"
        />
        <path
            d="M9.5 3.75C9.5 5.30722 8.34378 6.5 7 6.5C5.65622 6.5 4.5 5.30722 4.5 3.75C4.5 2.19278 5.65622 1 7 1C8.34378 1 9.5 2.19278 9.5 3.75Z"
            stroke="#243034"
            stroke-opacity="0.32"
        />
    </svg>
);

export const LpDefaultIcon = ({ size }: { size: number }) => (
    <LpIconContainer $size={size}>
        <PersonIcon />
    </LpIconContainer>
);
