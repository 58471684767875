import { PermissionsIcon } from "../PermissionsIcon";
import { Pills } from "../Pills";
import { Email, Name, TextButton, UserCard } from "../styled";
import { Actions, Hint } from "./styled";
import type { UserProps } from "./types";

export const User = ({ name, email, permissions, isActive, editUser }: UserProps) => {
    const userInfo = (
        <div>
            <Name>
                {name} {!isActive && <Hint>pending</Hint>}
            </Name>
            <Email>{email}</Email>
        </div>
    );

    const handleUserEdit = () =>
        editUser({
            name,
            email,
            permissions,
        });

    return (
        <UserCard>
            <PermissionsIcon permissions={permissions} isActive={isActive} />
            {userInfo}
            <Pills permissions={permissions} isActive={isActive} />
            <Actions>
                <TextButton onClick={handleUserEdit}>Edit</TextButton>
            </Actions>
        </UserCard>
    );
};
