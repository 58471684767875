import React, { useMemo } from "react";
import { useIsMakerUser } from "store/hooks";
import styled from "styled-components";
import { ClientType, CounterpartyLimit } from "types";
import {
    CounterpartyStatusType,
    ONE_HUNDRED_PERCENT,
    parseLimit,
    PERCENT_SHIFT,
    shouldUseUserGrossLimit,
} from "../../utils";
import { getMakerMarginLinesIndicator } from "../markerRiskOverview/utils";
import * as Styled from "./styled";

const Background = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 2px;
    overflow: hidden;
    border-radius: 2px;
    width: 100%;
    height: 4px;
    opacity: 0.16;
`;

const Equity = styled.div<{ $percent: number }>`
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    overflow: hidden;
    border-radius: 4px;
    width: 100%;
    height: 8px;
    clip-path: inset(0 calc(100% - ${(p) => p.$percent}%) 0 0);
`;

const Border = styled.div<{ $percent: number }>`
    position: absolute;
    top: -10px;
    left: calc(${(p) => p.$percent}% - 1px);
    width: 1px;
    height: 28px;
    background: linear-gradient(
        180deg,
        rgba(36, 48, 52, 0) 0%,
        rgba(36, 48, 52, 0.32) 49.45%,
        rgba(36, 48, 52, 0) 100%
    );
`;

type MakerMarginLinesIndicatorProps = {
    limit: CounterpartyLimit;
    cpType: ClientType;
};

export const MakerMarginLinesIndicator: React.VFC<MakerMarginLinesIndicatorProps> = (props) => {
    const { limit, cpType } = props;
    const { grossLimit, equity, mutualGrossLimit } = parseLimit(limit);

    const lines = useMemo(() => getMakerMarginLinesIndicator(limit), [limit]);
    const isMakerUser = useIsMakerUser();
    const shouldTakeUserGrossLimit = shouldUseUserGrossLimit(cpType, isMakerUser);

    const makerLimit = shouldTakeUserGrossLimit ? grossLimit : mutualGrossLimit ?? 0n;
    const takerEquity = shouldTakeUserGrossLimit ? equity * -1n : equity;
    const freeLimitPercent =
        makerLimit && takerEquity >= 0
            ? Number((BigInt(ONE_HUNDRED_PERCENT * PERCENT_SHIFT) * takerEquity) / makerLimit) * 1e-4
            : 0;

    return (
        <Styled.Container>
            <Background>
                <Styled.Line status={CounterpartyStatusType.NORMAL} value={100} />
                {lines.map(({ status, value }) => (
                    <Styled.Line key={status ?? "default"} status={status} value={value} />
                ))}
            </Background>
            <Equity $percent={freeLimitPercent}>
                <Styled.Line status={CounterpartyStatusType.NORMAL} value={100} />
                {lines.map(({ status, value }) => (
                    <Styled.Line key={status ?? "default"} status={status} value={value} />
                ))}
            </Equity>
            <Border $percent={freeLimitPercent} />
        </Styled.Container>
    );
};
