import styled from "styled-components";

export const Divider = styled.div`
    padding: 4px 0;
    background-color: transparent;

    ::before {
        content: "";
        display: block;
        height: 1px;
        background-color: ${(p) => p.theme.colors.ui12};
        width: 100%;
    }
`;
