import React from "react";
import styled, { css } from "styled-components";
import { IconError } from "../../components/icons";
import { onHover } from "../../const";
import { selectors } from "../../style/theme";
import { DummyButton } from "./Buttons";

export const InputGroup = styled.span`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
    & > input {
        width: 2.1ch;
        margin-right: 3px;
        text-align: end;
        background: transparent;
    }
`;

export const FormHeader = styled.header`
    ${selectors.mixinHeader2}
`;

export const FormLabel = styled.label`
    ${selectors.mixinInterface2}
    color: ${selectors.textSubdued};
    display: block;
`;

export const FormHint = styled.div`
    ${selectors.mixinBody2}
    color: ${(p) => p.theme.colors.textSubdued2};
    line-height: 20px;
`;
export const FormHintBold = styled.div`
    ${selectors.mixinBody2}
    color: ${(p) => p.theme.colors.textFG};
    line-height: 20px;
    font-weight: 600;
`;
export const FormInfo = styled.div`
    ${selectors.mixinBody2}
    color: ${(p) => p.theme.colors.textSubdued};
    font-weight: 600;
    line-height: 20px;
`;

export const FormFooter = styled.footer`
    ${selectors.mixinInterface2}
    color: ${selectors.textSubdued};
`;

export const ModalFormActions = styled.div`
    ${DummyButton} {
        ${(p) => p.theme.mixins.interface1}
        color: ${(p) => p.theme.colors.textSubdued};
        display: flex;
        align-items: center;
        transition: color ${(p) => p.theme.transitions.default};
        svg {
            margin-right: 7px;
        }
        ${onHover} {
            color: ${(p) => p.theme.colors.textFG};
        }
    }
`;

export const Error = styled.div`
    background: #fff;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    padding: 14px 25px 14px 48px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    position: relative;
    margin: 8px 0 24px; //todo remove
    z-index: 1;

    svg {
        position: absolute;
        top: 13px;
        left: 12px;
    }
    i {
        color: ${(p) => p.theme.colors.textSubdued};
        font-style: normal;
    }
`;

export const FormInputError = styled.div<{ isCentered?: true }>`
    ${selectors.mixinBody2}
    color: ${(p) => p.theme.colors.red};
    letter-spacing: -0.005em;
    ${({ isCentered }) =>
        isCentered &&
        css`
            text-align: center;
            margin-bottom: 16px;
        `}
`;

export const FormValue = styled.div.attrs((props) => ({
    ...props,
    children: props.children || "—",
}))`
    ${selectors.mixinInterface1}
`;

export const FormInline = styled.article`
    border-left: 3px solid rgba(0, 0, 0, 0.12);
    padding-left: 11px;
    word-break: break-word;
`;
export const ViewFormInline = styled(FormInline)`
    & > label {
        margin-top: 16px;
    }
    & > div {
        margin-top: 6px;
    }
    &:first-child {
        margin-top: 0;
    }
`;

export const FormError = ({ children, ...props }: React.HTMLProps<HTMLDivElement>) => {
    return (
        <Error {...{ props }}>
            <IconError /> {children}
        </Error>
    );
};

export const TextArea = styled.textarea<{ invalid?: boolean }>`
    padding: 12px;
    border-radius: 8px;
    background: ${(p) => p.theme.colors.smallButtonBG};
    resize: none;
    width: 100%;
    ${selectors.mixinHover}
    &::placeholder {
        color: ${selectors.textSubdued2};
    }
    :enabled:active,
    :enabled:focus {
        background-color: ${(p) => p.theme.colors.uiWhite100};
        box-shadow: 0 0 0 3px ${(p) => p.theme.colors.brand12},
            inset 0 0 0 1px ${(p) => p.theme.colors.brand100};
    }
    ${(p) =>
        p.invalid &&
        css`
            box-shadow: 0 0 0 3px ${p.theme.colors.negative16},
                inset 0 0 0 1px ${p.theme.colors.negative100} !important;
        `}
`;

export const FormFieldStack = styled.div`
    display: flex;
    & > * {
        flex: 1;
    }
    & > * + * {
        margin-left: 8px;
    }
    ${FormLabel} {
        margin-bottom: 8px;
    }
`;

export const FormHeaderIcon = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: center;
`;
