import { isDev } from "const/env";
import { SlimPage } from "feature/auth/slimPage";
import { BottomLogin, Text } from "feature/auth/style";
import { OutlinedButton } from "feature/form/Buttons";
import { FormHeader, FormHeaderIcon } from "feature/form/style";
import React from "react";
import { ButtonGroup, Form } from "style";
import { stringify } from "utils/json";
import { Copyright } from "./Copyright";
import { IconAttention } from "./icons";

type Props = { children: React.ReactNode; noUI?: boolean };
type State = { hasError: boolean; errorEncoded?: string };

class Boundary extends React.Component<Props, State> {
    state: State = { hasError: false };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: unknown, errorInfo: unknown) {
        this.setState({
            errorEncoded: btoa(stringify({ error, errorInfo })),
        });
    }

    render() {
        if (this.state.hasError) {
            if (this.props.noUI) return null;
            return (
                <SlimPage>
                    <Form>
                        <FormHeaderIcon>
                            <IconAttention />
                        </FormHeaderIcon>
                        <FormHeader>Oops, something went wrong</FormHeader>
                        <Text>Application has quit unexpectedly.</Text>
                        <ButtonGroup>
                            <OutlinedButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    location.reload();
                                }}
                            >
                                Refresh the page
                            </OutlinedButton>
                            {this.state.errorEncoded && (
                                <OutlinedButton
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (this.state.errorEncoded) {
                                            navigator.clipboard.writeText(this.state.errorEncoded);
                                        }
                                    }}
                                >
                                    Copy debug information
                                </OutlinedButton>
                            )}
                        </ButtonGroup>
                    </Form>
                    <BottomLogin>
                        <span>
                            <Copyright />
                        </span>
                    </BottomLogin>
                </SlimPage>
            );
        }
        return this.props.children;
    }
}

const PassThrough: React.FC = ({ children }) => <>{children}</>;

export const ErrorBoundary = isDev ? PassThrough : Boundary;
