import { Accordion } from "@fm-frontend/uikit";
import { getBestMarkerPrice } from "feature/trade/trading/TradingTile/utils/getBestMarkerPrice";
import { getBookLevels } from "feature/trade/trading/TradingTile/utils/getBookLevels";
import { EventContext } from "hooks/useEventEmitter";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Book } from "types";
import { formatValue } from "utils/EfxTypes";
import { MarketDepthSectionContext } from "./context";
import { MarketDepth } from "./MarketDepth";
import { useMarketDepthRestrictionState } from "./MarketDepth/EmptyState";
import { Title } from "./Title";

type MarketDepthProps = {
    isLoading: boolean | undefined;
    selectedInstrument?: string;
    selectedBook: Book;
    errorCode: number | undefined;
};

const LEVEL_DEPTH = 10;

export const MarketDepthSection: React.FC<MarketDepthProps> = ({
    isLoading = true,
    selectedInstrument,
    selectedBook,
    errorCode,
}) => {
    const [expanded, setExpanded] = useState(true);
    const [fullDepth, setFullDepth] = useState(false);
    const { emit } = useContext(EventContext);

    const { bidLevels, askLevels } = getBookLevels({
        book: selectedBook,
    });

    const { croppedBidLevels, croppedAskLevels } = useMemo(() => {
        if (fullDepth) {
            return {
                croppedBidLevels: bidLevels,
                croppedAskLevels: askLevels,
            };
        }

        return {
            croppedBidLevels: bidLevels.slice(0, LEVEL_DEPTH),
            croppedAskLevels: askLevels.slice(0, LEVEL_DEPTH),
        };
    }, [bidLevels, askLevels, fullDepth]);

    const bestBidMarketPrice = getBestMarkerPrice(croppedBidLevels);
    const bestAskMarketPrice = getBestMarkerPrice(croppedAskLevels);

    const restrictionState = useMarketDepthRestrictionState({
        errorCode,
        selectedInstrument,
        bidLevels,
        askLevels,
    });

    const handleMarketDepthItemClick = useCallback(
        (price?: number | bigint) => {
            if (price !== undefined) {
                emit?.({
                    instrument: selectedInstrument,
                    type: "setPrice",
                    value: formatValue(price, "price", true),
                });
            }
        },
        [emit, selectedInstrument],
    );

    return (
        <MarketDepthSectionContext.Provider
            value={{
                onMarketDepthItemClick: handleMarketDepthItemClick,
            }}
        >
            <Accordion
                header={
                    <Title
                        instrument={selectedInstrument}
                        bidPrice={bestBidMarketPrice}
                        askPrice={bestAskMarketPrice}
                    />
                }
                defaultExpanded={expanded}
                onExpandedChange={setExpanded}
                content={
                    <MarketDepth
                        loading={isLoading}
                        restrictionState={restrictionState}
                        bidList={croppedBidLevels}
                        askList={croppedAskLevels}
                        fullDepth={fullDepth}
                        handleFullDepth={() => setFullDepth(!fullDepth)}
                    />
                }
            />
        </MarketDepthSectionContext.Provider>
    );
};
