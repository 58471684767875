import { PSmall, PXSmall } from "@fm-frontend/uikit";
import { getLimitStatus } from "feature/assetsControl/components/AssetsTabContent/utils";
import { useActionPermissions } from "hooks/useActionPermissions";
import { FC } from "react";
import { useHistory } from "react-router";
import { useUserType } from "store/hooks";
import styled from "styled-components";
import { ClientType, ClientTypeEnum, CounterpartyLimit } from "types";
import { useFreeLimitPercent } from "../hooks/useFreeLimitPercent";
import { useIsMarkupAvailable } from "../hooks/useIsMarkupAvailable";
import { useLimitData } from "../hooks/useLimitData";
import { MenuItem } from "./Item";

const MarkupsContainer = styled.span`
    display: flex;
    gap: 4px;
`;

const AssetsCount = styled(PXSmall)`
    vertical-align: top;
`;

const Markups: FC<{ defaultMarkup: string | number; markupsPerInstruments: string | number }> = ({
    defaultMarkup,
    markupsPerInstruments,
}) => (
    <MarkupsContainer>
        <PSmall>{defaultMarkup}</PSmall>
        {markupsPerInstruments !== 0 && <AssetsCount>+{markupsPerInstruments}</AssetsCount>}
    </MarkupsContainer>
);

const Container = styled.div`
    display: flex;
    gap: 12px;
`;

const Highlight = styled.span<{ $percent: number }>`
    color: ${(p) => {
        switch (getLimitStatus(p.$percent)) {
            case "normal":
                return p.theme.colors.ui52;
            case "warn":
                return p.theme.colors.attention100;
            case "low":
                return p.theme.colors.negative100;
            default:
                return "transparent";
        }
    }};
`;

type MenuProps = {
    limit: CounterpartyLimit;
    limitCpType: ClientType;
    className?: string;
};

export const Menu: FC<MenuProps> = ({ limit, limitCpType, className }) => {
    const history = useHistory();
    const {
        cpId,
        defaultMarkup,
        markupsPerInstrumentsCount,
        limitPerAssetsCount,
        shortSalesBansCount,
        overnightFeesCount,
    } = useLimitData(limit);
    const userType = useUserType();
    const isMarkupAvailable = useIsMarkupAvailable(limitCpType);
    const { getUserActionPermissions } = useActionPermissions();
    const { isShortSalesBanAllowed, isOvernightsAllowed } = getUserActionPermissions(cpId);

    const freeLimitPercent = useFreeLimitPercent();
    const percents = freeLimitPercent
        .reduce((acc, item) => {
            if (item.cpId === cpId) {
                acc.push(item.userPercent);
            }
            return acc;
        }, [] as (number | null | undefined)[])
        .filter((item) => item !== null && item !== undefined) as number[];

    const minPercent = Math.min(...percents, 100);

    return (
        <Container className={className}>
            {isMarkupAvailable && (
                <MenuItem
                    title="Markups"
                    subtitle={
                        <Markups
                            defaultMarkup={defaultMarkup}
                            markupsPerInstruments={markupsPerInstrumentsCount}
                        />
                    }
                    onClick={() =>
                        history.push(
                            `/assets-and-instruments/instruments?cp=${cpId}&instrumentMarkups=-1`,
                        )
                    }
                />
            )}
            {userType !== ClientTypeEnum.SubaccountTaker && (
                <MenuItem
                    title="Limits per asset"
                    subtitle={
                        limitPerAssetsCount === 0 ? (
                            "No"
                        ) : (
                            <Highlight $percent={minPercent}>{limitPerAssetsCount}</Highlight>
                        )
                    }
                    onClick={() =>
                        history.push(
                            `/assets-and-instruments/assets?cp=${cpId}&shortsales=-1&overnights=-1&limitPerAsset=-1`,
                        )
                    }
                />
            )}
            {isShortSalesBanAllowed && (
                <MenuItem
                    title="Short sales"
                    subtitle={`${shortSalesBansCount} ban`}
                    onClick={() =>
                        history.push(
                            `/assets-and-instruments/assets?cp=${cpId}&shortsales=-1&overnights=-1&limitPerAsset=-1`,
                        )
                    }
                />
            )}
            {isOvernightsAllowed && (
                <MenuItem
                    title="Overnight fees"
                    subtitle={overnightFeesCount === 0 ? "No" : `${overnightFeesCount} fee`}
                    onClick={() =>
                        history.push(
                            `/assets-and-instruments/assets?cp=${cpId}&shortsales=-1&overnights=-1&limitPerAsset=-1`,
                        )
                    }
                />
            )}
        </Container>
    );
};
