import { EmDash } from "const";
import React from "react";
import { CopyCell } from "../CopyCell";

type LinkedTradeCellProps = {
    linkedTradeId?: number;
};

export const LinkedTradeCell: React.FC<LinkedTradeCellProps> = ({ linkedTradeId }) => {
    if (linkedTradeId === undefined || linkedTradeId === 0) {
        return <>{EmDash}</>;
    }

    return <CopyCell content={linkedTradeId}>{linkedTradeId}</CopyCell>;
};
