import { AccountStatusType, CryptoAddress } from "../types";

export const DEFAULT_CRYPTO_ADDRESS: CryptoAddress = {
    rule: null,
    currency: null,
    network: null,
    wallet: "",
    privateNote: "",
    memoOrTag: "",
    revisions: [],
    status: AccountStatusType.Verification,
};

export const isDefaultRow = (row: CryptoAddress) => {
    const { id, rule, currency, network, revisions, _validationItemId, status, memoOrTagRequired, ...fieldToValidate } =
        row;

    const cpDefault = !row.rule?.cp?.id;
    const currencyDefault = !row.currency?.id;
    const networkDefault = !row.network?.id;

    const otherFieldsDefault = !Object.values(fieldToValidate).some(Boolean);

    return cpDefault && currencyDefault && networkDefault && otherFieldsDefault;
};

export const removeDefaultRows = (rows: CryptoAddress[], cb: (defaultRowsIds: number[]) => void) => {
    const defaultRows = rows.reduce<number[]>((acc, curr, currentIndex) => {
        if (isDefaultRow(curr)) {
            acc.push(currentIndex);
        }

        return acc;
    }, []);

    if (defaultRows.length) {
        cb(defaultRows);
    }
};
