import {
    ACTIONS_TABLE_COLUMN_KEY,
    EXPAND_TABLE_COLUMN_KEY,
    IconButton,
    Icons,
} from "@fm-frontend/uikit";
import { createColumnHelper } from "@tanstack/react-table";
import { LimitInfo } from "feature/assetsControl/types";
import { useMemo } from "react";
import { useTheme } from "styled-components";
import { fmt, fmtDeltaratePercent } from "utils/format";
import { AssetRow, CommonAssetLimit, CpRow, RowData } from "../../types";
import { isAssetRow } from "../../useTableData";
import {
    AssetActionCell,
    AssetBanCell,
    AssetBanStatus,
    AssetExpandCell,
    AssetNameCell,
    AssetRefPriceCell,
    AssetRefPriceHeaderCell,
    CpActionCell,
    CpBanCell,
    CpExpandCell,
    CpLimitCell,
    CpNameCell,
    CpOvernightCell,
    CpTypeCell,
    GroupAssetCell,
    TwoRowsHeader,
} from "../cells";
import { CellType } from "../cells/BanCell";

export const columnHelper = createColumnHelper<RowData>();

const expandColumn = columnHelper.accessor((row) => row, {
    id: EXPAND_TABLE_COLUMN_KEY,
    header: ({ table }) => {
        const isAllRowsExpanded = table.getIsAllRowsExpanded();

        return (
            <IconButton
                variant="plain"
                Icon={isAllRowsExpanded ? Icons.CollapseAll : Icons.ExpandAll}
                onClick={table.getToggleAllRowsExpandedHandler()}
            />
        );
    },
    cell: (subject) => {
        const { row } = subject;
        const rowData = subject.getValue();

        if (isAssetRow(rowData)) {
            return (
                <AssetExpandCell
                    counterparties={rowData.counterparties}
                    isExpanded={row.getIsExpanded()}
                />
            );
        }

        return <CpExpandCell userLimit={rowData.userLimit} cpLimit={rowData.cpLimit} />;
    },
    enableSorting: false,
    meta: {
        cellStyleProps: {
            width: "32px",
            paddingRight: "4px",
            textAlign: "center",
        },
        headerStyleProps: {
            width: "32px",
            textAlign: "center",
        },
    },
});

const nameColumn = columnHelper.accessor((row) => row, {
    id: "name",
    cell: (subject) => {
        const value = subject.getValue();

        if (isAssetRow(value)) {
            return <AssetNameCell asset={value.currency} />;
        } else {
            const { id, name } = value;

            return <CpNameCell cpName={name} cpId={id} />;
        }
    },
    header: "Name",
    sortingFn: (rowA, rowB) => {
        const currency1 = rowA.original.currency;
        const currency2 = rowB.original.currency;

        return currency2.localeCompare(currency1);
    },
    meta: {
        headerStyleProps: {
            width: "17%",
        },
    },
});

const clientTypeColumn = columnHelper.accessor((row) => row, {
    id: "clientType",
    cell: (subject) => {
        const value = subject.getValue();
        if (isAssetRow(value)) {
            return <AssetRefPriceCell asset={value.currency} />;
        } else {
            return <CpTypeCell cpType={value.cpType} />;
        }
    },
    header: () => <AssetRefPriceHeaderCell />,
    enableSorting: false,
    meta: {
        cellStyleProps: {
            width: "88px",
            textAlign: "right",
            padding: "0 12px 0 0",
        },
        headerStyleProps: {
            width: "88px",
            textAlign: "right",
            padding: "0 12px 6px 0",
        },
    },
});

const buildShortSalesStatusColumn = ({
    columnId,
    title,
    subtitle,
    getShortSalesStatus,
    getCommonShortSalesStatus,
    cellType,
    borderLeftStyle,
}: {
    columnId: string;
    title?: string;
    subtitle: string;
    getCommonShortSalesStatus: (assetRow: AssetRow) => AssetBanStatus;
    getShortSalesStatus: (cpRow: CpRow) => boolean;
    cellType: CellType;
    borderLeftStyle?: string;
}) =>
    columnHelper.accessor((row) => row, {
        id: columnId,
        header: () => <TwoRowsHeader title={title} subtitle={subtitle} />,
        cell: (subject) => {
            const rowData = subject.getValue();

            if (isAssetRow(rowData)) {
                if (subject.row.getIsExpanded()) {
                    return null;
                }

                return <AssetBanCell status={getCommonShortSalesStatus(rowData)} />;
            }

            return (
                <CpBanCell
                    cellType={cellType}
                    cpType={rowData.cpType}
                    isBanned={getShortSalesStatus(rowData)}
                />
            );
        },
        enableSorting: false,
        meta: {
            cellStyleProps: {
                padding: "0 0 0 0",
                width: "80px",
                borderLeft: borderLeftStyle,
            },
            headerStyleProps: {
                width: "80px",
                padding: "0 0 6px 0",
            },
        },
    });
const CP_SHORT_SALES_STATUS_COLUMN_ID = "cpShortSaleStatus";

const buildLimitColumn = ({
    columnId,
    title,
    subtitle,
    getAssetLimit,
    getCommonAssetLimit,
    borderLeftStyle,
}: {
    columnId: string;
    title?: string;
    subtitle: string;
    getCommonAssetLimit: (assetRow: AssetRow) => CommonAssetLimit;
    getAssetLimit: (cpRow: CpRow) => LimitInfo | undefined;
    borderLeftStyle?: string;
}) =>
    columnHelper.accessor((row) => row, {
        id: columnId,
        header: () => <TwoRowsHeader title={title} subtitle={subtitle} />,
        cell: (subject) => {
            const rowData = subject.getValue();
            if (isAssetRow(rowData)) {
                if (subject.row.getIsExpanded()) {
                    return null;
                }

                const commonAssetLimit = getCommonAssetLimit(rowData);
                if (commonAssetLimit === "mixed" || commonAssetLimit === undefined) {
                    return <GroupAssetCell value={commonAssetLimit} />;
                }

                const formattedLimit = `${
                    fmt(commonAssetLimit.totalLimit, {
                        significantDigits: commonAssetLimit.significantDigits,
                        type: "limit",
                        showZero: true,
                    }).formattedValue
                } ${commonAssetLimit.limitAsset}`;
                return <GroupAssetCell value={formattedLimit} />;
            }

            const assetLimit = getAssetLimit(rowData);
            return <CpLimitCell limitInfo={assetLimit} />;
        },
        enableSorting: false,
        meta: {
            cellStyleProps: {
                padding: "0 0 0 0",
                borderLeft: borderLeftStyle,
            },
            headerStyleProps: {
                padding: "0 0 6px 0",
            },
        },
    });
const CP_LIMIT_COLUMN_ID = "cpLimit";

export const useOtherUserTypesColumns = () => {
    const theme = useTheme();

    return useMemo(() => {
        const commonBorderLeftStyle = `1px solid ${theme.colors.ui4}`;
        const boldBorderLeftStyle = `1px solid ${theme.colors.ui12}`;

        const userShortSalesStatusColumn = buildShortSalesStatusColumn({
            columnId: "yourShortSalesStatus",
            title: "Short sales",
            subtitle: "by You",
            getCommonShortSalesStatus: (assetRow) => assetRow.isUserSetShortSalesBan,
            getShortSalesStatus: (cpRow) => cpRow.isUserSetShortSalesBan,
            cellType: "user",
            borderLeftStyle: boldBorderLeftStyle,
        });
        const cpShortSalesStatusColumn = buildShortSalesStatusColumn({
            columnId: CP_SHORT_SALES_STATUS_COLUMN_ID,
            subtitle: "by CP",
            getCommonShortSalesStatus: (assetRow) => assetRow.isCpSetShortSalesBan,
            getShortSalesStatus: (cpRow) => cpRow.isCpSetShortSalesBan,
            cellType: "cp",
            borderLeftStyle: commonBorderLeftStyle,
        });
        const longOvernightsColumn = columnHelper.accessor((row) => row, {
            id: "longOvernights",
            header: () => <TwoRowsHeader title="Overnights" subtitle="Long" />,
            cell: (subject) => {
                const rowData = subject.getValue();

                if (isAssetRow(rowData)) {
                    if (subject.row.getIsExpanded()) {
                        return null;
                    }

                    const { positiveRate } = rowData ?? {};
                    if (typeof positiveRate === "number") {
                        return (
                            <GroupAssetCell
                                value={`${fmtDeltaratePercent(positiveRate).copyableValue}%`}
                            />
                        );
                    }
                    return <GroupAssetCell value={positiveRate} />;
                }

                const { positiveRate } = rowData ?? {};

                return <CpOvernightCell value={positiveRate} />;
            },
            enableSorting: false,
            meta: {
                cellStyleProps: {
                    width: "88px",
                    borderLeft: boldBorderLeftStyle,
                    padding: "0 0 0 0",
                },
                headerStyleProps: {
                    width: "88px",
                    padding: "0 0 6px 0",
                },
            },
        });
        const shortOvernightsColumn = columnHelper.accessor((row) => row, {
            id: "shortOvernights",
            header: () => <TwoRowsHeader subtitle="Short" />,
            cell: (subject) => {
                const rowData = subject.getValue();

                if (isAssetRow(rowData)) {
                    if (subject.row.getIsExpanded()) {
                        return null;
                    }

                    const { negativeRate } = rowData ?? {};
                    if (typeof negativeRate === "number") {
                        return (
                            <GroupAssetCell
                                value={`${fmtDeltaratePercent(negativeRate).copyableValue}%`}
                            />
                        );
                    }
                    return <GroupAssetCell value={negativeRate} />;
                }

                const { negativeRate } = rowData ?? {};

                return <CpOvernightCell value={negativeRate} />;
            },
            enableSorting: false,
            meta: {
                cellStyleProps: {
                    width: "88px",
                    borderLeft: commonBorderLeftStyle,
                    padding: "0 0 0 0",
                },
                headerStyleProps: {
                    width: "88px",
                    padding: "0 0 6px 0",
                },
            },
        });
        const userLimitColumn = buildLimitColumn({
            columnId: "userLimit",
            title: "Asset limit",
            subtitle: "by You",
            getCommonAssetLimit: (assetRow) => assetRow.userLimit,
            getAssetLimit: (cpRow) => cpRow.userLimit,
            borderLeftStyle: boldBorderLeftStyle,
        });
        const cpLimitColumn = buildLimitColumn({
            columnId: CP_LIMIT_COLUMN_ID,
            subtitle: "by CP",
            getCommonAssetLimit: (assetRow) => assetRow.cpLimit,
            getAssetLimit: (cpRow) => cpRow.cpLimit,
            borderLeftStyle: commonBorderLeftStyle,
        });
        const cpActionColumn = columnHelper.accessor((row) => row, {
            id: ACTIONS_TABLE_COLUMN_KEY,
            header: "",
            cell: (subject) => {
                const rowData = subject.getValue();

                if (isAssetRow(rowData)) {
                    return <AssetActionCell asset={rowData.currency} />;
                }

                return (
                    <CpActionCell
                        asset={rowData.currency}
                        cpId={rowData.id}
                        cpName={rowData.name}
                    />
                );
            },
            meta: {
                cellStyleProps: {
                    width: "40px",
                    borderLeft: boldBorderLeftStyle,
                    textAlign: "center",
                    padding: "0 0 0 0",
                },
                headerStyleProps: {
                    width: "40px",
                },
            },
        });

        const masterColumnsCounterpartiesView = [
            expandColumn,
            nameColumn,
            clientTypeColumn,
            userShortSalesStatusColumn,
            cpShortSalesStatusColumn,
            longOvernightsColumn,
            shortOvernightsColumn,
            userLimitColumn,
            cpLimitColumn,
            cpActionColumn,
        ];
        const masterColumnsSubaccountsView = masterColumnsCounterpartiesView.filter(
            ({ id }) => id !== CP_SHORT_SALES_STATUS_COLUMN_ID && id !== CP_LIMIT_COLUMN_ID,
        );
        const makerColumns = [
            expandColumn,
            nameColumn,
            clientTypeColumn,
            userShortSalesStatusColumn,
            longOvernightsColumn,
            shortOvernightsColumn,
            userLimitColumn,
            cpLimitColumn,
            cpActionColumn,
        ];

        const takerShortSalesStatusColumn = buildShortSalesStatusColumn({
            columnId: CP_SHORT_SALES_STATUS_COLUMN_ID,
            title: "Short sales",
            subtitle: "by CP",
            getCommonShortSalesStatus: (assetRow) => assetRow.isCpSetShortSalesBan,
            getShortSalesStatus: (cpRow) => cpRow.isCpSetShortSalesBan,
            cellType: "cp",
            borderLeftStyle: boldBorderLeftStyle,
        });
        const takerColumns = [
            expandColumn,
            nameColumn,
            clientTypeColumn,
            takerShortSalesStatusColumn,
            longOvernightsColumn,
            shortOvernightsColumn,
            userLimitColumn,
            cpLimitColumn,
            cpActionColumn,
        ];

        return {
            masterColumnsCounterpartiesView,
            masterColumnsSubaccountsView,
            makerColumns,
            takerColumns,
        };
    }, [theme]);
};
