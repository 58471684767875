import styled from "styled-components";
import { getMatch } from "../../../common";
import { HStack } from "../../../layout";
import { P } from "../../../typography";
import { DropdownOption } from "../types";

const Container = styled(HStack)`
    align-items: center;
    gap: 8px;
    overflow: hidden;
`;

type BasicOptionEssenceProps<T> = {
    option: DropdownOption<T>;
    query?: string;
};

export const BasicOptionEssence = <T,>({ option, query = "" }: BasicOptionEssenceProps<T>) => {
    if (!option) {
        return <></>;
    }

    return (
        <Container>
            <P title={option.text} ellipsis>
                {getMatch(option.text, query)}
            </P>
        </Container>
    );
};
