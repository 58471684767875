import { P } from "@fm-frontend/uikit";
import { EmDash } from "const";
import React from "react";
import { CopyCell } from "../CopyCell";

type ClientOrderIdColumnProps = {
    clientOrderId?: number;
};

export const ClientOrderIdCell: React.FC<ClientOrderIdColumnProps> = ({ clientOrderId }) => {
    if (clientOrderId === undefined || clientOrderId === 0) {
        return <>{EmDash}</>;
    }

    return (
        <CopyCell content={clientOrderId}>
            <P>{clientOrderId}</P>
        </CopyCell>
    );
};
