import styled from "styled-components";

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;

    & > svg {
        :first-child {
            z-index: 1;
            position: absolute;
            left: 12px;
        }
    }

    button {
        position: absolute;
        right: 12px;
        background-color: transparent;
    }

    & > div > div {
        padding-left: 32px;
        padding-right: 40px;
    }
`;

export const EmptyContent = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    text-align: center;
    padding: 0 14px;
`;

export const Panda = styled.div`
    font-size: 48px;
`;
