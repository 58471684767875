import React from "react";
import styled, { css } from "styled-components";
import { H3 } from "../typography";
import { shadowCss } from "./Layout";
import { BoxProps, getSpaceCss, MarginProps, Space } from "./types";

export * from "./Layout";

const ELLIPSIS_CSS = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const Box = styled.div<BoxProps & MarginProps>`
    ${(p) => p.asCard && shadowCss};
    width: ${(p) => p.width};
    height: ${(p) => p.height};
    min-width: ${(p) => p.minWidth};
    min-height: ${(p) => p.minHeight};
    max-width: ${(p) => p.maxWidth};
    max-height: ${(p) => p.maxHeight};
    overflow: ${(p) => p.overflow};

    margin: ${(p) => getSpaceCss(p.margin)};
    background-color: ${(p) => p.backgroundColor};
    ${(p) => p.ellipsis && ELLIPSIS_CSS}
`;

export type SpacingProps = Partial<{ spacing: Space }>;

export type PaddingProps = Partial<{
    padding: Space;
    paddingX: Space;
    paddingY: Space;
    paddingTop: Space;
    paddingBottom: Space;
    paddingLeft: Space;
    paddingRight: Space;
}>;

type DirectionProps = Partial<{ direction: "row" | "column" }>;

export type FlexProps = Partial<{
    flex: string | number;
    justifyContent: "start" | "end" | "center" | "space-between" | "space-around" | "space-evenly";
    alignItems: "stretch" | "center" | "flex-start" | "flex-end" | "baseline";
}>;

export const Flex = styled(Box)<SpacingProps & PaddingProps & FlexProps>`
    display: flex;
    flex: ${(p) => p.flex};

    gap: ${(p) => p.spacing && `${p.spacing}px`};

    padding: ${(p) => p.padding && `${p.padding}px`};
    padding-left: ${(p) =>
        p.paddingLeft !== undefined
            ? `${p.paddingLeft}px`
            : p.paddingX !== undefined
            ? `${p.paddingX}px`
            : null};
    padding-right: ${(p) =>
        p.paddingRight !== undefined
            ? `${p.paddingRight}px`
            : p.paddingX !== undefined
            ? `${p.paddingX}px`
            : null};
    padding-top: ${(p) =>
        p.paddingTop !== undefined
            ? `${p.paddingTop}px`
            : p.paddingY !== undefined
            ? `${p.paddingY}px`
            : null};
    padding-bottom: ${(p) =>
        p.paddingBottom !== undefined
            ? `${p.paddingBottom}px`
            : p.paddingY !== undefined
            ? `${p.paddingY}px`
            : null};

    justify-content: ${(p) => p.justifyContent};
    align-items: ${(p) => p.alignItems};
`;

export const Stack = styled(Flex)<DirectionProps>`
    flex-direction: ${(p) => p.direction ?? "row"};
`;

export const VStack = styled(Flex)`
    flex-direction: column;
`;

export const HStack = styled(Flex)`
    flex-direction: row;
`;

export const Wrap = styled(HStack)`
    flex-wrap: wrap;
`;

export const Center = styled(Flex)`
    align-items: center;
    justify-content: center;
`;

const FieldGroupFn = styled(Stack).attrs<typeof Stack>((props) => ({
    direction: props.direction ?? "column",
}))`
    gap: 1px;

    * > *[data-field-group] {
        border-radius: unset;
    }

    > :first-child *[data-field-group] {
        border-radius: 8px 8px 0 0;
    }
    > :last-child *[data-field-group] {
        border-radius: 0 0 8px 8px;
    }

    > :only-child *[data-field-group] {
        border-radius: 8px;
    }
`;

const FieldGroupTitle = styled(H3)`
    color: ${(p) => p.theme.colors.ui52};
`;

export const FieldGroup = Object.assign(FieldGroupFn, {
    Title: FieldGroupTitle,
});

const Container = styled(VStack)`
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    background-color: ${(p) => p.theme.colors.uiWhite100};
`;

export const StoryContainer: React.FC = ({ children }) => {
    return (
        <Container width="100%">
            <VStack spacing={24} minWidth="390px">
                {children}
            </VStack>
        </Container>
    );
};
