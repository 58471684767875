import { post } from "../index";

type CancelInvitationApiParams = {
    referralEmail: string;
};
export const cancelInvitationApi = (params: CancelInvitationApiParams) => {
    return post("cancelInviteLink", {
        email: params.referralEmail,
    });
};
