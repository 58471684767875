import { onHover, onMobile } from "const";
import { DropdownDirection } from "hooks/useDropdownDirection";
import { TransitionState } from "hooks/useTransition";
import { cover, ellipsis } from "polished";
import styled, { css } from "styled-components";
import { transition } from "utils";

export const ListBanner = styled.article`
    ${(p) => p.theme.mixins.interface2}
    max-width: 252px;
    white-space: break-spaces;
    hyphens: auto;
    text-align: center;
    margin: 9px 8px 10px;
    div {
        margin-top: 4px;
        font-weight: 400;
        font-size: 11px;
    }
`;

export const ListSectionLabel = styled.li<{ isCurrent: boolean }>`
    position: relative;
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: 0.04em;
    margin: 8px 12px 7px;

    color: rgba(36, 48, 52, 0.52);
    mark {
        color: ${(p) => p.theme.colors.textFG};
    }
    svg {
        position: absolute;
        top: -4px;
        right: -3px;
    }
    ${(p) =>
        p.isCurrent &&
        css`
            &,
            mark {
                color: ${(p) => p.theme.colors.brand};
                font-weight: 600;
            }
        `}
`;

export const ListButtonContainer = styled.section`
    width: 100%;
    padding: 8px;
    border-top: 1px solid rgba(36, 48, 52, 0.08);
    & > button {
        width: 100%;
    }
`;

export const ListContainer = styled.div<
    {
        transitionState: TransitionState;
        isCompact?: boolean;
        isMobile: boolean;
        hasHeader?: boolean;
        hasBottomAction?: boolean;
    } & DropdownDirection
>`
    position: absolute;
    min-width: ${(p) => (p.isCompact ? "200px" : "270px")};
    background: ${(p) => p.theme.colors.activeBG};
    box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.16), 0px 4px 6px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    white-space: nowrap;
    opacity: 0;
    z-index: 2;
    margin-top: ${(p) => (p.isCompact ? "0" : "3px")};
    transition: opacity 75ms 25ms ease-in-out, transform 100ms ease-in-out;
    ${({ horizontal, vertical, isCompact, isMobile }) => {
        if (isCompact && isMobile)
            if (horizontal === "right") {
                return css`
                    left: 40vw;
                    transform-origin: ${vertical === "top" ? "bottom" : "top"} left;
                `;
            }
        if (horizontal === "left") {
            return css`
                right: 0;
                transform-origin: ${vertical === "top" ? "bottom" : "top"} right;
            `;
        }
        if (horizontal === "right") {
            return css`
                left: ${vertical === "center" ? "calc(100% - 4px)" : "0"};
                transform-origin: ${vertical === "top" ? "bottom" : "top"} left;
            `;
        }
        if (horizontal === "center") {
            return css`
                left: ${vertical === "center" ? "calc(100% - 4px)" : "-8px"};
                transform-origin: ${vertical === "top" ? "bottom" : "top"} center;
            `;
        }
    }}
    ${({ vertical, hasHeader }) => {
        if (vertical === "center") {
            if (hasHeader)
                return css`
                    top: -34px;
                `;

            return css`
                top: -4px;
            `;
        }
        if (vertical === "top") {
            return css`
                bottom: 0;
            `;
        }
        if (vertical === "bottom") {
            return css`
                top: 100%;
            `;
        }
    }}}
    ${({ transitionState, vertical }) => {
        switch (transitionState) {
            case "entering":
            case "exiting": {
                return css`
                    transform: translateY(${vertical === "bottom" ? "-" : ""}6px) scale(0.8);
                `;
            }
            case "active": {
                return css`
                    opacity: 1;
                    transform: translate(0, 0);
                `;
            }
        }
    }}
    &:after {
        content: '';
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 12px;
        border-radius: 8px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) -9.26%, #FFFFFF 81.48%);
        opacity: 0.8;
        ${(p) =>
            p.hasBottomAction &&
            css`
                bottom: 41px;
                border-radius: 0;
            `}
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 12px;
        border-radius: 8px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) -9.26%, #FFFFFF 81.48%);
        opacity: 0.8;
        z-index: 1;
    }
`;

export const ListItem = styled.li<{
    isSelected: boolean;
    isFocused?: boolean;
    isCurrent?: boolean;
    isCompact?: boolean;
}>`
    ${(p) => p.theme.mixins.p}
    position: relative;
    list-style: none;
    border-radius: 6px;
    cursor: pointer;
    scroll-snap-align: center;
    transition: ${(p) => transition(p.theme.transitions.default, "color", "background-color")};
    display: flex;
    align-items: center;
    margin: 0 4px;
    color: ${(p) => p.theme.colors.textFG};

    ${onHover} {
        background-color: ${(p) => p.theme.colors.listItemHover};
        z-index: 2;
    }
    & > img,
    & > svg {
        margin: 6px 8px;
    }
    & > span:first-of-type {
        ${(p) => ellipsis(p.isCompact ? 112 : 182)}
    }
    & > span:last-of-type {
        margin: ${(p) => (p.isCompact ? "5px 6px 0" : "7px 12px 6px")};
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        font-size: 12px;
        color: ${(p) => p.theme.colors.ui32};
    }
    ${(p) =>
        p.isCurrent &&
        css`
            & > span,
            & > span:last-of-type {
                color: ${p.theme.colors.brand100};
                font-weight: ${p.isCompact ? "initial" : "500"};
            }
            & > span > svg path {
                fill: ${p.theme.colors.brand100};
            }
        `}
`;

export const List = styled.ul`
    user-select: none;
    padding: 4px 0;
    margin: 0;
    border: none;
    color: ${(p) => p.theme.colors.textSubdued};
    list-style: none;
    max-height: 326px;
    overflow: auto;
    scroll-snap-type: block mandatory;
    &:after {
        content: "";
        ${cover()}
        pointer-events: none;
        user-select: none;
        z-index: 1;
        border-radius: 8px;
    }
    ${ListItem} + ${ListSectionLabel} {
        margin-top: 13px;
    }
`;

export const ListSeparatorItem = styled.li`
    display: flex;
    justify-content: center;
    margin: 4px 0 3px;
`;

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #ebe9e9;
    border-radius: 1px;
`;

export const SearchSelectContainer = styled.div`
    position: relative;
    cursor: pointer;
    svg {
        margin-left: 2px;
    }
    li {
        display: flex;
        justify-content: space-between;
    }
`;

export const Contents = styled.div<{ isListOpen?: boolean; isActive?: boolean; hasQuery: boolean }>`
    width: 139px;
    ${(p) => transition(p.theme.transitions.default, "width", "background-color", "color")}
    background-color: ${(p) => (p.isActive ? p.theme.colors.brand : p.theme.colors.selectBG)};
    overflow: hidden;
    border-radius: 8px;

    ${onHover} {
        background-color: ${({ theme: { colors }, isActive }) =>
            isActive ? colors.brand : colors.selectBgHover};
    }

    input {
        position: relative;
        ${(p) => p.theme.mixins.p};
        line-height: 20px;
        overflow: hidden;
        background: transparent;
        display: block;
        width: 194px;
        padding: 10px 24px 10px 36px;
        &::placeholder {
            color: ${(p) => (p.isActive ? p.theme.colors.uiWhite100 : "#010101")};
            opacity: 1;
            font-weight: 600;
        }
        &::-webkit-search-cancel-button {
            display: none;
        }
        ${(p) =>
            p.isActive &&
            css`
                ${ellipsis(139)}
            `}
    }
    ${(p) =>
        p.isListOpen &&
        css`
            width: 254px;
            input {
                width: 100%;
                &::placeholder {
                    color: ${(p) => p.theme.colors.textSubdued};
                    font-weight: 400;
                }
            }
        `}

    & > svg:nth-of-type(1) {
        position: absolute;
        top: 13px;
        left: 12px;
        ${(p) =>
            p.isActive &&
            css`
                path:first-of-type {
                    fill: rgba(255, 255, 255, 0.54);
                }
                path:last-of-type {
                    stroke: rgba(255, 255, 255, 0.54);
                }
            `}
    }
    & > svg:nth-of-type(2) {
        position: absolute;
        right: 12px;
        top: 13px;
        pointer-events: none;
    }
    & > button {
        position: absolute;
        top: 13px;
        right: 12px;
        ${(p) =>
            !p.hasQuery &&
            css`
                opacity: 0;
                ${p.theme.mixins.nonInteractive}
            `}
    }
    ${onMobile} {
        width: unset;
    }
`;

export const MatchedPart = styled.span`
    color: rgba(36, 48, 52, 0.52);
    mark {
        color: ${(p) => p.theme.colors.textFG};
    }
`;
